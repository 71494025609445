import { InfoContext } from 'configure'
import React, { createContext, useState, useEffect, useMemo, useContext } from 'react'

const PendoContext = createContext<{ isPendoLoaded: boolean } | undefined>(undefined)
declare const pendo: pendo.Pendo

export const PendoProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isPendoLoaded, setIsPendoLoaded] = useState(false)
    const info = useContext(InfoContext)

    useEffect(() => {
        ;(async () => {
            try {
                if (isPendoLoaded) {
                    return
                }

                const environment = info?.environment?.toLowerCase()
                if (!environment || !['production', 'staging'].includes(environment)) {
                    console.info('Pendo is disabled')
                    return
                }

                const script = document.createElement('script')
                script.src = 'https://cdn.pendo.io/agent/static/35f845bc-c2e6-4140-43cf-dff32db93bdc/pendo.js'
                script.type = 'text/javascript'
                script.async = true
                script.onload = () => {
                    const account =
                        'CENSEO-' + (import.meta.env.VITE_APP_DEPLOYMENT_ENVIRONMENT ?? 'DEFAULT_ACCOUNT_ID')
                    pendo.initialize({
                        account: {
                            id: account.toUpperCase(),
                        },
                    })
                    setIsPendoLoaded(true)
                }
                document.getElementsByTagName('head')[0].appendChild(script)
            } catch (error) {
                console.error('Error getting the current user:', error)
            }
        })()
    }, [isPendoLoaded, info?.environment])

    return (
        <PendoContext.Provider value={useMemo(() => ({ isPendoLoaded }), [isPendoLoaded])}>
            {children}
        </PendoContext.Provider>
    )
}
