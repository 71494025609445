import React from 'react'
import { classNameMaker } from 'ui/utils'
import { Button, Text, Link as PsyomicsLink } from '@psyomics/components'
import { useAuth } from 'registration/context/auth'
import Styles from './AccountDetails.module.scss'
import { useNavigate, Link } from 'react-router-dom'
import { useDemoContext } from 'registration/pages/Demo/DemoContext'

type IAccountDetailsProps = React.ComponentPropsWithoutRef<'div'>

const AccountDetails: React.FC<IAccountDetailsProps> = ({ ...props }) => {
    const { demo } = useDemoContext()
    const containerClass: string = classNameMaker({
        [Styles.container]: true,
    })
    const {
        state: { user },
        signOutUser,
    } = useAuth()
    const navigate = useNavigate()

    return (
        <div className={containerClass} {...props}>
            <div>
                <Text color="mid" size="large" className={Styles.email}>
                    {demo ? 'example@email.com' : user?.email}
                </Text>

                <div style={{ fontSize: '16px', textDecoration: 'underline', marginTop: 'var(--s-1)' }}>
                    <PsyomicsLink>
                        <Link to="/change-password">Change Password</Link>
                    </PsyomicsLink>
                </div>
            </div>
            <Button
                onClick={async () => {
                    await signOutUser()
                    navigate('/')
                }}
                size="large"
                label="Sign out"
                appearance="link"
            />
        </div>
    )
}

export default AccountDetails
