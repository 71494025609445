import React from 'react'
import { classNameMaker } from '../../utils'
import { Logo } from '@psyomics/components'
import Styles from './Header.module.scss'

interface OurProps {
    readonly template?: 'landing' | 'form'
}

type IHeaderProps = OurProps & React.ComponentPropsWithoutRef<'div'>

/**
 * Header is the page header used by most pages to display the Psyomics logo
 */
const Header: React.FC<IHeaderProps> = ({ template = 'form', ...props }) => {
    const containerClass: string = classNameMaker({
        [Styles.container]: true,
        [Styles[`template-${template}`]]: template,
    })
    return (
        <div className={containerClass} {...props}>
            <Logo width="200" />
        </div>
    )
}

export default Header
