"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Which best describes your current living situation? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-1",
                    "optionSummaries": {
                        "adb85dd3-8bce-5816-8919-5172e3ebe6ac": "Living alone",
                        "9677e03c-ab1a-5b48-aecd-af7f763c2f48": "Living in shared accommodation with previously unknown individuals",
                        "613e5bd0-5dd9-509e-a24c-f517827bb490": "Living with friends or family",
                        "49638d05-afdd-514b-b719-6ad4797225cd": "None of the above"
                    }
                }
            ]
        },
        "section": [
            "Patient Current Status",
            "Family Status"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "On average, how often do you spend time with someone you feel close to, such as a close family member or a friend? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-2",
                    "optionSummaries": {
                        "f9ec0083-4f2f-583b-9e14-22833a7f6423": "Almost never",
                        "740bdccd-484c-5eb7-ac04-be5f35766291": "Quite rarely, around once every 2 weeks",
                        "913ecb47-51c8-5bd2-beff-5e2e243bd524": "Around once per week",
                        "f208a2bf-1e58-5d84-a53b-e744c38bbbb0": "2-4 times per week",
                        "8ec24a1a-96a7-5a7d-9061-13d13a160dbd": "Most days"
                    }
                }
            ]
        },
        "section": [
            "Patient Current Status",
            "Family Status"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Do you have someone you can talk to about your mental health concerns? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-3",
                    "optionSummaries": {
                        "65c9112f-6125-5a66-b2fd-2254c8077fab": "No, I don’t have anyone to talk to",
                        "703dd478-5941-53b7-a283-fec67b82fff6": "Yes, but they are currently uncontactable",
                        "5cce3df7-1e4c-55ef-a13a-0cb109a3a6d1": "Yes, I have someone to talk to",
                        "d53c3cd3-095c-5a18-bafb-89eada2f9a77": "Yes, I have someone that I have talked to"
                    }
                }
            ]
        },
        "section": [
            "Patient Current Status",
            "Family Status"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Are you currently in a supportive and relatively stable relationship? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-4",
                    "optionSummaries": {
                        "188c376f-9b12-5b24-b035-24ea5b5bfb54": "I am currently in a supportive and relatively stable relationship",
                        "441dc600-7ec7-5734-b8ce-1379c53b0dd3": "I am in a relationship but it doesn't feel supportive and stable at the moment",
                        "7ebcb370-091b-5558-9a2b-6b0f0cf7f14b": "I am single",
                        "d91c5927-02a3-5234-89d5-1a52fd8512fd": "Prefer not to say"
                    }
                }
            ]
        },
        "section": [
            "Patient Current Status",
            "Family Status"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Do you have any children under the age of 18? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-13",
                    "optionSummaries": {
                        "d8c660d5-fb13-529e-b799-5d797e68e000": "No children under 18",
                        "25230108-cdd8-55cf-9866-daf316466c27": "I have children under 18"
                    }
                }
            ]
        },
        "section": [
            "Patient Current Status",
            "Family Status"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "PCV2-14",
            "title": "Are you an unpaid carer for any of the following people? |",
            "overrides": {
                "b97973c6-df76-5c48-84db-e351c57a4f43": "Cares for spouse/partner",
                "1e3c88aa-bfd2-59e5-852a-1e12fea3d9f9": "Cares for child over 18",
                "6cccfb8e-3986-5b84-84b2-f4320a764677": "Cares for sibling(s)",
                "51c43a8e-62a4-52e4-bca7-75ae6c1a36e4": "Cares for parent/grandparent",
                "0693f6e7-3aa4-57bd-b86d-d9913ec377d9": "Other",
                "63653abe-a120-5161-a6f0-994529ae7540": "None"
            }
        },
        "section": [
            "Patient Current Status",
            "Family Status"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "What is your current employment situation? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-6",
                    "optionSummaries": {
                        "00f8401f-796d-5669-9bbd-cb3f489c028f": "Employed",
                        "ba95321c-b9fd-5fc2-a311-e798bc9bae81": "Self-employed",
                        "006dd4de-b660-5a59-b3b5-b0f42a797ce7": "Parental leave or taking time off work to care for a family member",
                        "57d06c34-8ada-50f5-9bb0-6074c3a295eb": "Student",
                        "18552af0-7fca-5d1c-8911-a9d36026a47f": "Retired",
                        "95b6e5e9-0e09-57e3-8445-8b3f821803dd": "Unemployed"
                    }
                }
            ]
        },
        "section": [
            "Patient Current Status",
            "Work and Financial Status"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Do you feel that your current financial situation is contributing to any of your mental health concerns? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-9",
                    "optionSummaries": {
                        "5dcab7c1-c884-512b-a893-94f4b41a0373": "No",
                        "e1c503e9-8baf-5773-bea6-0e4c0e9d5d7f": "Yes",
                        "a0738b11-edf6-5742-9020-8e165432cca8": "I am not sure",
                        "bfa6e007-b64b-50c8-849c-be18d4ce658f": "Prefer not to say"
                    }
                }
            ]
        },
        "section": [
            "Patient Current Status",
            "Work and Financial Status"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Which best describes your current living situation? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-10",
                    "optionSummaries": {
                        "13bb3203-2370-54ec-8015-00feab942b55": "I have a permanent place of residence (i.e. I own my own home, I rent a house or a room in a house, I live in my family home)",
                        "46d2d3fc-09de-534f-b15a-6f3e377813c0": "I have no permanent place of residence"
                    }
                }
            ]
        },
        "section": [
            "Patient Current Status",
            "Housing"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "How would you rate your overall physical health? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-17",
                    "optionSummaries": {
                        "da1140f3-7d70-58dc-ad28-28a600c62677": "My physical health is extremely poor",
                        "c5c5016f-7873-530b-bf5f-55af8da1a75f": "My physical health is very poor",
                        "e7e89d3f-3cdf-548a-906c-91abc57b1c55": "My physical health is quite poor",
                        "af273dec-7733-58fc-b069-f1aa4fcebae1": "My physical health is OK",
                        "a6814f67-54b8-56cc-92cb-b77252c3be2a": "My physical health is quite good",
                        "dfcbdad2-6a53-5eef-b057-9e70096c8944": "My physical health is very good"
                    }
                }
            ]
        },
        "section": [
            "Past Physical and Mental Health Conditions",
            "Physical Health"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "If you had to rate your mental health concerns, how would you rate your overall mental health? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-21",
                    "optionSummaries": {
                        "895135d1-2301-5161-b9be-56accb1d5fcf": "My mental health is extremely poor",
                        "49543cb6-9ae4-56fb-aaa4-d875346a0919": "My mental health is very poor",
                        "8adfde93-2f51-590c-8a8b-a326049d74c7": "My mental health is quite poor",
                        "4805b7f8-78e9-56b2-8b92-8b6580fb785a": "My mental health is OK",
                        "039fd69a-3b3a-5ca7-86ae-dc819903fe68": "My mental health is quite good",
                        "b4a39ab9-1595-546f-8d02-3a27ecffbab8": "My mental health is very good"
                    }
                }
            ]
        },
        "section": [
            "Past Physical and Mental Health Conditions",
            "Mental Health"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Have you ever been diagnosed with any mental health conditions by a healthcare professional? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-22",
                    "optionSummaries": {
                        "1c67990e-095f-51cc-9448-2d1da3a30405": "No",
                        "e1ded407-79fd-5f37-8c5b-19e738980ad2": "Yes"
                    }
                }
            ]
        },
        "section": [
            "Past Physical and Mental Health Conditions",
            "Mental Health"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "PCV2-15"
            ],
            "prefix": "",
            "formatText": "How much do you weigh, in KG? | {}"
        },
        "section": [
            "Past Physical and Mental Health Conditions (Breakdown)",
            "Physical Health"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "PCV2-16"
            ],
            "prefix": "",
            "formatText": "How tall are you, in CM? | {}"
        },
        "section": [
            "Past Physical and Mental Health Conditions (Breakdown)",
            "Physical Health"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "How would you rate your overall physical health? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-17",
                    "optionSummaries": {
                        "da1140f3-7d70-58dc-ad28-28a600c62677": "My physical health is extremely poor",
                        "c5c5016f-7873-530b-bf5f-55af8da1a75f": "My physical health is very poor",
                        "e7e89d3f-3cdf-548a-906c-91abc57b1c55": "My physical health is quite poor",
                        "af273dec-7733-58fc-b069-f1aa4fcebae1": "My physical health is OK",
                        "a6814f67-54b8-56cc-92cb-b77252c3be2a": "My physical health is quite good",
                        "dfcbdad2-6a53-5eef-b057-9e70096c8944": "My physical health is very good"
                    }
                }
            ]
        },
        "section": [
            "Past Physical and Mental Health Conditions (Breakdown)",
            "Physical Health"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Have you ever been diagnosed with any physical health conditions by a GP or healthcare professional which impact your wellbeing or mental health? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-18",
                    "optionSummaries": {
                        "1a5ddd02-d650-5614-8128-b22afbfbdbd9": "I have not been",
                        "6b496a0d-54e9-544e-9292-c58c9eaee687": "I have been"
                    }
                }
            ]
        },
        "section": [
            "Past Physical and Mental Health Conditions (Breakdown)",
            "Physical Health"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "PCV2-19"
            ],
            "prefix": "",
            "formatText": "Please can you give us some brief details about these physical health conditions, and how they impact your wellbeing or mental health? | {}"
        },
        "section": [
            "Past Physical and Mental Health Conditions (Breakdown)",
            "Physical Health"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "If you had to rate your mental health concerns, how would you rate your overall mental health? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-21",
                    "optionSummaries": {
                        "895135d1-2301-5161-b9be-56accb1d5fcf": "My mental health is extremely poor",
                        "49543cb6-9ae4-56fb-aaa4-d875346a0919": "My mental health is very poor",
                        "8adfde93-2f51-590c-8a8b-a326049d74c7": "My mental health is quite poor",
                        "4805b7f8-78e9-56b2-8b92-8b6580fb785a": "My mental health is OK",
                        "039fd69a-3b3a-5ca7-86ae-dc819903fe68": "My mental health is quite good",
                        "b4a39ab9-1595-546f-8d02-3a27ecffbab8": "My mental health is very good"
                    }
                }
            ]
        },
        "section": [
            "Past Physical and Mental Health Conditions (Breakdown)",
            "Mental Health"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Have you ever been diagnosed with any mental health conditions by a healthcare professional? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-22",
                    "optionSummaries": {
                        "1c67990e-095f-51cc-9448-2d1da3a30405": "No",
                        "e1ded407-79fd-5f37-8c5b-19e738980ad2": "Yes"
                    }
                }
            ]
        },
        "section": [
            "Past Physical and Mental Health Conditions (Breakdown)",
            "Mental Health"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "PCV2-23"
            ],
            "prefix": "",
            "formatText": "Please can you provide us a list of any mental health conditions you have been diagnosed with? | {}"
        },
        "section": [
            "Past Physical and Mental Health Conditions (Breakdown)",
            "Mental Health"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "PCV2-24"
            ],
            "prefix": "",
            "formatText": "Is there anything else about your current situation which you think is contributing to your mental health concerns? | {}"
        },
        "section": [
            "Past Physical and Mental Health Conditions (Breakdown)",
            "Mental Health"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "PCV2-25"
            ],
            "prefix": "",
            "formatText": "Please list any medications you are currently taking, including the dose if you know this. | {}"
        },
        "section": [
            "Past Physical and Mental Health Conditions (Breakdown)",
            "Mental Health"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Which best describes your current living situation? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-1",
                    "optionSummaries": {
                        "adb85dd3-8bce-5816-8919-5172e3ebe6ac": "Living alone",
                        "9677e03c-ab1a-5b48-aecd-af7f763c2f48": "Living in shared accommodation with previously unknown individuals",
                        "613e5bd0-5dd9-509e-a24c-f517827bb490": "Living with friends or family",
                        "49638d05-afdd-514b-b719-6ad4797225cd": "None of the above"
                    }
                }
            ]
        },
        "section": [
            "Patient Current Status (Breakdown)",
            "Family Status"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "On average, how often do you spend time with someone you feel close to, such as a close family member or a friend? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-2",
                    "optionSummaries": {
                        "f9ec0083-4f2f-583b-9e14-22833a7f6423": "Almost never",
                        "740bdccd-484c-5eb7-ac04-be5f35766291": "Quite rarely, around once every 2 weeks",
                        "913ecb47-51c8-5bd2-beff-5e2e243bd524": "Around once per week",
                        "f208a2bf-1e58-5d84-a53b-e744c38bbbb0": "2-4 times per week",
                        "8ec24a1a-96a7-5a7d-9061-13d13a160dbd": "Most days"
                    }
                }
            ]
        },
        "section": [
            "Patient Current Status (Breakdown)",
            "Family Status"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Do you have someone you can talk to about your mental health concerns? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-3",
                    "optionSummaries": {
                        "65c9112f-6125-5a66-b2fd-2254c8077fab": "No, I don’t have anyone to talk to",
                        "703dd478-5941-53b7-a283-fec67b82fff6": "Yes, but they are currently uncontactable",
                        "5cce3df7-1e4c-55ef-a13a-0cb109a3a6d1": "Yes, I have someone to talk to",
                        "d53c3cd3-095c-5a18-bafb-89eada2f9a77": "Yes, I have someone that I have talked to"
                    }
                }
            ]
        },
        "section": [
            "Patient Current Status (Breakdown)",
            "Family Status"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Are you currently in a supportive and relatively stable relationship? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-4",
                    "optionSummaries": {
                        "188c376f-9b12-5b24-b035-24ea5b5bfb54": "I am currently in a supportive and relatively stable relationship",
                        "441dc600-7ec7-5734-b8ce-1379c53b0dd3": "I am in a relationship but it doesn't feel supportive and stable at the moment",
                        "7ebcb370-091b-5558-9a2b-6b0f0cf7f14b": "I am single",
                        "d91c5927-02a3-5234-89d5-1a52fd8512fd": "Prefer not to say"
                    }
                }
            ]
        },
        "section": [
            "Patient Current Status (Breakdown)",
            "Family Status"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Do you feel that your current relationship situation is contributing to any of your mental health concerns? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-5",
                    "optionSummaries": {
                        "a9821ab5-609b-5458-8380-8a0b0f4a3db3": "No",
                        "d87608b6-55b4-5666-a57b-f16e153d089a": "Yes",
                        "7f1a57ff-f4f5-5181-af2e-b1ec1e232884": "I am not sure",
                        "fcc592f7-fc18-57d2-bd66-cb85eebad936": "Prefer not to say"
                    }
                }
            ]
        },
        "section": [
            "Patient Current Status (Breakdown)",
            "Family Status"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Do you have any children under the age of 18? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-13",
                    "optionSummaries": {
                        "d8c660d5-fb13-529e-b799-5d797e68e000": "No children under 18",
                        "25230108-cdd8-55cf-9866-daf316466c27": "I have children under 18"
                    }
                }
            ]
        },
        "section": [
            "Patient Current Status (Breakdown)",
            "Family Status"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Have you had a baby in the last 12 months? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-12",
                    "optionSummaries": {
                        "8edeb769-4512-5d5a-bbe8-2b45c0835299": "No",
                        "5bf1aeaf-025c-5772-b9aa-1956549c4427": "Yes"
                    }
                }
            ]
        },
        "section": [
            "Patient Current Status (Breakdown)",
            "Family Status"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Are you, or your partner, currently pregnant? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-20",
                    "optionSummaries": {
                        "258cdc86-94d2-510c-8918-31b7fd1df3bd": "No",
                        "8e17946a-2e79-5e6c-b835-4aaf2f1ac6f8": "Yes"
                    }
                }
            ]
        },
        "section": [
            "Patient Current Status (Breakdown)",
            "Family Status"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "PCV2-14",
            "title": "Are you an unpaid carer for any of the following people? |",
            "overrides": {
                "b97973c6-df76-5c48-84db-e351c57a4f43": "Cares for spouse/partner",
                "1e3c88aa-bfd2-59e5-852a-1e12fea3d9f9": "Cares for child over 18",
                "6cccfb8e-3986-5b84-84b2-f4320a764677": "Cares for sibling(s)",
                "51c43a8e-62a4-52e4-bca7-75ae6c1a36e4": "Cares for parent/grandparent",
                "0693f6e7-3aa4-57bd-b86d-d9913ec377d9": "Other",
                "63653abe-a120-5161-a6f0-994529ae7540": "None"
            }
        },
        "section": [
            "Patient Current Status (Breakdown)",
            "Family Status"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "What is your current employment situation? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-6",
                    "optionSummaries": {
                        "00f8401f-796d-5669-9bbd-cb3f489c028f": "Employed",
                        "ba95321c-b9fd-5fc2-a311-e798bc9bae81": "Self-employed",
                        "006dd4de-b660-5a59-b3b5-b0f42a797ce7": "Parental leave or taking time off work to care for a family member",
                        "57d06c34-8ada-50f5-9bb0-6074c3a295eb": "Student",
                        "18552af0-7fca-5d1c-8911-a9d36026a47f": "Retired",
                        "95b6e5e9-0e09-57e3-8445-8b3f821803dd": "Unemployed"
                    }
                }
            ]
        },
        "section": [
            "Patient Current Status (Breakdown)",
            "Work and Financial Status"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Do you feel that your current employment situation is contributing to any of your mental health concerns? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-7",
                    "optionSummaries": {
                        "457561c0-98b6-5242-8e12-ab51cf4cc9b0": "No",
                        "9ea2346f-530d-5da7-80f6-f7e1fde8942e": "Yes",
                        "f6352f59-313e-5493-8aa8-cf613427d226": "I am not sure"
                    }
                }
            ]
        },
        "section": [
            "Patient Current Status (Breakdown)",
            "Work and Financial Status"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Which of these statements would you say best represents your current situation in regards to money and paying your bills (e.g. rent or mortgage, utility bills, buying food)? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-8",
                    "optionSummaries": {
                        "1ef59007-c98c-52e2-9e8e-545e77e49a47": "No concerns",
                        "ba0ebebe-cb38-5941-9aaf-c4f62f4cc7d7": "Some concerns about money",
                        "0ada9880-67ab-557f-a9ec-64a2179a4149": "Struggling to pay some bills",
                        "69ae9d2e-f5d7-5ba1-9649-3fc640882fba": "Unable to pay most bills",
                        "eb0df2de-d214-5ff7-9e94-34b27ad8b5f9": "Unable to pay bills and in substantial debt",
                        "93654a5f-8ef9-54b4-9b94-d9b6dea5914e": "Prefer not to say"
                    }
                }
            ]
        },
        "section": [
            "Patient Current Status (Breakdown)",
            "Work and Financial Status"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Do you feel that your current financial situation is contributing to any of your mental health concerns? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-9",
                    "optionSummaries": {
                        "5dcab7c1-c884-512b-a893-94f4b41a0373": "No",
                        "e1c503e9-8baf-5773-bea6-0e4c0e9d5d7f": "Yes",
                        "a0738b11-edf6-5742-9020-8e165432cca8": "I am not sure",
                        "bfa6e007-b64b-50c8-849c-be18d4ce658f": "Prefer not to say"
                    }
                }
            ]
        },
        "section": [
            "Patient Current Status (Breakdown)",
            "Work and Financial Status"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Which best described your current living situation? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-10",
                    "optionSummaries": {
                        "13bb3203-2370-54ec-8015-00feab942b55": "I have a permanent place of residence (i.e. I own my own home, I rent a house or a room in a house, I live in my family home)",
                        "46d2d3fc-09de-534f-b15a-6f3e377813c0": "I have no permanent place of residence"
                    }
                }
            ]
        },
        "section": [
            "Patient Current Status (Breakdown)",
            "Housing"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Do you worry that you will suddenly become homeless? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCV2-11",
                    "optionSummaries": {
                        "dda0d067-918b-5f1e-8743-b9a66d869afa": "No",
                        "a40cc7a8-b47b-501e-a5a8-df8b4319c491": "Yes"
                    }
                }
            ]
        },
        "section": [
            "Patient Current Status (Breakdown)",
            "Housing"
        ]
    }
];
