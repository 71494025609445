"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "CombinedAnswersSummary",
            "questionsIdGroups": [
                [
                    "ISI-1a"
                ],
                [
                    "ISI-1b"
                ],
                [
                    "ISI-1c"
                ],
                [
                    "ISI-2"
                ],
                [
                    "ISI-3"
                ],
                [
                    "ISI-4"
                ],
                [
                    "ISI-5"
                ]
            ],
            "groupsType": "Mean",
            "pattern": "{}",
            "coefficients": {
                "0": "Not clinically significant (0-7)",
                "0.2857142857142857": "Subthreshold (8-14)",
                "0.5357142857142857": "Moderate (15-21)",
                "0.7857142857142857": "Severe (>= 22)"
            }
        },
        "section": [
            "Outcome"
        ]
    },
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "{}",
            "functionId": "IsiScoringKey"
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "1. Please rate the current (i.e., the past 2 weeks) severity of your insomnia problem(s) - Difficulty falling asleep | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ISI-1a",
                    "optionSummaries": {
                        "87faf8ed-d6ad-479c-a442-3d8e936b350f": "0 - None (on a scale where 0 = 'None' and 4 = 'Very')",
                        "8e9e12f5-2bda-4112-97a0-e337871446a5": "1 - Mild (on a scale where 0 = 'None' and 4 = 'Very')",
                        "6d94b585-d8cb-4b3a-bccf-325a571656ab": "2 - Moderate (on a scale where 0 = 'None' and 4 = 'Very')",
                        "dc59d1a1-ad7d-462c-918e-64238c6a47bb": "3 - Severe (on a scale where 0 = 'None' and 4 = 'Very')",
                        "1b962c40-80e7-4101-8aeb-521beefe2819": "4 - Very (on a scale where 0 = 'None' and 4 = 'Very')"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "2. Please rate the current (i.e., the past 2 weeks) severity of your insomnia problem(s) - Difficulty staying asleep | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ISI-1b",
                    "optionSummaries": {
                        "9b976912-dc17-43cf-950f-18df7204614b": "0 - None (on a scale where 0 = 'None' and 4 = 'Very')",
                        "a0b6d1ad-3aa7-4315-a82a-7feff6cd4c08": "1 - Mild (on a scale where 0 = 'None' and 4 = 'Very')",
                        "472b8618-9643-4462-8764-f64687a08a1e": "2 - Moderate (on a scale where 0 = 'None' and 4 = 'Very')",
                        "44cf52c3-122b-422c-b9fd-2e55587883da": "3 - Severe (on a scale where 0 = 'None' and 4 = 'Very')",
                        "77f13923-7057-4e29-9f61-4bc3d386fbc3": "4 - Very (on a scale where 0 = 'None' and 4 = 'Very')"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "3. Please rate the current (i.e., the past 2 weeks) SEVERITY of your insomnia problem(s) - Problem waking up too early | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ISI-1c",
                    "optionSummaries": {
                        "8a2ab7b5-c65d-4291-aebf-387e85ad7277": "0 - None (on a scale where 0 = 'None' and 4 = 'Very')",
                        "84f37627-e14d-4bc3-b5df-c0a2deda2e5b": "1 - Mild (on a scale where 0 = 'None' and 4 = 'Very')",
                        "246a7bcd-af06-458e-a4ed-ecc3ffb2a0d4": "2 - Moderate (on a scale where 0 = 'None' and 4 = 'Very')",
                        "51f64d13-c787-4dac-8204-55d05977883a": "3 - Severe (on a scale where 0 = 'None' and 4 = 'Very')",
                        "1091521b-2de4-4f21-a8fc-cca207333cf1": "4 - Very (on a scale where 0 = 'None' and 4 = 'Very')"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "4. How satisfied/dissatisfied are you with your current sleep pattern? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ISI-2",
                    "optionSummaries": {
                        "1ae15493-f6aa-4342-9306-37b14cd05476": "0 - Very satisfied (on a scale where 0 = 'very satisfied' and 4 = 'very dissatisfied')",
                        "19c266a8-6731-4a4e-b70a-07f6d5588559": "1 (on a scale where 0 = 'very satisfied' and 4 = 'very dissatisfied')",
                        "d2c02347-715c-41ee-8990-c9df657887f5": "2 (on a scale where 0 = 'very satisfied' and 4 = 'very dissatisfied')",
                        "bcd1a823-a915-45c1-86f0-e55b885c1ff8": "3 (on a scale where 0 = 'very satisfied' and 4 = 'very dissatisfied')",
                        "1234a38a-a6c3-4796-beb4-f5478156b9dc": "4 - Very dissatisfied (on a scale where 0 = 'very satisfied' and 4 = 'very dissatisfied')"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "5. To what extent do you consider your sleep problem to interfere with your daily functioning (e.g., daytime fatigue, mood, ability to function at work/daily chores, concentration, memory, mood, etc.). | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ISI-3",
                    "optionSummaries": {
                        "e1fdc76f-eab4-463f-a631-1bd1666071cd": "0 - Not at all interfering (on a scale where 0 = 'Not at all' and 4 = 'Very much')",
                        "3aa326a5-d399-4d76-9566-ad0823c79892": "1 - A little (on a scale where 0 = 'Not at all' and 4 = 'Very much')",
                        "d976d301-3f95-422a-909b-dc066f898dea": "2 - Somewhat (on a scale where 0 = 'Not at all' and 4 = 'Very much')",
                        "046c617b-6a41-45b8-a1de-ffd0eafd4dfb": "3 - Much (on a scale where 0 = 'Not at all' and 4 = 'Very much')",
                        "e3bab970-55d8-479d-9cf3-c9f9f1ea7601": "4 - Very much interfering (on a scale where 0 = 'Not at all' and 4 = 'Very much')"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "6. How noticeable to others do you think your sleeping problem is in terms of impairing the quality of your life? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ISI-4",
                    "optionSummaries": {
                        "c0ebe147-b2a8-4c2e-b312-aedcb4169b61": "0 - Not at all noticeable (on a scale where 0 = 'Not at all' and 4 = 'Very much')",
                        "e76d475a-a058-4330-a6da-4228013e3457": "1 - Barely (on a scale where 0 = 'Not at all' and 4 = 'Very much')",
                        "05e64720-dd56-44af-9368-088fe752d09f": "2 - Somewhat (on a scale where 0 = 'Not at all' and 4 = 'Very much')",
                        "73e498b5-bc5f-443c-8967-a52c289ec78e": "3 - Much (on a scale where 0 = 'Not at all' and 4 = 'Very much')",
                        "d72d5737-610f-4ad1-b7ab-8b665021d634": "4 - Very much noticeable (on a scale where 0 = 'Not at all' and 4 = 'Very much')"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "7. How worried/distressed are you about your current sleep problem? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ISI-5",
                    "optionSummaries": {
                        "cbbe185f-4ad8-4c02-b61e-b443fd379c43": "0 - Not at all (on a scale where 0 = 'Not at all' and 4 = 'Very much')",
                        "0dab9118-d57f-4a5b-8568-c9b1e23cb337": "1 - A little (on a scale where 0 = 'Not at all' and 4 = 'Very much')",
                        "2088d622-e89d-42f2-8657-4e13bf3b670d": "2 - Somewhat (on a scale where 0 = 'Not at all' and 4 = 'Very much')",
                        "f43a33dd-d88a-4246-9d21-a88ebac53856": "3 - Much (on a scale where 0 = 'Not at all' and 4 = 'Very much')",
                        "eebc29dc-9cce-4df8-a7d6-411decb5af91": "4 - Very much (on a scale where 0 = 'Not at all' and 4 = 'Very much')"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    }
];
