"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "{}",
            "functionId": "AdnmTotal"
        },
        "section": [
            "Overall score"
        ]
    },
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "{}",
            "functionId": "AdnmAjdRisk"
        },
        "section": [
            "Overall score"
        ]
    },
    {
        "answerSummary": {
            "type": "MultiOptionSummary",
            "questionId": "ADNM_content-1",
            "title": "",
            "overrides": {
                "9114620e-2e84-4a63-b26c-fd4c7bfcf558": "Other stressful event (see below for details)"
            }
        },
        "section": [
            "Patient-reported issues",
            "Stressful events in the past 6 months"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "ADNM_content-2"
            ],
            "prefix": "",
            "formatText": "{}"
        },
        "section": [
            "Patient-reported issues",
            "Most straining events reported by user: "
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Severity | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-2",
                    "optionSummaries": {
                        "65812d0f-8606-4e83-812a-eb998fa50db6": "1 - Never",
                        "a38baec1-47b8-43bf-8b8e-1a25d5c375fa": "2 - Rarely",
                        "a9d141a3-dbaa-4459-92f6-ada641585074": "3 - Sometimes",
                        "14344826-49f0-4663-910d-20452715c08d": "4 - Often"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "Since the stressful problem, I feel low and sad"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Duration of symptoms | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-3",
                    "optionSummaries": {
                        "6f24755c-5b3e-4e9b-b0dd-0b5f9cdefc7a": "< 1 month",
                        "310dc8db-e101-42a1-ad75-73bfea45c58a": "1 – 6 months",
                        "2330b87e-e720-421d-b85e-ca721f0b330f": "6 months – 2 years"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "Since the stressful problem, I feel low and sad"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Severity | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-4",
                    "optionSummaries": {
                        "d115043a-d1f2-489e-8e9d-d3fb479dd8ef": "1 - Never",
                        "719c167a-6f30-410c-85fb-6b8321ebb3d2": "2 - Rarely",
                        "3f7054d3-f91c-4e03-80c9-a29f184db7d1": "3 - Sometimes",
                        "df325eb8-b638-459c-be74-c60b2132ff9d": "4 - Often"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "I have to think about the stressful situation repeatedly"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Duration of symptoms | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-5",
                    "optionSummaries": {
                        "1fcbfe28-da57-492c-938e-b22050a74b76": "< 1 month",
                        "964f9328-f533-457a-8a39-eeb8936af22c": "1 – 6 months",
                        "4fa4a219-59fb-4dc6-a304-dc48abd8c06c": "6 months – 2 years"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "I have to think about the stressful situation repeatedly"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Severity | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-6",
                    "optionSummaries": {
                        "7f6301b9-bb78-440f-8a3a-3c6684691087": "1 - Never",
                        "471b2924-f9c9-4b4f-99c8-8bb02d0f090e": "2 - Rarely",
                        "75f6dc5e-6a7f-4d78-94a7-eb917f79a155": "3 - Sometimes",
                        "4ba69d72-b6a0-4697-b95a-afb06712f2a5": "4 - Often"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "I try to avoid talking about the stressful situation wherever possible"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Duration of symptoms | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-7",
                    "optionSummaries": {
                        "74ed5c09-35aa-4874-b916-90f8ac0328af": "< 1 month",
                        "2d02dd1b-de6d-4020-9fd9-8610e7eb7570": "1 – 6 months",
                        "9cb99fc8-7e34-4bac-8632-cea53481698c": "6 months – 2 years"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "I try to avoid talking about the stressful situation wherever possible"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Severity | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-8",
                    "optionSummaries": {
                        "a0828d97-aa33-4645-87b2-27183cbac416": "1 - Never",
                        "79ff2b78-66e7-40d4-ab14-97e948f603d4": "2 - Rarely",
                        "da3921e3-98ef-4014-94a1-66c8dda27dba": "3 - Sometimes",
                        "2ded982d-724e-4ee1-841e-389de084d94d": "4 - Often"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "I keep having to think about the stressful situation and this is a great burden to me"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Duration of symptoms | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-9",
                    "optionSummaries": {
                        "644d117c-b477-490b-b178-911ed2d1d31b": "< 1 month",
                        "842be0d9-fbd1-4f4f-a5dc-83211c3eb0c1": "1 – 6 months",
                        "640c0285-4c97-4fc7-86ab-af5a57fbe19f": "6 months – 2 years"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "I keep having to think about the stressful situation and this is a great burden to me"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Severity | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-10",
                    "optionSummaries": {
                        "5b9c4ecb-f4e9-4c92-815a-1659c8e77cb8": "1 - Never",
                        "067f2c36-c27b-4cf3-bdcb-e4480802cb54": "2 - Rarely",
                        "506059a4-3ffb-446d-bea2-dc49a9b95c19": "3 - Sometimes",
                        "f3a4d0c1-5f9e-49df-bfa2-371900ee80f6": "4 - Often"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "Nowadays, I do those activities which I used to enjoy much more rarely"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Duration of symptoms | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-11",
                    "optionSummaries": {
                        "8728017a-1072-4afa-8eb4-02681b89548d": "< 1 month",
                        "9dbda775-9e74-4cdc-bc0c-5df4e8157f2b": "1 – 6 months",
                        "fb1b8ef0-3e57-4da8-a56f-12e2cb6edc18": "6 months – 2 years"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "Nowadays, I do those activities which I used to enjoy much more rarely"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Severity | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-12",
                    "optionSummaries": {
                        "4a66d6b0-6eb4-49ed-b4af-b04470b68de4": "1 - Never",
                        "0a1f0e29-77d5-44a7-bff1-f504aa9341df": "2 - Rarely",
                        "42eeb934-c4bb-4896-852f-c1457f8b25b1": "3 - Sometimes",
                        "bbfdf139-9b7e-482f-ae39-cfaadf082929": "4 - Often"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "If I think about the stressful situation, I find myself in a real state of anxiety"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Duration of symptoms | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-13",
                    "optionSummaries": {
                        "ff16629e-034e-4fdf-8e9a-b8e0fa4c0a0d": "< 1 month",
                        "be2291af-44f3-4293-8a0d-afd4974af56b": "1 – 6 months",
                        "dfbfd4b4-2121-46a4-ae37-96bb6d52dc37": "6 months – 2 years"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "If I think about the stressful situation, I find myself in a real state of anxiety"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Severity | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-14",
                    "optionSummaries": {
                        "6cc0c5f9-6c3c-4e0e-842f-724166ed09fe": "1 - Never",
                        "e12bbd80-be09-47af-a368-085138d25f4c": "2 - Rarely",
                        "9b07cf53-7194-4f3b-a162-f431f4d7b8b3": "3 - Sometimes",
                        "281f82fc-3aa6-4f8c-9a2a-bf55d08c57bf": "4 - Often"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "I avoid certain things that might remind me of the stressful situation"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Duration of symptoms | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-15",
                    "optionSummaries": {
                        "9fb7a452-a621-442c-8050-78499e662431": "< 1 month",
                        "39b0518a-1025-4c57-98f3-1fa55e209e91": "1 – 6 months",
                        "e2323fd7-bd9c-486e-b9d3-5b587764286e": "6 months – 2 years"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "I avoid certain things that might remind me of the stressful situation"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Severity | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-16",
                    "optionSummaries": {
                        "902c2cce-24bb-47d6-9831-9589c6f60517": "1 - Never",
                        "7571932c-9179-4803-9511-bd4e37116e7e": "2 - Rarely",
                        "f78b0795-ab94-492f-957f-78f856457f48": "3 - Sometimes",
                        "cecc4ef6-84a9-4120-a841-eaa70577e997": "4 - Often"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "I am nervous and restless since the stressful situation"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Duration of symptoms | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-17",
                    "optionSummaries": {
                        "5bd454fa-078b-4b85-91b9-7c63b75888ad": "< 1 month",
                        "896c6650-cdbd-42c5-8c95-f5d8bbbccd07": "1 – 6 months",
                        "cce62cd8-ceca-4edc-b08d-034a375f9209": "6 months – 2 years"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "I am nervous and restless since the stressful situation"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Severity | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-18",
                    "optionSummaries": {
                        "62299499-c5f0-42d0-a26b-a315856457f5": "1 - Never",
                        "ca6bef28-4f62-4ed2-a6d8-f6218db9664c": "2 - Rarely",
                        "5f7c9848-c86a-49eb-87d6-6681989acfd2": "3 - Sometimes",
                        "8c1bbc7c-331f-4e37-b240-6d241ab937fe": "4 - Often"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "Since the stressful situation, I am much quicker to lose my temper, even over small things"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Duration of symptoms | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-19",
                    "optionSummaries": {
                        "be94f1cd-9b7b-4b49-a9fa-dd15fc979db9": "< 1 month",
                        "e95d7c35-02a8-45da-84ee-6b595ebc3044": "1 – 6 months",
                        "63bf6839-66a1-45e4-906b-9d91c12cc0af": "6 months – 2 years"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "Since the stressful situation, I am much quicker to lose my temper, even over small things"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Severity | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-20",
                    "optionSummaries": {
                        "b91099e3-7357-4b9b-ae01-9c94928ba405": "1 - Never",
                        "74a54303-715a-44c1-95c0-a9452b287e55": "2 - Rarely",
                        "8d125fdb-5f40-41a4-bc78-fc2da1282df6": "3 - Sometimes",
                        "64dd1133-b288-496e-9823-4953cf7bd5ec": "4 - Often"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "Since the stressful situation, I can only concentrate on certain things with difficulty"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Duration of symptoms | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-21",
                    "optionSummaries": {
                        "01e44c28-3ca2-4229-8401-e0579dae86b6": "< 1 month",
                        "cf01c1b0-481f-4c54-a6b8-84f87af4e424": "1 – 6 months",
                        "42017934-239d-4014-b2ab-8a2cd0346b5a": "6 months – 2 years"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "Since the stressful situation, I can only concentrate on certain things with difficulty"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Severity | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-22",
                    "optionSummaries": {
                        "2840f996-3bb8-4484-aff9-507a721d7575": "1 - Never",
                        "a1cf6d69-8298-416d-8612-6488c8bdac5f": "2 - Rarely",
                        "ef1c4262-fea1-45c0-a841-b1e1d5675d1c": "3 - Sometimes",
                        "b12643b0-9022-4ec5-a722-2b972597a3aa": "4 - Often"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "I try to abolish the stressful situation from my memory"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Duration of symptoms | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-23",
                    "optionSummaries": {
                        "483181f8-8e84-4324-ab7f-ec00ca925d16": "< 1 month",
                        "4a40de65-e88e-48ba-a177-7436d558880f": "1 – 6 months",
                        "d769ebe7-ea5f-4a33-b183-1d2743d34c58": "6 months – 2 years"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "I try to abolish the stressful situation from my memory"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Severity | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-24",
                    "optionSummaries": {
                        "24aff4b2-1420-469a-9d8e-88dac5e5a45f": "1 - Never",
                        "c6c018ba-54db-48d2-926e-1aa00c3723fd": "2 - Rarely",
                        "f0928238-23cf-415d-b57b-288522c95627": "3 - Sometimes",
                        "1129e3e5-26b0-40f3-b0e5-abe77ecbf986": "4 - Often"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "I have noticed that I am becoming more irritable due to the stressful situation"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Duration of symptoms | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-25",
                    "optionSummaries": {
                        "064fae04-faef-4440-8eef-3153bcea3b5f": "< 1 month",
                        "1b6766b4-a855-4617-8bb8-7940ffafcee7": "1 – 6 months",
                        "f01a99bd-1777-4c2f-b97c-abda201d8bc9": "6 months – 2 years"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "I have noticed that I am becoming more irritable due to the stressful situation"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Severity | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-26",
                    "optionSummaries": {
                        "1311c337-0c93-4367-8ed7-181227b5c02a": "1 - Never",
                        "484bba2e-05e4-47b7-beeb-4845c8549e29": "2 - Rarely",
                        "cc2010c7-bd17-4d8f-94c7-7ce7f6c64255": "3 - Sometimes",
                        "f044f1b2-21ab-4b16-98dd-fec5cfdda865": "4 - Often"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "I get constant memories of the stressful situation and can’t do anything to stop them"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Duration of symptoms | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-27",
                    "optionSummaries": {
                        "e1bad685-3452-4ddb-9e6d-ba62aa8be9ce": "< 1 month",
                        "b616e216-aeab-427b-8b78-812fabf6ff26": "1 – 6 months",
                        "9a71436e-d901-48a5-888b-b0b493501ec1": "6 months – 2 years"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "I get constant memories of the stressful situation and can’t do anything to stop them"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Severity | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-28",
                    "optionSummaries": {
                        "98011995-7ee4-4eae-92d2-cd3fd020ead4": "1 - Never",
                        "25b5aaf9-9b6e-403a-aa88-f5d1d75b9b53": "2 - Rarely",
                        "5fba8748-855c-4331-a880-7acd039a5888": "3 - Sometimes",
                        "6e1fc67e-5bbd-4a3a-93ed-7d795ee07cc6": "4 - Often"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "I try to suppress my feelings because they are a burden to me"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Duration of symptoms | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-29",
                    "optionSummaries": {
                        "3fe22871-2db3-4347-ba3c-86a849f5da7c": "< 1 month",
                        "7bbde7a0-9fd7-4f18-88b6-85fb1b818d4e": "1 – 6 months",
                        "2829782d-3fb9-41b6-8d98-aae57fb47244": "6 months – 2 years"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "I try to suppress my feelings because they are a burden to me"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Severity | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-30",
                    "optionSummaries": {
                        "cdf152b4-bf2d-40f3-a697-13ffd2f504a5": "1 - Never",
                        "909e037f-fcc4-4360-b065-df30482cf6fa": "2 - Rarely",
                        "6e6fb253-f964-47ce-b491-9f8beda8224f": "3 - Sometimes",
                        "b38aaf5a-27d0-4b91-8907-577b2e29b348": "4 - Often"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "My thoughts revolve around anything to do with the stressful situation"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Duration of symptoms | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-31",
                    "optionSummaries": {
                        "4f0db8d6-9ca1-4de8-8c34-d30ae06f386a": "< 1 month",
                        "a98163c9-991d-4d13-86ec-5801a8c9f056": "1 – 6 months",
                        "dade0488-c33a-4978-8b87-6f281ac5b664": "6 months – 2 years"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "My thoughts revolve around anything to do with the stressful situation"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Severity | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-32",
                    "optionSummaries": {
                        "1f53ba0f-f3df-4d99-9660-1cdf93243db5": "1 - Never",
                        "be2c1f3b-7a9c-45ac-944f-7195dce151c7": "2 - Rarely",
                        "10017256-8ca9-4f3e-8ae0-a436f1df76c1": "3 - Sometimes",
                        "9dc7ecfd-6999-4221-b184-93086e3435b5": "4 - Often"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "Since the stressful situation, I am scared of doing certain things or of getting into certain situations"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Duration of symptoms | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-33",
                    "optionSummaries": {
                        "c75230fb-713b-4fef-b342-ca1ae7f053be": "< 1 month",
                        "3cc68c74-7655-4416-b6d9-0a45f4771737": "1 – 6 months",
                        "334b6acd-4fc9-4851-ae5a-d83f23167907": "6 months – 2 years"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "Since the stressful situation, I am scared of doing certain things or of getting into certain situations"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Severity | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-34",
                    "optionSummaries": {
                        "ad41ec02-e4b3-4271-89a4-9821400b0376": "1 - Never",
                        "a482ed67-65e1-4f7d-bb41-712100d5250e": "2 - Rarely",
                        "0a47a8f2-8e1b-47a7-90f0-4a373da2f07f": "3 - Sometimes",
                        "bef300d5-8353-4331-b7e1-ab3c3add0aea": "4 - Often"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "Since the stressful situation, I don’t like going to work or carrying out the necessary tasks in everyday life"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Duration of symptoms | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-35",
                    "optionSummaries": {
                        "1c9ba056-4c91-4f1d-9b02-ab3e305d41ac": "< 1 month",
                        "0d6b886f-15d5-4548-882d-e95660128c07": "1 – 6 months",
                        "4ce01cd3-a5bc-4c05-ac7d-e9a267ea33d2": "6 months – 2 years"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "Since the stressful situation, I don’t like going to work or carrying out the necessary tasks in everyday life"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Severity | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-36",
                    "optionSummaries": {
                        "8d5941cf-bd97-4455-a5dd-c7b2e4e2cf80": "1 - Never",
                        "3f8fcc7c-1973-481e-b719-c498d79cfcb2": "2 - Rarely",
                        "b4641b0c-95f8-4337-82da-bb6dd7053018": "3 - Sometimes",
                        "f1351540-1c49-41d7-bbeb-46898cdcaa16": "4 - Often"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "I have been feeling dispirited since the stressful situation and have little hope for the future"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Duration of symptoms | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-37",
                    "optionSummaries": {
                        "ae028d33-dd83-4e97-a115-a4b09e7e8a38": "< 1 month",
                        "34b2e2e7-e24e-46b8-92b4-ea0cda92e7bb": "1 – 6 months",
                        "c5609c63-5e2e-4a6b-9dd9-84a40a872749": "6 months – 2 years"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "I have been feeling dispirited since the stressful situation and have little hope for the future"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Severity | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-38",
                    "optionSummaries": {
                        "18a16e92-b65b-44ef-b499-5b30b6acdb17": "1 - Never",
                        "30a33902-6b24-4692-bcc6-4a849fa40b7b": "2 - Rarely",
                        "1160a853-9035-47e3-9674-e8c6be37c462": "3 - Sometimes",
                        "2a247fc5-1eaa-4d3b-a891-508574422153": "4 - Often"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "Since the stressful situation, I can no longer sleep properly"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Duration of symptoms | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-39",
                    "optionSummaries": {
                        "437f2279-b5fb-42f4-99a0-6cded42c8b14": "< 1 month",
                        "eff6de92-db81-479c-aa7e-6f582d24ab23": "1 – 6 months",
                        "0d17c7f9-e6e5-4635-bf22-679ae0afe5a1": "6 months – 2 years"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "Since the stressful situation, I can no longer sleep properly"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Severity | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-40",
                    "optionSummaries": {
                        "b872542a-38a0-469e-a4fc-2de60d04e209": "1 - Never",
                        "2a3f8956-eef9-436c-8d4c-496e73a907fb": "2 - Rarely",
                        "efa3ed09-8baa-453c-a7d5-6d2016030b5c": "3 - Sometimes",
                        "91276a78-c4f2-4272-99fc-64fe3a97fed3": "4 - Often"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "Overall, the situation affected me strongly in my personal relationships, my leisure activities, or other important areas of life"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Duration of symptoms | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "ADNM-41",
                    "optionSummaries": {
                        "e5ba510a-e0eb-475e-91cf-9993e046efde": "< 1 month",
                        "05b0dddf-4350-4288-afa7-91c1c5409dcd": "1 – 6 months",
                        "beccdc38-ffee-4be9-a1e1-8b1e4a6b678d": "6 months – 2 years"
                    }
                }
            ]
        },
        "section": [
            "ADNM-20 questions/answers",
            "Overall, the situation affected me strongly in my personal relationships, my leisure activities, or other important areas of life"
        ]
    }
];
