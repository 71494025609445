import { Button, Heading, Page, Text } from '@psyomics/components'
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Styles from './Demo.module.scss'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import Header from 'ui/app/components/Header'
import { useDemoContext } from './DemoContext'

const DemoHomeCenseo: React.FC = () => {
    const navigate = useNavigate()
    const mixpanelApiUrl = useContext(MixpanelContext)
    const { visitedDemoHomepage } = useMixpanelEvent(mixpanelApiUrl)
    const { setDemo } = useDemoContext()

    useEffect(() => {
        visitedDemoHomepage('demo', undefined)
    }, [])

    return (
        <Page
            className={Styles.demoHome}
            style={{ backgroundColor: 'transparent' }}
            layout="standard"
            header={<Header patientFacing={false} demoMenu={true} />}
        >
            <Heading el="h2" size="heading2" color="mid" css={{ mb: 7 }}>
                Welcome to the Psyomics Platform demo
            </Heading>
            <div className={Styles.demoHomeText}>
                <Text size="medium" css={{ mb: 5 }}>
                    This demo will guide you through the journey of using Psyomics Platform, our digital front door
                    mental health platform. Psyomics Platform supports triage, assessment and treatment decisions at
                    various stages of the patient pathway by comprehensive, consistent data collection.
                </Text>
                <Text size="medium" css={{ mb: 5 }}>
                    We start with the{' '}
                    <Text el="span" size="medium" weight="bold">
                        Clinician Experience
                    </Text>
                    , this provides a selection of reports generated by Psyomics Platform, along with data visualisation
                    examples to provide insights into population health outcomes and app usage.
                </Text>
                <Text size="medium" css={{ mb: 7 }}>
                    Next, we&#39;ll explore the{' '}
                    <Text el="span" size="medium" weight="bold">
                        Service User Experience
                    </Text>
                    , demonstrating how our adaptive questionnaire delivers a personalised experience for each service
                    user. The adaptive and detailed questioning approach ensures that only relevant questions are asked,
                    while still gathering a comprehensive symptom and contextual profile.
                </Text>
            </div>

            <div className={Styles.startDemoBtnWrapper}>
                <Button
                    className="text-right"
                    appearance="primary"
                    size="small"
                    label="Start Demo"
                    onClick={() => {
                        setDemo(true)
                        navigate(`/demo/healthcare`)
                    }}
                />
            </div>
        </Page>
    )
}

export default DemoHomeCenseo
