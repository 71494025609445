import React, { useEffect, useState, useRef } from 'react'
import { Button } from '@psyomics/components'

import FilterIcon from 'ui/app/components/FilterIcon'
import Styles from './TextFilter.module.scss'

interface TextFilterProps {
    label: string
    text: string | null
    triggerFilter: (text: string | null) => void
}
export const TextFilter: React.FC<TextFilterProps> = ({ label, text, triggerFilter }) => {
    const [show, setShow] = useState<boolean>(false)
    const [filterText, setFilterText] = useState<string | null>(null)
    const idRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        const handler = (e: MouseEvent) => {
            if (idRef.current && !idRef.current.contains(e.target as Node)) {
                setShow(false)
            }
        }
        document.addEventListener('mousedown', handler)

        return () => {
            document.removeEventListener('mousedown', handler)
        }
    }, [])

    useEffect(() => {
        if (!show) {
            setFilterText(text)
        }
    }, [show])

    const toggleShow = (e: React.MouseEvent) => {
        setShow((current) => !current)
    }
    const toggleShowKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === ' ' || e.key === 'Spacebar') {
            console.log('space pressed')
            e.preventDefault()
            setShow((current) => !current)
        }
    }

    return (
        <div>
            <div className={Styles.filterCell}>
                <span
                    tabIndex={0}
                    role={'button'}
                    className={Styles.filterIconContainer}
                    onClick={toggleShow}
                    onKeyDown={toggleShowKeyPress}
                >
                    <FilterIcon></FilterIcon>
                    {filterText && <span className={Styles.filterActiveDot}></span>}
                </span>
            </div>
            <div
                style={{
                    position: 'relative',
                }}
            >
                <div ref={idRef} className={`${Styles.textFilter} ${show ? Styles.showText : ''}`}>
                    <label>
                        {label}:
                        <input
                            value={filterText || undefined}
                            type="text"
                            onChange={(e) => {
                                setFilterText(e.target.value)
                            }}
                        ></input>
                    </label>

                    <div className={Styles.buttonsContainer}>
                        <Button
                            size="small"
                            appearance="secondary"
                            label="clear"
                            onClick={() => {
                                setFilterText(null)
                                triggerFilter(null)
                            }}
                        ></Button>
                        <Button
                            size="small"
                            appearance="primary"
                            label="search"
                            onClick={() => {
                                triggerFilter(filterText)
                                setShow(false)
                            }}
                        ></Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
