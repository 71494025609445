import { Button, Heading, Page, Text } from '@psyomics/components'
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Styles from '../Demo.module.scss'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import Header from 'ui/app/components/Header'
import { useDemoContext } from '../DemoContext'

const DemoHomeHealthIt: React.FC = () => {
    const navigate = useNavigate()
    const mixpanelApiUrl = useContext(MixpanelContext)
    const { visitedDemoHomepage } = useMixpanelEvent(mixpanelApiUrl)
    const { setDemo } = useDemoContext()

    useEffect(() => {
        visitedDemoHomepage('demo', 'Demo Health IT')
    }, [])

    return (
        <Page
            className={Styles.demoHome}
            style={{ backgroundColor: 'transparent' }}
            layout="standard"
            header={<Header patientFacing={false} demoMenu={true} />}
        >
            <Heading el="h1" size="heading1" color="mid" css={{ mb: 7 }}>
                Welcome to the Psyomics Demo
            </Heading>
            <div className={Styles.demoHomeText}>
                <Text size="medium" css={{ mb: 5 }}>
                    This demo will guide you through the journey of using the Psyomics Platform, our digital front door
                    for mental health services. The Psyomics Platform supports healthcare professionals in capturing
                    data directly from patients to generate high quality, personalised reports to enable efficient care
                    navigation.
                </Text>
                <Text size="medium" css={{ mb: 5 }}>
                    We start with the{' '}
                    <Text el="span" size="medium" weight="bold">
                        Clinician Experience
                    </Text>
                    , this provides a selection of reports generated by Psyomics, and an example of some of the data
                    collected by app usage.
                </Text>
                <Text size="medium" css={{ mb: 7 }}>
                    Next, we&apos;ll walk through the{' '}
                    <Text el="span" size="medium" weight="bold">
                        Service User Experience
                    </Text>
                    , showcasing how our questionnaire provides an engaging journey by asking relevant questions
                    tailored to their mental health and personal context.
                </Text>
            </div>

            <div className={Styles.startDemoBtnWrapper}>
                <Button
                    className="text-right"
                    appearance="primary"
                    size="small"
                    label="Start Demo"
                    onClick={() => {
                        setDemo(true)
                        navigate(`/demo/health-it/dashboard`)
                    }}
                />
            </div>
        </Page>
    )
}

export default DemoHomeHealthIt
