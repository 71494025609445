"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
exports.questions = [
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "125ae7a4-99d8-481a-91fa-85b19baf9f15",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "8d74da66-c9ae-44e9-9d43-1f49f1cbfedf",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "MDQ-1a",
        "text": "Has there ever been a period of time when you were not your usual self and you felt so good or so hyper that other people thought you were not your normal self or you were so hyper that you got into trouble?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "848b7b1c-3ecf-4e68-a7fa-3c95a39812d1",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "3f398b71-0882-4106-a0b3-d9f821d64352",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "MDQ-1b",
        "text": "Has there ever been a period of time when you were not your usual self and you were so irritable that you shouted at people or started fights or arguments?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "805a2d00-cf87-4b4d-8e83-3c5f906c2bc1",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "edef13fe-172d-48b0-8563-f1747916b2ed",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "MDQ-1c",
        "text": "Has there ever been a period of time when you were not your usual self and you felt much more self-confident than usual?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b2f419ff-4ddd-44bf-b4d7-2de648ad4f33",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "50bb0db8-10ef-4eb9-8416-5ec3ecd03514",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "MDQ-1d",
        "text": "Has there ever been a period of time when you were not your usual self and you got much less sleep than usual and found you didn't really miss it?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "55758e6a-0090-409c-9827-ccfd79a5fa43",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "4d330482-c40b-476f-869f-ed63a2422983",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "MDQ-1e",
        "text": "Has there ever been a period of time when you were not your usual self and you were much more talkative or spoke faster than usual?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "19dd7f9a-fef9-4f7e-bbf4-ab41b8478014",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "999b098e-3362-4d39-8df7-addbc762e841",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "MDQ-1f",
        "text": "Has there ever been a period of time when you were not your usual self and thoughts raced through your head or you couldn't slow your mind down?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "171fb757-37fe-46d7-9826-06102075a1c5",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "be776d60-1419-429d-bfc4-96a9b4805be9",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "MDQ-1g",
        "text": "Has there ever been a period of time when you were not your usual self and you were so easily distracted by things around you that you had trouble concentrating or staying on track?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "303dec0f-4ca4-403e-bac1-63f59af29b84",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d80e0143-38bf-43a2-b0e9-1e0007f10a1e",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "MDQ-1h",
        "text": "Has there ever been a period of time when you were not your usual self and you had much more energy than usual?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "c2b62967-e25a-455a-a267-caa237ee9f2c",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "13c5b694-8d3d-4006-8905-53d79c9e59b8",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "MDQ-1i",
        "text": "Has there ever been a period of time when you were not your usual self and you were much more active or did many more things than usual?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a1ab5328-0b54-48b1-997f-6e692bc96f23",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "84b06f04-3506-4514-94b3-60806ae45be8",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "MDQ-1j",
        "text": "Has there ever been a period of time when you were not your usual self and you were much more social or outgoing than usual, for example, you telephoned friends in the middle of the night?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "bce6ba65-777f-4150-977d-25f9fcdcc97f",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "0ff785cc-698a-45ff-9b2c-d20a60144df1",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "MDQ-1k",
        "text": "Has there ever been a period of time when you were not your usual self and you were much more interested in sex than usual?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b611dc5b-a7a4-4642-a36b-b47729b55143",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "4a03a0e0-9a65-4a43-b87a-3daadb75895f",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "MDQ-1l",
        "text": "Has there ever been a period of time when you were not your usual self and you did things that were unusual for you or that other people might have thought were excessive, foolish, or risky?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b7b9167a-f75b-4077-bbc1-16280ec8697f",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "8e770ff6-ccf4-4a6c-8723-4291094916c3",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "MDQ-1m",
        "text": "Has there ever been a period of time when you were not your usual self and spending money got you or your family in trouble?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "aea62f9d-eff9-4d12-a699-5cbc55cf868b",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "257a3148-f813-4e91-979a-5b6c41438ec3",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "MDQ-2",
        "text": "If you checked YES to more than one of the previous questions, have several of these ever happened during the same period of time? (If needed, you can click the back button to see your previous answers)",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "5f0d5c27-81be-40ea-b98e-b7682a5acda8",
                "text": "No problem",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "157261c4-bcd6-46a3-914c-283b1e0551ac",
                "text": "Minor problem",
                "value": 0.3333333333333333
            },
            {
                "__typename": "Option",
                "id": "4fff7d37-c62b-47a0-a4c7-8e65c0e6d4d9",
                "text": "Moderate problem",
                "value": 0.6666666666666666
            },
            {
                "__typename": "Option",
                "id": "81911c8e-9b08-4dd2-af78-9f3bef81ccf6",
                "text": "Serious problem",
                "value": 1
            }
        ],
        "id": "MDQ-3",
        "text": "Of those issues, how much of a problem did any of these cause you — like being able to work having family, money, or legal troubles; getting into arguments or fights?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "165ff317-0925-451d-850c-b1e4430c0ea1",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "fd20ebe2-30f6-465f-87ee-c42cc75b8b7d",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "MDQ-4",
        "text": "Have any of your blood relatives (ie, children, siblings, parents, grandparents, aunts, uncles) had manic-depressive illness or bipolar disorder?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "7b1022aa-5a2a-4646-830a-0e41285f72a0",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "27833f0e-c25e-4431-8696-cab6ee44960b",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "MDQ-5",
        "text": "Has a health professional ever told you that you have manic-depressive illness or bipolar disorder?",
        "isMultiselect": false
    }
];
exports.aspects = {
    "mdq": {
        "type": "mean",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "MDQ-1a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "125ae7a4-99d8-481a-91fa-85b19baf9f15": 0,
                            "8d74da66-c9ae-44e9-9d43-1f49f1cbfedf": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "MDQ-1b",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "848b7b1c-3ecf-4e68-a7fa-3c95a39812d1": 0,
                            "3f398b71-0882-4106-a0b3-d9f821d64352": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "MDQ-1c",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "805a2d00-cf87-4b4d-8e83-3c5f906c2bc1": 0,
                            "edef13fe-172d-48b0-8563-f1747916b2ed": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "MDQ-1d",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "b2f419ff-4ddd-44bf-b4d7-2de648ad4f33": 0,
                            "50bb0db8-10ef-4eb9-8416-5ec3ecd03514": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "MDQ-1e",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "55758e6a-0090-409c-9827-ccfd79a5fa43": 0,
                            "4d330482-c40b-476f-869f-ed63a2422983": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "MDQ-1f",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "19dd7f9a-fef9-4f7e-bbf4-ab41b8478014": 0,
                            "999b098e-3362-4d39-8df7-addbc762e841": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "MDQ-1g",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "171fb757-37fe-46d7-9826-06102075a1c5": 0,
                            "be776d60-1419-429d-bfc4-96a9b4805be9": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "MDQ-1h",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "303dec0f-4ca4-403e-bac1-63f59af29b84": 0,
                            "d80e0143-38bf-43a2-b0e9-1e0007f10a1e": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "MDQ-1i",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "c2b62967-e25a-455a-a267-caa237ee9f2c": 0,
                            "13c5b694-8d3d-4006-8905-53d79c9e59b8": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "MDQ-1j",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "a1ab5328-0b54-48b1-997f-6e692bc96f23": 0,
                            "84b06f04-3506-4514-94b3-60806ae45be8": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "MDQ-1k",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "bce6ba65-777f-4150-977d-25f9fcdcc97f": 0,
                            "0ff785cc-698a-45ff-9b2c-d20a60144df1": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "MDQ-1l",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "b611dc5b-a7a4-4642-a36b-b47729b55143": 0,
                            "4a03a0e0-9a65-4a43-b87a-3daadb75895f": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "MDQ-1m",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "b7b9167a-f75b-4077-bbc1-16280ec8697f": 0,
                            "8e770ff6-ccf4-4a6c-8723-4291094916c3": 1
                        }
                    }
                }
            }
        ]
    }
};
exports.tests = {
    "mdq": [
        {
            "name": "MDQ 0 score-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0,
                "threshold": 0
            },
            "answers": {
                "MDQ-1a": {
                    "questionId": "MDQ-1a",
                    "chosenOptionIds": [
                        "125ae7a4-99d8-481a-91fa-85b19baf9f15"
                    ]
                },
                "MDQ-1b": {
                    "questionId": "MDQ-1b",
                    "chosenOptionIds": [
                        "848b7b1c-3ecf-4e68-a7fa-3c95a39812d1"
                    ]
                },
                "MDQ-1c": {
                    "questionId": "MDQ-1c",
                    "chosenOptionIds": [
                        "805a2d00-cf87-4b4d-8e83-3c5f906c2bc1"
                    ]
                },
                "MDQ-1d": {
                    "questionId": "MDQ-1d",
                    "chosenOptionIds": [
                        "b2f419ff-4ddd-44bf-b4d7-2de648ad4f33"
                    ]
                },
                "MDQ-1e": {
                    "questionId": "MDQ-1e",
                    "chosenOptionIds": [
                        "55758e6a-0090-409c-9827-ccfd79a5fa43"
                    ]
                },
                "MDQ-1f": {
                    "questionId": "MDQ-1f",
                    "chosenOptionIds": [
                        "19dd7f9a-fef9-4f7e-bbf4-ab41b8478014"
                    ]
                },
                "MDQ-1g": {
                    "questionId": "MDQ-1g",
                    "chosenOptionIds": [
                        "171fb757-37fe-46d7-9826-06102075a1c5"
                    ]
                },
                "MDQ-1h": {
                    "questionId": "MDQ-1h",
                    "chosenOptionIds": [
                        "303dec0f-4ca4-403e-bac1-63f59af29b84"
                    ]
                },
                "MDQ-1i": {
                    "questionId": "MDQ-1i",
                    "chosenOptionIds": [
                        "c2b62967-e25a-455a-a267-caa237ee9f2c"
                    ]
                },
                "MDQ-1j": {
                    "questionId": "MDQ-1j",
                    "chosenOptionIds": [
                        "a1ab5328-0b54-48b1-997f-6e692bc96f23"
                    ]
                },
                "MDQ-1k": {
                    "questionId": "MDQ-1k",
                    "chosenOptionIds": [
                        "bce6ba65-777f-4150-977d-25f9fcdcc97f"
                    ]
                },
                "MDQ-1l": {
                    "questionId": "MDQ-1l",
                    "chosenOptionIds": [
                        "b611dc5b-a7a4-4642-a36b-b47729b55143"
                    ]
                },
                "MDQ-1m": {
                    "questionId": "MDQ-1m",
                    "chosenOptionIds": [
                        "b7b9167a-f75b-4077-bbc1-16280ec8697f"
                    ]
                },
                "MDQ-2": {
                    "questionId": "MDQ-2",
                    "chosenOptionIds": [
                        "aea62f9d-eff9-4d12-a699-5cbc55cf868b"
                    ]
                },
                "MDQ-3": {
                    "questionId": "MDQ-3",
                    "chosenOptionIds": [
                        "5f0d5c27-81be-40ea-b98e-b7682a5acda8"
                    ]
                },
                "MDQ-4": {
                    "questionId": "MDQ-4",
                    "chosenOptionIds": [
                        "165ff317-0925-451d-850c-b1e4430c0ea1"
                    ]
                },
                "MDQ-5": {
                    "questionId": "MDQ-5",
                    "chosenOptionIds": [
                        "27833f0e-c25e-4431-8696-cab6ee44960b"
                    ]
                }
            }
        },
        {
            "name": "MDQ 1 score-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 1,
                "threshold": 0
            },
            "answers": {
                "MDQ-1a": {
                    "questionId": "MDQ-1a",
                    "chosenOptionIds": [
                        "8d74da66-c9ae-44e9-9d43-1f49f1cbfedf"
                    ]
                },
                "MDQ-1b": {
                    "questionId": "MDQ-1b",
                    "chosenOptionIds": [
                        "3f398b71-0882-4106-a0b3-d9f821d64352"
                    ]
                },
                "MDQ-1c": {
                    "questionId": "MDQ-1c",
                    "chosenOptionIds": [
                        "edef13fe-172d-48b0-8563-f1747916b2ed"
                    ]
                },
                "MDQ-1d": {
                    "questionId": "MDQ-1d",
                    "chosenOptionIds": [
                        "50bb0db8-10ef-4eb9-8416-5ec3ecd03514"
                    ]
                },
                "MDQ-1e": {
                    "questionId": "MDQ-1e",
                    "chosenOptionIds": [
                        "4d330482-c40b-476f-869f-ed63a2422983"
                    ]
                },
                "MDQ-1f": {
                    "questionId": "MDQ-1f",
                    "chosenOptionIds": [
                        "999b098e-3362-4d39-8df7-addbc762e841"
                    ]
                },
                "MDQ-1g": {
                    "questionId": "MDQ-1g",
                    "chosenOptionIds": [
                        "be776d60-1419-429d-bfc4-96a9b4805be9"
                    ]
                },
                "MDQ-1h": {
                    "questionId": "MDQ-1h",
                    "chosenOptionIds": [
                        "d80e0143-38bf-43a2-b0e9-1e0007f10a1e"
                    ]
                },
                "MDQ-1i": {
                    "questionId": "MDQ-1i",
                    "chosenOptionIds": [
                        "13c5b694-8d3d-4006-8905-53d79c9e59b8"
                    ]
                },
                "MDQ-1j": {
                    "questionId": "MDQ-1j",
                    "chosenOptionIds": [
                        "84b06f04-3506-4514-94b3-60806ae45be8"
                    ]
                },
                "MDQ-1k": {
                    "questionId": "MDQ-1k",
                    "chosenOptionIds": [
                        "0ff785cc-698a-45ff-9b2c-d20a60144df1"
                    ]
                },
                "MDQ-1l": {
                    "questionId": "MDQ-1l",
                    "chosenOptionIds": [
                        "4a03a0e0-9a65-4a43-b87a-3daadb75895f"
                    ]
                },
                "MDQ-1m": {
                    "questionId": "MDQ-1m",
                    "chosenOptionIds": [
                        "8e770ff6-ccf4-4a6c-8723-4291094916c3"
                    ]
                },
                "MDQ-2": {
                    "questionId": "MDQ-2",
                    "chosenOptionIds": [
                        "257a3148-f813-4e91-979a-5b6c41438ec3"
                    ]
                },
                "MDQ-3": {
                    "questionId": "MDQ-3",
                    "chosenOptionIds": [
                        "4fff7d37-c62b-47a0-a4c7-8e65c0e6d4d9"
                    ]
                },
                "MDQ-4": {
                    "questionId": "MDQ-4",
                    "chosenOptionIds": [
                        "165ff317-0925-451d-850c-b1e4430c0ea1"
                    ]
                },
                "MDQ-5": {
                    "questionId": "MDQ-5",
                    "chosenOptionIds": [
                        "27833f0e-c25e-4431-8696-cab6ee44960b"
                    ]
                }
            }
        },
        {
            "name": "MDQ partial score-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.3076923076923077,
                "threshold": 0
            },
            "answers": {
                "MDQ-1a": {
                    "questionId": "MDQ-1a",
                    "chosenOptionIds": [
                        "125ae7a4-99d8-481a-91fa-85b19baf9f15"
                    ]
                },
                "MDQ-1b": {
                    "questionId": "MDQ-1b",
                    "chosenOptionIds": [
                        "848b7b1c-3ecf-4e68-a7fa-3c95a39812d1"
                    ]
                },
                "MDQ-1c": {
                    "questionId": "MDQ-1c",
                    "chosenOptionIds": [
                        "805a2d00-cf87-4b4d-8e83-3c5f906c2bc1"
                    ]
                },
                "MDQ-1d": {
                    "questionId": "MDQ-1d",
                    "chosenOptionIds": [
                        "b2f419ff-4ddd-44bf-b4d7-2de648ad4f33"
                    ]
                },
                "MDQ-1e": {
                    "questionId": "MDQ-1e",
                    "chosenOptionIds": [
                        "55758e6a-0090-409c-9827-ccfd79a5fa43"
                    ]
                },
                "MDQ-1f": {
                    "questionId": "MDQ-1f",
                    "chosenOptionIds": [
                        "19dd7f9a-fef9-4f7e-bbf4-ab41b8478014"
                    ]
                },
                "MDQ-1g": {
                    "questionId": "MDQ-1g",
                    "chosenOptionIds": [
                        "171fb757-37fe-46d7-9826-06102075a1c5"
                    ]
                },
                "MDQ-1h": {
                    "questionId": "MDQ-1h",
                    "chosenOptionIds": [
                        "303dec0f-4ca4-403e-bac1-63f59af29b84"
                    ]
                },
                "MDQ-1i": {
                    "questionId": "MDQ-1i",
                    "chosenOptionIds": [
                        "c2b62967-e25a-455a-a267-caa237ee9f2c"
                    ]
                },
                "MDQ-1j": {
                    "questionId": "MDQ-1j",
                    "chosenOptionIds": [
                        "84b06f04-3506-4514-94b3-60806ae45be8"
                    ]
                },
                "MDQ-1k": {
                    "questionId": "MDQ-1k",
                    "chosenOptionIds": [
                        "0ff785cc-698a-45ff-9b2c-d20a60144df1"
                    ]
                },
                "MDQ-1l": {
                    "questionId": "MDQ-1l",
                    "chosenOptionIds": [
                        "4a03a0e0-9a65-4a43-b87a-3daadb75895f"
                    ]
                },
                "MDQ-1m": {
                    "questionId": "MDQ-1m",
                    "chosenOptionIds": [
                        "8e770ff6-ccf4-4a6c-8723-4291094916c3"
                    ]
                },
                "MDQ-2": {
                    "questionId": "MDQ-2",
                    "chosenOptionIds": [
                        "257a3148-f813-4e91-979a-5b6c41438ec3"
                    ]
                },
                "MDQ-3": {
                    "questionId": "MDQ-3",
                    "chosenOptionIds": [
                        "5f0d5c27-81be-40ea-b98e-b7682a5acda8"
                    ]
                },
                "MDQ-4": {
                    "questionId": "MDQ-4",
                    "chosenOptionIds": [
                        "165ff317-0925-451d-850c-b1e4430c0ea1"
                    ]
                },
                "MDQ-5": {
                    "questionId": "MDQ-5",
                    "chosenOptionIds": [
                        "27833f0e-c25e-4431-8696-cab6ee44960b"
                    ]
                }
            }
        },
        {
            "name": "MDQ partial score - sreened in-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.5384615384615384,
                "threshold": 0
            },
            "answers": {
                "MDQ-1a": {
                    "questionId": "MDQ-1a",
                    "chosenOptionIds": [
                        "8d74da66-c9ae-44e9-9d43-1f49f1cbfedf"
                    ]
                },
                "MDQ-1b": {
                    "questionId": "MDQ-1b",
                    "chosenOptionIds": [
                        "3f398b71-0882-4106-a0b3-d9f821d64352"
                    ]
                },
                "MDQ-1c": {
                    "questionId": "MDQ-1c",
                    "chosenOptionIds": [
                        "805a2d00-cf87-4b4d-8e83-3c5f906c2bc1"
                    ]
                },
                "MDQ-1d": {
                    "questionId": "MDQ-1d",
                    "chosenOptionIds": [
                        "50bb0db8-10ef-4eb9-8416-5ec3ecd03514"
                    ]
                },
                "MDQ-1e": {
                    "questionId": "MDQ-1e",
                    "chosenOptionIds": [
                        "4d330482-c40b-476f-869f-ed63a2422983"
                    ]
                },
                "MDQ-1f": {
                    "questionId": "MDQ-1f",
                    "chosenOptionIds": [
                        "999b098e-3362-4d39-8df7-addbc762e841"
                    ]
                },
                "MDQ-1g": {
                    "questionId": "MDQ-1g",
                    "chosenOptionIds": [
                        "171fb757-37fe-46d7-9826-06102075a1c5"
                    ]
                },
                "MDQ-1h": {
                    "questionId": "MDQ-1h",
                    "chosenOptionIds": [
                        "d80e0143-38bf-43a2-b0e9-1e0007f10a1e"
                    ]
                },
                "MDQ-1i": {
                    "questionId": "MDQ-1i",
                    "chosenOptionIds": [
                        "c2b62967-e25a-455a-a267-caa237ee9f2c"
                    ]
                },
                "MDQ-1j": {
                    "questionId": "MDQ-1j",
                    "chosenOptionIds": [
                        "84b06f04-3506-4514-94b3-60806ae45be8"
                    ]
                },
                "MDQ-1k": {
                    "questionId": "MDQ-1k",
                    "chosenOptionIds": [
                        "bce6ba65-777f-4150-977d-25f9fcdcc97f"
                    ]
                },
                "MDQ-1l": {
                    "questionId": "MDQ-1l",
                    "chosenOptionIds": [
                        "b611dc5b-a7a4-4642-a36b-b47729b55143"
                    ]
                },
                "MDQ-1m": {
                    "questionId": "MDQ-1m",
                    "chosenOptionIds": [
                        "b7b9167a-f75b-4077-bbc1-16280ec8697f"
                    ]
                },
                "MDQ-2": {
                    "questionId": "MDQ-2",
                    "chosenOptionIds": [
                        "257a3148-f813-4e91-979a-5b6c41438ec3"
                    ]
                },
                "MDQ-3": {
                    "questionId": "MDQ-3",
                    "chosenOptionIds": [
                        "4fff7d37-c62b-47a0-a4c7-8e65c0e6d4d9"
                    ]
                },
                "MDQ-4": {
                    "questionId": "MDQ-4",
                    "chosenOptionIds": [
                        "165ff317-0925-451d-850c-b1e4430c0ea1"
                    ]
                },
                "MDQ-5": {
                    "questionId": "MDQ-5",
                    "chosenOptionIds": [
                        "27833f0e-c25e-4431-8696-cab6ee44960b"
                    ]
                }
            }
        },
        {
            "name": "MDQ partial score - sreened out 1-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.46153846153846156,
                "threshold": 0
            },
            "answers": {
                "MDQ-1a": {
                    "questionId": "MDQ-1a",
                    "chosenOptionIds": [
                        "125ae7a4-99d8-481a-91fa-85b19baf9f15"
                    ]
                },
                "MDQ-1b": {
                    "questionId": "MDQ-1b",
                    "chosenOptionIds": [
                        "3f398b71-0882-4106-a0b3-d9f821d64352"
                    ]
                },
                "MDQ-1c": {
                    "questionId": "MDQ-1c",
                    "chosenOptionIds": [
                        "805a2d00-cf87-4b4d-8e83-3c5f906c2bc1"
                    ]
                },
                "MDQ-1d": {
                    "questionId": "MDQ-1d",
                    "chosenOptionIds": [
                        "50bb0db8-10ef-4eb9-8416-5ec3ecd03514"
                    ]
                },
                "MDQ-1e": {
                    "questionId": "MDQ-1e",
                    "chosenOptionIds": [
                        "4d330482-c40b-476f-869f-ed63a2422983"
                    ]
                },
                "MDQ-1f": {
                    "questionId": "MDQ-1f",
                    "chosenOptionIds": [
                        "999b098e-3362-4d39-8df7-addbc762e841"
                    ]
                },
                "MDQ-1g": {
                    "questionId": "MDQ-1g",
                    "chosenOptionIds": [
                        "171fb757-37fe-46d7-9826-06102075a1c5"
                    ]
                },
                "MDQ-1h": {
                    "questionId": "MDQ-1h",
                    "chosenOptionIds": [
                        "d80e0143-38bf-43a2-b0e9-1e0007f10a1e"
                    ]
                },
                "MDQ-1i": {
                    "questionId": "MDQ-1i",
                    "chosenOptionIds": [
                        "c2b62967-e25a-455a-a267-caa237ee9f2c"
                    ]
                },
                "MDQ-1j": {
                    "questionId": "MDQ-1j",
                    "chosenOptionIds": [
                        "84b06f04-3506-4514-94b3-60806ae45be8"
                    ]
                },
                "MDQ-1k": {
                    "questionId": "MDQ-1k",
                    "chosenOptionIds": [
                        "bce6ba65-777f-4150-977d-25f9fcdcc97f"
                    ]
                },
                "MDQ-1l": {
                    "questionId": "MDQ-1l",
                    "chosenOptionIds": [
                        "b611dc5b-a7a4-4642-a36b-b47729b55143"
                    ]
                },
                "MDQ-1m": {
                    "questionId": "MDQ-1m",
                    "chosenOptionIds": [
                        "b7b9167a-f75b-4077-bbc1-16280ec8697f"
                    ]
                },
                "MDQ-2": {
                    "questionId": "MDQ-2",
                    "chosenOptionIds": [
                        "257a3148-f813-4e91-979a-5b6c41438ec3"
                    ]
                },
                "MDQ-3": {
                    "questionId": "MDQ-3",
                    "chosenOptionIds": [
                        "4fff7d37-c62b-47a0-a4c7-8e65c0e6d4d9"
                    ]
                },
                "MDQ-4": {
                    "questionId": "MDQ-4",
                    "chosenOptionIds": [
                        "165ff317-0925-451d-850c-b1e4430c0ea1"
                    ]
                },
                "MDQ-5": {
                    "questionId": "MDQ-5",
                    "chosenOptionIds": [
                        "27833f0e-c25e-4431-8696-cab6ee44960b"
                    ]
                }
            }
        },
        {
            "name": "MDQ partial score - sreened out 2-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.5384615384615384,
                "threshold": 0
            },
            "answers": {
                "MDQ-1a": {
                    "questionId": "MDQ-1a",
                    "chosenOptionIds": [
                        "8d74da66-c9ae-44e9-9d43-1f49f1cbfedf"
                    ]
                },
                "MDQ-1b": {
                    "questionId": "MDQ-1b",
                    "chosenOptionIds": [
                        "3f398b71-0882-4106-a0b3-d9f821d64352"
                    ]
                },
                "MDQ-1c": {
                    "questionId": "MDQ-1c",
                    "chosenOptionIds": [
                        "805a2d00-cf87-4b4d-8e83-3c5f906c2bc1"
                    ]
                },
                "MDQ-1d": {
                    "questionId": "MDQ-1d",
                    "chosenOptionIds": [
                        "50bb0db8-10ef-4eb9-8416-5ec3ecd03514"
                    ]
                },
                "MDQ-1e": {
                    "questionId": "MDQ-1e",
                    "chosenOptionIds": [
                        "4d330482-c40b-476f-869f-ed63a2422983"
                    ]
                },
                "MDQ-1f": {
                    "questionId": "MDQ-1f",
                    "chosenOptionIds": [
                        "999b098e-3362-4d39-8df7-addbc762e841"
                    ]
                },
                "MDQ-1g": {
                    "questionId": "MDQ-1g",
                    "chosenOptionIds": [
                        "171fb757-37fe-46d7-9826-06102075a1c5"
                    ]
                },
                "MDQ-1h": {
                    "questionId": "MDQ-1h",
                    "chosenOptionIds": [
                        "d80e0143-38bf-43a2-b0e9-1e0007f10a1e"
                    ]
                },
                "MDQ-1i": {
                    "questionId": "MDQ-1i",
                    "chosenOptionIds": [
                        "c2b62967-e25a-455a-a267-caa237ee9f2c"
                    ]
                },
                "MDQ-1j": {
                    "questionId": "MDQ-1j",
                    "chosenOptionIds": [
                        "84b06f04-3506-4514-94b3-60806ae45be8"
                    ]
                },
                "MDQ-1k": {
                    "questionId": "MDQ-1k",
                    "chosenOptionIds": [
                        "bce6ba65-777f-4150-977d-25f9fcdcc97f"
                    ]
                },
                "MDQ-1l": {
                    "questionId": "MDQ-1l",
                    "chosenOptionIds": [
                        "b611dc5b-a7a4-4642-a36b-b47729b55143"
                    ]
                },
                "MDQ-1m": {
                    "questionId": "MDQ-1m",
                    "chosenOptionIds": [
                        "b7b9167a-f75b-4077-bbc1-16280ec8697f"
                    ]
                },
                "MDQ-2": {
                    "questionId": "MDQ-2",
                    "chosenOptionIds": [
                        "257a3148-f813-4e91-979a-5b6c41438ec3"
                    ]
                },
                "MDQ-3": {
                    "questionId": "MDQ-3",
                    "chosenOptionIds": [
                        "157261c4-bcd6-46a3-914c-283b1e0551ac"
                    ]
                },
                "MDQ-4": {
                    "questionId": "MDQ-4",
                    "chosenOptionIds": [
                        "165ff317-0925-451d-850c-b1e4430c0ea1"
                    ]
                },
                "MDQ-5": {
                    "questionId": "MDQ-5",
                    "chosenOptionIds": [
                        "27833f0e-c25e-4431-8696-cab6ee44960b"
                    ]
                }
            }
        },
        {
            "name": "MDQ partial score - sreened out 3-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.5384615384615384,
                "threshold": 0
            },
            "answers": {
                "MDQ-1a": {
                    "questionId": "MDQ-1a",
                    "chosenOptionIds": [
                        "8d74da66-c9ae-44e9-9d43-1f49f1cbfedf"
                    ]
                },
                "MDQ-1b": {
                    "questionId": "MDQ-1b",
                    "chosenOptionIds": [
                        "3f398b71-0882-4106-a0b3-d9f821d64352"
                    ]
                },
                "MDQ-1c": {
                    "questionId": "MDQ-1c",
                    "chosenOptionIds": [
                        "805a2d00-cf87-4b4d-8e83-3c5f906c2bc1"
                    ]
                },
                "MDQ-1d": {
                    "questionId": "MDQ-1d",
                    "chosenOptionIds": [
                        "50bb0db8-10ef-4eb9-8416-5ec3ecd03514"
                    ]
                },
                "MDQ-1e": {
                    "questionId": "MDQ-1e",
                    "chosenOptionIds": [
                        "4d330482-c40b-476f-869f-ed63a2422983"
                    ]
                },
                "MDQ-1f": {
                    "questionId": "MDQ-1f",
                    "chosenOptionIds": [
                        "999b098e-3362-4d39-8df7-addbc762e841"
                    ]
                },
                "MDQ-1g": {
                    "questionId": "MDQ-1g",
                    "chosenOptionIds": [
                        "171fb757-37fe-46d7-9826-06102075a1c5"
                    ]
                },
                "MDQ-1h": {
                    "questionId": "MDQ-1h",
                    "chosenOptionIds": [
                        "d80e0143-38bf-43a2-b0e9-1e0007f10a1e"
                    ]
                },
                "MDQ-1i": {
                    "questionId": "MDQ-1i",
                    "chosenOptionIds": [
                        "c2b62967-e25a-455a-a267-caa237ee9f2c"
                    ]
                },
                "MDQ-1j": {
                    "questionId": "MDQ-1j",
                    "chosenOptionIds": [
                        "84b06f04-3506-4514-94b3-60806ae45be8"
                    ]
                },
                "MDQ-1k": {
                    "questionId": "MDQ-1k",
                    "chosenOptionIds": [
                        "bce6ba65-777f-4150-977d-25f9fcdcc97f"
                    ]
                },
                "MDQ-1l": {
                    "questionId": "MDQ-1l",
                    "chosenOptionIds": [
                        "b611dc5b-a7a4-4642-a36b-b47729b55143"
                    ]
                },
                "MDQ-1m": {
                    "questionId": "MDQ-1m",
                    "chosenOptionIds": [
                        "b7b9167a-f75b-4077-bbc1-16280ec8697f"
                    ]
                },
                "MDQ-2": {
                    "questionId": "MDQ-2",
                    "chosenOptionIds": [
                        "aea62f9d-eff9-4d12-a699-5cbc55cf868b"
                    ]
                },
                "MDQ-3": {
                    "questionId": "MDQ-3",
                    "chosenOptionIds": [
                        "4fff7d37-c62b-47a0-a4c7-8e65c0e6d4d9"
                    ]
                },
                "MDQ-4": {
                    "questionId": "MDQ-4",
                    "chosenOptionIds": [
                        "fd20ebe2-30f6-465f-87ee-c42cc75b8b7d"
                    ]
                },
                "MDQ-5": {
                    "questionId": "MDQ-5",
                    "chosenOptionIds": [
                        "27833f0e-c25e-4431-8696-cab6ee44960b"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {};
