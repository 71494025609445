import { Button, Heading, Page, Text } from '@psyomics/components'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from 'ui/app/components/Header'
import { TypeformEmbed, TypeformType } from 'ui/app/pages/CheckIn/TypeformEmbed'
import { useDemoContext } from './DemoContext'
import HeaderImage from '../../../ui/app/images/nhs-assessment-complete.svg?react'

const DemoEndScreen: React.FC = () => {
    const navigate = useNavigate()
    const [showFeedback, setShowFeedback] = useState<boolean>(false)
    const { demoFrom } = useDemoContext()

    return (
        <Page
            isInsideMobileFrame={demoFrom === 'start'}
            layout="standard"
            width="standard"
            header={<Header patientFacing={true} />}
        >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ marginBottom: 'var(--s-6)' }}>
                    <HeaderImage height={'auto'} width={'300px'} />
                </div>

                <Heading el="h2" size="heading2" color="mid" css={{ mt: 6 }}>
                    Thank you for trying the Psyomics Platform!
                </Heading>
                <Text css={{ mt: 3, mb: 4 }}>Let us know what you think</Text>
                {!showFeedback && (
                    <div>
                        <Button
                            className="text-right"
                            appearance="primary"
                            size="large"
                            label="Give Feedback"
                            onClick={() => {
                                setShowFeedback(true)
                            }}
                        />
                    </div>
                )}

                {showFeedback && <TypeformEmbed type={TypeformType.DEMO_FEEDBACK} contract={'demo'}></TypeformEmbed>}

                <div>
                    <Button
                        css={{ mt: 4 }}
                        className="text-right"
                        appearance="secondary"
                        size="large"
                        label="Start Again"
                        onClick={() => {
                            navigate(`/demo/service-user/checkIn${demoFrom ? `?${demoFrom}` : ''}`)
                        }}
                    />
                </div>
            </div>
        </Page>
    )
}

export default DemoEndScreen
