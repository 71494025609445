import { captureException } from '@sentry/react'
import { InfoContext } from 'configure'
import { useAuth } from '../../../registration/context/auth'
import React, { useState, useEffect, useContext, createContext } from 'react'
import { useQuery } from '@apollo/client'
import { UserProfileData, userProfileQuery } from 'data/queries/userProfile'
import { CognitoUserAttributes, OrganisationConfig, QueryUserProfileArgs } from 'censeo-core'
import { CognitoUser } from 'amazon-cognito-identity-js'

export const ReferralDetailsContext = createContext<ReferralDetails | null>(null)

export type ReferralDetails = {
    status: 'no-id' | 'error' | 'not-found' | 'success' | 'loading' | 'pending' | 'id-not-set-yet'
    data?: {
        organisationCode: string
        organisationConfig: Record<string, string | boolean>
        organisationName: string
        validLink: boolean
        hasUserId: boolean
        publicId: string
        organisationNameAbbr?: string
        status: string
    }
    activeReferral?: {
        organisationCode: string
        organisationName: string
        validLink: boolean
        hasUserId: boolean
        dbPatientUserNeedsOnboarding: boolean
        publicId: string
        organisationNameAbbr?: string
        status: string
        organisationConfig: OrganisationConfig
    }
}

interface ReferralDetailsProps {
    children: React.ReactNode
    referralPublicId?: string
    setReferralPublicId?: React.Dispatch<React.SetStateAction<string | undefined>>
    callback?: (referralDetails: ReferralDetails['data']) => void
}

export function ReferralDetailsContextProvider({
    children,
    referralPublicId,
    setReferralPublicId,
    callback,
}: ReferralDetailsProps): JSX.Element {
    const info = useContext(InfoContext)
    const [referralDetails, setReferralDetails] = useState<ReferralDetails>({ status: 'pending' })
    const { getCurrentUser, state } = useAuth()
    const [user, setUser] = useState<(CognitoUser & { attributes: CognitoUserAttributes }) | undefined>(undefined)

    const {
        data: userProfileData,
        error: userProfileError,
        loading: userProfileLoading,
    } = useQuery<UserProfileData, QueryUserProfileArgs>(userProfileQuery, {
        variables: { userId: user?.attributes?.sub || '' },
        skip: !user?.attributes?.sub,
    })

    useEffect(() => {
        ;(async () => {
            const currentUser = await getCurrentUser()
            if (currentUser) {
                setUser(currentUser)
            }
        })()
    }, [state.user?.id])

    useEffect(() => {
        ;(async () => {
            if (userProfileLoading) return

            if (userProfileError) {
                captureException(userProfileError)
            }

            // Try using referral id from PG if no referral id is provided
            const dbReferralPublicId = userProfileData?.userProfile.referralPublicId
            if (!referralPublicId && dbReferralPublicId) {
                referralPublicId = dbReferralPublicId
            }

            if (!referralPublicId) {
                // If referralPublicId is not yet set but might come later, set 'id-not-set-yet'
                setReferralDetails({ status: 'id-not-set-yet' })
                return
            }

            if (referralPublicId) {
                setReferralDetails({ status: 'loading' })

                try {
                    const orgUrl = `${info?.referralApiUrl}referrals/unauthenticated?publicId=${referralPublicId}`
                    const openedLinkUrl = `${orgUrl}&action=openedLink`
                    const activeReferralUrl = `${
                        info?.referralApiUrl
                    }referrals/unauthenticated?activeReferral=${referralPublicId}${
                        user?.attributes?.sub ? `&authUserId=${user.attributes.sub}` : ''
                    }`

                    const [orgResponse, openedLinkResponse, activeReferralResponse] = await Promise.all([
                        retryFetch(orgUrl), // Get org
                        retryFetch(openedLinkUrl, { method: 'POST' }), // Open link
                        retryFetch(activeReferralUrl), // Get active referral details
                    ])

                    if (!orgResponse || !openedLinkResponse) return

                    if (orgResponse.status === 404) {
                        setReferralDetails({ status: 'not-found' })
                        captureException('404 not found - for publicId: ' + referralPublicId)
                        return
                    }
                    if (!orgResponse.ok) {
                        throw new Error(`HTTP error! Status: ${orgResponse.status} - ${orgResponse.statusText}`)
                    }

                    if (!openedLinkResponse.ok) {
                        captureException(
                            `Error in openedLink response from referrals API status:${openedLinkResponse.status}`,
                            { extra: { url: openedLinkUrl, response: await openedLinkResponse.text() } }
                        )
                    } else if (openedLinkResponse.status === 202) {
                        console.warn(
                            `Conflict in openedLink response from referrals API status:${openedLinkResponse.status}`,
                            activeReferralUrl,
                            await openedLinkResponse.text()
                        )
                    }

                    if (!activeReferralResponse.ok) {
                        captureException(
                            `Error in activeReferral response from referrals API status:${activeReferralResponse.status}`,
                            { extra: { url: activeReferralUrl, response: await activeReferralResponse.text() } }
                        )
                    }

                    const data = (await orgResponse.json()) as ReferralDetails['data']
                    if (callback) {
                        callback(data)
                    }
                    const activeReferral = (await activeReferralResponse.json()) as ReferralDetails['activeReferral']
                    setReferralDetails({ status: 'success', data, activeReferral })
                } catch (error) {
                    if (error instanceof Error && error.name !== 'AbortError') {
                        captureException(error.message)
                    } else {
                        captureException(String(error))
                    }
                    setReferralDetails({ status: 'error' })
                }
            } else if (referralDetails.status === 'pending') {
                setReferralDetails({ status: 'no-id' })
            }
        })()
    }, [
        referralPublicId,
        setReferralPublicId,
        info?.referralApiUrl,
        user,
        state.user,
        callback,
        userProfileData,
        userProfileLoading,
    ])

    return <ReferralDetailsContext.Provider value={referralDetails}>{children}</ReferralDetailsContext.Provider>
}

const retryFetch = async (url: string, options?: RequestInit, retries = 3, delay = 1000): Promise<Response> => {
    try {
        const response = await fetch(url, { ...options })
        return response
    } catch (error) {
        if (error instanceof Error && error.name === 'AbortError' && retries > 0) {
            await new Promise((resolve) => setTimeout(resolve, delay))
            return retryFetch(url, options, retries - 1, delay)
        } else {
            throw error
        }
    }
}
