import React, { useState, useContext, useEffect } from 'react'
import { Page, Button, Heading, ProgressDots, Logo, Text, Link } from '@psyomics/components'
import HeaderImage from '../../images/service-signup-header.svg?react'
import { useNavigate } from 'react-router-dom'
import Styles from './D2COnboarding.module.scss'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import { useRegistration } from 'registration/context/registration/useRegistration'
import { UserType } from 'censeo-core'

export const D2COnboarding: React.FC = () => {
    const landingPagesArray = [
        D2COnboardingContent1,
        D2COnboardingContent2,
        D2COnboardingContent3,
        D2COnboardingContent4,
    ]

    const { startD2C } = useRegistration()
    const mixpanelApiUrl = useContext(MixpanelContext)
    const { visitedCenseo } = useMixpanelEvent(mixpanelApiUrl)

    useEffect(() => {
        startD2C()
        visitedCenseo(undefined, undefined, UserType.D2C)
    }, [])

    const numLandingPages = landingPagesArray.length
    const [currentLandingPage, setLandingPage] = useState(0)
    const navigate = useNavigate()

    const clickNext = () => {
        if (currentLandingPage < numLandingPages - 1) {
            setLandingPage(currentLandingPage + 1)
        } else {
            navigate('/signup/confirmation')
        }
    }

    return (
        <Page
            className={Styles.page}
            layout="standard"
            width="narrow"
            header={
                <header className={Styles.headerWithIllustration}>
                    <div className={Styles.headerInner}>
                        <HeaderImage />
                        <div className={Styles.nav}>
                            <Logo height="30" width="100" />
                        </div>
                    </div>
                </header>
            }
        >
            {landingPagesArray[currentLandingPage] ? (
                React.createElement(landingPagesArray[currentLandingPage])
            ) : (
                <D2CError />
            )}
            <ProgressDots count={numLandingPages} current={currentLandingPage + 1} css={{ mt: 7, mb: 7 }} />
            <div className={Styles.nextBtnWrapper}>
                <Button
                    type="button"
                    appearance="primary"
                    fullWidth
                    size="large"
                    label="Next"
                    icon={null}
                    css={{ mb: 2 }}
                    onClick={() => clickNext()}
                />
            </div>
        </Page>
    )
}

const D2COnboardingContent1: React.FC = () => {
    return (
        <>
            <Heading el="h1" size="heading2" color="mid" css={{ mb: 3 }}>
                Hello, before we get started we wanted to welcome you to Psyomics Platform
            </Heading>
            <>
                <Text size="medium" css={{ mb: 5 }}>
                    An online self-assessment tool, designed to help you understand your own mental health better.
                </Text>
                <Text size="medium" css={{ mb: 5 }}>
                    Designed by expert mental health clinicians, with input from people with lived experience of mental
                    health symptoms, validated in real-life settings.
                </Text>
            </>
        </>
    )
}

const D2COnboardingContent2: React.FC = () => {
    return (
        <>
            <Heading el="h1" size="heading2" color="mid" css={{ mb: 3 }}>
                We&apos;ll ask you to complete six detailed question sessions
            </Heading>
            <>
                <Text size="medium" css={{ mb: 5 }}>
                    Each session takes around 5-10 minutes to finish.
                </Text>
                <Text size="medium" css={{ mb: 5 }}>
                    You can complete them all at once, or take as many breaks as needed - we will always have your
                    progress saved.
                </Text>
            </>
        </>
    )
}

const D2COnboardingContent3: React.FC = () => {
    return (
        <>
            <Heading el="h1" size="heading2" color="mid" css={{ mb: 3 }}>
                You&apos;ll receive a mental health report instantly after completion
            </Heading>
            <>
                <Text size="medium" css={{ mb: 5 }}>
                    This will contain a summary of your symptom profile summary based on your current mental health.
                </Text>
                <Text size="medium" css={{ mb: 5 }}>
                    The report is designed to help you or those around you achieve a better understanding of your mental
                    health and provide you with some information that may be helpful to you.
                </Text>

                <Text size="medium" css={{ mb: 5 }}>
                    This assessment costs a one off payment of £29.99, but if you&apos;re unhappy with the outcome you
                    can get your money back.
                </Text>
            </>
        </>
    )
}

const D2COnboardingContent4: React.FC = () => {
    return (
        <>
            <Heading el="h1" size="heading2" color="mid" css={{ mb: 3 }}>
                Have confidence in the outcome
            </Heading>
            <>
                <Text size="medium" css={{ mb: 5 }}>
                    Psyomics is a class 1 medical device registered under the MHRA.
                </Text>
                <Text size="medium" css={{ mb: 5 }}>
                    Your results are confidential. We have a commitment to your privacy, please see our full policy:{' '}
                    <Link rel="noreferrer" target="_blank" href="https://www.psyomics.com/privacy-policy">
                        Privacy Policy
                    </Link>
                </Text>
                <Text size="medium" css={{ mb: 5 }}>
                    Please note, the results of this assessment are not intended to be diagnostic.
                </Text>
            </>
        </>
    )
}

const D2CError: React.FC = () => {
    return (
        <>
            <Heading el="h1" size="heading2" color="mid" css={{ mb: 3 }}>
                Error!
            </Heading>
            <>
                <Text size="medium" css={{ mb: 5 }}>
                    Something wrong has happened.
                </Text>
                <Text size="medium" css={{ mb: 5 }}>
                    <Link href="/self-assessment">Click here to go back to the self-assessment homepage.</Link>
                </Text>
                <Text size="medium" css={{ mb: 5 }}>
                    If the issue persists email us at:{' '}
                    <Link href="mailto:support@psyomics.com">support@psyomics.com</Link>
                </Text>
            </>
        </>
    )
}
