"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "{}",
            "functionId": "mdqSeverity"
        },
        "section": []
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Has there ever been a period of time when you were not your usual self and you felt so good or so hyper that other people thought you were not your normal self or you were so hyper that you got into trouble? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "MDQ-1a",
                    "optionSummaries": {
                        "125ae7a4-99d8-481a-91fa-85b19baf9f15": "0 - No",
                        "8d74da66-c9ae-44e9-9d43-1f49f1cbfedf": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions and answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Has there ever been a period of time when you were not your usual self and you were so irritable that you shouted at people or started fights or arguments? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "MDQ-1b",
                    "optionSummaries": {
                        "848b7b1c-3ecf-4e68-a7fa-3c95a39812d1": "0 - No",
                        "3f398b71-0882-4106-a0b3-d9f821d64352": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions and answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Has there ever been a period of time when you were not your usual self and you felt much more self-confident than usual? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "MDQ-1c",
                    "optionSummaries": {
                        "805a2d00-cf87-4b4d-8e83-3c5f906c2bc1": "0 - No",
                        "edef13fe-172d-48b0-8563-f1747916b2ed": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions and answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Has there ever been a period of time when you were not your usual self and you got much less sleep than usual and found you didn't really miss it? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "MDQ-1d",
                    "optionSummaries": {
                        "b2f419ff-4ddd-44bf-b4d7-2de648ad4f33": "0 - No",
                        "50bb0db8-10ef-4eb9-8416-5ec3ecd03514": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions and answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Has there ever been a period of time when you were not your usual self and you were much more talkative or spoke faster than usual? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "MDQ-1e",
                    "optionSummaries": {
                        "55758e6a-0090-409c-9827-ccfd79a5fa43": "0 - No",
                        "4d330482-c40b-476f-869f-ed63a2422983": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions and answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Has there ever been a period of time when you were not your usual self and thoughts raced through your head or you couldn't slow your mind down? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "MDQ-1f",
                    "optionSummaries": {
                        "19dd7f9a-fef9-4f7e-bbf4-ab41b8478014": "0 - No",
                        "999b098e-3362-4d39-8df7-addbc762e841": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions and answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Has there ever been a period of time when you were not your usual self and you were so easily distracted by things around you that you had trouble concentrating or staying on track? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "MDQ-1g",
                    "optionSummaries": {
                        "171fb757-37fe-46d7-9826-06102075a1c5": "0 - No",
                        "be776d60-1419-429d-bfc4-96a9b4805be9": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions and answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Has there ever been a period of time when you were not your usual self and you had much more energy than usual? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "MDQ-1h",
                    "optionSummaries": {
                        "303dec0f-4ca4-403e-bac1-63f59af29b84": "0 - No",
                        "d80e0143-38bf-43a2-b0e9-1e0007f10a1e": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions and answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Has there ever been a period of time when you were not your usual self and you were much more active or did many more things than usual? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "MDQ-1i",
                    "optionSummaries": {
                        "c2b62967-e25a-455a-a267-caa237ee9f2c": "0 - No",
                        "13c5b694-8d3d-4006-8905-53d79c9e59b8": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions and answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Has there ever been a period of time when you were not your usual self and you were much more social or outgoing than usual, for example, you telephoned friends in the middle of the night? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "MDQ-1j",
                    "optionSummaries": {
                        "a1ab5328-0b54-48b1-997f-6e692bc96f23": "0 - No",
                        "84b06f04-3506-4514-94b3-60806ae45be8": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions and answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Has there ever been a period of time when you were not your usual self and you were much more interested in sex than usual? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "MDQ-1k",
                    "optionSummaries": {
                        "bce6ba65-777f-4150-977d-25f9fcdcc97f": "0 - No",
                        "0ff785cc-698a-45ff-9b2c-d20a60144df1": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions and answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Has there ever been a period of time when you were not your usual self and you did things that were unusual for you or that other people might have thought were excessive, foolish, or risky? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "MDQ-1l",
                    "optionSummaries": {
                        "b611dc5b-a7a4-4642-a36b-b47729b55143": "0 - No",
                        "4a03a0e0-9a65-4a43-b87a-3daadb75895f": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions and answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Has there ever been a period of time when you were not your usual self and spending money got you or your family in trouble? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "MDQ-1m",
                    "optionSummaries": {
                        "b7b9167a-f75b-4077-bbc1-16280ec8697f": "0 - No",
                        "8e770ff6-ccf4-4a6c-8723-4291094916c3": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions and answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "If you answered YES to more than one of the above, have several of these ever happened during the same period of time? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "MDQ-2",
                    "optionSummaries": {
                        "aea62f9d-eff9-4d12-a699-5cbc55cf868b": "0 - No",
                        "257a3148-f813-4e91-979a-5b6c41438ec3": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions and answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Of those issues, how much of a problem did any of these cause you — like being able to work having family, money, or legal troubles; getting into arguments or fights? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "MDQ-3",
                    "optionSummaries": {
                        "5f0d5c27-81be-40ea-b98e-b7682a5acda8": "0 - No problem",
                        "157261c4-bcd6-46a3-914c-283b1e0551ac": "0 - Minor problem",
                        "4fff7d37-c62b-47a0-a4c7-8e65c0e6d4d9": "1 - Moderate problem",
                        "81911c8e-9b08-4dd2-af78-9f3bef81ccf6": "1 - Serious problem"
                    }
                }
            ]
        },
        "section": [
            "Questions and answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Have any of your blood relatives (ie, children, siblings, parents, grandparents, aunts, uncles) had manic-depressive illness or bipolar disordere? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "MDQ-4",
                    "optionSummaries": {
                        "165ff317-0925-451d-850c-b1e4430c0ea1": "No (Non-scored question)",
                        "fd20ebe2-30f6-465f-87ee-c42cc75b8b7d": "Yes (Non-scored question)"
                    }
                }
            ]
        },
        "section": [
            "Questions and answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Has a health professional ever told you that you have manic-depressive illness or bipolar disorder? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "MDQ-5",
                    "optionSummaries": {
                        "7b1022aa-5a2a-4646-830a-0e41285f72a0": "No (Non-scored question)",
                        "27833f0e-c25e-4431-8696-cab6ee44960b": "Yes (Non-scored question)"
                    }
                }
            ]
        },
        "section": [
            "Questions and answers"
        ]
    }
];
