"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validAnswerForQuestion = exports.filterValidAnswers = void 0;
const questions_1 = require("./questions");
const sessionConfigs_1 = require("./sessionConfigs");
const filterValidAnswers = (answers, questions, aspects, questionPredicates, userId) => {
    const filteredOutAnswers = [];
    const filteredAnswers = answers.filter((answer) => {
        const isInvalidated = answer.invalidated === true;
        const question = questions.find((q) => q.id === answer.questionId);
        const isAnswerRequired = question && (0, questions_1.isRequired)(aspects, question, questionPredicates, answers);
        if (isInvalidated || !isAnswerRequired) {
            filteredOutAnswers.push(answer);
            return false;
        }
        return true;
    });
    if (userId) {
        const message = filteredOutAnswers.length > 0
            ? `Filtered out answers for user ${userId}: ${JSON.stringify(filteredOutAnswers)}`
            : `No answers were filtered out for user ${userId}`;
        console[filteredOutAnswers.length > 0 ? 'warn' : 'info'](message);
    }
    return filteredAnswers;
};
exports.filterValidAnswers = filterValidAnswers;
function validAnswerForQuestion(answer) {
    for (const conditionQuestions of Object.values(sessionConfigs_1.questions)) {
        for (const question of conditionQuestions) {
            if (question.id === answer.questionId) {
                if ((question.__typename === 'ContentQuestion' || question.__typename === 'RestrictedChoiceQuestion') &&
                    answer.chosenOptionIds &&
                    question.answers.some((a) => { var _a; return (_a = answer.chosenOptionIds) === null || _a === void 0 ? void 0 : _a.includes(a.id); })) {
                    return true;
                }
                if (question.__typename === 'FreeQuestion' && answer.freeValue !== undefined) {
                    return true;
                }
                if (question.__typename === 'FreeTextQuestion' && answer.freeText !== undefined) {
                    return true;
                }
                return false;
            }
        }
    }
    return false;
}
exports.validAnswerForQuestion = validAnswerForQuestion;
