"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
const censeo_1 = require("../../schema/generated/censeo");
exports.questions = [
    {
        "__typename": "FreeTextQuestion",
        "id": "DemR",
        "text": "What has led you to seek support for your mental health? You can also share any goals or hopes for feeling better",
        "userType": censeo_1.UserType.Nhs,
        "mandatory": true
    }
];
exports.aspects = {};
exports.tests = {};
exports.questionPredicates = {};
