import React, { useContext, useState } from 'react'
import * as Sentry from '@sentry/react'
import { Page, Text, Heading, Button } from '@psyomics/components'
import Header from 'registration/components/Header'
import Styles from './SmsOptOut.module.scss'
import { InfoContext } from 'configure'
import { Widget } from '@typeform/embed-react'
import { useNavigate } from 'react-router-dom'

type SmsOptOutProps = React.ComponentPropsWithoutRef<'div'> & {
    referralPublicId: string
}

export const SmsOptOut: React.FC<SmsOptOutProps> = ({ referralPublicId }) => {
    const info = useContext(InfoContext)
    const navigate = useNavigate()
    const [userAction, setUserAction] = useState('')
    const [loading, setLoading] = useState(false)

    const handleUserAction = async (action: 'optOutSms' | 'declineAssessment' | 'completeAssessment') => {
        switch (action) {
            case 'completeAssessment':
                navigate(`/referral/${referralPublicId}`)
                break
            case 'optOutSms':
            case 'declineAssessment': {
                setLoading(true)

                try {
                    const actionUrl = `${info?.referralApiUrl}referrals/unauthenticated?publicId=${referralPublicId}&action=${action}`
                    const response = await fetch(actionUrl, { method: 'POST' })
                    if (response.ok) {
                        setUserAction(action)
                    } else {
                        Sentry.captureException(`Failed with status: ${response.status}`)
                        setUserAction('failed')
                    }
                } catch (error) {
                    Sentry.captureException(error, {
                        extra: { errorMessage: 'Failed to declineAssessment', referralPublicId },
                    })
                    setUserAction('failed')
                }

                setLoading(false)

                break
            }
            default:
                Sentry.captureException(`Unhandled action: ${action}`)
                setUserAction('failed')
        }
    }

    return (
        <Page>
            <Header template="landing" />
            <div className={Styles.container}>
                {userAction === '' && (
                    <>
                        <div className={Styles.smsOptOutButtonWrapper}>
                            <Text css={{ mt: 6 }}>
                                <span style={{ fontWeight: 'var(--f-weight-demibold)' }}>
                                    If you would like to start or continue with your assessment, please use the button
                                    below.
                                </span>
                            </Text>
                            <Button
                                css={{ mt: 4, mb: 8 }}
                                appearance="secondary"
                                size="large"
                                label="Start or continue assessment"
                                loading={loading}
                                onClick={() => handleUserAction('completeAssessment')}
                            />
                            <Text>
                                <span style={{ fontWeight: 'var(--f-weight-demibold)' }}>
                                    We&#39;ll send you up to three reminders in total. If you would like to complete the
                                    assessment but not receive any further text reminders, please use this button.
                                </span>
                            </Text>
                            <Button
                                css={{ mt: 4, mb: 8 }}
                                appearance="primary"
                                size="large"
                                label="SMS opt-out"
                                loading={loading}
                                onClick={() => handleUserAction('optOutSms')}
                            />
                            <Text>
                                <span style={{ fontWeight: 'var(--f-weight-demibold)' }}>
                                    If you don’t want to complete the Psyomics Platform mental health assessment, please
                                    click below. If you change your mind, you can still complete the assessment, but we
                                    wont remind you.
                                </span>
                            </Text>
                            <Button
                                css={{ mt: 4, mb: 8 }}
                                appearance="primary"
                                size="large"
                                label="Decline assessment"
                                loading={loading}
                                onClick={() => handleUserAction('declineAssessment')}
                            />
                        </div>
                    </>
                )}
                {userAction === 'optOutSms' && (
                    <>
                        <Heading css={{ mt: 4, mb: 6 }} size="heading2" color="mid" role="heading">
                            Thanks for confirming, you have successfully unsubscribed from future SMS reminders.
                        </Heading>
                        <Widget id="QUVxUccg" className={Styles.typeformFeedback}></Widget>
                    </>
                )}
                {userAction === 'declineAssessment' && (
                    <>
                        <Heading css={{ mt: 4, mb: 6 }} size="heading2" color="mid" role="heading">
                            Thanks for confirming, you have chosen not to participate in the Psyomics Platform mental
                            health assessment.
                        </Heading>
                        <Widget id="Th8tf9gX" className={Styles.typeformFeedback}></Widget>
                    </>
                )}
                {userAction === 'failed' && (
                    <>
                        <Heading css={{ mt: 4, mb: 6 }} size="heading2" color="mid" role="heading">
                            Something went wrong
                        </Heading>
                        <Text css={{ mt: 4, mb: 4 }}>
                            Please try again later. If the problem persists, please contact us on{' '}
                            <a href="mailto:support@psyomics.com">support@psyomics.com</a>.
                        </Text>
                        <Button
                            css={{ mt: 6 }}
                            appearance="primary"
                            size="large"
                            label="Try again"
                            onClick={() => setUserAction('')}
                        />
                    </>
                )}
            </div>
        </Page>
    )
}
