"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
exports.questions = [
    {
        "__typename": "ContentQuestion",
        "id": "DemoHealthIT1-DIALOG-content-1",
        "text": "Welcome. We'd like to understand how different areas of your life are affecting you. \n\nWe'll start with some straightforward questions about your day-to-day life. \n\nThere are no right or wrong answers - we just want to understand your experience. \n\nSelect 'Continue' to start.",
        "answers": [
            {
                "__typename": "Option",
                "id": "b470d55a-d079-40a6-ad89-34d412ee304b",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "455c0832-57a5-4442-b50f-c8447c47aa56",
                "text": "Totally dissatisfied",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b1d8cf0f-6da0-477a-9c17-579f5588e9ea",
                "text": "Very dissatisfied",
                "value": 0.1666666666666666
            },
            {
                "__typename": "Option",
                "id": "4e4557cd-4bef-4435-a7f9-bfefb7387fd9",
                "text": "Fairly dissatisfied",
                "value": 0.3333333333333333
            },
            {
                "__typename": "Option",
                "id": "0dfa0d7b-fb6b-446f-82ad-494008732f36",
                "text": "In the middle",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "b76e7451-1cfd-4095-9b37-b3b48bc6a30c",
                "text": "Fairly satisfied",
                "value": 0.6666666666666666
            },
            {
                "__typename": "Option",
                "id": "f3fd8fb4-6df7-4e00-997d-aa23e6f0c0ce",
                "text": "Very satisfied",
                "value": 0.8333333333333333
            },
            {
                "__typename": "Option",
                "id": "1b5d18c6-8b94-4370-b5e1-f7e214add043",
                "text": "Totally satisfied",
                "value": 1
            }
        ],
        "id": "DemoHealthIT1-DIALOG-1",
        "text": "How satisfied are you with your mental health?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "57e2b984-6c51-4b62-9baa-33547aafe827",
                "text": "I don't have anyone to talk to",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "06bbb95f-80ce-46b7-a93e-ce8b8002c61f",
                "text": "Yes, but I can't contact them",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "afb9a46d-80a1-4cab-b118-c4fd0dbf7153",
                "text": "Yes, and I could talk to them",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "44f2f6d8-896b-4fe0-9d7a-9f1d6babe9ae",
                "text": "Yes, and I have talked to them",
                "value": 1
            }
        ],
        "id": "DemoHealthIT1-PCV2-3",
        "text": "Do you have someone you can talk to about your mental health concerns?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "00f8401f-796d-5669-9bbd-cb3f489c028f",
                "text": "Employed",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ba95321c-b9fd-5fc2-a311-e798bc9bae81",
                "text": "Self-employed",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "006dd4de-b660-5a59-b3b5-b0f42a797ce7",
                "text": "Parental leave or taking time off work to care for a family member",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "e4cca6e0-dfed-4b54-9e58-edfa5360e868",
                "text": "Student",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "b45cab06-48fa-4922-b159-ee3e777f639b",
                "text": "Retired",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "adbdec13-aefa-4e72-be6e-44afdedfffda",
                "text": "Unemployed",
                "value": 1
            }
        ],
        "id": "DemoHealthIT1-PCV2-6",
        "text": "What is your current employment situation?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "7c4d8c03-efa7-4245-a8e3-f96c3787e6a7",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "8de40a81-7e0e-49f5-b7a8-3be7a87c4435",
                "text": "Yes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "69c95be4-b522-48da-965c-c2ec297b461d",
                "text": "I am not sure",
                "value": 1
            }
        ],
        "id": "DemoHealthIT1-PCV2-7",
        "text": "Do you feel that your current employment situation is contributing to any of your mental health concerns?",
        "isMultiselect": false
    }
];
exports.aspects = {};
exports.tests = {};
exports.questionPredicates = {};
