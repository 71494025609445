"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAnswerValue = void 0;
const getAnswerValue = (surveyQuestions, answer, normalizationFactor, offset = 0) => {
    const correspondingQuestion = surveyQuestions.find((question) => question.id === answer.questionId);
    if (!correspondingQuestion)
        throw new Error(`No question found for: ` + answer.questionId);
    if (!('answers' in correspondingQuestion))
        throw new Error('not a RestrictedChoiceQuestion: ' + correspondingQuestion.id);
    const correspondingAnswer = correspondingQuestion.answers.find((qAnswer) => { var _a; return (_a = answer.chosenOptionIds) === null || _a === void 0 ? void 0 : _a.includes(qAnswer.id); });
    if (!correspondingAnswer) {
        throw new Error(`No answers found in question answers for: ` + JSON.stringify(answer.chosenOptionIds));
    }
    if (null === correspondingAnswer.value || undefined === correspondingAnswer.value)
        throw new Error(`No answer value found in question answers for: ` + JSON.stringify(answer.chosenOptionIds));
    return Math.round(correspondingAnswer.value * normalizationFactor) + offset;
};
exports.getAnswerValue = getAnswerValue;
