import { Option } from 'censeo-core'
import React, { useState } from 'react'
import QuestionPage from 'ui/app/components/QuestionPage'
import { Markdown, Button } from '@psyomics/components'

interface Props {
    options: Option[]
    onSelect: (optionIds: string[]) => void
    onBack: () => void
    question: string
    updating?: boolean
}

export const ContentQuestion: React.FC<Props> = ({ question, options, onSelect, onBack, updating = false }: Props) => {
    const [lastClicked, setLastClicked] = useState<string | null>(null)
    return (
        <QuestionPage okVisible={false} backDisabled={updating} updating={updating} onBack={onBack}>
            <Markdown color="mid" css={{ mb: 4 }}>
                {question}
            </Markdown>
            {options.map((option, i) => (
                <Button
                    css={{ mt: 2 }}
                    appearance={option.primary ? 'primary' : 'secondary'}
                    size="large"
                    label={option.text}
                    disabled={updating}
                    onClick={() => {
                        setLastClicked(option.id)
                        onSelect([option.id])
                    }}
                    key={option.id}
                    loading={updating && lastClicked === option.id}
                    fullWidth={true}
                />
            ))}
        </QuestionPage>
    )
}
