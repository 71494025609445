import { Button, Text, Heading } from '@psyomics/components'
import React, { useState } from 'react'
import { QRCodeSVG } from 'qrcode.react'
import { toAbsolutePath } from 'hostOverride'
import Styles from './DemoQr.module.scss'
import { useDemoContext } from '../DemoContext'

const DemoQr: React.FC = () => {
    const [show, setShow] = useState<boolean>(true)
    const { demoType } = useDemoContext()

    const url = toAbsolutePath(`/demo/service-user/checkIn?qrstart&type=${demoType}`)

    return (
        <>
            {show && (
                <div className={Styles.container}>
                    <Heading size="heading2" css={{ mb: 5 }}>
                        Welcome to the Psyomics Platform service user demo{' '}
                    </Heading>
                    <Text css={{ mb: 5 }}>
                        This is an example of the Psyomics Platform experience for service users. The questions shown
                        are only a small selection from the clinical questionnaire, shown as three short sessions.
                    </Text>
                    <Text css={{ mb: 5 }}>
                        Would you like to try it on your phone?<br></br>Scan the QR code.
                    </Text>
                    <QRCodeSVG value={url} size={135} level="H" marginSize={4} />
                    <div>
                        <Button appearance="primary" label="Got it!" onClick={() => setShow(false)}></Button>
                    </div>
                </div>
            )}
        </>
    )
}

export default DemoQr
