"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
exports.questions = [
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ba52aa32-bab9-44e4-bb68-df2ae4d00134",
                "text": "False",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a6a7c248-8833-4170-a8b3-58f09a115fee",
                "text": "True",
                "value": 1
            }
        ],
        "id": "PQ16-1",
        "text": "I feel uninterested in the things I used to enjoy.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "696e9d1a-a60c-4851-bb9e-f3329e858737",
                "text": "None",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "185f6c77-363d-43f1-b34d-6c3ef5da144c",
                "text": "Mild",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "189a78e9-5ab6-4c35-845d-7ce85e139ee2",
                "text": "Moderate",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "ec2b4c1e-0bbf-41b8-9097-3f69a30b1740",
                "text": "Severe",
                "value": 1
            }
        ],
        "id": "PQ16-2",
        "text": "How much distress did you experience from feeling uninterested in the things you used to enjoy?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "c75fe2ce-2682-4661-8bac-da62181010d9",
                "text": "False",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "17bd8f73-0a62-43fd-9392-91c10b44ad22",
                "text": "True",
                "value": 1
            }
        ],
        "id": "PQ16-3",
        "text": "I often seem to live through events exactly as they happened before (déjà vu).",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "bc5b1d6b-32cd-4a75-8791-eee6a2717fd6",
                "text": "None",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d8dcabf9-c43c-4fd9-baea-402034912901",
                "text": "Mild",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "218e77be-ab1b-43fc-86c9-158c859db6b7",
                "text": "Moderate",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "4e2563c5-3fff-45e4-941b-55beb01fbea6",
                "text": "Severe",
                "value": 1
            }
        ],
        "id": "PQ16-4",
        "text": "How much distress did you experience from often seeming to live through events exactly as they happened before (déjà vu)?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "fa0609de-87b9-4d4f-8abb-82c8f7e44712",
                "text": "False",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b6223a9b-27c9-4962-a76e-4f4a9aa17a32",
                "text": "True",
                "value": 1
            }
        ],
        "id": "PQ16-5",
        "text": "I sometimes smell or taste things that other people can’t smell or taste.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "327384bd-0414-4f76-a6ed-c5934dd57f55",
                "text": "None",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "de66c6de-546d-4a98-9eb1-93fddd23852a",
                "text": "Mild",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "c12c2f68-6702-4b66-a669-1a7b4b45d065",
                "text": "Moderate",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "0c043cdc-1445-4571-a884-74c82288d6f8",
                "text": "Severe",
                "value": 1
            }
        ],
        "id": "PQ16-6",
        "text": "How much distress did you experience from sometimes smelling or tasting things that other people can’t smell or taste?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "feb32ba3-0629-424b-b86a-ba38c8dd48e3",
                "text": "False",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "53881799-b644-48f2-8230-6856af7da942",
                "text": "True",
                "value": 1
            }
        ],
        "id": "PQ16-7",
        "text": "I often hear unusual sounds like banging, clicking, hissing, clapping or ringing in my ears.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "0b0e31cc-62a4-4110-a1a8-646549fc7e35",
                "text": "None",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ed001067-819c-4ee2-b6dc-680372ae7042",
                "text": "Mild",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "f1f22530-8493-498e-ba08-0b27ae12049e",
                "text": "Moderate",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "9c20db13-356d-4c1f-a396-52297a58b253",
                "text": "Severe",
                "value": 1
            }
        ],
        "id": "PQ16-8",
        "text": "How much distress did you experience from often hearing unusual sounds like banging, clicking, hissing, clapping or ringing in your ears?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "c9d94315-a43c-4a7c-a5d9-ff9f9b6eeb09",
                "text": "False",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "69aed6d9-adb4-4f39-a236-a65532fca894",
                "text": "True",
                "value": 1
            }
        ],
        "id": "PQ16-9",
        "text": "I have been confused at times whether something I experienced was real or imaginary.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "dd86ab9d-d442-4a10-98df-4e5c155fcf6e",
                "text": "None",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "81453f5c-f3bc-4b11-bc99-2cd06c05e35f",
                "text": "Mild",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "038a091d-2f6a-4410-99bb-6930ecd8130a",
                "text": "Moderate",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "23b999d1-5157-4a43-8780-78eb670ca3de",
                "text": "Severe",
                "value": 1
            }
        ],
        "id": "PQ16-10",
        "text": "How much distress did you experience from being confused at times whether something you experienced was real or imaginary?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "cd8191c8-f42f-4a7a-bc15-2d67d7656e28",
                "text": "False",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "9b82f7a5-4eca-419e-afc7-402399e897ea",
                "text": "True",
                "value": 1
            }
        ],
        "id": "PQ16-11",
        "text": "When I look at a person, or look at myself in a mirror, I have seen the face change right before my eyes.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8c3edbdf-23ee-4b38-bb87-6f5be436feba",
                "text": "None",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "fef16de9-f80b-4aef-90a9-c9835247a62f",
                "text": "Mild",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "077a7b78-6ba5-4188-b7e8-172a7eff8f72",
                "text": "Moderate",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "788ff4a2-fb37-4655-9a9d-d0723f2f7c98",
                "text": "Severe",
                "value": 1
            }
        ],
        "id": "PQ16-12",
        "text": "How much distress did you experience from seeing the face change right before your eyes when looking at a person, or yourself in a mirror?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a7fcc554-4d77-4ff4-aa62-017618d3a9fe",
                "text": "False",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "4ec44f32-fffc-4edd-b02f-07f64f36f408",
                "text": "True",
                "value": 1
            }
        ],
        "id": "PQ16-13",
        "text": "I get extremely anxious when meeting people for the first time.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6307e69b-b5f4-4a9e-b259-539d4ccdc7c4",
                "text": "None",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c759757d-d3fc-4836-9f83-1a37dd41c4a3",
                "text": "Mild",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "06be6bb2-bf78-499d-af38-f0ea14fc85d0",
                "text": "Moderate",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "0028737a-ad2e-4882-b857-7551101baa8a",
                "text": "Severe",
                "value": 1
            }
        ],
        "id": "PQ16-14",
        "text": "How much distress did you experience from getting extremely anxious when meeting people for the first time?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "d05b6553-b6e2-4705-aa65-71babd98a0a9",
                "text": "False",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ea28fddf-d374-4c71-b85a-55e5224558c3",
                "text": "True",
                "value": 1
            }
        ],
        "id": "PQ16-15",
        "text": "I have seen things that other people apparently can’t see.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "491b6a42-d8dc-4234-9a31-17d4a8939e81",
                "text": "None",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7026f08a-3b9b-4e5f-8531-eecb02159330",
                "text": "Mild",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "d6fbd883-c2ea-4487-a7da-6e6748adc8ed",
                "text": "Moderate",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "f50f09dc-abc0-41cd-89df-be98c4122e91",
                "text": "Severe",
                "value": 1
            }
        ],
        "id": "PQ16-16",
        "text": "How much distress did you experience from having seen things that other people apparently can’t see?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "53a0d94b-1a1f-4222-a072-6d850c60b105",
                "text": "False",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "1c45ff8b-70d3-4f37-a33d-5e0274c98386",
                "text": "True",
                "value": 1
            }
        ],
        "id": "PQ16-17",
        "text": "My thoughts are sometimes so strong that I can almost hear them.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "69619c7c-d313-471b-8f28-a339104de38d",
                "text": "None",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "29bbf8dd-10e9-4ef7-a1c1-d5efabfb8757",
                "text": "Mild",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "7356ae7f-ae83-454f-844b-431361be2b0e",
                "text": "Moderate",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "2612f271-6494-4c7c-aa4f-1421cc78caed",
                "text": "Severe",
                "value": 1
            }
        ],
        "id": "PQ16-18",
        "text": "How much distress did you experience from sometimes having thoughts so strong that you can almost hear them?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "67077ce7-f5ce-4558-89a2-fe9e3c04e4d5",
                "text": "False",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "9d407539-8496-4f80-933c-88c3bd3f104b",
                "text": "True",
                "value": 1
            }
        ],
        "id": "PQ16-19",
        "text": "I sometimes see special meanings in advertisements, shop windows, or in the way things are arranged around me.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "1c0ee8bb-6d1b-41c4-8493-84b0c001371c",
                "text": "None",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "afcaa979-d187-4378-a4ac-473ac2397dc3",
                "text": "Mild",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "12b45527-b8d0-496e-b11e-6c6c19fe33ca",
                "text": "Moderate",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "4adacf6c-efff-4655-98af-6bb3810eee1e",
                "text": "Severe",
                "value": 1
            }
        ],
        "id": "PQ16-20",
        "text": "How much distress did you experience from sometimes seeing special meanings in advertisements, shop windows, or in the way things are arranged around you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b0bf0d54-a05f-4f92-9287-f5f4aba9bb5d",
                "text": "False",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "bfe24eb1-bcc9-4f24-85dc-db6cd7fc0147",
                "text": "True",
                "value": 1
            }
        ],
        "id": "PQ16-21",
        "text": "Sometimes I have felt that I’m not in control of my own ideas or thoughts.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6f291fb4-b194-43d1-be75-759046df94ea",
                "text": "None",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "393c43b6-d9c6-403c-8913-044d33bb5365",
                "text": "Mild",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "3891120f-9ff8-496d-854c-73ae102bee79",
                "text": "Moderate",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "5e025a3d-9508-491c-8427-73f56f3ae779",
                "text": "Severe",
                "value": 1
            }
        ],
        "id": "PQ16-22",
        "text": "How much distress did you experience from sometimes feeling that you’re not in control of your own ideas or thoughts?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a51d638d-7cd9-4620-964c-7d2cee3c625b",
                "text": "False",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b610511c-a527-4a8d-9364-40841f56d699",
                "text": "True",
                "value": 1
            }
        ],
        "id": "PQ16-23",
        "text": "Sometimes I feel suddenly distracted by distant sounds that I am not normally aware of.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "07e2486c-4bb6-4989-a671-8b155017bbd5",
                "text": "None",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "6a8abf03-05bc-4329-ae4d-f188231ccf61",
                "text": "Mild",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "0ff4b3c4-2a4b-4b0e-91f6-2234f83d26e3",
                "text": "Moderate",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "7eb004cc-e5ec-404b-b8e4-7e18bee03a59",
                "text": "Severe",
                "value": 1
            }
        ],
        "id": "PQ16-24",
        "text": "How much distress did you experience from sometimes feeling suddenly distracted by distant sounds that you are not normally aware of?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "77230379-e298-438f-8b51-7ace302d1e11",
                "text": "False",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "6729fd97-effb-492e-b4cd-171cff5bc861",
                "text": "True",
                "value": 1
            }
        ],
        "id": "PQ16-25",
        "text": "I have heard things other people can’t hear like voices of people whispering or talking.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ca00b666-462f-4e0c-b4da-e592a8334f2c",
                "text": "None",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "675e8f0c-a50b-4f9c-bba8-6485e1f0f37d",
                "text": "Mild",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "7f44f7f7-6c2e-42ba-87c0-54d14bb2836e",
                "text": "Moderate",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "1ed22c05-4ec0-493d-9bbf-b65457a15aee",
                "text": "Severe",
                "value": 1
            }
        ],
        "id": "PQ16-26",
        "text": "How much distress did you experience from having heard things other people can’t hear like voices of people whispering or talking?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a97455ae-190b-44f6-ad18-fc21530122a7",
                "text": "False",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c1dc1d47-c09a-4893-b938-de4a89ec3752",
                "text": "True",
                "value": 1
            }
        ],
        "id": "PQ16-27",
        "text": "I often feel that others have it in for me.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "0eecdacf-2a78-4b35-8dac-28f8bbd0676c",
                "text": "None",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "15c6a5e8-d4b6-4b60-8b8f-59eddb2c8bdb",
                "text": "Mild",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "d113628c-cc0f-4dff-aea9-a36e883439e5",
                "text": "Moderate",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "85d21bf7-7f44-4b5d-8612-067ed1b451ab",
                "text": "Severe",
                "value": 1
            }
        ],
        "id": "PQ16-28",
        "text": "How much distress did you experience from often feeling that others have it in for you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "55d4fc44-9580-4527-86ba-a94a3c15190f",
                "text": "False",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "27ddef39-6320-49e6-8c2f-d3c9e48ffeb9",
                "text": "True",
                "value": 1
            }
        ],
        "id": "PQ16-29",
        "text": "I have had the sense that some person or force is around me, even though I could not see anyone.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8b264b5d-08b1-47ef-a86b-fb54ee62d97b",
                "text": "None",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "403c25b5-447b-43a6-8a09-05ce3bacbb12",
                "text": "Mild",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "ed138599-3f27-4122-9bd0-77526c186a06",
                "text": "Moderate",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "186dfb77-c06e-4dbf-9763-970509f73f70",
                "text": "Severe",
                "value": 1
            }
        ],
        "id": "PQ16-30",
        "text": "How much distress did you experience from having had the sense that some person or force is around you, even though you could not see anyone?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8ad16b9e-f3c5-48f7-ac40-dd5c3d2bccec",
                "text": "False",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b9707ed2-5434-4ec6-8404-e9610aeec041",
                "text": "True",
                "value": 1
            }
        ],
        "id": "PQ16-31",
        "text": "I feel that parts of my body have changed in some way, or that parts of my body are working differently than before.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "419624b3-ec59-4621-b8c4-d0cf6f5ac604",
                "text": "None",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ec9cdb8a-2e6c-48be-ac0a-96132772b9ee",
                "text": "Mild",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "c5b7f89b-3bfa-46d6-9928-9df56e9b44b9",
                "text": "Moderate",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "8138f443-dd64-410c-ad5e-5af16ddda7e7",
                "text": "Severe",
                "value": 1
            }
        ],
        "id": "PQ16-32",
        "text": "How much distress did you experience from feeling that parts of your body have changed in some way, or that parts of your body are working differently than before?",
        "isMultiselect": false
    }
];
exports.aspects = {
    "pq16": {
        "type": "mean",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PQ16-1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "ba52aa32-bab9-44e4-bb68-df2ae4d00134": 0,
                            "a6a7c248-8833-4170-a8b3-58f09a115fee": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PQ16-3",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "c75fe2ce-2682-4661-8bac-da62181010d9": 0,
                            "17bd8f73-0a62-43fd-9392-91c10b44ad22": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PQ16-5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "fa0609de-87b9-4d4f-8abb-82c8f7e44712": 0,
                            "b6223a9b-27c9-4962-a76e-4f4a9aa17a32": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PQ16-7",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "feb32ba3-0629-424b-b86a-ba38c8dd48e3": 0,
                            "53881799-b644-48f2-8230-6856af7da942": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PQ16-9",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "c9d94315-a43c-4a7c-a5d9-ff9f9b6eeb09": 0,
                            "69aed6d9-adb4-4f39-a236-a65532fca894": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PQ16-11",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "cd8191c8-f42f-4a7a-bc15-2d67d7656e28": 0,
                            "9b82f7a5-4eca-419e-afc7-402399e897ea": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PQ16-13",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "a7fcc554-4d77-4ff4-aa62-017618d3a9fe": 0,
                            "4ec44f32-fffc-4edd-b02f-07f64f36f408": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PQ16-15",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "d05b6553-b6e2-4705-aa65-71babd98a0a9": 0,
                            "ea28fddf-d374-4c71-b85a-55e5224558c3": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PQ16-17",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "53a0d94b-1a1f-4222-a072-6d850c60b105": 0,
                            "1c45ff8b-70d3-4f37-a33d-5e0274c98386": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PQ16-19",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "67077ce7-f5ce-4558-89a2-fe9e3c04e4d5": 0,
                            "9d407539-8496-4f80-933c-88c3bd3f104b": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PQ16-21",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "b0bf0d54-a05f-4f92-9287-f5f4aba9bb5d": 0,
                            "bfe24eb1-bcc9-4f24-85dc-db6cd7fc0147": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PQ16-23",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "a51d638d-7cd9-4620-964c-7d2cee3c625b": 0,
                            "b610511c-a527-4a8d-9364-40841f56d699": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PQ16-25",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "77230379-e298-438f-8b51-7ace302d1e11": 0,
                            "6729fd97-effb-492e-b4cd-171cff5bc861": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PQ16-27",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "a97455ae-190b-44f6-ad18-fc21530122a7": 0,
                            "c1dc1d47-c09a-4893-b938-de4a89ec3752": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PQ16-29",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "55d4fc44-9580-4527-86ba-a94a3c15190f": 0,
                            "27ddef39-6320-49e6-8c2f-d3c9e48ffeb9": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PQ16-31",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "8ad16b9e-f3c5-48f7-ac40-dd5c3d2bccec": 0,
                            "b9707ed2-5434-4ec6-8404-e9610aeec041": 1
                        }
                    }
                }
            }
        ]
    },
    "not(PQ16-1=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "PQ16-1",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "ba52aa32-bab9-44e4-bb68-df2ae4d00134": true,
                        "a6a7c248-8833-4170-a8b3-58f09a115fee": false
                    }
                }
            }
        }
    },
    "not(PQ16-3=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "PQ16-3",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "c75fe2ce-2682-4661-8bac-da62181010d9": true,
                        "17bd8f73-0a62-43fd-9392-91c10b44ad22": false
                    }
                }
            }
        }
    },
    "not(PQ16-5=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "PQ16-5",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "fa0609de-87b9-4d4f-8abb-82c8f7e44712": true,
                        "b6223a9b-27c9-4962-a76e-4f4a9aa17a32": false
                    }
                }
            }
        }
    },
    "not(PQ16-7=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "PQ16-7",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "feb32ba3-0629-424b-b86a-ba38c8dd48e3": true,
                        "53881799-b644-48f2-8230-6856af7da942": false
                    }
                }
            }
        }
    },
    "not(PQ16-9=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "PQ16-9",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "c9d94315-a43c-4a7c-a5d9-ff9f9b6eeb09": true,
                        "69aed6d9-adb4-4f39-a236-a65532fca894": false
                    }
                }
            }
        }
    },
    "not(PQ16-11=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "PQ16-11",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "cd8191c8-f42f-4a7a-bc15-2d67d7656e28": true,
                        "9b82f7a5-4eca-419e-afc7-402399e897ea": false
                    }
                }
            }
        }
    },
    "not(PQ16-13=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "PQ16-13",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "a7fcc554-4d77-4ff4-aa62-017618d3a9fe": true,
                        "4ec44f32-fffc-4edd-b02f-07f64f36f408": false
                    }
                }
            }
        }
    },
    "not(PQ16-15=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "PQ16-15",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "d05b6553-b6e2-4705-aa65-71babd98a0a9": true,
                        "ea28fddf-d374-4c71-b85a-55e5224558c3": false
                    }
                }
            }
        }
    },
    "not(PQ16-17=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "PQ16-17",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "53a0d94b-1a1f-4222-a072-6d850c60b105": true,
                        "1c45ff8b-70d3-4f37-a33d-5e0274c98386": false
                    }
                }
            }
        }
    },
    "not(PQ16-19=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "PQ16-19",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "67077ce7-f5ce-4558-89a2-fe9e3c04e4d5": true,
                        "9d407539-8496-4f80-933c-88c3bd3f104b": false
                    }
                }
            }
        }
    },
    "not(PQ16-21=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "PQ16-21",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "b0bf0d54-a05f-4f92-9287-f5f4aba9bb5d": true,
                        "bfe24eb1-bcc9-4f24-85dc-db6cd7fc0147": false
                    }
                }
            }
        }
    },
    "not(PQ16-23=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "PQ16-23",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "a51d638d-7cd9-4620-964c-7d2cee3c625b": true,
                        "b610511c-a527-4a8d-9364-40841f56d699": false
                    }
                }
            }
        }
    },
    "not(PQ16-25=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "PQ16-25",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "77230379-e298-438f-8b51-7ace302d1e11": true,
                        "6729fd97-effb-492e-b4cd-171cff5bc861": false
                    }
                }
            }
        }
    },
    "not(PQ16-27=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "PQ16-27",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "a97455ae-190b-44f6-ad18-fc21530122a7": true,
                        "c1dc1d47-c09a-4893-b938-de4a89ec3752": false
                    }
                }
            }
        }
    },
    "not(PQ16-29=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "PQ16-29",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "55d4fc44-9580-4527-86ba-a94a3c15190f": true,
                        "27ddef39-6320-49e6-8c2f-d3c9e48ffeb9": false
                    }
                }
            }
        }
    },
    "not(PQ16-31=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "PQ16-31",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "8ad16b9e-f3c5-48f7-ac40-dd5c3d2bccec": true,
                        "b9707ed2-5434-4ec6-8404-e9610aeec041": false
                    }
                }
            }
        }
    }
};
exports.tests = {
    "pq16": [
        {
            "name": "PQ16 - score 0-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0,
                "threshold": 0
            },
            "answers": {
                "PQ16-1": {
                    "questionId": "PQ16-1",
                    "chosenOptionIds": [
                        "ba52aa32-bab9-44e4-bb68-df2ae4d00134"
                    ]
                },
                "PQ16-3": {
                    "questionId": "PQ16-3",
                    "chosenOptionIds": [
                        "c75fe2ce-2682-4661-8bac-da62181010d9"
                    ]
                },
                "PQ16-5": {
                    "questionId": "PQ16-5",
                    "chosenOptionIds": [
                        "fa0609de-87b9-4d4f-8abb-82c8f7e44712"
                    ]
                },
                "PQ16-7": {
                    "questionId": "PQ16-7",
                    "chosenOptionIds": [
                        "feb32ba3-0629-424b-b86a-ba38c8dd48e3"
                    ]
                },
                "PQ16-9": {
                    "questionId": "PQ16-9",
                    "chosenOptionIds": [
                        "c9d94315-a43c-4a7c-a5d9-ff9f9b6eeb09"
                    ]
                },
                "PQ16-11": {
                    "questionId": "PQ16-11",
                    "chosenOptionIds": [
                        "cd8191c8-f42f-4a7a-bc15-2d67d7656e28"
                    ]
                },
                "PQ16-13": {
                    "questionId": "PQ16-13",
                    "chosenOptionIds": [
                        "a7fcc554-4d77-4ff4-aa62-017618d3a9fe"
                    ]
                },
                "PQ16-15": {
                    "questionId": "PQ16-15",
                    "chosenOptionIds": [
                        "d05b6553-b6e2-4705-aa65-71babd98a0a9"
                    ]
                },
                "PQ16-17": {
                    "questionId": "PQ16-17",
                    "chosenOptionIds": [
                        "53a0d94b-1a1f-4222-a072-6d850c60b105"
                    ]
                },
                "PQ16-19": {
                    "questionId": "PQ16-19",
                    "chosenOptionIds": [
                        "67077ce7-f5ce-4558-89a2-fe9e3c04e4d5"
                    ]
                },
                "PQ16-21": {
                    "questionId": "PQ16-21",
                    "chosenOptionIds": [
                        "b0bf0d54-a05f-4f92-9287-f5f4aba9bb5d"
                    ]
                },
                "PQ16-23": {
                    "questionId": "PQ16-23",
                    "chosenOptionIds": [
                        "a51d638d-7cd9-4620-964c-7d2cee3c625b"
                    ]
                },
                "PQ16-25": {
                    "questionId": "PQ16-25",
                    "chosenOptionIds": [
                        "77230379-e298-438f-8b51-7ace302d1e11"
                    ]
                },
                "PQ16-27": {
                    "questionId": "PQ16-27",
                    "chosenOptionIds": [
                        "a97455ae-190b-44f6-ad18-fc21530122a7"
                    ]
                },
                "PQ16-29": {
                    "questionId": "PQ16-29",
                    "chosenOptionIds": [
                        "55d4fc44-9580-4527-86ba-a94a3c15190f"
                    ]
                },
                "PQ16-31": {
                    "questionId": "PQ16-31",
                    "chosenOptionIds": [
                        "8ad16b9e-f3c5-48f7-ac40-dd5c3d2bccec"
                    ]
                }
            }
        },
        {
            "name": "PQ16 - score 5 -> no ultra-high risk (UHR) for psychosis-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.3125,
                "threshold": 0
            },
            "answers": {
                "PQ16-1": {
                    "questionId": "PQ16-1",
                    "chosenOptionIds": [
                        "a6a7c248-8833-4170-a8b3-58f09a115fee"
                    ]
                },
                "PQ16-3": {
                    "questionId": "PQ16-3",
                    "chosenOptionIds": [
                        "17bd8f73-0a62-43fd-9392-91c10b44ad22"
                    ]
                },
                "PQ16-5": {
                    "questionId": "PQ16-5",
                    "chosenOptionIds": [
                        "b6223a9b-27c9-4962-a76e-4f4a9aa17a32"
                    ]
                },
                "PQ16-7": {
                    "questionId": "PQ16-7",
                    "chosenOptionIds": [
                        "53881799-b644-48f2-8230-6856af7da942"
                    ]
                },
                "PQ16-9": {
                    "questionId": "PQ16-9",
                    "chosenOptionIds": [
                        "69aed6d9-adb4-4f39-a236-a65532fca894"
                    ]
                },
                "PQ16-11": {
                    "questionId": "PQ16-11",
                    "chosenOptionIds": [
                        "cd8191c8-f42f-4a7a-bc15-2d67d7656e28"
                    ]
                },
                "PQ16-13": {
                    "questionId": "PQ16-13",
                    "chosenOptionIds": [
                        "a7fcc554-4d77-4ff4-aa62-017618d3a9fe"
                    ]
                },
                "PQ16-15": {
                    "questionId": "PQ16-15",
                    "chosenOptionIds": [
                        "d05b6553-b6e2-4705-aa65-71babd98a0a9"
                    ]
                },
                "PQ16-17": {
                    "questionId": "PQ16-17",
                    "chosenOptionIds": [
                        "53a0d94b-1a1f-4222-a072-6d850c60b105"
                    ]
                },
                "PQ16-19": {
                    "questionId": "PQ16-19",
                    "chosenOptionIds": [
                        "67077ce7-f5ce-4558-89a2-fe9e3c04e4d5"
                    ]
                },
                "PQ16-21": {
                    "questionId": "PQ16-21",
                    "chosenOptionIds": [
                        "b0bf0d54-a05f-4f92-9287-f5f4aba9bb5d"
                    ]
                },
                "PQ16-23": {
                    "questionId": "PQ16-23",
                    "chosenOptionIds": [
                        "a51d638d-7cd9-4620-964c-7d2cee3c625b"
                    ]
                },
                "PQ16-25": {
                    "questionId": "PQ16-25",
                    "chosenOptionIds": [
                        "77230379-e298-438f-8b51-7ace302d1e11"
                    ]
                },
                "PQ16-27": {
                    "questionId": "PQ16-27",
                    "chosenOptionIds": [
                        "a97455ae-190b-44f6-ad18-fc21530122a7"
                    ]
                },
                "PQ16-29": {
                    "questionId": "PQ16-29",
                    "chosenOptionIds": [
                        "55d4fc44-9580-4527-86ba-a94a3c15190f"
                    ]
                },
                "PQ16-31": {
                    "questionId": "PQ16-31",
                    "chosenOptionIds": [
                        "8ad16b9e-f3c5-48f7-ac40-dd5c3d2bccec"
                    ]
                }
            }
        },
        {
            "name": "PQ16 - if score 6 -> ultra-high risk (UHR) for psychosis-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.38,
                "threshold": 0
            },
            "answers": {
                "PQ16-1": {
                    "questionId": "PQ16-1",
                    "chosenOptionIds": [
                        "a6a7c248-8833-4170-a8b3-58f09a115fee"
                    ]
                },
                "PQ16-3": {
                    "questionId": "PQ16-3",
                    "chosenOptionIds": [
                        "17bd8f73-0a62-43fd-9392-91c10b44ad22"
                    ]
                },
                "PQ16-5": {
                    "questionId": "PQ16-5",
                    "chosenOptionIds": [
                        "b6223a9b-27c9-4962-a76e-4f4a9aa17a32"
                    ]
                },
                "PQ16-7": {
                    "questionId": "PQ16-7",
                    "chosenOptionIds": [
                        "53881799-b644-48f2-8230-6856af7da942"
                    ]
                },
                "PQ16-9": {
                    "questionId": "PQ16-9",
                    "chosenOptionIds": [
                        "69aed6d9-adb4-4f39-a236-a65532fca894"
                    ]
                },
                "PQ16-11": {
                    "questionId": "PQ16-11",
                    "chosenOptionIds": [
                        "9b82f7a5-4eca-419e-afc7-402399e897ea"
                    ]
                },
                "PQ16-13": {
                    "questionId": "PQ16-13",
                    "chosenOptionIds": [
                        "a7fcc554-4d77-4ff4-aa62-017618d3a9fe"
                    ]
                },
                "PQ16-15": {
                    "questionId": "PQ16-15",
                    "chosenOptionIds": [
                        "d05b6553-b6e2-4705-aa65-71babd98a0a9"
                    ]
                },
                "PQ16-17": {
                    "questionId": "PQ16-17",
                    "chosenOptionIds": [
                        "53a0d94b-1a1f-4222-a072-6d850c60b105"
                    ]
                },
                "PQ16-19": {
                    "questionId": "PQ16-19",
                    "chosenOptionIds": [
                        "67077ce7-f5ce-4558-89a2-fe9e3c04e4d5"
                    ]
                },
                "PQ16-21": {
                    "questionId": "PQ16-21",
                    "chosenOptionIds": [
                        "b0bf0d54-a05f-4f92-9287-f5f4aba9bb5d"
                    ]
                },
                "PQ16-23": {
                    "questionId": "PQ16-23",
                    "chosenOptionIds": [
                        "a51d638d-7cd9-4620-964c-7d2cee3c625b"
                    ]
                },
                "PQ16-25": {
                    "questionId": "PQ16-25",
                    "chosenOptionIds": [
                        "77230379-e298-438f-8b51-7ace302d1e11"
                    ]
                },
                "PQ16-27": {
                    "questionId": "PQ16-27",
                    "chosenOptionIds": [
                        "a97455ae-190b-44f6-ad18-fc21530122a7"
                    ]
                },
                "PQ16-29": {
                    "questionId": "PQ16-29",
                    "chosenOptionIds": [
                        "55d4fc44-9580-4527-86ba-a94a3c15190f"
                    ]
                },
                "PQ16-31": {
                    "questionId": "PQ16-31",
                    "chosenOptionIds": [
                        "8ad16b9e-f3c5-48f7-ac40-dd5c3d2bccec"
                    ]
                }
            }
        },
        {
            "name": "PQ16 - if score 16 -> full score-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 1,
                "threshold": 0
            },
            "answers": {
                "PQ16-1": {
                    "questionId": "PQ16-1",
                    "chosenOptionIds": [
                        "a6a7c248-8833-4170-a8b3-58f09a115fee"
                    ]
                },
                "PQ16-3": {
                    "questionId": "PQ16-3",
                    "chosenOptionIds": [
                        "17bd8f73-0a62-43fd-9392-91c10b44ad22"
                    ]
                },
                "PQ16-5": {
                    "questionId": "PQ16-5",
                    "chosenOptionIds": [
                        "b6223a9b-27c9-4962-a76e-4f4a9aa17a32"
                    ]
                },
                "PQ16-7": {
                    "questionId": "PQ16-7",
                    "chosenOptionIds": [
                        "53881799-b644-48f2-8230-6856af7da942"
                    ]
                },
                "PQ16-9": {
                    "questionId": "PQ16-9",
                    "chosenOptionIds": [
                        "69aed6d9-adb4-4f39-a236-a65532fca894"
                    ]
                },
                "PQ16-11": {
                    "questionId": "PQ16-11",
                    "chosenOptionIds": [
                        "9b82f7a5-4eca-419e-afc7-402399e897ea"
                    ]
                },
                "PQ16-13": {
                    "questionId": "PQ16-13",
                    "chosenOptionIds": [
                        "4ec44f32-fffc-4edd-b02f-07f64f36f408"
                    ]
                },
                "PQ16-15": {
                    "questionId": "PQ16-15",
                    "chosenOptionIds": [
                        "ea28fddf-d374-4c71-b85a-55e5224558c3"
                    ]
                },
                "PQ16-17": {
                    "questionId": "PQ16-17",
                    "chosenOptionIds": [
                        "1c45ff8b-70d3-4f37-a33d-5e0274c98386"
                    ]
                },
                "PQ16-19": {
                    "questionId": "PQ16-19",
                    "chosenOptionIds": [
                        "9d407539-8496-4f80-933c-88c3bd3f104b"
                    ]
                },
                "PQ16-21": {
                    "questionId": "PQ16-21",
                    "chosenOptionIds": [
                        "bfe24eb1-bcc9-4f24-85dc-db6cd7fc0147"
                    ]
                },
                "PQ16-23": {
                    "questionId": "PQ16-23",
                    "chosenOptionIds": [
                        "b610511c-a527-4a8d-9364-40841f56d699"
                    ]
                },
                "PQ16-25": {
                    "questionId": "PQ16-25",
                    "chosenOptionIds": [
                        "6729fd97-effb-492e-b4cd-171cff5bc861"
                    ]
                },
                "PQ16-27": {
                    "questionId": "PQ16-27",
                    "chosenOptionIds": [
                        "c1dc1d47-c09a-4893-b938-de4a89ec3752"
                    ]
                },
                "PQ16-29": {
                    "questionId": "PQ16-29",
                    "chosenOptionIds": [
                        "27ddef39-6320-49e6-8c2f-d3c9e48ffeb9"
                    ]
                },
                "PQ16-31": {
                    "questionId": "PQ16-31",
                    "chosenOptionIds": [
                        "b9707ed2-5434-4ec6-8404-e9610aeec041"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {
    "PQ16-2": "not(PQ16-1=0)",
    "PQ16-4": "not(PQ16-3=0)",
    "PQ16-6": "not(PQ16-5=0)",
    "PQ16-8": "not(PQ16-7=0)",
    "PQ16-10": "not(PQ16-9=0)",
    "PQ16-12": "not(PQ16-11=0)",
    "PQ16-14": "not(PQ16-13=0)",
    "PQ16-16": "not(PQ16-15=0)",
    "PQ16-18": "not(PQ16-17=0)",
    "PQ16-20": "not(PQ16-19=0)",
    "PQ16-22": "not(PQ16-21=0)",
    "PQ16-24": "not(PQ16-23=0)",
    "PQ16-26": "not(PQ16-25=0)",
    "PQ16-28": "not(PQ16-27=0)",
    "PQ16-30": "not(PQ16-29=0)",
    "PQ16-32": "not(PQ16-31=0)"
};
