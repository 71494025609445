"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sessionConfigsWithQuestions = exports.d2cSessionConfig = exports.defaultSessionConfigs = exports.sessionConfigContainsAspect = exports.sessionsToAspects = exports.questions = void 0;
const Dem_1 = require("./yaml/generated/Dem");
const AD_1 = require("./yaml/generated/AD");
const AUDIT_1 = require("./yaml/generated/AUDIT");
const ADHD_1 = require("./yaml/generated/ADHD");
const BP_1 = require("./yaml/generated/BP");
const MDD_1 = require("./yaml/generated/MDD");
const DEMOMDD_1 = require("./yaml/generated/DEMOMDD");
const DEMOHEALTHIT1_1 = require("./yaml/generated/DEMOHEALTHIT1");
const DEMOHEALTHIT2_1 = require("./yaml/generated/DEMOHEALTHIT2");
const DEMOHEALTHIT3_1 = require("./yaml/generated/DEMOHEALTHIT3");
const GAD_1 = require("./yaml/generated/GAD");
const GAD7_1 = require("./yaml/generated/GAD7");
const MDQ_1 = require("./yaml/generated/MDQ");
const oci_1 = require("./yaml/generated/oci");
const ocir_1 = require("./yaml/generated/ocir");
const PCL5_1 = require("./yaml/generated/PCL5");
const SoA_1 = require("./yaml/generated/SoA");
const Insomnia_1 = require("./yaml/generated/Insomnia");
const ISI_1 = require("./yaml/generated/ISI");
const PD_1 = require("./yaml/generated/PD");
const EUPD_1 = require("./yaml/generated/EUPD");
const Suicide_1 = require("./yaml/generated/Suicide");
const ED_1 = require("./yaml/generated/ED");
const OCD_1 = require("./yaml/generated/OCD");
const Alcohol_1 = require("./yaml/generated/Alcohol");
const Substance_1 = require("./yaml/generated/Substance");
const PHQ9_1 = require("./yaml/generated/PHQ9");
const Psychosis_1 = require("./yaml/generated/Psychosis");
const PTSD_1 = require("./yaml/generated/PTSD");
const PC_1 = require("./yaml/generated/PC");
const DAST10_1 = require("./yaml/generated/DAST10");
const EDEQS_1 = require("./yaml/generated/EDEQS");
const PDSS_1 = require("./yaml/generated/PDSS");
const SPIN_1 = require("./yaml/generated/SPIN");
const V2SUICIDESELFHARM_1 = require("./yaml/generated/V2SUICIDESELFHARM");
const V2PC_1 = require("./yaml/generated/V2PC");
const FIVEPS_1 = require("./yaml/generated/FIVEPS");
const DIALOG_1 = require("./yaml/generated/DIALOG");
const MSIBPD_1 = require("./yaml/generated/MSIBPD");
const adnm_1 = require("./yaml/generated/adnm");
const pq16_1 = require("./yaml/generated/pq16");
const SCOFF_1 = require("./yaml/generated/SCOFF");
const sessionModule = __importStar(require("./sessionConfigs"));
exports.questions = {
    AD: AD_1.questions,
    AUDIT: AUDIT_1.questions,
    ADHD: ADHD_1.questions,
    MDD: MDD_1.questions,
    MDQ: MDQ_1.questions,
    DEMOGRAPHICS: Dem_1.questions,
    DEMOMDD: DEMOMDD_1.questions,
    DEMOHEALTHIT1: DEMOHEALTHIT1_1.questions,
    DEMOHEALTHIT2: DEMOHEALTHIT2_1.questions,
    DEMOHEALTHIT3: DEMOHEALTHIT3_1.questions,
    DIALOG: DIALOG_1.questions,
    GAD: GAD_1.questions,
    GAD7: GAD7_1.questions,
    OCI: oci_1.questions,
    OCIR: ocir_1.questions,
    PCL5: PCL5_1.questions,
    SOA: SoA_1.questions,
    PD: PD_1.questions,
    OCD: OCD_1.questions,
    INS: Insomnia_1.questions,
    ISI: ISI_1.questions,
    BP: BP_1.questions,
    PSYCHOSIS: Psychosis_1.questions,
    EUPD: EUPD_1.questions,
    ED: ED_1.questions,
    ALCOHOL: Alcohol_1.questions,
    FIVEPS: FIVEPS_1.questions,
    SUBSTANCE: Substance_1.questions,
    PHQ9: PHQ9_1.questions,
    PTSD: PTSD_1.questions,
    PC: PC_1.questions,
    SUICIDE_SELFHARM: Suicide_1.questions,
    DAST10: DAST10_1.questions,
    EDEQS: EDEQS_1.questions,
    SPIN: SPIN_1.questions,
    ADNM: adnm_1.questions,
    SCOFF: SCOFF_1.questions,
    PQ16: pq16_1.questions,
    PDSS: PDSS_1.questions,
    V2SUICIDESELFHARM: V2SUICIDESELFHARM_1.questions,
    V2PC: V2PC_1.questions,
    MSIBPD: MSIBPD_1.questions,
};
exports.sessionsToAspects = {
    AD: ['adj_ce'],
    AUDIT: ['audit'],
    ADHD: ['adhd'],
    MDD: ['simple_mdd'],
    MDQ: ['mdq'],
    DEMOGRAPHICS: [],
    DEMOMDD: ['simple_mdd'],
    DEMOHEALTHIT1: [],
    DEMOHEALTHIT2: [],
    DEMOHEALTHIT3: [],
    DIALOG: ['dialog'],
    GAD: ['gad_ce'],
    GAD7: ['gad7'],
    OCI: ['oci'],
    OCIR: ['ocir'],
    PCL5: ['pcl5'],
    SOA: ['soa_ce'],
    PD: ['pad_ce'],
    OCD: ['ocd_ce'],
    INS: ['ins_ce'],
    ISI: ['isi'],
    BP: ['simple_bd1', 'simple_bd2'],
    PSYCHOSIS: ['pso_ce'],
    EUPD: ['emi_ce'],
    ED: ['dse_ce'],
    ALCOHOL: ['alc_ce', 'alc_ce_dep', 'alc_pe_dep'],
    FIVEPS: ['five_ps'],
    SUBSTANCE: ['sbs_ce', 'sbs_pe'],
    PHQ9: ['phq9'],
    PTSD: ['trm_re_ptsd'],
    PC: ['pc'],
    SUICIDE_SELFHARM: ['s_current', 'sh_current'],
    DAST10: ['dast10'],
    EDEQS: ['ede_qs'],
    SPIN: ['spin'],
    ADNM: ['adnm'],
    SCOFF: ['scoff'],
    PQ16: ['pq16'],
    PDSS: ['pdss'],
    V2SUICIDESELFHARM: ['v2SuicideSelfharm'],
    V2PC: ['v2Pc'],
    MSIBPD: ['msi_bpd'],
};
const sessionConfigContainsAspect = (sessionConfig, aspect) => {
    if (!sessionConfig || sessionConfig.length === 0)
        throw new Error('no session config provided');
    if (!aspect)
        throw new Error('aspect string not provided');
    const aspectIsAccountedFor = Object.values(exports.sessionsToAspects)
        .reduce((acc, aspect) => [...acc, ...aspect], [])
        .includes(aspect);
    if (!aspectIsAccountedFor)
        throw new Error(`aspect: ${aspect}, not in sessionsToAspects`);
    let containsAspect = false;
    sessionConfig.forEach((session) => {
        session.sections.forEach((section) => {
            if (exports.sessionsToAspects[section].includes(aspect)) {
                containsAspect = true;
            }
        });
    });
    return containsAspect;
};
exports.sessionConfigContainsAspect = sessionConfigContainsAspect;
const defaultSessionConfigs = () => [
    { name: 'Session 1', sections: ['DIALOG', 'V2PC', 'DEMOGRAPHICS'] },
    {
        name: 'Session 2',
        sections: ['GAD7', 'ISI', 'SPIN', 'PDSS', 'OCI'],
    },
    { name: 'Session 3', sections: ['FIVEPS'] },
    { name: 'Session 4', sections: ['PCL5', 'SCOFF', 'PHQ9'] },
    {
        name: 'Session 5',
        sections: ['AUDIT', 'DAST10'],
    },
    { name: 'Session 6', sections: ['V2SUICIDESELFHARM'] },
];
exports.defaultSessionConfigs = defaultSessionConfigs;
exports.d2cSessionConfig = [
    {
        name: 'Session 1',
        sections: ['AD', 'INS'],
    },
    {
        name: 'Session 2',
        sections: ['MDD'],
    },
    {
        name: 'Session 3',
        sections: ['GAD', 'SOA', 'PD', 'OCD'],
    },
    {
        name: 'Session 4',
        sections: ['BP'],
    },
    {
        name: 'Session 5',
        sections: ['PSYCHOSIS', 'EUPD', 'ED', 'ALCOHOL', 'SUBSTANCE'],
    },
    {
        name: 'Session 6',
        sections: ['PC'],
    },
];
const sessionConfigsWithQuestions = (configs) => {
    configs = configs !== null && configs !== void 0 ? configs : sessionModule.defaultSessionConfigs();
    return configs.map((session) => {
        return {
            name: session.name,
            questions: session.sections.reduce((acc, next) => {
                acc = [...acc, ...exports.questions[next]];
                return acc;
            }, []),
        };
    });
};
exports.sessionConfigsWithQuestions = sessionConfigsWithQuestions;
//// A skimmed version for dev/testing purposes. Just uncomment this one and comment the other one above.
// export const sessionConfigs: SessionConfig[] = [
//     {
//         name: "Session 1",
//         questions: [
//             SuicideSelfharmQuestions[0],
//             ADQuestions[0]
//         ]
//     },
//     {
//         name: "Session 2",
//         questions: [
//             SuicideSelfharmQuestions[1],
//             ADQuestions[1]
//         ]
//     },
//     {
//         name: "Session 3",
//         questions: [
//             ADQuestions[2],
//             SuicideSelfharmQuestions[2],
//             ADQuestions[3],
//         ]
//     },
//     {
//         name: "Session 4",
//         questions: [
//             ADQuestions[4],
//             SuicideSelfharmQuestions[3],
//         ]
//     },
//     // should be completely hidden from D2C users!
//     {
//         name: "Session 5",
//         questions: [
//             SuicideSelfharmQuestions[4],
//         ]
//     },
//    ];
//// The original sessions data set used in the pilot experiment. This is used in the integration tests
