"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
const censeo_1 = require("../../schema/generated/censeo");
exports.questions = [
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "0ff22015-c26f-4f10-86f7-0296cd954a75",
                "text": "Divorce / separation"
            },
            {
                "__typename": "Option",
                "id": "57c88e90-0d19-46b6-b464-fb4c64f70e94",
                "text": "Family conflicts"
            },
            {
                "__typename": "Option",
                "id": "ade34f6c-9b96-49e0-b957-9e34ca02a536",
                "text": "Conflicts in working life"
            },
            {
                "__typename": "Option",
                "id": "44d8be2c-626b-42fc-9ec0-e457011cadbb",
                "text": "Conflicts with neighbors"
            },
            {
                "__typename": "Option",
                "id": "067c15d0-f634-4740-98b0-b58877cf818e",
                "text": "Illness of a loved one"
            },
            {
                "__typename": "Option",
                "id": "3702c44a-9a25-4cef-a491-f467101af4de",
                "text": "Death of a loved one"
            },
            {
                "__typename": "Option",
                "id": "9dca54fc-9039-42b5-87cb-9abfc39afb23",
                "text": "Adjustment due to retirement"
            },
            {
                "__typename": "Option",
                "id": "67179bed-4b55-4881-8c6e-3f0e983135db",
                "text": "Unemployment"
            },
            {
                "__typename": "Option",
                "id": "a8f62692-cf38-4f79-9c5e-dfbfd9038d17",
                "text": "Too much / too little work"
            },
            {
                "__typename": "Option",
                "id": "aee28cb9-ad83-4e09-8613-be2cde9a464d",
                "text": "Pressure to meet deadlines / time pressure"
            },
            {
                "__typename": "Option",
                "id": "4bafe619-e416-4d64-be74-49e18fd46a13",
                "text": "Moving to a new home"
            },
            {
                "__typename": "Option",
                "id": "352b44ce-a0a9-4f42-8746-94f6ec6df976",
                "text": "Financial problems"
            },
            {
                "__typename": "Option",
                "id": "d502311c-42cd-4c1b-856c-cc338feab784",
                "text": "Own serious illness"
            },
            {
                "__typename": "Option",
                "id": "a2a7aa5a-5fbb-46ff-a6e7-09fb708b56fd",
                "text": "Serious accident"
            },
            {
                "__typename": "Option",
                "id": "50d59ed8-209c-4648-93cd-ba61fbbb8d12",
                "text": "Assault"
            },
            {
                "__typename": "Option",
                "id": "d136cea9-599d-4bd5-ac4f-8bb4bc7d61b2",
                "text": "Termination of an important leisure activity"
            },
            {
                "__typename": "Option",
                "id": "9114620e-2e84-4a63-b26c-fd4c7bfcf558",
                "text": "Any other stressful event (please indicate in the next question)"
            }
        ],
        "id": "ADNM_content-1",
        "text": "Below is a list of stressful life events. Please indicate those events that happened during the past 6 months and  are currently a strong burden to you, or have burdened you in the last six months.\n\nYou can indicate as many events as applicable.",
        "isMultiselect": true
    },
    {
        "__typename": "FreeTextQuestion",
        "id": "ADNM_content-2",
        "text": "Looking at the event(s) you've selected and any others not listed, which do you find most straining and please explain why?",
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "ContentQuestion",
        "id": "ADNM-1",
        "text": "In the following, you will find various statements about which reactions these types of events can trigger. We ask you first of all to indicate how often the respective statement applies to you (“never” to “often”).\n\nAdditionally and for each question, we would like to ask you to indicate for how long you have been having this reaction. It can be less than one month (< 1 month), for approx. one month to half a year (< 6 months) or longer than 6 months (> 6 months). This will probably not be very easy to estimate, but please try to give a rough classification of the duration of the reaction.",
        "answers": [
            {
                "__typename": "Option",
                "id": "f2432e4e-4561-4550-87e2-cdb41f85afa8",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "65812d0f-8606-4e83-812a-eb998fa50db6",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a38baec1-47b8-43bf-8b8e-1a25d5c375fa",
                "text": "Rarely",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "a9d141a3-dbaa-4459-92f6-ada641585074",
                "text": "Sometimes",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "14344826-49f0-4663-910d-20452715c08d",
                "text": "Often",
                "value": 1
            }
        ],
        "id": "ADNM-2",
        "text": "Since the stressful problem, I feel low and sad.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6f24755c-5b3e-4e9b-b0dd-0b5f9cdefc7a",
                "text": "< 1 month",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "310dc8db-e101-42a1-ad75-73bfea45c58a",
                "text": "1 – 6 months",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "2330b87e-e720-421d-b85e-ca721f0b330f",
                "text": "6 months – 2 years",
                "value": 1
            }
        ],
        "id": "ADNM-3",
        "text": "How long has it lasted?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "d115043a-d1f2-489e-8e9d-d3fb479dd8ef",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "719c167a-6f30-410c-85fb-6b8321ebb3d2",
                "text": "Rarely",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "3f7054d3-f91c-4e03-80c9-a29f184db7d1",
                "text": "Sometimes",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "df325eb8-b638-459c-be74-c60b2132ff9d",
                "text": "Often",
                "value": 1
            }
        ],
        "id": "ADNM-4",
        "text": "I have to think about the stressful situation repeatedly.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "1fcbfe28-da57-492c-938e-b22050a74b76",
                "text": "< 1 month",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "964f9328-f533-457a-8a39-eeb8936af22c",
                "text": "1 – 6 months",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "4fa4a219-59fb-4dc6-a304-dc48abd8c06c",
                "text": "6 months – 2 years",
                "value": 1
            }
        ],
        "id": "ADNM-5",
        "text": "How long has it lasted?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "7f6301b9-bb78-440f-8a3a-3c6684691087",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "471b2924-f9c9-4b4f-99c8-8bb02d0f090e",
                "text": "Rarely",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "75f6dc5e-6a7f-4d78-94a7-eb917f79a155",
                "text": "Sometimes",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "4ba69d72-b6a0-4697-b95a-afb06712f2a5",
                "text": "Often",
                "value": 1
            }
        ],
        "id": "ADNM-6",
        "text": "I try to avoid talking about the stressful situation wherever possible.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "74ed5c09-35aa-4874-b916-90f8ac0328af",
                "text": "< 1 month",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2d02dd1b-de6d-4020-9fd9-8610e7eb7570",
                "text": "1 – 6 months",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "9cb99fc8-7e34-4bac-8632-cea53481698c",
                "text": "6 months – 2 years",
                "value": 1
            }
        ],
        "id": "ADNM-7",
        "text": "How long has it lasted?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a0828d97-aa33-4645-87b2-27183cbac416",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "79ff2b78-66e7-40d4-ab14-97e948f603d4",
                "text": "Rarely",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "da3921e3-98ef-4014-94a1-66c8dda27dba",
                "text": "Sometimes",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "2ded982d-724e-4ee1-841e-389de084d94d",
                "text": "Often",
                "value": 1
            }
        ],
        "id": "ADNM-8",
        "text": "I keep having to think about the stressful situation and this is a great burden to me.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "644d117c-b477-490b-b178-911ed2d1d31b",
                "text": "< 1 month",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "842be0d9-fbd1-4f4f-a5dc-83211c3eb0c1",
                "text": "1 – 6 months",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "640c0285-4c97-4fc7-86ab-af5a57fbe19f",
                "text": "6 months – 2 years",
                "value": 1
            }
        ],
        "id": "ADNM-9",
        "text": "How long has it lasted?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "5b9c4ecb-f4e9-4c92-815a-1659c8e77cb8",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "067f2c36-c27b-4cf3-bdcb-e4480802cb54",
                "text": "Rarely",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "506059a4-3ffb-446d-bea2-dc49a9b95c19",
                "text": "Sometimes",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "f3a4d0c1-5f9e-49df-bfa2-371900ee80f6",
                "text": "Often",
                "value": 1
            }
        ],
        "id": "ADNM-10",
        "text": "Nowadays, I do those activities which I used to enjoy much more rarely.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8728017a-1072-4afa-8eb4-02681b89548d",
                "text": "< 1 month",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "9dbda775-9e74-4cdc-bc0c-5df4e8157f2b",
                "text": "1 – 6 months",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "fb1b8ef0-3e57-4da8-a56f-12e2cb6edc18",
                "text": "6 months – 2 years",
                "value": 1
            }
        ],
        "id": "ADNM-11",
        "text": "How long has it lasted?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "4a66d6b0-6eb4-49ed-b4af-b04470b68de4",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "0a1f0e29-77d5-44a7-bff1-f504aa9341df",
                "text": "Rarely",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "42eeb934-c4bb-4896-852f-c1457f8b25b1",
                "text": "Sometimes",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "bbfdf139-9b7e-482f-ae39-cfaadf082929",
                "text": "Often",
                "value": 1
            }
        ],
        "id": "ADNM-12",
        "text": "If I think about the stressful situation, I find myself in a real state of anxiety.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ff16629e-034e-4fdf-8e9a-b8e0fa4c0a0d",
                "text": "< 1 month",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "be2291af-44f3-4293-8a0d-afd4974af56b",
                "text": "1 – 6 months",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "dfbfd4b4-2121-46a4-ae37-96bb6d52dc37",
                "text": "6 months – 2 years",
                "value": 1
            }
        ],
        "id": "ADNM-13",
        "text": "How long has it lasted?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6cc0c5f9-6c3c-4e0e-842f-724166ed09fe",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "e12bbd80-be09-47af-a368-085138d25f4c",
                "text": "Rarely",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "9b07cf53-7194-4f3b-a162-f431f4d7b8b3",
                "text": "Sometimes",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "281f82fc-3aa6-4f8c-9a2a-bf55d08c57bf",
                "text": "Often",
                "value": 1
            }
        ],
        "id": "ADNM-14",
        "text": "I avoid certain things that might remind me of the stressful situation.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "9fb7a452-a621-442c-8050-78499e662431",
                "text": "< 1 month",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "39b0518a-1025-4c57-98f3-1fa55e209e91",
                "text": "1 – 6 months",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "e2323fd7-bd9c-486e-b9d3-5b587764286e",
                "text": "6 months – 2 years",
                "value": 1
            }
        ],
        "id": "ADNM-15",
        "text": "How long has it lasted?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "902c2cce-24bb-47d6-9831-9589c6f60517",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7571932c-9179-4803-9511-bd4e37116e7e",
                "text": "Rarely",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "f78b0795-ab94-492f-957f-78f856457f48",
                "text": "Sometimes",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "cecc4ef6-84a9-4120-a841-eaa70577e997",
                "text": "Often",
                "value": 1
            }
        ],
        "id": "ADNM-16",
        "text": "I am nervous and restless since the stressful situation.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "5bd454fa-078b-4b85-91b9-7c63b75888ad",
                "text": "< 1 month",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "896c6650-cdbd-42c5-8c95-f5d8bbbccd07",
                "text": "1 – 6 months",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "cce62cd8-ceca-4edc-b08d-034a375f9209",
                "text": "6 months – 2 years",
                "value": 1
            }
        ],
        "id": "ADNM-17",
        "text": "How long has it lasted?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "62299499-c5f0-42d0-a26b-a315856457f5",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ca6bef28-4f62-4ed2-a6d8-f6218db9664c",
                "text": "Rarely",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "5f7c9848-c86a-49eb-87d6-6681989acfd2",
                "text": "Sometimes",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "8c1bbc7c-331f-4e37-b240-6d241ab937fe",
                "text": "Often",
                "value": 1
            }
        ],
        "id": "ADNM-18",
        "text": "Since the stressful situation, I am much quicker to lose my temper, even over small things.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "be94f1cd-9b7b-4b49-a9fa-dd15fc979db9",
                "text": "< 1 month",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "e95d7c35-02a8-45da-84ee-6b595ebc3044",
                "text": "1 – 6 months",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "63bf6839-66a1-45e4-906b-9d91c12cc0af",
                "text": "6 months – 2 years",
                "value": 1
            }
        ],
        "id": "ADNM-19",
        "text": "How long has it lasted?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b91099e3-7357-4b9b-ae01-9c94928ba405",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "74a54303-715a-44c1-95c0-a9452b287e55",
                "text": "Rarely",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "8d125fdb-5f40-41a4-bc78-fc2da1282df6",
                "text": "Sometimes",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "64dd1133-b288-496e-9823-4953cf7bd5ec",
                "text": "Often",
                "value": 1
            }
        ],
        "id": "ADNM-20",
        "text": "Since the stressful situation, I can only concentrate on certain things with difficulty.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "01e44c28-3ca2-4229-8401-e0579dae86b6",
                "text": "< 1 month",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "cf01c1b0-481f-4c54-a6b8-84f87af4e424",
                "text": "1 – 6 months",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "42017934-239d-4014-b2ab-8a2cd0346b5a",
                "text": "6 months – 2 years",
                "value": 1
            }
        ],
        "id": "ADNM-21",
        "text": "How long has it lasted?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "2840f996-3bb8-4484-aff9-507a721d7575",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a1cf6d69-8298-416d-8612-6488c8bdac5f",
                "text": "Rarely",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "ef1c4262-fea1-45c0-a841-b1e1d5675d1c",
                "text": "Sometimes",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "b12643b0-9022-4ec5-a722-2b972597a3aa",
                "text": "Often",
                "value": 1
            }
        ],
        "id": "ADNM-22",
        "text": "I try to abolish the stressful situation from my memory.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "483181f8-8e84-4324-ab7f-ec00ca925d16",
                "text": "< 1 month",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "4a40de65-e88e-48ba-a177-7436d558880f",
                "text": "1 – 6 months",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "d769ebe7-ea5f-4a33-b183-1d2743d34c58",
                "text": "6 months – 2 years",
                "value": 1
            }
        ],
        "id": "ADNM-23",
        "text": "How long has it lasted?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "24aff4b2-1420-469a-9d8e-88dac5e5a45f",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c6c018ba-54db-48d2-926e-1aa00c3723fd",
                "text": "Rarely",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "f0928238-23cf-415d-b57b-288522c95627",
                "text": "Sometimes",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "1129e3e5-26b0-40f3-b0e5-abe77ecbf986",
                "text": "Often",
                "value": 1
            }
        ],
        "id": "ADNM-24",
        "text": "I have noticed that I am becoming more irritable due to the stressful situation.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "064fae04-faef-4440-8eef-3153bcea3b5f",
                "text": "< 1 month",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "1b6766b4-a855-4617-8bb8-7940ffafcee7",
                "text": "1 – 6 months",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "f01a99bd-1777-4c2f-b97c-abda201d8bc9",
                "text": "6 months – 2 years",
                "value": 1
            }
        ],
        "id": "ADNM-25",
        "text": "How long has it lasted?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "1311c337-0c93-4367-8ed7-181227b5c02a",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "484bba2e-05e4-47b7-beeb-4845c8549e29",
                "text": "Rarely",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "cc2010c7-bd17-4d8f-94c7-7ce7f6c64255",
                "text": "Sometimes",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "f044f1b2-21ab-4b16-98dd-fec5cfdda865",
                "text": "Often",
                "value": 1
            }
        ],
        "id": "ADNM-26",
        "text": "I get constant memories of the stressful situation and can’t do anything to stop them.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e1bad685-3452-4ddb-9e6d-ba62aa8be9ce",
                "text": "< 1 month",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b616e216-aeab-427b-8b78-812fabf6ff26",
                "text": "1 – 6 months",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "9a71436e-d901-48a5-888b-b0b493501ec1",
                "text": "6 months – 2 years",
                "value": 1
            }
        ],
        "id": "ADNM-27",
        "text": "How long has it lasted?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "98011995-7ee4-4eae-92d2-cd3fd020ead4",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "25b5aaf9-9b6e-403a-aa88-f5d1d75b9b53",
                "text": "Rarely",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "5fba8748-855c-4331-a880-7acd039a5888",
                "text": "Sometimes",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "6e1fc67e-5bbd-4a3a-93ed-7d795ee07cc6",
                "text": "Often",
                "value": 1
            }
        ],
        "id": "ADNM-28",
        "text": "I try to suppress my feelings because they are a burden to me.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "3fe22871-2db3-4347-ba3c-86a849f5da7c",
                "text": "< 1 month",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7bbde7a0-9fd7-4f18-88b6-85fb1b818d4e",
                "text": "1 – 6 months",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "2829782d-3fb9-41b6-8d98-aae57fb47244",
                "text": "6 months – 2 years",
                "value": 1
            }
        ],
        "id": "ADNM-29",
        "text": "How long has it lasted?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "cdf152b4-bf2d-40f3-a697-13ffd2f504a5",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "909e037f-fcc4-4360-b065-df30482cf6fa",
                "text": "Rarely",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "6e6fb253-f964-47ce-b491-9f8beda8224f",
                "text": "Sometimes",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "b38aaf5a-27d0-4b91-8907-577b2e29b348",
                "text": "Often",
                "value": 1
            }
        ],
        "id": "ADNM-30",
        "text": "My thoughts revolve around anything to do with the stressful situation.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "4f0db8d6-9ca1-4de8-8c34-d30ae06f386a",
                "text": "< 1 month",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a98163c9-991d-4d13-86ec-5801a8c9f056",
                "text": "1 – 6 months",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "dade0488-c33a-4978-8b87-6f281ac5b664",
                "text": "6 months – 2 years",
                "value": 1
            }
        ],
        "id": "ADNM-31",
        "text": "How long has it lasted?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "1f53ba0f-f3df-4d99-9660-1cdf93243db5",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "be2c1f3b-7a9c-45ac-944f-7195dce151c7",
                "text": "Rarely",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "10017256-8ca9-4f3e-8ae0-a436f1df76c1",
                "text": "Sometimes",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "9dc7ecfd-6999-4221-b184-93086e3435b5",
                "text": "Often",
                "value": 1
            }
        ],
        "id": "ADNM-32",
        "text": "Since the stressful situation, I am scared of doing certain things or of getting into certain situations.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "c75230fb-713b-4fef-b342-ca1ae7f053be",
                "text": "< 1 month",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "3cc68c74-7655-4416-b6d9-0a45f4771737",
                "text": "1 – 6 months",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "334b6acd-4fc9-4851-ae5a-d83f23167907",
                "text": "6 months – 2 years",
                "value": 1
            }
        ],
        "id": "ADNM-33",
        "text": "How long has it lasted?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ad41ec02-e4b3-4271-89a4-9821400b0376",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a482ed67-65e1-4f7d-bb41-712100d5250e",
                "text": "Rarely",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "0a47a8f2-8e1b-47a7-90f0-4a373da2f07f",
                "text": "Sometimes",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "bef300d5-8353-4331-b7e1-ab3c3add0aea",
                "text": "Often",
                "value": 1
            }
        ],
        "id": "ADNM-34",
        "text": "Since the stressful situation, I don’t like going to work or carrying out the necessary tasks in everyday life.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "1c9ba056-4c91-4f1d-9b02-ab3e305d41ac",
                "text": "< 1 month",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "0d6b886f-15d5-4548-882d-e95660128c07",
                "text": "1 – 6 months",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "4ce01cd3-a5bc-4c05-ac7d-e9a267ea33d2",
                "text": "6 months – 2 years",
                "value": 1
            }
        ],
        "id": "ADNM-35",
        "text": "How long has it lasted?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8d5941cf-bd97-4455-a5dd-c7b2e4e2cf80",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "3f8fcc7c-1973-481e-b719-c498d79cfcb2",
                "text": "Rarely",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "b4641b0c-95f8-4337-82da-bb6dd7053018",
                "text": "Sometimes",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "f1351540-1c49-41d7-bbeb-46898cdcaa16",
                "text": "Often",
                "value": 1
            }
        ],
        "id": "ADNM-36",
        "text": "I have been feeling dispirited since the stressful situation and have little hope for the future.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ae028d33-dd83-4e97-a115-a4b09e7e8a38",
                "text": "< 1 month",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "34b2e2e7-e24e-46b8-92b4-ea0cda92e7bb",
                "text": "1 – 6 months",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "c5609c63-5e2e-4a6b-9dd9-84a40a872749",
                "text": "6 months – 2 years",
                "value": 1
            }
        ],
        "id": "ADNM-37",
        "text": "How long has it lasted?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "18a16e92-b65b-44ef-b499-5b30b6acdb17",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "30a33902-6b24-4692-bcc6-4a849fa40b7b",
                "text": "Rarely",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "1160a853-9035-47e3-9674-e8c6be37c462",
                "text": "Sometimes",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "2a247fc5-1eaa-4d3b-a891-508574422153",
                "text": "Often",
                "value": 1
            }
        ],
        "id": "ADNM-38",
        "text": "Since the stressful situation, I can no longer sleep properly.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "437f2279-b5fb-42f4-99a0-6cded42c8b14",
                "text": "< 1 month",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "eff6de92-db81-479c-aa7e-6f582d24ab23",
                "text": "1 – 6 months",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "0d17c7f9-e6e5-4635-bf22-679ae0afe5a1",
                "text": "6 months – 2 years",
                "value": 1
            }
        ],
        "id": "ADNM-39",
        "text": "How long has it lasted?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b872542a-38a0-469e-a4fc-2de60d04e209",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2a3f8956-eef9-436c-8d4c-496e73a907fb",
                "text": "Rarely",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "efa3ed09-8baa-453c-a7d5-6d2016030b5c",
                "text": "Sometimes",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "91276a78-c4f2-4272-99fc-64fe3a97fed3",
                "text": "Often",
                "value": 1
            }
        ],
        "id": "ADNM-40",
        "text": "Overall, the situation affected me strongly in my personal relationships, my leisure activities, or other important areas of life",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e5ba510a-e0eb-475e-91cf-9993e046efde",
                "text": "< 1 month",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "05b0dddf-4350-4288-afa7-91c1c5409dcd",
                "text": "1 – 6 months",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "beccdc38-ffee-4be9-a1e1-8b1e4a6b678d",
                "text": "6 months – 2 years",
                "value": 1
            }
        ],
        "id": "ADNM-41",
        "text": "How long has it lasted?",
        "isMultiselect": false
    }
];
exports.aspects = {
    "adnm": {
        "type": "mean",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ADNM-2",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "65812d0f-8606-4e83-812a-eb998fa50db6": 0,
                            "a38baec1-47b8-43bf-8b8e-1a25d5c375fa": 0.333333333333333,
                            "a9d141a3-dbaa-4459-92f6-ada641585074": 0.666666666666666,
                            "14344826-49f0-4663-910d-20452715c08d": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ADNM-4",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "d115043a-d1f2-489e-8e9d-d3fb479dd8ef": 0,
                            "719c167a-6f30-410c-85fb-6b8321ebb3d2": 0.333333333333333,
                            "3f7054d3-f91c-4e03-80c9-a29f184db7d1": 0.666666666666666,
                            "df325eb8-b638-459c-be74-c60b2132ff9d": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ADNM-6",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "7f6301b9-bb78-440f-8a3a-3c6684691087": 0,
                            "471b2924-f9c9-4b4f-99c8-8bb02d0f090e": 0.333333333333333,
                            "75f6dc5e-6a7f-4d78-94a7-eb917f79a155": 0.666666666666666,
                            "4ba69d72-b6a0-4697-b95a-afb06712f2a5": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ADNM-8",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "a0828d97-aa33-4645-87b2-27183cbac416": 0,
                            "79ff2b78-66e7-40d4-ab14-97e948f603d4": 0.333333333333333,
                            "da3921e3-98ef-4014-94a1-66c8dda27dba": 0.666666666666666,
                            "2ded982d-724e-4ee1-841e-389de084d94d": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ADNM-10",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "5b9c4ecb-f4e9-4c92-815a-1659c8e77cb8": 0,
                            "067f2c36-c27b-4cf3-bdcb-e4480802cb54": 0.333333333333333,
                            "506059a4-3ffb-446d-bea2-dc49a9b95c19": 0.666666666666666,
                            "f3a4d0c1-5f9e-49df-bfa2-371900ee80f6": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ADNM-12",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "4a66d6b0-6eb4-49ed-b4af-b04470b68de4": 0,
                            "0a1f0e29-77d5-44a7-bff1-f504aa9341df": 0.333333333333333,
                            "42eeb934-c4bb-4896-852f-c1457f8b25b1": 0.666666666666666,
                            "bbfdf139-9b7e-482f-ae39-cfaadf082929": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ADNM-14",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "6cc0c5f9-6c3c-4e0e-842f-724166ed09fe": 0,
                            "e12bbd80-be09-47af-a368-085138d25f4c": 0.333333333333333,
                            "9b07cf53-7194-4f3b-a162-f431f4d7b8b3": 0.666666666666666,
                            "281f82fc-3aa6-4f8c-9a2a-bf55d08c57bf": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ADNM-16",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "902c2cce-24bb-47d6-9831-9589c6f60517": 0,
                            "7571932c-9179-4803-9511-bd4e37116e7e": 0.333333333333333,
                            "f78b0795-ab94-492f-957f-78f856457f48": 0.666666666666666,
                            "cecc4ef6-84a9-4120-a841-eaa70577e997": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ADNM-18",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "62299499-c5f0-42d0-a26b-a315856457f5": 0,
                            "ca6bef28-4f62-4ed2-a6d8-f6218db9664c": 0.333333333333333,
                            "5f7c9848-c86a-49eb-87d6-6681989acfd2": 0.666666666666666,
                            "8c1bbc7c-331f-4e37-b240-6d241ab937fe": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ADNM-20",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "b91099e3-7357-4b9b-ae01-9c94928ba405": 0,
                            "74a54303-715a-44c1-95c0-a9452b287e55": 0.333333333333333,
                            "8d125fdb-5f40-41a4-bc78-fc2da1282df6": 0.666666666666666,
                            "64dd1133-b288-496e-9823-4953cf7bd5ec": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ADNM-22",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "2840f996-3bb8-4484-aff9-507a721d7575": 0,
                            "a1cf6d69-8298-416d-8612-6488c8bdac5f": 0.333333333333333,
                            "ef1c4262-fea1-45c0-a841-b1e1d5675d1c": 0.666666666666666,
                            "b12643b0-9022-4ec5-a722-2b972597a3aa": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ADNM-24",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "24aff4b2-1420-469a-9d8e-88dac5e5a45f": 0,
                            "c6c018ba-54db-48d2-926e-1aa00c3723fd": 0.333333333333333,
                            "f0928238-23cf-415d-b57b-288522c95627": 0.666666666666666,
                            "1129e3e5-26b0-40f3-b0e5-abe77ecbf986": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ADNM-26",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "1311c337-0c93-4367-8ed7-181227b5c02a": 0,
                            "484bba2e-05e4-47b7-beeb-4845c8549e29": 0.333333333333333,
                            "cc2010c7-bd17-4d8f-94c7-7ce7f6c64255": 0.666666666666666,
                            "f044f1b2-21ab-4b16-98dd-fec5cfdda865": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ADNM-28",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "98011995-7ee4-4eae-92d2-cd3fd020ead4": 0,
                            "25b5aaf9-9b6e-403a-aa88-f5d1d75b9b53": 0.333333333333333,
                            "5fba8748-855c-4331-a880-7acd039a5888": 0.666666666666666,
                            "6e1fc67e-5bbd-4a3a-93ed-7d795ee07cc6": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ADNM-30",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "cdf152b4-bf2d-40f3-a697-13ffd2f504a5": 0,
                            "909e037f-fcc4-4360-b065-df30482cf6fa": 0.333333333333333,
                            "6e6fb253-f964-47ce-b491-9f8beda8224f": 0.666666666666666,
                            "b38aaf5a-27d0-4b91-8907-577b2e29b348": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ADNM-32",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "1f53ba0f-f3df-4d99-9660-1cdf93243db5": 0,
                            "be2c1f3b-7a9c-45ac-944f-7195dce151c7": 0.333333333333333,
                            "10017256-8ca9-4f3e-8ae0-a436f1df76c1": 0.666666666666666,
                            "9dc7ecfd-6999-4221-b184-93086e3435b5": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ADNM-34",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "ad41ec02-e4b3-4271-89a4-9821400b0376": 0,
                            "a482ed67-65e1-4f7d-bb41-712100d5250e": 0.333333333333333,
                            "0a47a8f2-8e1b-47a7-90f0-4a373da2f07f": 0.666666666666666,
                            "bef300d5-8353-4331-b7e1-ab3c3add0aea": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ADNM-36",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "8d5941cf-bd97-4455-a5dd-c7b2e4e2cf80": 0,
                            "3f8fcc7c-1973-481e-b719-c498d79cfcb2": 0.333333333333333,
                            "b4641b0c-95f8-4337-82da-bb6dd7053018": 0.666666666666666,
                            "f1351540-1c49-41d7-bbeb-46898cdcaa16": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ADNM-38",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "18a16e92-b65b-44ef-b499-5b30b6acdb17": 0,
                            "30a33902-6b24-4692-bcc6-4a849fa40b7b": 0.333333333333333,
                            "1160a853-9035-47e3-9674-e8c6be37c462": 0.666666666666666,
                            "2a247fc5-1eaa-4d3b-a891-508574422153": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ADNM-40",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "b872542a-38a0-469e-a4fc-2de60d04e209": 0,
                            "2a3f8956-eef9-436c-8d4c-496e73a907fb": 0.333333333333333,
                            "efa3ed09-8baa-453c-a7d5-6d2016030b5c": 0.666666666666666,
                            "91276a78-c4f2-4272-99fc-64fe3a97fed3": 1
                        }
                    }
                }
            }
        ]
    },
    "ADNM_content_divorce": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ADNM_content-1",
            "options": {
                "type": "severities",
                "severities": {
                    "0ff22015-c26f-4f10-86f7-0296cd954a75": 1
                }
            }
        }
    },
    "ADNM_content_family_conflicts": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ADNM_content-1",
            "options": {
                "type": "severities",
                "severities": {
                    "57c88e90-0d19-46b6-b464-fb4c64f70e94": 1
                }
            }
        }
    },
    "ADNM_content_work_conflicts": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ADNM_content-1",
            "options": {
                "type": "severities",
                "severities": {
                    "ade34f6c-9b96-49e0-b957-9e34ca02a536": 1
                }
            }
        }
    },
    "ADNM_content_neighbor_conflicts": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ADNM_content-1",
            "options": {
                "type": "severities",
                "severities": {
                    "44d8be2c-626b-42fc-9ec0-e457011cadbb": 1
                }
            }
        }
    },
    "ADNM_content_illness_loved_one": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ADNM_content-1",
            "options": {
                "type": "severities",
                "severities": {
                    "067c15d0-f634-4740-98b0-b58877cf818e": 1
                }
            }
        }
    },
    "ADNM_content_death_loved_one": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ADNM_content-1",
            "options": {
                "type": "severities",
                "severities": {
                    "3702c44a-9a25-4cef-a491-f467101af4de": 1
                }
            }
        }
    },
    "ADNM_content_retirement": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ADNM_content-1",
            "options": {
                "type": "severities",
                "severities": {
                    "9dca54fc-9039-42b5-87cb-9abfc39afb23": 1
                }
            }
        }
    },
    "ADNM_content_unemployment": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ADNM_content-1",
            "options": {
                "type": "severities",
                "severities": {
                    "67179bed-4b55-4881-8c6e-3f0e983135db": 1
                }
            }
        }
    },
    "ADNM_content_work_pressure": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ADNM_content-1",
            "options": {
                "type": "severities",
                "severities": {
                    "a8f62692-cf38-4f79-9c5e-dfbfd9038d17": 1
                }
            }
        }
    },
    "ADNM_content_deadlines": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ADNM_content-1",
            "options": {
                "type": "severities",
                "severities": {
                    "aee28cb9-ad83-4e09-8613-be2cde9a464d": 1
                }
            }
        }
    },
    "ADNM_content_moving": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ADNM_content-1",
            "options": {
                "type": "severities",
                "severities": {
                    "4bafe619-e416-4d64-be74-49e18fd46a13": 1
                }
            }
        }
    },
    "ADNM_content_financial_problems": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ADNM_content-1",
            "options": {
                "type": "severities",
                "severities": {
                    "352b44ce-a0a9-4f42-8746-94f6ec6df976": 1
                }
            }
        }
    },
    "ADNM_content_illness": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ADNM_content-1",
            "options": {
                "type": "severities",
                "severities": {
                    "d502311c-42cd-4c1b-856c-cc338feab784": 1
                }
            }
        }
    },
    "ADNM_content_accident": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ADNM_content-1",
            "options": {
                "type": "severities",
                "severities": {
                    "a2a7aa5a-5fbb-46ff-a6e7-09fb708b56fd": 1
                }
            }
        }
    },
    "ADNM_content_assault": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ADNM_content-1",
            "options": {
                "type": "severities",
                "severities": {
                    "50d59ed8-209c-4648-93cd-ba61fbbb8d12": 1
                }
            }
        }
    },
    "ADNM_content_leisure_termination": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ADNM_content-1",
            "options": {
                "type": "severities",
                "severities": {
                    "d136cea9-599d-4bd5-ac4f-8bb4bc7d61b2": 1
                }
            }
        }
    },
    "ADNM_content_other": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "ADNM_content-1",
            "options": {
                "type": "severities",
                "severities": {
                    "9114620e-2e84-4a63-b26c-fd4c7bfcf558": 1
                }
            }
        }
    },
    "not(ADNM-2=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "ADNM-2",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "65812d0f-8606-4e83-812a-eb998fa50db6": true,
                        "a38baec1-47b8-43bf-8b8e-1a25d5c375fa": false,
                        "a9d141a3-dbaa-4459-92f6-ada641585074": false,
                        "14344826-49f0-4663-910d-20452715c08d": false
                    }
                }
            }
        }
    },
    "not(ADNM-4=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "ADNM-4",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "d115043a-d1f2-489e-8e9d-d3fb479dd8ef": true,
                        "719c167a-6f30-410c-85fb-6b8321ebb3d2": false,
                        "3f7054d3-f91c-4e03-80c9-a29f184db7d1": false,
                        "df325eb8-b638-459c-be74-c60b2132ff9d": false
                    }
                }
            }
        }
    },
    "not(ADNM-6=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "ADNM-6",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "7f6301b9-bb78-440f-8a3a-3c6684691087": true,
                        "471b2924-f9c9-4b4f-99c8-8bb02d0f090e": false,
                        "75f6dc5e-6a7f-4d78-94a7-eb917f79a155": false,
                        "4ba69d72-b6a0-4697-b95a-afb06712f2a5": false
                    }
                }
            }
        }
    },
    "not(ADNM-8=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "ADNM-8",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "a0828d97-aa33-4645-87b2-27183cbac416": true,
                        "79ff2b78-66e7-40d4-ab14-97e948f603d4": false,
                        "da3921e3-98ef-4014-94a1-66c8dda27dba": false,
                        "2ded982d-724e-4ee1-841e-389de084d94d": false
                    }
                }
            }
        }
    },
    "not(ADNM-10=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "ADNM-10",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "5b9c4ecb-f4e9-4c92-815a-1659c8e77cb8": true,
                        "067f2c36-c27b-4cf3-bdcb-e4480802cb54": false,
                        "506059a4-3ffb-446d-bea2-dc49a9b95c19": false,
                        "f3a4d0c1-5f9e-49df-bfa2-371900ee80f6": false
                    }
                }
            }
        }
    },
    "not(ADNM-12=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "ADNM-12",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "4a66d6b0-6eb4-49ed-b4af-b04470b68de4": true,
                        "0a1f0e29-77d5-44a7-bff1-f504aa9341df": false,
                        "42eeb934-c4bb-4896-852f-c1457f8b25b1": false,
                        "bbfdf139-9b7e-482f-ae39-cfaadf082929": false
                    }
                }
            }
        }
    },
    "not(ADNM-14=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "ADNM-14",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "6cc0c5f9-6c3c-4e0e-842f-724166ed09fe": true,
                        "e12bbd80-be09-47af-a368-085138d25f4c": false,
                        "9b07cf53-7194-4f3b-a162-f431f4d7b8b3": false,
                        "281f82fc-3aa6-4f8c-9a2a-bf55d08c57bf": false
                    }
                }
            }
        }
    },
    "not(ADNM-16=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "ADNM-16",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "902c2cce-24bb-47d6-9831-9589c6f60517": true,
                        "7571932c-9179-4803-9511-bd4e37116e7e": false,
                        "f78b0795-ab94-492f-957f-78f856457f48": false,
                        "cecc4ef6-84a9-4120-a841-eaa70577e997": false
                    }
                }
            }
        }
    },
    "not(ADNM-18=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "ADNM-18",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "62299499-c5f0-42d0-a26b-a315856457f5": true,
                        "ca6bef28-4f62-4ed2-a6d8-f6218db9664c": false,
                        "5f7c9848-c86a-49eb-87d6-6681989acfd2": false,
                        "8c1bbc7c-331f-4e37-b240-6d241ab937fe": false
                    }
                }
            }
        }
    },
    "not(ADNM-20=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "ADNM-20",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "b91099e3-7357-4b9b-ae01-9c94928ba405": true,
                        "74a54303-715a-44c1-95c0-a9452b287e55": false,
                        "8d125fdb-5f40-41a4-bc78-fc2da1282df6": false,
                        "64dd1133-b288-496e-9823-4953cf7bd5ec": false
                    }
                }
            }
        }
    },
    "not(ADNM-22=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "ADNM-22",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "2840f996-3bb8-4484-aff9-507a721d7575": true,
                        "a1cf6d69-8298-416d-8612-6488c8bdac5f": false,
                        "ef1c4262-fea1-45c0-a841-b1e1d5675d1c": false,
                        "b12643b0-9022-4ec5-a722-2b972597a3aa": false
                    }
                }
            }
        }
    },
    "not(ADNM-24=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "ADNM-24",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "24aff4b2-1420-469a-9d8e-88dac5e5a45f": true,
                        "c6c018ba-54db-48d2-926e-1aa00c3723fd": false,
                        "f0928238-23cf-415d-b57b-288522c95627": false,
                        "1129e3e5-26b0-40f3-b0e5-abe77ecbf986": false
                    }
                }
            }
        }
    },
    "not(ADNM-26=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "ADNM-26",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "1311c337-0c93-4367-8ed7-181227b5c02a": true,
                        "484bba2e-05e4-47b7-beeb-4845c8549e29": false,
                        "cc2010c7-bd17-4d8f-94c7-7ce7f6c64255": false,
                        "f044f1b2-21ab-4b16-98dd-fec5cfdda865": false
                    }
                }
            }
        }
    },
    "not(ADNM-28=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "ADNM-28",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "98011995-7ee4-4eae-92d2-cd3fd020ead4": true,
                        "25b5aaf9-9b6e-403a-aa88-f5d1d75b9b53": false,
                        "5fba8748-855c-4331-a880-7acd039a5888": false,
                        "6e1fc67e-5bbd-4a3a-93ed-7d795ee07cc6": false
                    }
                }
            }
        }
    },
    "not(ADNM-30=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "ADNM-30",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "cdf152b4-bf2d-40f3-a697-13ffd2f504a5": true,
                        "909e037f-fcc4-4360-b065-df30482cf6fa": false,
                        "6e6fb253-f964-47ce-b491-9f8beda8224f": false,
                        "b38aaf5a-27d0-4b91-8907-577b2e29b348": false
                    }
                }
            }
        }
    },
    "not(ADNM-32=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "ADNM-32",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "1f53ba0f-f3df-4d99-9660-1cdf93243db5": true,
                        "be2c1f3b-7a9c-45ac-944f-7195dce151c7": false,
                        "10017256-8ca9-4f3e-8ae0-a436f1df76c1": false,
                        "9dc7ecfd-6999-4221-b184-93086e3435b5": false
                    }
                }
            }
        }
    },
    "not(ADNM-34=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "ADNM-34",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "ad41ec02-e4b3-4271-89a4-9821400b0376": true,
                        "a482ed67-65e1-4f7d-bb41-712100d5250e": false,
                        "0a47a8f2-8e1b-47a7-90f0-4a373da2f07f": false,
                        "bef300d5-8353-4331-b7e1-ab3c3add0aea": false
                    }
                }
            }
        }
    },
    "not(ADNM-36=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "ADNM-36",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "8d5941cf-bd97-4455-a5dd-c7b2e4e2cf80": true,
                        "3f8fcc7c-1973-481e-b719-c498d79cfcb2": false,
                        "b4641b0c-95f8-4337-82da-bb6dd7053018": false,
                        "f1351540-1c49-41d7-bbeb-46898cdcaa16": false
                    }
                }
            }
        }
    },
    "not(ADNM-38=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "ADNM-38",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "18a16e92-b65b-44ef-b499-5b30b6acdb17": true,
                        "30a33902-6b24-4692-bcc6-4a849fa40b7b": false,
                        "1160a853-9035-47e3-9674-e8c6be37c462": false,
                        "2a247fc5-1eaa-4d3b-a891-508574422153": false
                    }
                }
            }
        }
    },
    "not(ADNM-40=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "ADNM-40",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "b872542a-38a0-469e-a4fc-2de60d04e209": true,
                        "2a3f8956-eef9-436c-8d4c-496e73a907fb": false,
                        "efa3ed09-8baa-453c-a7d5-6d2016030b5c": false,
                        "91276a78-c4f2-4272-99fc-64fe3a97fed3": false
                    }
                }
            }
        }
    }
};
exports.tests = {
    "adnm": [
        {
            "name": "ADNM - if score 20 -> low risk of AjD-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0,
                "threshold": 0
            },
            "answers": {
                "ADNM-2": {
                    "questionId": "ADNM-2",
                    "chosenOptionIds": [
                        "65812d0f-8606-4e83-812a-eb998fa50db6"
                    ]
                },
                "ADNM-4": {
                    "questionId": "ADNM-4",
                    "chosenOptionIds": [
                        "d115043a-d1f2-489e-8e9d-d3fb479dd8ef"
                    ]
                },
                "ADNM-6": {
                    "questionId": "ADNM-6",
                    "chosenOptionIds": [
                        "7f6301b9-bb78-440f-8a3a-3c6684691087"
                    ]
                },
                "ADNM-8": {
                    "questionId": "ADNM-8",
                    "chosenOptionIds": [
                        "a0828d97-aa33-4645-87b2-27183cbac416"
                    ]
                },
                "ADNM-10": {
                    "questionId": "ADNM-10",
                    "chosenOptionIds": [
                        "5b9c4ecb-f4e9-4c92-815a-1659c8e77cb8"
                    ]
                },
                "ADNM-12": {
                    "questionId": "ADNM-12",
                    "chosenOptionIds": [
                        "4a66d6b0-6eb4-49ed-b4af-b04470b68de4"
                    ]
                },
                "ADNM-14": {
                    "questionId": "ADNM-14",
                    "chosenOptionIds": [
                        "6cc0c5f9-6c3c-4e0e-842f-724166ed09fe"
                    ]
                },
                "ADNM-16": {
                    "questionId": "ADNM-16",
                    "chosenOptionIds": [
                        "902c2cce-24bb-47d6-9831-9589c6f60517"
                    ]
                },
                "ADNM-18": {
                    "questionId": "ADNM-18",
                    "chosenOptionIds": [
                        "62299499-c5f0-42d0-a26b-a315856457f5"
                    ]
                },
                "ADNM-20": {
                    "questionId": "ADNM-20",
                    "chosenOptionIds": [
                        "b91099e3-7357-4b9b-ae01-9c94928ba405"
                    ]
                },
                "ADNM-22": {
                    "questionId": "ADNM-22",
                    "chosenOptionIds": [
                        "2840f996-3bb8-4484-aff9-507a721d7575"
                    ]
                },
                "ADNM-24": {
                    "questionId": "ADNM-24",
                    "chosenOptionIds": [
                        "24aff4b2-1420-469a-9d8e-88dac5e5a45f"
                    ]
                },
                "ADNM-26": {
                    "questionId": "ADNM-26",
                    "chosenOptionIds": [
                        "1311c337-0c93-4367-8ed7-181227b5c02a"
                    ]
                },
                "ADNM-28": {
                    "questionId": "ADNM-28",
                    "chosenOptionIds": [
                        "98011995-7ee4-4eae-92d2-cd3fd020ead4"
                    ]
                },
                "ADNM-30": {
                    "questionId": "ADNM-30",
                    "chosenOptionIds": [
                        "cdf152b4-bf2d-40f3-a697-13ffd2f504a5"
                    ]
                },
                "ADNM-32": {
                    "questionId": "ADNM-32",
                    "chosenOptionIds": [
                        "1f53ba0f-f3df-4d99-9660-1cdf93243db5"
                    ]
                },
                "ADNM-34": {
                    "questionId": "ADNM-34",
                    "chosenOptionIds": [
                        "ad41ec02-e4b3-4271-89a4-9821400b0376"
                    ]
                },
                "ADNM-36": {
                    "questionId": "ADNM-36",
                    "chosenOptionIds": [
                        "8d5941cf-bd97-4455-a5dd-c7b2e4e2cf80"
                    ]
                },
                "ADNM-38": {
                    "questionId": "ADNM-38",
                    "chosenOptionIds": [
                        "18a16e92-b65b-44ef-b499-5b30b6acdb17"
                    ]
                },
                "ADNM-40": {
                    "questionId": "ADNM-40",
                    "chosenOptionIds": [
                        "b872542a-38a0-469e-a4fc-2de60d04e209"
                    ]
                }
            }
        },
        {
            "name": "ADNM - if score 48 -> high risk of AjD-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.46666666666666634,
                "threshold": 0
            },
            "answers": {
                "ADNM-2": {
                    "questionId": "ADNM-2",
                    "chosenOptionIds": [
                        "14344826-49f0-4663-910d-20452715c08d"
                    ]
                },
                "ADNM-4": {
                    "questionId": "ADNM-4",
                    "chosenOptionIds": [
                        "df325eb8-b638-459c-be74-c60b2132ff9d"
                    ]
                },
                "ADNM-6": {
                    "questionId": "ADNM-6",
                    "chosenOptionIds": [
                        "471b2924-f9c9-4b4f-99c8-8bb02d0f090e"
                    ]
                },
                "ADNM-8": {
                    "questionId": "ADNM-8",
                    "chosenOptionIds": [
                        "79ff2b78-66e7-40d4-ab14-97e948f603d4"
                    ]
                },
                "ADNM-10": {
                    "questionId": "ADNM-10",
                    "chosenOptionIds": [
                        "067f2c36-c27b-4cf3-bdcb-e4480802cb54"
                    ]
                },
                "ADNM-12": {
                    "questionId": "ADNM-12",
                    "chosenOptionIds": [
                        "0a1f0e29-77d5-44a7-bff1-f504aa9341df"
                    ]
                },
                "ADNM-14": {
                    "questionId": "ADNM-14",
                    "chosenOptionIds": [
                        "e12bbd80-be09-47af-a368-085138d25f4c"
                    ]
                },
                "ADNM-16": {
                    "questionId": "ADNM-16",
                    "chosenOptionIds": [
                        "7571932c-9179-4803-9511-bd4e37116e7e"
                    ]
                },
                "ADNM-18": {
                    "questionId": "ADNM-18",
                    "chosenOptionIds": [
                        "ca6bef28-4f62-4ed2-a6d8-f6218db9664c"
                    ]
                },
                "ADNM-20": {
                    "questionId": "ADNM-20",
                    "chosenOptionIds": [
                        "74a54303-715a-44c1-95c0-a9452b287e55"
                    ]
                },
                "ADNM-22": {
                    "questionId": "ADNM-22",
                    "chosenOptionIds": [
                        "a1cf6d69-8298-416d-8612-6488c8bdac5f"
                    ]
                },
                "ADNM-24": {
                    "questionId": "ADNM-24",
                    "chosenOptionIds": [
                        "c6c018ba-54db-48d2-926e-1aa00c3723fd"
                    ]
                },
                "ADNM-26": {
                    "questionId": "ADNM-26",
                    "chosenOptionIds": [
                        "484bba2e-05e4-47b7-beeb-4845c8549e29"
                    ]
                },
                "ADNM-28": {
                    "questionId": "ADNM-28",
                    "chosenOptionIds": [
                        "25b5aaf9-9b6e-403a-aa88-f5d1d75b9b53"
                    ]
                },
                "ADNM-30": {
                    "questionId": "ADNM-30",
                    "chosenOptionIds": [
                        "909e037f-fcc4-4360-b065-df30482cf6fa"
                    ]
                },
                "ADNM-32": {
                    "questionId": "ADNM-32",
                    "chosenOptionIds": [
                        "be2c1f3b-7a9c-45ac-944f-7195dce151c7"
                    ]
                },
                "ADNM-34": {
                    "questionId": "ADNM-34",
                    "chosenOptionIds": [
                        "a482ed67-65e1-4f7d-bb41-712100d5250e"
                    ]
                },
                "ADNM-36": {
                    "questionId": "ADNM-36",
                    "chosenOptionIds": [
                        "3f8fcc7c-1973-481e-b719-c498d79cfcb2"
                    ]
                },
                "ADNM-38": {
                    "questionId": "ADNM-38",
                    "chosenOptionIds": [
                        "2a247fc5-1eaa-4d3b-a891-508574422153"
                    ]
                },
                "ADNM-40": {
                    "questionId": "ADNM-40",
                    "chosenOptionIds": [
                        "91276a78-c4f2-4272-99fc-64fe3a97fed3"
                    ]
                }
            }
        },
        {
            "name": "ADNM - full score of 80-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 1,
                "threshold": 0
            },
            "answers": {
                "ADNM-2": {
                    "questionId": "ADNM-2",
                    "chosenOptionIds": [
                        "14344826-49f0-4663-910d-20452715c08d"
                    ]
                },
                "ADNM-4": {
                    "questionId": "ADNM-4",
                    "chosenOptionIds": [
                        "df325eb8-b638-459c-be74-c60b2132ff9d"
                    ]
                },
                "ADNM-6": {
                    "questionId": "ADNM-6",
                    "chosenOptionIds": [
                        "4ba69d72-b6a0-4697-b95a-afb06712f2a5"
                    ]
                },
                "ADNM-8": {
                    "questionId": "ADNM-8",
                    "chosenOptionIds": [
                        "2ded982d-724e-4ee1-841e-389de084d94d"
                    ]
                },
                "ADNM-10": {
                    "questionId": "ADNM-10",
                    "chosenOptionIds": [
                        "f3a4d0c1-5f9e-49df-bfa2-371900ee80f6"
                    ]
                },
                "ADNM-12": {
                    "questionId": "ADNM-12",
                    "chosenOptionIds": [
                        "bbfdf139-9b7e-482f-ae39-cfaadf082929"
                    ]
                },
                "ADNM-14": {
                    "questionId": "ADNM-14",
                    "chosenOptionIds": [
                        "281f82fc-3aa6-4f8c-9a2a-bf55d08c57bf"
                    ]
                },
                "ADNM-16": {
                    "questionId": "ADNM-16",
                    "chosenOptionIds": [
                        "cecc4ef6-84a9-4120-a841-eaa70577e997"
                    ]
                },
                "ADNM-18": {
                    "questionId": "ADNM-18",
                    "chosenOptionIds": [
                        "8c1bbc7c-331f-4e37-b240-6d241ab937fe"
                    ]
                },
                "ADNM-20": {
                    "questionId": "ADNM-20",
                    "chosenOptionIds": [
                        "64dd1133-b288-496e-9823-4953cf7bd5ec"
                    ]
                },
                "ADNM-22": {
                    "questionId": "ADNM-22",
                    "chosenOptionIds": [
                        "b12643b0-9022-4ec5-a722-2b972597a3aa"
                    ]
                },
                "ADNM-24": {
                    "questionId": "ADNM-24",
                    "chosenOptionIds": [
                        "1129e3e5-26b0-40f3-b0e5-abe77ecbf986"
                    ]
                },
                "ADNM-26": {
                    "questionId": "ADNM-26",
                    "chosenOptionIds": [
                        "f044f1b2-21ab-4b16-98dd-fec5cfdda865"
                    ]
                },
                "ADNM-28": {
                    "questionId": "ADNM-28",
                    "chosenOptionIds": [
                        "6e1fc67e-5bbd-4a3a-93ed-7d795ee07cc6"
                    ]
                },
                "ADNM-30": {
                    "questionId": "ADNM-30",
                    "chosenOptionIds": [
                        "b38aaf5a-27d0-4b91-8907-577b2e29b348"
                    ]
                },
                "ADNM-32": {
                    "questionId": "ADNM-32",
                    "chosenOptionIds": [
                        "9dc7ecfd-6999-4221-b184-93086e3435b5"
                    ]
                },
                "ADNM-34": {
                    "questionId": "ADNM-34",
                    "chosenOptionIds": [
                        "bef300d5-8353-4331-b7e1-ab3c3add0aea"
                    ]
                },
                "ADNM-36": {
                    "questionId": "ADNM-36",
                    "chosenOptionIds": [
                        "f1351540-1c49-41d7-bbeb-46898cdcaa16"
                    ]
                },
                "ADNM-38": {
                    "questionId": "ADNM-38",
                    "chosenOptionIds": [
                        "2a247fc5-1eaa-4d3b-a891-508574422153"
                    ]
                },
                "ADNM-40": {
                    "questionId": "ADNM-40",
                    "chosenOptionIds": [
                        "91276a78-c4f2-4272-99fc-64fe3a97fed3"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {
    "ADNM-3": "not(ADNM-2=0)",
    "ADNM-5": "not(ADNM-4=0)",
    "ADNM-7": "not(ADNM-6=0)",
    "ADNM-9": "not(ADNM-8=0)",
    "ADNM-11": "not(ADNM-10=0)",
    "ADNM-13": "not(ADNM-12=0)",
    "ADNM-15": "not(ADNM-14=0)",
    "ADNM-17": "not(ADNM-16=0)",
    "ADNM-19": "not(ADNM-18=0)",
    "ADNM-21": "not(ADNM-20=0)",
    "ADNM-23": "not(ADNM-22=0)",
    "ADNM-25": "not(ADNM-24=0)",
    "ADNM-27": "not(ADNM-26=0)",
    "ADNM-29": "not(ADNM-28=0)",
    "ADNM-31": "not(ADNM-30=0)",
    "ADNM-33": "not(ADNM-32=0)",
    "ADNM-35": "not(ADNM-34=0)",
    "ADNM-37": "not(ADNM-36=0)",
    "ADNM-39": "not(ADNM-38=0)",
    "ADNM-41": "not(ADNM-40=0)"
};
