"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "{}",
            "functionId": "OciSeverity"
        },
        "section": [
            "Outcome"
        ]
    },
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "1. Washing mean score: {} [Questions 2, 4, 8, 21, 22, 27, 38 and 42]",
            "functionId": "OciWashingMean"
        },
        "section": [
            "Subscale Mean Scores - Level of Distress"
        ]
    },
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "2. Checking mean score: {} [Questions 3, 7, 9, 10, 19, 24, 31, 32 and 40]",
            "functionId": "OciCheckingMean"
        },
        "section": [
            "Subscale Mean Scores - Level of Distress"
        ]
    },
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "3. Doubting mean score: {} [Questions 26, 37 and 41]",
            "functionId": "OciDoubtingMean"
        },
        "section": [
            "Subscale Mean Scores - Level of Distress"
        ]
    },
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "4. Ordering mean score: {} [Questions 14, 15, 23, 29 and 35]",
            "functionId": "OciOrderingMean"
        },
        "section": [
            "Subscale Mean Scores - Level of Distress"
        ]
    },
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "5. Obsessing mean score {} [Questions 1, 12, 13, 17, 20, 28, 30 and 33]",
            "functionId": "OciObsessingMean"
        },
        "section": [
            "Subscale Mean Scores - Level of Distress"
        ]
    },
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "6. Hoarding mean score {} [Questions 6, 11 and 34]",
            "functionId": "OciHoardingMean"
        },
        "section": [
            "Subscale Mean Scores - Level of Distress"
        ]
    },
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "7. Mental neutralising mean score {} [Questions 5, 16, 18, 25, 36 and 39]",
            "functionId": "OciMentalNeutralisingMean"
        },
        "section": [
            "Subscale Mean Scores - Level of Distress"
        ]
    },
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "{}",
            "functionId": "OciScoringKey"
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "0 - 4 describes how much that experience has distressed or bothered the patient during the past month.",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-1",
                    "optionSummaries": {
                        "a6383a05-2199-4c6d-8a74-463d64ac6657": "",
                        "ce2f562b-5d4b-465d-ac54-58f2c23f741a": "",
                        "850abeca-6b8a-42d1-9c6a-23bc4e49f500": "",
                        "1af317bd-d6ac-4b03-994f-00d0fc91bd4f": "",
                        "c7e449e7-9fab-4c64-bccf-5985a3ad43ba": ""
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "1. [Obsessing] Unpleasant thoughts come into my mind against my will and I cannot get rid of them | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-1",
                    "optionSummaries": {
                        "a6383a05-2199-4c6d-8a74-463d64ac6657": "0 - Not at all",
                        "ce2f562b-5d4b-465d-ac54-58f2c23f741a": "1 - A little",
                        "850abeca-6b8a-42d1-9c6a-23bc4e49f500": "2 - Moderately",
                        "1af317bd-d6ac-4b03-994f-00d0fc91bd4f": "3 - A lot",
                        "c7e449e7-9fab-4c64-bccf-5985a3ad43ba": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "2. [Washing] I think contact with bodily secretions (perspiration, saliva, blood, urine, etc) may contaminate my clothes or somehow harm me | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-2",
                    "optionSummaries": {
                        "c8204fb3-2d6c-4327-ac6e-3cca055e68ff": "0 - Not at all",
                        "f7ebe38e-0481-4a4b-8ba2-b2890079d4ce": "1 - A little",
                        "a417ea72-6c53-44b5-a747-802c2fc5d696": "2 - Moderately",
                        "3ed98ebd-a891-478d-9d6b-c3dd2a055e5e": "3 - A lot",
                        "bf4de7e0-5b4b-4d2d-be3c-2caf7ae08336": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "3. [Checking] I ask people to repeat things to me several times, even though I understood them the first time | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-3",
                    "optionSummaries": {
                        "0d0255bb-752a-4689-a3f1-e34a5db7803a": "0 - Not at all",
                        "74bf0e05-3b41-44f7-ac86-faed0670c627": "1 - A little",
                        "e3bdb0f3-9559-4ba7-a613-2833089b7ab0": "2 - Moderately",
                        "16038870-226c-4a3b-8c9b-b38e99f7d5f8": "3 - A lot",
                        "8b0bde8e-8dc1-4e3f-9b8b-14d8bf40d85a": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "4. [Washing] I wash and clean obsessively | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-4",
                    "optionSummaries": {
                        "618cfdd6-a954-4109-9061-f32308ba3c7c": "0 - Not at all",
                        "61cc75e1-f8fa-417e-9f8c-d6c8cd244529": "1 - A little",
                        "f4c30217-e600-4e77-b1e3-871ca1241cc5": "2 - Moderately",
                        "ea35d663-d1a3-4663-8394-0e91378725d3": "3 - A lot",
                        "f2056915-f4c3-4ce5-975c-fa0673623509": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "5. [Mental neutralising] I have to review mentally past events, conversations and actions to make sure that I didn't do something wrong | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-5",
                    "optionSummaries": {
                        "c7ab2dd0-9598-405d-b43e-a92c69f584a5": "0 - Not at all",
                        "173cad02-0b16-4864-adae-0c9bd63b5745": "1 - A little",
                        "60a81934-6205-4dee-9de3-59495d5ce78c": "2 - Moderately",
                        "91d12a0a-4c66-42ba-8a50-c9d6b7362802": "3 - A lot",
                        "3366a5be-c430-48d3-a432-cf26cfcdd5a4": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "6. [Hoarding] I have saved up so many things that they get in the way | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-6",
                    "optionSummaries": {
                        "328d3d9b-1050-4317-9200-0ec6bd72cef8": "0 - Not at all",
                        "c91cb05d-bd4b-4973-a1f0-cbca4486cade": "1 - A little",
                        "87c5945a-2f51-4123-b9db-ca66df4f1c07": "2 - Moderately",
                        "739b604a-66ef-42c5-8b28-5226dcf6e53c": "3 - A lot",
                        "6b3173e2-dd2a-49ba-9bf4-4133fb115422": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "7. [Checking] I check things more often than necessary | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-7",
                    "optionSummaries": {
                        "5d49fde9-66e4-483d-bd3b-f4a31cc5747f": "0 - Not at all",
                        "883ce287-e7ca-4f4d-970a-b294368e334f": "1 - A little",
                        "4e7ad016-7f60-4bb5-892a-7c86f5838bb0": "2 - Moderately",
                        "0306cd11-d479-48d7-bf42-78ec4993894a": "3 - A lot",
                        "d2ac1835-55f0-4a70-ace6-8142bfeaeace": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "8. [Washing] I avoid using public toilets because I am afraid of disease or contamination | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-8",
                    "optionSummaries": {
                        "d240a2e7-4361-4dcb-9a0c-954a2fa91a2d": "0 - Not at all",
                        "81711125-32e8-4941-ad76-27825cc5f1b7": "1 - A little",
                        "4cda8e47-9911-459b-b766-06501ab21b42": "2 - Moderately",
                        "3d685022-fe73-42ad-91f8-de863ed81cca": "3 - A lot",
                        "73cfac35-95d5-4765-91c5-e657e1687e8a": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "9. [Checking] I repeatedly check doors, windows, drawers etc. | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-9",
                    "optionSummaries": {
                        "4679154d-262d-4dd7-9400-8d6f3778d370": "0 - Not at all",
                        "3aabeae3-7340-47ee-b564-7df6b9af4a53": "1 - A little",
                        "a5d709dd-4da6-4b4b-9e36-347fbfc05a36": "2 - Moderately",
                        "8b2111e5-48ff-4fe1-b049-d4c95ae3416e": "3 - A lot",
                        "3a910533-45c0-457e-9f10-fadac28f2d89": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "10. [Checking] I repeatedly check gas and water taps and light switches after turning them off | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-10",
                    "optionSummaries": {
                        "fef92306-fc1a-4df9-a659-259ce0eabfd7": "0 - Not at all",
                        "87e01fc4-3f83-4ab6-aa3f-1628cea7cace": "1 - A little",
                        "4526497b-a1d7-405a-970a-0c407b41f709": "2 - Moderately",
                        "23ac262e-0dd5-4cac-b318-24ce209abf3e": "3 - A lot",
                        "df3a9a30-afa8-45ae-a63b-2fe74e87b6c7": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "11. [Hoarding] I collect things I don't need | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-11",
                    "optionSummaries": {
                        "d6c77058-4c90-45de-8521-78455f27346a": "0 - Not at all",
                        "5e9d88d5-0251-408b-b206-d51562ab0d30": "1 - A little",
                        "0d543c3a-1940-47f3-a488-e2c05ff43e67": "2 - Moderately",
                        "6be4b214-a0ce-4bdb-ba75-88a9658fa8b2": "3 - A lot",
                        "ec46001b-9408-4c59-b95e-db21f2ecd0a1": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "12. [Obsessing] I have thoughts of having hurt someone without knowing it | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-12",
                    "optionSummaries": {
                        "38ecb45a-50d8-49ab-bf54-7028a2dc1e27": "0 - Not at all",
                        "faf4ffa1-de16-4978-95c2-0d9c3a7d03f9": "1 - A little",
                        "66931bf4-159a-4f01-be50-551a76cd968c": "2 - Moderately",
                        "bfddb52a-4958-4cd7-97ad-c3d22392354b": "3 - A lot",
                        "bc8f3b04-f637-46f4-b1ca-e4a6065b10fc": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "13. [Obsessing] I have thoughts that I might want to harm myself or others | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-13",
                    "optionSummaries": {
                        "12fa6a9a-4b33-4164-8f23-bee06fd904b1": "0 - Not at all",
                        "9db6bf85-54f2-4c2f-9005-f56e26f74880": "1 - A little",
                        "3dae9c9e-3535-4871-a31f-2265d98371cb": "2 - Moderately",
                        "72237816-c11e-44af-b3a1-4d76ad321b9a": "3 - A lot",
                        "5ccb581a-6b8f-474e-93d2-9b31babd6c77": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "14. [Ordering] I get upset if objects are not arranged properly | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-14",
                    "optionSummaries": {
                        "90e797a2-605f-4cb0-9622-86f465592d33": "0 - Not at all",
                        "f143a3c7-3196-45e6-829d-f12fba1eb36a": "1 - A little",
                        "79948f1d-34b8-43cf-874b-3e800e5045d2": "2 - Moderately",
                        "074cd9ce-ce41-4f67-a2e7-9a9e63b33479": "3 - A lot",
                        "8154c188-2fd6-4bd1-b1f3-aea84ec820d6": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "15. [Ordering] I feel obliged to follow a particular order in dressing, undressing and washing myself | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-15",
                    "optionSummaries": {
                        "c94a3cc7-e504-4d7e-acd8-690db69ccae8": "0 - Not at all",
                        "8e029877-fbc0-4aa3-b63b-47c0731939e8": "1 - A little",
                        "ed843992-9c74-42ea-9a7a-2328579ae609": "2 - Moderately",
                        "ec11d6bf-0fba-4561-b6d3-9d1a1351b5bb": "3 - A lot",
                        "8adac844-d011-4442-814d-06d6526cf867": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "16. [Mental neutralising] I feel compelled to count while I am doing thing | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-16",
                    "optionSummaries": {
                        "941bf67e-0f0f-4617-8048-73c287a66785": "0 - Not at all",
                        "f6186e4d-7f0a-49e0-9329-a7b11191df9e": "1 - A little",
                        "7c8b7dac-1fa8-43be-aedb-548030f24087": "2 - Moderately",
                        "7eb1220c-4e29-4629-a36f-7d2505e6dff1": "3 - A lot",
                        "9b799242-a808-46c5-81b8-a4bd69d6c42a": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "17. [Obsessing] I am afraid of impulsively doing embarrassing or harmful things | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-17",
                    "optionSummaries": {
                        "80eb1b27-5fa5-45e0-83db-9832b6614b5f": "0 - Not at all",
                        "671f7514-42d0-449a-bd2e-5264b14c0d06": "1 - A little",
                        "300e0f44-6b91-4076-aada-38da96000e9f": "2 - Moderately",
                        "f7bf5d91-7a82-4f9e-b73d-f1d62ba6bcd1": "3 - A lot",
                        "b9eaf868-baab-472f-8e3d-e6b9875bbd96": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "18. [Mental neutralising] I need to pray to cancel bad thoughts or feelings | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-18",
                    "optionSummaries": {
                        "618a6686-d47b-4d24-8f4d-3f2eb8e0916c": "0 - Not at all",
                        "2aca05fa-27ad-4192-bc6b-1ce5819694ce": "1 - A little",
                        "4c5bde0f-8341-485d-aa88-1c7788818c2e": "2 - Moderately",
                        "88297bda-61fb-4911-a25d-50221f8e6173": "3 - A lot",
                        "ab513dc5-2b0d-4588-90f8-58c4fdce6113": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "19. [Checking] I keep on checking forms or other things I have written | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-19",
                    "optionSummaries": {
                        "73e264ee-e7f1-430c-b3d8-f5a1fbce22bc": "0 - Not at all",
                        "90ca743f-8ad1-47b2-bf1a-d81c55365c47": "1 - A little",
                        "c01d1b0f-d8da-42b5-90ac-edce6e051ad3": "2 - Moderately",
                        "2acb5fdc-bddd-421b-8557-c841eeec8cd1": "3 - A lot",
                        "bb0f0a5d-f3a2-4c28-a406-3ec24c0aab95": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "20. [Obsessing] I get upset at the sight of knives, scissors and other sharp objects in case I lose control with them | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-20",
                    "optionSummaries": {
                        "a1051697-369a-4070-89a7-1fa3c16bb121": "0 - Not at all",
                        "7ffd5742-e264-40a0-98a7-b284a35f551e": "1 - A little",
                        "a5a2721c-8a03-46f4-a2d1-cd0a1be9b82a": "2 - Moderately",
                        "280021d4-ea94-4bf6-bcd1-e11e4a03a5c7": "3 - A lot",
                        "c43d0794-4dcd-486e-acb0-6a58dc5b875b": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "21. [Washing] I am excessively concerned about cleanliness | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-21",
                    "optionSummaries": {
                        "b8462a3c-f24e-45cb-a179-0773858d4af2": "0 - Not at all",
                        "cc9174a5-b6c7-47a3-a3d2-8849d21951bc": "1 - A little",
                        "fb2f654d-a7f7-49f0-b8d9-f84e1c0546fe": "2 - Moderately",
                        "61dab5e3-d6bb-4cd5-b384-5135028f9735": "3 - A lot",
                        "17908b73-b994-4e29-ae86-f2f8b916c415": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "22. [Washing] I find it difficult to touch an object when I know it has been touched by strangers or certain people | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-22",
                    "optionSummaries": {
                        "de65a9e9-e827-4c53-8873-12c7cc7f21ba": "0 - Not at all",
                        "127c8356-6097-4e42-b329-4595556c2178": "1 - A little",
                        "658673fa-7627-4a02-b492-6aee929ae56d": "2 - Moderately",
                        "10d4c11a-b929-400b-8dba-b90b16ef7cf8": "3 - A lot",
                        "34d233e7-9f7c-48c8-8ea3-5845c1d99706": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "23. [Ordering] I need things to be arranged in a particular order | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-23",
                    "optionSummaries": {
                        "c57f9c36-3330-4e2f-9605-07442d556593": "0 - Not at all",
                        "d142eba3-d7df-4394-a0c7-11029fcb3a48": "1 - A little",
                        "ebff5192-accb-46d8-9d01-382616d86aca": "2 - Moderately",
                        "dc6af09b-6d72-4742-a2d0-e4d3596ff7a0": "3 - A lot",
                        "bb3dd17f-5dee-4e15-9903-c0f76b8c03f0": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "24. [Checking] I get behind in my work because I repeat things over and over again | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-24",
                    "optionSummaries": {
                        "5d4b94b1-0f9f-45fd-904a-849dbd074718": "0 - Not at all",
                        "ce7ae1b1-2fa1-46f7-bf39-697326898d7f": "1 - A little",
                        "68740cda-a86d-4d18-86ea-b1dad04c8dd8": "2 - Moderately",
                        "eb3e447a-436c-41a5-8bf2-abc46037eb0c": "3 - A lot",
                        "4da19c6a-f488-4fb2-b26a-1942a4c77d1b": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "25. [Mental neutralising] I feel I have to repeat certain numbers | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-25",
                    "optionSummaries": {
                        "f4757c81-4224-4786-8ccf-979197e872d5": "0 - Not at all",
                        "77421427-4d03-4658-9881-3b9caa71e4c0": "1 - A little",
                        "ec5092ab-0f52-4ef0-b0d1-d06ef9e80107": "2 - Moderately",
                        "420f359a-8e63-41b2-8787-09464e8f7520": "3 - A lot",
                        "3c9905aa-c38d-4e28-92bf-cf997d747fcc": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "26. [Doubting] After doing something carefully, I still have the impression I have not finished it | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-26",
                    "optionSummaries": {
                        "5a7275dd-0ec9-4be3-a262-3507c62e8717": "0 - Not at all",
                        "d7bca9dc-2442-45f8-96b4-cbec64cc7f35": "1 - A little",
                        "285609e2-b485-43b5-9ede-00d846a0cf82": "2 - Moderately",
                        "4926a1fb-7e1c-4583-accf-5680f7deb4bc": "3 - A lot",
                        "0725147d-7407-4419-a37b-0ee5632a804d": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "27. [Washing] I find it difficult to touch garbage or dirty things | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-27",
                    "optionSummaries": {
                        "354bc91e-9a8e-41db-9ece-dbe905bd4a0d": "0 - Not at all",
                        "2d164010-ec66-401e-9b7c-9322f0fec43a": "1 - A little",
                        "2b457fd9-b843-4c6b-8fac-e98a81644434": "2 - Moderately",
                        "3a0d78a1-12e4-45d9-9b6e-1a42c8edbdcc": "3 - A lot",
                        "25342c97-cc75-470f-923e-382500cc62a3": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "28. [Obsessing] I find it difficult to control my own thoughts | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-28",
                    "optionSummaries": {
                        "583757c5-9cfc-4e2d-9a07-d85c38ca8918": "0 - Not at all",
                        "92c15d56-4c91-4dab-bd71-33fea666ad80": "1 - A little",
                        "cf81bce0-871d-41cf-a19c-472494ebc202": "2 - Moderately",
                        "2375a465-1a92-4a14-957d-5f7daad1f7a7": "3 - A lot",
                        "8b0cb701-dc3c-4a04-97db-bc07d6140e17": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "29. [Ordering] I have to do things over and over again until it feels right | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-29",
                    "optionSummaries": {
                        "7437519f-d718-4ce5-8053-211379eabf24": "0 - Not at all",
                        "b3c1e0bf-cac2-4f37-aaf2-493e0209e399": "1 - A little",
                        "c7324820-b14a-4fcd-ae59-25d584d20cc1": "2 - Moderately",
                        "bc93537f-a656-4ef2-9ed6-bd52436ae66e": "3 - A lot",
                        "cbf5c709-170f-41c0-bd7a-c648e1970655": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "30. [Obsessing] I am upset by unpleasant thoughts that come into my mind against my will | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-30",
                    "optionSummaries": {
                        "d5590152-174b-471d-ae77-1e3335e6cc7e": "0 - Not at all",
                        "84822290-b1eb-492c-b97c-2c1ac530e7aa": "1 - A little",
                        "7ad9e30a-02fa-4a05-af74-e65fe7ae9a3a": "2 - Moderately",
                        "ecfbdb37-1c78-4efc-ac10-8bba3088be11": "3 - A lot",
                        "d5a32277-4fd6-42e5-b246-867edb8dff06": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "31. [Checking] Before going to sleep I have to do certain things in a certain way | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-31",
                    "optionSummaries": {
                        "94eb0b53-32a4-4baa-9881-9c1872a68b25": "0 - Not at all",
                        "6eed9b97-f9db-4dee-a854-7dfb6848a085": "1 - A little",
                        "444fd488-6d42-4043-ab1c-9dc7a87e9653": "2 - Moderately",
                        "a02109d1-0fca-4bd5-8901-da7c416f394c": "3 - A lot",
                        "48fd9b06-4408-43b4-864d-c908ca2d9144": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "32. [Checking] I go back to places to make sure that I have not harmed anyone | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-32",
                    "optionSummaries": {
                        "11b3574d-c8ea-44b6-bc55-630afaba07ca": "0 - Not at all",
                        "138f8e70-e01b-4b30-af03-4504745a5c9b": "1 - A little",
                        "25323c21-2efe-4772-98f1-709a0c9189a6": "2 - Moderately",
                        "42fef8e1-6512-47a7-a3db-7a2fcf288678": "3 - A lot",
                        "a9fdc32e-7d3e-4f18-8db7-09ec91f1f9d2": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "33. [Obsessing] I frequently get nasty thoughts and have difficulty in getting rid of them | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-33",
                    "optionSummaries": {
                        "56687eb0-9a00-44ce-a352-c16a46282593": "0 - Not at all",
                        "46703ecc-f443-4a87-b71f-bd7e236dcaa8": "1 - A little",
                        "76a75a75-3ff6-4441-a7d8-2e0d7e613bb6": "2 - Moderately",
                        "4ead53fa-4fcb-4932-87cd-419c31f29222": "3 - A lot",
                        "90efdc59-ba09-4e58-8141-adf1967e1940": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "34. [Hoarding] I avoid throwing things away because I am afraid I might need them later | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-34",
                    "optionSummaries": {
                        "e0dbeb8f-d986-4843-a6b1-b27d70d4ac3b": "0 - Not at all",
                        "ded1188e-23c2-444d-8c86-13ed95ec1734": "1 - A little",
                        "5837fc2d-f309-4050-bbdc-0b480513c721": "2 - Moderately",
                        "2655b2f6-a659-43cc-a8df-c93dc91fbdbc": "3 - A lot",
                        "b4562984-e594-42ab-ab82-af8ed6ebef99": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "35. [Ordering] I get upset if others change the way I have arranged my things | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-35",
                    "optionSummaries": {
                        "a4a42763-b4ad-45c2-8861-ce353f66e153": "0 - Not at all",
                        "63d11320-c09a-48a9-9604-fba5e9f7f2e6": "1 - A little",
                        "72283645-9e71-439f-96e7-dba204674320": "2 - Moderately",
                        "2974ff20-3e4d-4e69-a3a5-64ffeca673c4": "3 - A lot",
                        "fcd5d025-2226-4043-b1cf-569053da5d1a": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "36. [Mental neutralising] I feel that I must repeat certain words or phrases in my mind in order to wipe out bad thoughts, feelings or actions | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-36",
                    "optionSummaries": {
                        "72f4518e-4ba2-4766-9c52-a18e920b3a05": "0 - Not at all",
                        "f6b5ef72-c381-4bbf-b713-83019c557052": "1 - A little",
                        "84c883c8-fa19-4af9-a6fe-9ee7c821ec2b": "2 - Moderately",
                        "c27d4008-a7a4-4a5d-9a46-db52c6855364": "3 - A lot",
                        "82703344-54cb-4bbc-9366-655d7af814c0": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "37. [Doubting] After I have done things, I have persistent doubts about whether I really did them | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-37",
                    "optionSummaries": {
                        "85230d6a-02db-48a0-95b9-952c12b7e918": "0 - Not at all",
                        "27552bf9-6c47-4421-97fa-64a043295f55": "1 - A little",
                        "81d49534-2df3-46bf-b79d-f10c42f9104f": "2 - Moderately",
                        "595e4119-2ac7-4738-a590-38c6e6aa9b76": "3 - A lot",
                        "3ead3e3c-c01b-4037-9036-30262950d347": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "38. [Washing] I sometimes have to wash or clean myself simply because I feel contaminated | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-38",
                    "optionSummaries": {
                        "ad3b5dc9-97b2-4d6c-9397-7703d8720e00": "0 - Not at all",
                        "a42f2198-0e1c-4822-8c5a-c9d380437e0c": "1 - A little",
                        "de1e8bf5-c0da-4d17-a6db-76c334dcd293": "2 - Moderately",
                        "64274834-944d-4f84-acfa-de30d507a6fe": "3 - A lot",
                        "53c6a27b-8859-40a6-b2f5-5902c40375e6": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "39. [Mental neutralising] I feel that there are good and bad numbers | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-39",
                    "optionSummaries": {
                        "75f6b7e1-5d74-4214-a932-5682a2a5ac78": "0 - Not at all",
                        "0c2b209f-7ceb-451f-a11c-0104f19cdb82": "1 - A little",
                        "464494f3-ae62-4d63-b1e0-b12c3826d5f2": "2 - Moderately",
                        "6b1d4f88-87ac-4a1e-ad59-439bf9202723": "3 - A lot",
                        "8c837838-39d2-4e8f-a116-e39268bdb8fe": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "40. [Checking] I repeatedly check anything which might cause a fire | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-40",
                    "optionSummaries": {
                        "4c11d5d0-08bb-421f-9235-1859bb9c401c": "0 - Not at all",
                        "c9348fed-832f-4c0b-b87b-63455a1abac1": "1 - A little",
                        "b19c2b4c-7887-4371-a0ee-14be7c060d75": "2 - Moderately",
                        "f2f54eb5-3786-411b-b004-3e0471add605": "3 - A lot",
                        "241a3fe6-975f-4449-9c4f-60d37aa4fe1a": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "41. [Doubting] Even when I do something very carefully I feel that it is not quite right | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-41",
                    "optionSummaries": {
                        "b24359bd-9315-45b0-845d-8ca1ce2417f0": "0 - Not at all",
                        "fd6f6519-97f9-4b62-8185-d4851446a59f": "1 - A little",
                        "9a0d37a3-7202-4a7c-af62-4c61c14f6f85": "2 - Moderately",
                        "2c755490-e540-4a6c-9045-c353852b6767": "3 - A lot",
                        "5f07b839-a5ab-446c-8acd-c1f7c71b3e5a": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "42. [Washing] I wash my hands more often or longer than necessary | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI-42",
                    "optionSummaries": {
                        "51b550e6-1d5f-48f8-bf92-0705b52dd587": "0 - Not at all",
                        "9b161814-2e38-48b5-86a2-3e8cc90b0adc": "1 - A little",
                        "30ce1d49-d18b-4032-a0f2-17dc5154fee0": "2 - Moderately",
                        "bdab349c-ec94-4724-947c-4034b3750119": "3 - A lot",
                        "ae9a1070-45d9-4b7d-9f06-95649287be9b": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    }
];
