"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "CombinedAnswersSummary",
            "questionsIdGroups": [
                [
                    "DAST-1"
                ],
                [
                    "DAST-2"
                ],
                [
                    "DAST-3"
                ],
                [
                    "DAST-4"
                ],
                [
                    "DAST-5"
                ],
                [
                    "DAST-6"
                ],
                [
                    "DAST-7"
                ],
                [
                    "DAST-8"
                ],
                [
                    "DAST-9"
                ],
                [
                    "DAST-10"
                ]
            ],
            "groupsType": "Mean",
            "pattern": "{}",
            "coefficients": {
                "0": "None (< 1)",
                "0.1": "Low level (1-2)",
                "0.3": "Moderate level (3-5)",
                "0.6": "Substantial level (6-8)",
                "0.9": "Severe level (>= 9)"
            }
        },
        "section": [
            "Outcome"
        ]
    },
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "{}",
            "functionId": "Dast10ScoringKey"
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "1. Have you used drugs other than those required for medical reasons? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "DAST-1",
                    "optionSummaries": {
                        "10203601-a616-4fda-9bf1-706c39b4e88e": "0 - No",
                        "2813eee5-2f93-47e6-90ca-9d9f024c5f5b": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "2. Do you abuse more than one drug at a time? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "DAST-2",
                    "optionSummaries": {
                        "2f6e0b86-8ac9-4cf7-bd30-6efb210c5def": "0 - No",
                        "2dd960a5-333c-40c5-a07a-ae1cd48b319b": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "3. Are you always able to stop using drugs when you want to? (If never use drugs, answer 'Yes') | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "DAST-3",
                    "optionSummaries": {
                        "19492b92-03ec-4999-81d6-d997aea61f4c": "0 - Yes",
                        "11e5470d-1ff5-41e8-b5ca-b0ac1d7fdbc9": "1 - No"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "4. Have you had 'blackouts' or 'flashbacks' as a result of drug use? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "DAST-4",
                    "optionSummaries": {
                        "5e41cbb0-c26f-4d72-9cf0-974906c94e7c": "0 - No",
                        "5a157b72-ce87-422d-8fed-aab25f3042fd": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "5. Do you ever feel bad or guilty about your drug use? (If never use drugs, answer 'No') | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "DAST-5",
                    "optionSummaries": {
                        "d0fdc9ab-788b-47c1-9e97-5d751687140b": "0 - No",
                        "240c8040-a054-4434-93cb-260bd4d2fd78": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "6. Does your spouse (or parents) ever complain about your involvement with drugs? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "DAST-6",
                    "optionSummaries": {
                        "7060d7fc-6931-4cfe-95ff-de5f48a4c62d": "0 - No",
                        "2757526f-9f7b-45a6-8bac-0306b743b49f": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "7. Have you neglected your family because of your use of drugs? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "DAST-7",
                    "optionSummaries": {
                        "10686681-f42c-4912-92a7-18a1c2dcbddc": "0 - No",
                        "7a670253-5301-4f69-b7e4-cb0532f7da38": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "8. Have you engaged in illegal activities in order to obtain drugs? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "DAST-8",
                    "optionSummaries": {
                        "87d7ff71-65da-4ef0-9b12-f14b2914927a": "0 - No",
                        "bbc880a6-f53f-4236-a137-60ba0c342abf": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "9. Have you ever experienced withdrawal symptoms (felt sick) when you stopped taking drugs? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "DAST-9",
                    "optionSummaries": {
                        "0cd2a75c-2c72-46e3-bc57-90b10af47ebb": "0 - No",
                        "c2c584b5-51f0-4d1c-804b-ec65159062c3": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "10. Have you had medical problems as a result of your drug use (e.g. memory loss, hepatitis, convulsions, bleeding, etc.)? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "DAST-10",
                    "optionSummaries": {
                        "ad261975-b042-42fe-a43a-32e9d27788db": "0 - No",
                        "8f0e41a0-554d-439c-8b45-0a0909a06e42": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    }
];
