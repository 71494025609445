"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
exports.questions = [
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "dbe573d6-5fba-492e-be0d-1a1ee170fc40",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "125a7eda-82ba-4a99-83c0-e696d361fc55",
                "text": "Several days",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "831bf8b4-aa49-4bbf-8fba-d232a84abb5f",
                "text": "More than half the days",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "769f5145-db30-4d43-8693-5f70e845d899",
                "text": "Nearly every day",
                "value": 1
            }
        ],
        "id": "PHQ9-1",
        "text": "Over the last two weeks:\n\n**How often have you been bothered by little interest or pleasure in doing things?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "85fda995-fe12-4cda-9ed1-d826130d7c48",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a3d2724b-5259-4000-8ba6-18eb484990ec",
                "text": "Several days",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "dc9d66f1-cf94-4f9b-982d-076cb8456b2d",
                "text": "More than half the days",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "df47dbdb-3828-4e6a-b60e-3f90cf87d2bf",
                "text": "Nearly every day",
                "value": 1
            }
        ],
        "id": "PHQ9-2",
        "text": "Over the last two weeks:\n\n**How often have you been bothered by feeling down, depressed, or hopeless?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e827fb22-2923-441a-bb47-89a357c06bea",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "95032328-5739-4db3-b84d-92e1aae2a9f3",
                "text": "Several days",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "06cab638-e9b5-4d4c-bf87-3c39c513d08e",
                "text": "More than half the days",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "1e46647a-aed2-4743-b898-72ed9a124d2b",
                "text": "Nearly every day",
                "value": 1
            }
        ],
        "id": "PHQ9-3",
        "text": "Over the last two weeks:\n\n**How often have you been bothered by trouble falling or staying asleep, or sleeping too much?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "5cc56ebd-1dae-4ea1-ad4d-1688654bca4c",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "92ae7887-d4a4-4ca4-b625-f9f257ad80d0",
                "text": "Several days",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "ef213d38-cc6d-43ca-b8ca-51674addb41a",
                "text": "More than half the days",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "11bd5ee9-d975-44fe-b1a0-111790a17874",
                "text": "Nearly every day",
                "value": 1
            }
        ],
        "id": "PHQ9-4",
        "text": "Over the last two weeks:\n\n**How often have you been bothered by feeling tired or having little energy?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ce46771a-e4a1-481e-938b-69a2101d230c",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "3da02a45-5dc9-42d8-bb65-d9da394507b1",
                "text": "Several days",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "8da4060f-ba78-43be-8133-f796748fc2cc",
                "text": "More than half the days",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "7ac0d947-1fd4-4490-a9ab-0e44aa7c7fdf",
                "text": "Nearly every day",
                "value": 1
            }
        ],
        "id": "PHQ9-5",
        "text": "Over the last two weeks:\n\n**How often have you been bothered by poor appetite or overeating?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "f8d411eb-6779-492c-8fcf-993028994c5f",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "cdd78b2a-62ca-4d5d-9b6b-4501004e90c6",
                "text": "Several days",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "7a4130c2-bf7f-4c48-9c15-8f4a96916c7f",
                "text": "More than half the days",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "0eab3a18-d9a3-4348-866a-28ac8e5ec513",
                "text": "Nearly every day",
                "value": 1
            }
        ],
        "id": "PHQ9-6",
        "text": "Over the last two weeks:\n\n**How often have you been bothered by feeling bad about yourself - or that you are a failure or have let yourself or your family down?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "522cfd34-4ef6-49d7-b12c-5502d5ab9e13",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "413d6cf5-ee97-4f88-bbae-4450bdb0e802",
                "text": "Several days",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "75abc951-0543-4a36-b6c2-144e396c3f41",
                "text": "More than half the days",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "56ec3094-6f9a-48b8-927a-f532b0f807b1",
                "text": "Nearly every day",
                "value": 1
            }
        ],
        "id": "PHQ9-7",
        "text": "Over the last two weeks:\n\n**How often have you been bothered by trouble concentrating on things, such as reading the newspaper or watching television?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "f6bbb281-d92c-493b-9dd2-c6e455301b5d",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ec3a5d08-df23-4f87-8692-98ef565b877a",
                "text": "Several days",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "86ee26d5-9c84-46d9-a6bf-faa278d6eb79",
                "text": "More than half the days",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "598b6f5b-fbfb-4f1b-8501-d1a0f1746363",
                "text": "Nearly every day",
                "value": 1
            }
        ],
        "id": "PHQ9-8",
        "text": "Over the last two weeks, how often have you:\n\n**been bothered by moving or speaking so slowly that other people could have noticed?**\n\nOr the opposite:\n\n**been so fidgety or restless that you have been moving around a lot more than usual?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "c5078f8f-6e74-4fb8-ba85-102eac78197d",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ef3d12b2-6dde-4ac4-8362-4f8caed72fee",
                "text": "Several days",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "b68eb3c3-b673-4a90-ae54-4374c9ddab7d",
                "text": "More than half the days",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "2c164bb5-6fb3-4320-8211-ac2a6d358349",
                "text": "Nearly every day",
                "value": 1
            }
        ],
        "id": "PHQ9-9",
        "text": "Over the last two weeks:\n\n**How often have you been bothered by thoughts that you would be better off dead or of hurting yourself in some way?**",
        "isMultiselect": false
    },
    {
        "__typename": "ContentQuestion",
        "id": "PHQ-content",
        "text": "Thank you for sharing that. Take a moment if you need to before continuing.",
        "answers": [
            {
                "__typename": "Option",
                "id": "e36275db-2f47-4d3c-b0c4-d1b345f39a58",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    }
];
exports.aspects = {
    "phq9": {
        "type": "mean",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PHQ9-1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "dbe573d6-5fba-492e-be0d-1a1ee170fc40": 0,
                            "125a7eda-82ba-4a99-83c0-e696d361fc55": 0.333333333333333,
                            "831bf8b4-aa49-4bbf-8fba-d232a84abb5f": 0.666666666666666,
                            "769f5145-db30-4d43-8693-5f70e845d899": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PHQ9-2",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "85fda995-fe12-4cda-9ed1-d826130d7c48": 0,
                            "a3d2724b-5259-4000-8ba6-18eb484990ec": 0.333333333333333,
                            "dc9d66f1-cf94-4f9b-982d-076cb8456b2d": 0.666666666666666,
                            "df47dbdb-3828-4e6a-b60e-3f90cf87d2bf": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PHQ9-3",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "e827fb22-2923-441a-bb47-89a357c06bea": 0,
                            "95032328-5739-4db3-b84d-92e1aae2a9f3": 0.333333333333333,
                            "06cab638-e9b5-4d4c-bf87-3c39c513d08e": 0.666666666666666,
                            "1e46647a-aed2-4743-b898-72ed9a124d2b": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PHQ9-4",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "5cc56ebd-1dae-4ea1-ad4d-1688654bca4c": 0,
                            "92ae7887-d4a4-4ca4-b625-f9f257ad80d0": 0.333333333333333,
                            "ef213d38-cc6d-43ca-b8ca-51674addb41a": 0.666666666666666,
                            "11bd5ee9-d975-44fe-b1a0-111790a17874": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PHQ9-5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "ce46771a-e4a1-481e-938b-69a2101d230c": 0,
                            "3da02a45-5dc9-42d8-bb65-d9da394507b1": 0.333333333333333,
                            "8da4060f-ba78-43be-8133-f796748fc2cc": 0.666666666666666,
                            "7ac0d947-1fd4-4490-a9ab-0e44aa7c7fdf": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PHQ9-6",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "f8d411eb-6779-492c-8fcf-993028994c5f": 0,
                            "cdd78b2a-62ca-4d5d-9b6b-4501004e90c6": 0.333333333333333,
                            "7a4130c2-bf7f-4c48-9c15-8f4a96916c7f": 0.666666666666666,
                            "0eab3a18-d9a3-4348-866a-28ac8e5ec513": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PHQ9-7",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "522cfd34-4ef6-49d7-b12c-5502d5ab9e13": 0,
                            "413d6cf5-ee97-4f88-bbae-4450bdb0e802": 0.333333333333333,
                            "75abc951-0543-4a36-b6c2-144e396c3f41": 0.666666666666666,
                            "56ec3094-6f9a-48b8-927a-f532b0f807b1": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PHQ9-8",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "f6bbb281-d92c-493b-9dd2-c6e455301b5d": 0,
                            "ec3a5d08-df23-4f87-8692-98ef565b877a": 0.333333333333333,
                            "86ee26d5-9c84-46d9-a6bf-faa278d6eb79": 0.666666666666666,
                            "598b6f5b-fbfb-4f1b-8501-d1a0f1746363": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PHQ9-9",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "c5078f8f-6e74-4fb8-ba85-102eac78197d": 0,
                            "ef3d12b2-6dde-4ac4-8362-4f8caed72fee": 0.333333333333333,
                            "b68eb3c3-b673-4a90-ae54-4374c9ddab7d": 0.666666666666666,
                            "2c164bb5-6fb3-4320-8211-ac2a6d358349": 1
                        }
                    }
                }
            }
        ]
    }
};
exports.tests = {
    "phq9": [
        {
            "name": "PHQ9 - if score 4 -> minimal-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.14814815,
                "threshold": 0
            },
            "answers": {
                "PHQ9-1": {
                    "questionId": "PHQ9-1",
                    "chosenOptionIds": [
                        "125a7eda-82ba-4a99-83c0-e696d361fc55"
                    ]
                },
                "PHQ9-2": {
                    "questionId": "PHQ9-2",
                    "chosenOptionIds": [
                        "a3d2724b-5259-4000-8ba6-18eb484990ec"
                    ]
                },
                "PHQ9-3": {
                    "questionId": "PHQ9-3",
                    "chosenOptionIds": [
                        "95032328-5739-4db3-b84d-92e1aae2a9f3"
                    ]
                },
                "PHQ9-4": {
                    "questionId": "PHQ9-4",
                    "chosenOptionIds": [
                        "92ae7887-d4a4-4ca4-b625-f9f257ad80d0"
                    ]
                },
                "PHQ9-5": {
                    "questionId": "PHQ9-5",
                    "chosenOptionIds": [
                        "ce46771a-e4a1-481e-938b-69a2101d230c"
                    ]
                },
                "PHQ9-6": {
                    "questionId": "PHQ9-6",
                    "chosenOptionIds": [
                        "f8d411eb-6779-492c-8fcf-993028994c5f"
                    ]
                },
                "PHQ9-7": {
                    "questionId": "PHQ9-7",
                    "chosenOptionIds": [
                        "522cfd34-4ef6-49d7-b12c-5502d5ab9e13"
                    ]
                },
                "PHQ9-8": {
                    "questionId": "PHQ9-8",
                    "chosenOptionIds": [
                        "f6bbb281-d92c-493b-9dd2-c6e455301b5d"
                    ]
                },
                "PHQ9-9": {
                    "questionId": "PHQ9-9",
                    "chosenOptionIds": [
                        "c5078f8f-6e74-4fb8-ba85-102eac78197d"
                    ]
                }
            }
        },
        {
            "name": "PHQ9 - if score 5 -> mild-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.18518519,
                "threshold": 0
            },
            "answers": {
                "PHQ9-1": {
                    "questionId": "PHQ9-1",
                    "chosenOptionIds": [
                        "125a7eda-82ba-4a99-83c0-e696d361fc55"
                    ]
                },
                "PHQ9-2": {
                    "questionId": "PHQ9-2",
                    "chosenOptionIds": [
                        "a3d2724b-5259-4000-8ba6-18eb484990ec"
                    ]
                },
                "PHQ9-3": {
                    "questionId": "PHQ9-3",
                    "chosenOptionIds": [
                        "95032328-5739-4db3-b84d-92e1aae2a9f3"
                    ]
                },
                "PHQ9-4": {
                    "questionId": "PHQ9-4",
                    "chosenOptionIds": [
                        "92ae7887-d4a4-4ca4-b625-f9f257ad80d0"
                    ]
                },
                "PHQ9-5": {
                    "questionId": "PHQ9-5",
                    "chosenOptionIds": [
                        "ce46771a-e4a1-481e-938b-69a2101d230c"
                    ]
                },
                "PHQ9-6": {
                    "questionId": "PHQ9-6",
                    "chosenOptionIds": [
                        "f8d411eb-6779-492c-8fcf-993028994c5f"
                    ]
                },
                "PHQ9-7": {
                    "questionId": "PHQ9-7",
                    "chosenOptionIds": [
                        "413d6cf5-ee97-4f88-bbae-4450bdb0e802"
                    ]
                },
                "PHQ9-8": {
                    "questionId": "PHQ9-8",
                    "chosenOptionIds": [
                        "f6bbb281-d92c-493b-9dd2-c6e455301b5d"
                    ]
                },
                "PHQ9-9": {
                    "questionId": "PHQ9-9",
                    "chosenOptionIds": [
                        "c5078f8f-6e74-4fb8-ba85-102eac78197d"
                    ]
                }
            }
        },
        {
            "name": "PHQ9 - if score 9 -> mild-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.33333333,
                "threshold": 0
            },
            "answers": {
                "PHQ9-1": {
                    "questionId": "PHQ9-1",
                    "chosenOptionIds": [
                        "769f5145-db30-4d43-8693-5f70e845d899"
                    ]
                },
                "PHQ9-2": {
                    "questionId": "PHQ9-2",
                    "chosenOptionIds": [
                        "df47dbdb-3828-4e6a-b60e-3f90cf87d2bf"
                    ]
                },
                "PHQ9-3": {
                    "questionId": "PHQ9-3",
                    "chosenOptionIds": [
                        "06cab638-e9b5-4d4c-bf87-3c39c513d08e"
                    ]
                },
                "PHQ9-4": {
                    "questionId": "PHQ9-4",
                    "chosenOptionIds": [
                        "92ae7887-d4a4-4ca4-b625-f9f257ad80d0"
                    ]
                },
                "PHQ9-5": {
                    "questionId": "PHQ9-5",
                    "chosenOptionIds": [
                        "ce46771a-e4a1-481e-938b-69a2101d230c"
                    ]
                },
                "PHQ9-6": {
                    "questionId": "PHQ9-6",
                    "chosenOptionIds": [
                        "f8d411eb-6779-492c-8fcf-993028994c5f"
                    ]
                },
                "PHQ9-7": {
                    "questionId": "PHQ9-7",
                    "chosenOptionIds": [
                        "522cfd34-4ef6-49d7-b12c-5502d5ab9e13"
                    ]
                },
                "PHQ9-8": {
                    "questionId": "PHQ9-8",
                    "chosenOptionIds": [
                        "f6bbb281-d92c-493b-9dd2-c6e455301b5d"
                    ]
                },
                "PHQ9-9": {
                    "questionId": "PHQ9-9",
                    "chosenOptionIds": [
                        "c5078f8f-6e74-4fb8-ba85-102eac78197d"
                    ]
                }
            }
        },
        {
            "name": "PHQ9 - if score 10 -> moderate-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.37037037,
                "threshold": 0
            },
            "answers": {
                "PHQ9-1": {
                    "questionId": "PHQ9-1",
                    "chosenOptionIds": [
                        "769f5145-db30-4d43-8693-5f70e845d899"
                    ]
                },
                "PHQ9-2": {
                    "questionId": "PHQ9-2",
                    "chosenOptionIds": [
                        "df47dbdb-3828-4e6a-b60e-3f90cf87d2bf"
                    ]
                },
                "PHQ9-3": {
                    "questionId": "PHQ9-3",
                    "chosenOptionIds": [
                        "06cab638-e9b5-4d4c-bf87-3c39c513d08e"
                    ]
                },
                "PHQ9-4": {
                    "questionId": "PHQ9-4",
                    "chosenOptionIds": [
                        "92ae7887-d4a4-4ca4-b625-f9f257ad80d0"
                    ]
                },
                "PHQ9-5": {
                    "questionId": "PHQ9-5",
                    "chosenOptionIds": [
                        "3da02a45-5dc9-42d8-bb65-d9da394507b1"
                    ]
                },
                "PHQ9-6": {
                    "questionId": "PHQ9-6",
                    "chosenOptionIds": [
                        "f8d411eb-6779-492c-8fcf-993028994c5f"
                    ]
                },
                "PHQ9-7": {
                    "questionId": "PHQ9-7",
                    "chosenOptionIds": [
                        "522cfd34-4ef6-49d7-b12c-5502d5ab9e13"
                    ]
                },
                "PHQ9-8": {
                    "questionId": "PHQ9-8",
                    "chosenOptionIds": [
                        "f6bbb281-d92c-493b-9dd2-c6e455301b5d"
                    ]
                },
                "PHQ9-9": {
                    "questionId": "PHQ9-9",
                    "chosenOptionIds": [
                        "c5078f8f-6e74-4fb8-ba85-102eac78197d"
                    ]
                }
            }
        },
        {
            "name": "PHQ9 - if score 14 -> moderate-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.51851852,
                "threshold": 0
            },
            "answers": {
                "PHQ9-1": {
                    "questionId": "PHQ9-1",
                    "chosenOptionIds": [
                        "769f5145-db30-4d43-8693-5f70e845d899"
                    ]
                },
                "PHQ9-2": {
                    "questionId": "PHQ9-2",
                    "chosenOptionIds": [
                        "df47dbdb-3828-4e6a-b60e-3f90cf87d2bf"
                    ]
                },
                "PHQ9-3": {
                    "questionId": "PHQ9-3",
                    "chosenOptionIds": [
                        "1e46647a-aed2-4743-b898-72ed9a124d2b"
                    ]
                },
                "PHQ9-4": {
                    "questionId": "PHQ9-4",
                    "chosenOptionIds": [
                        "ef213d38-cc6d-43ca-b8ca-51674addb41a"
                    ]
                },
                "PHQ9-5": {
                    "questionId": "PHQ9-5",
                    "chosenOptionIds": [
                        "3da02a45-5dc9-42d8-bb65-d9da394507b1"
                    ]
                },
                "PHQ9-6": {
                    "questionId": "PHQ9-6",
                    "chosenOptionIds": [
                        "cdd78b2a-62ca-4d5d-9b6b-4501004e90c6"
                    ]
                },
                "PHQ9-7": {
                    "questionId": "PHQ9-7",
                    "chosenOptionIds": [
                        "413d6cf5-ee97-4f88-bbae-4450bdb0e802"
                    ]
                },
                "PHQ9-8": {
                    "questionId": "PHQ9-8",
                    "chosenOptionIds": [
                        "f6bbb281-d92c-493b-9dd2-c6e455301b5d"
                    ]
                },
                "PHQ9-9": {
                    "questionId": "PHQ9-9",
                    "chosenOptionIds": [
                        "c5078f8f-6e74-4fb8-ba85-102eac78197d"
                    ]
                }
            }
        },
        {
            "name": "PHQ9 - if score 15 -> moderately severe-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.55555556,
                "threshold": 0
            },
            "answers": {
                "PHQ9-1": {
                    "questionId": "PHQ9-1",
                    "chosenOptionIds": [
                        "769f5145-db30-4d43-8693-5f70e845d899"
                    ]
                },
                "PHQ9-2": {
                    "questionId": "PHQ9-2",
                    "chosenOptionIds": [
                        "df47dbdb-3828-4e6a-b60e-3f90cf87d2bf"
                    ]
                },
                "PHQ9-3": {
                    "questionId": "PHQ9-3",
                    "chosenOptionIds": [
                        "1e46647a-aed2-4743-b898-72ed9a124d2b"
                    ]
                },
                "PHQ9-4": {
                    "questionId": "PHQ9-4",
                    "chosenOptionIds": [
                        "11bd5ee9-d975-44fe-b1a0-111790a17874"
                    ]
                },
                "PHQ9-5": {
                    "questionId": "PHQ9-5",
                    "chosenOptionIds": [
                        "8da4060f-ba78-43be-8133-f796748fc2cc"
                    ]
                },
                "PHQ9-6": {
                    "questionId": "PHQ9-6",
                    "chosenOptionIds": [
                        "cdd78b2a-62ca-4d5d-9b6b-4501004e90c6"
                    ]
                },
                "PHQ9-7": {
                    "questionId": "PHQ9-7",
                    "chosenOptionIds": [
                        "522cfd34-4ef6-49d7-b12c-5502d5ab9e13"
                    ]
                },
                "PHQ9-8": {
                    "questionId": "PHQ9-8",
                    "chosenOptionIds": [
                        "f6bbb281-d92c-493b-9dd2-c6e455301b5d"
                    ]
                },
                "PHQ9-9": {
                    "questionId": "PHQ9-9",
                    "chosenOptionIds": [
                        "c5078f8f-6e74-4fb8-ba85-102eac78197d"
                    ]
                }
            }
        },
        {
            "name": "PHQ9 - if score 19 -> moderately severe-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.7037037,
                "threshold": 0
            },
            "answers": {
                "PHQ9-1": {
                    "questionId": "PHQ9-1",
                    "chosenOptionIds": [
                        "769f5145-db30-4d43-8693-5f70e845d899"
                    ]
                },
                "PHQ9-2": {
                    "questionId": "PHQ9-2",
                    "chosenOptionIds": [
                        "df47dbdb-3828-4e6a-b60e-3f90cf87d2bf"
                    ]
                },
                "PHQ9-3": {
                    "questionId": "PHQ9-3",
                    "chosenOptionIds": [
                        "1e46647a-aed2-4743-b898-72ed9a124d2b"
                    ]
                },
                "PHQ9-4": {
                    "questionId": "PHQ9-4",
                    "chosenOptionIds": [
                        "11bd5ee9-d975-44fe-b1a0-111790a17874"
                    ]
                },
                "PHQ9-5": {
                    "questionId": "PHQ9-5",
                    "chosenOptionIds": [
                        "7ac0d947-1fd4-4490-a9ab-0e44aa7c7fdf"
                    ]
                },
                "PHQ9-6": {
                    "questionId": "PHQ9-6",
                    "chosenOptionIds": [
                        "0eab3a18-d9a3-4348-866a-28ac8e5ec513"
                    ]
                },
                "PHQ9-7": {
                    "questionId": "PHQ9-7",
                    "chosenOptionIds": [
                        "413d6cf5-ee97-4f88-bbae-4450bdb0e802"
                    ]
                },
                "PHQ9-8": {
                    "questionId": "PHQ9-8",
                    "chosenOptionIds": [
                        "f6bbb281-d92c-493b-9dd2-c6e455301b5d"
                    ]
                },
                "PHQ9-9": {
                    "questionId": "PHQ9-9",
                    "chosenOptionIds": [
                        "c5078f8f-6e74-4fb8-ba85-102eac78197d"
                    ]
                }
            }
        },
        {
            "name": "PHQ9 - if score 20 -> severe-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.74074074,
                "threshold": 0
            },
            "answers": {
                "PHQ9-1": {
                    "questionId": "PHQ9-1",
                    "chosenOptionIds": [
                        "769f5145-db30-4d43-8693-5f70e845d899"
                    ]
                },
                "PHQ9-2": {
                    "questionId": "PHQ9-2",
                    "chosenOptionIds": [
                        "df47dbdb-3828-4e6a-b60e-3f90cf87d2bf"
                    ]
                },
                "PHQ9-3": {
                    "questionId": "PHQ9-3",
                    "chosenOptionIds": [
                        "1e46647a-aed2-4743-b898-72ed9a124d2b"
                    ]
                },
                "PHQ9-4": {
                    "questionId": "PHQ9-4",
                    "chosenOptionIds": [
                        "11bd5ee9-d975-44fe-b1a0-111790a17874"
                    ]
                },
                "PHQ9-5": {
                    "questionId": "PHQ9-5",
                    "chosenOptionIds": [
                        "7ac0d947-1fd4-4490-a9ab-0e44aa7c7fdf"
                    ]
                },
                "PHQ9-6": {
                    "questionId": "PHQ9-6",
                    "chosenOptionIds": [
                        "0eab3a18-d9a3-4348-866a-28ac8e5ec513"
                    ]
                },
                "PHQ9-7": {
                    "questionId": "PHQ9-7",
                    "chosenOptionIds": [
                        "75abc951-0543-4a36-b6c2-144e396c3f41"
                    ]
                },
                "PHQ9-8": {
                    "questionId": "PHQ9-8",
                    "chosenOptionIds": [
                        "f6bbb281-d92c-493b-9dd2-c6e455301b5d"
                    ]
                },
                "PHQ9-9": {
                    "questionId": "PHQ9-9",
                    "chosenOptionIds": [
                        "c5078f8f-6e74-4fb8-ba85-102eac78197d"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {};
