"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "5P-0.1"
            ],
            "prefix": "",
            "formatText": "What are the key issues for you at the moment that matter most to you? | {}"
        },
        "section": [
            "Patient Reported Mental Health Overview",
            "Presenting Problem(s)"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "5P-0.2"
            ],
            "prefix": "",
            "formatText": "How are these issues affecting your daily life? | {}"
        },
        "section": [
            "Patient Reported Mental Health Overview",
            "Presenting Problem(s)"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "5P-3"
            ],
            "prefix": "",
            "formatText": "What tends to make your symptoms worse? | {}"
        },
        "section": [
            "Patient Reported Mental Health Overview",
            "Present Triggers"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "5P-4"
            ],
            "prefix": "",
            "formatText": "Has there been any recent changes or stresses in your life? | {}"
        },
        "section": [
            "Patient Reported Mental Health Overview",
            "Present Triggers"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "5P-1"
            ],
            "prefix": "",
            "formatText": "What past experiences have influenced your current difficulties? | {}"
        },
        "section": [
            "Patient Reported Mental Health Overview",
            "Past Experiences and Background"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "5P-2"
            ],
            "prefix": "",
            "formatText": "Were there any particularly difficult or supportive relationships in your past? | {}"
        },
        "section": [
            "Patient Reported Mental Health Overview",
            "Past Experiences and Background"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "5P-5"
            ],
            "prefix": "",
            "formatText": "What makes it hard to feel better? | {}"
        },
        "section": [
            "Patient Reported Mental Health Overview",
            "Perpetuating Factors (What Keeps Things Difficult)"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "5P-6"
            ],
            "prefix": "",
            "formatText": "What barriers do you face in accessing support? | {}"
        },
        "section": [
            "Patient Reported Mental Health Overview",
            "Perpetuating Factors (What Keeps Things Difficult)"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "5P-7"
            ],
            "prefix": "",
            "formatText": "What helps you cope when things are difficult? | {}"
        },
        "section": [
            "Patient Reported Mental Health Overview",
            "Protective Factors (What Helps)"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "5P-8"
            ],
            "prefix": "",
            "formatText": "What activities or strategies help you manage stress? | {}"
        },
        "section": [
            "Patient Reported Mental Health Overview",
            "Protective Factors (What Helps)"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "5P-9"
            ],
            "prefix": "",
            "formatText": "What changes would you like to make in your life? | {}"
        },
        "section": [
            "Patient Reported Mental Health Overview",
            "Plans and Goals"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "5P-10"
            ],
            "prefix": "",
            "formatText": "What small steps could help you move forward? | {}"
        },
        "section": [
            "Patient Reported Mental Health Overview",
            "Plans and Goals"
        ]
    }
];
