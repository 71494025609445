"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateScoreFromNormalized = void 0;
const getAnswerValue_1 = require("./getAnswerValue");
const calculateScoreFromNormalized = (answers, expectedQuestionIds, surveyQuestions, normalizationFactor, offset = 0) => {
    const subset = answers.filter((ans) => expectedQuestionIds.includes(ans.questionId));
    if (subset.length === 0)
        return undefined;
    if (subset.length !== expectedQuestionIds.length) {
        throw new Error(`Incorrect number of expected questions: ` + JSON.stringify(subset));
    }
    const total = subset.reduce((acc, answer) => {
        const value = (0, getAnswerValue_1.getAnswerValue)(surveyQuestions, answer, normalizationFactor, offset);
        return acc + value;
    }, 0);
    return total;
};
exports.calculateScoreFromNormalized = calculateScoreFromNormalized;
