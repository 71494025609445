"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "CombinedAnswersSummary",
            "questionsIdGroups": [
                [
                    "EDE-1"
                ],
                [
                    "EDE-2"
                ],
                [
                    "EDE-3"
                ],
                [
                    "EDE-4"
                ],
                [
                    "EDE-5"
                ],
                [
                    "EDE-6"
                ],
                [
                    "EDE-7"
                ],
                [
                    "EDE-8"
                ],
                [
                    "EDE-9"
                ],
                [
                    "EDE-10"
                ],
                [
                    "EDE-11"
                ],
                [
                    "EDE-12"
                ]
            ],
            "groupsType": "Mean",
            "pattern": "Eating disorder symptoms {} screening threshold",
            "coefficients": {
                "0": "below",
                "0.415": "above"
            }
        },
        "section": []
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Have you been deliberately trying to limit the amount of food you eat to influence your weight or shape? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "EDE-1",
                    "optionSummaries": {
                        "5bcaffaa-cbd4-4b5f-b3b8-f6a16b9c6f96": "0 - 0 days",
                        "7916b318-11b9-48e7-b1fa-c2a692f8df42": "1 - 1-2 days",
                        "b75b5170-c845-4f32-b0a0-2733cf5a9ff7": "2 - 3-5 days",
                        "c2f5ca30-efb8-4200-8375-85e7b6b4cf6d": "3 - 6-7 days"
                    }
                }
            ]
        },
        "section": [
            "Over the last 7 days"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Have you gone for long periods of time without eating anything at all in order to influence your weight or shape? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "EDE-2",
                    "optionSummaries": {
                        "fbe54168-e7c9-41c0-ab56-876bdf3a1acc": "0 - 0 days",
                        "b64bdced-0c40-44dc-bf7b-dcefa1e5fb12": "1 - 1-2 days",
                        "957f65d3-54a8-4bc4-81fd-96a8d35be602": "2 - 3-5 days",
                        "25ecd748-8b62-424b-80d2-59f3910b4841": "3 - 6-7 days"
                    }
                }
            ]
        },
        "section": [
            "Over the last 7 days"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Has thinking about food, eating or calories made it very difficult to concentrate on things you are interested in? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "EDE-3",
                    "optionSummaries": {
                        "ff7e0e17-99d0-43b1-9795-f633cd93a270": "0 - 0 days",
                        "37320729-1937-4c77-8259-de0c66d77c8c": "1 - 1-2 days",
                        "bde6ac54-88c8-4dee-8857-e3f8b6a93ca6": "2 - 3-5 days",
                        "5b15ad7d-2f35-4021-9a25-97f44309b1f5": "3 - 6-7 days"
                    }
                }
            ]
        },
        "section": [
            "Over the last 7 days"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Has thinking about your weight or shape made it very difficult to concentrate on things you are interested in? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "EDE-4",
                    "optionSummaries": {
                        "d5a5717c-f2d1-4e9f-9219-4a23508a3865": "0 - 0 days",
                        "5d688a87-d027-4746-9600-28b213eece42": "1 - 1-2 days",
                        "07f11988-c6df-4fe3-a1de-ea0f912b9df7": "2 - 3-5 days",
                        "39ef7744-ced0-421a-b35c-3fb2d424eef8": "3 - 6-7 days"
                    }
                }
            ]
        },
        "section": [
            "Over the last 7 days"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Have you had a definite fear that you might gain weight? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "EDE-5",
                    "optionSummaries": {
                        "9340f3fa-4c71-44c4-8015-e47d8136f348": "0 - 0 days",
                        "2e62c6c8-7ce5-40dd-a2e3-d6448e331661": "1 - 1-2 days",
                        "bd3832fa-22f4-4849-a261-91d0d79036ec": "2 - 3-5 days",
                        "e3ed02c5-89ea-4a39-acc1-4401b7ba6f87": "3 - 6-7 days"
                    }
                }
            ]
        },
        "section": [
            "Over the last 7 days"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Have you had a strong desire to lose weight? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "EDE-6",
                    "optionSummaries": {
                        "299d6139-6dcc-46ed-8bdb-932fcf1de0e5": "0 - 0 days",
                        "4d48bdcf-f756-4527-b860-287cb9960d9c": "1 - 1-2 days",
                        "4666084b-a154-41b9-9928-008102c0d7a0": "2 - 3-5 days",
                        "e1339e01-67c8-4345-9305-7bb16b05e95b": "3 - 6-7 days"
                    }
                }
            ]
        },
        "section": [
            "Over the last 7 days"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Have you tried to control your weight or shape by making yourself sick (vomit) or taking laxatives? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "EDE-7",
                    "optionSummaries": {
                        "c6169844-558b-40f0-a843-f97dd49a91eb": "0 - 0 days",
                        "cd43c779-6d4c-44ea-8d27-a799d6927854": "1 - 1-2 days",
                        "efa99f5a-96ba-40f2-b2fe-160b22ac2f28": "2 - 3-5 days",
                        "8b31f3e0-9ed8-4375-a03c-7740c07b7dd7": "3 - 6-7 days"
                    }
                }
            ]
        },
        "section": [
            "Over the last 7 days"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Have you exercised in a driven or compulsive way as a means of controlling your weight, shape or body fat, or to burn off calories? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "EDE-8",
                    "optionSummaries": {
                        "188235d8-1c3e-48d3-92a0-cbae8b98667a": "0 - 0 days",
                        "2dc85912-854c-49c7-ac92-0fff989f50e2": "1 - 1-2 days",
                        "fe1e4808-f607-42a6-90e0-7a0341225b5b": "2 - 3-5 days",
                        "d9a2b484-9852-4df6-9739-9098660c775c": "3 - 6-7 days"
                    }
                }
            ]
        },
        "section": [
            "Over the last 7 days"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Have you had a sense of having lost control over your eating (at the time that you were eating)? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "EDE-9",
                    "optionSummaries": {
                        "249d2a50-c9db-4950-8561-2cf830830ca4": "0 - 0 days",
                        "14968026-d0e2-4876-8c71-62f1070cb3de": "1 - 1-2 days",
                        "0ca3a260-a6f2-4dff-9b52-39de0e76fa50": "2 - 3-5 days",
                        "daafea35-3e01-41c8-979e-e8c76b5e9189": "3 - 6-7 days"
                    }
                }
            ]
        },
        "section": [
            "Over the last 7 days"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Did you eat what other people would regard as an unusually large amount of food in one go? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "EDE-10",
                    "optionSummaries": {
                        "bf72638b-6209-4e2c-868d-b1e3773321b3": "0 - 0 days",
                        "a15ec674-a121-449a-a6bd-9c670ef4f3f4": "1 - 1-2 days",
                        "4a1dd773-0ac5-43b0-b858-1bdc833b9489": "2 - 3-5 days",
                        "48fda884-ac94-43dc-93cf-3b3d140fb30e": "3 - 6-7 days"
                    }
                }
            ]
        },
        "section": [
            "Over the last 7 days"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Has your weight or shape influenced how you think about (judge) yourself as a person? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "EDE-11",
                    "optionSummaries": {
                        "59893bc8-2610-4b73-b17b-90a9b4cf0286": "0 - Not at all",
                        "d62083b8-2390-4327-b5a0-53ae305dfdab": "1 - Slightly",
                        "9c619a3b-25af-4d86-a4c7-34c1fa5c780e": "2 - Moderately",
                        "5aa5fff2-897e-4f82-9dd3-d79c472c89a7": "3 - Markedly"
                    }
                }
            ]
        },
        "section": [
            "Over the last 7 days"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "How dissatisfied have you been with your weight or shape? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "EDE-12",
                    "optionSummaries": {
                        "2c94540e-f2a4-4f19-a02d-d92a9b9aa6cb": "0 - Not at all",
                        "7c8d33dd-e072-462a-b7cd-2cf6a22565b2": "1 - Slightly",
                        "28e75936-fa66-4ce6-b9ff-cac7a1ae88d6": "2 - Moderately",
                        "bfd69a21-0d96-4a61-b4de-d04a54074b11": "3 - Markedly"
                    }
                }
            ]
        },
        "section": [
            "Over the last 7 days"
        ]
    }
];
