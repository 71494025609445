import React, { useMemo, useState, useContext, useEffect } from 'react'
import { Heading, Page, Button } from '@psyomics/components'
import Styles from './ClinicalReports.module.scss'
import { fakeData, FakeDataRow, Risk, AssessmentStatus, fakeDataHealthIT } from './fakeData'
import SortArrow from 'ui/icons/SortArrow'
import UpArrow from 'ui/icons/UpArrow'
import DownArrow from 'ui/icons/DownArrow'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import Header from 'ui/app/components/Header'
import DemoFooter from 'ui/app/components/DemoFooter'
import { useDemoContext } from '../DemoContext'
import { DemoType } from 'censeo-core'

const riskColour = new Map([
    [Risk.LOW, '#259B24'],
    [Risk.MEDIUM, '#F9CC01'],
    [Risk.HIGH, '#FF9901'],
    [Risk.VERY_HIGH, '#f70811'],
])

const capitaliseFirstWord = (word: string) => {
    const firstLetter = word.slice(0, 1)
    const capitalLetter = firstLetter.toUpperCase()
    return capitalLetter + word.slice(1)
}

enum SortDirection {
    ASC = 'ASC',
    DESC = 'DESC',
}

interface Column {
    header: string
    accessorKey: keyof FakeDataRow
    cell?: (value: string | undefined) => string | JSX.Element
    sortable: boolean
}

const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
}

interface ClinicalReportListProps {
    risk: boolean
}

export const ClinicalReportList: React.FC<ClinicalReportListProps> = ({ risk }) => {
    const [sortKey, setSortKey] = useState<{ column: keyof FakeDataRow; direction: SortDirection } | undefined>({
        column: 'givenName',
        direction: SortDirection.ASC,
    })
    const columns: Column[] = [
        {
            header: 'First name',
            accessorKey: 'firstName',
            sortable: true,
        },
        {
            header: 'Surname',
            accessorKey: 'givenName',
            sortable: true,
        },
        {
            header: 'D.O.B.',
            accessorKey: 'dob',
            sortable: true,
            cell: (value) => {
                if (!value) return <></>
                const date = new Date(value)
                if (isNaN(date.getTime())) {
                    return 'Unknown'
                } else {
                    return formatDate(date)
                }
            },
        },
        {
            header: 'ID',
            accessorKey: 'nhsNumber',
            sortable: true,
        },
        {
            header: 'Completed',
            accessorKey: 'completed',
            sortable: true,
            cell: (value) => {
                if (!value) return <></>
                const date = new Date(value)
                if (isNaN(date.getTime())) {
                    return capitaliseFirstWord(value)
                } else {
                    return formatDate(date)
                }
            },
        },

        {
            header: 'Report',
            accessorKey: 'reportId',
            sortable: false,
            cell: (value) => {
                return value ? (
                    <div
                        style={{
                            verticalAlign: 'middle',
                            textAlign: 'center',
                        }}
                    >
                        <a href={'/demo/healthcare/report/' + value} target="_blank" rel="noreferrer">
                            <Button size="small" appearance="primary" label="View" type="button" />
                        </a>
                    </div>
                ) : (
                    <></>
                )
            },
        },
    ]
    if (risk) {
        columns.splice(5, 0, {
            header: 'Priority',
            accessorKey: 'risk',
            sortable: true,
            cell: (value) => {
                return value ? (
                    <div
                        style={{
                            backgroundColor: riskColour.get(value as Risk),
                            color: 'white',
                            verticalAlign: 'middle',
                            textAlign: 'center',
                        }}
                    >
                        {value}
                    </div>
                ) : (
                    <></>
                )
            },
        })
    }

    const sortValueOverride = (direction: SortDirection, key: keyof FakeDataRow, value: string | undefined) => {
        if (key === 'risk') {
            switch (value) {
                case Risk.VERY_HIGH:
                    return 5
                case Risk.HIGH:
                    return 4
                case Risk.MEDIUM:
                    return 3
                case Risk.LOW:
                    return 2
                default:
                    return direction === SortDirection.ASC ? Infinity : -Infinity
            }
        }
        if (key === 'completed') {
            switch (value) {
                case AssessmentStatus.IN_PROGRESS:
                    return direction === SortDirection.ASC ? Infinity : -Infinity
                case AssessmentStatus.UNCOMPLETED:
                    return direction === SortDirection.ASC ? Infinity : -Infinity
            }
        }
        return value
    }
    const organiseData = (
        data: FakeDataRow[],
        sort: { column: keyof FakeDataRow; direction: SortDirection } | undefined
    ): FakeDataRow[] => {
        const dataCopy = [...data]
        if (sort) {
            dataCopy.sort((a, b) => {
                const valueA = sortValueOverride(sort.direction, sort.column, a[sort.column]) || -Infinity
                const valueB = sortValueOverride(sort.direction, sort.column, b[sort.column]) || -Infinity
                if (valueA > valueB) {
                    return sort.direction === SortDirection.ASC ? 1 : -1
                } else if (valueA < valueB) {
                    return sort.direction === SortDirection.ASC ? -1 : 1
                } else {
                    return 0
                }
            })
        }
        return dataCopy
    }
    const organisedData = useMemo(() => organiseData(risk ? fakeData : fakeDataHealthIT, sortKey), [sortKey])
    return (
        <div className={Styles.card} style={{ overflowX: 'auto' }}>
            <table style={{ border: '1px solid var(--c-teal-mid)', borderCollapse: 'collapse' }}>
                <thead
                    style={{
                        borderBottom: '1px solid var(--c-teal-mid)',
                        backgroundColor: 'var(--c-teal-mid)',
                        color: 'white',
                        height: '60px',
                    }}
                >
                    <tr>
                        {columns.map((header) => (
                            <th
                                style={{
                                    borderRight: '1px solid white',
                                    WebkitUserSelect: 'none',
                                    userSelect: 'none',
                                }}
                                key={header.accessorKey}
                            >
                                <div
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        paddingRight: '9px',
                                        paddingLeft: '9px',
                                    }}
                                >
                                    <div>{header.header}</div>
                                    {header.sortable && (
                                        <span
                                            style={{
                                                minHeight: '20px',
                                                minWidth: '20px',
                                                position: 'relative',
                                                top: sortKey?.column === header.accessorKey ? '-2px' : '4px',
                                                left: '6px',
                                            }}
                                        >
                                            {sortKey?.column === header.accessorKey ? (
                                                sortKey?.direction === SortDirection.ASC ? (
                                                    <span
                                                        onClick={() => {
                                                            setSortKey(undefined)
                                                        }}
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <UpArrow color="white" height={10} width={20} />
                                                    </span>
                                                ) : (
                                                    <span
                                                        onClick={() => {
                                                            setSortKey({
                                                                column: header.accessorKey,
                                                                direction: SortDirection.ASC,
                                                            })
                                                        }}
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <DownArrow color="white" height={10} width={20} />
                                                    </span>
                                                )
                                            ) : (
                                                <span
                                                    onClick={() => {
                                                        setSortKey({
                                                            column: header.accessorKey,
                                                            direction: SortDirection.DESC,
                                                        })
                                                    }}
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <SortArrow color="grey" height={20} width={20} />
                                                </span>
                                            )}
                                        </span>
                                    )}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {organisedData.map((datum) => (
                        <tr
                            style={{ borderBottom: '1px solid var(--c-teal-mid)' }}
                            key={datum.firstName + datum.givenName + datum.dob}
                        >
                            {columns.map((column) => (
                                <td
                                    style={{
                                        height: '60px',
                                        padding: '0px 10px',
                                        borderRight: '1px solid var(--c-teal-mid)',
                                    }}
                                    key={column.accessorKey}
                                >
                                    {column.cell ? column.cell(datum[column.accessorKey]) : datum[column.accessorKey]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

const DemoClinicalReports: React.FC = () => {
    const { demoType } = useDemoContext()
    const mixpanelApiUrl = useContext(MixpanelContext)
    const { visitedDemoPatientMgt } = useMixpanelEvent(mixpanelApiUrl)
    useEffect(() => {
        visitedDemoPatientMgt(undefined, undefined)
    }, [])
    return (
        <Page
            width="wide"
            header={<Header patientFacing={false} demoMenu={true} />}
            footer={<DemoFooter footerType="clinician" />}
        >
            <Heading el="h1" size="heading1" color="mid" css={{ mb: 2 }}>
                Clinical Reports
            </Heading>
            <ClinicalReportList risk={demoType !== DemoType.Healthit} />
        </Page>
    )
}

export default DemoClinicalReports
