import React, { useContext, useEffect, useState } from 'react'
import { Heading, Page, Table, Button } from '@psyomics/components'
import { InfoContext } from 'configure'
import { Auth } from 'aws-amplify'
import Error from '../Error'
import { DateFilter } from './DateFilter'
import { TextFilter } from './TextFilter'
import Styles from './PatientReferrals.module.scss'
import { useParams } from 'react-router-dom'
import Header from 'ui/app/components/Header'

interface TableData {
    assessmentCompletedAt?: string
    assessmentCompletedOrCancelled?: string
    patientIdentifierLogical?: string
    patientName?: string
    ragScore?: number
    referralCreatedAt?: string
    suppliedHealthCareId?: string
    quicksilvaStatus?: string
    organisationConfig?: {
        quicksilva: boolean
    }
}

type SortableFields = 'user' | 'creationDate' | 'completionDate' | 'report'
const sortedFields: (SortableFields | undefined)[] = [undefined, 'user', 'creationDate', 'completionDate']
const defaultSortBy = 'creationDate'
const defaultSortDirection = 'desc'

const PatientReferrals: React.FC = () => {
    const info = useContext(InfoContext)
    const [referralsData, setReferralsData] = useState<TableData[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [errorMessage, setErrorMessage] = useState<{ title: string; message: string } | undefined>()
    const [sortBy, setSortBy] = useState<SortableFields>(defaultSortBy)
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>(defaultSortDirection)
    const [filterSettings, setFilterSettings] = useState<{
        user?: string | null
        assessmentCompletedAt?: {
            to: string | null
            from: string | null
        }
        referralCreatedAt?: {
            to: string | null
            from: string | null
        }
        suppliedHealthCareId?: string
    }>({})
    const { organisationCode } = useParams<{ organisationCode: string }>()

    const [currentPage, setCurrentPage] = useState<number>(0)
    const [pageSize, setPageSize] = useState<number>(10)
    const [totalCount, setTotalCount] = useState<number>(0)

    const updateSort = (col: number, direction: 'asc' | 'desc') => {
        if (col === -1) {
            setSortBy(defaultSortBy)
            setSortDirection(defaultSortDirection)
        } else {
            setSortBy(sortedFields[col] ?? defaultSortBy)
            setSortDirection(direction)
        }
    }

    useEffect(() => {
        const createdFrom = filterSettings.referralCreatedAt?.from ? filterSettings.referralCreatedAt.from : undefined
        const user = filterSettings.user ?? undefined
        const createdTo = filterSettings.referralCreatedAt?.to ? filterSettings.referralCreatedAt.to : undefined
        const completedFrom = filterSettings.assessmentCompletedAt?.from
            ? filterSettings.assessmentCompletedAt.from
            : undefined
        const completedTo = filterSettings.assessmentCompletedAt?.to
            ? filterSettings.assessmentCompletedAt.to
            : undefined
        const fetchData = async () => {
            setIsLoading(true)
            const res = await Auth.currentSession()
            const jwt = res.getAccessToken().getJwtToken()
            const result = await fetch(
                `${
                    info?.referralApiUrl
                }practitioner/patient-referrals?organisationCode=${organisationCode}&limit=${pageSize}&offset=${
                    currentPage * pageSize
                }${user ? `&user=${user}` : ''}${createdFrom ? `&fromCreationDate=${createdFrom}` : ''}${
                    createdTo ? `&toCreationDate=${createdTo}` : ''
                }${completedFrom ? `&fromCompletionDate=${completedFrom}` : ''}${
                    completedTo ? `&toCompletionDate=${completedTo}` : ''
                }${sortBy ? `&sortBy=${sortBy}` : ''}${
                    sortDirection ? `&sortDirection=${sortDirection.toUpperCase()}` : ''
                }`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            )
            if (result.status === 401) {
                setErrorMessage({
                    title: 'Access Denied',
                    message: 'Please contact support if you believe you should have access to this page.',
                })
                return
            }
            const jsonParsed = await result.json()
            if (jsonParsed && jsonParsed.data) {
                setReferralsData(jsonParsed.data as TableData[])
                setTotalCount(jsonParsed.totalCount)
            } else {
                setReferralsData([])
                setTotalCount(0)
            }
            setIsLoading(false)
        }
        fetchData()
    }, [organisationCode, currentPage, pageSize, filterSettings, sortBy, sortDirection])

    const filteredData = referralsData

    const adjustedTotalCount = totalCount

    if (errorMessage) {
        return (
            <Error
                customMessage={errorMessage.message}
                customTitle={errorMessage.title}
                homepageRedirect={`/account/${organisationCode}`}
            ></Error>
        )
    }

    const columns = [
        {
            title: 'Name',
            field: 'patientName',
            render: (rowData: any) => <div className="ph-no-capture">{rowData.patientName}</div>,
            sorting: false,
        },
        {
            title: 'User ID',
            field: 'patientIdentifierLogical',
            type: 'numeric',
            render: (rowData: any) => <div className="ph-no-capture">{rowData.patientIdentifierLogical}</div>,
            defaultSort: sortBy === 'user' ? sortDirection : undefined,
            FilterCell: (
                <TextFilter
                    label={'User'}
                    text={filterSettings.user || null}
                    triggerFilter={(text) => {
                        setFilterSettings((prev) => ({
                            ...prev,
                            user: !text ? undefined : text,
                        }))
                        setCurrentPage(0)
                    }}
                ></TextFilter>
            ),
        },
        {
            title: 'Referred Date',
            field: 'referralCreatedAt',
            type: 'date',
            dateSetting: {
                locale: 'en-gb',
                format: 'YYYY-MM-DD',
            },
            defaultSort: sortBy === 'creationDate' ? sortDirection : undefined,
            FilterCell: (
                <DateFilter
                    to={filterSettings.referralCreatedAt?.to || null}
                    from={filterSettings.referralCreatedAt?.from || null}
                    triggerFilter={(from, to) => {
                        setFilterSettings((prev) => ({
                            ...prev,
                            referralCreatedAt:
                                !to && !from
                                    ? undefined
                                    : {
                                          from,
                                          to,
                                      },
                        }))
                        setCurrentPage(0)
                    }}
                ></DateFilter>
            ),
        },
        {
            title: 'Completed',
            field: 'assessmentCompletedOrCancelled',
            type: 'date',
            dateSetting: {
                locale: 'en-gb',
                format: 'YYYY-MM-DD',
            },
            defaultSort: sortBy === 'completionDate' ? sortDirection : undefined,
            FilterCell: (
                <DateFilter
                    to={filterSettings.assessmentCompletedAt?.to || null}
                    from={filterSettings.assessmentCompletedAt?.from || null}
                    triggerFilter={(from, to) => {
                        setFilterSettings((prev) => ({
                            ...prev,
                            assessmentCompletedAt:
                                !to && !from
                                    ? undefined
                                    : {
                                          from,
                                          to,
                                      },
                        }))
                        setCurrentPage(0)
                    }}
                ></DateFilter>
            ),
        },
        ...(filteredData?.[0]?.organisationConfig?.quicksilva
            ? [
                  {
                      title: 'Quicksilva',
                      field: 'quicksilvaStatus',
                  },
              ]
            : []),
        {
            title: 'Report',
            field: 'assessmentId',
            sorting: false,
            render: (rowData: any) => (
                <div
                    style={{
                        verticalAlign: 'middle',
                        textAlign: 'center',
                    }}
                >
                    {rowData.assessmentId && rowData.assessmentCompletedAt && (
                        <a rel="noreferrer" href={'/report/' + rowData.assessmentId} target="_blank">
                            <Button size="small" appearance="primary" label="View" type="button" />
                        </a>
                    )}
                </div>
            ),
        },
    ]

    return (
        <Page width="x-wide" header={<Header patientFacing={false}></Header>}>
            <Heading el="h1" size="heading1" color="mid" css={{ mt: 5, mb: 5 }}>
                Psyomics Platform Referrals
            </Heading>

            <div
                style={{
                    maxWidth: '100%',
                    paddingTop: '30px',
                    paddingBottom: '200px',
                }}
                className={Styles.table}
            >
                <Table
                    title={''}
                    isLoading={isLoading}
                    columns={columns}
                    data={filteredData}
                    options={{
                        search: false,
                        exportButton: true,
                        filtering: true,
                        pageSize: pageSize,
                        pageSizeOptions: [5, 10, 20, 50],
                        paginationType: 'stepped',
                    }}
                    onOrderChange={updateSort}
                    totalCount={adjustedTotalCount}
                    page={currentPage}
                    onChangePage={(page: React.SetStateAction<number>) => setCurrentPage(page)}
                    onChangeRowsPerPage={(size: React.SetStateAction<number>) => {
                        setPageSize(size)
                        setCurrentPage(0)
                    }}
                />
            </div>
        </Page>
    )
}

export default PatientReferrals
