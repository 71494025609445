"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
const censeo_1 = require("../../schema/generated/censeo");
exports.questions = [
    {
        "__typename": "ContentQuestion",
        "id": "P-S",
        "text": "This session will ask about past experiences and how you thought and behaved in those situations.\n\nSome questions might feel difficult to answer but just try your best.\n\nYou can take a break at any point, as your answers are saved automatically.",
        "answers": [
            {
                "__typename": "Option",
                "id": "205b8415-4a42-57b0-9a63-b56b6b6e5eba",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "fe9c935a-f525-5a45-980a-13d21e4c1b28",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "185cf99e-324a-580e-8ca4-ee1fedf8eaa5",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "P-s1",
        "text": "Have you ever had any beliefs that everyone else claimed to be untrue or impossible?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "92de7cae-28f3-58f4-8d55-d550b0926cce",
                "text": "A belief that you possessed superior or special powers (e.g. the ability to heal people, read minds or control the weather)"
            },
            {
                "__typename": "Option",
                "id": "f6861254-2a8d-5a0c-b77d-451de537a402",
                "text": "A belief about being very important or famous or having a special purpose"
            },
            {
                "__typename": "Option",
                "id": "49a9e0be-f179-5a9b-b636-94800133eb7d",
                "text": "A belief that someone is out to get you, following you, spying on you or that something bad is going to happen"
            },
            {
                "__typename": "Option",
                "id": "a88a1596-13eb-5b68-8059-14a5457a3e80",
                "text": "A belief that your thoughts or feelings have been put in your head by an external force or that your thoughts, feelings or behaviours are being controlled by someone or something else"
            },
            {
                "__typename": "Option",
                "id": "ffe61661-4b61-5c35-8953-a4f5931f07eb",
                "text": "A belief that your thoughts could be read or heard by others"
            },
            {
                "__typename": "Option",
                "id": "91337a60-b5cd-5ad4-9da7-147a82ad9981",
                "text": "A belief that normal everyday occurrences are referring to or addressing you directly (e.g. this could occur in news coverage, TV programmes, song lyrics)"
            },
            {
                "__typename": "Option",
                "id": "8c7def60-8271-53a0-9d16-69792c21b125",
                "text": "A belief or feeling that there is something wrong with your body or that something strange was happening to it, even though other people told you nothing was wrong"
            },
            {
                "__typename": "Option",
                "id": "70762907-ef8b-5b48-ae7c-f8045b16d545",
                "text": "None of the above",
                "exclusive": true
            }
        ],
        "id": "P-1",
        "text": "Which of the following best describes your beliefs that everyone else claimed to be untrue or impossible? Please select all that apply.",
        "isMultiselect": true
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "aff33850-dfdd-51fe-96de-136c298a3894",
                "text": "I know they were false",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "fb76bfd1-76cd-5888-838b-49749d113e99",
                "text": "I am not quite sure if they were true",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "68f70517-4c97-5340-93dd-b79eebf6d594",
                "text": "I think they were probably true",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "8797dc78-1465-551b-adf1-9a9b616b2818",
                "text": "I know for certain that they were true ",
                "value": 1
            }
        ],
        "id": "P-8",
        "text": "How certain were you that these beliefs or experiences were true?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a06a0df5-f1a6-5d78-a869-6867a70cf56a",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c1521e66-a422-5675-afca-b3a23a7ac8dc",
                "text": "Yes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "f0c19643-aa63-5752-9797-fe7f87bd4d42",
                "text": "I am not sure",
                "value": 1
            }
        ],
        "id": "P-8.5",
        "text": "Are these beliefs or experiences shared by any groups you are a part of? For example, are they also held by a religious or cultural group to which you belong?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "61ae818a-e9b7-5b6e-81e2-fe8ff4956c28",
                "text": "Not any more than others",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "47b2f4ab-6f1e-529a-8de4-dc0b4af0905e",
                "text": "Yes, to a small extent",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "4b7e41e5-7c75-5000-84c3-aa1eef851fd8",
                "text": "Yes, somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "440b6e79-aa4f-5d30-8830-93db28db0080",
                "text": "Yes, very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "21fa3fb8-4df5-5e40-9d3d-466de786b439",
                "text": "Yes, to an extreme extent",
                "value": 1
            }
        ],
        "id": "P-9",
        "text": "Have these beliefs or experiences ever got you in trouble or made you avoid social situations, either online or face-to-face?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "948efbb7-c1f9-5927-926b-ea990488d997",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "50570c73-4998-522a-9f38-8c250f0b4870",
                "text": "Yes, I have heard voices",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "1e84b880-6bc4-56ef-a39c-861146a22d2b",
                "text": "Yes, I have heard sounds",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "2d131e4d-a23b-5c69-9c1f-5238481f9ff0",
                "text": "Yes, I have heard voices and sounds",
                "value": 1
            }
        ],
        "id": "P-10",
        "text": "Have you ever heard unexplainable voices or sounds when no one was around?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6701d5c3-af37-5f1d-ac7f-b7682f874d88",
                "text": "Commenting on your thoughts or behaviour"
            },
            {
                "__typename": "Option",
                "id": "60a5f05a-3416-5c24-8dc8-286e54eded0f",
                "text": "Talking to each other"
            },
            {
                "__typename": "Option",
                "id": "934e3ebb-020c-5866-aacb-a15757ba361e",
                "text": "Telling you what to do"
            },
            {
                "__typename": "Option",
                "id": "931561b0-dfa5-58e4-a364-2f2fba8dbb76",
                "text": "Insulting you or threatening you"
            },
            {
                "__typename": "Option",
                "id": "63fd1433-c54c-5919-b065-9ba501432f0a",
                "text": "Something not mentioned above"
            },
            {
                "__typename": "Option",
                "id": "ad6e160d-4c46-5259-950d-f91cfc4b4918",
                "text": "None of the above",
                "exclusive": true
            }
        ],
        "id": "P-10.1",
        "text": "You said you have heard voices when no one else was around. Did you experience them doing any of the following? Please select all that apply.",
        "isMultiselect": true
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "46ce36c6-a6ae-5b8d-8153-3a81180a1794",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "da4433ed-e848-550b-bdd9-39388bcde9df",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "P-11",
        "text": "Have other people said they cannot hear these voices or sounds?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a84f9382-3a31-5d0c-bec1-78a620f305a8",
                "text": "No, not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "21113b65-0b4d-5940-b8d9-83cfb0a3868e",
                "text": "Yes, to a small extent",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "01c44680-7676-5bca-829e-d9cc7f45d38b",
                "text": "Yes, somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "852e4d61-d1aa-5572-8b01-9d18239300dc",
                "text": "Yes, very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "b4162925-93a1-5d13-a982-01df565c634e",
                "text": "Yes, to an extreme extent",
                "value": 1
            }
        ],
        "id": "P-12",
        "text": "Did these voices or sounds that no one else could hear cause you a great deal of distress?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "d8d37d7c-f5c1-552a-86e3-eb624667b794",
                "text": "Not any more than usual",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "03dea62b-8d69-5468-8f3f-ac2f048d439d",
                "text": "Yes, to a small extent",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "24f64a32-4946-58dd-9650-198f1f101558",
                "text": "Yes, somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "a7e245dc-06d7-510d-ab65-047a2d519068",
                "text": "Yes, very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "7c6020eb-9e56-596b-9a1c-164840917df1",
                "text": "Yes, to an extreme extent",
                "value": 1
            }
        ],
        "id": "P-13",
        "text": "Did the voices or sounds that no one else could hear make it hard to concentrate?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "91733703-99a3-5bfe-8594-37294663d33c",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ddf3bc5f-45a4-59e2-af71-ec9206686f5e",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "P-14",
        "text": "While being fully awake, have you ever had visions or seen things that other people could not see?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ab353641-6eb3-51f4-a237-37264dae683e",
                "text": "Not at all scary or distressing",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7caf039d-925a-52dc-afb2-8cc42e72e537",
                "text": "Slightly scary or distressing",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "077fe7f1-c94c-5422-8dde-3ba3a5cfa1cc",
                "text": "Somewhat scary or distressing",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "bb2712bc-d3a6-5b66-a7f1-e3f823742776",
                "text": "Very scary or distressing",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "27dd9cd9-f58f-5881-8028-4e1fd8cc8b8e",
                "text": "Extremely scary or distressing",
                "value": 1
            }
        ],
        "id": "P-15",
        "text": "How distressing or scary were these visions that no one else could see?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "33e1884d-346b-55a1-9ffe-9eb98a66307a",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "cc6abf84-fdcb-5fd0-9073-ffd1d19fd3e8",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "P-16",
        "text": "Some people notice strange smells or tastes that other people are unaware of. Is this something that has happened to you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "fe1965b1-472d-512e-a4df-f11c5f97a9f7",
                "text": "I am experiencing them at the moment",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "e6d49d14-493d-577d-ae4d-d01a9ed811dd",
                "text": "In the last week",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "1c9ccc9d-580c-5254-b388-c6963f9c4f38",
                "text": "In the last month",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "f377b4ff-5afe-5f67-8cc1-49b006063262",
                "text": "In the last year",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "0afed356-d53d-59a5-83b6-a6d3f6667129",
                "text": "Several years ago",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "abbfc8c8-8fea-5212-90ec-6d96fa522603",
                "text": "Decades ago",
                "value": 1
            }
        ],
        "id": "P-17",
        "text": "You mentioned that you have had some unusual thoughts or experiences, such as thinking you are more important that others, seeing or hearing things that no one else can, or thinking that others can hear your thoughts.\n\nApproximately when did these beliefs or experiences start?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "P-18",
        "text": "How long did it take from the time you first had the beliefs or experiences to the time they stopped completely and didn't return?",
        "unit": censeo_1.Unit.DurationMinutes
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "884145d1-bb81-5bd8-83d7-0f7e50f5ac1a",
                "text": "In the last couple of weeks",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d769295c-c6b4-5794-9bf4-edc732d602c8",
                "text": "A month ago",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "b6b7d9e6-0fb5-53d1-8bc0-c59ab9e5a767",
                "text": "Several months ago",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "59621fe0-d7f7-5376-8349-d2e9aeef0119",
                "text": "In the last year",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "5ea8a949-f8ab-50ee-b925-eab3db9d05a0",
                "text": "Several years ago",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "6a6b0064-8980-5160-8a72-8f84497df01c",
                "text": "Decades ago",
                "value": 1
            }
        ],
        "id": "P-19",
        "text": "Approximately, when did you last have these beliefs (e.g. thinking your thoughts could be read by others) or experiences (e.g. seeing or hearing things that no one else can)?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "4d7f84b8-e916-54f9-ad2e-84726c1112d2",
                "text": "They have only ever occurred during a period of feeling 'very low'",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "1cf436b7-34b9-562b-966f-8f33c82a0930",
                "text": "They have only ever occurred during a period of feeling very 'up' or 'hyper'",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "8edcffd0-696e-5c38-a606-16d46b768cb9",
                "text": "They seem unrelated to my mood",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "21b7e519-0939-5691-bee9-265027ada775",
                "text": "I am not sure",
                "value": 1
            }
        ],
        "id": "P-20",
        "text": "Some people only have these beliefs or experiences during times when their mood is 'very low' or when they feel very 'up' or 'hyper'. Is this something you can identify with?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a4a00eec-10fa-5c1c-b4ff-3842aaf2e517",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ee604d74-bf61-53ed-921e-25bc321ebb69",
                "text": "Yes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "457f40b9-1409-50a8-ae61-860d8e492eb3",
                "text": "I am not sure",
                "value": 1
            }
        ],
        "id": "P-21",
        "text": "Did these experiences or beliefs only occur in the context of taking certain drugs or substances? This could be substances such as MDMA, cocaine, amphetamines, methamphetamines, cannabis, alcohol, LSD or 'magic mushrooms'.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "05993c42-a9c2-59cf-bbd8-32a1b8159b1f",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "e15af503-7259-5384-a744-c2e08d529c44",
                "text": "Yes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "d5f166e9-fe1e-5b24-a39d-c4ec26b7312b",
                "text": "I am not sure",
                "value": 1
            }
        ],
        "id": "P-22",
        "text": "Were these experiences or beliefs brought on by taking prescription drugs? This could be drugs such as antibiotics, anti-malarial drugs, ADHD drugs, opioids, antidepressants or sedatives.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "bbd15824-83c7-568e-80fb-ef90a4940158",
                "text": "Basic needs such as eating and sleeping"
            },
            {
                "__typename": "Option",
                "id": "93f3f978-d21f-5c06-8225-8d7a69112f76",
                "text": "Working or studying"
            },
            {
                "__typename": "Option",
                "id": "9d9e0e60-87d9-5d02-ab73-63bc3d19b120",
                "text": "Other aspects of my life not mentioned above"
            },
            {
                "__typename": "Option",
                "id": "f7c15310-f5de-403e-946f-88b613b696de",
                "text": "No areas of my life are affected by these unusual experiences of beliefs",
                "exclusive": true
            }
        ],
        "id": "P-23",
        "text": "Which areas of your life, if any, did you feel were disrupted by these unusual experiences or beliefs? Please select all that apply.",
        "isMultiselect": true
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a159e629-4bd4-50bd-aa38-972050915c3b",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "81c10138-67e0-58b8-9b02-e20473ba4c05",
                "text": "To a slight extent",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "c4578377-d25c-50db-aa9b-876271a1ca23",
                "text": "To a moderate extent",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "1dc197b0-71ea-5b5f-9525-04118c59921f",
                "text": "To a significant extent",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "f711c07d-0cce-5fdb-952b-4bf5e4436de2",
                "text": "To an extreme extent",
                "value": 1
            }
        ],
        "id": "P-23.0",
        "text": "To what extent did these unusual beliefs or experiences affect these areas of your life, or required you to make additional effort to maintain functioning in those areas?",
        "isMultiselect": false
    }
];
exports.aspects = {
    "pso_ce": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_psychosis"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "p_c"
                }
            }
        ],
        "anyToAll": 1
    },
    "pso_pe": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_psychosis"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "p_p"
                }
            }
        ],
        "anyToAll": 1
    },
    "has_psychosis": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "p_duration"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "p_symptoms"
                }
            }
        ],
        "anyToAll": 1
    },
    "p_symptoms": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "p_delusions"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "p_hallucinations"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "p_delusions": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "P-s1",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "fe9c935a-f525-5a45-980a-13d21e4c1b28": false,
                            "185cf99e-324a-580e-8ca4-ee1fedf8eaa5": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "P1_powers"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "P1_famous"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "P1_persecution"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "P1_control"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "P1_mindreading"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "P1_referencing"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "P1_sensation"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "P1_none"
                }
            }
        ],
        "anyToAll": 0.1111111111111111
    },
    "p_hallucinations": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "P-10",
                            "options": {
                                "type": "severities",
                                "severities": {
                                    "948efbb7-c1f9-5927-926b-ea990488d997": 0.03557118927263617,
                                    "50570c73-4998-522a-9f38-8c250f0b4870": 0.5,
                                    "1e84b880-6bc4-56ef-a39c-861146a22d2b": 0.9644288107273639,
                                    "2d131e4d-a23b-5c69-9c1f-5238481f9ff0": 0.9987706013787226
                                }
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "P-11",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "46ce36c6-a6ae-5b8d-8153-3a81180a1794": false,
                                    "da4433ed-e848-550b-bdd9-39388bcde9df": true
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "P-14",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "91733703-99a3-5bfe-8594-37294663d33c": false,
                                    "ddf3bc5f-45a4-59e2-af71-ec9206686f5e": true
                                }
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "P-16",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "33e1884d-346b-55a1-9ffe-9eb98a66307a": false,
                                    "cc6abf84-fdcb-5fd0-9073-ffd1d19fd3e8": true
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            }
        ],
        "anyToAll": 0.5
    },
    "p_duration": {
        "type": "input",
        "signal": {
            "type": "SpecificNumberInput",
            "questionId": "P-18",
            "input": 2,
            "operator": ">="
        }
    },
    "p_not_substance_induced": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "P-21",
            "options": {
                "type": "predicates",
                "predicates": {
                    "a4a00eec-10fa-5c1c-b4ff-3842aaf2e517": true,
                    "ee604d74-bf61-53ed-921e-25bc321ebb69": false,
                    "457f40b9-1409-50a8-ae61-860d8e492eb3": false
                }
            }
        }
    },
    "p_not_medication_induced": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "P-22",
            "options": {
                "type": "predicates",
                "predicates": {
                    "05993c42-a9c2-59cf-bbd8-32a1b8159b1f": true,
                    "e15af503-7259-5384-a744-c2e08d529c44": false,
                    "d5f166e9-fe1e-5b24-a39d-c4ec26b7312b": false
                }
            }
        }
    },
    "p_c": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "P-19",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "884145d1-bb81-5bd8-83d7-0f7e50f5ac1a": 0.9975273768433652,
                            "d769295c-c6b4-5794-9bf4-edc732d602c8": 0.9820137900379085,
                            "b6b7d9e6-0fb5-53d1-8bc0-c59ab9e5a767": 0.8807970779778824,
                            "59621fe0-d7f7-5376-8349-d2e9aeef0119": 0.5,
                            "5ea8a949-f8ab-50ee-b925-eab3db9d05a0": 0.11920292202211746,
                            "6a6b0064-8980-5160-8a72-8f84497df01c": 0.01798620996209155
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "P-17",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "fe1965b1-472d-512e-a4df-f11c5f97a9f7": true,
                            "e6d49d14-493d-577d-ae4d-d01a9ed811dd": false,
                            "1c9ccc9d-580c-5254-b388-c6963f9c4f38": false,
                            "f377b4ff-5afe-5f67-8cc1-49b006063262": false,
                            "0afed356-d53d-59a5-83b6-a6d3f6667129": false,
                            "abbfc8c8-8fea-5212-90ec-6d96fa522603": false
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "p_p": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "p_c"
            }
        }
    },
    "P1_powers": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "P-1",
            "options": {
                "type": "severities",
                "severities": {
                    "92de7cae-28f3-58f4-8d55-d550b0926cce": 1
                }
            }
        }
    },
    "P1_famous": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "P-1",
            "options": {
                "type": "severities",
                "severities": {
                    "f6861254-2a8d-5a0c-b77d-451de537a402": 1
                }
            }
        }
    },
    "P1_persecution": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "P-1",
            "options": {
                "type": "severities",
                "severities": {
                    "49a9e0be-f179-5a9b-b636-94800133eb7d": 1
                }
            }
        }
    },
    "P1_control": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "P-1",
            "options": {
                "type": "severities",
                "severities": {
                    "a88a1596-13eb-5b68-8059-14a5457a3e80": 1
                }
            }
        }
    },
    "P1_mindreading": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "P-1",
            "options": {
                "type": "severities",
                "severities": {
                    "ffe61661-4b61-5c35-8953-a4f5931f07eb": 1
                }
            }
        }
    },
    "P1_referencing": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "P-1",
            "options": {
                "type": "severities",
                "severities": {
                    "91337a60-b5cd-5ad4-9da7-147a82ad9981": 1
                }
            }
        }
    },
    "P1_sensation": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "P-1",
            "options": {
                "type": "severities",
                "severities": {
                    "8c7def60-8271-53a0-9d16-69792c21b125": 1
                }
            }
        }
    },
    "P1_none": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "P-1",
            "options": {
                "type": "severities",
                "severities": {
                    "70762907-ef8b-5b48-ae7c-f8045b16d545": 1
                }
            }
        }
    },
    "P10.1_comment": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "P-10.1",
            "options": {
                "type": "severities",
                "severities": {
                    "6701d5c3-af37-5f1d-ac7f-b7682f874d88": 1
                }
            }
        }
    },
    "P10.1_talking": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "P-10.1",
            "options": {
                "type": "severities",
                "severities": {
                    "60a5f05a-3416-5c24-8dc8-286e54eded0f": 1
                }
            }
        }
    },
    "P10.1_instructing": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "P-10.1",
            "options": {
                "type": "severities",
                "severities": {
                    "934e3ebb-020c-5866-aacb-a15757ba361e": 1
                }
            }
        }
    },
    "P10.1_insulting": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "P-10.1",
            "options": {
                "type": "severities",
                "severities": {
                    "931561b0-dfa5-58e4-a364-2f2fba8dbb76": 1
                }
            }
        }
    },
    "P10.1_other": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "P-10.1",
            "options": {
                "type": "severities",
                "severities": {
                    "63fd1433-c54c-5919-b065-9ba501432f0a": 1
                }
            }
        }
    },
    "P10.1_": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "P-10.1",
            "options": {
                "type": "severities",
                "severities": {
                    "ad6e160d-4c46-5259-950d-f91cfc4b4918": 1
                }
            }
        }
    },
    "P23_basic": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "P-23",
            "options": {
                "type": "severities",
                "severities": {
                    "bbd15824-83c7-568e-80fb-ef90a4940158": 1
                }
            }
        }
    },
    "P23_work": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "P-23",
            "options": {
                "type": "severities",
                "severities": {
                    "93f3f978-d21f-5c06-8225-8d7a69112f76": 1
                }
            }
        }
    },
    "PD23_other": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "P-23",
            "options": {
                "type": "severities",
                "severities": {
                    "9d9e0e60-87d9-5d02-ab73-63bc3d19b120": 1
                }
            }
        }
    },
    "P23_none": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "P-23",
            "options": {
                "type": "severities",
                "severities": {
                    "f7c15310-f5de-403e-946f-88b613b696de": 1
                }
            }
        }
    },
    "not(P-s1=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "P-s1",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28": true,
                        "185cf99e-324a-580e-8ca4-ee1fedf8eaa5": false
                    }
                }
            }
        }
    },
    "and[not(P-10=0.66), not(P-10=0)]": {
        "type": "merge",
        "wires": [
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AnsweredOption",
                        "questionId": "P-10",
                        "options": {
                            "type": "predicates",
                            "predicates": {
                                "948efbb7-c1f9-5927-926b-ea990488d997": false,
                                "50570c73-4998-522a-9f38-8c250f0b4870": false,
                                "1e84b880-6bc4-56ef-a39c-861146a22d2b": true,
                                "2d131e4d-a23b-5c69-9c1f-5238481f9ff0": false
                            }
                        }
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AnsweredOption",
                        "questionId": "P-10",
                        "options": {
                            "type": "predicates",
                            "predicates": {
                                "948efbb7-c1f9-5927-926b-ea990488d997": true,
                                "50570c73-4998-522a-9f38-8c250f0b4870": false,
                                "1e84b880-6bc4-56ef-a39c-861146a22d2b": false,
                                "2d131e4d-a23b-5c69-9c1f-5238481f9ff0": false
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "or[P-10=0.66, and[not(P-10=0.66), not(P-10=0)]]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "P-10",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "948efbb7-c1f9-5927-926b-ea990488d997": false,
                            "50570c73-4998-522a-9f38-8c250f0b4870": false,
                            "1e84b880-6bc4-56ef-a39c-861146a22d2b": true,
                            "2d131e4d-a23b-5c69-9c1f-5238481f9ff0": false
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "and[not(P-10=0.66), not(P-10=0)]"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "not(P-14=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "P-14",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "91733703-99a3-5bfe-8594-37294663d33c": true,
                        "ddf3bc5f-45a4-59e2-af71-ec9206686f5e": false
                    }
                }
            }
        }
    },
    "and[or[isUndefined(and[P-s1=0, P-10=0, P-14=0, P-16=0]), not(and[P-s1=0, P-10=0, P-14=0, P-16=0])], not(isUndefined(P-16=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "P-s1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "fe9c935a-f525-5a45-980a-13d21e4c1b28": true,
                                                "185cf99e-324a-580e-8ca4-ee1fedf8eaa5": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "P-10",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "948efbb7-c1f9-5927-926b-ea990488d997": true,
                                                "50570c73-4998-522a-9f38-8c250f0b4870": false,
                                                "1e84b880-6bc4-56ef-a39c-861146a22d2b": false,
                                                "2d131e4d-a23b-5c69-9c1f-5238481f9ff0": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "P-14",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "91733703-99a3-5bfe-8594-37294663d33c": true,
                                                "ddf3bc5f-45a4-59e2-af71-ec9206686f5e": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "P-16",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "33e1884d-346b-55a1-9ffe-9eb98a66307a": true,
                                                "cc6abf84-fdcb-5fd0-9073-ffd1d19fd3e8": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "P-s1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "fe9c935a-f525-5a45-980a-13d21e4c1b28": true,
                                                "185cf99e-324a-580e-8ca4-ee1fedf8eaa5": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "P-10",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "948efbb7-c1f9-5927-926b-ea990488d997": true,
                                                "50570c73-4998-522a-9f38-8c250f0b4870": false,
                                                "1e84b880-6bc4-56ef-a39c-861146a22d2b": false,
                                                "2d131e4d-a23b-5c69-9c1f-5238481f9ff0": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "P-14",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "91733703-99a3-5bfe-8594-37294663d33c": true,
                                                "ddf3bc5f-45a4-59e2-af71-ec9206686f5e": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "P-16",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "33e1884d-346b-55a1-9ffe-9eb98a66307a": true,
                                                "cc6abf84-fdcb-5fd0-9073-ffd1d19fd3e8": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "P-16",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "33e1884d-346b-55a1-9ffe-9eb98a66307a": true,
                                    "cc6abf84-fdcb-5fd0-9073-ffd1d19fd3e8": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[or[isUndefined(P-17=0), not(P-17=0)], not(isUndefined(P-18=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "P-17",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "fe1965b1-472d-512e-a4df-f11c5f97a9f7": true,
                                        "e6d49d14-493d-577d-ae4d-d01a9ed811dd": false,
                                        "1c9ccc9d-580c-5254-b388-c6963f9c4f38": false,
                                        "f377b4ff-5afe-5f67-8cc1-49b006063262": false,
                                        "0afed356-d53d-59a5-83b6-a6d3f6667129": false,
                                        "abbfc8c8-8fea-5212-90ec-6d96fa522603": false
                                    }
                                }
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "P-17",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "fe1965b1-472d-512e-a4df-f11c5f97a9f7": true,
                                        "e6d49d14-493d-577d-ae4d-d01a9ed811dd": false,
                                        "1c9ccc9d-580c-5254-b388-c6963f9c4f38": false,
                                        "f377b4ff-5afe-5f67-8cc1-49b006063262": false,
                                        "0afed356-d53d-59a5-83b6-a6d3f6667129": false,
                                        "abbfc8c8-8fea-5212-90ec-6d96fa522603": false
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "SpecificNumberInput",
                            "questionId": "P-18",
                            "input": 0,
                            "operator": "="
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[or[isUndefined(P23_none=1), not(P23_none=1)], not(isUndefined(P-23=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "P23_none"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "P23_none"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "P-23",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "bbd15824-83c7-568e-80fb-ef90a4940158": false,
                                    "93f3f978-d21f-5c06-8225-8d7a69112f76": false,
                                    "9d9e0e60-87d9-5d02-ab73-63bc3d19b120": false,
                                    "f7c15310-f5de-403e-946f-88b613b696de": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    }
};
exports.tests = {
    "pso_ce": [
        {
            "name": "screened user: Z3RPNEO aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: NEL6L3V aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: K39ZL3L aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6PE670L aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MRME6 aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PMG3W aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7OXEL aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YX60R aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6EDLX3M aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0KG5E1 aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: NELRPEV aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZQN3R aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: REO9232 aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N6P0Q aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E148ED aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8G2EW aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6LM0L aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: K39ML0L aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7VX0L aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGQQ0P aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EW2K38 aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YR40R aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y329ME8 aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VZ63L aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZJN0R aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8D2EW aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NN50Q aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10QKZ0P aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7ZR3L aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8PLEW aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0K1931 aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3J1D0Y aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10QJ8EP aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3P66EW aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32OM08 aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME84L3W aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NO53Q aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: K392M0L aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6EDJMEM aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10Q6R0P aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0KPDE1 aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YPO3R aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23X6V0N aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JO40Y aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGGGEP aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: R04JV34 aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EWGK38 aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0K9D01 aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGLK3P aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: NEL9J3V aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE694EL aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VGM0L aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32QME8 aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6NMEL aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z356NEO aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XV2EN aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R443O aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6EDW60M aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JV43Y aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63Y843R aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7DM0L aspect: pso_ce should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "P-1": {
                    "questionId": "P-1",
                    "chosenOptionIds": [
                        "49a9e0be-f179-5a9b-b636-94800133eb7d",
                        "a88a1596-13eb-5b68-8059-14a5457a3e80",
                        "ffe61661-4b61-5c35-8953-a4f5931f07eb",
                        "8c7def60-8271-53a0-9d16-69792c21b125"
                    ]
                },
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-17": {
                    "questionId": "P-17",
                    "chosenOptionIds": [
                        "0afed356-d53d-59a5-83b6-a6d3f6667129"
                    ]
                },
                "P-18": {
                    "questionId": "P-18",
                    "freeValue": 1825
                },
                "P-19": {
                    "questionId": "P-19",
                    "chosenOptionIds": [
                        "59621fe0-d7f7-5376-8349-d2e9aeef0119"
                    ]
                },
                "P-20": {
                    "questionId": "P-20",
                    "chosenOptionIds": [
                        "8edcffd0-696e-5c38-a606-16d46b768cb9"
                    ]
                },
                "P-21": {
                    "questionId": "P-21",
                    "chosenOptionIds": [
                        "a4a00eec-10fa-5c1c-b4ff-3842aaf2e517"
                    ]
                },
                "P-22": {
                    "questionId": "P-22",
                    "chosenOptionIds": [
                        "05993c42-a9c2-59cf-bbd8-32a1b8159b1f"
                    ]
                },
                "P-23": {
                    "questionId": "P-23",
                    "chosenOptionIds": [
                        "bbd15824-83c7-568e-80fb-ef90a4940158",
                        "93f3f978-d21f-5c06-8225-8d7a69112f76",
                        "9d9e0e60-87d9-5d02-ab73-63bc3d19b120"
                    ]
                },
                "P-23.0": {
                    "questionId": "P-23.0",
                    "chosenOptionIds": [
                        "1dc197b0-71ea-5b5f-9525-04118c59921f"
                    ]
                },
                "P-8": {
                    "questionId": "P-8",
                    "chosenOptionIds": [
                        "8797dc78-1465-551b-adf1-9a9b616b2818"
                    ]
                },
                "P-8.5": {
                    "questionId": "P-8.5",
                    "chosenOptionIds": [
                        "c1521e66-a422-5675-afca-b3a23a7ac8dc"
                    ]
                },
                "P-9": {
                    "questionId": "P-9",
                    "chosenOptionIds": [
                        "440b6e79-aa4f-5d30-8830-93db28db0080"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "185cf99e-324a-580e-8ca4-ee1fedf8eaa5"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32LM08 aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3J4P0Y aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PX60W aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: REOQG32 aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VQM3L aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35L10O aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE72M0L aspect: pso_ce should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "P-1": {
                    "questionId": "P-1",
                    "chosenOptionIds": [
                        "92de7cae-28f3-58f4-8d55-d550b0926cce",
                        "91337a60-b5cd-5ad4-9da7-147a82ad9981",
                        "49a9e0be-f179-5a9b-b636-94800133eb7d",
                        "f6861254-2a8d-5a0c-b77d-451de537a402"
                    ]
                },
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-17": {
                    "questionId": "P-17",
                    "chosenOptionIds": [
                        "0afed356-d53d-59a5-83b6-a6d3f6667129"
                    ]
                },
                "P-18": {
                    "questionId": "P-18",
                    "freeValue": 1095
                },
                "P-19": {
                    "questionId": "P-19",
                    "chosenOptionIds": [
                        "b6b7d9e6-0fb5-53d1-8bc0-c59ab9e5a767"
                    ]
                },
                "P-20": {
                    "questionId": "P-20",
                    "chosenOptionIds": [
                        "4d7f84b8-e916-54f9-ad2e-84726c1112d2"
                    ]
                },
                "P-21": {
                    "questionId": "P-21",
                    "chosenOptionIds": [
                        "a4a00eec-10fa-5c1c-b4ff-3842aaf2e517"
                    ]
                },
                "P-22": {
                    "questionId": "P-22",
                    "chosenOptionIds": [
                        "05993c42-a9c2-59cf-bbd8-32a1b8159b1f"
                    ]
                },
                "P-23": {
                    "questionId": "P-23",
                    "chosenOptionIds": [
                        "93f3f978-d21f-5c06-8225-8d7a69112f76",
                        "9d9e0e60-87d9-5d02-ab73-63bc3d19b120"
                    ]
                },
                "P-23.0": {
                    "questionId": "P-23.0",
                    "chosenOptionIds": [
                        "81c10138-67e0-58b8-9b02-e20473ba4c05"
                    ]
                },
                "P-8": {
                    "questionId": "P-8",
                    "chosenOptionIds": [
                        "fb76bfd1-76cd-5888-838b-49749d113e99"
                    ]
                },
                "P-8.5": {
                    "questionId": "P-8.5",
                    "chosenOptionIds": [
                        "f0c19643-aa63-5752-9797-fe7f87bd4d42"
                    ]
                },
                "P-9": {
                    "questionId": "P-9",
                    "chosenOptionIds": [
                        "4b7e41e5-7c75-5000-84c3-aa1eef851fd8"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "185cf99e-324a-580e-8ca4-ee1fedf8eaa5"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8MOEW aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-1": {
                    "questionId": "P-1",
                    "chosenOptionIds": [
                        "70762907-ef8b-5b48-ae7c-f8045b16d545"
                    ]
                },
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-17": {
                    "questionId": "P-17",
                    "chosenOptionIds": [
                        "0afed356-d53d-59a5-83b6-a6d3f6667129"
                    ]
                },
                "P-18": {
                    "questionId": "P-18",
                    "freeValue": 0
                },
                "P-19": {
                    "questionId": "P-19",
                    "chosenOptionIds": [
                        "b6b7d9e6-0fb5-53d1-8bc0-c59ab9e5a767"
                    ]
                },
                "P-20": {
                    "questionId": "P-20",
                    "chosenOptionIds": [
                        "21b7e519-0939-5691-bee9-265027ada775"
                    ]
                },
                "P-21": {
                    "questionId": "P-21",
                    "chosenOptionIds": [
                        "457f40b9-1409-50a8-ae61-860d8e492eb3"
                    ]
                },
                "P-22": {
                    "questionId": "P-22",
                    "chosenOptionIds": [
                        "d5f166e9-fe1e-5b24-a39d-c4ec26b7312b"
                    ]
                },
                "P-23": {
                    "questionId": "P-23",
                    "chosenOptionIds": [
                        "93f3f978-d21f-5c06-8225-8d7a69112f76",
                        "bbd15824-83c7-568e-80fb-ef90a4940158",
                        "9d9e0e60-87d9-5d02-ab73-63bc3d19b120"
                    ]
                },
                "P-23.0": {
                    "questionId": "P-23.0",
                    "chosenOptionIds": [
                        "c4578377-d25c-50db-aa9b-876271a1ca23"
                    ]
                },
                "P-8": {
                    "questionId": "P-8",
                    "chosenOptionIds": [
                        "fb76bfd1-76cd-5888-838b-49749d113e99"
                    ]
                },
                "P-8.5": {
                    "questionId": "P-8.5",
                    "chosenOptionIds": [
                        "f0c19643-aa63-5752-9797-fe7f87bd4d42"
                    ]
                },
                "P-9": {
                    "questionId": "P-9",
                    "chosenOptionIds": [
                        "4b7e41e5-7c75-5000-84c3-aa1eef851fd8"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "185cf99e-324a-580e-8ca4-ee1fedf8eaa5"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35MQ3O aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZW63R aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EG443P aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-1": {
                    "questionId": "P-1",
                    "chosenOptionIds": [
                        "92de7cae-28f3-58f4-8d55-d550b0926cce",
                        "f6861254-2a8d-5a0c-b77d-451de537a402",
                        "a88a1596-13eb-5b68-8059-14a5457a3e80",
                        "91337a60-b5cd-5ad4-9da7-147a82ad9981",
                        "8c7def60-8271-53a0-9d16-69792c21b125"
                    ]
                },
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "1e84b880-6bc4-56ef-a39c-861146a22d2b"
                    ]
                },
                "P-11": {
                    "questionId": "P-11",
                    "chosenOptionIds": [
                        "da4433ed-e848-550b-bdd9-39388bcde9df"
                    ]
                },
                "P-12": {
                    "questionId": "P-12",
                    "chosenOptionIds": [
                        "01c44680-7676-5bca-829e-d9cc7f45d38b"
                    ]
                },
                "P-13": {
                    "questionId": "P-13",
                    "chosenOptionIds": [
                        "24f64a32-4946-58dd-9650-198f1f101558"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "ddf3bc5f-45a4-59e2-af71-ec9206686f5e"
                    ]
                },
                "P-15": {
                    "questionId": "P-15",
                    "chosenOptionIds": [
                        "077fe7f1-c94c-5422-8dde-3ba3a5cfa1cc"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-17": {
                    "questionId": "P-17",
                    "chosenOptionIds": [
                        "0afed356-d53d-59a5-83b6-a6d3f6667129"
                    ]
                },
                "P-18": {
                    "questionId": "P-18",
                    "freeValue": 4015
                },
                "P-19": {
                    "questionId": "P-19",
                    "chosenOptionIds": [
                        "5ea8a949-f8ab-50ee-b925-eab3db9d05a0"
                    ]
                },
                "P-20": {
                    "questionId": "P-20",
                    "chosenOptionIds": [
                        "21b7e519-0939-5691-bee9-265027ada775"
                    ]
                },
                "P-21": {
                    "questionId": "P-21",
                    "chosenOptionIds": [
                        "a4a00eec-10fa-5c1c-b4ff-3842aaf2e517"
                    ]
                },
                "P-22": {
                    "questionId": "P-22",
                    "chosenOptionIds": [
                        "05993c42-a9c2-59cf-bbd8-32a1b8159b1f"
                    ]
                },
                "P-23": {
                    "questionId": "P-23",
                    "chosenOptionIds": [
                        "9d9e0e60-87d9-5d02-ab73-63bc3d19b120"
                    ]
                },
                "P-23.0": {
                    "questionId": "P-23.0",
                    "chosenOptionIds": [
                        "81c10138-67e0-58b8-9b02-e20473ba4c05"
                    ]
                },
                "P-8": {
                    "questionId": "P-8",
                    "chosenOptionIds": [
                        "aff33850-dfdd-51fe-96de-136c298a3894"
                    ]
                },
                "P-8.5": {
                    "questionId": "P-8.5",
                    "chosenOptionIds": [
                        "a06a0df5-f1a6-5d78-a869-6867a70cf56a"
                    ]
                },
                "P-9": {
                    "questionId": "P-9",
                    "chosenOptionIds": [
                        "61ae818a-e9b7-5b6e-81e2-fe8ff4956c28"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "185cf99e-324a-580e-8ca4-ee1fedf8eaa5"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R6O0O aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: R047J04 aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME87V3W aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-1": {
                    "questionId": "P-1",
                    "chosenOptionIds": [
                        "49a9e0be-f179-5a9b-b636-94800133eb7d"
                    ]
                },
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "ddf3bc5f-45a4-59e2-af71-ec9206686f5e"
                    ]
                },
                "P-15": {
                    "questionId": "P-15",
                    "chosenOptionIds": [
                        "bb2712bc-d3a6-5b66-a7f1-e3f823742776"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-17": {
                    "questionId": "P-17",
                    "chosenOptionIds": [
                        "0afed356-d53d-59a5-83b6-a6d3f6667129"
                    ]
                },
                "P-18": {
                    "questionId": "P-18",
                    "freeValue": 0.0006944444444444444
                },
                "P-19": {
                    "questionId": "P-19",
                    "chosenOptionIds": [
                        "884145d1-bb81-5bd8-83d7-0f7e50f5ac1a"
                    ]
                },
                "P-20": {
                    "questionId": "P-20",
                    "chosenOptionIds": [
                        "21b7e519-0939-5691-bee9-265027ada775"
                    ]
                },
                "P-21": {
                    "questionId": "P-21",
                    "chosenOptionIds": [
                        "a4a00eec-10fa-5c1c-b4ff-3842aaf2e517"
                    ]
                },
                "P-22": {
                    "questionId": "P-22",
                    "chosenOptionIds": [
                        "05993c42-a9c2-59cf-bbd8-32a1b8159b1f"
                    ]
                },
                "P-23": {
                    "questionId": "P-23",
                    "chosenOptionIds": [
                        "9d9e0e60-87d9-5d02-ab73-63bc3d19b120"
                    ]
                },
                "P-23.0": {
                    "questionId": "P-23.0",
                    "chosenOptionIds": [
                        "c4578377-d25c-50db-aa9b-876271a1ca23"
                    ]
                },
                "P-8": {
                    "questionId": "P-8",
                    "chosenOptionIds": [
                        "8797dc78-1465-551b-adf1-9a9b616b2818"
                    ]
                },
                "P-8.5": {
                    "questionId": "P-8.5",
                    "chosenOptionIds": [
                        "a06a0df5-f1a6-5d78-a869-6867a70cf56a"
                    ]
                },
                "P-9": {
                    "questionId": "P-9",
                    "chosenOptionIds": [
                        "440b6e79-aa4f-5d30-8830-93db28db0080"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "185cf99e-324a-580e-8ca4-ee1fedf8eaa5"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E17NED aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE61J0L aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JK4EY aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EW4D08 aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3RLO3O aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JZREY aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7WX3L aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3P263W aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        },
        {
            "name": "screened user: NELXPEV aspect: pso_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "P-10": {
                    "questionId": "P-10",
                    "chosenOptionIds": [
                        "948efbb7-c1f9-5927-926b-ea990488d997"
                    ]
                },
                "P-14": {
                    "questionId": "P-14",
                    "chosenOptionIds": [
                        "91733703-99a3-5bfe-8594-37294663d33c"
                    ]
                },
                "P-16": {
                    "questionId": "P-16",
                    "chosenOptionIds": [
                        "33e1884d-346b-55a1-9ffe-9eb98a66307a"
                    ]
                },
                "P-S": {
                    "questionId": "P-S",
                    "chosenOptionIds": [
                        "205b8415-4a42-57b0-9a63-b56b6b6e5eba"
                    ]
                },
                "P-s1": {
                    "questionId": "P-s1",
                    "chosenOptionIds": [
                        "fe9c935a-f525-5a45-980a-13d21e4c1b28"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {
    "P-1": "not(P-s1=0)",
    "P-8": "not(P-s1=0)",
    "P-8.5": "not(P-s1=0)",
    "P-9": "not(P-s1=0)",
    "P-10.1": "and[not(P-10=0.66), not(P-10=0)]",
    "P-11": "or[P-10=0.66, and[not(P-10=0.66), not(P-10=0)]]",
    "P-12": "or[P-10=0.66, and[not(P-10=0.66), not(P-10=0)]]",
    "P-13": "or[P-10=0.66, and[not(P-10=0.66), not(P-10=0)]]",
    "P-15": "not(P-14=0)",
    "P-17": "and[or[isUndefined(and[P-s1=0, P-10=0, P-14=0, P-16=0]), not(and[P-s1=0, P-10=0, P-14=0, P-16=0])], not(isUndefined(P-16=0))]",
    "P-18": "and[or[isUndefined(and[P-s1=0, P-10=0, P-14=0, P-16=0]), not(and[P-s1=0, P-10=0, P-14=0, P-16=0])], not(isUndefined(P-16=0))]",
    "P-19": "and[or[isUndefined(P-17=0), not(P-17=0)], not(isUndefined(P-18=0))]",
    "P-20": "and[or[isUndefined(and[P-s1=0, P-10=0, P-14=0, P-16=0]), not(and[P-s1=0, P-10=0, P-14=0, P-16=0])], not(isUndefined(P-16=0))]",
    "P-21": "and[or[isUndefined(and[P-s1=0, P-10=0, P-14=0, P-16=0]), not(and[P-s1=0, P-10=0, P-14=0, P-16=0])], not(isUndefined(P-16=0))]",
    "P-22": "and[or[isUndefined(and[P-s1=0, P-10=0, P-14=0, P-16=0]), not(and[P-s1=0, P-10=0, P-14=0, P-16=0])], not(isUndefined(P-16=0))]",
    "P-23": "and[or[isUndefined(and[P-s1=0, P-10=0, P-14=0, P-16=0]), not(and[P-s1=0, P-10=0, P-14=0, P-16=0])], not(isUndefined(P-16=0))]",
    "P-23.0": "and[or[isUndefined(P23_none=1), not(P23_none=1)], not(isUndefined(P-23=0))]"
};
