import React from 'react'
import { Text, List, ListItem } from '@psyomics/components'
import Validation from 'registration/components/Validation'

interface PasswordRulesProps {
    hasMinLength: (password: string) => boolean
    hasUpperCase: (password: string) => boolean
    hasLowerCase: (password: string) => boolean
    hasNumber: (password: string) => boolean
    hasSpecialChar: (password: string) => boolean
}

export const passwordValidationFunctions: PasswordRulesProps = {
    hasMinLength: (password: string) => password.length >= 8,
    hasUpperCase: (password: string) => /[A-Z]/.test(password),
    hasLowerCase: (password: string) => /[a-z]/.test(password),
    hasNumber: (password: string) => /\d/.test(password),
    hasSpecialChar: (password: string) => /[!@#$%^&*(),.?":{}|<>]/.test(password),
}

const PasswordRules: React.FC<{ password?: string }> = ({ password }) => {
    const noPassword = password !== undefined
    return (
        <Text color="light" size="small">
            Your password must contain:
            <List spacing={1} css={{ mt: 2 }}>
                <ListItem>
                    <Validation applyStyle={noPassword && !passwordValidationFunctions.hasMinLength(password)}>
                        At least 8 characters
                    </Validation>
                </ListItem>
                <ListItem>
                    <Validation applyStyle={noPassword && !passwordValidationFunctions.hasUpperCase(password)}>
                        One uppercase character
                    </Validation>
                </ListItem>
                <ListItem>
                    <Validation applyStyle={noPassword && !passwordValidationFunctions.hasLowerCase(password)}>
                        One lowercase character
                    </Validation>
                </ListItem>
                <ListItem>
                    <Validation applyStyle={password !== undefined && !passwordValidationFunctions.hasNumber(password)}>
                        One numeric character
                    </Validation>
                </ListItem>
                <ListItem>
                    <Validation applyStyle={noPassword && !passwordValidationFunctions.hasSpecialChar(password)}>
                        One symbol character (* ! £ $ @)
                    </Validation>
                </ListItem>
            </List>
        </Text>
    )
}

export default PasswordRules
