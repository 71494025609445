"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "{}",
            "functionId": "Gad7Severity"
        },
        "section": [
            "Outcome"
        ]
    },
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "{}",
            "functionId": "Gad7ScoringKey"
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "1. Feeling nervous, anxious, or on edge | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "GAD7-1",
                    "optionSummaries": {
                        "4ea93ecb-d926-4892-91b8-9f873ef6185f": "0 - Not at all",
                        "2567389e-a4a4-4094-b502-7380094b5263": "1 - Several days",
                        "8aefd5f1-89d5-4707-882f-2937f9f01eba": "2 - More than half the days",
                        "e30ee916-3e11-4244-9937-1146940b7daa": "3 - Nearly every day"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "2. Not being able to stop or control worrying | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "GAD7-2",
                    "optionSummaries": {
                        "41bebfa2-4538-48c9-8e24-216858c44e72": "0 - Not at all",
                        "6e027877-b40e-4295-82f4-e43af97b58fa": "1 - Several days",
                        "693d81df-cf3f-431f-8d23-372150709059": "2 - More than half the days",
                        "3b223845-ad4d-43af-86a4-a47f1f3ee4e1": "3 - Nearly every day"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "3. Worrying too much about different things | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "GAD7-3",
                    "optionSummaries": {
                        "3187ac61-9092-4be7-9f8a-717b25ba838b": "0 - Not at all",
                        "c1c04481-505f-4e23-8ea8-16bb9bcdea91": "1 - Several days",
                        "e4a80d32-ec37-474e-bbdb-32ca13ceedf8": "2 - More than half the days",
                        "a433f33d-a253-489e-87a3-c9a5f9461439": "3 - Nearly every day"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "4. Trouble relaxing | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "GAD7-4",
                    "optionSummaries": {
                        "468f9470-c70a-4cd3-965e-2a5846877d04": "0 - Not at all",
                        "51f5beb8-e597-4d30-9bd0-2308f6220020": "1 - Several days",
                        "ee3eca9d-492b-413f-9390-2c805d12e454": "2 - More than half the days",
                        "b1e016a8-525c-4c18-975d-8976b256ac17": "3 - Nearly every day"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "5. Being so restless that it is hard to sit still | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "GAD7-5",
                    "optionSummaries": {
                        "78bfb5fc-7f66-4469-bb4c-e6c238e7fba3": "0 - Not at all",
                        "5568a010-48b1-49f2-8c37-ccbd557af5ad": "1 - Several days",
                        "478ec691-f333-4d6b-b361-da703c3b1e52": "2 - More than half the days",
                        "07829607-ad08-4dd8-b3b6-cc1dbad76977": "3 - Nearly every day"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "6. Becoming easily annoyed or irritable | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "GAD7-6",
                    "optionSummaries": {
                        "0d16f6f0-9ff0-46f1-98e4-904f187c9401": "0 - Not at all",
                        "4dfb954d-4745-4db6-ad48-cf85ee3a4905": "1 - Several days",
                        "bd6399ce-b73f-48d6-a8f5-5b0cd7c8de89": "2 - More than half the days",
                        "13141ab7-8a74-406d-a7eb-62e6d2e9e5e5": "3 - Nearly every day"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "7. Feeling afraid, as if something awful might happen | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "GAD7-7",
                    "optionSummaries": {
                        "102a45c2-8167-4f91-8dee-c0a8a4d155f3": "0 - Not at all",
                        "af1f3fd2-c52e-4905-8298-736e79132bb0": "1 - Several days",
                        "ee62bba3-c90f-4577-9497-c77e540a118d": "2 - More than half the days",
                        "49a0101b-0dd2-4731-a0ac-1ec58c7c02cd": "3 - Nearly every day"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    }
];
