import React, { useState } from 'react'
import { Input, Button, Text } from '@psyomics/components'
import Field from 'registration/components/Field'
import Label from 'registration/components/Label'
import Card from 'registration/components/Card'
import Auth from '@aws-amplify/auth'
import Validation from 'registration/components/Validation'
import { useAsync } from 'registration/hooks/use-async'
import { ResetPasswordFormProps } from '../use-ResetPassword'
import PasswordRules from 'ui/app/components/PasswordRules'

type IResetPassword = ResetPasswordFormProps & React.ComponentPropsWithoutRef<'div'>

const ResetPasswordEnterCode: React.FC<IResetPassword> = ({
    formikProps: { values, errors, touched, handleChange, handleBlur, validateForm },
    setPageState,
}) => {
    const asyncResetPass = useAsync()
    const [clickedSubmitCode, setClickedSubmitCode] = useState(false)

    return (
        <Card>
            <Text color="mid">
                A verification code has been sent to the email address you supplied. Enter it below, then choose a new
                password for your account.
            </Text>
            <Field>
                <Label htmlFor="code">Verification code</Label>
                <Input
                    type="text"
                    id="code"
                    name="code"
                    placeholder="Code"
                    appearance="outlined"
                    onChange={handleChange}
                    onKeyUp={handleChange}
                    onBlur={handleBlur}
                    value={values.code}
                />
                <Text size="small" color="light">
                    Didn&#39;t receive a code?{' '}
                    <Button
                        size="small"
                        appearance="link"
                        label="Resend it now"
                        onClick={() => {
                            Auth.forgotPassword(values.email)
                        }}
                    />
                </Text>
                {clickedSubmitCode && errors.code && <Validation>{errors.code}</Validation>}
            </Field>

            <Field>
                <Label htmlFor="password">Password</Label>
                <Input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Password"
                    appearance="outlined"
                    onChange={handleChange}
                    onKeyUp={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                />
                {clickedSubmitCode && errors.password && <Validation>{errors.password}</Validation>}
            </Field>
            <Field>
                <Label htmlFor="password">Confirm your password</Label>
                <Input
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder="Password"
                    appearance="outlined"
                    onChange={handleChange}
                    onKeyUp={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                />
                {clickedSubmitCode && errors.confirmPassword && <Validation>{errors.confirmPassword}</Validation>}
            </Field>
            <PasswordRules></PasswordRules>
            <Button
                type="submit"
                appearance="primary"
                fullWidth
                size="large"
                label="Continue"
                icon={null}
                disabled={!!asyncResetPass.isLoading}
                onClick={async () => {
                    setClickedSubmitCode(true)
                    await validateForm()
                    if (
                        touched.code &&
                        !errors.code &&
                        touched.password &&
                        !errors.password &&
                        touched.confirmPassword &&
                        !errors.confirmPassword
                    ) {
                        await asyncResetPass.run(
                            Auth.forgotPasswordSubmit(values.email, values.code, values.password).then(() =>
                                setPageState('success')
                            )
                        )
                    }
                }}
            />
            {asyncResetPass.isError && asyncResetPass.error && (
                <Validation>Reset password error: {asyncResetPass.error.message}</Validation>
            )}
        </Card>
    )
}

export default ResetPasswordEnterCode
