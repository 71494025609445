"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
exports.questions = [
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "1e2492d3-7e08-4287-a9f1-f3dbef2612b2",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "4c1c637b-7761-49a5-90af-e70832845e6d",
                "text": "Rarely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "b0ea051b-76ac-4fd3-9d8e-cad22d60f786",
                "text": "Sometimes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "ac3f5a3e-fd93-4202-9025-d4e78d22df04",
                "text": "Often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "f9e84075-ae14-47c6-8ac7-1c0cf7b57b3e",
                "text": "Very Often",
                "value": 1
            }
        ],
        "id": "ASRS1",
        "text": "How often do you have trouble wrapping up the final details of a project, once the challenging parts have been done?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "aa8e0ca0-41d7-454b-adb3-ca850d9ad836",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ac4acf1a-ec33-44f5-a740-ba737688b826",
                "text": "Rarely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "628d1f37-f6d5-4a30-b154-5ac3121b51a3",
                "text": "Sometimes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "bbafdd89-81bf-46c6-b7c0-ab9ede5f9b2b",
                "text": "Often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "e2d45793-c88e-437b-b8ac-c76a8110f0f5",
                "text": "Very Often",
                "value": 1
            }
        ],
        "id": "ASRS2",
        "text": "How often do you have difficulty getting things in order when you have to do a task that requires organization?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "97ef4ce5-b425-4b58-9a9c-c5f9fd00324f",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f4447b42-b9fc-47f2-94ad-0f5ebf292417",
                "text": "Rarely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "0a71cb5f-98cb-4130-9625-50c5cfa690ff",
                "text": "Sometimes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "ed30d4b1-99bf-4d1e-84c9-8af4a6136cf1",
                "text": "Often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "645ec227-aed2-4992-a639-fb14d6ccbdf2",
                "text": "Very Often",
                "value": 1
            }
        ],
        "id": "ASRS3",
        "text": "How often do you have problems remembering appointments or obligations?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "bb80c0a5-0acf-409d-877c-21e0eec4ba93",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "e7aa837c-3a8b-43ef-a87e-2c8b3b238cf5",
                "text": "Rarely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "1b3b13ea-b3ec-4aaa-92da-01058e86c4c6",
                "text": "Sometimes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "4b03fe6b-aaa6-4aaf-9524-f71f0af63967",
                "text": "Often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "3b178b28-a8cf-44a5-8d8c-a20e223350be",
                "text": "Very Often",
                "value": 1
            }
        ],
        "id": "ASRS4",
        "text": "When you have a task that requires a lot of thought, how often do you avoid or delay getting started?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "f30bde00-6fd5-45ce-98fe-feb2107c5a91",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "28c2c65e-f9b2-42bf-92a6-f94fb33a8531",
                "text": "Rarely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "c0469aa5-3511-4306-9e2a-769a8b2baa9d",
                "text": "Sometimes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "3bbad350-b4ae-4e9d-8b08-7caa5913fe8b",
                "text": "Often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "27597175-adf0-4d86-9371-613eebb40252",
                "text": "Very Often",
                "value": 1
            }
        ],
        "id": "ASRS5",
        "text": "How often do you fidget or squirm with your hands or feet when you have to sit down for a long time?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "66ed09ee-495b-47f4-a535-bff7f9d53970",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "3d35ce2e-1ff6-408f-a313-f77066649fcc",
                "text": "Rarely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "7fb4d290-f352-4a2e-bf70-868ce348f766",
                "text": "Sometimes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "2af14acb-3029-4501-8c38-0b15fd54d2f8",
                "text": "Often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "d63623e1-c39a-48a9-b55f-68c3507bf242",
                "text": "Very Often",
                "value": 1
            }
        ],
        "id": "ASRS6",
        "text": "How often do you feel overly active and compelled to do things, like you were driven by a motor?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ed15bfa8-d408-46ab-9525-b0b42e0d595a",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "762868bd-7c15-49e8-9bc7-f44eaf2d4c3e",
                "text": "Rarely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "c5c1c174-fccf-4b80-b7c2-7ffb478aad99",
                "text": "Sometimes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "43e2d851-93f5-4683-bf3a-81eca0801214",
                "text": "Often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "26a1feb5-3d03-4573-9cd2-e355a336260f",
                "text": "Very Often",
                "value": 1
            }
        ],
        "id": "ASRS7",
        "text": "How often do you make careless mistakes when you have to work on a boring or difficult project?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "d5030f6f-ad19-49ae-a0cb-db56d5ba31f9",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "93ab502c-59cd-4c37-9e46-7a93cc4c43b2",
                "text": "Rarely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "e8b36a33-1c26-4ba1-b171-dc23072cb4a2",
                "text": "Sometimes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "7fc9c7d8-2183-4f2e-9c06-68c07288c9c8",
                "text": "Often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "081d5f80-4ce9-490a-8ff4-be6ecba5a784",
                "text": "Very Often",
                "value": 1
            }
        ],
        "id": "ASRS8",
        "text": "How often do you have difficulty keeping your attention when you are doing boring or repetitive work?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "79de57bb-786b-49b7-ba5d-4eef37005d2c",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "804a74f9-d711-4ebd-8fb5-4006bde9a792",
                "text": "Rarely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "458cedfe-2e02-4ac2-aec6-dd12e8e97a15",
                "text": "Sometimes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "48eb803a-2a5b-4ec2-ae66-2b0c0e551ef8",
                "text": "Often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "88f28277-ed2f-4f03-8dc1-d0bf1f84b17d",
                "text": "Very Often",
                "value": 1
            }
        ],
        "id": "ASRS9",
        "text": "How often do you have difficulty concentrating on what people say to you, even when they are speaking to you directly?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "90526640-e201-488c-a16c-c84dcb771bc9",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "6f4d19eb-21f7-43f3-955e-f5e26ecde9da",
                "text": "Rarely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "62bac245-b02c-4cf1-9386-d4d4d9e3c342",
                "text": "Sometimes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "bfee8a4e-9d5a-417a-9991-b4d11a74e594",
                "text": "Often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "c7dffed4-c9b1-4262-8cd2-a75d8cc0d51b",
                "text": "Very Often",
                "value": 1
            }
        ],
        "id": "ASRS10",
        "text": "How often do you misplace or have difficulty finding things at home or at work?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "df93abe7-de34-4966-b517-baab39465ddd",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "bd51c1e5-2786-46c6-81ef-0fa63a08767a",
                "text": "Rarely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "30c661fd-c642-4211-a480-fd49accf8a20",
                "text": "Sometimes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "2a043db0-25ac-4029-adb3-27b0fec3cce4",
                "text": "Often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "831251ef-b279-4722-a3dd-e696b3fe7007",
                "text": "Very Often",
                "value": 1
            }
        ],
        "id": "ASRS11",
        "text": "How often are you distracted by activity or noise around you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "484c95df-070b-4b06-a210-c6aa2cb652c4",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b9133a66-1b0f-4b5a-930b-7a20395cb9b5",
                "text": "Rarely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "fe3ca6e9-9701-4ac3-ac92-71ba2d585c01",
                "text": "Sometimes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "32c8cd6c-c567-401b-8bdb-2c240950ca88",
                "text": "Often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "b0328927-6e29-4f25-9e5c-9223ec0b20b3",
                "text": "Very Often",
                "value": 1
            }
        ],
        "id": "ASRS12",
        "text": "How often do you leave your seat in meetings or other situations in which you are expected to remain seated?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "72820602-4ddb-437d-bb2b-020a8c6396e4",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7948b873-0e10-4166-8797-9653ef952ae2",
                "text": "Rarely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "dba1ca95-33e3-4134-881b-ca5c0dcb0e94",
                "text": "Sometimes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "2b63acfa-8485-4d21-aa86-7295f4e65c06",
                "text": "Often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "124f9b9b-a33b-4890-aec0-f2d225342b68",
                "text": "Very Often",
                "value": 1
            }
        ],
        "id": "ASRS13",
        "text": "How often do you feel restless or fidgety?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6c9bab2e-9d57-46f6-be81-0c931ed4e320",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "35a9bc08-10b9-42a8-b4de-7ecdbce0a0ee",
                "text": "Rarely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "2e71efd1-fcea-42f3-9cf7-428ffc0c5315",
                "text": "Sometimes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "345134c7-6418-4da6-81ef-f5aabf12a8d3",
                "text": "Often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "536a070b-a090-41f1-bd46-c59263d0eee2",
                "text": "Very Often",
                "value": 1
            }
        ],
        "id": "ASRS14",
        "text": "How often do you have difficulty unwinding and relaxing when you have time to yourself?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "3a809ef8-942a-4ee8-89f3-447dc5dc5d04",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d81d7d63-b185-46df-9fd5-6d32c89463ed",
                "text": "Rarely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "b150ca31-c022-4c5d-869c-867bd61d8d61",
                "text": "Sometimes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "8387ada7-92a4-46f9-9833-505cbb2568fe",
                "text": "Often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "ed4e7cf7-fd51-4dcf-b098-66b290160392",
                "text": "Very Often",
                "value": 1
            }
        ],
        "id": "ASRS15",
        "text": "How often do you find yourself talking too much when you are in social situations?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "afd3f876-98d1-4e90-892b-57d26f960c01",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "68b34045-9196-4026-9978-5287ab1448b6",
                "text": "Rarely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "357825a6-bfc8-4ed7-8915-d3ea0a265eb3",
                "text": "Sometimes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "7e05d75b-7ad3-4456-bd44-18ce686b7680",
                "text": "Often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "5e23b844-e348-441e-846f-f1732175c16c",
                "text": "Very Often",
                "value": 1
            }
        ],
        "id": "ASRS16",
        "text": "When you're in a conversation, how often do you find yourself finishing the sentences of the people you are talking to, before they can finish themselves?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "287f23de-4ddd-4976-a2a4-76028e6f89f1",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7ddd44b1-ace8-478b-a074-78b6c97f5780",
                "text": "Rarely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "931d1351-2285-4c95-8df4-83e41fb50689",
                "text": "Sometimes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "20c06007-6e2a-4fc3-8d13-fbfceec83daa",
                "text": "Often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "04a05b95-3c7f-40cd-9b70-b14292602fb1",
                "text": "Very Often",
                "value": 1
            }
        ],
        "id": "ASRS17",
        "text": "How often do you have difficulty waiting your turn in situations when turn taking is required?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "24e8ee51-b4e8-41e9-922b-fe93e03fe04c",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "0c1f5baf-cfec-4851-a6ab-3b57d211fbf5",
                "text": "Rarely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "dcfdba97-2862-419f-b106-61622f95faaf",
                "text": "Sometimes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "a827fb2a-b3ee-49cf-9770-0472d3555d0d",
                "text": "Often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "03fe899d-f60b-4ef7-a5e1-7cdf43b78d09",
                "text": "Very Often",
                "value": 1
            }
        ],
        "id": "ASRS18",
        "text": "How often do you interrupt others when they are busy?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "c7f96f1a-5dd5-4324-84b8-886e6b47f8d6",
                "text": "Not at all or very slightly",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2de63b2c-b5f9-4d55-8c54-ef85d7791a64",
                "text": "Mildly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "9033a8f3-9d67-43f7-9b5c-3446e3c2c6db",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "d7b1c68b-8e18-4a76-83bd-5c2bd072b984",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "5fa54c0a-9589-4bb0-b934-69eaf1f5d890",
                "text": "Very much",
                "value": 1
            }
        ],
        "id": "WURS1",
        "text": "As a child, I had concentration problems, easily distracted",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "7w8x9y0z-1a2b-3c4d-5e6f-7g8h9i0j1k2l",
                "text": "Not at all or very slightly",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2e3f4a5b-6c7d-8e9f-0a1b-2c3d4e5f6a7b",
                "text": "Mildly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "8n9o0p1q-2r3s-4t5u-6v7w-8x9y0z1a2b3c",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "3d4e5f6a-7b8c-9d0e-1f2a-3b4c5d6e7f8a",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "4o5p6q7r-8s9t-0u1v-2w3x-4y5z6a7b8c9d",
                "text": "Very much",
                "value": 1
            }
        ],
        "id": "WURS2",
        "text": "As a child, I was anxious, worrying",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "132f64dc-4c3e-4362-bb64-2fa3e6e4c76c",
                "text": "Not at all or very slightly",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "3c0a1c7f-1a91-4bf6-b3e7-0c0e4f531976",
                "text": "Mildly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "9dcebf70-36b1-4ddc-8f44-89c94bbf4c8f",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "f7e8d6b2-44bb-4a1c-9bf8-1df4b5e0c7e9",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "83bb76b9-9d9f-4b4e-921c-c5e1e828f57e",
                "text": "Very much",
                "value": 1
            }
        ],
        "id": "WURS3",
        "text": "As a child, I was nervous, fidgety",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "7b8333a3-9ecf-4a93-8f3b-10b3e9b5f6c2",
                "text": "Not at all or very slightly",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2e60fcd0-345f-43b2-9e8e-4a0f3ebcbe3d",
                "text": "Mildly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "0c7f4d9b-1db9-4e94-9a91-d0f0cc6d8a21",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "12e5f0c8-5a36-481d-8fa1-3f4d9b5e1b6e",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "6d4f80d3-11b1-4f17-a6df-6b0c6a6f2e2f",
                "text": "Very much",
                "value": 1
            }
        ],
        "id": "WURS4",
        "text": "As a child, I was inattentive, daydreaming",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "f701cb56-71b4-42cb-9857-648657d3a96d",
                "text": "Not at all or very slightly",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "3dad2929-ef01-4b2e-9ec2-bde1757efb61",
                "text": "Mildly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "b2be3a82-0a55-4163-b957-167c5fb7ae62",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "0a16daa4-d469-4af0-b8ff-988e722081bf",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "1340f1d1-b6cb-4136-bd1c-72a5d50fc076",
                "text": "Very much",
                "value": 1
            }
        ],
        "id": "WURS5",
        "text": "As a child, I was hot- or short-tempered, low boiling point",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "eefb6ce5-1a69-48b5-ac7e-2082119f0bff",
                "text": "Not at all or very slightly",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ed5a974b-cced-45aa-8123-af9762ce28b6",
                "text": "Mildly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "7e6a78ba-2f64-4493-9175-55a605929360",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "dc88eca8-192b-4b86-9e9a-330cd3dbb079",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "8d8c1924-c065-4875-b318-5662106fe463",
                "text": "Very much",
                "value": 1
            }
        ],
        "id": "WURS6",
        "text": "As a child, I had temper outbursts, tantrums",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6a1e59f8-7367-46a5-9f1c-ccfda1e2e3e8",
                "text": "Not at all or very slightly",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "9b5f6a7c-2c33-4b9f-9e9b-8f7d5e6c4c2b",
                "text": "Mildly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "b99a8d85-41d5-4e1d-8a94-bf8c9d5f7d3c",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "5f3c6e0b-8e8d-4c76-a9a3-d4e9b5f6c7d8",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "4c1f9e2a-5d7b-4a8b-8c4f-9e6d7f5a1b2c",
                "text": "Very much",
                "value": 1
            }
        ],
        "id": "WURS7",
        "text": "As a child, I had trouble with stick-to-it-iveness, not following through, failing to finish things started",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e35d2ae1-9df5-4d4c-842e-f1e3e9e19795",
                "text": "Not at all or very slightly",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "08922ad8-1f41-41e1-98ef-d5e3c152e459",
                "text": "Mildly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "2f35db32-7e8c-4e6c-8e06-04bd2edc5bd1",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "3d4b05bd-5441-4202-b1e8-cc528a88995a",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "b9c317ab-73af-40e5-a934-bba4a9b31de0",
                "text": "Very much",
                "value": 1
            }
        ],
        "id": "WURS8",
        "text": "As a child, I was stubborn, strong-willed",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "9d0e1f2a-3b4c-5d6e-7f8a-9b0c1d2e3f4a",
                "text": "Not at all or very slightly",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "5d6e7f8a-9b0c-1d2e-3f4a-5b6c7d8e9f0a",
                "text": "Mildly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "0i1j2k3l-4m5n-6o7p-8q9r-0s1t2u3v4w5x",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "6c7d8e9f-0a1b-2c3d-4e5f-6a7b8c9d0e1f",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "7y8z9a0b-1c2d-3e4f-5g6h-7i8j9k0l1m2n",
                "text": "Very much",
                "value": 1
            }
        ],
        "id": "WURS9",
        "text": "As a child, I was sad or blue, depressed, unhappy",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "0193b205-95ef-4609-8136-06d9e3f17a74",
                "text": "Not at all or very slightly",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "46bb5d8e-89cf-46ab-9e26-8f09b78d34fa",
                "text": "Mildly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "a4d8300f-d32a-4700-9f66-34a4866c1467",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "91882f0e-3e5c-4f7b-a30b-1f0d577d9a8d",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "53b989bf-e82e-4e95-9fa3-9873d09c78b1",
                "text": "Very much",
                "value": 1
            }
        ],
        "id": "WURS10",
        "text": "As a child, I was disobedient with parents, rebellious, sassy",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8k9l0m1n-2o3p-4q5r-6s7t-8u9v0w1x2y3z",
                "text": "Not at all or very slightly",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "1b2c3d4e-5f6a-7b8c-9d0e-1f2a3b4c5d6e",
                "text": "Mildly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "9o0p1q2r-3s4t-5u6v-7w8x-9y0z1a2b3c4d",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "4b1a0031-c77f-4f28-925a-d8936c1f3298",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "5e6f7g8h-9i0j-1k2l-3m4n-5o6p7q8r9s0t",
                "text": "Very much",
                "value": 1
            }
        ],
        "id": "WURS11",
        "text": "As a child, I had a low opinion of myself",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "85ff2ae0-5f1e-4b1e-9d33-7ed58b7c9e0f",
                "text": "Not at all or very slightly",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "da825593-6b9d-4f9d-b03f-b2cfbd98e3aa",
                "text": "Mildly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "ac01d8f9-3e5b-4425-b6b0-8d14f0f6e882",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "dd3db7c1-7e2d-48f1-b3ec-4d80d8a4f5b2",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "7a0f9ba5-9068-4de6-9c47-c9350d6ad472",
                "text": "Very much",
                "value": 1
            }
        ],
        "id": "WURS12",
        "text": "As a child, I was irritable",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "48c3d6ea-726a-4dca-99be-78d58e6be6fa",
                "text": "Not at all or very slightly",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f3a5d0b4-7598-4f93-9b5d-1b0a6b36e94e",
                "text": "Mildly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "04a2e8cc-82ab-4e9b-b4de-2e31b5675394",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "2570f8ad-8e75-4f80-939b-3ab2e88b9a72",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "c85c2830-64b0-4dc5-9f06-4b6261f6570e",
                "text": "Very much",
                "value": 1
            }
        ],
        "id": "WURS13",
        "text": "As a child, I was moody, ups and downs",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "d2e98b3d-771e-4dd5-a31e-3e7f86c7c0d5",
                "text": "Not at all or very slightly",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "9e6f7f71-8d14-42e1-927e-92e134a4e8e7",
                "text": "Mildly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "71a0f4d4-7a4f-4266-b987-1376d4e0e1a3",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "55e2d506-0842-4c7d-9d8e-f3b9c36d7e6f",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "a929e35b-1b6e-4a5a-bb55-3de8f0867d56",
                "text": "Very much",
                "value": 1
            }
        ],
        "id": "WURS14",
        "text": "As a child, I was angry",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8f7d5e6c-4c2b-4b9f-9e9b-9b5f6a7c2c33",
                "text": "Not at all or very slightly",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7a1b2c3d-4e5f-6a7b-8c9d-0e1f2a3b4c5d",
                "text": "Mildly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "9e6d7f5a-1b2c-3d4e-5f6a-7b8c9d0e1f2a",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "5f3e4d2c-1b0a-3d4c-5e6f-7a8b9c0d1e2f",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "4c5d6e7f-8a9b-0c1d-2e3f-4a5b6c7d8e9f",
                "text": "Very much",
                "value": 1
            }
        ],
        "id": "WURS15",
        "text": "As a child, I was acting without thinking, impulsive",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "0e1f2a3b-4c5d-6e7f-8a9b-0c1d2e3f4a5b",
                "text": "Not at all or very slightly",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "5b6c7d8e-9f0a-1b2c-3d4e-5f6a7b8c9d0e",
                "text": "Mildly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "6f7a8b9c-0d1e-2f3g-4h5i-6j7k8l9m0n1o",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "22c33c0f-f1f0-4baf-a29e-34fae194784c",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "7b8c9d0e-1f2a-3b4c-5d6e-7f8a9b0c1d2e",
                "text": "Very much",
                "value": 1
            }
        ],
        "id": "WURS16",
        "text": "As a child, I had a tendency to be immature",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "3f4g5h6i-7j8k-9l0m-1n2o-3p4q5r6s7t8u",
                "text": "Not at all or very slightly",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "6a7b8c9d-0e1f-2a3b-4c5d-6e7f8a9b0c1d",
                "text": "Mildly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "4v5w6x7y-8z9a-0b1c-2d3e-4f5g6h7i8j9k",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "7g8h9i0j-1k2l-3m4n-5o6p-7q8r9s0t1u2v",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "0p1q2r3s-4t5u-6v7w-8x9y-0z1a2b3c4d5e",
                "text": "Very much",
                "value": 1
            }
        ],
        "id": "WURS17",
        "text": "As a child, I had guilty feelings, regretful",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "62053d76-44c1-4755-95d1-7496868b9e8a",
                "text": "Not at all or very slightly",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "65a81be0-50bb-4e14-99ba-3d8e7c4f8b3c",
                "text": "Mildly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "2c9e28a4-d176-49a6-b53b-bc6a57e0b4e4",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "82a156c4-0c65-4d12-bd4c-e52ec2c3d4d1",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "0bf63445-8c1b-4950-81c4-1ce2b44fdd91",
                "text": "Very much",
                "value": 1
            }
        ],
        "id": "WURS18",
        "text": "As a child, I was losing control of myself",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "9e2f4ef0-45b3-4f07-83af-7ae8240a0de9",
                "text": "Not at all or very slightly",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f942d8b6-8ed9-4c66-b6a8-9a8f5c5bb6c9",
                "text": "Mildly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "3ed97129-1e70-448d-9305-1dfd2ec8e44a",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "124d7b5e-6f4a-4de6-8c6d-6c73670d1a0b",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "49331e1e-9e65-4c0b-a69c-42b4e6d8ab05",
                "text": "Very much",
                "value": 1
            }
        ],
        "id": "WURS19",
        "text": "As a child, I tended to be or act irrational",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "9y0z1a2b-3c4d-5e6f-7g8h-9i0j1k2l3m4n",
                "text": "Not at all or very slightly",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "5f6a7b8c-9d0e-1f2a-3b4c-5d6e7f8a9b0c",
                "text": "Mildly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "1n2o3p4q-5r6s-7t8u-9v0w-1x2y3z4a5b6c",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "6e7f8a9b-0c1d-2e3f-4a5b-6c7d8e9f0a1b",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "8o9p0q1r-2s3t-4u5v-6w7x-8y9z0a1b2c3d",
                "text": "Very much",
                "value": 1
            }
        ],
        "id": "WURS20",
        "text": "As a child, I was unpopular with other children, didn’t keep friends for long, didn’t get along with other children",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "54d62461-1f80-4ec6-8c24-2db8d37f9f19",
                "text": "Not at all or very slightly",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "aaf6d1ad-0a18-411a-b0d8-3a0ab1268df1",
                "text": "Mildly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "9b2e29f6-c819-470c-846a-4c9ad8f8b71a",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "7f466126-7655-4c49-8cbb-6fb3ef389ba3",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "e9bdf07c-bebb-42fb-9df8-9c4e8af9af56",
                "text": "Very much",
                "value": 1
            }
        ],
        "id": "WURS21",
        "text": "As a child, I had trouble seeing things from someone else’s point of view",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "90a9a7b7-0e60-4b78-8d1d-8e6f1d3a6b3e",
                "text": "Not at all or very slightly",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "6b3d0b0c-81ea-49bf-9b2c-88c5b445e7f9",
                "text": "Mildly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "93aee1d6-d49b-4e20-bd7b-0e57782e367a",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "47d098a1-0f79-45bb-8a2e-1dd978bbcd60",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "522f7318-0c8c-4e9f-bc07-2679d9d4c730",
                "text": "Very much",
                "value": 1
            }
        ],
        "id": "WURS22",
        "text": "As a child, I had trouble with authorities, trouble with school, visits to principal’s office",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8a9b0c1d-2e3f-4a5b-6c7d-8e9f0a1b2c3d",
                "text": "Not at all or very slightly",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "579802d2-7a7c-40ee-b7a6-b9c2066e2ee4",
                "text": "Mildly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "9e0f1g2h-3i4j-5k6l-7m8n-9o0p1q2r3s4t",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "4e5f6a7b-8c9d-0e1f-2a3b-4c5d6e7f8a9b",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "0f1g2h3i-4j5k-6l7m-8n9o-0p1q2r3s4t5u",
                "text": "Very much",
                "value": 1
            }
        ],
        "id": "WURS23",
        "text": "As a child in school, I was a poor student, slow learner",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "5u6v7w8x-9y0z-1a2b-3c4d-5e6f7g8h9i0j",
                "text": "Not at all or very slightly",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "af8c0b6a-7fcf-428a-be53-e4612554f61e",
                "text": "Mildly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "2afd39ff-460f-4139-8965-487b7ca865f1",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "37d124aa-6374-435a-bf00-eac8b40db205",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "8c9d0e1f-2a3b-4c5d-6e7f-8a9b0c1d2e3f",
                "text": "Very much",
                "value": 1
            }
        ],
        "id": "WURS24",
        "text": "As a child in school, I had trouble with mathematics or numbers",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "9f0a1b2c-3d4e-5f6a-7b8c-9d0e1f2a3b4c",
                "text": "Not at all or very slightly",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "0h1i2j3k-4l5m-6n7o-8p9q-0r1s2t3u4v5w",
                "text": "Mildly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "5ed158e5-6221-46ba-bc7a-83d65b5c03b6",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "6g7h8i9j-0k1l-2m3n-4o5p-6q7r8s9t0u1v",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "1f2a3b4c-5d6e-7f8a-9b0c-1d2e3f4a5b6c",
                "text": "Very much",
                "value": 1
            }
        ],
        "id": "WURS25",
        "text": "As a child in school, I was not achieving up to potential",
        "isMultiselect": false
    }
];
exports.aspects = {
    "adhd": {
        "type": "merge",
        "wires": [
            {
                "type": "toPredicate",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "adhd_asrs_possible"
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "adhd_wurs25"
                }
            }
        ],
        "anyToAll": 1
    },
    "adhd_asrs_possible": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ASRS1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "1e2492d3-7e08-4287-a9f1-f3dbef2612b2": 0.0066928509242848554,
                            "4c1c637b-7761-49a5-90af-e70832845e6d": 0.07585818002124355,
                            "b0ea051b-76ac-4fd3-9d8e-cad22d60f786": 0.5,
                            "ac3f5a3e-fd93-4202-9025-d4e78d22df04": 0.9241418199787566,
                            "f9e84075-ae14-47c6-8ac7-1c0cf7b57b3e": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ASRS2",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "aa8e0ca0-41d7-454b-adb3-ca850d9ad836": 0.0066928509242848554,
                            "ac4acf1a-ec33-44f5-a740-ba737688b826": 0.07585818002124355,
                            "628d1f37-f6d5-4a30-b154-5ac3121b51a3": 0.5,
                            "bbafdd89-81bf-46c6-b7c0-ab9ede5f9b2b": 0.9241418199787566,
                            "e2d45793-c88e-437b-b8ac-c76a8110f0f5": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ASRS3",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "97ef4ce5-b425-4b58-9a9c-c5f9fd00324f": 0.0066928509242848554,
                            "f4447b42-b9fc-47f2-94ad-0f5ebf292417": 0.07585818002124355,
                            "0a71cb5f-98cb-4130-9625-50c5cfa690ff": 0.5,
                            "ed30d4b1-99bf-4d1e-84c9-8af4a6136cf1": 0.9241418199787566,
                            "645ec227-aed2-4992-a639-fb14d6ccbdf2": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ASRS4",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "bb80c0a5-0acf-409d-877c-21e0eec4ba93": 0.0005527786369235996,
                            "e7aa837c-3a8b-43ef-a87e-2c8b3b238cf5": 0.0066928509242848554,
                            "1b3b13ea-b3ec-4aaa-92da-01058e86c4c6": 0.07585818002124355,
                            "4b03fe6b-aaa6-4aaf-9524-f71f0af63967": 0.5,
                            "3b178b28-a8cf-44a5-8d8c-a20e223350be": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ASRS5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "f30bde00-6fd5-45ce-98fe-feb2107c5a91": 0.0005527786369235996,
                            "28c2c65e-f9b2-42bf-92a6-f94fb33a8531": 0.0066928509242848554,
                            "c0469aa5-3511-4306-9e2a-769a8b2baa9d": 0.07585818002124355,
                            "3bbad350-b4ae-4e9d-8b08-7caa5913fe8b": 0.5,
                            "27597175-adf0-4d86-9371-613eebb40252": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ASRS6",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "66ed09ee-495b-47f4-a535-bff7f9d53970": 0.0005527786369235996,
                            "3d35ce2e-1ff6-408f-a313-f77066649fcc": 0.0066928509242848554,
                            "7fb4d290-f352-4a2e-bf70-868ce348f766": 0.07585818002124355,
                            "2af14acb-3029-4501-8c38-0b15fd54d2f8": 0.5,
                            "d63623e1-c39a-48a9-b55f-68c3507bf242": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.6666666666666666
    },
    "adhd_wurs25_mean": {
        "type": "mean",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "WURS1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "c7f96f1a-5dd5-4324-84b8-886e6b47f8d6": 0,
                            "2de63b2c-b5f9-4d55-8c54-ef85d7791a64": 0.25,
                            "9033a8f3-9d67-43f7-9b5c-3446e3c2c6db": 0.5,
                            "d7b1c68b-8e18-4a76-83bd-5c2bd072b984": 0.75,
                            "5fa54c0a-9589-4bb0-b934-69eaf1f5d890": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "WURS2",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "7w8x9y0z-1a2b-3c4d-5e6f-7g8h9i0j1k2l": 0,
                            "2e3f4a5b-6c7d-8e9f-0a1b-2c3d4e5f6a7b": 0.25,
                            "8n9o0p1q-2r3s-4t5u-6v7w-8x9y0z1a2b3c": 0.5,
                            "3d4e5f6a-7b8c-9d0e-1f2a-3b4c5d6e7f8a": 0.75,
                            "4o5p6q7r-8s9t-0u1v-2w3x-4y5z6a7b8c9d": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "WURS3",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "132f64dc-4c3e-4362-bb64-2fa3e6e4c76c": 0,
                            "3c0a1c7f-1a91-4bf6-b3e7-0c0e4f531976": 0.25,
                            "9dcebf70-36b1-4ddc-8f44-89c94bbf4c8f": 0.5,
                            "f7e8d6b2-44bb-4a1c-9bf8-1df4b5e0c7e9": 0.75,
                            "83bb76b9-9d9f-4b4e-921c-c5e1e828f57e": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "WURS4",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "7b8333a3-9ecf-4a93-8f3b-10b3e9b5f6c2": 0,
                            "2e60fcd0-345f-43b2-9e8e-4a0f3ebcbe3d": 0.25,
                            "0c7f4d9b-1db9-4e94-9a91-d0f0cc6d8a21": 0.5,
                            "12e5f0c8-5a36-481d-8fa1-3f4d9b5e1b6e": 0.75,
                            "6d4f80d3-11b1-4f17-a6df-6b0c6a6f2e2f": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "WURS5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "f701cb56-71b4-42cb-9857-648657d3a96d": 0,
                            "3dad2929-ef01-4b2e-9ec2-bde1757efb61": 0.25,
                            "b2be3a82-0a55-4163-b957-167c5fb7ae62": 0.5,
                            "0a16daa4-d469-4af0-b8ff-988e722081bf": 0.75,
                            "1340f1d1-b6cb-4136-bd1c-72a5d50fc076": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "WURS6",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "eefb6ce5-1a69-48b5-ac7e-2082119f0bff": 0,
                            "ed5a974b-cced-45aa-8123-af9762ce28b6": 0.25,
                            "7e6a78ba-2f64-4493-9175-55a605929360": 0.5,
                            "dc88eca8-192b-4b86-9e9a-330cd3dbb079": 0.75,
                            "8d8c1924-c065-4875-b318-5662106fe463": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "WURS7",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "6a1e59f8-7367-46a5-9f1c-ccfda1e2e3e8": 0,
                            "9b5f6a7c-2c33-4b9f-9e9b-8f7d5e6c4c2b": 0.25,
                            "b99a8d85-41d5-4e1d-8a94-bf8c9d5f7d3c": 0.5,
                            "5f3c6e0b-8e8d-4c76-a9a3-d4e9b5f6c7d8": 0.75,
                            "4c1f9e2a-5d7b-4a8b-8c4f-9e6d7f5a1b2c": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "WURS8",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "e35d2ae1-9df5-4d4c-842e-f1e3e9e19795": 0,
                            "08922ad8-1f41-41e1-98ef-d5e3c152e459": 0.25,
                            "2f35db32-7e8c-4e6c-8e06-04bd2edc5bd1": 0.5,
                            "3d4b05bd-5441-4202-b1e8-cc528a88995a": 0.75,
                            "b9c317ab-73af-40e5-a934-bba4a9b31de0": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "WURS9",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "9d0e1f2a-3b4c-5d6e-7f8a-9b0c1d2e3f4a": 0,
                            "5d6e7f8a-9b0c-1d2e-3f4a-5b6c7d8e9f0a": 0.25,
                            "0i1j2k3l-4m5n-6o7p-8q9r-0s1t2u3v4w5x": 0.5,
                            "6c7d8e9f-0a1b-2c3d-4e5f-6a7b8c9d0e1f": 0.75,
                            "7y8z9a0b-1c2d-3e4f-5g6h-7i8j9k0l1m2n": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "WURS10",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "0193b205-95ef-4609-8136-06d9e3f17a74": 0,
                            "46bb5d8e-89cf-46ab-9e26-8f09b78d34fa": 0.25,
                            "a4d8300f-d32a-4700-9f66-34a4866c1467": 0.5,
                            "91882f0e-3e5c-4f7b-a30b-1f0d577d9a8d": 0.75,
                            "53b989bf-e82e-4e95-9fa3-9873d09c78b1": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "WURS11",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "8k9l0m1n-2o3p-4q5r-6s7t-8u9v0w1x2y3z": 0,
                            "1b2c3d4e-5f6a-7b8c-9d0e-1f2a3b4c5d6e": 0.25,
                            "9o0p1q2r-3s4t-5u6v-7w8x-9y0z1a2b3c4d": 0.5,
                            "4b1a0031-c77f-4f28-925a-d8936c1f3298": 0.75,
                            "5e6f7g8h-9i0j-1k2l-3m4n-5o6p7q8r9s0t": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "WURS12",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "85ff2ae0-5f1e-4b1e-9d33-7ed58b7c9e0f": 0,
                            "da825593-6b9d-4f9d-b03f-b2cfbd98e3aa": 0.25,
                            "ac01d8f9-3e5b-4425-b6b0-8d14f0f6e882": 0.5,
                            "dd3db7c1-7e2d-48f1-b3ec-4d80d8a4f5b2": 0.75,
                            "7a0f9ba5-9068-4de6-9c47-c9350d6ad472": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "WURS13",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "48c3d6ea-726a-4dca-99be-78d58e6be6fa": 0,
                            "f3a5d0b4-7598-4f93-9b5d-1b0a6b36e94e": 0.25,
                            "04a2e8cc-82ab-4e9b-b4de-2e31b5675394": 0.5,
                            "2570f8ad-8e75-4f80-939b-3ab2e88b9a72": 0.75,
                            "c85c2830-64b0-4dc5-9f06-4b6261f6570e": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "WURS14",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "d2e98b3d-771e-4dd5-a31e-3e7f86c7c0d5": 0,
                            "9e6f7f71-8d14-42e1-927e-92e134a4e8e7": 0.25,
                            "71a0f4d4-7a4f-4266-b987-1376d4e0e1a3": 0.5,
                            "55e2d506-0842-4c7d-9d8e-f3b9c36d7e6f": 0.75,
                            "a929e35b-1b6e-4a5a-bb55-3de8f0867d56": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "WURS15",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "8f7d5e6c-4c2b-4b9f-9e9b-9b5f6a7c2c33": 0,
                            "7a1b2c3d-4e5f-6a7b-8c9d-0e1f2a3b4c5d": 0.25,
                            "9e6d7f5a-1b2c-3d4e-5f6a-7b8c9d0e1f2a": 0.5,
                            "5f3e4d2c-1b0a-3d4c-5e6f-7a8b9c0d1e2f": 0.75,
                            "4c5d6e7f-8a9b-0c1d-2e3f-4a5b6c7d8e9f": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "WURS16",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "0e1f2a3b-4c5d-6e7f-8a9b-0c1d2e3f4a5b": 0,
                            "5b6c7d8e-9f0a-1b2c-3d4e-5f6a7b8c9d0e": 0.25,
                            "6f7a8b9c-0d1e-2f3g-4h5i-6j7k8l9m0n1o": 0.5,
                            "22c33c0f-f1f0-4baf-a29e-34fae194784c": 0.75,
                            "7b8c9d0e-1f2a-3b4c-5d6e-7f8a9b0c1d2e": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "WURS17",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "3f4g5h6i-7j8k-9l0m-1n2o-3p4q5r6s7t8u": 0,
                            "6a7b8c9d-0e1f-2a3b-4c5d-6e7f8a9b0c1d": 0.25,
                            "4v5w6x7y-8z9a-0b1c-2d3e-4f5g6h7i8j9k": 0.5,
                            "7g8h9i0j-1k2l-3m4n-5o6p-7q8r9s0t1u2v": 0.75,
                            "0p1q2r3s-4t5u-6v7w-8x9y-0z1a2b3c4d5e": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "WURS18",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "62053d76-44c1-4755-95d1-7496868b9e8a": 0,
                            "65a81be0-50bb-4e14-99ba-3d8e7c4f8b3c": 0.25,
                            "2c9e28a4-d176-49a6-b53b-bc6a57e0b4e4": 0.5,
                            "82a156c4-0c65-4d12-bd4c-e52ec2c3d4d1": 0.75,
                            "0bf63445-8c1b-4950-81c4-1ce2b44fdd91": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "WURS19",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "9e2f4ef0-45b3-4f07-83af-7ae8240a0de9": 0,
                            "f942d8b6-8ed9-4c66-b6a8-9a8f5c5bb6c9": 0.25,
                            "3ed97129-1e70-448d-9305-1dfd2ec8e44a": 0.5,
                            "124d7b5e-6f4a-4de6-8c6d-6c73670d1a0b": 0.75,
                            "49331e1e-9e65-4c0b-a69c-42b4e6d8ab05": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "WURS20",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "9y0z1a2b-3c4d-5e6f-7g8h-9i0j1k2l3m4n": 0,
                            "5f6a7b8c-9d0e-1f2a-3b4c-5d6e7f8a9b0c": 0.25,
                            "1n2o3p4q-5r6s-7t8u-9v0w-1x2y3z4a5b6c": 0.5,
                            "6e7f8a9b-0c1d-2e3f-4a5b-6c7d8e9f0a1b": 0.75,
                            "8o9p0q1r-2s3t-4u5v-6w7x-8y9z0a1b2c3d": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "WURS21",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "54d62461-1f80-4ec6-8c24-2db8d37f9f19": 0,
                            "aaf6d1ad-0a18-411a-b0d8-3a0ab1268df1": 0.25,
                            "9b2e29f6-c819-470c-846a-4c9ad8f8b71a": 0.5,
                            "7f466126-7655-4c49-8cbb-6fb3ef389ba3": 0.75,
                            "e9bdf07c-bebb-42fb-9df8-9c4e8af9af56": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "WURS22",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "90a9a7b7-0e60-4b78-8d1d-8e6f1d3a6b3e": 0,
                            "6b3d0b0c-81ea-49bf-9b2c-88c5b445e7f9": 0.25,
                            "93aee1d6-d49b-4e20-bd7b-0e57782e367a": 0.5,
                            "47d098a1-0f79-45bb-8a2e-1dd978bbcd60": 0.75,
                            "522f7318-0c8c-4e9f-bc07-2679d9d4c730": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "WURS23",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "8a9b0c1d-2e3f-4a5b-6c7d-8e9f0a1b2c3d": 0,
                            "579802d2-7a7c-40ee-b7a6-b9c2066e2ee4": 0.25,
                            "9e0f1g2h-3i4j-5k6l-7m8n-9o0p1q2r3s4t": 0.5,
                            "4e5f6a7b-8c9d-0e1f-2a3b-4c5d6e7f8a9b": 0.75,
                            "0f1g2h3i-4j5k-6l7m-8n9o-0p1q2r3s4t5u": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "WURS24",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "5u6v7w8x-9y0z-1a2b-3c4d-5e6f7g8h9i0j": 0,
                            "af8c0b6a-7fcf-428a-be53-e4612554f61e": 0.25,
                            "2afd39ff-460f-4139-8965-487b7ca865f1": 0.5,
                            "37d124aa-6374-435a-bf00-eac8b40db205": 0.75,
                            "8c9d0e1f-2a3b-4c5d-6e7f-8a9b0c1d2e3f": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "WURS25",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "9f0a1b2c-3d4e-5f6a-7b8c-9d0e1f2a3b4c": 0,
                            "0h1i2j3k-4l5m-6n7o-8p9q-0r1s2t3u4v5w": 0.25,
                            "5ed158e5-6221-46ba-bc7a-83d65b5c03b6": 0.5,
                            "6g7h8i9j-0k1l-2m3n-4o5p-6q7r8s9t0u1v": 0.75,
                            "1f2a3b4c-5d6e-7f8a-9b0c-1d2e3f4a5b6c": 1
                        }
                    }
                }
            }
        ]
    },
    "adhd_wurs25": {
        "type": "segmentStretch",
        "thresholds": [
            [
                0,
                0.25
            ],
            [
                0.36,
                0.5
            ],
            [
                0.46,
                0.75
            ]
        ],
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "adhd_wurs25_mean"
            }
        }
    },
    "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "ASRS1",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "1e2492d3-7e08-4287-a9f1-f3dbef2612b2": 0.0066928509242848554,
                                            "4c1c637b-7761-49a5-90af-e70832845e6d": 0.07585818002124355,
                                            "b0ea051b-76ac-4fd3-9d8e-cad22d60f786": 0.5,
                                            "ac3f5a3e-fd93-4202-9025-d4e78d22df04": 0.9241418199787566,
                                            "f9e84075-ae14-47c6-8ac7-1c0cf7b57b3e": 0.9933071490757153
                                        }
                                    }
                                }
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "merge",
                                        "wires": [
                                            {
                                                "type": "input",
                                                "signal": {
                                                    "type": "AnsweredOption",
                                                    "questionId": "ASRS2",
                                                    "options": {
                                                        "type": "severities",
                                                        "severities": {
                                                            "aa8e0ca0-41d7-454b-adb3-ca850d9ad836": 0.0066928509242848554,
                                                            "ac4acf1a-ec33-44f5-a740-ba737688b826": 0.07585818002124355,
                                                            "628d1f37-f6d5-4a30-b154-5ac3121b51a3": 0.5,
                                                            "bbafdd89-81bf-46c6-b7c0-ab9ede5f9b2b": 0.9241418199787566,
                                                            "e2d45793-c88e-437b-b8ac-c76a8110f0f5": 0.9933071490757153
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                "type": "merge",
                                                "wires": [
                                                    {
                                                        "type": "merge",
                                                        "wires": [
                                                            {
                                                                "type": "input",
                                                                "signal": {
                                                                    "type": "AnsweredOption",
                                                                    "questionId": "ASRS3",
                                                                    "options": {
                                                                        "type": "severities",
                                                                        "severities": {
                                                                            "97ef4ce5-b425-4b58-9a9c-c5f9fd00324f": 0.0066928509242848554,
                                                                            "f4447b42-b9fc-47f2-94ad-0f5ebf292417": 0.07585818002124355,
                                                                            "0a71cb5f-98cb-4130-9625-50c5cfa690ff": 0.5,
                                                                            "ed30d4b1-99bf-4d1e-84c9-8af4a6136cf1": 0.9241418199787566,
                                                                            "645ec227-aed2-4992-a639-fb14d6ccbdf2": 0.9933071490757153
                                                                        }
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                "type": "merge",
                                                                "wires": [
                                                                    {
                                                                        "type": "input",
                                                                        "signal": {
                                                                            "type": "AnsweredOption",
                                                                            "questionId": "ASRS4",
                                                                            "options": {
                                                                                "type": "severities",
                                                                                "severities": {
                                                                                    "bb80c0a5-0acf-409d-877c-21e0eec4ba93": 0.0005527786369235996,
                                                                                    "e7aa837c-3a8b-43ef-a87e-2c8b3b238cf5": 0.0066928509242848554,
                                                                                    "1b3b13ea-b3ec-4aaa-92da-01058e86c4c6": 0.07585818002124355,
                                                                                    "4b03fe6b-aaa6-4aaf-9524-f71f0af63967": 0.5,
                                                                                    "3b178b28-a8cf-44a5-8d8c-a20e223350be": 0.9241418199787566
                                                                                }
                                                                            }
                                                                        }
                                                                    },
                                                                    {
                                                                        "type": "input",
                                                                        "signal": {
                                                                            "type": "AnsweredOption",
                                                                            "questionId": "ASRS5",
                                                                            "options": {
                                                                                "type": "severities",
                                                                                "severities": {
                                                                                    "f30bde00-6fd5-45ce-98fe-feb2107c5a91": 0.0005527786369235996,
                                                                                    "28c2c65e-f9b2-42bf-92a6-f94fb33a8531": 0.0066928509242848554,
                                                                                    "c0469aa5-3511-4306-9e2a-769a8b2baa9d": 0.07585818002124355,
                                                                                    "3bbad350-b4ae-4e9d-8b08-7caa5913fe8b": 0.5,
                                                                                    "27597175-adf0-4d86-9371-613eebb40252": 0.9241418199787566
                                                                                }
                                                                            }
                                                                        }
                                                                    },
                                                                    {
                                                                        "type": "input",
                                                                        "signal": {
                                                                            "type": "AnsweredOption",
                                                                            "questionId": "ASRS6",
                                                                            "options": {
                                                                                "type": "severities",
                                                                                "severities": {
                                                                                    "66ed09ee-495b-47f4-a535-bff7f9d53970": 0.0005527786369235996,
                                                                                    "3d35ce2e-1ff6-408f-a313-f77066649fcc": 0.0066928509242848554,
                                                                                    "7fb4d290-f352-4a2e-bf70-868ce348f766": 0.07585818002124355,
                                                                                    "2af14acb-3029-4501-8c38-0b15fd54d2f8": 0.5,
                                                                                    "d63623e1-c39a-48a9-b55f-68c3507bf242": 0.9241418199787566
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                ],
                                                                "anyToAll": 0.3333333333333333
                                                            }
                                                        ],
                                                        "anyToAll": 1
                                                    },
                                                    {
                                                        "type": "merge",
                                                        "wires": [
                                                            {
                                                                "type": "input",
                                                                "signal": {
                                                                    "type": "AnsweredOption",
                                                                    "questionId": "ASRS4",
                                                                    "options": {
                                                                        "type": "severities",
                                                                        "severities": {
                                                                            "bb80c0a5-0acf-409d-877c-21e0eec4ba93": 0.0005527786369235996,
                                                                            "e7aa837c-3a8b-43ef-a87e-2c8b3b238cf5": 0.0066928509242848554,
                                                                            "1b3b13ea-b3ec-4aaa-92da-01058e86c4c6": 0.07585818002124355,
                                                                            "4b03fe6b-aaa6-4aaf-9524-f71f0af63967": 0.5,
                                                                            "3b178b28-a8cf-44a5-8d8c-a20e223350be": 0.9241418199787566
                                                                        }
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                "type": "input",
                                                                "signal": {
                                                                    "type": "AnsweredOption",
                                                                    "questionId": "ASRS5",
                                                                    "options": {
                                                                        "type": "severities",
                                                                        "severities": {
                                                                            "f30bde00-6fd5-45ce-98fe-feb2107c5a91": 0.0005527786369235996,
                                                                            "28c2c65e-f9b2-42bf-92a6-f94fb33a8531": 0.0066928509242848554,
                                                                            "c0469aa5-3511-4306-9e2a-769a8b2baa9d": 0.07585818002124355,
                                                                            "3bbad350-b4ae-4e9d-8b08-7caa5913fe8b": 0.5,
                                                                            "27597175-adf0-4d86-9371-613eebb40252": 0.9241418199787566
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        ],
                                                        "anyToAll": 1
                                                    },
                                                    {
                                                        "type": "merge",
                                                        "wires": [
                                                            {
                                                                "type": "input",
                                                                "signal": {
                                                                    "type": "AnsweredOption",
                                                                    "questionId": "ASRS4",
                                                                    "options": {
                                                                        "type": "severities",
                                                                        "severities": {
                                                                            "bb80c0a5-0acf-409d-877c-21e0eec4ba93": 0.0005527786369235996,
                                                                            "e7aa837c-3a8b-43ef-a87e-2c8b3b238cf5": 0.0066928509242848554,
                                                                            "1b3b13ea-b3ec-4aaa-92da-01058e86c4c6": 0.07585818002124355,
                                                                            "4b03fe6b-aaa6-4aaf-9524-f71f0af63967": 0.5,
                                                                            "3b178b28-a8cf-44a5-8d8c-a20e223350be": 0.9241418199787566
                                                                        }
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                "type": "input",
                                                                "signal": {
                                                                    "type": "AnsweredOption",
                                                                    "questionId": "ASRS6",
                                                                    "options": {
                                                                        "type": "severities",
                                                                        "severities": {
                                                                            "66ed09ee-495b-47f4-a535-bff7f9d53970": 0.0005527786369235996,
                                                                            "3d35ce2e-1ff6-408f-a313-f77066649fcc": 0.0066928509242848554,
                                                                            "7fb4d290-f352-4a2e-bf70-868ce348f766": 0.07585818002124355,
                                                                            "2af14acb-3029-4501-8c38-0b15fd54d2f8": 0.5,
                                                                            "d63623e1-c39a-48a9-b55f-68c3507bf242": 0.9241418199787566
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        ],
                                                        "anyToAll": 1
                                                    },
                                                    {
                                                        "type": "merge",
                                                        "wires": [
                                                            {
                                                                "type": "input",
                                                                "signal": {
                                                                    "type": "AnsweredOption",
                                                                    "questionId": "ASRS5",
                                                                    "options": {
                                                                        "type": "severities",
                                                                        "severities": {
                                                                            "f30bde00-6fd5-45ce-98fe-feb2107c5a91": 0.0005527786369235996,
                                                                            "28c2c65e-f9b2-42bf-92a6-f94fb33a8531": 0.0066928509242848554,
                                                                            "c0469aa5-3511-4306-9e2a-769a8b2baa9d": 0.07585818002124355,
                                                                            "3bbad350-b4ae-4e9d-8b08-7caa5913fe8b": 0.5,
                                                                            "27597175-adf0-4d86-9371-613eebb40252": 0.9241418199787566
                                                                        }
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                "type": "input",
                                                                "signal": {
                                                                    "type": "AnsweredOption",
                                                                    "questionId": "ASRS6",
                                                                    "options": {
                                                                        "type": "severities",
                                                                        "severities": {
                                                                            "66ed09ee-495b-47f4-a535-bff7f9d53970": 0.0005527786369235996,
                                                                            "3d35ce2e-1ff6-408f-a313-f77066649fcc": 0.0066928509242848554,
                                                                            "7fb4d290-f352-4a2e-bf70-868ce348f766": 0.07585818002124355,
                                                                            "2af14acb-3029-4501-8c38-0b15fd54d2f8": 0.5,
                                                                            "d63623e1-c39a-48a9-b55f-68c3507bf242": 0.9241418199787566
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        ],
                                                        "anyToAll": 1
                                                    }
                                                ],
                                                "anyToAll": 0.25
                                            }
                                        ],
                                        "anyToAll": 1
                                    },
                                    {
                                        "type": "merge",
                                        "wires": [
                                            {
                                                "type": "input",
                                                "signal": {
                                                    "type": "AnsweredOption",
                                                    "questionId": "ASRS3",
                                                    "options": {
                                                        "type": "severities",
                                                        "severities": {
                                                            "97ef4ce5-b425-4b58-9a9c-c5f9fd00324f": 0.0066928509242848554,
                                                            "f4447b42-b9fc-47f2-94ad-0f5ebf292417": 0.07585818002124355,
                                                            "0a71cb5f-98cb-4130-9625-50c5cfa690ff": 0.5,
                                                            "ed30d4b1-99bf-4d1e-84c9-8af4a6136cf1": 0.9241418199787566,
                                                            "645ec227-aed2-4992-a639-fb14d6ccbdf2": 0.9933071490757153
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                "type": "input",
                                                "signal": {
                                                    "type": "AnsweredOption",
                                                    "questionId": "ASRS4",
                                                    "options": {
                                                        "type": "severities",
                                                        "severities": {
                                                            "bb80c0a5-0acf-409d-877c-21e0eec4ba93": 0.0005527786369235996,
                                                            "e7aa837c-3a8b-43ef-a87e-2c8b3b238cf5": 0.0066928509242848554,
                                                            "1b3b13ea-b3ec-4aaa-92da-01058e86c4c6": 0.07585818002124355,
                                                            "4b03fe6b-aaa6-4aaf-9524-f71f0af63967": 0.5,
                                                            "3b178b28-a8cf-44a5-8d8c-a20e223350be": 0.9241418199787566
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                "type": "input",
                                                "signal": {
                                                    "type": "AnsweredOption",
                                                    "questionId": "ASRS5",
                                                    "options": {
                                                        "type": "severities",
                                                        "severities": {
                                                            "f30bde00-6fd5-45ce-98fe-feb2107c5a91": 0.0005527786369235996,
                                                            "28c2c65e-f9b2-42bf-92a6-f94fb33a8531": 0.0066928509242848554,
                                                            "c0469aa5-3511-4306-9e2a-769a8b2baa9d": 0.07585818002124355,
                                                            "3bbad350-b4ae-4e9d-8b08-7caa5913fe8b": 0.5,
                                                            "27597175-adf0-4d86-9371-613eebb40252": 0.9241418199787566
                                                        }
                                                    }
                                                }
                                            }
                                        ],
                                        "anyToAll": 1
                                    },
                                    {
                                        "type": "merge",
                                        "wires": [
                                            {
                                                "type": "input",
                                                "signal": {
                                                    "type": "AnsweredOption",
                                                    "questionId": "ASRS3",
                                                    "options": {
                                                        "type": "severities",
                                                        "severities": {
                                                            "97ef4ce5-b425-4b58-9a9c-c5f9fd00324f": 0.0066928509242848554,
                                                            "f4447b42-b9fc-47f2-94ad-0f5ebf292417": 0.07585818002124355,
                                                            "0a71cb5f-98cb-4130-9625-50c5cfa690ff": 0.5,
                                                            "ed30d4b1-99bf-4d1e-84c9-8af4a6136cf1": 0.9241418199787566,
                                                            "645ec227-aed2-4992-a639-fb14d6ccbdf2": 0.9933071490757153
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                "type": "input",
                                                "signal": {
                                                    "type": "AnsweredOption",
                                                    "questionId": "ASRS4",
                                                    "options": {
                                                        "type": "severities",
                                                        "severities": {
                                                            "bb80c0a5-0acf-409d-877c-21e0eec4ba93": 0.0005527786369235996,
                                                            "e7aa837c-3a8b-43ef-a87e-2c8b3b238cf5": 0.0066928509242848554,
                                                            "1b3b13ea-b3ec-4aaa-92da-01058e86c4c6": 0.07585818002124355,
                                                            "4b03fe6b-aaa6-4aaf-9524-f71f0af63967": 0.5,
                                                            "3b178b28-a8cf-44a5-8d8c-a20e223350be": 0.9241418199787566
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                "type": "input",
                                                "signal": {
                                                    "type": "AnsweredOption",
                                                    "questionId": "ASRS6",
                                                    "options": {
                                                        "type": "severities",
                                                        "severities": {
                                                            "66ed09ee-495b-47f4-a535-bff7f9d53970": 0.0005527786369235996,
                                                            "3d35ce2e-1ff6-408f-a313-f77066649fcc": 0.0066928509242848554,
                                                            "7fb4d290-f352-4a2e-bf70-868ce348f766": 0.07585818002124355,
                                                            "2af14acb-3029-4501-8c38-0b15fd54d2f8": 0.5,
                                                            "d63623e1-c39a-48a9-b55f-68c3507bf242": 0.9241418199787566
                                                        }
                                                    }
                                                }
                                            }
                                        ],
                                        "anyToAll": 1
                                    },
                                    {
                                        "type": "merge",
                                        "wires": [
                                            {
                                                "type": "input",
                                                "signal": {
                                                    "type": "AnsweredOption",
                                                    "questionId": "ASRS3",
                                                    "options": {
                                                        "type": "severities",
                                                        "severities": {
                                                            "97ef4ce5-b425-4b58-9a9c-c5f9fd00324f": 0.0066928509242848554,
                                                            "f4447b42-b9fc-47f2-94ad-0f5ebf292417": 0.07585818002124355,
                                                            "0a71cb5f-98cb-4130-9625-50c5cfa690ff": 0.5,
                                                            "ed30d4b1-99bf-4d1e-84c9-8af4a6136cf1": 0.9241418199787566,
                                                            "645ec227-aed2-4992-a639-fb14d6ccbdf2": 0.9933071490757153
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                "type": "input",
                                                "signal": {
                                                    "type": "AnsweredOption",
                                                    "questionId": "ASRS5",
                                                    "options": {
                                                        "type": "severities",
                                                        "severities": {
                                                            "f30bde00-6fd5-45ce-98fe-feb2107c5a91": 0.0005527786369235996,
                                                            "28c2c65e-f9b2-42bf-92a6-f94fb33a8531": 0.0066928509242848554,
                                                            "c0469aa5-3511-4306-9e2a-769a8b2baa9d": 0.07585818002124355,
                                                            "3bbad350-b4ae-4e9d-8b08-7caa5913fe8b": 0.5,
                                                            "27597175-adf0-4d86-9371-613eebb40252": 0.9241418199787566
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                "type": "input",
                                                "signal": {
                                                    "type": "AnsweredOption",
                                                    "questionId": "ASRS6",
                                                    "options": {
                                                        "type": "severities",
                                                        "severities": {
                                                            "66ed09ee-495b-47f4-a535-bff7f9d53970": 0.0005527786369235996,
                                                            "3d35ce2e-1ff6-408f-a313-f77066649fcc": 0.0066928509242848554,
                                                            "7fb4d290-f352-4a2e-bf70-868ce348f766": 0.07585818002124355,
                                                            "2af14acb-3029-4501-8c38-0b15fd54d2f8": 0.5,
                                                            "d63623e1-c39a-48a9-b55f-68c3507bf242": 0.9241418199787566
                                                        }
                                                    }
                                                }
                                            }
                                        ],
                                        "anyToAll": 1
                                    },
                                    {
                                        "type": "merge",
                                        "wires": [
                                            {
                                                "type": "input",
                                                "signal": {
                                                    "type": "AnsweredOption",
                                                    "questionId": "ASRS4",
                                                    "options": {
                                                        "type": "severities",
                                                        "severities": {
                                                            "bb80c0a5-0acf-409d-877c-21e0eec4ba93": 0.0005527786369235996,
                                                            "e7aa837c-3a8b-43ef-a87e-2c8b3b238cf5": 0.0066928509242848554,
                                                            "1b3b13ea-b3ec-4aaa-92da-01058e86c4c6": 0.07585818002124355,
                                                            "4b03fe6b-aaa6-4aaf-9524-f71f0af63967": 0.5,
                                                            "3b178b28-a8cf-44a5-8d8c-a20e223350be": 0.9241418199787566
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                "type": "input",
                                                "signal": {
                                                    "type": "AnsweredOption",
                                                    "questionId": "ASRS5",
                                                    "options": {
                                                        "type": "severities",
                                                        "severities": {
                                                            "f30bde00-6fd5-45ce-98fe-feb2107c5a91": 0.0005527786369235996,
                                                            "28c2c65e-f9b2-42bf-92a6-f94fb33a8531": 0.0066928509242848554,
                                                            "c0469aa5-3511-4306-9e2a-769a8b2baa9d": 0.07585818002124355,
                                                            "3bbad350-b4ae-4e9d-8b08-7caa5913fe8b": 0.5,
                                                            "27597175-adf0-4d86-9371-613eebb40252": 0.9241418199787566
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                "type": "input",
                                                "signal": {
                                                    "type": "AnsweredOption",
                                                    "questionId": "ASRS6",
                                                    "options": {
                                                        "type": "severities",
                                                        "severities": {
                                                            "66ed09ee-495b-47f4-a535-bff7f9d53970": 0.0005527786369235996,
                                                            "3d35ce2e-1ff6-408f-a313-f77066649fcc": 0.0066928509242848554,
                                                            "7fb4d290-f352-4a2e-bf70-868ce348f766": 0.07585818002124355,
                                                            "2af14acb-3029-4501-8c38-0b15fd54d2f8": 0.5,
                                                            "d63623e1-c39a-48a9-b55f-68c3507bf242": 0.9241418199787566
                                                        }
                                                    }
                                                }
                                            }
                                        ],
                                        "anyToAll": 1
                                    }
                                ],
                                "anyToAll": 0.2
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "ASRS2",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "aa8e0ca0-41d7-454b-adb3-ca850d9ad836": 0.0066928509242848554,
                                            "ac4acf1a-ec33-44f5-a740-ba737688b826": 0.07585818002124355,
                                            "628d1f37-f6d5-4a30-b154-5ac3121b51a3": 0.5,
                                            "bbafdd89-81bf-46c6-b7c0-ab9ede5f9b2b": 0.9241418199787566,
                                            "e2d45793-c88e-437b-b8ac-c76a8110f0f5": 0.9933071490757153
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "ASRS3",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "97ef4ce5-b425-4b58-9a9c-c5f9fd00324f": 0.0066928509242848554,
                                            "f4447b42-b9fc-47f2-94ad-0f5ebf292417": 0.07585818002124355,
                                            "0a71cb5f-98cb-4130-9625-50c5cfa690ff": 0.5,
                                            "ed30d4b1-99bf-4d1e-84c9-8af4a6136cf1": 0.9241418199787566,
                                            "645ec227-aed2-4992-a639-fb14d6ccbdf2": 0.9933071490757153
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "ASRS4",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "bb80c0a5-0acf-409d-877c-21e0eec4ba93": 0.0005527786369235996,
                                            "e7aa837c-3a8b-43ef-a87e-2c8b3b238cf5": 0.0066928509242848554,
                                            "1b3b13ea-b3ec-4aaa-92da-01058e86c4c6": 0.07585818002124355,
                                            "4b03fe6b-aaa6-4aaf-9524-f71f0af63967": 0.5,
                                            "3b178b28-a8cf-44a5-8d8c-a20e223350be": 0.9241418199787566
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "ASRS5",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "f30bde00-6fd5-45ce-98fe-feb2107c5a91": 0.0005527786369235996,
                                            "28c2c65e-f9b2-42bf-92a6-f94fb33a8531": 0.0066928509242848554,
                                            "c0469aa5-3511-4306-9e2a-769a8b2baa9d": 0.07585818002124355,
                                            "3bbad350-b4ae-4e9d-8b08-7caa5913fe8b": 0.5,
                                            "27597175-adf0-4d86-9371-613eebb40252": 0.9241418199787566
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "ASRS2",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "aa8e0ca0-41d7-454b-adb3-ca850d9ad836": 0.0066928509242848554,
                                            "ac4acf1a-ec33-44f5-a740-ba737688b826": 0.07585818002124355,
                                            "628d1f37-f6d5-4a30-b154-5ac3121b51a3": 0.5,
                                            "bbafdd89-81bf-46c6-b7c0-ab9ede5f9b2b": 0.9241418199787566,
                                            "e2d45793-c88e-437b-b8ac-c76a8110f0f5": 0.9933071490757153
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "ASRS3",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "97ef4ce5-b425-4b58-9a9c-c5f9fd00324f": 0.0066928509242848554,
                                            "f4447b42-b9fc-47f2-94ad-0f5ebf292417": 0.07585818002124355,
                                            "0a71cb5f-98cb-4130-9625-50c5cfa690ff": 0.5,
                                            "ed30d4b1-99bf-4d1e-84c9-8af4a6136cf1": 0.9241418199787566,
                                            "645ec227-aed2-4992-a639-fb14d6ccbdf2": 0.9933071490757153
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "ASRS4",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "bb80c0a5-0acf-409d-877c-21e0eec4ba93": 0.0005527786369235996,
                                            "e7aa837c-3a8b-43ef-a87e-2c8b3b238cf5": 0.0066928509242848554,
                                            "1b3b13ea-b3ec-4aaa-92da-01058e86c4c6": 0.07585818002124355,
                                            "4b03fe6b-aaa6-4aaf-9524-f71f0af63967": 0.5,
                                            "3b178b28-a8cf-44a5-8d8c-a20e223350be": 0.9241418199787566
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "ASRS6",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "66ed09ee-495b-47f4-a535-bff7f9d53970": 0.0005527786369235996,
                                            "3d35ce2e-1ff6-408f-a313-f77066649fcc": 0.0066928509242848554,
                                            "7fb4d290-f352-4a2e-bf70-868ce348f766": 0.07585818002124355,
                                            "2af14acb-3029-4501-8c38-0b15fd54d2f8": 0.5,
                                            "d63623e1-c39a-48a9-b55f-68c3507bf242": 0.9241418199787566
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "ASRS2",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "aa8e0ca0-41d7-454b-adb3-ca850d9ad836": 0.0066928509242848554,
                                            "ac4acf1a-ec33-44f5-a740-ba737688b826": 0.07585818002124355,
                                            "628d1f37-f6d5-4a30-b154-5ac3121b51a3": 0.5,
                                            "bbafdd89-81bf-46c6-b7c0-ab9ede5f9b2b": 0.9241418199787566,
                                            "e2d45793-c88e-437b-b8ac-c76a8110f0f5": 0.9933071490757153
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "ASRS3",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "97ef4ce5-b425-4b58-9a9c-c5f9fd00324f": 0.0066928509242848554,
                                            "f4447b42-b9fc-47f2-94ad-0f5ebf292417": 0.07585818002124355,
                                            "0a71cb5f-98cb-4130-9625-50c5cfa690ff": 0.5,
                                            "ed30d4b1-99bf-4d1e-84c9-8af4a6136cf1": 0.9241418199787566,
                                            "645ec227-aed2-4992-a639-fb14d6ccbdf2": 0.9933071490757153
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "ASRS5",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "f30bde00-6fd5-45ce-98fe-feb2107c5a91": 0.0005527786369235996,
                                            "28c2c65e-f9b2-42bf-92a6-f94fb33a8531": 0.0066928509242848554,
                                            "c0469aa5-3511-4306-9e2a-769a8b2baa9d": 0.07585818002124355,
                                            "3bbad350-b4ae-4e9d-8b08-7caa5913fe8b": 0.5,
                                            "27597175-adf0-4d86-9371-613eebb40252": 0.9241418199787566
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "ASRS6",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "66ed09ee-495b-47f4-a535-bff7f9d53970": 0.0005527786369235996,
                                            "3d35ce2e-1ff6-408f-a313-f77066649fcc": 0.0066928509242848554,
                                            "7fb4d290-f352-4a2e-bf70-868ce348f766": 0.07585818002124355,
                                            "2af14acb-3029-4501-8c38-0b15fd54d2f8": 0.5,
                                            "d63623e1-c39a-48a9-b55f-68c3507bf242": 0.9241418199787566
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "ASRS2",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "aa8e0ca0-41d7-454b-adb3-ca850d9ad836": 0.0066928509242848554,
                                            "ac4acf1a-ec33-44f5-a740-ba737688b826": 0.07585818002124355,
                                            "628d1f37-f6d5-4a30-b154-5ac3121b51a3": 0.5,
                                            "bbafdd89-81bf-46c6-b7c0-ab9ede5f9b2b": 0.9241418199787566,
                                            "e2d45793-c88e-437b-b8ac-c76a8110f0f5": 0.9933071490757153
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "ASRS4",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "bb80c0a5-0acf-409d-877c-21e0eec4ba93": 0.0005527786369235996,
                                            "e7aa837c-3a8b-43ef-a87e-2c8b3b238cf5": 0.0066928509242848554,
                                            "1b3b13ea-b3ec-4aaa-92da-01058e86c4c6": 0.07585818002124355,
                                            "4b03fe6b-aaa6-4aaf-9524-f71f0af63967": 0.5,
                                            "3b178b28-a8cf-44a5-8d8c-a20e223350be": 0.9241418199787566
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "ASRS5",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "f30bde00-6fd5-45ce-98fe-feb2107c5a91": 0.0005527786369235996,
                                            "28c2c65e-f9b2-42bf-92a6-f94fb33a8531": 0.0066928509242848554,
                                            "c0469aa5-3511-4306-9e2a-769a8b2baa9d": 0.07585818002124355,
                                            "3bbad350-b4ae-4e9d-8b08-7caa5913fe8b": 0.5,
                                            "27597175-adf0-4d86-9371-613eebb40252": 0.9241418199787566
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "ASRS6",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "66ed09ee-495b-47f4-a535-bff7f9d53970": 0.0005527786369235996,
                                            "3d35ce2e-1ff6-408f-a313-f77066649fcc": 0.0066928509242848554,
                                            "7fb4d290-f352-4a2e-bf70-868ce348f766": 0.07585818002124355,
                                            "2af14acb-3029-4501-8c38-0b15fd54d2f8": 0.5,
                                            "d63623e1-c39a-48a9-b55f-68c3507bf242": 0.9241418199787566
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "ASRS3",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "97ef4ce5-b425-4b58-9a9c-c5f9fd00324f": 0.0066928509242848554,
                                            "f4447b42-b9fc-47f2-94ad-0f5ebf292417": 0.07585818002124355,
                                            "0a71cb5f-98cb-4130-9625-50c5cfa690ff": 0.5,
                                            "ed30d4b1-99bf-4d1e-84c9-8af4a6136cf1": 0.9241418199787566,
                                            "645ec227-aed2-4992-a639-fb14d6ccbdf2": 0.9933071490757153
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "ASRS4",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "bb80c0a5-0acf-409d-877c-21e0eec4ba93": 0.0005527786369235996,
                                            "e7aa837c-3a8b-43ef-a87e-2c8b3b238cf5": 0.0066928509242848554,
                                            "1b3b13ea-b3ec-4aaa-92da-01058e86c4c6": 0.07585818002124355,
                                            "4b03fe6b-aaa6-4aaf-9524-f71f0af63967": 0.5,
                                            "3b178b28-a8cf-44a5-8d8c-a20e223350be": 0.9241418199787566
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "ASRS5",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "f30bde00-6fd5-45ce-98fe-feb2107c5a91": 0.0005527786369235996,
                                            "28c2c65e-f9b2-42bf-92a6-f94fb33a8531": 0.0066928509242848554,
                                            "c0469aa5-3511-4306-9e2a-769a8b2baa9d": 0.07585818002124355,
                                            "3bbad350-b4ae-4e9d-8b08-7caa5913fe8b": 0.5,
                                            "27597175-adf0-4d86-9371-613eebb40252": 0.9241418199787566
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "ASRS6",
                                    "options": {
                                        "type": "severities",
                                        "severities": {
                                            "66ed09ee-495b-47f4-a535-bff7f9d53970": 0.0005527786369235996,
                                            "3d35ce2e-1ff6-408f-a313-f77066649fcc": 0.0066928509242848554,
                                            "7fb4d290-f352-4a2e-bf70-868ce348f766": 0.07585818002124355,
                                            "2af14acb-3029-4501-8c38-0b15fd54d2f8": 0.5,
                                            "d63623e1-c39a-48a9-b55f-68c3507bf242": 0.9241418199787566
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    }
                ],
                "anyToAll": 0.16666666666666666
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "ASRS6",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "66ed09ee-495b-47f4-a535-bff7f9d53970": true,
                                    "3d35ce2e-1ff6-408f-a313-f77066649fcc": false,
                                    "7fb4d290-f352-4a2e-bf70-868ce348f766": false,
                                    "2af14acb-3029-4501-8c38-0b15fd54d2f8": false,
                                    "d63623e1-c39a-48a9-b55f-68c3507bf242": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    }
};
exports.tests = {
    "adhd": [
        {
            "name": "adhd_sars_possible_and_wurs_100_score_highly_likely_higher_range-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 1,
                "threshold": 0
            },
            "answers": {
                "ASRS1": {
                    "questionId": "ASRS1",
                    "chosenOptionIds": [
                        "f9e84075-ae14-47c6-8ac7-1c0cf7b57b3e"
                    ]
                },
                "ASRS2": {
                    "questionId": "ASRS2",
                    "chosenOptionIds": [
                        "e2d45793-c88e-437b-b8ac-c76a8110f0f5"
                    ]
                },
                "ASRS3": {
                    "questionId": "ASRS3",
                    "chosenOptionIds": [
                        "645ec227-aed2-4992-a639-fb14d6ccbdf2"
                    ]
                },
                "ASRS4": {
                    "questionId": "ASRS4",
                    "chosenOptionIds": [
                        "3b178b28-a8cf-44a5-8d8c-a20e223350be"
                    ]
                },
                "ASRS5": {
                    "questionId": "ASRS5",
                    "chosenOptionIds": [
                        "27597175-adf0-4d86-9371-613eebb40252"
                    ]
                },
                "ASRS6": {
                    "questionId": "ASRS6",
                    "chosenOptionIds": [
                        "d63623e1-c39a-48a9-b55f-68c3507bf242"
                    ]
                },
                "ASRS7": {
                    "questionId": "ASRS7",
                    "chosenOptionIds": [
                        "26a1feb5-3d03-4573-9cd2-e355a336260f"
                    ]
                },
                "ASRS8": {
                    "questionId": "ASRS8",
                    "chosenOptionIds": [
                        "081d5f80-4ce9-490a-8ff4-be6ecba5a784"
                    ]
                },
                "ASRS9": {
                    "questionId": "ASRS9",
                    "chosenOptionIds": [
                        "88f28277-ed2f-4f03-8dc1-d0bf1f84b17d"
                    ]
                },
                "ASRS10": {
                    "questionId": "ASRS10",
                    "chosenOptionIds": [
                        "c7dffed4-c9b1-4262-8cd2-a75d8cc0d51b"
                    ]
                },
                "ASRS11": {
                    "questionId": "ASRS11",
                    "chosenOptionIds": [
                        "831251ef-b279-4722-a3dd-e696b3fe7007"
                    ]
                },
                "ASRS12": {
                    "questionId": "ASRS12",
                    "chosenOptionIds": [
                        "b0328927-6e29-4f25-9e5c-9223ec0b20b3"
                    ]
                },
                "ASRS13": {
                    "questionId": "ASRS13",
                    "chosenOptionIds": [
                        "124f9b9b-a33b-4890-aec0-f2d225342b68"
                    ]
                },
                "ASRS14": {
                    "questionId": "ASRS14",
                    "chosenOptionIds": [
                        "536a070b-a090-41f1-bd46-c59263d0eee2"
                    ]
                },
                "ASRS15": {
                    "questionId": "ASRS15",
                    "chosenOptionIds": [
                        "ed4e7cf7-fd51-4dcf-b098-66b290160392"
                    ]
                },
                "ASRS16": {
                    "questionId": "ASRS16",
                    "chosenOptionIds": [
                        "5e23b844-e348-441e-846f-f1732175c16c"
                    ]
                },
                "ASRS17": {
                    "questionId": "ASRS17",
                    "chosenOptionIds": [
                        "04a05b95-3c7f-40cd-9b70-b14292602fb1"
                    ]
                },
                "ASRS18": {
                    "questionId": "ASRS18",
                    "chosenOptionIds": [
                        "03fe899d-f60b-4ef7-a5e1-7cdf43b78d09"
                    ]
                },
                "WURS1": {
                    "questionId": "WURS1",
                    "chosenOptionIds": [
                        "5fa54c0a-9589-4bb0-b934-69eaf1f5d890"
                    ]
                },
                "WURS2": {
                    "questionId": "WURS2",
                    "chosenOptionIds": [
                        "4o5p6q7r-8s9t-0u1v-2w3x-4y5z6a7b8c9d"
                    ]
                },
                "WURS3": {
                    "questionId": "WURS3",
                    "chosenOptionIds": [
                        "83bb76b9-9d9f-4b4e-921c-c5e1e828f57e"
                    ]
                },
                "WURS4": {
                    "questionId": "WURS4",
                    "chosenOptionIds": [
                        "6d4f80d3-11b1-4f17-a6df-6b0c6a6f2e2f"
                    ]
                },
                "WURS5": {
                    "questionId": "WURS5",
                    "chosenOptionIds": [
                        "1340f1d1-b6cb-4136-bd1c-72a5d50fc076"
                    ]
                },
                "WURS6": {
                    "questionId": "WURS6",
                    "chosenOptionIds": [
                        "8d8c1924-c065-4875-b318-5662106fe463"
                    ]
                },
                "WURS7": {
                    "questionId": "WURS7",
                    "chosenOptionIds": [
                        "4c1f9e2a-5d7b-4a8b-8c4f-9e6d7f5a1b2c"
                    ]
                },
                "WURS8": {
                    "questionId": "WURS8",
                    "chosenOptionIds": [
                        "b9c317ab-73af-40e5-a934-bba4a9b31de0"
                    ]
                },
                "WURS9": {
                    "questionId": "WURS9",
                    "chosenOptionIds": [
                        "7y8z9a0b-1c2d-3e4f-5g6h-7i8j9k0l1m2n"
                    ]
                },
                "WURS10": {
                    "questionId": "WURS10",
                    "chosenOptionIds": [
                        "53b989bf-e82e-4e95-9fa3-9873d09c78b1"
                    ]
                },
                "WURS11": {
                    "questionId": "WURS11",
                    "chosenOptionIds": [
                        "5e6f7g8h-9i0j-1k2l-3m4n-5o6p7q8r9s0t"
                    ]
                },
                "WURS12": {
                    "questionId": "WURS12",
                    "chosenOptionIds": [
                        "7a0f9ba5-9068-4de6-9c47-c9350d6ad472"
                    ]
                },
                "WURS13": {
                    "questionId": "WURS13",
                    "chosenOptionIds": [
                        "c85c2830-64b0-4dc5-9f06-4b6261f6570e"
                    ]
                },
                "WURS14": {
                    "questionId": "WURS14",
                    "chosenOptionIds": [
                        "a929e35b-1b6e-4a5a-bb55-3de8f0867d56"
                    ]
                },
                "WURS15": {
                    "questionId": "WURS15",
                    "chosenOptionIds": [
                        "4c5d6e7f-8a9b-0c1d-2e3f-4a5b6c7d8e9f"
                    ]
                },
                "WURS16": {
                    "questionId": "WURS16",
                    "chosenOptionIds": [
                        "7b8c9d0e-1f2a-3b4c-5d6e-7f8a9b0c1d2e"
                    ]
                },
                "WURS17": {
                    "questionId": "WURS17",
                    "chosenOptionIds": [
                        "0p1q2r3s-4t5u-6v7w-8x9y-0z1a2b3c4d5e"
                    ]
                },
                "WURS18": {
                    "questionId": "WURS18",
                    "chosenOptionIds": [
                        "0bf63445-8c1b-4950-81c4-1ce2b44fdd91"
                    ]
                },
                "WURS19": {
                    "questionId": "WURS19",
                    "chosenOptionIds": [
                        "49331e1e-9e65-4c0b-a69c-42b4e6d8ab05"
                    ]
                },
                "WURS20": {
                    "questionId": "WURS20",
                    "chosenOptionIds": [
                        "8o9p0q1r-2s3t-4u5v-6w7x-8y9z0a1b2c3d"
                    ]
                },
                "WURS21": {
                    "questionId": "WURS21",
                    "chosenOptionIds": [
                        "e9bdf07c-bebb-42fb-9df8-9c4e8af9af56"
                    ]
                },
                "WURS22": {
                    "questionId": "WURS22",
                    "chosenOptionIds": [
                        "522f7318-0c8c-4e9f-bc07-2679d9d4c730"
                    ]
                },
                "WURS23": {
                    "questionId": "WURS23",
                    "chosenOptionIds": [
                        "0f1g2h3i-4j5k-6l7m-8n9o-0p1q2r3s4t5u"
                    ]
                },
                "WURS24": {
                    "questionId": "WURS24",
                    "chosenOptionIds": [
                        "8c9d0e1f-2a3b-4c5d-6e7f-8a9b0c1d2e3f"
                    ]
                },
                "WURS25": {
                    "questionId": "WURS25",
                    "chosenOptionIds": [
                        "1f2a3b4c-5d6e-7f8a-9b0c-1d2e3f4a5b6c"
                    ]
                }
            }
        },
        {
            "name": "adhd_asrs_possible_and_wurs_46_score_highly_likely_lower_range-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.75,
                "threshold": 0
            },
            "answers": {
                "ASRS1": {
                    "questionId": "ASRS1",
                    "chosenOptionIds": [
                        "f9e84075-ae14-47c6-8ac7-1c0cf7b57b3e"
                    ]
                },
                "ASRS2": {
                    "questionId": "ASRS2",
                    "chosenOptionIds": [
                        "e2d45793-c88e-437b-b8ac-c76a8110f0f5"
                    ]
                },
                "ASRS3": {
                    "questionId": "ASRS3",
                    "chosenOptionIds": [
                        "645ec227-aed2-4992-a639-fb14d6ccbdf2"
                    ]
                },
                "ASRS4": {
                    "questionId": "ASRS4",
                    "chosenOptionIds": [
                        "3b178b28-a8cf-44a5-8d8c-a20e223350be"
                    ]
                },
                "ASRS5": {
                    "questionId": "ASRS5",
                    "chosenOptionIds": [
                        "27597175-adf0-4d86-9371-613eebb40252"
                    ]
                },
                "ASRS6": {
                    "questionId": "ASRS6",
                    "chosenOptionIds": [
                        "d63623e1-c39a-48a9-b55f-68c3507bf242"
                    ]
                },
                "ASRS7": {
                    "questionId": "ASRS7",
                    "chosenOptionIds": [
                        "26a1feb5-3d03-4573-9cd2-e355a336260f"
                    ]
                },
                "ASRS8": {
                    "questionId": "ASRS8",
                    "chosenOptionIds": [
                        "081d5f80-4ce9-490a-8ff4-be6ecba5a784"
                    ]
                },
                "ASRS9": {
                    "questionId": "ASRS9",
                    "chosenOptionIds": [
                        "88f28277-ed2f-4f03-8dc1-d0bf1f84b17d"
                    ]
                },
                "ASRS10": {
                    "questionId": "ASRS10",
                    "chosenOptionIds": [
                        "c7dffed4-c9b1-4262-8cd2-a75d8cc0d51b"
                    ]
                },
                "ASRS11": {
                    "questionId": "ASRS11",
                    "chosenOptionIds": [
                        "831251ef-b279-4722-a3dd-e696b3fe7007"
                    ]
                },
                "ASRS12": {
                    "questionId": "ASRS12",
                    "chosenOptionIds": [
                        "b0328927-6e29-4f25-9e5c-9223ec0b20b3"
                    ]
                },
                "ASRS13": {
                    "questionId": "ASRS13",
                    "chosenOptionIds": [
                        "124f9b9b-a33b-4890-aec0-f2d225342b68"
                    ]
                },
                "ASRS14": {
                    "questionId": "ASRS14",
                    "chosenOptionIds": [
                        "536a070b-a090-41f1-bd46-c59263d0eee2"
                    ]
                },
                "ASRS15": {
                    "questionId": "ASRS15",
                    "chosenOptionIds": [
                        "ed4e7cf7-fd51-4dcf-b098-66b290160392"
                    ]
                },
                "ASRS16": {
                    "questionId": "ASRS16",
                    "chosenOptionIds": [
                        "5e23b844-e348-441e-846f-f1732175c16c"
                    ]
                },
                "ASRS17": {
                    "questionId": "ASRS17",
                    "chosenOptionIds": [
                        "04a05b95-3c7f-40cd-9b70-b14292602fb1"
                    ]
                },
                "ASRS18": {
                    "questionId": "ASRS18",
                    "chosenOptionIds": [
                        "03fe899d-f60b-4ef7-a5e1-7cdf43b78d09"
                    ]
                },
                "WURS1": {
                    "questionId": "WURS1",
                    "chosenOptionIds": [
                        "5fa54c0a-9589-4bb0-b934-69eaf1f5d890"
                    ]
                },
                "WURS2": {
                    "questionId": "WURS2",
                    "chosenOptionIds": [
                        "4o5p6q7r-8s9t-0u1v-2w3x-4y5z6a7b8c9d"
                    ]
                },
                "WURS3": {
                    "questionId": "WURS3",
                    "chosenOptionIds": [
                        "83bb76b9-9d9f-4b4e-921c-c5e1e828f57e"
                    ]
                },
                "WURS4": {
                    "questionId": "WURS4",
                    "chosenOptionIds": [
                        "6d4f80d3-11b1-4f17-a6df-6b0c6a6f2e2f"
                    ]
                },
                "WURS5": {
                    "questionId": "WURS5",
                    "chosenOptionIds": [
                        "1340f1d1-b6cb-4136-bd1c-72a5d50fc076"
                    ]
                },
                "WURS6": {
                    "questionId": "WURS6",
                    "chosenOptionIds": [
                        "8d8c1924-c065-4875-b318-5662106fe463"
                    ]
                },
                "WURS7": {
                    "questionId": "WURS7",
                    "chosenOptionIds": [
                        "4c1f9e2a-5d7b-4a8b-8c4f-9e6d7f5a1b2c"
                    ]
                },
                "WURS8": {
                    "questionId": "WURS8",
                    "chosenOptionIds": [
                        "b9c317ab-73af-40e5-a934-bba4a9b31de0"
                    ]
                },
                "WURS9": {
                    "questionId": "WURS9",
                    "chosenOptionIds": [
                        "7y8z9a0b-1c2d-3e4f-5g6h-7i8j9k0l1m2n"
                    ]
                },
                "WURS10": {
                    "questionId": "WURS10",
                    "chosenOptionIds": [
                        "53b989bf-e82e-4e95-9fa3-9873d09c78b1"
                    ]
                },
                "WURS11": {
                    "questionId": "WURS11",
                    "chosenOptionIds": [
                        "5e6f7g8h-9i0j-1k2l-3m4n-5o6p7q8r9s0t"
                    ]
                },
                "WURS12": {
                    "questionId": "WURS12",
                    "chosenOptionIds": [
                        "ac01d8f9-3e5b-4425-b6b0-8d14f0f6e882"
                    ]
                },
                "WURS13": {
                    "questionId": "WURS13",
                    "chosenOptionIds": [
                        "48c3d6ea-726a-4dca-99be-78d58e6be6fa"
                    ]
                },
                "WURS14": {
                    "questionId": "WURS14",
                    "chosenOptionIds": [
                        "d2e98b3d-771e-4dd5-a31e-3e7f86c7c0d5"
                    ]
                },
                "WURS15": {
                    "questionId": "WURS15",
                    "chosenOptionIds": [
                        "8f7d5e6c-4c2b-4b9f-9e9b-9b5f6a7c2c33"
                    ]
                },
                "WURS16": {
                    "questionId": "WURS16",
                    "chosenOptionIds": [
                        "0e1f2a3b-4c5d-6e7f-8a9b-0c1d2e3f4a5b"
                    ]
                },
                "WURS17": {
                    "questionId": "WURS17",
                    "chosenOptionIds": [
                        "3f4g5h6i-7j8k-9l0m-1n2o-3p4q5r6s7t8u"
                    ]
                },
                "WURS18": {
                    "questionId": "WURS18",
                    "chosenOptionIds": [
                        "62053d76-44c1-4755-95d1-7496868b9e8a"
                    ]
                },
                "WURS19": {
                    "questionId": "WURS19",
                    "chosenOptionIds": [
                        "9e2f4ef0-45b3-4f07-83af-7ae8240a0de9"
                    ]
                },
                "WURS20": {
                    "questionId": "WURS20",
                    "chosenOptionIds": [
                        "9y0z1a2b-3c4d-5e6f-7g8h-9i0j1k2l3m4n"
                    ]
                },
                "WURS21": {
                    "questionId": "WURS21",
                    "chosenOptionIds": [
                        "54d62461-1f80-4ec6-8c24-2db8d37f9f19"
                    ]
                },
                "WURS22": {
                    "questionId": "WURS22",
                    "chosenOptionIds": [
                        "90a9a7b7-0e60-4b78-8d1d-8e6f1d3a6b3e"
                    ]
                },
                "WURS23": {
                    "questionId": "WURS23",
                    "chosenOptionIds": [
                        "8a9b0c1d-2e3f-4a5b-6c7d-8e9f0a1b2c3d"
                    ]
                },
                "WURS24": {
                    "questionId": "WURS24",
                    "chosenOptionIds": [
                        "5u6v7w8x-9y0z-1a2b-3c4d-5e6f7g8h9i0j"
                    ]
                },
                "WURS25": {
                    "questionId": "WURS25",
                    "chosenOptionIds": [
                        "9f0a1b2c-3d4e-5f6a-7b8c-9d0e1f2a3b4c"
                    ]
                }
            }
        },
        {
            "name": "adhd_asrs_possible_and_wurs_45_score_probable_higher_range-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.72,
                "threshold": 0
            },
            "answers": {
                "ASRS1": {
                    "questionId": "ASRS1",
                    "chosenOptionIds": [
                        "f9e84075-ae14-47c6-8ac7-1c0cf7b57b3e"
                    ]
                },
                "ASRS2": {
                    "questionId": "ASRS2",
                    "chosenOptionIds": [
                        "e2d45793-c88e-437b-b8ac-c76a8110f0f5"
                    ]
                },
                "ASRS3": {
                    "questionId": "ASRS3",
                    "chosenOptionIds": [
                        "645ec227-aed2-4992-a639-fb14d6ccbdf2"
                    ]
                },
                "ASRS4": {
                    "questionId": "ASRS4",
                    "chosenOptionIds": [
                        "3b178b28-a8cf-44a5-8d8c-a20e223350be"
                    ]
                },
                "ASRS5": {
                    "questionId": "ASRS5",
                    "chosenOptionIds": [
                        "27597175-adf0-4d86-9371-613eebb40252"
                    ]
                },
                "ASRS6": {
                    "questionId": "ASRS6",
                    "chosenOptionIds": [
                        "d63623e1-c39a-48a9-b55f-68c3507bf242"
                    ]
                },
                "ASRS7": {
                    "questionId": "ASRS7",
                    "chosenOptionIds": [
                        "26a1feb5-3d03-4573-9cd2-e355a336260f"
                    ]
                },
                "ASRS8": {
                    "questionId": "ASRS8",
                    "chosenOptionIds": [
                        "081d5f80-4ce9-490a-8ff4-be6ecba5a784"
                    ]
                },
                "ASRS9": {
                    "questionId": "ASRS9",
                    "chosenOptionIds": [
                        "88f28277-ed2f-4f03-8dc1-d0bf1f84b17d"
                    ]
                },
                "ASRS10": {
                    "questionId": "ASRS10",
                    "chosenOptionIds": [
                        "c7dffed4-c9b1-4262-8cd2-a75d8cc0d51b"
                    ]
                },
                "ASRS11": {
                    "questionId": "ASRS11",
                    "chosenOptionIds": [
                        "831251ef-b279-4722-a3dd-e696b3fe7007"
                    ]
                },
                "ASRS12": {
                    "questionId": "ASRS12",
                    "chosenOptionIds": [
                        "b0328927-6e29-4f25-9e5c-9223ec0b20b3"
                    ]
                },
                "ASRS13": {
                    "questionId": "ASRS13",
                    "chosenOptionIds": [
                        "124f9b9b-a33b-4890-aec0-f2d225342b68"
                    ]
                },
                "ASRS14": {
                    "questionId": "ASRS14",
                    "chosenOptionIds": [
                        "536a070b-a090-41f1-bd46-c59263d0eee2"
                    ]
                },
                "ASRS15": {
                    "questionId": "ASRS15",
                    "chosenOptionIds": [
                        "ed4e7cf7-fd51-4dcf-b098-66b290160392"
                    ]
                },
                "ASRS16": {
                    "questionId": "ASRS16",
                    "chosenOptionIds": [
                        "5e23b844-e348-441e-846f-f1732175c16c"
                    ]
                },
                "ASRS17": {
                    "questionId": "ASRS17",
                    "chosenOptionIds": [
                        "04a05b95-3c7f-40cd-9b70-b14292602fb1"
                    ]
                },
                "ASRS18": {
                    "questionId": "ASRS18",
                    "chosenOptionIds": [
                        "03fe899d-f60b-4ef7-a5e1-7cdf43b78d09"
                    ]
                },
                "WURS1": {
                    "questionId": "WURS1",
                    "chosenOptionIds": [
                        "5fa54c0a-9589-4bb0-b934-69eaf1f5d890"
                    ]
                },
                "WURS2": {
                    "questionId": "WURS2",
                    "chosenOptionIds": [
                        "4o5p6q7r-8s9t-0u1v-2w3x-4y5z6a7b8c9d"
                    ]
                },
                "WURS3": {
                    "questionId": "WURS3",
                    "chosenOptionIds": [
                        "83bb76b9-9d9f-4b4e-921c-c5e1e828f57e"
                    ]
                },
                "WURS4": {
                    "questionId": "WURS4",
                    "chosenOptionIds": [
                        "6d4f80d3-11b1-4f17-a6df-6b0c6a6f2e2f"
                    ]
                },
                "WURS5": {
                    "questionId": "WURS5",
                    "chosenOptionIds": [
                        "1340f1d1-b6cb-4136-bd1c-72a5d50fc076"
                    ]
                },
                "WURS6": {
                    "questionId": "WURS6",
                    "chosenOptionIds": [
                        "8d8c1924-c065-4875-b318-5662106fe463"
                    ]
                },
                "WURS7": {
                    "questionId": "WURS7",
                    "chosenOptionIds": [
                        "4c1f9e2a-5d7b-4a8b-8c4f-9e6d7f5a1b2c"
                    ]
                },
                "WURS8": {
                    "questionId": "WURS8",
                    "chosenOptionIds": [
                        "b9c317ab-73af-40e5-a934-bba4a9b31de0"
                    ]
                },
                "WURS9": {
                    "questionId": "WURS9",
                    "chosenOptionIds": [
                        "7y8z9a0b-1c2d-3e4f-5g6h-7i8j9k0l1m2n"
                    ]
                },
                "WURS10": {
                    "questionId": "WURS10",
                    "chosenOptionIds": [
                        "53b989bf-e82e-4e95-9fa3-9873d09c78b1"
                    ]
                },
                "WURS11": {
                    "questionId": "WURS11",
                    "chosenOptionIds": [
                        "5e6f7g8h-9i0j-1k2l-3m4n-5o6p7q8r9s0t"
                    ]
                },
                "WURS12": {
                    "questionId": "WURS12",
                    "chosenOptionIds": [
                        "da825593-6b9d-4f9d-b03f-b2cfbd98e3aa"
                    ]
                },
                "WURS13": {
                    "questionId": "WURS13",
                    "chosenOptionIds": [
                        "48c3d6ea-726a-4dca-99be-78d58e6be6fa"
                    ]
                },
                "WURS14": {
                    "questionId": "WURS14",
                    "chosenOptionIds": [
                        "d2e98b3d-771e-4dd5-a31e-3e7f86c7c0d5"
                    ]
                },
                "WURS15": {
                    "questionId": "WURS15",
                    "chosenOptionIds": [
                        "8f7d5e6c-4c2b-4b9f-9e9b-9b5f6a7c2c33"
                    ]
                },
                "WURS16": {
                    "questionId": "WURS16",
                    "chosenOptionIds": [
                        "0e1f2a3b-4c5d-6e7f-8a9b-0c1d2e3f4a5b"
                    ]
                },
                "WURS17": {
                    "questionId": "WURS17",
                    "chosenOptionIds": [
                        "3f4g5h6i-7j8k-9l0m-1n2o-3p4q5r6s7t8u"
                    ]
                },
                "WURS18": {
                    "questionId": "WURS18",
                    "chosenOptionIds": [
                        "62053d76-44c1-4755-95d1-7496868b9e8a"
                    ]
                },
                "WURS19": {
                    "questionId": "WURS19",
                    "chosenOptionIds": [
                        "9e2f4ef0-45b3-4f07-83af-7ae8240a0de9"
                    ]
                },
                "WURS20": {
                    "questionId": "WURS20",
                    "chosenOptionIds": [
                        "9y0z1a2b-3c4d-5e6f-7g8h-9i0j1k2l3m4n"
                    ]
                },
                "WURS21": {
                    "questionId": "WURS21",
                    "chosenOptionIds": [
                        "54d62461-1f80-4ec6-8c24-2db8d37f9f19"
                    ]
                },
                "WURS22": {
                    "questionId": "WURS22",
                    "chosenOptionIds": [
                        "90a9a7b7-0e60-4b78-8d1d-8e6f1d3a6b3e"
                    ]
                },
                "WURS23": {
                    "questionId": "WURS23",
                    "chosenOptionIds": [
                        "8a9b0c1d-2e3f-4a5b-6c7d-8e9f0a1b2c3d"
                    ]
                },
                "WURS24": {
                    "questionId": "WURS24",
                    "chosenOptionIds": [
                        "5u6v7w8x-9y0z-1a2b-3c4d-5e6f7g8h9i0j"
                    ]
                },
                "WURS25": {
                    "questionId": "WURS25",
                    "chosenOptionIds": [
                        "9f0a1b2c-3d4e-5f6a-7b8c-9d0e1f2a3b4c"
                    ]
                }
            }
        },
        {
            "name": "adhd_asrs_possible_and_wurs_36_score_probable_lower-range-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.5,
                "threshold": 0
            },
            "answers": {
                "ASRS1": {
                    "questionId": "ASRS1",
                    "chosenOptionIds": [
                        "b0ea051b-76ac-4fd3-9d8e-cad22d60f786"
                    ]
                },
                "ASRS2": {
                    "questionId": "ASRS2",
                    "chosenOptionIds": [
                        "628d1f37-f6d5-4a30-b154-5ac3121b51a3"
                    ]
                },
                "ASRS3": {
                    "questionId": "ASRS3",
                    "chosenOptionIds": [
                        "0a71cb5f-98cb-4130-9625-50c5cfa690ff"
                    ]
                },
                "ASRS4": {
                    "questionId": "ASRS4",
                    "chosenOptionIds": [
                        "4b03fe6b-aaa6-4aaf-9524-f71f0af63967"
                    ]
                },
                "ASRS5": {
                    "questionId": "ASRS5",
                    "chosenOptionIds": [
                        "f30bde00-6fd5-45ce-98fe-feb2107c5a91"
                    ]
                },
                "ASRS6": {
                    "questionId": "ASRS6",
                    "chosenOptionIds": [
                        "66ed09ee-495b-47f4-a535-bff7f9d53970"
                    ]
                },
                "ASRS7": {
                    "questionId": "ASRS7",
                    "chosenOptionIds": [
                        "ed15bfa8-d408-46ab-9525-b0b42e0d595a"
                    ]
                },
                "ASRS8": {
                    "questionId": "ASRS8",
                    "chosenOptionIds": [
                        "d5030f6f-ad19-49ae-a0cb-db56d5ba31f9"
                    ]
                },
                "ASRS9": {
                    "questionId": "ASRS9",
                    "chosenOptionIds": [
                        "79de57bb-786b-49b7-ba5d-4eef37005d2c"
                    ]
                },
                "ASRS10": {
                    "questionId": "ASRS10",
                    "chosenOptionIds": [
                        "90526640-e201-488c-a16c-c84dcb771bc9"
                    ]
                },
                "ASRS11": {
                    "questionId": "ASRS11",
                    "chosenOptionIds": [
                        "df93abe7-de34-4966-b517-baab39465ddd"
                    ]
                },
                "ASRS12": {
                    "questionId": "ASRS12",
                    "chosenOptionIds": [
                        "484c95df-070b-4b06-a210-c6aa2cb652c4"
                    ]
                },
                "ASRS13": {
                    "questionId": "ASRS13",
                    "chosenOptionIds": [
                        "72820602-4ddb-437d-bb2b-020a8c6396e4"
                    ]
                },
                "ASRS14": {
                    "questionId": "ASRS14",
                    "chosenOptionIds": [
                        "6c9bab2e-9d57-46f6-be81-0c931ed4e320"
                    ]
                },
                "ASRS15": {
                    "questionId": "ASRS15",
                    "chosenOptionIds": [
                        "3a809ef8-942a-4ee8-89f3-447dc5dc5d04"
                    ]
                },
                "ASRS16": {
                    "questionId": "ASRS16",
                    "chosenOptionIds": [
                        "afd3f876-98d1-4e90-892b-57d26f960c01"
                    ]
                },
                "ASRS17": {
                    "questionId": "ASRS17",
                    "chosenOptionIds": [
                        "287f23de-4ddd-4976-a2a4-76028e6f89f1"
                    ]
                },
                "ASRS18": {
                    "questionId": "ASRS18",
                    "chosenOptionIds": [
                        "24e8ee51-b4e8-41e9-922b-fe93e03fe04c"
                    ]
                },
                "WURS1": {
                    "questionId": "WURS1",
                    "chosenOptionIds": [
                        "5fa54c0a-9589-4bb0-b934-69eaf1f5d890"
                    ]
                },
                "WURS2": {
                    "questionId": "WURS2",
                    "chosenOptionIds": [
                        "4o5p6q7r-8s9t-0u1v-2w3x-4y5z6a7b8c9d"
                    ]
                },
                "WURS3": {
                    "questionId": "WURS3",
                    "chosenOptionIds": [
                        "83bb76b9-9d9f-4b4e-921c-c5e1e828f57e"
                    ]
                },
                "WURS4": {
                    "questionId": "WURS4",
                    "chosenOptionIds": [
                        "6d4f80d3-11b1-4f17-a6df-6b0c6a6f2e2f"
                    ]
                },
                "WURS5": {
                    "questionId": "WURS5",
                    "chosenOptionIds": [
                        "1340f1d1-b6cb-4136-bd1c-72a5d50fc076"
                    ]
                },
                "WURS6": {
                    "questionId": "WURS6",
                    "chosenOptionIds": [
                        "8d8c1924-c065-4875-b318-5662106fe463"
                    ]
                },
                "WURS7": {
                    "questionId": "WURS7",
                    "chosenOptionIds": [
                        "4c1f9e2a-5d7b-4a8b-8c4f-9e6d7f5a1b2c"
                    ]
                },
                "WURS8": {
                    "questionId": "WURS8",
                    "chosenOptionIds": [
                        "b9c317ab-73af-40e5-a934-bba4a9b31de0"
                    ]
                },
                "WURS9": {
                    "questionId": "WURS9",
                    "chosenOptionIds": [
                        "7y8z9a0b-1c2d-3e4f-5g6h-7i8j9k0l1m2n"
                    ]
                },
                "WURS10": {
                    "questionId": "WURS10",
                    "chosenOptionIds": [
                        "0193b205-95ef-4609-8136-06d9e3f17a74"
                    ]
                },
                "WURS11": {
                    "questionId": "WURS11",
                    "chosenOptionIds": [
                        "8k9l0m1n-2o3p-4q5r-6s7t-8u9v0w1x2y3z"
                    ]
                },
                "WURS12": {
                    "questionId": "WURS12",
                    "chosenOptionIds": [
                        "85ff2ae0-5f1e-4b1e-9d33-7ed58b7c9e0f"
                    ]
                },
                "WURS13": {
                    "questionId": "WURS13",
                    "chosenOptionIds": [
                        "48c3d6ea-726a-4dca-99be-78d58e6be6fa"
                    ]
                },
                "WURS14": {
                    "questionId": "WURS14",
                    "chosenOptionIds": [
                        "d2e98b3d-771e-4dd5-a31e-3e7f86c7c0d5"
                    ]
                },
                "WURS15": {
                    "questionId": "WURS15",
                    "chosenOptionIds": [
                        "8f7d5e6c-4c2b-4b9f-9e9b-9b5f6a7c2c33"
                    ]
                },
                "WURS16": {
                    "questionId": "WURS16",
                    "chosenOptionIds": [
                        "0e1f2a3b-4c5d-6e7f-8a9b-0c1d2e3f4a5b"
                    ]
                },
                "WURS17": {
                    "questionId": "WURS17",
                    "chosenOptionIds": [
                        "3f4g5h6i-7j8k-9l0m-1n2o-3p4q5r6s7t8u"
                    ]
                },
                "WURS18": {
                    "questionId": "WURS18",
                    "chosenOptionIds": [
                        "62053d76-44c1-4755-95d1-7496868b9e8a"
                    ]
                },
                "WURS19": {
                    "questionId": "WURS19",
                    "chosenOptionIds": [
                        "9e2f4ef0-45b3-4f07-83af-7ae8240a0de9"
                    ]
                },
                "WURS20": {
                    "questionId": "WURS20",
                    "chosenOptionIds": [
                        "9y0z1a2b-3c4d-5e6f-7g8h-9i0j1k2l3m4n"
                    ]
                },
                "WURS21": {
                    "questionId": "WURS21",
                    "chosenOptionIds": [
                        "54d62461-1f80-4ec6-8c24-2db8d37f9f19"
                    ]
                },
                "WURS22": {
                    "questionId": "WURS22",
                    "chosenOptionIds": [
                        "90a9a7b7-0e60-4b78-8d1d-8e6f1d3a6b3e"
                    ]
                },
                "WURS23": {
                    "questionId": "WURS23",
                    "chosenOptionIds": [
                        "8a9b0c1d-2e3f-4a5b-6c7d-8e9f0a1b2c3d"
                    ]
                },
                "WURS24": {
                    "questionId": "WURS24",
                    "chosenOptionIds": [
                        "5u6v7w8x-9y0z-1a2b-3c4d-5e6f7g8h9i0j"
                    ]
                },
                "WURS25": {
                    "questionId": "WURS25",
                    "chosenOptionIds": [
                        "9f0a1b2c-3d4e-5f6a-7b8c-9d0e1f2a3b4c"
                    ]
                }
            }
        },
        {
            "name": "adhd_asrs_possible_and_wurs_36_score_probable_lower-range_another-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.5,
                "threshold": 0
            },
            "answers": {
                "ASRS1": {
                    "questionId": "ASRS1",
                    "chosenOptionIds": [
                        "b0ea051b-76ac-4fd3-9d8e-cad22d60f786"
                    ]
                },
                "ASRS2": {
                    "questionId": "ASRS2",
                    "chosenOptionIds": [
                        "628d1f37-f6d5-4a30-b154-5ac3121b51a3"
                    ]
                },
                "ASRS3": {
                    "questionId": "ASRS3",
                    "chosenOptionIds": [
                        "0a71cb5f-98cb-4130-9625-50c5cfa690ff"
                    ]
                },
                "ASRS4": {
                    "questionId": "ASRS4",
                    "chosenOptionIds": [
                        "4b03fe6b-aaa6-4aaf-9524-f71f0af63967"
                    ]
                },
                "ASRS5": {
                    "questionId": "ASRS5",
                    "chosenOptionIds": [
                        "f30bde00-6fd5-45ce-98fe-feb2107c5a91"
                    ]
                },
                "ASRS6": {
                    "questionId": "ASRS6",
                    "chosenOptionIds": [
                        "66ed09ee-495b-47f4-a535-bff7f9d53970"
                    ]
                },
                "ASRS7": {
                    "questionId": "ASRS7",
                    "chosenOptionIds": [
                        "ed15bfa8-d408-46ab-9525-b0b42e0d595a"
                    ]
                },
                "ASRS8": {
                    "questionId": "ASRS8",
                    "chosenOptionIds": [
                        "d5030f6f-ad19-49ae-a0cb-db56d5ba31f9"
                    ]
                },
                "ASRS9": {
                    "questionId": "ASRS9",
                    "chosenOptionIds": [
                        "79de57bb-786b-49b7-ba5d-4eef37005d2c"
                    ]
                },
                "ASRS10": {
                    "questionId": "ASRS10",
                    "chosenOptionIds": [
                        "90526640-e201-488c-a16c-c84dcb771bc9"
                    ]
                },
                "ASRS11": {
                    "questionId": "ASRS11",
                    "chosenOptionIds": [
                        "df93abe7-de34-4966-b517-baab39465ddd"
                    ]
                },
                "ASRS12": {
                    "questionId": "ASRS12",
                    "chosenOptionIds": [
                        "484c95df-070b-4b06-a210-c6aa2cb652c4"
                    ]
                },
                "ASRS13": {
                    "questionId": "ASRS13",
                    "chosenOptionIds": [
                        "72820602-4ddb-437d-bb2b-020a8c6396e4"
                    ]
                },
                "ASRS14": {
                    "questionId": "ASRS14",
                    "chosenOptionIds": [
                        "6c9bab2e-9d57-46f6-be81-0c931ed4e320"
                    ]
                },
                "ASRS15": {
                    "questionId": "ASRS15",
                    "chosenOptionIds": [
                        "3a809ef8-942a-4ee8-89f3-447dc5dc5d04"
                    ]
                },
                "ASRS16": {
                    "questionId": "ASRS16",
                    "chosenOptionIds": [
                        "afd3f876-98d1-4e90-892b-57d26f960c01"
                    ]
                },
                "ASRS17": {
                    "questionId": "ASRS17",
                    "chosenOptionIds": [
                        "287f23de-4ddd-4976-a2a4-76028e6f89f1"
                    ]
                },
                "ASRS18": {
                    "questionId": "ASRS18",
                    "chosenOptionIds": [
                        "24e8ee51-b4e8-41e9-922b-fe93e03fe04c"
                    ]
                },
                "WURS1": {
                    "questionId": "WURS1",
                    "chosenOptionIds": [
                        "5fa54c0a-9589-4bb0-b934-69eaf1f5d890"
                    ]
                },
                "WURS2": {
                    "questionId": "WURS2",
                    "chosenOptionIds": [
                        "4o5p6q7r-8s9t-0u1v-2w3x-4y5z6a7b8c9d"
                    ]
                },
                "WURS3": {
                    "questionId": "WURS3",
                    "chosenOptionIds": [
                        "83bb76b9-9d9f-4b4e-921c-c5e1e828f57e"
                    ]
                },
                "WURS4": {
                    "questionId": "WURS4",
                    "chosenOptionIds": [
                        "6d4f80d3-11b1-4f17-a6df-6b0c6a6f2e2f"
                    ]
                },
                "WURS5": {
                    "questionId": "WURS5",
                    "chosenOptionIds": [
                        "1340f1d1-b6cb-4136-bd1c-72a5d50fc076"
                    ]
                },
                "WURS6": {
                    "questionId": "WURS6",
                    "chosenOptionIds": [
                        "8d8c1924-c065-4875-b318-5662106fe463"
                    ]
                },
                "WURS7": {
                    "questionId": "WURS7",
                    "chosenOptionIds": [
                        "4c1f9e2a-5d7b-4a8b-8c4f-9e6d7f5a1b2c"
                    ]
                },
                "WURS8": {
                    "questionId": "WURS8",
                    "chosenOptionIds": [
                        "b9c317ab-73af-40e5-a934-bba4a9b31de0"
                    ]
                },
                "WURS9": {
                    "questionId": "WURS9",
                    "chosenOptionIds": [
                        "6c7d8e9f-0a1b-2c3d-4e5f-6a7b8c9d0e1f"
                    ]
                },
                "WURS10": {
                    "questionId": "WURS10",
                    "chosenOptionIds": [
                        "46bb5d8e-89cf-46ab-9e26-8f09b78d34fa"
                    ]
                },
                "WURS11": {
                    "questionId": "WURS11",
                    "chosenOptionIds": [
                        "8k9l0m1n-2o3p-4q5r-6s7t-8u9v0w1x2y3z"
                    ]
                },
                "WURS12": {
                    "questionId": "WURS12",
                    "chosenOptionIds": [
                        "85ff2ae0-5f1e-4b1e-9d33-7ed58b7c9e0f"
                    ]
                },
                "WURS13": {
                    "questionId": "WURS13",
                    "chosenOptionIds": [
                        "48c3d6ea-726a-4dca-99be-78d58e6be6fa"
                    ]
                },
                "WURS14": {
                    "questionId": "WURS14",
                    "chosenOptionIds": [
                        "d2e98b3d-771e-4dd5-a31e-3e7f86c7c0d5"
                    ]
                },
                "WURS15": {
                    "questionId": "WURS15",
                    "chosenOptionIds": [
                        "8f7d5e6c-4c2b-4b9f-9e9b-9b5f6a7c2c33"
                    ]
                },
                "WURS16": {
                    "questionId": "WURS16",
                    "chosenOptionIds": [
                        "0e1f2a3b-4c5d-6e7f-8a9b-0c1d2e3f4a5b"
                    ]
                },
                "WURS17": {
                    "questionId": "WURS17",
                    "chosenOptionIds": [
                        "3f4g5h6i-7j8k-9l0m-1n2o-3p4q5r6s7t8u"
                    ]
                },
                "WURS18": {
                    "questionId": "WURS18",
                    "chosenOptionIds": [
                        "62053d76-44c1-4755-95d1-7496868b9e8a"
                    ]
                },
                "WURS19": {
                    "questionId": "WURS19",
                    "chosenOptionIds": [
                        "9e2f4ef0-45b3-4f07-83af-7ae8240a0de9"
                    ]
                },
                "WURS20": {
                    "questionId": "WURS20",
                    "chosenOptionIds": [
                        "9y0z1a2b-3c4d-5e6f-7g8h-9i0j1k2l3m4n"
                    ]
                },
                "WURS21": {
                    "questionId": "WURS21",
                    "chosenOptionIds": [
                        "54d62461-1f80-4ec6-8c24-2db8d37f9f19"
                    ]
                },
                "WURS22": {
                    "questionId": "WURS22",
                    "chosenOptionIds": [
                        "90a9a7b7-0e60-4b78-8d1d-8e6f1d3a6b3e"
                    ]
                },
                "WURS23": {
                    "questionId": "WURS23",
                    "chosenOptionIds": [
                        "8a9b0c1d-2e3f-4a5b-6c7d-8e9f0a1b2c3d"
                    ]
                },
                "WURS24": {
                    "questionId": "WURS24",
                    "chosenOptionIds": [
                        "5u6v7w8x-9y0z-1a2b-3c4d-5e6f7g8h9i0j"
                    ]
                },
                "WURS25": {
                    "questionId": "WURS25",
                    "chosenOptionIds": [
                        "9f0a1b2c-3d4e-5f6a-7b8c-9d0e1f2a3b4c"
                    ]
                }
            }
        },
        {
            "name": "adhd_asrs_possible_and_wurs_35_score_possible_higher_range-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.49,
                "threshold": 0
            },
            "answers": {
                "ASRS1": {
                    "questionId": "ASRS1",
                    "chosenOptionIds": [
                        "b0ea051b-76ac-4fd3-9d8e-cad22d60f786"
                    ]
                },
                "ASRS2": {
                    "questionId": "ASRS2",
                    "chosenOptionIds": [
                        "628d1f37-f6d5-4a30-b154-5ac3121b51a3"
                    ]
                },
                "ASRS3": {
                    "questionId": "ASRS3",
                    "chosenOptionIds": [
                        "0a71cb5f-98cb-4130-9625-50c5cfa690ff"
                    ]
                },
                "ASRS4": {
                    "questionId": "ASRS4",
                    "chosenOptionIds": [
                        "4b03fe6b-aaa6-4aaf-9524-f71f0af63967"
                    ]
                },
                "ASRS5": {
                    "questionId": "ASRS5",
                    "chosenOptionIds": [
                        "f30bde00-6fd5-45ce-98fe-feb2107c5a91"
                    ]
                },
                "ASRS6": {
                    "questionId": "ASRS6",
                    "chosenOptionIds": [
                        "66ed09ee-495b-47f4-a535-bff7f9d53970"
                    ]
                },
                "ASRS7": {
                    "questionId": "ASRS7",
                    "chosenOptionIds": [
                        "ed15bfa8-d408-46ab-9525-b0b42e0d595a"
                    ]
                },
                "ASRS8": {
                    "questionId": "ASRS8",
                    "chosenOptionIds": [
                        "d5030f6f-ad19-49ae-a0cb-db56d5ba31f9"
                    ]
                },
                "ASRS9": {
                    "questionId": "ASRS9",
                    "chosenOptionIds": [
                        "79de57bb-786b-49b7-ba5d-4eef37005d2c"
                    ]
                },
                "ASRS10": {
                    "questionId": "ASRS10",
                    "chosenOptionIds": [
                        "90526640-e201-488c-a16c-c84dcb771bc9"
                    ]
                },
                "ASRS11": {
                    "questionId": "ASRS11",
                    "chosenOptionIds": [
                        "df93abe7-de34-4966-b517-baab39465ddd"
                    ]
                },
                "ASRS12": {
                    "questionId": "ASRS12",
                    "chosenOptionIds": [
                        "484c95df-070b-4b06-a210-c6aa2cb652c4"
                    ]
                },
                "ASRS13": {
                    "questionId": "ASRS13",
                    "chosenOptionIds": [
                        "72820602-4ddb-437d-bb2b-020a8c6396e4"
                    ]
                },
                "ASRS14": {
                    "questionId": "ASRS14",
                    "chosenOptionIds": [
                        "6c9bab2e-9d57-46f6-be81-0c931ed4e320"
                    ]
                },
                "ASRS15": {
                    "questionId": "ASRS15",
                    "chosenOptionIds": [
                        "3a809ef8-942a-4ee8-89f3-447dc5dc5d04"
                    ]
                },
                "ASRS16": {
                    "questionId": "ASRS16",
                    "chosenOptionIds": [
                        "afd3f876-98d1-4e90-892b-57d26f960c01"
                    ]
                },
                "ASRS17": {
                    "questionId": "ASRS17",
                    "chosenOptionIds": [
                        "287f23de-4ddd-4976-a2a4-76028e6f89f1"
                    ]
                },
                "ASRS18": {
                    "questionId": "ASRS18",
                    "chosenOptionIds": [
                        "24e8ee51-b4e8-41e9-922b-fe93e03fe04c"
                    ]
                },
                "WURS1": {
                    "questionId": "WURS1",
                    "chosenOptionIds": [
                        "5fa54c0a-9589-4bb0-b934-69eaf1f5d890"
                    ]
                },
                "WURS2": {
                    "questionId": "WURS2",
                    "chosenOptionIds": [
                        "4o5p6q7r-8s9t-0u1v-2w3x-4y5z6a7b8c9d"
                    ]
                },
                "WURS3": {
                    "questionId": "WURS3",
                    "chosenOptionIds": [
                        "83bb76b9-9d9f-4b4e-921c-c5e1e828f57e"
                    ]
                },
                "WURS4": {
                    "questionId": "WURS4",
                    "chosenOptionIds": [
                        "6d4f80d3-11b1-4f17-a6df-6b0c6a6f2e2f"
                    ]
                },
                "WURS5": {
                    "questionId": "WURS5",
                    "chosenOptionIds": [
                        "1340f1d1-b6cb-4136-bd1c-72a5d50fc076"
                    ]
                },
                "WURS6": {
                    "questionId": "WURS6",
                    "chosenOptionIds": [
                        "8d8c1924-c065-4875-b318-5662106fe463"
                    ]
                },
                "WURS7": {
                    "questionId": "WURS7",
                    "chosenOptionIds": [
                        "4c1f9e2a-5d7b-4a8b-8c4f-9e6d7f5a1b2c"
                    ]
                },
                "WURS8": {
                    "questionId": "WURS8",
                    "chosenOptionIds": [
                        "b9c317ab-73af-40e5-a934-bba4a9b31de0"
                    ]
                },
                "WURS9": {
                    "questionId": "WURS9",
                    "chosenOptionIds": [
                        "6c7d8e9f-0a1b-2c3d-4e5f-6a7b8c9d0e1f"
                    ]
                },
                "WURS10": {
                    "questionId": "WURS10",
                    "chosenOptionIds": [
                        "0193b205-95ef-4609-8136-06d9e3f17a74"
                    ]
                },
                "WURS11": {
                    "questionId": "WURS11",
                    "chosenOptionIds": [
                        "8k9l0m1n-2o3p-4q5r-6s7t-8u9v0w1x2y3z"
                    ]
                },
                "WURS12": {
                    "questionId": "WURS12",
                    "chosenOptionIds": [
                        "85ff2ae0-5f1e-4b1e-9d33-7ed58b7c9e0f"
                    ]
                },
                "WURS13": {
                    "questionId": "WURS13",
                    "chosenOptionIds": [
                        "48c3d6ea-726a-4dca-99be-78d58e6be6fa"
                    ]
                },
                "WURS14": {
                    "questionId": "WURS14",
                    "chosenOptionIds": [
                        "d2e98b3d-771e-4dd5-a31e-3e7f86c7c0d5"
                    ]
                },
                "WURS15": {
                    "questionId": "WURS15",
                    "chosenOptionIds": [
                        "8f7d5e6c-4c2b-4b9f-9e9b-9b5f6a7c2c33"
                    ]
                },
                "WURS16": {
                    "questionId": "WURS16",
                    "chosenOptionIds": [
                        "0e1f2a3b-4c5d-6e7f-8a9b-0c1d2e3f4a5b"
                    ]
                },
                "WURS17": {
                    "questionId": "WURS17",
                    "chosenOptionIds": [
                        "3f4g5h6i-7j8k-9l0m-1n2o-3p4q5r6s7t8u"
                    ]
                },
                "WURS18": {
                    "questionId": "WURS18",
                    "chosenOptionIds": [
                        "62053d76-44c1-4755-95d1-7496868b9e8a"
                    ]
                },
                "WURS19": {
                    "questionId": "WURS19",
                    "chosenOptionIds": [
                        "9e2f4ef0-45b3-4f07-83af-7ae8240a0de9"
                    ]
                },
                "WURS20": {
                    "questionId": "WURS20",
                    "chosenOptionIds": [
                        "9y0z1a2b-3c4d-5e6f-7g8h-9i0j1k2l3m4n"
                    ]
                },
                "WURS21": {
                    "questionId": "WURS21",
                    "chosenOptionIds": [
                        "54d62461-1f80-4ec6-8c24-2db8d37f9f19"
                    ]
                },
                "WURS22": {
                    "questionId": "WURS22",
                    "chosenOptionIds": [
                        "90a9a7b7-0e60-4b78-8d1d-8e6f1d3a6b3e"
                    ]
                },
                "WURS23": {
                    "questionId": "WURS23",
                    "chosenOptionIds": [
                        "8a9b0c1d-2e3f-4a5b-6c7d-8e9f0a1b2c3d"
                    ]
                },
                "WURS24": {
                    "questionId": "WURS24",
                    "chosenOptionIds": [
                        "5u6v7w8x-9y0z-1a2b-3c4d-5e6f7g8h9i0j"
                    ]
                },
                "WURS25": {
                    "questionId": "WURS25",
                    "chosenOptionIds": [
                        "9f0a1b2c-3d4e-5f6a-7b8c-9d0e1f2a3b4c"
                    ]
                }
            }
        },
        {
            "name": "adhd_asrs_possible_and_wurs_25_score_possible_lower_range-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.42,
                "threshold": 0
            },
            "answers": {
                "ASRS1": {
                    "questionId": "ASRS1",
                    "chosenOptionIds": [
                        "b0ea051b-76ac-4fd3-9d8e-cad22d60f786"
                    ]
                },
                "ASRS2": {
                    "questionId": "ASRS2",
                    "chosenOptionIds": [
                        "628d1f37-f6d5-4a30-b154-5ac3121b51a3"
                    ]
                },
                "ASRS3": {
                    "questionId": "ASRS3",
                    "chosenOptionIds": [
                        "0a71cb5f-98cb-4130-9625-50c5cfa690ff"
                    ]
                },
                "ASRS4": {
                    "questionId": "ASRS4",
                    "chosenOptionIds": [
                        "4b03fe6b-aaa6-4aaf-9524-f71f0af63967"
                    ]
                },
                "ASRS5": {
                    "questionId": "ASRS5",
                    "chosenOptionIds": [
                        "f30bde00-6fd5-45ce-98fe-feb2107c5a91"
                    ]
                },
                "ASRS6": {
                    "questionId": "ASRS6",
                    "chosenOptionIds": [
                        "66ed09ee-495b-47f4-a535-bff7f9d53970"
                    ]
                },
                "ASRS7": {
                    "questionId": "ASRS7",
                    "chosenOptionIds": [
                        "ed15bfa8-d408-46ab-9525-b0b42e0d595a"
                    ]
                },
                "ASRS8": {
                    "questionId": "ASRS8",
                    "chosenOptionIds": [
                        "d5030f6f-ad19-49ae-a0cb-db56d5ba31f9"
                    ]
                },
                "ASRS9": {
                    "questionId": "ASRS9",
                    "chosenOptionIds": [
                        "79de57bb-786b-49b7-ba5d-4eef37005d2c"
                    ]
                },
                "ASRS10": {
                    "questionId": "ASRS10",
                    "chosenOptionIds": [
                        "90526640-e201-488c-a16c-c84dcb771bc9"
                    ]
                },
                "ASRS11": {
                    "questionId": "ASRS11",
                    "chosenOptionIds": [
                        "df93abe7-de34-4966-b517-baab39465ddd"
                    ]
                },
                "ASRS12": {
                    "questionId": "ASRS12",
                    "chosenOptionIds": [
                        "484c95df-070b-4b06-a210-c6aa2cb652c4"
                    ]
                },
                "ASRS13": {
                    "questionId": "ASRS13",
                    "chosenOptionIds": [
                        "72820602-4ddb-437d-bb2b-020a8c6396e4"
                    ]
                },
                "ASRS14": {
                    "questionId": "ASRS14",
                    "chosenOptionIds": [
                        "6c9bab2e-9d57-46f6-be81-0c931ed4e320"
                    ]
                },
                "ASRS15": {
                    "questionId": "ASRS15",
                    "chosenOptionIds": [
                        "3a809ef8-942a-4ee8-89f3-447dc5dc5d04"
                    ]
                },
                "ASRS16": {
                    "questionId": "ASRS16",
                    "chosenOptionIds": [
                        "afd3f876-98d1-4e90-892b-57d26f960c01"
                    ]
                },
                "ASRS17": {
                    "questionId": "ASRS17",
                    "chosenOptionIds": [
                        "287f23de-4ddd-4976-a2a4-76028e6f89f1"
                    ]
                },
                "ASRS18": {
                    "questionId": "ASRS18",
                    "chosenOptionIds": [
                        "24e8ee51-b4e8-41e9-922b-fe93e03fe04c"
                    ]
                },
                "WURS1": {
                    "questionId": "WURS1",
                    "chosenOptionIds": [
                        "5fa54c0a-9589-4bb0-b934-69eaf1f5d890"
                    ]
                },
                "WURS2": {
                    "questionId": "WURS2",
                    "chosenOptionIds": [
                        "4o5p6q7r-8s9t-0u1v-2w3x-4y5z6a7b8c9d"
                    ]
                },
                "WURS3": {
                    "questionId": "WURS3",
                    "chosenOptionIds": [
                        "83bb76b9-9d9f-4b4e-921c-c5e1e828f57e"
                    ]
                },
                "WURS4": {
                    "questionId": "WURS4",
                    "chosenOptionIds": [
                        "6d4f80d3-11b1-4f17-a6df-6b0c6a6f2e2f"
                    ]
                },
                "WURS5": {
                    "questionId": "WURS5",
                    "chosenOptionIds": [
                        "1340f1d1-b6cb-4136-bd1c-72a5d50fc076"
                    ]
                },
                "WURS6": {
                    "questionId": "WURS6",
                    "chosenOptionIds": [
                        "8d8c1924-c065-4875-b318-5662106fe463"
                    ]
                },
                "WURS7": {
                    "questionId": "WURS7",
                    "chosenOptionIds": [
                        "9b5f6a7c-2c33-4b9f-9e9b-8f7d5e6c4c2b"
                    ]
                },
                "WURS8": {
                    "questionId": "WURS8",
                    "chosenOptionIds": [
                        "e35d2ae1-9df5-4d4c-842e-f1e3e9e19795"
                    ]
                },
                "WURS9": {
                    "questionId": "WURS9",
                    "chosenOptionIds": [
                        "9d0e1f2a-3b4c-5d6e-7f8a-9b0c1d2e3f4a"
                    ]
                },
                "WURS10": {
                    "questionId": "WURS10",
                    "chosenOptionIds": [
                        "0193b205-95ef-4609-8136-06d9e3f17a74"
                    ]
                },
                "WURS11": {
                    "questionId": "WURS11",
                    "chosenOptionIds": [
                        "8k9l0m1n-2o3p-4q5r-6s7t-8u9v0w1x2y3z"
                    ]
                },
                "WURS12": {
                    "questionId": "WURS12",
                    "chosenOptionIds": [
                        "85ff2ae0-5f1e-4b1e-9d33-7ed58b7c9e0f"
                    ]
                },
                "WURS13": {
                    "questionId": "WURS13",
                    "chosenOptionIds": [
                        "48c3d6ea-726a-4dca-99be-78d58e6be6fa"
                    ]
                },
                "WURS14": {
                    "questionId": "WURS14",
                    "chosenOptionIds": [
                        "d2e98b3d-771e-4dd5-a31e-3e7f86c7c0d5"
                    ]
                },
                "WURS15": {
                    "questionId": "WURS15",
                    "chosenOptionIds": [
                        "8f7d5e6c-4c2b-4b9f-9e9b-9b5f6a7c2c33"
                    ]
                },
                "WURS16": {
                    "questionId": "WURS16",
                    "chosenOptionIds": [
                        "0e1f2a3b-4c5d-6e7f-8a9b-0c1d2e3f4a5b"
                    ]
                },
                "WURS17": {
                    "questionId": "WURS17",
                    "chosenOptionIds": [
                        "3f4g5h6i-7j8k-9l0m-1n2o-3p4q5r6s7t8u"
                    ]
                },
                "WURS18": {
                    "questionId": "WURS18",
                    "chosenOptionIds": [
                        "62053d76-44c1-4755-95d1-7496868b9e8a"
                    ]
                },
                "WURS19": {
                    "questionId": "WURS19",
                    "chosenOptionIds": [
                        "9e2f4ef0-45b3-4f07-83af-7ae8240a0de9"
                    ]
                },
                "WURS20": {
                    "questionId": "WURS20",
                    "chosenOptionIds": [
                        "9y0z1a2b-3c4d-5e6f-7g8h-9i0j1k2l3m4n"
                    ]
                },
                "WURS21": {
                    "questionId": "WURS21",
                    "chosenOptionIds": [
                        "54d62461-1f80-4ec6-8c24-2db8d37f9f19"
                    ]
                },
                "WURS22": {
                    "questionId": "WURS22",
                    "chosenOptionIds": [
                        "90a9a7b7-0e60-4b78-8d1d-8e6f1d3a6b3e"
                    ]
                },
                "WURS23": {
                    "questionId": "WURS23",
                    "chosenOptionIds": [
                        "8a9b0c1d-2e3f-4a5b-6c7d-8e9f0a1b2c3d"
                    ]
                },
                "WURS24": {
                    "questionId": "WURS24",
                    "chosenOptionIds": [
                        "5u6v7w8x-9y0z-1a2b-3c4d-5e6f7g8h9i0j"
                    ]
                },
                "WURS25": {
                    "questionId": "WURS25",
                    "chosenOptionIds": [
                        "9f0a1b2c-3d4e-5f6a-7b8c-9d0e1f2a3b4c"
                    ]
                }
            }
        },
        {
            "name": "adhd_asrs_possible_and_wurs_25_score_all_mildly_possible_lower_range-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.42,
                "threshold": 0
            },
            "answers": {
                "ASRS1": {
                    "questionId": "ASRS1",
                    "chosenOptionIds": [
                        "b0ea051b-76ac-4fd3-9d8e-cad22d60f786"
                    ]
                },
                "ASRS2": {
                    "questionId": "ASRS2",
                    "chosenOptionIds": [
                        "628d1f37-f6d5-4a30-b154-5ac3121b51a3"
                    ]
                },
                "ASRS3": {
                    "questionId": "ASRS3",
                    "chosenOptionIds": [
                        "0a71cb5f-98cb-4130-9625-50c5cfa690ff"
                    ]
                },
                "ASRS4": {
                    "questionId": "ASRS4",
                    "chosenOptionIds": [
                        "4b03fe6b-aaa6-4aaf-9524-f71f0af63967"
                    ]
                },
                "ASRS5": {
                    "questionId": "ASRS5",
                    "chosenOptionIds": [
                        "f30bde00-6fd5-45ce-98fe-feb2107c5a91"
                    ]
                },
                "ASRS6": {
                    "questionId": "ASRS6",
                    "chosenOptionIds": [
                        "66ed09ee-495b-47f4-a535-bff7f9d53970"
                    ]
                },
                "ASRS7": {
                    "questionId": "ASRS7",
                    "chosenOptionIds": [
                        "ed15bfa8-d408-46ab-9525-b0b42e0d595a"
                    ]
                },
                "ASRS8": {
                    "questionId": "ASRS8",
                    "chosenOptionIds": [
                        "d5030f6f-ad19-49ae-a0cb-db56d5ba31f9"
                    ]
                },
                "ASRS9": {
                    "questionId": "ASRS9",
                    "chosenOptionIds": [
                        "79de57bb-786b-49b7-ba5d-4eef37005d2c"
                    ]
                },
                "ASRS10": {
                    "questionId": "ASRS10",
                    "chosenOptionIds": [
                        "90526640-e201-488c-a16c-c84dcb771bc9"
                    ]
                },
                "ASRS11": {
                    "questionId": "ASRS11",
                    "chosenOptionIds": [
                        "df93abe7-de34-4966-b517-baab39465ddd"
                    ]
                },
                "ASRS12": {
                    "questionId": "ASRS12",
                    "chosenOptionIds": [
                        "484c95df-070b-4b06-a210-c6aa2cb652c4"
                    ]
                },
                "ASRS13": {
                    "questionId": "ASRS13",
                    "chosenOptionIds": [
                        "72820602-4ddb-437d-bb2b-020a8c6396e4"
                    ]
                },
                "ASRS14": {
                    "questionId": "ASRS14",
                    "chosenOptionIds": [
                        "6c9bab2e-9d57-46f6-be81-0c931ed4e320"
                    ]
                },
                "ASRS15": {
                    "questionId": "ASRS15",
                    "chosenOptionIds": [
                        "3a809ef8-942a-4ee8-89f3-447dc5dc5d04"
                    ]
                },
                "ASRS16": {
                    "questionId": "ASRS16",
                    "chosenOptionIds": [
                        "afd3f876-98d1-4e90-892b-57d26f960c01"
                    ]
                },
                "ASRS17": {
                    "questionId": "ASRS17",
                    "chosenOptionIds": [
                        "287f23de-4ddd-4976-a2a4-76028e6f89f1"
                    ]
                },
                "ASRS18": {
                    "questionId": "ASRS18",
                    "chosenOptionIds": [
                        "24e8ee51-b4e8-41e9-922b-fe93e03fe04c"
                    ]
                },
                "WURS1": {
                    "questionId": "WURS1",
                    "chosenOptionIds": [
                        "2de63b2c-b5f9-4d55-8c54-ef85d7791a64"
                    ]
                },
                "WURS2": {
                    "questionId": "WURS2",
                    "chosenOptionIds": [
                        "2e3f4a5b-6c7d-8e9f-0a1b-2c3d4e5f6a7b"
                    ]
                },
                "WURS3": {
                    "questionId": "WURS3",
                    "chosenOptionIds": [
                        "3c0a1c7f-1a91-4bf6-b3e7-0c0e4f531976"
                    ]
                },
                "WURS4": {
                    "questionId": "WURS4",
                    "chosenOptionIds": [
                        "2e60fcd0-345f-43b2-9e8e-4a0f3ebcbe3d"
                    ]
                },
                "WURS6": {
                    "questionId": "WURS6",
                    "chosenOptionIds": [
                        "ed5a974b-cced-45aa-8123-af9762ce28b6"
                    ]
                },
                "WURS7": {
                    "questionId": "WURS7",
                    "chosenOptionIds": [
                        "9b5f6a7c-2c33-4b9f-9e9b-8f7d5e6c4c2b"
                    ]
                },
                "WURS8": {
                    "questionId": "WURS8",
                    "chosenOptionIds": [
                        "08922ad8-1f41-41e1-98ef-d5e3c152e459"
                    ]
                },
                "WURS9": {
                    "questionId": "WURS9",
                    "chosenOptionIds": [
                        "5d6e7f8a-9b0c-1d2e-3f4a-5b6c7d8e9f0a"
                    ]
                },
                "WURS10": {
                    "questionId": "WURS10",
                    "chosenOptionIds": [
                        "46bb5d8e-89cf-46ab-9e26-8f09b78d34fa"
                    ]
                },
                "WURS11": {
                    "questionId": "WURS11",
                    "chosenOptionIds": [
                        "1b2c3d4e-5f6a-7b8c-9d0e-1f2a3b4c5d6e"
                    ]
                },
                "WURS12": {
                    "questionId": "WURS12",
                    "chosenOptionIds": [
                        "da825593-6b9d-4f9d-b03f-b2cfbd98e3aa"
                    ]
                },
                "WURS13": {
                    "questionId": "WURS13",
                    "chosenOptionIds": [
                        "f3a5d0b4-7598-4f93-9b5d-1b0a6b36e94e"
                    ]
                },
                "WURS14": {
                    "questionId": "WURS14",
                    "chosenOptionIds": [
                        "9e6f7f71-8d14-42e1-927e-92e134a4e8e7"
                    ]
                },
                "WURS15": {
                    "questionId": "WURS15",
                    "chosenOptionIds": [
                        "7a1b2c3d-4e5f-6a7b-8c9d-0e1f2a3b4c5d"
                    ]
                },
                "WURS16": {
                    "questionId": "WURS16",
                    "chosenOptionIds": [
                        "5b6c7d8e-9f0a-1b2c-3d4e-5f6a7b8c9d0e"
                    ]
                },
                "WURS17": {
                    "questionId": "WURS17",
                    "chosenOptionIds": [
                        "6a7b8c9d-0e1f-2a3b-4c5d-6e7f8a9b0c1d"
                    ]
                },
                "WURS18": {
                    "questionId": "WURS18",
                    "chosenOptionIds": [
                        "65a81be0-50bb-4e14-99ba-3d8e7c4f8b3c"
                    ]
                },
                "WURS19": {
                    "questionId": "WURS19",
                    "chosenOptionIds": [
                        "f942d8b6-8ed9-4c66-b6a8-9a8f5c5bb6c9"
                    ]
                },
                "WURS20": {
                    "questionId": "WURS20",
                    "chosenOptionIds": [
                        "5f6a7b8c-9d0e-1f2a-3b4c-5d6e7f8a9b0c"
                    ]
                },
                "WURS21": {
                    "questionId": "WURS21",
                    "chosenOptionIds": [
                        "aaf6d1ad-0a18-411a-b0d8-3a0ab1268df1"
                    ]
                },
                "WURS22": {
                    "questionId": "WURS22",
                    "chosenOptionIds": [
                        "6b3d0b0c-81ea-49bf-9b2c-88c5b445e7f9"
                    ]
                },
                "WURS23": {
                    "questionId": "WURS23",
                    "chosenOptionIds": [
                        "579802d2-7a7c-40ee-b7a6-b9c2066e2ee4"
                    ]
                },
                "WURS24": {
                    "questionId": "WURS24",
                    "chosenOptionIds": [
                        "af8c0b6a-7fcf-428a-be53-e4612554f61e"
                    ]
                },
                "WURS25": {
                    "questionId": "WURS25",
                    "chosenOptionIds": [
                        "0h1i2j3k-4l5m-6n7o-8p9q-0r1s2t3u4v5w"
                    ]
                }
            }
        },
        {
            "name": "adhd_asrs_possible_and_wurs_0_score_possible-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.25,
                "threshold": 0
            },
            "answers": {
                "ASRS1": {
                    "questionId": "ASRS1",
                    "chosenOptionIds": [
                        "b0ea051b-76ac-4fd3-9d8e-cad22d60f786"
                    ]
                },
                "ASRS2": {
                    "questionId": "ASRS2",
                    "chosenOptionIds": [
                        "628d1f37-f6d5-4a30-b154-5ac3121b51a3"
                    ]
                },
                "ASRS3": {
                    "questionId": "ASRS3",
                    "chosenOptionIds": [
                        "0a71cb5f-98cb-4130-9625-50c5cfa690ff"
                    ]
                },
                "ASRS4": {
                    "questionId": "ASRS4",
                    "chosenOptionIds": [
                        "4b03fe6b-aaa6-4aaf-9524-f71f0af63967"
                    ]
                },
                "ASRS5": {
                    "questionId": "ASRS5",
                    "chosenOptionIds": [
                        "f30bde00-6fd5-45ce-98fe-feb2107c5a91"
                    ]
                },
                "ASRS6": {
                    "questionId": "ASRS6",
                    "chosenOptionIds": [
                        "66ed09ee-495b-47f4-a535-bff7f9d53970"
                    ]
                },
                "ASRS7": {
                    "questionId": "ASRS7",
                    "chosenOptionIds": [
                        "ed15bfa8-d408-46ab-9525-b0b42e0d595a"
                    ]
                },
                "ASRS8": {
                    "questionId": "ASRS8",
                    "chosenOptionIds": [
                        "d5030f6f-ad19-49ae-a0cb-db56d5ba31f9"
                    ]
                },
                "ASRS9": {
                    "questionId": "ASRS9",
                    "chosenOptionIds": [
                        "79de57bb-786b-49b7-ba5d-4eef37005d2c"
                    ]
                },
                "ASRS10": {
                    "questionId": "ASRS10",
                    "chosenOptionIds": [
                        "90526640-e201-488c-a16c-c84dcb771bc9"
                    ]
                },
                "ASRS11": {
                    "questionId": "ASRS11",
                    "chosenOptionIds": [
                        "df93abe7-de34-4966-b517-baab39465ddd"
                    ]
                },
                "ASRS12": {
                    "questionId": "ASRS12",
                    "chosenOptionIds": [
                        "484c95df-070b-4b06-a210-c6aa2cb652c4"
                    ]
                },
                "ASRS13": {
                    "questionId": "ASRS13",
                    "chosenOptionIds": [
                        "72820602-4ddb-437d-bb2b-020a8c6396e4"
                    ]
                },
                "ASRS14": {
                    "questionId": "ASRS14",
                    "chosenOptionIds": [
                        "6c9bab2e-9d57-46f6-be81-0c931ed4e320"
                    ]
                },
                "ASRS15": {
                    "questionId": "ASRS15",
                    "chosenOptionIds": [
                        "3a809ef8-942a-4ee8-89f3-447dc5dc5d04"
                    ]
                },
                "ASRS16": {
                    "questionId": "ASRS16",
                    "chosenOptionIds": [
                        "afd3f876-98d1-4e90-892b-57d26f960c01"
                    ]
                },
                "ASRS17": {
                    "questionId": "ASRS17",
                    "chosenOptionIds": [
                        "287f23de-4ddd-4976-a2a4-76028e6f89f1"
                    ]
                },
                "ASRS18": {
                    "questionId": "ASRS18",
                    "chosenOptionIds": [
                        "24e8ee51-b4e8-41e9-922b-fe93e03fe04c"
                    ]
                },
                "WURS1": {
                    "questionId": "WURS1",
                    "chosenOptionIds": [
                        "c7f96f1a-5dd5-4324-84b8-886e6b47f8d6"
                    ]
                },
                "WURS2": {
                    "questionId": "WURS2",
                    "chosenOptionIds": [
                        "7w8x9y0z-1a2b-3c4d-5e6f-7g8h9i0j1k2l"
                    ]
                },
                "WURS3": {
                    "questionId": "WURS3",
                    "chosenOptionIds": [
                        "132f64dc-4c3e-4362-bb64-2fa3e6e4c76c"
                    ]
                },
                "WURS4": {
                    "questionId": "WURS4",
                    "chosenOptionIds": [
                        "7b8333a3-9ecf-4a93-8f3b-10b3e9b5f6c2"
                    ]
                },
                "WURS6": {
                    "questionId": "WURS6",
                    "chosenOptionIds": [
                        "eefb6ce5-1a69-48b5-ac7e-2082119f0bff"
                    ]
                },
                "WURS7": {
                    "questionId": "WURS7",
                    "chosenOptionIds": [
                        "6a1e59f8-7367-46a5-9f1c-ccfda1e2e3e8"
                    ]
                },
                "WURS8": {
                    "questionId": "WURS8",
                    "chosenOptionIds": [
                        "e35d2ae1-9df5-4d4c-842e-f1e3e9e19795"
                    ]
                },
                "WURS9": {
                    "questionId": "WURS9",
                    "chosenOptionIds": [
                        "9d0e1f2a-3b4c-5d6e-7f8a-9b0c1d2e3f4a"
                    ]
                },
                "WURS10": {
                    "questionId": "WURS10",
                    "chosenOptionIds": [
                        "0193b205-95ef-4609-8136-06d9e3f17a74"
                    ]
                },
                "WURS11": {
                    "questionId": "WURS11",
                    "chosenOptionIds": [
                        "8k9l0m1n-2o3p-4q5r-6s7t-8u9v0w1x2y3z"
                    ]
                },
                "WURS12": {
                    "questionId": "WURS12",
                    "chosenOptionIds": [
                        "85ff2ae0-5f1e-4b1e-9d33-7ed58b7c9e0f"
                    ]
                },
                "WURS13": {
                    "questionId": "WURS13",
                    "chosenOptionIds": [
                        "48c3d6ea-726a-4dca-99be-78d58e6be6fa"
                    ]
                },
                "WURS14": {
                    "questionId": "WURS14",
                    "chosenOptionIds": [
                        "d2e98b3d-771e-4dd5-a31e-3e7f86c7c0d5"
                    ]
                },
                "WURS15": {
                    "questionId": "WURS15",
                    "chosenOptionIds": [
                        "8f7d5e6c-4c2b-4b9f-9e9b-9b5f6a7c2c33"
                    ]
                },
                "WURS16": {
                    "questionId": "WURS16",
                    "chosenOptionIds": [
                        "0e1f2a3b-4c5d-6e7f-8a9b-0c1d2e3f4a5b"
                    ]
                },
                "WURS17": {
                    "questionId": "WURS17",
                    "chosenOptionIds": [
                        "3f4g5h6i-7j8k-9l0m-1n2o-3p4q5r6s7t8u"
                    ]
                },
                "WURS18": {
                    "questionId": "WURS18",
                    "chosenOptionIds": [
                        "62053d76-44c1-4755-95d1-7496868b9e8a"
                    ]
                },
                "WURS19": {
                    "questionId": "WURS19",
                    "chosenOptionIds": [
                        "9e2f4ef0-45b3-4f07-83af-7ae8240a0de9"
                    ]
                },
                "WURS20": {
                    "questionId": "WURS20",
                    "chosenOptionIds": [
                        "9y0z1a2b-3c4d-5e6f-7g8h-9i0j1k2l3m4n"
                    ]
                },
                "WURS21": {
                    "questionId": "WURS21",
                    "chosenOptionIds": [
                        "54d62461-1f80-4ec6-8c24-2db8d37f9f19"
                    ]
                },
                "WURS22": {
                    "questionId": "WURS22",
                    "chosenOptionIds": [
                        "90a9a7b7-0e60-4b78-8d1d-8e6f1d3a6b3e"
                    ]
                },
                "WURS23": {
                    "questionId": "WURS23",
                    "chosenOptionIds": [
                        "8a9b0c1d-2e3f-4a5b-6c7d-8e9f0a1b2c3d"
                    ]
                },
                "WURS24": {
                    "questionId": "WURS24",
                    "chosenOptionIds": [
                        "5u6v7w8x-9y0z-1a2b-3c4d-5e6f7g8h9i0j"
                    ]
                },
                "WURS25": {
                    "questionId": "WURS25",
                    "chosenOptionIds": [
                        "9f0a1b2c-3d4e-5f6a-7b8c-9d0e1f2a3b4c"
                    ]
                }
            }
        },
        {
            "name": "adhd_asrs_not_possible_part_a_only_score_unlikely_higher_range-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.05,
                "threshold": 0.1
            },
            "answers": {
                "ASRS1": {
                    "questionId": "ASRS1",
                    "chosenOptionIds": [
                        "4c1c637b-7761-49a5-90af-e70832845e6d"
                    ]
                },
                "ASRS2": {
                    "questionId": "ASRS2",
                    "chosenOptionIds": [
                        "ac4acf1a-ec33-44f5-a740-ba737688b826"
                    ]
                },
                "ASRS3": {
                    "questionId": "ASRS3",
                    "chosenOptionIds": [
                        "f4447b42-b9fc-47f2-94ad-0f5ebf292417"
                    ]
                },
                "ASRS4": {
                    "questionId": "ASRS4",
                    "chosenOptionIds": [
                        "1b3b13ea-b3ec-4aaa-92da-01058e86c4c6"
                    ]
                },
                "ASRS5": {
                    "questionId": "ASRS5",
                    "chosenOptionIds": [
                        "c0469aa5-3511-4306-9e2a-769a8b2baa9d"
                    ]
                },
                "ASRS6": {
                    "questionId": "ASRS6",
                    "chosenOptionIds": [
                        "7fb4d290-f352-4a2e-bf70-868ce348f766"
                    ]
                }
            }
        },
        {
            "name": "adhd_asrs_not_possible_part_a_only_score_unlikely_lower_range-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.1,
                "threshold": 0.2
            },
            "answers": {
                "ASRS1": {
                    "questionId": "ASRS1",
                    "chosenOptionIds": [
                        "1e2492d3-7e08-4287-a9f1-f3dbef2612b2"
                    ]
                },
                "ASRS2": {
                    "questionId": "ASRS2",
                    "chosenOptionIds": [
                        "aa8e0ca0-41d7-454b-adb3-ca850d9ad836"
                    ]
                },
                "ASRS3": {
                    "questionId": "ASRS3",
                    "chosenOptionIds": [
                        "97ef4ce5-b425-4b58-9a9c-c5f9fd00324f"
                    ]
                },
                "ASRS4": {
                    "questionId": "ASRS4",
                    "chosenOptionIds": [
                        "bb80c0a5-0acf-409d-877c-21e0eec4ba93"
                    ]
                },
                "ASRS5": {
                    "questionId": "ASRS5",
                    "chosenOptionIds": [
                        "f30bde00-6fd5-45ce-98fe-feb2107c5a91"
                    ]
                },
                "ASRS6": {
                    "questionId": "ASRS6",
                    "chosenOptionIds": [
                        "66ed09ee-495b-47f4-a535-bff7f9d53970"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {
    "ASRS7": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "ASRS8": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "ASRS9": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "ASRS10": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "ASRS11": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "ASRS12": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "ASRS13": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "ASRS14": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "ASRS15": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "ASRS16": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "ASRS17": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "ASRS18": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "WURS1": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "WURS2": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "WURS3": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "WURS4": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "WURS5": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "WURS6": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "WURS7": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "WURS8": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "WURS9": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "WURS10": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "WURS11": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "WURS12": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "WURS13": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "WURS14": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "WURS15": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "WURS16": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "WURS17": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "WURS18": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "WURS19": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "WURS20": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "WURS21": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "WURS22": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "WURS23": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "WURS24": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]",
    "WURS25": "and[or[and[ASRS1>=0.5, or[and[ASRS2>=0.5, or[and[ASRS3>=0.5, or[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], and[ASRS4>=0.75, ASRS5>=0.75], and[ASRS4>=0.75, ASRS6>=0.75], and[ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]]], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS4>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS3>=0.5, ASRS5>=0.75, ASRS6>=0.75], and[ASRS2>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75], and[ASRS3>=0.5, ASRS4>=0.75, ASRS5>=0.75, ASRS6>=0.75]], not(isUndefined(ASRS6=0))]"
};
