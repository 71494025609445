import React, { useEffect, useState, useRef } from 'react'
import { Button } from '@psyomics/components'

import FilterIcon from 'ui/app/components/FilterIcon'
import Styles from './DateFilter.module.scss'

interface DateFilterProps {
    to: string | null
    from: string | null
    triggerFilter: (from: string | null, to: string | null) => void
}
export const DateFilter: React.FC<DateFilterProps> = ({ to, from, triggerFilter }) => {
    const [show, setShow] = useState<boolean>(false)
    const [dates, setDates] = useState<{ to: DateFilterProps['to']; from: DateFilterProps['from'] }>({
        to: null,
        from: null,
    })
    const idRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        const handler = (e: MouseEvent) => {
            if (idRef.current && !idRef.current.contains(e.target as Node)) {
                setShow(false)
            }
        }
        document.addEventListener('mousedown', handler)
        return () => {
            document.removeEventListener('mousedown', handler)
        }
    }, [])

    useEffect(() => {
        if (!show) {
            setDates({ to, from })
        }
    }, [show])

    const toggleShow = (e: React.MouseEvent) => {
        setShow((current) => !current)
    }
    const toggleShowKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === ' ' || e.key === 'Spacebar') {
            e.preventDefault()
            setShow((current) => !current)
        }
    }

    return (
        <div>
            <div className={Styles.filterCell}>
                <span
                    tabIndex={0}
                    role={'button'}
                    className={Styles.filterIconContainer}
                    onClick={toggleShow}
                    onKeyDown={toggleShowKeyPress}
                >
                    <FilterIcon></FilterIcon>
                    {(from || to) && <span className={Styles.filterActiveDot}></span>}
                </span>
            </div>
            <div
                style={{
                    position: 'relative',
                }}
            >
                <div ref={idRef} className={`${Styles.dateFilter} ${show ? Styles.showDates : ''}`}>
                    <label>
                        From:
                        <input
                            value={dates.from || undefined}
                            type="date"
                            onChange={(e) => {
                                setDates((prevDates) => ({ ...prevDates, from: e.target.value }))
                            }}
                        ></input>
                    </label>

                    <label>
                        To:
                        <input
                            value={dates.to || undefined}
                            type="date"
                            onChange={(e) => {
                                setDates((prevDates) => ({ ...prevDates, to: e.target.value }))
                            }}
                        ></input>
                    </label>
                    <div className={Styles.buttonsContainer}>
                        <Button
                            size="small"
                            appearance="secondary"
                            label="clear"
                            onClick={() => {
                                setDates({ to: null, from: null })
                                triggerFilter(null, null)
                            }}
                        ></Button>
                        <Button
                            size="small"
                            appearance="primary"
                            label="search"
                            onClick={() => {
                                triggerFilter(dates.from, dates.to)
                                setShow(false)
                            }}
                        ></Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
