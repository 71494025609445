"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
exports.questions = [
    {
        "__typename": "ContentQuestion",
        "id": "OCI-content-1",
        "text": "The next questions will ask about repetitive thoughts, images, or impulses that can be hard to ignore or get rid of. The questions will also ask if you have ever experienced an urge to perform certain actions to reduce anxiety or prevent something bad from happening.\n\nThese thoughts and urges can vary widely from person to person, so you'll be asked about a range of different experiences, some of which may not feel relevant to you.\n\nIt's important to know:\n\n- If you skip these questions, you will not be able to go back later.\n- Skipping will not affect your care.\n- You can discuss these topics with your clinician whenever you are ready.",
        "answers": [
            {
                "__typename": "Option",
                "id": "04925d67-b76c-4659-b0b7-9b9f70eae0cc",
                "text": "Continue and answer these questions",
                "primary": true,
                "value": 1
            },
            {
                "__typename": "Option",
                "id": "f36ef97c-cad8-4ec6-b3ef-747d0c5790c1",
                "text": "Skip these questions",
                "primary": false,
                "value": 0
            }
        ]
    },
    {
        "__typename": "ContentQuestion",
        "id": "OCI-content-2",
        "text": "The following statements refer to experiences that many people have in their everyday lives.\nSelect the option that best describes how much that experience has distressed or bothered you during the **past month**.",
        "answers": [
            {
                "__typename": "Option",
                "id": "2ff40db1-f4d7-4ce1-a8ce-f3112dcf743b",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a6383a05-2199-4c6d-8a74-463d64ac6657",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ce2f562b-5d4b-465d-ac54-58f2c23f741a",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "850abeca-6b8a-42d1-9c6a-23bc4e49f500",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "1af317bd-d6ac-4b03-994f-00d0fc91bd4f",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "c7e449e7-9fab-4c64-bccf-5985a3ad43ba",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-1",
        "text": "During the past month:\n\n**Unpleasant thoughts come into my mind against my will and I cannot get rid of them**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "c8204fb3-2d6c-4327-ac6e-3cca055e68ff",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f7ebe38e-0481-4a4b-8ba2-b2890079d4ce",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "a417ea72-6c53-44b5-a747-802c2fc5d696",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "3ed98ebd-a891-478d-9d6b-c3dd2a055e5e",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "bf4de7e0-5b4b-4d2d-be3c-2caf7ae08336",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-2",
        "text": "During the past month:\n\n**I think contact with bodily secretions (perspiration, saliva, blood, urine, etc) may contaminate my clothes or somehow harm me**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "0d0255bb-752a-4689-a3f1-e34a5db7803a",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "74bf0e05-3b41-44f7-ac86-faed0670c627",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "e3bdb0f3-9559-4ba7-a613-2833089b7ab0",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "16038870-226c-4a3b-8c9b-b38e99f7d5f8",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "8b0bde8e-8dc1-4e3f-9b8b-14d8bf40d85a",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-3",
        "text": "During the past month:\n\n**I ask people to repeat things to me several times, even though I understood them the first time**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "618cfdd6-a954-4109-9061-f32308ba3c7c",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "61cc75e1-f8fa-417e-9f8c-d6c8cd244529",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "f4c30217-e600-4e77-b1e3-871ca1241cc5",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "ea35d663-d1a3-4663-8394-0e91378725d3",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "f2056915-f4c3-4ce5-975c-fa0673623509",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-4",
        "text": "During the past month:\n\n**I wash and clean obsessively**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "c7ab2dd0-9598-405d-b43e-a92c69f584a5",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "173cad02-0b16-4864-adae-0c9bd63b5745",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "60a81934-6205-4dee-9de3-59495d5ce78c",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "91d12a0a-4c66-42ba-8a50-c9d6b7362802",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "3366a5be-c430-48d3-a432-cf26cfcdd5a4",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-5",
        "text": "During the past month:\n\n**I have to review mentally past events, conversations and actions to make sure that I didn't do something wrong**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "328d3d9b-1050-4317-9200-0ec6bd72cef8",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c91cb05d-bd4b-4973-a1f0-cbca4486cade",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "87c5945a-2f51-4123-b9db-ca66df4f1c07",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "739b604a-66ef-42c5-8b28-5226dcf6e53c",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "6b3173e2-dd2a-49ba-9bf4-4133fb115422",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-6",
        "text": "During the past month:\n\n**I have saved up so many things that they get in the way**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "5d49fde9-66e4-483d-bd3b-f4a31cc5747f",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "883ce287-e7ca-4f4d-970a-b294368e334f",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "4e7ad016-7f60-4bb5-892a-7c86f5838bb0",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "0306cd11-d479-48d7-bf42-78ec4993894a",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "d2ac1835-55f0-4a70-ace6-8142bfeaeace",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-7",
        "text": "During the past month:\n\n**I check things more often than necessary**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "d240a2e7-4361-4dcb-9a0c-954a2fa91a2d",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "81711125-32e8-4941-ad76-27825cc5f1b7",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "4cda8e47-9911-459b-b766-06501ab21b42",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "3d685022-fe73-42ad-91f8-de863ed81cca",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "73cfac35-95d5-4765-91c5-e657e1687e8a",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-8",
        "text": "During the past month:\n\n**I avoid using public toilets because I am afraid of disease or contamination**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "4679154d-262d-4dd7-9400-8d6f3778d370",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "3aabeae3-7340-47ee-b564-7df6b9af4a53",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "a5d709dd-4da6-4b4b-9e36-347fbfc05a36",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "8b2111e5-48ff-4fe1-b049-d4c95ae3416e",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "3a910533-45c0-457e-9f10-fadac28f2d89",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-9",
        "text": "During the past month:\n\n**I repeatedly check doors, windows, drawers etc.**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "fef92306-fc1a-4df9-a659-259ce0eabfd7",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "87e01fc4-3f83-4ab6-aa3f-1628cea7cace",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "4526497b-a1d7-405a-970a-0c407b41f709",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "23ac262e-0dd5-4cac-b318-24ce209abf3e",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "df3a9a30-afa8-45ae-a63b-2fe74e87b6c7",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-10",
        "text": "During the past month:\n\n**I repeatedly check gas and water taps and light switches after turning them off**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "d6c77058-4c90-45de-8521-78455f27346a",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "5e9d88d5-0251-408b-b206-d51562ab0d30",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "0d543c3a-1940-47f3-a488-e2c05ff43e67",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "6be4b214-a0ce-4bdb-ba75-88a9658fa8b2",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "ec46001b-9408-4c59-b95e-db21f2ecd0a1",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-11",
        "text": "During the past month:\n\n**I collect things I don't need**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "38ecb45a-50d8-49ab-bf54-7028a2dc1e27",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "faf4ffa1-de16-4978-95c2-0d9c3a7d03f9",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "66931bf4-159a-4f01-be50-551a76cd968c",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "bfddb52a-4958-4cd7-97ad-c3d22392354b",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "bc8f3b04-f637-46f4-b1ca-e4a6065b10fc",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-12",
        "text": "During the past month:\n\n**I have thoughts of having hurt someone without knowing it**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "12fa6a9a-4b33-4164-8f23-bee06fd904b1",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "9db6bf85-54f2-4c2f-9005-f56e26f74880",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "3dae9c9e-3535-4871-a31f-2265d98371cb",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "72237816-c11e-44af-b3a1-4d76ad321b9a",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "5ccb581a-6b8f-474e-93d2-9b31babd6c77",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-13",
        "text": "During the past month:\n\n**I have thoughts that I might want to harm myself or others**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "90e797a2-605f-4cb0-9622-86f465592d33",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f143a3c7-3196-45e6-829d-f12fba1eb36a",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "79948f1d-34b8-43cf-874b-3e800e5045d2",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "074cd9ce-ce41-4f67-a2e7-9a9e63b33479",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "8154c188-2fd6-4bd1-b1f3-aea84ec820d6",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-14",
        "text": "During the past month:\n\n**I get upset if objects are not arranged properly**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "c94a3cc7-e504-4d7e-acd8-690db69ccae8",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "8e029877-fbc0-4aa3-b63b-47c0731939e8",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "ed843992-9c74-42ea-9a7a-2328579ae609",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "ec11d6bf-0fba-4561-b6d3-9d1a1351b5bb",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "8adac844-d011-4442-814d-06d6526cf867",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-15",
        "text": "During the past month:\n\n**I feel obliged to follow a particular order in dressing, undressing and washing myself**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "941bf67e-0f0f-4617-8048-73c287a66785",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f6186e4d-7f0a-49e0-9329-a7b11191df9e",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "7c8b7dac-1fa8-43be-aedb-548030f24087",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "7eb1220c-4e29-4629-a36f-7d2505e6dff1",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "9b799242-a808-46c5-81b8-a4bd69d6c42a",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-16",
        "text": "During the past month:\n\n**I feel compelled to count while I am doing things**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "80eb1b27-5fa5-45e0-83db-9832b6614b5f",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "671f7514-42d0-449a-bd2e-5264b14c0d06",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "300e0f44-6b91-4076-aada-38da96000e9f",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "f7bf5d91-7a82-4f9e-b73d-f1d62ba6bcd1",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "b9eaf868-baab-472f-8e3d-e6b9875bbd96",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-17",
        "text": "During the past month:\n\n**I am afraid of impulsively doing embarrassing or harmful things**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "618a6686-d47b-4d24-8f4d-3f2eb8e0916c",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2aca05fa-27ad-4192-bc6b-1ce5819694ce",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "4c5bde0f-8341-485d-aa88-1c7788818c2e",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "88297bda-61fb-4911-a25d-50221f8e6173",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "ab513dc5-2b0d-4588-90f8-58c4fdce6113",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-18",
        "text": "During the past month:\n\n**I need to pray to cancel bad thoughts or feelings**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "73e264ee-e7f1-430c-b3d8-f5a1fbce22bc",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "90ca743f-8ad1-47b2-bf1a-d81c55365c47",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "c01d1b0f-d8da-42b5-90ac-edce6e051ad3",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "2acb5fdc-bddd-421b-8557-c841eeec8cd1",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "bb0f0a5d-f3a2-4c28-a406-3ec24c0aab95",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-19",
        "text": "During the past month:\n\n**I keep on checking forms or other things I have written**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a1051697-369a-4070-89a7-1fa3c16bb121",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7ffd5742-e264-40a0-98a7-b284a35f551e",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "a5a2721c-8a03-46f4-a2d1-cd0a1be9b82a",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "280021d4-ea94-4bf6-bcd1-e11e4a03a5c7",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "c43d0794-4dcd-486e-acb0-6a58dc5b875b",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-20",
        "text": "During the past month:\n\n**I get upset at the sight of knives, scissors and other sharp objects in case I lose control with them**",
        "isMultiselect": false
    },
    {
        "__typename": "ContentQuestion",
        "id": "OCI-content-3",
        "text": "You are making good progress through these questions about repetitive thoughts, images, or impulses and urges to perform certain actions. You are about half-way through these questions. \n\nRemember to select the option that best describes how much that experience has distressed or bothered you during the **past month**.\n\nPlease take a break if you need one. \n\nSelect 'Continue' when you are ready to carry on with the questions.",
        "answers": [
            {
                "__typename": "Option",
                "id": "5b863420-ff33-4076-95e6-287f6392d2c7",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b8462a3c-f24e-45cb-a179-0773858d4af2",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "cc9174a5-b6c7-47a3-a3d2-8849d21951bc",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "fb2f654d-a7f7-49f0-b8d9-f84e1c0546fe",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "61dab5e3-d6bb-4cd5-b384-5135028f9735",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "17908b73-b994-4e29-ae86-f2f8b916c415",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-21",
        "text": "During the past month:\n\n**I am excessively concerned about cleanliness**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "de65a9e9-e827-4c53-8873-12c7cc7f21ba",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "127c8356-6097-4e42-b329-4595556c2178",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "658673fa-7627-4a02-b492-6aee929ae56d",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "10d4c11a-b929-400b-8dba-b90b16ef7cf8",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "34d233e7-9f7c-48c8-8ea3-5845c1d99706",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-22",
        "text": "During the past month:\n\n**I find it difficult to touch an object when I know it has been touched by strangers or certain people**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "c57f9c36-3330-4e2f-9605-07442d556593",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d142eba3-d7df-4394-a0c7-11029fcb3a48",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "ebff5192-accb-46d8-9d01-382616d86aca",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "dc6af09b-6d72-4742-a2d0-e4d3596ff7a0",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "bb3dd17f-5dee-4e15-9903-c0f76b8c03f0",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-23",
        "text": "During the past month:\n\n**I need things to be arranged in a particular order**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "5d4b94b1-0f9f-45fd-904a-849dbd074718",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ce7ae1b1-2fa1-46f7-bf39-697326898d7f",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "68740cda-a86d-4d18-86ea-b1dad04c8dd8",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "eb3e447a-436c-41a5-8bf2-abc46037eb0c",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "4da19c6a-f488-4fb2-b26a-1942a4c77d1b",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-24",
        "text": "During the past month:\n\n**I get behind in my work because I repeat things over and over again**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "f4757c81-4224-4786-8ccf-979197e872d5",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "77421427-4d03-4658-9881-3b9caa71e4c0",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "ec5092ab-0f52-4ef0-b0d1-d06ef9e80107",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "420f359a-8e63-41b2-8787-09464e8f7520",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "3c9905aa-c38d-4e28-92bf-cf997d747fcc",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-25",
        "text": "During the past month:\n\n**I feel I have to repeat certain numbers**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "5a7275dd-0ec9-4be3-a262-3507c62e8717",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d7bca9dc-2442-45f8-96b4-cbec64cc7f35",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "285609e2-b485-43b5-9ede-00d846a0cf82",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "4926a1fb-7e1c-4583-accf-5680f7deb4bc",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "0725147d-7407-4419-a37b-0ee5632a804d",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-26",
        "text": "During the past month:\n\n**After doing something carefully, I still have the impression I have not finished it**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "354bc91e-9a8e-41db-9ece-dbe905bd4a0d",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2d164010-ec66-401e-9b7c-9322f0fec43a",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "2b457fd9-b843-4c6b-8fac-e98a81644434",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "3a0d78a1-12e4-45d9-9b6e-1a42c8edbdcc",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "25342c97-cc75-470f-923e-382500cc62a3",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-27",
        "text": "During the past month:\n\n**I find it difficult to touch garbage or dirty things**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "583757c5-9cfc-4e2d-9a07-d85c38ca8918",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "92c15d56-4c91-4dab-bd71-33fea666ad80",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "cf81bce0-871d-41cf-a19c-472494ebc202",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "2375a465-1a92-4a14-957d-5f7daad1f7a7",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "8b0cb701-dc3c-4a04-97db-bc07d6140e17",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-28",
        "text": "During the past month:\n\n**I find it difficult to control my own thoughts**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "7437519f-d718-4ce5-8053-211379eabf24",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b3c1e0bf-cac2-4f37-aaf2-493e0209e399",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "c7324820-b14a-4fcd-ae59-25d584d20cc1",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "bc93537f-a656-4ef2-9ed6-bd52436ae66e",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "cbf5c709-170f-41c0-bd7a-c648e1970655",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-29",
        "text": "During the past month:\n\n**I have to do things over and over again until it feels right**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "d5590152-174b-471d-ae77-1e3335e6cc7e",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "84822290-b1eb-492c-b97c-2c1ac530e7aa",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "7ad9e30a-02fa-4a05-af74-e65fe7ae9a3a",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "ecfbdb37-1c78-4efc-ac10-8bba3088be11",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "d5a32277-4fd6-42e5-b246-867edb8dff06",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-30",
        "text": "During the past month:\n\n**I am upset by unpleasant thoughts that come into my mind against my will**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "94eb0b53-32a4-4baa-9881-9c1872a68b25",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "6eed9b97-f9db-4dee-a854-7dfb6848a085",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "444fd488-6d42-4043-ab1c-9dc7a87e9653",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "a02109d1-0fca-4bd5-8901-da7c416f394c",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "48fd9b06-4408-43b4-864d-c908ca2d9144",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-31",
        "text": "During the past month:\n\n**Before going to sleep I have to do certain things in a certain way**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "11b3574d-c8ea-44b6-bc55-630afaba07ca",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "138f8e70-e01b-4b30-af03-4504745a5c9b",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "25323c21-2efe-4772-98f1-709a0c9189a6",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "42fef8e1-6512-47a7-a3db-7a2fcf288678",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "a9fdc32e-7d3e-4f18-8db7-09ec91f1f9d2",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-32",
        "text": "During the past month:\n\n**I go back to places to make sure that I have not harmed anyone**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "56687eb0-9a00-44ce-a352-c16a46282593",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "46703ecc-f443-4a87-b71f-bd7e236dcaa8",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "76a75a75-3ff6-4441-a7d8-2e0d7e613bb6",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "4ead53fa-4fcb-4932-87cd-419c31f29222",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "90efdc59-ba09-4e58-8141-adf1967e1940",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-33",
        "text": "During the past month:\n\n**I frequently get nasty thoughts and have difficulty getting rid of them**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e0dbeb8f-d986-4843-a6b1-b27d70d4ac3b",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ded1188e-23c2-444d-8c86-13ed95ec1734",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "5837fc2d-f309-4050-bbdc-0b480513c721",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "2655b2f6-a659-43cc-a8df-c93dc91fbdbc",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "b4562984-e594-42ab-ab82-af8ed6ebef99",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-34",
        "text": "During the past month:\n\n**I avoid throwing things away because I am afraid I might need them later**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a4a42763-b4ad-45c2-8861-ce353f66e153",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "63d11320-c09a-48a9-9604-fba5e9f7f2e6",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "72283645-9e71-439f-96e7-dba204674320",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "2974ff20-3e4d-4e69-a3a5-64ffeca673c4",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "fcd5d025-2226-4043-b1cf-569053da5d1a",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-35",
        "text": "During the past month:\n\n**I get upset if others change the way I have arranged my things**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "72f4518e-4ba2-4766-9c52-a18e920b3a05",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f6b5ef72-c381-4bbf-b713-83019c557052",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "84c883c8-fa19-4af9-a6fe-9ee7c821ec2b",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "c27d4008-a7a4-4a5d-9a46-db52c6855364",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "82703344-54cb-4bbc-9366-655d7af814c0",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-36",
        "text": "During the past month:\n\n**I feel that I must repeat certain words or phrases in my mind in order to wipe out bad thoughts, feelings or actions**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "85230d6a-02db-48a0-95b9-952c12b7e918",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "27552bf9-6c47-4421-97fa-64a043295f55",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "81d49534-2df3-46bf-b79d-f10c42f9104f",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "595e4119-2ac7-4738-a590-38c6e6aa9b76",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "3ead3e3c-c01b-4037-9036-30262950d347",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-37",
        "text": "During the past month:\n\n**After I have done things, I have persistent doubts about whether I really did them**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ad3b5dc9-97b2-4d6c-9397-7703d8720e00",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a42f2198-0e1c-4822-8c5a-c9d380437e0c",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "de1e8bf5-c0da-4d17-a6db-76c334dcd293",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "64274834-944d-4f84-acfa-de30d507a6fe",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "53c6a27b-8859-40a6-b2f5-5902c40375e6",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-38",
        "text": "During the past month:\n\n**I sometimes have to wash or clean myself simply because I feel contaminated**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "75f6b7e1-5d74-4214-a932-5682a2a5ac78",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "0c2b209f-7ceb-451f-a11c-0104f19cdb82",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "464494f3-ae62-4d63-b1e0-b12c3826d5f2",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "6b1d4f88-87ac-4a1e-ad59-439bf9202723",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "8c837838-39d2-4e8f-a116-e39268bdb8fe",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-39",
        "text": "During the past month:\n\n**I feel that there are good and bad numbers**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "4c11d5d0-08bb-421f-9235-1859bb9c401c",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c9348fed-832f-4c0b-b87b-63455a1abac1",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "b19c2b4c-7887-4371-a0ee-14be7c060d75",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "f2f54eb5-3786-411b-b004-3e0471add605",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "241a3fe6-975f-4449-9c4f-60d37aa4fe1a",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-40",
        "text": "During the past month:\n\n**I repeatedly check anything which might cause a fire**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b24359bd-9315-45b0-845d-8ca1ce2417f0",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "fd6f6519-97f9-4b62-8185-d4851446a59f",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "9a0d37a3-7202-4a7c-af62-4c61c14f6f85",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "2c755490-e540-4a6c-9045-c353852b6767",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "5f07b839-a5ab-446c-8acd-c1f7c71b3e5a",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-41",
        "text": "During the past month:\n\n**Even when I do something very carefully I feel that it is not quite right**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "51b550e6-1d5f-48f8-bf92-0705b52dd587",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "9b161814-2e38-48b5-86a2-3e8cc90b0adc",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "30ce1d49-d18b-4032-a0f2-17dc5154fee0",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "bdab349c-ec94-4724-947c-4034b3750119",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "ae9a1070-45d9-4b7d-9f06-95649287be9b",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI-42",
        "text": "During the past month:\n\n**I wash my hands more often or longer than necessary**",
        "isMultiselect": false
    }
];
exports.aspects = {
    "oci": {
        "type": "mean",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "a6383a05-2199-4c6d-8a74-463d64ac6657": 0,
                            "ce2f562b-5d4b-465d-ac54-58f2c23f741a": 0.25,
                            "850abeca-6b8a-42d1-9c6a-23bc4e49f500": 0.5,
                            "1af317bd-d6ac-4b03-994f-00d0fc91bd4f": 0.75,
                            "c7e449e7-9fab-4c64-bccf-5985a3ad43ba": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-2",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "c8204fb3-2d6c-4327-ac6e-3cca055e68ff": 0,
                            "f7ebe38e-0481-4a4b-8ba2-b2890079d4ce": 0.25,
                            "a417ea72-6c53-44b5-a747-802c2fc5d696": 0.5,
                            "3ed98ebd-a891-478d-9d6b-c3dd2a055e5e": 0.75,
                            "bf4de7e0-5b4b-4d2d-be3c-2caf7ae08336": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-3",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "0d0255bb-752a-4689-a3f1-e34a5db7803a": 0,
                            "74bf0e05-3b41-44f7-ac86-faed0670c627": 0.25,
                            "e3bdb0f3-9559-4ba7-a613-2833089b7ab0": 0.5,
                            "16038870-226c-4a3b-8c9b-b38e99f7d5f8": 0.75,
                            "8b0bde8e-8dc1-4e3f-9b8b-14d8bf40d85a": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-4",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "618cfdd6-a954-4109-9061-f32308ba3c7c": 0,
                            "61cc75e1-f8fa-417e-9f8c-d6c8cd244529": 0.25,
                            "f4c30217-e600-4e77-b1e3-871ca1241cc5": 0.5,
                            "ea35d663-d1a3-4663-8394-0e91378725d3": 0.75,
                            "f2056915-f4c3-4ce5-975c-fa0673623509": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "c7ab2dd0-9598-405d-b43e-a92c69f584a5": 0,
                            "173cad02-0b16-4864-adae-0c9bd63b5745": 0.25,
                            "60a81934-6205-4dee-9de3-59495d5ce78c": 0.5,
                            "91d12a0a-4c66-42ba-8a50-c9d6b7362802": 0.75,
                            "3366a5be-c430-48d3-a432-cf26cfcdd5a4": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-6",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "328d3d9b-1050-4317-9200-0ec6bd72cef8": 0,
                            "c91cb05d-bd4b-4973-a1f0-cbca4486cade": 0.25,
                            "87c5945a-2f51-4123-b9db-ca66df4f1c07": 0.5,
                            "739b604a-66ef-42c5-8b28-5226dcf6e53c": 0.75,
                            "6b3173e2-dd2a-49ba-9bf4-4133fb115422": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-7",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "5d49fde9-66e4-483d-bd3b-f4a31cc5747f": 0,
                            "883ce287-e7ca-4f4d-970a-b294368e334f": 0.25,
                            "4e7ad016-7f60-4bb5-892a-7c86f5838bb0": 0.5,
                            "0306cd11-d479-48d7-bf42-78ec4993894a": 0.75,
                            "d2ac1835-55f0-4a70-ace6-8142bfeaeace": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-8",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "d240a2e7-4361-4dcb-9a0c-954a2fa91a2d": 0,
                            "81711125-32e8-4941-ad76-27825cc5f1b7": 0.25,
                            "4cda8e47-9911-459b-b766-06501ab21b42": 0.5,
                            "3d685022-fe73-42ad-91f8-de863ed81cca": 0.75,
                            "73cfac35-95d5-4765-91c5-e657e1687e8a": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-9",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "4679154d-262d-4dd7-9400-8d6f3778d370": 0,
                            "3aabeae3-7340-47ee-b564-7df6b9af4a53": 0.25,
                            "a5d709dd-4da6-4b4b-9e36-347fbfc05a36": 0.5,
                            "8b2111e5-48ff-4fe1-b049-d4c95ae3416e": 0.75,
                            "3a910533-45c0-457e-9f10-fadac28f2d89": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-10",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "fef92306-fc1a-4df9-a659-259ce0eabfd7": 0,
                            "87e01fc4-3f83-4ab6-aa3f-1628cea7cace": 0.25,
                            "4526497b-a1d7-405a-970a-0c407b41f709": 0.5,
                            "23ac262e-0dd5-4cac-b318-24ce209abf3e": 0.75,
                            "df3a9a30-afa8-45ae-a63b-2fe74e87b6c7": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-11",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "d6c77058-4c90-45de-8521-78455f27346a": 0,
                            "5e9d88d5-0251-408b-b206-d51562ab0d30": 0.25,
                            "0d543c3a-1940-47f3-a488-e2c05ff43e67": 0.5,
                            "6be4b214-a0ce-4bdb-ba75-88a9658fa8b2": 0.75,
                            "ec46001b-9408-4c59-b95e-db21f2ecd0a1": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-12",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "38ecb45a-50d8-49ab-bf54-7028a2dc1e27": 0,
                            "faf4ffa1-de16-4978-95c2-0d9c3a7d03f9": 0.25,
                            "66931bf4-159a-4f01-be50-551a76cd968c": 0.5,
                            "bfddb52a-4958-4cd7-97ad-c3d22392354b": 0.75,
                            "bc8f3b04-f637-46f4-b1ca-e4a6065b10fc": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-13",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "12fa6a9a-4b33-4164-8f23-bee06fd904b1": 0,
                            "9db6bf85-54f2-4c2f-9005-f56e26f74880": 0.25,
                            "3dae9c9e-3535-4871-a31f-2265d98371cb": 0.5,
                            "72237816-c11e-44af-b3a1-4d76ad321b9a": 0.75,
                            "5ccb581a-6b8f-474e-93d2-9b31babd6c77": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-14",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "90e797a2-605f-4cb0-9622-86f465592d33": 0,
                            "f143a3c7-3196-45e6-829d-f12fba1eb36a": 0.25,
                            "79948f1d-34b8-43cf-874b-3e800e5045d2": 0.5,
                            "074cd9ce-ce41-4f67-a2e7-9a9e63b33479": 0.75,
                            "8154c188-2fd6-4bd1-b1f3-aea84ec820d6": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-15",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "c94a3cc7-e504-4d7e-acd8-690db69ccae8": 0,
                            "8e029877-fbc0-4aa3-b63b-47c0731939e8": 0.25,
                            "ed843992-9c74-42ea-9a7a-2328579ae609": 0.5,
                            "ec11d6bf-0fba-4561-b6d3-9d1a1351b5bb": 0.75,
                            "8adac844-d011-4442-814d-06d6526cf867": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-16",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "941bf67e-0f0f-4617-8048-73c287a66785": 0,
                            "f6186e4d-7f0a-49e0-9329-a7b11191df9e": 0.25,
                            "7c8b7dac-1fa8-43be-aedb-548030f24087": 0.5,
                            "7eb1220c-4e29-4629-a36f-7d2505e6dff1": 0.75,
                            "9b799242-a808-46c5-81b8-a4bd69d6c42a": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-17",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "80eb1b27-5fa5-45e0-83db-9832b6614b5f": 0,
                            "671f7514-42d0-449a-bd2e-5264b14c0d06": 0.25,
                            "300e0f44-6b91-4076-aada-38da96000e9f": 0.5,
                            "f7bf5d91-7a82-4f9e-b73d-f1d62ba6bcd1": 0.75,
                            "b9eaf868-baab-472f-8e3d-e6b9875bbd96": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-18",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "618a6686-d47b-4d24-8f4d-3f2eb8e0916c": 0,
                            "2aca05fa-27ad-4192-bc6b-1ce5819694ce": 0.25,
                            "4c5bde0f-8341-485d-aa88-1c7788818c2e": 0.5,
                            "88297bda-61fb-4911-a25d-50221f8e6173": 0.75,
                            "ab513dc5-2b0d-4588-90f8-58c4fdce6113": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-19",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "73e264ee-e7f1-430c-b3d8-f5a1fbce22bc": 0,
                            "90ca743f-8ad1-47b2-bf1a-d81c55365c47": 0.25,
                            "c01d1b0f-d8da-42b5-90ac-edce6e051ad3": 0.5,
                            "2acb5fdc-bddd-421b-8557-c841eeec8cd1": 0.75,
                            "bb0f0a5d-f3a2-4c28-a406-3ec24c0aab95": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-20",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "a1051697-369a-4070-89a7-1fa3c16bb121": 0,
                            "7ffd5742-e264-40a0-98a7-b284a35f551e": 0.25,
                            "a5a2721c-8a03-46f4-a2d1-cd0a1be9b82a": 0.5,
                            "280021d4-ea94-4bf6-bcd1-e11e4a03a5c7": 0.75,
                            "c43d0794-4dcd-486e-acb0-6a58dc5b875b": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-21",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "b8462a3c-f24e-45cb-a179-0773858d4af2": 0,
                            "cc9174a5-b6c7-47a3-a3d2-8849d21951bc": 0.25,
                            "fb2f654d-a7f7-49f0-b8d9-f84e1c0546fe": 0.5,
                            "61dab5e3-d6bb-4cd5-b384-5135028f9735": 0.75,
                            "17908b73-b994-4e29-ae86-f2f8b916c415": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-22",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "de65a9e9-e827-4c53-8873-12c7cc7f21ba": 0,
                            "127c8356-6097-4e42-b329-4595556c2178": 0.25,
                            "658673fa-7627-4a02-b492-6aee929ae56d": 0.5,
                            "10d4c11a-b929-400b-8dba-b90b16ef7cf8": 0.75,
                            "34d233e7-9f7c-48c8-8ea3-5845c1d99706": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-23",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "c57f9c36-3330-4e2f-9605-07442d556593": 0,
                            "d142eba3-d7df-4394-a0c7-11029fcb3a48": 0.25,
                            "ebff5192-accb-46d8-9d01-382616d86aca": 0.5,
                            "dc6af09b-6d72-4742-a2d0-e4d3596ff7a0": 0.75,
                            "bb3dd17f-5dee-4e15-9903-c0f76b8c03f0": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-24",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "5d4b94b1-0f9f-45fd-904a-849dbd074718": 0,
                            "ce7ae1b1-2fa1-46f7-bf39-697326898d7f": 0.25,
                            "68740cda-a86d-4d18-86ea-b1dad04c8dd8": 0.5,
                            "eb3e447a-436c-41a5-8bf2-abc46037eb0c": 0.75,
                            "4da19c6a-f488-4fb2-b26a-1942a4c77d1b": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-25",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "f4757c81-4224-4786-8ccf-979197e872d5": 0,
                            "77421427-4d03-4658-9881-3b9caa71e4c0": 0.25,
                            "ec5092ab-0f52-4ef0-b0d1-d06ef9e80107": 0.5,
                            "420f359a-8e63-41b2-8787-09464e8f7520": 0.75,
                            "3c9905aa-c38d-4e28-92bf-cf997d747fcc": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-26",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "5a7275dd-0ec9-4be3-a262-3507c62e8717": 0,
                            "d7bca9dc-2442-45f8-96b4-cbec64cc7f35": 0.25,
                            "285609e2-b485-43b5-9ede-00d846a0cf82": 0.5,
                            "4926a1fb-7e1c-4583-accf-5680f7deb4bc": 0.75,
                            "0725147d-7407-4419-a37b-0ee5632a804d": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-27",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "354bc91e-9a8e-41db-9ece-dbe905bd4a0d": 0,
                            "2d164010-ec66-401e-9b7c-9322f0fec43a": 0.25,
                            "2b457fd9-b843-4c6b-8fac-e98a81644434": 0.5,
                            "3a0d78a1-12e4-45d9-9b6e-1a42c8edbdcc": 0.75,
                            "25342c97-cc75-470f-923e-382500cc62a3": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-28",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "583757c5-9cfc-4e2d-9a07-d85c38ca8918": 0,
                            "92c15d56-4c91-4dab-bd71-33fea666ad80": 0.25,
                            "cf81bce0-871d-41cf-a19c-472494ebc202": 0.5,
                            "2375a465-1a92-4a14-957d-5f7daad1f7a7": 0.75,
                            "8b0cb701-dc3c-4a04-97db-bc07d6140e17": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-29",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "7437519f-d718-4ce5-8053-211379eabf24": 0,
                            "b3c1e0bf-cac2-4f37-aaf2-493e0209e399": 0.25,
                            "c7324820-b14a-4fcd-ae59-25d584d20cc1": 0.5,
                            "bc93537f-a656-4ef2-9ed6-bd52436ae66e": 0.75,
                            "cbf5c709-170f-41c0-bd7a-c648e1970655": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-30",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "d5590152-174b-471d-ae77-1e3335e6cc7e": 0,
                            "84822290-b1eb-492c-b97c-2c1ac530e7aa": 0.25,
                            "7ad9e30a-02fa-4a05-af74-e65fe7ae9a3a": 0.5,
                            "ecfbdb37-1c78-4efc-ac10-8bba3088be11": 0.75,
                            "d5a32277-4fd6-42e5-b246-867edb8dff06": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-31",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "94eb0b53-32a4-4baa-9881-9c1872a68b25": 0,
                            "6eed9b97-f9db-4dee-a854-7dfb6848a085": 0.25,
                            "444fd488-6d42-4043-ab1c-9dc7a87e9653": 0.5,
                            "a02109d1-0fca-4bd5-8901-da7c416f394c": 0.75,
                            "48fd9b06-4408-43b4-864d-c908ca2d9144": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-32",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "11b3574d-c8ea-44b6-bc55-630afaba07ca": 0,
                            "138f8e70-e01b-4b30-af03-4504745a5c9b": 0.25,
                            "25323c21-2efe-4772-98f1-709a0c9189a6": 0.5,
                            "42fef8e1-6512-47a7-a3db-7a2fcf288678": 0.75,
                            "a9fdc32e-7d3e-4f18-8db7-09ec91f1f9d2": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-33",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "56687eb0-9a00-44ce-a352-c16a46282593": 0,
                            "46703ecc-f443-4a87-b71f-bd7e236dcaa8": 0.25,
                            "76a75a75-3ff6-4441-a7d8-2e0d7e613bb6": 0.5,
                            "4ead53fa-4fcb-4932-87cd-419c31f29222": 0.75,
                            "90efdc59-ba09-4e58-8141-adf1967e1940": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-34",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "e0dbeb8f-d986-4843-a6b1-b27d70d4ac3b": 0,
                            "ded1188e-23c2-444d-8c86-13ed95ec1734": 0.25,
                            "5837fc2d-f309-4050-bbdc-0b480513c721": 0.5,
                            "2655b2f6-a659-43cc-a8df-c93dc91fbdbc": 0.75,
                            "b4562984-e594-42ab-ab82-af8ed6ebef99": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-35",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "a4a42763-b4ad-45c2-8861-ce353f66e153": 0,
                            "63d11320-c09a-48a9-9604-fba5e9f7f2e6": 0.25,
                            "72283645-9e71-439f-96e7-dba204674320": 0.5,
                            "2974ff20-3e4d-4e69-a3a5-64ffeca673c4": 0.75,
                            "fcd5d025-2226-4043-b1cf-569053da5d1a": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-36",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "72f4518e-4ba2-4766-9c52-a18e920b3a05": 0,
                            "f6b5ef72-c381-4bbf-b713-83019c557052": 0.25,
                            "84c883c8-fa19-4af9-a6fe-9ee7c821ec2b": 0.5,
                            "c27d4008-a7a4-4a5d-9a46-db52c6855364": 0.75,
                            "82703344-54cb-4bbc-9366-655d7af814c0": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-37",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "85230d6a-02db-48a0-95b9-952c12b7e918": 0,
                            "27552bf9-6c47-4421-97fa-64a043295f55": 0.25,
                            "81d49534-2df3-46bf-b79d-f10c42f9104f": 0.5,
                            "595e4119-2ac7-4738-a590-38c6e6aa9b76": 0.75,
                            "3ead3e3c-c01b-4037-9036-30262950d347": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-38",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "ad3b5dc9-97b2-4d6c-9397-7703d8720e00": 0,
                            "a42f2198-0e1c-4822-8c5a-c9d380437e0c": 0.25,
                            "de1e8bf5-c0da-4d17-a6db-76c334dcd293": 0.5,
                            "64274834-944d-4f84-acfa-de30d507a6fe": 0.75,
                            "53c6a27b-8859-40a6-b2f5-5902c40375e6": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-39",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "75f6b7e1-5d74-4214-a932-5682a2a5ac78": 0,
                            "0c2b209f-7ceb-451f-a11c-0104f19cdb82": 0.25,
                            "464494f3-ae62-4d63-b1e0-b12c3826d5f2": 0.5,
                            "6b1d4f88-87ac-4a1e-ad59-439bf9202723": 0.75,
                            "8c837838-39d2-4e8f-a116-e39268bdb8fe": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-40",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "4c11d5d0-08bb-421f-9235-1859bb9c401c": 0,
                            "c9348fed-832f-4c0b-b87b-63455a1abac1": 0.25,
                            "b19c2b4c-7887-4371-a0ee-14be7c060d75": 0.5,
                            "f2f54eb5-3786-411b-b004-3e0471add605": 0.75,
                            "241a3fe6-975f-4449-9c4f-60d37aa4fe1a": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-41",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "b24359bd-9315-45b0-845d-8ca1ce2417f0": 0,
                            "fd6f6519-97f9-4b62-8185-d4851446a59f": 0.25,
                            "9a0d37a3-7202-4a7c-af62-4c61c14f6f85": 0.5,
                            "2c755490-e540-4a6c-9045-c353852b6767": 0.75,
                            "5f07b839-a5ab-446c-8acd-c1f7c71b3e5a": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI-42",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "51b550e6-1d5f-48f8-bf92-0705b52dd587": 0,
                            "9b161814-2e38-48b5-86a2-3e8cc90b0adc": 0.25,
                            "30ce1d49-d18b-4032-a0f2-17dc5154fee0": 0.5,
                            "bdab349c-ec94-4724-947c-4034b3750119": 0.75,
                            "ae9a1070-45d9-4b7d-9f06-95649287be9b": 1
                        }
                    }
                }
            }
        ]
    },
    "not(OCI-content-1=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "OCI-content-1",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "04925d67-b76c-4659-b0b7-9b9f70eae0cc": false,
                        "f36ef97c-cad8-4ec6-b3ef-747d0c5790c1": true
                    }
                }
            }
        }
    }
};
exports.tests = {
    "oci": [
        {
            "name": "OCI - zero mean score-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0,
                "threshold": 0
            },
            "answers": {
                "OCI-1": {
                    "questionId": "OCI-1",
                    "chosenOptionIds": [
                        "a6383a05-2199-4c6d-8a74-463d64ac6657"
                    ]
                },
                "OCI-2": {
                    "questionId": "OCI-2",
                    "chosenOptionIds": [
                        "c8204fb3-2d6c-4327-ac6e-3cca055e68ff"
                    ]
                },
                "OCI-3": {
                    "questionId": "OCI-3",
                    "chosenOptionIds": [
                        "0d0255bb-752a-4689-a3f1-e34a5db7803a"
                    ]
                },
                "OCI-4": {
                    "questionId": "OCI-4",
                    "chosenOptionIds": [
                        "618cfdd6-a954-4109-9061-f32308ba3c7c"
                    ]
                },
                "OCI-5": {
                    "questionId": "OCI-5",
                    "chosenOptionIds": [
                        "c7ab2dd0-9598-405d-b43e-a92c69f584a5"
                    ]
                },
                "OCI-6": {
                    "questionId": "OCI-6",
                    "chosenOptionIds": [
                        "328d3d9b-1050-4317-9200-0ec6bd72cef8"
                    ]
                },
                "OCI-7": {
                    "questionId": "OCI-7",
                    "chosenOptionIds": [
                        "5d49fde9-66e4-483d-bd3b-f4a31cc5747f"
                    ]
                },
                "OCI-8": {
                    "questionId": "OCI-8",
                    "chosenOptionIds": [
                        "d240a2e7-4361-4dcb-9a0c-954a2fa91a2d"
                    ]
                },
                "OCI-9": {
                    "questionId": "OCI-9",
                    "chosenOptionIds": [
                        "4679154d-262d-4dd7-9400-8d6f3778d370"
                    ]
                },
                "OCI-10": {
                    "questionId": "OCI-10",
                    "chosenOptionIds": [
                        "fef92306-fc1a-4df9-a659-259ce0eabfd7"
                    ]
                },
                "OCI-11": {
                    "questionId": "OCI-11",
                    "chosenOptionIds": [
                        "d6c77058-4c90-45de-8521-78455f27346a"
                    ]
                },
                "OCI-12": {
                    "questionId": "OCI-12",
                    "chosenOptionIds": [
                        "38ecb45a-50d8-49ab-bf54-7028a2dc1e27"
                    ]
                },
                "OCI-13": {
                    "questionId": "OCI-13",
                    "chosenOptionIds": [
                        "12fa6a9a-4b33-4164-8f23-bee06fd904b1"
                    ]
                },
                "OCI-14": {
                    "questionId": "OCI-14",
                    "chosenOptionIds": [
                        "90e797a2-605f-4cb0-9622-86f465592d33"
                    ]
                },
                "OCI-15": {
                    "questionId": "OCI-15",
                    "chosenOptionIds": [
                        "c94a3cc7-e504-4d7e-acd8-690db69ccae8"
                    ]
                },
                "OCI-16": {
                    "questionId": "OCI-16",
                    "chosenOptionIds": [
                        "941bf67e-0f0f-4617-8048-73c287a66785"
                    ]
                },
                "OCI-17": {
                    "questionId": "OCI-17",
                    "chosenOptionIds": [
                        "80eb1b27-5fa5-45e0-83db-9832b6614b5f"
                    ]
                },
                "OCI-18": {
                    "questionId": "OCI-18",
                    "chosenOptionIds": [
                        "618a6686-d47b-4d24-8f4d-3f2eb8e0916c"
                    ]
                },
                "OCI-19": {
                    "questionId": "OCI-19",
                    "chosenOptionIds": [
                        "73e264ee-e7f1-430c-b3d8-f5a1fbce22bc"
                    ]
                },
                "OCI-20": {
                    "questionId": "OCI-20",
                    "chosenOptionIds": [
                        "a1051697-369a-4070-89a7-1fa3c16bb121"
                    ]
                },
                "OCI-21": {
                    "questionId": "OCI-21",
                    "chosenOptionIds": [
                        "b8462a3c-f24e-45cb-a179-0773858d4af2"
                    ]
                },
                "OCI-22": {
                    "questionId": "OCI-22",
                    "chosenOptionIds": [
                        "de65a9e9-e827-4c53-8873-12c7cc7f21ba"
                    ]
                },
                "OCI-23": {
                    "questionId": "OCI-23",
                    "chosenOptionIds": [
                        "c57f9c36-3330-4e2f-9605-07442d556593"
                    ]
                },
                "OCI-24": {
                    "questionId": "OCI-24",
                    "chosenOptionIds": [
                        "5d4b94b1-0f9f-45fd-904a-849dbd074718"
                    ]
                },
                "OCI-25": {
                    "questionId": "OCI-25",
                    "chosenOptionIds": [
                        "f4757c81-4224-4786-8ccf-979197e872d5"
                    ]
                },
                "OCI-26": {
                    "questionId": "OCI-26",
                    "chosenOptionIds": [
                        "5a7275dd-0ec9-4be3-a262-3507c62e8717"
                    ]
                },
                "OCI-27": {
                    "questionId": "OCI-27",
                    "chosenOptionIds": [
                        "354bc91e-9a8e-41db-9ece-dbe905bd4a0d"
                    ]
                },
                "OCI-28": {
                    "questionId": "OCI-28",
                    "chosenOptionIds": [
                        "583757c5-9cfc-4e2d-9a07-d85c38ca8918"
                    ]
                },
                "OCI-29": {
                    "questionId": "OCI-29",
                    "chosenOptionIds": [
                        "7437519f-d718-4ce5-8053-211379eabf24"
                    ]
                },
                "OCI-30": {
                    "questionId": "OCI-30",
                    "chosenOptionIds": [
                        "d5590152-174b-471d-ae77-1e3335e6cc7e"
                    ]
                },
                "OCI-31": {
                    "questionId": "OCI-31",
                    "chosenOptionIds": [
                        "94eb0b53-32a4-4baa-9881-9c1872a68b25"
                    ]
                },
                "OCI-32": {
                    "questionId": "OCI-32",
                    "chosenOptionIds": [
                        "11b3574d-c8ea-44b6-bc55-630afaba07ca"
                    ]
                },
                "OCI-33": {
                    "questionId": "OCI-33",
                    "chosenOptionIds": [
                        "56687eb0-9a00-44ce-a352-c16a46282593"
                    ]
                },
                "OCI-34": {
                    "questionId": "OCI-34",
                    "chosenOptionIds": [
                        "e0dbeb8f-d986-4843-a6b1-b27d70d4ac3b"
                    ]
                },
                "OCI-35": {
                    "questionId": "OCI-35",
                    "chosenOptionIds": [
                        "a4a42763-b4ad-45c2-8861-ce353f66e153"
                    ]
                },
                "OCI-36": {
                    "questionId": "OCI-36",
                    "chosenOptionIds": [
                        "72f4518e-4ba2-4766-9c52-a18e920b3a05"
                    ]
                },
                "OCI-37": {
                    "questionId": "OCI-37",
                    "chosenOptionIds": [
                        "85230d6a-02db-48a0-95b9-952c12b7e918"
                    ]
                },
                "OCI-38": {
                    "questionId": "OCI-38",
                    "chosenOptionIds": [
                        "ad3b5dc9-97b2-4d6c-9397-7703d8720e00"
                    ]
                },
                "OCI-39": {
                    "questionId": "OCI-39",
                    "chosenOptionIds": [
                        "75f6b7e1-5d74-4214-a932-5682a2a5ac78"
                    ]
                },
                "OCI-40": {
                    "questionId": "OCI-40",
                    "chosenOptionIds": [
                        "4c11d5d0-08bb-421f-9235-1859bb9c401c"
                    ]
                },
                "OCI-41": {
                    "questionId": "OCI-41",
                    "chosenOptionIds": [
                        "b24359bd-9315-45b0-845d-8ca1ce2417f0"
                    ]
                },
                "OCI-42": {
                    "questionId": "OCI-42",
                    "chosenOptionIds": [
                        "51b550e6-1d5f-48f8-bf92-0705b52dd587"
                    ]
                }
            }
        },
        {
            "name": "OCI - score below threshold-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.24404762,
                "threshold": 0
            },
            "answers": {
                "OCI-1": {
                    "questionId": "OCI-1",
                    "chosenOptionIds": [
                        "c7e449e7-9fab-4c64-bccf-5985a3ad43ba"
                    ]
                },
                "OCI-2": {
                    "questionId": "OCI-2",
                    "chosenOptionIds": [
                        "bf4de7e0-5b4b-4d2d-be3c-2caf7ae08336"
                    ]
                },
                "OCI-3": {
                    "questionId": "OCI-3",
                    "chosenOptionIds": [
                        "8b0bde8e-8dc1-4e3f-9b8b-14d8bf40d85a"
                    ]
                },
                "OCI-4": {
                    "questionId": "OCI-4",
                    "chosenOptionIds": [
                        "f2056915-f4c3-4ce5-975c-fa0673623509"
                    ]
                },
                "OCI-5": {
                    "questionId": "OCI-5",
                    "chosenOptionIds": [
                        "3366a5be-c430-48d3-a432-cf26cfcdd5a4"
                    ]
                },
                "OCI-6": {
                    "questionId": "OCI-6",
                    "chosenOptionIds": [
                        "6b3173e2-dd2a-49ba-9bf4-4133fb115422"
                    ]
                },
                "OCI-7": {
                    "questionId": "OCI-7",
                    "chosenOptionIds": [
                        "d2ac1835-55f0-4a70-ace6-8142bfeaeace"
                    ]
                },
                "OCI-8": {
                    "questionId": "OCI-8",
                    "chosenOptionIds": [
                        "73cfac35-95d5-4765-91c5-e657e1687e8a"
                    ]
                },
                "OCI-9": {
                    "questionId": "OCI-9",
                    "chosenOptionIds": [
                        "3a910533-45c0-457e-9f10-fadac28f2d89"
                    ]
                },
                "OCI-10": {
                    "questionId": "OCI-10",
                    "chosenOptionIds": [
                        "df3a9a30-afa8-45ae-a63b-2fe74e87b6c7"
                    ]
                },
                "OCI-11": {
                    "questionId": "OCI-11",
                    "chosenOptionIds": [
                        "5e9d88d5-0251-408b-b206-d51562ab0d30"
                    ]
                },
                "OCI-12": {
                    "questionId": "OCI-12",
                    "chosenOptionIds": [
                        "38ecb45a-50d8-49ab-bf54-7028a2dc1e27"
                    ]
                },
                "OCI-13": {
                    "questionId": "OCI-13",
                    "chosenOptionIds": [
                        "12fa6a9a-4b33-4164-8f23-bee06fd904b1"
                    ]
                },
                "OCI-14": {
                    "questionId": "OCI-14",
                    "chosenOptionIds": [
                        "90e797a2-605f-4cb0-9622-86f465592d33"
                    ]
                },
                "OCI-15": {
                    "questionId": "OCI-15",
                    "chosenOptionIds": [
                        "c94a3cc7-e504-4d7e-acd8-690db69ccae8"
                    ]
                },
                "OCI-16": {
                    "questionId": "OCI-16",
                    "chosenOptionIds": [
                        "941bf67e-0f0f-4617-8048-73c287a66785"
                    ]
                },
                "OCI-17": {
                    "questionId": "OCI-17",
                    "chosenOptionIds": [
                        "80eb1b27-5fa5-45e0-83db-9832b6614b5f"
                    ]
                },
                "OCI-18": {
                    "questionId": "OCI-18",
                    "chosenOptionIds": [
                        "618a6686-d47b-4d24-8f4d-3f2eb8e0916c"
                    ]
                },
                "OCI-19": {
                    "questionId": "OCI-19",
                    "chosenOptionIds": [
                        "73e264ee-e7f1-430c-b3d8-f5a1fbce22bc"
                    ]
                },
                "OCI-20": {
                    "questionId": "OCI-20",
                    "chosenOptionIds": [
                        "a1051697-369a-4070-89a7-1fa3c16bb121"
                    ]
                },
                "OCI-21": {
                    "questionId": "OCI-21",
                    "chosenOptionIds": [
                        "b8462a3c-f24e-45cb-a179-0773858d4af2"
                    ]
                },
                "OCI-22": {
                    "questionId": "OCI-22",
                    "chosenOptionIds": [
                        "de65a9e9-e827-4c53-8873-12c7cc7f21ba"
                    ]
                },
                "OCI-23": {
                    "questionId": "OCI-23",
                    "chosenOptionIds": [
                        "c57f9c36-3330-4e2f-9605-07442d556593"
                    ]
                },
                "OCI-24": {
                    "questionId": "OCI-24",
                    "chosenOptionIds": [
                        "5d4b94b1-0f9f-45fd-904a-849dbd074718"
                    ]
                },
                "OCI-25": {
                    "questionId": "OCI-25",
                    "chosenOptionIds": [
                        "f4757c81-4224-4786-8ccf-979197e872d5"
                    ]
                },
                "OCI-26": {
                    "questionId": "OCI-26",
                    "chosenOptionIds": [
                        "5a7275dd-0ec9-4be3-a262-3507c62e8717"
                    ]
                },
                "OCI-27": {
                    "questionId": "OCI-27",
                    "chosenOptionIds": [
                        "354bc91e-9a8e-41db-9ece-dbe905bd4a0d"
                    ]
                },
                "OCI-28": {
                    "questionId": "OCI-28",
                    "chosenOptionIds": [
                        "583757c5-9cfc-4e2d-9a07-d85c38ca8918"
                    ]
                },
                "OCI-29": {
                    "questionId": "OCI-29",
                    "chosenOptionIds": [
                        "7437519f-d718-4ce5-8053-211379eabf24"
                    ]
                },
                "OCI-30": {
                    "questionId": "OCI-30",
                    "chosenOptionIds": [
                        "d5590152-174b-471d-ae77-1e3335e6cc7e"
                    ]
                },
                "OCI-31": {
                    "questionId": "OCI-31",
                    "chosenOptionIds": [
                        "94eb0b53-32a4-4baa-9881-9c1872a68b25"
                    ]
                },
                "OCI-32": {
                    "questionId": "OCI-32",
                    "chosenOptionIds": [
                        "11b3574d-c8ea-44b6-bc55-630afaba07ca"
                    ]
                },
                "OCI-33": {
                    "questionId": "OCI-33",
                    "chosenOptionIds": [
                        "56687eb0-9a00-44ce-a352-c16a46282593"
                    ]
                },
                "OCI-34": {
                    "questionId": "OCI-34",
                    "chosenOptionIds": [
                        "e0dbeb8f-d986-4843-a6b1-b27d70d4ac3b"
                    ]
                },
                "OCI-35": {
                    "questionId": "OCI-35",
                    "chosenOptionIds": [
                        "a4a42763-b4ad-45c2-8861-ce353f66e153"
                    ]
                },
                "OCI-36": {
                    "questionId": "OCI-36",
                    "chosenOptionIds": [
                        "72f4518e-4ba2-4766-9c52-a18e920b3a05"
                    ]
                },
                "OCI-37": {
                    "questionId": "OCI-37",
                    "chosenOptionIds": [
                        "85230d6a-02db-48a0-95b9-952c12b7e918"
                    ]
                },
                "OCI-38": {
                    "questionId": "OCI-38",
                    "chosenOptionIds": [
                        "ad3b5dc9-97b2-4d6c-9397-7703d8720e00"
                    ]
                },
                "OCI-39": {
                    "questionId": "OCI-39",
                    "chosenOptionIds": [
                        "75f6b7e1-5d74-4214-a932-5682a2a5ac78"
                    ]
                },
                "OCI-40": {
                    "questionId": "OCI-40",
                    "chosenOptionIds": [
                        "4c11d5d0-08bb-421f-9235-1859bb9c401c"
                    ]
                },
                "OCI-41": {
                    "questionId": "OCI-41",
                    "chosenOptionIds": [
                        "b24359bd-9315-45b0-845d-8ca1ce2417f0"
                    ]
                },
                "OCI-42": {
                    "questionId": "OCI-42",
                    "chosenOptionIds": [
                        "51b550e6-1d5f-48f8-bf92-0705b52dd587"
                    ]
                }
            }
        },
        {
            "name": "OCI - score on threshold-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.25,
                "threshold": 0
            },
            "answers": {
                "OCI-1": {
                    "questionId": "OCI-1",
                    "chosenOptionIds": [
                        "c7e449e7-9fab-4c64-bccf-5985a3ad43ba"
                    ]
                },
                "OCI-2": {
                    "questionId": "OCI-2",
                    "chosenOptionIds": [
                        "bf4de7e0-5b4b-4d2d-be3c-2caf7ae08336"
                    ]
                },
                "OCI-3": {
                    "questionId": "OCI-3",
                    "chosenOptionIds": [
                        "8b0bde8e-8dc1-4e3f-9b8b-14d8bf40d85a"
                    ]
                },
                "OCI-4": {
                    "questionId": "OCI-4",
                    "chosenOptionIds": [
                        "f2056915-f4c3-4ce5-975c-fa0673623509"
                    ]
                },
                "OCI-5": {
                    "questionId": "OCI-5",
                    "chosenOptionIds": [
                        "3366a5be-c430-48d3-a432-cf26cfcdd5a4"
                    ]
                },
                "OCI-6": {
                    "questionId": "OCI-6",
                    "chosenOptionIds": [
                        "6b3173e2-dd2a-49ba-9bf4-4133fb115422"
                    ]
                },
                "OCI-7": {
                    "questionId": "OCI-7",
                    "chosenOptionIds": [
                        "d2ac1835-55f0-4a70-ace6-8142bfeaeace"
                    ]
                },
                "OCI-8": {
                    "questionId": "OCI-8",
                    "chosenOptionIds": [
                        "73cfac35-95d5-4765-91c5-e657e1687e8a"
                    ]
                },
                "OCI-9": {
                    "questionId": "OCI-9",
                    "chosenOptionIds": [
                        "3a910533-45c0-457e-9f10-fadac28f2d89"
                    ]
                },
                "OCI-10": {
                    "questionId": "OCI-10",
                    "chosenOptionIds": [
                        "df3a9a30-afa8-45ae-a63b-2fe74e87b6c7"
                    ]
                },
                "OCI-11": {
                    "questionId": "OCI-11",
                    "chosenOptionIds": [
                        "0d543c3a-1940-47f3-a488-e2c05ff43e67"
                    ]
                },
                "OCI-12": {
                    "questionId": "OCI-12",
                    "chosenOptionIds": [
                        "38ecb45a-50d8-49ab-bf54-7028a2dc1e27"
                    ]
                },
                "OCI-13": {
                    "questionId": "OCI-13",
                    "chosenOptionIds": [
                        "12fa6a9a-4b33-4164-8f23-bee06fd904b1"
                    ]
                },
                "OCI-14": {
                    "questionId": "OCI-14",
                    "chosenOptionIds": [
                        "90e797a2-605f-4cb0-9622-86f465592d33"
                    ]
                },
                "OCI-15": {
                    "questionId": "OCI-15",
                    "chosenOptionIds": [
                        "c94a3cc7-e504-4d7e-acd8-690db69ccae8"
                    ]
                },
                "OCI-16": {
                    "questionId": "OCI-16",
                    "chosenOptionIds": [
                        "941bf67e-0f0f-4617-8048-73c287a66785"
                    ]
                },
                "OCI-17": {
                    "questionId": "OCI-17",
                    "chosenOptionIds": [
                        "80eb1b27-5fa5-45e0-83db-9832b6614b5f"
                    ]
                },
                "OCI-18": {
                    "questionId": "OCI-18",
                    "chosenOptionIds": [
                        "618a6686-d47b-4d24-8f4d-3f2eb8e0916c"
                    ]
                },
                "OCI-19": {
                    "questionId": "OCI-19",
                    "chosenOptionIds": [
                        "73e264ee-e7f1-430c-b3d8-f5a1fbce22bc"
                    ]
                },
                "OCI-20": {
                    "questionId": "OCI-20",
                    "chosenOptionIds": [
                        "a1051697-369a-4070-89a7-1fa3c16bb121"
                    ]
                },
                "OCI-21": {
                    "questionId": "OCI-21",
                    "chosenOptionIds": [
                        "b8462a3c-f24e-45cb-a179-0773858d4af2"
                    ]
                },
                "OCI-22": {
                    "questionId": "OCI-22",
                    "chosenOptionIds": [
                        "de65a9e9-e827-4c53-8873-12c7cc7f21ba"
                    ]
                },
                "OCI-23": {
                    "questionId": "OCI-23",
                    "chosenOptionIds": [
                        "c57f9c36-3330-4e2f-9605-07442d556593"
                    ]
                },
                "OCI-24": {
                    "questionId": "OCI-24",
                    "chosenOptionIds": [
                        "5d4b94b1-0f9f-45fd-904a-849dbd074718"
                    ]
                },
                "OCI-25": {
                    "questionId": "OCI-25",
                    "chosenOptionIds": [
                        "f4757c81-4224-4786-8ccf-979197e872d5"
                    ]
                },
                "OCI-26": {
                    "questionId": "OCI-26",
                    "chosenOptionIds": [
                        "5a7275dd-0ec9-4be3-a262-3507c62e8717"
                    ]
                },
                "OCI-27": {
                    "questionId": "OCI-27",
                    "chosenOptionIds": [
                        "354bc91e-9a8e-41db-9ece-dbe905bd4a0d"
                    ]
                },
                "OCI-28": {
                    "questionId": "OCI-28",
                    "chosenOptionIds": [
                        "583757c5-9cfc-4e2d-9a07-d85c38ca8918"
                    ]
                },
                "OCI-29": {
                    "questionId": "OCI-29",
                    "chosenOptionIds": [
                        "7437519f-d718-4ce5-8053-211379eabf24"
                    ]
                },
                "OCI-30": {
                    "questionId": "OCI-30",
                    "chosenOptionIds": [
                        "d5590152-174b-471d-ae77-1e3335e6cc7e"
                    ]
                },
                "OCI-31": {
                    "questionId": "OCI-31",
                    "chosenOptionIds": [
                        "94eb0b53-32a4-4baa-9881-9c1872a68b25"
                    ]
                },
                "OCI-32": {
                    "questionId": "OCI-32",
                    "chosenOptionIds": [
                        "11b3574d-c8ea-44b6-bc55-630afaba07ca"
                    ]
                },
                "OCI-33": {
                    "questionId": "OCI-33",
                    "chosenOptionIds": [
                        "56687eb0-9a00-44ce-a352-c16a46282593"
                    ]
                },
                "OCI-34": {
                    "questionId": "OCI-34",
                    "chosenOptionIds": [
                        "e0dbeb8f-d986-4843-a6b1-b27d70d4ac3b"
                    ]
                },
                "OCI-35": {
                    "questionId": "OCI-35",
                    "chosenOptionIds": [
                        "a4a42763-b4ad-45c2-8861-ce353f66e153"
                    ]
                },
                "OCI-36": {
                    "questionId": "OCI-36",
                    "chosenOptionIds": [
                        "72f4518e-4ba2-4766-9c52-a18e920b3a05"
                    ]
                },
                "OCI-37": {
                    "questionId": "OCI-37",
                    "chosenOptionIds": [
                        "85230d6a-02db-48a0-95b9-952c12b7e918"
                    ]
                },
                "OCI-38": {
                    "questionId": "OCI-38",
                    "chosenOptionIds": [
                        "ad3b5dc9-97b2-4d6c-9397-7703d8720e00"
                    ]
                },
                "OCI-39": {
                    "questionId": "OCI-39",
                    "chosenOptionIds": [
                        "75f6b7e1-5d74-4214-a932-5682a2a5ac78"
                    ]
                },
                "OCI-40": {
                    "questionId": "OCI-40",
                    "chosenOptionIds": [
                        "4c11d5d0-08bb-421f-9235-1859bb9c401c"
                    ]
                },
                "OCI-41": {
                    "questionId": "OCI-41",
                    "chosenOptionIds": [
                        "b24359bd-9315-45b0-845d-8ca1ce2417f0"
                    ]
                },
                "OCI-42": {
                    "questionId": "OCI-42",
                    "chosenOptionIds": [
                        "51b550e6-1d5f-48f8-bf92-0705b52dd587"
                    ]
                }
            }
        },
        {
            "name": "OCI - maximum score-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 1,
                "threshold": 0
            },
            "answers": {
                "OCI-1": {
                    "questionId": "OCI-1",
                    "chosenOptionIds": [
                        "c7e449e7-9fab-4c64-bccf-5985a3ad43ba"
                    ]
                },
                "OCI-2": {
                    "questionId": "OCI-2",
                    "chosenOptionIds": [
                        "bf4de7e0-5b4b-4d2d-be3c-2caf7ae08336"
                    ]
                },
                "OCI-3": {
                    "questionId": "OCI-3",
                    "chosenOptionIds": [
                        "8b0bde8e-8dc1-4e3f-9b8b-14d8bf40d85a"
                    ]
                },
                "OCI-4": {
                    "questionId": "OCI-4",
                    "chosenOptionIds": [
                        "f2056915-f4c3-4ce5-975c-fa0673623509"
                    ]
                },
                "OCI-5": {
                    "questionId": "OCI-5",
                    "chosenOptionIds": [
                        "3366a5be-c430-48d3-a432-cf26cfcdd5a4"
                    ]
                },
                "OCI-6": {
                    "questionId": "OCI-6",
                    "chosenOptionIds": [
                        "6b3173e2-dd2a-49ba-9bf4-4133fb115422"
                    ]
                },
                "OCI-7": {
                    "questionId": "OCI-7",
                    "chosenOptionIds": [
                        "d2ac1835-55f0-4a70-ace6-8142bfeaeace"
                    ]
                },
                "OCI-8": {
                    "questionId": "OCI-8",
                    "chosenOptionIds": [
                        "73cfac35-95d5-4765-91c5-e657e1687e8a"
                    ]
                },
                "OCI-9": {
                    "questionId": "OCI-9",
                    "chosenOptionIds": [
                        "3a910533-45c0-457e-9f10-fadac28f2d89"
                    ]
                },
                "OCI-10": {
                    "questionId": "OCI-10",
                    "chosenOptionIds": [
                        "df3a9a30-afa8-45ae-a63b-2fe74e87b6c7"
                    ]
                },
                "OCI-11": {
                    "questionId": "OCI-11",
                    "chosenOptionIds": [
                        "ec46001b-9408-4c59-b95e-db21f2ecd0a1"
                    ]
                },
                "OCI-12": {
                    "questionId": "OCI-12",
                    "chosenOptionIds": [
                        "bc8f3b04-f637-46f4-b1ca-e4a6065b10fc"
                    ]
                },
                "OCI-13": {
                    "questionId": "OCI-13",
                    "chosenOptionIds": [
                        "5ccb581a-6b8f-474e-93d2-9b31babd6c77"
                    ]
                },
                "OCI-14": {
                    "questionId": "OCI-14",
                    "chosenOptionIds": [
                        "8154c188-2fd6-4bd1-b1f3-aea84ec820d6"
                    ]
                },
                "OCI-15": {
                    "questionId": "OCI-15",
                    "chosenOptionIds": [
                        "8adac844-d011-4442-814d-06d6526cf867"
                    ]
                },
                "OCI-16": {
                    "questionId": "OCI-16",
                    "chosenOptionIds": [
                        "9b799242-a808-46c5-81b8-a4bd69d6c42a"
                    ]
                },
                "OCI-17": {
                    "questionId": "OCI-17",
                    "chosenOptionIds": [
                        "b9eaf868-baab-472f-8e3d-e6b9875bbd96"
                    ]
                },
                "OCI-18": {
                    "questionId": "OCI-18",
                    "chosenOptionIds": [
                        "ab513dc5-2b0d-4588-90f8-58c4fdce6113"
                    ]
                },
                "OCI-19": {
                    "questionId": "OCI-19",
                    "chosenOptionIds": [
                        "bb0f0a5d-f3a2-4c28-a406-3ec24c0aab95"
                    ]
                },
                "OCI-20": {
                    "questionId": "OCI-20",
                    "chosenOptionIds": [
                        "c43d0794-4dcd-486e-acb0-6a58dc5b875b"
                    ]
                },
                "OCI-21": {
                    "questionId": "OCI-21",
                    "chosenOptionIds": [
                        "17908b73-b994-4e29-ae86-f2f8b916c415"
                    ]
                },
                "OCI-22": {
                    "questionId": "OCI-22",
                    "chosenOptionIds": [
                        "34d233e7-9f7c-48c8-8ea3-5845c1d99706"
                    ]
                },
                "OCI-23": {
                    "questionId": "OCI-23",
                    "chosenOptionIds": [
                        "bb3dd17f-5dee-4e15-9903-c0f76b8c03f0"
                    ]
                },
                "OCI-24": {
                    "questionId": "OCI-24",
                    "chosenOptionIds": [
                        "4da19c6a-f488-4fb2-b26a-1942a4c77d1b"
                    ]
                },
                "OCI-25": {
                    "questionId": "OCI-25",
                    "chosenOptionIds": [
                        "3c9905aa-c38d-4e28-92bf-cf997d747fcc"
                    ]
                },
                "OCI-26": {
                    "questionId": "OCI-26",
                    "chosenOptionIds": [
                        "0725147d-7407-4419-a37b-0ee5632a804d"
                    ]
                },
                "OCI-27": {
                    "questionId": "OCI-27",
                    "chosenOptionIds": [
                        "25342c97-cc75-470f-923e-382500cc62a3"
                    ]
                },
                "OCI-28": {
                    "questionId": "OCI-28",
                    "chosenOptionIds": [
                        "8b0cb701-dc3c-4a04-97db-bc07d6140e17"
                    ]
                },
                "OCI-29": {
                    "questionId": "OCI-29",
                    "chosenOptionIds": [
                        "cbf5c709-170f-41c0-bd7a-c648e1970655"
                    ]
                },
                "OCI-30": {
                    "questionId": "OCI-30",
                    "chosenOptionIds": [
                        "d5a32277-4fd6-42e5-b246-867edb8dff06"
                    ]
                },
                "OCI-31": {
                    "questionId": "OCI-31",
                    "chosenOptionIds": [
                        "48fd9b06-4408-43b4-864d-c908ca2d9144"
                    ]
                },
                "OCI-32": {
                    "questionId": "OCI-32",
                    "chosenOptionIds": [
                        "a9fdc32e-7d3e-4f18-8db7-09ec91f1f9d2"
                    ]
                },
                "OCI-33": {
                    "questionId": "OCI-33",
                    "chosenOptionIds": [
                        "90efdc59-ba09-4e58-8141-adf1967e1940"
                    ]
                },
                "OCI-34": {
                    "questionId": "OCI-34",
                    "chosenOptionIds": [
                        "b4562984-e594-42ab-ab82-af8ed6ebef99"
                    ]
                },
                "OCI-35": {
                    "questionId": "OCI-35",
                    "chosenOptionIds": [
                        "fcd5d025-2226-4043-b1cf-569053da5d1a"
                    ]
                },
                "OCI-36": {
                    "questionId": "OCI-36",
                    "chosenOptionIds": [
                        "82703344-54cb-4bbc-9366-655d7af814c0"
                    ]
                },
                "OCI-37": {
                    "questionId": "OCI-37",
                    "chosenOptionIds": [
                        "3ead3e3c-c01b-4037-9036-30262950d347"
                    ]
                },
                "OCI-38": {
                    "questionId": "OCI-38",
                    "chosenOptionIds": [
                        "53c6a27b-8859-40a6-b2f5-5902c40375e6"
                    ]
                },
                "OCI-39": {
                    "questionId": "OCI-39",
                    "chosenOptionIds": [
                        "8c837838-39d2-4e8f-a116-e39268bdb8fe"
                    ]
                },
                "OCI-40": {
                    "questionId": "OCI-40",
                    "chosenOptionIds": [
                        "241a3fe6-975f-4449-9c4f-60d37aa4fe1a"
                    ]
                },
                "OCI-41": {
                    "questionId": "OCI-41",
                    "chosenOptionIds": [
                        "5f07b839-a5ab-446c-8acd-c1f7c71b3e5a"
                    ]
                },
                "OCI-42": {
                    "questionId": "OCI-42",
                    "chosenOptionIds": [
                        "ae9a1070-45d9-4b7d-9f06-95649287be9b"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {
    "OCI-content-2": "not(OCI-content-1=0)",
    "OCI-1": "not(OCI-content-1=0)",
    "OCI-2": "not(OCI-content-1=0)",
    "OCI-3": "not(OCI-content-1=0)",
    "OCI-4": "not(OCI-content-1=0)",
    "OCI-5": "not(OCI-content-1=0)",
    "OCI-6": "not(OCI-content-1=0)",
    "OCI-7": "not(OCI-content-1=0)",
    "OCI-8": "not(OCI-content-1=0)",
    "OCI-9": "not(OCI-content-1=0)",
    "OCI-10": "not(OCI-content-1=0)",
    "OCI-11": "not(OCI-content-1=0)",
    "OCI-12": "not(OCI-content-1=0)",
    "OCI-13": "not(OCI-content-1=0)",
    "OCI-14": "not(OCI-content-1=0)",
    "OCI-15": "not(OCI-content-1=0)",
    "OCI-16": "not(OCI-content-1=0)",
    "OCI-17": "not(OCI-content-1=0)",
    "OCI-18": "not(OCI-content-1=0)",
    "OCI-19": "not(OCI-content-1=0)",
    "OCI-20": "not(OCI-content-1=0)",
    "OCI-content-3": "not(OCI-content-1=0)",
    "OCI-21": "not(OCI-content-1=0)",
    "OCI-22": "not(OCI-content-1=0)",
    "OCI-23": "not(OCI-content-1=0)",
    "OCI-24": "not(OCI-content-1=0)",
    "OCI-25": "not(OCI-content-1=0)",
    "OCI-26": "not(OCI-content-1=0)",
    "OCI-27": "not(OCI-content-1=0)",
    "OCI-28": "not(OCI-content-1=0)",
    "OCI-29": "not(OCI-content-1=0)",
    "OCI-30": "not(OCI-content-1=0)",
    "OCI-31": "not(OCI-content-1=0)",
    "OCI-32": "not(OCI-content-1=0)",
    "OCI-33": "not(OCI-content-1=0)",
    "OCI-34": "not(OCI-content-1=0)",
    "OCI-35": "not(OCI-content-1=0)",
    "OCI-36": "not(OCI-content-1=0)",
    "OCI-37": "not(OCI-content-1=0)",
    "OCI-38": "not(OCI-content-1=0)",
    "OCI-39": "not(OCI-content-1=0)",
    "OCI-40": "not(OCI-content-1=0)",
    "OCI-41": "not(OCI-content-1=0)",
    "OCI-42": "not(OCI-content-1=0)"
};
