import React, { ChangeEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Input, Button } from '@psyomics/components'
import Field from '../../../registration/components/Field'
import Label from '../../../registration/components/Label'
import Validation from '../../../registration/components/Validation'
import ContentPage from '../components/ContentPage'
import { useAuth } from 'registration/context/auth'
import PasswordRules, { passwordValidationFunctions } from '../components/PasswordRules'
import { Auth } from 'aws-amplify'
import * as Sentry from '@sentry/react'
import Styles from './ChangePassword.module.scss'
const { hasMinLength, hasUpperCase, hasLowerCase, hasSpecialChar, hasNumber } = passwordValidationFunctions

type PasswordTypes = 'current' | 'new' | 'repeat'

export const ChangePassword: React.FC = () => {
    const CURRENT_PASSWORD_MESSAGE = 'Current password is required'
    const REPEAT_PASSWORD_MESSAGE = 'Passwords do not match'
    const initState = () => ({
        current: {
            val: '',
            show: false,
        },
        new: {
            val: '',
            show: false,
        },
        repeat: {
            val: '',
            show: false,
        },
    })
    const { getCurrentUser } = useAuth()
    const [values, setValues] = useState<ReturnType<typeof initState>>(initState())
    const [errors, setErrors] = useState<{
        currentPassword?: string
        newPassword?: string
        confirmPassword?: string
    }>({})
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [submitSuccess, setSubmitSuccess] = useState(false)

    const handleChange = (field: PasswordTypes) => (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value
        setValues({
            ...values,
            [field]: {
                ...values[field],
                val: newValue,
            },
        })

        const errorsField =
            field === 'current' ? 'currentPassword' : field === 'new' ? 'newPassword' : 'confirmPassword'
        // Clear related errors when typing
        if (errors[errorsField]) {
            setErrors({
                ...errors,
                [errorsField]: undefined,
            })
        }

        // Clear confirmPassword error if new password is changed
        if (field === 'new' && errors.confirmPassword) {
            setErrors({
                ...errors,
                confirmPassword: undefined,
            })
        }
    }

    const togglePasswordVisibility = (field: PasswordTypes) => {
        setValues({
            ...values,
            [field]: {
                ...values[field],
                show: !values[field].show,
            },
        })
    }

    function newValError(val: string): string | undefined {
        if (!val) {
            return 'New password is required'
        } else if (!hasMinLength(values.new.val)) {
            return 'Password must be at least 8 characters long'
        } else if (
            !hasUpperCase(values.new.val) ||
            !hasLowerCase(values.new.val) ||
            !hasNumber(values.new.val) ||
            !hasSpecialChar(values.new.val)
        ) {
            return 'Password must meet all requirements'
        }
    }

    const validateForm = () => {
        const newErrors: {
            currentPassword?: string
            newPassword?: string
            confirmPassword?: string
        } = {}

        //  current password
        if (!values.current.val) {
            newErrors.currentPassword = CURRENT_PASSWORD_MESSAGE
        }

        // new password
        const newVError = newValError(values.new.val)
        if (newVError) newErrors.newPassword = newVError

        //  password confirmation
        if (values.new.val !== values.repeat.val) {
            newErrors.confirmPassword = REPEAT_PASSWORD_MESSAGE
        }

        setErrors(newErrors)
        return Object.keys(newErrors).length === 0
    }

    const handleBlur = (field: PasswordTypes) => () => {
        const newErrors = { ...errors }

        if (field === 'current' && !values.current.val) {
            newErrors.currentPassword = CURRENT_PASSWORD_MESSAGE
        }

        if (field === 'new') {
            const newVError = newValError(values.new.val)
            if (newVError) {
                newErrors.newPassword = newVError
            } else {
                delete newErrors.newPassword
            }
        }

        if (
            (field === 'repeat' || (field === 'new' && values.new.val && values.repeat.val)) &&
            values.new.val !== values.repeat.val
        ) {
            newErrors.confirmPassword = REPEAT_PASSWORD_MESSAGE
        } else if (field === 'repeat') {
            delete newErrors.confirmPassword
        }

        setErrors(newErrors)
    }

    async function handleSubmit(e: React.FormEvent) {
        e.preventDefault()

        if (!validateForm()) {
            return
        }

        setSubmitSuccess(false)

        setIsSubmitting(true)

        try {
            const user = await getCurrentUser()
            if (user && values.current.val && values.new.val) {
                await Auth.changePassword(user, values.current.val, values.new.val)
                setSubmitSuccess(true)
                setValues(initState())
            } else if (!user) {
                const noUserError = new Error('No user found for password change')
                Sentry.captureException(noUserError, { extra: { user } })
                throw noUserError
            } else {
                throw new Error('Missing required information for password change')
            }
        } catch (error: any) {
            Sentry.captureException(error, {
                extra: {
                    level: 'log',
                    values,
                },
            })

            if (error.code === 'NotAuthorizedException') {
                setErrors({
                    ...errors,
                    currentPassword: 'Incorrect current password',
                })
            } else if (error.code === 'LimitExceededException') {
                setErrors({
                    ...errors,
                    currentPassword: 'Too many attempts. Please try again later',
                })
            } else if (error.code === 'InvalidPasswordException') {
                setErrors({
                    ...errors,
                    newPassword: error.message,
                })
            } else {
                setErrors({
                    ...errors,
                    currentPassword: 'An error occurred while changing your password',
                })
            }
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <form onSubmit={handleSubmit} className={Styles.form}>
            <Field>
                <Label htmlFor="currentPassword">Your Current Password</Label>
                <Input
                    data-private="true"
                    type={values.current.show ? 'text' : 'password'}
                    id="currentPassword"
                    name="currentPassword"
                    appearance="outlined"
                    invalid={!!errors?.currentPassword}
                    onChange={handleChange('current')}
                    onBlur={handleBlur('current')}
                    value={values.current.val}
                    suffix={
                        <SuffixShowHide
                            viewingHideButton={values.current.show}
                            togglePasswordVisibility={() => {
                                togglePasswordVisibility('current')
                            }}
                        ></SuffixShowHide>
                    }
                    placeholder="Current Password"
                    disabled={isSubmitting}
                />
                {errors?.currentPassword && <Validation>{errors.currentPassword}</Validation>}
            </Field>

            <Field>
                <Label htmlFor="newPassword">Your New Password</Label>
                <Input
                    type={values.new.show ? 'text' : 'password'}
                    id="newPassword"
                    name="newPassword"
                    appearance="outlined"
                    invalid={!!errors?.newPassword}
                    onChange={handleChange('new')}
                    onBlur={handleBlur('new')}
                    value={values.new.val}
                    suffix={
                        <SuffixShowHide
                            viewingHideButton={values.new.show}
                            togglePasswordVisibility={() => {
                                togglePasswordVisibility('new')
                            }}
                        ></SuffixShowHide>
                    }
                    placeholder="New Password"
                    disabled={isSubmitting}
                />
                {errors?.newPassword && <Validation>{errors.newPassword}</Validation>}
            </Field>

            <Field>
                <Label htmlFor="confirmPassword">Your New Password (again)</Label>
                <Input
                    type={values.repeat.show ? 'text' : 'password'}
                    id="confirmPassword"
                    name="confirmPassword"
                    appearance="outlined"
                    invalid={!!errors?.confirmPassword}
                    onChange={handleChange('repeat')}
                    onBlur={handleBlur('repeat')}
                    value={values.repeat.val}
                    suffix={
                        <SuffixShowHide
                            viewingHideButton={values.repeat.show}
                            togglePasswordVisibility={() => {
                                togglePasswordVisibility('repeat')
                            }}
                        ></SuffixShowHide>
                    }
                    placeholder="New Password"
                    disabled={isSubmitting}
                />
                {errors?.confirmPassword && <Validation>{errors.confirmPassword}</Validation>}
            </Field>

            <PasswordRules password={values.new.val || undefined} />

            {submitSuccess && <div aria-live="polite">Password successfully changed!</div>}

            <div>
                <Button
                    disabled={
                        isSubmitting ||
                        errors.currentPassword ||
                        errors.confirmPassword ||
                        errors.newPassword ||
                        !values.current.val ||
                        !values.new.val ||
                        !values.repeat.val
                    }
                    appearance="primary"
                    type="submit"
                    label="Save New Password"
                    loading={isSubmitting}
                />
            </div>
        </form>
    )
}

export const ChangePasswordPage: React.FC = () => {
    const navigate = useNavigate()
    return (
        <ContentPage
            headingText="Change Your Password"
            headingButton={{
                text: 'Back',
                link: () => {
                    navigate(-1)
                },
            }}
        >
            <ChangePassword></ChangePassword>
        </ContentPage>
    )
}

export const SuffixShowHide: React.FC<{ viewingHideButton: boolean; togglePasswordVisibility: () => void }> = ({
    viewingHideButton,
    togglePasswordVisibility,
}) => {
    return (
        <button
            type="button"
            className={Styles.showHide}
            tabIndex={0}
            onClick={(e) => {
                togglePasswordVisibility()
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    togglePasswordVisibility()
                }
            }}
            aria-label={viewingHideButton ? 'Hide current password' : 'Show current password'}
        >
            {viewingHideButton ? 'hide' : 'show'}
        </button>
    )
}
