"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
const censeo_1 = require("../../schema/generated/censeo");
exports.questions = [
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "3a8b6b34-07c3-5c77-8553-5da6165e4e2a",
                "text": "No problems",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a06e5af9-7423-5a52-9f90-b813c26565cd",
                "text": "Slight problems",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "aef2d8b9-cf9f-56c2-ad48-e1ebd632ca77",
                "text": "Significant problems",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "ee98184e-21e5-5ba1-9200-93b8b793d5b0",
                "text": "Severe problems",
                "value": 1
            }
        ],
        "id": "In-1",
        "text": "Have you experienced any problems with not sleeping enough recently (i.e. over the past few weeks)? \nThis may include finding it hard to get to sleep at night, waking up during the night, or waking up too early in the morning.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "3eac8bff-c7ef-590d-b79e-6a34771e0384",
                "text": "No, there are no clear external factors impacting my sleep",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7a5cbb84-cf16-51e0-84b7-e4723c916836",
                "text": "Yes, I don't have the time or opportunity to sleep",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "28bb9c4d-6d74-5d8c-93fa-6bb1d018bccb",
                "text": "Yes, I have poor sleep conditions",
                "value": 1
            }
        ],
        "id": "In-2",
        "text": "Are your sleep difficulties due to external factors such as not having the time or opportunity to sleep (e.g. shift work, newborn babies)  or having poor sleep conditions (e.g. noise, light)?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ac326a51-7f53-50f3-9ff7-ea86ba6131de",
                "text": "Satisfied",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "9301153f-717c-5274-ba9d-30ef58506872",
                "text": "Slightly dissatisfied",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "1fdf222e-20cd-55e5-84c5-fdb29cb8c10a",
                "text": "Moderately dissatisfied",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "1f2fa891-03ee-520d-85f2-0effd7ed4929",
                "text": "Very dissatisfied",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "49e95f65-9b56-5793-b77f-cf84c5edde1c",
                "text": "Extremely dissatisfied",
                "value": 1
            }
        ],
        "id": "In-3",
        "text": "Over the past few weeks, how satisfied have you been with the quantity or quality of your sleep?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "9e719d2d-3882-57ee-b04f-54fad97dfe0c",
                "text": "It never or rarely takes me more than 30 minutes to fall asleep",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "6356b6e1-c3d1-5625-921b-02ed5e7906f1",
                "text": "It sometimes takes me more than 30 minutes to fall asleep",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "008a928e-e359-5012-9ab5-7f10f55cba4e",
                "text": "It often takes me more than 30 minutes to fall asleep",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "b2ed5b66-1b5f-5103-905d-93cc7cc24b29",
                "text": "It almost always or always takes me more than 30 minutes to fall asleep",
                "value": 1
            }
        ],
        "id": "In-4",
        "text": "Which of these statements most accurately describes your sleep over  the past few weeks?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "91c61ffa-89ce-5d64-bc2a-821a148c1889",
                "text": "I never or rarely wake up in the night",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "e1280b2b-4883-55d4-9fde-006cb7a9ff65",
                "text": "I sometimes wake up in the night",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "4632255c-da2c-5ecd-8625-5a11c077f318",
                "text": "I often wake up in the night",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "0c2db685-4d19-5af4-a79f-b493e2f1b5dd",
                "text": "I almost always or always wake up in the night",
                "value": 1
            }
        ],
        "id": "In-5",
        "text": "Which of these statements most accurately describes your sleep over  the past few weeks?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "d06953ca-22b8-51e0-bccd-e83b29c68f80",
                "text": "I never or rarely wake up more than 30 minutes before I need to",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "0f9486d2-6653-5883-81af-6639d6b2864a",
                "text": "I sometimes wake up more than 30 minutes before I need to",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "89ae5db3-64e0-583c-9851-2bb2aca93eed",
                "text": "I often wake up more than 30 minutes before I need to",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "9582d3c5-0593-5b97-8919-1a3c8ba4969f",
                "text": "I almost always or always wake up more than 30 minutes before I need to",
                "value": 1
            }
        ],
        "id": "In-7",
        "text": "Which of these statements most accurately describes your sleep  over the past few weeks?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "In-8",
        "text": "On an typical night, approximately how many hours of sleep do you get?",
        "unit": censeo_1.Unit.None
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "06603bb4-61d6-5865-ba16-bfd5ace66da2",
                "text": "1–2 nights per week",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "50e6662a-fe0d-5151-8d7f-96a4e52e3756",
                "text": "3–4 nights per week",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "f494b02f-9ac9-5ae9-94b9-aea01e773c36",
                "text": "5–6 nights per week",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "8c6f7f1c-ae12-52b6-bb87-867f7be40803",
                "text": "Every night",
                "value": 1
            }
        ],
        "id": "In-9",
        "text": "In a typical week, approximately how many nights are you affected by sleep problems?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "In-10",
        "text": "Approximately how long have you had these problems sleeping?  If you are not sure, please give your best estimate.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "dc6c7fa2-28ac-5cef-93f9-1925663d803d",
                "text": "No, it doesn't really bother me",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "dbc05e91-2751-5ca2-a9a6-d500e05aac76",
                "text": "Yes, slightly distressing",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "c6a59208-d315-55f8-bf9a-7858994cb083",
                "text": "Yes, moderately distressing",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "93a6f63e-0a2a-56ab-8cb7-35aaa2472713",
                "text": "Yes, very distressing",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "f1537ed3-6bcd-5fef-924c-804b5c18536a",
                "text": "Yes, extremely distressing",
                "value": 1
            }
        ],
        "id": "In-11",
        "text": "Do you find your sleep problems distressing (i.e. do you worry or get upset about them)?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "4ae113c2-5677-58b1-9a0f-bb90310fae83",
                "text": "No, never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "54e3b956-9bed-500e-8688-61a31c2cf50c",
                "text": "Sometimes",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "663a518d-aa06-511e-b86b-bdd379c2df9c",
                "text": "Quite often",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "cff540a8-01a6-5fd5-9549-11e45e4f293a",
                "text": "Very often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "199c9da9-7dd8-5667-baa6-a45176b4661c",
                "text": "All the time",
                "value": 1
            }
        ],
        "id": "In-12",
        "text": "Because of your sleep problems, do you ever fall asleep during the day without meaning to?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "bba6d73a-dbb7-5aa1-950c-e023e93bb6f5",
                "text": "No, never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d9aa763a-58bc-583a-9b2f-c32f61474f83",
                "text": "Sometimes",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "14131069-85b9-5bb8-be85-e0ddd452a5a9",
                "text": "Quite often",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "3727c048-d68f-5b27-955f-9fde9d11b57c",
                "text": "Very often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "2af66c9b-bab3-5448-bbcc-1c124ff4d276",
                "text": "All the time",
                "value": 1
            }
        ],
        "id": "In-13",
        "text": "Because of your sleep problems, have you ever fallen asleep in a dangerous  situation (e.g. while driving a car, while operating machinery, while cooking)?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b5730e2c-5b65-5db2-8203-236ca3fabecf",
                "text": "No, not at all concerned",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "66324493-9e40-5b6e-a1e2-218684881c4b",
                "text": "Yes, slightly concerned",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "1411fb90-e50a-5075-a1df-655689e3e23a",
                "text": "Yes, moderately concerned",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "cc16c77f-e49a-5238-aa1d-e3cc4844a8b5",
                "text": "Yes, very concerned",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "3052c6fe-22a4-5451-818a-eb6819d8572c",
                "text": "Yes, extremely concerned",
                "value": 1
            }
        ],
        "id": "In-14",
        "text": "Are you worried about your lack of sleep might be affecting you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e94675da-6fb2-5470-a6f3-d7d2e2f03f3d",
                "text": "No, never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "8369b683-4933-57db-b8ca-4bc363efebb3",
                "text": "Yes, sometimes",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "eaa71b08-9994-5cd6-a747-dbc9c7028d41",
                "text": "Yes, quite often",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "768d36b2-99f6-52d5-828e-ac4aa7c1af98",
                "text": "Yes, very often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "fed858ba-a9ce-5ca0-8fe3-53947fa72a39",
                "text": "Yes, all the time",
                "value": 1
            }
        ],
        "id": "In-15",
        "text": "Have you found that the lack of sleep affects your concentration, memory or attention span?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "12c2ab31-e048-51b9-a607-06fc1a91c81c",
                "text": "No, never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "38000a7d-95f8-509b-a2fc-645d6c241769",
                "text": "Sometimes",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "70cbb18e-77a3-51ec-8d9c-48d624956d47",
                "text": "Quite often",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "ab1bdce5-400a-567d-a0ff-92fe1fc099d2",
                "text": "Very often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "b7e4e182-a94b-5d50-b7f2-cc8e323ae638",
                "text": "All the time",
                "value": 1
            }
        ],
        "id": "In-16",
        "text": "Do you ever use alcohol, over-the-counter drugs, or medication not prescribed by a  healthcare professional (e.g., a GP) to help with your sleep problems?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "4b22dd2f-c083-5b47-89c2-536f0e141377",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "85c708df-eaf0-5749-ab43-08470798a3f9",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "In-17",
        "text": "Have you ever been diagnosed with a sleep disorder  (e.g. sleep apnoea, narcolepsy, parasomnia)?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8926a3e0-4939-57d9-ab63-21eaa6cdbfaf",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7260a0f8-9cca-5761-b31d-bdcf0b07be65",
                "text": "Yes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "7249e1aa-bac0-5e81-9189-841edcfa568c",
                "text": "I am not sure",
                "value": 1
            }
        ],
        "id": "In-18",
        "text": "Do you suffer from any medical conditions or use any  medication that may affect your sleep?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "78bc4ce9-eede-5e71-8f2f-ccc07e727c3d",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "96213629-1187-52b2-9e7a-a0c851bcbe0e",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "In-19",
        "text": "Are your sleeping difficulties the main reason for seeking help?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a10b645d-916a-520c-a439-d5dfbd1d5465",
                "text": "Basic needs such as eating and washing"
            },
            {
                "__typename": "Option",
                "id": "bcc56784-ea59-5dc2-8c65-a2fe19434781",
                "text": "Working or studying"
            },
            {
                "__typename": "Option",
                "id": "f80fac01-7377-5e9a-982b-74b3bd9b5608",
                "text": "Intimate relationships"
            },
            {
                "__typename": "Option",
                "id": "9b4fae9f-9ea1-550c-9e62-f9575b443436",
                "text": "Social life"
            },
            {
                "__typename": "Option",
                "id": "ad34db74-4106-57f8-9443-cd9820028a90",
                "text": "Family"
            },
            {
                "__typename": "Option",
                "id": "99ab10a1-a8ba-5aea-a09c-0dfd2031301e",
                "text": "Leisure activities, hobbies and interests"
            },
            {
                "__typename": "Option",
                "id": "262ab8d3-bffb-5952-92af-afb5b6c20cbf",
                "text": "Keeping fit and healthy"
            },
            {
                "__typename": "Option",
                "id": "0ebed11d-c85e-5b65-8421-c3b4feb3f213",
                "text": "Finances"
            },
            {
                "__typename": "Option",
                "id": "a176e73d-22fd-541f-98df-d9dc34dc5748",
                "text": "Other aspects of my life not mentioned above"
            },
            {
                "__typename": "Option",
                "id": "252e68b2-7776-4d91-aded-fa8334f1a7cb",
                "text": "No areas of my life are affected by sleep problems",
                "exclusive": true
            }
        ],
        "id": "In-20",
        "text": "Which areas of your life, if any, do you feel are affected by your sleep problems?  Please select all that apply.",
        "isMultiselect": true
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "f358d4ad-61fb-50d6-9e7b-96f74c083255",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c5b2f626-3dbd-5274-b94a-80038bb354ec",
                "text": "To a slight extent",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "94fb5213-cd49-543d-a957-4b7cb2b0a384",
                "text": "To a moderate extent",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "6227fa46-142f-57ce-85d8-c558a73f98a8",
                "text": "To a significant extent",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "bdacf2ac-3084-55f2-9c3d-5f7a86fbc4ab",
                "text": "To an extreme extent",
                "value": 1
            }
        ],
        "id": "In-20.0",
        "text": "To what extent do your sleep problems affect these areas of your life, or require you to make additional effort to maintain functioning in those areas?",
        "isMultiselect": false
    }
];
exports.aspects = {
    "ins_ce": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "In-1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "3a8b6b34-07c3-5c77-8553-5da6165e4e2a": 0.03557118927263617,
                            "a06e5af9-7423-5a52-9f90-b813c26565cd": 0.5,
                            "aef2d8b9-cf9f-56c2-ad48-e1ebd632ca77": 0.9644288107273639,
                            "ee98184e-21e5-5ba1-9200-93b8b793d5b0": 0.9987706013787226
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "in_A_sleep_quant_qual"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "in_B_distress_impairment"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "in_C_frequency"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "in_D_duration"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "in_E_opportunity"
                }
            }
        ],
        "anyToAll": 1
    },
    "in_A_sleep_quant_qual": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "in_initiating"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "in_maintaining"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "in_awakening"
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "in_B_distress_impairment": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "in_distress"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "in_impairment"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "in_C_frequency": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "In-9",
            "options": {
                "type": "severities",
                "severities": {
                    "06603bb4-61d6-5865-ba16-bfd5ace66da2": 0.03557118927263617,
                    "50e6662a-fe0d-5151-8d7f-96a4e52e3756": 0.5,
                    "f494b02f-9ac9-5ae9-94b9-aea01e773c36": 0.9644288107273639,
                    "8c6f7f1c-ae12-52b6-bb87-867f7be40803": 0.9987706013787226
                }
            }
        }
    },
    "in_D_duration": {
        "type": "input",
        "signal": {
            "type": "SpecificNumberInput",
            "questionId": "In-10",
            "input": 90,
            "operator": ">="
        }
    },
    "in_E_opportunity": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "In-2",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "3eac8bff-c7ef-590d-b79e-6a34771e0384": false,
                        "7a5cbb84-cf16-51e0-84b7-e4723c916836": true,
                        "28bb9c4d-6d74-5d8c-93fa-6bb1d018bccb": false
                    }
                }
            }
        }
    },
    "in_initiating": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "In-4",
            "options": {
                "type": "severities",
                "severities": {
                    "9e719d2d-3882-57ee-b04f-54fad97dfe0c": 0.03557118927263617,
                    "6356b6e1-c3d1-5625-921b-02ed5e7906f1": 0.5,
                    "008a928e-e359-5012-9ab5-7f10f55cba4e": 0.9644288107273639,
                    "b2ed5b66-1b5f-5103-905d-93cc7cc24b29": 0.9987706013787226
                }
            }
        }
    },
    "in_maintaining": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "In-5",
            "options": {
                "type": "severities",
                "severities": {
                    "91c61ffa-89ce-5d64-bc2a-821a148c1889": 0.001358519950428958,
                    "e1280b2b-4883-55d4-9fde-006cb7a9ff65": 0.03557118927263617,
                    "4632255c-da2c-5ecd-8625-5a11c077f318": 0.5,
                    "0c2db685-4d19-5af4-a79f-b493e2f1b5dd": 0.9677045353015494
                }
            }
        }
    },
    "in_awakening": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "In-7",
            "options": {
                "type": "severities",
                "severities": {
                    "d06953ca-22b8-51e0-bccd-e83b29c68f80": 0.03557118927263617,
                    "0f9486d2-6653-5883-81af-6639d6b2864a": 0.5,
                    "89ae5db3-64e0-583c-9851-2bb2aca93eed": 0.9644288107273639,
                    "9582d3c5-0593-5b97-8919-1a3c8ba4969f": 0.9987706013787226
                }
            }
        }
    },
    "in_distress": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "In-11",
            "options": {
                "type": "severities",
                "severities": {
                    "dc6c7fa2-28ac-5cef-93f9-1925663d803d": 0.0066928509242848554,
                    "dbc05e91-2751-5ca2-a9a6-d500e05aac76": 0.07585818002124355,
                    "c6a59208-d315-55f8-bf9a-7858994cb083": 0.5,
                    "93a6f63e-0a2a-56ab-8cb7-35aaa2472713": 0.9241418199787566,
                    "f1537ed3-6bcd-5fef-924c-804b5c18536a": 0.9933071490757153
                }
            }
        }
    },
    "in_impairment": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "In20_basic"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "In20_work"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "In20_relationships"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "In20_social"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "In20_family"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "In21_leisure"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "In21_health"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "In21_financial"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "In21_other"
                        }
                    }
                ],
                "anyToAll": 0.2222222222222222
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "In-20.0",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "f358d4ad-61fb-50d6-9e7b-96f74c083255": 0.0066928509242848554,
                            "c5b2f626-3dbd-5274-b94a-80038bb354ec": 0.07585818002124355,
                            "94fb5213-cd49-543d-a957-4b7cb2b0a384": 0.5,
                            "6227fa46-142f-57ce-85d8-c558a73f98a8": 0.9241418199787566,
                            "bdacf2ac-3084-55f2-9c3d-5f7a86fbc4ab": 0.9933071490757153
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "In20_basic": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "In-20",
            "options": {
                "type": "severities",
                "severities": {
                    "a10b645d-916a-520c-a439-d5dfbd1d5465": 1
                }
            }
        }
    },
    "In20_work": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "In-20",
            "options": {
                "type": "severities",
                "severities": {
                    "bcc56784-ea59-5dc2-8c65-a2fe19434781": 1
                }
            }
        }
    },
    "In20_relationships": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "In-20",
            "options": {
                "type": "severities",
                "severities": {
                    "f80fac01-7377-5e9a-982b-74b3bd9b5608": 1
                }
            }
        }
    },
    "In20_social": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "In-20",
            "options": {
                "type": "severities",
                "severities": {
                    "9b4fae9f-9ea1-550c-9e62-f9575b443436": 1
                }
            }
        }
    },
    "In20_family": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "In-20",
            "options": {
                "type": "severities",
                "severities": {
                    "ad34db74-4106-57f8-9443-cd9820028a90": 1
                }
            }
        }
    },
    "In21_leisure": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "In-20",
            "options": {
                "type": "severities",
                "severities": {
                    "99ab10a1-a8ba-5aea-a09c-0dfd2031301e": 1
                }
            }
        }
    },
    "In21_health": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "In-20",
            "options": {
                "type": "severities",
                "severities": {
                    "262ab8d3-bffb-5952-92af-afb5b6c20cbf": 1
                }
            }
        }
    },
    "In21_financial": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "In-20",
            "options": {
                "type": "severities",
                "severities": {
                    "0ebed11d-c85e-5b65-8421-c3b4feb3f213": 1
                }
            }
        }
    },
    "In21_other": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "In-20",
            "options": {
                "type": "severities",
                "severities": {
                    "a176e73d-22fd-541f-98df-d9dc34dc5748": 1
                }
            }
        }
    },
    "In20_none": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "In-20",
            "options": {
                "type": "severities",
                "severities": {
                    "252e68b2-7776-4d91-aded-fa8334f1a7cb": 1
                }
            }
        }
    },
    "not(In-1=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "In-1",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "3a8b6b34-07c3-5c77-8553-5da6165e4e2a": true,
                        "a06e5af9-7423-5a52-9f90-b813c26565cd": false,
                        "aef2d8b9-cf9f-56c2-ad48-e1ebd632ca77": false,
                        "ee98184e-21e5-5ba1-9200-93b8b793d5b0": false
                    }
                }
            }
        }
    },
    "and[or[isUndefined(In20_none=1), not(In20_none=1)], not(isUndefined(In-20=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "In20_none"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "In20_none"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "In-20",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "a10b645d-916a-520c-a439-d5dfbd1d5465": false,
                                    "bcc56784-ea59-5dc2-8c65-a2fe19434781": false,
                                    "f80fac01-7377-5e9a-982b-74b3bd9b5608": false,
                                    "9b4fae9f-9ea1-550c-9e62-f9575b443436": false,
                                    "ad34db74-4106-57f8-9443-cd9820028a90": false,
                                    "99ab10a1-a8ba-5aea-a09c-0dfd2031301e": false,
                                    "262ab8d3-bffb-5952-92af-afb5b6c20cbf": false,
                                    "0ebed11d-c85e-5b65-8421-c3b4feb3f213": false,
                                    "a176e73d-22fd-541f-98df-d9dc34dc5748": false,
                                    "252e68b2-7776-4d91-aded-fa8334f1a7cb": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    }
};
exports.tests = {
    "ins_ce": [
        {
            "name": "Tech team adjusted from 76, Censeo vPrevious user: K392M0L aspect: ins_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.97
            },
            "answers": {
                "In-1": {
                    "questionId": "In-1",
                    "chosenOptionIds": [
                        "aef2d8b9-cf9f-56c2-ad48-e1ebd632ca77"
                    ]
                },
                "In-10": {
                    "questionId": "In-10",
                    "freeValue": 3650
                },
                "In-11": {
                    "questionId": "In-11",
                    "chosenOptionIds": [
                        "f1537ed3-6bcd-5fef-924c-804b5c18536a"
                    ]
                },
                "In-12": {
                    "questionId": "In-12",
                    "chosenOptionIds": [
                        "4ae113c2-5677-58b1-9a0f-bb90310fae83"
                    ]
                },
                "In-13": {
                    "questionId": "In-13",
                    "chosenOptionIds": [
                        "bba6d73a-dbb7-5aa1-950c-e023e93bb6f5"
                    ]
                },
                "In-14": {
                    "questionId": "In-14",
                    "chosenOptionIds": [
                        "cc16c77f-e49a-5238-aa1d-e3cc4844a8b5"
                    ]
                },
                "In-15": {
                    "questionId": "In-15",
                    "chosenOptionIds": [
                        "768d36b2-99f6-52d5-828e-ac4aa7c1af98"
                    ]
                },
                "In-16": {
                    "questionId": "In-16",
                    "chosenOptionIds": [
                        "ab1bdce5-400a-567d-a0ff-92fe1fc099d2"
                    ]
                },
                "In-17": {
                    "questionId": "In-17",
                    "chosenOptionIds": [
                        "85c708df-eaf0-5749-ab43-08470798a3f9"
                    ]
                },
                "In-18": {
                    "questionId": "In-18",
                    "chosenOptionIds": [
                        "8926a3e0-4939-57d9-ab63-21eaa6cdbfaf"
                    ]
                },
                "In-19": {
                    "questionId": "In-19",
                    "chosenOptionIds": [
                        "96213629-1187-52b2-9e7a-a0c851bcbe0e"
                    ]
                },
                "In-2": {
                    "questionId": "In-2",
                    "chosenOptionIds": [
                        "3eac8bff-c7ef-590d-b79e-6a34771e0384"
                    ]
                },
                "In-20": {
                    "questionId": "In-20",
                    "chosenOptionIds": [
                        "bcc56784-ea59-5dc2-8c65-a2fe19434781",
                        "9b4fae9f-9ea1-550c-9e62-f9575b443436",
                        "f80fac01-7377-5e9a-982b-74b3bd9b5608",
                        "ad34db74-4106-57f8-9443-cd9820028a90"
                    ]
                },
                "In-20.0": {
                    "questionId": "In-20.0",
                    "chosenOptionIds": [
                        "6227fa46-142f-57ce-85d8-c558a73f98a8"
                    ]
                },
                "In-3": {
                    "questionId": "In-3",
                    "chosenOptionIds": [
                        "1f2fa891-03ee-520d-85f2-0effd7ed4929"
                    ]
                },
                "In-4": {
                    "questionId": "In-4",
                    "chosenOptionIds": [
                        "6356b6e1-c3d1-5625-921b-02ed5e7906f1"
                    ]
                },
                "In-5": {
                    "questionId": "In-5",
                    "chosenOptionIds": [
                        "4632255c-da2c-5ecd-8625-5a11c077f318"
                    ]
                },
                "In-7": {
                    "questionId": "In-7",
                    "chosenOptionIds": [
                        "89ae5db3-64e0-583c-9851-2bb2aca93eed"
                    ]
                },
                "In-8": {
                    "questionId": "In-8",
                    "freeValue": 5
                },
                "In-9": {
                    "questionId": "In-9",
                    "chosenOptionIds": [
                        "8c6f7f1c-ae12-52b6-bb87-867f7be40803"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: XE7DM0L aspect: ins_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "In-1": {
                    "questionId": "In-1",
                    "chosenOptionIds": [
                        "aef2d8b9-cf9f-56c2-ad48-e1ebd632ca77"
                    ]
                },
                "In-10": {
                    "questionId": "In-10",
                    "freeValue": 5475
                },
                "In-11": {
                    "questionId": "In-11",
                    "chosenOptionIds": [
                        "93a6f63e-0a2a-56ab-8cb7-35aaa2472713"
                    ]
                },
                "In-12": {
                    "questionId": "In-12",
                    "chosenOptionIds": [
                        "54e3b956-9bed-500e-8688-61a31c2cf50c"
                    ]
                },
                "In-13": {
                    "questionId": "In-13",
                    "chosenOptionIds": [
                        "bba6d73a-dbb7-5aa1-950c-e023e93bb6f5"
                    ]
                },
                "In-14": {
                    "questionId": "In-14",
                    "chosenOptionIds": [
                        "cc16c77f-e49a-5238-aa1d-e3cc4844a8b5"
                    ]
                },
                "In-15": {
                    "questionId": "In-15",
                    "chosenOptionIds": [
                        "768d36b2-99f6-52d5-828e-ac4aa7c1af98"
                    ]
                },
                "In-16": {
                    "questionId": "In-16",
                    "chosenOptionIds": [
                        "12c2ab31-e048-51b9-a607-06fc1a91c81c"
                    ]
                },
                "In-17": {
                    "questionId": "In-17",
                    "chosenOptionIds": [
                        "4b22dd2f-c083-5b47-89c2-536f0e141377"
                    ]
                },
                "In-18": {
                    "questionId": "In-18",
                    "chosenOptionIds": [
                        "7260a0f8-9cca-5761-b31d-bdcf0b07be65"
                    ]
                },
                "In-19": {
                    "questionId": "In-19",
                    "chosenOptionIds": [
                        "78bc4ce9-eede-5e71-8f2f-ccc07e727c3d"
                    ]
                },
                "In-2": {
                    "questionId": "In-2",
                    "chosenOptionIds": [
                        "28bb9c4d-6d74-5d8c-93fa-6bb1d018bccb"
                    ]
                },
                "In-20": {
                    "questionId": "In-20",
                    "chosenOptionIds": [
                        "bcc56784-ea59-5dc2-8c65-a2fe19434781",
                        "f80fac01-7377-5e9a-982b-74b3bd9b5608",
                        "9b4fae9f-9ea1-550c-9e62-f9575b443436",
                        "ad34db74-4106-57f8-9443-cd9820028a90",
                        "99ab10a1-a8ba-5aea-a09c-0dfd2031301e",
                        "262ab8d3-bffb-5952-92af-afb5b6c20cbf",
                        "0ebed11d-c85e-5b65-8421-c3b4feb3f213",
                        "a176e73d-22fd-541f-98df-d9dc34dc5748"
                    ]
                },
                "In-20.0": {
                    "questionId": "In-20.0",
                    "chosenOptionIds": [
                        "94fb5213-cd49-543d-a957-4b7cb2b0a384"
                    ]
                },
                "In-3": {
                    "questionId": "In-3",
                    "chosenOptionIds": [
                        "1f2fa891-03ee-520d-85f2-0effd7ed4929"
                    ]
                },
                "In-4": {
                    "questionId": "In-4",
                    "chosenOptionIds": [
                        "008a928e-e359-5012-9ab5-7f10f55cba4e"
                    ]
                },
                "In-5": {
                    "questionId": "In-5",
                    "chosenOptionIds": [
                        "4632255c-da2c-5ecd-8625-5a11c077f318"
                    ]
                },
                "In-7": {
                    "questionId": "In-7",
                    "chosenOptionIds": [
                        "9582d3c5-0593-5b97-8919-1a3c8ba4969f"
                    ]
                },
                "In-8": {
                    "questionId": "In-8",
                    "freeValue": 4
                },
                "In-9": {
                    "questionId": "In-9",
                    "chosenOptionIds": [
                        "8c6f7f1c-ae12-52b6-bb87-867f7be40803"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: XE7DM0L aspect: ins_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.77
            },
            "answers": {
                "In-1": {
                    "questionId": "In-1",
                    "chosenOptionIds": [
                        "aef2d8b9-cf9f-56c2-ad48-e1ebd632ca77"
                    ]
                },
                "In-10": {
                    "questionId": "In-10",
                    "freeValue": 5475
                },
                "In-11": {
                    "questionId": "In-11",
                    "chosenOptionIds": [
                        "93a6f63e-0a2a-56ab-8cb7-35aaa2472713"
                    ]
                },
                "In-12": {
                    "questionId": "In-12",
                    "chosenOptionIds": [
                        "54e3b956-9bed-500e-8688-61a31c2cf50c"
                    ]
                },
                "In-13": {
                    "questionId": "In-13",
                    "chosenOptionIds": [
                        "bba6d73a-dbb7-5aa1-950c-e023e93bb6f5"
                    ]
                },
                "In-14": {
                    "questionId": "In-14",
                    "chosenOptionIds": [
                        "cc16c77f-e49a-5238-aa1d-e3cc4844a8b5"
                    ]
                },
                "In-15": {
                    "questionId": "In-15",
                    "chosenOptionIds": [
                        "768d36b2-99f6-52d5-828e-ac4aa7c1af98"
                    ]
                },
                "In-16": {
                    "questionId": "In-16",
                    "chosenOptionIds": [
                        "12c2ab31-e048-51b9-a607-06fc1a91c81c"
                    ]
                },
                "In-17": {
                    "questionId": "In-17",
                    "chosenOptionIds": [
                        "4b22dd2f-c083-5b47-89c2-536f0e141377"
                    ]
                },
                "In-18": {
                    "questionId": "In-18",
                    "chosenOptionIds": [
                        "7260a0f8-9cca-5761-b31d-bdcf0b07be65"
                    ]
                },
                "In-19": {
                    "questionId": "In-19",
                    "chosenOptionIds": [
                        "78bc4ce9-eede-5e71-8f2f-ccc07e727c3d"
                    ]
                },
                "In-2": {
                    "questionId": "In-2",
                    "chosenOptionIds": [
                        "28bb9c4d-6d74-5d8c-93fa-6bb1d018bccb"
                    ]
                },
                "In-20": {
                    "questionId": "In-20",
                    "chosenOptionIds": [
                        "bcc56784-ea59-5dc2-8c65-a2fe19434781",
                        "f80fac01-7377-5e9a-982b-74b3bd9b5608",
                        "9b4fae9f-9ea1-550c-9e62-f9575b443436",
                        "ad34db74-4106-57f8-9443-cd9820028a90",
                        "99ab10a1-a8ba-5aea-a09c-0dfd2031301e",
                        "262ab8d3-bffb-5952-92af-afb5b6c20cbf",
                        "0ebed11d-c85e-5b65-8421-c3b4feb3f213",
                        "a176e73d-22fd-541f-98df-d9dc34dc5748"
                    ]
                },
                "In-20.0": {
                    "questionId": "In-20.0",
                    "chosenOptionIds": [
                        "94fb5213-cd49-543d-a957-4b7cb2b0a384"
                    ]
                },
                "In-3": {
                    "questionId": "In-3",
                    "chosenOptionIds": [
                        "1f2fa891-03ee-520d-85f2-0effd7ed4929"
                    ]
                },
                "In-4": {
                    "questionId": "In-4",
                    "chosenOptionIds": [
                        "008a928e-e359-5012-9ab5-7f10f55cba4e"
                    ]
                },
                "In-5": {
                    "questionId": "In-5",
                    "chosenOptionIds": [
                        "4632255c-da2c-5ecd-8625-5a11c077f318"
                    ]
                },
                "In-7": {
                    "questionId": "In-7",
                    "chosenOptionIds": [
                        "9582d3c5-0593-5b97-8919-1a3c8ba4969f"
                    ]
                },
                "In-8": {
                    "questionId": "In-8",
                    "freeValue": 4
                },
                "In-9": {
                    "questionId": "In-9",
                    "chosenOptionIds": [
                        "8c6f7f1c-ae12-52b6-bb87-867f7be40803"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted from 75, Censeo vPrevious user: 4EGRG3P aspect: ins_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.95
            },
            "answers": {
                "In-1": {
                    "questionId": "In-1",
                    "chosenOptionIds": [
                        "aef2d8b9-cf9f-56c2-ad48-e1ebd632ca77"
                    ]
                },
                "In-10": {
                    "questionId": "In-10",
                    "freeValue": 3285
                },
                "In-11": {
                    "questionId": "In-11",
                    "chosenOptionIds": [
                        "93a6f63e-0a2a-56ab-8cb7-35aaa2472713"
                    ]
                },
                "In-12": {
                    "questionId": "In-12",
                    "chosenOptionIds": [
                        "54e3b956-9bed-500e-8688-61a31c2cf50c"
                    ]
                },
                "In-13": {
                    "questionId": "In-13",
                    "chosenOptionIds": [
                        "bba6d73a-dbb7-5aa1-950c-e023e93bb6f5"
                    ]
                },
                "In-14": {
                    "questionId": "In-14",
                    "chosenOptionIds": [
                        "66324493-9e40-5b6e-a1e2-218684881c4b"
                    ]
                },
                "In-15": {
                    "questionId": "In-15",
                    "chosenOptionIds": [
                        "768d36b2-99f6-52d5-828e-ac4aa7c1af98"
                    ]
                },
                "In-16": {
                    "questionId": "In-16",
                    "chosenOptionIds": [
                        "12c2ab31-e048-51b9-a607-06fc1a91c81c"
                    ]
                },
                "In-17": {
                    "questionId": "In-17",
                    "chosenOptionIds": [
                        "4b22dd2f-c083-5b47-89c2-536f0e141377"
                    ]
                },
                "In-18": {
                    "questionId": "In-18",
                    "chosenOptionIds": [
                        "7249e1aa-bac0-5e81-9189-841edcfa568c"
                    ]
                },
                "In-19": {
                    "questionId": "In-19",
                    "chosenOptionIds": [
                        "78bc4ce9-eede-5e71-8f2f-ccc07e727c3d"
                    ]
                },
                "In-2": {
                    "questionId": "In-2",
                    "chosenOptionIds": [
                        "3eac8bff-c7ef-590d-b79e-6a34771e0384"
                    ]
                },
                "In-20": {
                    "questionId": "In-20",
                    "chosenOptionIds": [
                        "0ebed11d-c85e-5b65-8421-c3b4feb3f213",
                        "262ab8d3-bffb-5952-92af-afb5b6c20cbf",
                        "99ab10a1-a8ba-5aea-a09c-0dfd2031301e",
                        "ad34db74-4106-57f8-9443-cd9820028a90",
                        "f80fac01-7377-5e9a-982b-74b3bd9b5608",
                        "9b4fae9f-9ea1-550c-9e62-f9575b443436",
                        "bcc56784-ea59-5dc2-8c65-a2fe19434781",
                        "a10b645d-916a-520c-a439-d5dfbd1d5465"
                    ]
                },
                "In-20.0": {
                    "questionId": "In-20.0",
                    "chosenOptionIds": [
                        "94fb5213-cd49-543d-a957-4b7cb2b0a384"
                    ]
                },
                "In-3": {
                    "questionId": "In-3",
                    "chosenOptionIds": [
                        "1fdf222e-20cd-55e5-84c5-fdb29cb8c10a"
                    ]
                },
                "In-4": {
                    "questionId": "In-4",
                    "chosenOptionIds": [
                        "b2ed5b66-1b5f-5103-905d-93cc7cc24b29"
                    ]
                },
                "In-5": {
                    "questionId": "In-5",
                    "chosenOptionIds": [
                        "0c2db685-4d19-5af4-a79f-b493e2f1b5dd"
                    ]
                },
                "In-7": {
                    "questionId": "In-7",
                    "chosenOptionIds": [
                        "9582d3c5-0593-5b97-8919-1a3c8ba4969f"
                    ]
                },
                "In-8": {
                    "questionId": "In-8",
                    "freeValue": 5
                },
                "In-9": {
                    "questionId": "In-9",
                    "chosenOptionIds": [
                        "f494b02f-9ac9-5ae9-94b9-aea01e773c36"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: 63YM43R aspect: ins_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "In-1": {
                    "questionId": "In-1",
                    "chosenOptionIds": [
                        "aef2d8b9-cf9f-56c2-ad48-e1ebd632ca77"
                    ]
                },
                "In-10": {
                    "questionId": "In-10",
                    "freeValue": 1825
                },
                "In-11": {
                    "questionId": "In-11",
                    "chosenOptionIds": [
                        "c6a59208-d315-55f8-bf9a-7858994cb083"
                    ]
                },
                "In-12": {
                    "questionId": "In-12",
                    "chosenOptionIds": [
                        "4ae113c2-5677-58b1-9a0f-bb90310fae83"
                    ]
                },
                "In-13": {
                    "questionId": "In-13",
                    "chosenOptionIds": [
                        "bba6d73a-dbb7-5aa1-950c-e023e93bb6f5"
                    ]
                },
                "In-14": {
                    "questionId": "In-14",
                    "chosenOptionIds": [
                        "1411fb90-e50a-5075-a1df-655689e3e23a"
                    ]
                },
                "In-15": {
                    "questionId": "In-15",
                    "chosenOptionIds": [
                        "eaa71b08-9994-5cd6-a747-dbc9c7028d41"
                    ]
                },
                "In-16": {
                    "questionId": "In-16",
                    "chosenOptionIds": [
                        "70cbb18e-77a3-51ec-8d9c-48d624956d47"
                    ]
                },
                "In-17": {
                    "questionId": "In-17",
                    "chosenOptionIds": [
                        "4b22dd2f-c083-5b47-89c2-536f0e141377"
                    ]
                },
                "In-18": {
                    "questionId": "In-18",
                    "chosenOptionIds": [
                        "8926a3e0-4939-57d9-ab63-21eaa6cdbfaf"
                    ]
                },
                "In-19": {
                    "questionId": "In-19",
                    "chosenOptionIds": [
                        "78bc4ce9-eede-5e71-8f2f-ccc07e727c3d"
                    ]
                },
                "In-2": {
                    "questionId": "In-2",
                    "chosenOptionIds": [
                        "3eac8bff-c7ef-590d-b79e-6a34771e0384"
                    ]
                },
                "In-20": {
                    "questionId": "In-20",
                    "chosenOptionIds": [
                        "bcc56784-ea59-5dc2-8c65-a2fe19434781",
                        "f80fac01-7377-5e9a-982b-74b3bd9b5608",
                        "9b4fae9f-9ea1-550c-9e62-f9575b443436",
                        "262ab8d3-bffb-5952-92af-afb5b6c20cbf"
                    ]
                },
                "In-20.0": {
                    "questionId": "In-20.0",
                    "chosenOptionIds": [
                        "6227fa46-142f-57ce-85d8-c558a73f98a8"
                    ]
                },
                "In-3": {
                    "questionId": "In-3",
                    "chosenOptionIds": [
                        "1f2fa891-03ee-520d-85f2-0effd7ed4929"
                    ]
                },
                "In-4": {
                    "questionId": "In-4",
                    "chosenOptionIds": [
                        "6356b6e1-c3d1-5625-921b-02ed5e7906f1"
                    ]
                },
                "In-5": {
                    "questionId": "In-5",
                    "chosenOptionIds": [
                        "4632255c-da2c-5ecd-8625-5a11c077f318"
                    ]
                },
                "In-7": {
                    "questionId": "In-7",
                    "chosenOptionIds": [
                        "d06953ca-22b8-51e0-bccd-e83b29c68f80"
                    ]
                },
                "In-8": {
                    "questionId": "In-8",
                    "freeValue": 6
                },
                "In-9": {
                    "questionId": "In-9",
                    "chosenOptionIds": [
                        "f494b02f-9ac9-5ae9-94b9-aea01e773c36"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: 63YM43R aspect: ins_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.72
            },
            "answers": {
                "In-1": {
                    "questionId": "In-1",
                    "chosenOptionIds": [
                        "aef2d8b9-cf9f-56c2-ad48-e1ebd632ca77"
                    ]
                },
                "In-10": {
                    "questionId": "In-10",
                    "freeValue": 1825
                },
                "In-11": {
                    "questionId": "In-11",
                    "chosenOptionIds": [
                        "c6a59208-d315-55f8-bf9a-7858994cb083"
                    ]
                },
                "In-12": {
                    "questionId": "In-12",
                    "chosenOptionIds": [
                        "4ae113c2-5677-58b1-9a0f-bb90310fae83"
                    ]
                },
                "In-13": {
                    "questionId": "In-13",
                    "chosenOptionIds": [
                        "bba6d73a-dbb7-5aa1-950c-e023e93bb6f5"
                    ]
                },
                "In-14": {
                    "questionId": "In-14",
                    "chosenOptionIds": [
                        "1411fb90-e50a-5075-a1df-655689e3e23a"
                    ]
                },
                "In-15": {
                    "questionId": "In-15",
                    "chosenOptionIds": [
                        "eaa71b08-9994-5cd6-a747-dbc9c7028d41"
                    ]
                },
                "In-16": {
                    "questionId": "In-16",
                    "chosenOptionIds": [
                        "70cbb18e-77a3-51ec-8d9c-48d624956d47"
                    ]
                },
                "In-17": {
                    "questionId": "In-17",
                    "chosenOptionIds": [
                        "4b22dd2f-c083-5b47-89c2-536f0e141377"
                    ]
                },
                "In-18": {
                    "questionId": "In-18",
                    "chosenOptionIds": [
                        "8926a3e0-4939-57d9-ab63-21eaa6cdbfaf"
                    ]
                },
                "In-19": {
                    "questionId": "In-19",
                    "chosenOptionIds": [
                        "78bc4ce9-eede-5e71-8f2f-ccc07e727c3d"
                    ]
                },
                "In-2": {
                    "questionId": "In-2",
                    "chosenOptionIds": [
                        "3eac8bff-c7ef-590d-b79e-6a34771e0384"
                    ]
                },
                "In-20": {
                    "questionId": "In-20",
                    "chosenOptionIds": [
                        "bcc56784-ea59-5dc2-8c65-a2fe19434781",
                        "f80fac01-7377-5e9a-982b-74b3bd9b5608",
                        "9b4fae9f-9ea1-550c-9e62-f9575b443436",
                        "262ab8d3-bffb-5952-92af-afb5b6c20cbf"
                    ]
                },
                "In-20.0": {
                    "questionId": "In-20.0",
                    "chosenOptionIds": [
                        "6227fa46-142f-57ce-85d8-c558a73f98a8"
                    ]
                },
                "In-3": {
                    "questionId": "In-3",
                    "chosenOptionIds": [
                        "1f2fa891-03ee-520d-85f2-0effd7ed4929"
                    ]
                },
                "In-4": {
                    "questionId": "In-4",
                    "chosenOptionIds": [
                        "6356b6e1-c3d1-5625-921b-02ed5e7906f1"
                    ]
                },
                "In-5": {
                    "questionId": "In-5",
                    "chosenOptionIds": [
                        "4632255c-da2c-5ecd-8625-5a11c077f318"
                    ]
                },
                "In-7": {
                    "questionId": "In-7",
                    "chosenOptionIds": [
                        "d06953ca-22b8-51e0-bccd-e83b29c68f80"
                    ]
                },
                "In-8": {
                    "questionId": "In-8",
                    "freeValue": 6
                },
                "In-9": {
                    "questionId": "In-9",
                    "chosenOptionIds": [
                        "f494b02f-9ac9-5ae9-94b9-aea01e773c36"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted from 51, Censeo vPrevious user: XE72M0L aspect: ins_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.46
            },
            "answers": {
                "In-1": {
                    "questionId": "In-1",
                    "chosenOptionIds": [
                        "a06e5af9-7423-5a52-9f90-b813c26565cd"
                    ]
                },
                "In-10": {
                    "questionId": "In-10",
                    "freeValue": 1095
                },
                "In-11": {
                    "questionId": "In-11",
                    "chosenOptionIds": [
                        "f1537ed3-6bcd-5fef-924c-804b5c18536a"
                    ]
                },
                "In-12": {
                    "questionId": "In-12",
                    "chosenOptionIds": [
                        "54e3b956-9bed-500e-8688-61a31c2cf50c"
                    ]
                },
                "In-13": {
                    "questionId": "In-13",
                    "chosenOptionIds": [
                        "bba6d73a-dbb7-5aa1-950c-e023e93bb6f5"
                    ]
                },
                "In-14": {
                    "questionId": "In-14",
                    "chosenOptionIds": [
                        "b5730e2c-5b65-5db2-8203-236ca3fabecf"
                    ]
                },
                "In-15": {
                    "questionId": "In-15",
                    "chosenOptionIds": [
                        "eaa71b08-9994-5cd6-a747-dbc9c7028d41"
                    ]
                },
                "In-16": {
                    "questionId": "In-16",
                    "chosenOptionIds": [
                        "12c2ab31-e048-51b9-a607-06fc1a91c81c"
                    ]
                },
                "In-17": {
                    "questionId": "In-17",
                    "chosenOptionIds": [
                        "85c708df-eaf0-5749-ab43-08470798a3f9"
                    ]
                },
                "In-18": {
                    "questionId": "In-18",
                    "chosenOptionIds": [
                        "8926a3e0-4939-57d9-ab63-21eaa6cdbfaf"
                    ]
                },
                "In-19": {
                    "questionId": "In-19",
                    "chosenOptionIds": [
                        "78bc4ce9-eede-5e71-8f2f-ccc07e727c3d"
                    ]
                },
                "In-2": {
                    "questionId": "In-2",
                    "chosenOptionIds": [
                        "3eac8bff-c7ef-590d-b79e-6a34771e0384"
                    ]
                },
                "In-20": {
                    "questionId": "In-20",
                    "chosenOptionIds": [
                        "bcc56784-ea59-5dc2-8c65-a2fe19434781",
                        "f80fac01-7377-5e9a-982b-74b3bd9b5608",
                        "9b4fae9f-9ea1-550c-9e62-f9575b443436",
                        "ad34db74-4106-57f8-9443-cd9820028a90",
                        "99ab10a1-a8ba-5aea-a09c-0dfd2031301e",
                        "a10b645d-916a-520c-a439-d5dfbd1d5465",
                        "262ab8d3-bffb-5952-92af-afb5b6c20cbf",
                        "0ebed11d-c85e-5b65-8421-c3b4feb3f213",
                        "a176e73d-22fd-541f-98df-d9dc34dc5748"
                    ]
                },
                "In-20.0": {
                    "questionId": "In-20.0",
                    "chosenOptionIds": [
                        "bdacf2ac-3084-55f2-9c3d-5f7a86fbc4ab"
                    ]
                },
                "In-3": {
                    "questionId": "In-3",
                    "chosenOptionIds": [
                        "9301153f-717c-5274-ba9d-30ef58506872"
                    ]
                },
                "In-4": {
                    "questionId": "In-4",
                    "chosenOptionIds": [
                        "9e719d2d-3882-57ee-b04f-54fad97dfe0c"
                    ]
                },
                "In-5": {
                    "questionId": "In-5",
                    "chosenOptionIds": [
                        "e1280b2b-4883-55d4-9fde-006cb7a9ff65"
                    ]
                },
                "In-7": {
                    "questionId": "In-7",
                    "chosenOptionIds": [
                        "d06953ca-22b8-51e0-bccd-e83b29c68f80"
                    ]
                },
                "In-8": {
                    "questionId": "In-8",
                    "freeValue": 10
                },
                "In-9": {
                    "questionId": "In-9",
                    "chosenOptionIds": [
                        "8c6f7f1c-ae12-52b6-bb87-867f7be40803"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: REOG402 aspect: ins_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "In-1": {
                    "questionId": "In-1",
                    "chosenOptionIds": [
                        "aef2d8b9-cf9f-56c2-ad48-e1ebd632ca77"
                    ]
                },
                "In-10": {
                    "questionId": "In-10",
                    "freeValue": 730
                },
                "In-11": {
                    "questionId": "In-11",
                    "chosenOptionIds": [
                        "c6a59208-d315-55f8-bf9a-7858994cb083"
                    ]
                },
                "In-12": {
                    "questionId": "In-12",
                    "chosenOptionIds": [
                        "4ae113c2-5677-58b1-9a0f-bb90310fae83"
                    ]
                },
                "In-13": {
                    "questionId": "In-13",
                    "chosenOptionIds": [
                        "bba6d73a-dbb7-5aa1-950c-e023e93bb6f5"
                    ]
                },
                "In-14": {
                    "questionId": "In-14",
                    "chosenOptionIds": [
                        "cc16c77f-e49a-5238-aa1d-e3cc4844a8b5"
                    ]
                },
                "In-15": {
                    "questionId": "In-15",
                    "chosenOptionIds": [
                        "eaa71b08-9994-5cd6-a747-dbc9c7028d41"
                    ]
                },
                "In-16": {
                    "questionId": "In-16",
                    "chosenOptionIds": [
                        "ab1bdce5-400a-567d-a0ff-92fe1fc099d2"
                    ]
                },
                "In-17": {
                    "questionId": "In-17",
                    "chosenOptionIds": [
                        "4b22dd2f-c083-5b47-89c2-536f0e141377"
                    ]
                },
                "In-18": {
                    "questionId": "In-18",
                    "chosenOptionIds": [
                        "7260a0f8-9cca-5761-b31d-bdcf0b07be65"
                    ]
                },
                "In-19": {
                    "questionId": "In-19",
                    "chosenOptionIds": [
                        "96213629-1187-52b2-9e7a-a0c851bcbe0e"
                    ]
                },
                "In-2": {
                    "questionId": "In-2",
                    "chosenOptionIds": [
                        "28bb9c4d-6d74-5d8c-93fa-6bb1d018bccb"
                    ]
                },
                "In-20": {
                    "questionId": "In-20",
                    "chosenOptionIds": [
                        "f80fac01-7377-5e9a-982b-74b3bd9b5608",
                        "9b4fae9f-9ea1-550c-9e62-f9575b443436",
                        "ad34db74-4106-57f8-9443-cd9820028a90",
                        "99ab10a1-a8ba-5aea-a09c-0dfd2031301e",
                        "262ab8d3-bffb-5952-92af-afb5b6c20cbf",
                        "bcc56784-ea59-5dc2-8c65-a2fe19434781"
                    ]
                },
                "In-20.0": {
                    "questionId": "In-20.0",
                    "chosenOptionIds": [
                        "94fb5213-cd49-543d-a957-4b7cb2b0a384"
                    ]
                },
                "In-3": {
                    "questionId": "In-3",
                    "chosenOptionIds": [
                        "1f2fa891-03ee-520d-85f2-0effd7ed4929"
                    ]
                },
                "In-4": {
                    "questionId": "In-4",
                    "chosenOptionIds": [
                        "9e719d2d-3882-57ee-b04f-54fad97dfe0c"
                    ]
                },
                "In-5": {
                    "questionId": "In-5",
                    "chosenOptionIds": [
                        "e1280b2b-4883-55d4-9fde-006cb7a9ff65"
                    ]
                },
                "In-7": {
                    "questionId": "In-7",
                    "chosenOptionIds": [
                        "0f9486d2-6653-5883-81af-6639d6b2864a"
                    ]
                },
                "In-8": {
                    "questionId": "In-8",
                    "freeValue": 6
                },
                "In-9": {
                    "questionId": "In-9",
                    "chosenOptionIds": [
                        "f494b02f-9ac9-5ae9-94b9-aea01e773c36"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: REOG402 aspect: ins_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.67
            },
            "answers": {
                "In-1": {
                    "questionId": "In-1",
                    "chosenOptionIds": [
                        "aef2d8b9-cf9f-56c2-ad48-e1ebd632ca77"
                    ]
                },
                "In-10": {
                    "questionId": "In-10",
                    "freeValue": 730
                },
                "In-11": {
                    "questionId": "In-11",
                    "chosenOptionIds": [
                        "c6a59208-d315-55f8-bf9a-7858994cb083"
                    ]
                },
                "In-12": {
                    "questionId": "In-12",
                    "chosenOptionIds": [
                        "4ae113c2-5677-58b1-9a0f-bb90310fae83"
                    ]
                },
                "In-13": {
                    "questionId": "In-13",
                    "chosenOptionIds": [
                        "bba6d73a-dbb7-5aa1-950c-e023e93bb6f5"
                    ]
                },
                "In-14": {
                    "questionId": "In-14",
                    "chosenOptionIds": [
                        "cc16c77f-e49a-5238-aa1d-e3cc4844a8b5"
                    ]
                },
                "In-15": {
                    "questionId": "In-15",
                    "chosenOptionIds": [
                        "eaa71b08-9994-5cd6-a747-dbc9c7028d41"
                    ]
                },
                "In-16": {
                    "questionId": "In-16",
                    "chosenOptionIds": [
                        "ab1bdce5-400a-567d-a0ff-92fe1fc099d2"
                    ]
                },
                "In-17": {
                    "questionId": "In-17",
                    "chosenOptionIds": [
                        "4b22dd2f-c083-5b47-89c2-536f0e141377"
                    ]
                },
                "In-18": {
                    "questionId": "In-18",
                    "chosenOptionIds": [
                        "7260a0f8-9cca-5761-b31d-bdcf0b07be65"
                    ]
                },
                "In-19": {
                    "questionId": "In-19",
                    "chosenOptionIds": [
                        "96213629-1187-52b2-9e7a-a0c851bcbe0e"
                    ]
                },
                "In-2": {
                    "questionId": "In-2",
                    "chosenOptionIds": [
                        "28bb9c4d-6d74-5d8c-93fa-6bb1d018bccb"
                    ]
                },
                "In-20": {
                    "questionId": "In-20",
                    "chosenOptionIds": [
                        "f80fac01-7377-5e9a-982b-74b3bd9b5608",
                        "9b4fae9f-9ea1-550c-9e62-f9575b443436",
                        "ad34db74-4106-57f8-9443-cd9820028a90",
                        "99ab10a1-a8ba-5aea-a09c-0dfd2031301e",
                        "262ab8d3-bffb-5952-92af-afb5b6c20cbf",
                        "bcc56784-ea59-5dc2-8c65-a2fe19434781"
                    ]
                },
                "In-20.0": {
                    "questionId": "In-20.0",
                    "chosenOptionIds": [
                        "94fb5213-cd49-543d-a957-4b7cb2b0a384"
                    ]
                },
                "In-3": {
                    "questionId": "In-3",
                    "chosenOptionIds": [
                        "1f2fa891-03ee-520d-85f2-0effd7ed4929"
                    ]
                },
                "In-4": {
                    "questionId": "In-4",
                    "chosenOptionIds": [
                        "9e719d2d-3882-57ee-b04f-54fad97dfe0c"
                    ]
                },
                "In-5": {
                    "questionId": "In-5",
                    "chosenOptionIds": [
                        "e1280b2b-4883-55d4-9fde-006cb7a9ff65"
                    ]
                },
                "In-7": {
                    "questionId": "In-7",
                    "chosenOptionIds": [
                        "0f9486d2-6653-5883-81af-6639d6b2864a"
                    ]
                },
                "In-8": {
                    "questionId": "In-8",
                    "freeValue": 6
                },
                "In-9": {
                    "questionId": "In-9",
                    "chosenOptionIds": [
                        "f494b02f-9ac9-5ae9-94b9-aea01e773c36"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted from 52, Censeo vPrevious user: R047J04 aspect: ins_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.47
            },
            "answers": {
                "In-1": {
                    "questionId": "In-1",
                    "chosenOptionIds": [
                        "aef2d8b9-cf9f-56c2-ad48-e1ebd632ca77"
                    ]
                },
                "In-10": {
                    "questionId": "In-10",
                    "freeValue": 5475
                },
                "In-11": {
                    "questionId": "In-11",
                    "chosenOptionIds": [
                        "93a6f63e-0a2a-56ab-8cb7-35aaa2472713"
                    ]
                },
                "In-12": {
                    "questionId": "In-12",
                    "chosenOptionIds": [
                        "54e3b956-9bed-500e-8688-61a31c2cf50c"
                    ]
                },
                "In-13": {
                    "questionId": "In-13",
                    "chosenOptionIds": [
                        "bba6d73a-dbb7-5aa1-950c-e023e93bb6f5"
                    ]
                },
                "In-14": {
                    "questionId": "In-14",
                    "chosenOptionIds": [
                        "66324493-9e40-5b6e-a1e2-218684881c4b"
                    ]
                },
                "In-15": {
                    "questionId": "In-15",
                    "chosenOptionIds": [
                        "eaa71b08-9994-5cd6-a747-dbc9c7028d41"
                    ]
                },
                "In-16": {
                    "questionId": "In-16",
                    "chosenOptionIds": [
                        "70cbb18e-77a3-51ec-8d9c-48d624956d47"
                    ]
                },
                "In-17": {
                    "questionId": "In-17",
                    "chosenOptionIds": [
                        "4b22dd2f-c083-5b47-89c2-536f0e141377"
                    ]
                },
                "In-18": {
                    "questionId": "In-18",
                    "chosenOptionIds": [
                        "7260a0f8-9cca-5761-b31d-bdcf0b07be65"
                    ]
                },
                "In-19": {
                    "questionId": "In-19",
                    "chosenOptionIds": [
                        "78bc4ce9-eede-5e71-8f2f-ccc07e727c3d"
                    ]
                },
                "In-2": {
                    "questionId": "In-2",
                    "chosenOptionIds": [
                        "7a5cbb84-cf16-51e0-84b7-e4723c916836"
                    ]
                },
                "In-20": {
                    "questionId": "In-20",
                    "chosenOptionIds": [
                        "bcc56784-ea59-5dc2-8c65-a2fe19434781",
                        "f80fac01-7377-5e9a-982b-74b3bd9b5608",
                        "9b4fae9f-9ea1-550c-9e62-f9575b443436",
                        "99ab10a1-a8ba-5aea-a09c-0dfd2031301e",
                        "262ab8d3-bffb-5952-92af-afb5b6c20cbf",
                        "0ebed11d-c85e-5b65-8421-c3b4feb3f213",
                        "a176e73d-22fd-541f-98df-d9dc34dc5748"
                    ]
                },
                "In-20.0": {
                    "questionId": "In-20.0",
                    "chosenOptionIds": [
                        "6227fa46-142f-57ce-85d8-c558a73f98a8"
                    ]
                },
                "In-3": {
                    "questionId": "In-3",
                    "chosenOptionIds": [
                        "1f2fa891-03ee-520d-85f2-0effd7ed4929"
                    ]
                },
                "In-4": {
                    "questionId": "In-4",
                    "chosenOptionIds": [
                        "008a928e-e359-5012-9ab5-7f10f55cba4e"
                    ]
                },
                "In-5": {
                    "questionId": "In-5",
                    "chosenOptionIds": [
                        "4632255c-da2c-5ecd-8625-5a11c077f318"
                    ]
                },
                "In-7": {
                    "questionId": "In-7",
                    "chosenOptionIds": [
                        "89ae5db3-64e0-583c-9851-2bb2aca93eed"
                    ]
                },
                "In-8": {
                    "questionId": "In-8",
                    "freeValue": 5
                },
                "In-9": {
                    "questionId": "In-9",
                    "chosenOptionIds": [
                        "f494b02f-9ac9-5ae9-94b9-aea01e773c36"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {
    "In-2": "not(In-1=0)",
    "In-3": "not(In-1=0)",
    "In-4": "not(In-1=0)",
    "In-5": "not(In-1=0)",
    "In-7": "not(In-1=0)",
    "In-8": "not(In-1=0)",
    "In-9": "not(In-1=0)",
    "In-10": "not(In-1=0)",
    "In-11": "not(In-1=0)",
    "In-12": "not(In-1=0)",
    "In-13": "not(In-1=0)",
    "In-14": "not(In-1=0)",
    "In-15": "not(In-1=0)",
    "In-16": "not(In-1=0)",
    "In-17": "not(In-1=0)",
    "In-18": "not(In-1=0)",
    "In-19": "not(In-1=0)",
    "In-20": "not(In-1=0)",
    "In-20.0": "and[or[isUndefined(In20_none=1), not(In20_none=1)], not(isUndefined(In-20=0))]"
};
