"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
exports.questions = [
    {
        "__typename": "ContentQuestion",
        "id": "DIALOG-content-1",
        "text": "Welcome. We'd like to understand how different areas of your life are affecting you. \n\nWe'll start with some straightforward questions about your day-to-day life. For each area, please rate your level of satisfaction. \n\nThere are no right or wrong answers - we just want to understand your experience. \n\nSelect 'Continue' to start.",
        "answers": [
            {
                "__typename": "Option",
                "id": "3a4226cc-8244-43d5-9cce-b5f2f5be65cb",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "c31eed7a-ba3b-485b-a2ff-6114a958092c",
                "text": "Totally dissatisfied",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "e0672ad5-cb59-45d8-806d-7fcc5e382300",
                "text": "Very dissatisfied",
                "value": 0.1666666666666666
            },
            {
                "__typename": "Option",
                "id": "5fb868a1-7a43-4a78-98f1-01499d3f4484",
                "text": "Fairly dissatisfied",
                "value": 0.3333333333333333
            },
            {
                "__typename": "Option",
                "id": "6e866b91-c07d-455c-85d3-987758cf8943",
                "text": "In the middle",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "19a5c0a7-46a5-4112-abde-85742be9b163",
                "text": "Fairly satisfied",
                "value": 0.6666666666666666
            },
            {
                "__typename": "Option",
                "id": "0b9dc957-a262-4101-8a4b-d9c777ca42ad",
                "text": "Very satisfied",
                "value": 0.8333333333333333
            },
            {
                "__typename": "Option",
                "id": "8c9719a4-39fb-4f34-8ac8-b0a7e23aaafe",
                "text": "Totally satisfied",
                "value": 1
            }
        ],
        "id": "DIALOG-1",
        "text": "How satisfied are you with your mental health?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "38135e83-ef64-4c56-884c-d1682f5f76ab",
                "text": "Totally dissatisfied",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "881edab5-0a3d-49c8-82d4-ca7dd057101d",
                "text": "Very dissatisfied",
                "value": 0.1666666666666666
            },
            {
                "__typename": "Option",
                "id": "ef40b07c-274b-472b-b1ea-983785d92d0e",
                "text": "Fairly dissatisfied",
                "value": 0.3333333333333333
            },
            {
                "__typename": "Option",
                "id": "7b3877a7-cb53-4697-9415-56e22a5b139a",
                "text": "In the middle",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "045198f9-799f-4e25-a04e-94e8c75b50ec",
                "text": "Fairly satisfied",
                "value": 0.6666666666666666
            },
            {
                "__typename": "Option",
                "id": "af487b23-e039-464f-a722-6cbdf2c5ca50",
                "text": "Very satisfied",
                "value": 0.8333333333333333
            },
            {
                "__typename": "Option",
                "id": "00696be6-140b-48f2-997b-0796cac796c4",
                "text": "Totally satisfied",
                "value": 1
            }
        ],
        "id": "DIALOG-2",
        "text": "How satisfied are you with your physical health?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "d1ae4cc9-7540-457e-a7a5-358fb1c81c94",
                "text": "Totally dissatisfied",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "5c54c47c-4eab-41eb-8251-0c1aba41f078",
                "text": "Very dissatisfied",
                "value": 0.1666666666666666
            },
            {
                "__typename": "Option",
                "id": "fcdea99f-24d8-40e8-ad5d-62d94b0aa803",
                "text": "Fairly dissatisfied",
                "value": 0.3333333333333333
            },
            {
                "__typename": "Option",
                "id": "f16757d7-0a94-4840-b8aa-a13d1fa02fc6",
                "text": "In the middle",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "b1dc5bce-f4cd-4705-a313-83bdcaf68f6a",
                "text": "Fairly satisfied",
                "value": 0.6666666666666666
            },
            {
                "__typename": "Option",
                "id": "56a11ace-2077-4de0-82f4-5af623fc8be2",
                "text": "Very satisfied",
                "value": 0.8333333333333333
            },
            {
                "__typename": "Option",
                "id": "e058a433-15a5-46e4-939e-7e2fddd1b857",
                "text": "Totally satisfied",
                "value": 1
            }
        ],
        "id": "DIALOG-3",
        "text": "How satisfied are you with your job situation?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "d47b2e34-ce8a-4189-b5a8-e35d635069d5",
                "text": "Totally dissatisfied",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b312d87b-a11d-4bdf-acfc-fc3404103ac9",
                "text": "Very dissatisfied",
                "value": 0.1666666666666666
            },
            {
                "__typename": "Option",
                "id": "ee1bb0a8-d319-4d83-9b41-1271d8ddbc7c",
                "text": "Fairly dissatisfied",
                "value": 0.3333333333333333
            },
            {
                "__typename": "Option",
                "id": "9549354c-91b7-4af8-bef8-23f6096ad374",
                "text": "In the middle",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "bca995fe-dea9-4d1d-a220-f1861b4f203b",
                "text": "Fairly satisfied",
                "value": 0.6666666666666666
            },
            {
                "__typename": "Option",
                "id": "daef6350-fe3d-417d-9573-44f0979ef38d",
                "text": "Very satisfied",
                "value": 0.8333333333333333
            },
            {
                "__typename": "Option",
                "id": "5217d3bf-8869-4afe-9ae2-c15416cd6627",
                "text": "Totally satisfied",
                "value": 1
            }
        ],
        "id": "DIALOG-4",
        "text": "How satisfied are you with your accommodation?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ca5dae1b-e701-471f-8a4a-f9e9b9daa406",
                "text": "Totally dissatisfied",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "31d8c15a-981c-4d19-ad59-fbac7190c7f5",
                "text": "Very dissatisfied",
                "value": 0.1666666666666666
            },
            {
                "__typename": "Option",
                "id": "0c00275b-f702-48be-9052-6df3864547f4",
                "text": "Fairly dissatisfied",
                "value": 0.3333333333333333
            },
            {
                "__typename": "Option",
                "id": "3e11e87c-85c7-4628-8c25-b302f836f0d8",
                "text": "In the middle",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "5f2ade94-7162-4e4c-b7e1-d5f66c332331",
                "text": "Fairly satisfied",
                "value": 0.6666666666666666
            },
            {
                "__typename": "Option",
                "id": "66860de1-78c1-45f4-9484-e4116be23424",
                "text": "Very satisfied",
                "value": 0.8333333333333333
            },
            {
                "__typename": "Option",
                "id": "870ab283-6318-460d-ba45-74813a25933e",
                "text": "Totally satisfied",
                "value": 1
            }
        ],
        "id": "DIALOG-5",
        "text": "How satisfied are you with your leisure activities?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "62352937-8683-44cc-a8ae-4df8feae9a23",
                "text": "Totally dissatisfied",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "0a9ed2d3-4419-4b7c-afcb-42fcb881e8d1",
                "text": "Very dissatisfied",
                "value": 0.1666666666666666
            },
            {
                "__typename": "Option",
                "id": "62f1e983-e735-4564-80c6-ff3709986528",
                "text": "Fairly dissatisfied",
                "value": 0.3333333333333333
            },
            {
                "__typename": "Option",
                "id": "e9809f4f-fb4e-4f60-8b59-bfcabae4d173",
                "text": "In the middle",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "370c1124-74db-42b7-8c7a-627a662cc2d7",
                "text": "Fairly satisfied",
                "value": 0.6666666666666666
            },
            {
                "__typename": "Option",
                "id": "7b91ba25-52e3-46cc-996a-4067c9139594",
                "text": "Very satisfied",
                "value": 0.8333333333333333
            },
            {
                "__typename": "Option",
                "id": "023dfe31-fb83-4c26-a3b7-105942076905",
                "text": "Totally satisfied",
                "value": 1
            }
        ],
        "id": "DIALOG-6",
        "text": "How satisfied are you with your relationship with your partner/family?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "77f974b0-64d6-474a-9259-0f6df51a69aa",
                "text": "Totally dissatisfied",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "bcc97a09-1ee0-4d11-8c5c-34df241b64bd",
                "text": "Very dissatisfied",
                "value": 0.1666666666666666
            },
            {
                "__typename": "Option",
                "id": "252fe100-c636-4818-8799-2c47c6bcffec",
                "text": "Fairly dissatisfied",
                "value": 0.3333333333333333
            },
            {
                "__typename": "Option",
                "id": "7b3001df-455b-421f-b944-7c212d0ed19a",
                "text": "In the middle",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "4e72848e-0ea4-41fb-84ff-1c43856bac10",
                "text": "Fairly satisfied",
                "value": 0.6666666666666666
            },
            {
                "__typename": "Option",
                "id": "903abd19-16df-407a-9d3f-376e231b249f",
                "text": "Very satisfied",
                "value": 0.8333333333333333
            },
            {
                "__typename": "Option",
                "id": "1a77f97d-af7a-433c-9ed7-67818eadd47b",
                "text": "Totally satisfied",
                "value": 1
            }
        ],
        "id": "DIALOG-7",
        "text": "How satisfied are you with your friendships?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "9b007e07-69a3-4169-af44-ef3fd85da32a",
                "text": "Totally dissatisfied",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "8f582dd9-9655-45f5-b224-529297629e6f",
                "text": "Very dissatisfied",
                "value": 0.1666666666666666
            },
            {
                "__typename": "Option",
                "id": "8a27db93-8aaf-405a-8134-a61129b2f35f",
                "text": "Fairly dissatisfied",
                "value": 0.3333333333333333
            },
            {
                "__typename": "Option",
                "id": "10cfcfc7-6f3f-42c4-9ce2-0ab802d5740f",
                "text": "In the middle",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "b36df1c0-ef5f-4e0d-b9b3-d232a91d3b2e",
                "text": "Fairly satisfied",
                "value": 0.6666666666666666
            },
            {
                "__typename": "Option",
                "id": "fd10a6f0-d2d6-401f-aec0-8b2aad0eb5a1",
                "text": "Very satisfied",
                "value": 0.8333333333333333
            },
            {
                "__typename": "Option",
                "id": "0b87412b-397c-4fe6-9577-8449ea8dcb1e",
                "text": "Totally satisfied",
                "value": 1
            }
        ],
        "id": "DIALOG-8",
        "text": "How satisfied are you with your personal safety?",
        "isMultiselect": false
    },
    {
        "__typename": "ContentQuestion",
        "id": "DIALOG-content-2",
        "text": "Thank you for answering those 8 questions about your life. \n\nThe next questions are about your experience of any treatment or help you are currently receiving. \n\nAgain, there is no right or wrong answer, so please try to answer as honestly as you can. \n\nIf the question does not apply to you please state so. \n\nSelect 'Continue' to start.",
        "answers": [
            {
                "__typename": "Option",
                "id": "7f4bc6d2-9188-4abd-ac22-70cfcd6663f7",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "f9242a69-47b7-417f-ae27-f48830b5b061",
                "text": "Totally dissatisfied",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "4d74ff9e-bc3e-43b6-a4ee-809fdcc91427",
                "text": "Very dissatisfied",
                "value": 0.1666666666666666
            },
            {
                "__typename": "Option",
                "id": "70090e82-54f6-46ad-ba69-d6d34a1954d7",
                "text": "Fairly dissatisfied",
                "value": 0.3333333333333333
            },
            {
                "__typename": "Option",
                "id": "80340380-7d63-4501-a67c-0af959ca9dbc",
                "text": "In the middle",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "c2b850a4-73d5-444d-a653-efc0f4966ced",
                "text": "Fairly satisfied",
                "value": 0.6666666666666666
            },
            {
                "__typename": "Option",
                "id": "ffd8bc15-0657-4824-afce-1112c4f3e504",
                "text": "Very satisfied",
                "value": 0.8333333333333333
            },
            {
                "__typename": "Option",
                "id": "8dfbf95a-0ba6-46fc-9805-667ba62d181b",
                "text": "Totally satisfied",
                "value": 1
            }
        ],
        "id": "DIALOG-9",
        "text": "How satisfied are you with your medication?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "7f56bc6d-86a6-4bdc-92da-25491fefdb19",
                "text": "Totally dissatisfied",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "85461eb6-73ec-4fad-9ae0-c34e4ae7c3b0",
                "text": "Very dissatisfied",
                "value": 0.1666666666666666
            },
            {
                "__typename": "Option",
                "id": "2e6b50ea-7c9b-4e0f-9594-e4388b92ab45",
                "text": "Fairly dissatisfied",
                "value": 0.3333333333333333
            },
            {
                "__typename": "Option",
                "id": "a0bccf61-bfc6-4c6e-b1f6-e920812d57c6",
                "text": "In the middle",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "f22b7f22-da3d-4130-8f02-1bd3e6c21e70",
                "text": "Fairly satisfied",
                "value": 0.6666666666666666
            },
            {
                "__typename": "Option",
                "id": "2c73eebb-e18a-4219-86fc-43c130036874",
                "text": "Very satisfied",
                "value": 0.8333333333333333
            },
            {
                "__typename": "Option",
                "id": "f98d705f-7d30-48f8-ad31-a3908007717e",
                "text": "Totally satisfied",
                "value": 1
            }
        ],
        "id": "DIALOG-10",
        "text": "How satisfied are you with the practical help you receive?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "3e159433-02e1-45fd-80ef-9d548bb61be5",
                "text": "Totally dissatisfied",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c157f278-5953-41ea-b719-5245a366399d",
                "text": "Very dissatisfied",
                "value": 0.1666666666666666
            },
            {
                "__typename": "Option",
                "id": "2ed78689-de93-4f52-a123-27822a9a14ed",
                "text": "Fairly dissatisfied",
                "value": 0.3333333333333333
            },
            {
                "__typename": "Option",
                "id": "01ff7444-3d21-46c5-895d-1bc2eaca23f3",
                "text": "In the middle",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "3851e5b5-d5c9-473a-bb7d-2e86daaa4b20",
                "text": "Fairly satisfied",
                "value": 0.6666666666666666
            },
            {
                "__typename": "Option",
                "id": "cae43568-e190-4dd4-a645-8d54619651d2",
                "text": "Very satisfied",
                "value": 0.8333333333333333
            },
            {
                "__typename": "Option",
                "id": "a05f65ec-4377-406e-b444-c8a4c21c59a1",
                "text": "Totally satisfied",
                "value": 1
            }
        ],
        "id": "DIALOG-11",
        "text": "How satisfied are you with your meetings with mental health professionals?",
        "isMultiselect": false
    }
];
exports.aspects = {
    "dialog": {
        "type": "static"
    }
};
exports.tests = {};
exports.questionPredicates = {};
