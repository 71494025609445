"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "{}",
            "functionId": "ScoffSeverity"
        },
        "section": [
            "Outcome"
        ]
    },
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "{}",
            "functionId": "ScoffScoringKey"
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "1. Do you make yourself sick because you feel uncomfortably full? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SCOFF-1",
                    "optionSummaries": {
                        "e0eaa4cd-5620-4c33-928d-d519aa08246e": "0 - No",
                        "bd562ae7-f6a5-4c90-986e-d7a0775b33fd": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "2. Do you worry you have lost control over how much you eat? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SCOFF-2",
                    "optionSummaries": {
                        "ca3d2d61-a99e-4aef-a143-28dcf7a11b74": "0 - No",
                        "dec92aba-a555-4bdc-9f85-4301c852792b": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "3. Have you recently lost more than one stone (6.35 kg) in a three-month period? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SCOFF-3",
                    "optionSummaries": {
                        "ea0da749-e9e1-4860-bdaa-d55efb821662": "0 - No",
                        "afae9912-278f-49a0-9997-86ad98f4de9f": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "4. Do you believe yourself to be fat when others say you are too thin? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SCOFF-4",
                    "optionSummaries": {
                        "9290f8d3-d0cf-4c49-bc1f-26724c6e4320": "0 - No",
                        "cd806bc9-53ec-4f38-aecf-123ce260a0ba": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "5. Would you say food dominates your life? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SCOFF-5",
                    "optionSummaries": {
                        "0c6d4a9d-fcd5-478e-80e2-379b93846c94": "0 - No",
                        "b92959b6-7f31-4e8e-91e8-17b2373ca25c": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "6. Are you satisfied with your eating patterns? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SCOFF-6",
                    "optionSummaries": {
                        "90ccaf6a-7abd-45cd-a09e-bd369f072f43": "0 - No",
                        "14a5d540-2c89-4d9a-a52a-c31c85c3ac9b": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "7. Do you ever eat in secret? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SCOFF-7",
                    "optionSummaries": {
                        "d5d2c703-05fb-4a50-b5b5-ed1bbf7355b1": "0 - No",
                        "934e2d1b-3d74-46a7-8da4-f6a7566f777a": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    }
];
