import React, { useContext, useState } from 'react'
import { Heading, Text, Button, Input } from '@psyomics/components'
import Content from '../../../components/Content'
import Field from '../../../components/Field'
import Label from '../../../components/Label'
import Validation from '../../../components/Validation'
import Styles from './Name.module.scss'
import { useRegistration } from 'registration/context/registration/useRegistration'
import { useReportingApi } from 'ui/app/reporting'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import { UserType } from 'censeo-core'
import { getYourNameMessage } from 'registration/utils/messages'
import { OnboardingFormProps } from 'registration/pages/Onboarding/use-Onboarding'
import { ReferralDetails } from 'ui/app/context/referralDetails'

type NameProps = OnboardingFormProps & {
    referralDetails: ReferralDetails
}

const Name: React.FC<NameProps> = ({
    values,
    errors,
    touched,
    onChange,
    onBlur,
    validateForm,
    setPageState,
    referralDetails,
}) => {
    const [nextPressed, setNextPressed] = useState(false)
    const { state } = useRegistration()
    const reporting = useReportingApi()
    const mixpanelApiUrl = useContext(MixpanelContext)
    const { nameEntered } = useMixpanelEvent(mixpanelApiUrl)

    const isD2CUser = state?.userType === UserType.D2C // TODO: raise bug as state is no longer working after the registration was moved to onboard (postsignup)
    const showNHSRelatedText = !referralDetails.activeReferral?.organisationConfig?.omitNhsLookup

    const handleSubmit = async () => {
        reporting?.insertedName(state?.referralCode)
        nameEntered(
            referralDetails.activeReferral?.organisationCode,
            referralDetails.activeReferral?.organisationName,
            referralDetails.activeReferral?.publicId
        )
        setPageState('postcode')
    }

    return (
        <Content>
            <div className={Styles.intro}>
                <Heading el="h1" size="heading2">
                    Your name
                </Heading>
                <Text el="p" size="medium">
                    {getYourNameMessage(isD2CUser, showNHSRelatedText)}
                </Text>
            </div>
            <div className={Styles.form}>
                <fieldset className={Styles.fieldset}>
                    <Field>
                        <Label appearance="secondary" htmlFor="firstName">
                            First name
                        </Label>
                        <Input
                            data-private="lipsum"
                            type="text"
                            autoComplete="given-name"
                            id="firstName"
                            name="firstName"
                            placeholder="First name"
                            appearance="outlined"
                            invalid={!!(errors.firstName && touched.firstName)}
                            onChange={onChange}
                            onKeyUp={onChange}
                            onBlur={onBlur}
                            value={values.firstName}
                        />
                        {nextPressed && errors.firstName ? <Validation>{errors.firstName}</Validation> : null}
                    </Field>
                    <Field>
                        <Label appearance="secondary" htmlFor="lastName">
                            Last name
                        </Label>
                        <Input
                            data-private="lipsum"
                            type="text"
                            autoComplete="family-name"
                            id="lastName"
                            name="lastName"
                            placeholder="Last name"
                            appearance="outlined"
                            invalid={!!(errors.lastName && touched.lastName)}
                            onChange={onChange}
                            onKeyUp={onChange}
                            onBlur={onBlur}
                            value={values.lastName}
                        />
                        {nextPressed && errors.lastName ? <Validation>{errors.lastName}</Validation> : null}
                    </Field>
                </fieldset>
            </div>
            <div className={Styles.action}>
                <Button
                    type="button"
                    name="Next"
                    appearance="primary"
                    fullWidth
                    size="large"
                    label="Next"
                    disabled={!values.firstName || !values.lastName}
                    onClick={async () => {
                        setNextPressed(true)
                        const newErrors = await validateForm?.()

                        if (!newErrors?.lastName && !newErrors?.firstName) {
                            handleSubmit()
                            setPageState('postcode')
                        }
                    }}
                />
            </div>
        </Content>
    )
}

export default Name
