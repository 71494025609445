import React, { useContext, useState } from 'react'
import { Text, Button } from '@psyomics/components'
import { OnboardingFormProps } from '../use-Onboarding'
import RadioGroup from 'registration/components/RadioGroup/RadioGroup'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import { ReferralDetails } from 'ui/app/context/referralDetails'

export enum SexOptions {
    Female = 'Female',
    Male = 'Male',
    Other = 'Other',
    NotRecorded = 'Not recorded',
}

type IOnboarding = OnboardingFormProps & { referralDetails: ReferralDetails } & React.ComponentPropsWithoutRef<'div'>

const Sex: React.FC<IOnboarding> = ({ values, errors, onChange, validateForm, setPageState, referralDetails }) => {
    const [showErrors, setShowErrors] = useState(false)
    const mixpanelApiUrl = useContext(MixpanelContext)
    const { sexEntered } = useMixpanelEvent(mixpanelApiUrl)
    const showNHSRelatedText = !referralDetails.activeReferral?.organisationConfig.omitNhsLookup

    return (
        <>
            <Text color="mid" css={{ mb: 4 }}>
                In order to improve your experience we&#39;d like to find out a little more about you.
            </Text>
            <RadioGroup
                groupName="sex"
                label={`What is your sex? ${showNHSRelatedText ? '(as on your NHS record)' : ''}`}
                value={values.sex}
                onChange={onChange}
                displayOr={showNHSRelatedText}
                options={Object.keys(SexOptions)
                    .filter((key) => {
                        if (!showNHSRelatedText && key === 'NotRecorded') {
                            return false
                        }
                        return true
                    })
                    .map((m) => ({
                        label: (SexOptions as any)[m],
                        value: m,
                    }))}
                error={showErrors ? errors.sex : undefined}
            />
            <Button
                type="submit"
                appearance="primary"
                fullWidth
                size="large"
                css={{ mt: 6 }}
                label="Next"
                onClick={async () => {
                    const errors = await validateForm()
                    setShowErrors(true)
                    if (!errors.sex) {
                        sexEntered(
                            referralDetails?.activeReferral?.organisationCode,
                            referralDetails?.activeReferral?.organisationName,
                            values.sex,
                            referralDetails?.activeReferral?.publicId
                        )
                        setPageState('gender')
                    }
                }}
            />
        </>
    )
}

export default Sex
