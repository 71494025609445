"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
exports.questions = [
    {
        "__typename": "ContentQuestion",
        "id": "OCI_R-1",
        "text": "The following statements refer to experiences that many people have in their everyday lives.\nSelect the option that best describes how much that experience has distressed or bothered you during the PAST MONTH.",
        "answers": [
            {
                "__typename": "Option",
                "id": "e2432e4e-4561-4550-87e2-cdb41f85afa8",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "13b7f302-0fd7-4d1b-aef0-c0497bddbc6c",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7646ef49-0c23-4516-b132-02cf44dac3cd",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "565e2012-036d-4dc3-a0a3-96d11f9dc70c",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "457bde62-7b9d-4e6b-b8fd-3c47edd3a1fc",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "fb74ba05-4489-48e4-911a-8c58c77bba91",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI_R-2",
        "text": "I have saved up so many things that they get in the way (in the past month)",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "bab86586-0ccd-492a-9a75-6c2f22e86ab3",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "296e536f-bbe5-4994-a34d-0a89b3490c9a",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "832bcab2-d39e-4198-858b-3788cc30908b",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "2e9c8242-9de5-4450-aa3d-635e9dca03af",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "9bf9a461-5817-446e-9ec5-d8e9cebab9a7",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI_R-3",
        "text": "I check things more often than necessary (in the past month)",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "38e7ac14-8a29-45cc-8a49-0003921d119f",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f6f2fcd1-0a03-44f7-99af-576535ee0a1c",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "4b625451-7b24-4dcd-9cfa-851fd3d9b194",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "48ec0abf-b682-49ac-b604-b70d72056f5f",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "5a13fc6a-f5fa-4fdc-86a0-9cfd86414dd9",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI_R-4",
        "text": "I get upset if objects are not arranged properly (in the past month)",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6961b86d-57da-4148-91f2-b48c56f8c7ea",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7fe921e0-c1e4-4cf0-80d2-1f221159cfd8",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "b1a1116e-0455-460e-b937-21b7c4b9480b",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "0d4624bc-9565-4a2c-bf98-f0199362ef67",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "9aa037e0-e1bf-442d-903b-0d521c3b16df",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI_R-5",
        "text": "I feel compelled to count while I am doing things (in the past month)",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "44d85fc8-1f8c-45c9-9dcb-c8ee1caf8f6d",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "cc024879-36e8-4bac-87a3-7b492d9de8cb",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "fc754e30-1dbe-4b5e-b05d-bb334ee4e347",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "d7ec3936-d183-4833-8d80-f4399d255d77",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "698cde41-b06a-49be-99ba-21016759d37c",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI_R-6",
        "text": "I find it difficult to touch an object when I know it has been touched by strangers or certain people (in the past month)",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "90a9b18e-f6ed-476e-b00a-913409cfab60",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "9af9b95c-c26c-4dcb-adb4-8c1610ef15e5",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "02834643-1011-4d82-8359-21e828d9bc23",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "a93ea17c-0ac4-4271-9f85-710a24409e58",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "2fec116c-335a-4da7-9153-57d4cd8438b9",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI_R-7",
        "text": "I find it difficult to control my own thoughts (in the past month)",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "24b2f7ab-2222-415e-a43b-5f35417f18d7",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "51f596f5-f310-4d28-b0b6-0640a72adb0b",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "560ad5d8-9025-4df9-9617-d43ad39f1d1b",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "1890db87-c398-44fd-a39a-99ac170add80",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "17e2f02e-b299-49a9-8c87-f7fbf055262c",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI_R-8",
        "text": "I collect things I don't need (in the past month)",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8a57a734-2634-4b0f-97a1-15e375985fa7",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "427172e3-78e7-430c-baca-369391a130e1",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "342d2fc0-250a-4eb2-8e6d-6e1fd70d4558",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "6c8556a1-72bd-4892-86b6-e395fde62cb7",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "c8b942c7-c9ce-42b8-8172-41c7ed29a2c3",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI_R-9",
        "text": "I repeatedly check doors, windows, drawers, etc (in the past month)",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e3bdb0a8-d4b2-4b48-9840-08e7a84e04ed",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "847f2166-d531-4a51-b3db-e383b870549b",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "0982d4d0-4381-4bbe-8005-5fa5edab4053",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "d26c22f4-ae63-414b-a2a9-b34eba404940",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "16baad7b-fc4a-4f05-9905-179ae60659d7",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI_R-10",
        "text": "I get upset if others change the way I have arranged things (in the past month)",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "022708d6-da3a-492b-af94-9f59d146789c",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "cee86485-08d9-4f0d-856b-d0357479c4b6",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "58b60964-1a22-4fdf-9f0c-8fff56cc8b5b",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "330d37b1-b0e1-4b8d-bd52-7145ee56124a",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "e5044612-5422-4c8f-9b0f-9cf7bd8913d5",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI_R-11",
        "text": "I feel I have to repeat certain numbers (in the past month)",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "06ace63c-76fa-44a9-b54a-a4ca212179b8",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ce4972d8-a8a2-4909-b316-42f290b0be08",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "95810cfc-b9d0-4122-868d-ddd66d6ad59d",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "0e2b9c74-ddc5-4536-8ddd-e7b1c1894509",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "cf3e4209-6108-4cf8-9d78-2fb8ef5c0e28",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI_R-12",
        "text": "I sometimes have to wash or clean myself simply because I feel contaminated (in the past month)",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "d8c763a9-70f7-4e27-91e9-deb75bc8dd52",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "46b9f602-ab0d-491e-931b-f98fd961b60c",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "77a8d6a3-0711-46f8-b50e-1d2715adff02",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "eaa9d917-5d91-41e9-b895-7ae2714c536f",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "f0a7d382-58fd-42cb-8546-52b2eeceda4d",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI_R-13",
        "text": "I am upset by unpleasant thoughts that come into my mind against my will (in the past month)",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "5c3f296f-f9a3-4159-a608-8a571a593fc6",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "1196f470-b956-48da-b0ff-3d58a848b060",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "6d46cdf8-179a-4117-ac00-081cb2f39c23",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "cf1e9416-e714-4f5c-b7c3-89c8c49cf673",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "5d0a5863-e505-4acb-adc3-3b7ae92f6e48",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI_R-14",
        "text": "I avoid throwing things away because I am afraid I might need them later (in the past month)",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "1569e528-7499-4472-9ff6-ba2d553520cc",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ac542969-492d-43a8-993f-0c3afd2bb92f",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "8c264d46-824d-41a8-b2c3-261002adbffa",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "62727ae9-d254-4e87-bd16-8c6c18ef890d",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "ee32aa1b-2cf3-41d4-9b88-6db89cd506b7",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI_R-15",
        "text": "I repeatedly check gas and water taps and light switches after turning them off (in the past month)",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "0cc0e03f-9298-4ea2-847c-a5b6e589d87c",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "748fafdf-a71b-4737-ac66-a65f619a7523",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "37b5e49d-68a8-44a2-a744-7dccf43ed642",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "9cf22bcd-9e90-42f7-9f77-70b91d91f9ad",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "acad0ce6-d760-4f64-8517-895febc602c0",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI_R-16",
        "text": "I need things to be arranged in a particular order (in the past month)",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "01196058-39f5-46a2-ba0e-2b3e85ef09e1",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "8a6a2fdf-48b1-40ed-9be6-eed7a07f5e67",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "29a9fa3f-0aea-4984-923a-3515d1753d6a",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "e3738514-70eb-4cfe-b3b7-af1f907fffac",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "6f979724-353c-459c-8cf7-93cb21b50a9d",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI_R-17",
        "text": "I feel that there are good and bad numbers (in the past month)",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "785d7cf1-1d5e-4473-b768-149d472ac41e",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "8cc6a438-4a40-4953-85cb-dcf31fd716fe",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "83adf75f-52d0-4807-b4e1-d4b99a3c7222",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "38db906c-a00b-4f75-a01e-30522abea3dc",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "401bb479-78a4-42a2-8973-3660263e3a10",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI_R-18",
        "text": "I wash my hands more often or longer than necessary (in the past month)",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "4a683a19-a34b-4262-9461-43a6d44cf340",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "76d30f14-0462-4c5f-a362-673b378ba787",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "b04b5161-7e76-4216-b7e1-e19fbc072903",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "8212d3d3-7e34-4627-8c22-774d0a82274f",
                "text": "A lot",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "f3e0225c-0167-47d3-a013-e1c961973054",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "OCI_R-19",
        "text": "I frequently get nasty thoughts and have difficulty in getting rid of them (in the past month)",
        "isMultiselect": false
    }
];
exports.aspects = {
    "ocir": {
        "type": "mean",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI_R-2",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "13b7f302-0fd7-4d1b-aef0-c0497bddbc6c": 0,
                            "7646ef49-0c23-4516-b132-02cf44dac3cd": 0.25,
                            "565e2012-036d-4dc3-a0a3-96d11f9dc70c": 0.5,
                            "457bde62-7b9d-4e6b-b8fd-3c47edd3a1fc": 0.75,
                            "fb74ba05-4489-48e4-911a-8c58c77bba91": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI_R-3",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "bab86586-0ccd-492a-9a75-6c2f22e86ab3": 0,
                            "296e536f-bbe5-4994-a34d-0a89b3490c9a": 0.25,
                            "832bcab2-d39e-4198-858b-3788cc30908b": 0.5,
                            "2e9c8242-9de5-4450-aa3d-635e9dca03af": 0.75,
                            "9bf9a461-5817-446e-9ec5-d8e9cebab9a7": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI_R-4",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "38e7ac14-8a29-45cc-8a49-0003921d119f": 0,
                            "f6f2fcd1-0a03-44f7-99af-576535ee0a1c": 0.25,
                            "4b625451-7b24-4dcd-9cfa-851fd3d9b194": 0.5,
                            "48ec0abf-b682-49ac-b604-b70d72056f5f": 0.75,
                            "5a13fc6a-f5fa-4fdc-86a0-9cfd86414dd9": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI_R-5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "6961b86d-57da-4148-91f2-b48c56f8c7ea": 0,
                            "7fe921e0-c1e4-4cf0-80d2-1f221159cfd8": 0.25,
                            "b1a1116e-0455-460e-b937-21b7c4b9480b": 0.5,
                            "0d4624bc-9565-4a2c-bf98-f0199362ef67": 0.75,
                            "9aa037e0-e1bf-442d-903b-0d521c3b16df": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI_R-6",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "44d85fc8-1f8c-45c9-9dcb-c8ee1caf8f6d": 0,
                            "cc024879-36e8-4bac-87a3-7b492d9de8cb": 0.25,
                            "fc754e30-1dbe-4b5e-b05d-bb334ee4e347": 0.5,
                            "d7ec3936-d183-4833-8d80-f4399d255d77": 0.75,
                            "698cde41-b06a-49be-99ba-21016759d37c": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI_R-7",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "90a9b18e-f6ed-476e-b00a-913409cfab60": 0,
                            "9af9b95c-c26c-4dcb-adb4-8c1610ef15e5": 0.25,
                            "02834643-1011-4d82-8359-21e828d9bc23": 0.5,
                            "a93ea17c-0ac4-4271-9f85-710a24409e58": 0.75,
                            "2fec116c-335a-4da7-9153-57d4cd8438b9": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI_R-8",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "24b2f7ab-2222-415e-a43b-5f35417f18d7": 0,
                            "51f596f5-f310-4d28-b0b6-0640a72adb0b": 0.25,
                            "560ad5d8-9025-4df9-9617-d43ad39f1d1b": 0.5,
                            "1890db87-c398-44fd-a39a-99ac170add80": 0.75,
                            "17e2f02e-b299-49a9-8c87-f7fbf055262c": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI_R-9",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "8a57a734-2634-4b0f-97a1-15e375985fa7": 0,
                            "427172e3-78e7-430c-baca-369391a130e1": 0.25,
                            "342d2fc0-250a-4eb2-8e6d-6e1fd70d4558": 0.5,
                            "6c8556a1-72bd-4892-86b6-e395fde62cb7": 0.75,
                            "c8b942c7-c9ce-42b8-8172-41c7ed29a2c3": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI_R-10",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "e3bdb0a8-d4b2-4b48-9840-08e7a84e04ed": 0,
                            "847f2166-d531-4a51-b3db-e383b870549b": 0.25,
                            "0982d4d0-4381-4bbe-8005-5fa5edab4053": 0.5,
                            "d26c22f4-ae63-414b-a2a9-b34eba404940": 0.75,
                            "16baad7b-fc4a-4f05-9905-179ae60659d7": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI_R-11",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "022708d6-da3a-492b-af94-9f59d146789c": 0,
                            "cee86485-08d9-4f0d-856b-d0357479c4b6": 0.25,
                            "58b60964-1a22-4fdf-9f0c-8fff56cc8b5b": 0.5,
                            "330d37b1-b0e1-4b8d-bd52-7145ee56124a": 0.75,
                            "e5044612-5422-4c8f-9b0f-9cf7bd8913d5": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI_R-12",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "06ace63c-76fa-44a9-b54a-a4ca212179b8": 0,
                            "ce4972d8-a8a2-4909-b316-42f290b0be08": 0.25,
                            "95810cfc-b9d0-4122-868d-ddd66d6ad59d": 0.5,
                            "0e2b9c74-ddc5-4536-8ddd-e7b1c1894509": 0.75,
                            "cf3e4209-6108-4cf8-9d78-2fb8ef5c0e28": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI_R-13",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "d8c763a9-70f7-4e27-91e9-deb75bc8dd52": 0,
                            "46b9f602-ab0d-491e-931b-f98fd961b60c": 0.25,
                            "77a8d6a3-0711-46f8-b50e-1d2715adff02": 0.5,
                            "eaa9d917-5d91-41e9-b895-7ae2714c536f": 0.75,
                            "f0a7d382-58fd-42cb-8546-52b2eeceda4d": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI_R-14",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "5c3f296f-f9a3-4159-a608-8a571a593fc6": 0,
                            "1196f470-b956-48da-b0ff-3d58a848b060": 0.25,
                            "6d46cdf8-179a-4117-ac00-081cb2f39c23": 0.5,
                            "cf1e9416-e714-4f5c-b7c3-89c8c49cf673": 0.75,
                            "5d0a5863-e505-4acb-adc3-3b7ae92f6e48": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI_R-15",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "1569e528-7499-4472-9ff6-ba2d553520cc": 0,
                            "ac542969-492d-43a8-993f-0c3afd2bb92f": 0.25,
                            "8c264d46-824d-41a8-b2c3-261002adbffa": 0.5,
                            "62727ae9-d254-4e87-bd16-8c6c18ef890d": 0.75,
                            "ee32aa1b-2cf3-41d4-9b88-6db89cd506b7": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI_R-16",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "0cc0e03f-9298-4ea2-847c-a5b6e589d87c": 0,
                            "748fafdf-a71b-4737-ac66-a65f619a7523": 0.25,
                            "37b5e49d-68a8-44a2-a744-7dccf43ed642": 0.5,
                            "9cf22bcd-9e90-42f7-9f77-70b91d91f9ad": 0.75,
                            "acad0ce6-d760-4f64-8517-895febc602c0": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI_R-17",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "01196058-39f5-46a2-ba0e-2b3e85ef09e1": 0,
                            "8a6a2fdf-48b1-40ed-9be6-eed7a07f5e67": 0.25,
                            "29a9fa3f-0aea-4984-923a-3515d1753d6a": 0.5,
                            "e3738514-70eb-4cfe-b3b7-af1f907fffac": 0.75,
                            "6f979724-353c-459c-8cf7-93cb21b50a9d": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI_R-18",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "785d7cf1-1d5e-4473-b768-149d472ac41e": 0,
                            "8cc6a438-4a40-4953-85cb-dcf31fd716fe": 0.25,
                            "83adf75f-52d0-4807-b4e1-d4b99a3c7222": 0.5,
                            "38db906c-a00b-4f75-a01e-30522abea3dc": 0.75,
                            "401bb479-78a4-42a2-8973-3660263e3a10": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "OCI_R-19",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "4a683a19-a34b-4262-9461-43a6d44cf340": 0,
                            "76d30f14-0462-4c5f-a362-673b378ba787": 0.25,
                            "b04b5161-7e76-4216-b7e1-e19fbc072903": 0.5,
                            "8212d3d3-7e34-4627-8c22-774d0a82274f": 0.75,
                            "f3e0225c-0167-47d3-a013-e1c961973054": 1
                        }
                    }
                }
            }
        ]
    }
};
exports.tests = {
    "ocir": [
        {
            "name": "OCI-R - if score 0 -> no OCD-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0,
                "threshold": 0
            },
            "answers": {
                "OCI_R-2": {
                    "questionId": "OCI_R-2",
                    "chosenOptionIds": [
                        "13b7f302-0fd7-4d1b-aef0-c0497bddbc6c"
                    ]
                },
                "OCI_R-3": {
                    "questionId": "OCI_R-3",
                    "chosenOptionIds": [
                        "bab86586-0ccd-492a-9a75-6c2f22e86ab3"
                    ]
                },
                "OCI_R-4": {
                    "questionId": "OCI_R-4",
                    "chosenOptionIds": [
                        "38e7ac14-8a29-45cc-8a49-0003921d119f"
                    ]
                },
                "OCI_R-5": {
                    "questionId": "OCI_R-5",
                    "chosenOptionIds": [
                        "6961b86d-57da-4148-91f2-b48c56f8c7ea"
                    ]
                },
                "OCI_R-6": {
                    "questionId": "OCI_R-6",
                    "chosenOptionIds": [
                        "44d85fc8-1f8c-45c9-9dcb-c8ee1caf8f6d"
                    ]
                },
                "OCI_R-7": {
                    "questionId": "OCI_R-7",
                    "chosenOptionIds": [
                        "90a9b18e-f6ed-476e-b00a-913409cfab60"
                    ]
                },
                "OCI_R-8": {
                    "questionId": "OCI_R-8",
                    "chosenOptionIds": [
                        "24b2f7ab-2222-415e-a43b-5f35417f18d7"
                    ]
                },
                "OCI_R-9": {
                    "questionId": "OCI_R-9",
                    "chosenOptionIds": [
                        "8a57a734-2634-4b0f-97a1-15e375985fa7"
                    ]
                },
                "OCI_R-10": {
                    "questionId": "OCI_R-10",
                    "chosenOptionIds": [
                        "e3bdb0a8-d4b2-4b48-9840-08e7a84e04ed"
                    ]
                },
                "OCI_R-11": {
                    "questionId": "OCI_R-11",
                    "chosenOptionIds": [
                        "022708d6-da3a-492b-af94-9f59d146789c"
                    ]
                },
                "OCI_R-12": {
                    "questionId": "OCI_R-12",
                    "chosenOptionIds": [
                        "06ace63c-76fa-44a9-b54a-a4ca212179b8"
                    ]
                },
                "OCI_R-13": {
                    "questionId": "OCI_R-13",
                    "chosenOptionIds": [
                        "d8c763a9-70f7-4e27-91e9-deb75bc8dd52"
                    ]
                },
                "OCI_R-14": {
                    "questionId": "OCI_R-14",
                    "chosenOptionIds": [
                        "5c3f296f-f9a3-4159-a608-8a571a593fc6"
                    ]
                },
                "OCI_R-15": {
                    "questionId": "OCI_R-15",
                    "chosenOptionIds": [
                        "1569e528-7499-4472-9ff6-ba2d553520cc"
                    ]
                },
                "OCI_R-16": {
                    "questionId": "OCI_R-16",
                    "chosenOptionIds": [
                        "0cc0e03f-9298-4ea2-847c-a5b6e589d87c"
                    ]
                },
                "OCI_R-17": {
                    "questionId": "OCI_R-17",
                    "chosenOptionIds": [
                        "01196058-39f5-46a2-ba0e-2b3e85ef09e1"
                    ]
                },
                "OCI_R-18": {
                    "questionId": "OCI_R-18",
                    "chosenOptionIds": [
                        "785d7cf1-1d5e-4473-b768-149d472ac41e"
                    ]
                },
                "OCI_R-19": {
                    "questionId": "OCI_R-19",
                    "chosenOptionIds": [
                        "4a683a19-a34b-4262-9461-43a6d44cf340"
                    ]
                }
            }
        },
        {
            "name": "OCI-R - if score 20 -> no likelihood of OCD diagnosis-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.2777777777777778,
                "threshold": 0
            },
            "answers": {
                "OCI_R-2": {
                    "questionId": "OCI_R-2",
                    "chosenOptionIds": [
                        "7646ef49-0c23-4516-b132-02cf44dac3cd"
                    ]
                },
                "OCI_R-3": {
                    "questionId": "OCI_R-3",
                    "chosenOptionIds": [
                        "296e536f-bbe5-4994-a34d-0a89b3490c9a"
                    ]
                },
                "OCI_R-4": {
                    "questionId": "OCI_R-4",
                    "chosenOptionIds": [
                        "f6f2fcd1-0a03-44f7-99af-576535ee0a1c"
                    ]
                },
                "OCI_R-5": {
                    "questionId": "OCI_R-5",
                    "chosenOptionIds": [
                        "7fe921e0-c1e4-4cf0-80d2-1f221159cfd8"
                    ]
                },
                "OCI_R-6": {
                    "questionId": "OCI_R-6",
                    "chosenOptionIds": [
                        "cc024879-36e8-4bac-87a3-7b492d9de8cb"
                    ]
                },
                "OCI_R-7": {
                    "questionId": "OCI_R-7",
                    "chosenOptionIds": [
                        "9af9b95c-c26c-4dcb-adb4-8c1610ef15e5"
                    ]
                },
                "OCI_R-8": {
                    "questionId": "OCI_R-8",
                    "chosenOptionIds": [
                        "17e2f02e-b299-49a9-8c87-f7fbf055262c"
                    ]
                },
                "OCI_R-9": {
                    "questionId": "OCI_R-9",
                    "chosenOptionIds": [
                        "c8b942c7-c9ce-42b8-8172-41c7ed29a2c3"
                    ]
                },
                "OCI_R-10": {
                    "questionId": "OCI_R-10",
                    "chosenOptionIds": [
                        "16baad7b-fc4a-4f05-9905-179ae60659d7"
                    ]
                },
                "OCI_R-11": {
                    "questionId": "OCI_R-11",
                    "chosenOptionIds": [
                        "cee86485-08d9-4f0d-856b-d0357479c4b6"
                    ]
                },
                "OCI_R-12": {
                    "questionId": "OCI_R-12",
                    "chosenOptionIds": [
                        "ce4972d8-a8a2-4909-b316-42f290b0be08"
                    ]
                },
                "OCI_R-13": {
                    "questionId": "OCI_R-13",
                    "chosenOptionIds": [
                        "d8c763a9-70f7-4e27-91e9-deb75bc8dd52"
                    ]
                },
                "OCI_R-14": {
                    "questionId": "OCI_R-14",
                    "chosenOptionIds": [
                        "5c3f296f-f9a3-4159-a608-8a571a593fc6"
                    ]
                },
                "OCI_R-15": {
                    "questionId": "OCI_R-15",
                    "chosenOptionIds": [
                        "1569e528-7499-4472-9ff6-ba2d553520cc"
                    ]
                },
                "OCI_R-16": {
                    "questionId": "OCI_R-16",
                    "chosenOptionIds": [
                        "0cc0e03f-9298-4ea2-847c-a5b6e589d87c"
                    ]
                },
                "OCI_R-17": {
                    "questionId": "OCI_R-17",
                    "chosenOptionIds": [
                        "01196058-39f5-46a2-ba0e-2b3e85ef09e1"
                    ]
                },
                "OCI_R-18": {
                    "questionId": "OCI_R-18",
                    "chosenOptionIds": [
                        "785d7cf1-1d5e-4473-b768-149d472ac41e"
                    ]
                },
                "OCI_R-19": {
                    "questionId": "OCI_R-19",
                    "chosenOptionIds": [
                        "4a683a19-a34b-4262-9461-43a6d44cf340"
                    ]
                }
            }
        },
        {
            "name": "OCI-R - if score 21-> likelihood of OCD diagnosis-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.2916666666666667,
                "threshold": 0
            },
            "answers": {
                "OCI_R-2": {
                    "questionId": "OCI_R-2",
                    "chosenOptionIds": [
                        "7646ef49-0c23-4516-b132-02cf44dac3cd"
                    ]
                },
                "OCI_R-3": {
                    "questionId": "OCI_R-3",
                    "chosenOptionIds": [
                        "296e536f-bbe5-4994-a34d-0a89b3490c9a"
                    ]
                },
                "OCI_R-4": {
                    "questionId": "OCI_R-4",
                    "chosenOptionIds": [
                        "f6f2fcd1-0a03-44f7-99af-576535ee0a1c"
                    ]
                },
                "OCI_R-5": {
                    "questionId": "OCI_R-5",
                    "chosenOptionIds": [
                        "7fe921e0-c1e4-4cf0-80d2-1f221159cfd8"
                    ]
                },
                "OCI_R-6": {
                    "questionId": "OCI_R-6",
                    "chosenOptionIds": [
                        "cc024879-36e8-4bac-87a3-7b492d9de8cb"
                    ]
                },
                "OCI_R-7": {
                    "questionId": "OCI_R-7",
                    "chosenOptionIds": [
                        "9af9b95c-c26c-4dcb-adb4-8c1610ef15e5"
                    ]
                },
                "OCI_R-8": {
                    "questionId": "OCI_R-8",
                    "chosenOptionIds": [
                        "17e2f02e-b299-49a9-8c87-f7fbf055262c"
                    ]
                },
                "OCI_R-9": {
                    "questionId": "OCI_R-9",
                    "chosenOptionIds": [
                        "c8b942c7-c9ce-42b8-8172-41c7ed29a2c3"
                    ]
                },
                "OCI_R-10": {
                    "questionId": "OCI_R-10",
                    "chosenOptionIds": [
                        "16baad7b-fc4a-4f05-9905-179ae60659d7"
                    ]
                },
                "OCI_R-11": {
                    "questionId": "OCI_R-11",
                    "chosenOptionIds": [
                        "cee86485-08d9-4f0d-856b-d0357479c4b6"
                    ]
                },
                "OCI_R-12": {
                    "questionId": "OCI_R-12",
                    "chosenOptionIds": [
                        "ce4972d8-a8a2-4909-b316-42f290b0be08"
                    ]
                },
                "OCI_R-13": {
                    "questionId": "OCI_R-13",
                    "chosenOptionIds": [
                        "46b9f602-ab0d-491e-931b-f98fd961b60c"
                    ]
                },
                "OCI_R-14": {
                    "questionId": "OCI_R-14",
                    "chosenOptionIds": [
                        "5c3f296f-f9a3-4159-a608-8a571a593fc6"
                    ]
                },
                "OCI_R-15": {
                    "questionId": "OCI_R-15",
                    "chosenOptionIds": [
                        "1569e528-7499-4472-9ff6-ba2d553520cc"
                    ]
                },
                "OCI_R-16": {
                    "questionId": "OCI_R-16",
                    "chosenOptionIds": [
                        "0cc0e03f-9298-4ea2-847c-a5b6e589d87c"
                    ]
                },
                "OCI_R-17": {
                    "questionId": "OCI_R-17",
                    "chosenOptionIds": [
                        "01196058-39f5-46a2-ba0e-2b3e85ef09e1"
                    ]
                },
                "OCI_R-18": {
                    "questionId": "OCI_R-18",
                    "chosenOptionIds": [
                        "785d7cf1-1d5e-4473-b768-149d472ac41e"
                    ]
                },
                "OCI_R-19": {
                    "questionId": "OCI_R-19",
                    "chosenOptionIds": [
                        "4a683a19-a34b-4262-9461-43a6d44cf340"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {};
