"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
exports.questions = [
    {
        "__typename": "ContentQuestion",
        "id": "PDSS-content-1",
        "text": "The following questions refer to panic attacks and limited symptom attacks. \n\nFor these questions we define a panic attack as a sudden rush of fear or discomfort accompanied by at least 4 of the symptoms listed below:\n\n- Rapid or pounding heartbeat\n- Sweating\n- Trembling or shaking\n- Breathlessness\n- Feeling of choking\n- Chest pain or discomfort\n- Nausea\n- Dizziness or faintness\n- Feelings of unreality\n- Numbness or tingling\n- Chills or hot flushes\n- Fear of losing control or going crazy\n- Fear of dying",
        "answers": [
            {
                "__typename": "Option",
                "id": "cbd89139-ca02-49ea-b4e2-0d05ee1b2619",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "ContentQuestion",
        "id": "PDSS-content-2",
        "text": "In order to qualify as a sudden rush, the symptoms must peak within 10 minutes. \n\nEpisodes like panic attacks but which have fewer than 4 of the listed symptoms on the previous screen are called limited symptom attacks.\n\nTake your time with these questions, and remember there are no right or wrong answers. \n\nSelect 'Continue' to start.",
        "answers": [
            {
                "__typename": "Option",
                "id": "09042e83-8636-48ea-b9d7-e01ea799ea0b",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "5d995fdb-9ca9-49b9-936a-f3b7730b10c9",
                "text": "No panic or limited symptom episodes",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "e1d73a6c-685b-4815-8faf-77b486005ef5",
                "text": "Mild: no full panic attacks and no more than 1 limited symptom attack/day",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "400c315c-20fd-4d71-a4cd-a5db49f77090",
                "text": "Moderate: 1 or 2 full panic attacks and/or multiple limited symptom attacks/day",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "4211cb00-758a-4e12-b9c7-0cb2b14f4348",
                "text": "Severe: more than 2 full attacks but not more than 1/day on average",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "83ebdd37-1eae-471b-a076-440b62fc9155",
                "text": "Extreme: full panic attacks occurred more than once a day, more days than not",
                "value": 1
            }
        ],
        "id": "PDSS-1",
        "text": "How many panic and limited symptoms attacks did you have during the week?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "cee669a3-1834-4aa9-ac03-de9a3f71ef80",
                "text": "Not at all distressing, or no panic or limited symptom attacks during the past week",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2934c4fc-f86a-4d57-b894-a5ab891924e8",
                "text": "Mildly distressing (not too intense)",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "c0a8f6c2-e3a2-4845-8a0a-2507d6dfc376",
                "text": "Moderately distressing (intense, but still manageable)",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "5cc4e100-1db9-4001-9075-1f6d3cca1e74",
                "text": "Severely distressing (very intense)",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "cd1bd867-35e6-464e-b7e5-990fbd38f79f",
                "text": "Extremely distressing (extreme distress during all attacks)",
                "value": 1
            }
        ],
        "id": "PDSS-2",
        "text": "If you had any panic attacks during the past week, how distressing (uncomfortable, frightening) were they while they were happening?\n\nIf you had more than one, give an average rating. If you didn't have any panic attacks but did have limited symptom attacks, answer for the limited symptom attacks.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8669cfb6-f317-4797-a327-6c1a34a1b82a",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "e58937b8-ee9f-4d49-896e-acb1b90fa416",
                "text": "Occasionally or only mildly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "d3ad960a-dbd0-4108-bb50-ae545eff0684",
                "text": "Frequently or moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "f38af45e-6512-43e8-8c73-37ebe779430e",
                "text": "Very often or to a very disturbing degree",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "33edeb7b-56b4-4b3b-b8af-a9ac0c506a43",
                "text": "Nearly constantly and to a disabling extent",
                "value": 1
            }
        ],
        "id": "PDSS-3",
        "text": "During the past week, how much have you worried or felt anxious about when your next panic attack would occur or about fears related to the attacks (for example, that they could mean you have physical or mental health problems or could cause you social embarrassment)?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "79205eda-1ca1-4f31-b8d4-e7a052d7625e",
                "text": "None: no fear or avoidance",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b04d02dc-761a-41c3-92b9-be551225c2ee",
                "text": "Mild: occasional fear and/or avoidance but I could usually confront or endure the situation. There was little or no modification of my lifestyle due to this.",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "415bdce9-02cf-417c-ad85-208fd126b0ee",
                "text": "Moderate: noticeable fear and/or avoidance but still manageable. I avoided some situations, but I could confront them with a companion. There was some modification of my lifestyle because of this, but my overall functioning was not impaired.",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "521ea5ea-2f25-485e-906c-89ffcc87c7ff",
                "text": "Severe: extensive avoidance. Substantial modification of my lifestyle was required to accommodate the avoidance making it difficult to manage usual activities.",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "30839d01-6d8a-4df0-8302-b77e25b2af04",
                "text": "Extreme: pervasive disabling fear and/or avoidance. Extensive modification in my lifestyle was required such that important tasks were not performed.",
                "value": 1
            }
        ],
        "id": "PDSS-4",
        "text": "During the past week were there any places or situations (e.g., public transportation, movie theaters, crowds, bridges, tunnels, shopping malls, being alone) you avoided, or felt afraid of (uncomfortable in, wanted to avoid or leave), because of fear of having a panic attack? \n\nAre there any other situations that you would have avoided or been afraid of if they had come up during the week, for the same reason? \n\nIf yes to either question, please rate your level of fear and avoidance this past week.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "9adb1279-2eb9-4a6b-8e79-8b438fc561bf",
                "text": "No fear or avoidance of situations or activities because of distressing physical sensations.",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "3fbfa4bb-0dc9-4ecb-9bc5-24f4636c5dd4",
                "text": "Mild: occasional fear and/or avoidance, but usually I could confront or endure with little distress activities that cause physical sensations. There was little modification of my lifestyle due to this. ",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "9e0d3762-2451-4300-b9f1-d61add169c74",
                "text": "Moderate: noticeable avoidance but still manageable. There was definite, but limited, modification of my lifestyle such that my overall functioning was not impaired.",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "a2e0dc6f-3263-4aef-9e01-9c5008ddf01a",
                "text": "Severe: extensive avoidance. There was substantial modification of my lifestyle or interference in my functioning.",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "7d91c8cf-52bd-4f54-ab09-8b9ee3b5f51d",
                "text": "Extreme: pervasive and disabling avoidance. There was extensive modification in my lifestyle due to this such that important tasks or activities were not performed.",
                "value": 1
            }
        ],
        "id": "PDSS-5",
        "text": "During the past week, were there any activities (e.g., physical exertion, sexual relations, taking a hot shower or bath, drinking coffee, watching an exciting or scary movie) that you avoided, or felt afraid of (uncomfortable doing, wanted to avoid or stop), because they caused physical sensations like those you feel during panic attacks or that you were afraid might trigger a panic attack?\n\nAre there any other activities that you would have avoided or been afraid of if they had come up during the week for that reason? \n\nIf yes to either question, please rate your level of fear and avoidance of those activities this past week.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "628e4aef-df14-45b8-b8f4-15327275308a",
                "text": "No interference with work or home responsibilities",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "318f73b5-24ed-4f7d-a4dd-4deabd5bdfc4",
                "text": "Slight interference with work or home responsibilities",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "f3954559-4580-4134-b37c-ec5de6d3703b",
                "text": "Significant interference with work or home responsibilities, but I still could manage to do the things I needed to do.",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "61e650df-25c6-4f5b-81ee-abe313f978a4",
                "text": "Substantial impairment in work or home responsibilities; there were many important things I couldn't do because of these problems.",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "88a7e897-43bb-48dd-be70-d5fe5ca94c3e",
                "text": "Extreme, incapacitating impairment such that I was essentially unable to manage any work or home responsibilities.",
                "value": 1
            }
        ],
        "id": "PDSS-6",
        "text": "During the past week, how much did the above symptoms altogether (panic and limited symptom attacks, worry about attacks, and fear of situations and activities because of attacks) interfere with your ability to work or carry out your responsibilities at home?\n\nIf your work or home responsibilities were less than usual this past week, answer how you think you would have done if the responsibilities had been usual.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "1881985e-884a-418d-940e-fec9cb872729",
                "text": "No interference",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "4d83337c-c2f6-4f63-bb63-37195627a451",
                "text": "Slight interference with social activities",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "ac713a60-0f92-4cc3-9c86-4f42886d44d2",
                "text": "Significant interference with social activities but I could manage to do most things if I made the effort.",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "1185c9fe-36c4-4606-bbb6-d1d28b0ecea3",
                "text": "Substantial impairment in social activities; there are many social things I couldn't do because of these problems.",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "785b6979-d7f5-43ab-b6af-725c717c4fc8",
                "text": "Extreme, incapacitating impairment, such that there was hardly anything social I could do.",
                "value": 1
            }
        ],
        "id": "PDSS-7",
        "text": "During the past week, how much did panic and limited symptom attacks, worry about attacks and fear of situations and activities because of attacks interfere with your social life?\n\nIf you didn't have many opportunities to socialize this past week, answer how you think you would have done if you did have opportunities.",
        "isMultiselect": false
    }
];
exports.aspects = {
    "pdss": {
        "type": "mean",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PDSS-1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "5d995fdb-9ca9-49b9-936a-f3b7730b10c9": 0,
                            "e1d73a6c-685b-4815-8faf-77b486005ef5": 0.25,
                            "400c315c-20fd-4d71-a4cd-a5db49f77090": 0.5,
                            "4211cb00-758a-4e12-b9c7-0cb2b14f4348": 0.75,
                            "83ebdd37-1eae-471b-a076-440b62fc9155": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PDSS-2",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "cee669a3-1834-4aa9-ac03-de9a3f71ef80": 0,
                            "2934c4fc-f86a-4d57-b894-a5ab891924e8": 0.25,
                            "c0a8f6c2-e3a2-4845-8a0a-2507d6dfc376": 0.5,
                            "5cc4e100-1db9-4001-9075-1f6d3cca1e74": 0.75,
                            "cd1bd867-35e6-464e-b7e5-990fbd38f79f": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PDSS-3",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "8669cfb6-f317-4797-a327-6c1a34a1b82a": 0,
                            "e58937b8-ee9f-4d49-896e-acb1b90fa416": 0.25,
                            "d3ad960a-dbd0-4108-bb50-ae545eff0684": 0.5,
                            "f38af45e-6512-43e8-8c73-37ebe779430e": 0.75,
                            "33edeb7b-56b4-4b3b-b8af-a9ac0c506a43": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PDSS-4",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "79205eda-1ca1-4f31-b8d4-e7a052d7625e": 0,
                            "b04d02dc-761a-41c3-92b9-be551225c2ee": 0.25,
                            "415bdce9-02cf-417c-ad85-208fd126b0ee": 0.5,
                            "521ea5ea-2f25-485e-906c-89ffcc87c7ff": 0.75,
                            "30839d01-6d8a-4df0-8302-b77e25b2af04": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PDSS-5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "9adb1279-2eb9-4a6b-8e79-8b438fc561bf": 0,
                            "3fbfa4bb-0dc9-4ecb-9bc5-24f4636c5dd4": 0.25,
                            "9e0d3762-2451-4300-b9f1-d61add169c74": 0.5,
                            "a2e0dc6f-3263-4aef-9e01-9c5008ddf01a": 0.75,
                            "7d91c8cf-52bd-4f54-ab09-8b9ee3b5f51d": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PDSS-6",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "628e4aef-df14-45b8-b8f4-15327275308a": 0,
                            "318f73b5-24ed-4f7d-a4dd-4deabd5bdfc4": 0.25,
                            "f3954559-4580-4134-b37c-ec5de6d3703b": 0.5,
                            "61e650df-25c6-4f5b-81ee-abe313f978a4": 0.75,
                            "88a7e897-43bb-48dd-be70-d5fe5ca94c3e": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PDSS-7",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "1881985e-884a-418d-940e-fec9cb872729": 0,
                            "4d83337c-c2f6-4f63-bb63-37195627a451": 0.25,
                            "ac713a60-0f92-4cc3-9c86-4f42886d44d2": 0.5,
                            "1185c9fe-36c4-4606-bbb6-d1d28b0ecea3": 0.75,
                            "785b6979-d7f5-43ab-b6af-725c717c4fc8": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "PDSS-8"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "PDSS-9"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "PDSS-10"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "PDSS-11"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "PDSS-12"
                }
            }
        ]
    }
};
exports.tests = {
    "pdss": [
        {
            "name": "PDSS 0 score-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0,
                "threshold": 0
            },
            "answers": {
                "PDSS-1": {
                    "questionId": "PDSS-1",
                    "chosenOptionIds": [
                        "5d995fdb-9ca9-49b9-936a-f3b7730b10c9"
                    ]
                },
                "PDSS-2": {
                    "questionId": "PDSS-2",
                    "chosenOptionIds": [
                        "cee669a3-1834-4aa9-ac03-de9a3f71ef80"
                    ]
                },
                "PDSS-3": {
                    "questionId": "PDSS-3",
                    "chosenOptionIds": [
                        "8669cfb6-f317-4797-a327-6c1a34a1b82a"
                    ]
                },
                "PDSS-4": {
                    "questionId": "PDSS-4",
                    "chosenOptionIds": [
                        "79205eda-1ca1-4f31-b8d4-e7a052d7625e"
                    ]
                },
                "PDSS-5": {
                    "questionId": "PDSS-5",
                    "chosenOptionIds": [
                        "9adb1279-2eb9-4a6b-8e79-8b438fc561bf"
                    ]
                },
                "PDSS-6": {
                    "questionId": "PDSS-6",
                    "chosenOptionIds": [
                        "628e4aef-df14-45b8-b8f4-15327275308a"
                    ]
                },
                "PDSS-7": {
                    "questionId": "PDSS-7",
                    "chosenOptionIds": [
                        "1881985e-884a-418d-940e-fec9cb872729"
                    ]
                }
            }
        },
        {
            "name": "PDSS full score-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 1,
                "threshold": 0
            },
            "answers": {
                "PDSS-1": {
                    "questionId": "PDSS-1",
                    "chosenOptionIds": [
                        "83ebdd37-1eae-471b-a076-440b62fc9155"
                    ]
                },
                "PDSS-2": {
                    "questionId": "PDSS-2",
                    "chosenOptionIds": [
                        "cd1bd867-35e6-464e-b7e5-990fbd38f79f"
                    ]
                },
                "PDSS-3": {
                    "questionId": "PDSS-3",
                    "chosenOptionIds": [
                        "33edeb7b-56b4-4b3b-b8af-a9ac0c506a43"
                    ]
                },
                "PDSS-4": {
                    "questionId": "PDSS-4",
                    "chosenOptionIds": [
                        "30839d01-6d8a-4df0-8302-b77e25b2af04"
                    ]
                },
                "PDSS-5": {
                    "questionId": "PDSS-5",
                    "chosenOptionIds": [
                        "7d91c8cf-52bd-4f54-ab09-8b9ee3b5f51d"
                    ]
                },
                "PDSS-6": {
                    "questionId": "PDSS-6",
                    "chosenOptionIds": [
                        "88a7e897-43bb-48dd-be70-d5fe5ca94c3e"
                    ]
                },
                "PDSS-7": {
                    "questionId": "PDSS-7",
                    "chosenOptionIds": [
                        "785b6979-d7f5-43ab-b6af-725c717c4fc8"
                    ]
                }
            }
        },
        {
            "name": "PDSS - partial score 0.5-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.5,
                "threshold": 0
            },
            "answers": {
                "PDSS-1": {
                    "questionId": "PDSS-1",
                    "chosenOptionIds": [
                        "400c315c-20fd-4d71-a4cd-a5db49f77090"
                    ]
                },
                "PDSS-2": {
                    "questionId": "PDSS-2",
                    "chosenOptionIds": [
                        "c0a8f6c2-e3a2-4845-8a0a-2507d6dfc376"
                    ]
                },
                "PDSS-3": {
                    "questionId": "PDSS-3",
                    "chosenOptionIds": [
                        "d3ad960a-dbd0-4108-bb50-ae545eff0684"
                    ]
                },
                "PDSS-4": {
                    "questionId": "PDSS-4",
                    "chosenOptionIds": [
                        "415bdce9-02cf-417c-ad85-208fd126b0ee"
                    ]
                },
                "PDSS-5": {
                    "questionId": "PDSS-5",
                    "chosenOptionIds": [
                        "9e0d3762-2451-4300-b9f1-d61add169c74"
                    ]
                },
                "PDSS-6": {
                    "questionId": "PDSS-6",
                    "chosenOptionIds": [
                        "f3954559-4580-4134-b37c-ec5de6d3703b"
                    ]
                },
                "PDSS-7": {
                    "questionId": "PDSS-7",
                    "chosenOptionIds": [
                        "ac713a60-0f92-4cc3-9c86-4f42886d44d2"
                    ]
                }
            }
        },
        {
            "name": "PDSS score 0.75-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.75,
                "threshold": 0
            },
            "answers": {
                "PDSS-1": {
                    "questionId": "PDSS-1",
                    "chosenOptionIds": [
                        "4211cb00-758a-4e12-b9c7-0cb2b14f4348"
                    ]
                },
                "PDSS-2": {
                    "questionId": "PDSS-2",
                    "chosenOptionIds": [
                        "5cc4e100-1db9-4001-9075-1f6d3cca1e74"
                    ]
                },
                "PDSS-3": {
                    "questionId": "PDSS-3",
                    "chosenOptionIds": [
                        "f38af45e-6512-43e8-8c73-37ebe779430e"
                    ]
                },
                "PDSS-4": {
                    "questionId": "PDSS-4",
                    "chosenOptionIds": [
                        "521ea5ea-2f25-485e-906c-89ffcc87c7ff"
                    ]
                },
                "PDSS-5": {
                    "questionId": "PDSS-5",
                    "chosenOptionIds": [
                        "a2e0dc6f-3263-4aef-9e01-9c5008ddf01a"
                    ]
                },
                "PDSS-6": {
                    "questionId": "PDSS-6",
                    "chosenOptionIds": [
                        "61e650df-25c6-4f5b-81ee-abe313f978a4"
                    ]
                },
                "PDSS-7": {
                    "questionId": "PDSS-7",
                    "chosenOptionIds": [
                        "1185c9fe-36c4-4606-bbb6-d1d28b0ecea3"
                    ]
                }
            }
        },
        {
            "name": "PDSS score 0.25-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.25,
                "threshold": 0
            },
            "answers": {
                "PDSS-1": {
                    "questionId": "PDSS-1",
                    "chosenOptionIds": [
                        "e1d73a6c-685b-4815-8faf-77b486005ef5"
                    ]
                },
                "PDSS-2": {
                    "questionId": "PDSS-2",
                    "chosenOptionIds": [
                        "2934c4fc-f86a-4d57-b894-a5ab891924e8"
                    ]
                },
                "PDSS-3": {
                    "questionId": "PDSS-3",
                    "chosenOptionIds": [
                        "e58937b8-ee9f-4d49-896e-acb1b90fa416"
                    ]
                },
                "PDSS-4": {
                    "questionId": "PDSS-4",
                    "chosenOptionIds": [
                        "b04d02dc-761a-41c3-92b9-be551225c2ee"
                    ]
                },
                "PDSS-5": {
                    "questionId": "PDSS-5",
                    "chosenOptionIds": [
                        "3fbfa4bb-0dc9-4ecb-9bc5-24f4636c5dd4"
                    ]
                },
                "PDSS-6": {
                    "questionId": "PDSS-6",
                    "chosenOptionIds": [
                        "318f73b5-24ed-4f7d-a4dd-4deabd5bdfc4"
                    ]
                },
                "PDSS-7": {
                    "questionId": "PDSS-7",
                    "chosenOptionIds": [
                        "4d83337c-c2f6-4f63-bb63-37195627a451"
                    ]
                }
            }
        },
        {
            "name": "PDSS partial score 1-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.5357142857142857,
                "threshold": 0
            },
            "answers": {
                "PDSS-1": {
                    "questionId": "PDSS-1",
                    "chosenOptionIds": [
                        "400c315c-20fd-4d71-a4cd-a5db49f77090"
                    ]
                },
                "PDSS-2": {
                    "questionId": "PDSS-2",
                    "chosenOptionIds": [
                        "cee669a3-1834-4aa9-ac03-de9a3f71ef80"
                    ]
                },
                "PDSS-3": {
                    "questionId": "PDSS-3",
                    "chosenOptionIds": [
                        "f38af45e-6512-43e8-8c73-37ebe779430e"
                    ]
                },
                "PDSS-4": {
                    "questionId": "PDSS-4",
                    "chosenOptionIds": [
                        "b04d02dc-761a-41c3-92b9-be551225c2ee"
                    ]
                },
                "PDSS-5": {
                    "questionId": "PDSS-5",
                    "chosenOptionIds": [
                        "a2e0dc6f-3263-4aef-9e01-9c5008ddf01a"
                    ]
                },
                "PDSS-6": {
                    "questionId": "PDSS-6",
                    "chosenOptionIds": [
                        "61e650df-25c6-4f5b-81ee-abe313f978a4"
                    ]
                },
                "PDSS-7": {
                    "questionId": "PDSS-7",
                    "chosenOptionIds": [
                        "1185c9fe-36c4-4606-bbb6-d1d28b0ecea3"
                    ]
                }
            }
        },
        {
            "name": "PDSS partial score 2-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.5357142857142857,
                "threshold": 0
            },
            "answers": {
                "PDSS-1": {
                    "questionId": "PDSS-1",
                    "chosenOptionIds": [
                        "83ebdd37-1eae-471b-a076-440b62fc9155"
                    ]
                },
                "PDSS-2": {
                    "questionId": "PDSS-2",
                    "chosenOptionIds": [
                        "2934c4fc-f86a-4d57-b894-a5ab891924e8"
                    ]
                },
                "PDSS-3": {
                    "questionId": "PDSS-3",
                    "chosenOptionIds": [
                        "d3ad960a-dbd0-4108-bb50-ae545eff0684"
                    ]
                },
                "PDSS-4": {
                    "questionId": "PDSS-4",
                    "chosenOptionIds": [
                        "521ea5ea-2f25-485e-906c-89ffcc87c7ff"
                    ]
                },
                "PDSS-5": {
                    "questionId": "PDSS-5",
                    "chosenOptionIds": [
                        "3fbfa4bb-0dc9-4ecb-9bc5-24f4636c5dd4"
                    ]
                },
                "PDSS-6": {
                    "questionId": "PDSS-6",
                    "chosenOptionIds": [
                        "f3954559-4580-4134-b37c-ec5de6d3703b"
                    ]
                },
                "PDSS-7": {
                    "questionId": "PDSS-7",
                    "chosenOptionIds": [
                        "ac713a60-0f92-4cc3-9c86-4f42886d44d2"
                    ]
                }
            }
        },
        {
            "name": "PDSS partial score 3-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.4642857142857143,
                "threshold": 0
            },
            "answers": {
                "PDSS-1": {
                    "questionId": "PDSS-1",
                    "chosenOptionIds": [
                        "5d995fdb-9ca9-49b9-936a-f3b7730b10c9"
                    ]
                },
                "PDSS-2": {
                    "questionId": "PDSS-2",
                    "chosenOptionIds": [
                        "2934c4fc-f86a-4d57-b894-a5ab891924e8"
                    ]
                },
                "PDSS-3": {
                    "questionId": "PDSS-3",
                    "chosenOptionIds": [
                        "d3ad960a-dbd0-4108-bb50-ae545eff0684"
                    ]
                },
                "PDSS-4": {
                    "questionId": "PDSS-4",
                    "chosenOptionIds": [
                        "521ea5ea-2f25-485e-906c-89ffcc87c7ff"
                    ]
                },
                "PDSS-5": {
                    "questionId": "PDSS-5",
                    "chosenOptionIds": [
                        "7d91c8cf-52bd-4f54-ab09-8b9ee3b5f51d"
                    ]
                },
                "PDSS-6": {
                    "questionId": "PDSS-6",
                    "chosenOptionIds": [
                        "f3954559-4580-4134-b37c-ec5de6d3703b"
                    ]
                },
                "PDSS-7": {
                    "questionId": "PDSS-7",
                    "chosenOptionIds": [
                        "4d83337c-c2f6-4f63-bb63-37195627a451"
                    ]
                }
            }
        },
        {
            "name": "PDSS Normal score-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.035714285714285,
                "threshold": 0
            },
            "answers": {
                "PDSS-1": {
                    "questionId": "PDSS-1",
                    "chosenOptionIds": [
                        "e1d73a6c-685b-4815-8faf-77b486005ef5"
                    ]
                },
                "PDSS-2": {
                    "questionId": "PDSS-2",
                    "chosenOptionIds": [
                        "cee669a3-1834-4aa9-ac03-de9a3f71ef80"
                    ]
                },
                "PDSS-3": {
                    "questionId": "PDSS-3",
                    "chosenOptionIds": [
                        "8669cfb6-f317-4797-a327-6c1a34a1b82a"
                    ]
                },
                "PDSS-4": {
                    "questionId": "PDSS-4",
                    "chosenOptionIds": [
                        "79205eda-1ca1-4f31-b8d4-e7a052d7625e"
                    ]
                },
                "PDSS-5": {
                    "questionId": "PDSS-5",
                    "chosenOptionIds": [
                        "9adb1279-2eb9-4a6b-8e79-8b438fc561bf"
                    ]
                },
                "PDSS-6": {
                    "questionId": "PDSS-6",
                    "chosenOptionIds": [
                        "628e4aef-df14-45b8-b8f4-15327275308a"
                    ]
                },
                "PDSS-7": {
                    "questionId": "PDSS-7",
                    "chosenOptionIds": [
                        "1881985e-884a-418d-940e-fec9cb872729"
                    ]
                }
            }
        },
        {
            "name": "PDSS Borderline score-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.07142857142857142,
                "threshold": 0
            },
            "answers": {
                "PDSS-1": {
                    "questionId": "PDSS-1",
                    "chosenOptionIds": [
                        "400c315c-20fd-4d71-a4cd-a5db49f77090"
                    ]
                },
                "PDSS-2": {
                    "questionId": "PDSS-2",
                    "chosenOptionIds": [
                        "cee669a3-1834-4aa9-ac03-de9a3f71ef80"
                    ]
                },
                "PDSS-3": {
                    "questionId": "PDSS-3",
                    "chosenOptionIds": [
                        "8669cfb6-f317-4797-a327-6c1a34a1b82a"
                    ]
                },
                "PDSS-4": {
                    "questionId": "PDSS-4",
                    "chosenOptionIds": [
                        "79205eda-1ca1-4f31-b8d4-e7a052d7625e"
                    ]
                },
                "PDSS-5": {
                    "questionId": "PDSS-5",
                    "chosenOptionIds": [
                        "9adb1279-2eb9-4a6b-8e79-8b438fc561bf"
                    ]
                },
                "PDSS-6": {
                    "questionId": "PDSS-6",
                    "chosenOptionIds": [
                        "628e4aef-df14-45b8-b8f4-15327275308a"
                    ]
                },
                "PDSS-7": {
                    "questionId": "PDSS-7",
                    "chosenOptionIds": [
                        "1881985e-884a-418d-940e-fec9cb872729"
                    ]
                }
            }
        },
        {
            "name": "PDSS slightly ill score-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.214285714285714,
                "threshold": 0
            },
            "answers": {
                "PDSS-1": {
                    "questionId": "PDSS-1",
                    "chosenOptionIds": [
                        "e1d73a6c-685b-4815-8faf-77b486005ef5"
                    ]
                },
                "PDSS-2": {
                    "questionId": "PDSS-2",
                    "chosenOptionIds": [
                        "2934c4fc-f86a-4d57-b894-a5ab891924e8"
                    ]
                },
                "PDSS-3": {
                    "questionId": "PDSS-3",
                    "chosenOptionIds": [
                        "e58937b8-ee9f-4d49-896e-acb1b90fa416"
                    ]
                },
                "PDSS-4": {
                    "questionId": "PDSS-4",
                    "chosenOptionIds": [
                        "b04d02dc-761a-41c3-92b9-be551225c2ee"
                    ]
                },
                "PDSS-5": {
                    "questionId": "PDSS-5",
                    "chosenOptionIds": [
                        "3fbfa4bb-0dc9-4ecb-9bc5-24f4636c5dd4"
                    ]
                },
                "PDSS-6": {
                    "questionId": "PDSS-6",
                    "chosenOptionIds": [
                        "318f73b5-24ed-4f7d-a4dd-4deabd5bdfc4"
                    ]
                },
                "PDSS-7": {
                    "questionId": "PDSS-7",
                    "chosenOptionIds": [
                        "1881985e-884a-418d-940e-fec9cb872729"
                    ]
                }
            }
        },
        {
            "name": "PDSS moderately ill score-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.42857142857142,
                "threshold": 0
            },
            "answers": {
                "PDSS-1": {
                    "questionId": "PDSS-1",
                    "chosenOptionIds": [
                        "400c315c-20fd-4d71-a4cd-a5db49f77090"
                    ]
                },
                "PDSS-2": {
                    "questionId": "PDSS-2",
                    "chosenOptionIds": [
                        "c0a8f6c2-e3a2-4845-8a0a-2507d6dfc376"
                    ]
                },
                "PDSS-3": {
                    "questionId": "PDSS-3",
                    "chosenOptionIds": [
                        "d3ad960a-dbd0-4108-bb50-ae545eff0684"
                    ]
                },
                "PDSS-4": {
                    "questionId": "PDSS-4",
                    "chosenOptionIds": [
                        "415bdce9-02cf-417c-ad85-208fd126b0ee"
                    ]
                },
                "PDSS-5": {
                    "questionId": "PDSS-5",
                    "chosenOptionIds": [
                        "9e0d3762-2451-4300-b9f1-d61add169c74"
                    ]
                },
                "PDSS-6": {
                    "questionId": "PDSS-6",
                    "chosenOptionIds": [
                        "f3954559-4580-4134-b37c-ec5de6d3703b"
                    ]
                },
                "PDSS-7": {
                    "questionId": "PDSS-7",
                    "chosenOptionIds": [
                        "1881985e-884a-418d-940e-fec9cb872729"
                    ]
                }
            }
        },
        {
            "name": "PDSS markedly ill score-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.5,
                "threshold": 0
            },
            "answers": {
                "PDSS-1": {
                    "questionId": "PDSS-1",
                    "chosenOptionIds": [
                        "400c315c-20fd-4d71-a4cd-a5db49f77090"
                    ]
                },
                "PDSS-2": {
                    "questionId": "PDSS-2",
                    "chosenOptionIds": [
                        "c0a8f6c2-e3a2-4845-8a0a-2507d6dfc376"
                    ]
                },
                "PDSS-3": {
                    "questionId": "PDSS-3",
                    "chosenOptionIds": [
                        "d3ad960a-dbd0-4108-bb50-ae545eff0684"
                    ]
                },
                "PDSS-4": {
                    "questionId": "PDSS-4",
                    "chosenOptionIds": [
                        "415bdce9-02cf-417c-ad85-208fd126b0ee"
                    ]
                },
                "PDSS-5": {
                    "questionId": "PDSS-5",
                    "chosenOptionIds": [
                        "9e0d3762-2451-4300-b9f1-d61add169c74"
                    ]
                },
                "PDSS-6": {
                    "questionId": "PDSS-6",
                    "chosenOptionIds": [
                        "61e650df-25c6-4f5b-81ee-abe313f978a4"
                    ]
                },
                "PDSS-7": {
                    "questionId": "PDSS-7",
                    "chosenOptionIds": [
                        "4d83337c-c2f6-4f63-bb63-37195627a451"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {};
