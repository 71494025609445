"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "{}",
            "functionId": "Pcl5Severity"
        },
        "section": [
            "Outcome"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "PCL5-3"
            ],
            "prefix": "",
            "formatText": "Briefly identify the worst event if you feel comfortable doing so | {}"
        },
        "section": [
            "Criterion A questions/answers"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "PCL5-4"
            ],
            "prefix": "",
            "formatText": "When did it happen | {} ago"
        },
        "section": [
            "Criterion A questions/answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Did it involve actual or threatened death, serious injury, or sexual violence? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCL5-5",
                    "optionSummaries": {
                        "a750efe2-187a-484c-af45-175f4e37afc8": "No",
                        "4a080bac-136a-4c5f-9852-6e72b540fb26": "Yes"
                    }
                }
            ]
        },
        "section": [
            "Criterion A questions/answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "How did you experience it? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCL5-6",
                    "optionSummaries": {
                        "29653428-70cf-4748-8c36-c7ce734287fe": "Has happened to them directly",
                        "06e1848b-3530-499d-80ec-0707bcd2481c": "Has witnessed it",
                        "15fa4f60-4d1f-4d7a-966d-63477576aa06": "Has learned about it happening to a close family member or close friend",
                        "11771260-9976-4351-8f10-eab976660b51": "Has repeatedly been exposed to details about it as part of their job",
                        "1515d0bd-470f-46ea-899e-05e698b09936": "Other"
                    }
                }
            ]
        },
        "section": [
            "Criterion A questions/answers"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "PCL5-7"
            ],
            "prefix": "",
            "formatText": "Please describe how you experienced it | {}"
        },
        "section": [
            "Criterion A questions/answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "If the event involved the death of a close family member or close friend, was it due to some kind of accident or violence, or was it due to natural causes? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCL5-8",
                    "optionSummaries": {
                        "72b04d10-4093-431e-a3d3-2e83b26f919a": "Accident or violence",
                        "b0834cc6-941d-4385-a92b-4508f55ced4a": "Natural causes",
                        "ec7dcc2d-25a2-4c2d-b1d3-859d89f2db42": "Not applicable (the event did not involve the death of a close family member or close friend)"
                    }
                }
            ]
        },
        "section": [
            "Criterion A questions/answers"
        ]
    },
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "{}",
            "functionId": "Pcl5ScoringKey"
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "1. Repeated, disturbing, and unwanted memories of the stressful experience? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCL5-9",
                    "optionSummaries": {
                        "4c558e02-1c22-4b83-81f6-8da4e0e15635": "0 - Not at all",
                        "cd6ece9f-eb4a-467e-aa47-1c4a55e07ffe": "1 - A little bit",
                        "87ba395f-7df9-431a-801c-fe6913df2057": "2 - Moderately",
                        "610c7126-ce79-4539-b299-a5da35987db6": "3 - Quite a bit",
                        "108d3ccb-56cf-4341-a883-7c46afcafc94": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "2. Repeated, disturbing dreams of the stressful experience? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCL5-10",
                    "optionSummaries": {
                        "219e670f-faee-4a70-8899-a137f731f9d8": "0 - Not at all",
                        "51d051e5-384c-4e97-a75e-25b7c90d025a": "1 - A little bit",
                        "6d5d4837-d157-4210-9bab-f1a4c0c80ff4": "2 - Moderately",
                        "240ed192-3005-49e2-b88f-6774b831fca1": "3 - Quite a bit",
                        "acfd7d15-8601-4074-a0c1-6da741aada9d": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "3. Suddenly feeling or acting as if the stressful experience were actually happening again? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCL5-11",
                    "optionSummaries": {
                        "701a12ce-9bc7-4ee2-854c-4bc8e9e216c4": "0 - Not at all",
                        "5d362f0c-d6d3-42b8-85db-beae4bfed563": "1 - A little bit",
                        "36789157-4c84-40fc-9777-40c133337fda": "2 - Moderately",
                        "ba3f1cf2-5f2c-42ff-8d66-6d3eda7b6650": "3 - Quite a bit",
                        "a53e12cb-a35f-47ec-9b78-4f19060bb837": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "4. Feeling very upset when something reminded you of the stressful experience? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCL5-12",
                    "optionSummaries": {
                        "44bf8244-e179-44ca-97fa-937b060f98c5": "0 - Not at all",
                        "a4f53107-4eac-4369-8b29-023a1d2fbd7b": "1 - A little bit",
                        "3e680d86-7e2c-478e-a7ac-d6d591bf679c": "2 - Moderately",
                        "df0a07ed-198c-41f6-bbcd-66488127306b": "3 - Quite a bit",
                        "c57f3792-c931-42e8-852b-8e8812cc00fa": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "5. Having strong physical reactions when something reminded you of the stressful experience (e.g. heart pounding, trouble breathing, sweating)? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCL5-13",
                    "optionSummaries": {
                        "17c10d9b-c054-496d-9251-9c4f05d63654": "0 - Not at all",
                        "65e968c5-994f-4ae7-b89c-952e06bb15b4": "1 - A little bit",
                        "4f2f11a8-22ec-4464-a813-dc45632207a8": "2 - Moderately",
                        "dc3db2bd-22d6-4cc9-879b-50d1bee3114a": "3 - Quite a bit",
                        "cd16b05f-da14-4e08-b218-20b2025c8b7a": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "6. Avoiding memories, thoughts, or feelings related to the stressful experience? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCL5-14",
                    "optionSummaries": {
                        "80b53b93-f3a8-4b03-800f-84b57364ca53": "0 - Not at all",
                        "dcc1f7f1-4824-4362-a570-e05ff4c0095b": "1 - A little bit",
                        "55678193-1313-4caf-b4b8-621ad239c766": "2 - Moderately",
                        "48cea9b9-3889-4f9c-b120-52b6032ee46a": "3 - Quite a bit",
                        "6b8ee38e-ed89-42c6-9b56-0182cc25eed4": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "7. Avoiding external reminders of the stressful experience (e.g. people, places, conversations)? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCL5-15",
                    "optionSummaries": {
                        "2258fa4c-c35f-4c99-8946-e44ad37cea2e": "0 - Not at all",
                        "5132d86c-6b5a-41e2-be12-dec9f0202939": "1 - A little bit",
                        "d3121f15-462d-49f7-88e9-955de3cac7a0": "2 - Moderately",
                        "594106bc-5213-4f54-9ae3-b7d72306d309": "3 - Quite a bit",
                        "ba9203ce-72a8-42c0-8f44-b0f816d1fe8c": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "8. Trouble remembering important parts of the stressful experience? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCL5-16",
                    "optionSummaries": {
                        "03a782ce-fde1-4cc1-9168-35e828bb394c": "0 - Not at all",
                        "0aa47162-1a0a-4877-98c7-10b474f74bea": "1 - A little bit",
                        "bc2cb342-f778-4244-99fb-2457cb969a95": "2 - Moderately",
                        "b4e3452a-ec13-44c0-a808-5d00aac84d7b": "3 - Quite a bit",
                        "fd95f6f0-b153-4c81-b678-5051909266d9": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "9. Having strong negative beliefs about yourself, other people, or the world (e.g. I am bad, no one can be trusted)? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCL5-17",
                    "optionSummaries": {
                        "7660ddcf-ee6b-4d00-a32b-ed4590652833": "0 - Not at all",
                        "dbd690e1-4d01-49d4-b258-2e811073d491": "1 - A little bit",
                        "be8bdec9-b349-4226-9135-f8c2c3245505": "2 - Moderately",
                        "f82bcaef-57ac-422a-840a-f1a5c76f4d11": "3 - Quite a bit",
                        "2d116a4e-f0ee-4bfd-8dfb-314c5f7f04bc": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "10. Blaming yourself or someone else for the stressful experience or what happened after it? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCL5-18",
                    "optionSummaries": {
                        "758b44bf-45fe-442b-b11b-866679996790": "0 - Not at all",
                        "5a192e94-514a-4fc7-bde7-ec2cc3cbb24b": "1 - A little bit",
                        "e3bbb3aa-9834-428e-a793-24f7a813baa7": "2 - Moderately",
                        "149a4d50-4f36-43cc-8517-2bc2b2f087c4": "3 - Quite a bit",
                        "b5a22847-ac34-48fd-9a2b-681d8fd77561": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "11. Having strong negative feelings such as fear, horror, anger, guilt, or shame? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCL5-19",
                    "optionSummaries": {
                        "609ea34e-b459-48a2-a5ca-873720d3c49a": "0 - Not at all",
                        "ff50ea11-b2f9-46bd-8382-0345ae8247ec": "1 - A little bit",
                        "13476c73-f84b-4ede-a535-381c11deefbb": "2 - Moderately",
                        "86c5d10e-7baf-4727-b6c9-8e9550059c9d": "3 - Quite a bit",
                        "622a39e9-4f94-44de-bdab-84bfaed9d72c": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "12. Loss of interest in activities you used to enjoy? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCL5-20",
                    "optionSummaries": {
                        "608a47f0-6424-4384-bb17-5f27dedac17f": "0 - Not at all",
                        "270065ca-261a-4a2f-b0de-c923c5580846": "1 - A little bit",
                        "81bc02c7-3f91-4580-b4ec-87e522ecaa73": "2 - Moderately",
                        "ebeea7ad-e26b-4693-9ddf-67b439263579": "3 - Quite a bit",
                        "12745236-86fe-4692-ad41-c9affd672be1": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "13. Feeling distant or cut off from other people? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCL5-21",
                    "optionSummaries": {
                        "3ac0782a-e24c-4fc6-be3b-4be63d486889": "0 - Not at all",
                        "0cd4339d-2c07-494e-becf-b19f6b592345": "1 - A little bit",
                        "3f2f3a69-c8ef-49a0-abde-6375982ab6c1": "2 - Moderately",
                        "babf6984-9156-4184-950f-38822c526406": "3 - Quite a bit",
                        "427c8b3f-2c9d-485c-b42d-25db655b59fb": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "14. Trouble experiencing positive feelings (e.g. being unable to feel happiness)? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCL5-22",
                    "optionSummaries": {
                        "42d9e562-0631-4c70-b373-eb14b6a43cab": "0 - Not at all",
                        "28acc69c-f091-4748-b36d-f550138bad37": "1 - A little bit",
                        "bfd7cb7d-fa27-4636-8ba5-e42826c007f6": "2 - Moderately",
                        "edb29ac9-0876-4117-af74-bab0048a7ac5": "3 - Quite a bit",
                        "f7b8291c-6375-4db9-b3fc-4d80dd45e300": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "15. Irritable behavior, angry outbursts, or acting aggressively? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCL5-23",
                    "optionSummaries": {
                        "ab79f56c-f3e5-4538-8e30-eac5d47b355a": "0 - Not at all",
                        "f3d1f052-bc53-4fc2-8245-5793fcbe807d": "1 - A little bit",
                        "d5252eea-04f4-44cc-a5f6-c49401ec9046": "2 - Moderately",
                        "7820d980-aa11-4285-8e80-0b896109ddd7": "3 - Quite a bit",
                        "6ea01e19-fae7-44e2-840b-c9b7773405cf": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "16. Taking too many risks or doing things that could cause you harm? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCL5-24",
                    "optionSummaries": {
                        "d868f251-b355-4453-81f6-18083545a854": "0 - Not at all",
                        "cbbe20b0-a321-4869-8195-47d15a68c204": "1 - A little bit",
                        "159f4559-657b-46ba-9c69-6229e2384bac": "2 - Moderately",
                        "3ddc23a6-7fcd-4c11-8f78-5542ff6ff6f2": "3 - Quite a bit",
                        "329b493c-fb60-4e94-b7fa-5182013ed05d": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "17. Being “superalert,” watchful, or on guard? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCL5-25",
                    "optionSummaries": {
                        "34923662-4c68-482f-b23d-261a47127613": "0 - Not at all",
                        "ad0a4d30-3651-4287-ad1c-f63b7423d6b3": "1 - A little bit",
                        "8b7f2cbf-45f8-4bc9-aa98-eaa79d1205fa": "2 - Moderately",
                        "9c09e58d-ec22-4b67-a9a2-efc7e8274b79": "3 - Quite a bit",
                        "ae3a687f-2750-46af-8551-2220b6c27f26": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "18. Feeling jumpy or easily startled? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCL5-26",
                    "optionSummaries": {
                        "43fa5958-ae0f-4a4c-9d3f-2d5dec0aeaae": "0 - Not at all",
                        "a46cf2c1-fd3a-43cc-b47a-a8a3db9cdf06": "1 - A little bit",
                        "fb73bd09-c6ae-4523-af8c-694c7435c3b9": "2 - Moderately",
                        "3ff70490-270d-441f-b84e-cfaf5a58397f": "3 - Quite a bit",
                        "9d0ec9dc-2abb-4b84-b9c4-56cfb810da3d": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "19. Having difficulty concentrating? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCL5-27",
                    "optionSummaries": {
                        "1921b4d6-329e-4ea1-97a6-ce72fa0f3b00": "0 - Not at all",
                        "6b438b73-b62b-4f9e-af74-b134ead7c3bc": "1 - A little bit",
                        "414a18c3-80df-4404-b2b7-6bba97ad38b9": "2 - Moderately",
                        "cf882678-2d70-4107-8825-0f296b0d8850": "3 - Quite a bit",
                        "aa53e279-f17e-462d-ba63-66600b3d9161": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "20. Trouble falling or staying asleep? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PCL5-28",
                    "optionSummaries": {
                        "b3134d9a-497d-4889-8a40-41c15f69a379": "0 - Not at all",
                        "d94a2d11-79d4-4a91-9d4b-6b4715cd3910": "1 - A little bit",
                        "4d18980b-e8d4-4882-95a1-e911c705ea1e": "2 - Moderately",
                        "cf4a57d6-4360-44d7-a23f-85c9230082af": "3 - Quite a bit",
                        "45062077-8442-43fa-8e45-eff57043045e": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    }
];
