import { isInternationalDemo } from './locale'

export const getAboutYouMessage = (isD2CUser: boolean, showNHSRelatedText: boolean): string => {
    if (isD2CUser) {
        return 'This will be shown on your report once you have finished the questionnaire.'
    } else if (isInternationalDemo() || !showNHSRelatedText) {
        return 'Before you begin, we need to confirm some details.'
    } else {
        return 'Before you begin, we need to confirm some details with your NHS record.'
    }
}

export const getYourNameMessage = (isD2CUser: boolean, showNHSRelatedText: boolean): string => {
    if (isD2CUser) {
        return 'Please enter your preferred first and last name, this will be used on your report.'
    }

    if (isInternationalDemo() || !showNHSRelatedText) {
        return 'Please enter your first and last name.'
    } else {
        return 'Please enter your first and last name as it appears on your NHS record.'
    }
}
