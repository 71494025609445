"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateSubscaleMean = void 0;
const calculateScoreFromNormalized_1 = require("./calculateScoreFromNormalized");
const toFormattedMean_1 = require("./toFormattedMean");
const calculateSubscaleMean = (allQuestions, answers, subscaleQuestionIds, normalizationFactor) => {
    const subscaleQuestions = allQuestions.filter(({ id }) => subscaleQuestionIds.includes(id));
    const answeredQuestions = answers.filter(({ questionId }) => subscaleQuestionIds.includes(questionId));
    const subTotal = (0, calculateScoreFromNormalized_1.calculateScoreFromNormalized)(answeredQuestions, subscaleQuestionIds, allQuestions, normalizationFactor);
    return subTotal !== undefined ? (0, toFormattedMean_1.toFormattedMean)(subTotal, subscaleQuestions.length) : '-';
};
exports.calculateSubscaleMean = calculateSubscaleMean;
