import { gql } from '@apollo/client'
import { Question } from 'censeo-core'
import { DocumentNode } from 'graphql'

const QUESTION_FRAGMENT = gql`
    fragment QuestionFields on Question {
        ... on FreeQuestion {
            id
            __typename
            text
            answer
            skippable
            unit
        }
        ... on ContentQuestion {
            id
            __typename
            text
            answers {
                id
                text
                primary
            }
            chosenOptionIds
        }
        ... on RestrictedChoiceQuestion {
            id
            __typename
            text
            answers {
                id
                text
                exclusive
            }
            isMultiselect
            chosenOptionIds
        }
        ... on FreeTextQuestion {
            id
            __typename
            text
            answerText
            mandatory
            skippable
        }
    }
`
export const questionQuery: DocumentNode = gql`
    query Question($id: ID!) {
        question(id: $id) {
            ...QuestionFields
        }
    }
    ${QUESTION_FRAGMENT}
`

export const demoQuestionQuery: DocumentNode = gql`
    query DemoQuestion($demoType: DemoType!, $allAnswers: [AnsweredQuestion!]!, $id: ID!) {
        demoQuestion(demoType: $demoType, allAnswers: $allAnswers, id: $id) {
            ...QuestionFields
        }
    }
    ${QUESTION_FRAGMENT}
`

export interface StandardQuestionData {
    question: Question
}
export interface DemoQuestionData {
    demoQuestion: Question
}
export type QuestionData = StandardQuestionData | DemoQuestionData
