"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
const censeo_1 = require("../../schema/generated/censeo");
exports.questions = [
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "44fd9eb3-aee2-5af0-b83c-dbbbf9514022",
                "text": "Depression (also know as major depressive disorder)"
            },
            {
                "__typename": "Option",
                "id": "ff95cad7-222f-53dd-b92b-d5ddc6b6ac5a",
                "text": "Bipolar disorder (formerly known as manic depression)"
            },
            {
                "__typename": "Option",
                "id": "07106ab9-5949-5b5f-bc96-117139ebd397",
                "text": "Generalised anxiety disorder"
            },
            {
                "__typename": "Option",
                "id": "f52974b4-d0c2-589c-88d8-8628f43a2f5e",
                "text": "A personality disorder"
            },
            {
                "__typename": "Option",
                "id": "6a4d8b43-be3d-59b5-9bf4-1ca9f35b6973",
                "text": "Post-traumatic stress disorder"
            },
            {
                "__typename": "Option",
                "id": "8c599171-d554-5547-9f54-2066c1a711dd",
                "text": "Insomnia"
            },
            {
                "__typename": "Option",
                "id": "4896b504-2025-52c3-85e2-c6cf3205cef7",
                "text": "Obsessive compulsive disorder (OCD)"
            },
            {
                "__typename": "Option",
                "id": "fdbdbcd2-e188-5502-8ad3-c8a2bcf78b6c",
                "text": "Schizophrenia"
            },
            {
                "__typename": "Option",
                "id": "f1833a31-d36a-53f7-91f7-a677ac081e7c",
                "text": "Panic disorder"
            },
            {
                "__typename": "Option",
                "id": "afcb6534-a0a0-5fe6-9729-1269be20cdf3",
                "text": "Social phobia/social anxiety disorder"
            },
            {
                "__typename": "Option",
                "id": "c2921864-bbf7-5735-9313-848fb9d5a6d1",
                "text": "An eating disorder"
            },
            {
                "__typename": "Option",
                "id": "fccec712-43ac-521b-a358-47fd975076c4",
                "text": "Attention deficit hyperactivity disorder"
            },
            {
                "__typename": "Option",
                "id": "46f0fc24-fa01-56a0-b5ed-dd47b5786b19",
                "text": "Autism spectrum disorder"
            },
            {
                "__typename": "Option",
                "id": "8f17d281-b1f1-57ca-9dbb-7e687e09bc26",
                "text": "Alcohol use disorder"
            },
            {
                "__typename": "Option",
                "id": "6db89a27-212e-5b14-a78c-5e0bdf1861de",
                "text": "Substance use disorder"
            },
            {
                "__typename": "Option",
                "id": "60fe0bb4-d770-56f2-b05a-864625a48e4d",
                "text": "I have not been diagnosed with any of the above",
                "exclusive": true
            }
        ],
        "id": "Dem11",
        "text": "Have you ever been diagnosed with any of the following? Select all that apply.",
        "isMultiselect": true
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "9d064e64-ec95-50b8-af10-5b9d39e9fb8d",
                "text": "I am feeling OK like my usual self",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "05653d8f-0bd2-5c1a-8a9f-fee81d9e4cfa",
                "text": "I am feeling low or depressed",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "db090d78-6247-560e-893c-e74389c1efc6",
                "text": "I am feeling manic",
                "value": 1
            }
        ],
        "id": "Dem18",
        "text": "\nSome people have periods of time when they feel \"manic\". They might experience **one or more of the following**:\n\n1. Feeling elated and excited\n\n2. Sleeping much less than usual, but still having lots of energy\n\n3. Feeling like their mind is going very fast, with lots of new ideas and plans\n\n4. Feeling much more sociable and active\n\n5. Feeling very restless or unable to sit still\n\n6. Doing things like driving too fast, spending a lot of money or taking risks.\n7. Feeling more agitated or irritable than normal\nSome people also have times when they are feeling low or depressed. They may feel sad, tired, hopeless and bad about themselves.\nWhich best describes how you are feeling? ",
        "isMultiselect": false
    },
    {
        "__typename": "ContentQuestion",
        "id": "BPS",
        "text": "This first section asks you some questions about how you are feeling at the moment and what is going on in your life. ",
        "answers": [
            {
                "__typename": "Option",
                "id": "e2984767-d667-5b32-9670-f54a4f34aebf",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "ContentQuestion",
        "id": "BP1",
        "text": "Take your time in answering the questions and remember you can take a break whenever you like.",
        "answers": [
            {
                "__typename": "Option",
                "id": "7053b295-aee4-5fb6-be13-c442378a9971",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "7599bcaa-89a5-5f71-80ea-b70c360bb44a",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ccbdcbec-96d0-5362-977b-784289f5cfc0",
                "text": "I think so in the past",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "83e6efd5-64a2-5f0e-a973-9278eae90cfb",
                "text": "Definitely in the past",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "6215a3de-a3e4-5f86-a039-1006646e6b93",
                "text": "I think I am currently feeling manic",
                "value": 1
            }
        ],
        "id": "BP1.5",
        "text": "Have you ever had a period of time when you have felt manic? ",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP2",
        "text": "How old were you when first felt symptoms of mania? It can be hard to tell so just give your best guess.",
        "unit": censeo_1.Unit.Age
    },
    {
        "__typename": "ContentQuestion",
        "id": "BP_neg1",
        "text": "We are going to ask some questions about the times you may have felt manic.\n\nSome of the questions may feel repetitive, but they are an important part of the assessment. Try to answer as best as you can.",
        "answers": [
            {
                "__typename": "Option",
                "id": "119208b0-ff3b-5c43-80be-4f07e65b6248",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "f03de28d-8f71-5af4-be2f-45f4e6092a3d",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP3",
        "text": "Have you experienced more than one period of time feeling manic? \n\nDo note it can feel different each time: you may feel happy and excited, or irritable and agitated. It can last anything from a few days to a few months. Sometimes it can be feel milder and sometimes it can feel more intense. If you are not sure if it counts, select \"yes\" anyway. ",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP3.01",
        "text": "How many different periods of mania do you think you have had?",
        "unit": censeo_1.Unit.None
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b56edfbb-cf11-50ee-9149-9e36f4cc1ea0",
                "text": "Less than 1 hour a day",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "5bf1e313-2624-5b5f-9673-37ae9319ff0c",
                "text": "1-3 hours a day",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "11a8ab35-b2d7-55a4-8a77-809dc8f1f78a",
                "text": "4-6 hours a day",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "9531c3ff-ab2b-5198-a490-919ede5afb07",
                "text": "7-9 hours a day",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "c31cf816-04e0-52f4-8da3-04437042c014",
                "text": "10-12 hours a day",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "c053dd45-79bd-59bf-82df-7e9ecd85dc11",
                "text": "Over 12 hours a day",
                "value": 1
            }
        ],
        "id": "BP3.1",
        "text": "Try to pinpoint the longest period of time that you felt manic. During that time, how much of a typical day did it affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "43011291-20a8-59ce-8bac-df882587ff5c",
                "text": "1 day a week",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ec333f8b-146a-5700-a15d-527032ceac3f",
                "text": "2–3 days a week",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "c3319113-403a-5ecb-aa8a-071221d92c50",
                "text": "4–5 days a week",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "f6654515-5729-5299-a4f0-da99056e7ac9",
                "text": "6–7 days a week",
                "value": 1
            }
        ],
        "id": "BP3.2",
        "text": "During this time, how many days a week did you feel manic for?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP3.3",
        "text": "How long was your longest period of time of feeling manic? It can be hard to remember but give your best estimate.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "93412936-97c0-5128-ad67-3fa955db1300",
                "text": "Not at all sure",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f304bf93-0c68-5da0-80ba-ccecc2d0fce1",
                "text": "Not very sure",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "6ec82bbf-7582-5509-ace2-5ecf0ce82ec3",
                "text": "Moderately sure",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "8eb41335-a8c4-5822-9881-b0383524cb1c",
                "text": "Very sure",
                "value": 1
            }
        ],
        "id": "BP3.4",
        "text": "It can be hard to remember the details of times when you feel manic. How sure are you about the length of this time? There is no right or wrong answer. ",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6b1af00b-b5de-5d59-bb4b-7e8d396d2138",
                "text": "Much more happy",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "9765aba8-7d55-5928-b79d-36aa58646036",
                "text": "Much more irritable and agitated",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "4884cc9c-7210-5173-9438-d1eae9e9a6a8",
                "text": "Much more elated, but also more irritable",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "f4705d21-04ac-574a-90f3-5e73c089a3ff",
                "text": "It's hard to pinpoint the change in my mood",
                "value": 1
            }
        ],
        "id": "BP4",
        "text": "Try to pinpoint your most extreme period of feeling manic. This is when the change in your mood and energy levels were most noticeable.\n\nHow did you feel during this time? ",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "66a3460c-65ce-5a4c-9774-2e303ae4961e",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "1be4b3b1-3a88-5544-af54-ebdfee1ce891",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP4.5",
        "text": "During this time did you feel higher in energy or that you wanted to do more activities than usual?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "1f743ff4-52a2-5104-aa5a-b38e75363021",
                "text": "Less than 1 hour a day",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "38e2ca3d-f06e-5ca5-b301-7906fdad6741",
                "text": "1-3 hours a day",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "9b037682-7f02-573a-88e8-662b5b9ae4ed",
                "text": "4-6 hours a day",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "e2cba9a7-5960-5839-88e2-6af7b8af3aa9",
                "text": "7-9 hours a day",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "9b30d461-df50-57fe-ba43-fbcaf82b25fc",
                "text": "10-12 hours a day",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "0f5b2a9a-603c-59a7-b70a-efb21f3e5ec6",
                "text": "Over 12 hours a day",
                "value": 1
            }
        ],
        "id": "BP5",
        "text": "During this time, how many hours of the day did it affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6a2ded69-f2ba-5374-b667-a542580d92ff",
                "text": "1 day a week",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c3c07e60-7b68-585c-8bc0-cbe7939a5c5f",
                "text": "2–3 days a week",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "e078853b-2d60-5ce8-925e-6f3693305c5d",
                "text": "4–5 days a week",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "8bf2b201-3339-5ac5-8365-e39aece2c3f4",
                "text": "6–7 days a week",
                "value": 1
            }
        ],
        "id": "BP5.1",
        "text": "How many days a week did it affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP5.2",
        "text": "How long did this most extreme period of feeling manic, last for? Just give your best estimate.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "495f2944-a25b-5bb8-aa89-c7b93e2bb0d4",
                "text": "Not at all sure",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a101d0bf-f19c-53f5-8a7c-0f91a4bc5c8a",
                "text": "Not very sure",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "5b752ce4-66ea-5f46-8d24-7cb297d53053",
                "text": "Moderately sure",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "8e31a32e-2ea1-52e2-aaff-3fd252051295",
                "text": "Very sure",
                "value": 1
            }
        ],
        "id": "BP5.3",
        "text": "It can be hard to remember the details of times when you feel manic. How sure are you about the length of this time? There is no right or wrong answer.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6560ae14-d813-51a0-b37b-cc9e44ecffaa",
                "text": "Less than 1 hour a day",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "742a22d6-a570-5e3a-8e72-c001f1e9f98b",
                "text": "1-3 hours a day",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "0f2295c8-9598-5e68-a9a6-c87ca1f600af",
                "text": "4-6 hours a day",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "33ae5abe-63ad-57d4-86a4-0dcd33576094",
                "text": "7-9 hours a day",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "29d4f06e-7031-5ec7-a155-41dd137ca140",
                "text": "10-12 hours a day",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "52f153db-76e7-57fa-90bd-63d5a923964a",
                "text": "Over 12 hours a day",
                "value": 1
            }
        ],
        "id": "BP6",
        "text": "During this time, how many hours of the day did it affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "77b4d620-93e4-5bc5-baad-4995d168f392",
                "text": "1 day a week",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "430b68c6-29cf-5a15-ab4c-89bd842383bf",
                "text": "2–3 days a week",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "a99773bd-2bff-523e-bc29-1f761307b3d4",
                "text": "4–5 days a week",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "f0b738f5-44c3-5a6c-9a31-ab7878568a81",
                "text": "6–7 days a week",
                "value": 1
            }
        ],
        "id": "BP6.1",
        "text": "How many days a week did you experience feeling manic for during this most extreme episode?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP6.2",
        "text": "How long did this period of mania last for? Just give your best estimate.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "df17fdbe-e756-5ece-ad3d-19cafeef31d1",
                "text": "Not at all sure",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a19e0696-e780-5235-88b9-f5c36ce0afc3",
                "text": "Not very sure",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "e061c578-086e-5990-bba6-279cb273f3a5",
                "text": "Moderately sure",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "94b6a547-3559-5363-971f-5eab65977d22",
                "text": "Very sure",
                "value": 1
            }
        ],
        "id": "BP6.3",
        "text": "It can be hard to remember the details of times when you feel manic. How sure are you about the length of this time? There is no right or wrong answer. ",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "f160a5be-9070-56d6-9163-15f172f107e0",
                "text": "Less than 1 hour a day",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d959af5c-40b7-5278-bdcc-66c3fd17ed8d",
                "text": "1-3 hours a day",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "58656f9f-9267-5c1d-9132-f342d05dc39e",
                "text": "4-6 hours a day",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "a2d6fa3e-e88a-53c9-9913-428837a5c3ee",
                "text": "7-9 hours a day",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "e96c7a38-d74e-5087-b14b-21d3177ce73e",
                "text": "10-12 hours a day",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "4678730f-670d-50ba-94cb-71d817698a0a",
                "text": "Over 12 hours a day",
                "value": 1
            }
        ],
        "id": "BP6.5",
        "text": "During this time, how many hours of the day did it affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "f3f2d7da-5c63-5e35-a1ab-729a174949c5",
                "text": "1 day a week",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "370c5f43-7c15-5f4f-b49a-8719bcb29c2a",
                "text": "2–3 days a week",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "7cb3515b-004f-58a1-a02b-59c7d9510637",
                "text": "4–5 days a week",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "ef07ce6c-9277-581a-bde7-14889359301b",
                "text": "6–7 days a week",
                "value": 1
            }
        ],
        "id": "BP6.7",
        "text": "How many days a week did it affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP6.8",
        "text": "How long did this period of mania last for? Try to give your best estimate.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8c8117af-9320-59a6-aa47-ebfe1f05ccc4",
                "text": "Not at all sure",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "155da231-22f3-50d9-8786-2860edd50aab",
                "text": "Not very sure",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "75d51935-7fc0-5e05-813c-62bbdd8f8335",
                "text": "Moderately sure",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "3f775bde-9e25-538e-bc79-7bcb0a91bc1f",
                "text": "Very sure",
                "value": 1
            }
        ],
        "id": "BP6.9",
        "text": "It can be hard to remember the details of times when you feel manic. How sure are you about the length of this time? There is no right or wrong answer. ",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "4ceb0625-db98-50f5-a099-c9df92ffa626",
                "text": "Less than 1 hour a day",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "0c0ab687-08ee-5bfd-8795-5e0284c1e430",
                "text": "1-3 hours a day",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "6369e481-8f15-557a-b9b6-4c06e0a3ec21",
                "text": "4-6 hours a day",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "f22cc4a7-6a1e-580d-b5a0-b44bd8cd2117",
                "text": "7-9 hours a day",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "f4e7e62d-742e-5c1b-b549-4b11a22c4be2",
                "text": "10-12 hours a day",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "32f283d2-1a05-54df-9d98-7639d2670c41",
                "text": "Over 12 hours a day",
                "value": 1
            }
        ],
        "id": "BP7",
        "text": "During this time, how many hours of the day did it affect you? ",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6b62060b-0aee-5ec9-beef-85c908359e3d",
                "text": "1 day a week",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c077c76d-70ea-541e-b93c-aa943ccde0c2",
                "text": "2–3 days a week",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "bedc40d1-6d09-5151-b80b-8db92115483b",
                "text": "4–5 days a week",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "6b0b2ebe-61e1-58f5-86ad-8c05d3a16679",
                "text": "6–7 days a week",
                "value": 1
            }
        ],
        "id": "BP7.1",
        "text": "How many days a week did it affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP7.2",
        "text": "How long did this most extreme episode of feeling energetic and proactive last for? Don't worry if you can't remember exactly. Please just give your best estimate.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "455f915a-3cf9-5453-8bdb-a0fc0d91ca60",
                "text": "Not at all sure",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "067ab1b6-912a-5137-997e-91f394b7bf9a",
                "text": "Not very sure",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "5830f812-10e2-579d-ba9a-721f817ddb0e",
                "text": "Moderately sure",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "2240ae5e-dfde-5c23-875f-7c0a746812b8",
                "text": "Very sure",
                "value": 1
            }
        ],
        "id": "BP7.3",
        "text": "It can be hard to remember the details of times when you feel manic. How sure are you about the length of this time? There is no right or wrong answer. ",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "87e0081b-9455-5ced-9225-10c2f70b1734",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "9b3c44f8-4468-5f37-86e9-ed0908e71d5c",
                "text": "I'm not sure",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "5d89a61f-a292-5fa7-b2ec-af892cf73338",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP8",
        "text": "Do you think these feelings were caused by any medication you were taking?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "5020bfdd-a280-56cf-b7f5-3b0b7e667165",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a5d71cc4-3c6f-5e37-a507-1dfa312124b8",
                "text": "I'm not sure",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "e12f28e7-b4ae-57af-96d7-8d83e672785a",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP8.01",
        "text": "Do you think these feelings were caused by drinking alcohol or drug taking?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e88670a3-c6d8-5c96-914e-b9fc1818aeda",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "1ff60197-2010-588f-bf6a-9be48f2b3359",
                "text": "I'm not sure",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "7d3a0aa5-605b-58d1-a834-073feefe8e5d",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP8.02",
        "text": "Do you think these feelings were caused by a physical illness you have?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "3568cdb1-2500-512b-aada-2547c5bfd835",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c7d02d20-9064-5a94-86d4-053ed361ed32",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP8.0",
        "text": "During this period of time, did you ever spend a night or more in hospital due to manic symptoms?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP8.1",
        "text": "How many times did you stay in hospital during this time? ",
        "unit": censeo_1.Unit.None
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP8.2",
        "text": "During this period, how long was your stay in hospital?",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP8.3",
        "text": "During this period, how long was your longest stay in hospital? ",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "16b49c5e-2955-5b04-997e-e975eb932180",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "eaa4eab0-aec1-5be4-832a-67ef473744d7",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP8.4",
        "text": "During this time have you ever had a formal/compulsory admission to hospital (i.e. been admitted against your will or 'sectioned') due to these symptoms?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "89a3d3f3-747b-59f8-9a32-311618df7607",
                "text": "More happy and high",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a",
                "text": "More irritable and agitated",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "74034187-2f1a-54cb-8bdf-1eb3572a286a",
                "text": "More elated and irritable",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "22f9a995-3f9a-5fe9-99bb-71b7f1f96621",
                "text": "More energetic and proactive",
                "value": 1
            }
        ],
        "id": "BP12",
        "text": "How did you feel during this past period of feeling manic?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "cd7ce00f-4e7c-57ac-9c6c-4137efa24c8c",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b7cc3eef-f36c-5fb4-9ebb-9ce64c834159",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP12.5",
        "text": "Did you feel more energetic or like you wanted to do more activities than usual?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a03b4df9-48f3-5151-b6a7-dd71bfcb2fdd",
                "text": "Less than 1 hour a day",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "6e3b2f0d-fb56-52bf-89c6-bb98b6e0cd3b",
                "text": "1-3 hours a day",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "1aa23278-1b6e-5950-91ec-0b2d3babbee3",
                "text": "4-6 hours a day",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "b0ebeb98-c11e-57a1-ba58-6457ca05c434",
                "text": "7-9 hours a day",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "8cd7d7ed-1de8-5155-81b5-85202bce3abc",
                "text": "10-12 hours a day",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "9818f960-c604-59a3-a1ad-372baff1b59b",
                "text": "Over 12 hours a day",
                "value": 1
            }
        ],
        "id": "BP13",
        "text": "During this time, how many hours of the day did it affect you? ",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "bf94a386-34ce-5e35-a1d4-cb50686e6e01",
                "text": "1 day a week",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7a2a48ee-f1b5-5c8d-974a-247da2a9f76b",
                "text": "2–3 days a week",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "632d3ebf-df58-52bd-9e1e-19deb67269bf",
                "text": "4–5 days a week",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "49f1206d-ce81-58fc-af76-30be0fffd7f5",
                "text": "6–7 days a week",
                "value": 1
            }
        ],
        "id": "BP13.1",
        "text": "How many days a week did it affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP13.2",
        "text": "How long did this period last for? Just give your best guess.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "d1bcedf2-2527-57c5-8faf-806dcacf0fdd",
                "text": "Not at all sure",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a170743a-0467-5fe9-8ae7-6bef7627cc74",
                "text": "Not very sure",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "54def841-d09f-54e5-9c8f-26b666a1bc37",
                "text": "Moderately sure",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "9b46bc6e-5c3a-52c6-a383-b7c685f72cee",
                "text": "Very sure",
                "value": 1
            }
        ],
        "id": "BP13.3",
        "text": "It can be hard to remember the details of times when you feel manic. How sure are you about the length of this time? There is no right or wrong answer.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "5585a7e8-a4b7-567e-b339-90537a58f1e3",
                "text": "Less than 1 hour a day",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "97bbe87b-c5ff-51c1-a99c-cec263cff7d4",
                "text": "1-3 hours a day",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "a3126449-b062-5872-80a1-5950acf6bfa4",
                "text": "4-6 hours a day",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "ac3ece45-bbdf-528a-9563-b82e962351e8",
                "text": "7-9 hours a day",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "dca91893-52f2-504e-b794-1f89dfa61ab8",
                "text": "10-12 hours a day",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "84994ca2-fcea-54fe-a7d6-9b44ec07f0bd",
                "text": "Over 12 hours a day",
                "value": 1
            }
        ],
        "id": "BP14",
        "text": "During this time, how many hours of the day did it affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "f2b7f127-8ab7-5609-8820-43158baee15c",
                "text": "1 day a week",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "69f9a784-ce6d-5c82-bd3f-4c9c44bf5992",
                "text": "2–3 days a week",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "e1b5b8f7-1317-5136-a8c1-2afdfa91e615",
                "text": "4–5 days a week",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "00462af7-705a-5635-a847-029a631411c0",
                "text": "6–7 days a week",
                "value": 1
            }
        ],
        "id": "BP14.1",
        "text": "How many days a week did it affect you for?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP14.2",
        "text": "How long did this period last for? Just give your best guess.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ac6d7839-60fb-5529-97b0-5d0f13ed20de",
                "text": "Not at all sure",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "9f5fc152-75fb-59d8-8f40-7a6ca15c69cb",
                "text": "Not very sure",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "d11770ab-32a3-560e-a110-52869d35ad5e",
                "text": "Moderately sure",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "8c8b64f5-0dae-51df-9e07-48317b805d3a",
                "text": "Very sure",
                "value": 1
            }
        ],
        "id": "BP14.3",
        "text": "It can be hard to remember the details of times when you feel manic. How sure are you about the length of this time? There is no right or wrong answer. ",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ce1ffa1a-f506-51cf-8d4e-869680808628",
                "text": "Less than 1 hour a day",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d7e78959-cebd-524d-9863-f3cb141abfb4",
                "text": "1-3 hours a day",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "3a2461f2-b999-59ae-a70a-cd3a034afd00",
                "text": "4-6 hours a day",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "8def5c3e-98b8-54b6-89f9-a4df19e76d66",
                "text": "7-9 hours a day",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "8a362b4c-6d0e-527a-8610-6572060f7476",
                "text": "10-12 hours",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "f2c52c87-ab50-5315-96ce-3528652a7a32",
                "text": "Over 12 hours a day",
                "value": 1
            }
        ],
        "id": "BP14.5",
        "text": "During this time, how much of the day did it affect you for?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "282c0f7e-ea67-578e-87b3-bfab84fbf0c0",
                "text": "1 day a week",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "e05fa18d-bc4f-5678-9692-644f2e2e1a3c",
                "text": "2–3 days a week",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "8c8a8e3b-6201-5a54-8a71-3d37f75d6d2d",
                "text": "4–5 days a week",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "d77159fb-9574-5a11-9e4f-9dccffb0db35",
                "text": "6–7 days a week",
                "value": 1
            }
        ],
        "id": "BP14.6",
        "text": "How many days per week did it affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP14.7",
        "text": "How long did this period of time last for? Just give your best guess.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "7b6f5669-2010-5ff6-84ad-f5d0422a5f52",
                "text": "Not at all sure",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "166cac26-72c2-51df-b8ce-2f014998b6c4",
                "text": "Not very sure",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "bb386470-f2f4-54ad-acb7-9f8a5b70c2d3",
                "text": "Moderately sure",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "e3666db0-f488-5b69-bdb7-46053d50b291",
                "text": "Very sure",
                "value": 1
            }
        ],
        "id": "BP14.8",
        "text": "It can be hard to remember the details of times when you feel manic. How sure are you about the length of this time? There is no right or wrong answer.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "1286eda2-5e31-52dc-95c5-78ded5b43b2a",
                "text": "Less than 1 hour a day",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "9a1158f1-f08a-581d-a088-650041097c24",
                "text": "1-3 hours a day",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "2dae4847-432c-5e66-9a74-3ded3e4eada9",
                "text": "4-6 hours a day",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "d8c37d8b-01b7-581e-9d97-f6c8b8bf9267",
                "text": "7-9 hours a day",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "54874bb5-1fab-5ab4-b9d4-bed8f8ea4d13",
                "text": "10-12 hours a day",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "e9643ff4-2842-5b40-9c98-5b5d0e9b6a5e",
                "text": "Over 12 hours a day",
                "value": 1
            }
        ],
        "id": "BP15",
        "text": "During this time, how much of the day did it affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "0fe6799b-72e6-5cd8-a921-d03ac42f803d",
                "text": "1 day a week",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "aa7bd0b8-95ca-5179-a037-3956a8c2816d",
                "text": "2–3 days a week",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "2fe2c05f-897a-55a4-876c-68763dede792",
                "text": "4–5 days a week",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "35a5b342-38f4-5343-8a4f-a3f024b95530",
                "text": "6–7 days a week",
                "value": 1
            }
        ],
        "id": "BP15.1",
        "text": "During this time, how many days a week did it affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP15.2",
        "text": "How long did this period of mania last for? Try to give your best guess.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "cfa519ff-08a9-5fa4-b12d-87b7a597cb13",
                "text": "Not at all sure",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "10414c02-0b51-5e7e-952f-8410debae858",
                "text": "Not very sure",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "e65c7281-c61b-5d1b-80d2-6d817860040c",
                "text": "Moderately sure",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "c4ce76fe-b6b8-5cae-9479-302ab45b1f45",
                "text": "Very sure",
                "value": 1
            }
        ],
        "id": "BP15.3",
        "text": "It can be hard to remember the details of times when you feel manic. How sure are you about the length of this time? There is no right or wrong answer. ",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "fabceeea-8fea-5444-8b67-8f189f366c27",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f1d6b61f-7ae0-5a6f-bbd0-a81b771141d3",
                "text": "I'm not sure",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "1177fdea-4956-595a-8005-2feda1df3b6e",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP16",
        "text": "Do you think these feelings were caused by any medication you were taking?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "f81655d7-78e5-59b1-a7eb-90a2ec7a107d",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "5d4d2592-d29a-513f-8e9c-461e29598f49",
                "text": "I'm not sure",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "c90c5fc5-83d1-51b0-9b21-4245313f5b0c",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP16.01",
        "text": "Do you think these feelings were caused by drinking alcohol or drug taking?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "16a695c9-3ac1-5d34-b799-44602d85b556",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "35443373-aeb0-549a-bb4b-dad9998fb802",
                "text": "I'm not sure",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "26b98c7e-ad2d-5e05-bbee-e6088f365d60",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP16.02",
        "text": "Do you think these feelings were caused by a physical illness you have?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "4b26caa5-9124-51b2-ab08-2f4e977cee1f",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d425010d-9cb9-54a7-a1a6-2c7b577896e7",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP16.0",
        "text": "During this time did you ever spend a night or more in hospital due to feeling manic?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP16.1",
        "text": "How many times did you have a stay in hospital during this past manic period? ",
        "unit": censeo_1.Unit.None
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP16.2",
        "text": "During this past period of feeling manic, how long was your hospital stay?",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP16.3",
        "text": "During this period, how long was your longest stay in hospital? ",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "2d4daf12-3d1d-5d5b-b03b-8ae16663db77",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "3dc6f331-9c62-574c-afd4-6c281e71b14c",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP16.4",
        "text": "During this time did you ever spend a night or more in hospital due to feeling manic?",
        "isMultiselect": false
    },
    {
        "__typename": "ContentQuestion",
        "id": "BPS2",
        "text": "You mentioned that you have a diagnosis of bipolar disorder. We would like to ask some questions about your experience. \nIt might be difficult to remember some of things we ask about, just answer as best you can.",
        "answers": [
            {
                "__typename": "Option",
                "id": "316e99d8-6ceb-52b4-a93f-fc23d563c46a",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP67",
        "text": "How many different periods of mania do you think you have had? You may have felt unusually happy, elated and excitable, or more irritable and agitated than usual. This may have lasted anything from a few days to a few months. It can be hard to tell so just give your best guess.",
        "unit": censeo_1.Unit.None
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP68",
        "text": "How old were you when you first felt manic or hypomanic? It can be hard to tell so just give your best guess.",
        "unit": censeo_1.Unit.Age
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "4d617024-1f6d-573b-b4cf-b19deec742b8",
                "text": "Less than 1 hour a day",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "71cd0d66-8421-57bf-8210-5689e7518ddb",
                "text": "1-3 hours a day",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "96ad175e-fb84-5715-8499-88943263d3aa",
                "text": "4-6 hours a day",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "7ba739db-135e-5349-b089-e4313dcd2c94",
                "text": "7-9 hours a day",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "0d3b98e3-49be-51d8-94e6-b2d4f9d7f070",
                "text": "10-12 hours a day",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "718900e0-1d81-5826-81ae-531706e15d8a",
                "text": "Over 12 hours a day",
                "value": 1
            }
        ],
        "id": "BP68.1",
        "text": "Try to pinpoint your longest episode of feeling manic. How much of a typical day did it affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "fd0a975d-cc5c-5457-8bbd-268324ba2e87",
                "text": "1 day a week",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "bdfeff69-22bc-5e8b-9d62-e7b990e8f332",
                "text": "2–3 days a week",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "e05b3b2c-6ce7-5e96-991d-9482839bb693",
                "text": "4–5 days a week",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "5d8787f8-4138-5f42-b7d2-ae3afd336249",
                "text": "6–7 days a week",
                "value": 1
            }
        ],
        "id": "BP68.2",
        "text": "During your longest episode of feeling manic, how many days a week did it affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP68.3",
        "text": "How long did this longest episode last for? Just give your best guess.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "2bd1b5ba-74ca-5b38-b914-8aada2bdbaa6",
                "text": "Not at all sure",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "225ded5f-bf47-5069-938c-25f4d15d564e",
                "text": "Not very sure",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "3831e42b-1f7b-5dce-8b8d-4b090ff95f5f",
                "text": "Moderately sure",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "208e1e51-488d-5fbd-8e38-1e75fd0331c4",
                "text": "Very sure",
                "value": 1
            }
        ],
        "id": "BP68.4",
        "text": "It can be hard to remember the details of times when you feel manic. How sure are you about the length of this time? There is no right or wrong answer.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a36902b1-c83f-5e6f-b877-c059c1065bb5",
                "text": "More happy and high",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d210ccff-4e0a-5c2c-afe5-b8f3163c72d7",
                "text": "More irritable and agitated",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "63921591-01f8-50fb-9355-b55fe483faa3",
                "text": "More elated and irritable",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "94ec394b-cb15-50df-8a09-56fc2267a32a",
                "text": "More energetic and proactive",
                "value": 1
            }
        ],
        "id": "BP69",
        "text": "Think about the time when your feelings of mania or hypomania were at their most extreme, or noticable. How did you feel during this time?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "04566a9b-eb5a-56cd-9708-86354be1bb5a",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "101d58bf-4d7f-50d8-a674-2a940051d124",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP69.5",
        "text": "During this time did you feel more energetic than usual or that you wanted to do more activities?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "aca0ad52-0349-505b-a2c9-851d9151f9f4",
                "text": "Less than 1 hour a day",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "6713ca95-099d-5eb6-aafa-4588d8517e3f",
                "text": "1-3 hours a day",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "45c6a41e-5b3d-5eee-9509-830ff88f0c48",
                "text": "4-6 hours a day",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "b8c6c8ad-d35e-54bd-a51c-789c271b5b64",
                "text": "7-9 hours a day",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "b064197f-ae70-5a7a-99a5-4084a68bb429",
                "text": "10-12 hours a day",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "7af885b6-8430-5131-bbe0-e3b3085e1124",
                "text": "Over 12 hours a day",
                "value": 1
            }
        ],
        "id": "BP70",
        "text": "How many hours of the day did it affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "0c8ee31c-61b4-553c-8f3a-98dfb5c580e9",
                "text": "1 day per week",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "1947b35d-4a64-5e7c-99da-8652829ac820",
                "text": "2–3 days a week",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "9e283db4-1a5e-5edb-a052-544a898ae61b",
                "text": "4–5 days a week",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "3edc89bb-15dc-51c5-810a-704abe295dfe",
                "text": "6–7 days a week",
                "value": 1
            }
        ],
        "id": "BP70.1",
        "text": "How many days a week did it affect you for?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP70.2",
        "text": "How long did this period of time last for? Just give your best guess.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8c24266b-7368-50b7-af0a-d9a41afec6f3",
                "text": "Not at all sure",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "0e46d53a-eb8f-5b24-af8d-c3a26e16446f",
                "text": "Not very sure",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "101f0fd0-21f4-56bb-ad9a-5f24c6662b7b",
                "text": "Moderately sure",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "b8ec3f2b-ea6b-56ee-a15b-4382fa6c806e",
                "text": "Very sure",
                "value": 1
            }
        ],
        "id": "BP70.3",
        "text": "It can be hard to remember the details of times when you feel manic. How sure are you about the length of this time? There is no right or wrong answer. ",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "0fc9c17b-b857-574e-8324-e0a11c09fa19",
                "text": "Less than 1 hour a day",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "af8225ea-ab7c-5332-a82a-722b6541423a",
                "text": "1-3 hours a day",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "93c4a6fa-278e-5144-a6fe-c5f4d0e9aac7",
                "text": "4-6 hours a day",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "ae2c1f88-830b-564f-833d-0a2b6409893a",
                "text": "7-9 hours a day",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "02b44dd4-f89e-58c8-bd23-d8fd037216e0",
                "text": "10-12 hours a day",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "28680d32-9e22-5e15-8a5b-7a3ad7062d02",
                "text": "Over 12 hours a day",
                "value": 1
            }
        ],
        "id": "BP71",
        "text": "During this time how much of the day did it affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "dc11a69a-230b-5c81-8d9a-8f660c70bfd9",
                "text": "1 day a week",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "6d196333-35e9-5a21-bf8e-8717a902d337",
                "text": "2–3 days a week",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "0b299ed5-2778-5635-b13b-1fc25fe69c12",
                "text": "4–5 days a week",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "0ea18b82-5d36-5d3d-a053-b7198a5269db",
                "text": "6–7 days a week",
                "value": 1
            }
        ],
        "id": "BP71.1",
        "text": "How many days a week did it affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP71.2",
        "text": "How long did this period of time last for? Just give your best guess.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "feec6124-d1e0-55c4-bfcf-addfd2abad01",
                "text": "Not at all sure",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f820698f-9777-57ed-a050-d41737dceb05",
                "text": "Not very sure",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "87e8dac9-4f50-502c-83fc-87b3f12697d0",
                "text": "Moderately sure",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "2613eb2c-65e9-5af7-a49f-060d517d74ec",
                "text": "Very sure",
                "value": 1
            }
        ],
        "id": "BP71.3",
        "text": "It can be hard to remember the details of times when you feel manic. How sure are you about the length of this time? There is no right or wrong answer. ",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "4f62cee0-6206-58eb-90ed-5a29705a372b",
                "text": "Less than 1 hour a day",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b3996862-f449-5749-b7b9-4ae68796f6c7",
                "text": "1-3 hours a day",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "69c34c7b-2729-540a-94de-673059e72121",
                "text": "4-6 hours a day",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "99a45b06-3b08-543d-bf5d-9f1f0ccc662c",
                "text": "7-9 hours a day",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "dfacec02-0d53-5c82-a2fa-e76119206899",
                "text": "10-12 hours a day",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "5147083f-dd93-5969-b323-a37718d1b91c",
                "text": "Over 12 hours a day",
                "value": 1
            }
        ],
        "id": "BP71.5",
        "text": "During this time how much of the day did it affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ce19af7f-087d-5e10-a35d-7d45cb40f98e",
                "text": "1 day a week",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "46b1d982-b81d-5d0b-944a-c8f4c7c89399",
                "text": "2–3 days a week",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "56690226-655e-5208-ba0f-54e3b33d57ae",
                "text": "4–5 days a week",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "b32b3687-08fc-516d-9588-cc4e0e67329b",
                "text": "6–7 days a week",
                "value": 1
            }
        ],
        "id": "BP71.6",
        "text": "How many days a week did it affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP71.7",
        "text": "How long did this period of time last for? Just give your best guess.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "968cdf73-98fa-58cd-a420-f6c253add90e",
                "text": "Not at all sure",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "38c867bb-d5f4-500f-b531-eae3c03cbcee",
                "text": "Not very sure",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "86f4e988-f781-52ca-8c0f-a27e7868b3b4",
                "text": "Moderately sure",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "65c8698f-8f2c-5b7e-b938-93993cbafc7f",
                "text": "Very sure",
                "value": 1
            }
        ],
        "id": "BP71.8",
        "text": "It can be hard to remember the details of times when you feel manic. How sure are you about the length of this time? There is no right or wrong answer. ",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e5b36105-c9f7-5416-b2dd-8109e47019f9",
                "text": "Less than 1 hour a day",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "240181ac-d397-5683-ba61-de5f01332698",
                "text": "1-3 hours a day",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "f6691e23-cdfb-567b-b2d0-99a4b804a73e",
                "text": "4-6 hours a day",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "32012424-6e5d-5ebd-a173-ba0297980f44",
                "text": "7-9 hours a day",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "fad72e1f-8588-5f92-840b-825f3b65a669",
                "text": "10-12 hours a day",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "705b79dd-e16b-50fc-8532-645edfc14a8f",
                "text": "Over 12 hours a day",
                "value": 1
            }
        ],
        "id": "BP72",
        "text": "During this time how much of the day did it affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "7f041e31-3b4a-5190-bbb0-12676f8e4dc9",
                "text": "1 day a week",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "89fbcf0e-e324-56f3-a361-d614329e24ea",
                "text": "2–3 days a week",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "03dcd030-84ff-5da2-b617-a648734921a5",
                "text": "4–5 days a week",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "3b0835fe-f178-58d2-906f-f8a66737c021",
                "text": "6–7 days a week",
                "value": 1
            }
        ],
        "id": "BP72.1",
        "text": "How many days a week did it affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP72.2",
        "text": "How long did this period of time last for? Just give your best guess.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "854c880e-13ab-5d3a-8ccd-e00176f28eb1",
                "text": "\"Not at all sure",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "6544f4f3-3ab1-569c-83d3-d4d8ad7ffc0c",
                "text": "Not very sure",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "643660a4-4811-562c-8ddb-1f3c7f8b62b4",
                "text": "Moderately sure",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "e6d5c97d-9c7e-50d0-9ee5-c31432fc5b6b",
                "text": "Very sure",
                "value": 1
            }
        ],
        "id": "BP72.3",
        "text": "It can be hard to remember the details of times when you feel manic. How sure are you about the length of this time? There is no right or wrong answer.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "21a70a37-bd8f-55ee-87b5-6e6bdef90126",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "0494c3ad-1e81-5816-8001-d870d3e5eaf4",
                "text": "I'm not sure",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "533a5f92-26d0-5f93-953a-20d136c92f34",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP73",
        "text": "Do you think this episode of mania was triggered by any medication you were taking?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a61f0333-4a19-54ee-9886-c8b5464de077",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "4b8c30e3-d7dd-54b8-b999-12c1076318a4",
                "text": "I'm not sure",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "5fae50d4-a361-5ddb-bfb5-cb00b8578c3c",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP73.01",
        "text": "Do you think this episode of mania was triggered by drinking alcohol or taking drugs?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "2b9f932a-99c5-5d9d-b6bb-ef83446af732",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "853fcccd-bcaf-576e-9068-5b49da5fa0c3",
                "text": "I'm not sure",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "0628a699-725a-5870-8d3c-b542305e4830",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP73.02",
        "text": "Do you think this episode of mania was triggered by a physical illness?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "dad63f8c-7498-59d2-ac8d-a8d57a9dbab8",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "cd4ba75c-7b4b-5126-9170-d54c05b66f1f",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP74",
        "text": "During this period of time did you ever spent a night or more in hospital due to these symptoms?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP74.1",
        "text": "How many times did you have a stay in hospital during this past manic period? ",
        "unit": censeo_1.Unit.None
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP74.2",
        "text": "During this time, how long was your hospital stay?",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP74.3",
        "text": "During this period, how long was your longest stay in hospital? ",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e99f2054-5f88-5322-b642-9257a24f81be",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "45734e40-8054-5f6b-8c8f-a9410fe95696",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP74.4",
        "text": "Did you have a formal/compulsory admission to hospital (i.e. admitted against your will or 'sectioned') during this time?",
        "isMultiselect": false
    },
    {
        "__typename": "ContentQuestion",
        "id": "BP9",
        "text": "The next set of questions will ask you about your feelings and experiences during your most extreme period of mania.\n\nThink about this time in your life and try to answer the questions as accurately as possible.",
        "answers": [
            {
                "__typename": "Option",
                "id": "becfe35c-2096-5848-87ae-3f763cf59dd6",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "ContentQuestion",
        "id": "BP10",
        "text": "The next set of questions will ask you about your feelings and experiences during your most extreme period of mania.\n\nThink about this time in your life and try to answer the questions as accurately as possible.",
        "answers": [
            {
                "__typename": "Option",
                "id": "7ad54f0a-87a6-5e82-bfc3-44b540230082",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "ContentQuestion",
        "id": "BP11",
        "text": "The next set of questions will ask you about your feelings and experiences during your most extreme period of mania.\n\nThink about this time in your life and try to answer the questions as accurately as possible.",
        "answers": [
            {
                "__typename": "Option",
                "id": "e54bdb41-2f8b-5524-b99b-415c31ee1576",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "3629add1-9666-5590-8025-6ec116bafb5c",
                "text": "More happy and high",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "23e37a7e-3e45-53e6-a29b-75da72b79ff0",
                "text": "More irritable and agitated",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "3477feaf-ad87-5fb3-8384-196bc93f5c05",
                "text": "More elated and irritable",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "bf6cf52e-7867-5073-837f-205e3e78121d",
                "text": "More energetic and proactive",
                "value": 1
            }
        ],
        "id": "BP78",
        "text": "How did you feel during this period of feeling manic?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "bb2ee161-cec3-57fa-b85c-52c2612b391d",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "9c1bb644-2129-5e71-98c2-f16f6747632e",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP78.5",
        "text": "During this time, did you have more energy than normal, or want to do more activities?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "32c4702c-0e20-5a42-ab8c-625263b3dd54",
                "text": "Less than 1 hour a day",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "9fd76bef-808e-53ff-8cc7-48d025708649",
                "text": "1-3 hours a day",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "bb32ed85-6e28-5e4d-9b3d-256373ad7145",
                "text": "4-6 hours a day",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "62f57a12-a41d-52a5-8cd4-ea2fb118b58c",
                "text": "7-9 hours a day",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "179c1c17-e5b8-5a2c-8ec3-ec6cbd59a840",
                "text": "10-12 hours a day",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "4f59a1e5-dfb4-5354-9869-4f1e7a4aa6c4",
                "text": "Over 12 hours a day",
                "value": 1
            }
        ],
        "id": "BP79",
        "text": "During this most extreme period of mania or hypomania, how many hours of the day did it affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "9ef68ae4-af66-5ea2-9961-2ddf2ce4425e",
                "text": "1 day a week",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "fce787ed-5443-5522-81ba-c6fa566d3743",
                "text": "2–3 days a week",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "782b65e7-2501-5414-b12a-302daead9bf1",
                "text": "4–5 days a week",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "be369f00-ee1d-56e4-a34e-e357cbfd2b76",
                "text": "6–7 days a week",
                "value": 1
            }
        ],
        "id": "BP79.1",
        "text": "How many days per week did you feel manic for?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP79.2",
        "text": "How long did this time last for? Just give your best guess.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "276d511e-9c09-5b98-a229-c39fb8b98f5b",
                "text": "Not at all sure",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "415137c5-8de4-5587-8f90-b99aa0aa982c",
                "text": "Not very sure",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "19d75cf1-7424-5fb2-b1fe-c2667b6259df",
                "text": "Moderately sure",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "d278c709-a305-5822-8b62-5e4ed23efeca",
                "text": "Very sure",
                "value": 1
            }
        ],
        "id": "BP79.3",
        "text": "It can be hard to remember the details of times when you feel manic. How sure are you about the length of this time? There is no right or wrong answer.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "f971ee05-8a9f-558d-a491-ec6c235f579a",
                "text": "Less than 1 hour a day",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "5d5dd946-9124-53b8-bafc-173b27575b5d",
                "text": "1-3 hours a day",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "3feb1a3a-38f7-5830-979e-d3bba7f181f7",
                "text": "4-6 hours a day",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "95b64a37-13ad-59f9-8562-53d90f02e672",
                "text": "7-9 hours a day",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "aabd9ffd-ac8c-51a3-8bf3-c79d4e1bab6c",
                "text": "10-12 hours a day",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "cf20772a-dc0a-58a9-8878-b3eecba225c2",
                "text": "More than 12 hours a day",
                "value": 1
            }
        ],
        "id": "BP80",
        "text": "During this period how much of the day did it affect you for?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "fcc5d718-229f-5f07-9aa1-d3dc4289afaf",
                "text": "1 day a week",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "0ba28b6c-58e5-5e14-af28-b83c64d7de59",
                "text": "2–3 days a week",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "2250304e-421b-5cf8-9453-0eab14a2170b",
                "text": "4–5 days a week",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "ca674276-1d63-53d4-bca8-79316c8f6295",
                "text": "6–7 days a week",
                "value": 1
            }
        ],
        "id": "BP80.1",
        "text": "How many days per week did it affect you for?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP80.2",
        "text": "How long did this period last for? Just give your best guess.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "1b637f63-9bb8-5e04-9b03-a8cda8e1cb78",
                "text": "Not at all sure",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d151602d-2086-5598-9a57-92deb45d117b",
                "text": "Not very sure",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "30724850-af64-59d8-aaa0-a09c00bd0067",
                "text": "Moderately sure",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "abea45e3-262d-5014-994d-84d6776966ea",
                "text": "Very sure\"",
                "value": 1
            }
        ],
        "id": "BP80.3",
        "text": "It can be hard to remember the details of times when you feel manic. How sure are you about the length of this time? There is no right or wrong answer.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "06a8d0e8-fc8b-5f2a-8113-c4778da46faf",
                "text": "Less than 1 hour a day",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a3e7092a-c243-5f8b-9108-050cc0ca0593",
                "text": "1-3 hours a day",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "757ca82a-6c4a-5d04-88ee-979319b6fcd7",
                "text": "4-6 hours a day",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "6d17a5fb-825d-57e3-9bc5-1c7ed4e07d26",
                "text": "7-9 hours a day",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "8d702773-3ca7-5737-8e58-7a316d353551",
                "text": "10-12 hours a day",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "a1f34de5-9920-5767-b94d-4c5f08713c56",
                "text": "More than 12 hours",
                "value": 1
            }
        ],
        "id": "BP80.5",
        "text": "During this period how much of the day did it affect you for?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "c57fbe44-613a-52fb-a5f4-1930edba11d7",
                "text": "1 day a week",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "47fe93bd-8834-5e54-99a9-6e1b62c9f072",
                "text": "2–3 days a week",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "bc83c397-0824-59fe-b3a1-1683aab4f487",
                "text": "4–5 days a week",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "25a4571f-2338-5796-be87-b1b2af243e03",
                "text": "6–7 days a week",
                "value": 1
            }
        ],
        "id": "BP80.6",
        "text": "How many days per week did it affect you for?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP80.7",
        "text": "How long did this period last for? Just give your best guess.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a2bcbc4d-2432-5e32-a078-49b31d13e2d0",
                "text": "Not at all sure",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "beae5170-2daa-5393-a3fe-ec55762d25b2",
                "text": "Not very sure",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "065bdeae-4bd9-5962-952a-2d13c1cf73d3",
                "text": "Moderately sure",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "596658eb-cba0-5e8b-96a6-476cb7cf5c0e",
                "text": "Very sure",
                "value": 1
            }
        ],
        "id": "BP80.8",
        "text": "It can be hard to remember the details of times when you feel manic. How sure are you about the length of this time? There is no right or wrong answer.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "3753501c-6596-58db-96d1-2f0b31982bba",
                "text": "Less than 1 hour a day",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "49032c24-157e-5206-8a5a-3ddc49e99166",
                "text": "1-3 hours a day",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "9a7f6546-bc93-59c9-b503-3b56ed0ccafd",
                "text": "4-6 hours a day",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "254f447d-d040-5198-8a28-496e22e6d6e2",
                "text": "7-9 hours a day",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "1e2b4334-2201-5b8c-ac01-97624fa1f917",
                "text": "10-12 hours a day",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "8b61c95b-684f-5d31-986c-ab32bd4cff29",
                "text": "More than 12 hours",
                "value": 1
            }
        ],
        "id": "BP81",
        "text": "During this period how much of the day did it affect you for?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6d388eb5-265a-5381-b8b4-1258f0d4c896",
                "text": "1 day a week",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ed9f80ca-b9ec-54df-92c9-a8b252612e6c",
                "text": "2–3 days a week",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "2a892e94-55a7-540f-994e-4d1fc65dbb15",
                "text": "4–5 days a week",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "d465a1fd-b8bf-544d-b8ae-5b1c59299af4",
                "text": "6–7 days a week",
                "value": 1
            }
        ],
        "id": "BP81.1",
        "text": "How many days per week did it affect you for?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP81.2",
        "text": "How long did this period last for? Just give your best guess.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "32b3f551-7af2-529e-ac2f-1bd0e68c1d58",
                "text": "Not at all sure",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "27ce8cc1-459e-56bf-ae35-6458ccc45fe6",
                "text": "Not very sure",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "219990be-b4e3-503d-ad75-81ea8a3ae1b6",
                "text": "Moderately sure",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "4eb1cb4d-3ec0-524b-862a-a06b2cbee8a2",
                "text": "Very sure",
                "value": 1
            }
        ],
        "id": "BP81.3",
        "text": "It can be hard to remember the details of times when you feel manic. How sure are you about the length of this time? There is no right or wrong answer.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "bda5b81b-2ea8-5188-8043-427658192114",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "980128e7-3f2a-5a9b-8f02-006ab1406e72",
                "text": "I'm not sure",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "7b9e4de8-b1ff-57fd-999f-d7ca5d62d3bd",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP82",
        "text": "Do you think that your feelings of mania might have been caused by any medication you were taking?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "34c9ffdb-6bfb-581c-957a-1a8f2bbf6c06",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a19c3b29-f8d6-53e4-b1a7-1cbf18ad53b3",
                "text": "I'm not sure",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "221aa86e-8f2b-5bd2-9171-e10b6c6066f0",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP82.01",
        "text": "Do you think that your feelings of mania might have been caused by drinking alcohol or taking drugs?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6ce2bd68-3332-5aa2-af17-93ff5835f913",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f23eb48e-2f3d-51df-bf70-ca47eaee5ace",
                "text": "I'm not sure",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "996a40a1-7464-527c-874a-635fe4f41a85",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP82.02",
        "text": "Do you think that your feelings of mania might have been caused by a physical illness?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "dad46374-d0d7-5468-97d1-ff70b6a2f893",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "49b6c935-6235-511a-b989-acce3e5f3572",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP83",
        "text": "During this time of past mania did you ever spent a night or more in hospital?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP83.1",
        "text": "How many different times were you hospitalised overnight during this past period when you felt manic? ",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "91374129-e29c-5341-a28e-736721ee7128",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c827fdc7-e41c-5fdf-b4d0-71b50a9d97ed",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP83.2",
        "text": "During this time did you have a formal/compulsory admission to hospital (i.e. were admitted against your will or 'sectioned') due to these symptoms?",
        "isMultiselect": false
    },
    {
        "__typename": "ContentQuestion",
        "id": "BP17",
        "text": "The next set of questions will ask you about your feelings and experiences during this period of time. \n\nTry to answer the questions as best you can.",
        "answers": [
            {
                "__typename": "Option",
                "id": "9fa962fb-6bd5-50fc-9063-459e175cbcc3",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "ContentQuestion",
        "id": "BP18",
        "text": "The next set of questions will ask you about your feelings and experiences during this period of time. \n\nTry to answer the questions as best you can.",
        "answers": [
            {
                "__typename": "Option",
                "id": "b577f323-ec54-5d25-a2b9-4f8de30d60a2",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "ContentQuestion",
        "id": "BP19",
        "text": "The next set of questions will ask you about your feelings and experiences during this period of time. \n\nTry to answer the questions as best you can.",
        "answers": [
            {
                "__typename": "Option",
                "id": "601bc370-8a4c-5b1f-a7f6-6c174f421733",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "7949bd96-3757-5573-bd89-3a91a383cc99",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "fb568891-2ea3-5200-bbdb-ac0695f30b02",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "659c0d9a-389d-5b62-b3ac-e385dfafe63c",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "5bc9fc9f-d402-56b0-97eb-fe64add38edf",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "8cf63df7-755b-597a-9125-d7435c303261",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP87b",
        "text": "Have you ever had a period of time where you felt much more self-confident than usual?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "fa24b924-a8e1-5f02-893b-3d9643007991",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "e29e159f-f691-5aa7-b5a0-d13fe2c54170",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "a6244707-7c91-5858-a2e1-f6f953f7b85e",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "7e4bdef7-d166-5a09-b3a4-639c1700578c",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "887103af-eaf1-54a0-90d9-f9d6867fc69b",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP88b",
        "text": "Have you ever had a period of time where you felt much more active than usual?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "41d2829a-3971-552f-8c5a-c77e7d0073e7",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "db509963-d872-5b97-ba9b-a276f58e0189",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "db64e5ea-3aa0-50f0-a9ad-94f7010267eb",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "df5f6aa3-ab8c-5a4c-b9d9-e9a482aba1d7",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "adfc4e81-0d5f-5ba6-8507-9072b6e02bba",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP89b",
        "text": "Do you feel very sociable and like you want to spend more time with people than usual?.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "95c2822d-27ad-5bb0-982f-8c2b949c8918",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b4a79888-68e8-5b52-9a4e-2149be85f6e7",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "607460aa-6bfa-56fd-9d06-75ec91ef85a1",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "940868cd-d067-573d-9a59-29b2ca63222f",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "5915edb5-db3b-5f9a-8b43-09542fcaa677",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP90b",
        "text": "Have you ever had a period of time when you slept a lot less than normal but didn't miss it?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f59918ba-eb3a-5cf1-81aa-8b7f6ac0df5e",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "dd87a55d-829b-54a2-a63d-5b8b634688ea",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "195241f6-1da5-5b54-b7ce-e73bb54d7d2b",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "bdcabc25-9c00-5456-9d9b-a30e5b36cf6c",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP92b",
        "text": "Have you ever had a period of time when you were much more talkative than usual?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "038cb12b-2783-569a-b1d7-e28c65e591a3",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "1f4fafbf-913e-5edc-bfaf-3baae49fc91d",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "33cefe4b-19c9-55e0-a87c-03ffd99d30bf",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "24f97aee-7df6-5c11-b615-f5064af38f63",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "9cf74958-461b-59df-b1a2-7ee7d7696a0b",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP94b",
        "text": "Have you ever had a period of time when your thoughts were racing and that you couldn't slow them down?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "32255970-db0f-5cc0-a199-d412edf7ab7c",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP95b",
        "text": "Have you ever had a period of time when others noticed that you were speaking much faster than usual or sending more messages than usual? ",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8c3d3503-6334-55b9-b0f0-94c8e520094e",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "477d4084-be50-50ae-9e88-dc9e03cdad29",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "c1f02353-e805-53f2-ab3b-34499538a407",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "e200f36c-3005-5e37-82d3-9228fcf60bfb",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "4043e017-8609-50ef-bb4a-29f57c28965a",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP96b",
        "text": "Have you ever had a period of time where you felt like you wanted to be everywhere and do everything at once?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6895096a-ef29-546e-9967-d7a23b4a2859",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "8fa0f169-f510-5c9e-a4be-fbcc964f7480",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "8a1fc783-28cb-5bf7-aa78-8f6b7c36ad29",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "b8a41bad-3222-55bd-9527-a98e36c1f3ee",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "94b68a0a-8608-59d6-a8bb-48cf3c52f891",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP97b",
        "text": "Have you ever had a period when you felt a lot more energetic or irritable than usual?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "01641419-fbfc-52a5-ae63-0fa736ff9847",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f7b75a2b-3bd2-5734-9fa6-7706f7f8ac89",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "cae1d431-f7ae-5a5a-924d-25c540e96d28",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "1385fe83-520e-5986-8bec-9f0630d48219",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "81112699-9c7d-5ee5-a605-5d4647a698d1",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP98b",
        "text": "Have you ever had a period when you felt much less shy or inhibited or behaved in a way that you later regretted?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "96faf550-f157-55b9-8f15-6be82cd511a8",
                "text": "Yes, I have had a period of time when I did things that were excessive or foolish"
            },
            {
                "__typename": "Option",
                "id": "3f987aaa-e435-55a9-9c1b-aa6d2e476738",
                "text": "Yes, I had a period of time when I did things that were risky or dangerous"
            },
            {
                "__typename": "Option",
                "id": "68860df1-e835-50d6-8eb2-f9d9f2f97efa",
                "text": "No",
                "exclusive": true
            }
        ],
        "id": "BP99b",
        "text": "During the past period of feeling manic did you do things that other people might think were irresponsible or risky?\n\nThese things might include spending more money than you should, engaging in risky or promiscuous sexual behaviour, or dangerous driving. Don't include things you have done while drinking or taking drugs.",
        "isMultiselect": true
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "255f682d-5067-5a6d-835a-fe587b4169e6",
                "text": "I did some foolish things but didn't get into trouble",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "74ac8d69-c317-57fb-a5e5-a93c1309c0f7",
                "text": "I did things that got me into trouble",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "474c64d7-23b9-5015-9cdd-60661ad38d39",
                "text": "I got into serious trouble due to my behaviour",
                "value": 1
            }
        ],
        "id": "BP99.1b",
        "text": "What was this period of time like? ",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "0f933dbb-72b4-5212-b415-8ea806f59368",
                "text": "I did some risky things, but didn't get into trouble",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "32f6041f-9e2c-537c-9db7-19f89011fce9",
                "text": "I did things that got me into trouble",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "a8224369-4083-5ab5-8cad-c4563490599c",
                "text": "I got into serious trouble due to my behaviour",
                "value": 1
            }
        ],
        "id": "BP99.2b",
        "text": "What was this period of time like?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d0adef9f-05e3-5ddf-a0f3-60eeb50d3862",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "c5d5e724-00d6-5a76-bcaf-e4b6545b7fa4",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "e4575ba2-20f0-519e-967b-d5769763cadc",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "cb008e5c-46a4-5b1b-bcea-6f59111913d0",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP99.3b",
        "text": "Have you had a period of time when you spent more money than you could afford to or got into debt without worrying about it?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "cb80bcac-db78-55bd-b051-bdd00801fd54",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "31b2d2c8-8f28-514e-9e58-aa878f6c8761",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "c9856080-d9de-533e-92bf-7fbe2b6cd99a",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "fe614147-f80a-5aff-bb03-9298781e2468",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "923cb2af-7bb5-5341-8349-003206ae043d",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP101b",
        "text": "Have you ever had a period of time when you found yourself being more distracted than usual, by even the smallest of interruptions?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "71338232-94be-5805-b822-94db8be9c5a1",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "46a64b5b-6641-5b1b-92aa-5366444fb134",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "00afc1e6-b78a-5223-a6e7-5d3aaab833e0",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "acb61f4d-a637-587c-bd33-3e9195921dd3",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "523c7d8e-e42b-5654-b037-e8837afb49e7",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP102b",
        "text": "Have you ever had a period of time when you found yourself being particularly creative, or had lots of new ideas and plans?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "867b0ee4-73d9-5aac-b96d-bc2d85d5b183",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c4bec2f3-b4c0-5a86-abf4-28921c5b1956",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP103b",
        "text": "Have you ever had any beliefs that others said were untrue or that you now see are not true?\n\nThis might include thinking that you have powers like telepathy or the ability to heal people, or that you are famous, or that someone is coming after you.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "c59b5910-3279-509f-8175-f2fd0da2dc86",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "75eb3568-195e-5720-827c-1fd0f84c6e75",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP104b",
        "text": "Have you ever heard voices or sounds, or seen things that others didn't see?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "2700e917-0fd4-5241-975c-959339855190",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ee14461c-9f47-5bb5-bdf8-44437a25fc44",
                "text": "I'm not sure",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "4431a100-df22-5217-896c-62c1e7c74723",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP105b",
        "text": "Do these beliefs or hallucinations tend to happen when you are feeling manic?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP106b",
        "text": "How long did you feel like this for? Just give your best guess.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e408e8a3-8395-5c34-992a-3c1ff862dc84",
                "text": "More happy and high",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "8c35da7e-8c0b-50bb-a037-b80a9f1df3d2",
                "text": "More irritable and agitated",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "3648c32c-0ce7-5c1d-a7e8-d45a899b40f9",
                "text": "More elated and irritable",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "9658b463-86ce-5495-a40a-7361dc568105",
                "text": "More energetic and proactive",
                "value": 1
            }
        ],
        "id": "BP106",
        "text": "How would you describe your mood at the moment?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6c88c07e-4483-5b5b-b6ad-44cff962863e",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d25b13ec-c438-5509-85b0-83a43b6793d4",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP108",
        "text": "Do you feel like you have more energy than usual or want to do more activities than usual?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP110",
        "text": "How long have you been feeling like this? Just give your best guess.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a6d87c31-9a11-5151-958a-040439463b3d",
                "text": "Not at all sure",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "85bf649a-4487-5b00-8c08-82ad143ea966",
                "text": "Not very sure",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "c7cabc22-64a2-5eab-b570-889f9a8f782b",
                "text": "Moderately sure",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "7c60a3ff-2742-5128-b0a0-3157bfe8c0f5",
                "text": "Very sure",
                "value": 1
            }
        ],
        "id": "BP110.01",
        "text": "It can be hard to remember the details around times when you feel manic. How sure are you about the length of this period of time? There is no right  or wrong answer.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "19f0b41f-9236-571f-8db4-20bd59fb8509",
                "text": "1 day a week",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "6a7ef643-1318-5d1c-af19-efebc5d5a5f5",
                "text": "2–3 days a week",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "9d19a3c0-120d-5dfd-a794-8aa0e8d39e77",
                "text": "4–5 days a week",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "9ce31a68-0e9d-55e2-a573-174bcbeb7458",
                "text": "6–7 days a week",
                "value": 1
            }
        ],
        "id": "BP110.1",
        "text": "How many days per week do you feel like this?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6d6f6520-7078-5bed-b90a-ee565f1a1808",
                "text": "Less than 1 hour a day",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c179d355-032c-5287-9119-1349133180ef",
                "text": "1-3 hours a day",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "d15430b9-4f4f-5c97-8716-cb907c561dd2",
                "text": "4-6 hours a day",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "faf183ab-b9b4-5f0d-a996-dfa04be4e401",
                "text": "7-9 hours a day",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "dbc05d00-3f66-51fd-825b-712e8609aa9f",
                "text": "10-12 hours a day",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "38ac373b-568f-5787-b9f1-de042f507c61",
                "text": "Over 12 hours a day",
                "value": 1
            }
        ],
        "id": "BP110.2",
        "text": "How many hours of the day do these feelings affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "02b20612-0b18-5aba-91d5-3ebd2589fb3d",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "059fdaea-ce9e-527c-ae94-e00d2b0dbe01",
                "text": "I'm not sure",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "6b8461a6-c3ac-5f8e-9142-e7d46d8034b2",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP111",
        "text": "Do you think these feelings could be caused by any medication you are taking?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "c532b673-2762-5ed0-8cbb-6f70d9977c20",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2b5bf2a3-e1c4-5c93-b481-2862ef2453db",
                "text": "I'm not sure",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "eda4cbcd-c7ee-52c8-b1bb-c3112da16f58",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP111.1",
        "text": "Do you think these feelings could be caused by drinking alcohol drug taking?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "4c9c60d0-cd73-504d-8b03-b734045376ae",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "6d6a3478-7734-5127-b59e-092b42109314",
                "text": "I'm not sure",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "b218cc67-f452-588d-b809-7cd97ee83e20",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP111.2",
        "text": "Do you think these feelings could be caused by a physical illness?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6db4ba23-1d8f-58b9-b11e-eaea15417e28",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "71867305-9c3a-564c-a0c7-e187b27e8d79",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP112",
        "text": "Have you ever had a period of feeling manic in the past? \nThis may have involved your mood being more happy, and excitable, or more irritable and agitated. They may have lasted anything from a few days to a few months.",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP112.1",
        "text": "How many distinct periods time do you think you have had of feeling manic? ",
        "unit": censeo_1.Unit.None
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "5e64808c-1776-564f-b324-faba8fbd9136",
                "text": "Less than 1 hour a day",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "53ee2d2f-ae86-584b-a065-a4e76cecacf3",
                "text": "1-3 hours a day",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "86011770-63b1-5c02-bfaf-a1faf0f67743",
                "text": "4-6 hours a day",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "67d3df7e-ef54-5d0a-9cb3-55e607777ab6",
                "text": "7-9 hours a day",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "158f7b58-6651-5c6e-8a4b-41995ae3a765",
                "text": "10-12 hours a day",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "e345c4c9-57cd-54e0-9f72-71a69033da95",
                "text": "Over 12 hours a day",
                "value": 1
            }
        ],
        "id": "BP112.11",
        "text": "Try to pinpoint your longest episode of feeling manic. How much of a typical day did it affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "47152b8c-67cb-50db-8c2a-6b9d5252dd00",
                "text": "1 day a week",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "42d3031f-3362-5188-a9b3-10a808052a4c",
                "text": "2–3 days a week",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "e962d197-b406-5bcd-81c7-17eb8845b9f9",
                "text": "4–5 days a week",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "add1ea84-0327-5281-9cf4-5ddbd95b2a8f",
                "text": "6–7 days a week",
                "value": 1
            }
        ],
        "id": "BP112.12",
        "text": "During this time, how many days a week did it affect you for?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP112.13",
        "text": "How long did this period of time last? Just give your best guess.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8bedbb44-cc6d-50cd-bf32-2f155b992c77",
                "text": "Not at all sure",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ea23e7a2-72bc-5600-961e-410a47c77b68",
                "text": "Not very sure",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "0304c7fa-aad9-57be-9ff2-dd20913e3d1c",
                "text": "Moderately sure",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "6ead4f84-b429-5a17-85d6-808bd332aa25",
                "text": "Very sure",
                "value": 1
            }
        ],
        "id": "BP112.14",
        "text": "It can be hard to remember the details of times when you feel manic. How sure are you about the length of this time? There is no right or wrong answer. ",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6079cfc0-03c5-5003-87ad-3dff357f0f74",
                "text": "It was more severe in the past than it is now",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "9ff851b9-a640-557e-a750-a4d6e2ffbc49",
                "text": "Quite similar",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "0220e07a-c424-5583-b5a4-d53440a7bc6e",
                "text": "My feelings now are more extreme than in the past",
                "value": 1
            }
        ],
        "id": "BP112.2",
        "text": "How does your past period of feeling manic compare with how you feel now? ",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "f596911a-c0b8-51b8-a6c3-e0e50444761a",
                "text": "It was more severe in the past than it is now",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "dc8d63aa-5242-52d9-b439-526944ab2713",
                "text": "Quite similar",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "5de2cc88-13a4-5f04-a3d2-1333852193f9",
                "text": "My feelings now are more extreme than in the past",
                "value": 1
            }
        ],
        "id": "BP112.3",
        "text": "How does your most extreme past feelings of mania compare with how you are feeling now?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP112.4",
        "text": "How many distinct periods time do you think you have had of feeling manic? ",
        "unit": censeo_1.Unit.None
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "292eb980-02f1-58c6-a10f-129e933621b1",
                "text": "Less than 1 hour a day",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2e9cd96e-806f-5503-90a2-236bc6bf9637",
                "text": "1-3 hours a day",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "15f1fd65-47bd-593b-a941-57b8a14185f2",
                "text": "4-6 hours a day",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "75cd9f23-514e-5bc4-949f-1c2c7a4b1c13",
                "text": "7-9 hours a day",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "6c760bbf-0922-5edf-b553-3f6cfb7a14b1",
                "text": "10-12 hours a day",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "e5fa0670-90bf-5bf4-843c-0d81d07b7494",
                "text": "Over 12 hours per day a day",
                "value": 1
            }
        ],
        "id": "BP112.41",
        "text": "Try to pinpoint your longest episode of feeling manic. How much of a typical day did it affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "5f525bc7-41c7-5cae-beca-1d225c96befb",
                "text": "1 day a week",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "09593b3f-d6e3-5161-8271-a523700aa4ae",
                "text": "2–3 days a week",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "fb66de07-9697-502a-ba4f-54db581b92ed",
                "text": "4–5 days a week",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "744f6684-76b7-5716-87a3-cdf767e6a929",
                "text": "6–7 days a week",
                "value": 1
            }
        ],
        "id": "BP112.42",
        "text": "During this time, how many days a week did it affect you?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP112.43",
        "text": "How long did this time last? Just give your best guess.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "00b06868-292a-5c53-99fa-429e2cb84810",
                "text": "Not at all sure",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "fc48ef94-76bc-5f16-aece-d3ada7dfb0e8",
                "text": "Not very sure",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "125e4463-b901-5baa-a01a-dbc3d447870f",
                "text": "Moderately sure",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "88eff6ec-7434-5255-b69e-d44310919588",
                "text": "Very sure",
                "value": 1
            }
        ],
        "id": "BP112.44",
        "text": "It can be hard to remember the details of times when you feel manic. How sure are you about the length of this time? There is no right or wrong answer. ",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "71ad4086-a47a-5265-86b2-eed25d6d5abe",
                "text": "It was more severe in the past than it is now",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7e08ea1c-7f7d-5f72-87fb-1df882aeed10",
                "text": "Quite similar",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "e1e59a23-091e-54fa-b3ce-49a3fa26fb2a",
                "text": "My feelings now are more extreme than in the past",
                "value": 1
            }
        ],
        "id": "BP112.5",
        "text": "How does your past period of feeling manic compare with how you feel now?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a2924f91-50e4-560e-a7e7-327ed9b0ad26",
                "text": "It was more severe in the past than it is now",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "bbc68d1c-8068-5eb7-a7ff-600e2ccb4e9f",
                "text": "Quite similar",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "3cd21659-e85a-524c-8549-44f033916396",
                "text": "My feelings now are more extreme than in the past",
                "value": 1
            }
        ],
        "id": "BP112.6",
        "text": "How does your most extreme past feelings of mania compare with how you are feeling now?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b16103eb-2ecf-595e-80e4-30baf575e01b",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b761d57f-0aed-52ed-9ffb-467f63c4fb29",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP113",
        "text": "In reference to your current episode of feeling persistently more energetic, excitable or irritable:\n\nHave you ever spent a night or more in hospital due to these symptoms?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP113.1",
        "text": "How many times have you stayed in hospital during this past manic period?",
        "unit": censeo_1.Unit.None
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP113.2",
        "text": "How long was your hospital stay?",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP113.3",
        "text": "During this period, how long was your longest stay in hospital? ",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "bd60deee-3a91-5bc5-9487-267c44221755",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "126719e4-fe06-5b9a-9791-f58c8f290fd4",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP113.4",
        "text": "In reference to your current episode of feeling persistently more energetic, excitable or irritable:\n\nHave you ever had a formal/compulsory admission to hospital (i.e. been admitted against your will or 'sectioned') due to these symptoms?",
        "isMultiselect": false
    },
    {
        "__typename": "ContentQuestion",
        "id": "BP114.1",
        "text": "The next set of questions will ask you about your feelings and experiences during your most extreme/most noticeable episode of feeling persistently more energetic and 'up' than usual that last lasted for 4 days or more.\n\nThink about this time in your life and try to answer the questions as accurately as possible. Even if you are not 100% sure, just answer as best you can. Feel free to take your time.",
        "answers": [
            {
                "__typename": "Option",
                "id": "eca60916-bfe7-5262-9df1-e058076bb053",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "ContentQuestion",
        "id": "BP114.2",
        "text": "The next set of questions will ask you about your feelings and experiences during your previous episode of feeling persistently more energetic and 'up' than usual for one week or more. \n\nThink about this time in your life and try to answer the questions as accurately as possible. Even if you are not 100% sure, just answer as best you can. Feel free to take your time.",
        "answers": [
            {
                "__typename": "Option",
                "id": "22b2502c-0072-5849-b1ba-362cb59071a7",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "ContentQuestion",
        "id": "BP114.3",
        "text": "The next set of questions will ask you about your feelings and experiences during your most extreme/most noticeable episode of feeling persistently more energetic and 'up' than usual that lasted for 4 days or more.\n\nThink about this time in your life and try to answer the questions as accurately as possible. Even if you are not 100% sure, just answer as best you can. Feel free to take your time.",
        "answers": [
            {
                "__typename": "Option",
                "id": "1e2caf46-f196-5689-ac30-b0e241c8bff8",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "ContentQuestion",
        "id": "BP114.4",
        "text": "The next set of questions will ask you about your feelings and experiences during your previous episode of feeling persistently more energetic and 'up' than usual that lasted for 4 days or more.\n\nThink about this time in your life and try to answer the questions as accurately as possible. Even if you are not 100% sure, just answer as best you can. Feel free to take your time.",
        "answers": [
            {
                "__typename": "Option",
                "id": "84bc1b3e-bfa2-56b8-9ed2-f6ce89c0e841",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "387b19c0-67d0-557e-9fba-ae065dcf3587",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ad1cfcc2-c1dc-57ef-b964-17d355fa0402",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "9ae96e50-5c86-58a7-a845-fb6347c7edd1",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "e620af65-9b5b-57e6-b13a-65616e67e084",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "960a08ff-7af7-56da-afda-5415a1654c85",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP87",
        "text": "During your past period of feeling manic did you feel much more self-confident than usual?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "afb57366-c0b3-5f73-92eb-27bc133ad2ed",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f501dc76-7fcd-586b-ba1d-ad70ab8e8d33",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "a4ed572c-6aae-57f6-bc35-ea46929c1437",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "5f694075-3a97-5f8b-8701-2710a43127a9",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "cb636d32-4f4e-51b3-a23f-2a11b8771b7e",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP88",
        "text": "During your past period of feeling manic were you doing many more activities active than usual?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "667ba57c-b1cd-5f9b-aa12-58e65a981910",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2404146e-cd56-5356-a23b-6427b4bbba06",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "92e118de-38b9-5d5a-96ba-7b50547c0621",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "28c75e9e-94ef-5c50-8645-f4ff93ccf2ec",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "0953f30c-801a-54f6-8238-b9dc42ab17f9",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP89",
        "text": "Do you feel very sociable at the moment, wanting to spend more time with people than usual?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "fd30dc27-8e89-5d1a-9d95-8844603d4379",
                "text": "No, I slept normally",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "5d5fa968-aeb3-569a-8a71-483ef04b9a6a",
                "text": "I slept 1 hour less a night",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "a2056280-4b54-5258-8bb4-407b521fff4b",
                "text": "I slept 2–3 hours less a night",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "93bfeb6e-215e-5f4b-b6de-0bc364e05be5",
                "text": "I slept 4–6 hours less a night",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "7b2421d0-6b5b-5666-b0a9-951887636db1",
                "text": "I didn't sleep at all",
                "value": 1
            }
        ],
        "id": "BP90",
        "text": "During your past period of feeling manic did you get much less sleep than usual without feeling tired?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e22cf233-c174-52fb-af55-97bf9c46e971",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d9fb150b-68b8-50ed-bb1d-eafd41f63f3b",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "c211fabc-a62d-5440-8ddc-f57eb21d7679",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "eb97df23-3118-56bc-8c37-c54d7a750e4f",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "33e48f82-ae80-5f20-a3a5-79692d21ad1a",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP90.1",
        "text": "During your past period of feeling manic, were you unable to take care of yourself? (e.g. not washing or eating properly)",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "46c90446-3ae9-53f5-9b57-ad19d66b5be6",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "0feda24f-7b01-557b-8ca5-10eb924cbdac",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "1f57d1a0-3682-5d7d-b66b-8179ec5c3988",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "cc7c09ae-1cb7-5b9a-9cd3-8d1012b90ee8",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "7cd91611-c433-5f81-b829-8454750b387c",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP91",
        "text": "During your past period of feeling manic, did you have any problems with your work that you don't usually have? (E.g., difficulty meeting deadlines or conflict with colleagues)",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP91.1",
        "text": "During your past period of feeling manic how much time did you take off? If you didn't take time off, enter '0 days'.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "d680a8d9-e35f-563f-9d1d-e7c3136aff6d",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ef5a7b22-b2b2-5a5b-8a80-ce5c251033fd",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "17619692-6d18-5558-99da-766a1c275e03",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "63f0b17a-9832-5f0f-8630-e74f1a4fdfe7",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "3984b363-353f-56b1-ad96-4840225109ef",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP92",
        "text": "During your past period of feeling manic were you much more talkative than usual?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "c88e3fb5-913a-5c5c-a42b-603f7bb60a40",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "94795194-4a80-590f-be62-ee78f2fa0ff5",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "f35fe383-d2aa-51c4-9fde-961b1de6d52b",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "82997889-d795-572b-a597-e484ef078b39",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "940c16a8-1835-582a-9dad-bd52e3a49cc7",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP93",
        "text": "During your past period of feeling manic did your close relationships become difficult or strained?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "732c51f9-a0b6-5951-bd7d-eaaec24316ba",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "201c7ee3-56dc-51e9-ad55-69951192ab16",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "ec84966e-bb9f-5ba9-a0cf-d5d3503a74e0",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "2555d898-6736-5804-951e-e3c438313029",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "1e86d64a-b972-500a-9120-7884e4b86ede",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP94",
        "text": "Did you frequently feel like your thoughts were racing and you couldn't slow them down?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "4a7d2992-b9e5-5e91-9a28-c2283dda5106",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "de2ea429-4fcd-53b8-8d9f-ebb69a637a02",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP95",
        "text": "Did others notice that you were speaking faster or sending more messages?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6928c696-e6d7-5a14-a004-15a497ac6562",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "dbab3669-e910-5267-b7b1-fcb661ea7e81",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "6ade3a8d-8516-5a88-baa0-f996f32730d2",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "9b27e3ff-f8ce-54c5-9220-0479ae53e889",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "e232ba2d-36a9-524e-9fb2-8541b27bb569",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP96",
        "text": "Did you feel like you wanted to be everywhere and do everything at once?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "17b4da6f-7135-523c-b90b-2fe3b66785ff",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2723ed09-37f6-522c-92b1-e518d4fe9315",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "4a7683c6-774e-5362-8060-740f61986c9b",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "79075051-dcb3-5321-9f27-b21697f8f194",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "352885c9-c1e1-5206-9f52-8d9b6d2aa166",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP97",
        "text": "Were you more restless or did you find it harder to sit still?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "3cf77140-2757-58eb-b4ea-f2a90cd766e7",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "58777a2e-4b97-5413-b340-efe722e45936",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "cfb8fe0e-821a-554a-bb57-922f0ecc0f44",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "a0a2f2f1-ed73-59bb-add9-5f3569bbbecb",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "b14d53e4-f898-52ac-87d7-3aa62abc9d7b",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP98",
        "text": "Were you less shy and inhibited?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "63509127-aadf-5fe2-8e1f-19d0d846d6ce",
                "text": "Yes, I did things that were excessive or foolish"
            },
            {
                "__typename": "Option",
                "id": "36502f0b-4563-5e03-80f2-a46dabeeae74",
                "text": "Yes, I did things that were risky or dangerous"
            },
            {
                "__typename": "Option",
                "id": "5c06d516-e816-58d6-997e-f8fa91131ac7",
                "text": "None of the above",
                "exclusive": true
            }
        ],
        "id": "BP99",
        "text": "During the past period of feeling manic did you do things that other people might think were irresponsible or risky?\n\nThese things might include spending more money than you should, engaging in risky or promiscuous sexual behaviour, or dangerous driving. Don't include things you have done while drinking or taking drugs.",
        "isMultiselect": true
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "34d19f12-207f-56ce-bde8-8d616f6e8b26",
                "text": "I did slightly foolish things but never got into trouble",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "659c1b81-1b5d-5c34-9d0d-ce0ceae2a3b3",
                "text": "I did foolish things that got me into a bit of trouble",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "74809c02-f47f-5955-a416-b40fc66ff214",
                "text": "I did very foolish things that caused serious trouble",
                "value": 1
            }
        ],
        "id": "BP99.1",
        "text": "During the past period of feeling manic which of the below best describes your behaviour?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ac6c9baf-e6df-5453-8665-0b796e1b83f8",
                "text": "I did slightly risky things, but never got into trouble",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f55f25af-1247-5c48-8385-341f7e4fea59",
                "text": "I did risky things that got me into a bit of trouble",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "e8c07c0f-f594-5e38-a18d-fb9d7835cf9f",
                "text": "I did very risky things that caused serious trouble",
                "value": 1
            }
        ],
        "id": "BP99.2",
        "text": "Which of the following best describes your behaviour during this period?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa",
                "text": "No, not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a020ed85-ec9d-5778-8006-41f634cab09d",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "e81fe613-4923-55ed-bd6c-bd3f19e026f9",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "b9e6efff-f5b2-5911-ba7a-17c768e4613e",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "dee1118a-2ca3-52a6-ac43-a24ea7385f88",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP99.3",
        "text": "During this past period of feeling manic, were you irresponsible with money?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "db7aa4b7-99a5-5475-a4e8-a744f3ef5a4a",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "a20d7b84-720e-53c5-a450-45cbde434507",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "182e121d-415c-5f42-a76c-a996f5f38b3f",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "026a1cea-7cfc-540b-bfe5-42886be90595",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP100",
        "text": "Did you find it difficult to continue doing your normal leisure activities?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6a5aa899-7b89-5661-a554-71b6058ad4a4",
                "text": "No, not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "e9eb902a-d327-5ec5-938c-dc317ca59b13",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "d8c1997f-d722-536c-ad9d-296b5e10ed04",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "ca5ac96d-2b69-5200-a063-61db840c9ef2",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "1c4ed441-5919-569e-98ba-b49be1bc3957",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP101",
        "text": "Did you find yourself being more distracted by even the smallest of interruptions?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "d3224e16-1b0c-5266-9e14-ac72d2b2e988",
                "text": "No, not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "1daccd4c-e5ff-5f18-ba55-7c2d2d93b849",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "7230003d-a290-5a65-ae6b-b7d564b5db38",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "a3a547f3-b48b-567f-a32a-6a3f5f4bacd7",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "e76cac02-f6b2-5ab6-a467-e3025d857ab9",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP102",
        "text": "Did you feel more creative than usual, coming up with lots of new ideas and plans?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b24df75b-6bb9-5f5f-99e3-c883ec3644ec",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f2228920-3fec-5097-a63d-990c25d50c6c",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP103",
        "text": "Did you have beliefs that others claimed were untrue or that you now see to be untrue?\n\nThis might include thinking that you have powers like telepathy or the ability to heal people, or that you are famous, or that someone is coming after you.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "71f4aadd-848c-56d9-b008-3b1bf7ddae28",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "de4464c8-78fe-5876-aa00-25826ce5ec25",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP104",
        "text": "Did you ever hear voices or sounds, or see things that others didn't?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "64aa1680-6b5b-5a5b-bb92-75995d27ddcd",
                "text": "No, they have only ever happened during a period of feeling manic",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "978f2ff4-94d9-5f7e-9126-e8657be0c7ca",
                "text": "I'm not sure",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "4e9d21b3-29c7-5526-ada9-67b61957994e",
                "text": "Yes, they have also happened when my mood has felt more normal",
                "value": 1
            }
        ],
        "id": "BP105",
        "text": "Have you also had these beliefs during times when your mood felt more normal?",
        "isMultiselect": false
    },
    {
        "__typename": "ContentQuestion",
        "id": "BP114",
        "text": "The next set of questions will ask you for more detail about how your mood and energy levels are affecting you at the moment. Try to answer as best you can and take your time.",
        "answers": [
            {
                "__typename": "Option",
                "id": "a0206e39-59ad-539c-a2ff-e9fd935cf48b",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e7b2a68b-de30-51bd-bb56-cda1254d3dbc",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "dd02d36a-25b2-5daa-8cc6-5bb2d1382229",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "c523c420-264e-5e54-ad59-a27e36b23132",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "030fff48-057e-5e57-8776-30a94e459131",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "e67cb250-dabf-5ed8-8a1b-7a184dd74efd",
                "text": "Very much more",
                "value": 1
            }
        ],
        "id": "BP115",
        "text": "Do you feel more self-confident than usual?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "bce3bb8c-216a-5167-8ec7-35c39096d501",
                "text": "No, not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2d30ef1e-59ee-5b0a-8c01-0dcbfd64f6ca",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "cd9085de-4e15-5383-b814-a261f79b136f",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "bab62178-4367-5df2-99ea-d5224dffb53e",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "68c9cbe1-3f8f-5168-a960-d5ff6e8bb716",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP116",
        "text": "Are you taking part in more activities at the moment?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "354c012f-3484-58ad-9300-d9e29a212ae3",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c68c6fcb-e62b-5749-80e4-9c4ad9995b97",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "aae4e65c-c66b-5e2d-8a18-88b17f5e6eb8",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "0d476185-4df9-5c70-837d-2b5ff4e08893",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "1d169f46-d28a-5b85-b98f-31e378c13d40",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP117",
        "text": "Do you feel very sociable at the moment, wanting to spend more time with people than usual?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ab15cfa9-d8ea-50f2-9c54-d7e033818659",
                "text": "No, I am sleeping normally",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a221f7d7-f43a-5e8f-ac87-bb3cc3a447f3",
                "text": "I am sleeping an 1 hour less a night",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "a611c3a0-727b-5a9b-8d15-d805b2ecf757",
                "text": "I am sleeping 2–3 hours less a night",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "e642f54c-d6fc-57f8-a53c-73dbd698d428",
                "text": "I am sleeping 4-6 hours less a night",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "1eda0859-de62-56d0-836a-3f1778a6064b",
                "text": "I am not sleeping at all",
                "value": 1
            }
        ],
        "id": "BP118",
        "text": "Are you getting much less sleep than usual, but not really missing it?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "3299a8a7-4375-55ee-87b2-76bda766fd11",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a7937947-986d-5fbf-83cd-d1c893dcdc7a",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "801fd4db-2f09-5e10-a9b7-43319a3ac1d0",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "f80f4b1b-7286-52d0-8218-7083ae3bb582",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "3780fe32-30da-5e3b-bad1-6d360381e7e4",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP118.1",
        "text": "Have you been struggling to take care of yourself, (e.g. not washing or eating properly)?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "3f4ab649-e8be-559b-a32f-9ccb317ffb38",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "faf4eb68-5627-5070-8937-42cf8cbc74d7",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "50c573e0-accf-5acc-be15-616cb65704cc",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "6d54b724-84ce-55bd-9912-b4951133fb61",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "64d22ab4-481d-5258-9244-0ec9d3ba77a7",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP119",
        "text": "Are you having problems at work? (E.g., difficulty meeting deadlines or conflict with colleagues)",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "BP119.1",
        "text": "How much time have you taken time off work or study? If you haven't taken time off, select '0 days'.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "548c329b-3169-5742-b17d-437216e6a454",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c8162ccf-aae0-5678-aeb9-fe02ed13ad99",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "3c0c2a71-99b9-5210-b4e7-2734537fe5f4",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "e500747f-17cd-555a-a1e8-8968fd2be980",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "137ac9f7-52c3-5502-8d0f-653df1384d35",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP120",
        "text": "During your past period of feeling manic were you much more talkative than usual?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "76891993-4f79-5b7a-ad6f-b7be1c4994fb",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c344141d-3295-5ecc-bf2a-4c45f9d330db",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "7e71cdc3-11c7-5156-87b9-a53194156832",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "7caa27c0-01f2-586f-825c-7c1bd527ef54",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "2dd3fa4b-7390-5c95-b04b-c5c77974e8c2",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP121",
        "text": "Are you finding your close relationships more difficult or strained at the moment?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "84ec10d0-2e9c-5671-9d59-dc91b256ca0b",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "08c42ff1-0b0f-5018-8e88-552f8b8cf02a",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "f1f5184d-35fb-5cac-b89f-4a4015d9de77",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "b1759380-318d-54b0-9224-163fb659558b",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "3d008b0e-8759-568c-9aae-ba21c8b6ae79",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP122",
        "text": "Do you frequently feel like your thoughts are racing and you can't slow them down?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "972a0931-d7f1-5ad4-90af-91046fd7b193",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "5c6d0cea-9bce-5b98-b6ad-dcb0e3fb6b0d",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP123",
        "text": "Have others noticed that you're speaking faster than usual or sending more messages?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6410ed4b-d607-5edd-baae-ffeb61769bf8",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d7e7a81a-6129-54d0-8a45-35c7e9ae3bf3",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "343ddd19-6dd5-54fb-a817-730184c2faf1",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "29c09b6c-a1ca-5b73-9d71-1eeef40c8aef",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "368a583d-81fc-5f77-a51c-647fd7f8f473",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP124",
        "text": "Do you feel like you want to be everywhere and do everything at once?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "49914ab7-ff35-5bed-9786-13574e73a883",
                "text": "No, not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d7075f96-ec23-51f7-9293-9bf725963409",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "8136d074-8df6-5a91-b6b6-e8ffcfb42d7c",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "1926be5c-c655-5494-8fb8-9492f4a4324e",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "19dd84c4-ccf5-5060-83ae-e1f2f977f1c0",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP125",
        "text": "Are you feeling more restless and finding it harder to sit still?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "7bdce4bf-24ca-5470-a5cd-68c1e5c7ef03",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "52d061e3-96b8-5053-893a-03758e4f0518",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "3b0900cc-ebc0-5606-a6f6-066c9c6c2c97",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "47856c8e-63c1-5066-bf95-92ff01e0982a",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "f5e0c5dc-06c9-5f60-9b97-3b79d63a406c",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP126",
        "text": "Are you feeling much less shy and inhibited?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "1fc78738-6afa-5714-89a1-8d5698fb9f69",
                "text": "Yes, I have been doing things that are excessive or foolish"
            },
            {
                "__typename": "Option",
                "id": "27638a2c-33e4-58c5-a185-4dcdd837f93c",
                "text": "Yes, I have been doing things that are risky or dangerous"
            },
            {
                "__typename": "Option",
                "id": "5f58bb60-dff1-585c-a5f3-26e34e4dc957",
                "text": "No",
                "exclusive": true
            }
        ],
        "id": "BP127",
        "text": "During the past period of feeling manic did you do things that other people might think were irresponsible or risky?\n\nThese things might include spending more money than you should, engaging in risky sexual behaviour, or dangerous driving. Don't include things you have done while drinking or taking drugs.",
        "isMultiselect": true
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a2bbf0b5-4203-5b73-89f8-05f59515356f",
                "text": "I did slightly foolish things but didn't get into trouble",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b76730b2-4e72-5ab7-b0c7-03b88d01b19c",
                "text": "I did foolish things that got me into trouble",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "68c97dc1-4b15-59a6-b2ae-49597771e772",
                "text": "I did very foolish things that caused serious trouble",
                "value": 1
            }
        ],
        "id": "BP127.1",
        "text": "Which of the below best describes your behaviour?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6ed360b8-fd85-5299-b879-f07ef1494425",
                "text": "I did slightly riskier things, but didn't get into trouble",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f44c6fd9-72d7-5254-bf0d-52045ae546c7",
                "text": "I did risky things that got me into trouble",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "2e4d8704-5188-5d7c-bfaf-6c83462b3621",
                "text": "I did very risky things that caused serious trouble",
                "value": 1
            }
        ],
        "id": "BP127.2",
        "text": "Which of the below best describes your behaviour?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e1cc34e0-2ad5-5043-ba9b-c38b53b1d2de",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "38efc61e-4155-51f0-8b64-341f627e5482",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "2d74a699-1958-513e-94d7-e25ebf081a91",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "cd346eed-7344-51aa-aa80-7964a3dce896",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "e6bc1265-542f-5413-9743-ee7e5adec6e3",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP127.3",
        "text": "Are you spending more money than you can afford or getting into debt?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6b43ea3f-8741-56e6-808f-129fe78b7440",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ebff4971-05dc-52ed-9957-33c0ca1479c1",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "5bf8ce18-aa1a-5c57-86d6-687efcad92de",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "f2c70c18-c55b-5447-9859-67292866bede",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "8a7adbc6-1053-55ce-b226-1e2bc2131050",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP128",
        "text": "Are you finding it more difficult to continue with your usual leisure activities?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "3a5070b7-374a-5369-aceb-6b8936db2970",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c534368a-9c51-5f0d-8b17-9df288fd59a0",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "00293814-728b-5c19-8744-73c54d0d4db2",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "4b90a2f8-960f-5d69-8e86-9c46af2fec0f",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "477c3730-71ba-505f-9caa-07a83398580f",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP129",
        "text": "Do you find yourself being distracted by even the smallest of interruptions?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "7a066bdc-85a0-56cc-8632-2e83740855c1",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b4725e03-4f40-5305-9d3d-1ff9297f8bff",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "bf6cdff0-63a3-5516-b548-b3c2d0ef83ab",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "a04d679f-abeb-5fb0-9db7-2ed9997ab672",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "a2d91cbf-fa72-5028-91d6-9f00d0ce8aa3",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "BP130",
        "text": "Do you feel more creative than usual, with lots of new ideas and plans?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e5863f5d-32a6-520a-9b40-8591adf1c1b8",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "cf80f9fa-de7b-5954-b005-e9ec1b1b07a9",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP131",
        "text": "Have you believed things that others claim are untrue?\n\nThis might include thinking that you have powers like telepathy, the ability to heal people, that you are famous, or that someone is coming after you.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "da9000ec-69f6-580b-9ec9-756b702c2962",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7da9fe3a-3186-562a-b4f2-170d5baf0c76",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "BP132",
        "text": "Have you been hearing voices or sounds, or seeing things that others didn't hear or see?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "2e0701e7-7bb8-5a5d-9e33-d95fac9352d6",
                "text": "No, they have only ever happened during a period of feeling manic",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "75391070-9bde-59e4-b44a-68f38b6a4472",
                "text": "I'm not sure",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "36121dba-4b4b-584f-9f5b-4b715cf0b2af",
                "text": "Yes, they have also happened when my mood has felt more normal",
                "value": 1
            }
        ],
        "id": "BP133",
        "text": "Have you also had these beliefs during times when your mood felt more normal?",
        "isMultiselect": false
    },
    {
        "__typename": "ContentQuestion",
        "id": "HMS",
        "text": "You are nearly at the end of this session and are making great progress.\n\nThese last few questions may feel a little repetitive but they are important to help us better understand your situation.",
        "answers": [
            {
                "__typename": "Option",
                "id": "976f055b-ac14-5a89-8536-7c66d9ec95e5",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "086c0ca6-3cdd-584d-a949-a5ed5e3d8aa4",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "60caa355-cd97-591a-9344-1e9858939855",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "3d860ce1-917d-5fdf-a025-ce36b81bb1fb",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "8434c112-273f-5c5a-a704-62c8cb63dd51",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "c44193b6-642c-56d3-ada3-eda9bd17a42e",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM1",
        "text": "During your most extreme period of feeling manic, did you want to get involved with as many activities and projects as possible?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "de662c3f-66b4-566f-856d-2124e2725542",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2b56aa7d-493f-5a18-aa9f-f97304f89130",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "a046d610-d27a-59c1-b130-38312005cf1c",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "fc6fa8a1-ab36-5c3d-901d-feec17df2f1a",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "3bf2fe22-146e-560d-8aae-39a56747f149",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM2",
        "text": "During your most extreme period of feeling manic, did you want to get involved with as many activities and projects as possible?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "bcdbdc29-42ba-5c6e-b655-fcca007ce28d",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "da93cad9-334a-57d5-b26d-fabb43b2d059",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "7823432e-ad44-5fbe-9781-48d9ce136451",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "1d37ab0b-0386-5463-a135-eebb9f0f0e0c",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "dbce9e65-8768-5d55-af51-0fd58c8e9853",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM3",
        "text": "During your most extreme period of feeling manic, did you want to get involved with as many activities and projects as possible?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "002aec48-2785-5a3e-856a-7b2ff6990863",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "67ff3da2-b682-5e3d-a30c-e07aa27c64b8",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "5dd99b1f-4bcd-5921-857a-6f99b2bc139c",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "1f142f13-5d5e-57af-82f3-5df564dae9cb",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "c8fd6c78-0f80-5c1a-9cdf-61fd1d153c52",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM4",
        "text": "During your most extreme period of feeling manic, did you want to get involved with as many activities and projects as possible?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b9cb4697-38be-5d4a-af02-623aea1363b0",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a96ba3ce-dc9b-54ab-8a24-5ece4cf16447",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "f6322217-0772-5ca4-a7a8-e8efbf97dbec",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "f7cb9a05-4357-5077-8730-767d9ed58b02",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "67484765-83ea-5089-99bf-bbf7a66818fa",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM5",
        "text": "Did you want to get involved with as many activities and projects as possible?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "70ede32f-268b-5ca6-864a-107cdd966cc5",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "868022b7-397f-52c6-9d1e-77ce0c8ed442",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "462c1a4b-5cc8-591b-864d-7a9319b983c0",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "661c6bc5-4691-5ba3-b0bd-96222d039db2",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "ed1586b4-e29c-5b36-9283-d6a861c277df",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM6",
        "text": "Did you want to get involved with as many activities and projects as possible?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "cd2f9957-ea07-5b37-ace2-0e4ee0a5c05a",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7d828e74-497e-5b42-9adc-f6559649481e",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "b43d7a75-2456-5a42-9e2c-2edbe34ccc12",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "931a7dd0-61d5-54b9-9e85-3c5ff79d312b",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "9d06d48e-dfe4-59da-af07-4baf37a61e5c",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM7",
        "text": "Did you want to get involved with as many activities and projects as possible?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "7b94568b-b9d0-5ea2-91ca-4afe242acfb8",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d46fbd7a-074b-5014-9767-77e52f83e365",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "e91203fa-5410-59f8-a2a7-cfbd0257e576",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "6bafc310-38fa-5bc1-bca7-412515a46505",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "2ccd221e-eeba-5fa4-b5b1-f1439c64398b",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM18",
        "text": "Did you want to get involved with as many activities and projects as possible?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "99f474d7-d509-592a-856a-afed343fb1b8",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "fbfbcb17-2315-5dc6-a771-7f1051d7d464",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "e75678bb-0b29-540e-8fbe-40a63336374d",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "38b41f78-6e4b-5925-9642-2331ed02b6ed",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "05764f7c-41bd-5cc0-a71e-be182e4dd627",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM19",
        "text": "Did you want to get involved with as many activities and projects as possible?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "4ff0f88c-e951-5e87-a941-815105819115",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "4eb576cf-e8d9-51f2-94cd-d232476ee5cb",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "b8816e4b-2104-5ce7-a5b0-b804a1a0dc20",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "be55154a-b96f-5c1c-91de-2a118e42e6f8",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "ba281b75-7509-59be-a0b3-12bccbd60e6d",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM20",
        "text": "Did you want to get involved with as many activities and projects as possible?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "98ea07a0-ae95-570f-8d11-a3e7c682e986",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c3ee8981-dec3-5b3c-80ca-c1023f983a6d",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "6b99de8b-634a-5eb0-bc65-bdf30eb9d433",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "0f8903ea-258c-54c8-9d4d-643924d3c234",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "7977ac6e-f8f2-5d46-ba88-d01586f6e45d",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM21",
        "text": "During your past episode of feeling manic, did you tend to talk much more than others?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "696fdd9d-5ced-504b-a933-bb3e575b0099",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a43647cb-a0e8-5705-adb9-a2b68877dc77",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "9d212885-9b07-5623-8234-226adff08f6d",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "8f7acbc1-9ebe-5a52-95ce-b50d62c27345",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "5b02ea99-72f6-5b68-b536-b6f32aecc86c",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM22",
        "text": "During your past episode of feeling manic, did you meet more people than usual?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "c41974b6-d394-569c-8f45-49f79fcac5c6",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "5574c021-c3c4-513a-a4b6-5ee416e8aa9e",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "56aac6d1-1fcc-5a72-bfda-2ae5d875df21",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "3ab9515b-73f0-5fcf-a4d0-aebbc24c2721",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "c347cbdd-4788-538c-b190-8008a1e964e2",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM23",
        "text": "During your past episode of feeling manic, did you feel confident that you could do things better than everyone else?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "43c32319-c75f-5169-b0e5-713820721d4f",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "3fd957d1-0cbb-5397-9812-353be5c2184e",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "c8c8c192-aea3-5585-b11f-9d13faf4367d",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "0e9545ba-3f35-52b0-9a31-7fe54c933928",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "b60806d0-ac44-589e-a567-a91918ccf9f8",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM25",
        "text": "Did you feel like you were the life and soul of the party at every social gathering you went to?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b2cb304f-f035-53fc-8763-e2f91b031f9b",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d2c670c4-b05b-597a-a0af-5342230535a0",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "898957c6-8cd1-53e5-98f8-c16789aba613",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "eb41dc92-16ec-5abf-a841-e1d0b262083e",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "65bee7d4-bf7f-5a21-9d92-f16651b0d1aa",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM26",
        "text": "Were your sexual feelings and thoughts much more prominent than usual?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "0170b4cf-0541-53cd-a9db-818d5067dc23",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "bcd05aa7-d9e3-5bdc-8661-0daa33c7b9b3",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "366c6a1b-c8b2-5faf-9d2f-72dbde24e869",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "57de31c8-9bf0-5003-9dbb-0e8559f994dd",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "cec918d6-b855-5672-94a8-cf14e5ae963a",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM28",
        "text": "Did you wear more colourful and extravagant clothes or makeup?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "2be66549-5767-51b4-82ed-deeab0723f8d",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "6b35cd3b-d67a-54af-94f6-e39ddf5af6c6",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "7b310da1-94e3-5d45-a6c3-3905eeb28ec6",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "1240a093-e011-5bb9-9dbe-b61af95e9cde",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "efcb2ed0-864a-521d-ac0d-99e0fd4b38f5",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM29",
        "text": "During your past episodes of feeling manic: \n\nDid you feel fearful or suspicious of things and people around you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "0983ccf5-a426-53d0-adbb-1baf6832190c",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "aa90f24c-ee55-515b-b5a2-78bdacbe25a0",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "2fcb75b3-7ba4-50b5-b2a3-ad1ead57dbe1",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "73b26794-a969-527b-8268-f17c4e485bda",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM30",
        "text": "Were you being more flirtatious or sexually active than usual?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "834b04f2-af09-5337-8fd5-be4a70c99e75",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7e32475a-3fcc-51ac-b414-ab46578efdfd",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "a56fcb96-f868-5d08-a358-9b403eb3cd16",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "e979459c-cb23-5cea-bedd-432a62288787",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "0f2a6025-0d8f-5d89-81a2-c3cc425cd7c4",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM31",
        "text": "Did you get things done (such as housework, admin or work) more quickly and easily?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "86277249-cf50-5f91-a27f-f3a69344e796",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "fa32b497-4a4b-5076-a391-b6dffa8e1851",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "fda00d52-c8be-5b69-bdbd-905c13dddd4e",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "fd982070-f2ad-5ff4-be48-fb6c78e856c4",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "e9e6eb20-c3f6-516f-8431-d94c3555cc05",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM32",
        "text": "Did you spend much more than usual without really caring about the consequences?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8823c74f-ff7c-52d8-9c73-fd3b4180f48a",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "e95276b0-ee81-580b-b532-b0215f04abd5",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "bde4e5f3-19e4-571f-84a2-5b9d188d74ef",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "0360ab4b-77d5-5fcd-8d60-b7a005c12ceb",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "f0be1669-63f2-5fb0-9af7-55be0b4fb2c2",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM33",
        "text": "Did you tend to make a lot of jokes or puns?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "1d07911e-929d-5719-aeea-a68f593d18e8",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "4b8e4843-d260-59e5-8468-1303b1c3a638",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "7d6921a2-7e63-5f12-9c75-dff8b592fabc",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "42710499-d4f3-5f65-bfad-382dde7e2f83",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "ba200400-b5a1-5ae8-aead-863c1a72be98",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM34",
        "text": "Did you think much faster and much more clearly than usual?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "c5f21a2b-5af8-525d-bfab-0ed035defec0",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2b40020d-33ba-5560-9075-d482aa324496",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "9eeab515-74ed-5cdc-8b02-9ce8c1a2d197",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "13ede675-77ba-58af-911e-c3a4457f4e56",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "fef4dc4e-6c90-5b86-88ae-e9a5ee7c9d5a",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM35",
        "text": "Are you feeling like you want to do as many activities as possible?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "47bfc26b-4237-5a82-9cc4-67d348c4acb0",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "fc7ec28a-9883-58d5-a088-f3b113c2b4b3",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "10794c2e-d0f1-5546-87aa-f3bc40003974",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "ee51f6bc-2dbd-5285-9ef7-f667a7a1bdb0",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "79ac7b3c-38af-5670-b744-5d39000401df",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM36",
        "text": "Are you talking much more than others?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "20de534d-f482-51c5-ad87-bd054787fddc",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a2c38330-1fdc-55c8-9a19-209f55bd344f",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "77031c75-3438-5152-9142-8478bb9bdc66",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "cfd0a32c-e988-5dce-85c8-e07628f719b5",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "05934e5e-fca9-566e-9a69-eec32b7da87b",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM37",
        "text": "Are you socialising more than usual?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "5125bbbb-70fb-5adc-a41b-c65c6b96180d",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "69b8ea50-630a-587f-8283-640e5773e249",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "01783efc-b456-5a0d-95fc-ee2321cc4f8d",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "6a9568e0-386d-5b07-a513-b1903bd81f31",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "5db8d12e-30bb-555d-83f6-4a55db85785f",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM38",
        "text": "Do you feel more confident that you are better than others at doing things?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "596144bf-d0ea-5d17-a2ae-7096c31e2def",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "3110a9c5-47d0-5e35-952b-74c6a481b635",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "82682922-e4e5-5674-9a94-b96f5d798846",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "7606c31a-ed49-584c-9e82-c909a18843cd",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "449c3bd8-c1d4-530d-8bcc-e4bf16434a9d",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM40",
        "text": "Do you feel like you are the life and soul of the party?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "dd9cb999-d487-51f2-8ca3-f121593feca0",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "5dc47933-8a72-5087-b068-38e22758b9f7",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "db39ddc0-79fd-5c61-b063-7f0bc0bab024",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "e8be146c-dcaf-5060-ad4a-468ca2878cb2",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "8fea83cc-74c5-577c-b8ed-8d538140b44d",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM41",
        "text": "Are your sexual feelings and thoughts much more prominent than usual?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a8b07611-dcc0-55ef-ae78-d4ceb7ad42e3",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d41a8ccb-18d3-58ef-b09a-b2b0e34dc48c",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "ad8a0302-30ec-5868-a265-87c35baefdac",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "08b88c09-4278-5bd5-b72d-4f4a9a69acd0",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "2023b400-6f09-59ac-b67e-657f6d592763",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM43",
        "text": "Are you tending to wear more extravagant clothes or makeup than usual at the moment?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "3675f391-ff12-51e5-b53f-b851b05414ee",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "00b843b3-a869-5c49-8c2b-f15c3bfbbb6d",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "726ac010-d4fa-5cca-a6d0-4bbd6bd7ac47",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "7e4e0798-dfa8-5a49-a106-a26902cb89c3",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "fe1e5434-ebd9-5a33-92c8-acca24a484b7",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM44",
        "text": "Do you currently feel fearful or suspicious of things or people around you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "28a20a2a-3318-5dba-9e59-86d943d3ac73",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2722d440-372d-5471-8cab-ef78b8026a24",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "637476f9-73ea-5066-9b3e-8c4bc05c3b51",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "a4667066-a91a-56d4-8b53-682a65490819",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "9cfe579e-f4fa-589a-bcd7-1f28243d92f2",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM45",
        "text": "Are you being more flirtatious or sexually active than usual?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "9f868300-e1df-5567-8ebf-e2f984022e52",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a54e0650-9e33-5e16-a4a9-2c31bc10c96c",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "a57434cf-7c45-5e3d-9d0d-c8b432490e99",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "772a32ae-a62a-57a3-9d99-92106761d701",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "4aa7c56d-2337-5383-80d4-d0599322231e",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM46",
        "text": "Are you getting things done (such as housework, admin or work) more quickly and easily?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "41ec9370-ba26-5cbc-ad35-d9e578788aa4",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "e03a7683-7e3e-563d-b531-33e529887c90",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "c0256df5-0653-5e2e-b656-58097a53c002",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "49d31acb-f117-54a2-b2bc-5680ccad6e6f",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "6f3ea1ee-6ac6-53b8-804e-609d817b88da",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM47",
        "text": "Are you spending much more money than usual without really worrying?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "c0e7db40-86e9-5cba-98b0-e42a3fc0835b",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2db89264-9c8a-54e5-8efc-1b1ca45b0233",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "9b74dd11-97db-567e-8d3d-836001d82229",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "c140b03a-5169-5731-838a-a63f017c99b6",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "fdd2a948-7ae9-5aef-bf30-81970a357202",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM48",
        "text": "Are you making a lot of jokes or puns?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ad65e31f-19f9-587c-bc99-0b3549cc5cb0",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "555cca84-b650-53ab-944e-2289adb21903",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "7a5feb6e-c27f-54d2-9473-fc197426685c",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "b6ba01ed-b20b-5d63-8093-eb7c245c8f85",
                "text": "Significantly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "7c044595-1479-5f7c-b2ac-b356553a69c2",
                "text": "Very much so",
                "value": 1
            }
        ],
        "id": "HM49",
        "text": "Are you thinking much faster and more clearly?",
        "isMultiselect": false
    }
];
exports.aspects = {
    "bd_symptom_3plus_agg": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_self_esteem"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_decreased_sleep_need"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_more_talkative"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_flight_of_ideas"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_distractibility"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_increase_goal_directed"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_risky"
                }
            }
        ],
        "anyToAll": 0.42857142857142855
    },
    "bd_symptom_4plus_agg": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_self_esteem"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_decreased_sleep_need"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_more_talkative"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_flight_of_ideas"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_distractibility"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_increase_goal_directed"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_risky"
                }
            }
        ],
        "anyToAll": 0.5714285714285714
    },
    "bd_symptom_5plus_agg": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_self_esteem"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_decreased_sleep_need"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_more_talkative"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_flight_of_ideas"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_distractibility"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_increase_goal_directed"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_risky"
                }
            }
        ],
        "anyToAll": 0.7142857142857143
    },
    "bd_unusual_persistent_highlike_episode": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP1.5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "7599bcaa-89a5-5f71-80ea-b70c360bb44a": 0.03557118927263617,
                            "ccbdcbec-96d0-5362-977b-784289f5cfc0": 0.5,
                            "83e6efd5-64a2-5f0e-a973-9278eae90cfb": 0.9644288107273639,
                            "6215a3de-a3e4-5f86-a039-1006646e6b93": 0.9987706013787226
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "Dem11_bpd"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_hard_to_pinpoint": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP4",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "6b1af00b-b5de-5d59-bb4b-7e8d396d2138": 0,
                            "9765aba8-7d55-5928-b79d-36aa58646036": 0.33,
                            "4884cc9c-7210-5173-9438-d1eae9e9a6a8": 0.66,
                            "f4705d21-04ac-574a-90f3-5e73c089a3ff": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP12",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "89a3d3f3-747b-59f8-9a32-311618df7607": 0,
                            "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a": 0.33,
                            "74034187-2f1a-54cb-8bdf-1eb3572a286a": 0.66,
                            "22f9a995-3f9a-5fe9-99bb-71b7f1f96621": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP69",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "a36902b1-c83f-5e6f-b877-c059c1065bb5": 0,
                            "d210ccff-4e0a-5c2c-afe5-b8f3163c72d7": 0.33,
                            "63921591-01f8-50fb-9355-b55fe483faa3": 0.66,
                            "94ec394b-cb15-50df-8a09-56fc2267a32a": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP78",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "3629add1-9666-5590-8025-6ec116bafb5c": 0,
                            "23e37a7e-3e45-53e6-a29b-75da72b79ff0": 0.33,
                            "3477feaf-ad87-5fb3-8384-196bc93f5c05": 0.66,
                            "bf6cf52e-7867-5073-837f-205e3e78121d": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP106",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "e408e8a3-8395-5c34-992a-3c1ff862dc84": 0,
                            "8c35da7e-8c0b-50bb-a037-b80a9f1df3d2": 0.33,
                            "3648c32c-0ce7-5c1d-a7e8-d45a899b40f9": 0.66,
                            "9658b463-86ce-5495-a40a-7361dc568105": 1
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.2
    },
    "bd_energy_higher_participate_more": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP4.5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "66a3460c-65ce-5a4c-9774-2e303ae4961e": 0,
                            "1be4b3b1-3a88-5544-af54-ebdfee1ce891": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP12.5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "cd7ce00f-4e7c-57ac-9c6c-4137efa24c8c": 0,
                            "b7cc3eef-f36c-5fb4-9ebb-9ce64c834159": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP69.5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "04566a9b-eb5a-56cd-9708-86354be1bb5a": 0,
                            "101d58bf-4d7f-50d8-a674-2a940051d124": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP78.5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "bb2ee161-cec3-57fa-b85c-52c2612b391d": 0,
                            "9c1bb644-2129-5e71-98c2-f16f6747632e": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP108",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "6c88c07e-4483-5b5b-b6ad-44cff962863e": 0,
                            "d25b13ec-c438-5509-85b0-83a43b6793d4": 1
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.2
    },
    "bd_increased_engergy_and_GD_activity": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_hard_to_pinpoint"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_energy_higher_participate_more"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_increase_goal_directed"
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "bd_irritable_mood": {
        "type": "merge",
        "wires": [
            {
                "type": "equals",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "bd_hard_to_pinpoint"
                    }
                },
                "value": 0.33
            },
            {
                "type": "equals",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "bd_hard_to_pinpoint"
                    }
                },
                "value": 0.66
            }
        ],
        "anyToAll": 0.5
    },
    "bd_symptom_2_core": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_self_confident"
                }
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "filter",
                        "sigmoid": {
                            "middle": 0.5,
                            "width": 1
                        },
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "bd_sexual_feelings"
                            }
                        }
                    },
                    {
                        "type": "filter",
                        "sigmoid": {
                            "middle": 0.5,
                            "width": 1
                        },
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "bd_sexual_behaviour"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "bd_excessive_spending"
                    }
                }
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "filter",
                        "sigmoid": {
                            "middle": 0.5,
                            "width": 1
                        },
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "bd_sociable_outgoing"
                            }
                        }
                    },
                    {
                        "type": "filter",
                        "sigmoid": {
                            "middle": 0.5,
                            "width": 1
                        },
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "bd_soul_of_party"
                            }
                        }
                    },
                    {
                        "type": "filter",
                        "sigmoid": {
                            "middle": 0.5,
                            "width": 1
                        },
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "bd_meet_people"
                            }
                        }
                    }
                ],
                "anyToAll": 0.3333333333333333
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_delusions"
                }
            }
        ],
        "anyToAll": 0.4
    },
    "bd_manic_duration_week_plus": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP5.2",
                    "input": 7,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP6.2",
                    "input": 7,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP6.8",
                    "input": 7,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP7.2",
                    "input": 7,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP13.2",
                    "input": 7,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP14.2",
                    "input": 7,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP14.7",
                    "input": 7,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP15.2",
                    "input": 7,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP70.2",
                    "input": 7,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP71.2",
                    "input": 7,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP71.7",
                    "input": 7,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP72.2",
                    "input": 7,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP79.2",
                    "input": 7,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP80.2",
                    "input": 7,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP80.7",
                    "input": 7,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP81.2",
                    "input": 7,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP110",
                    "input": 7,
                    "operator": ">="
                }
            }
        ],
        "anyToAll": 0.058823529411764705
    },
    "bd_longest_manic_duration_week_plus": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP3.3",
                    "input": 7,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP68.3",
                    "input": 7,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP112.13",
                    "input": 7,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP112.43",
                    "input": 7,
                    "operator": ">="
                }
            }
        ],
        "anyToAll": 0.25
    },
    "bd_manic_duration_fortnight_plus": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP5.2",
                    "input": 14,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP6.2",
                    "input": 14,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP6.8",
                    "input": 14,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP7.2",
                    "input": 14,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP13.2",
                    "input": 14,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP14.2",
                    "input": 14,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP14.7",
                    "input": 14,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP15.2",
                    "input": 14,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP70.2",
                    "input": 14,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP71.2",
                    "input": 14,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP71.7",
                    "input": 14,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP72.2",
                    "input": 14,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP79.2",
                    "input": 14,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP80.2",
                    "input": 14,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP80.7",
                    "input": 14,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP81.2",
                    "input": 14,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP110",
                    "input": 14,
                    "operator": ">="
                }
            }
        ],
        "anyToAll": 0.058823529411764705
    },
    "bd_manic_duration_4days_plus": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP5.2",
                    "input": 4,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP6.2",
                    "input": 4,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP6.8",
                    "input": 4,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP7.2",
                    "input": 4,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP13.2",
                    "input": 4,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP14.2",
                    "input": 4,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP14.7",
                    "input": 4,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP15.2",
                    "input": 4,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP70.2",
                    "input": 4,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP71.2",
                    "input": 4,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP71.7",
                    "input": 4,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP72.2",
                    "input": 4,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP79.2",
                    "input": 4,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP80.2",
                    "input": 4,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP80.7",
                    "input": 4,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP81.2",
                    "input": 4,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP110",
                    "input": 4,
                    "operator": ">="
                }
            }
        ],
        "anyToAll": 0.058823529411764705
    },
    "bd_longest_manic_duration_4days_plus": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP3.3",
                    "input": 4,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP68.3",
                    "input": 4,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP112.13",
                    "input": 4,
                    "operator": ">="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP112.43",
                    "input": 4,
                    "operator": ">="
                }
            }
        ],
        "anyToAll": 0.25
    },
    "bd_hours_per_day": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "1f743ff4-52a2-5104-aa5a-b38e75363021": 0.0066928509242848554,
                            "38e2ca3d-f06e-5ca5-b301-7906fdad6741": 0.04742587317756678,
                            "9b037682-7f02-573a-88e8-662b5b9ae4ed": 0.26894142136999516,
                            "e2cba9a7-5960-5839-88e2-6af7b8af3aa9": 0.7310585786300049,
                            "9b30d461-df50-57fe-ba43-fbcaf82b25fc": 0.9525741268224334,
                            "0f5b2a9a-603c-59a7-b70a-efb21f3e5ec6": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP6",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "6560ae14-d813-51a0-b37b-cc9e44ecffaa": 0.0066928509242848554,
                            "742a22d6-a570-5e3a-8e72-c001f1e9f98b": 0.04742587317756678,
                            "0f2295c8-9598-5e68-a9a6-c87ca1f600af": 0.26894142136999516,
                            "33ae5abe-63ad-57d4-86a4-0dcd33576094": 0.7310585786300049,
                            "29d4f06e-7031-5ec7-a155-41dd137ca140": 0.9525741268224334,
                            "52f153db-76e7-57fa-90bd-63d5a923964a": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP6.5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "f160a5be-9070-56d6-9163-15f172f107e0": 0.0066928509242848554,
                            "d959af5c-40b7-5278-bdcc-66c3fd17ed8d": 0.04742587317756678,
                            "58656f9f-9267-5c1d-9132-f342d05dc39e": 0.26894142136999516,
                            "a2d6fa3e-e88a-53c9-9913-428837a5c3ee": 0.7310585786300049,
                            "e96c7a38-d74e-5087-b14b-21d3177ce73e": 0.9525741268224334,
                            "4678730f-670d-50ba-94cb-71d817698a0a": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP7",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "4ceb0625-db98-50f5-a099-c9df92ffa626": 0.0066928509242848554,
                            "0c0ab687-08ee-5bfd-8795-5e0284c1e430": 0.04742587317756678,
                            "6369e481-8f15-557a-b9b6-4c06e0a3ec21": 0.26894142136999516,
                            "f22cc4a7-6a1e-580d-b5a0-b44bd8cd2117": 0.7310585786300049,
                            "f4e7e62d-742e-5c1b-b549-4b11a22c4be2": 0.9525741268224334,
                            "32f283d2-1a05-54df-9d98-7639d2670c41": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP13",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "a03b4df9-48f3-5151-b6a7-dd71bfcb2fdd": 0.0066928509242848554,
                            "6e3b2f0d-fb56-52bf-89c6-bb98b6e0cd3b": 0.04742587317756678,
                            "1aa23278-1b6e-5950-91ec-0b2d3babbee3": 0.26894142136999516,
                            "b0ebeb98-c11e-57a1-ba58-6457ca05c434": 0.7310585786300049,
                            "8cd7d7ed-1de8-5155-81b5-85202bce3abc": 0.9525741268224334,
                            "9818f960-c604-59a3-a1ad-372baff1b59b": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP14",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "5585a7e8-a4b7-567e-b339-90537a58f1e3": 0.0066928509242848554,
                            "97bbe87b-c5ff-51c1-a99c-cec263cff7d4": 0.04742587317756678,
                            "a3126449-b062-5872-80a1-5950acf6bfa4": 0.26894142136999516,
                            "ac3ece45-bbdf-528a-9563-b82e962351e8": 0.7310585786300049,
                            "dca91893-52f2-504e-b794-1f89dfa61ab8": 0.9525741268224334,
                            "84994ca2-fcea-54fe-a7d6-9b44ec07f0bd": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP14.5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "ce1ffa1a-f506-51cf-8d4e-869680808628": 0.0066928509242848554,
                            "d7e78959-cebd-524d-9863-f3cb141abfb4": 0.04742587317756678,
                            "3a2461f2-b999-59ae-a70a-cd3a034afd00": 0.26894142136999516,
                            "8def5c3e-98b8-54b6-89f9-a4df19e76d66": 0.7310585786300049,
                            "8a362b4c-6d0e-527a-8610-6572060f7476": 0.9525741268224334,
                            "f2c52c87-ab50-5315-96ce-3528652a7a32": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP15",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "1286eda2-5e31-52dc-95c5-78ded5b43b2a": 0.0066928509242848554,
                            "9a1158f1-f08a-581d-a088-650041097c24": 0.04742587317756678,
                            "2dae4847-432c-5e66-9a74-3ded3e4eada9": 0.26894142136999516,
                            "d8c37d8b-01b7-581e-9d97-f6c8b8bf9267": 0.7310585786300049,
                            "54874bb5-1fab-5ab4-b9d4-bed8f8ea4d13": 0.9525741268224334,
                            "e9643ff4-2842-5b40-9c98-5b5d0e9b6a5e": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP70",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "aca0ad52-0349-505b-a2c9-851d9151f9f4": 0.0066928509242848554,
                            "6713ca95-099d-5eb6-aafa-4588d8517e3f": 0.04742587317756678,
                            "45c6a41e-5b3d-5eee-9509-830ff88f0c48": 0.26894142136999516,
                            "b8c6c8ad-d35e-54bd-a51c-789c271b5b64": 0.7310585786300049,
                            "b064197f-ae70-5a7a-99a5-4084a68bb429": 0.9525741268224334,
                            "7af885b6-8430-5131-bbe0-e3b3085e1124": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP71",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "0fc9c17b-b857-574e-8324-e0a11c09fa19": 0.0066928509242848554,
                            "af8225ea-ab7c-5332-a82a-722b6541423a": 0.04742587317756678,
                            "93c4a6fa-278e-5144-a6fe-c5f4d0e9aac7": 0.26894142136999516,
                            "ae2c1f88-830b-564f-833d-0a2b6409893a": 0.7310585786300049,
                            "02b44dd4-f89e-58c8-bd23-d8fd037216e0": 0.9525741268224334,
                            "28680d32-9e22-5e15-8a5b-7a3ad7062d02": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP71.5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "4f62cee0-6206-58eb-90ed-5a29705a372b": 0.0066928509242848554,
                            "b3996862-f449-5749-b7b9-4ae68796f6c7": 0.04742587317756678,
                            "69c34c7b-2729-540a-94de-673059e72121": 0.26894142136999516,
                            "99a45b06-3b08-543d-bf5d-9f1f0ccc662c": 0.7310585786300049,
                            "dfacec02-0d53-5c82-a2fa-e76119206899": 0.9525741268224334,
                            "5147083f-dd93-5969-b323-a37718d1b91c": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP72",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "e5b36105-c9f7-5416-b2dd-8109e47019f9": 0.0066928509242848554,
                            "240181ac-d397-5683-ba61-de5f01332698": 0.04742587317756678,
                            "f6691e23-cdfb-567b-b2d0-99a4b804a73e": 0.26894142136999516,
                            "32012424-6e5d-5ebd-a173-ba0297980f44": 0.7310585786300049,
                            "fad72e1f-8588-5f92-840b-825f3b65a669": 0.9525741268224334,
                            "705b79dd-e16b-50fc-8532-645edfc14a8f": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP79",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "32c4702c-0e20-5a42-ab8c-625263b3dd54": 0.0066928509242848554,
                            "9fd76bef-808e-53ff-8cc7-48d025708649": 0.04742587317756678,
                            "bb32ed85-6e28-5e4d-9b3d-256373ad7145": 0.26894142136999516,
                            "62f57a12-a41d-52a5-8cd4-ea2fb118b58c": 0.7310585786300049,
                            "179c1c17-e5b8-5a2c-8ec3-ec6cbd59a840": 0.9525741268224334,
                            "4f59a1e5-dfb4-5354-9869-4f1e7a4aa6c4": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP80",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "f971ee05-8a9f-558d-a491-ec6c235f579a": 0.0066928509242848554,
                            "5d5dd946-9124-53b8-bafc-173b27575b5d": 0.04742587317756678,
                            "3feb1a3a-38f7-5830-979e-d3bba7f181f7": 0.26894142136999516,
                            "95b64a37-13ad-59f9-8562-53d90f02e672": 0.7310585786300049,
                            "aabd9ffd-ac8c-51a3-8bf3-c79d4e1bab6c": 0.9525741268224334,
                            "cf20772a-dc0a-58a9-8878-b3eecba225c2": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP80.5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "06a8d0e8-fc8b-5f2a-8113-c4778da46faf": 0.0066928509242848554,
                            "a3e7092a-c243-5f8b-9108-050cc0ca0593": 0.04742587317756678,
                            "757ca82a-6c4a-5d04-88ee-979319b6fcd7": 0.26894142136999516,
                            "6d17a5fb-825d-57e3-9bc5-1c7ed4e07d26": 0.7310585786300049,
                            "8d702773-3ca7-5737-8e58-7a316d353551": 0.9525741268224334,
                            "a1f34de5-9920-5767-b94d-4c5f08713c56": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP81",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "3753501c-6596-58db-96d1-2f0b31982bba": 0.0066928509242848554,
                            "49032c24-157e-5206-8a5a-3ddc49e99166": 0.04742587317756678,
                            "9a7f6546-bc93-59c9-b503-3b56ed0ccafd": 0.26894142136999516,
                            "254f447d-d040-5198-8a28-496e22e6d6e2": 0.7310585786300049,
                            "1e2b4334-2201-5b8c-ac01-97624fa1f917": 0.9525741268224334,
                            "8b61c95b-684f-5d31-986c-ab32bd4cff29": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP110.2",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "6d6f6520-7078-5bed-b90a-ee565f1a1808": 0.0066928509242848554,
                            "c179d355-032c-5287-9119-1349133180ef": 0.04742587317756678,
                            "d15430b9-4f4f-5c97-8716-cb907c561dd2": 0.26894142136999516,
                            "faf183ab-b9b4-5f0d-a996-dfa04be4e401": 0.7310585786300049,
                            "dbc05d00-3f66-51fd-825b-712e8609aa9f": 0.9525741268224334,
                            "38ac373b-568f-5787-b9f1-de042f507c61": 0.9933071490757153
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.058823529411764705
    },
    "bd_longest_hours_per_day": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP3.1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "b56edfbb-cf11-50ee-9149-9e36f4cc1ea0": 0.0066928509242848554,
                            "5bf1e313-2624-5b5f-9673-37ae9319ff0c": 0.04742587317756678,
                            "11a8ab35-b2d7-55a4-8a77-809dc8f1f78a": 0.26894142136999516,
                            "9531c3ff-ab2b-5198-a490-919ede5afb07": 0.7310585786300049,
                            "c31cf816-04e0-52f4-8da3-04437042c014": 0.9525741268224334,
                            "c053dd45-79bd-59bf-82df-7e9ecd85dc11": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP68.1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "4d617024-1f6d-573b-b4cf-b19deec742b8": 0.0066928509242848554,
                            "71cd0d66-8421-57bf-8210-5689e7518ddb": 0.04742587317756678,
                            "96ad175e-fb84-5715-8499-88943263d3aa": 0.26894142136999516,
                            "7ba739db-135e-5349-b089-e4313dcd2c94": 0.7310585786300049,
                            "0d3b98e3-49be-51d8-94e6-b2d4f9d7f070": 0.9525741268224334,
                            "718900e0-1d81-5826-81ae-531706e15d8a": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP112.11",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "5e64808c-1776-564f-b324-faba8fbd9136": 0.0066928509242848554,
                            "53ee2d2f-ae86-584b-a065-a4e76cecacf3": 0.04742587317756678,
                            "86011770-63b1-5c02-bfaf-a1faf0f67743": 0.26894142136999516,
                            "67d3df7e-ef54-5d0a-9cb3-55e607777ab6": 0.7310585786300049,
                            "158f7b58-6651-5c6e-8a4b-41995ae3a765": 0.9525741268224334,
                            "e345c4c9-57cd-54e0-9f72-71a69033da95": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP112.41",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "292eb980-02f1-58c6-a10f-129e933621b1": 0.0066928509242848554,
                            "2e9cd96e-806f-5503-90a2-236bc6bf9637": 0.04742587317756678,
                            "15f1fd65-47bd-593b-a941-57b8a14185f2": 0.26894142136999516,
                            "75cd9f23-514e-5bc4-949f-1c2c7a4b1c13": 0.7310585786300049,
                            "6c760bbf-0922-5edf-b553-3f6cfb7a14b1": 0.9525741268224334,
                            "e5fa0670-90bf-5bf4-843c-0d81d07b7494": 0.9933071490757153
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.25
    },
    "bd_days_per_week": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP5.1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "6a2ded69-f2ba-5374-b667-a542580d92ff": 0.001358519950428958,
                            "c3c07e60-7b68-585c-8bc0-cbe7939a5c5f": 0.03557118927263617,
                            "e078853b-2d60-5ce8-925e-6f3693305c5d": 0.5,
                            "8bf2b201-3339-5ac5-8365-e39aece2c3f4": 0.9677045353015494
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP6.1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "77b4d620-93e4-5bc5-baad-4995d168f392": 0.001358519950428958,
                            "430b68c6-29cf-5a15-ab4c-89bd842383bf": 0.03557118927263617,
                            "a99773bd-2bff-523e-bc29-1f761307b3d4": 0.5,
                            "f0b738f5-44c3-5a6c-9a31-ab7878568a81": 0.9677045353015494
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP6.7",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "f3f2d7da-5c63-5e35-a1ab-729a174949c5": 0.001358519950428958,
                            "370c5f43-7c15-5f4f-b49a-8719bcb29c2a": 0.03557118927263617,
                            "7cb3515b-004f-58a1-a02b-59c7d9510637": 0.5,
                            "ef07ce6c-9277-581a-bde7-14889359301b": 0.9677045353015494
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP7.1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "6b62060b-0aee-5ec9-beef-85c908359e3d": 0.001358519950428958,
                            "c077c76d-70ea-541e-b93c-aa943ccde0c2": 0.03557118927263617,
                            "bedc40d1-6d09-5151-b80b-8db92115483b": 0.5,
                            "6b0b2ebe-61e1-58f5-86ad-8c05d3a16679": 0.9677045353015494
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP13.1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "bf94a386-34ce-5e35-a1d4-cb50686e6e01": 0.001358519950428958,
                            "7a2a48ee-f1b5-5c8d-974a-247da2a9f76b": 0.03557118927263617,
                            "632d3ebf-df58-52bd-9e1e-19deb67269bf": 0.5,
                            "49f1206d-ce81-58fc-af76-30be0fffd7f5": 0.9677045353015494
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP14.1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "f2b7f127-8ab7-5609-8820-43158baee15c": 0.001358519950428958,
                            "69f9a784-ce6d-5c82-bd3f-4c9c44bf5992": 0.03557118927263617,
                            "e1b5b8f7-1317-5136-a8c1-2afdfa91e615": 0.5,
                            "00462af7-705a-5635-a847-029a631411c0": 0.9677045353015494
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP14.6",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "282c0f7e-ea67-578e-87b3-bfab84fbf0c0": 0.001358519950428958,
                            "e05fa18d-bc4f-5678-9692-644f2e2e1a3c": 0.03557118927263617,
                            "8c8a8e3b-6201-5a54-8a71-3d37f75d6d2d": 0.5,
                            "d77159fb-9574-5a11-9e4f-9dccffb0db35": 0.9677045353015494
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP15.1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "0fe6799b-72e6-5cd8-a921-d03ac42f803d": 0.001358519950428958,
                            "aa7bd0b8-95ca-5179-a037-3956a8c2816d": 0.03557118927263617,
                            "2fe2c05f-897a-55a4-876c-68763dede792": 0.5,
                            "35a5b342-38f4-5343-8a4f-a3f024b95530": 0.9677045353015494
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP70.1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "0c8ee31c-61b4-553c-8f3a-98dfb5c580e9": 0.001358519950428958,
                            "1947b35d-4a64-5e7c-99da-8652829ac820": 0.03557118927263617,
                            "9e283db4-1a5e-5edb-a052-544a898ae61b": 0.5,
                            "3edc89bb-15dc-51c5-810a-704abe295dfe": 0.9677045353015494
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP71.1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "dc11a69a-230b-5c81-8d9a-8f660c70bfd9": 0.001358519950428958,
                            "6d196333-35e9-5a21-bf8e-8717a902d337": 0.03557118927263617,
                            "0b299ed5-2778-5635-b13b-1fc25fe69c12": 0.5,
                            "0ea18b82-5d36-5d3d-a053-b7198a5269db": 0.9677045353015494
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP71.6",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "ce19af7f-087d-5e10-a35d-7d45cb40f98e": 0.001358519950428958,
                            "46b1d982-b81d-5d0b-944a-c8f4c7c89399": 0.03557118927263617,
                            "56690226-655e-5208-ba0f-54e3b33d57ae": 0.5,
                            "b32b3687-08fc-516d-9588-cc4e0e67329b": 0.9677045353015494
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP72.1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "7f041e31-3b4a-5190-bbb0-12676f8e4dc9": 0.001358519950428958,
                            "89fbcf0e-e324-56f3-a361-d614329e24ea": 0.03557118927263617,
                            "03dcd030-84ff-5da2-b617-a648734921a5": 0.5,
                            "3b0835fe-f178-58d2-906f-f8a66737c021": 0.9677045353015494
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP79.1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "9ef68ae4-af66-5ea2-9961-2ddf2ce4425e": 0.001358519950428958,
                            "fce787ed-5443-5522-81ba-c6fa566d3743": 0.03557118927263617,
                            "782b65e7-2501-5414-b12a-302daead9bf1": 0.5,
                            "be369f00-ee1d-56e4-a34e-e357cbfd2b76": 0.9677045353015494
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP80.1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "fcc5d718-229f-5f07-9aa1-d3dc4289afaf": 0.001358519950428958,
                            "0ba28b6c-58e5-5e14-af28-b83c64d7de59": 0.03557118927263617,
                            "2250304e-421b-5cf8-9453-0eab14a2170b": 0.5,
                            "ca674276-1d63-53d4-bca8-79316c8f6295": 0.9677045353015494
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP80.6",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "c57fbe44-613a-52fb-a5f4-1930edba11d7": 0.001358519950428958,
                            "47fe93bd-8834-5e54-99a9-6e1b62c9f072": 0.03557118927263617,
                            "bc83c397-0824-59fe-b3a1-1683aab4f487": 0.5,
                            "25a4571f-2338-5796-be87-b1b2af243e03": 0.9677045353015494
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP81.1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "6d388eb5-265a-5381-b8b4-1258f0d4c896": 0.001358519950428958,
                            "ed9f80ca-b9ec-54df-92c9-a8b252612e6c": 0.03557118927263617,
                            "2a892e94-55a7-540f-994e-4d1fc65dbb15": 0.5,
                            "d465a1fd-b8bf-544d-b8ae-5b1c59299af4": 0.9677045353015494
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP110.1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "19f0b41f-9236-571f-8db4-20bd59fb8509": 0.001358519950428958,
                            "6a7ef643-1318-5d1c-af19-efebc5d5a5f5": 0.03557118927263617,
                            "9d19a3c0-120d-5dfd-a794-8aa0e8d39e77": 0.5,
                            "9ce31a68-0e9d-55e2-a573-174bcbeb7458": 0.9677045353015494
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.058823529411764705
    },
    "bd_longest_days_per_week": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP3.2",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "43011291-20a8-59ce-8bac-df882587ff5c": 0.001358519950428958,
                            "ec333f8b-146a-5700-a15d-527032ceac3f": 0.03557118927263617,
                            "c3319113-403a-5ecb-aa8a-071221d92c50": 0.5,
                            "f6654515-5729-5299-a4f0-da99056e7ac9": 0.9677045353015494
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP68.2",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "fd0a975d-cc5c-5457-8bbd-268324ba2e87": 0.001358519950428958,
                            "bdfeff69-22bc-5e8b-9d62-e7b990e8f332": 0.03557118927263617,
                            "e05b3b2c-6ce7-5e96-991d-9482839bb693": 0.5,
                            "5d8787f8-4138-5f42-b7d2-ae3afd336249": 0.9677045353015494
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP112.12",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "47152b8c-67cb-50db-8c2a-6b9d5252dd00": 0.001358519950428958,
                            "42d3031f-3362-5188-a9b3-10a808052a4c": 0.03557118927263617,
                            "e962d197-b406-5bcd-81c7-17eb8845b9f9": 0.5,
                            "add1ea84-0327-5281-9cf4-5ddbd95b2a8f": 0.9677045353015494
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP112.42",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "5f525bc7-41c7-5cae-beca-1d225c96befb": 0.001358519950428958,
                            "09593b3f-d6e3-5161-8271-a523700aa4ae": 0.03557118927263617,
                            "fb66de07-9697-502a-ba4f-54db581b92ed": 0.5,
                            "744f6684-76b7-5716-87a3-cdf767e6a929": 0.9677045353015494
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.25
    },
    "bd_self_confident": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP87",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "387b19c0-67d0-557e-9fba-ae065dcf3587": 0.0005527786369235996,
                            "ad1cfcc2-c1dc-57ef-b964-17d355fa0402": 0.0066928509242848554,
                            "9ae96e50-5c86-58a7-a845-fb6347c7edd1": 0.07585818002124355,
                            "e620af65-9b5b-57e6-b13a-65616e67e084": 0.5,
                            "960a08ff-7af7-56da-afda-5415a1654c85": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP115",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "e7b2a68b-de30-51bd-bb56-cda1254d3dbc": 0.0005527786369235996,
                            "dd02d36a-25b2-5daa-8cc6-5bb2d1382229": 0.0066928509242848554,
                            "c523c420-264e-5e54-ad59-a27e36b23132": 0.07585818002124355,
                            "030fff48-057e-5e57-8776-30a94e459131": 0.5,
                            "e67cb250-dabf-5ed8-8a1b-7a184dd74efd": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP87b",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "7949bd96-3757-5573-bd89-3a91a383cc99": 0.0005527786369235996,
                            "fb568891-2ea3-5200-bbdb-ac0695f30b02": 0.0066928509242848554,
                            "659c0d9a-389d-5b62-b3ac-e385dfafe63c": 0.07585818002124355,
                            "5bc9fc9f-d402-56b0-97eb-fe64add38edf": 0.5,
                            "8cf63df7-755b-597a-9125-d7435c303261": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "bd_out_perform": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM23",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "c41974b6-d394-569c-8f45-49f79fcac5c6": 0.0005527786369235996,
                            "5574c021-c3c4-513a-a4b6-5ee416e8aa9e": 0.0066928509242848554,
                            "56aac6d1-1fcc-5a72-bfda-2ae5d875df21": 0.07585818002124355,
                            "3ab9515b-73f0-5fcf-a4d0-aebbc24c2721": 0.5,
                            "c347cbdd-4788-538c-b190-8008a1e964e2": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM38",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "5125bbbb-70fb-5adc-a41b-c65c6b96180d": 0.0005527786369235996,
                            "69b8ea50-630a-587f-8283-640e5773e249": 0.0066928509242848554,
                            "01783efc-b456-5a0d-95fc-ee2321cc4f8d": 0.07585818002124355,
                            "6a9568e0-386d-5b07-a513-b1903bd81f31": 0.5,
                            "5db8d12e-30bb-555d-83f6-4a55db85785f": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_self_esteem": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_self_confident"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_out_perform"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_decreased_sleep_need": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP90",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "fd30dc27-8e89-5d1a-9d95-8844603d4379": 0.0005527786369235996,
                            "5d5fa968-aeb3-569a-8a71-483ef04b9a6a": 0.0066928509242848554,
                            "a2056280-4b54-5258-8bb4-407b521fff4b": 0.07585818002124355,
                            "93bfeb6e-215e-5f4b-b6de-0bc364e05be5": 0.5,
                            "7b2421d0-6b5b-5666-b0a9-951887636db1": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP118",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "ab15cfa9-d8ea-50f2-9c54-d7e033818659": 0.0005527786369235996,
                            "a221f7d7-f43a-5e8f-ac87-bb3cc3a447f3": 0.0066928509242848554,
                            "a611c3a0-727b-5a9b-8d15-d805b2ecf757": 0.07585818002124355,
                            "e642f54c-d6fc-57f8-a53c-73dbd698d428": 0.5,
                            "1eda0859-de62-56d0-836a-3f1778a6064b": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP90b",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "95c2822d-27ad-5bb0-982f-8c2b949c8918": 0.0005527786369235996,
                            "b4a79888-68e8-5b52-9a4e-2149be85f6e7": 0.0066928509242848554,
                            "607460aa-6bfa-56fd-9d06-75ec91ef85a1": 0.07585818002124355,
                            "940868cd-d067-573d-9a59-29b2ca63222f": 0.5,
                            "5915edb5-db3b-5f9a-8b43-09542fcaa677": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "bd_talk_to_everyone": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP92",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "d680a8d9-e35f-563f-9d1d-e7c3136aff6d": 0.0005527786369235996,
                            "ef5a7b22-b2b2-5a5b-8a80-ce5c251033fd": 0.0066928509242848554,
                            "17619692-6d18-5558-99da-766a1c275e03": 0.07585818002124355,
                            "63f0b17a-9832-5f0f-8630-e74f1a4fdfe7": 0.5,
                            "3984b363-353f-56b1-ad96-4840225109ef": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP120",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "548c329b-3169-5742-b17d-437216e6a454": 0.0005527786369235996,
                            "c8162ccf-aae0-5678-aeb9-fe02ed13ad99": 0.0066928509242848554,
                            "3c0c2a71-99b9-5210-b4e7-2734537fe5f4": 0.07585818002124355,
                            "e500747f-17cd-555a-a1e8-8968fd2be980": 0.5,
                            "137ac9f7-52c3-5502-8d0f-653df1384d35": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP92b",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "b2f4c490-30df-5c8f-87c0-bc4acbcfb8e0": 0.0005527786369235996,
                            "f59918ba-eb3a-5cf1-81aa-8b7f6ac0df5e": 0.0066928509242848554,
                            "dd87a55d-829b-54a2-a63d-5b8b634688ea": 0.07585818002124355,
                            "195241f6-1da5-5b54-b7ce-e73bb54d7d2b": 0.5,
                            "bdcabc25-9c00-5456-9d9b-a30e5b36cf6c": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "bd_others_speaking_faster": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP95",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "4a7d2992-b9e5-5e91-9a28-c2283dda5106": false,
                            "de2ea429-4fcd-53b8-8d9f-ebb69a637a02": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP123",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "972a0931-d7f1-5ad4-90af-91046fd7b193": false,
                            "5c6d0cea-9bce-5b98-b6ad-dcb0e3fb6b0d": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP95b",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "fb0bd4a7-2793-5b81-badc-fe81b4bc82dc": false,
                            "32255970-db0f-5cc0-a199-d412edf7ab7c": true
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "bd_talk_more_conversations": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM21",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "98ea07a0-ae95-570f-8d11-a3e7c682e986": 0.0005527786369235996,
                            "c3ee8981-dec3-5b3c-80ca-c1023f983a6d": 0.0066928509242848554,
                            "6b99de8b-634a-5eb0-bc65-bdf30eb9d433": 0.07585818002124355,
                            "0f8903ea-258c-54c8-9d4d-643924d3c234": 0.5,
                            "7977ac6e-f8f2-5d46-ba88-d01586f6e45d": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM36",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "47bfc26b-4237-5a82-9cc4-67d348c4acb0": 0.0005527786369235996,
                            "fc7ec28a-9883-58d5-a088-f3b113c2b4b3": 0.0066928509242848554,
                            "10794c2e-d0f1-5546-87aa-f3bc40003974": 0.07585818002124355,
                            "ee51f6bc-2dbd-5285-9ef7-f667a7a1bdb0": 0.5,
                            "79ac7b3c-38af-5670-b744-5d39000401df": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_jokes_puns": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM33",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "8823c74f-ff7c-52d8-9c73-fd3b4180f48a": 0.0005527786369235996,
                            "e95276b0-ee81-580b-b532-b0215f04abd5": 0.0066928509242848554,
                            "bde4e5f3-19e4-571f-84a2-5b9d188d74ef": 0.07585818002124355,
                            "0360ab4b-77d5-5fcd-8d60-b7a005c12ceb": 0.5,
                            "f0be1669-63f2-5fb0-9af7-55be0b4fb2c2": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM48",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "c0e7db40-86e9-5cba-98b0-e42a3fc0835b": 0.0005527786369235996,
                            "2db89264-9c8a-54e5-8efc-1b1ca45b0233": 0.0066928509242848554,
                            "9b74dd11-97db-567e-8d3d-836001d82229": 0.07585818002124355,
                            "c140b03a-5169-5731-838a-a63f017c99b6": 0.5,
                            "fdd2a948-7ae9-5aef-bf30-81970a357202": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_more_talkative": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_talk_to_everyone"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_others_speaking_faster"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_talk_more_conversations"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_jokes_puns"
                }
            }
        ],
        "anyToAll": 0.25
    },
    "bd_racing_thoughts": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP94",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "732c51f9-a0b6-5951-bd7d-eaaec24316ba": 0.0005527786369235996,
                            "201c7ee3-56dc-51e9-ad55-69951192ab16": 0.0066928509242848554,
                            "ec84966e-bb9f-5ba9-a0cf-d5d3503a74e0": 0.07585818002124355,
                            "2555d898-6736-5804-951e-e3c438313029": 0.5,
                            "1e86d64a-b972-500a-9120-7884e4b86ede": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP122",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "84ec10d0-2e9c-5671-9d59-dc91b256ca0b": 0.0005527786369235996,
                            "08c42ff1-0b0f-5018-8e88-552f8b8cf02a": 0.0066928509242848554,
                            "f1f5184d-35fb-5cac-b89f-4a4015d9de77": 0.07585818002124355,
                            "b1759380-318d-54b0-9224-163fb659558b": 0.5,
                            "3d008b0e-8759-568c-9aae-ba21c8b6ae79": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP94b",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "038cb12b-2783-569a-b1d7-e28c65e591a3": 0.0005527786369235996,
                            "1f4fafbf-913e-5edc-bfaf-3baae49fc91d": 0.0066928509242848554,
                            "33cefe4b-19c9-55e0-a87c-03ffd99d30bf": 0.07585818002124355,
                            "24f97aee-7df6-5c11-b615-f5064af38f63": 0.5,
                            "9cf74958-461b-59df-b1a2-7ee7d7696a0b": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "bd_creative": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP102",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "d3224e16-1b0c-5266-9e14-ac72d2b2e988": 0.0005527786369235996,
                            "1daccd4c-e5ff-5f18-ba55-7c2d2d93b849": 0.0066928509242848554,
                            "7230003d-a290-5a65-ae6b-b7d564b5db38": 0.07585818002124355,
                            "a3a547f3-b48b-567f-a32a-6a3f5f4bacd7": 0.5,
                            "e76cac02-f6b2-5ab6-a467-e3025d857ab9": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP130",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "7a066bdc-85a0-56cc-8632-2e83740855c1": 0.0005527786369235996,
                            "b4725e03-4f40-5305-9d3d-1ff9297f8bff": 0.0066928509242848554,
                            "bf6cdff0-63a3-5516-b548-b3c2d0ef83ab": 0.07585818002124355,
                            "a04d679f-abeb-5fb0-9db7-2ed9997ab672": 0.5,
                            "a2d91cbf-fa72-5028-91d6-9f00d0ce8aa3": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP102b",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "71338232-94be-5805-b822-94db8be9c5a1": 0.0005527786369235996,
                            "46a64b5b-6641-5b1b-92aa-5366444fb134": 0.0066928509242848554,
                            "00afc1e6-b78a-5223-a6e7-5d3aaab833e0": 0.07585818002124355,
                            "acb61f4d-a637-587c-bd33-3e9195921dd3": 0.5,
                            "523c7d8e-e42b-5654-b037-e8837afb49e7": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "bd_think_faster": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM34",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "1d07911e-929d-5719-aeea-a68f593d18e8": 0.0005527786369235996,
                            "4b8e4843-d260-59e5-8468-1303b1c3a638": 0.0066928509242848554,
                            "7d6921a2-7e63-5f12-9c75-dff8b592fabc": 0.07585818002124355,
                            "42710499-d4f3-5f65-bfad-382dde7e2f83": 0.5,
                            "ba200400-b5a1-5ae8-aead-863c1a72be98": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM49",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "ad65e31f-19f9-587c-bc99-0b3549cc5cb0": 0.0005527786369235996,
                            "555cca84-b650-53ab-944e-2289adb21903": 0.0066928509242848554,
                            "7a5feb6e-c27f-54d2-9473-fc197426685c": 0.07585818002124355,
                            "b6ba01ed-b20b-5d63-8093-eb7c245c8f85": 0.5,
                            "7c044595-1479-5f7c-b2ac-b356553a69c2": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_flight_of_ideas": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_racing_thoughts"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_creative"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_think_faster"
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "bd_distractibility": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP101",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "6a5aa899-7b89-5661-a554-71b6058ad4a4": 0.0005527786369235996,
                            "e9eb902a-d327-5ec5-938c-dc317ca59b13": 0.0066928509242848554,
                            "d8c1997f-d722-536c-ad9d-296b5e10ed04": 0.07585818002124355,
                            "ca5ac96d-2b69-5200-a063-61db840c9ef2": 0.5,
                            "1c4ed441-5919-569e-98ba-b49be1bc3957": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP129",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "3a5070b7-374a-5369-aceb-6b8936db2970": 0.0005527786369235996,
                            "c534368a-9c51-5f0d-8b17-9df288fd59a0": 0.0066928509242848554,
                            "00293814-728b-5c19-8744-73c54d0d4db2": 0.07585818002124355,
                            "4b90a2f8-960f-5d69-8e86-9c46af2fec0f": 0.5,
                            "477c3730-71ba-505f-9caa-07a83398580f": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP101b",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "cb80bcac-db78-55bd-b051-bdd00801fd54": 0.0005527786369235996,
                            "31b2d2c8-8f28-514e-9e58-aa878f6c8761": 0.0066928509242848554,
                            "c9856080-d9de-533e-92bf-7fbe2b6cd99a": 0.07585818002124355,
                            "fe614147-f80a-5aff-bb03-9298781e2468": 0.5,
                            "923cb2af-7bb5-5341-8349-003206ae043d": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "bd_active_events": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP88",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "afb57366-c0b3-5f73-92eb-27bc133ad2ed": 0.0005527786369235996,
                            "f501dc76-7fcd-586b-ba1d-ad70ab8e8d33": 0.0066928509242848554,
                            "a4ed572c-6aae-57f6-bc35-ea46929c1437": 0.07585818002124355,
                            "5f694075-3a97-5f8b-8701-2710a43127a9": 0.5,
                            "cb636d32-4f4e-51b3-a23f-2a11b8771b7e": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP116",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "bce3bb8c-216a-5167-8ec7-35c39096d501": 0.0005527786369235996,
                            "2d30ef1e-59ee-5b0a-8c01-0dcbfd64f6ca": 0.0066928509242848554,
                            "cd9085de-4e15-5383-b814-a261f79b136f": 0.07585818002124355,
                            "bab62178-4367-5df2-99ea-d5224dffb53e": 0.5,
                            "68c9cbe1-3f8f-5168-a960-d5ff6e8bb716": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP88b",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "fa24b924-a8e1-5f02-893b-3d9643007991": 0.0005527786369235996,
                            "e29e159f-f691-5aa7-b5a0-d13fe2c54170": 0.0066928509242848554,
                            "a6244707-7c91-5858-a2e1-f6f953f7b85e": 0.07585818002124355,
                            "7e4bdef7-d166-5a09-b3a4-639c1700578c": 0.5,
                            "887103af-eaf1-54a0-90d9-f9d6867fc69b": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "bd_everywhere_everything": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP96",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "6928c696-e6d7-5a14-a004-15a497ac6562": 0.0005527786369235996,
                            "dbab3669-e910-5267-b7b1-fcb661ea7e81": 0.0066928509242848554,
                            "6ade3a8d-8516-5a88-baa0-f996f32730d2": 0.07585818002124355,
                            "9b27e3ff-f8ce-54c5-9220-0479ae53e889": 0.5,
                            "e232ba2d-36a9-524e-9fb2-8541b27bb569": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP124",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "6410ed4b-d607-5edd-baae-ffeb61769bf8": 0.0005527786369235996,
                            "d7e7a81a-6129-54d0-8a45-35c7e9ae3bf3": 0.0066928509242848554,
                            "343ddd19-6dd5-54fb-a817-730184c2faf1": 0.07585818002124355,
                            "29c09b6c-a1ca-5b73-9d71-1eeef40c8aef": 0.5,
                            "368a583d-81fc-5f77-a51c-647fd7f8f473": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP96b",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "8c3d3503-6334-55b9-b0f0-94c8e520094e": 0.0005527786369235996,
                            "477d4084-be50-50ae-9e88-dc9e03cdad29": 0.0066928509242848554,
                            "c1f02353-e805-53f2-ab3b-34499538a407": 0.07585818002124355,
                            "e200f36c-3005-5e37-82d3-9228fcf60bfb": 0.5,
                            "4043e017-8609-50ef-bb4a-29f57c28965a": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "bd_sociable_outgoing": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP89",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "667ba57c-b1cd-5f9b-aa12-58e65a981910": 0.0005527786369235996,
                            "2404146e-cd56-5356-a23b-6427b4bbba06": 0.0066928509242848554,
                            "92e118de-38b9-5d5a-96ba-7b50547c0621": 0.07585818002124355,
                            "28c75e9e-94ef-5c50-8645-f4ff93ccf2ec": 0.5,
                            "0953f30c-801a-54f6-8238-b9dc42ab17f9": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP117",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "354c012f-3484-58ad-9300-d9e29a212ae3": 0.0005527786369235996,
                            "c68c6fcb-e62b-5749-80e4-9c4ad9995b97": 0.0066928509242848554,
                            "aae4e65c-c66b-5e2d-8a18-88b17f5e6eb8": 0.07585818002124355,
                            "0d476185-4df9-5c70-837d-2b5ff4e08893": 0.5,
                            "1d169f46-d28a-5b85-b98f-31e378c13d40": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP89b",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "41d2829a-3971-552f-8c5a-c77e7d0073e7": 0.0005527786369235996,
                            "db509963-d872-5b97-ba9b-a276f58e0189": 0.0066928509242848554,
                            "db64e5ea-3aa0-50f0-a9ad-94f7010267eb": 0.07585818002124355,
                            "df5f6aa3-ab8c-5a4c-b9d9-e9a482aba1d7": 0.5,
                            "adfc4e81-0d5f-5ba6-8507-9072b6e02bba": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "bd_less_inhibited": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP98",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "3cf77140-2757-58eb-b4ea-f2a90cd766e7": 0.0005527786369235996,
                            "58777a2e-4b97-5413-b340-efe722e45936": 0.0066928509242848554,
                            "cfb8fe0e-821a-554a-bb57-922f0ecc0f44": 0.07585818002124355,
                            "a0a2f2f1-ed73-59bb-add9-5f3569bbbecb": 0.5,
                            "b14d53e4-f898-52ac-87d7-3aa62abc9d7b": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP126",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "7bdce4bf-24ca-5470-a5cd-68c1e5c7ef03": 0.0005527786369235996,
                            "52d061e3-96b8-5053-893a-03758e4f0518": 0.0066928509242848554,
                            "3b0900cc-ebc0-5606-a6f6-066c9c6c2c97": 0.07585818002124355,
                            "47856c8e-63c1-5066-bf95-92ff01e0982a": 0.5,
                            "f5e0c5dc-06c9-5f60-9b97-3b79d63a406c": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP98b",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "01641419-fbfc-52a5-ae63-0fa736ff9847": 0.0005527786369235996,
                            "f7b75a2b-3bd2-5734-9fa6-7706f7f8ac89": 0.0066928509242848554,
                            "cae1d431-f7ae-5a5a-924d-25c540e96d28": 0.07585818002124355,
                            "1385fe83-520e-5986-8bec-9f0630d48219": 0.5,
                            "81112699-9c7d-5ee5-a605-5d4647a698d1": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "bd_activities_projects": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "086c0ca6-3cdd-584d-a949-a5ed5e3d8aa4": 0.0066928509242848554,
                            "60caa355-cd97-591a-9344-1e9858939855": 0.07585818002124355,
                            "3d860ce1-917d-5fdf-a025-ce36b81bb1fb": 0.5,
                            "8434c112-273f-5c5a-a704-62c8cb63dd51": 0.9241418199787566,
                            "c44193b6-642c-56d3-ada3-eda9bd17a42e": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM2",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "de662c3f-66b4-566f-856d-2124e2725542": 0.0066928509242848554,
                            "2b56aa7d-493f-5a18-aa9f-f97304f89130": 0.07585818002124355,
                            "a046d610-d27a-59c1-b130-38312005cf1c": 0.5,
                            "fc6fa8a1-ab36-5c3d-901d-feec17df2f1a": 0.9241418199787566,
                            "3bf2fe22-146e-560d-8aae-39a56747f149": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM3",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "bcdbdc29-42ba-5c6e-b655-fcca007ce28d": 0.0066928509242848554,
                            "da93cad9-334a-57d5-b26d-fabb43b2d059": 0.07585818002124355,
                            "7823432e-ad44-5fbe-9781-48d9ce136451": 0.5,
                            "1d37ab0b-0386-5463-a135-eebb9f0f0e0c": 0.9241418199787566,
                            "dbce9e65-8768-5d55-af51-0fd58c8e9853": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM4",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "002aec48-2785-5a3e-856a-7b2ff6990863": 0.0066928509242848554,
                            "67ff3da2-b682-5e3d-a30c-e07aa27c64b8": 0.07585818002124355,
                            "5dd99b1f-4bcd-5921-857a-6f99b2bc139c": 0.5,
                            "1f142f13-5d5e-57af-82f3-5df564dae9cb": 0.9241418199787566,
                            "c8fd6c78-0f80-5c1a-9cdf-61fd1d153c52": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "b9cb4697-38be-5d4a-af02-623aea1363b0": 0.0066928509242848554,
                            "a96ba3ce-dc9b-54ab-8a24-5ece4cf16447": 0.07585818002124355,
                            "f6322217-0772-5ca4-a7a8-e8efbf97dbec": 0.5,
                            "f7cb9a05-4357-5077-8730-767d9ed58b02": 0.9241418199787566,
                            "67484765-83ea-5089-99bf-bbf7a66818fa": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM6",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "70ede32f-268b-5ca6-864a-107cdd966cc5": 0.0066928509242848554,
                            "868022b7-397f-52c6-9d1e-77ce0c8ed442": 0.07585818002124355,
                            "462c1a4b-5cc8-591b-864d-7a9319b983c0": 0.5,
                            "661c6bc5-4691-5ba3-b0bd-96222d039db2": 0.9241418199787566,
                            "ed1586b4-e29c-5b36-9283-d6a861c277df": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM7",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "cd2f9957-ea07-5b37-ace2-0e4ee0a5c05a": 0.0066928509242848554,
                            "7d828e74-497e-5b42-9adc-f6559649481e": 0.07585818002124355,
                            "b43d7a75-2456-5a42-9e2c-2edbe34ccc12": 0.5,
                            "931a7dd0-61d5-54b9-9e85-3c5ff79d312b": 0.9241418199787566,
                            "9d06d48e-dfe4-59da-af07-4baf37a61e5c": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM18",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "7b94568b-b9d0-5ea2-91ca-4afe242acfb8": 0.0066928509242848554,
                            "d46fbd7a-074b-5014-9767-77e52f83e365": 0.07585818002124355,
                            "e91203fa-5410-59f8-a2a7-cfbd0257e576": 0.5,
                            "6bafc310-38fa-5bc1-bca7-412515a46505": 0.9241418199787566,
                            "2ccd221e-eeba-5fa4-b5b1-f1439c64398b": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM18",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "7b94568b-b9d0-5ea2-91ca-4afe242acfb8": 0.0066928509242848554,
                            "d46fbd7a-074b-5014-9767-77e52f83e365": 0.07585818002124355,
                            "e91203fa-5410-59f8-a2a7-cfbd0257e576": 0.5,
                            "6bafc310-38fa-5bc1-bca7-412515a46505": 0.9241418199787566,
                            "2ccd221e-eeba-5fa4-b5b1-f1439c64398b": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM20",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "4ff0f88c-e951-5e87-a941-815105819115": 0.0066928509242848554,
                            "4eb576cf-e8d9-51f2-94cd-d232476ee5cb": 0.07585818002124355,
                            "b8816e4b-2104-5ce7-a5b0-b804a1a0dc20": 0.5,
                            "be55154a-b96f-5c1c-91de-2a118e42e6f8": 0.9241418199787566,
                            "ba281b75-7509-59be-a0b3-12bccbd60e6d": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM35",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "c5f21a2b-5af8-525d-bfab-0ed035defec0": 0.0066928509242848554,
                            "2b40020d-33ba-5560-9075-d482aa324496": 0.07585818002124355,
                            "9eeab515-74ed-5cdc-8b02-9ce8c1a2d197": 0.5,
                            "13ede675-77ba-58af-911e-c3a4457f4e56": 0.9241418199787566,
                            "fef4dc4e-6c90-5b86-88ae-e9a5ee7c9d5a": 0.9933071490757153
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.09090909090909091
    },
    "bd_meet_people": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM22",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "696fdd9d-5ced-504b-a933-bb3e575b0099": 0.0005527786369235996,
                            "a43647cb-a0e8-5705-adb9-a2b68877dc77": 0.0066928509242848554,
                            "9d212885-9b07-5623-8234-226adff08f6d": 0.07585818002124355,
                            "8f7acbc1-9ebe-5a52-95ce-b50d62c27345": 0.5,
                            "5b02ea99-72f6-5b68-b536-b6f32aecc86c": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM37",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "20de534d-f482-51c5-ad87-bd054787fddc": 0.0005527786369235996,
                            "a2c38330-1fdc-55c8-9a19-209f55bd344f": 0.0066928509242848554,
                            "77031c75-3438-5152-9142-8478bb9bdc66": 0.07585818002124355,
                            "cfd0a32c-e988-5dce-85c8-e07628f719b5": 0.5,
                            "05934e5e-fca9-566e-9a69-eec32b7da87b": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_soul_of_party": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM25",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "43c32319-c75f-5169-b0e5-713820721d4f": 0.0005527786369235996,
                            "3fd957d1-0cbb-5397-9812-353be5c2184e": 0.0066928509242848554,
                            "c8c8c192-aea3-5585-b11f-9d13faf4367d": 0.07585818002124355,
                            "0e9545ba-3f35-52b0-9a31-7fe54c933928": 0.5,
                            "b60806d0-ac44-589e-a567-a91918ccf9f8": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM40",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "596144bf-d0ea-5d17-a2ae-7096c31e2def": 0.0005527786369235996,
                            "3110a9c5-47d0-5e35-952b-74c6a481b635": 0.0066928509242848554,
                            "82682922-e4e5-5674-9a94-b96f5d798846": 0.07585818002124355,
                            "7606c31a-ed49-584c-9e82-c909a18843cd": 0.5,
                            "449c3bd8-c1d4-530d-8bcc-e4bf16434a9d": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_sexual_feelings": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM26",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "b2cb304f-f035-53fc-8763-e2f91b031f9b": 0.0005527786369235996,
                            "d2c670c4-b05b-597a-a0af-5342230535a0": 0.0066928509242848554,
                            "898957c6-8cd1-53e5-98f8-c16789aba613": 0.07585818002124355,
                            "eb41dc92-16ec-5abf-a841-e1d0b262083e": 0.5,
                            "65bee7d4-bf7f-5a21-9d92-f16651b0d1aa": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM41",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "dd9cb999-d487-51f2-8ca3-f121593feca0": 0.0005527786369235996,
                            "5dc47933-8a72-5087-b068-38e22758b9f7": 0.0066928509242848554,
                            "db39ddc0-79fd-5c61-b063-7f0bc0bab024": 0.07585818002124355,
                            "e8be146c-dcaf-5060-ad4a-468ca2878cb2": 0.5,
                            "8fea83cc-74c5-577c-b8ed-8d538140b44d": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_sexual_behaviour": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM30",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "2a17c2fe-2592-58b1-98e9-3dc4168fe3e3": 0.0005527786369235996,
                            "0983ccf5-a426-53d0-adbb-1baf6832190c": 0.0066928509242848554,
                            "aa90f24c-ee55-515b-b5a2-78bdacbe25a0": 0.07585818002124355,
                            "2fcb75b3-7ba4-50b5-b2a3-ad1ead57dbe1": 0.5,
                            "73b26794-a969-527b-8268-f17c4e485bda": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM45",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "28a20a2a-3318-5dba-9e59-86d943d3ac73": 0.0005527786369235996,
                            "2722d440-372d-5471-8cab-ef78b8026a24": 0.0066928509242848554,
                            "637476f9-73ea-5066-9b3e-8c4bc05c3b51": 0.07585818002124355,
                            "a4667066-a91a-56d4-8b53-682a65490819": 0.5,
                            "9cfe579e-f4fa-589a-bcd7-1f28243d92f2": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_appearance_extravagance": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM28",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "0170b4cf-0541-53cd-a9db-818d5067dc23": 0.0005527786369235996,
                            "bcd05aa7-d9e3-5bdc-8661-0daa33c7b9b3": 0.0066928509242848554,
                            "366c6a1b-c8b2-5faf-9d2f-72dbde24e869": 0.07585818002124355,
                            "57de31c8-9bf0-5003-9dbb-0e8559f994dd": 0.5,
                            "cec918d6-b855-5672-94a8-cf14e5ae963a": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM43",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "a8b07611-dcc0-55ef-ae78-d4ceb7ad42e3": 0.0005527786369235996,
                            "d41a8ccb-18d3-58ef-b09a-b2b0e34dc48c": 0.0066928509242848554,
                            "ad8a0302-30ec-5868-a265-87c35baefdac": 0.07585818002124355,
                            "08b88c09-4278-5bd5-b72d-4f4a9a69acd0": 0.5,
                            "2023b400-6f09-59ac-b67e-657f6d592763": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_quicker_doing": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM31",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "834b04f2-af09-5337-8fd5-be4a70c99e75": 0.0005527786369235996,
                            "7e32475a-3fcc-51ac-b414-ab46578efdfd": 0.0066928509242848554,
                            "a56fcb96-f868-5d08-a358-9b403eb3cd16": 0.07585818002124355,
                            "e979459c-cb23-5cea-bedd-432a62288787": 0.5,
                            "0f2a6025-0d8f-5d89-81a2-c3cc425cd7c4": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM46",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "9f868300-e1df-5567-8ebf-e2f984022e52": 0.0005527786369235996,
                            "a54e0650-9e33-5e16-a4a9-2c31bc10c96c": 0.0066928509242848554,
                            "a57434cf-7c45-5e3d-9d0d-c8b432490e99": 0.07585818002124355,
                            "772a32ae-a62a-57a3-9d99-92106761d701": 0.5,
                            "4aa7c56d-2337-5383-80d4-d0599322231e": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_increase_goal_directed": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_active_events"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_everywhere_everything"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_sociable_outgoing"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_activities_projects"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_soul_of_party"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_meet_people"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_sexual_feelings"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_sexual_behaviour"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_appearance_extravagance"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_quicker_doing"
                }
            }
        ],
        "anyToAll": 0.1
    },
    "bd_excessive_spending_hm": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM32",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "86277249-cf50-5f91-a27f-f3a69344e796": 0.0005527786369235996,
                            "fa32b497-4a4b-5076-a391-b6dffa8e1851": 0.0066928509242848554,
                            "fda00d52-c8be-5b69-bdbd-905c13dddd4e": 0.07585818002124355,
                            "fd982070-f2ad-5ff4-be48-fb6c78e856c4": 0.5,
                            "e9e6eb20-c3f6-516f-8431-d94c3555cc05": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "HM47",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "41ec9370-ba26-5cbc-ad35-d9e578788aa4": 0.0005527786369235996,
                            "e03a7683-7e3e-563d-b531-33e529887c90": 0.0066928509242848554,
                            "c0256df5-0653-5e2e-b656-58097a53c002": 0.07585818002124355,
                            "49d31acb-f117-54a2-b2bc-5680ccad6e6f": 0.5,
                            "6f3ea1ee-6ac6-53b8-804e-609d817b88da": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_excessive_spending": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP99.3",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "c0cc09c3-1335-5eb4-8801-b3cdaf74f6fa": 0.0005527786369235996,
                            "a020ed85-ec9d-5778-8006-41f634cab09d": 0.0066928509242848554,
                            "e81fe613-4923-55ed-bd6c-bd3f19e026f9": 0.07585818002124355,
                            "b9e6efff-f5b2-5911-ba7a-17c768e4613e": 0.5,
                            "dee1118a-2ca3-52a6-ac43-a24ea7385f88": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP99.3b",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "bf30a6c2-f8e2-5305-b4d4-7ad6227449d3": 0.0005527786369235996,
                            "d0adef9f-05e3-5ddf-a0f3-60eeb50d3862": 0.0066928509242848554,
                            "c5d5e724-00d6-5a76-bcaf-e4b6545b7fa4": 0.07585818002124355,
                            "e4575ba2-20f0-519e-967b-d5769763cadc": 0.5,
                            "cb008e5c-46a4-5b1b-bcea-6f59111913d0": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP127.3",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "e1cc34e0-2ad5-5043-ba9b-c38b53b1d2de": 0.0005527786369235996,
                            "38efc61e-4155-51f0-8b64-341f627e5482": 0.0066928509242848554,
                            "2d74a699-1958-513e-94d7-e25ebf081a91": 0.07585818002124355,
                            "cd346eed-7344-51aa-aa80-7964a3dce896": 0.5,
                            "e6bc1265-542f-5413-9743-ee7e5adec6e3": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "bd_others_excessive_foolish": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP99.1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "34d19f12-207f-56ce-bde8-8d616f6e8b26": 0.0005527786369235996,
                            "659c1b81-1b5d-5c34-9d0d-ce0ceae2a3b3": 0.07585818002124355,
                            "74809c02-f47f-5955-a416-b40fc66ff214": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP127.1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "a2bbf0b5-4203-5b73-89f8-05f59515356f": 0.0005527786369235996,
                            "b76730b2-4e72-5ab7-b0c7-03b88d01b19c": 0.07585818002124355,
                            "68c97dc1-4b15-59a6-b2ae-49597771e772": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP99.1b",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "255f682d-5067-5a6d-835a-fe587b4169e6": 0.0005527786369235996,
                            "74ac8d69-c317-57fb-a5e5-a93c1309c0f7": 0.07585818002124355,
                            "474c64d7-23b9-5015-9cdd-60661ad38d39": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "bd_others_risky_dangerous": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP99.2",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "ac6c9baf-e6df-5453-8665-0b796e1b83f8": 0.0005527786369235996,
                            "f55f25af-1247-5c48-8385-341f7e4fea59": 0.07585818002124355,
                            "e8c07c0f-f594-5e38-a18d-fb9d7835cf9f": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP127.2",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "6ed360b8-fd85-5299-b879-f07ef1494425": 0.0005527786369235996,
                            "f44c6fd9-72d7-5254-bf0d-52045ae546c7": 0.07585818002124355,
                            "2e4d8704-5188-5d7c-bfaf-6c83462b3621": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP99.2b",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "0f933dbb-72b4-5212-b415-8ea806f59368": 0.0005527786369235996,
                            "32f6041f-9e2c-537c-9db7-19f89011fce9": 0.07585818002124355,
                            "a8224369-4083-5ab5-8cad-c4563490599c": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "bd_risky": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_others_excessive_foolish"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_others_risky_dangerous"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_excessive_spending"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_excessive_spending_hm"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_less_inhibited"
                }
            }
        ],
        "anyToAll": 0.2
    },
    "bd_work_problems": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP91",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "46c90446-3ae9-53f5-9b57-ad19d66b5be6": 0,
                            "0feda24f-7b01-557b-8ca5-10eb924cbdac": 0.25,
                            "1f57d1a0-3682-5d7d-b66b-8179ec5c3988": 0.5,
                            "cc7c09ae-1cb7-5b9a-9cd3-8d1012b90ee8": 0.75,
                            "7cd91611-c433-5f81-b829-8454750b387c": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP119",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "3f4ab649-e8be-559b-a32f-9ccb317ffb38": 0,
                            "faf4eb68-5627-5070-8937-42cf8cbc74d7": 0.25,
                            "50c573e0-accf-5acc-be15-616cb65704cc": 0.5,
                            "6d54b724-84ce-55bd-9912-b4951133fb61": 0.75,
                            "64d22ab4-481d-5258-9244-0ec9d3ba77a7": 1
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_time_off_work": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP91.1",
                    "options": {
                        "type": "severities",
                        "severities": {}
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP119.1",
                    "options": {
                        "type": "severities",
                        "severities": {}
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_self_neglect": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP90.1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "e22cf233-c174-52fb-af55-97bf9c46e971": 0,
                            "d9fb150b-68b8-50ed-bb1d-eafd41f63f3b": 0.25,
                            "c211fabc-a62d-5440-8ddc-f57eb21d7679": 0.5,
                            "eb97df23-3118-56bc-8c37-c54d7a750e4f": 0.75,
                            "33e48f82-ae80-5f20-a3a5-79692d21ad1a": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP118.1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "3299a8a7-4375-55ee-87b2-76bda766fd11": 0,
                            "a7937947-986d-5fbf-83cd-d1c893dcdc7a": 0.25,
                            "801fd4db-2f09-5e10-a9b7-43319a3ac1d0": 0.5,
                            "f80f4b1b-7286-52d0-8218-7083ae3bb582": 0.75,
                            "3780fe32-30da-5e3b-bad1-6d360381e7e4": 1
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_leasure_problems": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP100",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "4a0f1c4d-38cf-5de4-99e3-0ee818f9709c": 0,
                            "db7aa4b7-99a5-5475-a4e8-a744f3ef5a4a": 0.25,
                            "a20d7b84-720e-53c5-a450-45cbde434507": 0.5,
                            "182e121d-415c-5f42-a76c-a996f5f38b3f": 0.75,
                            "026a1cea-7cfc-540b-bfe5-42886be90595": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP128",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "6b43ea3f-8741-56e6-808f-129fe78b7440": 0,
                            "ebff4971-05dc-52ed-9957-33c0ca1479c1": 0.25,
                            "5bf8ce18-aa1a-5c57-86d6-687efcad92de": 0.5,
                            "f2c70c18-c55b-5447-9859-67292866bede": 0.75,
                            "8a7adbc6-1053-55ce-b226-1e2bc2131050": 1
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_relationship_problems": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP93",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "c88e3fb5-913a-5c5c-a42b-603f7bb60a40": 0,
                            "94795194-4a80-590f-be62-ee78f2fa0ff5": 0.25,
                            "f35fe383-d2aa-51c4-9fde-961b1de6d52b": 0.5,
                            "82997889-d795-572b-a597-e484ef078b39": 0.75,
                            "940c16a8-1835-582a-9dad-bd52e3a49cc7": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP121",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "76891993-4f79-5b7a-ad6f-b7be1c4994fb": 0,
                            "c344141d-3295-5ecc-bf2a-4c45f9d330db": 0.25,
                            "7e71cdc3-11c7-5156-87b9-a53194156832": 0.5,
                            "7caa27c0-01f2-586f-825c-7c1bd527ef54": 0.75,
                            "2dd3fa4b-7390-5c95-b04b-c5c77974e8c2": 1
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_functional_impairment_sub": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.75,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "bd_work_problems"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 1,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "bd_time_off_work"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.75,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "bd_self_neglect"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.75,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "bd_leasure_problems"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.75,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "bd_relationship_problems"
                    }
                }
            }
        ],
        "anyToAll": 0.2
    },
    "bd_functional_impairment_atleastone": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "bd_work_problems"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "bd_leasure_problems"
                    }
                }
            },
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.5,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "bd_relationship_problems"
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "bd_hospitalisation_yes": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP74",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "dad63f8c-7498-59d2-ac8d-a8d57a9dbab8": false,
                            "cd4ba75c-7b4b-5126-9170-d54c05b66f1f": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP83",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "dad46374-d0d7-5468-97d1-ff70b6a2f893": false,
                            "49b6c935-6235-511a-b989-acce3e5f3572": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP113",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "b16103eb-2ecf-595e-80e4-30baf575e01b": false,
                            "b761d57f-0aed-52ed-9ffb-467f63c4fb29": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP16.0",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "4b26caa5-9124-51b2-ab08-2f4e977cee1f": false,
                            "d425010d-9cb9-54a7-a1a6-2c7b577896e7": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP8.0",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "3568cdb1-2500-512b-aada-2547c5bfd835": false,
                            "c7d02d20-9064-5a94-86d4-053ed361ed32": true
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.2
    },
    "bd_hospitalisation_no": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "bd_hospitalisation_yes"
            }
        }
    },
    "bd_irrational_beliefs": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP103",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "b24df75b-6bb9-5f5f-99e3-c883ec3644ec": 0,
                            "f2228920-3fec-5097-a63d-990c25d50c6c": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP131",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "e5863f5d-32a6-520a-9b40-8591adf1c1b8": 0,
                            "cf80f9fa-de7b-5954-b005-e9ec1b1b07a9": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP103b",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "867b0ee4-73d9-5aac-b96d-bc2d85d5b183": 0,
                            "c4bec2f3-b4c0-5a86-abf4-28921c5b1956": 1
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "bd_hallucinations": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP104",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "71f4aadd-848c-56d9-b008-3b1bf7ddae28": 0,
                            "de4464c8-78fe-5876-aa00-25826ce5ec25": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP132",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "da9000ec-69f6-580b-9ec9-756b702c2962": 0,
                            "7da9fe3a-3186-562a-b4f2-170d5baf0c76": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP104b",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "c59b5910-3279-509f-8175-f2fd0da2dc86": 0,
                            "75eb3568-195e-5720-827c-1fd0f84c6e75": 1
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "bd_delusions": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_irrational_beliefs"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_hallucinations"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_current_episode": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "Dem18",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "9d064e64-ec95-50b8-af10-5b9d39e9fb8d": false,
                            "05653d8f-0bd2-5c1a-8a9f-fee81d9e4cfa": false,
                            "db090d78-6247-560e-893c-e74389c1efc6": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP1.5",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "7599bcaa-89a5-5f71-80ea-b70c360bb44a": false,
                            "ccbdcbec-96d0-5362-977b-784289f5cfc0": false,
                            "83e6efd5-64a2-5f0e-a973-9278eae90cfb": false,
                            "6215a3de-a3e4-5f86-a039-1006646e6b93": true
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_manic_duration_sub": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "bd_manic_duration_week_plus"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "bd_hours_per_day"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "bd_days_per_week"
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "bd_longest_manic_duration_week_plus"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "bd_longest_hours_per_day"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "bd_longest_days_per_week"
                        }
                    }
                ],
                "anyToAll": 1
            }
        ],
        "anyToAll": 0.5
    },
    "bd_duration_or_hospitalisation": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_hospitalisation_yes"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_manic_duration_sub"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_modsev_functional_impairment_agg": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_functional_impairment_sub"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_hospitalisation_yes"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_manic_episode_classic": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_unusual_persistent_highlike_episode"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_manic_duration_sub"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_symptom_3plus_agg"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_modsev_functional_impairment_agg"
                }
            }
        ],
        "anyToAll": 1
    },
    "bd_manic_episode_irritable": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_unusual_persistent_highlike_episode"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_irritable_mood"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_manic_duration_sub"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_symptom_4plus_agg"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_modsev_functional_impairment_agg"
                }
            }
        ],
        "anyToAll": 1
    },
    "bd_manic_episode": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_manic_episode_classic"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_manic_episode_irritable"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_severity": {
        "type": "input",
        "signal": {
            "type": "AspectReference",
            "aspectName": "bd_manic_episode"
        }
    },
    "bd_hypomanic_duration_sub": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "bd_manic_duration_4days_plus"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "bd_hours_per_day"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "bd_days_per_week"
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "bd_longest_manic_duration_4days_plus"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "bd_longest_hours_per_day"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "bd_longest_days_per_week"
                        }
                    }
                ],
                "anyToAll": 1
            }
        ],
        "anyToAll": 0.5
    },
    "bd_mild_functional_impairment_agg": {
        "type": "merge",
        "wires": [
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "bd_functional_impairment_sub"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "bd_manic_duration_week_plus"
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_hypomanic_functional_impairment_or_symptoms": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_functional_impairment_atleastone"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_symptom_5plus_agg"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_hypomanic_episode": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_unusual_persistent_highlike_episode"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_hypomanic_duration_sub"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_symptom_3plus_agg"
                }
            }
        ],
        "anyToAll": 1
    },
    "bd_threshold_manic_episode": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.6,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "bd_severity"
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_manic_episode"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_threshold_hypomanic_episode": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.25,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "bd_severity"
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_hypomanic_episode"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "bd_threshold_submanic_episode": {
        "type": "filter",
        "sigmoid": {
            "middle": 0.1,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "bd_severity"
            }
        }
    },
    "has_bd1": {
        "type": "input",
        "signal": {
            "type": "AspectReference",
            "aspectName": "bd_threshold_manic_episode"
        }
    },
    "has_bd2": {
        "type": "merge",
        "wires": [
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_bd1"
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_threshold_hypomanic_episode"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_lifetime_mdd_episode"
                }
            }
        ],
        "anyToAll": 1
    },
    "has_current_mdd": {
        "type": "merge",
        "wires": [
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_bd1"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_bd2"
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_current_episode_above_mild"
                }
            }
        ],
        "anyToAll": 1
    },
    "has_past_mdd": {
        "type": "merge",
        "wires": [
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_bd1"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_bd2"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_mdd"
                    }
                }
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "has_past_mild_dep"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "has_past_moderate_dep"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "has_past_severe_dep"
                        }
                    }
                ],
                "anyToAll": 0.3333333333333333
            }
        ],
        "anyToAll": 1
    },
    "has_current_normal_mood": {
        "type": "merge",
        "wires": [
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_subclinical_lowmood"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_subclinical_manic"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_mild_dep"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_moderate_dep"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_severe_dep"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_manic_symptoms"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "has_current_subclinical_lowmood": {
        "type": "merge",
        "wires": [
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_mild_dep"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_moderate_dep"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_severe_dep"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_subclinical_manic"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_manic_symptoms"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "has_current_subclinical_manic": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "bd_threshold_submanic_episode"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "bd_threshold_hypomanic_episode"
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_current_episode"
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_bd1"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_bd2"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_mild_dep"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_moderate_dep"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_severe_dep"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "has_current_manic_symptoms": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_current_episode"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_threshold_hypomanic_episode"
                }
            }
        ],
        "anyToAll": 1
    },
    "has_current_mild_dep": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_current_episode_above_mild"
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_moderate_dep"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_severe_dep"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_manic_symptoms"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "has_current_moderate_dep": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_current_episode_above_moderate"
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_severe_dep"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_manic_symptoms"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "has_current_severe_dep": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_current_episode_above_severe"
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_current_manic_symptoms"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "mdd_current_episode_above_mild": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.3,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "mdd_current_severity"
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_current_mdd_mild"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "mdd_current_episode_above_moderate": {
        "type": "filter",
        "sigmoid": {
            "middle": 0.6,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "mdd_current_severity"
            }
        }
    },
    "mdd_current_episode_above_severe": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.8,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "mdd_current_severity"
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_current_mdd_severe"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "has_past_mild_dep": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_past_above_mild"
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_past_moderate_dep"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_past_severe_dep"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "has_past_moderate_dep": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_past_above_moderate"
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_past_severe_dep"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "has_past_severe_dep": {
        "type": "input",
        "signal": {
            "type": "AspectReference",
            "aspectName": "mdd_past_above_severe"
        }
    },
    "mdd_past_above_mild": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.3,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "mdd_worst_severity"
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_past_mdd_mild"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "mdd_past_above_moderate": {
        "type": "filter",
        "sigmoid": {
            "middle": 0.6,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "mdd_worst_severity"
            }
        }
    },
    "mdd_past_above_severe": {
        "type": "merge",
        "wires": [
            {
                "type": "filter",
                "sigmoid": {
                    "middle": 0.8,
                    "width": 1
                },
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "mdd_worst_severity"
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_past_mdd_severe"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "always_false": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "DemS"
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "DemS"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "always_true": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "always_false"
            }
        }
    },
    "bd1_cm": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_bd1"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_current_manic_symptoms"
                }
            }
        ],
        "anyToAll": 1
    },
    "mdd_cd_mi": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_current_mdd"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_current_mild_dep"
                }
            }
        ],
        "anyToAll": 1
    },
    "simple_bd1": {
        "type": "input",
        "signal": {
            "type": "AspectReference",
            "aspectName": "bd_manic_episode"
        }
    },
    "simple_lifetime_mdd_episode": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_current_mdd_mild"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_past_mdd_mild"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "mdd_for_bd2": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "simple_lifetime_mdd_episode"
                }
            },
            {
                "type": "toPredicate",
                "wire": {
                    "type": "filter",
                    "sigmoid": {
                        "middle": 0.25,
                        "width": 1
                    },
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "bd_hypomanic_episode"
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "simple_bd2": {
        "type": "merge",
        "wires": [
            {
                "type": "toPredicate",
                "wire": {
                    "type": "not",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "simple_bd1"
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "bd_hypomanic_episode"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "mdd_for_bd2"
                }
            }
        ],
        "anyToAll": 1
    },
    "simple_mdd": {
        "type": "merge",
        "wires": [
            {
                "type": "toPredicate",
                "wire": {
                    "type": "not",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "simple_bd1"
                        }
                    }
                }
            },
            {
                "type": "toPredicate",
                "wire": {
                    "type": "not",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "simple_bd2"
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_current_mdd_mild"
                }
            }
        ],
        "anyToAll": 1
    },
    "Dem11_mdd": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "Dem11",
            "options": {
                "type": "severities",
                "severities": {
                    "44fd9eb3-aee2-5af0-b83c-dbbbf9514022": 1
                }
            }
        }
    },
    "Dem11_bpd": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "Dem11",
            "options": {
                "type": "severities",
                "severities": {
                    "ff95cad7-222f-53dd-b92b-d5ddc6b6ac5a": 1
                }
            }
        }
    },
    "Dem11_gad": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "Dem11",
            "options": {
                "type": "severities",
                "severities": {
                    "07106ab9-5949-5b5f-bc96-117139ebd397": 1
                }
            }
        }
    },
    "Dem11_pers": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "Dem11",
            "options": {
                "type": "severities",
                "severities": {
                    "f52974b4-d0c2-589c-88d8-8628f43a2f5e": 1
                }
            }
        }
    },
    "Dem11_ptsd": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "Dem11",
            "options": {
                "type": "severities",
                "severities": {
                    "6a4d8b43-be3d-59b5-9bf4-1ca9f35b6973": 1
                }
            }
        }
    },
    "Dem11_ins": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "Dem11",
            "options": {
                "type": "severities",
                "severities": {
                    "8c599171-d554-5547-9f54-2066c1a711dd": 1
                }
            }
        }
    },
    "Dem25_OCD": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "Dem11",
            "options": {
                "type": "severities",
                "severities": {
                    "4896b504-2025-52c3-85e2-c6cf3205cef7": 1
                }
            }
        }
    },
    "Dem25_Sch": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "Dem11",
            "options": {
                "type": "severities",
                "severities": {
                    "fdbdbcd2-e188-5502-8ad3-c8a2bcf78b6c": 1
                }
            }
        }
    },
    "Dem25_Panic": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "Dem11",
            "options": {
                "type": "severities",
                "severities": {
                    "f1833a31-d36a-53f7-91f7-a677ac081e7c": 1
                }
            }
        }
    },
    "Dem25_SA": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "Dem11",
            "options": {
                "type": "severities",
                "severities": {
                    "afcb6534-a0a0-5fe6-9729-1269be20cdf3": 1
                }
            }
        }
    },
    "Dem25_ED": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "Dem11",
            "options": {
                "type": "severities",
                "severities": {
                    "c2921864-bbf7-5735-9313-848fb9d5a6d1": 1
                }
            }
        }
    },
    "Dem31.1_ADHD": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "Dem11",
            "options": {
                "type": "severities",
                "severities": {
                    "fccec712-43ac-521b-a358-47fd975076c4": 1
                }
            }
        }
    },
    "Dem31.1_ASD": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "Dem11",
            "options": {
                "type": "severities",
                "severities": {
                    "46f0fc24-fa01-56a0-b5ed-dd47b5786b19": 1
                }
            }
        }
    },
    "Dem31.1_Au": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "Dem11",
            "options": {
                "type": "severities",
                "severities": {
                    "8f17d281-b1f1-57ca-9dbb-7e687e09bc26": 1
                }
            }
        }
    },
    "Dem31.1_Su": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "Dem11",
            "options": {
                "type": "severities",
                "severities": {
                    "6db89a27-212e-5b14-a78c-5e0bdf1861de": 1
                }
            }
        }
    },
    "Dem11_none": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "Dem11",
            "options": {
                "type": "severities",
                "severities": {
                    "60fe0bb4-d770-56f2-b05a-864625a48e4d": 1
                }
            }
        }
    },
    "BP99b_excessive": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "BP99b",
            "options": {
                "type": "severities",
                "severities": {
                    "96faf550-f157-55b9-8f15-6be82cd511a8": 1
                }
            }
        }
    },
    "BP99b_dangerous": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "BP99b",
            "options": {
                "type": "severities",
                "severities": {
                    "3f987aaa-e435-55a9-9c1b-aa6d2e476738": 1
                }
            }
        }
    },
    "BP99b_no": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "BP99b",
            "options": {
                "type": "severities",
                "severities": {
                    "68860df1-e835-50d6-8eb2-f9d9f2f97efa": 1
                }
            }
        }
    },
    "BP99_excessive": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "BP99",
            "options": {
                "type": "severities",
                "severities": {
                    "63509127-aadf-5fe2-8e1f-19d0d846d6ce": 1
                }
            }
        }
    },
    "BP99_dangerous": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "BP99",
            "options": {
                "type": "severities",
                "severities": {
                    "36502f0b-4563-5e03-80f2-a46dabeeae74": 1
                }
            }
        }
    },
    "BP99_no": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "BP99",
            "options": {
                "type": "severities",
                "severities": {
                    "5c06d516-e816-58d6-997e-f8fa91131ac7": 1
                }
            }
        }
    },
    "BP127_excessive": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "BP127",
            "options": {
                "type": "severities",
                "severities": {
                    "1fc78738-6afa-5714-89a1-8d5698fb9f69": 1
                }
            }
        }
    },
    "BP127_dangerous": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "BP127",
            "options": {
                "type": "severities",
                "severities": {
                    "27638a2c-33e4-58c5-a185-4dcdd837f93c": 1
                }
            }
        }
    },
    "BP127_no": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "BP127",
            "options": {
                "type": "severities",
                "severities": {
                    "5f58bb60-dff1-585c-a5f3-26e34e4dc957": 1
                }
            }
        }
    },
    "and[Dem11_bpd=1, not(isUndefined(Dem11=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "Dem11_bpd"
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "Dem11",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "44fd9eb3-aee2-5af0-b83c-dbbbf9514022": false,
                                    "ff95cad7-222f-53dd-b92b-d5ddc6b6ac5a": false,
                                    "07106ab9-5949-5b5f-bc96-117139ebd397": false,
                                    "f52974b4-d0c2-589c-88d8-8628f43a2f5e": false,
                                    "6a4d8b43-be3d-59b5-9bf4-1ca9f35b6973": false,
                                    "8c599171-d554-5547-9f54-2066c1a711dd": false,
                                    "4896b504-2025-52c3-85e2-c6cf3205cef7": false,
                                    "fdbdbcd2-e188-5502-8ad3-c8a2bcf78b6c": false,
                                    "f1833a31-d36a-53f7-91f7-a677ac081e7c": false,
                                    "afcb6534-a0a0-5fe6-9729-1269be20cdf3": false,
                                    "c2921864-bbf7-5735-9313-848fb9d5a6d1": false,
                                    "fccec712-43ac-521b-a358-47fd975076c4": false,
                                    "46f0fc24-fa01-56a0-b5ed-dd47b5786b19": false,
                                    "8f17d281-b1f1-57ca-9dbb-7e687e09bc26": false,
                                    "6db89a27-212e-5b14-a78c-5e0bdf1861de": false,
                                    "60fe0bb4-d770-56f2-b05a-864625a48e4d": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[Dem11_bpd=0, not(isUndefined(BPS=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "Dem11_bpd"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BPS",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "e2984767-d667-5b32-9670-f54a4f34aebf": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP1.5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "7599bcaa-89a5-5f71-80ea-b70c360bb44a": 0.03557118927263617,
                            "ccbdcbec-96d0-5362-977b-784289f5cfc0": 0.5,
                            "83e6efd5-64a2-5f0e-a973-9278eae90cfb": 0.9644288107273639,
                            "6215a3de-a3e4-5f86-a039-1006646e6b93": 0.9987706013787226
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP1.5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "7599bcaa-89a5-5f71-80ea-b70c360bb44a": 0.9986414800495711,
                            "ccbdcbec-96d0-5362-977b-784289f5cfc0": 0.9644288107273639,
                            "83e6efd5-64a2-5f0e-a973-9278eae90cfb": 0.5,
                            "6215a3de-a3e4-5f86-a039-1006646e6b93": 0.03229546469845057
                        }
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "BP1.5=0"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "BP1.5=0": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "BP1.5",
            "options": {
                "type": "predicates",
                "predicates": {
                    "7599bcaa-89a5-5f71-80ea-b70c360bb44a": true,
                    "ccbdcbec-96d0-5362-977b-784289f5cfc0": false,
                    "83e6efd5-64a2-5f0e-a973-9278eae90cfb": false,
                    "6215a3de-a3e4-5f86-a039-1006646e6b93": false
                }
            }
        }
    },
    "BP3=1": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "BP3",
            "options": {
                "type": "predicates",
                "predicates": {
                    "f03de28d-8f71-5af4-be2f-45f4e6092a3d": false,
                    "c7bee7a0-3037-5eb1-96a1-85b884d7e1dd": true
                }
            }
        }
    },
    "and[not(BP3.01=0), not(BP3.01=1)]": {
        "type": "merge",
        "wires": [
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "SpecificNumberInput",
                        "questionId": "BP3.01",
                        "input": 0,
                        "operator": "="
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "SpecificNumberInput",
                        "questionId": "BP3.01",
                        "input": 1,
                        "operator": "="
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "or[BP4=0, BP4=0.33, BP4=0.66]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP4",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "6b1af00b-b5de-5d59-bb4b-7e8d396d2138": true,
                            "9765aba8-7d55-5928-b79d-36aa58646036": false,
                            "4884cc9c-7210-5173-9438-d1eae9e9a6a8": false,
                            "f4705d21-04ac-574a-90f3-5e73c089a3ff": false
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP4",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "6b1af00b-b5de-5d59-bb4b-7e8d396d2138": false,
                            "9765aba8-7d55-5928-b79d-36aa58646036": true,
                            "4884cc9c-7210-5173-9438-d1eae9e9a6a8": false,
                            "f4705d21-04ac-574a-90f3-5e73c089a3ff": false
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP4",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "6b1af00b-b5de-5d59-bb4b-7e8d396d2138": false,
                            "9765aba8-7d55-5928-b79d-36aa58646036": false,
                            "4884cc9c-7210-5173-9438-d1eae9e9a6a8": true,
                            "f4705d21-04ac-574a-90f3-5e73c089a3ff": false
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "and[BP4=0, not(isUndefined(BP4.5=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP4",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "6b1af00b-b5de-5d59-bb4b-7e8d396d2138": true,
                            "9765aba8-7d55-5928-b79d-36aa58646036": false,
                            "4884cc9c-7210-5173-9438-d1eae9e9a6a8": false,
                            "f4705d21-04ac-574a-90f3-5e73c089a3ff": false
                        }
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP4.5",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "66a3460c-65ce-5a4c-9774-2e303ae4961e": true,
                                    "1be4b3b1-3a88-5544-af54-ebdfee1ce891": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[or[isUndefined(BP4=0), and[not(BP4=0), not(BP4=0.66)], BP4=0.33], not(isUndefined(BP4.5=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP4",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "6b1af00b-b5de-5d59-bb4b-7e8d396d2138": true,
                                        "9765aba8-7d55-5928-b79d-36aa58646036": false,
                                        "4884cc9c-7210-5173-9438-d1eae9e9a6a8": false,
                                        "f4705d21-04ac-574a-90f3-5e73c089a3ff": false
                                    }
                                }
                            }
                        }
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "not",
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP4",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "6b1af00b-b5de-5d59-bb4b-7e8d396d2138": true,
                                                "9765aba8-7d55-5928-b79d-36aa58646036": false,
                                                "4884cc9c-7210-5173-9438-d1eae9e9a6a8": false,
                                                "f4705d21-04ac-574a-90f3-5e73c089a3ff": false
                                            }
                                        }
                                    }
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP4",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "6b1af00b-b5de-5d59-bb4b-7e8d396d2138": false,
                                                "9765aba8-7d55-5928-b79d-36aa58646036": false,
                                                "4884cc9c-7210-5173-9438-d1eae9e9a6a8": true,
                                                "f4705d21-04ac-574a-90f3-5e73c089a3ff": false
                                            }
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP4",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "6b1af00b-b5de-5d59-bb4b-7e8d396d2138": false,
                                    "9765aba8-7d55-5928-b79d-36aa58646036": true,
                                    "4884cc9c-7210-5173-9438-d1eae9e9a6a8": false,
                                    "f4705d21-04ac-574a-90f3-5e73c089a3ff": false
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.3333333333333333
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP4.5",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "66a3460c-65ce-5a4c-9774-2e303ae4961e": true,
                                    "1be4b3b1-3a88-5544-af54-ebdfee1ce891": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[BP4=0.66, not(isUndefined(BP4.5=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP4",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "6b1af00b-b5de-5d59-bb4b-7e8d396d2138": false,
                            "9765aba8-7d55-5928-b79d-36aa58646036": false,
                            "4884cc9c-7210-5173-9438-d1eae9e9a6a8": true,
                            "f4705d21-04ac-574a-90f3-5e73c089a3ff": false
                        }
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP4.5",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "66a3460c-65ce-5a4c-9774-2e303ae4961e": true,
                                    "1be4b3b1-3a88-5544-af54-ebdfee1ce891": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[not(BP4=0), not(BP4=0.33), not(BP4=0.66)]": {
        "type": "merge",
        "wires": [
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AnsweredOption",
                        "questionId": "BP4",
                        "options": {
                            "type": "predicates",
                            "predicates": {
                                "6b1af00b-b5de-5d59-bb4b-7e8d396d2138": true,
                                "9765aba8-7d55-5928-b79d-36aa58646036": false,
                                "4884cc9c-7210-5173-9438-d1eae9e9a6a8": false,
                                "f4705d21-04ac-574a-90f3-5e73c089a3ff": false
                            }
                        }
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AnsweredOption",
                        "questionId": "BP4",
                        "options": {
                            "type": "predicates",
                            "predicates": {
                                "6b1af00b-b5de-5d59-bb4b-7e8d396d2138": false,
                                "9765aba8-7d55-5928-b79d-36aa58646036": true,
                                "4884cc9c-7210-5173-9438-d1eae9e9a6a8": false,
                                "f4705d21-04ac-574a-90f3-5e73c089a3ff": false
                            }
                        }
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AnsweredOption",
                        "questionId": "BP4",
                        "options": {
                            "type": "predicates",
                            "predicates": {
                                "6b1af00b-b5de-5d59-bb4b-7e8d396d2138": false,
                                "9765aba8-7d55-5928-b79d-36aa58646036": false,
                                "4884cc9c-7210-5173-9438-d1eae9e9a6a8": true,
                                "f4705d21-04ac-574a-90f3-5e73c089a3ff": false
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "BP8.0=1": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "BP8.0",
            "options": {
                "type": "predicates",
                "predicates": {
                    "3568cdb1-2500-512b-aada-2547c5bfd835": false,
                    "c7d02d20-9064-5a94-86d4-053ed361ed32": true
                }
            }
        }
    },
    "BP8.1=0": {
        "type": "input",
        "signal": {
            "type": "SpecificNumberInput",
            "questionId": "BP8.1",
            "input": 0,
            "operator": "="
        }
    },
    "or[BP3.01=0, BP3.01=1, not(BP3=1)]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP3.01",
                    "input": 0,
                    "operator": "="
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "SpecificNumberInput",
                    "questionId": "BP3.01",
                    "input": 1,
                    "operator": "="
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "BP3=1"
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "BP12<1": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "BP12",
            "options": {
                "type": "severities",
                "severities": {
                    "89a3d3f3-747b-59f8-9a32-311618df7607": 0.9986414800495711,
                    "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a": 0.9644288107273639,
                    "74034187-2f1a-54cb-8bdf-1eb3572a286a": 0.5,
                    "22f9a995-3f9a-5fe9-99bb-71b7f1f96621": 0.03229546469845057
                }
            }
        }
    },
    "and[BP12=0, not(isUndefined(BP12.5=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP12",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "89a3d3f3-747b-59f8-9a32-311618df7607": true,
                            "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a": false,
                            "74034187-2f1a-54cb-8bdf-1eb3572a286a": false,
                            "22f9a995-3f9a-5fe9-99bb-71b7f1f96621": false
                        }
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP12.5",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "cd7ce00f-4e7c-57ac-9c6c-4137efa24c8c": true,
                                    "b7cc3eef-f36c-5fb4-9ebb-9ce64c834159": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[BP12=0.33, not(isUndefined(BP12.5=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP12",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "89a3d3f3-747b-59f8-9a32-311618df7607": false,
                            "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a": true,
                            "74034187-2f1a-54cb-8bdf-1eb3572a286a": false,
                            "22f9a995-3f9a-5fe9-99bb-71b7f1f96621": false
                        }
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP12.5",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "cd7ce00f-4e7c-57ac-9c6c-4137efa24c8c": true,
                                    "b7cc3eef-f36c-5fb4-9ebb-9ce64c834159": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[or[isUndefined(BP12=0), and[not(BP12=0), not(BP12=0.33)]], not(isUndefined(BP12.5=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP12",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "89a3d3f3-747b-59f8-9a32-311618df7607": true,
                                        "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a": false,
                                        "74034187-2f1a-54cb-8bdf-1eb3572a286a": false,
                                        "22f9a995-3f9a-5fe9-99bb-71b7f1f96621": false
                                    }
                                }
                            }
                        }
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "not",
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP12",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "89a3d3f3-747b-59f8-9a32-311618df7607": true,
                                                "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a": false,
                                                "74034187-2f1a-54cb-8bdf-1eb3572a286a": false,
                                                "22f9a995-3f9a-5fe9-99bb-71b7f1f96621": false
                                            }
                                        }
                                    }
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP12",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "89a3d3f3-747b-59f8-9a32-311618df7607": false,
                                                "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a": true,
                                                "74034187-2f1a-54cb-8bdf-1eb3572a286a": false,
                                                "22f9a995-3f9a-5fe9-99bb-71b7f1f96621": false
                                            }
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP12.5",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "cd7ce00f-4e7c-57ac-9c6c-4137efa24c8c": true,
                                    "b7cc3eef-f36c-5fb4-9ebb-9ce64c834159": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "not(BP12<1)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "BP12<1"
            }
        }
    },
    "BP16.0=1": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "BP16.0",
            "options": {
                "type": "predicates",
                "predicates": {
                    "4b26caa5-9124-51b2-ab08-2f4e977cee1f": false,
                    "d425010d-9cb9-54a7-a1a6-2c7b577896e7": true
                }
            }
        }
    },
    "BP16.1=0": {
        "type": "input",
        "signal": {
            "type": "SpecificNumberInput",
            "questionId": "BP16.1",
            "input": 0,
            "operator": "="
        }
    },
    "not(BP16.1=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "BP16.1=0"
            }
        }
    },
    "and[or[isUndefined(Dem11_bpd=0), not(Dem11_bpd=0)], not(isUndefined(BPS=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "not",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AspectReference",
                                    "aspectName": "Dem11_bpd"
                                }
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "not",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AspectReference",
                                    "aspectName": "Dem11_bpd"
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BPS",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "e2984767-d667-5b32-9670-f54a4f34aebf": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[Dem18<1, not(isUndefined(BPS2=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "Dem18",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "9d064e64-ec95-50b8-af10-5b9d39e9fb8d": 0.9933071490757152,
                            "05653d8f-0bd2-5c1a-8a9f-fee81d9e4cfa": 0.5,
                            "db090d78-6247-560e-893c-e74389c1efc6": 0.006692850924284732
                        }
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BPS2",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "316e99d8-6ceb-52b4-a93f-fc23d563c46a": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[or[isUndefined(BP67=0), and[not(BP67=0), not(BP67=1)]], not(isUndefined(BP68=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "SpecificNumberInput",
                                "questionId": "BP67",
                                "input": 0,
                                "operator": "="
                            }
                        }
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "not",
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "SpecificNumberInput",
                                        "questionId": "BP67",
                                        "input": 0,
                                        "operator": "="
                                    }
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "SpecificNumberInput",
                                        "questionId": "BP67",
                                        "input": 1,
                                        "operator": "="
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "SpecificNumberInput",
                            "questionId": "BP68",
                            "input": 0,
                            "operator": "="
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "BP69<1": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "BP69",
            "options": {
                "type": "severities",
                "severities": {
                    "a36902b1-c83f-5e6f-b877-c059c1065bb5": 0.9986414800495711,
                    "d210ccff-4e0a-5c2c-afe5-b8f3163c72d7": 0.9644288107273639,
                    "63921591-01f8-50fb-9355-b55fe483faa3": 0.5,
                    "94ec394b-cb15-50df-8a09-56fc2267a32a": 0.03229546469845057
                }
            }
        }
    },
    "and[BP69=0, not(isUndefined(BP69.5=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP69",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "a36902b1-c83f-5e6f-b877-c059c1065bb5": true,
                            "d210ccff-4e0a-5c2c-afe5-b8f3163c72d7": false,
                            "63921591-01f8-50fb-9355-b55fe483faa3": false,
                            "94ec394b-cb15-50df-8a09-56fc2267a32a": false
                        }
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP69.5",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "04566a9b-eb5a-56cd-9708-86354be1bb5a": true,
                                    "101d58bf-4d7f-50d8-a674-2a940051d124": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[or[isUndefined(BP69=0), and[not(BP69=0), not(BP69=0.66)], BP69=0.33], not(isUndefined(BP69.5=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP69",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "a36902b1-c83f-5e6f-b877-c059c1065bb5": true,
                                        "d210ccff-4e0a-5c2c-afe5-b8f3163c72d7": false,
                                        "63921591-01f8-50fb-9355-b55fe483faa3": false,
                                        "94ec394b-cb15-50df-8a09-56fc2267a32a": false
                                    }
                                }
                            }
                        }
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "not",
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP69",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "a36902b1-c83f-5e6f-b877-c059c1065bb5": true,
                                                "d210ccff-4e0a-5c2c-afe5-b8f3163c72d7": false,
                                                "63921591-01f8-50fb-9355-b55fe483faa3": false,
                                                "94ec394b-cb15-50df-8a09-56fc2267a32a": false
                                            }
                                        }
                                    }
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP69",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "a36902b1-c83f-5e6f-b877-c059c1065bb5": false,
                                                "d210ccff-4e0a-5c2c-afe5-b8f3163c72d7": false,
                                                "63921591-01f8-50fb-9355-b55fe483faa3": true,
                                                "94ec394b-cb15-50df-8a09-56fc2267a32a": false
                                            }
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP69",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "a36902b1-c83f-5e6f-b877-c059c1065bb5": false,
                                    "d210ccff-4e0a-5c2c-afe5-b8f3163c72d7": true,
                                    "63921591-01f8-50fb-9355-b55fe483faa3": false,
                                    "94ec394b-cb15-50df-8a09-56fc2267a32a": false
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.3333333333333333
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP69.5",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "04566a9b-eb5a-56cd-9708-86354be1bb5a": true,
                                    "101d58bf-4d7f-50d8-a674-2a940051d124": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[BP69=0.66, not(isUndefined(BP69.5=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP69",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "a36902b1-c83f-5e6f-b877-c059c1065bb5": false,
                            "d210ccff-4e0a-5c2c-afe5-b8f3163c72d7": false,
                            "63921591-01f8-50fb-9355-b55fe483faa3": true,
                            "94ec394b-cb15-50df-8a09-56fc2267a32a": false
                        }
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP69.5",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "04566a9b-eb5a-56cd-9708-86354be1bb5a": true,
                                    "101d58bf-4d7f-50d8-a674-2a940051d124": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "not(BP69<1)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "BP69<1"
            }
        }
    },
    "BP74=1": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "BP74",
            "options": {
                "type": "predicates",
                "predicates": {
                    "dad63f8c-7498-59d2-ac8d-a8d57a9dbab8": false,
                    "cd4ba75c-7b4b-5126-9170-d54c05b66f1f": true
                }
            }
        }
    },
    "BP74.1=0": {
        "type": "input",
        "signal": {
            "type": "SpecificNumberInput",
            "questionId": "BP74.1",
            "input": 0,
            "operator": "="
        }
    },
    "not(BP74.1=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "BP74.1=0"
            }
        }
    },
    "or[and[not(BP8.0=1), BP4=0], and[not(BP8.0=1), BP4=0.66], and[or[BP4=0, BP4=0.66], not(isUndefined(BP8.4=0))], and[not(BP74=1), BP69=0], and[not(BP74=1), BP69=0.66], and[or[BP69=0, BP69=0.66], not(isUndefined(BP74.4=0))]]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP8.0=1"
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP4",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "6b1af00b-b5de-5d59-bb4b-7e8d396d2138": true,
                                    "9765aba8-7d55-5928-b79d-36aa58646036": false,
                                    "4884cc9c-7210-5173-9438-d1eae9e9a6a8": false,
                                    "f4705d21-04ac-574a-90f3-5e73c089a3ff": false
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP8.0=1"
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP4",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "6b1af00b-b5de-5d59-bb4b-7e8d396d2138": false,
                                    "9765aba8-7d55-5928-b79d-36aa58646036": false,
                                    "4884cc9c-7210-5173-9438-d1eae9e9a6a8": true,
                                    "f4705d21-04ac-574a-90f3-5e73c089a3ff": false
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP4",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "6b1af00b-b5de-5d59-bb4b-7e8d396d2138": true,
                                            "9765aba8-7d55-5928-b79d-36aa58646036": false,
                                            "4884cc9c-7210-5173-9438-d1eae9e9a6a8": false,
                                            "f4705d21-04ac-574a-90f3-5e73c089a3ff": false
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP4",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "6b1af00b-b5de-5d59-bb4b-7e8d396d2138": false,
                                            "9765aba8-7d55-5928-b79d-36aa58646036": false,
                                            "4884cc9c-7210-5173-9438-d1eae9e9a6a8": true,
                                            "f4705d21-04ac-574a-90f3-5e73c089a3ff": false
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP8.4",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "16b49c5e-2955-5b04-997e-e975eb932180": true,
                                            "eaa4eab0-aec1-5be4-832a-67ef473744d7": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP74=1"
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP69",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "a36902b1-c83f-5e6f-b877-c059c1065bb5": true,
                                    "d210ccff-4e0a-5c2c-afe5-b8f3163c72d7": false,
                                    "63921591-01f8-50fb-9355-b55fe483faa3": false,
                                    "94ec394b-cb15-50df-8a09-56fc2267a32a": false
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP74=1"
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP69",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "a36902b1-c83f-5e6f-b877-c059c1065bb5": false,
                                    "d210ccff-4e0a-5c2c-afe5-b8f3163c72d7": false,
                                    "63921591-01f8-50fb-9355-b55fe483faa3": true,
                                    "94ec394b-cb15-50df-8a09-56fc2267a32a": false
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP69",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "a36902b1-c83f-5e6f-b877-c059c1065bb5": true,
                                            "d210ccff-4e0a-5c2c-afe5-b8f3163c72d7": false,
                                            "63921591-01f8-50fb-9355-b55fe483faa3": false,
                                            "94ec394b-cb15-50df-8a09-56fc2267a32a": false
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP69",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "a36902b1-c83f-5e6f-b877-c059c1065bb5": false,
                                            "d210ccff-4e0a-5c2c-afe5-b8f3163c72d7": false,
                                            "63921591-01f8-50fb-9355-b55fe483faa3": true,
                                            "94ec394b-cb15-50df-8a09-56fc2267a32a": false
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP74.4",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "e99f2054-5f88-5322-b642-9257a24f81be": true,
                                            "45734e40-8054-5f6b-8c8f-a9410fe95696": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            }
        ],
        "anyToAll": 0.16666666666666666
    },
    "or[and[BP4=0.33, or[not(BP8.0=1), not(isUndefined(BP8.4=0))]], and[not(BP74=1), BP69=0.33], and[BP69=0.33, not(isUndefined(BP74.4=0))]]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP4",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "6b1af00b-b5de-5d59-bb4b-7e8d396d2138": false,
                                    "9765aba8-7d55-5928-b79d-36aa58646036": true,
                                    "4884cc9c-7210-5173-9438-d1eae9e9a6a8": false,
                                    "f4705d21-04ac-574a-90f3-5e73c089a3ff": false
                                }
                            }
                        }
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "not",
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "BP8.0=1"
                                    }
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "isUnknown",
                                    "wire": {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "BP8.4",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "16b49c5e-2955-5b04-997e-e975eb932180": true,
                                                    "eaa4eab0-aec1-5be4-832a-67ef473744d7": false
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP74=1"
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP69",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "a36902b1-c83f-5e6f-b877-c059c1065bb5": false,
                                    "d210ccff-4e0a-5c2c-afe5-b8f3163c72d7": true,
                                    "63921591-01f8-50fb-9355-b55fe483faa3": false,
                                    "94ec394b-cb15-50df-8a09-56fc2267a32a": false
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP69",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "a36902b1-c83f-5e6f-b877-c059c1065bb5": false,
                                    "d210ccff-4e0a-5c2c-afe5-b8f3163c72d7": true,
                                    "63921591-01f8-50fb-9355-b55fe483faa3": false,
                                    "94ec394b-cb15-50df-8a09-56fc2267a32a": false
                                }
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP74.4",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "e99f2054-5f88-5322-b642-9257a24f81be": true,
                                            "45734e40-8054-5f6b-8c8f-a9410fe95696": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "or[and[or[isUndefined(BP4=0), and[not(BP4=0), not(BP4=0.33), not(BP4=0.66)]], or[not(BP8.0=1), not(isUndefined(BP8.4=0))]], and[or[isUndefined(BP69=0), and[not(BP69=0), not(BP69=0.33), not(BP69=0.66)]], not(BP74=1)], and[or[isUndefined(BP69=0), and[not(BP69=0), not(BP69=0.33), not(BP69=0.66)]], not(isUndefined(BP74.4=0))]]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP4",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "6b1af00b-b5de-5d59-bb4b-7e8d396d2138": true,
                                                "9765aba8-7d55-5928-b79d-36aa58646036": false,
                                                "4884cc9c-7210-5173-9438-d1eae9e9a6a8": false,
                                                "f4705d21-04ac-574a-90f3-5e73c089a3ff": false
                                            }
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AspectReference",
                                    "aspectName": "and[not(BP4=0), not(BP4=0.33), not(BP4=0.66)]"
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "not",
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "BP8.0=1"
                                    }
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "isUnknown",
                                    "wire": {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "BP8.4",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "16b49c5e-2955-5b04-997e-e975eb932180": true,
                                                    "eaa4eab0-aec1-5be4-832a-67ef473744d7": false
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP69",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "a36902b1-c83f-5e6f-b877-c059c1065bb5": true,
                                                "d210ccff-4e0a-5c2c-afe5-b8f3163c72d7": false,
                                                "63921591-01f8-50fb-9355-b55fe483faa3": false,
                                                "94ec394b-cb15-50df-8a09-56fc2267a32a": false
                                            }
                                        }
                                    }
                                }
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "BP69",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "a36902b1-c83f-5e6f-b877-c059c1065bb5": true,
                                                        "d210ccff-4e0a-5c2c-afe5-b8f3163c72d7": false,
                                                        "63921591-01f8-50fb-9355-b55fe483faa3": false,
                                                        "94ec394b-cb15-50df-8a09-56fc2267a32a": false
                                                    }
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "BP69",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "a36902b1-c83f-5e6f-b877-c059c1065bb5": false,
                                                        "d210ccff-4e0a-5c2c-afe5-b8f3163c72d7": true,
                                                        "63921591-01f8-50fb-9355-b55fe483faa3": false,
                                                        "94ec394b-cb15-50df-8a09-56fc2267a32a": false
                                                    }
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "BP69",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "a36902b1-c83f-5e6f-b877-c059c1065bb5": false,
                                                        "d210ccff-4e0a-5c2c-afe5-b8f3163c72d7": false,
                                                        "63921591-01f8-50fb-9355-b55fe483faa3": true,
                                                        "94ec394b-cb15-50df-8a09-56fc2267a32a": false
                                                    }
                                                }
                                            }
                                        }
                                    }
                                ],
                                "anyToAll": 1
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP74=1"
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP69",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "a36902b1-c83f-5e6f-b877-c059c1065bb5": true,
                                                "d210ccff-4e0a-5c2c-afe5-b8f3163c72d7": false,
                                                "63921591-01f8-50fb-9355-b55fe483faa3": false,
                                                "94ec394b-cb15-50df-8a09-56fc2267a32a": false
                                            }
                                        }
                                    }
                                }
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "BP69",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "a36902b1-c83f-5e6f-b877-c059c1065bb5": true,
                                                        "d210ccff-4e0a-5c2c-afe5-b8f3163c72d7": false,
                                                        "63921591-01f8-50fb-9355-b55fe483faa3": false,
                                                        "94ec394b-cb15-50df-8a09-56fc2267a32a": false
                                                    }
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "BP69",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "a36902b1-c83f-5e6f-b877-c059c1065bb5": false,
                                                        "d210ccff-4e0a-5c2c-afe5-b8f3163c72d7": true,
                                                        "63921591-01f8-50fb-9355-b55fe483faa3": false,
                                                        "94ec394b-cb15-50df-8a09-56fc2267a32a": false
                                                    }
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "BP69",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "a36902b1-c83f-5e6f-b877-c059c1065bb5": false,
                                                        "d210ccff-4e0a-5c2c-afe5-b8f3163c72d7": false,
                                                        "63921591-01f8-50fb-9355-b55fe483faa3": true,
                                                        "94ec394b-cb15-50df-8a09-56fc2267a32a": false
                                                    }
                                                }
                                            }
                                        }
                                    }
                                ],
                                "anyToAll": 1
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP74.4",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "e99f2054-5f88-5322-b642-9257a24f81be": true,
                                            "45734e40-8054-5f6b-8c8f-a9410fe95696": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "and[or[BP67=0, BP67=1], not(isUndefined(BP68=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "SpecificNumberInput",
                            "questionId": "BP67",
                            "input": 0,
                            "operator": "="
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "SpecificNumberInput",
                            "questionId": "BP67",
                            "input": 1,
                            "operator": "="
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "SpecificNumberInput",
                            "questionId": "BP68",
                            "input": 0,
                            "operator": "="
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "BP78<1": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "BP78",
            "options": {
                "type": "severities",
                "severities": {
                    "3629add1-9666-5590-8025-6ec116bafb5c": 0.9986414800495711,
                    "23e37a7e-3e45-53e6-a29b-75da72b79ff0": 0.9644288107273639,
                    "3477feaf-ad87-5fb3-8384-196bc93f5c05": 0.5,
                    "bf6cf52e-7867-5073-837f-205e3e78121d": 0.03229546469845057
                }
            }
        }
    },
    "and[BP78=0, not(isUndefined(BP78.5=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP78",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "3629add1-9666-5590-8025-6ec116bafb5c": true,
                            "23e37a7e-3e45-53e6-a29b-75da72b79ff0": false,
                            "3477feaf-ad87-5fb3-8384-196bc93f5c05": false,
                            "bf6cf52e-7867-5073-837f-205e3e78121d": false
                        }
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP78.5",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "bb2ee161-cec3-57fa-b85c-52c2612b391d": true,
                                    "9c1bb644-2129-5e71-98c2-f16f6747632e": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[or[isUndefined(BP78=0), and[not(BP78=0), not(BP78=0.66)], BP78=0.33], not(isUndefined(BP78.5=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP78",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "3629add1-9666-5590-8025-6ec116bafb5c": true,
                                        "23e37a7e-3e45-53e6-a29b-75da72b79ff0": false,
                                        "3477feaf-ad87-5fb3-8384-196bc93f5c05": false,
                                        "bf6cf52e-7867-5073-837f-205e3e78121d": false
                                    }
                                }
                            }
                        }
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "not",
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP78",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "3629add1-9666-5590-8025-6ec116bafb5c": true,
                                                "23e37a7e-3e45-53e6-a29b-75da72b79ff0": false,
                                                "3477feaf-ad87-5fb3-8384-196bc93f5c05": false,
                                                "bf6cf52e-7867-5073-837f-205e3e78121d": false
                                            }
                                        }
                                    }
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP78",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "3629add1-9666-5590-8025-6ec116bafb5c": false,
                                                "23e37a7e-3e45-53e6-a29b-75da72b79ff0": false,
                                                "3477feaf-ad87-5fb3-8384-196bc93f5c05": true,
                                                "bf6cf52e-7867-5073-837f-205e3e78121d": false
                                            }
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP78",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "3629add1-9666-5590-8025-6ec116bafb5c": false,
                                    "23e37a7e-3e45-53e6-a29b-75da72b79ff0": true,
                                    "3477feaf-ad87-5fb3-8384-196bc93f5c05": false,
                                    "bf6cf52e-7867-5073-837f-205e3e78121d": false
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.3333333333333333
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP78.5",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "bb2ee161-cec3-57fa-b85c-52c2612b391d": true,
                                    "9c1bb644-2129-5e71-98c2-f16f6747632e": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[BP78=0.66, not(isUndefined(BP78.5=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP78",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "3629add1-9666-5590-8025-6ec116bafb5c": false,
                            "23e37a7e-3e45-53e6-a29b-75da72b79ff0": false,
                            "3477feaf-ad87-5fb3-8384-196bc93f5c05": true,
                            "bf6cf52e-7867-5073-837f-205e3e78121d": false
                        }
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP78.5",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "bb2ee161-cec3-57fa-b85c-52c2612b391d": true,
                                    "9c1bb644-2129-5e71-98c2-f16f6747632e": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "not(BP78<1)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "BP78<1"
            }
        }
    },
    "BP83=1": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "BP83",
            "options": {
                "type": "predicates",
                "predicates": {
                    "dad46374-d0d7-5468-97d1-ff70b6a2f893": false,
                    "49b6c935-6235-511a-b989-acce3e5f3572": true
                }
            }
        }
    },
    "or[and[not(BP16.0=1), BP12=0], and[not(BP16.0=1), BP12=0.66], and[or[BP12=0, BP12=0.66], not(isUndefined(BP16.4=0))], and[not(BP83=1), BP78=0], and[not(BP83=1), BP78=0.66], and[or[BP78=0, BP78=0.66], not(isUndefined(BP83.2=0))]]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP16.0=1"
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP12",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "89a3d3f3-747b-59f8-9a32-311618df7607": true,
                                    "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a": false,
                                    "74034187-2f1a-54cb-8bdf-1eb3572a286a": false,
                                    "22f9a995-3f9a-5fe9-99bb-71b7f1f96621": false
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP16.0=1"
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP12",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "89a3d3f3-747b-59f8-9a32-311618df7607": false,
                                    "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a": false,
                                    "74034187-2f1a-54cb-8bdf-1eb3572a286a": true,
                                    "22f9a995-3f9a-5fe9-99bb-71b7f1f96621": false
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP12",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "89a3d3f3-747b-59f8-9a32-311618df7607": true,
                                            "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a": false,
                                            "74034187-2f1a-54cb-8bdf-1eb3572a286a": false,
                                            "22f9a995-3f9a-5fe9-99bb-71b7f1f96621": false
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP12",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "89a3d3f3-747b-59f8-9a32-311618df7607": false,
                                            "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a": false,
                                            "74034187-2f1a-54cb-8bdf-1eb3572a286a": true,
                                            "22f9a995-3f9a-5fe9-99bb-71b7f1f96621": false
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP16.4",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "2d4daf12-3d1d-5d5b-b03b-8ae16663db77": true,
                                            "3dc6f331-9c62-574c-afd4-6c281e71b14c": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP83=1"
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP78",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "3629add1-9666-5590-8025-6ec116bafb5c": true,
                                    "23e37a7e-3e45-53e6-a29b-75da72b79ff0": false,
                                    "3477feaf-ad87-5fb3-8384-196bc93f5c05": false,
                                    "bf6cf52e-7867-5073-837f-205e3e78121d": false
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP83=1"
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP78",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "3629add1-9666-5590-8025-6ec116bafb5c": false,
                                    "23e37a7e-3e45-53e6-a29b-75da72b79ff0": false,
                                    "3477feaf-ad87-5fb3-8384-196bc93f5c05": true,
                                    "bf6cf52e-7867-5073-837f-205e3e78121d": false
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP78",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "3629add1-9666-5590-8025-6ec116bafb5c": true,
                                            "23e37a7e-3e45-53e6-a29b-75da72b79ff0": false,
                                            "3477feaf-ad87-5fb3-8384-196bc93f5c05": false,
                                            "bf6cf52e-7867-5073-837f-205e3e78121d": false
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP78",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "3629add1-9666-5590-8025-6ec116bafb5c": false,
                                            "23e37a7e-3e45-53e6-a29b-75da72b79ff0": false,
                                            "3477feaf-ad87-5fb3-8384-196bc93f5c05": true,
                                            "bf6cf52e-7867-5073-837f-205e3e78121d": false
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP83.2",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "91374129-e29c-5341-a28e-736721ee7128": true,
                                            "c827fdc7-e41c-5fdf-b4d0-71b50a9d97ed": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            }
        ],
        "anyToAll": 0.16666666666666666
    },
    "or[and[BP12=0.33, or[not(BP16.0=1), not(isUndefined(BP16.4=0))]], and[not(BP83=1), BP78=0.33], and[BP78=0.33, not(isUndefined(BP83.2=0))]]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP12",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "89a3d3f3-747b-59f8-9a32-311618df7607": false,
                                    "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a": true,
                                    "74034187-2f1a-54cb-8bdf-1eb3572a286a": false,
                                    "22f9a995-3f9a-5fe9-99bb-71b7f1f96621": false
                                }
                            }
                        }
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "not",
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "BP16.0=1"
                                    }
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "isUnknown",
                                    "wire": {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "BP16.4",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "2d4daf12-3d1d-5d5b-b03b-8ae16663db77": true,
                                                    "3dc6f331-9c62-574c-afd4-6c281e71b14c": false
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP83=1"
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP78",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "3629add1-9666-5590-8025-6ec116bafb5c": false,
                                    "23e37a7e-3e45-53e6-a29b-75da72b79ff0": true,
                                    "3477feaf-ad87-5fb3-8384-196bc93f5c05": false,
                                    "bf6cf52e-7867-5073-837f-205e3e78121d": false
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP78",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "3629add1-9666-5590-8025-6ec116bafb5c": false,
                                    "23e37a7e-3e45-53e6-a29b-75da72b79ff0": true,
                                    "3477feaf-ad87-5fb3-8384-196bc93f5c05": false,
                                    "bf6cf52e-7867-5073-837f-205e3e78121d": false
                                }
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP83.2",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "91374129-e29c-5341-a28e-736721ee7128": true,
                                            "c827fdc7-e41c-5fdf-b4d0-71b50a9d97ed": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "or[and[or[isUndefined(BP12=0), and[not(BP12=0), not(BP12=0.33), not(BP12=0.66)]], or[not(BP16.0=1), not(isUndefined(BP16.4=0))]], and[or[isUndefined(BP78=0), and[not(BP78=0), not(BP78=0.33), not(BP78=0.66)]], not(BP83=1)], and[or[isUndefined(BP78=0), and[not(BP78=0), not(BP78=0.33), not(BP78=0.66)]], not(isUndefined(BP83.2=0))]]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP12",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "89a3d3f3-747b-59f8-9a32-311618df7607": true,
                                                "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a": false,
                                                "74034187-2f1a-54cb-8bdf-1eb3572a286a": false,
                                                "22f9a995-3f9a-5fe9-99bb-71b7f1f96621": false
                                            }
                                        }
                                    }
                                }
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "BP12",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "89a3d3f3-747b-59f8-9a32-311618df7607": true,
                                                        "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a": false,
                                                        "74034187-2f1a-54cb-8bdf-1eb3572a286a": false,
                                                        "22f9a995-3f9a-5fe9-99bb-71b7f1f96621": false
                                                    }
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "BP12",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "89a3d3f3-747b-59f8-9a32-311618df7607": false,
                                                        "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a": true,
                                                        "74034187-2f1a-54cb-8bdf-1eb3572a286a": false,
                                                        "22f9a995-3f9a-5fe9-99bb-71b7f1f96621": false
                                                    }
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "BP12",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "89a3d3f3-747b-59f8-9a32-311618df7607": false,
                                                        "1d6299ac-2ac6-51e2-9a3f-13e8d44e652a": false,
                                                        "74034187-2f1a-54cb-8bdf-1eb3572a286a": true,
                                                        "22f9a995-3f9a-5fe9-99bb-71b7f1f96621": false
                                                    }
                                                }
                                            }
                                        }
                                    }
                                ],
                                "anyToAll": 1
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "not",
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "BP16.0=1"
                                    }
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "isUnknown",
                                    "wire": {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "BP16.4",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "2d4daf12-3d1d-5d5b-b03b-8ae16663db77": true,
                                                    "3dc6f331-9c62-574c-afd4-6c281e71b14c": false
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP78",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "3629add1-9666-5590-8025-6ec116bafb5c": true,
                                                "23e37a7e-3e45-53e6-a29b-75da72b79ff0": false,
                                                "3477feaf-ad87-5fb3-8384-196bc93f5c05": false,
                                                "bf6cf52e-7867-5073-837f-205e3e78121d": false
                                            }
                                        }
                                    }
                                }
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "BP78",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "3629add1-9666-5590-8025-6ec116bafb5c": true,
                                                        "23e37a7e-3e45-53e6-a29b-75da72b79ff0": false,
                                                        "3477feaf-ad87-5fb3-8384-196bc93f5c05": false,
                                                        "bf6cf52e-7867-5073-837f-205e3e78121d": false
                                                    }
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "BP78",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "3629add1-9666-5590-8025-6ec116bafb5c": false,
                                                        "23e37a7e-3e45-53e6-a29b-75da72b79ff0": true,
                                                        "3477feaf-ad87-5fb3-8384-196bc93f5c05": false,
                                                        "bf6cf52e-7867-5073-837f-205e3e78121d": false
                                                    }
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "BP78",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "3629add1-9666-5590-8025-6ec116bafb5c": false,
                                                        "23e37a7e-3e45-53e6-a29b-75da72b79ff0": false,
                                                        "3477feaf-ad87-5fb3-8384-196bc93f5c05": true,
                                                        "bf6cf52e-7867-5073-837f-205e3e78121d": false
                                                    }
                                                }
                                            }
                                        }
                                    }
                                ],
                                "anyToAll": 1
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP83=1"
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP78",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "3629add1-9666-5590-8025-6ec116bafb5c": true,
                                                "23e37a7e-3e45-53e6-a29b-75da72b79ff0": false,
                                                "3477feaf-ad87-5fb3-8384-196bc93f5c05": false,
                                                "bf6cf52e-7867-5073-837f-205e3e78121d": false
                                            }
                                        }
                                    }
                                }
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "BP78",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "3629add1-9666-5590-8025-6ec116bafb5c": true,
                                                        "23e37a7e-3e45-53e6-a29b-75da72b79ff0": false,
                                                        "3477feaf-ad87-5fb3-8384-196bc93f5c05": false,
                                                        "bf6cf52e-7867-5073-837f-205e3e78121d": false
                                                    }
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "BP78",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "3629add1-9666-5590-8025-6ec116bafb5c": false,
                                                        "23e37a7e-3e45-53e6-a29b-75da72b79ff0": true,
                                                        "3477feaf-ad87-5fb3-8384-196bc93f5c05": false,
                                                        "bf6cf52e-7867-5073-837f-205e3e78121d": false
                                                    }
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "BP78",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "3629add1-9666-5590-8025-6ec116bafb5c": false,
                                                        "23e37a7e-3e45-53e6-a29b-75da72b79ff0": false,
                                                        "3477feaf-ad87-5fb3-8384-196bc93f5c05": true,
                                                        "bf6cf52e-7867-5073-837f-205e3e78121d": false
                                                    }
                                                }
                                            }
                                        }
                                    }
                                ],
                                "anyToAll": 1
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP83.2",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "91374129-e29c-5341-a28e-736721ee7128": true,
                                            "c827fdc7-e41c-5fdf-b4d0-71b50a9d97ed": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "and[or[isUndefined(BP99b_no=1), not(BP99b_no=1)], BP99b_excessive=1, not(isUndefined(BP99b=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP99b_no"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP99b_no"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "BP99b_excessive"
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP99b",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "96faf550-f157-55b9-8f15-6be82cd511a8": false,
                                    "3f987aaa-e435-55a9-9c1b-aa6d2e476738": false,
                                    "68860df1-e835-50d6-8eb2-f9d9f2f97efa": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[BP99b_dangerous=1, or[and[or[isUndefined(BP99b_no=1), not(BP99b_no=1)], or[isUndefined(BP99b_excessive=1), not(BP99b_excessive=1)], not(isUndefined(BP99b=0))], not(isUndefined(BP99.1b=0))]]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "BP99b_dangerous"
                }
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "isUnknown",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP99b_no"
                                            }
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP99b_no"
                                            }
                                        }
                                    }
                                ],
                                "anyToAll": 0.5
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "isUnknown",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP99b_excessive"
                                            }
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP99b_excessive"
                                            }
                                        }
                                    }
                                ],
                                "anyToAll": 0.5
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "isUnknown",
                                    "wire": {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "BP99b",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "96faf550-f157-55b9-8f15-6be82cd511a8": false,
                                                    "3f987aaa-e435-55a9-9c1b-aa6d2e476738": false,
                                                    "68860df1-e835-50d6-8eb2-f9d9f2f97efa": false
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP99.1b",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "255f682d-5067-5a6d-835a-fe587b4169e6": true,
                                            "74ac8d69-c317-57fb-a5e5-a93c1309c0f7": false,
                                            "474c64d7-23b9-5015-9cdd-60661ad38d39": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            }
        ],
        "anyToAll": 1
    },
    "and[or[isUndefined(and[BP103b=0, BP104b=0]), not(and[BP103b=0, BP104b=0])], or[and[BP103b=1, BP104b=0], and[BP103b=0, BP104b=1]]]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP103b",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "867b0ee4-73d9-5aac-b96d-bc2d85d5b183": true,
                                                "c4bec2f3-b4c0-5a86-abf4-28921c5b1956": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP104b",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "c59b5910-3279-509f-8175-f2fd0da2dc86": true,
                                                "75eb3568-195e-5720-827c-1fd0f84c6e75": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP103b",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "867b0ee4-73d9-5aac-b96d-bc2d85d5b183": true,
                                                "c4bec2f3-b4c0-5a86-abf4-28921c5b1956": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP104b",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "c59b5910-3279-509f-8175-f2fd0da2dc86": true,
                                                "75eb3568-195e-5720-827c-1fd0f84c6e75": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP103b",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "867b0ee4-73d9-5aac-b96d-bc2d85d5b183": false,
                                            "c4bec2f3-b4c0-5a86-abf4-28921c5b1956": true
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP104b",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "c59b5910-3279-509f-8175-f2fd0da2dc86": true,
                                            "75eb3568-195e-5720-827c-1fd0f84c6e75": false
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP103b",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "867b0ee4-73d9-5aac-b96d-bc2d85d5b183": true,
                                            "c4bec2f3-b4c0-5a86-abf4-28921c5b1956": false
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP104b",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "c59b5910-3279-509f-8175-f2fd0da2dc86": false,
                                            "75eb3568-195e-5720-827c-1fd0f84c6e75": true
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    }
                ],
                "anyToAll": 0.5
            }
        ],
        "anyToAll": 1
    },
    "and[or[isUndefined(and[BP103b=0, BP104b=0]), not(and[BP103b=0, BP104b=0])], or[and[BP103b=1, BP104b=0], and[BP103b=0, BP104b=1], and[or[isUndefined(and[BP103b=1, BP104b=0]), not(and[BP103b=1, BP104b=0])], or[isUndefined(and[BP103b=0, BP104b=1]), not(and[BP103b=0, BP104b=1])], not(isUndefined(BP104b=0))]]]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP103b",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "867b0ee4-73d9-5aac-b96d-bc2d85d5b183": true,
                                                "c4bec2f3-b4c0-5a86-abf4-28921c5b1956": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP104b",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "c59b5910-3279-509f-8175-f2fd0da2dc86": true,
                                                "75eb3568-195e-5720-827c-1fd0f84c6e75": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP103b",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "867b0ee4-73d9-5aac-b96d-bc2d85d5b183": true,
                                                "c4bec2f3-b4c0-5a86-abf4-28921c5b1956": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP104b",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "c59b5910-3279-509f-8175-f2fd0da2dc86": true,
                                                "75eb3568-195e-5720-827c-1fd0f84c6e75": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP103b",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "867b0ee4-73d9-5aac-b96d-bc2d85d5b183": false,
                                            "c4bec2f3-b4c0-5a86-abf4-28921c5b1956": true
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP104b",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "c59b5910-3279-509f-8175-f2fd0da2dc86": true,
                                            "75eb3568-195e-5720-827c-1fd0f84c6e75": false
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP103b",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "867b0ee4-73d9-5aac-b96d-bc2d85d5b183": true,
                                            "c4bec2f3-b4c0-5a86-abf4-28921c5b1956": false
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP104b",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "c59b5910-3279-509f-8175-f2fd0da2dc86": false,
                                            "75eb3568-195e-5720-827c-1fd0f84c6e75": true
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "isUnknown",
                                        "wire": {
                                            "type": "merge",
                                            "wires": [
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "BP103b",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "867b0ee4-73d9-5aac-b96d-bc2d85d5b183": false,
                                                                "c4bec2f3-b4c0-5a86-abf4-28921c5b1956": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "BP104b",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "c59b5910-3279-509f-8175-f2fd0da2dc86": true,
                                                                "75eb3568-195e-5720-827c-1fd0f84c6e75": false
                                                            }
                                                        }
                                                    }
                                                }
                                            ],
                                            "anyToAll": 1
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "merge",
                                            "wires": [
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "BP103b",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "867b0ee4-73d9-5aac-b96d-bc2d85d5b183": false,
                                                                "c4bec2f3-b4c0-5a86-abf4-28921c5b1956": true
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "BP104b",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "c59b5910-3279-509f-8175-f2fd0da2dc86": true,
                                                                "75eb3568-195e-5720-827c-1fd0f84c6e75": false
                                                            }
                                                        }
                                                    }
                                                }
                                            ],
                                            "anyToAll": 1
                                        }
                                    }
                                ],
                                "anyToAll": 0.5
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "isUnknown",
                                        "wire": {
                                            "type": "merge",
                                            "wires": [
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "BP103b",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "867b0ee4-73d9-5aac-b96d-bc2d85d5b183": true,
                                                                "c4bec2f3-b4c0-5a86-abf4-28921c5b1956": false
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "BP104b",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "c59b5910-3279-509f-8175-f2fd0da2dc86": false,
                                                                "75eb3568-195e-5720-827c-1fd0f84c6e75": true
                                                            }
                                                        }
                                                    }
                                                }
                                            ],
                                            "anyToAll": 1
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "merge",
                                            "wires": [
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "BP103b",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "867b0ee4-73d9-5aac-b96d-bc2d85d5b183": true,
                                                                "c4bec2f3-b4c0-5a86-abf4-28921c5b1956": false
                                                            }
                                                        }
                                                    }
                                                },
                                                {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AnsweredOption",
                                                        "questionId": "BP104b",
                                                        "options": {
                                                            "type": "predicates",
                                                            "predicates": {
                                                                "c59b5910-3279-509f-8175-f2fd0da2dc86": false,
                                                                "75eb3568-195e-5720-827c-1fd0f84c6e75": true
                                                            }
                                                        }
                                                    }
                                                }
                                            ],
                                            "anyToAll": 1
                                        }
                                    }
                                ],
                                "anyToAll": 0.5
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "isUnknown",
                                    "wire": {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "BP104b",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "c59b5910-3279-509f-8175-f2fd0da2dc86": true,
                                                    "75eb3568-195e-5720-827c-1fd0f84c6e75": false
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    }
                ],
                "anyToAll": 0.3333333333333333
            }
        ],
        "anyToAll": 1
    },
    "or[and[not(BP1.5=0), not(and[BP1.5>=0.33, BP1.5<1])], and[or[isUndefined(Dem18<1), not(Dem18<1)], not(isUndefined(BPS2=0))]]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP1.5=0"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP1.5",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "7599bcaa-89a5-5f71-80ea-b70c360bb44a": 0.03557118927263617,
                                                "ccbdcbec-96d0-5362-977b-784289f5cfc0": 0.5,
                                                "83e6efd5-64a2-5f0e-a973-9278eae90cfb": 0.9644288107273639,
                                                "6215a3de-a3e4-5f86-a039-1006646e6b93": 0.9987706013787226
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP1.5",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "7599bcaa-89a5-5f71-80ea-b70c360bb44a": 0.9986414800495711,
                                                "ccbdcbec-96d0-5362-977b-784289f5cfc0": 0.9644288107273639,
                                                "83e6efd5-64a2-5f0e-a973-9278eae90cfb": 0.5,
                                                "6215a3de-a3e4-5f86-a039-1006646e6b93": 0.03229546469845057
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "Dem18",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "9d064e64-ec95-50b8-af10-5b9d39e9fb8d": 0.9933071490757152,
                                                "05653d8f-0bd2-5c1a-8a9f-fee81d9e4cfa": 0.5,
                                                "db090d78-6247-560e-893c-e74389c1efc6": 0.006692850924284732
                                            }
                                        }
                                    }
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "Dem18",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "9d064e64-ec95-50b8-af10-5b9d39e9fb8d": 0.9933071490757152,
                                                "05653d8f-0bd2-5c1a-8a9f-fee81d9e4cfa": 0.5,
                                                "db090d78-6247-560e-893c-e74389c1efc6": 0.006692850924284732
                                            }
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BPS2",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "316e99d8-6ceb-52b4-a93f-fc23d563c46a": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            }
        ],
        "anyToAll": 0.5
    },
    "or[BP106=0, BP106=0.33, BP106=0.66]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP106",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "e408e8a3-8395-5c34-992a-3c1ff862dc84": true,
                            "8c35da7e-8c0b-50bb-a037-b80a9f1df3d2": false,
                            "3648c32c-0ce7-5c1d-a7e8-d45a899b40f9": false,
                            "9658b463-86ce-5495-a40a-7361dc568105": false
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP106",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "e408e8a3-8395-5c34-992a-3c1ff862dc84": false,
                            "8c35da7e-8c0b-50bb-a037-b80a9f1df3d2": true,
                            "3648c32c-0ce7-5c1d-a7e8-d45a899b40f9": false,
                            "9658b463-86ce-5495-a40a-7361dc568105": false
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP106",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "e408e8a3-8395-5c34-992a-3c1ff862dc84": false,
                            "8c35da7e-8c0b-50bb-a037-b80a9f1df3d2": false,
                            "3648c32c-0ce7-5c1d-a7e8-d45a899b40f9": true,
                            "9658b463-86ce-5495-a40a-7361dc568105": false
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "not(BP112=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "BP112",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "6db4ba23-1d8f-58b9-b11e-eaea15417e28": true,
                        "71867305-9c3a-564c-a0c7-e187b27e8d79": false
                    }
                }
            }
        }
    },
    "and[not(BP112.1=0), not(BP112.1=1)]": {
        "type": "merge",
        "wires": [
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "BP112.1=0"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "BP112.1=1"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "BP112.1=1": {
        "type": "input",
        "signal": {
            "type": "SpecificNumberInput",
            "questionId": "BP112.1",
            "input": 1,
            "operator": "="
        }
    },
    "BP112.1=0": {
        "type": "input",
        "signal": {
            "type": "SpecificNumberInput",
            "questionId": "BP112.1",
            "input": 0,
            "operator": "="
        }
    },
    "and[not(BP112.4=0), not(BP112.4=1)]": {
        "type": "merge",
        "wires": [
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "SpecificNumberInput",
                        "questionId": "BP112.4",
                        "input": 0,
                        "operator": "="
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "BP112.4=1"
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "BP112.4=1": {
        "type": "input",
        "signal": {
            "type": "SpecificNumberInput",
            "questionId": "BP112.4",
            "input": 1,
            "operator": "="
        }
    },
    "BP113=1": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "BP113",
            "options": {
                "type": "predicates",
                "predicates": {
                    "b16103eb-2ecf-595e-80e4-30baf575e01b": false,
                    "b761d57f-0aed-52ed-9ffb-467f63c4fb29": true
                }
            }
        }
    },
    "BP113.1=0": {
        "type": "input",
        "signal": {
            "type": "SpecificNumberInput",
            "questionId": "BP113.1",
            "input": 0,
            "operator": "="
        }
    },
    "not(BP113.1=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "BP113.1=0"
            }
        }
    },
    "and[or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]], or[not(BP113=1), not(isUndefined(BP113.4=0))]]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP112.3",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "f596911a-c0b8-51b8-a6c3-e0e50444761a": true,
                                            "dc8d63aa-5242-52d9-b439-526944ab2713": false,
                                            "5de2cc88-13a4-5f04-a3d2-1333852193f9": false
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "SpecificNumberInput",
                                    "questionId": "BP110",
                                    "input": 0.6,
                                    "operator": ">="
                                }
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AspectReference",
                                    "aspectName": "BP112.1=1"
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "SpecificNumberInput",
                                    "questionId": "BP110",
                                    "input": 0.6,
                                    "operator": "<"
                                }
                            }
                        ],
                        "anyToAll": 1
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP113=1"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP113.4",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "bd60deee-3a91-5bc5-9487-267c44221755": true,
                                            "126719e4-fe06-5b9a-9791-f58c8f290fd4": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            }
        ],
        "anyToAll": 1
    },
    "and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0, or[not(BP113=1), not(isUndefined(BP113.4=0))]]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "BP112.1=1"
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "SpecificNumberInput",
                                        "questionId": "BP110",
                                        "input": 0.6,
                                        "operator": "<"
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "BP112.1=1"
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "SpecificNumberInput",
                                        "questionId": "BP110",
                                        "input": 0.6,
                                        "operator": "<"
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP112.2",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "6079cfc0-03c5-5003-87ad-3dff357f0f74": true,
                            "9ff851b9-a640-557e-a750-a4d6e2ffbc49": false,
                            "0220e07a-c424-5583-b5a4-d53440a7bc6e": false
                        }
                    }
                }
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP113=1"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP113.4",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "bd60deee-3a91-5bc5-9487-267c44221755": true,
                                            "126719e4-fe06-5b9a-9791-f58c8f290fd4": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            }
        ],
        "anyToAll": 1
    },
    "and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]], or[not(BP113=1), not(isUndefined(BP113.4=0))]]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP112.3",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "f596911a-c0b8-51b8-a6c3-e0e50444761a": true,
                                                "dc8d63aa-5242-52d9-b439-526944ab2713": false,
                                                "5de2cc88-13a4-5f04-a3d2-1333852193f9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "SpecificNumberInput",
                                        "questionId": "BP110",
                                        "input": 0.6,
                                        "operator": ">="
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP112.3",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "f596911a-c0b8-51b8-a6c3-e0e50444761a": true,
                                                "dc8d63aa-5242-52d9-b439-526944ab2713": false,
                                                "5de2cc88-13a4-5f04-a3d2-1333852193f9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "SpecificNumberInput",
                                        "questionId": "BP110",
                                        "input": 0.6,
                                        "operator": ">="
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "BP112.1=1"
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "SpecificNumberInput",
                                        "questionId": "BP110",
                                        "input": 0.6,
                                        "operator": "<"
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "BP112.1=1"
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "SpecificNumberInput",
                                        "questionId": "BP110",
                                        "input": 0.6,
                                        "operator": "<"
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP112.2",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "6079cfc0-03c5-5003-87ad-3dff357f0f74": true,
                                        "9ff851b9-a640-557e-a750-a4d6e2ffbc49": false,
                                        "0220e07a-c424-5583-b5a4-d53440a7bc6e": false
                                    }
                                }
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP112.2",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "6079cfc0-03c5-5003-87ad-3dff357f0f74": true,
                                        "9ff851b9-a640-557e-a750-a4d6e2ffbc49": false,
                                        "0220e07a-c424-5583-b5a4-d53440a7bc6e": false
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AspectReference",
                                    "aspectName": "BP112.4=1"
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "SpecificNumberInput",
                                    "questionId": "BP110",
                                    "input": 0.4,
                                    "operator": "<"
                                }
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP112.6",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "a2924f91-50e4-560e-a7e7-327ed9b0ad26": true,
                                            "bbc68d1c-8068-5eb7-a7ff-600e2ccb4e9f": false,
                                            "3cd21659-e85a-524c-8549-44f033916396": false
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "SpecificNumberInput",
                                    "questionId": "BP110",
                                    "input": 0.4,
                                    "operator": ">="
                                }
                            }
                        ],
                        "anyToAll": 1
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP113=1"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP113.4",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "bd60deee-3a91-5bc5-9487-267c44221755": true,
                                            "126719e4-fe06-5b9a-9791-f58c8f290fd4": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            }
        ],
        "anyToAll": 1
    },
    "and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0, or[not(BP113=1), not(isUndefined(BP113.4=0))]]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "BP112.1=1"
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "SpecificNumberInput",
                                        "questionId": "BP110",
                                        "input": 0.6,
                                        "operator": "<"
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "BP112.1=1"
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "SpecificNumberInput",
                                        "questionId": "BP110",
                                        "input": 0.6,
                                        "operator": "<"
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "BP112.4=1"
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "SpecificNumberInput",
                                        "questionId": "BP110",
                                        "input": 0.4,
                                        "operator": "<"
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "BP112.4=1"
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "SpecificNumberInput",
                                        "questionId": "BP110",
                                        "input": 0.4,
                                        "operator": "<"
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP112.5",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "71ad4086-a47a-5265-86b2-eed25d6d5abe": true,
                            "7e08ea1c-7f7d-5f72-87fb-1df882aeed10": false,
                            "e1e59a23-091e-54fa-b3ce-49a3fa26fb2a": false
                        }
                    }
                }
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP113=1"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP113.4",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "bd60deee-3a91-5bc5-9487-267c44221755": true,
                                            "126719e4-fe06-5b9a-9791-f58c8f290fd4": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            }
        ],
        "anyToAll": 1
    },
    "or[and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)], and[Dem18<1, not(isUndefined(BPS2=0))], and[not(BP113=1), or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]]], and[or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0, not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]]], and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0, not(isUndefined(BP113.4=0))]]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)]"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "and[Dem18<1, not(isUndefined(BPS2=0))]"
                }
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP113=1"
                            }
                        }
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "BP112.3",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "f596911a-c0b8-51b8-a6c3-e0e50444761a": true,
                                                    "dc8d63aa-5242-52d9-b439-526944ab2713": false,
                                                    "5de2cc88-13a4-5f04-a3d2-1333852193f9": false
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "SpecificNumberInput",
                                            "questionId": "BP110",
                                            "input": 0.6,
                                            "operator": ">="
                                        }
                                    }
                                ],
                                "anyToAll": 1
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AspectReference",
                                            "aspectName": "BP112.1=1"
                                        }
                                    },
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "SpecificNumberInput",
                                            "questionId": "BP110",
                                            "input": 0.6,
                                            "operator": "<"
                                        }
                                    }
                                ],
                                "anyToAll": 1
                            }
                        ],
                        "anyToAll": 0.5
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "BP112.3",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "f596911a-c0b8-51b8-a6c3-e0e50444761a": true,
                                                    "dc8d63aa-5242-52d9-b439-526944ab2713": false,
                                                    "5de2cc88-13a4-5f04-a3d2-1333852193f9": false
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "SpecificNumberInput",
                                            "questionId": "BP110",
                                            "input": 0.6,
                                            "operator": ">="
                                        }
                                    }
                                ],
                                "anyToAll": 1
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AspectReference",
                                            "aspectName": "BP112.1=1"
                                        }
                                    },
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "SpecificNumberInput",
                                            "questionId": "BP110",
                                            "input": 0.6,
                                            "operator": "<"
                                        }
                                    }
                                ],
                                "anyToAll": 1
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP113.4",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "bd60deee-3a91-5bc5-9487-267c44221755": true,
                                            "126719e4-fe06-5b9a-9791-f58c8f290fd4": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP113=1"
                            }
                        }
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP112.1=1"
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "SpecificNumberInput",
                                                "questionId": "BP110",
                                                "input": 0.6,
                                                "operator": "<"
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP112.1=1"
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "SpecificNumberInput",
                                                "questionId": "BP110",
                                                "input": 0.6,
                                                "operator": "<"
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP112.2",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "6079cfc0-03c5-5003-87ad-3dff357f0f74": true,
                                    "9ff851b9-a640-557e-a750-a4d6e2ffbc49": false,
                                    "0220e07a-c424-5583-b5a4-d53440a7bc6e": false
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP112.1=1"
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "SpecificNumberInput",
                                                "questionId": "BP110",
                                                "input": 0.6,
                                                "operator": "<"
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP112.1=1"
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "SpecificNumberInput",
                                                "questionId": "BP110",
                                                "input": 0.6,
                                                "operator": "<"
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP112.2",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "6079cfc0-03c5-5003-87ad-3dff357f0f74": true,
                                    "9ff851b9-a640-557e-a750-a4d6e2ffbc49": false,
                                    "0220e07a-c424-5583-b5a4-d53440a7bc6e": false
                                }
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP113.4",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "bd60deee-3a91-5bc5-9487-267c44221755": true,
                                            "126719e4-fe06-5b9a-9791-f58c8f290fd4": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP113=1"
                            }
                        }
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "BP112.3",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "f596911a-c0b8-51b8-a6c3-e0e50444761a": true,
                                                        "dc8d63aa-5242-52d9-b439-526944ab2713": false,
                                                        "5de2cc88-13a4-5f04-a3d2-1333852193f9": false
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "SpecificNumberInput",
                                                "questionId": "BP110",
                                                "input": 0.6,
                                                "operator": ">="
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "BP112.3",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "f596911a-c0b8-51b8-a6c3-e0e50444761a": true,
                                                        "dc8d63aa-5242-52d9-b439-526944ab2713": false,
                                                        "5de2cc88-13a4-5f04-a3d2-1333852193f9": false
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "SpecificNumberInput",
                                                "questionId": "BP110",
                                                "input": 0.6,
                                                "operator": ">="
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP112.1=1"
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "SpecificNumberInput",
                                                "questionId": "BP110",
                                                "input": 0.6,
                                                "operator": "<"
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP112.1=1"
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "SpecificNumberInput",
                                                "questionId": "BP110",
                                                "input": 0.6,
                                                "operator": "<"
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP112.2",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "6079cfc0-03c5-5003-87ad-3dff357f0f74": true,
                                                "9ff851b9-a640-557e-a750-a4d6e2ffbc49": false,
                                                "0220e07a-c424-5583-b5a4-d53440a7bc6e": false
                                            }
                                        }
                                    }
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP112.2",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "6079cfc0-03c5-5003-87ad-3dff357f0f74": true,
                                                "9ff851b9-a640-557e-a750-a4d6e2ffbc49": false,
                                                "0220e07a-c424-5583-b5a4-d53440a7bc6e": false
                                            }
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AspectReference",
                                            "aspectName": "BP112.4=1"
                                        }
                                    },
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "SpecificNumberInput",
                                            "questionId": "BP110",
                                            "input": 0.4,
                                            "operator": "<"
                                        }
                                    }
                                ],
                                "anyToAll": 1
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "BP112.6",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "a2924f91-50e4-560e-a7e7-327ed9b0ad26": true,
                                                    "bbc68d1c-8068-5eb7-a7ff-600e2ccb4e9f": false,
                                                    "3cd21659-e85a-524c-8549-44f033916396": false
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "SpecificNumberInput",
                                            "questionId": "BP110",
                                            "input": 0.4,
                                            "operator": ">="
                                        }
                                    }
                                ],
                                "anyToAll": 1
                            }
                        ],
                        "anyToAll": 0.5
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "BP112.3",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "f596911a-c0b8-51b8-a6c3-e0e50444761a": true,
                                                        "dc8d63aa-5242-52d9-b439-526944ab2713": false,
                                                        "5de2cc88-13a4-5f04-a3d2-1333852193f9": false
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "SpecificNumberInput",
                                                "questionId": "BP110",
                                                "input": 0.6,
                                                "operator": ">="
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "BP112.3",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "f596911a-c0b8-51b8-a6c3-e0e50444761a": true,
                                                        "dc8d63aa-5242-52d9-b439-526944ab2713": false,
                                                        "5de2cc88-13a4-5f04-a3d2-1333852193f9": false
                                                    }
                                                }
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "SpecificNumberInput",
                                                "questionId": "BP110",
                                                "input": 0.6,
                                                "operator": ">="
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP112.1=1"
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "SpecificNumberInput",
                                                "questionId": "BP110",
                                                "input": 0.6,
                                                "operator": "<"
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP112.1=1"
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "SpecificNumberInput",
                                                "questionId": "BP110",
                                                "input": 0.6,
                                                "operator": "<"
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP112.2",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "6079cfc0-03c5-5003-87ad-3dff357f0f74": true,
                                                "9ff851b9-a640-557e-a750-a4d6e2ffbc49": false,
                                                "0220e07a-c424-5583-b5a4-d53440a7bc6e": false
                                            }
                                        }
                                    }
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP112.2",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "6079cfc0-03c5-5003-87ad-3dff357f0f74": true,
                                                "9ff851b9-a640-557e-a750-a4d6e2ffbc49": false,
                                                "0220e07a-c424-5583-b5a4-d53440a7bc6e": false
                                            }
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AspectReference",
                                            "aspectName": "BP112.4=1"
                                        }
                                    },
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "SpecificNumberInput",
                                            "questionId": "BP110",
                                            "input": 0.4,
                                            "operator": "<"
                                        }
                                    }
                                ],
                                "anyToAll": 1
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "BP112.6",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "a2924f91-50e4-560e-a7e7-327ed9b0ad26": true,
                                                    "bbc68d1c-8068-5eb7-a7ff-600e2ccb4e9f": false,
                                                    "3cd21659-e85a-524c-8549-44f033916396": false
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "SpecificNumberInput",
                                            "questionId": "BP110",
                                            "input": 0.4,
                                            "operator": ">="
                                        }
                                    }
                                ],
                                "anyToAll": 1
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP113.4",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "bd60deee-3a91-5bc5-9487-267c44221755": true,
                                            "126719e4-fe06-5b9a-9791-f58c8f290fd4": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP113=1"
                            }
                        }
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP112.1=1"
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "SpecificNumberInput",
                                                "questionId": "BP110",
                                                "input": 0.6,
                                                "operator": "<"
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP112.1=1"
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "SpecificNumberInput",
                                                "questionId": "BP110",
                                                "input": 0.6,
                                                "operator": "<"
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP112.4=1"
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "SpecificNumberInput",
                                                "questionId": "BP110",
                                                "input": 0.4,
                                                "operator": "<"
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP112.4=1"
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "SpecificNumberInput",
                                                "questionId": "BP110",
                                                "input": 0.4,
                                                "operator": "<"
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP112.5",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "71ad4086-a47a-5265-86b2-eed25d6d5abe": true,
                                    "7e08ea1c-7f7d-5f72-87fb-1df882aeed10": false,
                                    "e1e59a23-091e-54fa-b3ce-49a3fa26fb2a": false
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP112.1=1"
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "SpecificNumberInput",
                                                "questionId": "BP110",
                                                "input": 0.6,
                                                "operator": "<"
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP112.1=1"
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "SpecificNumberInput",
                                                "questionId": "BP110",
                                                "input": 0.6,
                                                "operator": "<"
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "isUnknown",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP112.4=1"
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "SpecificNumberInput",
                                                "questionId": "BP110",
                                                "input": 0.4,
                                                "operator": "<"
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "merge",
                                    "wires": [
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP112.4=1"
                                            }
                                        },
                                        {
                                            "type": "input",
                                            "signal": {
                                                "type": "SpecificNumberInput",
                                                "questionId": "BP110",
                                                "input": 0.4,
                                                "operator": "<"
                                            }
                                        }
                                    ],
                                    "anyToAll": 1
                                }
                            }
                        ],
                        "anyToAll": 0.5
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP112.5",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "71ad4086-a47a-5265-86b2-eed25d6d5abe": true,
                                    "7e08ea1c-7f7d-5f72-87fb-1df882aeed10": false,
                                    "e1e59a23-091e-54fa-b3ce-49a3fa26fb2a": false
                                }
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP113.4",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "bd60deee-3a91-5bc5-9487-267c44221755": true,
                                            "126719e4-fe06-5b9a-9791-f58c8f290fd4": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            }
        ],
        "anyToAll": 0.1
    },
    "and[or[isUndefined(BP99_no=1), not(BP99_no=1)], BP99_excessive=1, not(isUndefined(BP99=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP99_no"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP99_no"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "BP99_excessive"
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP99",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "63509127-aadf-5fe2-8e1f-19d0d846d6ce": false,
                                    "36502f0b-4563-5e03-80f2-a46dabeeae74": false,
                                    "5c06d516-e816-58d6-997e-f8fa91131ac7": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[BP99_dangerous=1, or[and[or[isUndefined(BP99_no=1), not(BP99_no=1)], or[isUndefined(BP99_excessive=1), not(BP99_excessive=1)], not(isUndefined(BP99=0))], not(isUndefined(BP99.1=0))]]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "BP99_dangerous"
                }
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "isUnknown",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP99_no"
                                            }
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP99_no"
                                            }
                                        }
                                    }
                                ],
                                "anyToAll": 0.5
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "isUnknown",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP99_excessive"
                                            }
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP99_excessive"
                                            }
                                        }
                                    }
                                ],
                                "anyToAll": 0.5
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "isUnknown",
                                    "wire": {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "BP99",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "63509127-aadf-5fe2-8e1f-19d0d846d6ce": false,
                                                    "36502f0b-4563-5e03-80f2-a46dabeeae74": false,
                                                    "5c06d516-e816-58d6-997e-f8fa91131ac7": false
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP99.1",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "34d19f12-207f-56ce-bde8-8d616f6e8b26": true,
                                            "659c1b81-1b5d-5c34-9d0d-ce0ceae2a3b3": false,
                                            "74809c02-f47f-5955-a416-b40fc66ff214": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            }
        ],
        "anyToAll": 1
    },
    "and[or[isUndefined(and[BP103=0, BP104=0]), not(and[BP103=0, BP104=0])], or[and[BP103=1, BP104=0], and[BP103=0, BP104=1], and[BP103=1, BP104=1]]]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP103",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "b24df75b-6bb9-5f5f-99e3-c883ec3644ec": true,
                                                "f2228920-3fec-5097-a63d-990c25d50c6c": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP104",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "71f4aadd-848c-56d9-b008-3b1bf7ddae28": true,
                                                "de4464c8-78fe-5876-aa00-25826ce5ec25": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP103",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "b24df75b-6bb9-5f5f-99e3-c883ec3644ec": true,
                                                "f2228920-3fec-5097-a63d-990c25d50c6c": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP104",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "71f4aadd-848c-56d9-b008-3b1bf7ddae28": true,
                                                "de4464c8-78fe-5876-aa00-25826ce5ec25": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP103",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "b24df75b-6bb9-5f5f-99e3-c883ec3644ec": false,
                                            "f2228920-3fec-5097-a63d-990c25d50c6c": true
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP104",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "71f4aadd-848c-56d9-b008-3b1bf7ddae28": true,
                                            "de4464c8-78fe-5876-aa00-25826ce5ec25": false
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP103",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "b24df75b-6bb9-5f5f-99e3-c883ec3644ec": true,
                                            "f2228920-3fec-5097-a63d-990c25d50c6c": false
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP104",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "71f4aadd-848c-56d9-b008-3b1bf7ddae28": false,
                                            "de4464c8-78fe-5876-aa00-25826ce5ec25": true
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP103",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "b24df75b-6bb9-5f5f-99e3-c883ec3644ec": false,
                                            "f2228920-3fec-5097-a63d-990c25d50c6c": true
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP104",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "71f4aadd-848c-56d9-b008-3b1bf7ddae28": false,
                                            "de4464c8-78fe-5876-aa00-25826ce5ec25": true
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    }
                ],
                "anyToAll": 0.3333333333333333
            }
        ],
        "anyToAll": 1
    },
    "and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], or[isUndefined(and[BP112.6=0, BP110>=0.4]), not(and[BP112.6=0, BP110>=0.4])], or[isUndefined(BP112.5=0), not(BP112.5=0)], or[not(BP113=1), not(isUndefined(BP113.4=0))]]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP112.3",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "f596911a-c0b8-51b8-a6c3-e0e50444761a": true,
                                                "dc8d63aa-5242-52d9-b439-526944ab2713": false,
                                                "5de2cc88-13a4-5f04-a3d2-1333852193f9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "SpecificNumberInput",
                                        "questionId": "BP110",
                                        "input": 0.6,
                                        "operator": ">="
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP112.3",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "f596911a-c0b8-51b8-a6c3-e0e50444761a": true,
                                                "dc8d63aa-5242-52d9-b439-526944ab2713": false,
                                                "5de2cc88-13a4-5f04-a3d2-1333852193f9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "SpecificNumberInput",
                                        "questionId": "BP110",
                                        "input": 0.6,
                                        "operator": ">="
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "BP112.1=1"
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "SpecificNumberInput",
                                        "questionId": "BP110",
                                        "input": 0.6,
                                        "operator": "<"
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "BP112.1=1"
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "SpecificNumberInput",
                                        "questionId": "BP110",
                                        "input": 0.6,
                                        "operator": "<"
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP112.2",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "6079cfc0-03c5-5003-87ad-3dff357f0f74": true,
                                        "9ff851b9-a640-557e-a750-a4d6e2ffbc49": false,
                                        "0220e07a-c424-5583-b5a4-d53440a7bc6e": false
                                    }
                                }
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP112.2",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "6079cfc0-03c5-5003-87ad-3dff357f0f74": true,
                                        "9ff851b9-a640-557e-a750-a4d6e2ffbc49": false,
                                        "0220e07a-c424-5583-b5a4-d53440a7bc6e": false
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "BP112.4=1"
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "SpecificNumberInput",
                                        "questionId": "BP110",
                                        "input": 0.4,
                                        "operator": "<"
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AspectReference",
                                        "aspectName": "BP112.4=1"
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "SpecificNumberInput",
                                        "questionId": "BP110",
                                        "input": 0.4,
                                        "operator": "<"
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP112.6",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "a2924f91-50e4-560e-a7e7-327ed9b0ad26": true,
                                                "bbc68d1c-8068-5eb7-a7ff-600e2ccb4e9f": false,
                                                "3cd21659-e85a-524c-8549-44f033916396": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "SpecificNumberInput",
                                        "questionId": "BP110",
                                        "input": 0.4,
                                        "operator": ">="
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP112.6",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "a2924f91-50e4-560e-a7e7-327ed9b0ad26": true,
                                                "bbc68d1c-8068-5eb7-a7ff-600e2ccb4e9f": false,
                                                "3cd21659-e85a-524c-8549-44f033916396": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "SpecificNumberInput",
                                        "questionId": "BP110",
                                        "input": 0.4,
                                        "operator": ">="
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP112.5",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "71ad4086-a47a-5265-86b2-eed25d6d5abe": true,
                                        "7e08ea1c-7f7d-5f72-87fb-1df882aeed10": false,
                                        "e1e59a23-091e-54fa-b3ce-49a3fa26fb2a": false
                                    }
                                }
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP112.5",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "71ad4086-a47a-5265-86b2-eed25d6d5abe": true,
                                        "7e08ea1c-7f7d-5f72-87fb-1df882aeed10": false,
                                        "e1e59a23-091e-54fa-b3ce-49a3fa26fb2a": false
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP113=1"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP113.4",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "bd60deee-3a91-5bc5-9487-267c44221755": true,
                                            "126719e4-fe06-5b9a-9791-f58c8f290fd4": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            }
        ],
        "anyToAll": 1
    },
    "and[or[isUndefined(BP127_no=1), not(BP127_no=1)], BP127_excessive=1, not(isUndefined(BP127=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP127_no"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP127_no"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "BP127_excessive"
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP127",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "1fc78738-6afa-5714-89a1-8d5698fb9f69": false,
                                    "27638a2c-33e4-58c5-a185-4dcdd837f93c": false,
                                    "5f58bb60-dff1-585c-a5f3-26e34e4dc957": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[BP127_dangerous=1, or[and[or[isUndefined(BP127_no=1), not(BP127_no=1)], or[isUndefined(BP127_excessive=1), not(BP127_excessive=1)], not(isUndefined(BP127=0))], not(isUndefined(BP127.1=0))]]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "BP127_dangerous"
                }
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "isUnknown",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP127_no"
                                            }
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP127_no"
                                            }
                                        }
                                    }
                                ],
                                "anyToAll": 0.5
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "isUnknown",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP127_excessive"
                                            }
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP127_excessive"
                                            }
                                        }
                                    }
                                ],
                                "anyToAll": 0.5
                            },
                            {
                                "type": "not",
                                "wire": {
                                    "type": "isUnknown",
                                    "wire": {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "BP127",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "1fc78738-6afa-5714-89a1-8d5698fb9f69": false,
                                                    "27638a2c-33e4-58c5-a185-4dcdd837f93c": false,
                                                    "5f58bb60-dff1-585c-a5f3-26e34e4dc957": false
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "isUnknown",
                            "wire": {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "BP127.1",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "a2bbf0b5-4203-5b73-89f8-05f59515356f": true,
                                            "b76730b2-4e72-5ab7-b0c7-03b88d01b19c": false,
                                            "68c97dc1-4b15-59a6-b2ae-49597771e772": false
                                        }
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            }
        ],
        "anyToAll": 1
    },
    "and[or[isUndefined(BP127_no=1), not(BP127_no=1)], not(isUndefined(BP127=0)), or[and[or[isUndefined(BP127_excessive=1), not(BP127_excessive=1)], or[isUndefined(BP127_dangerous=1), not(isUndefined(BP127_dangerous=0))]], BP127_excessive=1]]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP127_no"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP127_no"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP127",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "1fc78738-6afa-5714-89a1-8d5698fb9f69": false,
                                    "27638a2c-33e4-58c5-a185-4dcdd837f93c": false,
                                    "5f58bb60-dff1-585c-a5f3-26e34e4dc957": false
                                }
                            }
                        }
                    }
                }
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "isUnknown",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP127_excessive"
                                            }
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP127_excessive"
                                            }
                                        }
                                    }
                                ],
                                "anyToAll": 0.5
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "isUnknown",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AspectReference",
                                                "aspectName": "BP127_dangerous"
                                            }
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "isUnknown",
                                            "wire": {
                                                "type": "not",
                                                "wire": {
                                                    "type": "input",
                                                    "signal": {
                                                        "type": "AspectReference",
                                                        "aspectName": "BP127_dangerous"
                                                    }
                                                }
                                            }
                                        }
                                    }
                                ],
                                "anyToAll": 0.5
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "BP127_excessive"
                        }
                    }
                ],
                "anyToAll": 0.5
            }
        ],
        "anyToAll": 1
    },
    "and[or[isUndefined(and[BP131=0, BP132=0]), not(and[BP131=0, BP132=0])], not(isUndefined(BP132=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP131",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "e5863f5d-32a6-520a-9b40-8591adf1c1b8": true,
                                                "cf80f9fa-de7b-5954-b005-e9ec1b1b07a9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP132",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "da9000ec-69f6-580b-9ec9-756b702c2962": true,
                                                "7da9fe3a-3186-562a-b4f2-170d5baf0c76": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP131",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "e5863f5d-32a6-520a-9b40-8591adf1c1b8": true,
                                                "cf80f9fa-de7b-5954-b005-e9ec1b1b07a9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP132",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "da9000ec-69f6-580b-9ec9-756b702c2962": true,
                                                "7da9fe3a-3186-562a-b4f2-170d5baf0c76": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP132",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "da9000ec-69f6-580b-9ec9-756b702c2962": true,
                                    "7da9fe3a-3186-562a-b4f2-170d5baf0c76": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "or[and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)], and[or[isUndefined(Dem11_bpd=0), not(Dem11_bpd=0)], not(isUndefined(BPS=0))], and[not(BP1.5=0), not(and[BP1.5>=0.33, BP1.5<1])]]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)]"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "and[or[isUndefined(Dem11_bpd=0), not(Dem11_bpd=0)], not(isUndefined(BPS=0))]"
                }
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "BP1.5=0"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP1.5",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "7599bcaa-89a5-5f71-80ea-b70c360bb44a": 0.03557118927263617,
                                                "ccbdcbec-96d0-5362-977b-784289f5cfc0": 0.5,
                                                "83e6efd5-64a2-5f0e-a973-9278eae90cfb": 0.9644288107273639,
                                                "6215a3de-a3e4-5f86-a039-1006646e6b93": 0.9987706013787226
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "BP1.5",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "7599bcaa-89a5-5f71-80ea-b70c360bb44a": 0.9986414800495711,
                                                "ccbdcbec-96d0-5362-977b-784289f5cfc0": 0.9644288107273639,
                                                "83e6efd5-64a2-5f0e-a973-9278eae90cfb": 0.5,
                                                "6215a3de-a3e4-5f86-a039-1006646e6b93": 0.03229546469845057
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 1
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "and[BP9=1, not(isUndefined(HMS=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP9",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "becfe35c-2096-5848-87ae-3f763cf59dd6": true
                        }
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "HMS",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "976f055b-ac14-5a89-8536-7c66d9ec95e5": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[BP10=1, not(isUndefined(HMS=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP10",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "7ad54f0a-87a6-5e82-bfc3-44b540230082": true
                        }
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "HMS",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "976f055b-ac14-5a89-8536-7c66d9ec95e5": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[BP11=1, not(isUndefined(HMS=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP11",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "e54bdb41-2f8b-5524-b99b-415c31ee1576": true
                        }
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "HMS",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "976f055b-ac14-5a89-8536-7c66d9ec95e5": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[BP114.1=1, not(isUndefined(HMS=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP114.1",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "eca60916-bfe7-5262-9df1-e058076bb053": true
                        }
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "HMS",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "976f055b-ac14-5a89-8536-7c66d9ec95e5": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[BP114.3=1, not(isUndefined(HMS=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP114.3",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "1e2caf46-f196-5689-ac30-b0e241c8bff8": true
                        }
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "HMS",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "976f055b-ac14-5a89-8536-7c66d9ec95e5": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[BP114.2=1, not(isUndefined(HMS=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP114.2",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "22b2502c-0072-5849-b1ba-362cb59071a7": true
                        }
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "HMS",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "976f055b-ac14-5a89-8536-7c66d9ec95e5": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[or[isUndefined(BP9=1), not(BP9=1)], or[isUndefined(BP10=1), not(BP10=1)], or[isUndefined(BP11=1), not(BP11=1)], or[isUndefined(BP17=1), not(BP17=1)], or[isUndefined(BP18=1), not(BP18=1)], or[isUndefined(BP19=1), not(BP19=1)], or[isUndefined(BP114=1), not(BP114=1)], or[isUndefined(BP114.1=1), not(BP114.1=1)], or[isUndefined(BP114.3=1), not(BP114.3=1)], or[isUndefined(BP114.2=1), not(BP114.2=1)], not(isUndefined(HMS=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP9",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "becfe35c-2096-5848-87ae-3f763cf59dd6": true
                                    }
                                }
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP9",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "becfe35c-2096-5848-87ae-3f763cf59dd6": true
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP10",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "7ad54f0a-87a6-5e82-bfc3-44b540230082": true
                                    }
                                }
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP10",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "7ad54f0a-87a6-5e82-bfc3-44b540230082": true
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP11",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "e54bdb41-2f8b-5524-b99b-415c31ee1576": true
                                    }
                                }
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP11",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "e54bdb41-2f8b-5524-b99b-415c31ee1576": true
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP17",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "9fa962fb-6bd5-50fc-9063-459e175cbcc3": true
                                    }
                                }
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP17",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "9fa962fb-6bd5-50fc-9063-459e175cbcc3": true
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP18",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "b577f323-ec54-5d25-a2b9-4f8de30d60a2": true
                                    }
                                }
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP18",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "b577f323-ec54-5d25-a2b9-4f8de30d60a2": true
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP19",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "601bc370-8a4c-5b1f-a7f6-6c174f421733": true
                                    }
                                }
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP19",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "601bc370-8a4c-5b1f-a7f6-6c174f421733": true
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP114",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "a0206e39-59ad-539c-a2ff-e9fd935cf48b": true
                                    }
                                }
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP114",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "a0206e39-59ad-539c-a2ff-e9fd935cf48b": true
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP114.1",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "eca60916-bfe7-5262-9df1-e058076bb053": true
                                    }
                                }
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP114.1",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "eca60916-bfe7-5262-9df1-e058076bb053": true
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP114.3",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "1e2caf46-f196-5689-ac30-b0e241c8bff8": true
                                    }
                                }
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP114.3",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "1e2caf46-f196-5689-ac30-b0e241c8bff8": true
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP114.2",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "22b2502c-0072-5849-b1ba-362cb59071a7": true
                                    }
                                }
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AnsweredOption",
                                "questionId": "BP114.2",
                                "options": {
                                    "type": "predicates",
                                    "predicates": {
                                        "22b2502c-0072-5849-b1ba-362cb59071a7": true
                                    }
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "HMS",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "976f055b-ac14-5a89-8536-7c66d9ec95e5": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[BP17=1, not(isUndefined(HMS=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP17",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "9fa962fb-6bd5-50fc-9063-459e175cbcc3": true
                        }
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "HMS",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "976f055b-ac14-5a89-8536-7c66d9ec95e5": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[BP18=1, not(isUndefined(HMS=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP18",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "b577f323-ec54-5d25-a2b9-4f8de30d60a2": true
                        }
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "HMS",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "976f055b-ac14-5a89-8536-7c66d9ec95e5": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[BP19=1, not(isUndefined(HMS=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP19",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "601bc370-8a4c-5b1f-a7f6-6c174f421733": true
                        }
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "HMS",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "976f055b-ac14-5a89-8536-7c66d9ec95e5": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[not(isUndefined(HMS=0)), or[and[or[isUndefined(BP9=1), not(BP9=1)], or[and[or[isUndefined(BP10=1), not(BP10=1)], or[and[or[isUndefined(BP11=1), not(BP11=1)], or[and[or[isUndefined(BP17=1), not(BP17=1)], or[and[or[isUndefined(BP18=1), not(BP18=1)], or[and[or[isUndefined(BP19=1), not(BP19=1)], or[isUndefined(BP114=1), not(BP114=1)], or[and[or[isUndefined(BP114.1=1), not(BP114.1=1)], or[and[or[isUndefined(BP114.3=1), not(BP114.3=1)], or[isUndefined(BP114.2=1), not(isUndefined(BP114.2=0))]], BP114.3=1]], BP114.1=1]], BP19=1]], BP18=1]], BP17=1]], BP11=1]], BP10=1]], BP9=1]]": {
        "type": "merge",
        "wires": [
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "HMS",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "976f055b-ac14-5a89-8536-7c66d9ec95e5": false
                                }
                            }
                        }
                    }
                }
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "isUnknown",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "BP9",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "becfe35c-2096-5848-87ae-3f763cf59dd6": true
                                                    }
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "not",
                                        "wire": {
                                            "type": "input",
                                            "signal": {
                                                "type": "AnsweredOption",
                                                "questionId": "BP9",
                                                "options": {
                                                    "type": "predicates",
                                                    "predicates": {
                                                        "becfe35c-2096-5848-87ae-3f763cf59dd6": true
                                                    }
                                                }
                                            }
                                        }
                                    }
                                ],
                                "anyToAll": 0.5
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "merge",
                                        "wires": [
                                            {
                                                "type": "merge",
                                                "wires": [
                                                    {
                                                        "type": "isUnknown",
                                                        "wire": {
                                                            "type": "input",
                                                            "signal": {
                                                                "type": "AnsweredOption",
                                                                "questionId": "BP10",
                                                                "options": {
                                                                    "type": "predicates",
                                                                    "predicates": {
                                                                        "7ad54f0a-87a6-5e82-bfc3-44b540230082": true
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    },
                                                    {
                                                        "type": "not",
                                                        "wire": {
                                                            "type": "input",
                                                            "signal": {
                                                                "type": "AnsweredOption",
                                                                "questionId": "BP10",
                                                                "options": {
                                                                    "type": "predicates",
                                                                    "predicates": {
                                                                        "7ad54f0a-87a6-5e82-bfc3-44b540230082": true
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                ],
                                                "anyToAll": 0.5
                                            },
                                            {
                                                "type": "merge",
                                                "wires": [
                                                    {
                                                        "type": "merge",
                                                        "wires": [
                                                            {
                                                                "type": "merge",
                                                                "wires": [
                                                                    {
                                                                        "type": "isUnknown",
                                                                        "wire": {
                                                                            "type": "input",
                                                                            "signal": {
                                                                                "type": "AnsweredOption",
                                                                                "questionId": "BP11",
                                                                                "options": {
                                                                                    "type": "predicates",
                                                                                    "predicates": {
                                                                                        "e54bdb41-2f8b-5524-b99b-415c31ee1576": true
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    },
                                                                    {
                                                                        "type": "not",
                                                                        "wire": {
                                                                            "type": "input",
                                                                            "signal": {
                                                                                "type": "AnsweredOption",
                                                                                "questionId": "BP11",
                                                                                "options": {
                                                                                    "type": "predicates",
                                                                                    "predicates": {
                                                                                        "e54bdb41-2f8b-5524-b99b-415c31ee1576": true
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                ],
                                                                "anyToAll": 0.5
                                                            },
                                                            {
                                                                "type": "merge",
                                                                "wires": [
                                                                    {
                                                                        "type": "merge",
                                                                        "wires": [
                                                                            {
                                                                                "type": "merge",
                                                                                "wires": [
                                                                                    {
                                                                                        "type": "isUnknown",
                                                                                        "wire": {
                                                                                            "type": "input",
                                                                                            "signal": {
                                                                                                "type": "AnsweredOption",
                                                                                                "questionId": "BP17",
                                                                                                "options": {
                                                                                                    "type": "predicates",
                                                                                                    "predicates": {
                                                                                                        "9fa962fb-6bd5-50fc-9063-459e175cbcc3": true
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    },
                                                                                    {
                                                                                        "type": "not",
                                                                                        "wire": {
                                                                                            "type": "input",
                                                                                            "signal": {
                                                                                                "type": "AnsweredOption",
                                                                                                "questionId": "BP17",
                                                                                                "options": {
                                                                                                    "type": "predicates",
                                                                                                    "predicates": {
                                                                                                        "9fa962fb-6bd5-50fc-9063-459e175cbcc3": true
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                ],
                                                                                "anyToAll": 0.5
                                                                            },
                                                                            {
                                                                                "type": "merge",
                                                                                "wires": [
                                                                                    {
                                                                                        "type": "merge",
                                                                                        "wires": [
                                                                                            {
                                                                                                "type": "merge",
                                                                                                "wires": [
                                                                                                    {
                                                                                                        "type": "isUnknown",
                                                                                                        "wire": {
                                                                                                            "type": "input",
                                                                                                            "signal": {
                                                                                                                "type": "AnsweredOption",
                                                                                                                "questionId": "BP18",
                                                                                                                "options": {
                                                                                                                    "type": "predicates",
                                                                                                                    "predicates": {
                                                                                                                        "b577f323-ec54-5d25-a2b9-4f8de30d60a2": true
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    },
                                                                                                    {
                                                                                                        "type": "not",
                                                                                                        "wire": {
                                                                                                            "type": "input",
                                                                                                            "signal": {
                                                                                                                "type": "AnsweredOption",
                                                                                                                "questionId": "BP18",
                                                                                                                "options": {
                                                                                                                    "type": "predicates",
                                                                                                                    "predicates": {
                                                                                                                        "b577f323-ec54-5d25-a2b9-4f8de30d60a2": true
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                ],
                                                                                                "anyToAll": 0.5
                                                                                            },
                                                                                            {
                                                                                                "type": "merge",
                                                                                                "wires": [
                                                                                                    {
                                                                                                        "type": "merge",
                                                                                                        "wires": [
                                                                                                            {
                                                                                                                "type": "merge",
                                                                                                                "wires": [
                                                                                                                    {
                                                                                                                        "type": "isUnknown",
                                                                                                                        "wire": {
                                                                                                                            "type": "input",
                                                                                                                            "signal": {
                                                                                                                                "type": "AnsweredOption",
                                                                                                                                "questionId": "BP19",
                                                                                                                                "options": {
                                                                                                                                    "type": "predicates",
                                                                                                                                    "predicates": {
                                                                                                                                        "601bc370-8a4c-5b1f-a7f6-6c174f421733": true
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    },
                                                                                                                    {
                                                                                                                        "type": "not",
                                                                                                                        "wire": {
                                                                                                                            "type": "input",
                                                                                                                            "signal": {
                                                                                                                                "type": "AnsweredOption",
                                                                                                                                "questionId": "BP19",
                                                                                                                                "options": {
                                                                                                                                    "type": "predicates",
                                                                                                                                    "predicates": {
                                                                                                                                        "601bc370-8a4c-5b1f-a7f6-6c174f421733": true
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                ],
                                                                                                                "anyToAll": 0.5
                                                                                                            },
                                                                                                            {
                                                                                                                "type": "merge",
                                                                                                                "wires": [
                                                                                                                    {
                                                                                                                        "type": "isUnknown",
                                                                                                                        "wire": {
                                                                                                                            "type": "input",
                                                                                                                            "signal": {
                                                                                                                                "type": "AnsweredOption",
                                                                                                                                "questionId": "BP114",
                                                                                                                                "options": {
                                                                                                                                    "type": "predicates",
                                                                                                                                    "predicates": {
                                                                                                                                        "a0206e39-59ad-539c-a2ff-e9fd935cf48b": true
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    },
                                                                                                                    {
                                                                                                                        "type": "not",
                                                                                                                        "wire": {
                                                                                                                            "type": "input",
                                                                                                                            "signal": {
                                                                                                                                "type": "AnsweredOption",
                                                                                                                                "questionId": "BP114",
                                                                                                                                "options": {
                                                                                                                                    "type": "predicates",
                                                                                                                                    "predicates": {
                                                                                                                                        "a0206e39-59ad-539c-a2ff-e9fd935cf48b": true
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                ],
                                                                                                                "anyToAll": 0.5
                                                                                                            },
                                                                                                            {
                                                                                                                "type": "merge",
                                                                                                                "wires": [
                                                                                                                    {
                                                                                                                        "type": "merge",
                                                                                                                        "wires": [
                                                                                                                            {
                                                                                                                                "type": "merge",
                                                                                                                                "wires": [
                                                                                                                                    {
                                                                                                                                        "type": "isUnknown",
                                                                                                                                        "wire": {
                                                                                                                                            "type": "input",
                                                                                                                                            "signal": {
                                                                                                                                                "type": "AnsweredOption",
                                                                                                                                                "questionId": "BP114.1",
                                                                                                                                                "options": {
                                                                                                                                                    "type": "predicates",
                                                                                                                                                    "predicates": {
                                                                                                                                                        "eca60916-bfe7-5262-9df1-e058076bb053": true
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    },
                                                                                                                                    {
                                                                                                                                        "type": "not",
                                                                                                                                        "wire": {
                                                                                                                                            "type": "input",
                                                                                                                                            "signal": {
                                                                                                                                                "type": "AnsweredOption",
                                                                                                                                                "questionId": "BP114.1",
                                                                                                                                                "options": {
                                                                                                                                                    "type": "predicates",
                                                                                                                                                    "predicates": {
                                                                                                                                                        "eca60916-bfe7-5262-9df1-e058076bb053": true
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                ],
                                                                                                                                "anyToAll": 0.5
                                                                                                                            },
                                                                                                                            {
                                                                                                                                "type": "merge",
                                                                                                                                "wires": [
                                                                                                                                    {
                                                                                                                                        "type": "merge",
                                                                                                                                        "wires": [
                                                                                                                                            {
                                                                                                                                                "type": "merge",
                                                                                                                                                "wires": [
                                                                                                                                                    {
                                                                                                                                                        "type": "isUnknown",
                                                                                                                                                        "wire": {
                                                                                                                                                            "type": "input",
                                                                                                                                                            "signal": {
                                                                                                                                                                "type": "AnsweredOption",
                                                                                                                                                                "questionId": "BP114.3",
                                                                                                                                                                "options": {
                                                                                                                                                                    "type": "predicates",
                                                                                                                                                                    "predicates": {
                                                                                                                                                                        "1e2caf46-f196-5689-ac30-b0e241c8bff8": true
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    },
                                                                                                                                                    {
                                                                                                                                                        "type": "not",
                                                                                                                                                        "wire": {
                                                                                                                                                            "type": "input",
                                                                                                                                                            "signal": {
                                                                                                                                                                "type": "AnsweredOption",
                                                                                                                                                                "questionId": "BP114.3",
                                                                                                                                                                "options": {
                                                                                                                                                                    "type": "predicates",
                                                                                                                                                                    "predicates": {
                                                                                                                                                                        "1e2caf46-f196-5689-ac30-b0e241c8bff8": true
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                ],
                                                                                                                                                "anyToAll": 0.5
                                                                                                                                            },
                                                                                                                                            {
                                                                                                                                                "type": "merge",
                                                                                                                                                "wires": [
                                                                                                                                                    {
                                                                                                                                                        "type": "isUnknown",
                                                                                                                                                        "wire": {
                                                                                                                                                            "type": "input",
                                                                                                                                                            "signal": {
                                                                                                                                                                "type": "AnsweredOption",
                                                                                                                                                                "questionId": "BP114.2",
                                                                                                                                                                "options": {
                                                                                                                                                                    "type": "predicates",
                                                                                                                                                                    "predicates": {
                                                                                                                                                                        "22b2502c-0072-5849-b1ba-362cb59071a7": true
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    },
                                                                                                                                                    {
                                                                                                                                                        "type": "not",
                                                                                                                                                        "wire": {
                                                                                                                                                            "type": "isUnknown",
                                                                                                                                                            "wire": {
                                                                                                                                                                "type": "input",
                                                                                                                                                                "signal": {
                                                                                                                                                                    "type": "AnsweredOption",
                                                                                                                                                                    "questionId": "BP114.2",
                                                                                                                                                                    "options": {
                                                                                                                                                                        "type": "predicates",
                                                                                                                                                                        "predicates": {
                                                                                                                                                                            "22b2502c-0072-5849-b1ba-362cb59071a7": false
                                                                                                                                                                        }
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                ],
                                                                                                                                                "anyToAll": 0.5
                                                                                                                                            }
                                                                                                                                        ],
                                                                                                                                        "anyToAll": 1
                                                                                                                                    },
                                                                                                                                    {
                                                                                                                                        "type": "input",
                                                                                                                                        "signal": {
                                                                                                                                            "type": "AnsweredOption",
                                                                                                                                            "questionId": "BP114.3",
                                                                                                                                            "options": {
                                                                                                                                                "type": "predicates",
                                                                                                                                                "predicates": {
                                                                                                                                                    "1e2caf46-f196-5689-ac30-b0e241c8bff8": true
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                ],
                                                                                                                                "anyToAll": 0.5
                                                                                                                            }
                                                                                                                        ],
                                                                                                                        "anyToAll": 1
                                                                                                                    },
                                                                                                                    {
                                                                                                                        "type": "input",
                                                                                                                        "signal": {
                                                                                                                            "type": "AnsweredOption",
                                                                                                                            "questionId": "BP114.1",
                                                                                                                            "options": {
                                                                                                                                "type": "predicates",
                                                                                                                                "predicates": {
                                                                                                                                    "eca60916-bfe7-5262-9df1-e058076bb053": true
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                ],
                                                                                                                "anyToAll": 0.5
                                                                                                            }
                                                                                                        ],
                                                                                                        "anyToAll": 1
                                                                                                    },
                                                                                                    {
                                                                                                        "type": "input",
                                                                                                        "signal": {
                                                                                                            "type": "AnsweredOption",
                                                                                                            "questionId": "BP19",
                                                                                                            "options": {
                                                                                                                "type": "predicates",
                                                                                                                "predicates": {
                                                                                                                    "601bc370-8a4c-5b1f-a7f6-6c174f421733": true
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                ],
                                                                                                "anyToAll": 0.5
                                                                                            }
                                                                                        ],
                                                                                        "anyToAll": 1
                                                                                    },
                                                                                    {
                                                                                        "type": "input",
                                                                                        "signal": {
                                                                                            "type": "AnsweredOption",
                                                                                            "questionId": "BP18",
                                                                                            "options": {
                                                                                                "type": "predicates",
                                                                                                "predicates": {
                                                                                                    "b577f323-ec54-5d25-a2b9-4f8de30d60a2": true
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                ],
                                                                                "anyToAll": 0.5
                                                                            }
                                                                        ],
                                                                        "anyToAll": 1
                                                                    },
                                                                    {
                                                                        "type": "input",
                                                                        "signal": {
                                                                            "type": "AnsweredOption",
                                                                            "questionId": "BP17",
                                                                            "options": {
                                                                                "type": "predicates",
                                                                                "predicates": {
                                                                                    "9fa962fb-6bd5-50fc-9063-459e175cbcc3": true
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                ],
                                                                "anyToAll": 0.5
                                                            }
                                                        ],
                                                        "anyToAll": 1
                                                    },
                                                    {
                                                        "type": "input",
                                                        "signal": {
                                                            "type": "AnsweredOption",
                                                            "questionId": "BP11",
                                                            "options": {
                                                                "type": "predicates",
                                                                "predicates": {
                                                                    "e54bdb41-2f8b-5524-b99b-415c31ee1576": true
                                                                }
                                                            }
                                                        }
                                                    }
                                                ],
                                                "anyToAll": 0.5
                                            }
                                        ],
                                        "anyToAll": 1
                                    },
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "BP10",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "7ad54f0a-87a6-5e82-bfc3-44b540230082": true
                                                }
                                            }
                                        }
                                    }
                                ],
                                "anyToAll": 0.5
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "BP9",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "becfe35c-2096-5848-87ae-3f763cf59dd6": true
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            }
        ],
        "anyToAll": 1
    },
    "and[BP114=1, not(isUndefined(HMS=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "BP114",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "a0206e39-59ad-539c-a2ff-e9fd935cf48b": true
                        }
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "HMS",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "976f055b-ac14-5a89-8536-7c66d9ec95e5": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    }
};
exports.tests = {
    "simple_bd1": [
        {
            "name": "screened user: SBD1001 - SIMPLE_BD1 - SUBCLINICAL (severity 25)-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.26,
                "threshold": 0
            },
            "answers": {
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "ccbdcbec-96d0-5362-977b-784289f5cfc0"
                    ]
                },
                "BP5.2": {
                    "questionId": "BP5.2",
                    "freeValue": 6
                },
                "BP5": {
                    "questionId": "BP5",
                    "chosenOptionIds": [
                        "0f5b2a9a-603c-59a7-b70a-efb21f3e5ec6"
                    ]
                },
                "BP6": {
                    "questionId": "BP6",
                    "chosenOptionIds": [
                        "52f153db-76e7-57fa-90bd-63d5a923964a"
                    ]
                },
                "BP6.5": {
                    "questionId": "BP6.5",
                    "chosenOptionIds": [
                        "4678730f-670d-50ba-94cb-71d817698a0a"
                    ]
                },
                "BP13": {
                    "questionId": "BP13",
                    "chosenOptionIds": [
                        "9818f960-c604-59a3-a1ad-372baff1b59b"
                    ]
                },
                "BP14": {
                    "questionId": "BP14",
                    "chosenOptionIds": [
                        "84994ca2-fcea-54fe-a7d6-9b44ec07f0bd"
                    ]
                },
                "BP14.5": {
                    "questionId": "BP14.5",
                    "chosenOptionIds": [
                        "f2c52c87-ab50-5315-96ce-3528652a7a32"
                    ]
                },
                "BP15": {
                    "questionId": "BP15",
                    "chosenOptionIds": [
                        "e9643ff4-2842-5b40-9c98-5b5d0e9b6a5e"
                    ]
                },
                "BP70": {
                    "questionId": "BP70",
                    "chosenOptionIds": [
                        "7af885b6-8430-5131-bbe0-e3b3085e1124"
                    ]
                },
                "BP79": {
                    "questionId": "BP79",
                    "chosenOptionIds": [
                        "4f59a1e5-dfb4-5354-9869-4f1e7a4aa6c4"
                    ]
                },
                "BP80": {
                    "questionId": "BP80",
                    "chosenOptionIds": [
                        "cf20772a-dc0a-58a9-8878-b3eecba225c2"
                    ]
                },
                "BP80.5": {
                    "questionId": "BP80.5",
                    "chosenOptionIds": [
                        "a1f34de5-9920-5767-b94d-4c5f08713c56"
                    ]
                },
                "BP110.2": {
                    "questionId": "BP110.2",
                    "chosenOptionIds": [
                        "38ac373b-568f-5787-b9f1-de042f507c61"
                    ]
                },
                "BP5.1": {
                    "questionId": "BP5.1",
                    "chosenOptionIds": [
                        "8bf2b201-3339-5ac5-8365-e39aece2c3f4"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 6
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "c053dd45-79bd-59bf-82df-7e9ecd85dc11"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "f6654515-5729-5299-a4f0-da99056e7ac9"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "960a08ff-7af7-56da-afda-5415a1654c85"
                    ]
                },
                "HM23": {
                    "questionId": "HM23",
                    "chosenOptionIds": [
                        "c347cbdd-4788-538c-b190-8008a1e964e2"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "7b2421d0-6b5b-5666-b0a9-951887636db1"
                    ]
                },
                "BP118": {
                    "questionId": "BP118",
                    "chosenOptionIds": [
                        "1eda0859-de62-56d0-836a-3f1778a6064b"
                    ]
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "3984b363-353f-56b1-ad96-4840225109ef"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "de2ea429-4fcd-53b8-8d9f-ebb69a637a02"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "f0be1669-63f2-5fb0-9af7-55be0b4fb2c2"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "1e86d64a-b972-500a-9120-7884e4b86ede"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "e76cac02-f6b2-5ab6-a467-e3025d857ab9"
                    ]
                },
                "BP74": {
                    "questionId": "BP74",
                    "chosenOptionIds": [
                        "cd4ba75c-7b4b-5126-9170-d54c05b66f1f"
                    ]
                }
            }
        },
        {
            "name": "screened user: SBD1002 - SIMPLE_BD1 - CLINICAL (severity 58)-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.635,
                "threshold": 0.03
            },
            "answers": {
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "6215a3de-a3e4-5f86-a039-1006646e6b93"
                    ]
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "c053dd45-79bd-59bf-82df-7e9ecd85dc11"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "f6654515-5729-5299-a4f0-da99056e7ac9"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 7
                },
                "BP5": {
                    "questionId": "BP5",
                    "chosenOptionIds": [
                        "0f5b2a9a-603c-59a7-b70a-efb21f3e5ec6"
                    ]
                },
                "BP5.1": {
                    "questionId": "BP5.1",
                    "chosenOptionIds": [
                        "8bf2b201-3339-5ac5-8365-e39aece2c3f4"
                    ]
                },
                "BP5.2": {
                    "questionId": "BP5.2",
                    "freeValue": 7
                },
                "BP74": {
                    "questionId": "BP74",
                    "chosenOptionIds": [
                        "cd4ba75c-7b4b-5126-9170-d54c05b66f1f"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "960a08ff-7af7-56da-afda-5415a1654c85"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "7b2421d0-6b5b-5666-b0a9-951887636db1"
                    ]
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "3984b363-353f-56b1-ad96-4840225109ef"
                    ]
                }
            }
        },
        {
            "name": "screened user: SBD1003 - SIMPLE_BD1 - SEVERE (severity 76)-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.79,
                "threshold": 0
            },
            "answers": {
                "BP1.5": {
                    "questionId": "BP1.5",
                    "chosenOptionIds": [
                        "6215a3de-a3e4-5f86-a039-1006646e6b93"
                    ]
                },
                "BP5.2": {
                    "questionId": "BP5.2",
                    "freeValue": 7
                },
                "BP5": {
                    "questionId": "BP5",
                    "chosenOptionIds": [
                        "0f5b2a9a-603c-59a7-b70a-efb21f3e5ec6"
                    ]
                },
                "BP3.3": {
                    "questionId": "BP3.3",
                    "freeValue": 7
                },
                "BP3.1": {
                    "questionId": "BP3.1",
                    "chosenOptionIds": [
                        "c053dd45-79bd-59bf-82df-7e9ecd85dc11"
                    ]
                },
                "BP3.2": {
                    "questionId": "BP3.2",
                    "chosenOptionIds": [
                        "f6654515-5729-5299-a4f0-da99056e7ac9"
                    ]
                },
                "BP87": {
                    "questionId": "BP87",
                    "chosenOptionIds": [
                        "960a08ff-7af7-56da-afda-5415a1654c85"
                    ]
                },
                "BP115": {
                    "questionId": "BP115",
                    "chosenOptionIds": [
                        "e67cb250-dabf-5ed8-8a1b-7a184dd74efd"
                    ]
                },
                "BP87b": {
                    "questionId": "BP87b",
                    "chosenOptionIds": [
                        "8cf63df7-755b-597a-9125-d7435c303261"
                    ]
                },
                "BP90": {
                    "questionId": "BP90",
                    "chosenOptionIds": [
                        "7b2421d0-6b5b-5666-b0a9-951887636db1"
                    ]
                },
                "BP118": {
                    "questionId": "BP118",
                    "chosenOptionIds": [
                        "1eda0859-de62-56d0-836a-3f1778a6064b"
                    ]
                },
                "BP90b": {
                    "questionId": "BP90b",
                    "chosenOptionIds": [
                        "5915edb5-db3b-5f9a-8b43-09542fcaa677"
                    ]
                },
                "BP92": {
                    "questionId": "BP92",
                    "chosenOptionIds": [
                        "3984b363-353f-56b1-ad96-4840225109ef"
                    ]
                },
                "BP120": {
                    "questionId": "BP120",
                    "chosenOptionIds": [
                        "137ac9f7-52c3-5502-8d0f-653df1384d35"
                    ]
                },
                "BP92b": {
                    "questionId": "BP92b",
                    "chosenOptionIds": [
                        "bdcabc25-9c00-5456-9d9b-a30e5b36cf6c"
                    ]
                },
                "BP95": {
                    "questionId": "BP95",
                    "chosenOptionIds": [
                        "de2ea429-4fcd-53b8-8d9f-ebb69a637a02"
                    ]
                },
                "BP123": {
                    "questionId": "BP123",
                    "chosenOptionIds": [
                        "5c6d0cea-9bce-5b98-b6ad-dcb0e3fb6b0d"
                    ]
                },
                "BP95b": {
                    "questionId": "BP95b",
                    "chosenOptionIds": [
                        "32255970-db0f-5cc0-a199-d412edf7ab7c"
                    ]
                },
                "HM21": {
                    "questionId": "HM21",
                    "chosenOptionIds": [
                        "7977ac6e-f8f2-5d46-ba88-d01586f6e45d"
                    ]
                },
                "HM36": {
                    "questionId": "HM36",
                    "chosenOptionIds": [
                        "79ac7b3c-38af-5670-b744-5d39000401df"
                    ]
                },
                "HM33": {
                    "questionId": "HM33",
                    "chosenOptionIds": [
                        "f0be1669-63f2-5fb0-9af7-55be0b4fb2c2"
                    ]
                },
                "HM48": {
                    "questionId": "HM48",
                    "chosenOptionIds": [
                        "fdd2a948-7ae9-5aef-bf30-81970a357202"
                    ]
                },
                "BP94": {
                    "questionId": "BP94",
                    "chosenOptionIds": [
                        "1e86d64a-b972-500a-9120-7884e4b86ede"
                    ]
                },
                "BP122": {
                    "questionId": "BP122",
                    "chosenOptionIds": [
                        "3d008b0e-8759-568c-9aae-ba21c8b6ae79"
                    ]
                },
                "BP94b": {
                    "questionId": "BP94b",
                    "chosenOptionIds": [
                        "9cf74958-461b-59df-b1a2-7ee7d7696a0b"
                    ]
                },
                "BP102": {
                    "questionId": "BP102",
                    "chosenOptionIds": [
                        "e76cac02-f6b2-5ab6-a467-e3025d857ab9"
                    ]
                },
                "BP130": {
                    "questionId": "BP130",
                    "chosenOptionIds": [
                        "a2d91cbf-fa72-5028-91d6-9f00d0ce8aa3"
                    ]
                },
                "BP102b": {
                    "questionId": "BP102b",
                    "chosenOptionIds": [
                        "523c7d8e-e42b-5654-b037-e8837afb49e7"
                    ]
                },
                "HM34": {
                    "questionId": "HM34",
                    "chosenOptionIds": [
                        "ba200400-b5a1-5ae8-aead-863c1a72be98"
                    ]
                },
                "HM49": {
                    "questionId": "HM49",
                    "chosenOptionIds": [
                        "7c044595-1479-5f7c-b2ac-b356553a69c2"
                    ]
                },
                "BP101": {
                    "questionId": "BP101",
                    "chosenOptionIds": [
                        "1c4ed441-5919-569e-98ba-b49be1bc3957"
                    ]
                },
                "BP129": {
                    "questionId": "BP129",
                    "chosenOptionIds": [
                        "477c3730-71ba-505f-9caa-07a83398580f"
                    ]
                },
                "BP101b": {
                    "questionId": "BP101b",
                    "chosenOptionIds": [
                        "923cb2af-7bb5-5341-8349-003206ae043d"
                    ]
                },
                "BP88": {
                    "questionId": "BP88",
                    "chosenOptionIds": [
                        "cb636d32-4f4e-51b3-a23f-2a11b8771b7e"
                    ]
                },
                "BP99.1": {
                    "questionId": "BP99.1",
                    "chosenOptionIds": [
                        "74809c02-f47f-5955-a416-b40fc66ff214"
                    ]
                },
                "BP127.1": {
                    "questionId": "BP127.1",
                    "chosenOptionIds": [
                        "68c97dc1-4b15-59a6-b2ae-49597771e772"
                    ]
                },
                "BP99.1b": {
                    "questionId": "BP99.1b",
                    "chosenOptionIds": [
                        "474c64d7-23b9-5015-9cdd-60661ad38d39"
                    ]
                },
                "BP127.2": {
                    "questionId": "BP127.2",
                    "chosenOptionIds": [
                        "2e4d8704-5188-5d7c-bfaf-6c83462b3621"
                    ]
                },
                "BP99.2b": {
                    "questionId": "BP99.2b",
                    "chosenOptionIds": [
                        "a8224369-4083-5ab5-8cad-c4563490599c"
                    ]
                },
                "BP99.3": {
                    "questionId": "BP99.3",
                    "chosenOptionIds": [
                        "dee1118a-2ca3-52a6-ac43-a24ea7385f88"
                    ]
                },
                "HM47": {
                    "questionId": "HM47",
                    "chosenOptionIds": [
                        "6f3ea1ee-6ac6-53b8-804e-609d817b88da"
                    ]
                },
                "BP98": {
                    "questionId": "BP98",
                    "chosenOptionIds": [
                        "b14d53e4-f898-52ac-87d7-3aa62abc9d7b"
                    ]
                },
                "BP100": {
                    "questionId": "BP100",
                    "chosenOptionIds": [
                        "026a1cea-7cfc-540b-bfe5-42886be90595"
                    ]
                },
                "BP128": {
                    "questionId": "BP128",
                    "chosenOptionIds": [
                        "8a7adbc6-1053-55ce-b226-1e2bc2131050"
                    ]
                },
                "BP93": {
                    "questionId": "BP93",
                    "chosenOptionIds": [
                        "940c16a8-1835-582a-9dad-bd52e3a49cc7"
                    ]
                },
                "BP121": {
                    "questionId": "BP121",
                    "chosenOptionIds": [
                        "2dd3fa4b-7390-5c95-b04b-c5c77974e8c2"
                    ]
                },
                "BP74": {
                    "questionId": "BP74",
                    "chosenOptionIds": [
                        "cd4ba75c-7b4b-5126-9170-d54c05b66f1f"
                    ]
                },
                "BP83": {
                    "questionId": "BP83",
                    "chosenOptionIds": [
                        "49b6c935-6235-511a-b989-acce3e5f3572"
                    ]
                },
                "BP113": {
                    "questionId": "BP113",
                    "chosenOptionIds": [
                        "b761d57f-0aed-52ed-9ffb-467f63c4fb29"
                    ]
                },
                "BP16.0": {
                    "questionId": "BP16.0",
                    "chosenOptionIds": [
                        "d425010d-9cb9-54a7-a1a6-2c7b577896e7"
                    ]
                },
                "BP8.0": {
                    "questionId": "BP8.0",
                    "chosenOptionIds": [
                        "c7d02d20-9064-5a94-86d4-053ed361ed32"
                    ]
                },
                "BP4": {
                    "questionId": "BP4",
                    "chosenOptionIds": [
                        "f4705d21-04ac-574a-90f3-5e73c089a3ff"
                    ]
                },
                "BP69": {
                    "questionId": "BP69",
                    "chosenOptionIds": [
                        "94ec394b-cb15-50df-8a09-56fc2267a32a"
                    ]
                },
                "BP78": {
                    "questionId": "BP78",
                    "chosenOptionIds": [
                        "bf6cf52e-7867-5073-837f-205e3e78121d"
                    ]
                },
                "BP106": {
                    "questionId": "BP106",
                    "chosenOptionIds": [
                        "9658b463-86ce-5495-a40a-7361dc568105"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {
    "Dem18": "and[Dem11_bpd=1, not(isUndefined(Dem11=0))]",
    "BP1": "and[Dem11_bpd=0, not(isUndefined(BPS=0))]",
    "BP1.5": "and[Dem11_bpd=0, not(isUndefined(BPS=0))]",
    "BP2": "and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)]",
    "BP_neg1": "BP1.5=0",
    "BP3": "and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)]",
    "BP3.01": "BP3=1",
    "BP3.1": "and[not(BP3.01=0), not(BP3.01=1)]",
    "BP3.2": "and[not(BP3.01=0), not(BP3.01=1)]",
    "BP3.3": "and[not(BP3.01=0), not(BP3.01=1)]",
    "BP3.4": "and[not(BP3.01=0), not(BP3.01=1)]",
    "BP4": "and[not(BP3.01=0), not(BP3.01=1)]",
    "BP4.5": "or[BP4=0, BP4=0.33, BP4=0.66]",
    "BP5": "and[BP4=0, not(isUndefined(BP4.5=0))]",
    "BP5.1": "and[BP4=0, not(isUndefined(BP4.5=0))]",
    "BP5.2": "and[BP4=0, not(isUndefined(BP4.5=0))]",
    "BP5.3": "and[BP4=0, not(isUndefined(BP4.5=0))]",
    "BP6": "and[or[isUndefined(BP4=0), and[not(BP4=0), not(BP4=0.66)], BP4=0.33], not(isUndefined(BP4.5=0))]",
    "BP6.1": "and[or[isUndefined(BP4=0), and[not(BP4=0), not(BP4=0.66)], BP4=0.33], not(isUndefined(BP4.5=0))]",
    "BP6.2": "and[or[isUndefined(BP4=0), and[not(BP4=0), not(BP4=0.66)], BP4=0.33], not(isUndefined(BP4.5=0))]",
    "BP6.3": "and[or[isUndefined(BP4=0), and[not(BP4=0), not(BP4=0.66)], BP4=0.33], not(isUndefined(BP4.5=0))]",
    "BP6.5": "and[BP4=0.66, not(isUndefined(BP4.5=0))]",
    "BP6.7": "and[BP4=0.66, not(isUndefined(BP4.5=0))]",
    "BP6.8": "and[BP4=0.66, not(isUndefined(BP4.5=0))]",
    "BP6.9": "and[BP4=0.66, not(isUndefined(BP4.5=0))]",
    "BP7": "and[not(BP4=0), not(BP4=0.33), not(BP4=0.66)]",
    "BP7.1": "and[not(BP4=0), not(BP4=0.33), not(BP4=0.66)]",
    "BP7.2": "and[not(BP4=0), not(BP4=0.33), not(BP4=0.66)]",
    "BP7.3": "and[not(BP4=0), not(BP4=0.33), not(BP4=0.66)]",
    "BP8": "and[not(BP3.01=0), not(BP3.01=1)]",
    "BP8.01": "and[not(BP3.01=0), not(BP3.01=1)]",
    "BP8.02": "and[not(BP3.01=0), not(BP3.01=1)]",
    "BP8.0": "and[not(BP3.01=0), not(BP3.01=1)]",
    "BP8.1": "BP8.0=1",
    "BP8.2": "BP8.1=0",
    "BP8.3": "BP8.0=1",
    "BP8.4": "BP8.0=1",
    "BP12": "or[BP3.01=0, BP3.01=1, not(BP3=1)]",
    "BP12.5": "BP12<1",
    "BP13": "and[BP12=0, not(isUndefined(BP12.5=0))]",
    "BP13.1": "and[BP12=0, not(isUndefined(BP12.5=0))]",
    "BP13.2": "and[BP12=0, not(isUndefined(BP12.5=0))]",
    "BP13.3": "and[BP12=0, not(isUndefined(BP12.5=0))]",
    "BP14": "and[BP12=0.33, not(isUndefined(BP12.5=0))]",
    "BP14.1": "and[BP12=0.33, not(isUndefined(BP12.5=0))]",
    "BP14.2": "and[BP12=0.33, not(isUndefined(BP12.5=0))]",
    "BP14.3": "and[BP12=0.33, not(isUndefined(BP12.5=0))]",
    "BP14.5": "and[or[isUndefined(BP12=0), and[not(BP12=0), not(BP12=0.33)]], not(isUndefined(BP12.5=0))]",
    "BP14.6": "and[or[isUndefined(BP12=0), and[not(BP12=0), not(BP12=0.33)]], not(isUndefined(BP12.5=0))]",
    "BP14.7": "and[or[isUndefined(BP12=0), and[not(BP12=0), not(BP12=0.33)]], not(isUndefined(BP12.5=0))]",
    "BP14.8": "and[or[isUndefined(BP12=0), and[not(BP12=0), not(BP12=0.33)]], not(isUndefined(BP12.5=0))]",
    "BP15": "not(BP12<1)",
    "BP15.1": "not(BP12<1)",
    "BP15.2": "not(BP12<1)",
    "BP15.3": "not(BP12<1)",
    "BP16": "or[BP3.01=0, BP3.01=1, not(BP3=1)]",
    "BP16.01": "or[BP3.01=0, BP3.01=1, not(BP3=1)]",
    "BP16.02": "or[BP3.01=0, BP3.01=1, not(BP3=1)]",
    "BP16.0": "or[BP3.01=0, BP3.01=1, not(BP3=1)]",
    "BP16.1": "BP16.0=1",
    "BP16.2": "BP16.1=0",
    "BP16.3": "not(BP16.1=0)",
    "BP16.4": "BP16.0=1",
    "BPS2": "and[or[isUndefined(Dem11_bpd=0), not(Dem11_bpd=0)], not(isUndefined(BPS=0))]",
    "BP67": "and[Dem18<1, not(isUndefined(BPS2=0))]",
    "BP68": "and[Dem18<1, not(isUndefined(BPS2=0))]",
    "BP68.1": "and[or[isUndefined(BP67=0), and[not(BP67=0), not(BP67=1)]], not(isUndefined(BP68=0))]",
    "BP68.2": "and[or[isUndefined(BP67=0), and[not(BP67=0), not(BP67=1)]], not(isUndefined(BP68=0))]",
    "BP68.3": "and[or[isUndefined(BP67=0), and[not(BP67=0), not(BP67=1)]], not(isUndefined(BP68=0))]",
    "BP68.4": "and[or[isUndefined(BP67=0), and[not(BP67=0), not(BP67=1)]], not(isUndefined(BP68=0))]",
    "BP69": "and[or[isUndefined(BP67=0), and[not(BP67=0), not(BP67=1)]], not(isUndefined(BP68=0))]",
    "BP69.5": "BP69<1",
    "BP70": "and[BP69=0, not(isUndefined(BP69.5=0))]",
    "BP70.1": "and[BP69=0, not(isUndefined(BP69.5=0))]",
    "BP70.2": "and[BP69=0, not(isUndefined(BP69.5=0))]",
    "BP70.3": "and[BP69=0, not(isUndefined(BP69.5=0))]",
    "BP71": "and[or[isUndefined(BP69=0), and[not(BP69=0), not(BP69=0.66)], BP69=0.33], not(isUndefined(BP69.5=0))]",
    "BP71.1": "and[or[isUndefined(BP69=0), and[not(BP69=0), not(BP69=0.66)], BP69=0.33], not(isUndefined(BP69.5=0))]",
    "BP71.2": "and[or[isUndefined(BP69=0), and[not(BP69=0), not(BP69=0.66)], BP69=0.33], not(isUndefined(BP69.5=0))]",
    "BP71.3": "and[or[isUndefined(BP69=0), and[not(BP69=0), not(BP69=0.66)], BP69=0.33], not(isUndefined(BP69.5=0))]",
    "BP71.5": "and[BP69=0.66, not(isUndefined(BP69.5=0))]",
    "BP71.6": "and[BP69=0.66, not(isUndefined(BP69.5=0))]",
    "BP71.7": "and[BP69=0.66, not(isUndefined(BP69.5=0))]",
    "BP71.8": "and[BP69=0.66, not(isUndefined(BP69.5=0))]",
    "BP72": "not(BP69<1)",
    "BP72.1": "not(BP69<1)",
    "BP72.2": "not(BP69<1)",
    "BP72.3": "not(BP69<1)",
    "BP73": "and[or[isUndefined(BP67=0), and[not(BP67=0), not(BP67=1)]], not(isUndefined(BP68=0))]",
    "BP73.01": "and[or[isUndefined(BP67=0), and[not(BP67=0), not(BP67=1)]], not(isUndefined(BP68=0))]",
    "BP73.02": "and[or[isUndefined(BP67=0), and[not(BP67=0), not(BP67=1)]], not(isUndefined(BP68=0))]",
    "BP74": "and[or[isUndefined(BP67=0), and[not(BP67=0), not(BP67=1)]], not(isUndefined(BP68=0))]",
    "BP74.1": "BP74=1",
    "BP74.2": "BP74.1=0",
    "BP74.3": "not(BP74.1=0)",
    "BP74.4": "BP74=1",
    "BP9": "or[and[not(BP8.0=1), BP4=0], and[not(BP8.0=1), BP4=0.66], and[or[BP4=0, BP4=0.66], not(isUndefined(BP8.4=0))], and[not(BP74=1), BP69=0], and[not(BP74=1), BP69=0.66], and[or[BP69=0, BP69=0.66], not(isUndefined(BP74.4=0))]]",
    "BP10": "or[and[BP4=0.33, or[not(BP8.0=1), not(isUndefined(BP8.4=0))]], and[not(BP74=1), BP69=0.33], and[BP69=0.33, not(isUndefined(BP74.4=0))]]",
    "BP11": "or[and[or[isUndefined(BP4=0), and[not(BP4=0), not(BP4=0.33), not(BP4=0.66)]], or[not(BP8.0=1), not(isUndefined(BP8.4=0))]], and[or[isUndefined(BP69=0), and[not(BP69=0), not(BP69=0.33), not(BP69=0.66)]], not(BP74=1)], and[or[isUndefined(BP69=0), and[not(BP69=0), not(BP69=0.33), not(BP69=0.66)]], not(isUndefined(BP74.4=0))]]",
    "BP78": "and[or[BP67=0, BP67=1], not(isUndefined(BP68=0))]",
    "BP78.5": "BP78<1",
    "BP79": "and[BP78=0, not(isUndefined(BP78.5=0))]",
    "BP79.1": "and[BP78=0, not(isUndefined(BP78.5=0))]",
    "BP79.2": "and[BP78=0, not(isUndefined(BP78.5=0))]",
    "BP79.3": "and[BP78=0, not(isUndefined(BP78.5=0))]",
    "BP80": "and[or[isUndefined(BP78=0), and[not(BP78=0), not(BP78=0.66)], BP78=0.33], not(isUndefined(BP78.5=0))]",
    "BP80.1": "and[or[isUndefined(BP78=0), and[not(BP78=0), not(BP78=0.66)], BP78=0.33], not(isUndefined(BP78.5=0))]",
    "BP80.2": "and[or[isUndefined(BP78=0), and[not(BP78=0), not(BP78=0.66)], BP78=0.33], not(isUndefined(BP78.5=0))]",
    "BP80.3": "and[or[isUndefined(BP78=0), and[not(BP78=0), not(BP78=0.66)], BP78=0.33], not(isUndefined(BP78.5=0))]",
    "BP80.5": "and[BP78=0.66, not(isUndefined(BP78.5=0))]",
    "BP80.6": "and[BP78=0.66, not(isUndefined(BP78.5=0))]",
    "BP80.7": "and[BP78=0.66, not(isUndefined(BP78.5=0))]",
    "BP80.8": "and[BP78=0.66, not(isUndefined(BP78.5=0))]",
    "BP81": "not(BP78<1)",
    "BP81.1": "not(BP78<1)",
    "BP81.2": "not(BP78<1)",
    "BP81.3": "not(BP78<1)",
    "BP82": "and[or[BP67=0, BP67=1], not(isUndefined(BP68=0))]",
    "BP82.01": "and[or[BP67=0, BP67=1], not(isUndefined(BP68=0))]",
    "BP82.02": "and[or[BP67=0, BP67=1], not(isUndefined(BP68=0))]",
    "BP83": "and[or[BP67=0, BP67=1], not(isUndefined(BP68=0))]",
    "BP83.1": "BP83=1",
    "BP83.2": "BP83=1",
    "BP17": "or[and[not(BP16.0=1), BP12=0], and[not(BP16.0=1), BP12=0.66], and[or[BP12=0, BP12=0.66], not(isUndefined(BP16.4=0))], and[not(BP83=1), BP78=0], and[not(BP83=1), BP78=0.66], and[or[BP78=0, BP78=0.66], not(isUndefined(BP83.2=0))]]",
    "BP18": "or[and[BP12=0.33, or[not(BP16.0=1), not(isUndefined(BP16.4=0))]], and[not(BP83=1), BP78=0.33], and[BP78=0.33, not(isUndefined(BP83.2=0))]]",
    "BP19": "or[and[or[isUndefined(BP12=0), and[not(BP12=0), not(BP12=0.33), not(BP12=0.66)]], or[not(BP16.0=1), not(isUndefined(BP16.4=0))]], and[or[isUndefined(BP78=0), and[not(BP78=0), not(BP78=0.33), not(BP78=0.66)]], not(BP83=1)], and[or[isUndefined(BP78=0), and[not(BP78=0), not(BP78=0.33), not(BP78=0.66)]], not(isUndefined(BP83.2=0))]]",
    "BP87b": "BP1.5=0",
    "BP88b": "BP1.5=0",
    "BP89b": "BP1.5=0",
    "BP90b": "BP1.5=0",
    "BP92b": "BP1.5=0",
    "BP94b": "BP1.5=0",
    "BP95b": "BP1.5=0",
    "BP96b": "BP1.5=0",
    "BP97b": "BP1.5=0",
    "BP98b": "BP1.5=0",
    "BP99b": "BP1.5=0",
    "BP99.1b": "and[or[isUndefined(BP99b_no=1), not(BP99b_no=1)], BP99b_excessive=1, not(isUndefined(BP99b=0))]",
    "BP99.2b": "and[BP99b_dangerous=1, or[and[or[isUndefined(BP99b_no=1), not(BP99b_no=1)], or[isUndefined(BP99b_excessive=1), not(BP99b_excessive=1)], not(isUndefined(BP99b=0))], not(isUndefined(BP99.1b=0))]]",
    "BP99.3b": "BP1.5=0",
    "BP101b": "BP1.5=0",
    "BP102b": "BP1.5=0",
    "BP103b": "BP1.5=0",
    "BP104b": "BP1.5=0",
    "BP105b": "and[or[isUndefined(and[BP103b=0, BP104b=0]), not(and[BP103b=0, BP104b=0])], or[and[BP103b=1, BP104b=0], and[BP103b=0, BP104b=1]]]",
    "BP106b": "and[or[isUndefined(and[BP103b=0, BP104b=0]), not(and[BP103b=0, BP104b=0])], or[and[BP103b=1, BP104b=0], and[BP103b=0, BP104b=1], and[or[isUndefined(and[BP103b=1, BP104b=0]), not(and[BP103b=1, BP104b=0])], or[isUndefined(and[BP103b=0, BP104b=1]), not(and[BP103b=0, BP104b=1])], not(isUndefined(BP104b=0))]]]",
    "BP106": "or[and[not(BP1.5=0), not(and[BP1.5>=0.33, BP1.5<1])], and[or[isUndefined(Dem18<1), not(Dem18<1)], not(isUndefined(BPS2=0))]]",
    "BP108": "or[BP106=0, BP106=0.33, BP106=0.66]",
    "BP110": "or[and[not(BP1.5=0), not(and[BP1.5>=0.33, BP1.5<1])], and[or[isUndefined(Dem18<1), not(Dem18<1)], not(isUndefined(BPS2=0))]]",
    "BP110.01": "or[and[not(BP1.5=0), not(and[BP1.5>=0.33, BP1.5<1])], and[or[isUndefined(Dem18<1), not(Dem18<1)], not(isUndefined(BPS2=0))]]",
    "BP110.1": "or[and[not(BP1.5=0), not(and[BP1.5>=0.33, BP1.5<1])], and[or[isUndefined(Dem18<1), not(Dem18<1)], not(isUndefined(BPS2=0))]]",
    "BP110.2": "or[and[not(BP1.5=0), not(and[BP1.5>=0.33, BP1.5<1])], and[or[isUndefined(Dem18<1), not(Dem18<1)], not(isUndefined(BPS2=0))]]",
    "BP111": "or[and[not(BP1.5=0), not(and[BP1.5>=0.33, BP1.5<1])], and[or[isUndefined(Dem18<1), not(Dem18<1)], not(isUndefined(BPS2=0))]]",
    "BP111.1": "or[and[not(BP1.5=0), not(and[BP1.5>=0.33, BP1.5<1])], and[or[isUndefined(Dem18<1), not(Dem18<1)], not(isUndefined(BPS2=0))]]",
    "BP111.2": "or[and[not(BP1.5=0), not(and[BP1.5>=0.33, BP1.5<1])], and[or[isUndefined(Dem18<1), not(Dem18<1)], not(isUndefined(BPS2=0))]]",
    "BP112": "or[and[not(BP1.5=0), not(and[BP1.5>=0.33, BP1.5<1])], and[or[isUndefined(Dem18<1), not(Dem18<1)], not(isUndefined(BPS2=0))]]",
    "BP112.1": "not(BP112=0)",
    "BP112.11": "and[not(BP112.1=0), not(BP112.1=1)]",
    "BP112.12": "and[not(BP112.1=0), not(BP112.1=1)]",
    "BP112.13": "and[not(BP112.1=0), not(BP112.1=1)]",
    "BP112.14": "and[not(BP112.1=0), not(BP112.1=1)]",
    "BP112.2": "BP112.1=1",
    "BP112.3": "and[not(BP112.1=0), not(BP112.1=1)]",
    "BP112.4": "BP112.1=0",
    "BP112.41": "and[not(BP112.4=0), not(BP112.4=1)]",
    "BP112.42": "and[not(BP112.4=0), not(BP112.4=1)]",
    "BP112.43": "and[not(BP112.4=0), not(BP112.4=1)]",
    "BP112.44": "and[not(BP112.4=0), not(BP112.4=1)]",
    "BP112.5": "BP112.4=1",
    "BP112.6": "and[not(BP112.4=0), not(BP112.4=1)]",
    "BP113": "or[and[not(BP1.5=0), not(and[BP1.5>=0.33, BP1.5<1])], and[or[isUndefined(Dem18<1), not(Dem18<1)], not(isUndefined(BPS2=0))]]",
    "BP113.1": "BP113=1",
    "BP113.2": "BP113.1=0",
    "BP113.3": "not(BP113.1=0)",
    "BP113.4": "BP113=1",
    "BP114.1": "and[or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]], or[not(BP113=1), not(isUndefined(BP113.4=0))]]",
    "BP114.2": "and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0, or[not(BP113=1), not(isUndefined(BP113.4=0))]]",
    "BP114.3": "and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]], or[not(BP113=1), not(isUndefined(BP113.4=0))]]",
    "BP114.4": "and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0, or[not(BP113=1), not(isUndefined(BP113.4=0))]]",
    "BP87": "or[and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)], and[Dem18<1, not(isUndefined(BPS2=0))], and[not(BP113=1), or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]]], and[or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0, not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]]], and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0, not(isUndefined(BP113.4=0))]]",
    "BP88": "or[and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)], and[Dem18<1, not(isUndefined(BPS2=0))], and[not(BP113=1), or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]]], and[or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0, not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]]], and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0, not(isUndefined(BP113.4=0))]]",
    "BP89": "or[and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)], and[Dem18<1, not(isUndefined(BPS2=0))], and[not(BP113=1), or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]]], and[or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0, not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]]], and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0, not(isUndefined(BP113.4=0))]]",
    "BP90": "or[and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)], and[Dem18<1, not(isUndefined(BPS2=0))], and[not(BP113=1), or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]]], and[or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0, not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]]], and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0, not(isUndefined(BP113.4=0))]]",
    "BP90.1": "or[and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)], and[Dem18<1, not(isUndefined(BPS2=0))], and[not(BP113=1), or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]]], and[or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0, not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]]], and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0, not(isUndefined(BP113.4=0))]]",
    "BP91": "or[and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)], and[Dem18<1, not(isUndefined(BPS2=0))], and[not(BP113=1), or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]]], and[or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0, not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]]], and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0, not(isUndefined(BP113.4=0))]]",
    "BP91.1": "or[and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)], and[Dem18<1, not(isUndefined(BPS2=0))], and[not(BP113=1), or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]]], and[or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0, not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]]], and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0, not(isUndefined(BP113.4=0))]]",
    "BP92": "or[and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)], and[Dem18<1, not(isUndefined(BPS2=0))], and[not(BP113=1), or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]]], and[or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0, not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]]], and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0, not(isUndefined(BP113.4=0))]]",
    "BP93": "or[and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)], and[Dem18<1, not(isUndefined(BPS2=0))], and[not(BP113=1), or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]]], and[or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0, not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]]], and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0, not(isUndefined(BP113.4=0))]]",
    "BP94": "or[and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)], and[Dem18<1, not(isUndefined(BPS2=0))], and[not(BP113=1), or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]]], and[or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0, not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]]], and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0, not(isUndefined(BP113.4=0))]]",
    "BP95": "or[and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)], and[Dem18<1, not(isUndefined(BPS2=0))], and[not(BP113=1), or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]]], and[or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0, not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]]], and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0, not(isUndefined(BP113.4=0))]]",
    "BP96": "or[and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)], and[Dem18<1, not(isUndefined(BPS2=0))], and[not(BP113=1), or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]]], and[or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0, not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]]], and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0, not(isUndefined(BP113.4=0))]]",
    "BP97": "or[and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)], and[Dem18<1, not(isUndefined(BPS2=0))], and[not(BP113=1), or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]]], and[or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0, not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]]], and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0, not(isUndefined(BP113.4=0))]]",
    "BP98": "or[and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)], and[Dem18<1, not(isUndefined(BPS2=0))], and[not(BP113=1), or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]]], and[or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0, not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]]], and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0, not(isUndefined(BP113.4=0))]]",
    "BP99": "or[and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)], and[Dem18<1, not(isUndefined(BPS2=0))], and[not(BP113=1), or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]]], and[or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0, not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]]], and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0, not(isUndefined(BP113.4=0))]]",
    "BP99.1": "and[or[isUndefined(BP99_no=1), not(BP99_no=1)], BP99_excessive=1, not(isUndefined(BP99=0))]",
    "BP99.2": "and[BP99_dangerous=1, or[and[or[isUndefined(BP99_no=1), not(BP99_no=1)], or[isUndefined(BP99_excessive=1), not(BP99_excessive=1)], not(isUndefined(BP99=0))], not(isUndefined(BP99.1=0))]]",
    "BP99.3": "or[and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)], and[Dem18<1, not(isUndefined(BPS2=0))], and[not(BP113=1), or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]]], and[or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0, not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]]], and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0, not(isUndefined(BP113.4=0))]]",
    "BP100": "or[and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)], and[Dem18<1, not(isUndefined(BPS2=0))], and[not(BP113=1), or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]]], and[or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0, not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]]], and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0, not(isUndefined(BP113.4=0))]]",
    "BP101": "or[and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)], and[Dem18<1, not(isUndefined(BPS2=0))], and[not(BP113=1), or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]]], and[or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0, not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]]], and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0, not(isUndefined(BP113.4=0))]]",
    "BP102": "or[and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)], and[Dem18<1, not(isUndefined(BPS2=0))], and[not(BP113=1), or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]]], and[or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0, not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]]], and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0, not(isUndefined(BP113.4=0))]]",
    "BP103": "or[and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)], and[Dem18<1, not(isUndefined(BPS2=0))], and[not(BP113=1), or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]]], and[or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0, not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]]], and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0, not(isUndefined(BP113.4=0))]]",
    "BP104": "or[and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)], and[Dem18<1, not(isUndefined(BPS2=0))], and[not(BP113=1), or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]]], and[or[and[BP112.3=0, BP110>=0.6], and[BP112.1=1, BP110<0.6]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], BP112.2=0, not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]]], and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[and[BP112.4=1, BP110<0.4], and[BP112.6=0, BP110>=0.4]], not(isUndefined(BP113.4=0))], and[not(BP113=1), or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0], and[or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], BP112.5=0, not(isUndefined(BP113.4=0))]]",
    "BP105": "and[or[isUndefined(and[BP103=0, BP104=0]), not(and[BP103=0, BP104=0])], or[and[BP103=1, BP104=0], and[BP103=0, BP104=1], and[BP103=1, BP104=1]]]",
    "BP114": "and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], or[isUndefined(and[BP112.6=0, BP110>=0.4]), not(and[BP112.6=0, BP110>=0.4])], or[isUndefined(BP112.5=0), not(BP112.5=0)], or[not(BP113=1), not(isUndefined(BP113.4=0))]]",
    "BP115": "and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], or[isUndefined(and[BP112.6=0, BP110>=0.4]), not(and[BP112.6=0, BP110>=0.4])], or[isUndefined(BP112.5=0), not(BP112.5=0)], or[not(BP113=1), not(isUndefined(BP113.4=0))]]",
    "BP116": "and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], or[isUndefined(and[BP112.6=0, BP110>=0.4]), not(and[BP112.6=0, BP110>=0.4])], or[isUndefined(BP112.5=0), not(BP112.5=0)], or[not(BP113=1), not(isUndefined(BP113.4=0))]]",
    "BP117": "and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], or[isUndefined(and[BP112.6=0, BP110>=0.4]), not(and[BP112.6=0, BP110>=0.4])], or[isUndefined(BP112.5=0), not(BP112.5=0)], or[not(BP113=1), not(isUndefined(BP113.4=0))]]",
    "BP118": "and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], or[isUndefined(and[BP112.6=0, BP110>=0.4]), not(and[BP112.6=0, BP110>=0.4])], or[isUndefined(BP112.5=0), not(BP112.5=0)], or[not(BP113=1), not(isUndefined(BP113.4=0))]]",
    "BP118.1": "and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], or[isUndefined(and[BP112.6=0, BP110>=0.4]), not(and[BP112.6=0, BP110>=0.4])], or[isUndefined(BP112.5=0), not(BP112.5=0)], or[not(BP113=1), not(isUndefined(BP113.4=0))]]",
    "BP119": "and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], or[isUndefined(and[BP112.6=0, BP110>=0.4]), not(and[BP112.6=0, BP110>=0.4])], or[isUndefined(BP112.5=0), not(BP112.5=0)], or[not(BP113=1), not(isUndefined(BP113.4=0))]]",
    "BP119.1": "and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], or[isUndefined(and[BP112.6=0, BP110>=0.4]), not(and[BP112.6=0, BP110>=0.4])], or[isUndefined(BP112.5=0), not(BP112.5=0)], or[not(BP113=1), not(isUndefined(BP113.4=0))]]",
    "BP120": "and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], or[isUndefined(and[BP112.6=0, BP110>=0.4]), not(and[BP112.6=0, BP110>=0.4])], or[isUndefined(BP112.5=0), not(BP112.5=0)], or[not(BP113=1), not(isUndefined(BP113.4=0))]]",
    "BP121": "and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], or[isUndefined(and[BP112.6=0, BP110>=0.4]), not(and[BP112.6=0, BP110>=0.4])], or[isUndefined(BP112.5=0), not(BP112.5=0)], or[not(BP113=1), not(isUndefined(BP113.4=0))]]",
    "BP122": "and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], or[isUndefined(and[BP112.6=0, BP110>=0.4]), not(and[BP112.6=0, BP110>=0.4])], or[isUndefined(BP112.5=0), not(BP112.5=0)], or[not(BP113=1), not(isUndefined(BP113.4=0))]]",
    "BP123": "and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], or[isUndefined(and[BP112.6=0, BP110>=0.4]), not(and[BP112.6=0, BP110>=0.4])], or[isUndefined(BP112.5=0), not(BP112.5=0)], or[not(BP113=1), not(isUndefined(BP113.4=0))]]",
    "BP124": "and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], or[isUndefined(and[BP112.6=0, BP110>=0.4]), not(and[BP112.6=0, BP110>=0.4])], or[isUndefined(BP112.5=0), not(BP112.5=0)], or[not(BP113=1), not(isUndefined(BP113.4=0))]]",
    "BP125": "and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], or[isUndefined(and[BP112.6=0, BP110>=0.4]), not(and[BP112.6=0, BP110>=0.4])], or[isUndefined(BP112.5=0), not(BP112.5=0)], or[not(BP113=1), not(isUndefined(BP113.4=0))]]",
    "BP126": "and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], or[isUndefined(and[BP112.6=0, BP110>=0.4]), not(and[BP112.6=0, BP110>=0.4])], or[isUndefined(BP112.5=0), not(BP112.5=0)], or[not(BP113=1), not(isUndefined(BP113.4=0))]]",
    "BP127": "and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], or[isUndefined(and[BP112.6=0, BP110>=0.4]), not(and[BP112.6=0, BP110>=0.4])], or[isUndefined(BP112.5=0), not(BP112.5=0)], or[not(BP113=1), not(isUndefined(BP113.4=0))]]",
    "BP127.1": "and[or[isUndefined(BP127_no=1), not(BP127_no=1)], BP127_excessive=1, not(isUndefined(BP127=0))]",
    "BP127.2": "and[BP127_dangerous=1, or[and[or[isUndefined(BP127_no=1), not(BP127_no=1)], or[isUndefined(BP127_excessive=1), not(BP127_excessive=1)], not(isUndefined(BP127=0))], not(isUndefined(BP127.1=0))]]",
    "BP127.3": "and[or[isUndefined(BP127_no=1), not(BP127_no=1)], not(isUndefined(BP127=0)), or[and[or[isUndefined(BP127_excessive=1), not(BP127_excessive=1)], or[isUndefined(BP127_dangerous=1), not(isUndefined(BP127_dangerous=0))]], BP127_excessive=1]]",
    "BP128": "and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], or[isUndefined(and[BP112.6=0, BP110>=0.4]), not(and[BP112.6=0, BP110>=0.4])], or[isUndefined(BP112.5=0), not(BP112.5=0)], or[not(BP113=1), not(isUndefined(BP113.4=0))]]",
    "BP129": "and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], or[isUndefined(and[BP112.6=0, BP110>=0.4]), not(and[BP112.6=0, BP110>=0.4])], or[isUndefined(BP112.5=0), not(BP112.5=0)], or[not(BP113=1), not(isUndefined(BP113.4=0))]]",
    "BP130": "and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], or[isUndefined(and[BP112.6=0, BP110>=0.4]), not(and[BP112.6=0, BP110>=0.4])], or[isUndefined(BP112.5=0), not(BP112.5=0)], or[not(BP113=1), not(isUndefined(BP113.4=0))]]",
    "BP131": "and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], or[isUndefined(and[BP112.6=0, BP110>=0.4]), not(and[BP112.6=0, BP110>=0.4])], or[isUndefined(BP112.5=0), not(BP112.5=0)], or[not(BP113=1), not(isUndefined(BP113.4=0))]]",
    "BP132": "and[or[isUndefined(and[BP112.3=0, BP110>=0.6]), not(and[BP112.3=0, BP110>=0.6])], or[isUndefined(and[BP112.1=1, BP110<0.6]), not(and[BP112.1=1, BP110<0.6])], or[isUndefined(BP112.2=0), not(BP112.2=0)], or[isUndefined(and[BP112.4=1, BP110<0.4]), not(and[BP112.4=1, BP110<0.4])], or[isUndefined(and[BP112.6=0, BP110>=0.4]), not(and[BP112.6=0, BP110>=0.4])], or[isUndefined(BP112.5=0), not(BP112.5=0)], or[not(BP113=1), not(isUndefined(BP113.4=0))]]",
    "BP133": "and[or[isUndefined(and[BP131=0, BP132=0]), not(and[BP131=0, BP132=0])], not(isUndefined(BP132=0))]",
    "HMS": "or[and[BP1.5>=0.33, BP1.5<1, not(BP1.5=0)], and[or[isUndefined(Dem11_bpd=0), not(Dem11_bpd=0)], not(isUndefined(BPS=0))], and[not(BP1.5=0), not(and[BP1.5>=0.33, BP1.5<1])]]",
    "HM1": "and[BP9=1, not(isUndefined(HMS=0))]",
    "HM2": "and[BP10=1, not(isUndefined(HMS=0))]",
    "HM3": "and[BP11=1, not(isUndefined(HMS=0))]",
    "HM4": "and[BP114.1=1, not(isUndefined(HMS=0))]",
    "HM5": "and[BP114.3=1, not(isUndefined(HMS=0))]",
    "HM6": "and[BP114.2=1, not(isUndefined(HMS=0))]",
    "HM7": "and[or[isUndefined(BP9=1), not(BP9=1)], or[isUndefined(BP10=1), not(BP10=1)], or[isUndefined(BP11=1), not(BP11=1)], or[isUndefined(BP17=1), not(BP17=1)], or[isUndefined(BP18=1), not(BP18=1)], or[isUndefined(BP19=1), not(BP19=1)], or[isUndefined(BP114=1), not(BP114=1)], or[isUndefined(BP114.1=1), not(BP114.1=1)], or[isUndefined(BP114.3=1), not(BP114.3=1)], or[isUndefined(BP114.2=1), not(BP114.2=1)], not(isUndefined(HMS=0))]",
    "HM18": "and[BP17=1, not(isUndefined(HMS=0))]",
    "HM19": "and[BP18=1, not(isUndefined(HMS=0))]",
    "HM20": "and[BP19=1, not(isUndefined(HMS=0))]",
    "HM21": "and[not(isUndefined(HMS=0)), or[and[or[isUndefined(BP9=1), not(BP9=1)], or[and[or[isUndefined(BP10=1), not(BP10=1)], or[and[or[isUndefined(BP11=1), not(BP11=1)], or[and[or[isUndefined(BP17=1), not(BP17=1)], or[and[or[isUndefined(BP18=1), not(BP18=1)], or[and[or[isUndefined(BP19=1), not(BP19=1)], or[isUndefined(BP114=1), not(BP114=1)], or[and[or[isUndefined(BP114.1=1), not(BP114.1=1)], or[and[or[isUndefined(BP114.3=1), not(BP114.3=1)], or[isUndefined(BP114.2=1), not(isUndefined(BP114.2=0))]], BP114.3=1]], BP114.1=1]], BP19=1]], BP18=1]], BP17=1]], BP11=1]], BP10=1]], BP9=1]]",
    "HM22": "and[not(isUndefined(HMS=0)), or[and[or[isUndefined(BP9=1), not(BP9=1)], or[and[or[isUndefined(BP10=1), not(BP10=1)], or[and[or[isUndefined(BP11=1), not(BP11=1)], or[and[or[isUndefined(BP17=1), not(BP17=1)], or[and[or[isUndefined(BP18=1), not(BP18=1)], or[and[or[isUndefined(BP19=1), not(BP19=1)], or[isUndefined(BP114=1), not(BP114=1)], or[and[or[isUndefined(BP114.1=1), not(BP114.1=1)], or[and[or[isUndefined(BP114.3=1), not(BP114.3=1)], or[isUndefined(BP114.2=1), not(isUndefined(BP114.2=0))]], BP114.3=1]], BP114.1=1]], BP19=1]], BP18=1]], BP17=1]], BP11=1]], BP10=1]], BP9=1]]",
    "HM23": "and[not(isUndefined(HMS=0)), or[and[or[isUndefined(BP9=1), not(BP9=1)], or[and[or[isUndefined(BP10=1), not(BP10=1)], or[and[or[isUndefined(BP11=1), not(BP11=1)], or[and[or[isUndefined(BP17=1), not(BP17=1)], or[and[or[isUndefined(BP18=1), not(BP18=1)], or[and[or[isUndefined(BP19=1), not(BP19=1)], or[isUndefined(BP114=1), not(BP114=1)], or[and[or[isUndefined(BP114.1=1), not(BP114.1=1)], or[and[or[isUndefined(BP114.3=1), not(BP114.3=1)], or[isUndefined(BP114.2=1), not(isUndefined(BP114.2=0))]], BP114.3=1]], BP114.1=1]], BP19=1]], BP18=1]], BP17=1]], BP11=1]], BP10=1]], BP9=1]]",
    "HM25": "and[not(isUndefined(HMS=0)), or[and[or[isUndefined(BP9=1), not(BP9=1)], or[and[or[isUndefined(BP10=1), not(BP10=1)], or[and[or[isUndefined(BP11=1), not(BP11=1)], or[and[or[isUndefined(BP17=1), not(BP17=1)], or[and[or[isUndefined(BP18=1), not(BP18=1)], or[and[or[isUndefined(BP19=1), not(BP19=1)], or[isUndefined(BP114=1), not(BP114=1)], or[and[or[isUndefined(BP114.1=1), not(BP114.1=1)], or[and[or[isUndefined(BP114.3=1), not(BP114.3=1)], or[isUndefined(BP114.2=1), not(isUndefined(BP114.2=0))]], BP114.3=1]], BP114.1=1]], BP19=1]], BP18=1]], BP17=1]], BP11=1]], BP10=1]], BP9=1]]",
    "HM26": "and[not(isUndefined(HMS=0)), or[and[or[isUndefined(BP9=1), not(BP9=1)], or[and[or[isUndefined(BP10=1), not(BP10=1)], or[and[or[isUndefined(BP11=1), not(BP11=1)], or[and[or[isUndefined(BP17=1), not(BP17=1)], or[and[or[isUndefined(BP18=1), not(BP18=1)], or[and[or[isUndefined(BP19=1), not(BP19=1)], or[isUndefined(BP114=1), not(BP114=1)], or[and[or[isUndefined(BP114.1=1), not(BP114.1=1)], or[and[or[isUndefined(BP114.3=1), not(BP114.3=1)], or[isUndefined(BP114.2=1), not(isUndefined(BP114.2=0))]], BP114.3=1]], BP114.1=1]], BP19=1]], BP18=1]], BP17=1]], BP11=1]], BP10=1]], BP9=1]]",
    "HM28": "and[not(isUndefined(HMS=0)), or[and[or[isUndefined(BP9=1), not(BP9=1)], or[and[or[isUndefined(BP10=1), not(BP10=1)], or[and[or[isUndefined(BP11=1), not(BP11=1)], or[and[or[isUndefined(BP17=1), not(BP17=1)], or[and[or[isUndefined(BP18=1), not(BP18=1)], or[and[or[isUndefined(BP19=1), not(BP19=1)], or[isUndefined(BP114=1), not(BP114=1)], or[and[or[isUndefined(BP114.1=1), not(BP114.1=1)], or[and[or[isUndefined(BP114.3=1), not(BP114.3=1)], or[isUndefined(BP114.2=1), not(isUndefined(BP114.2=0))]], BP114.3=1]], BP114.1=1]], BP19=1]], BP18=1]], BP17=1]], BP11=1]], BP10=1]], BP9=1]]",
    "HM29": "and[not(isUndefined(HMS=0)), or[and[or[isUndefined(BP9=1), not(BP9=1)], or[and[or[isUndefined(BP10=1), not(BP10=1)], or[and[or[isUndefined(BP11=1), not(BP11=1)], or[and[or[isUndefined(BP17=1), not(BP17=1)], or[and[or[isUndefined(BP18=1), not(BP18=1)], or[and[or[isUndefined(BP19=1), not(BP19=1)], or[isUndefined(BP114=1), not(BP114=1)], or[and[or[isUndefined(BP114.1=1), not(BP114.1=1)], or[and[or[isUndefined(BP114.3=1), not(BP114.3=1)], or[isUndefined(BP114.2=1), not(isUndefined(BP114.2=0))]], BP114.3=1]], BP114.1=1]], BP19=1]], BP18=1]], BP17=1]], BP11=1]], BP10=1]], BP9=1]]",
    "HM30": "and[not(isUndefined(HMS=0)), or[and[or[isUndefined(BP9=1), not(BP9=1)], or[and[or[isUndefined(BP10=1), not(BP10=1)], or[and[or[isUndefined(BP11=1), not(BP11=1)], or[and[or[isUndefined(BP17=1), not(BP17=1)], or[and[or[isUndefined(BP18=1), not(BP18=1)], or[and[or[isUndefined(BP19=1), not(BP19=1)], or[isUndefined(BP114=1), not(BP114=1)], or[and[or[isUndefined(BP114.1=1), not(BP114.1=1)], or[and[or[isUndefined(BP114.3=1), not(BP114.3=1)], or[isUndefined(BP114.2=1), not(isUndefined(BP114.2=0))]], BP114.3=1]], BP114.1=1]], BP19=1]], BP18=1]], BP17=1]], BP11=1]], BP10=1]], BP9=1]]",
    "HM31": "and[not(isUndefined(HMS=0)), or[and[or[isUndefined(BP9=1), not(BP9=1)], or[and[or[isUndefined(BP10=1), not(BP10=1)], or[and[or[isUndefined(BP11=1), not(BP11=1)], or[and[or[isUndefined(BP17=1), not(BP17=1)], or[and[or[isUndefined(BP18=1), not(BP18=1)], or[and[or[isUndefined(BP19=1), not(BP19=1)], or[isUndefined(BP114=1), not(BP114=1)], or[and[or[isUndefined(BP114.1=1), not(BP114.1=1)], or[and[or[isUndefined(BP114.3=1), not(BP114.3=1)], or[isUndefined(BP114.2=1), not(isUndefined(BP114.2=0))]], BP114.3=1]], BP114.1=1]], BP19=1]], BP18=1]], BP17=1]], BP11=1]], BP10=1]], BP9=1]]",
    "HM32": "and[not(isUndefined(HMS=0)), or[and[or[isUndefined(BP9=1), not(BP9=1)], or[and[or[isUndefined(BP10=1), not(BP10=1)], or[and[or[isUndefined(BP11=1), not(BP11=1)], or[and[or[isUndefined(BP17=1), not(BP17=1)], or[and[or[isUndefined(BP18=1), not(BP18=1)], or[and[or[isUndefined(BP19=1), not(BP19=1)], or[isUndefined(BP114=1), not(BP114=1)], or[and[or[isUndefined(BP114.1=1), not(BP114.1=1)], or[and[or[isUndefined(BP114.3=1), not(BP114.3=1)], or[isUndefined(BP114.2=1), not(isUndefined(BP114.2=0))]], BP114.3=1]], BP114.1=1]], BP19=1]], BP18=1]], BP17=1]], BP11=1]], BP10=1]], BP9=1]]",
    "HM33": "and[not(isUndefined(HMS=0)), or[and[or[isUndefined(BP9=1), not(BP9=1)], or[and[or[isUndefined(BP10=1), not(BP10=1)], or[and[or[isUndefined(BP11=1), not(BP11=1)], or[and[or[isUndefined(BP17=1), not(BP17=1)], or[and[or[isUndefined(BP18=1), not(BP18=1)], or[and[or[isUndefined(BP19=1), not(BP19=1)], or[isUndefined(BP114=1), not(BP114=1)], or[and[or[isUndefined(BP114.1=1), not(BP114.1=1)], or[and[or[isUndefined(BP114.3=1), not(BP114.3=1)], or[isUndefined(BP114.2=1), not(isUndefined(BP114.2=0))]], BP114.3=1]], BP114.1=1]], BP19=1]], BP18=1]], BP17=1]], BP11=1]], BP10=1]], BP9=1]]",
    "HM34": "and[not(isUndefined(HMS=0)), or[and[or[isUndefined(BP9=1), not(BP9=1)], or[and[or[isUndefined(BP10=1), not(BP10=1)], or[and[or[isUndefined(BP11=1), not(BP11=1)], or[and[or[isUndefined(BP17=1), not(BP17=1)], or[and[or[isUndefined(BP18=1), not(BP18=1)], or[and[or[isUndefined(BP19=1), not(BP19=1)], or[isUndefined(BP114=1), not(BP114=1)], or[and[or[isUndefined(BP114.1=1), not(BP114.1=1)], or[and[or[isUndefined(BP114.3=1), not(BP114.3=1)], or[isUndefined(BP114.2=1), not(isUndefined(BP114.2=0))]], BP114.3=1]], BP114.1=1]], BP19=1]], BP18=1]], BP17=1]], BP11=1]], BP10=1]], BP9=1]]",
    "HM35": "and[BP114=1, not(isUndefined(HMS=0))]",
    "HM36": "and[BP114=1, not(isUndefined(HMS=0))]",
    "HM37": "and[BP114=1, not(isUndefined(HMS=0))]",
    "HM38": "and[BP114=1, not(isUndefined(HMS=0))]",
    "HM40": "and[BP114=1, not(isUndefined(HMS=0))]",
    "HM41": "and[BP114=1, not(isUndefined(HMS=0))]",
    "HM43": "and[BP114=1, not(isUndefined(HMS=0))]",
    "HM44": "and[BP114=1, not(isUndefined(HMS=0))]",
    "HM45": "and[BP114=1, not(isUndefined(HMS=0))]",
    "HM46": "and[BP114=1, not(isUndefined(HMS=0))]",
    "HM47": "and[BP114=1, not(isUndefined(HMS=0))]",
    "HM48": "and[BP114=1, not(isUndefined(HMS=0))]",
    "HM49": "and[BP114=1, not(isUndefined(HMS=0))]"
};
