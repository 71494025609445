import React, { useContext } from 'react'
import * as Sentry from '@sentry/react'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { CheckInData, StandardCheckInData, checkInQuery, demoCheckInQuery } from 'data/queries/checkIn'
import { Splash } from '../Splash'
import { ISessionCardProps } from '@psyomics/components/components/SessionCard/SessionCard'
import { AssessmentCompleteSent } from './AssessmentCompleteSent'
import { AssessmentCompleteView } from './AssessmentCompleteView'
import { SessionSummary } from './SessionSummary'
import { OrganisationContext } from 'ui/app/context/organisation'
import { useAuth } from 'registration/context/auth'
import { ReferralDetailsContext } from 'ui/app/context/referralDetails'
import CenseoError from '../Error'
import { NoAssessmentsInProgress } from './NoAssessmentsInProgress'
import { AnsweredQuestion, DemoType } from 'censeo-core'
import DemoEndScreen from 'registration/pages/Demo/DemoEndScreen'

const CANCELLED_STATUS = 'cancelled'

type SessionNumber = 1 | 2 | 3 | 4 | 5 | 6

type Props = {
    setQuestionId: (id: string) => void
    demo?: {
        type: DemoType
        allAnswers: AnsweredQuestion[]
    }
}

export const CheckIn: React.FC<Props> = ({ setQuestionId, demo }) => {
    console.log('d', demo)
    const {
        loading,
        data: checkInQueryData,
        error: apiError,
    } = useQuery<CheckInData, any>(demo ? demoCheckInQuery : checkInQuery, {
        fetchPolicy: 'no-cache',
        variables: { allAnswers: demo?.allAnswers, demoType: demo?.type },
    })
    const data: StandardCheckInData | undefined =
        checkInQueryData && 'checkIn' in checkInQueryData
            ? checkInQueryData
            : checkInQueryData && 'demoCheckIn' in checkInQueryData
            ? {
                  checkIn: checkInQueryData.demoCheckIn,
                  authorisedReportsForUser: [],
              }
            : undefined
    if (data && !('checkIn' in data)) {
        Sentry.captureException('no checkin data found in checking', { extra: data })
    }
    const navigate = useNavigate()
    const organisation = useContext(OrganisationContext)
    const referralDetails = useContext(ReferralDetailsContext)
    const { isD2CUser } = useAuth()
    const isDemoUser = referralDetails?.data?.organisationCode?.startsWith('demo')

    if (apiError) {
        const errMsg = `GraphQL checkInQuery request failed for referral ${referralDetails?.data?.publicId}. Response from API: ${apiError.message}`
        Sentry.captureException(errMsg)
        return <CenseoError customTitle={`Next session not found (Referral ID: ${referralDetails?.data?.publicId})`} />
    }

    if (loading || !data) {
        return <Splash />
    }

    const latestReport = data.checkIn?.latestReport
    const remainingSessionsLeft =
        Array.isArray(data.checkIn?.remainingSessions) && data.checkIn?.remainingSessions.length > 0
    const isFirstVisit =
        !latestReport && data.checkIn?.completedSessions?.length === 0 && !data.checkIn?.incompleteSession

    const completeSessionCards: ISessionCardProps[] = data.checkIn.completedSessions.map((session, index) => ({
        title: session.name,
        status: 'complete',
        sessionNumber: (index + 1) as SessionNumber,
    }))

    const startSessionWithQuestion = (questionId: string) => {
        if (demo) {
            navigate('/demo/service-user/question')
        } else {
            navigate('/assessment/question')
        }
        setQuestionId(questionId)
    }

    const incompleteSession = data.checkIn.incompleteSession
    const incompleteSessionCard: ISessionCardProps[] = incompleteSession
        ? [
              {
                  progress: incompleteSession.percentageComplete,
                  title: incompleteSession.session.name,
                  status: 'current',
                  duration: incompleteSession.session.minutesDuration,
                  sessionNumber: (completeSessionCards.length + 1) as SessionNumber,
                  onClick: () => startSessionWithQuestion(incompleteSession.nextQuestionId),
              },
          ]
        : data.checkIn.remainingSessions.length
        ? [
              {
                  title: data.checkIn.remainingSessions[0].name,
                  duration: data.checkIn.remainingSessions[0].minutesDuration,
                  status: 'current',
                  onClick: () => startSessionWithQuestion(data.checkIn.remainingSessions[0].firstQuestionId),
                  sessionNumber: (completeSessionCards.length + 1) as SessionNumber,
              },
          ]
        : []

    const remainingSessionCards: ISessionCardProps[] = data.checkIn.remainingSessions
        .slice(data.checkIn.incompleteSession ? 0 : 1)
        .map((session, index) => ({
            title: session.name,
            status: 'locked',
            duration: session.minutesDuration,
            sessionNumber: (completeSessionCards.length + incompleteSessionCard.length + 1 + index) as SessionNumber,
        }))

    const allSessionCards = [...completeSessionCards, ...incompleteSessionCard, ...remainingSessionCards]

    if (!referralDetails?.data?.status) {
        Sentry.captureMessage('No status for referral found', { extra: { referralDetails } })
    }
    if (referralDetails?.activeReferral?.status === CANCELLED_STATUS) {
        return <NoAssessmentsInProgress isFirstVisit={isFirstVisit}></NoAssessmentsInProgress>
    } else if (!latestReport || remainingSessionsLeft || incompleteSession) {
        return (
            <SessionSummary
                isDemoUser={!!isDemoUser}
                isFirstVisit={isFirstVisit}
                completeSessionCards={completeSessionCards}
                allSessionCards={allSessionCards}
            />
        )
    } else if (organisation?.demo || isD2CUser || data?.authorisedReportsForUser?.length > 0) {
        return (
            <AssessmentCompleteView latestReport={latestReport} authorisedReportsData={data.authorisedReportsForUser} />
        )
    } else if (demo) {
        return <DemoEndScreen />
    } else {
        return <AssessmentCompleteSent />
    }
}
