"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
const censeo_1 = require("../../schema/generated/censeo");
exports.questions = [
    {
        "__typename": "ContentQuestion",
        "id": "EUPD-S",
        "text": "For many people it is normal for their mood to change or fluctuate throughout the day, but for some people these mood changes may be troubling. This next set of questions will ask you about your changes in mood and how they may affect your life.",
        "answers": [
            {
                "__typename": "Option",
                "id": "8300ceda-a5ff-5d27-ab8d-2a1695aee722",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "0e1d7bfa-9457-5852-b487-4628a4c83ef1",
                "text": "No, not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "15fa7f47-7f26-595f-986d-532716789a6f",
                "text": "To a small extent",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "52fb5f3a-b30f-5f77-a2bd-0620b67a1adc",
                "text": "Somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "5efe5d1e-b865-5328-b632-d1235e4f612d",
                "text": "Very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "9c2ca9f0-e093-5ac3-8a23-494cb9298513",
                "text": "To an extreme extent",
                "value": 1
            }
        ],
        "id": "EUPD-1",
        "text": "Do you tend to experience emotions more intensely than others?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ec6f9d53-dae0-5eb6-b0a6-ae8dc7774cb5",
                "text": "No, not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d25ceaaa-5094-5581-a0df-81187f0ef77d",
                "text": "To a small extent",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "04b30646-e5ef-56c6-8e67-b80b07442d24",
                "text": "Somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "336fae53-720d-5c45-bc21-01f833ad9e22",
                "text": "Very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "44285291-8282-578e-858a-31e91b05796f",
                "text": "To an extreme extent",
                "value": 1
            }
        ],
        "id": "EUPD-2",
        "text": "During the course of a day, do you find that your mood can change from one extreme to another in just a couple of hours (e.g. from feeling OK to feeling very angry and irritated)?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "EUPD-3",
        "text": "Approximately, how long do these individual mood states usually last?",
        "unit": censeo_1.Unit.DurationMinutes
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e59ec14b-a63b-5b39-8af0-4e05190954bd",
                "text": "Stress"
            },
            {
                "__typename": "Option",
                "id": "b03a43f4-b673-5258-99e9-afa5b73c6c84",
                "text": "Lack of sleep"
            },
            {
                "__typename": "Option",
                "id": "2ac4ba8b-b73f-5790-a1d6-40f0aea5bb59",
                "text": "Interpersonal conflict or arguments"
            },
            {
                "__typename": "Option",
                "id": "56c58db7-8e4f-5774-a543-b390c23fb315",
                "text": "Feeling rejected or abandoned"
            },
            {
                "__typename": "Option",
                "id": "cd63d3bf-934e-57c7-a58c-874b91be70f0",
                "text": "It changes out of the blue"
            },
            {
                "__typename": "Option",
                "id": "6852fc6a-567a-56fc-87aa-00f50df61d57",
                "text": "I am not sure",
                "exclusive": true
            }
        ],
        "id": "EUPD-4",
        "text": "What usually makes your mood change? Select all that apply.",
        "isMultiselect": true
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a9a54f81-e19d-583d-9514-47dbe7d11969",
                "text": "No, never afraid",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2f3b0b13-2c2d-578f-9059-10e39f93d419",
                "text": "Sometimes afraid",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "ef9010e1-083b-5af9-a20d-3e9bd4bb20e2",
                "text": "Quite often afraid",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "5cff41b8-0903-5527-bc17-cd7326b077d3",
                "text": "Very often afraid",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "88234b57-8f96-5f70-9e96-516192851a59",
                "text": "Afraid all the time",
                "value": 1
            }
        ],
        "id": "EUPD-5",
        "text": "Are you often very afraid that others will abandon or leave you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "3832f310-388e-529e-b128-effddd7578f6",
                "text": "No, never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "6ee2285c-b326-5ba8-b2cb-4296da4b714d",
                "text": "Sometimes",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "a67e923e-933d-5b5c-93f9-7f7a5712bbe6",
                "text": "Quite often",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "c851a6d3-10b8-5cbb-bf8b-93473d172427",
                "text": "Very often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "f3b124a8-51fa-5ab6-9520-e1c0a1a4ce23",
                "text": "All the time",
                "value": 1
            }
        ],
        "id": "EUPD-6",
        "text": "Do you often go to extreme lengths to stop people rejecting or abandoning you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "03ee1167-bca7-500a-b80a-b5d170bfc229",
                "text": "No, never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c086bfd4-9480-5dd1-baa3-350a440d8b7e",
                "text": "Sometimes",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "a7d77d06-87b4-54f2-a7ee-6388ddc50b3e",
                "text": "Quite often",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "3e1c46ad-4ca6-56ce-9680-7b2936e513d1",
                "text": "Very often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "95c52894-0082-5afe-ba2e-117446e682c5",
                "text": "All the time",
                "value": 1
            }
        ],
        "id": "EUPD-7",
        "text": "Do you tend to get into very intense and turbulent relationships or friendships that often don't last?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "5a420be4-064f-502b-862d-a333dc354dac",
                "text": "No, not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "8d9927a8-6d9c-544d-88e1-b39adbe9c2dd",
                "text": "To a small extent",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "4910399c-7c7b-5e77-9f24-f8a248ddf28d",
                "text": "Somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "577b2e11-8447-5953-aeb7-b99ee825deb9",
                "text": "Very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "6bbed69c-d98b-5eff-98da-549cac6b89ac",
                "text": "To an extreme extent",
                "value": 1
            }
        ],
        "id": "EUPD-8",
        "text": "Does your anger level often feel out of proportion, intense and difficult to control?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a3a58218-085c-5005-bf1b-94cad8132616",
                "text": "Not any more than others",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "cbfbd155-5a25-52e3-9586-68db54b9dd39",
                "text": "Yes, to a small extent",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "e73d0b05-7b4b-5016-9e9b-f199b371c9af",
                "text": "Yes, somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "065d8ebc-76ad-5e44-8a8a-521a6d319107",
                "text": "Yes, very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "f1f68c05-871b-52f9-9891-c1eb99bc9b6f",
                "text": "Yes, to an extreme extent",
                "value": 1
            }
        ],
        "id": "EUPD-10",
        "text": "Does your image of yourself, who you are and what you believe change rapidly and often, even during the course of one day?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "33ab9f09-e55c-5e50-978c-0492d1a26f65",
                "text": "Not any more than others",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c9665e32-d743-5773-8683-57415abd4af6",
                "text": "Yes, to a small extent",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "23bece9a-88d3-5c82-8057-3015a6b79872",
                "text": "Yes, somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "9f9ba45a-2149-5fe0-96f9-38b83794dcd6",
                "text": "Yes, very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "2e7e6acf-184c-5ff5-97b9-b57549c75430",
                "text": "Yes, to an extreme extent",
                "value": 1
            }
        ],
        "id": "EUPD-11",
        "text": "Would you say you struggle with low self-esteem or a low sense of self-worth?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "9a12c5c9-c7bf-559c-89ca-ffc6db5e976a",
                "text": "Not any more than others",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "19bca353-363e-5287-84dc-f9a8692946ed",
                "text": "Yes, to a small extent",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "1c7ce652-b851-58f6-85e6-768f765fbe0f",
                "text": "Yes, somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "e7a6bf13-e4ed-5b8b-bed2-e40884e40e38",
                "text": "Yes, very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "96e3b252-6135-5811-8a14-b5cb7973f263",
                "text": "Yes, to an extreme extent",
                "value": 1
            }
        ],
        "id": "EUPD-12",
        "text": "Do you feel 'empty' or like you are 'no one' a lot of the time?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "3741395b-0563-51bd-99c4-ca335ca7c5c3",
                "text": "Not any more than others",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "9dfc160e-6d03-5394-9411-5086eb0d84f6",
                "text": "Yes, to a small extent",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "d8bd0a18-11d0-5da4-9048-512a3c027199",
                "text": "Yes, somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "0eef6dac-01b8-5acd-90a6-b8bbdc2600d6",
                "text": "Yes, very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "a8ff3134-ce03-54cd-8154-6064c567fe71",
                "text": "Yes, to an extreme extent",
                "value": 1
            }
        ],
        "id": "EUPD-13",
        "text": "Do you feel that you don't know who you are?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6b84f725-12d7-5ce7-9dcf-1483247a1a95",
                "text": "Not any more than others",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "0e690495-a758-5d5f-93fd-5fccf3304a93",
                "text": "Yes, to a small extent",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "83fa9a5c-2067-56d7-9d8f-7e8df8a9438f",
                "text": "Yes, somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "9ce96b06-685e-587c-9c34-4c80e5a1497f",
                "text": "Yes, very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "cf3fab17-0381-50f7-8f68-8de714f54af0",
                "text": "Yes, to an extreme extent",
                "value": 1
            }
        ],
        "id": "EUPD-15",
        "text": "Do you tend to act impulsively and do things that could harm you, such as binge eating or drinking, gambling, taking drugs or driving dangerously?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "157f1ca7-66b8-532a-bfab-f51f963a5cc2",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "40bf173b-27ae-5980-87bd-2e9287bea4e1",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "EUPD-15.1",
        "text": "Do you act in impulsive ways such as binge eating or drinking, gambling, taking drugs or driving dangerously to show others how you feel?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "533f77e8-7955-4569-9933-3f58f27c711e",
                "text": "No, not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "88fe8be6-0bab-4c0e-9ad8-04857d021fcf",
                "text": "Yes, somewhat",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "3d3cbdd0-4ea6-4d27-b75d-769fa987bf26",
                "text": "Yes, moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "41a45947-dfc1-4f7c-b42e-fdadc495d035",
                "text": "Yes, very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "e960a0f7-7a43-429b-93db-32dc25f55c53",
                "text": "Yes, to an extreme extent",
                "value": 1
            }
        ],
        "id": "EUPD-15.2",
        "text": "When you feel stressed, do you tend to feel suspicious of other people or spaced out or disconnected from what is going on around you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "c5325cd4-a4dd-5ae5-b59a-a1170674c53d",
                "text": "Only recently, or for just a short period of time in the past",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "234e69c8-3812-52bc-933c-e80cd2a16c2e",
                "text": "For several days",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "bf9981cb-fee0-51ca-9c5f-9c0859029b31",
                "text": "For several weeks",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "4f737e01-d8bd-52b9-83ba-266c96a8cb0f",
                "text": "For several months",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "650808a8-2e6f-5f93-aada-7e217f78687c",
                "text": "For many years",
                "value": 0.8
            },
            {
                "__typename": "Option",
                "id": "549c3369-b2fa-5346-bc86-fdeb2d56f2e9",
                "text": "I have always felt like this, or at least for as long as I can remember",
                "value": 1
            }
        ],
        "id": "EUPD-16",
        "text": "How long have you been experiencing any of the intense feelings mentioned in the previous questions (e.g. quick changes in mood, fear of abandonment, feeling intense anger, acting impulsively, experiencing changes in self-image or feeling empty)? If you can't exactly remember, please select your best estimate.",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "EUPD-17",
        "text": "Approximately, how old were you when these intense feelings started (e.g. quick changes in mood, fear of abandonment, feeling intense anger, acting impulsively, experiencing changes in self-image or feeling empty)?",
        "unit": censeo_1.Unit.Age
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b6e918f8-7528-5e71-89d3-76924c5c7872",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f0d62708-1864-5541-a81d-7817b478c4d4",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "EUPD-18",
        "text": "Since first experiencing these intense feelings and thoughts (e.g. quick changes in mood, fear of abandonment, feeling intense anger, acting impulsively, experiencing changes in self-image or feeling empty), have you ever had a period of a few months or more when they didn't bother you at all?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "f50b79c8-bd2e-5842-bb07-416baf417668",
                "text": "Basic needs such as eating and sleeping"
            },
            {
                "__typename": "Option",
                "id": "294ce365-f53c-50bf-83fb-0b347170fa9d",
                "text": "Working or studying"
            },
            {
                "__typename": "Option",
                "id": "c6cb064a-f7fd-5042-b1b2-73020e4f4160",
                "text": "Intimate relationships"
            },
            {
                "__typename": "Option",
                "id": "c9f6ab7a-f17c-5c62-86c5-b8040246221a",
                "text": "Social life"
            },
            {
                "__typename": "Option",
                "id": "92a400d7-d419-59dd-89f5-39e4e30acba9",
                "text": "Family"
            },
            {
                "__typename": "Option",
                "id": "7ed40115-acb8-5f3f-9eb4-212597b1a202",
                "text": "Leisure activities, hobbies and interests"
            },
            {
                "__typename": "Option",
                "id": "679c0cc3-6667-5084-9ab8-5ecd7538a662",
                "text": "Keeping fit and healthy"
            },
            {
                "__typename": "Option",
                "id": "523c6fce-132a-5d2f-8773-42390be65d84",
                "text": "Finances"
            },
            {
                "__typename": "Option",
                "id": "8d8acccc-449c-51b9-9711-e07c69c1acfd",
                "text": "Other aspects of my life not mentioned above"
            },
            {
                "__typename": "Option",
                "id": "e16bb2f7-89ba-452c-a7ba-307adca9adc4",
                "text": "No areas of my life are affected by these intense feelings or thoughts",
                "exclusive": true
            }
        ],
        "id": "EUPD-19",
        "text": "Which areas of your life, if any, do you feel are disrupted by these intense feelings and thoughts (e.g. quick changes in mood, fear of abandonment, feeling intense anger, acting impulsively, experiencing changes in self-image or feeling empty)? Please select all that apply.",
        "isMultiselect": true
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "3aad497e-3595-54e6-94a4-add4730ee861",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "331e1b22-ef8d-5cac-8f9c-004666f13d0a",
                "text": "To a slight extent",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "1213f822-87a0-516a-9832-682008ea5f4a",
                "text": "To a moderate extent",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "8dd94ec7-732b-55da-add7-b38553048df6",
                "text": "To a significant extent",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "1a640268-73b2-5133-ae7d-165973ac73a1",
                "text": "To an extreme extent",
                "value": 1
            }
        ],
        "id": "EUPD-19.0",
        "text": "To what extent do these intense feelings and thoughts affect these areas of your life, or require you to make additional effort to maintain functioning in those areas?",
        "isMultiselect": false
    }
];
exports.aspects = {
    "emi_ce": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "ei_screen"
                }
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "ei_abandonment"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "ei_interpersonal_instability"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "ei_identity_disturbance"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "ei_impulsivity"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "ei_suicidal_behaviour"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "ei_affective_instability"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "ei_emptiness"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "ei_inappropriate_anger"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "ei_paranoia_dissociation"
                        }
                    }
                ],
                "anyToAll": 0.5555555555555556
            }
        ],
        "anyToAll": 1
    },
    "ei_screen": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "EUPD-1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "0e1d7bfa-9457-5852-b487-4628a4c83ef1": 0.0005527786369235996,
                            "15fa7f47-7f26-595f-986d-532716789a6f": 0.0066928509242848554,
                            "52fb5f3a-b30f-5f77-a2bd-0620b67a1adc": 0.07585818002124355,
                            "5efe5d1e-b865-5328-b632-d1235e4f612d": 0.5,
                            "9c2ca9f0-e093-5ac3-8a23-494cb9298513": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "EUPD-2",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "ec6f9d53-dae0-5eb6-b0a6-ae8dc7774cb5": 0.0005527786369235996,
                            "d25ceaaa-5094-5581-a0df-81187f0ef77d": 0.0066928509242848554,
                            "04b30646-e5ef-56c6-8e67-b80b07442d24": 0.07585818002124355,
                            "336fae53-720d-5c45-bc21-01f833ad9e22": 0.5,
                            "44285291-8282-578e-858a-31e91b05796f": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "ei_abandonment": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "EUPD-5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "a9a54f81-e19d-583d-9514-47dbe7d11969": 0.0066928509242848554,
                            "2f3b0b13-2c2d-578f-9059-10e39f93d419": 0.07585818002124355,
                            "ef9010e1-083b-5af9-a20d-3e9bd4bb20e2": 0.5,
                            "5cff41b8-0903-5527-bc17-cd7326b077d3": 0.9241418199787566,
                            "88234b57-8f96-5f70-9e96-516192851a59": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "EUPD-6",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "3832f310-388e-529e-b128-effddd7578f6": 0.0005527786369235996,
                            "6ee2285c-b326-5ba8-b2cb-4296da4b714d": 0.0066928509242848554,
                            "a67e923e-933d-5b5c-93f9-7f7a5712bbe6": 0.07585818002124355,
                            "c851a6d3-10b8-5cbb-bf8b-93473d172427": 0.5,
                            "f3b124a8-51fa-5ab6-9520-e1c0a1a4ce23": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "ei_interpersonal_instability": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "EUPD-7",
            "options": {
                "type": "severities",
                "severities": {
                    "03ee1167-bca7-500a-b80a-b5d170bfc229": 0.0066928509242848554,
                    "c086bfd4-9480-5dd1-baa3-350a440d8b7e": 0.07585818002124355,
                    "a7d77d06-87b4-54f2-a7ee-6388ddc50b3e": 0.5,
                    "3e1c46ad-4ca6-56ce-9680-7b2936e513d1": 0.9241418199787566,
                    "95c52894-0082-5afe-ba2e-117446e682c5": 0.9933071490757153
                }
            }
        }
    },
    "ei_identity_disturbance": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "EUPD-10",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "a3a58218-085c-5005-bf1b-94cad8132616": 0.0066928509242848554,
                            "cbfbd155-5a25-52e3-9586-68db54b9dd39": 0.07585818002124355,
                            "e73d0b05-7b4b-5016-9e9b-f199b371c9af": 0.5,
                            "065d8ebc-76ad-5e44-8a8a-521a6d319107": 0.9241418199787566,
                            "f1f68c05-871b-52f9-9891-c1eb99bc9b6f": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "EUPD-13",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "3741395b-0563-51bd-99c4-ca335ca7c5c3": 0.0005527786369235996,
                            "9dfc160e-6d03-5394-9411-5086eb0d84f6": 0.0066928509242848554,
                            "d8bd0a18-11d0-5da4-9048-512a3c027199": 0.07585818002124355,
                            "0eef6dac-01b8-5acd-90a6-b8bbdc2600d6": 0.5,
                            "a8ff3134-ce03-54cd-8154-6064c567fe71": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "ei_impulsivity": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "EUPD-15",
            "options": {
                "type": "severities",
                "severities": {
                    "6b84f725-12d7-5ce7-9dcf-1483247a1a95": 0.0005527786369235996,
                    "0e690495-a758-5d5f-93fd-5fccf3304a93": 0.0066928509242848554,
                    "83fa9a5c-2067-56d7-9d8f-7e8df8a9438f": 0.07585818002124355,
                    "9ce96b06-685e-587c-9c34-4c80e5a1497f": 0.5,
                    "cf3fab17-0381-50f7-8f68-8de714f54af0": 0.9241418199787566
                }
            }
        }
    },
    "ei_paranoia_dissociation": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "EUPD-15.2",
            "options": {
                "type": "severities",
                "severities": {
                    "533f77e8-7955-4569-9933-3f58f27c711e": 0.0005527786369235996,
                    "88fe8be6-0bab-4c0e-9ad8-04857d021fcf": 0.0066928509242848554,
                    "3d3cbdd0-4ea6-4d27-b75d-769fa987bf26": 0.07585818002124355,
                    "41a45947-dfc1-4f7c-b42e-fdadc495d035": 0.5,
                    "e960a0f7-7a43-429b-93db-32dc25f55c53": 0.9241418199787566
                }
            }
        }
    },
    "ei_suicidal_behaviour": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_s"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_sh"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "ei_affective_instability": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "EUPD-1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "0e1d7bfa-9457-5852-b487-4628a4c83ef1": 0.0005527786369235996,
                            "15fa7f47-7f26-595f-986d-532716789a6f": 0.0066928509242848554,
                            "52fb5f3a-b30f-5f77-a2bd-0620b67a1adc": 0.07585818002124355,
                            "5efe5d1e-b865-5328-b632-d1235e4f612d": 0.5,
                            "9c2ca9f0-e093-5ac3-8a23-494cb9298513": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "EUPD-2",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "ec6f9d53-dae0-5eb6-b0a6-ae8dc7774cb5": 0.0005527786369235996,
                            "d25ceaaa-5094-5581-a0df-81187f0ef77d": 0.0066928509242848554,
                            "04b30646-e5ef-56c6-8e67-b80b07442d24": 0.07585818002124355,
                            "336fae53-720d-5c45-bc21-01f833ad9e22": 0.5,
                            "44285291-8282-578e-858a-31e91b05796f": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "ei_emptiness": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "EUPD-12",
            "options": {
                "type": "severities",
                "severities": {
                    "9a12c5c9-c7bf-559c-89ca-ffc6db5e976a": 0.0005527786369235996,
                    "19bca353-363e-5287-84dc-f9a8692946ed": 0.0066928509242848554,
                    "1c7ce652-b851-58f6-85e6-768f765fbe0f": 0.07585818002124355,
                    "e7a6bf13-e4ed-5b8b-bed2-e40884e40e38": 0.5,
                    "96e3b252-6135-5811-8a14-b5cb7973f263": 0.9241418199787566
                }
            }
        }
    },
    "ei_inappropriate_anger": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "EUPD-8",
            "options": {
                "type": "severities",
                "severities": {
                    "5a420be4-064f-502b-862d-a333dc354dac": 0.0005527786369235996,
                    "8d9927a8-6d9c-544d-88e1-b39adbe9c2dd": 0.0066928509242848554,
                    "4910399c-7c7b-5e77-9f24-f8a248ddf28d": 0.07585818002124355,
                    "577b2e11-8447-5953-aeb7-b99ee825deb9": 0.5,
                    "6bbed69c-d98b-5eff-98da-549cac6b89ac": 0.9241418199787566
                }
            }
        }
    },
    "EUPD4_stress": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "EUPD-4",
            "options": {
                "type": "severities",
                "severities": {
                    "e59ec14b-a63b-5b39-8af0-4e05190954bd": 1
                }
            }
        }
    },
    "EUPD4_sleep": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "EUPD-4",
            "options": {
                "type": "severities",
                "severities": {
                    "b03a43f4-b673-5258-99e9-afa5b73c6c84": 1
                }
            }
        }
    },
    "EUPD4_conflict": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "EUPD-4",
            "options": {
                "type": "severities",
                "severities": {
                    "2ac4ba8b-b73f-5790-a1d6-40f0aea5bb59": 1
                }
            }
        }
    },
    "EUPD4_rejection": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "EUPD-4",
            "options": {
                "type": "severities",
                "severities": {
                    "56c58db7-8e4f-5774-a543-b390c23fb315": 1
                }
            }
        }
    },
    "EUPD4_blue": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "EUPD-4",
            "options": {
                "type": "severities",
                "severities": {
                    "cd63d3bf-934e-57c7-a58c-874b91be70f0": 1
                }
            }
        }
    },
    "EUPD4_unsure": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "EUPD-4",
            "options": {
                "type": "severities",
                "severities": {
                    "6852fc6a-567a-56fc-87aa-00f50df61d57": 1
                }
            }
        }
    },
    "EUPD19_basic": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "EUPD-19",
            "options": {
                "type": "severities",
                "severities": {
                    "f50b79c8-bd2e-5842-bb07-416baf417668": 1
                }
            }
        }
    },
    "EUPD19_work": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "EUPD-19",
            "options": {
                "type": "severities",
                "severities": {
                    "294ce365-f53c-50bf-83fb-0b347170fa9d": 1
                }
            }
        }
    },
    "EUPD19_relationships": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "EUPD-19",
            "options": {
                "type": "severities",
                "severities": {
                    "c6cb064a-f7fd-5042-b1b2-73020e4f4160": 1
                }
            }
        }
    },
    "EUPD19_social": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "EUPD-19",
            "options": {
                "type": "severities",
                "severities": {
                    "c9f6ab7a-f17c-5c62-86c5-b8040246221a": 1
                }
            }
        }
    },
    "EUPD19_family": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "EUPD-19",
            "options": {
                "type": "severities",
                "severities": {
                    "92a400d7-d419-59dd-89f5-39e4e30acba9": 1
                }
            }
        }
    },
    "EUPD20_leisure": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "EUPD-19",
            "options": {
                "type": "severities",
                "severities": {
                    "7ed40115-acb8-5f3f-9eb4-212597b1a202": 1
                }
            }
        }
    },
    "EUPD20_health": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "EUPD-19",
            "options": {
                "type": "severities",
                "severities": {
                    "679c0cc3-6667-5084-9ab8-5ecd7538a662": 1
                }
            }
        }
    },
    "EUPD20_financial": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "EUPD-19",
            "options": {
                "type": "severities",
                "severities": {
                    "523c6fce-132a-5d2f-8773-42390be65d84": 1
                }
            }
        }
    },
    "EUPD20_other": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "EUPD-19",
            "options": {
                "type": "severities",
                "severities": {
                    "8d8acccc-449c-51b9-9711-e07c69c1acfd": 1
                }
            }
        }
    },
    "EUPD19_none": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "EUPD-19",
            "options": {
                "type": "severities",
                "severities": {
                    "e16bb2f7-89ba-452c-a7ba-307adca9adc4": 1
                }
            }
        }
    },
    "and[or[isUndefined(and[EUPD-1<=0.5, EUPD-2<=0.5]), not(and[EUPD-1<=0.5, EUPD-2<=0.5])], not(isUndefined(EUPD-2=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-1",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "0e1d7bfa-9457-5852-b487-4628a4c83ef1": 0.9933071490757152,
                                                "15fa7f47-7f26-595f-986d-532716789a6f": 0.9241418199787564,
                                                "52fb5f3a-b30f-5f77-a2bd-0620b67a1adc": 0.5,
                                                "5efe5d1e-b865-5328-b632-d1235e4f612d": 0.07585818002124345,
                                                "9c2ca9f0-e093-5ac3-8a23-494cb9298513": 0.006692850924284732
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-2",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "ec6f9d53-dae0-5eb6-b0a6-ae8dc7774cb5": 0.9933071490757152,
                                                "d25ceaaa-5094-5581-a0df-81187f0ef77d": 0.9241418199787564,
                                                "04b30646-e5ef-56c6-8e67-b80b07442d24": 0.5,
                                                "336fae53-720d-5c45-bc21-01f833ad9e22": 0.07585818002124345,
                                                "44285291-8282-578e-858a-31e91b05796f": 0.006692850924284732
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-1",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "0e1d7bfa-9457-5852-b487-4628a4c83ef1": 0.9933071490757152,
                                                "15fa7f47-7f26-595f-986d-532716789a6f": 0.9241418199787564,
                                                "52fb5f3a-b30f-5f77-a2bd-0620b67a1adc": 0.5,
                                                "5efe5d1e-b865-5328-b632-d1235e4f612d": 0.07585818002124345,
                                                "9c2ca9f0-e093-5ac3-8a23-494cb9298513": 0.006692850924284732
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-2",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "ec6f9d53-dae0-5eb6-b0a6-ae8dc7774cb5": 0.9933071490757152,
                                                "d25ceaaa-5094-5581-a0df-81187f0ef77d": 0.9241418199787564,
                                                "04b30646-e5ef-56c6-8e67-b80b07442d24": 0.5,
                                                "336fae53-720d-5c45-bc21-01f833ad9e22": 0.07585818002124345,
                                                "44285291-8282-578e-858a-31e91b05796f": 0.006692850924284732
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "EUPD-2",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "ec6f9d53-dae0-5eb6-b0a6-ae8dc7774cb5": true,
                                    "d25ceaaa-5094-5581-a0df-81187f0ef77d": false,
                                    "04b30646-e5ef-56c6-8e67-b80b07442d24": false,
                                    "336fae53-720d-5c45-bc21-01f833ad9e22": false,
                                    "44285291-8282-578e-858a-31e91b05796f": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[or[isUndefined(and[EUPD-1<=0.25, EUPD-5<=0.25, EUPD-6<=0.25, EUPD-7<=0.25, EUPD-8<=0.25, EUPD-10<=0.25, EUPD-11<=0.25, EUPD-12<=0.25, EUPD-13<=0.25, EUPD-15<=0.25]), not(and[EUPD-1<=0.25, EUPD-5<=0.25, EUPD-6<=0.25, EUPD-7<=0.25, EUPD-8<=0.25, EUPD-10<=0.25, EUPD-11<=0.25, EUPD-12<=0.25, EUPD-13<=0.25, EUPD-15<=0.25])], not(EUPD-15=0)]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-1",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "0e1d7bfa-9457-5852-b487-4628a4c83ef1": 0.9241418199787564,
                                                "15fa7f47-7f26-595f-986d-532716789a6f": 0.5,
                                                "52fb5f3a-b30f-5f77-a2bd-0620b67a1adc": 0.07585818002124345,
                                                "5efe5d1e-b865-5328-b632-d1235e4f612d": 0.006692850924284732,
                                                "9c2ca9f0-e093-5ac3-8a23-494cb9298513": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-5",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "a9a54f81-e19d-583d-9514-47dbe7d11969": 0.9241418199787564,
                                                "2f3b0b13-2c2d-578f-9059-10e39f93d419": 0.5,
                                                "ef9010e1-083b-5af9-a20d-3e9bd4bb20e2": 0.07585818002124345,
                                                "5cff41b8-0903-5527-bc17-cd7326b077d3": 0.006692850924284732,
                                                "88234b57-8f96-5f70-9e96-516192851a59": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-6",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "3832f310-388e-529e-b128-effddd7578f6": 0.9241418199787564,
                                                "6ee2285c-b326-5ba8-b2cb-4296da4b714d": 0.5,
                                                "a67e923e-933d-5b5c-93f9-7f7a5712bbe6": 0.07585818002124345,
                                                "c851a6d3-10b8-5cbb-bf8b-93473d172427": 0.006692850924284732,
                                                "f3b124a8-51fa-5ab6-9520-e1c0a1a4ce23": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-7",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "03ee1167-bca7-500a-b80a-b5d170bfc229": 0.9241418199787564,
                                                "c086bfd4-9480-5dd1-baa3-350a440d8b7e": 0.5,
                                                "a7d77d06-87b4-54f2-a7ee-6388ddc50b3e": 0.07585818002124345,
                                                "3e1c46ad-4ca6-56ce-9680-7b2936e513d1": 0.006692850924284732,
                                                "95c52894-0082-5afe-ba2e-117446e682c5": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-8",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "5a420be4-064f-502b-862d-a333dc354dac": 0.9241418199787564,
                                                "8d9927a8-6d9c-544d-88e1-b39adbe9c2dd": 0.5,
                                                "4910399c-7c7b-5e77-9f24-f8a248ddf28d": 0.07585818002124345,
                                                "577b2e11-8447-5953-aeb7-b99ee825deb9": 0.006692850924284732,
                                                "6bbed69c-d98b-5eff-98da-549cac6b89ac": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-10",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "a3a58218-085c-5005-bf1b-94cad8132616": 0.9241418199787564,
                                                "cbfbd155-5a25-52e3-9586-68db54b9dd39": 0.5,
                                                "e73d0b05-7b4b-5016-9e9b-f199b371c9af": 0.07585818002124345,
                                                "065d8ebc-76ad-5e44-8a8a-521a6d319107": 0.006692850924284732,
                                                "f1f68c05-871b-52f9-9891-c1eb99bc9b6f": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-11",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "33ab9f09-e55c-5e50-978c-0492d1a26f65": 0.9241418199787564,
                                                "c9665e32-d743-5773-8683-57415abd4af6": 0.5,
                                                "23bece9a-88d3-5c82-8057-3015a6b79872": 0.07585818002124345,
                                                "9f9ba45a-2149-5fe0-96f9-38b83794dcd6": 0.006692850924284732,
                                                "2e7e6acf-184c-5ff5-97b9-b57549c75430": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-12",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "9a12c5c9-c7bf-559c-89ca-ffc6db5e976a": 0.9241418199787564,
                                                "19bca353-363e-5287-84dc-f9a8692946ed": 0.5,
                                                "1c7ce652-b851-58f6-85e6-768f765fbe0f": 0.07585818002124345,
                                                "e7a6bf13-e4ed-5b8b-bed2-e40884e40e38": 0.006692850924284732,
                                                "96e3b252-6135-5811-8a14-b5cb7973f263": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-13",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "3741395b-0563-51bd-99c4-ca335ca7c5c3": 0.9241418199787564,
                                                "9dfc160e-6d03-5394-9411-5086eb0d84f6": 0.5,
                                                "d8bd0a18-11d0-5da4-9048-512a3c027199": 0.07585818002124345,
                                                "0eef6dac-01b8-5acd-90a6-b8bbdc2600d6": 0.006692850924284732,
                                                "a8ff3134-ce03-54cd-8154-6064c567fe71": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-15",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "6b84f725-12d7-5ce7-9dcf-1483247a1a95": 0.9241418199787564,
                                                "0e690495-a758-5d5f-93fd-5fccf3304a93": 0.5,
                                                "83fa9a5c-2067-56d7-9d8f-7e8df8a9438f": 0.07585818002124345,
                                                "9ce96b06-685e-587c-9c34-4c80e5a1497f": 0.006692850924284732,
                                                "cf3fab17-0381-50f7-8f68-8de714f54af0": 0.000552778636923601
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-1",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "0e1d7bfa-9457-5852-b487-4628a4c83ef1": 0.9241418199787564,
                                                "15fa7f47-7f26-595f-986d-532716789a6f": 0.5,
                                                "52fb5f3a-b30f-5f77-a2bd-0620b67a1adc": 0.07585818002124345,
                                                "5efe5d1e-b865-5328-b632-d1235e4f612d": 0.006692850924284732,
                                                "9c2ca9f0-e093-5ac3-8a23-494cb9298513": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-5",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "a9a54f81-e19d-583d-9514-47dbe7d11969": 0.9241418199787564,
                                                "2f3b0b13-2c2d-578f-9059-10e39f93d419": 0.5,
                                                "ef9010e1-083b-5af9-a20d-3e9bd4bb20e2": 0.07585818002124345,
                                                "5cff41b8-0903-5527-bc17-cd7326b077d3": 0.006692850924284732,
                                                "88234b57-8f96-5f70-9e96-516192851a59": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-6",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "3832f310-388e-529e-b128-effddd7578f6": 0.9241418199787564,
                                                "6ee2285c-b326-5ba8-b2cb-4296da4b714d": 0.5,
                                                "a67e923e-933d-5b5c-93f9-7f7a5712bbe6": 0.07585818002124345,
                                                "c851a6d3-10b8-5cbb-bf8b-93473d172427": 0.006692850924284732,
                                                "f3b124a8-51fa-5ab6-9520-e1c0a1a4ce23": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-7",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "03ee1167-bca7-500a-b80a-b5d170bfc229": 0.9241418199787564,
                                                "c086bfd4-9480-5dd1-baa3-350a440d8b7e": 0.5,
                                                "a7d77d06-87b4-54f2-a7ee-6388ddc50b3e": 0.07585818002124345,
                                                "3e1c46ad-4ca6-56ce-9680-7b2936e513d1": 0.006692850924284732,
                                                "95c52894-0082-5afe-ba2e-117446e682c5": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-8",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "5a420be4-064f-502b-862d-a333dc354dac": 0.9241418199787564,
                                                "8d9927a8-6d9c-544d-88e1-b39adbe9c2dd": 0.5,
                                                "4910399c-7c7b-5e77-9f24-f8a248ddf28d": 0.07585818002124345,
                                                "577b2e11-8447-5953-aeb7-b99ee825deb9": 0.006692850924284732,
                                                "6bbed69c-d98b-5eff-98da-549cac6b89ac": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-10",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "a3a58218-085c-5005-bf1b-94cad8132616": 0.9241418199787564,
                                                "cbfbd155-5a25-52e3-9586-68db54b9dd39": 0.5,
                                                "e73d0b05-7b4b-5016-9e9b-f199b371c9af": 0.07585818002124345,
                                                "065d8ebc-76ad-5e44-8a8a-521a6d319107": 0.006692850924284732,
                                                "f1f68c05-871b-52f9-9891-c1eb99bc9b6f": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-11",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "33ab9f09-e55c-5e50-978c-0492d1a26f65": 0.9241418199787564,
                                                "c9665e32-d743-5773-8683-57415abd4af6": 0.5,
                                                "23bece9a-88d3-5c82-8057-3015a6b79872": 0.07585818002124345,
                                                "9f9ba45a-2149-5fe0-96f9-38b83794dcd6": 0.006692850924284732,
                                                "2e7e6acf-184c-5ff5-97b9-b57549c75430": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-12",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "9a12c5c9-c7bf-559c-89ca-ffc6db5e976a": 0.9241418199787564,
                                                "19bca353-363e-5287-84dc-f9a8692946ed": 0.5,
                                                "1c7ce652-b851-58f6-85e6-768f765fbe0f": 0.07585818002124345,
                                                "e7a6bf13-e4ed-5b8b-bed2-e40884e40e38": 0.006692850924284732,
                                                "96e3b252-6135-5811-8a14-b5cb7973f263": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-13",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "3741395b-0563-51bd-99c4-ca335ca7c5c3": 0.9241418199787564,
                                                "9dfc160e-6d03-5394-9411-5086eb0d84f6": 0.5,
                                                "d8bd0a18-11d0-5da4-9048-512a3c027199": 0.07585818002124345,
                                                "0eef6dac-01b8-5acd-90a6-b8bbdc2600d6": 0.006692850924284732,
                                                "a8ff3134-ce03-54cd-8154-6064c567fe71": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-15",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "6b84f725-12d7-5ce7-9dcf-1483247a1a95": 0.9241418199787564,
                                                "0e690495-a758-5d5f-93fd-5fccf3304a93": 0.5,
                                                "83fa9a5c-2067-56d7-9d8f-7e8df8a9438f": 0.07585818002124345,
                                                "9ce96b06-685e-587c-9c34-4c80e5a1497f": 0.006692850924284732,
                                                "cf3fab17-0381-50f7-8f68-8de714f54af0": 0.000552778636923601
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AnsweredOption",
                        "questionId": "EUPD-15",
                        "options": {
                            "type": "predicates",
                            "predicates": {
                                "6b84f725-12d7-5ce7-9dcf-1483247a1a95": true,
                                "0e690495-a758-5d5f-93fd-5fccf3304a93": false,
                                "83fa9a5c-2067-56d7-9d8f-7e8df8a9438f": false,
                                "9ce96b06-685e-587c-9c34-4c80e5a1497f": false,
                                "cf3fab17-0381-50f7-8f68-8de714f54af0": false
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[or[isUndefined(and[EUPD-1<=0.25, EUPD-5<=0.25, EUPD-6<=0.25, EUPD-7<=0.25, EUPD-8<=0.25, EUPD-10<=0.25, EUPD-11<=0.25, EUPD-12<=0.25, EUPD-13<=0.25, EUPD-15<=0.25]), not(and[EUPD-1<=0.25, EUPD-5<=0.25, EUPD-6<=0.25, EUPD-7<=0.25, EUPD-8<=0.25, EUPD-10<=0.25, EUPD-11<=0.25, EUPD-12<=0.25, EUPD-13<=0.25, EUPD-15<=0.25])], not(isUndefined(EUPD-15=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-1",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "0e1d7bfa-9457-5852-b487-4628a4c83ef1": 0.9241418199787564,
                                                "15fa7f47-7f26-595f-986d-532716789a6f": 0.5,
                                                "52fb5f3a-b30f-5f77-a2bd-0620b67a1adc": 0.07585818002124345,
                                                "5efe5d1e-b865-5328-b632-d1235e4f612d": 0.006692850924284732,
                                                "9c2ca9f0-e093-5ac3-8a23-494cb9298513": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-5",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "a9a54f81-e19d-583d-9514-47dbe7d11969": 0.9241418199787564,
                                                "2f3b0b13-2c2d-578f-9059-10e39f93d419": 0.5,
                                                "ef9010e1-083b-5af9-a20d-3e9bd4bb20e2": 0.07585818002124345,
                                                "5cff41b8-0903-5527-bc17-cd7326b077d3": 0.006692850924284732,
                                                "88234b57-8f96-5f70-9e96-516192851a59": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-6",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "3832f310-388e-529e-b128-effddd7578f6": 0.9241418199787564,
                                                "6ee2285c-b326-5ba8-b2cb-4296da4b714d": 0.5,
                                                "a67e923e-933d-5b5c-93f9-7f7a5712bbe6": 0.07585818002124345,
                                                "c851a6d3-10b8-5cbb-bf8b-93473d172427": 0.006692850924284732,
                                                "f3b124a8-51fa-5ab6-9520-e1c0a1a4ce23": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-7",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "03ee1167-bca7-500a-b80a-b5d170bfc229": 0.9241418199787564,
                                                "c086bfd4-9480-5dd1-baa3-350a440d8b7e": 0.5,
                                                "a7d77d06-87b4-54f2-a7ee-6388ddc50b3e": 0.07585818002124345,
                                                "3e1c46ad-4ca6-56ce-9680-7b2936e513d1": 0.006692850924284732,
                                                "95c52894-0082-5afe-ba2e-117446e682c5": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-8",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "5a420be4-064f-502b-862d-a333dc354dac": 0.9241418199787564,
                                                "8d9927a8-6d9c-544d-88e1-b39adbe9c2dd": 0.5,
                                                "4910399c-7c7b-5e77-9f24-f8a248ddf28d": 0.07585818002124345,
                                                "577b2e11-8447-5953-aeb7-b99ee825deb9": 0.006692850924284732,
                                                "6bbed69c-d98b-5eff-98da-549cac6b89ac": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-10",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "a3a58218-085c-5005-bf1b-94cad8132616": 0.9241418199787564,
                                                "cbfbd155-5a25-52e3-9586-68db54b9dd39": 0.5,
                                                "e73d0b05-7b4b-5016-9e9b-f199b371c9af": 0.07585818002124345,
                                                "065d8ebc-76ad-5e44-8a8a-521a6d319107": 0.006692850924284732,
                                                "f1f68c05-871b-52f9-9891-c1eb99bc9b6f": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-11",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "33ab9f09-e55c-5e50-978c-0492d1a26f65": 0.9241418199787564,
                                                "c9665e32-d743-5773-8683-57415abd4af6": 0.5,
                                                "23bece9a-88d3-5c82-8057-3015a6b79872": 0.07585818002124345,
                                                "9f9ba45a-2149-5fe0-96f9-38b83794dcd6": 0.006692850924284732,
                                                "2e7e6acf-184c-5ff5-97b9-b57549c75430": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-12",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "9a12c5c9-c7bf-559c-89ca-ffc6db5e976a": 0.9241418199787564,
                                                "19bca353-363e-5287-84dc-f9a8692946ed": 0.5,
                                                "1c7ce652-b851-58f6-85e6-768f765fbe0f": 0.07585818002124345,
                                                "e7a6bf13-e4ed-5b8b-bed2-e40884e40e38": 0.006692850924284732,
                                                "96e3b252-6135-5811-8a14-b5cb7973f263": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-13",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "3741395b-0563-51bd-99c4-ca335ca7c5c3": 0.9241418199787564,
                                                "9dfc160e-6d03-5394-9411-5086eb0d84f6": 0.5,
                                                "d8bd0a18-11d0-5da4-9048-512a3c027199": 0.07585818002124345,
                                                "0eef6dac-01b8-5acd-90a6-b8bbdc2600d6": 0.006692850924284732,
                                                "a8ff3134-ce03-54cd-8154-6064c567fe71": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-15",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "6b84f725-12d7-5ce7-9dcf-1483247a1a95": 0.9241418199787564,
                                                "0e690495-a758-5d5f-93fd-5fccf3304a93": 0.5,
                                                "83fa9a5c-2067-56d7-9d8f-7e8df8a9438f": 0.07585818002124345,
                                                "9ce96b06-685e-587c-9c34-4c80e5a1497f": 0.006692850924284732,
                                                "cf3fab17-0381-50f7-8f68-8de714f54af0": 0.000552778636923601
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-1",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "0e1d7bfa-9457-5852-b487-4628a4c83ef1": 0.9241418199787564,
                                                "15fa7f47-7f26-595f-986d-532716789a6f": 0.5,
                                                "52fb5f3a-b30f-5f77-a2bd-0620b67a1adc": 0.07585818002124345,
                                                "5efe5d1e-b865-5328-b632-d1235e4f612d": 0.006692850924284732,
                                                "9c2ca9f0-e093-5ac3-8a23-494cb9298513": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-5",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "a9a54f81-e19d-583d-9514-47dbe7d11969": 0.9241418199787564,
                                                "2f3b0b13-2c2d-578f-9059-10e39f93d419": 0.5,
                                                "ef9010e1-083b-5af9-a20d-3e9bd4bb20e2": 0.07585818002124345,
                                                "5cff41b8-0903-5527-bc17-cd7326b077d3": 0.006692850924284732,
                                                "88234b57-8f96-5f70-9e96-516192851a59": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-6",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "3832f310-388e-529e-b128-effddd7578f6": 0.9241418199787564,
                                                "6ee2285c-b326-5ba8-b2cb-4296da4b714d": 0.5,
                                                "a67e923e-933d-5b5c-93f9-7f7a5712bbe6": 0.07585818002124345,
                                                "c851a6d3-10b8-5cbb-bf8b-93473d172427": 0.006692850924284732,
                                                "f3b124a8-51fa-5ab6-9520-e1c0a1a4ce23": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-7",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "03ee1167-bca7-500a-b80a-b5d170bfc229": 0.9241418199787564,
                                                "c086bfd4-9480-5dd1-baa3-350a440d8b7e": 0.5,
                                                "a7d77d06-87b4-54f2-a7ee-6388ddc50b3e": 0.07585818002124345,
                                                "3e1c46ad-4ca6-56ce-9680-7b2936e513d1": 0.006692850924284732,
                                                "95c52894-0082-5afe-ba2e-117446e682c5": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-8",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "5a420be4-064f-502b-862d-a333dc354dac": 0.9241418199787564,
                                                "8d9927a8-6d9c-544d-88e1-b39adbe9c2dd": 0.5,
                                                "4910399c-7c7b-5e77-9f24-f8a248ddf28d": 0.07585818002124345,
                                                "577b2e11-8447-5953-aeb7-b99ee825deb9": 0.006692850924284732,
                                                "6bbed69c-d98b-5eff-98da-549cac6b89ac": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-10",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "a3a58218-085c-5005-bf1b-94cad8132616": 0.9241418199787564,
                                                "cbfbd155-5a25-52e3-9586-68db54b9dd39": 0.5,
                                                "e73d0b05-7b4b-5016-9e9b-f199b371c9af": 0.07585818002124345,
                                                "065d8ebc-76ad-5e44-8a8a-521a6d319107": 0.006692850924284732,
                                                "f1f68c05-871b-52f9-9891-c1eb99bc9b6f": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-11",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "33ab9f09-e55c-5e50-978c-0492d1a26f65": 0.9241418199787564,
                                                "c9665e32-d743-5773-8683-57415abd4af6": 0.5,
                                                "23bece9a-88d3-5c82-8057-3015a6b79872": 0.07585818002124345,
                                                "9f9ba45a-2149-5fe0-96f9-38b83794dcd6": 0.006692850924284732,
                                                "2e7e6acf-184c-5ff5-97b9-b57549c75430": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-12",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "9a12c5c9-c7bf-559c-89ca-ffc6db5e976a": 0.9241418199787564,
                                                "19bca353-363e-5287-84dc-f9a8692946ed": 0.5,
                                                "1c7ce652-b851-58f6-85e6-768f765fbe0f": 0.07585818002124345,
                                                "e7a6bf13-e4ed-5b8b-bed2-e40884e40e38": 0.006692850924284732,
                                                "96e3b252-6135-5811-8a14-b5cb7973f263": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-13",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "3741395b-0563-51bd-99c4-ca335ca7c5c3": 0.9241418199787564,
                                                "9dfc160e-6d03-5394-9411-5086eb0d84f6": 0.5,
                                                "d8bd0a18-11d0-5da4-9048-512a3c027199": 0.07585818002124345,
                                                "0eef6dac-01b8-5acd-90a6-b8bbdc2600d6": 0.006692850924284732,
                                                "a8ff3134-ce03-54cd-8154-6064c567fe71": 0.000552778636923601
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "EUPD-15",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "6b84f725-12d7-5ce7-9dcf-1483247a1a95": 0.9241418199787564,
                                                "0e690495-a758-5d5f-93fd-5fccf3304a93": 0.5,
                                                "83fa9a5c-2067-56d7-9d8f-7e8df8a9438f": 0.07585818002124345,
                                                "9ce96b06-685e-587c-9c34-4c80e5a1497f": 0.006692850924284732,
                                                "cf3fab17-0381-50f7-8f68-8de714f54af0": 0.000552778636923601
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "EUPD-15",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "6b84f725-12d7-5ce7-9dcf-1483247a1a95": true,
                                    "0e690495-a758-5d5f-93fd-5fccf3304a93": false,
                                    "83fa9a5c-2067-56d7-9d8f-7e8df8a9438f": false,
                                    "9ce96b06-685e-587c-9c34-4c80e5a1497f": false,
                                    "cf3fab17-0381-50f7-8f68-8de714f54af0": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "and[or[isUndefined(EUPD19_none=1), not(EUPD19_none=1)], not(isUndefined(EUPD-19=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "EUPD19_none"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "EUPD19_none"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "EUPD-19",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "f50b79c8-bd2e-5842-bb07-416baf417668": false,
                                    "294ce365-f53c-50bf-83fb-0b347170fa9d": false,
                                    "c6cb064a-f7fd-5042-b1b2-73020e4f4160": false,
                                    "c9f6ab7a-f17c-5c62-86c5-b8040246221a": false,
                                    "92a400d7-d419-59dd-89f5-39e4e30acba9": false,
                                    "7ed40115-acb8-5f3f-9eb4-212597b1a202": false,
                                    "679c0cc3-6667-5084-9ab8-5ecd7538a662": false,
                                    "523c6fce-132a-5d2f-8773-42390be65d84": false,
                                    "8d8acccc-449c-51b9-9711-e07c69c1acfd": false,
                                    "e16bb2f7-89ba-452c-a7ba-307adca9adc4": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    }
};
exports.tests = {
    "emi_ce": [
        {
            "name": "screened user: NEL6L3V aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "15fa7f47-7f26-595f-986d-532716789a6f"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "d25ceaaa-5094-5581-a0df-81187f0ef77d"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YX60R aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "52fb5f3a-b30f-5f77-a2bd-0620b67a1adc"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "04b30646-e5ef-56c6-8e67-b80b07442d24"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6LM0L aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "52fb5f3a-b30f-5f77-a2bd-0620b67a1adc"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "04b30646-e5ef-56c6-8e67-b80b07442d24"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: K39ML0L aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "0e1d7bfa-9457-5852-b487-4628a4c83ef1"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "d25ceaaa-5094-5581-a0df-81187f0ef77d"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGQQ0P aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "15fa7f47-7f26-595f-986d-532716789a6f"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "d25ceaaa-5094-5581-a0df-81187f0ef77d"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y329ME8 aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "15fa7f47-7f26-595f-986d-532716789a6f"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "04b30646-e5ef-56c6-8e67-b80b07442d24"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "8e2bdd16-a97a-5130-8f6e-edb8dd52afe3"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "81b3f713-07b4-5d30-8066-60c1326c2aa1"
                    ]
                },
                "S-1a": {
                    "questionId": "S-1a",
                    "freeValue": 42
                },
                "S-2.5a": {
                    "questionId": "S-2.5a",
                    "chosenOptionIds": [
                        "455f8af3-f57a-5fe0-808e-aad6d2439855"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "9bce9d08-d129-5989-8b7b-5612590dc430"
                    ]
                },
                "S-2a": {
                    "questionId": "S-2a",
                    "chosenOptionIds": [
                        "541369ff-8077-58e5-8bf7-90a8a94d14bb"
                    ]
                },
                "S-3a": {
                    "questionId": "S-3a",
                    "freeValue": 7
                },
                "S-4a": {
                    "questionId": "S-4a",
                    "chosenOptionIds": [
                        "5627de59-f614-56c2-887e-4c153fb61012"
                    ]
                },
                "S-5a": {
                    "questionId": "S-5a",
                    "chosenOptionIds": [
                        "86839467-8345-58f0-9355-a14ba91c7079"
                    ]
                },
                "S-6a": {
                    "questionId": "S-6a",
                    "chosenOptionIds": [
                        "c0db0893-6906-5a21-ab74-144c85c0683d"
                    ]
                },
                "S-8a": {
                    "questionId": "S-8a",
                    "chosenOptionIds": [
                        "4f14f047-a4d6-5709-95b7-4742bec76fa7"
                    ]
                },
                "S-9.5a": {
                    "questionId": "S-9.5a",
                    "chosenOptionIds": [
                        "9e611ece-c2d6-5900-8832-3cbf939f729d"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "981f1af5-5769-5932-8778-58a6e01aa624"
                    ]
                },
                "SH-s2": {
                    "questionId": "SH-s2",
                    "freeValue": 17
                },
                "SH-s3": {
                    "questionId": "SH-s3",
                    "chosenOptionIds": [
                        "29309292-72fb-5781-ae9a-973977aac3af"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VZ63L aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "15fa7f47-7f26-595f-986d-532716789a6f"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "ec6f9d53-dae0-5eb6-b0a6-ae8dc7774cb5"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "8e2bdd16-a97a-5130-8f6e-edb8dd52afe3"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "e8bbf23a-7af1-5ec5-a666-e81bbeed6043"
                    ]
                },
                "S-1a": {
                    "questionId": "S-1a",
                    "freeValue": 3
                },
                "S-2.5a": {
                    "questionId": "S-2.5a",
                    "chosenOptionIds": [
                        "455f8af3-f57a-5fe0-808e-aad6d2439855"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "f18f48b0-a401-5787-bb00-ca8d99523304"
                    ]
                },
                "S-2a": {
                    "questionId": "S-2a",
                    "chosenOptionIds": [
                        "51f2de30-69cd-5a83-85a4-2e3310861a6f"
                    ]
                },
                "S-3a": {
                    "questionId": "S-3a",
                    "freeValue": 1
                },
                "S-4a": {
                    "questionId": "S-4a",
                    "chosenOptionIds": [
                        "1ffa1a8c-d1e3-54ab-9551-deb6e599c67f"
                    ]
                },
                "S-5a": {
                    "questionId": "S-5a",
                    "chosenOptionIds": [
                        "0e07d0fe-f085-5440-b0f4-a5c8345833d3"
                    ]
                },
                "S-6a": {
                    "questionId": "S-6a",
                    "chosenOptionIds": [
                        "c6b26b20-786e-537b-89bc-71c4c3155678"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "981f1af5-5769-5932-8778-58a6e01aa624"
                    ]
                },
                "SH-s2": {
                    "questionId": "SH-s2",
                    "freeValue": 29
                },
                "SH-s3": {
                    "questionId": "SH-s3",
                    "chosenOptionIds": [
                        "e2e1bae1-c85a-5ab1-9b76-10e31f65a40e"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZJN0R aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "15fa7f47-7f26-595f-986d-532716789a6f"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "d25ceaaa-5094-5581-a0df-81187f0ef77d"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: R042O34 aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "52fb5f3a-b30f-5f77-a2bd-0620b67a1adc"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "d25ceaaa-5094-5581-a0df-81187f0ef77d"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-13.0a": {
                    "questionId": "S-13.0a",
                    "chosenOptionIds": [
                        "35639af8-738a-5ba6-bf47-d0fa52511234"
                    ]
                },
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "fadcf643-dd8c-5f24-90d5-f971cf3c6ec7"
                    ]
                },
                "S-14a": {
                    "questionId": "S-14a",
                    "freeValue": 9490
                },
                "S-15a": {
                    "questionId": "S-15a",
                    "freeValue": 1
                },
                "S-17a": {
                    "questionId": "S-17a",
                    "chosenOptionIds": [
                        "7bd78121-d1a4-5bd7-8be2-eefa7e976604"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "e8bbf23a-7af1-5ec5-a666-e81bbeed6043"
                    ]
                },
                "S-1a": {
                    "questionId": "S-1a",
                    "freeValue": 3650
                },
                "S-2.5a": {
                    "questionId": "S-2.5a",
                    "chosenOptionIds": [
                        "455f8af3-f57a-5fe0-808e-aad6d2439855"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "9bce9d08-d129-5989-8b7b-5612590dc430"
                    ]
                },
                "S-2a": {
                    "questionId": "S-2a",
                    "chosenOptionIds": [
                        "51f2de30-69cd-5a83-85a4-2e3310861a6f"
                    ]
                },
                "S-3a": {
                    "questionId": "S-3a",
                    "freeValue": 20
                },
                "S-4a": {
                    "questionId": "S-4a",
                    "chosenOptionIds": [
                        "1ffa1a8c-d1e3-54ab-9551-deb6e599c67f"
                    ]
                },
                "S-5a": {
                    "questionId": "S-5a",
                    "chosenOptionIds": [
                        "0e07d0fe-f085-5440-b0f4-a5c8345833d3"
                    ]
                },
                "S-6a": {
                    "questionId": "S-6a",
                    "chosenOptionIds": [
                        "c6b26b20-786e-537b-89bc-71c4c3155678"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NN50Q aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "0e1d7bfa-9457-5852-b487-4628a4c83ef1"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "d25ceaaa-5094-5581-a0df-81187f0ef77d"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N4J3Q aspect: emi_ce should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "9c2ca9f0-e093-5ac3-8a23-494cb9298513"
                    ]
                },
                "EUPD-10": {
                    "questionId": "EUPD-10",
                    "chosenOptionIds": [
                        "f1f68c05-871b-52f9-9891-c1eb99bc9b6f"
                    ]
                },
                "EUPD-11": {
                    "questionId": "EUPD-11",
                    "chosenOptionIds": [
                        "2e7e6acf-184c-5ff5-97b9-b57549c75430"
                    ]
                },
                "EUPD-12": {
                    "questionId": "EUPD-12",
                    "chosenOptionIds": [
                        "96e3b252-6135-5811-8a14-b5cb7973f263"
                    ]
                },
                "EUPD-13": {
                    "questionId": "EUPD-13",
                    "chosenOptionIds": [
                        "a8ff3134-ce03-54cd-8154-6064c567fe71"
                    ]
                },
                "EUPD-15": {
                    "questionId": "EUPD-15",
                    "chosenOptionIds": [
                        "9ce96b06-685e-587c-9c34-4c80e5a1497f"
                    ]
                },
                "EUPD-15.1": {
                    "questionId": "EUPD-15.1",
                    "chosenOptionIds": [
                        "157f1ca7-66b8-532a-bfab-f51f963a5cc2"
                    ]
                },
                "EUPD-16": {
                    "questionId": "EUPD-16",
                    "chosenOptionIds": [
                        "650808a8-2e6f-5f93-aada-7e217f78687c"
                    ]
                },
                "EUPD-17": {
                    "questionId": "EUPD-17",
                    "freeValue": 22
                },
                "EUPD-18": {
                    "questionId": "EUPD-18",
                    "chosenOptionIds": [
                        "b6e918f8-7528-5e71-89d3-76924c5c7872"
                    ]
                },
                "EUPD-19": {
                    "questionId": "EUPD-19",
                    "chosenOptionIds": [
                        "f50b79c8-bd2e-5842-bb07-416baf417668",
                        "294ce365-f53c-50bf-83fb-0b347170fa9d",
                        "c9f6ab7a-f17c-5c62-86c5-b8040246221a",
                        "92a400d7-d419-59dd-89f5-39e4e30acba9",
                        "7ed40115-acb8-5f3f-9eb4-212597b1a202",
                        "523c6fce-132a-5d2f-8773-42390be65d84"
                    ]
                },
                "EUPD-19.0": {
                    "questionId": "EUPD-19.0",
                    "chosenOptionIds": [
                        "8dd94ec7-732b-55da-add7-b38553048df6"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "44285291-8282-578e-858a-31e91b05796f"
                    ]
                },
                "EUPD-3": {
                    "questionId": "EUPD-3",
                    "freeValue": 0.041666666666666664
                },
                "EUPD-4": {
                    "questionId": "EUPD-4",
                    "chosenOptionIds": [
                        "e59ec14b-a63b-5b39-8af0-4e05190954bd",
                        "b03a43f4-b673-5258-99e9-afa5b73c6c84",
                        "2ac4ba8b-b73f-5790-a1d6-40f0aea5bb59",
                        "56c58db7-8e4f-5774-a543-b390c23fb315",
                        "cd63d3bf-934e-57c7-a58c-874b91be70f0"
                    ]
                },
                "EUPD-5": {
                    "questionId": "EUPD-5",
                    "chosenOptionIds": [
                        "2f3b0b13-2c2d-578f-9059-10e39f93d419"
                    ]
                },
                "EUPD-6": {
                    "questionId": "EUPD-6",
                    "chosenOptionIds": [
                        "3832f310-388e-529e-b128-effddd7578f6"
                    ]
                },
                "EUPD-7": {
                    "questionId": "EUPD-7",
                    "chosenOptionIds": [
                        "03ee1167-bca7-500a-b80a-b5d170bfc229"
                    ]
                },
                "EUPD-8": {
                    "questionId": "EUPD-8",
                    "chosenOptionIds": [
                        "577b2e11-8447-5953-aeb7-b99ee825deb9"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-13.0a": {
                    "questionId": "S-13.0a",
                    "chosenOptionIds": [
                        "35639af8-738a-5ba6-bf47-d0fa52511234"
                    ]
                },
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "8e2bdd16-a97a-5130-8f6e-edb8dd52afe3"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "e8bbf23a-7af1-5ec5-a666-e81bbeed6043"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "c5321fad-d3b3-58d2-979b-11ca81e25dbf"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "981f1af5-5769-5932-8778-58a6e01aa624"
                    ]
                },
                "SH-s2": {
                    "questionId": "SH-s2",
                    "freeValue": 25
                },
                "SH-s3": {
                    "questionId": "SH-s3",
                    "chosenOptionIds": [
                        "29309292-72fb-5781-ae9a-973977aac3af"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7ZR3L aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "0e1d7bfa-9457-5852-b487-4628a4c83ef1"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "ec6f9d53-dae0-5eb6-b0a6-ae8dc7774cb5"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N5PEQ aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "0e1d7bfa-9457-5852-b487-4628a4c83ef1"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "04b30646-e5ef-56c6-8e67-b80b07442d24"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10QJ8EP aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "0e1d7bfa-9457-5852-b487-4628a4c83ef1"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "ec6f9d53-dae0-5eb6-b0a6-ae8dc7774cb5"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME84L3W aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "15fa7f47-7f26-595f-986d-532716789a6f"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "d25ceaaa-5094-5581-a0df-81187f0ef77d"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from TRUE, Censeo vPrevious user: K392M0L aspect: emi_ce",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "5efe5d1e-b865-5328-b632-d1235e4f612d"
                    ]
                },
                "EUPD-10": {
                    "questionId": "EUPD-10",
                    "chosenOptionIds": [
                        "cbfbd155-5a25-52e3-9586-68db54b9dd39"
                    ]
                },
                "EUPD-11": {
                    "questionId": "EUPD-11",
                    "chosenOptionIds": [
                        "2e7e6acf-184c-5ff5-97b9-b57549c75430"
                    ]
                },
                "EUPD-12": {
                    "questionId": "EUPD-12",
                    "chosenOptionIds": [
                        "e7a6bf13-e4ed-5b8b-bed2-e40884e40e38"
                    ]
                },
                "EUPD-13": {
                    "questionId": "EUPD-13",
                    "chosenOptionIds": [
                        "d8bd0a18-11d0-5da4-9048-512a3c027199"
                    ]
                },
                "EUPD-15": {
                    "questionId": "EUPD-15",
                    "chosenOptionIds": [
                        "83fa9a5c-2067-56d7-9d8f-7e8df8a9438f"
                    ]
                },
                "EUPD-15.1": {
                    "questionId": "EUPD-15.1",
                    "chosenOptionIds": [
                        "40bf173b-27ae-5980-87bd-2e9287bea4e1"
                    ]
                },
                "EUPD-16": {
                    "questionId": "EUPD-16",
                    "chosenOptionIds": [
                        "650808a8-2e6f-5f93-aada-7e217f78687c"
                    ]
                },
                "EUPD-17": {
                    "questionId": "EUPD-17",
                    "freeValue": 20
                },
                "EUPD-18": {
                    "questionId": "EUPD-18",
                    "chosenOptionIds": [
                        "b6e918f8-7528-5e71-89d3-76924c5c7872"
                    ]
                },
                "EUPD-19": {
                    "questionId": "EUPD-19",
                    "chosenOptionIds": [
                        "294ce365-f53c-50bf-83fb-0b347170fa9d",
                        "f50b79c8-bd2e-5842-bb07-416baf417668",
                        "c9f6ab7a-f17c-5c62-86c5-b8040246221a",
                        "92a400d7-d419-59dd-89f5-39e4e30acba9",
                        "7ed40115-acb8-5f3f-9eb4-212597b1a202"
                    ]
                },
                "EUPD-19.0": {
                    "questionId": "EUPD-19.0",
                    "chosenOptionIds": [
                        "8dd94ec7-732b-55da-add7-b38553048df6"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "04b30646-e5ef-56c6-8e67-b80b07442d24"
                    ]
                },
                "EUPD-3": {
                    "questionId": "EUPD-3",
                    "freeValue": 0
                },
                "EUPD-4": {
                    "questionId": "EUPD-4",
                    "chosenOptionIds": [
                        "56c58db7-8e4f-5774-a543-b390c23fb315",
                        "cd63d3bf-934e-57c7-a58c-874b91be70f0",
                        "b03a43f4-b673-5258-99e9-afa5b73c6c84",
                        "e59ec14b-a63b-5b39-8af0-4e05190954bd"
                    ]
                },
                "EUPD-5": {
                    "questionId": "EUPD-5",
                    "chosenOptionIds": [
                        "ef9010e1-083b-5af9-a20d-3e9bd4bb20e2"
                    ]
                },
                "EUPD-6": {
                    "questionId": "EUPD-6",
                    "chosenOptionIds": [
                        "a67e923e-933d-5b5c-93f9-7f7a5712bbe6"
                    ]
                },
                "EUPD-7": {
                    "questionId": "EUPD-7",
                    "chosenOptionIds": [
                        "c086bfd4-9480-5dd1-baa3-350a440d8b7e"
                    ]
                },
                "EUPD-8": {
                    "questionId": "EUPD-8",
                    "chosenOptionIds": [
                        "577b2e11-8447-5953-aeb7-b99ee825deb9"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10Q6R0P aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "0e1d7bfa-9457-5852-b487-4628a4c83ef1"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "ec6f9d53-dae0-5eb6-b0a6-ae8dc7774cb5"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from TRUE, Censeo vPrevious user: 50MQK36 aspect: emi_ce",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "52fb5f3a-b30f-5f77-a2bd-0620b67a1adc"
                    ]
                },
                "EUPD-10": {
                    "questionId": "EUPD-10",
                    "chosenOptionIds": [
                        "cbfbd155-5a25-52e3-9586-68db54b9dd39"
                    ]
                },
                "EUPD-11": {
                    "questionId": "EUPD-11",
                    "chosenOptionIds": [
                        "c9665e32-d743-5773-8683-57415abd4af6"
                    ]
                },
                "EUPD-12": {
                    "questionId": "EUPD-12",
                    "chosenOptionIds": [
                        "e7a6bf13-e4ed-5b8b-bed2-e40884e40e38"
                    ]
                },
                "EUPD-13": {
                    "questionId": "EUPD-13",
                    "chosenOptionIds": [
                        "0eef6dac-01b8-5acd-90a6-b8bbdc2600d6"
                    ]
                },
                "EUPD-15": {
                    "questionId": "EUPD-15",
                    "chosenOptionIds": [
                        "6b84f725-12d7-5ce7-9dcf-1483247a1a95"
                    ]
                },
                "EUPD-16": {
                    "questionId": "EUPD-16",
                    "chosenOptionIds": [
                        "549c3369-b2fa-5346-bc86-fdeb2d56f2e9"
                    ]
                },
                "EUPD-17": {
                    "questionId": "EUPD-17",
                    "freeValue": 8
                },
                "EUPD-18": {
                    "questionId": "EUPD-18",
                    "chosenOptionIds": [
                        "b6e918f8-7528-5e71-89d3-76924c5c7872"
                    ]
                },
                "EUPD-19": {
                    "questionId": "EUPD-19",
                    "chosenOptionIds": [
                        "f50b79c8-bd2e-5842-bb07-416baf417668",
                        "294ce365-f53c-50bf-83fb-0b347170fa9d",
                        "c6cb064a-f7fd-5042-b1b2-73020e4f4160",
                        "c9f6ab7a-f17c-5c62-86c5-b8040246221a",
                        "92a400d7-d419-59dd-89f5-39e4e30acba9",
                        "7ed40115-acb8-5f3f-9eb4-212597b1a202",
                        "679c0cc3-6667-5084-9ab8-5ecd7538a662",
                        "523c6fce-132a-5d2f-8773-42390be65d84"
                    ]
                },
                "EUPD-19.0": {
                    "questionId": "EUPD-19.0",
                    "chosenOptionIds": [
                        "1213f822-87a0-516a-9832-682008ea5f4a"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "336fae53-720d-5c45-bc21-01f833ad9e22"
                    ]
                },
                "EUPD-3": {
                    "questionId": "EUPD-3",
                    "freeValue": 0.125
                },
                "EUPD-4": {
                    "questionId": "EUPD-4",
                    "chosenOptionIds": [
                        "e59ec14b-a63b-5b39-8af0-4e05190954bd",
                        "b03a43f4-b673-5258-99e9-afa5b73c6c84",
                        "2ac4ba8b-b73f-5790-a1d6-40f0aea5bb59"
                    ]
                },
                "EUPD-5": {
                    "questionId": "EUPD-5",
                    "chosenOptionIds": [
                        "a9a54f81-e19d-583d-9514-47dbe7d11969"
                    ]
                },
                "EUPD-6": {
                    "questionId": "EUPD-6",
                    "chosenOptionIds": [
                        "6ee2285c-b326-5ba8-b2cb-4296da4b714d"
                    ]
                },
                "EUPD-7": {
                    "questionId": "EUPD-7",
                    "chosenOptionIds": [
                        "c086bfd4-9480-5dd1-baa3-350a440d8b7e"
                    ]
                },
                "EUPD-8": {
                    "questionId": "EUPD-8",
                    "chosenOptionIds": [
                        "8d9927a8-6d9c-544d-88e1-b39adbe9c2dd"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "981f1af5-5769-5932-8778-58a6e01aa624"
                    ]
                },
                "SH-s2": {
                    "questionId": "SH-s2",
                    "freeValue": 14
                },
                "SH-s3": {
                    "questionId": "SH-s3",
                    "chosenOptionIds": [
                        "29309292-72fb-5781-ae9a-973977aac3af"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23X4V3N aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "0e1d7bfa-9457-5852-b487-4628a4c83ef1"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "ec6f9d53-dae0-5eb6-b0a6-ae8dc7774cb5"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35X10O aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "52fb5f3a-b30f-5f77-a2bd-0620b67a1adc"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "ec6f9d53-dae0-5eb6-b0a6-ae8dc7774cb5"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0K9D01 aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "0e1d7bfa-9457-5852-b487-4628a4c83ef1"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "04b30646-e5ef-56c6-8e67-b80b07442d24"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGLK3P aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "0e1d7bfa-9457-5852-b487-4628a4c83ef1"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "ec6f9d53-dae0-5eb6-b0a6-ae8dc7774cb5"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: NEL9J3V aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "0e1d7bfa-9457-5852-b487-4628a4c83ef1"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "ec6f9d53-dae0-5eb6-b0a6-ae8dc7774cb5"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-13.0a": {
                    "questionId": "S-13.0a",
                    "chosenOptionIds": [
                        "35639af8-738a-5ba6-bf47-d0fa52511234"
                    ]
                },
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "fadcf643-dd8c-5f24-90d5-f971cf3c6ec7"
                    ]
                },
                "S-14a": {
                    "questionId": "S-14a",
                    "freeValue": 730
                },
                "S-15a": {
                    "questionId": "S-15a",
                    "freeValue": 3
                },
                "S-17a": {
                    "questionId": "S-17a",
                    "chosenOptionIds": [
                        "7bd78121-d1a4-5bd7-8be2-eefa7e976604"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "890e7c6f-4bdf-5d6c-87e4-d2fc93a63102"
                    ]
                },
                "S-1a": {
                    "questionId": "S-1a",
                    "freeValue": 90
                },
                "S-2.5a": {
                    "questionId": "S-2.5a",
                    "chosenOptionIds": [
                        "455f8af3-f57a-5fe0-808e-aad6d2439855"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "28660364-460b-5f7f-a281-104044331d08"
                    ]
                },
                "S-2a": {
                    "questionId": "S-2a",
                    "chosenOptionIds": [
                        "541369ff-8077-58e5-8bf7-90a8a94d14bb"
                    ]
                },
                "S-3a": {
                    "questionId": "S-3a",
                    "freeValue": 14
                },
                "S-4a": {
                    "questionId": "S-4a",
                    "chosenOptionIds": [
                        "2ad36ca1-d48c-57c8-a058-9de92c14e840"
                    ]
                },
                "S-5a": {
                    "questionId": "S-5a",
                    "chosenOptionIds": [
                        "86839467-8345-58f0-9355-a14ba91c7079"
                    ]
                },
                "S-6a": {
                    "questionId": "S-6a",
                    "chosenOptionIds": [
                        "870c5cac-fb60-51ca-9d26-bf9e93d56ab2"
                    ]
                },
                "S-8a": {
                    "questionId": "S-8a",
                    "chosenOptionIds": [
                        "fb782e60-165b-5cd2-ae70-c40b281bc387"
                    ]
                },
                "S-9.5a": {
                    "questionId": "S-9.5a",
                    "chosenOptionIds": [
                        "9e611ece-c2d6-5900-8832-3cbf939f729d"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE694EL aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "0e1d7bfa-9457-5852-b487-4628a4c83ef1"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "d25ceaaa-5094-5581-a0df-81187f0ef77d"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: K399M3L aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "52fb5f3a-b30f-5f77-a2bd-0620b67a1adc"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "04b30646-e5ef-56c6-8e67-b80b07442d24"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "8e2bdd16-a97a-5130-8f6e-edb8dd52afe3"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "81b3f713-07b4-5d30-8066-60c1326c2aa1"
                    ]
                },
                "S-1a": {
                    "questionId": "S-1a",
                    "freeValue": 390
                },
                "S-2.5a": {
                    "questionId": "S-2.5a",
                    "chosenOptionIds": [
                        "455f8af3-f57a-5fe0-808e-aad6d2439855"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "f18f48b0-a401-5787-bb00-ca8d99523304"
                    ]
                },
                "S-2a": {
                    "questionId": "S-2a",
                    "chosenOptionIds": [
                        "541369ff-8077-58e5-8bf7-90a8a94d14bb"
                    ]
                },
                "S-3a": {
                    "questionId": "S-3a",
                    "freeValue": 2
                },
                "S-4a": {
                    "questionId": "S-4a",
                    "chosenOptionIds": [
                        "dada9607-7e9d-565b-8073-73b780535bbe"
                    ]
                },
                "S-5a": {
                    "questionId": "S-5a",
                    "chosenOptionIds": [
                        "86839467-8345-58f0-9355-a14ba91c7079"
                    ]
                },
                "S-6a": {
                    "questionId": "S-6a",
                    "chosenOptionIds": [
                        "0667075d-d44a-5ec5-8a05-4d25659c831d"
                    ]
                },
                "S-8a": {
                    "questionId": "S-8a",
                    "chosenOptionIds": [
                        "4f14f047-a4d6-5709-95b7-4742bec76fa7"
                    ]
                },
                "S-9.5a": {
                    "questionId": "S-9.5a",
                    "chosenOptionIds": [
                        "9e611ece-c2d6-5900-8832-3cbf939f729d"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63Y843R aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "15fa7f47-7f26-595f-986d-532716789a6f"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "ec6f9d53-dae0-5eb6-b0a6-ae8dc7774cb5"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XX43N aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "52fb5f3a-b30f-5f77-a2bd-0620b67a1adc"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "04b30646-e5ef-56c6-8e67-b80b07442d24"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3J4P0Y aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "0e1d7bfa-9457-5852-b487-4628a4c83ef1"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "04b30646-e5ef-56c6-8e67-b80b07442d24"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VQM3L aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "0e1d7bfa-9457-5852-b487-4628a4c83ef1"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "ec6f9d53-dae0-5eb6-b0a6-ae8dc7774cb5"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from TRUE, Censeo vPrevious user: 4EGRG3P aspect: emi_ce",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "5efe5d1e-b865-5328-b632-d1235e4f612d"
                    ]
                },
                "EUPD-10": {
                    "questionId": "EUPD-10",
                    "chosenOptionIds": [
                        "065d8ebc-76ad-5e44-8a8a-521a6d319107"
                    ]
                },
                "EUPD-11": {
                    "questionId": "EUPD-11",
                    "chosenOptionIds": [
                        "2e7e6acf-184c-5ff5-97b9-b57549c75430"
                    ]
                },
                "EUPD-12": {
                    "questionId": "EUPD-12",
                    "chosenOptionIds": [
                        "e7a6bf13-e4ed-5b8b-bed2-e40884e40e38"
                    ]
                },
                "EUPD-13": {
                    "questionId": "EUPD-13",
                    "chosenOptionIds": [
                        "a8ff3134-ce03-54cd-8154-6064c567fe71"
                    ]
                },
                "EUPD-15": {
                    "questionId": "EUPD-15",
                    "chosenOptionIds": [
                        "83fa9a5c-2067-56d7-9d8f-7e8df8a9438f"
                    ]
                },
                "EUPD-15.1": {
                    "questionId": "EUPD-15.1",
                    "chosenOptionIds": [
                        "157f1ca7-66b8-532a-bfab-f51f963a5cc2"
                    ]
                },
                "EUPD-16": {
                    "questionId": "EUPD-16",
                    "chosenOptionIds": [
                        "549c3369-b2fa-5346-bc86-fdeb2d56f2e9"
                    ]
                },
                "EUPD-17": {
                    "questionId": "EUPD-17",
                    "freeValue": 18
                },
                "EUPD-18": {
                    "questionId": "EUPD-18",
                    "chosenOptionIds": [
                        "b6e918f8-7528-5e71-89d3-76924c5c7872"
                    ]
                },
                "EUPD-19": {
                    "questionId": "EUPD-19",
                    "chosenOptionIds": [
                        "f50b79c8-bd2e-5842-bb07-416baf417668",
                        "294ce365-f53c-50bf-83fb-0b347170fa9d",
                        "c6cb064a-f7fd-5042-b1b2-73020e4f4160",
                        "c9f6ab7a-f17c-5c62-86c5-b8040246221a",
                        "92a400d7-d419-59dd-89f5-39e4e30acba9",
                        "7ed40115-acb8-5f3f-9eb4-212597b1a202",
                        "679c0cc3-6667-5084-9ab8-5ecd7538a662",
                        "523c6fce-132a-5d2f-8773-42390be65d84"
                    ]
                },
                "EUPD-19.0": {
                    "questionId": "EUPD-19.0",
                    "chosenOptionIds": [
                        "1a640268-73b2-5133-ae7d-165973ac73a1"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "44285291-8282-578e-858a-31e91b05796f"
                    ]
                },
                "EUPD-3": {
                    "questionId": "EUPD-3",
                    "freeValue": 0.5
                },
                "EUPD-4": {
                    "questionId": "EUPD-4",
                    "chosenOptionIds": [
                        "cd63d3bf-934e-57c7-a58c-874b91be70f0"
                    ]
                },
                "EUPD-5": {
                    "questionId": "EUPD-5",
                    "chosenOptionIds": [
                        "5cff41b8-0903-5527-bc17-cd7326b077d3"
                    ]
                },
                "EUPD-6": {
                    "questionId": "EUPD-6",
                    "chosenOptionIds": [
                        "3832f310-388e-529e-b128-effddd7578f6"
                    ]
                },
                "EUPD-7": {
                    "questionId": "EUPD-7",
                    "chosenOptionIds": [
                        "03ee1167-bca7-500a-b80a-b5d170bfc229"
                    ]
                },
                "EUPD-8": {
                    "questionId": "EUPD-8",
                    "chosenOptionIds": [
                        "4910399c-7c7b-5e77-9f24-f8a248ddf28d"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8MOEW aspect: emi_ce should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "5efe5d1e-b865-5328-b632-d1235e4f612d"
                    ]
                },
                "EUPD-10": {
                    "questionId": "EUPD-10",
                    "chosenOptionIds": [
                        "f1f68c05-871b-52f9-9891-c1eb99bc9b6f"
                    ]
                },
                "EUPD-11": {
                    "questionId": "EUPD-11",
                    "chosenOptionIds": [
                        "9f9ba45a-2149-5fe0-96f9-38b83794dcd6"
                    ]
                },
                "EUPD-12": {
                    "questionId": "EUPD-12",
                    "chosenOptionIds": [
                        "96e3b252-6135-5811-8a14-b5cb7973f263"
                    ]
                },
                "EUPD-13": {
                    "questionId": "EUPD-13",
                    "chosenOptionIds": [
                        "a8ff3134-ce03-54cd-8154-6064c567fe71"
                    ]
                },
                "EUPD-15": {
                    "questionId": "EUPD-15",
                    "chosenOptionIds": [
                        "9ce96b06-685e-587c-9c34-4c80e5a1497f"
                    ]
                },
                "EUPD-15.1": {
                    "questionId": "EUPD-15.1",
                    "chosenOptionIds": [
                        "40bf173b-27ae-5980-87bd-2e9287bea4e1"
                    ]
                },
                "EUPD-16": {
                    "questionId": "EUPD-16",
                    "chosenOptionIds": [
                        "650808a8-2e6f-5f93-aada-7e217f78687c"
                    ]
                },
                "EUPD-17": {
                    "questionId": "EUPD-17",
                    "freeValue": 7
                },
                "EUPD-18": {
                    "questionId": "EUPD-18",
                    "chosenOptionIds": [
                        "f0d62708-1864-5541-a81d-7817b478c4d4"
                    ]
                },
                "EUPD-19": {
                    "questionId": "EUPD-19",
                    "chosenOptionIds": [
                        "294ce365-f53c-50bf-83fb-0b347170fa9d",
                        "c6cb064a-f7fd-5042-b1b2-73020e4f4160",
                        "c9f6ab7a-f17c-5c62-86c5-b8040246221a",
                        "92a400d7-d419-59dd-89f5-39e4e30acba9",
                        "7ed40115-acb8-5f3f-9eb4-212597b1a202",
                        "523c6fce-132a-5d2f-8773-42390be65d84",
                        "679c0cc3-6667-5084-9ab8-5ecd7538a662"
                    ]
                },
                "EUPD-19.0": {
                    "questionId": "EUPD-19.0",
                    "chosenOptionIds": [
                        "1a640268-73b2-5133-ae7d-165973ac73a1"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "336fae53-720d-5c45-bc21-01f833ad9e22"
                    ]
                },
                "EUPD-3": {
                    "questionId": "EUPD-3",
                    "freeValue": 70
                },
                "EUPD-4": {
                    "questionId": "EUPD-4",
                    "chosenOptionIds": [
                        "56c58db7-8e4f-5774-a543-b390c23fb315",
                        "2ac4ba8b-b73f-5790-a1d6-40f0aea5bb59"
                    ]
                },
                "EUPD-5": {
                    "questionId": "EUPD-5",
                    "chosenOptionIds": [
                        "ef9010e1-083b-5af9-a20d-3e9bd4bb20e2"
                    ]
                },
                "EUPD-6": {
                    "questionId": "EUPD-6",
                    "chosenOptionIds": [
                        "c851a6d3-10b8-5cbb-bf8b-93473d172427"
                    ]
                },
                "EUPD-7": {
                    "questionId": "EUPD-7",
                    "chosenOptionIds": [
                        "c086bfd4-9480-5dd1-baa3-350a440d8b7e"
                    ]
                },
                "EUPD-8": {
                    "questionId": "EUPD-8",
                    "chosenOptionIds": [
                        "6bbed69c-d98b-5eff-98da-549cac6b89ac"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-13.0a": {
                    "questionId": "S-13.0a",
                    "chosenOptionIds": [
                        "35639af8-738a-5ba6-bf47-d0fa52511234"
                    ]
                },
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "8e2bdd16-a97a-5130-8f6e-edb8dd52afe3"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "e8bbf23a-7af1-5ec5-a666-e81bbeed6043"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "f18f48b0-a401-5787-bb00-ca8d99523304"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE61J0L aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "0e1d7bfa-9457-5852-b487-4628a4c83ef1"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "ec6f9d53-dae0-5eb6-b0a6-ae8dc7774cb5"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EW4D08 aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "15fa7f47-7f26-595f-986d-532716789a6f"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "d25ceaaa-5094-5581-a0df-81187f0ef77d"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-13.0a": {
                    "questionId": "S-13.0a",
                    "chosenOptionIds": [
                        "35639af8-738a-5ba6-bf47-d0fa52511234"
                    ]
                },
                "S-13a": {
                    "questionId": "S-13a",
                    "chosenOptionIds": [
                        "fadcf643-dd8c-5f24-90d5-f971cf3c6ec7"
                    ]
                },
                "S-14a": {
                    "questionId": "S-14a",
                    "freeValue": 60
                },
                "S-15a": {
                    "questionId": "S-15a",
                    "freeValue": 5
                },
                "S-17a": {
                    "questionId": "S-17a",
                    "chosenOptionIds": [
                        "7bd78121-d1a4-5bd7-8be2-eefa7e976604"
                    ]
                },
                "S-18a": {
                    "questionId": "S-18a",
                    "chosenOptionIds": [
                        "e8bbf23a-7af1-5ec5-a666-e81bbeed6043"
                    ]
                },
                "S-1a": {
                    "questionId": "S-1a",
                    "freeValue": 730
                },
                "S-2.5a": {
                    "questionId": "S-2.5a",
                    "chosenOptionIds": [
                        "455f8af3-f57a-5fe0-808e-aad6d2439855"
                    ]
                },
                "S-22a": {
                    "questionId": "S-22a",
                    "chosenOptionIds": [
                        "28660364-460b-5f7f-a281-104044331d08"
                    ]
                },
                "S-2a": {
                    "questionId": "S-2a",
                    "chosenOptionIds": [
                        "541369ff-8077-58e5-8bf7-90a8a94d14bb"
                    ]
                },
                "S-3a": {
                    "questionId": "S-3a",
                    "freeValue": 20
                },
                "S-4a": {
                    "questionId": "S-4a",
                    "chosenOptionIds": [
                        "2ad36ca1-d48c-57c8-a058-9de92c14e840"
                    ]
                },
                "S-5a": {
                    "questionId": "S-5a",
                    "chosenOptionIds": [
                        "86839467-8345-58f0-9355-a14ba91c7079"
                    ]
                },
                "S-6a": {
                    "questionId": "S-6a",
                    "chosenOptionIds": [
                        "0181dcdd-3d3d-512a-bdf8-de8a996bd7e8"
                    ]
                },
                "S-8a": {
                    "questionId": "S-8a",
                    "chosenOptionIds": [
                        "fb782e60-165b-5cd2-ae70-c40b281bc387"
                    ]
                },
                "S-9.5a": {
                    "questionId": "S-9.5a",
                    "chosenOptionIds": [
                        "9e611ece-c2d6-5900-8832-3cbf939f729d"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "00f4664d-929e-5301-bd5b-f405a7ab8ce4"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "10e008b4-feb5-5134-9869-3dfc46cb9924"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "e90b0a3b-f835-5a9b-948b-d1b685da2c2e"
                    ]
                },
                "S-s4": {
                    "questionId": "S-s4",
                    "chosenOptionIds": [
                        "48d1552a-30b1-5dee-a6f1-f9e12ced08a2"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-1.5a": {
                    "questionId": "SH-1.5a",
                    "chosenOptionIds": [
                        "38c7a9b9-d5a0-5a57-b6fd-4b8f2c7499a8"
                    ]
                },
                "SH-16a": {
                    "questionId": "SH-16a",
                    "chosenOptionIds": [
                        "f3060ab6-8ac7-559d-838c-a58eeb5d018e"
                    ]
                },
                "SH-1a": {
                    "questionId": "SH-1a",
                    "freeValue": 2
                },
                "SH-23.5a": {
                    "questionId": "SH-23.5a",
                    "chosenOptionIds": [
                        "a80457e5-70be-5103-ad49-69a47f84d227"
                    ]
                },
                "SH-23a": {
                    "questionId": "SH-23a",
                    "chosenOptionIds": [
                        "025fa831-b50c-51c6-8ece-7f851af56e4f"
                    ]
                },
                "SH-2a": {
                    "questionId": "SH-2a",
                    "chosenOptionIds": [
                        "fe25eeda-81fa-58a3-9c72-545aabe803f5"
                    ]
                },
                "SH-4a": {
                    "questionId": "SH-4a",
                    "freeValue": 180
                },
                "SH-6a": {
                    "questionId": "SH-6a",
                    "chosenOptionIds": [
                        "a770529d-17f7-5b21-8f7b-3b508e9cb983"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "981f1af5-5769-5932-8778-58a6e01aa624"
                    ]
                },
                "SH-s2": {
                    "questionId": "SH-s2",
                    "freeValue": 36
                },
                "SH-s3": {
                    "questionId": "SH-s3",
                    "chosenOptionIds": [
                        "29309292-72fb-5781-ae9a-973977aac3af"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3RLO3O aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "15fa7f47-7f26-595f-986d-532716789a6f"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "ec6f9d53-dae0-5eb6-b0a6-ae8dc7774cb5"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7WX3L aspect: emi_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "EUPD-1": {
                    "questionId": "EUPD-1",
                    "chosenOptionIds": [
                        "15fa7f47-7f26-595f-986d-532716789a6f"
                    ]
                },
                "EUPD-2": {
                    "questionId": "EUPD-2",
                    "chosenOptionIds": [
                        "d25ceaaa-5094-5581-a0df-81187f0ef77d"
                    ]
                },
                "EUPD-S": {
                    "questionId": "EUPD-S",
                    "chosenOptionIds": [
                        "8300ceda-a5ff-5d27-ab8d-2a1695aee722"
                    ]
                },
                "S-S": {
                    "questionId": "S-S",
                    "chosenOptionIds": [
                        "9e3bfb5c-e1f9-565f-9521-261bdb19a460"
                    ]
                },
                "S-s1": {
                    "questionId": "S-s1",
                    "chosenOptionIds": [
                        "59a1b51e-3319-5c4d-a9f4-e50455a6f5a6"
                    ]
                },
                "S-s2": {
                    "questionId": "S-s2",
                    "chosenOptionIds": [
                        "07c32652-123e-5f58-894e-f4ed9c8b110e"
                    ]
                },
                "S-s3": {
                    "questionId": "S-s3",
                    "chosenOptionIds": [
                        "7a0a26f8-6581-5cce-b2e7-6cbc7cf05c35"
                    ]
                },
                "SH-0": {
                    "questionId": "SH-0",
                    "chosenOptionIds": [
                        "7f1aeb20-7b53-50bd-98c3-05a188150863"
                    ]
                },
                "SH-S": {
                    "questionId": "SH-S",
                    "chosenOptionIds": [
                        "a755d608-919a-58ed-9230-2785f62dd1fc"
                    ]
                },
                "SH-s1": {
                    "questionId": "SH-s1",
                    "chosenOptionIds": [
                        "519336e4-f9db-552e-a464-11ea704d6288"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {
    "EUPD-3": "and[or[isUndefined(and[EUPD-1<=0.5, EUPD-2<=0.5]), not(and[EUPD-1<=0.5, EUPD-2<=0.5])], not(isUndefined(EUPD-2=0))]",
    "EUPD-4": "and[or[isUndefined(and[EUPD-1<=0.5, EUPD-2<=0.5]), not(and[EUPD-1<=0.5, EUPD-2<=0.5])], not(isUndefined(EUPD-2=0))]",
    "EUPD-5": "and[or[isUndefined(and[EUPD-1<=0.5, EUPD-2<=0.5]), not(and[EUPD-1<=0.5, EUPD-2<=0.5])], not(isUndefined(EUPD-2=0))]",
    "EUPD-6": "and[or[isUndefined(and[EUPD-1<=0.5, EUPD-2<=0.5]), not(and[EUPD-1<=0.5, EUPD-2<=0.5])], not(isUndefined(EUPD-2=0))]",
    "EUPD-7": "and[or[isUndefined(and[EUPD-1<=0.5, EUPD-2<=0.5]), not(and[EUPD-1<=0.5, EUPD-2<=0.5])], not(isUndefined(EUPD-2=0))]",
    "EUPD-8": "and[or[isUndefined(and[EUPD-1<=0.5, EUPD-2<=0.5]), not(and[EUPD-1<=0.5, EUPD-2<=0.5])], not(isUndefined(EUPD-2=0))]",
    "EUPD-10": "and[or[isUndefined(and[EUPD-1<=0.5, EUPD-2<=0.5]), not(and[EUPD-1<=0.5, EUPD-2<=0.5])], not(isUndefined(EUPD-2=0))]",
    "EUPD-11": "and[or[isUndefined(and[EUPD-1<=0.5, EUPD-2<=0.5]), not(and[EUPD-1<=0.5, EUPD-2<=0.5])], not(isUndefined(EUPD-2=0))]",
    "EUPD-12": "and[or[isUndefined(and[EUPD-1<=0.5, EUPD-2<=0.5]), not(and[EUPD-1<=0.5, EUPD-2<=0.5])], not(isUndefined(EUPD-2=0))]",
    "EUPD-13": "and[or[isUndefined(and[EUPD-1<=0.5, EUPD-2<=0.5]), not(and[EUPD-1<=0.5, EUPD-2<=0.5])], not(isUndefined(EUPD-2=0))]",
    "EUPD-15": "and[or[isUndefined(and[EUPD-1<=0.5, EUPD-2<=0.5]), not(and[EUPD-1<=0.5, EUPD-2<=0.5])], not(isUndefined(EUPD-2=0))]",
    "EUPD-15.1": "and[or[isUndefined(and[EUPD-1<=0.25, EUPD-5<=0.25, EUPD-6<=0.25, EUPD-7<=0.25, EUPD-8<=0.25, EUPD-10<=0.25, EUPD-11<=0.25, EUPD-12<=0.25, EUPD-13<=0.25, EUPD-15<=0.25]), not(and[EUPD-1<=0.25, EUPD-5<=0.25, EUPD-6<=0.25, EUPD-7<=0.25, EUPD-8<=0.25, EUPD-10<=0.25, EUPD-11<=0.25, EUPD-12<=0.25, EUPD-13<=0.25, EUPD-15<=0.25])], not(EUPD-15=0)]",
    "EUPD-15.2": "and[or[isUndefined(and[EUPD-1<=0.25, EUPD-5<=0.25, EUPD-6<=0.25, EUPD-7<=0.25, EUPD-8<=0.25, EUPD-10<=0.25, EUPD-11<=0.25, EUPD-12<=0.25, EUPD-13<=0.25, EUPD-15<=0.25]), not(and[EUPD-1<=0.25, EUPD-5<=0.25, EUPD-6<=0.25, EUPD-7<=0.25, EUPD-8<=0.25, EUPD-10<=0.25, EUPD-11<=0.25, EUPD-12<=0.25, EUPD-13<=0.25, EUPD-15<=0.25])], not(isUndefined(EUPD-15=0))]",
    "EUPD-16": "and[or[isUndefined(and[EUPD-1<=0.25, EUPD-5<=0.25, EUPD-6<=0.25, EUPD-7<=0.25, EUPD-8<=0.25, EUPD-10<=0.25, EUPD-11<=0.25, EUPD-12<=0.25, EUPD-13<=0.25, EUPD-15<=0.25]), not(and[EUPD-1<=0.25, EUPD-5<=0.25, EUPD-6<=0.25, EUPD-7<=0.25, EUPD-8<=0.25, EUPD-10<=0.25, EUPD-11<=0.25, EUPD-12<=0.25, EUPD-13<=0.25, EUPD-15<=0.25])], not(isUndefined(EUPD-15=0))]",
    "EUPD-17": "and[or[isUndefined(and[EUPD-1<=0.25, EUPD-5<=0.25, EUPD-6<=0.25, EUPD-7<=0.25, EUPD-8<=0.25, EUPD-10<=0.25, EUPD-11<=0.25, EUPD-12<=0.25, EUPD-13<=0.25, EUPD-15<=0.25]), not(and[EUPD-1<=0.25, EUPD-5<=0.25, EUPD-6<=0.25, EUPD-7<=0.25, EUPD-8<=0.25, EUPD-10<=0.25, EUPD-11<=0.25, EUPD-12<=0.25, EUPD-13<=0.25, EUPD-15<=0.25])], not(isUndefined(EUPD-15=0))]",
    "EUPD-18": "and[or[isUndefined(and[EUPD-1<=0.25, EUPD-5<=0.25, EUPD-6<=0.25, EUPD-7<=0.25, EUPD-8<=0.25, EUPD-10<=0.25, EUPD-11<=0.25, EUPD-12<=0.25, EUPD-13<=0.25, EUPD-15<=0.25]), not(and[EUPD-1<=0.25, EUPD-5<=0.25, EUPD-6<=0.25, EUPD-7<=0.25, EUPD-8<=0.25, EUPD-10<=0.25, EUPD-11<=0.25, EUPD-12<=0.25, EUPD-13<=0.25, EUPD-15<=0.25])], not(isUndefined(EUPD-15=0))]",
    "EUPD-19": "and[or[isUndefined(and[EUPD-1<=0.25, EUPD-5<=0.25, EUPD-6<=0.25, EUPD-7<=0.25, EUPD-8<=0.25, EUPD-10<=0.25, EUPD-11<=0.25, EUPD-12<=0.25, EUPD-13<=0.25, EUPD-15<=0.25]), not(and[EUPD-1<=0.25, EUPD-5<=0.25, EUPD-6<=0.25, EUPD-7<=0.25, EUPD-8<=0.25, EUPD-10<=0.25, EUPD-11<=0.25, EUPD-12<=0.25, EUPD-13<=0.25, EUPD-15<=0.25])], not(isUndefined(EUPD-15=0))]",
    "EUPD-19.0": "and[or[isUndefined(EUPD19_none=1), not(EUPD19_none=1)], not(isUndefined(EUPD-19=0))]"
};
