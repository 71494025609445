"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.totalScore = exports.mdqSeverity = exports.calculateASRS = exports.calculateWURS = exports.calculateMSI = exports.calculateISI = exports.calculatePHQ9 = exports.calculateScoff = exports.calculatePcl5 = exports.calculateGad7 = exports.calculatePq16 = exports.calculateOciR = exports.calculateAdnm = exports.functionSummaries = void 0;
const ADHD_1 = require("./generated/ADHD");
const GAD7_1 = require("./generated/GAD7");
const EDEQS_1 = require("./generated/EDEQS");
const SCOFF_1 = require("./generated/SCOFF");
const PCL5_1 = require("./generated/PCL5");
const PHQ9_1 = require("./generated/PHQ9");
const SPIN_1 = require("./generated/SPIN");
const MDQ_1 = require("./generated/MDQ");
const PDSS_1 = require("./generated/PDSS");
const ISI_1 = require("./generated/ISI");
const oci_1 = require("./generated/oci");
const ocir_1 = require("./generated/ocir");
const MSIBPD_1 = require("./generated/MSIBPD");
const adnm_1 = require("./generated/adnm");
const pq16_1 = require("./generated/pq16");
const calculateScoreFromNormalized_1 = require("../utils/calculateScoreFromNormalized");
const oci_2 = require("../conditions/oci");
exports.functionSummaries = {
    AsrsThreshold: (answers) => calculateASRS(answers).threshold,
    AsrsA: (answers) => calculateASRS(answers).partA,
    AsrsB: (answers) => calculateASRS(answers).partB,
    WursTotal: (answers) => { var _a; return (_a = calculateWURS(answers)) === null || _a === void 0 ? void 0 : _a.main; },
    WursDisruptive: (answers) => { var _a; return (_a = calculateWURS(answers)) === null || _a === void 0 ? void 0 : _a.disruptive; },
    WursAdhd: (answers) => { var _a; return (_a = calculateWURS(answers)) === null || _a === void 0 ? void 0 : _a.adhd; },
    WursAnxiety: (answers) => { var _a; return (_a = calculateWURS(answers)) === null || _a === void 0 ? void 0 : _a.anxietyDisphoria; },
    Pcl5Total: (answers) => { var _a; return (_a = calculatePcl5(answers)) === null || _a === void 0 ? void 0 : _a.score; },
    Pcl5Severity: (answers) => { var _a; return (_a = calculatePcl5(answers)) === null || _a === void 0 ? void 0 : _a.severity; },
    Gad7Total: (answers) => calculateGad7(answers).score,
    Gad7Severity: (answers) => calculateGad7(answers).severity,
    mdqTotal: (answers) => { var _a; return (_a = mdqSeverity(answers)) === null || _a === void 0 ? void 0 : _a.total; },
    mdqSeverity: (answers) => { var _a; return (_a = mdqSeverity(answers)) === null || _a === void 0 ? void 0 : _a.severity; },
    edeQsTotal: (answers) => String(totalScore(answers, EDEQS_1.questions)),
    spinTotal: (answers) => String(totalScore(answers, SPIN_1.questions, ['SPIN-content'])),
    pdssTotal: (answers) => String(totalScore(answers, PDSS_1.questions, ['PDSS-content-1', 'PDSS-content-2'])),
    Phq9Total: (answers) => { var _a; return (_a = calculatePHQ9(answers)) === null || _a === void 0 ? void 0 : _a.score; },
    IsiTotal: (answers) => { var _a; return (_a = calculateISI(answers)) === null || _a === void 0 ? void 0 : _a.score; },
    MsiBpdTotal: (answers) => { var _a; return (_a = calculateMSI(answers)) === null || _a === void 0 ? void 0 : _a.score; },
    OciRTotal: (answers) => { var _a; return (_a = calculateOciR(answers)) === null || _a === void 0 ? void 0 : _a.score; },
    OciROcdSeverity: (answers) => { var _a; return (_a = calculateOciR(answers)) === null || _a === void 0 ? void 0 : _a.ocdSeverity; },
    OciTotal: (answers) => { var _a; return (_a = calculateOciTotal(answers)) === null || _a === void 0 ? void 0 : _a.score; },
    OciSeverity: (answers) => { var _a; return (_a = calculateOciTotal(answers)) === null || _a === void 0 ? void 0 : _a.ocdSeverity; },
    OciWashingMean: (answers) => (0, oci_2.calculateOciWashingMean)(answers),
    OciCheckingMean: (answers) => (0, oci_2.calculateOciCheckingMean)(answers),
    OciDoubtingMean: (answers) => (0, oci_2.calculateOciDoubtingMean)(answers),
    OciOrderingMean: (answers) => (0, oci_2.calculateOciOrderingMean)(answers),
    OciObsessingMean: (answers) => (0, oci_2.calculateOciObsessingMean)(answers),
    OciHoardingMean: (answers) => (0, oci_2.calculateOciHoardingMean)(answers),
    OciMentalNeutralisingMean: (answers) => (0, oci_2.calculateOciMentalNeutralisingMean)(answers),
    AdnmTotal: (answers) => { var _a; return (_a = calculateAdnm(answers)) === null || _a === void 0 ? void 0 : _a.score; },
    AdnmAjdRisk: (answers) => { var _a; return (_a = calculateAdnm(answers)) === null || _a === void 0 ? void 0 : _a.ajdRisk; },
    PQ16Score: (answers) => { var _a; return (_a = calculatePq16(answers)) === null || _a === void 0 ? void 0 : _a.score; },
    PQ16RiskLevel: (answers) => { var _a; return (_a = calculatePq16(answers)) === null || _a === void 0 ? void 0 : _a.riskLevel; },
    ScoffSeverity: (answers) => { var _a; return (_a = calculateScoff(answers)) === null || _a === void 0 ? void 0 : _a.severity; },
    // Scoring keys
    Pcl5ScoringKey: () => '0 - Not at all ｜ 1 - A little bit ｜ 2 - Moderately ｜ 3 - Quite a bit ｜ 4 - Extremely',
    Gad7ScoringKey: () => '0 - Not at all ｜ 1 - Several days ｜ 2 - More than half the days ｜ 3 - Nearly every day',
    IsiScoringKey: () => '0 - None ｜ 1 - Mild ｜ 2 - Moderate ｜ 3 - Severe ｜ 4 - Very',
    SpinScoringKey: () => '0 - Not at all ｜ 1 - A little bit ｜ 2 - Somewhat ｜ 3 - Very much ｜ 4 - Extremely',
    PdssScoringKey: () => '0 - None ｜ 1 - Mild ｜ 2 - Moderate ｜ 3 - Severe ｜ 4 - Extreme',
    OciScoringKey: () => '0 - Not at all ｜ 1 - A little ｜ 2 - Moderately ｜ 3 - A lot ｜ 4 - Extremely',
    OciRScoringKey: () => '0 - Not at all ｜ 1 - A little ｜ 2 - Moderately ｜ 3 - A lot ｜ 4 - Extremely',
    ScoffScoringKey: () => '0 - No ｜ 1 - Yes',
    Phq9ScoringKey: () => '0 - Not at all ｜ 1 - Several days ｜ 2 - More than half the days ｜ 3 - Nearly every day',
    Dast10ScoringKey: () => '0 - No ｜ 1 - Yes',
};
function calculateAdnm(answers) {
    const totalScore = 80;
    const contentAndDurationQuestions = [
        'ADNM_content-1',
        'ADNM_content-2',
        'ADNM-1',
        'ADNM-3',
        'ADNM-5',
        'ADNM-7',
        'ADNM-9',
        'ADNM-11',
        'ADNM-13',
        'ADNM-15',
        'ADNM-17',
        'ADNM-19',
        'ADNM-21',
        'ADNM-23',
        'ADNM-25',
        'ADNM-27',
        'ADNM-29',
        'ADNM-31',
        'ADNM-33',
        'ADNM-35',
        'ADNM-37',
        'ADNM-39',
        'ADNM-41',
    ];
    const adnmTotalScore = (0, calculateScoreFromNormalized_1.calculateScoreFromNormalized)(answers, adnm_1.questions.filter((question) => !contentAndDurationQuestions.includes(question.id)).map((question) => question.id), adnm_1.questions, 3, 1);
    if (!adnmTotalScore && adnmTotalScore !== 0) {
        return undefined;
    }
    const ajdRisk = `Likely to meet the criteria for Adjustment Disorder: ${adnmTotalScore > 47.5 ? 'Yes' : 'No'} (cutoff score > 47.5)`;
    return { score: `${adnmTotalScore} out of ${totalScore}`, ajdRisk };
}
exports.calculateAdnm = calculateAdnm;
function calculateOciR(answers) {
    const totalScore = 72;
    const contentQuestions = ['OCI_R-1'];
    const score = (0, calculateScoreFromNormalized_1.calculateScoreFromNormalized)(answers, ocir_1.questions.filter((question) => !contentQuestions.includes(question.id)).map((question) => question.id), ocir_1.questions, 4);
    if (!score && score !== 0)
        return undefined;
    const ocdSeverity = score < 21 ? 'Not likely' : 'Likely';
    return { score: `${score} out of ${totalScore}`, ocdSeverity };
}
exports.calculateOciR = calculateOciR;
function calculateOciTotal(answers) {
    const threshold = 42;
    const contentQuestions = ['OCI-content-1', 'OCI-content-2', 'OCI-content-3'];
    const allQuestions = oci_1.questions.filter((q) => !contentQuestions.includes(q.id));
    const maximumScore = allQuestions.length * 4;
    const score = (0, calculateScoreFromNormalized_1.calculateScoreFromNormalized)(answers, allQuestions.map((question) => question.id), oci_1.questions, 4);
    if (score === undefined)
        return undefined;
    return {
        score: `Total score ${score} out of ${maximumScore}`,
        ocdSeverity: `${score < threshold ? 'Not suggested (0-41)' : 'Suggested (>= 42)'}`,
    };
}
function calculatePq16(answers) {
    const totalScore = 16;
    const screenInQuestionIds = [
        'PQ16-1',
        'PQ16-3',
        'PQ16-5',
        'PQ16-7',
        'PQ16-9',
        'PQ16-11',
        'PQ16-13',
        'PQ16-15',
        'PQ16-17',
        'PQ16-19',
        'PQ16-21',
        'PQ16-23',
        'PQ16-25',
        'PQ16-27',
        'PQ16-29',
        'PQ16-31',
    ];
    const adjustmentFactor = 1;
    const pq16QuestionIds = new Set(pq16_1.questions.filter((question) => screenInQuestionIds.includes(question.id)).map((question) => question.id));
    const pq16Answers = answers.filter((answer) => pq16QuestionIds.has(answer.questionId));
    const score = pq16Answers.reduce((acc, pq16Answer) => {
        var _a, _b, _c;
        const question = pq16_1.questions.find((q) => q.id === pq16Answer.questionId);
        if ((question === null || question === void 0 ? void 0 : question.__typename) !== 'RestrictedChoiceQuestion' ||
            (((_a = pq16Answer === null || pq16Answer === void 0 ? void 0 : pq16Answer.chosenOptionIds) === null || _a === void 0 ? void 0 : _a.length) && ((_b = pq16Answer === null || pq16Answer === void 0 ? void 0 : pq16Answer.chosenOptionIds) === null || _b === void 0 ? void 0 : _b.length) < 1) ||
            ((_c = pq16Answer === null || pq16Answer === void 0 ? void 0 : pq16Answer.chosenOptionIds) === null || _c === void 0 ? void 0 : _c.some((id) => id === null || id === undefined)))
            throw new Error('All PQ16 answers should be single choice');
        const { value: normalisedScore } = question.answers.find((possibleAnswer) => { var _a; return possibleAnswer.id === ((_a = pq16Answer.chosenOptionIds) === null || _a === void 0 ? void 0 : _a[0]); });
        return Number(acc) + Math.round(Number(normalisedScore) * adjustmentFactor);
    }, 0);
    if (!score && score !== 0)
        return undefined;
    const riskLevel = `Heightened risk of emerging psychosis: ${score < 6 ? 'No' : 'Yes'} (cutoff score >= 6)`;
    return { score: `${score} out of ${totalScore}`, riskLevel };
}
exports.calculatePq16 = calculatePq16;
function calculateGad7(answers) {
    const totalScore = 21;
    const adjustmentFactor = 3;
    const gad7QuestionIds = new Set(GAD7_1.questions.filter((q) => q.id !== 'GAD7-content').map((question) => question.id));
    const gad7Answers = answers.filter((answer) => gad7QuestionIds.has(answer.questionId));
    const gad7Score = gad7Answers.reduce((acc, gad7Answer) => {
        var _a, _b, _c;
        const question = GAD7_1.questions.find((q) => q.id === gad7Answer.questionId);
        if ((question === null || question === void 0 ? void 0 : question.__typename) !== 'RestrictedChoiceQuestion' ||
            (((_a = gad7Answer === null || gad7Answer === void 0 ? void 0 : gad7Answer.chosenOptionIds) === null || _a === void 0 ? void 0 : _a.length) && ((_b = gad7Answer === null || gad7Answer === void 0 ? void 0 : gad7Answer.chosenOptionIds) === null || _b === void 0 ? void 0 : _b.length) < 1) ||
            ((_c = gad7Answer === null || gad7Answer === void 0 ? void 0 : gad7Answer.chosenOptionIds) === null || _c === void 0 ? void 0 : _c.some((id) => id === null || id === undefined)))
            throw new Error('All GAD7 answers should be single choice');
        const { value } = question.answers.find((possibleAnswer) => { var _a; return possibleAnswer.id === ((_a = gad7Answer.chosenOptionIds) === null || _a === void 0 ? void 0 : _a[0]); });
        return Number(acc) + Math.round(Number(value) * adjustmentFactor);
    }, 0);
    let severity = 'Minimal (0-4)';
    if (gad7Score >= 5)
        severity = 'Mild (5-9)';
    if (gad7Score >= 10)
        severity = 'Moderate (10-14)';
    if (gad7Score >= 15)
        severity = 'Severe (>= 15)';
    return {
        score: `${gad7Score} out of ${totalScore}`,
        severity,
    };
}
exports.calculateGad7 = calculateGad7;
function calculatePcl5(answers) {
    const supplementalQuestionIds = [
        'PCL5-content-1',
        'PCL5-content-2',
        'PCL5-1',
        'PCL5-2',
        'PCL5-3',
        'PCL5-4',
        'PCL5-5',
        'PCL5-6',
        'PCL5-7',
        'PCL5-8',
    ];
    const totalScore = 80;
    const pcl5Score = (0, calculateScoreFromNormalized_1.calculateScoreFromNormalized)(answers, PCL5_1.questions.filter((question) => !supplementalQuestionIds.includes(question.id)).map((question) => question.id), PCL5_1.questions, 4);
    if (!pcl5Score && pcl5Score !== 0) {
        console.error('Could not calculate PCL-5 score');
        return undefined;
    }
    return {
        score: `${pcl5Score} out of ${totalScore}`,
        severity: pcl5Score >= 31 ? 'PTSD probable (>= 31)' : 'PTSD not probable (0-30)',
    };
}
exports.calculatePcl5 = calculatePcl5;
function calculateScoff(answers) {
    const excludedQuestions = ['SCOFF-content', 'SCOFF-6', 'SCOFF-7'];
    const filteredScoffQuestions = SCOFF_1.questions.filter((q) => !excludedQuestions.includes(q.id));
    const scoffScore = (0, calculateScoreFromNormalized_1.calculateScoreFromNormalized)(answers, filteredScoffQuestions.map((q) => q.id), filteredScoffQuestions, 1);
    if (!scoffScore && scoffScore !== 0) {
        console.error('Could not calculate SCOFF score');
        return undefined;
    }
    return {
        severity: scoffScore >= 2 ? 'Likely (>= 2)' : 'Not likely (0-1)',
    };
}
exports.calculateScoff = calculateScoff;
function calculatePHQ9(answers) {
    const filteredPHQQuestions = PHQ9_1.questions.filter((q) => q.id !== 'PHQ-content');
    const expectedIds = filteredPHQQuestions.map((q) => q.id);
    const score = (0, calculateScoreFromNormalized_1.calculateScoreFromNormalized)(answers, expectedIds, filteredPHQQuestions, 3);
    if (!score && score !== 0)
        return undefined;
    return { score: String(score) };
}
exports.calculatePHQ9 = calculatePHQ9;
function calculateISI(answers) {
    const filteredISIQuestions = ISI_1.questions.filter((q) => q.id !== 'ISI-content');
    const expectedIds = filteredISIQuestions.map((q) => q.id);
    const score = (0, calculateScoreFromNormalized_1.calculateScoreFromNormalized)(answers, expectedIds, filteredISIQuestions, 4);
    if (!score && score !== 0)
        return undefined;
    return { score: String(score) };
}
exports.calculateISI = calculateISI;
function calculateMSI(answers) {
    const expectedIds = MSIBPD_1.questions.map((q) => q.id);
    const score = (0, calculateScoreFromNormalized_1.calculateScoreFromNormalized)(answers, expectedIds, MSIBPD_1.questions, 1);
    if (!score && score !== 0)
        return undefined;
    return { score: String(score) };
}
exports.calculateMSI = calculateMSI;
function calculateWURS(answers) {
    const questionSubsections = {
        anxietyDisphoria: ['WURS2', 'WURS9', 'WURS11', 'WURS17'],
        adhd: ['WURS1', 'WURS3', 'WURS4', 'WURS7', 'WURS15', 'WURS16', 'WURS23', 'WURS24', 'WURS25'],
        disruptive: [
            'WURS5',
            'WURS6',
            'WURS8',
            'WURS10',
            'WURS12',
            'WURS13',
            'WURS14',
            'WURS18',
            'WURS19',
            'WURS21',
            'WURS22',
        ],
    };
    const wursAnswers = answers.filter((answer) => answer.questionId.startsWith('WURS'));
    if (wursAnswers.length === 0)
        return undefined;
    if (wursAnswers.length !== 25)
        throw new Error('Incorrect number of WURS questions: ' + JSON.stringify(wursAnswers));
    const total = wursAnswers.reduce((acc, answer) => {
        const correspondingQuestion = ADHD_1.questions.find((question) => question.id === answer.questionId);
        if (!correspondingQuestion)
            throw new Error('No WURS question found for :' + answer.questionId);
        if ('answers' in correspondingQuestion) {
            const correspondingAnswer = correspondingQuestion.answers.find((qAnswer) => { var _a; return (_a = answer.chosenOptionIds) === null || _a === void 0 ? void 0 : _a.includes(qAnswer.id); });
            if (!correspondingAnswer)
                throw new Error('No answers found in ADHD WURS question answers for: ' + JSON.stringify(answer.chosenOptionIds));
            if (null === correspondingAnswer.value || undefined === correspondingAnswer.value)
                throw new Error('No answer value found in ADHD WURS question answers for: ' +
                    JSON.stringify(answer.chosenOptionIds));
            acc.main = acc.main + correspondingAnswer.value;
            if (questionSubsections.anxietyDisphoria.includes(answer.questionId)) {
                acc.anxietyDisphoria = acc.anxietyDisphoria + correspondingAnswer.value;
            }
            if (questionSubsections.adhd.includes(answer.questionId)) {
                acc.adhd = acc.adhd + correspondingAnswer.value;
            }
            if (questionSubsections.disruptive.includes(answer.questionId)) {
                acc.disruptive = acc.disruptive + correspondingAnswer.value;
            }
        }
        else {
            throw new Error('not a RestrictedChoiceQuestion: ' + correspondingQuestion.id);
        }
        return acc;
    }, { main: 0, disruptive: 0, adhd: 0, anxietyDisphoria: 0 });
    const adjustmentFactor = 4;
    const adjustedResults = {
        main: String(total.main * adjustmentFactor),
        disruptive: String(Math.round((100 * (total.disruptive * adjustmentFactor)) / questionSubsections.disruptive.length) / 100),
        anxietyDisphoria: String(Math.round((100 * (total.anxietyDisphoria * adjustmentFactor)) / questionSubsections.anxietyDisphoria.length) / 100),
        adhd: String(Math.round((100 * (total.adhd * adjustmentFactor)) / questionSubsections.adhd.length) / 100),
    };
    return adjustedResults;
}
exports.calculateWURS = calculateWURS;
function calculateASRS(answers) {
    const DEFAULT_THRESHOLD_OF_OFTEN = 0.75;
    const DEFAULT_THRESHOLD_OF_SOMETIMES = 0.5;
    const NUMBER_OF_QUESTIONS_IN_PART_A = 6;
    const QUESTION_NUMBER_CLINCAL_AT_SOMETIMES = [1, 2, 3, 9, 12, 16, 18];
    const asrsAnswers = answers.filter((answer) => answer.questionId.startsWith('ASRS'));
    const partAValid = ['ASRS1', 'ASRS2', 'ASRS3', 'ASRS4', 'ASRS5', 'ASRS6'].every((qId) => asrsAnswers.map((answers) => answers.questionId).includes(qId));
    const partBValid = [
        'ASRS7',
        'ASRS8',
        'ASRS9',
        'ASRS10',
        'ASRS11',
        'ASRS12',
        'ASRS13',
        'ASRS14',
        'ASRS15',
        'ASRS16',
    ].every((qId) => asrsAnswers.map((answers) => answers.questionId).includes(qId));
    const total = asrsAnswers.reduce((acc, answer) => {
        const correspondingQuestion = ADHD_1.questions.find((question) => question.id === answer.questionId);
        if (!correspondingQuestion)
            throw new Error('No ASRS question found for :' + answer.questionId);
        if ('answers' in correspondingQuestion) {
            const correspondingAnswer = correspondingQuestion.answers.find((qAnswer) => { var _a; return (_a = answer.chosenOptionIds) === null || _a === void 0 ? void 0 : _a.includes(qAnswer.id); });
            if (!correspondingAnswer)
                throw new Error('No answers found in ADHD ASRS question answers for: ' + JSON.stringify(answer.chosenOptionIds));
            if (null === correspondingAnswer.value || undefined === correspondingAnswer.value)
                throw new Error('No answer value found in ADHD ASRS question answers for: ' +
                    JSON.stringify(answer.chosenOptionIds));
            const questionNumber = parseInt(answer.questionId.replace('ASRS', ''));
            if (isNaN(questionNumber)) {
                throw new Error('Question Number not found for: ' + answer.questionId);
            }
            let increaseScore = false;
            if (QUESTION_NUMBER_CLINCAL_AT_SOMETIMES.includes(questionNumber) &&
                correspondingAnswer.value >= DEFAULT_THRESHOLD_OF_SOMETIMES) {
                increaseScore = true;
            }
            else if (correspondingAnswer.value >= DEFAULT_THRESHOLD_OF_OFTEN) {
                increaseScore = true;
            }
            if (increaseScore) {
                if (questionNumber <= NUMBER_OF_QUESTIONS_IN_PART_A) {
                    acc.partA++;
                }
                else {
                    acc.partB++;
                }
            }
        }
        else {
            throw new Error('not a RestrictedChoiceQuestion: ' + correspondingQuestion.id);
        }
        return acc;
    }, { partA: 0, partB: 0 });
    const returnValue = {
        threshold: partAValid ? (total.partA >= 4 ? 'Yes' : 'No') : undefined,
        partA: partAValid ? String(total.partA) : undefined,
        partB: partBValid ? String(total.partB) : undefined,
    };
    return returnValue;
}
exports.calculateASRS = calculateASRS;
function mdqSeverity(allAnswers) {
    const MAIN_QUESTIONS_PREFIX = 'MDQ-1';
    const SECOND_QUESTION = 'MDQ-2';
    const THIRD_QUESTION = 'MDQ-3';
    const mainQuestions = MDQ_1.questions.filter((q) => q.id.startsWith(MAIN_QUESTIONS_PREFIX));
    const mainSectionTotal = totalScore(allAnswers, mainQuestions);
    const secondTotal = totalScore(allAnswers, MDQ_1.questions.filter((q) => SECOND_QUESTION === q.id));
    const thirdTotal = totalScore(allAnswers, MDQ_1.questions.filter((q) => THIRD_QUESTION === q.id));
    if (mainSectionTotal === undefined || secondTotal === undefined || thirdTotal === undefined)
        return undefined;
    let thresholdSymptomString = 'Further medical assessment for bipolar disorder is NOT clearly warranted.';
    if (mainSectionTotal >= 7 && secondTotal === 1 && thirdTotal >= 2) {
        thresholdSymptomString = 'Further medical assessment for bipolar disorder is clearly warranted.';
    }
    return {
        total: `Screening result: ${mainSectionTotal}/13 symptoms endorsed, ${secondTotal ? '' : 'not '}within the time period, and ${thirdTotal >= 2 ? '' : 'no '}functional impairment reported`,
        severity: thresholdSymptomString,
    };
}
exports.mdqSeverity = mdqSeverity;
function totalScore(allAnswers, conditionQuestions, questionIdsToIgnore = [], adjustmentFactor = 'implicit') {
    let score = 0;
    for (const question of conditionQuestions.filter((q) => !questionIdsToIgnore.includes(q.id))) {
        const answer = allAnswers.find((answer) => answer.questionId === question.id);
        if (question.__typename !== 'RestrictedChoiceQuestion')
            return undefined;
        if (adjustmentFactor === 'implicit')
            adjustmentFactor = question.answers.length - 1;
        const q_answer_id = question.answers.find((q_answers) => { var _a; return (_a = answer === null || answer === void 0 ? void 0 : answer.chosenOptionIds) === null || _a === void 0 ? void 0 : _a.includes(q_answers.id); });
        if ((q_answer_id === null || q_answer_id === void 0 ? void 0 : q_answer_id.value) === undefined || (q_answer_id === null || q_answer_id === void 0 ? void 0 : q_answer_id.value) === null)
            return undefined;
        score += adjustmentFactor * q_answer_id.value;
    }
    return Math.round(score);
}
exports.totalScore = totalScore;
