"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "CombinedAnswersSummary",
            "questionsIdGroups": [
                [
                    "AUDIT-1"
                ],
                [
                    "AUDIT-2"
                ],
                [
                    "AUDIT-3"
                ],
                [
                    "AUDIT-4"
                ],
                [
                    "AUDIT-5"
                ],
                [
                    "AUDIT-6"
                ],
                [
                    "AUDIT-7"
                ],
                [
                    "AUDIT-8"
                ],
                [
                    "AUDIT-9"
                ],
                [
                    "AUDIT-10"
                ]
            ],
            "groupsType": "Mean",
            "pattern": "{}",
            "coefficients": {
                "0": "Low risk (0-7)",
                "0.2": "Increasing risk (8-15)",
                "0.4": "Higher risk (16-19)",
                "0.5": "Possible dependence (>= 20)"
            }
        },
        "section": [
            "Outcome"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "1. How often do you have a drink containing alcohol? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AUDIT-1",
                    "optionSummaries": {
                        "66b9082e-dfcf-4973-9817-f1dab293e240": "0 - Never",
                        "8b3db405-a38e-47af-9923-0bd1347296ce": "1 - Monthly or less",
                        "14b51ddd-26f1-4e83-b814-8d535c35a1a5": "2 - 2 to 4 times per month",
                        "d68c3c0d-8793-4d0d-ba25-612d0be95528": "3 - 2 to 3 times per week",
                        "c99092eb-2f5c-4a7c-8919-b017b7309666": "4 - 4 times or more per week"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "2. How many units of alcohol do you drink on a typical day when you are drinking? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AUDIT-2",
                    "optionSummaries": {
                        "c94027a7-5533-4c72-a225-49e3334ad17e": "0 - 0 to 2",
                        "db170668-e8cf-4f01-97b6-a825c5a83d54": "1 - 3 to 4",
                        "bb56c814-b684-4e67-ab87-923428b45855": "2 - 5 to 6",
                        "c95818c7-9d1b-42d8-bdfc-106c38c7b390": "3 - 7 to 9",
                        "ba319391-78dd-495f-be81-525555a7fe00": "4 - 10 or more"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "3. How often have you had 6 or more units if female, or 8 or more if male, on a single occasion in the last year? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AUDIT-3",
                    "optionSummaries": {
                        "e8131416-845e-4a8b-b07a-21eadf6ba8b4": "0 - Never",
                        "b60b3e1f-0ff8-4a62-9cb9-cf9df7f960b1": "1 - Less than monthly",
                        "b0f08bde-9b0b-4766-a74f-237166df9d45": "2 - Monthly",
                        "6c8da46b-9087-43c5-aaa4-3290476cc2b3": "3 - Weekly",
                        "625105b1-8158-40e0-966d-40a53a0bb772": "4 - Daily or almost daily"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "4. How often during the last year have you found that you were not able to stop drinking once you had started? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AUDIT-4",
                    "optionSummaries": {
                        "8ea33a08-4f05-4a0a-a1fa-9c8a54b5cbb3": "0 - Never",
                        "4980e75f-0b9a-4975-a983-486367ce2ae9": "1 - Less than monthly",
                        "dd1c6973-3361-445b-99bc-01015cfc04cc": "2 - Monthly",
                        "b6da52df-3358-4778-9782-b1fe7410bf2e": "3 - Weekly",
                        "37c07351-9877-4776-b297-3b5f119348d8": "4 - Daily or almost daily"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "5. How often during the last year have you failed to do what was normally expected from you because of your drinking? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AUDIT-5",
                    "optionSummaries": {
                        "772d916d-15aa-4c36-b50a-b1a0299e40fc": "0 - Never",
                        "05ec6098-e563-4c80-be2a-5f574f9ff065": "1 - Less than monthly",
                        "4e5086e4-49e2-47eb-88ae-b5b19cdb67ad": "2 - Monthly",
                        "c03a12e1-9c6a-41b8-a058-7f45811562eb": "3 - Weekly",
                        "04fd0e4f-4e03-46e4-9d2b-cb692e76ef29": "4 - Daily or almost daily"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "6. How often in the last year have you needed an alcoholic drink in the morning to get yourself going after a heavy drinking session? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AUDIT-6",
                    "optionSummaries": {
                        "01c4bcae-b412-4260-a300-43690e9692c9": "0 - Never",
                        "7c35cc42-4aaa-456a-9add-4ff7f3f3fc6d": "1 - Less than monthly",
                        "e9c092cd-9633-461e-9cb6-618f23b22b41": "2 - Monthly",
                        "da3132a9-2b85-4268-9e78-4884faa8ab1b": "3 - Weekly",
                        "8ad21db5-05ab-4fc0-a928-104236164f00": "4 - Daily or almost daily"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "7. How often during the last year have you had a feeling of guilt or remorse after drinking? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AUDIT-7",
                    "optionSummaries": {
                        "e20b71bf-2bf7-4e08-b749-756263308ade": "0 - Never",
                        "7b01ab3b-14c0-4794-b77f-c581ce825c4b": "1 - Less than monthly",
                        "bc95fbe5-f6eb-4633-b288-cf1c1946bbe0": "2 - Monthly",
                        "f01a2bd5-1955-4ee6-a6e7-260bd2a27500": "3 - Weekly",
                        "a979ac31-ad56-49b9-82c3-7f2f3f95f309": "4 - Daily or almost daily"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "8. How often during the last year have you been unable to remember what happened the night before because you had been drinking? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AUDIT-8",
                    "optionSummaries": {
                        "b23cc03d-1b42-478f-b416-d82c59ef1875": "0 - Never",
                        "f3cb7dc2-a20d-41b9-b3bc-18e528fce4c2": "1 - Less than monthly",
                        "b8aa110e-e663-4a52-8feb-34e985762d31": "2 - Monthly",
                        "0b3f3ae8-18de-4f14-99f4-601ec57e5337": "3 - Weekly",
                        "6a3b8732-f416-4ced-b94e-da929d8166cd": "4 - Daily or almost daily"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "9. Have you or somebody else been injured as a result of your drinking? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AUDIT-9",
                    "optionSummaries": {
                        "be1cd10b-7265-4776-a450-52241d735341": "0 - No",
                        "d659e645-41dc-42e4-bc8b-1780402d9bf0": "2 - Yes, but no in the last year",
                        "bdb862fb-e9ba-4061-a268-6a31db606ebf": "4 - Yes, during the last year"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "10. Has a relative or friend, doctor or other health worker been concerned about your drinking or suggested that you cut down? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "AUDIT-10",
                    "optionSummaries": {
                        "01cf2d78-83cd-4a49-a3d2-9420ba4dc56d": "0 - No",
                        "54a6fb22-5c4e-49f4-818f-485d1b8a2c73": "2 - Yes, but no in the last year",
                        "40cdc210-3a2e-426d-b16d-303730853310": "4 - Yes, during the last year"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    }
];
