"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
exports.questions = [
    {
        "__typename": "ContentQuestion",
        "id": "AUDIT-content-1",
        "text": "Thank you for completing that section. Please take a break if you need one. \n\nThe next few questions are about alcohol and drug use. We ask everyone these questions as they can affect mental health and wellbeing. Your honest answers help us understand your situation better. \n\nSelect 'Continue' to start.",
        "answers": [
            {
                "__typename": "Option",
                "id": "3490cadb-6e14-45a8-a530-e4b927a7f7d6",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "ContentQuestion",
        "id": "AUDIT-content-2",
        "text": "The next set of questions are about your use of and relationship with alcohol during the past 12 months.\n\nBefore the questions, we want to provide you with some information about units of alcohol.\n\nDifferent drinks contain different amounts of alcohol, measured in units. The amount of units in an alcoholic drink change depending on the size of the drink and the strength of the drink, referred to as ABV. Here is a quick guide:\n\n- Single small shot of spirits (25ml, 40% ABV): 1 unit\n- Alcopop (275ml, 4.6% ABV): 1.3 units\n- Small glass of wine (125ml, 12% ABV): 1.5 units\n- Bottle of beer/lager/cider (330ml, 5% ABV): 1.7 units\n- Can of beer/lager/cider (440ml, 5.5% ABV): 2.4 units\n- Pint of lower-strength beer/lager/cider (3.6% ABV): 2 units\n- Standard glass of wine (175ml, 12% ABV): 2.1 units\n- Pint of higher-strength beer/lager/cider (5.2% ABV): 3 units\n- Large glass of wine (250ml, 12% ABV): 3 units\n\nSelect 'Continue' to start.",
        "answers": [
            {
                "__typename": "Option",
                "id": "5587cece-d379-4ec9-989d-353f694960dd",
                "text": "continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "66b9082e-dfcf-4973-9817-f1dab293e240",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "8b3db405-a38e-47af-9923-0bd1347296ce",
                "text": "Monthly or less",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "14b51ddd-26f1-4e83-b814-8d535c35a1a5",
                "text": "2 to 4 times per month",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "d68c3c0d-8793-4d0d-ba25-612d0be95528",
                "text": "2 to 3 times per week",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "c99092eb-2f5c-4a7c-8919-b017b7309666",
                "text": "4 times or more per week",
                "value": 1
            }
        ],
        "id": "AUDIT-1",
        "text": "How often do you have a drink containing alcohol?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "c94027a7-5533-4c72-a225-49e3334ad17e",
                "text": "0 to 2",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "db170668-e8cf-4f01-97b6-a825c5a83d54",
                "text": "3 to 4",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "bb56c814-b684-4e67-ab87-923428b45855",
                "text": "5 to 6",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "c95818c7-9d1b-42d8-bdfc-106c38c7b390",
                "text": "7 to 9",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "ba319391-78dd-495f-be81-525555a7fe00",
                "text": "10 or more",
                "value": 1
            }
        ],
        "id": "AUDIT-2",
        "text": "How many units of alcohol do you drink on a typical day when you are drinking?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e8131416-845e-4a8b-b07a-21eadf6ba8b4",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b60b3e1f-0ff8-4a62-9cb9-cf9df7f960b1",
                "text": "Less than monthly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "b0f08bde-9b0b-4766-a74f-237166df9d45",
                "text": "Monthly",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "6c8da46b-9087-43c5-aaa4-3290476cc2b3",
                "text": "Weekly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "625105b1-8158-40e0-966d-40a53a0bb772",
                "text": "Daily or almost daily",
                "value": 1
            }
        ],
        "id": "AUDIT-3",
        "text": "How often have you had 6 or more units if female, or 8 or more if male, on a single occasion in the last year?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8ea33a08-4f05-4a0a-a1fa-9c8a54b5cbb3",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "4980e75f-0b9a-4975-a983-486367ce2ae9",
                "text": "Less than monthly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "dd1c6973-3361-445b-99bc-01015cfc04cc",
                "text": "Monthly",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "b6da52df-3358-4778-9782-b1fe7410bf2e",
                "text": "Weekly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "37c07351-9877-4776-b297-3b5f119348d8",
                "text": "Daily or almost daily",
                "value": 1
            }
        ],
        "id": "AUDIT-4",
        "text": "How often during the last year have you found that you were not able to stop drinking once you had started?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "772d916d-15aa-4c36-b50a-b1a0299e40fc",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "05ec6098-e563-4c80-be2a-5f574f9ff065",
                "text": "Less than monthly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "4e5086e4-49e2-47eb-88ae-b5b19cdb67ad",
                "text": "Monthly",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "c03a12e1-9c6a-41b8-a058-7f45811562eb",
                "text": "Weekly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "04fd0e4f-4e03-46e4-9d2b-cb692e76ef29",
                "text": "Daily or almost daily",
                "value": 1
            }
        ],
        "id": "AUDIT-5",
        "text": "How often during the last year have you failed to do what was normally expected from you because of your drinking?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "01c4bcae-b412-4260-a300-43690e9692c9",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7c35cc42-4aaa-456a-9add-4ff7f3f3fc6d",
                "text": "Less than monthly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "e9c092cd-9633-461e-9cb6-618f23b22b41",
                "text": "Monthly",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "da3132a9-2b85-4268-9e78-4884faa8ab1b",
                "text": "Weekly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "8ad21db5-05ab-4fc0-a928-104236164f00",
                "text": "Daily or almost daily",
                "value": 1
            }
        ],
        "id": "AUDIT-6",
        "text": "How often in the last year have you needed an alcoholic drink in the morning to get yourself going after a heavy drinking session?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e20b71bf-2bf7-4e08-b749-756263308ade",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7b01ab3b-14c0-4794-b77f-c581ce825c4b",
                "text": "Less than monthly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "bc95fbe5-f6eb-4633-b288-cf1c1946bbe0",
                "text": "Monthly",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "f01a2bd5-1955-4ee6-a6e7-260bd2a27500",
                "text": "Weekly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "a979ac31-ad56-49b9-82c3-7f2f3f95f309",
                "text": "Daily or almost daily",
                "value": 1
            }
        ],
        "id": "AUDIT-7",
        "text": "How often during the last year have you had a feeling of guilt or remorse after drinking?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b23cc03d-1b42-478f-b416-d82c59ef1875",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f3cb7dc2-a20d-41b9-b3bc-18e528fce4c2",
                "text": "Less than monthly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "b8aa110e-e663-4a52-8feb-34e985762d31",
                "text": "Monthly",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "0b3f3ae8-18de-4f14-99f4-601ec57e5337",
                "text": "Weekly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "6a3b8732-f416-4ced-b94e-da929d8166cd",
                "text": "Daily or almost daily",
                "value": 1
            }
        ],
        "id": "AUDIT-8",
        "text": "How often during the last year have you been unable to remember what happened the night before because you had been drinking?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "be1cd10b-7265-4776-a450-52241d735341",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d659e645-41dc-42e4-bc8b-1780402d9bf0",
                "text": "Yes, but not in the last year",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "bdb862fb-e9ba-4061-a268-6a31db606ebf",
                "text": "Yes, during the last year",
                "value": 1
            }
        ],
        "id": "AUDIT-9",
        "text": "Have you or somebody else been injured as a result of your drinking?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "01cf2d78-83cd-4a49-a3d2-9420ba4dc56d",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "54a6fb22-5c4e-49f4-818f-485d1b8a2c73",
                "text": "Yes, but not in the last year",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "40cdc210-3a2e-426d-b16d-303730853310",
                "text": "Yes, during the last year",
                "value": 1
            }
        ],
        "id": "AUDIT-10",
        "text": "Has a relative or friend, doctor or other health worker been concerned about your drinking or suggested that you cut down?",
        "isMultiselect": false
    }
];
exports.aspects = {
    "audit": {
        "type": "mean",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AUDIT-1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "66b9082e-dfcf-4973-9817-f1dab293e240": 0,
                            "8b3db405-a38e-47af-9923-0bd1347296ce": 0.25,
                            "14b51ddd-26f1-4e83-b814-8d535c35a1a5": 0.5,
                            "d68c3c0d-8793-4d0d-ba25-612d0be95528": 0.75,
                            "c99092eb-2f5c-4a7c-8919-b017b7309666": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AUDIT-2",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "c94027a7-5533-4c72-a225-49e3334ad17e": 0,
                            "db170668-e8cf-4f01-97b6-a825c5a83d54": 0.25,
                            "bb56c814-b684-4e67-ab87-923428b45855": 0.5,
                            "c95818c7-9d1b-42d8-bdfc-106c38c7b390": 0.75,
                            "ba319391-78dd-495f-be81-525555a7fe00": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AUDIT-3",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "e8131416-845e-4a8b-b07a-21eadf6ba8b4": 0,
                            "b60b3e1f-0ff8-4a62-9cb9-cf9df7f960b1": 0.25,
                            "b0f08bde-9b0b-4766-a74f-237166df9d45": 0.5,
                            "6c8da46b-9087-43c5-aaa4-3290476cc2b3": 0.75,
                            "625105b1-8158-40e0-966d-40a53a0bb772": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AUDIT-4",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "8ea33a08-4f05-4a0a-a1fa-9c8a54b5cbb3": 0,
                            "4980e75f-0b9a-4975-a983-486367ce2ae9": 0.25,
                            "dd1c6973-3361-445b-99bc-01015cfc04cc": 0.5,
                            "b6da52df-3358-4778-9782-b1fe7410bf2e": 0.75,
                            "37c07351-9877-4776-b297-3b5f119348d8": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AUDIT-5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "772d916d-15aa-4c36-b50a-b1a0299e40fc": 0,
                            "05ec6098-e563-4c80-be2a-5f574f9ff065": 0.25,
                            "4e5086e4-49e2-47eb-88ae-b5b19cdb67ad": 0.5,
                            "c03a12e1-9c6a-41b8-a058-7f45811562eb": 0.75,
                            "04fd0e4f-4e03-46e4-9d2b-cb692e76ef29": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AUDIT-6",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "01c4bcae-b412-4260-a300-43690e9692c9": 0,
                            "7c35cc42-4aaa-456a-9add-4ff7f3f3fc6d": 0.25,
                            "e9c092cd-9633-461e-9cb6-618f23b22b41": 0.5,
                            "da3132a9-2b85-4268-9e78-4884faa8ab1b": 0.75,
                            "8ad21db5-05ab-4fc0-a928-104236164f00": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AUDIT-7",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "e20b71bf-2bf7-4e08-b749-756263308ade": 0,
                            "7b01ab3b-14c0-4794-b77f-c581ce825c4b": 0.25,
                            "bc95fbe5-f6eb-4633-b288-cf1c1946bbe0": 0.5,
                            "f01a2bd5-1955-4ee6-a6e7-260bd2a27500": 0.75,
                            "a979ac31-ad56-49b9-82c3-7f2f3f95f309": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AUDIT-8",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "b23cc03d-1b42-478f-b416-d82c59ef1875": 0,
                            "f3cb7dc2-a20d-41b9-b3bc-18e528fce4c2": 0.25,
                            "b8aa110e-e663-4a52-8feb-34e985762d31": 0.5,
                            "0b3f3ae8-18de-4f14-99f4-601ec57e5337": 0.75,
                            "6a3b8732-f416-4ced-b94e-da929d8166cd": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AUDIT-9",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "be1cd10b-7265-4776-a450-52241d735341": 0,
                            "d659e645-41dc-42e4-bc8b-1780402d9bf0": 0.5,
                            "bdb862fb-e9ba-4061-a268-6a31db606ebf": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AUDIT-10",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "01cf2d78-83cd-4a49-a3d2-9420ba4dc56d": 0,
                            "54a6fb22-5c4e-49f4-818f-485d1b8a2c73": 0.5,
                            "40cdc210-3a2e-426d-b16d-303730853310": 1
                        }
                    }
                }
            }
        ]
    }
};
exports.tests = {
    "audit": [
        {
            "name": "AUDIT - 0 score-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0,
                "threshold": 0
            },
            "answers": {
                "AUDIT-1": {
                    "questionId": "AUDIT-1",
                    "chosenOptionIds": [
                        "66b9082e-dfcf-4973-9817-f1dab293e240"
                    ]
                },
                "AUDIT-2": {
                    "questionId": "AUDIT-2",
                    "chosenOptionIds": [
                        "c94027a7-5533-4c72-a225-49e3334ad17e"
                    ]
                },
                "AUDIT-3": {
                    "questionId": "AUDIT-3",
                    "chosenOptionIds": [
                        "e8131416-845e-4a8b-b07a-21eadf6ba8b4"
                    ]
                },
                "AUDIT-4": {
                    "questionId": "AUDIT-4",
                    "chosenOptionIds": [
                        "8ea33a08-4f05-4a0a-a1fa-9c8a54b5cbb3"
                    ]
                },
                "AUDIT-5": {
                    "questionId": "AUDIT-5",
                    "chosenOptionIds": [
                        "772d916d-15aa-4c36-b50a-b1a0299e40fc"
                    ]
                },
                "AUDIT-6": {
                    "questionId": "AUDIT-6",
                    "chosenOptionIds": [
                        "01c4bcae-b412-4260-a300-43690e9692c9"
                    ]
                },
                "AUDIT-7": {
                    "questionId": "AUDIT-7",
                    "chosenOptionIds": [
                        "e20b71bf-2bf7-4e08-b749-756263308ade"
                    ]
                },
                "AUDIT-8": {
                    "questionId": "AUDIT-8",
                    "chosenOptionIds": [
                        "b23cc03d-1b42-478f-b416-d82c59ef1875"
                    ]
                },
                "AUDIT-9": {
                    "questionId": "AUDIT-9",
                    "chosenOptionIds": [
                        "be1cd10b-7265-4776-a450-52241d735341"
                    ]
                },
                "AUDIT-10": {
                    "questionId": "AUDIT-10",
                    "chosenOptionIds": [
                        "01cf2d78-83cd-4a49-a3d2-9420ba4dc56d"
                    ]
                }
            }
        },
        {
            "name": "AUDIT - 10 score-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 1,
                "threshold": 0
            },
            "answers": {
                "AUDIT-1": {
                    "questionId": "AUDIT-1",
                    "chosenOptionIds": [
                        "c99092eb-2f5c-4a7c-8919-b017b7309666"
                    ]
                },
                "AUDIT-2": {
                    "questionId": "AUDIT-2",
                    "chosenOptionIds": [
                        "ba319391-78dd-495f-be81-525555a7fe00"
                    ]
                },
                "AUDIT-3": {
                    "questionId": "AUDIT-3",
                    "chosenOptionIds": [
                        "625105b1-8158-40e0-966d-40a53a0bb772"
                    ]
                },
                "AUDIT-4": {
                    "questionId": "AUDIT-4",
                    "chosenOptionIds": [
                        "37c07351-9877-4776-b297-3b5f119348d8"
                    ]
                },
                "AUDIT-5": {
                    "questionId": "AUDIT-5",
                    "chosenOptionIds": [
                        "04fd0e4f-4e03-46e4-9d2b-cb692e76ef29"
                    ]
                },
                "AUDIT-6": {
                    "questionId": "AUDIT-6",
                    "chosenOptionIds": [
                        "8ad21db5-05ab-4fc0-a928-104236164f00"
                    ]
                },
                "AUDIT-7": {
                    "questionId": "AUDIT-7",
                    "chosenOptionIds": [
                        "a979ac31-ad56-49b9-82c3-7f2f3f95f309"
                    ]
                },
                "AUDIT-8": {
                    "questionId": "AUDIT-8",
                    "chosenOptionIds": [
                        "6a3b8732-f416-4ced-b94e-da929d8166cd"
                    ]
                },
                "AUDIT-9": {
                    "questionId": "AUDIT-9",
                    "chosenOptionIds": [
                        "bdb862fb-e9ba-4061-a268-6a31db606ebf"
                    ]
                },
                "AUDIT-10": {
                    "questionId": "AUDIT-10",
                    "chosenOptionIds": [
                        "40cdc210-3a2e-426d-b16d-303730853310"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {};
