import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Heading, Text, Page } from '@psyomics/components'
import HeaderImage from '../../images/nhs-assessment-complete.svg?react'
import Styles from './CheckIn.module.scss'
import { LatestReport, AuthorisedReportsForUser } from 'censeo-core'
import { OrganisationContext } from 'ui/app/context/organisation'
import { useAuth } from 'registration/context/auth'
import Header from 'ui/app/components/Header'

interface AssessmentCompleteView {
    latestReport: LatestReport
    authorisedReportsData?: AuthorisedReportsForUser[]
}

export const AssessmentCompleteView: React.FC<AssessmentCompleteView> = ({ latestReport, authorisedReportsData }) => {
    const navigate = useNavigate()
    const { isD2CUser } = useAuth()
    const organisation = useContext(OrganisationContext)
    const id = latestReport.report.id

    return (
        <Page layout="standard" header={<Header patientFacing={true} />}>
            <div style={{ marginBottom: 'var(--s-6)' }}>
                <HeaderImage width={'100%'} className={Styles.headerImage} />
            </div>

            <Heading className={Styles.heading} el="h1" size="heading2" color="mid">
                Thank you for completing the assessment.
            </Heading>
            <div>
                {authorisedReportsData?.length ? (
                    <>
                        <Text color="mid" css={{ mb: 5 }}>
                            Your healthcare professional has shared your Psyomics Platform report with you.
                        </Text>
                        <Text color="mid" css={{ mb: 5 }}>
                            Select the &quot;View Report&quot; button below to see your report.
                        </Text>
                        <Text color="mid" css={{ mb: 5 }}>
                            Your Psyomics Platform report is generated using the answers you provided in the
                            pre-assessment questionnaire. It is designed to give you more information about some of the
                            symptoms that may be currently affecting you. You can access your report here on your
                            Psyomics Platform account, or you can also download and save it as a PDF.
                        </Text>
                        <div
                            className={Styles.completeContent}
                            style={{
                                marginTop: 'var(--s-4)',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 'var(--s-3)',
                                alignItems: 'stretch',
                            }}
                        >
                            {authorisedReportsData.map((report) => {
                                return (
                                    <div
                                        key={report.assessmentId}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            padding: 'var(--s-2)',
                                            borderRadius: '8px',
                                            backgroundColor: '#f9f9f9',
                                            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                                        }}
                                    >
                                        <Text>
                                            Assessment completed{' '}
                                            {new Date(report.createdAt).toLocaleDateString('en-GB', {
                                                timeZone: 'UTC',
                                            })}
                                        </Text>
                                        <Button
                                            size="large"
                                            appearance="primary"
                                            label="View Report"
                                            onClick={() => {
                                                navigate('/your-report/' + report.assessmentId)
                                            }}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                        <Text color="mid" css={{ mt: 5, mb: 7 }}>
                            If you require any technical support please contact{' '}
                            <a href="mailto:support@psyomics.com">support@psyomics.com</a>.
                        </Text>
                    </>
                ) : (
                    <div className={Styles.completeContent}>
                        {' '}
                        {organisation?.demo ? (
                            <div className={Styles.textLeft}>
                                <Text color="mid" size="medium" css={{ mb: 2 }}>
                                    That&#39;s the end of the demo assessment. The answers that you have selected have
                                    been analysed to create the below report, which you can view by clicking the below
                                    button.
                                </Text>
                                <Text color="mid" css={{ mb: 2 }}>
                                    This generated report is not visible to anyone else.
                                </Text>
                                <Text color="mid" css={{ mb: 2 }}>
                                    In the full version of Psyomics Platform, by default the report is sent to the
                                    patient / service user&apos;s care team. They then have the choice to share the
                                    report with the patient /service user.
                                </Text>
                            </div>
                        ) : (
                            <Text color="mid">View the report of your most recent assessment</Text>
                        )}
                        <div className={Styles.completeButtons}>
                            <Button
                                onClick={() =>
                                    navigate(
                                        `${organisation?.demo ? '/demo' : ''}/${
                                            isD2CUser ? 'your-report' : 'report'
                                        }/${id}`
                                    )
                                }
                                size="large"
                                label="View report"
                                appearance="primary"
                                className={Styles.viewReport}
                            ></Button>
                        </div>
                    </div>
                )}
            </div>
        </Page>
    )
}
