import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import { Input, Markdown, NumberInput, SegmentedButtons } from '@psyomics/components'
import QuestionPage from 'ui/app/components/QuestionPage'
import { Unit } from 'censeo-core'

interface Props {
    value: number | undefined | null
    onSelect: (value: number) => void
    onBack: () => void
    question: string
    unit: Unit
    skippable: string | undefined | null
    updating: boolean
}

const addDefault = (unit: Unit, value: number | undefined | null) => {
    if (value === undefined || value === null) {
        switch (unit) {
            case Unit.DurationHours:
                return 1 / 24
            case Unit.DurationMinutes:
                return 1 / (24 * 60)
            case Unit.None:
                return 0
        }
    }

    return value
}

export const FreeQuestion: React.FC<Props> = (props: Props) => {
    const [value, setValue] = useState(props.value)
    const valueWithDefault = addDefault(props.unit, value)
    const handleOk = () =>
        valueWithDefault !== undefined && valueWithDefault !== null && props.onSelect(valueWithDefault)
    const handleSkip = () => props.onSelect(-1) // If skipped, make it obvious using -1 as a value
    const okDisabled =
        valueWithDefault === undefined || valueWithDefault == null || valueWithDefault < (props.skippable ? 1 : 0)

    return (
        <QuestionPage
            onOk={handleOk}
            onSkip={handleSkip}
            onBack={props.onBack}
            skippable={props.skippable}
            okDisabled={okDisabled}
            backDisabled={props.updating}
            updating={props.updating}
            value={value}
        >
            <Markdown color="mid" css={{ mb: 4 }}>
                {props.question}
            </Markdown>
            <Selector
                onChange={setValue}
                value={valueWithDefault}
                setValue={setValue}
                unit={props.unit}
                skippable={props.skippable}
                updating={props.updating}
            />
        </QuestionPage>
    )
}

interface SelectorProps {
    value: number | undefined | null
    setValue: React.Dispatch<React.SetStateAction<number | null | undefined>>
    onChange: (value: number) => void
    unit: Unit
    skippable: string | undefined | null
    updating: boolean
}

const Selector: React.FC<SelectorProps> = (props) => {
    switch (props.unit) {
        case Unit.Age:
            return (
                <Input
                    css={{
                        mb: 3,
                    }}
                    fullWidth
                    placeholder="your age"
                    suffix="years old"
                    type="number"
                    value={props.value || undefined}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        props.onChange(Number(event.currentTarget.value))
                    }
                    readOnly={props.updating}
                />
            )
        // todo number input

        case Unit.None:
            // Todo make number input take an undefined value, for now the defaults should stop it from being zero
            return props.skippable ? (
                <NumberInputWithValidation value={props.value} setValue={props.onChange} />
            ) : (
                <NumberInput
                    value={props.value || 0}
                    step={1}
                    onChange={(value: number) => props.onChange(value)}
                    min={0}
                    readonly={props.updating}
                />
            )

        case Unit.DurationHours:
            return <Duration {...{ ...props, showMinutes: false }} />

        case Unit.DurationMinutes:
            return <Duration {...{ ...props, showMinutes: true }} />
    }

    return null
}

// TODO: move to components? ///////////////////////////////////////
const NumberInputWithValidation: React.FC<{
    value: unknown
    setValue: (value: number) => void
}> = ({ value, setValue }) => {
    const [error, setError] = useState<boolean>(false)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value
        if (inputValue === '' || (/^\d+$/.test(inputValue) && Number(inputValue) >= 0)) {
            setValue(Number(inputValue))
            setError(false)
        } else {
            setError(true)
        }
    }

    return (
        <TextField
            type="number"
            value={Number(value) > 0 ? value : undefined}
            onChange={handleChange}
            error={error}
            helperText={error ? 'Please enter a valid number' : ''}
            placeholder="Enter a number"
            fullWidth
            sx={{
                '& .MuiInputBase-root': {
                    borderRadius: 'var(--d-radius-md)',
                },
            }}
        />
    )
}
// TODO: end ///////////////////////////////////////////////////////

enum DurationUnit {
    Minutes = 'Minutes',
    Hours = 'Hours',
    Days = 'Days',
    Weeks = 'Weeks',
    Months = 'Months',
    Years = 'Years',
}
interface DurationProps extends SelectorProps {
    showMinutes: boolean
}

// Todo make number input take an undefined value, for now the defaults should stop it from being zero
const Duration: React.FC<DurationProps> = (props) => {
    const value = props.value || 0

    const units = props.showMinutes
        ? [DurationUnit.Minutes, DurationUnit.Hours, DurationUnit.Days, DurationUnit.Weeks, DurationUnit.Months]
        : [DurationUnit.Hours, DurationUnit.Days, DurationUnit.Weeks, DurationUnit.Months, DurationUnit.Years]

    const [initalUnit, initialHowMany] = chooseUnit(units, DurationUnit.Days, value)
    const [unit, setUnit] = React.useState(initalUnit)
    const [howMany, setHowMany] = React.useState(initialHowMany)

    const activeUnitIndex = units.indexOf(unit)

    return (
        <>
            <NumberInput
                value={howMany}
                step={1}
                css={{
                    mb: 6,
                }}
                min={0}
                onChange={(newValue: number) => {
                    const newHowMany = Number(newValue)
                    setHowMany(newHowMany)
                    props.onChange(toDays(unit, newHowMany))
                }}
                readonly={props.updating}
            />
            <SegmentedButtons
                fullWidth={true}
                name="duration"
                activeItem={activeUnitIndex}
                onChange={(e: any) => {
                    const newUnit = e.target.value
                    setUnit(newUnit)
                    props.onChange(toDays(newUnit, howMany))
                }}
                items={units.map((u, i) => ({
                    label: u,
                    id: u,
                    value: u,
                }))}
                readonly={props.updating}
            />
        </>
    )
}

const toDays = (unit: DurationUnit, howMany: number) =>
    (howMany / (inDays[unit].multiplier || 1)) * (inDays[unit].modulo || 1)

const inDays: {
    [duration in DurationUnit]: {
        modulo?: number
        multiplier?: number
    }
} = {
    [DurationUnit.Minutes]: {
        multiplier: 24 * 60,
    },
    [DurationUnit.Hours]: {
        multiplier: 24,
    },
    [DurationUnit.Days]: {},
    [DurationUnit.Weeks]: {
        modulo: 7,
    },
    [DurationUnit.Months]: {
        modulo: 30,
    },
    [DurationUnit.Years]: {
        modulo: 365,
    },
}

const chooseUnit = (units: DurationUnit[], zeroValueUnit: DurationUnit, value: number): [DurationUnit, number] => {
    if (value === 0) {
        return [zeroValueUnit, 0]
    }

    const unit =
        units
            .slice(0)
            .reverse()
            .find((u) => {
                const remainder = (value * (inDays[u].multiplier || 1)) % (inDays[u].modulo || 1)
                return remainder === 0
            }) || units[0]

    return [unit, (value * (inDays[unit].multiplier || 1)) / (inDays[unit].modulo || 1)]
}
