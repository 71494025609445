"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
exports.questions = [
    {
        "__typename": "ContentQuestion",
        "id": "ISI-content",
        "text": "The next questions are about whether you are having trouble sleeping at the moment (i.e., in the last 2 weeks). This can sometimes be referred to as insomnia. \n\nTake your time with these questions, and remember there are no right or wrong answers. \n\nSelect 'Continue' to start.",
        "answers": [
            {
                "__typename": "Option",
                "id": "a6e0b8c6-23e8-4ead-8ace-f7677877634b",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "87faf8ed-d6ad-479c-a442-3d8e936b350f",
                "text": "None",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "8e9e12f5-2bda-4112-97a0-e337871446a5",
                "text": "Mild",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "6d94b585-d8cb-4b3a-bccf-325a571656ab",
                "text": "Moderate",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "dc59d1a1-ad7d-462c-918e-64238c6a47bb",
                "text": "Severe",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "1b962c40-80e7-4101-8aeb-521beefe2819",
                "text": "Very",
                "value": 1
            }
        ],
        "id": "ISI-1a",
        "text": "Please rate the current (i.e., the past 2 weeks) **severity** of your insomnia problem(s):\n\n**Difficulty falling asleep**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "9b976912-dc17-43cf-950f-18df7204614b",
                "text": "None",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a0b6d1ad-3aa7-4315-a82a-7feff6cd4c08",
                "text": "Mild",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "472b8618-9643-4462-8764-f64687a08a1e",
                "text": "Moderate",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "44cf52c3-122b-422c-b9fd-2e55587883da",
                "text": "Severe",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "77f13923-7057-4e29-9f61-4bc3d386fbc3",
                "text": "Very",
                "value": 1
            }
        ],
        "id": "ISI-1b",
        "text": "Please rate the current (i.e., the past 2 weeks) **severity** of your insomnia problem(s):\n\n**Difficulty staying asleep**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8a2ab7b5-c65d-4291-aebf-387e85ad7277",
                "text": "None",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "84f37627-e14d-4bc3-b5df-c0a2deda2e5b",
                "text": "Mild",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "246a7bcd-af06-458e-a4ed-ecc3ffb2a0d4",
                "text": "Moderate",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "51f64d13-c787-4dac-8204-55d05977883a",
                "text": "Severe",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "1091521b-2de4-4f21-a8fc-cca207333cf1",
                "text": "Very",
                "value": 1
            }
        ],
        "id": "ISI-1c",
        "text": "Please rate the current (i.e., the past 2 weeks) **severity** of your insomnia problem(s):\n\n**Problem waking up too early**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "1ae15493-f6aa-4342-9306-37b14cd05476",
                "text": "0 - Very satisfied",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "19c266a8-6731-4a4e-b70a-07f6d5588559",
                "text": "1",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "d2c02347-715c-41ee-8990-c9df657887f5",
                "text": "2",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "bcd1a823-a915-45c1-86f0-e55b885c1ff8",
                "text": "3",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "1234a38a-a6c3-4796-beb4-f5478156b9dc",
                "text": "4 - Very dissatisfied",
                "value": 1
            }
        ],
        "id": "ISI-2",
        "text": "How **satisfied/dissatisfied** are you with your current sleep pattern?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e1fdc76f-eab4-463f-a631-1bd1666071cd",
                "text": "Not at all interfering",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "3aa326a5-d399-4d76-9566-ad0823c79892",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "d976d301-3f95-422a-909b-dc066f898dea",
                "text": "Somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "046c617b-6a41-45b8-a1de-ffd0eafd4dfb",
                "text": "Much",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "e3bab970-55d8-479d-9cf3-c9f9f1ea7601",
                "text": "Very much interfering",
                "value": 1
            }
        ],
        "id": "ISI-3",
        "text": "To what extent do you consider your sleep problem to **interfere** with your daily functioning (e.g., daytime fatigue, mood, ability to function at work/daily chores, concentration, memory, mood, etc.).",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "c0ebe147-b2a8-4c2e-b312-aedcb4169b61",
                "text": "Not at all noticeable",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "e76d475a-a058-4330-a6da-4228013e3457",
                "text": "Barely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "05e64720-dd56-44af-9368-088fe752d09f",
                "text": "Somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "73e498b5-bc5f-443c-8967-a52c289ec78e",
                "text": "Much",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "d72d5737-610f-4ad1-b7ab-8b665021d634",
                "text": "Very much noticeable",
                "value": 1
            }
        ],
        "id": "ISI-4",
        "text": "How **noticeable** to others do you think your sleeping problem is in terms of impairing the quality of your life?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "cbbe185f-4ad8-4c02-b61e-b443fd379c43",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "0dab9118-d57f-4a5b-8568-c9b1e23cb337",
                "text": "A little",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "2088d622-e89d-42f2-8657-4e13bf3b670d",
                "text": "Somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "f43a33dd-d88a-4246-9d21-a88ebac53856",
                "text": "Much",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "eebc29dc-9cce-4df8-a7d6-411decb5af91",
                "text": "Very much",
                "value": 1
            }
        ],
        "id": "ISI-5",
        "text": "How **worried/distressed** are you about your current sleep problem?",
        "isMultiselect": false
    }
];
exports.aspects = {
    "isi": {
        "type": "mean",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ISI-1a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "87faf8ed-d6ad-479c-a442-3d8e936b350f": 0,
                            "8e9e12f5-2bda-4112-97a0-e337871446a5": 0.25,
                            "6d94b585-d8cb-4b3a-bccf-325a571656ab": 0.5,
                            "dc59d1a1-ad7d-462c-918e-64238c6a47bb": 0.75,
                            "1b962c40-80e7-4101-8aeb-521beefe2819": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ISI-1b",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "9b976912-dc17-43cf-950f-18df7204614b": 0,
                            "a0b6d1ad-3aa7-4315-a82a-7feff6cd4c08": 0.25,
                            "472b8618-9643-4462-8764-f64687a08a1e": 0.5,
                            "44cf52c3-122b-422c-b9fd-2e55587883da": 0.75,
                            "77f13923-7057-4e29-9f61-4bc3d386fbc3": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ISI-1c",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "8a2ab7b5-c65d-4291-aebf-387e85ad7277": 0,
                            "84f37627-e14d-4bc3-b5df-c0a2deda2e5b": 0.25,
                            "246a7bcd-af06-458e-a4ed-ecc3ffb2a0d4": 0.5,
                            "51f64d13-c787-4dac-8204-55d05977883a": 0.75,
                            "1091521b-2de4-4f21-a8fc-cca207333cf1": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ISI-2",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "1ae15493-f6aa-4342-9306-37b14cd05476": 0,
                            "19c266a8-6731-4a4e-b70a-07f6d5588559": 0.25,
                            "d2c02347-715c-41ee-8990-c9df657887f5": 0.5,
                            "bcd1a823-a915-45c1-86f0-e55b885c1ff8": 0.75,
                            "1234a38a-a6c3-4796-beb4-f5478156b9dc": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ISI-3",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "e1fdc76f-eab4-463f-a631-1bd1666071cd": 0,
                            "3aa326a5-d399-4d76-9566-ad0823c79892": 0.25,
                            "d976d301-3f95-422a-909b-dc066f898dea": 0.5,
                            "046c617b-6a41-45b8-a1de-ffd0eafd4dfb": 0.75,
                            "e3bab970-55d8-479d-9cf3-c9f9f1ea7601": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ISI-4",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "c0ebe147-b2a8-4c2e-b312-aedcb4169b61": 0,
                            "e76d475a-a058-4330-a6da-4228013e3457": 0.25,
                            "05e64720-dd56-44af-9368-088fe752d09f": 0.5,
                            "73e498b5-bc5f-443c-8967-a52c289ec78e": 0.75,
                            "d72d5737-610f-4ad1-b7ab-8b665021d634": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "ISI-5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "cbbe185f-4ad8-4c02-b61e-b443fd379c43": 0,
                            "0dab9118-d57f-4a5b-8568-c9b1e23cb337": 0.25,
                            "2088d622-e89d-42f2-8657-4e13bf3b670d": 0.5,
                            "f43a33dd-d88a-4246-9d21-a88ebac53856": 0.75,
                            "eebc29dc-9cce-4df8-a7d6-411decb5af91": 1
                        }
                    }
                }
            }
        ]
    }
};
exports.tests = {
    "isi": [
        {
            "name": "ISI - if score 7 -> minimal-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.25,
                "threshold": 0
            },
            "answers": {
                "ISI-1a": {
                    "questionId": "ISI-1a",
                    "chosenOptionIds": [
                        "8e9e12f5-2bda-4112-97a0-e337871446a5"
                    ]
                },
                "ISI-1b": {
                    "questionId": "ISI-1b",
                    "chosenOptionIds": [
                        "a0b6d1ad-3aa7-4315-a82a-7feff6cd4c08"
                    ]
                },
                "ISI-1c": {
                    "questionId": "ISI-1c",
                    "chosenOptionIds": [
                        "84f37627-e14d-4bc3-b5df-c0a2deda2e5b"
                    ]
                },
                "ISI-2": {
                    "questionId": "ISI-2",
                    "chosenOptionIds": [
                        "19c266a8-6731-4a4e-b70a-07f6d5588559"
                    ]
                },
                "ISI-3": {
                    "questionId": "ISI-3",
                    "chosenOptionIds": [
                        "3aa326a5-d399-4d76-9566-ad0823c79892"
                    ]
                },
                "ISI-4": {
                    "questionId": "ISI-4",
                    "chosenOptionIds": [
                        "e76d475a-a058-4330-a6da-4228013e3457"
                    ]
                },
                "ISI-5": {
                    "questionId": "ISI-5",
                    "chosenOptionIds": [
                        "0dab9118-d57f-4a5b-8568-c9b1e23cb337"
                    ]
                }
            }
        },
        {
            "name": "ISI - if score 8 -> subthreshold-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.2857142857142857,
                "threshold": 0
            },
            "answers": {
                "ISI-1a": {
                    "questionId": "ISI-1a",
                    "chosenOptionIds": [
                        "8e9e12f5-2bda-4112-97a0-e337871446a5"
                    ]
                },
                "ISI-1b": {
                    "questionId": "ISI-1b",
                    "chosenOptionIds": [
                        "a0b6d1ad-3aa7-4315-a82a-7feff6cd4c08"
                    ]
                },
                "ISI-1c": {
                    "questionId": "ISI-1c",
                    "chosenOptionIds": [
                        "84f37627-e14d-4bc3-b5df-c0a2deda2e5b"
                    ]
                },
                "ISI-2": {
                    "questionId": "ISI-2",
                    "chosenOptionIds": [
                        "19c266a8-6731-4a4e-b70a-07f6d5588559"
                    ]
                },
                "ISI-3": {
                    "questionId": "ISI-3",
                    "chosenOptionIds": [
                        "3aa326a5-d399-4d76-9566-ad0823c79892"
                    ]
                },
                "ISI-4": {
                    "questionId": "ISI-4",
                    "chosenOptionIds": [
                        "e76d475a-a058-4330-a6da-4228013e3457"
                    ]
                },
                "ISI-5": {
                    "questionId": "ISI-5",
                    "chosenOptionIds": [
                        "2088d622-e89d-42f2-8657-4e13bf3b670d"
                    ]
                }
            }
        },
        {
            "name": "ISI - if score 14 -> subthreshold-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.5,
                "threshold": 0
            },
            "answers": {
                "ISI-1a": {
                    "questionId": "ISI-1a",
                    "chosenOptionIds": [
                        "6d94b585-d8cb-4b3a-bccf-325a571656ab"
                    ]
                },
                "ISI-1b": {
                    "questionId": "ISI-1b",
                    "chosenOptionIds": [
                        "472b8618-9643-4462-8764-f64687a08a1e"
                    ]
                },
                "ISI-1c": {
                    "questionId": "ISI-1c",
                    "chosenOptionIds": [
                        "246a7bcd-af06-458e-a4ed-ecc3ffb2a0d4"
                    ]
                },
                "ISI-2": {
                    "questionId": "ISI-2",
                    "chosenOptionIds": [
                        "d2c02347-715c-41ee-8990-c9df657887f5"
                    ]
                },
                "ISI-3": {
                    "questionId": "ISI-3",
                    "chosenOptionIds": [
                        "d976d301-3f95-422a-909b-dc066f898dea"
                    ]
                },
                "ISI-4": {
                    "questionId": "ISI-4",
                    "chosenOptionIds": [
                        "05e64720-dd56-44af-9368-088fe752d09f"
                    ]
                },
                "ISI-5": {
                    "questionId": "ISI-5",
                    "chosenOptionIds": [
                        "2088d622-e89d-42f2-8657-4e13bf3b670d"
                    ]
                }
            }
        },
        {
            "name": "ISI - if score 15 -> moderate-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.5357142857142857,
                "threshold": 0
            },
            "answers": {
                "ISI-1a": {
                    "questionId": "ISI-1a",
                    "chosenOptionIds": [
                        "6d94b585-d8cb-4b3a-bccf-325a571656ab"
                    ]
                },
                "ISI-1b": {
                    "questionId": "ISI-1b",
                    "chosenOptionIds": [
                        "472b8618-9643-4462-8764-f64687a08a1e"
                    ]
                },
                "ISI-1c": {
                    "questionId": "ISI-1c",
                    "chosenOptionIds": [
                        "246a7bcd-af06-458e-a4ed-ecc3ffb2a0d4"
                    ]
                },
                "ISI-2": {
                    "questionId": "ISI-2",
                    "chosenOptionIds": [
                        "d2c02347-715c-41ee-8990-c9df657887f5"
                    ]
                },
                "ISI-3": {
                    "questionId": "ISI-3",
                    "chosenOptionIds": [
                        "d976d301-3f95-422a-909b-dc066f898dea"
                    ]
                },
                "ISI-4": {
                    "questionId": "ISI-4",
                    "chosenOptionIds": [
                        "05e64720-dd56-44af-9368-088fe752d09f"
                    ]
                },
                "ISI-5": {
                    "questionId": "ISI-5",
                    "chosenOptionIds": [
                        "f43a33dd-d88a-4246-9d21-a88ebac53856"
                    ]
                }
            }
        },
        {
            "name": "ISI - if score 21 -> moderate-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.75,
                "threshold": 0
            },
            "answers": {
                "ISI-1a": {
                    "questionId": "ISI-1a",
                    "chosenOptionIds": [
                        "dc59d1a1-ad7d-462c-918e-64238c6a47bb"
                    ]
                },
                "ISI-1b": {
                    "questionId": "ISI-1b",
                    "chosenOptionIds": [
                        "44cf52c3-122b-422c-b9fd-2e55587883da"
                    ]
                },
                "ISI-1c": {
                    "questionId": "ISI-1c",
                    "chosenOptionIds": [
                        "51f64d13-c787-4dac-8204-55d05977883a"
                    ]
                },
                "ISI-2": {
                    "questionId": "ISI-2",
                    "chosenOptionIds": [
                        "bcd1a823-a915-45c1-86f0-e55b885c1ff8"
                    ]
                },
                "ISI-3": {
                    "questionId": "ISI-3",
                    "chosenOptionIds": [
                        "046c617b-6a41-45b8-a1de-ffd0eafd4dfb"
                    ]
                },
                "ISI-4": {
                    "questionId": "ISI-4",
                    "chosenOptionIds": [
                        "73e498b5-bc5f-443c-8967-a52c289ec78e"
                    ]
                },
                "ISI-5": {
                    "questionId": "ISI-5",
                    "chosenOptionIds": [
                        "f43a33dd-d88a-4246-9d21-a88ebac53856"
                    ]
                }
            }
        },
        {
            "name": "ISI - if score 22 -> severe-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.7857142857142857,
                "threshold": 0
            },
            "answers": {
                "ISI-1a": {
                    "questionId": "ISI-1a",
                    "chosenOptionIds": [
                        "dc59d1a1-ad7d-462c-918e-64238c6a47bb"
                    ]
                },
                "ISI-1b": {
                    "questionId": "ISI-1b",
                    "chosenOptionIds": [
                        "44cf52c3-122b-422c-b9fd-2e55587883da"
                    ]
                },
                "ISI-1c": {
                    "questionId": "ISI-1c",
                    "chosenOptionIds": [
                        "51f64d13-c787-4dac-8204-55d05977883a"
                    ]
                },
                "ISI-2": {
                    "questionId": "ISI-2",
                    "chosenOptionIds": [
                        "bcd1a823-a915-45c1-86f0-e55b885c1ff8"
                    ]
                },
                "ISI-3": {
                    "questionId": "ISI-3",
                    "chosenOptionIds": [
                        "046c617b-6a41-45b8-a1de-ffd0eafd4dfb"
                    ]
                },
                "ISI-4": {
                    "questionId": "ISI-4",
                    "chosenOptionIds": [
                        "73e498b5-bc5f-443c-8967-a52c289ec78e"
                    ]
                },
                "ISI-5": {
                    "questionId": "ISI-5",
                    "chosenOptionIds": [
                        "eebc29dc-9cce-4df8-a7d6-411decb5af91"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {};
