import React from 'react'
import { Page, Text, Button, Link } from '@psyomics/components'
import { useNavigate } from 'react-router-dom'
import Header from 'registration/components/Header'
import { classNameMaker } from 'ui/utils'
import Styles from './Error.module.scss'
import { useAuth } from 'registration/context/auth'
import { useDemoContext } from 'registration/pages/Demo/DemoContext'

interface ErrorProps {
    customTitle?: string
    customMessage?: string
    homepageButton?: boolean
    homepageRedirect?: string
    logOutButton?: boolean
    homepageButtonLabel?: string
    homebuttonCbFn?: () => void
}

const Error: React.FC<ErrorProps> = ({
    customTitle,
    customMessage,
    homepageButton = true,
    logOutButton = false,
    homepageRedirect = '/',
    homepageButtonLabel = 'Homepage',
    homebuttonCbFn,
}) => {
    const containerClass: string = classNameMaker({
        [Styles.container]: true,
    })

    const { signOutUser } = useAuth()
    const navigate = useNavigate()
    const { demo, demoFrom } = useDemoContext()

    let logoutRedirect = '/'

    if (demo) {
        homepageRedirect = '/demo'
        logoutRedirect = '/demo'
    }

    return (
        <Page isInsideMobileFrame={demo && demoFrom === 'start'}>
            <Header template="landing" />
            <div className={containerClass}>
                <Text size="large" color="mid" role="heading">
                    {customTitle ? customTitle : 'Unexpected error'}
                </Text>
            </div>

            {customMessage ? (
                <Text css={{ mt: 4 }}>{customMessage}</Text>
            ) : (
                <>
                    <Text css={{ mt: 4 }}>Something went wrong.</Text>
                    <Text css={{ mt: 2 }}>Try to refresh this page or go to the homepage.</Text>
                </>
            )}
            <Text css={{ mt: 2 }}>
                Alternatively, please feel free to contact us on{' '}
                <Link href="mailto:support@psyomics.com">support@psyomics.com</Link> if the problem continues.
            </Text>

            {homepageButton && (
                <div className={Styles.formActions}>
                    <Button
                        css={{ mt: 4 }}
                        appearance="primary"
                        fullWidth
                        size="large"
                        label={homepageButtonLabel}
                        onClick={() => {
                            if (homebuttonCbFn) {
                                homebuttonCbFn()
                            }
                            navigate(homepageRedirect)
                        }}
                    />
                </div>
            )}
            {logOutButton && (
                <div className={Styles.formActions}>
                    <Button
                        css={{ mt: 4 }}
                        appearance="primary"
                        fullWidth
                        size="large"
                        label="Sign out"
                        onClick={async () => {
                            await signOutUser()
                            navigate(logoutRedirect)
                        }}
                    />
                </div>
            )}
        </Page>
    )
}

export default Error
