"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createPatientContextV2 = exports.createPatientContext = exports.generateReportDetails = exports.getReportDetails = void 0;
const reportData_1 = require("./conditions/reportData");
const _1 = require(".");
const answers_1 = require("./answers");
const mapSymptomSections = (symptomSections, isRiskSection = false) => {
    return symptomSections.map((x) => ({
        __typename: 'SymptomsSection',
        title: x.title,
        symptoms: x.symptoms.map((y) => {
            const isFreeText = y.isFreeText;
            return {
                __typename: 'Symptom',
                description: y.description,
                ...(isRiskSection && { questionId: y.questionId, showOnRagReport: y.showOnRagReport }),
                ...(isFreeText && { isFreeText }),
            };
        }),
        symptomsSubSections: mapSubsections(x, isRiskSection),
    }));
};
const mapSubsections = (symptomSection, isRiskSection = false) => {
    return symptomSection.subSections.reduce((acc, cur) => {
        const val = {
            __typename: 'SymptomsSubSection',
            title: cur.title,
            symptoms: cur.symptoms.map((s) => {
                const isFreeText = s.isFreeText;
                return {
                    __typename: 'Symptom',
                    description: s.description,
                    ...(isRiskSection && { questionId: s.questionId, showOnRagReport: s.showOnRagReport }),
                    ...(isFreeText && { isFreeText }),
                };
            }),
        };
        return acc.concat([val]).concat(mapSubsections(cur, isRiskSection));
    }, []);
};
const toScoreBreakdown = (scoreBreakdown) => ({
    __typename: 'ScoreBreakdown',
    ...scoreBreakdown,
    breakdownSections: scoreBreakdown.breakdownSections.map((section) => ({
        __typename: 'ScoreBreakdownSection',
        ...section,
    })),
});
const getReportDetails = (data) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const conditionInfo = (0, _1.conditionInformation)(data.user.userType);
    const demographics = {
        __typename: 'Demographics',
        ...data.user,
        nhsNumberTrust: data.trustNhsNumber,
    };
    const risk = {
        __typename: 'Risk',
        score: (_a = data.reportData.rag) === null || _a === void 0 ? void 0 : _a.score,
        outcomes: ((_b = data.reportData.rag) === null || _b === void 0 ? void 0 : _b.outcomes)
            ? (_c = data.reportData.rag) === null || _c === void 0 ? void 0 : _c.outcomes.map(({ name, symptoms, scoreText, ...rest }) => {
                return {
                    __typename: 'Outcome',
                    name,
                    friendlyName: (0, reportData_1.ragOutcomeNamesFromName)(data.user.userType)[name],
                    code: Object.keys(conditionInfo).find((key) => conditionInfo[key].name === name),
                    symptomsSections: mapSymptomSections(symptoms, true),
                    ...rest,
                };
            })
            : [],
    };
    const sitb = {
        __typename: 'SITB',
        questionsAndAnswers: (_e = (_d = data.reportData.sitb) === null || _d === void 0 ? void 0 : _d.questionsAndAnswers) === null || _e === void 0 ? void 0 : _e.map((qa) => ({
            __typename: 'QuestionAndAnswer',
            ...qa,
        })),
    };
    const riskSummary = {
        __typename: 'RiskSummary',
        score: (_f = data.reportData.rag) === null || _f === void 0 ? void 0 : _f.score,
        outcomes: ((_g = data.reportData.rag) === null || _g === void 0 ? void 0 : _g.outcomes)
            ? (_h = data.reportData.rag) === null || _h === void 0 ? void 0 : _h.outcomes.map((outcome) => ({
                __typename: 'Outcome',
                code: Object.keys(conditionInfo).find((key) => conditionInfo[key].name === outcome.name),
                name: outcome.name,
                friendlyName: (0, reportData_1.ragOutcomeNamesFromName)(data.user.userType)[outcome.name],
                score: outcome.score,
            }))
            : [],
    };
    const keyProblems = [
        {
            __typename: 'KeyProblems',
            title: 'Reasons for seeking treatment',
            description: data.freeTextAnswer || '',
        },
    ];
    const conditions = data.reportData.conditions.map(({ name, symptoms, score, scoreBreakdown, skipped }) => {
        const conditionCode = Object.keys(conditionInfo).find((key) => conditionInfo[key].name === name);
        const condition = conditionInfo[conditionCode];
        return {
            __typename: 'Conditions',
            code: conditionCode,
            name,
            patientFacingName: condition.patientFacingName,
            symptomsSections: mapSymptomSections(symptoms),
            isSevere: (0, reportData_1.isSevere)(score),
            isSubClinical: (0, reportData_1.isSubClinical)(score),
            seriousness: (0, reportData_1.toSeriousness)(score),
            symptomMatchLevel: (0, reportData_1.toSymptomMatchLevel)(score),
            score,
            introduction: conditionInfo[conditionCode].introduction,
            ...(scoreBreakdown
                ? {
                    scoreBreakdown: toScoreBreakdown(scoreBreakdown),
                }
                : {}),
            skipped,
        };
    });
    const conditionsSummary = [
        {
            code: 'adj_ce',
            group: 1,
            order: 1,
        },
        {
            code: 'simple_mdd',
            group: 1,
            order: 2,
        },
        {
            code: 'gad_ce',
            group: 1,
            order: 3,
        },
        {
            code: 'soa_ce',
            group: 1,
            order: 4,
        },
        {
            code: 'pad_ce',
            group: 1,
            order: 5,
        },
        {
            code: 'ocd_ce',
            group: 1,
            order: 6,
        },
        {
            code: 'ins_ce',
            group: 1,
            order: 7,
        },
        {
            code: 'emi_ce',
            group: 2,
            order: 1,
        },
        {
            code: 'trm_re_ptsd',
            group: 2,
            order: 2,
        },
        {
            code: 'dse_ce',
            group: 3,
            order: 1,
        },
        {
            code: 'simple_bd1',
            group: 4,
            order: 1,
        },
        {
            code: 'simple_bd2',
            group: 4,
            order: 2,
        },
        {
            code: 'pso_ce',
            group: 4,
            order: 3,
        },
        {
            code: 'alc_ce',
            group: 5,
            order: 2,
        },
        {
            code: 'sbs_ce',
            group: 5,
            order: 1,
        },
        {
            code: 'adhd',
            group: 6,
            order: 1,
        },
        {
            code: 'gad7',
            group: 9,
            order: 2,
        },
        {
            code: 'dast10',
            group: 10,
            order: 2,
        },
        {
            code: 'phq9',
            group: 9,
            order: 1,
        },
        {
            code: 'ede_qs',
            group: 11,
            order: 3,
        },
        {
            code: 'spin',
            group: 9,
            order: 3,
        },
        {
            code: 'isi',
            group: 11,
            order: 2,
        },
        {
            code: 'pdss',
            group: 9,
            order: 4,
        },
        {
            code: 'pcl5',
            group: 7,
            order: 1,
        },
        {
            code: 'msi_bpd',
            group: 7,
            order: 2,
        },
        {
            code: 'mdq',
            group: 8,
            order: 1,
        },
        {
            code: 'adnm',
            group: 7,
            order: 3,
        },
        {
            code: 'oci',
            group: 11,
            order: 1,
        },
        {
            code: 'pq16',
            group: 8,
            order: 2,
        },
        {
            code: 'scoff',
            group: 11,
            order: 4,
        },
        {
            code: 'audit',
            group: 10,
            order: 19,
        },
    ]
        .filter((summary) => data.sessionConfig.some((session) => {
        const sessionCodes = session.sections.map((sec) => _1.sessionsToAspects[sec]).flat();
        return sessionCodes.includes(summary.code);
    }))
        .map((summary) => {
        var _a;
        const groupTitles = {
            7: 'Trauma',
            8: 'Mood and Psychosis Screening',
            9: 'Depression and Anxiety',
            10: 'Substance Use',
            11: 'Other Clinical Measures',
        };
        const conditionsSummaryTypename = 'ConditionsSummary';
        const measures = conditionInfo[summary.code].measures;
        const summaryCopy = {
            ...summary,
            __typename: conditionsSummaryTypename,
            displayName: conditionInfo[summary.code].name,
            displayNameContextual: (_a = conditionInfo[summary.code].nameContextual) !== null && _a !== void 0 ? _a : conditionInfo[summary.code].patientFacingName,
            patientFacingDisplayName: conditionInfo[summary.code].patientFacingName,
            groupName: groupTitles[summary.group],
            measures: measures && {
                __typename: 'Measures',
                total: measures.total,
                thresholds: measures.thresholds.map((threshold) => ({
                    __typename: 'Threshold',
                    thresholdValue: threshold.thresholdValue,
                    thresholdLabel: threshold.thresholdLabel,
                })),
            },
        };
        return summaryCopy;
    });
    Object.freeze(conditionsSummary);
    const patientContext = (0, exports.createPatientContext)(data, conditionInfo);
    const patientContextV2 = (0, exports.createPatientContextV2)(data, conditionInfo);
    const patientContextSummary = [
        {
            __typename: 'PatientContextSummary',
            name: 'Childcare responsibilities',
            group: 1,
            order: 1,
        },
        {
            __typename: 'PatientContextSummary',
            name: 'Current pregnancy',
            group: 1,
            order: 2,
        },
        {
            __typename: 'PatientContextSummary',
            name: 'Unpaid caring responsibilities',
            group: 1,
            order: 3,
        },
        {
            __typename: 'PatientContextSummary',
            name: 'Employment status',
            group: 2,
            order: 1,
        },
        {
            __typename: 'PatientContextSummary',
            name: 'Financial status',
            group: 2,
            order: 2,
        },
        {
            __typename: 'PatientContextSummary',
            name: 'Place of residence',
            group: 2,
            order: 3,
        },
        {
            __typename: 'PatientContextSummary',
            name: 'Perception of physical health',
            group: 3,
            order: 1,
        },
        {
            __typename: 'PatientContextSummary',
            name: 'Physical health diagnosis impacting on mental health',
            group: 3,
            order: 2,
        },
        {
            __typename: 'PatientContextSummary',
            name: 'Perception of mental health',
            group: 4,
            order: 1,
        },
        {
            __typename: 'PatientContextSummary',
            name: 'Previous mental health diagnosis',
            group: 4,
            order: 2,
        },
        {
            __typename: 'PatientContextSummary',
            name: 'Time spent with loved ones',
            group: 5,
            order: 1,
        },
        {
            __typename: 'PatientContextSummary',
            name: 'Someone to talk to',
            group: 5,
            order: 2,
        },
    ];
    const reportDetails = {
        __typename: 'ReportDetails',
        demographics,
        risk,
        riskSummary,
        keyProblems,
        conditions,
        conditionsSummary,
        patientContext,
        patientContextV2,
        patientContextSummary,
        completedDate: data.assessmentCompletedDate,
        sitb,
    };
    return {
        report: reportDetails,
        metadata: {
            ragDecisions: data.reportData.rag.decisions,
            ...(data.orgCode !== undefined && { orgCode: data.orgCode }),
        },
    };
};
exports.getReportDetails = getReportDetails;
const generateReportDetails = (questions, aspects, answers, aspectsToEvaluate, user, sessionConfig = _1.defaultSessionConfigs, noThreshold = false, trustNhsNumber, assessmentCompletedDate, orgCode) => {
    const filteredAnswers = (0, answers_1.filterValidAnswers)(answers, questions, aspects, _1.questionPredicates, user.id);
    const evaluatedAspects = (0, _1.evaluateAspects)(aspectsToEvaluate, aspects, filteredAnswers);
    const reportData = (0, reportData_1.getReportData)(questions, filteredAnswers, evaluatedAspects, noThreshold, user, sessionConfig());
    const freeTextAnswer = filteredAnswers.find((a) => a.questionId === 'DemR');
    const bmi = (0, _1.calculateBmi)(filteredAnswers, questions);
    const data = {
        reportData,
        user,
        trustNhsNumber,
        freeTextAnswer: freeTextAnswer === null || freeTextAnswer === void 0 ? void 0 : freeTextAnswer.freeText,
        bmi,
        assessmentCompletedDate,
        orgCode,
        sessionConfig: sessionConfig(),
    };
    const reportDetails = (0, exports.getReportDetails)(data);
    return reportDetails;
};
exports.generateReportDetails = generateReportDetails;
const createPatientContext = (data, conditionInfo) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const PAST_ALCOHOL_SECTION_NAME = 'Previous episode';
    const PAST_SUBSTANCE_SECTION_NAME = 'Episode details';
    const reportData = data.reportData;
    let pastAlcoholPreviousEpisodeSymptoms = (_c = (_b = (_a = reportData.staticConditions) === null || _a === void 0 ? void 0 : _a[conditionInfo['alc_pe_dep'].name]) === null || _b === void 0 ? void 0 : _b.symptoms) === null || _c === void 0 ? void 0 : _c.find((symptomSection) => {
        return symptomSection.title === PAST_ALCOHOL_SECTION_NAME;
    });
    function createBase(title, description, foundSymptoms) {
        return {
            subSections: [
                {
                    subSections: [],
                    symptoms: [
                        {
                            description,
                        },
                    ],
                    title: '',
                },
            ],
            symptoms: [],
            ...foundSymptoms,
            title,
        };
    }
    pastAlcoholPreviousEpisodeSymptoms = createBase('Past Alcohol Use', 'No alcohol use recorded', pastAlcoholPreviousEpisodeSymptoms);
    let pastSubstanceEpisodeSymptoms = (_f = (_e = (_d = reportData.staticConditions) === null || _d === void 0 ? void 0 : _d[conditionInfo['sbs_pe'].name]) === null || _e === void 0 ? void 0 : _e.symptoms) === null || _f === void 0 ? void 0 : _f.find((symptomSection) => {
        return symptomSection.title === PAST_SUBSTANCE_SECTION_NAME;
    });
    pastSubstanceEpisodeSymptoms = createBase('Past Substance Use', 'No substance use recorded', pastSubstanceEpisodeSymptoms);
    const patientContextSymptoms = ((_h = (_g = reportData.staticConditions) === null || _g === void 0 ? void 0 : _g[conditionInfo['pc'].name]) === null || _h === void 0 ? void 0 : _h.symptoms) || [];
    const patientContext = [
        ...patientContextSymptoms,
        pastAlcoholPreviousEpisodeSymptoms,
        pastSubstanceEpisodeSymptoms,
    ].map((x) => {
        return {
            __typename: 'PatientContext',
            title: x.title,
            sections: x.subSections
                .map((y) => {
                if (y.symptoms[0]) {
                    return y.symptoms
                        .map((symp, i) => {
                        const { title } = y;
                        const isWeight = title === 'Weight';
                        const isHeight = title === 'Height';
                        const baseSection = (description) => ({
                            __typename: 'Sections',
                            title: i > 0 ? '' : title,
                            description,
                        });
                        if (isWeight || isHeight) {
                            const value = parseFloat(symp.description);
                            const unit = isWeight ? 'Kg' : 'cm';
                            const desc = value <= 0 ? 'Preferred not to say' : `${symp.description} ${unit}`;
                            const sections = [baseSection(desc)];
                            if (isHeight) {
                                sections.push({
                                    __typename: 'Sections',
                                    title: 'Body Mass Index',
                                    description: data.bmi,
                                });
                            }
                            return sections;
                        }
                        return baseSection(symp.description);
                    })
                        .flat();
                }
                else {
                    return y.subSections
                        .map((subSections) => subSections.symptoms.map((symp, i) => {
                        return {
                            __typename: 'Sections',
                            title: i > 0 ? '' : y.title,
                            description: symp.description,
                        };
                    }))
                        .flat();
                }
            })
                .flat(),
        };
    });
    return patientContext;
};
exports.createPatientContext = createPatientContext;
const createPatientContextV2 = (data, conditionInfo) => {
    var _a, _b, _c, _d, _e, _f;
    const v2Pc = ((_b = (_a = data.reportData.staticConditions) === null || _a === void 0 ? void 0 : _a[conditionInfo.v2Pc.name]) === null || _b === void 0 ? void 0 : _b.symptoms) || [];
    const fivePs = ((_d = (_c = data.reportData.staticConditions) === null || _c === void 0 ? void 0 : _c[conditionInfo.five_ps.name]) === null || _d === void 0 ? void 0 : _d.symptoms) || [];
    const dialog = ((_f = (_e = data.reportData.staticConditions) === null || _e === void 0 ? void 0 : _e[conditionInfo.dialog.name]) === null || _f === void 0 ? void 0 : _f.symptoms) || [];
    const allSections = [...v2Pc, ...fivePs, ...dialog];
    return allSections.map((section) => {
        const subSections = [];
        if (section.symptoms.length) {
            subSections.push({
                __typename: 'PatientContextSubSection',
                title: section.title,
                symptoms: section.symptoms.map((sym) => {
                    const isFreeText = sym.isFreeText;
                    return {
                        __typename: 'Symptom',
                        description: sym.description,
                        ...(isFreeText && { isFreeText }),
                    };
                }),
            });
        }
        for (const subSec of section.subSections) {
            const mergedSymptoms = [];
            for (const symptom of subSec.symptoms) {
                const isFreeText = symptom.isFreeText;
                mergedSymptoms.push({
                    __typename: 'Symptom',
                    description: symptom.description,
                    ...(isFreeText && { isFreeText }),
                });
            }
            for (const nested of subSec.subSections) {
                if (nested.symptoms.length) {
                    mergedSymptoms.push({
                        __typename: 'Symptom',
                        description: nested.title + nested.symptoms.map((s) => s.description).join(';'), // multichoice answers delimited with ';'
                    });
                }
            }
            subSections.push({
                __typename: 'PatientContextSubSection',
                title: subSec.title,
                symptoms: mergedSymptoms,
            });
        }
        return {
            __typename: 'PatientContextSection',
            title: section.title,
            subSections,
        };
    });
};
exports.createPatientContextV2 = createPatientContextV2;
