"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
exports.questions = [
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "5bcaffaa-cbd4-4b5f-b3b8-f6a16b9c6f96",
                "text": "0 days",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7916b318-11b9-48e7-b1fa-c2a692f8df42",
                "text": "1-2 days",
                "value": 0.3333333333333333
            },
            {
                "__typename": "Option",
                "id": "b75b5170-c845-4f32-b0a0-2733cf5a9ff7",
                "text": "3-5 days",
                "value": 0.6666666666666666
            },
            {
                "__typename": "Option",
                "id": "c2f5ca30-efb8-4200-8375-85e7b6b4cf6d",
                "text": "6-7 days",
                "value": 1
            }
        ],
        "id": "EDE-1",
        "text": "How many of the past 7 days have you been deliberately trying to limit the amount of food you eat to influence your weight or shape (whether or not you have succeeded)?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "fbe54168-e7c9-41c0-ab56-876bdf3a1acc",
                "text": "0 days",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b64bdced-0c40-44dc-bf7b-dcefa1e5fb12",
                "text": "1-2 days",
                "value": 0.3333333333333333
            },
            {
                "__typename": "Option",
                "id": "957f65d3-54a8-4bc4-81fd-96a8d35be602",
                "text": "3-5 days",
                "value": 0.6666666666666666
            },
            {
                "__typename": "Option",
                "id": "25ecd748-8b62-424b-80d2-59f3910b4841",
                "text": "6-7 days",
                "value": 1
            }
        ],
        "id": "EDE-2",
        "text": "How many of the past 7 days have you gone for long periods of time (e.g., 8 or more waking hours) without eating anything  at all in order to influence your weight or shape?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ff7e0e17-99d0-43b1-9795-f633cd93a270",
                "text": "0 days",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "37320729-1937-4c77-8259-de0c66d77c8c",
                "text": "1-2 days",
                "value": 0.3333333333333333
            },
            {
                "__typename": "Option",
                "id": "bde6ac54-88c8-4dee-8857-e3f8b6a93ca6",
                "text": "3-5 days",
                "value": 0.6666666666666666
            },
            {
                "__typename": "Option",
                "id": "5b15ad7d-2f35-4021-9a25-97f44309b1f5",
                "text": "6-7 days",
                "value": 1
            }
        ],
        "id": "EDE-3",
        "text": "How many of the past 7 days has thinking about food, eating or calories made it very difficult to concentrate on things you  are interested in (such as working, following a conversation or reading)?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "d5a5717c-f2d1-4e9f-9219-4a23508a3865",
                "text": "0 days",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "5d688a87-d027-4746-9600-28b213eece42",
                "text": "1-2 days",
                "value": 0.3333333333333333
            },
            {
                "__typename": "Option",
                "id": "07f11988-c6df-4fe3-a1de-ea0f912b9df7",
                "text": "3-5 days",
                "value": 0.6666666666666666
            },
            {
                "__typename": "Option",
                "id": "39ef7744-ced0-421a-b35c-3fb2d424eef8",
                "text": "6-7 days",
                "value": 1
            }
        ],
        "id": "EDE-4",
        "text": "How many of the past 7 days has thinking about your weight or shape made it very difficult to concentrate on things you are  interested in (such as working, following a conversation or reading)?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "9340f3fa-4c71-44c4-8015-e47d8136f348",
                "text": "0 days",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2e62c6c8-7ce5-40dd-a2e3-d6448e331661",
                "text": "1-2 days",
                "value": 0.3333333333333333
            },
            {
                "__typename": "Option",
                "id": "bd3832fa-22f4-4849-a261-91d0d79036ec",
                "text": "3-5 days",
                "value": 0.6666666666666666
            },
            {
                "__typename": "Option",
                "id": "e3ed02c5-89ea-4a39-acc1-4401b7ba6f87",
                "text": "6-7 days",
                "value": 1
            }
        ],
        "id": "EDE-5",
        "text": "How many of the past 7 days have you had a definite fear that you might  gain weight?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "299d6139-6dcc-46ed-8bdb-932fcf1de0e5",
                "text": "0 days",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "4d48bdcf-f756-4527-b860-287cb9960d9c",
                "text": "1-2 days",
                "value": 0.3333333333333333
            },
            {
                "__typename": "Option",
                "id": "4666084b-a154-41b9-9928-008102c0d7a0",
                "text": "3-5 days",
                "value": 0.6666666666666666
            },
            {
                "__typename": "Option",
                "id": "e1339e01-67c8-4345-9305-7bb16b05e95b",
                "text": "6-7 days",
                "value": 1
            }
        ],
        "id": "EDE-6",
        "text": "How many of the past 7 days have you had a strong desire to lose weight?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "c6169844-558b-40f0-a843-f97dd49a91eb",
                "text": "0 days",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "cd43c779-6d4c-44ea-8d27-a799d6927854",
                "text": "1-2 days",
                "value": 0.3333333333333333
            },
            {
                "__typename": "Option",
                "id": "efa99f5a-96ba-40f2-b2fe-160b22ac2f28",
                "text": "3-5 days",
                "value": 0.6666666666666666
            },
            {
                "__typename": "Option",
                "id": "8b31f3e0-9ed8-4375-a03c-7740c07b7dd7",
                "text": "6-7 days",
                "value": 1
            }
        ],
        "id": "EDE-7",
        "text": "How many of the past 7 days have you tried to control your weight or shape by making yourself sick (vomit) or taking laxatives?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "188235d8-1c3e-48d3-92a0-cbae8b98667a",
                "text": "0 days",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2dc85912-854c-49c7-ac92-0fff989f50e2",
                "text": "1-2 days",
                "value": 0.3333333333333333
            },
            {
                "__typename": "Option",
                "id": "fe1e4808-f607-42a6-90e0-7a0341225b5b",
                "text": "3-5 days",
                "value": 0.6666666666666666
            },
            {
                "__typename": "Option",
                "id": "d9a2b484-9852-4df6-9739-9098660c775c",
                "text": "6-7 days",
                "value": 1
            }
        ],
        "id": "EDE-8",
        "text": "How many of the past 7 days have you exercised in a driven or compulsive way as a means of controlling your weight, shape  or body fat, or to burn off calories?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "249d2a50-c9db-4950-8561-2cf830830ca4",
                "text": "0 days",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "14968026-d0e2-4876-8c71-62f1070cb3de",
                "text": "1-2 days",
                "value": 0.3333333333333333
            },
            {
                "__typename": "Option",
                "id": "0ca3a260-a6f2-4dff-9b52-39de0e76fa50",
                "text": "3-5 days",
                "value": 0.6666666666666666
            },
            {
                "__typename": "Option",
                "id": "daafea35-3e01-41c8-979e-e8c76b5e9189",
                "text": "6-7 days",
                "value": 1
            }
        ],
        "id": "EDE-9",
        "text": "How many of the past 7 days have you had a sense of having lost control  over your eating (at the time that you were eating)?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "bf72638b-6209-4e2c-868d-b1e3773321b3",
                "text": "0 days",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a15ec674-a121-449a-a6bd-9c670ef4f3f4",
                "text": "1-2 days",
                "value": 0.3333333333333333
            },
            {
                "__typename": "Option",
                "id": "4a1dd773-0ac5-43b0-b858-1bdc833b9489",
                "text": "3-5 days",
                "value": 0.6666666666666666
            },
            {
                "__typename": "Option",
                "id": "48fda884-ac94-43dc-93cf-3b3d140fb30e",
                "text": "6-7 days",
                "value": 1
            }
        ],
        "id": "EDE-10",
        "text": "On how many of the last 7 days days ( i.e. days on which you had a sense of having lost control over your  eating) did you eat what other people would regard as an unusually large amount of food in one go?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "59893bc8-2610-4b73-b17b-90a9b4cf0286",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d62083b8-2390-4327-b5a0-53ae305dfdab",
                "text": "Slightly",
                "value": 0.3333333333333333
            },
            {
                "__typename": "Option",
                "id": "9c619a3b-25af-4d86-a4c7-34c1fa5c780e",
                "text": "Moderately",
                "value": 0.6666666666666666
            },
            {
                "__typename": "Option",
                "id": "5aa5fff2-897e-4f82-9dd3-d79c472c89a7",
                "text": "Markedly",
                "value": 1
            }
        ],
        "id": "EDE-11",
        "text": "Over the past 7 days has your weight or shape influenced how you  think about (judge) yourself as a person?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "2c94540e-f2a4-4f19-a02d-d92a9b9aa6cb",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7c8d33dd-e072-462a-b7cd-2cf6a22565b2",
                "text": "Slightly",
                "value": 0.3333333333333333
            },
            {
                "__typename": "Option",
                "id": "28e75936-fa66-4ce6-b9ff-cac7a1ae88d6",
                "text": "Moderately",
                "value": 0.6666666666666666
            },
            {
                "__typename": "Option",
                "id": "bfd69a21-0d96-4a61-b4de-d04a54074b11",
                "text": "Markedly",
                "value": 1
            }
        ],
        "id": "EDE-12",
        "text": "Over the past 7 days how dissatisfied have you been with your weight or shape?",
        "isMultiselect": false
    }
];
exports.aspects = {
    "ede_qs": {
        "type": "mean",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "EDE-1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "5bcaffaa-cbd4-4b5f-b3b8-f6a16b9c6f96": 0,
                            "7916b318-11b9-48e7-b1fa-c2a692f8df42": 0.3333333333333333,
                            "b75b5170-c845-4f32-b0a0-2733cf5a9ff7": 0.6666666666666666,
                            "c2f5ca30-efb8-4200-8375-85e7b6b4cf6d": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "EDE-2",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "fbe54168-e7c9-41c0-ab56-876bdf3a1acc": 0,
                            "b64bdced-0c40-44dc-bf7b-dcefa1e5fb12": 0.3333333333333333,
                            "957f65d3-54a8-4bc4-81fd-96a8d35be602": 0.6666666666666666,
                            "25ecd748-8b62-424b-80d2-59f3910b4841": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "EDE-3",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "ff7e0e17-99d0-43b1-9795-f633cd93a270": 0,
                            "37320729-1937-4c77-8259-de0c66d77c8c": 0.3333333333333333,
                            "bde6ac54-88c8-4dee-8857-e3f8b6a93ca6": 0.6666666666666666,
                            "5b15ad7d-2f35-4021-9a25-97f44309b1f5": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "EDE-4",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "d5a5717c-f2d1-4e9f-9219-4a23508a3865": 0,
                            "5d688a87-d027-4746-9600-28b213eece42": 0.3333333333333333,
                            "07f11988-c6df-4fe3-a1de-ea0f912b9df7": 0.6666666666666666,
                            "39ef7744-ced0-421a-b35c-3fb2d424eef8": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "EDE-5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "9340f3fa-4c71-44c4-8015-e47d8136f348": 0,
                            "2e62c6c8-7ce5-40dd-a2e3-d6448e331661": 0.3333333333333333,
                            "bd3832fa-22f4-4849-a261-91d0d79036ec": 0.6666666666666666,
                            "e3ed02c5-89ea-4a39-acc1-4401b7ba6f87": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "EDE-6",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "299d6139-6dcc-46ed-8bdb-932fcf1de0e5": 0,
                            "4d48bdcf-f756-4527-b860-287cb9960d9c": 0.3333333333333333,
                            "4666084b-a154-41b9-9928-008102c0d7a0": 0.6666666666666666,
                            "e1339e01-67c8-4345-9305-7bb16b05e95b": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "EDE-7",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "c6169844-558b-40f0-a843-f97dd49a91eb": 0,
                            "cd43c779-6d4c-44ea-8d27-a799d6927854": 0.3333333333333333,
                            "efa99f5a-96ba-40f2-b2fe-160b22ac2f28": 0.6666666666666666,
                            "8b31f3e0-9ed8-4375-a03c-7740c07b7dd7": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "EDE-8",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "188235d8-1c3e-48d3-92a0-cbae8b98667a": 0,
                            "2dc85912-854c-49c7-ac92-0fff989f50e2": 0.3333333333333333,
                            "fe1e4808-f607-42a6-90e0-7a0341225b5b": 0.6666666666666666,
                            "d9a2b484-9852-4df6-9739-9098660c775c": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "EDE-9",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "249d2a50-c9db-4950-8561-2cf830830ca4": 0,
                            "14968026-d0e2-4876-8c71-62f1070cb3de": 0.3333333333333333,
                            "0ca3a260-a6f2-4dff-9b52-39de0e76fa50": 0.6666666666666666,
                            "daafea35-3e01-41c8-979e-e8c76b5e9189": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "EDE-10",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "bf72638b-6209-4e2c-868d-b1e3773321b3": 0,
                            "a15ec674-a121-449a-a6bd-9c670ef4f3f4": 0.3333333333333333,
                            "4a1dd773-0ac5-43b0-b858-1bdc833b9489": 0.6666666666666666,
                            "48fda884-ac94-43dc-93cf-3b3d140fb30e": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "EDE-11",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "59893bc8-2610-4b73-b17b-90a9b4cf0286": 0,
                            "d62083b8-2390-4327-b5a0-53ae305dfdab": 0.3333333333333333,
                            "9c619a3b-25af-4d86-a4c7-34c1fa5c780e": 0.6666666666666666,
                            "5aa5fff2-897e-4f82-9dd3-d79c472c89a7": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "EDE-12",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "2c94540e-f2a4-4f19-a02d-d92a9b9aa6cb": 0,
                            "7c8d33dd-e072-462a-b7cd-2cf6a22565b2": 0.3333333333333333,
                            "28e75936-fa66-4ce6-b9ff-cac7a1ae88d6": 0.6666666666666666,
                            "bfd69a21-0d96-4a61-b4de-d04a54074b11": 1
                        }
                    }
                }
            }
        ]
    }
};
exports.tests = {
    "ede_qs": [
        {
            "name": "EDE-QS - 0 score-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0,
                "threshold": 0
            },
            "answers": {
                "EDE-1": {
                    "questionId": "EDE-1",
                    "chosenOptionIds": [
                        "5bcaffaa-cbd4-4b5f-b3b8-f6a16b9c6f96"
                    ]
                },
                "EDE-2": {
                    "questionId": "EDE-2",
                    "chosenOptionIds": [
                        "fbe54168-e7c9-41c0-ab56-876bdf3a1acc"
                    ]
                },
                "EDE-3": {
                    "questionId": "EDE-3",
                    "chosenOptionIds": [
                        "ff7e0e17-99d0-43b1-9795-f633cd93a270"
                    ]
                },
                "EDE-4": {
                    "questionId": "EDE-4",
                    "chosenOptionIds": [
                        "d5a5717c-f2d1-4e9f-9219-4a23508a3865"
                    ]
                },
                "EDE-5": {
                    "questionId": "EDE-5",
                    "chosenOptionIds": [
                        "9340f3fa-4c71-44c4-8015-e47d8136f348"
                    ]
                },
                "EDE-6": {
                    "questionId": "EDE-6",
                    "chosenOptionIds": [
                        "299d6139-6dcc-46ed-8bdb-932fcf1de0e5"
                    ]
                },
                "EDE-7": {
                    "questionId": "EDE-7",
                    "chosenOptionIds": [
                        "c6169844-558b-40f0-a843-f97dd49a91eb"
                    ]
                },
                "EDE-8": {
                    "questionId": "EDE-8",
                    "chosenOptionIds": [
                        "188235d8-1c3e-48d3-92a0-cbae8b98667a"
                    ]
                },
                "EDE-9": {
                    "questionId": "EDE-9",
                    "chosenOptionIds": [
                        "249d2a50-c9db-4950-8561-2cf830830ca4"
                    ]
                },
                "EDE-10": {
                    "questionId": "EDE-10",
                    "chosenOptionIds": [
                        "bf72638b-6209-4e2c-868d-b1e3773321b3"
                    ]
                },
                "EDE-11": {
                    "questionId": "EDE-11",
                    "chosenOptionIds": [
                        "59893bc8-2610-4b73-b17b-90a9b4cf0286"
                    ]
                },
                "EDE-12": {
                    "questionId": "EDE-12",
                    "chosenOptionIds": [
                        "2c94540e-f2a4-4f19-a02d-d92a9b9aa6cb"
                    ]
                }
            }
        },
        {
            "name": "EDE-QS- full score-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 1,
                "threshold": 0
            },
            "answers": {
                "EDE-1": {
                    "questionId": "EDE-1",
                    "chosenOptionIds": [
                        "c2f5ca30-efb8-4200-8375-85e7b6b4cf6d"
                    ]
                },
                "EDE-2": {
                    "questionId": "EDE-2",
                    "chosenOptionIds": [
                        "25ecd748-8b62-424b-80d2-59f3910b4841"
                    ]
                },
                "EDE-3": {
                    "questionId": "EDE-3",
                    "chosenOptionIds": [
                        "5b15ad7d-2f35-4021-9a25-97f44309b1f5"
                    ]
                },
                "EDE-4": {
                    "questionId": "EDE-4",
                    "chosenOptionIds": [
                        "39ef7744-ced0-421a-b35c-3fb2d424eef8"
                    ]
                },
                "EDE-5": {
                    "questionId": "EDE-5",
                    "chosenOptionIds": [
                        "e3ed02c5-89ea-4a39-acc1-4401b7ba6f87"
                    ]
                },
                "EDE-6": {
                    "questionId": "EDE-6",
                    "chosenOptionIds": [
                        "e1339e01-67c8-4345-9305-7bb16b05e95b"
                    ]
                },
                "EDE-7": {
                    "questionId": "EDE-7",
                    "chosenOptionIds": [
                        "8b31f3e0-9ed8-4375-a03c-7740c07b7dd7"
                    ]
                },
                "EDE-8": {
                    "questionId": "EDE-8",
                    "chosenOptionIds": [
                        "d9a2b484-9852-4df6-9739-9098660c775c"
                    ]
                },
                "EDE-9": {
                    "questionId": "EDE-9",
                    "chosenOptionIds": [
                        "daafea35-3e01-41c8-979e-e8c76b5e9189"
                    ]
                },
                "EDE-10": {
                    "questionId": "EDE-10",
                    "chosenOptionIds": [
                        "48fda884-ac94-43dc-93cf-3b3d140fb30e"
                    ]
                },
                "EDE-11": {
                    "questionId": "EDE-11",
                    "chosenOptionIds": [
                        "5aa5fff2-897e-4f82-9dd3-d79c472c89a7"
                    ]
                },
                "EDE-12": {
                    "questionId": "EDE-12",
                    "chosenOptionIds": [
                        "bfd69a21-0d96-4a61-b4de-d04a54074b11"
                    ]
                }
            }
        },
        {
            "name": "EDE-QS - partial score 1/3-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.3333333333333,
                "threshold": 0
            },
            "answers": {
                "EDE-1": {
                    "questionId": "EDE-1",
                    "chosenOptionIds": [
                        "7916b318-11b9-48e7-b1fa-c2a692f8df42"
                    ]
                },
                "EDE-2": {
                    "questionId": "EDE-2",
                    "chosenOptionIds": [
                        "b64bdced-0c40-44dc-bf7b-dcefa1e5fb12"
                    ]
                },
                "EDE-3": {
                    "questionId": "EDE-3",
                    "chosenOptionIds": [
                        "37320729-1937-4c77-8259-de0c66d77c8c"
                    ]
                },
                "EDE-4": {
                    "questionId": "EDE-4",
                    "chosenOptionIds": [
                        "5d688a87-d027-4746-9600-28b213eece42"
                    ]
                },
                "EDE-5": {
                    "questionId": "EDE-5",
                    "chosenOptionIds": [
                        "2e62c6c8-7ce5-40dd-a2e3-d6448e331661"
                    ]
                },
                "EDE-6": {
                    "questionId": "EDE-6",
                    "chosenOptionIds": [
                        "4d48bdcf-f756-4527-b860-287cb9960d9c"
                    ]
                },
                "EDE-7": {
                    "questionId": "EDE-7",
                    "chosenOptionIds": [
                        "cd43c779-6d4c-44ea-8d27-a799d6927854"
                    ]
                },
                "EDE-8": {
                    "questionId": "EDE-8",
                    "chosenOptionIds": [
                        "2dc85912-854c-49c7-ac92-0fff989f50e2"
                    ]
                },
                "EDE-9": {
                    "questionId": "EDE-9",
                    "chosenOptionIds": [
                        "14968026-d0e2-4876-8c71-62f1070cb3de"
                    ]
                },
                "EDE-10": {
                    "questionId": "EDE-10",
                    "chosenOptionIds": [
                        "a15ec674-a121-449a-a6bd-9c670ef4f3f4"
                    ]
                },
                "EDE-11": {
                    "questionId": "EDE-11",
                    "chosenOptionIds": [
                        "d62083b8-2390-4327-b5a0-53ae305dfdab"
                    ]
                },
                "EDE-12": {
                    "questionId": "EDE-12",
                    "chosenOptionIds": [
                        "7c8d33dd-e072-462a-b7cd-2cf6a22565b2"
                    ]
                }
            }
        },
        {
            "name": "EDE-QS score 2/3-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.666666666666666,
                "threshold": 0
            },
            "answers": {
                "EDE-1": {
                    "questionId": "EDE-1",
                    "chosenOptionIds": [
                        "b75b5170-c845-4f32-b0a0-2733cf5a9ff7"
                    ]
                },
                "EDE-2": {
                    "questionId": "EDE-2",
                    "chosenOptionIds": [
                        "957f65d3-54a8-4bc4-81fd-96a8d35be602"
                    ]
                },
                "EDE-3": {
                    "questionId": "EDE-3",
                    "chosenOptionIds": [
                        "bde6ac54-88c8-4dee-8857-e3f8b6a93ca6"
                    ]
                },
                "EDE-4": {
                    "questionId": "EDE-4",
                    "chosenOptionIds": [
                        "07f11988-c6df-4fe3-a1de-ea0f912b9df7"
                    ]
                },
                "EDE-5": {
                    "questionId": "EDE-5",
                    "chosenOptionIds": [
                        "bd3832fa-22f4-4849-a261-91d0d79036ec"
                    ]
                },
                "EDE-6": {
                    "questionId": "EDE-6",
                    "chosenOptionIds": [
                        "4666084b-a154-41b9-9928-008102c0d7a0"
                    ]
                },
                "EDE-7": {
                    "questionId": "EDE-7",
                    "chosenOptionIds": [
                        "efa99f5a-96ba-40f2-b2fe-160b22ac2f28"
                    ]
                },
                "EDE-8": {
                    "questionId": "EDE-8",
                    "chosenOptionIds": [
                        "fe1e4808-f607-42a6-90e0-7a0341225b5b"
                    ]
                },
                "EDE-9": {
                    "questionId": "EDE-9",
                    "chosenOptionIds": [
                        "0ca3a260-a6f2-4dff-9b52-39de0e76fa50"
                    ]
                },
                "EDE-10": {
                    "questionId": "EDE-10",
                    "chosenOptionIds": [
                        "4a1dd773-0ac5-43b0-b858-1bdc833b9489"
                    ]
                },
                "EDE-11": {
                    "questionId": "EDE-11",
                    "chosenOptionIds": [
                        "9c619a3b-25af-4d86-a4c7-34c1fa5c780e"
                    ]
                },
                "EDE-12": {
                    "questionId": "EDE-12",
                    "chosenOptionIds": [
                        "28e75936-fa66-4ce6-b9ff-cac7a1ae88d6"
                    ]
                }
            }
        },
        {
            "name": "EDE-QS partial score 1-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.52777777777,
                "threshold": 0
            },
            "answers": {
                "EDE-1": {
                    "questionId": "EDE-1",
                    "chosenOptionIds": [
                        "b75b5170-c845-4f32-b0a0-2733cf5a9ff7"
                    ]
                },
                "EDE-2": {
                    "questionId": "EDE-2",
                    "chosenOptionIds": [
                        "fbe54168-e7c9-41c0-ab56-876bdf3a1acc"
                    ]
                },
                "EDE-3": {
                    "questionId": "EDE-3",
                    "chosenOptionIds": [
                        "5b15ad7d-2f35-4021-9a25-97f44309b1f5"
                    ]
                },
                "EDE-4": {
                    "questionId": "EDE-4",
                    "chosenOptionIds": [
                        "5d688a87-d027-4746-9600-28b213eece42"
                    ]
                },
                "EDE-5": {
                    "questionId": "EDE-5",
                    "chosenOptionIds": [
                        "e3ed02c5-89ea-4a39-acc1-4401b7ba6f87"
                    ]
                },
                "EDE-6": {
                    "questionId": "EDE-6",
                    "chosenOptionIds": [
                        "e1339e01-67c8-4345-9305-7bb16b05e95b"
                    ]
                },
                "EDE-7": {
                    "questionId": "EDE-7",
                    "chosenOptionIds": [
                        "8b31f3e0-9ed8-4375-a03c-7740c07b7dd7"
                    ]
                },
                "EDE-8": {
                    "questionId": "EDE-8",
                    "chosenOptionIds": [
                        "188235d8-1c3e-48d3-92a0-cbae8b98667a"
                    ]
                },
                "EDE-9": {
                    "questionId": "EDE-9",
                    "chosenOptionIds": [
                        "249d2a50-c9db-4950-8561-2cf830830ca4"
                    ]
                },
                "EDE-10": {
                    "questionId": "EDE-10",
                    "chosenOptionIds": [
                        "a15ec674-a121-449a-a6bd-9c670ef4f3f4"
                    ]
                },
                "EDE-11": {
                    "questionId": "EDE-11",
                    "chosenOptionIds": [
                        "d62083b8-2390-4327-b5a0-53ae305dfdab"
                    ]
                },
                "EDE-12": {
                    "questionId": "EDE-12",
                    "chosenOptionIds": [
                        "28e75936-fa66-4ce6-b9ff-cac7a1ae88d6"
                    ]
                }
            }
        },
        {
            "name": "EDE-QS partial score 2-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.55555555555,
                "threshold": 0
            },
            "answers": {
                "EDE-1": {
                    "questionId": "EDE-1",
                    "chosenOptionIds": [
                        "c2f5ca30-efb8-4200-8375-85e7b6b4cf6d"
                    ]
                },
                "EDE-2": {
                    "questionId": "EDE-2",
                    "chosenOptionIds": [
                        "b64bdced-0c40-44dc-bf7b-dcefa1e5fb12"
                    ]
                },
                "EDE-3": {
                    "questionId": "EDE-3",
                    "chosenOptionIds": [
                        "bde6ac54-88c8-4dee-8857-e3f8b6a93ca6"
                    ]
                },
                "EDE-4": {
                    "questionId": "EDE-4",
                    "chosenOptionIds": [
                        "39ef7744-ced0-421a-b35c-3fb2d424eef8"
                    ]
                },
                "EDE-5": {
                    "questionId": "EDE-5",
                    "chosenOptionIds": [
                        "2e62c6c8-7ce5-40dd-a2e3-d6448e331661"
                    ]
                },
                "EDE-6": {
                    "questionId": "EDE-6",
                    "chosenOptionIds": [
                        "4666084b-a154-41b9-9928-008102c0d7a0"
                    ]
                },
                "EDE-7": {
                    "questionId": "EDE-7",
                    "chosenOptionIds": [
                        "efa99f5a-96ba-40f2-b2fe-160b22ac2f28"
                    ]
                },
                "EDE-8": {
                    "questionId": "EDE-8",
                    "chosenOptionIds": [
                        "d9a2b484-9852-4df6-9739-9098660c775c"
                    ]
                },
                "EDE-9": {
                    "questionId": "EDE-9",
                    "chosenOptionIds": [
                        "249d2a50-c9db-4950-8561-2cf830830ca4"
                    ]
                },
                "EDE-10": {
                    "questionId": "EDE-10",
                    "chosenOptionIds": [
                        "bf72638b-6209-4e2c-868d-b1e3773321b3"
                    ]
                },
                "EDE-11": {
                    "questionId": "EDE-11",
                    "chosenOptionIds": [
                        "d62083b8-2390-4327-b5a0-53ae305dfdab"
                    ]
                },
                "EDE-12": {
                    "questionId": "EDE-12",
                    "chosenOptionIds": [
                        "28e75936-fa66-4ce6-b9ff-cac7a1ae88d6"
                    ]
                }
            }
        },
        {
            "name": "EDE-QS partial score 3-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.41666666666,
                "threshold": 0
            },
            "answers": {
                "EDE-1": {
                    "questionId": "EDE-1",
                    "chosenOptionIds": [
                        "5bcaffaa-cbd4-4b5f-b3b8-f6a16b9c6f96"
                    ]
                },
                "EDE-2": {
                    "questionId": "EDE-2",
                    "chosenOptionIds": [
                        "b64bdced-0c40-44dc-bf7b-dcefa1e5fb12"
                    ]
                },
                "EDE-3": {
                    "questionId": "EDE-3",
                    "chosenOptionIds": [
                        "bde6ac54-88c8-4dee-8857-e3f8b6a93ca6"
                    ]
                },
                "EDE-4": {
                    "questionId": "EDE-4",
                    "chosenOptionIds": [
                        "39ef7744-ced0-421a-b35c-3fb2d424eef8"
                    ]
                },
                "EDE-5": {
                    "questionId": "EDE-5",
                    "chosenOptionIds": [
                        "2e62c6c8-7ce5-40dd-a2e3-d6448e331661"
                    ]
                },
                "EDE-6": {
                    "questionId": "EDE-6",
                    "chosenOptionIds": [
                        "4666084b-a154-41b9-9928-008102c0d7a0"
                    ]
                },
                "EDE-7": {
                    "questionId": "EDE-7",
                    "chosenOptionIds": [
                        "cd43c779-6d4c-44ea-8d27-a799d6927854"
                    ]
                },
                "EDE-8": {
                    "questionId": "EDE-8",
                    "chosenOptionIds": [
                        "d9a2b484-9852-4df6-9739-9098660c775c"
                    ]
                },
                "EDE-9": {
                    "questionId": "EDE-9",
                    "chosenOptionIds": [
                        "249d2a50-c9db-4950-8561-2cf830830ca4"
                    ]
                },
                "EDE-10": {
                    "questionId": "EDE-10",
                    "chosenOptionIds": [
                        "bf72638b-6209-4e2c-868d-b1e3773321b3"
                    ]
                },
                "EDE-11": {
                    "questionId": "EDE-11",
                    "chosenOptionIds": [
                        "59893bc8-2610-4b73-b17b-90a9b4cf0286"
                    ]
                },
                "EDE-12": {
                    "questionId": "EDE-12",
                    "chosenOptionIds": [
                        "28e75936-fa66-4ce6-b9ff-cac7a1ae88d6"
                    ]
                }
            }
        },
        {
            "name": "EDE-QS boundry below-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.3888888888888,
                "threshold": 0
            },
            "answers": {
                "EDE-1": {
                    "questionId": "EDE-1",
                    "chosenOptionIds": [
                        "5bcaffaa-cbd4-4b5f-b3b8-f6a16b9c6f96"
                    ]
                },
                "EDE-2": {
                    "questionId": "EDE-2",
                    "chosenOptionIds": [
                        "fbe54168-e7c9-41c0-ab56-876bdf3a1acc"
                    ]
                },
                "EDE-3": {
                    "questionId": "EDE-3",
                    "chosenOptionIds": [
                        "bde6ac54-88c8-4dee-8857-e3f8b6a93ca6"
                    ]
                },
                "EDE-4": {
                    "questionId": "EDE-4",
                    "chosenOptionIds": [
                        "39ef7744-ced0-421a-b35c-3fb2d424eef8"
                    ]
                },
                "EDE-5": {
                    "questionId": "EDE-5",
                    "chosenOptionIds": [
                        "2e62c6c8-7ce5-40dd-a2e3-d6448e331661"
                    ]
                },
                "EDE-6": {
                    "questionId": "EDE-6",
                    "chosenOptionIds": [
                        "4666084b-a154-41b9-9928-008102c0d7a0"
                    ]
                },
                "EDE-7": {
                    "questionId": "EDE-7",
                    "chosenOptionIds": [
                        "cd43c779-6d4c-44ea-8d27-a799d6927854"
                    ]
                },
                "EDE-8": {
                    "questionId": "EDE-8",
                    "chosenOptionIds": [
                        "d9a2b484-9852-4df6-9739-9098660c775c"
                    ]
                },
                "EDE-9": {
                    "questionId": "EDE-9",
                    "chosenOptionIds": [
                        "249d2a50-c9db-4950-8561-2cf830830ca4"
                    ]
                },
                "EDE-10": {
                    "questionId": "EDE-10",
                    "chosenOptionIds": [
                        "bf72638b-6209-4e2c-868d-b1e3773321b3"
                    ]
                },
                "EDE-11": {
                    "questionId": "EDE-11",
                    "chosenOptionIds": [
                        "59893bc8-2610-4b73-b17b-90a9b4cf0286"
                    ]
                },
                "EDE-12": {
                    "questionId": "EDE-12",
                    "chosenOptionIds": [
                        "28e75936-fa66-4ce6-b9ff-cac7a1ae88d6"
                    ]
                }
            }
        },
        {
            "name": "EDE-QS boundry above-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.4166666666666,
                "threshold": 0
            },
            "answers": {
                "EDE-1": {
                    "questionId": "EDE-1",
                    "chosenOptionIds": [
                        "5bcaffaa-cbd4-4b5f-b3b8-f6a16b9c6f96"
                    ]
                },
                "EDE-2": {
                    "questionId": "EDE-2",
                    "chosenOptionIds": [
                        "b64bdced-0c40-44dc-bf7b-dcefa1e5fb12"
                    ]
                },
                "EDE-3": {
                    "questionId": "EDE-3",
                    "chosenOptionIds": [
                        "bde6ac54-88c8-4dee-8857-e3f8b6a93ca6"
                    ]
                },
                "EDE-4": {
                    "questionId": "EDE-4",
                    "chosenOptionIds": [
                        "39ef7744-ced0-421a-b35c-3fb2d424eef8"
                    ]
                },
                "EDE-5": {
                    "questionId": "EDE-5",
                    "chosenOptionIds": [
                        "2e62c6c8-7ce5-40dd-a2e3-d6448e331661"
                    ]
                },
                "EDE-6": {
                    "questionId": "EDE-6",
                    "chosenOptionIds": [
                        "4666084b-a154-41b9-9928-008102c0d7a0"
                    ]
                },
                "EDE-7": {
                    "questionId": "EDE-7",
                    "chosenOptionIds": [
                        "cd43c779-6d4c-44ea-8d27-a799d6927854"
                    ]
                },
                "EDE-8": {
                    "questionId": "EDE-8",
                    "chosenOptionIds": [
                        "d9a2b484-9852-4df6-9739-9098660c775c"
                    ]
                },
                "EDE-9": {
                    "questionId": "EDE-9",
                    "chosenOptionIds": [
                        "249d2a50-c9db-4950-8561-2cf830830ca4"
                    ]
                },
                "EDE-10": {
                    "questionId": "EDE-10",
                    "chosenOptionIds": [
                        "bf72638b-6209-4e2c-868d-b1e3773321b3"
                    ]
                },
                "EDE-11": {
                    "questionId": "EDE-11",
                    "chosenOptionIds": [
                        "59893bc8-2610-4b73-b17b-90a9b4cf0286"
                    ]
                },
                "EDE-12": {
                    "questionId": "EDE-12",
                    "chosenOptionIds": [
                        "28e75936-fa66-4ce6-b9ff-cac7a1ae88d6"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {};
