"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
const censeo_1 = require("../../schema/generated/censeo");
exports.questions = [
    {
        "__typename": "ContentQuestion",
        "id": "5P-content",
        "text": "Thank you for answering those questions. Now we'd like to give you space to describe your experiences in your own words. You can skip any of these questions, but this is your chance to tell us what matters most to you and what you hope might change.  \n\nIf you do not want to answer one of the questions, you can continue to the next one by clicking the 'OK' button.\n\nSelect 'Continue' to start.",
        "answers": [
            {
                "__typename": "Option",
                "id": "ff12c38b-6b00-4a9b-8f51-ebafeb3c7901",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "FreeTextQuestion",
        "id": "5P-0.1",
        "text": "What are the key issues for you at the moment that matter most to you?",
        "userType": censeo_1.UserType.Nhs,
        "mandatory": false
    },
    {
        "__typename": "FreeTextQuestion",
        "id": "5P-0.2",
        "text": "How are these issues affecting your daily life?",
        "userType": censeo_1.UserType.Nhs,
        "mandatory": false
    },
    {
        "__typename": "FreeTextQuestion",
        "id": "5P-3",
        "text": "What tends to make your symptoms worse?",
        "userType": censeo_1.UserType.Nhs,
        "mandatory": false
    },
    {
        "__typename": "FreeTextQuestion",
        "id": "5P-4",
        "text": "Has there been any recent changes or stresses in your life?",
        "userType": censeo_1.UserType.Nhs,
        "mandatory": false
    },
    {
        "__typename": "FreeTextQuestion",
        "id": "5P-1",
        "text": "What past experiences have influenced your current difficulties?",
        "userType": censeo_1.UserType.Nhs,
        "mandatory": false
    },
    {
        "__typename": "FreeTextQuestion",
        "id": "5P-2",
        "text": "Were there any particularly difficult or supportive relationships in your past?",
        "userType": censeo_1.UserType.Nhs,
        "mandatory": false
    },
    {
        "__typename": "FreeTextQuestion",
        "id": "5P-5",
        "text": "What makes it hard to feel better?",
        "userType": censeo_1.UserType.Nhs,
        "mandatory": false
    },
    {
        "__typename": "FreeTextQuestion",
        "id": "5P-6",
        "text": "What barriers do you face in accessing support?",
        "userType": censeo_1.UserType.Nhs,
        "mandatory": false
    },
    {
        "__typename": "FreeTextQuestion",
        "id": "5P-7",
        "text": "What helps you cope when things are difficult?",
        "userType": censeo_1.UserType.Nhs,
        "mandatory": false
    },
    {
        "__typename": "FreeTextQuestion",
        "id": "5P-8",
        "text": "What activities or strategies help you manage stress?",
        "userType": censeo_1.UserType.Nhs,
        "mandatory": false
    },
    {
        "__typename": "FreeTextQuestion",
        "id": "5P-9",
        "text": "What changes would you like to make in your life?",
        "userType": censeo_1.UserType.Nhs,
        "mandatory": false
    },
    {
        "__typename": "FreeTextQuestion",
        "id": "5P-10",
        "text": "What small steps could help you move forward?",
        "userType": censeo_1.UserType.Nhs,
        "mandatory": false
    }
];
exports.aspects = {
    "five_ps": {
        "type": "static"
    }
};
exports.tests = {};
exports.questionPredicates = {};
