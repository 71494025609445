"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
const censeo_1 = require("../../schema/generated/censeo");
exports.questions = [
    {
        "__typename": "ContentQuestion",
        "id": "PCL5-content-1",
        "text": "The next section includes questions about difficult experiences and feelings that some people find upsetting. \n\nThese may include questions about trauma, unusual experiences, eating concerns, and mood changes. \n\nYou can take breaks at any time, and there's a 'need urgent help' button at the top of the screen if you need it. \n\nClick 'Continue' to start.",
        "answers": [
            {
                "__typename": "Option",
                "id": "793c0fe0-87ad-4b5d-a9cf-2386cecb52ff",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "ContentQuestion",
        "id": "PCL5-content-2",
        "text": "The next set of questions asks about difficult or traumatic experiences. These might include questions about past events that were frightening, overwhelming, or distressing. It is important to know:\n\n- If you skip them, you will not be able to go back later.\n- Skipping will not affect your care.\n- You can talk about these experiences with your clinician whenever you are ready.",
        "answers": [
            {
                "__typename": "Option",
                "id": "d1128e4b-b964-47e8-b4e5-7d9d3eeee386",
                "text": "Continue and answer these questions",
                "primary": true,
                "value": 1
            },
            {
                "__typename": "Option",
                "id": "1638436a-7746-4bd4-8a89-4ee120fb843b",
                "text": "Skip these questions",
                "primary": false,
                "value": 0
            }
        ]
    },
    {
        "__typename": "ContentQuestion",
        "id": "PCL5-1",
        "text": "The following questions ask about problems you may have had after a very stressful experience involving actual or threatened death, serious injury, or sexual violence.\n\nIt could be something that happened to you directly, something you witnessed, or something you learned happened to a close family member or close friend.\n\nSome examples are a serious accident; fire; disaster such as a hurricane, tornado, or earthquake; physical or sexual attack or abuse; war; homicide; or suicide.\n\nPlease note that you can find information if you need urgent help by clicking on the 'urgent help' button above.\n\nIf you prefer you can skip these question about such stressful experiences and move on to the next set of questions.",
        "answers": [
            {
                "__typename": "Option",
                "id": "d14224b7-686e-4c46-9cf7-2e7f834d595c",
                "text": "Continue",
                "primary": true,
                "value": 1
            },
            {
                "__typename": "Option",
                "id": "b4b04fa5-a082-4f2d-ace0-2905b515aa1e",
                "text": "Skip these questions",
                "primary": false,
                "value": 0
            }
        ],
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "ContentQuestion",
        "id": "PCL5-2",
        "text": "First, we will ask a few questions about your worst event, which for these questions means the event that currently bothers you the most. This could be one of the examples on the previous page or some other very stressful experience.\n\nAlso, it could be a single event (for example, a car crash) or multiple similar events (for example, multiple stressful events in a war-zone or repeated sexual abuse).\n\nIf at any point you do not wish to continue, you can skip.",
        "answers": [
            {
                "__typename": "Option",
                "id": "ec46a085-7224-4e80-a0b6-885adff8b6f7",
                "text": "Continue",
                "primary": true,
                "value": 1
            },
            {
                "__typename": "Option",
                "id": "33aafd70-80e4-45fd-bde8-58484a182b70",
                "text": "Skip the rest of this section",
                "primary": false,
                "value": 0
            }
        ],
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "FreeTextQuestion",
        "id": "PCL5-3",
        "text": "Briefly identify the worst event if you feel comfortable doing so. If not, please click 'Continue'.",
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "FreeQuestion",
        "id": "PCL5-4",
        "text": "When did it happen? Please estimate if you are not sure.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a750efe2-187a-484c-af45-175f4e37afc8",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "4a080bac-136a-4c5f-9852-6e72b540fb26",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "PCL5-5",
        "text": "Did it involve actual or threatened death, serious injury, or sexual violence?",
        "isMultiselect": false,
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "29653428-70cf-4748-8c36-c7ce734287fe",
                "text": "It happened to me directly",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "06e1848b-3530-499d-80ec-0707bcd2481c",
                "text": "I witnessed it",
                "value": 0.2
            },
            {
                "__typename": "Option",
                "id": "15fa4f60-4d1f-4d7a-966d-63477576aa06",
                "text": "I learned about it happening to a close family member or close friend",
                "value": 0.4
            },
            {
                "__typename": "Option",
                "id": "11771260-9976-4351-8f10-eab976660b51",
                "text": "I was repeatedly exposed to details about it as part of my job (for example, paramedic, police, military, or other first responder)",
                "value": 0.6
            },
            {
                "__typename": "Option",
                "id": "1515d0bd-470f-46ea-899e-05e698b09936",
                "text": "Other",
                "value": 0.8
            }
        ],
        "id": "PCL5-6",
        "text": "How did you experience it?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeTextQuestion",
        "id": "PCL5-7",
        "text": "Please describe how you experienced it. If you'd prefer not to provide details, please click 'OK'.",
        "userType": censeo_1.UserType.Nhs
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "72b04d10-4093-431e-a3d3-2e83b26f919a",
                "text": "Accident or violence",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b0834cc6-941d-4385-a92b-4508f55ced4a",
                "text": "Natural causes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "ec7dcc2d-25a2-4c2d-b1d3-859d89f2db42",
                "text": "Not applicable (the event did not involve the death of a close family member or close friend)",
                "value": 1
            }
        ],
        "id": "PCL5-8",
        "text": "If the event involved the death of a close family member or close friend, was it due to some kind of accident or violence, or was it due to natural causes?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "4c558e02-1c22-4b83-81f6-8da4e0e15635",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "cd6ece9f-eb4a-467e-aa47-1c4a55e07ffe",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "87ba395f-7df9-431a-801c-fe6913df2057",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "610c7126-ce79-4539-b299-a5da35987db6",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "108d3ccb-56cf-4341-a883-7c46afcafc94",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "PCL5-9",
        "text": "In the past month, how much were you bothered by: \n\n**Repeated, disturbing, and unwanted memories of the stressful experience?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "219e670f-faee-4a70-8899-a137f731f9d8",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "51d051e5-384c-4e97-a75e-25b7c90d025a",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "6d5d4837-d157-4210-9bab-f1a4c0c80ff4",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "240ed192-3005-49e2-b88f-6774b831fca1",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "acfd7d15-8601-4074-a0c1-6da741aada9d",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "PCL5-10",
        "text": "In the past month, how much were you bothered by: \n\n**Repeated, disturbing dreams of the stressful experience?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "701a12ce-9bc7-4ee2-854c-4bc8e9e216c4",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "5d362f0c-d6d3-42b8-85db-beae4bfed563",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "36789157-4c84-40fc-9777-40c133337fda",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "ba3f1cf2-5f2c-42ff-8d66-6d3eda7b6650",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "a53e12cb-a35f-47ec-9b78-4f19060bb837",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "PCL5-11",
        "text": "In the past month, how much were you bothered by: \n\n**Suddenly feeling or acting as if the stressful experience were actually happening again (as if you were actually back there reliving it)?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "44bf8244-e179-44ca-97fa-937b060f98c5",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a4f53107-4eac-4369-8b29-023a1d2fbd7b",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "3e680d86-7e2c-478e-a7ac-d6d591bf679c",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "df0a07ed-198c-41f6-bbcd-66488127306b",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "c57f3792-c931-42e8-852b-8e8812cc00fa",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "PCL5-12",
        "text": "In the past month, how much were you bothered by: \n\n**Feeling very upset when something reminded you of the stressful experience?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "17c10d9b-c054-496d-9251-9c4f05d63654",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "65e968c5-994f-4ae7-b89c-952e06bb15b4",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "4f2f11a8-22ec-4464-a813-dc45632207a8",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "dc3db2bd-22d6-4cc9-879b-50d1bee3114a",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "cd16b05f-da14-4e08-b218-20b2025c8b7a",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "PCL5-13",
        "text": "In the past month, how much were you bothered by:\n\n**Having strong physical reactions when something reminded you of the stressful experience (for example, heart pounding, trouble breathing, sweating)?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "80b53b93-f3a8-4b03-800f-84b57364ca53",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "dcc1f7f1-4824-4362-a570-e05ff4c0095b",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "55678193-1313-4caf-b4b8-621ad239c766",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "48cea9b9-3889-4f9c-b120-52b6032ee46a",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "6b8ee38e-ed89-42c6-9b56-0182cc25eed4",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "PCL5-14",
        "text": "In the past month, how much were you bothered by: \n\n**Avoiding memories, thoughts, or feelings related to the stressful experience?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "2258fa4c-c35f-4c99-8946-e44ad37cea2e",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "5132d86c-6b5a-41e2-be12-dec9f0202939",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "d3121f15-462d-49f7-88e9-955de3cac7a0",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "594106bc-5213-4f54-9ae3-b7d72306d309",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "ba9203ce-72a8-42c0-8f44-b0f816d1fe8c",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "PCL5-15",
        "text": "In the past month, how much were you bothered by: \n\n**Avoiding external reminders of the stressful experience (for example, people, places, conversations, activities, objects, or situations)?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "03a782ce-fde1-4cc1-9168-35e828bb394c",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "0aa47162-1a0a-4877-98c7-10b474f74bea",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "bc2cb342-f778-4244-99fb-2457cb969a95",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "b4e3452a-ec13-44c0-a808-5d00aac84d7b",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "fd95f6f0-b153-4c81-b678-5051909266d9",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "PCL5-16",
        "text": "In the past month, how much were you bothered by: \n\n**Trouble remembering important parts of the stressful experience?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "7660ddcf-ee6b-4d00-a32b-ed4590652833",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "dbd690e1-4d01-49d4-b258-2e811073d491",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "be8bdec9-b349-4226-9135-f8c2c3245505",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "f82bcaef-57ac-422a-840a-f1a5c76f4d11",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "2d116a4e-f0ee-4bfd-8dfb-314c5f7f04bc",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "PCL5-17",
        "text": "In the past month, how much were you bothered by: \n\n**Having strong negative beliefs about yourself, other people, or the world (for example, having thoughts such as: I am bad, there is something seriously wrong with me, no one can be trusted, the world is completely dangerous)?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "758b44bf-45fe-442b-b11b-866679996790",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "5a192e94-514a-4fc7-bde7-ec2cc3cbb24b",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "e3bbb3aa-9834-428e-a793-24f7a813baa7",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "149a4d50-4f36-43cc-8517-2bc2b2f087c4",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "b5a22847-ac34-48fd-9a2b-681d8fd77561",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "PCL5-18",
        "text": "In the past month, how much were you bothered by: \n\n**Blaming yourself or someone else for the stressful experience or what happened after it?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "609ea34e-b459-48a2-a5ca-873720d3c49a",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ff50ea11-b2f9-46bd-8382-0345ae8247ec",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "13476c73-f84b-4ede-a535-381c11deefbb",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "86c5d10e-7baf-4727-b6c9-8e9550059c9d",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "622a39e9-4f94-44de-bdab-84bfaed9d72c",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "PCL5-19",
        "text": "In the past month, how much were you bothered by: \n\n**Having strong negative feelings such as fear, horror, anger, guilt, or shame?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "608a47f0-6424-4384-bb17-5f27dedac17f",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "270065ca-261a-4a2f-b0de-c923c5580846",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "81bc02c7-3f91-4580-b4ec-87e522ecaa73",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "ebeea7ad-e26b-4693-9ddf-67b439263579",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "12745236-86fe-4692-ad41-c9affd672be1",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "PCL5-20",
        "text": "In the past month, how much were you bothered by: \n\n**Loss of interest in activities that you used to enjoy?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "3ac0782a-e24c-4fc6-be3b-4be63d486889",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "0cd4339d-2c07-494e-becf-b19f6b592345",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "3f2f3a69-c8ef-49a0-abde-6375982ab6c1",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "babf6984-9156-4184-950f-38822c526406",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "427c8b3f-2c9d-485c-b42d-25db655b59fb",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "PCL5-21",
        "text": "In the past month, how much were you bothered by: \n\n**Feeling distant or cut off from other people?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "42d9e562-0631-4c70-b373-eb14b6a43cab",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "28acc69c-f091-4748-b36d-f550138bad37",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "bfd7cb7d-fa27-4636-8ba5-e42826c007f6",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "edb29ac9-0876-4117-af74-bab0048a7ac5",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "f7b8291c-6375-4db9-b3fc-4d80dd45e300",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "PCL5-22",
        "text": "In the past month, how much were you bothered by: \n\n**Trouble experiencing positive feelings (for example, being unable to feel happiness or have loving feelings for people close to you)?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ab79f56c-f3e5-4538-8e30-eac5d47b355a",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f3d1f052-bc53-4fc2-8245-5793fcbe807d",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "d5252eea-04f4-44cc-a5f6-c49401ec9046",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "7820d980-aa11-4285-8e80-0b896109ddd7",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "6ea01e19-fae7-44e2-840b-c9b7773405cf",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "PCL5-23",
        "text": "In the past month, how much were you bothered by: \n\n**Irritable behavior, angry outbursts, or acting aggressively?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "d868f251-b355-4453-81f6-18083545a854",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "cbbe20b0-a321-4869-8195-47d15a68c204",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "159f4559-657b-46ba-9c69-6229e2384bac",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "3ddc23a6-7fcd-4c11-8f78-5542ff6ff6f2",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "329b493c-fb60-4e94-b7fa-5182013ed05d",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "PCL5-24",
        "text": "In the past month, how much were you bothered by: \n\n**Taking too many risks or doing things that could cause you harm?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "34923662-4c68-482f-b23d-261a47127613",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ad0a4d30-3651-4287-ad1c-f63b7423d6b3",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "8b7f2cbf-45f8-4bc9-aa98-eaa79d1205fa",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "9c09e58d-ec22-4b67-a9a2-efc7e8274b79",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "ae3a687f-2750-46af-8551-2220b6c27f26",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "PCL5-25",
        "text": "In the past month, how much were you bothered by: \n\n**Being “superalert” or watchful or on guard?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "43fa5958-ae0f-4a4c-9d3f-2d5dec0aeaae",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a46cf2c1-fd3a-43cc-b47a-a8a3db9cdf06",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "fb73bd09-c6ae-4523-af8c-694c7435c3b9",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "3ff70490-270d-441f-b84e-cfaf5a58397f",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "9d0ec9dc-2abb-4b84-b9c4-56cfb810da3d",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "PCL5-26",
        "text": "In the past month, how much were you bothered by: \n\n**Feeling jumpy or easily startled?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "1921b4d6-329e-4ea1-97a6-ce72fa0f3b00",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "6b438b73-b62b-4f9e-af74-b134ead7c3bc",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "414a18c3-80df-4404-b2b7-6bba97ad38b9",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "cf882678-2d70-4107-8825-0f296b0d8850",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "aa53e279-f17e-462d-ba63-66600b3d9161",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "PCL5-27",
        "text": "In the past month, how much were you bothered by:\n\n**Having difficulty concentrating?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b3134d9a-497d-4889-8a40-41c15f69a379",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d94a2d11-79d4-4a91-9d4b-6b4715cd3910",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "4d18980b-e8d4-4882-95a1-e911c705ea1e",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "cf4a57d6-4360-44d7-a23f-85c9230082af",
                "text": "Quite a bit",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "45062077-8442-43fa-8e45-eff57043045e",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "PCL5-28",
        "text": "In the past month, how much were you bothered by:\n\n**Trouble falling or staying asleep?**",
        "isMultiselect": false
    }
];
exports.aspects = {
    "pcl5": {
        "type": "mean",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PCL5-9",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "4c558e02-1c22-4b83-81f6-8da4e0e15635": 0,
                            "cd6ece9f-eb4a-467e-aa47-1c4a55e07ffe": 0.25,
                            "87ba395f-7df9-431a-801c-fe6913df2057": 0.5,
                            "610c7126-ce79-4539-b299-a5da35987db6": 0.75,
                            "108d3ccb-56cf-4341-a883-7c46afcafc94": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PCL5-10",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "219e670f-faee-4a70-8899-a137f731f9d8": 0,
                            "51d051e5-384c-4e97-a75e-25b7c90d025a": 0.25,
                            "6d5d4837-d157-4210-9bab-f1a4c0c80ff4": 0.5,
                            "240ed192-3005-49e2-b88f-6774b831fca1": 0.75,
                            "acfd7d15-8601-4074-a0c1-6da741aada9d": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PCL5-11",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "701a12ce-9bc7-4ee2-854c-4bc8e9e216c4": 0,
                            "5d362f0c-d6d3-42b8-85db-beae4bfed563": 0.25,
                            "36789157-4c84-40fc-9777-40c133337fda": 0.5,
                            "ba3f1cf2-5f2c-42ff-8d66-6d3eda7b6650": 0.75,
                            "a53e12cb-a35f-47ec-9b78-4f19060bb837": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PCL5-12",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "44bf8244-e179-44ca-97fa-937b060f98c5": 0,
                            "a4f53107-4eac-4369-8b29-023a1d2fbd7b": 0.25,
                            "3e680d86-7e2c-478e-a7ac-d6d591bf679c": 0.5,
                            "df0a07ed-198c-41f6-bbcd-66488127306b": 0.75,
                            "c57f3792-c931-42e8-852b-8e8812cc00fa": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PCL5-13",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "17c10d9b-c054-496d-9251-9c4f05d63654": 0,
                            "65e968c5-994f-4ae7-b89c-952e06bb15b4": 0.25,
                            "4f2f11a8-22ec-4464-a813-dc45632207a8": 0.5,
                            "dc3db2bd-22d6-4cc9-879b-50d1bee3114a": 0.75,
                            "cd16b05f-da14-4e08-b218-20b2025c8b7a": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PCL5-14",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "80b53b93-f3a8-4b03-800f-84b57364ca53": 0,
                            "dcc1f7f1-4824-4362-a570-e05ff4c0095b": 0.25,
                            "55678193-1313-4caf-b4b8-621ad239c766": 0.5,
                            "48cea9b9-3889-4f9c-b120-52b6032ee46a": 0.75,
                            "6b8ee38e-ed89-42c6-9b56-0182cc25eed4": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PCL5-15",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "2258fa4c-c35f-4c99-8946-e44ad37cea2e": 0,
                            "5132d86c-6b5a-41e2-be12-dec9f0202939": 0.25,
                            "d3121f15-462d-49f7-88e9-955de3cac7a0": 0.5,
                            "594106bc-5213-4f54-9ae3-b7d72306d309": 0.75,
                            "ba9203ce-72a8-42c0-8f44-b0f816d1fe8c": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PCL5-16",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "03a782ce-fde1-4cc1-9168-35e828bb394c": 0,
                            "0aa47162-1a0a-4877-98c7-10b474f74bea": 0.25,
                            "bc2cb342-f778-4244-99fb-2457cb969a95": 0.5,
                            "b4e3452a-ec13-44c0-a808-5d00aac84d7b": 0.75,
                            "fd95f6f0-b153-4c81-b678-5051909266d9": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PCL5-17",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "7660ddcf-ee6b-4d00-a32b-ed4590652833": 0,
                            "dbd690e1-4d01-49d4-b258-2e811073d491": 0.25,
                            "be8bdec9-b349-4226-9135-f8c2c3245505": 0.5,
                            "f82bcaef-57ac-422a-840a-f1a5c76f4d11": 0.75,
                            "2d116a4e-f0ee-4bfd-8dfb-314c5f7f04bc": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PCL5-18",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "758b44bf-45fe-442b-b11b-866679996790": 0,
                            "5a192e94-514a-4fc7-bde7-ec2cc3cbb24b": 0.25,
                            "e3bbb3aa-9834-428e-a793-24f7a813baa7": 0.5,
                            "149a4d50-4f36-43cc-8517-2bc2b2f087c4": 0.75,
                            "b5a22847-ac34-48fd-9a2b-681d8fd77561": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PCL5-19",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "609ea34e-b459-48a2-a5ca-873720d3c49a": 0,
                            "ff50ea11-b2f9-46bd-8382-0345ae8247ec": 0.25,
                            "13476c73-f84b-4ede-a535-381c11deefbb": 0.5,
                            "86c5d10e-7baf-4727-b6c9-8e9550059c9d": 0.75,
                            "622a39e9-4f94-44de-bdab-84bfaed9d72c": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PCL5-20",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "608a47f0-6424-4384-bb17-5f27dedac17f": 0,
                            "270065ca-261a-4a2f-b0de-c923c5580846": 0.25,
                            "81bc02c7-3f91-4580-b4ec-87e522ecaa73": 0.5,
                            "ebeea7ad-e26b-4693-9ddf-67b439263579": 0.75,
                            "12745236-86fe-4692-ad41-c9affd672be1": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PCL5-21",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "3ac0782a-e24c-4fc6-be3b-4be63d486889": 0,
                            "0cd4339d-2c07-494e-becf-b19f6b592345": 0.25,
                            "3f2f3a69-c8ef-49a0-abde-6375982ab6c1": 0.5,
                            "babf6984-9156-4184-950f-38822c526406": 0.75,
                            "427c8b3f-2c9d-485c-b42d-25db655b59fb": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PCL5-22",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "42d9e562-0631-4c70-b373-eb14b6a43cab": 0,
                            "28acc69c-f091-4748-b36d-f550138bad37": 0.25,
                            "bfd7cb7d-fa27-4636-8ba5-e42826c007f6": 0.5,
                            "edb29ac9-0876-4117-af74-bab0048a7ac5": 0.75,
                            "f7b8291c-6375-4db9-b3fc-4d80dd45e300": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PCL5-23",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "ab79f56c-f3e5-4538-8e30-eac5d47b355a": 0,
                            "f3d1f052-bc53-4fc2-8245-5793fcbe807d": 0.25,
                            "d5252eea-04f4-44cc-a5f6-c49401ec9046": 0.5,
                            "7820d980-aa11-4285-8e80-0b896109ddd7": 0.75,
                            "6ea01e19-fae7-44e2-840b-c9b7773405cf": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PCL5-24",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "d868f251-b355-4453-81f6-18083545a854": 0,
                            "cbbe20b0-a321-4869-8195-47d15a68c204": 0.25,
                            "159f4559-657b-46ba-9c69-6229e2384bac": 0.5,
                            "3ddc23a6-7fcd-4c11-8f78-5542ff6ff6f2": 0.75,
                            "329b493c-fb60-4e94-b7fa-5182013ed05d": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PCL5-25",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "34923662-4c68-482f-b23d-261a47127613": 0,
                            "ad0a4d30-3651-4287-ad1c-f63b7423d6b3": 0.25,
                            "8b7f2cbf-45f8-4bc9-aa98-eaa79d1205fa": 0.5,
                            "9c09e58d-ec22-4b67-a9a2-efc7e8274b79": 0.75,
                            "ae3a687f-2750-46af-8551-2220b6c27f26": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PCL5-26",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "43fa5958-ae0f-4a4c-9d3f-2d5dec0aeaae": 0,
                            "a46cf2c1-fd3a-43cc-b47a-a8a3db9cdf06": 0.25,
                            "fb73bd09-c6ae-4523-af8c-694c7435c3b9": 0.5,
                            "3ff70490-270d-441f-b84e-cfaf5a58397f": 0.75,
                            "9d0ec9dc-2abb-4b84-b9c4-56cfb810da3d": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PCL5-27",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "1921b4d6-329e-4ea1-97a6-ce72fa0f3b00": 0,
                            "6b438b73-b62b-4f9e-af74-b134ead7c3bc": 0.25,
                            "414a18c3-80df-4404-b2b7-6bba97ad38b9": 0.5,
                            "cf882678-2d70-4107-8825-0f296b0d8850": 0.75,
                            "aa53e279-f17e-462d-ba63-66600b3d9161": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PCL5-28",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "b3134d9a-497d-4889-8a40-41c15f69a379": 0,
                            "d94a2d11-79d4-4a91-9d4b-6b4715cd3910": 0.25,
                            "4d18980b-e8d4-4882-95a1-e911c705ea1e": 0.5,
                            "cf4a57d6-4360-44d7-a23f-85c9230082af": 0.75,
                            "45062077-8442-43fa-8e45-eff57043045e": 1
                        }
                    }
                }
            }
        ]
    },
    "not(PCL5-content-2=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "PCL5-content-2",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "d1128e4b-b964-47e8-b4e5-7d9d3eeee386": false,
                        "1638436a-7746-4bd4-8a89-4ee120fb843b": true
                    }
                }
            }
        }
    },
    "not(PCL5-1=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "PCL5-1",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "d14224b7-686e-4c46-9cf7-2e7f834d595c": false,
                        "b4b04fa5-a082-4f2d-ace0-2905b515aa1e": true
                    }
                }
            }
        }
    },
    "not(PCL5-2=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "PCL5-2",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "ec46a085-7224-4e80-a0b6-885adff8b6f7": false,
                        "33aafd70-80e4-45fd-bde8-58484a182b70": true
                    }
                }
            }
        }
    }
};
exports.tests = {
    "pcl5": [
        {
            "name": "PCL5 - if score 0 -> Not probable-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0,
                "threshold": 0
            },
            "answers": {
                "PCL5-9": {
                    "questionId": "PCL5-9",
                    "chosenOptionIds": [
                        "4c558e02-1c22-4b83-81f6-8da4e0e15635"
                    ]
                },
                "PCL5-10": {
                    "questionId": "PCL5-10",
                    "chosenOptionIds": [
                        "219e670f-faee-4a70-8899-a137f731f9d8"
                    ]
                },
                "PCL5-11": {
                    "questionId": "PCL5-11",
                    "chosenOptionIds": [
                        "701a12ce-9bc7-4ee2-854c-4bc8e9e216c4"
                    ]
                },
                "PCL5-12": {
                    "questionId": "PCL5-12",
                    "chosenOptionIds": [
                        "44bf8244-e179-44ca-97fa-937b060f98c5"
                    ]
                },
                "PCL5-13": {
                    "questionId": "PCL5-13",
                    "chosenOptionIds": [
                        "17c10d9b-c054-496d-9251-9c4f05d63654"
                    ]
                },
                "PCL5-14": {
                    "questionId": "PCL5-14",
                    "chosenOptionIds": [
                        "80b53b93-f3a8-4b03-800f-84b57364ca53"
                    ]
                },
                "PCL5-15": {
                    "questionId": "PCL5-15",
                    "chosenOptionIds": [
                        "2258fa4c-c35f-4c99-8946-e44ad37cea2e"
                    ]
                },
                "PCL5-16": {
                    "questionId": "PCL5-16",
                    "chosenOptionIds": [
                        "03a782ce-fde1-4cc1-9168-35e828bb394c"
                    ]
                },
                "PCL5-17": {
                    "questionId": "PCL5-17",
                    "chosenOptionIds": [
                        "7660ddcf-ee6b-4d00-a32b-ed4590652833"
                    ]
                },
                "PCL5-18": {
                    "questionId": "PCL5-18",
                    "chosenOptionIds": [
                        "758b44bf-45fe-442b-b11b-866679996790"
                    ]
                },
                "PCL5-19": {
                    "questionId": "PCL5-19",
                    "chosenOptionIds": [
                        "609ea34e-b459-48a2-a5ca-873720d3c49a"
                    ]
                },
                "PCL5-20": {
                    "questionId": "PCL5-20",
                    "chosenOptionIds": [
                        "608a47f0-6424-4384-bb17-5f27dedac17f"
                    ]
                },
                "PCL5-21": {
                    "questionId": "PCL5-21",
                    "chosenOptionIds": [
                        "3ac0782a-e24c-4fc6-be3b-4be63d486889"
                    ]
                },
                "PCL5-22": {
                    "questionId": "PCL5-22",
                    "chosenOptionIds": [
                        "42d9e562-0631-4c70-b373-eb14b6a43cab"
                    ]
                },
                "PCL5-23": {
                    "questionId": "PCL5-23",
                    "chosenOptionIds": [
                        "ab79f56c-f3e5-4538-8e30-eac5d47b355a"
                    ]
                },
                "PCL5-24": {
                    "questionId": "PCL5-24",
                    "chosenOptionIds": [
                        "d868f251-b355-4453-81f6-18083545a854"
                    ]
                },
                "PCL5-25": {
                    "questionId": "PCL5-25",
                    "chosenOptionIds": [
                        "34923662-4c68-482f-b23d-261a47127613"
                    ]
                },
                "PCL5-26": {
                    "questionId": "PCL5-26",
                    "chosenOptionIds": [
                        "43fa5958-ae0f-4a4c-9d3f-2d5dec0aeaae"
                    ]
                },
                "PCL5-27": {
                    "questionId": "PCL5-27",
                    "chosenOptionIds": [
                        "1921b4d6-329e-4ea1-97a6-ce72fa0f3b00"
                    ]
                },
                "PCL5-28": {
                    "questionId": "PCL5-28",
                    "chosenOptionIds": [
                        "b3134d9a-497d-4889-8a40-41c15f69a379"
                    ]
                }
            }
        },
        {
            "name": "PCL5 - if score 31 -> Probable-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.3875,
                "threshold": 0
            },
            "answers": {
                "PCL5-9": {
                    "questionId": "PCL5-9",
                    "chosenOptionIds": [
                        "108d3ccb-56cf-4341-a883-7c46afcafc94"
                    ]
                },
                "PCL5-10": {
                    "questionId": "PCL5-10",
                    "chosenOptionIds": [
                        "acfd7d15-8601-4074-a0c1-6da741aada9d"
                    ]
                },
                "PCL5-11": {
                    "questionId": "PCL5-11",
                    "chosenOptionIds": [
                        "a53e12cb-a35f-47ec-9b78-4f19060bb837"
                    ]
                },
                "PCL5-12": {
                    "questionId": "PCL5-12",
                    "chosenOptionIds": [
                        "c57f3792-c931-42e8-852b-8e8812cc00fa"
                    ]
                },
                "PCL5-13": {
                    "questionId": "PCL5-13",
                    "chosenOptionIds": [
                        "cd16b05f-da14-4e08-b218-20b2025c8b7a"
                    ]
                },
                "PCL5-14": {
                    "questionId": "PCL5-14",
                    "chosenOptionIds": [
                        "6b8ee38e-ed89-42c6-9b56-0182cc25eed4"
                    ]
                },
                "PCL5-15": {
                    "questionId": "PCL5-15",
                    "chosenOptionIds": [
                        "ba9203ce-72a8-42c0-8f44-b0f816d1fe8c"
                    ]
                },
                "PCL5-16": {
                    "questionId": "PCL5-16",
                    "chosenOptionIds": [
                        "b4e3452a-ec13-44c0-a808-5d00aac84d7b"
                    ]
                },
                "PCL5-17": {
                    "questionId": "PCL5-17",
                    "chosenOptionIds": [
                        "7660ddcf-ee6b-4d00-a32b-ed4590652833"
                    ]
                },
                "PCL5-18": {
                    "questionId": "PCL5-18",
                    "chosenOptionIds": [
                        "758b44bf-45fe-442b-b11b-866679996790"
                    ]
                },
                "PCL5-19": {
                    "questionId": "PCL5-19",
                    "chosenOptionIds": [
                        "609ea34e-b459-48a2-a5ca-873720d3c49a"
                    ]
                },
                "PCL5-20": {
                    "questionId": "PCL5-20",
                    "chosenOptionIds": [
                        "608a47f0-6424-4384-bb17-5f27dedac17f"
                    ]
                },
                "PCL5-21": {
                    "questionId": "PCL5-21",
                    "chosenOptionIds": [
                        "3ac0782a-e24c-4fc6-be3b-4be63d486889"
                    ]
                },
                "PCL5-22": {
                    "questionId": "PCL5-22",
                    "chosenOptionIds": [
                        "42d9e562-0631-4c70-b373-eb14b6a43cab"
                    ]
                },
                "PCL5-23": {
                    "questionId": "PCL5-23",
                    "chosenOptionIds": [
                        "ab79f56c-f3e5-4538-8e30-eac5d47b355a"
                    ]
                },
                "PCL5-24": {
                    "questionId": "PCL5-24",
                    "chosenOptionIds": [
                        "d868f251-b355-4453-81f6-18083545a854"
                    ]
                },
                "PCL5-25": {
                    "questionId": "PCL5-25",
                    "chosenOptionIds": [
                        "34923662-4c68-482f-b23d-261a47127613"
                    ]
                },
                "PCL5-26": {
                    "questionId": "PCL5-26",
                    "chosenOptionIds": [
                        "43fa5958-ae0f-4a4c-9d3f-2d5dec0aeaae"
                    ]
                },
                "PCL5-27": {
                    "questionId": "PCL5-27",
                    "chosenOptionIds": [
                        "1921b4d6-329e-4ea1-97a6-ce72fa0f3b00"
                    ]
                },
                "PCL5-28": {
                    "questionId": "PCL5-28",
                    "chosenOptionIds": [
                        "b3134d9a-497d-4889-8a40-41c15f69a379"
                    ]
                }
            }
        },
        {
            "name": "PCL5 - if score 80 -> Probable-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 1,
                "threshold": 0
            },
            "answers": {
                "PCL5-9": {
                    "questionId": "PCL5-9",
                    "chosenOptionIds": [
                        "108d3ccb-56cf-4341-a883-7c46afcafc94"
                    ]
                },
                "PCL5-10": {
                    "questionId": "PCL5-10",
                    "chosenOptionIds": [
                        "acfd7d15-8601-4074-a0c1-6da741aada9d"
                    ]
                },
                "PCL5-11": {
                    "questionId": "PCL5-11",
                    "chosenOptionIds": [
                        "a53e12cb-a35f-47ec-9b78-4f19060bb837"
                    ]
                },
                "PCL5-12": {
                    "questionId": "PCL5-12",
                    "chosenOptionIds": [
                        "c57f3792-c931-42e8-852b-8e8812cc00fa"
                    ]
                },
                "PCL5-13": {
                    "questionId": "PCL5-13",
                    "chosenOptionIds": [
                        "cd16b05f-da14-4e08-b218-20b2025c8b7a"
                    ]
                },
                "PCL5-14": {
                    "questionId": "PCL5-14",
                    "chosenOptionIds": [
                        "6b8ee38e-ed89-42c6-9b56-0182cc25eed4"
                    ]
                },
                "PCL5-15": {
                    "questionId": "PCL5-15",
                    "chosenOptionIds": [
                        "ba9203ce-72a8-42c0-8f44-b0f816d1fe8c"
                    ]
                },
                "PCL5-16": {
                    "questionId": "PCL5-16",
                    "chosenOptionIds": [
                        "fd95f6f0-b153-4c81-b678-5051909266d9"
                    ]
                },
                "PCL5-17": {
                    "questionId": "PCL5-17",
                    "chosenOptionIds": [
                        "2d116a4e-f0ee-4bfd-8dfb-314c5f7f04bc"
                    ]
                },
                "PCL5-18": {
                    "questionId": "PCL5-18",
                    "chosenOptionIds": [
                        "b5a22847-ac34-48fd-9a2b-681d8fd77561"
                    ]
                },
                "PCL5-19": {
                    "questionId": "PCL5-19",
                    "chosenOptionIds": [
                        "622a39e9-4f94-44de-bdab-84bfaed9d72c"
                    ]
                },
                "PCL5-20": {
                    "questionId": "PCL5-20",
                    "chosenOptionIds": [
                        "12745236-86fe-4692-ad41-c9affd672be1"
                    ]
                },
                "PCL5-21": {
                    "questionId": "PCL5-21",
                    "chosenOptionIds": [
                        "427c8b3f-2c9d-485c-b42d-25db655b59fb"
                    ]
                },
                "PCL5-22": {
                    "questionId": "PCL5-22",
                    "chosenOptionIds": [
                        "f7b8291c-6375-4db9-b3fc-4d80dd45e300"
                    ]
                },
                "PCL5-23": {
                    "questionId": "PCL5-23",
                    "chosenOptionIds": [
                        "6ea01e19-fae7-44e2-840b-c9b7773405cf"
                    ]
                },
                "PCL5-24": {
                    "questionId": "PCL5-24",
                    "chosenOptionIds": [
                        "329b493c-fb60-4e94-b7fa-5182013ed05d"
                    ]
                },
                "PCL5-25": {
                    "questionId": "PCL5-25",
                    "chosenOptionIds": [
                        "ae3a687f-2750-46af-8551-2220b6c27f26"
                    ]
                },
                "PCL5-26": {
                    "questionId": "PCL5-26",
                    "chosenOptionIds": [
                        "9d0ec9dc-2abb-4b84-b9c4-56cfb810da3d"
                    ]
                },
                "PCL5-27": {
                    "questionId": "PCL5-27",
                    "chosenOptionIds": [
                        "aa53e279-f17e-462d-ba63-66600b3d9161"
                    ]
                },
                "PCL5-28": {
                    "questionId": "PCL5-28",
                    "chosenOptionIds": [
                        "45062077-8442-43fa-8e45-eff57043045e"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {
    "PCL5-1": "not(PCL5-content-2=0)",
    "PCL5-2": "not(PCL5-1=0)",
    "PCL5-3": "not(PCL5-2=0)",
    "PCL5-4": "not(PCL5-2=0)",
    "PCL5-5": "not(PCL5-2=0)",
    "PCL5-6": "not(PCL5-2=0)",
    "PCL5-7": "not(PCL5-2=0)",
    "PCL5-8": "not(PCL5-2=0)",
    "PCL5-9": "not(PCL5-2=0)",
    "PCL5-10": "not(PCL5-2=0)",
    "PCL5-11": "not(PCL5-2=0)",
    "PCL5-12": "not(PCL5-2=0)",
    "PCL5-13": "not(PCL5-2=0)",
    "PCL5-14": "not(PCL5-2=0)",
    "PCL5-15": "not(PCL5-2=0)",
    "PCL5-16": "not(PCL5-2=0)",
    "PCL5-17": "not(PCL5-2=0)",
    "PCL5-18": "not(PCL5-2=0)",
    "PCL5-19": "not(PCL5-2=0)",
    "PCL5-20": "not(PCL5-2=0)",
    "PCL5-21": "not(PCL5-2=0)",
    "PCL5-22": "not(PCL5-2=0)",
    "PCL5-23": "not(PCL5-2=0)",
    "PCL5-24": "not(PCL5-2=0)",
    "PCL5-25": "not(PCL5-2=0)",
    "PCL5-26": "not(PCL5-2=0)",
    "PCL5-27": "not(PCL5-2=0)",
    "PCL5-28": "not(PCL5-2=0)"
};
