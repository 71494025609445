import React, { useContext } from 'react'
import ContentPage from '../components/ContentPage'
import { useNavigate } from 'react-router'
import { Text, Heading } from '@psyomics/components'
import { InfoContext } from '../../../configure'
import Styles from './RegulatoryInformation.module.scss'
import * as Sentry from '@sentry/react'
import { useDemoContext } from 'registration/pages/Demo/DemoContext'

export const RegulatoryInformation: React.FC = () => {
    const { demo } = useDemoContext()
    const navigate = useNavigate()
    const onMyAccount = () => {
        navigate(demo ? '/demo/service-user/userprofile' : '/userprofile')
    }
    const info = useContext(InfoContext)
    const releaseDate = info?.date
        ? new Date(info.date).toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' })
        : 'No release date information'
    const version = info?.appVersion ? info?.appVersion : 'No version information'
    if (!info?.appVersion) {
        Sentry.captureMessage('No app version found for regulatory page')
    }
    return (
        <ContentPage headingText={'About this app'} headingButton={{ text: 'My Account', link: onMyAccount }}>
            <div className={Styles.content}>
                <Heading el="h2" size="heading3" className={Styles.heading}>
                    How this app works
                </Heading>
                <Text el="p" css={{ mb: 5 }}>
                    This app uses standardised questionnaires to help collect information about your symptoms which will
                    be reviewed by a Healthcare Professional
                </Text>
                <Heading el="h2" size="heading3" className={Styles.heading}>
                    Software information
                </Heading>
                <Text el="h4">App name</Text>
                <Text weight="demibold" css={{ mb: 4 }} el="p">
                    Psyomics Platform
                </Text>
                <div className={Styles.iconRow}>
                    <div>
                        <Text el="h3">App version</Text>
                        <Text weight="demibold" el="p">
                            {version}
                        </Text>
                    </div>
                </div>
                <div className={Styles.iconRow}>
                    <div>
                        <Text el="h3">Date of release</Text>
                        <Text weight="demibold" el="p">
                            {releaseDate}
                        </Text>
                    </div>
                </div>
                <div className={Styles.iconRow}>
                    <div>
                        <Text el="h3">Manufacturer or maintainer</Text>
                        <Text el="p" weight="demibold">
                            Psyomics Ltd.
                            <br />
                            7 Quy Court Colliers Lane,
                            <br />
                            Stow-Cum-Quy,
                            <br />
                            Cambridge,
                            <br />
                            England,
                            <br />
                            CB25 9AU
                            <br />
                        </Text>
                    </div>
                </div>
            </div>
        </ContentPage>
    )
}
