import React from 'react'
import { classNameMaker } from 'ui/utils'
import { Page, Button, Spinner } from '@psyomics/components'
import { useNavigate } from 'react-router-dom'
import Styles from './QuestionPage.module.scss'
import HelpModal from 'ui/app/components/HelpModal'
import { Timers, pauseTimer } from 'ui/utils/timers'
import { useDemoContext } from 'registration/pages/Demo/DemoContext'

interface OurProps {
    readonly isInformational?: boolean
    readonly onOk?: () => void
    readonly onSkip?: () => void
    readonly onBack: () => void
    readonly okVisible?: boolean
    readonly okDisabled?: boolean
    readonly backDisabled?: boolean
    readonly updating?: boolean
    readonly value?: number | null | undefined
    readonly skippable?: string | null | undefined
}

type IQuestionPageProps = OurProps & React.ComponentPropsWithoutRef<'div'>

const QuestionPage: React.FC<IQuestionPageProps> = ({
    isInformational = false,
    onOk,
    onBack,
    onSkip,
    okVisible = true,
    okDisabled = false,
    children,
    updating = false,
    backDisabled = false,
    ...props
}) => {
    const { demo, demoFrom } = useDemoContext()
    const footerClasses = classNameMaker({
        [Styles.footer]: true,
    })
    const navigate = useNavigate()
    const onExit = () => {
        pauseTimer(Timers.AssessmentTotalDuration)
        pauseTimer(Timers.QuestionTimeSpent)
        navigate(demo ? '/demo/service-user/checkIn' : '/welcome')
    }

    const Header = (
        <header className={Styles.header}>
            <HelpModal label="Urgent help" />
            <Button appearance="secondary" label="Exit" size="small" onClick={onExit} />
        </header>
    )
    const Footer = (
        <footer className={footerClasses}>
            <Button
                size="small"
                aria-label="Previous question"
                icon="arrow-left"
                onClick={() => onBack()}
                disabled={backDisabled}
            ></Button>
            <Button size="small" onClick={onOk} icon="arrow-right" aria-label="Next question" disabled={okDisabled} />
        </footer>
    )
    return (
        <Page isInsideMobileFrame={demoFrom === 'start'} header={Header} footer={Footer} {...props}>
            {children}
            <div className={props.skippable && onSkip ? Styles.actionContainerWithSkip : Styles.actionContainer}>
                {okVisible ? (
                    <>
                        <Button
                            appearance="primary"
                            size="large"
                            label={isInformational ? 'Continue' : 'OK'}
                            disabled={okDisabled}
                            onClick={onOk}
                            loading={updating}
                        />
                        {props.skippable && onSkip && (
                            <Button
                                appearance="secondary"
                                size="large"
                                label={props.skippable}
                                disabled={false}
                                onClick={onSkip}
                            />
                        )}
                    </>
                ) : (
                    updating && <Spinner size="medium" />
                )}
            </div>
        </Page>
    )
}

export default QuestionPage
