"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
const censeo_1 = require("../../schema/generated/censeo");
exports.questions = [
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e3f844ee-a641-5899-a9da-8d4dbd688782",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a38151d3-973e-530c-b7c2-51b9e33001d5",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "729f7460-99f9-56df-94fe-854d75e389b1",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "6b433886-476a-53c6-ada2-1e2260c12d17",
                "text": "Very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "c4f76c19-a59a-59dd-89af-dbc327647d2d",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "SP-s1",
        "text": "Have you ever been afraid that you might fall short of other people's expectations and that they will notice?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "3796a228-2196-5f8a-9bdd-ce0d6f1ed8c5",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c3f9b887-668c-5d88-bb1f-4492aa66b0e4",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "9e25633b-ffae-5cf6-aefc-bd5a3d5a0a12",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "43effc14-ac9c-56fb-bba9-aca3514c6c77",
                "text": "Very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "0c4dea35-391c-55c6-8db1-5f20a1f4d6a7",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "SP-s2",
        "text": "Have you ever been afraid or embarrassed about being watched?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "89f821ca-0625-5069-b53c-b427bb9e13d4",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "662a26db-5ace-5827-a9b4-5d9907eefa79",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "06ead534-7682-558b-8af2-f1fe4ca79c1f",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "5fe7f80f-b639-5222-a34d-394a586888a4",
                "text": "Very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "a6d974f8-4999-5ad7-ae39-524148b324c0",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "SP-s3",
        "text": "Have you ever been afraid of being the focus of attention?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a0f2ad4f-2e36-5a45-9552-a8bf992a1ef1",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "918e349b-2944-5ad5-8010-9bc2bf8bdc2a",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "256f2151-3716-5adc-89c2-7cc79b551197",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "442311b3-fedd-589a-95a1-5a0b5c17f8c4",
                "text": "Very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "89646e2f-b5da-5058-bf39-0dca36bec2cb",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "SP-s4",
        "text": "Have you ever been afraid of being humiliated?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "35110660-4738-5bcd-96a1-3ad5a821141f",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "081d965a-5599-5747-93df-ec639c98b41f",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SP-s5",
        "text": "Is this anxiety in social situations something that you have experienced in the last 6 months?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "SP-s6b",
        "text": "For how long did you feel this fear or anxiety of social situations? If you can't remember, please give your best estimate.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "FreeQuestion",
        "id": "SP-b",
        "text": "How long ago did you experience this period of fear or anxiety related to social situations? If you can't remember, please give your best estimate.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "FreeQuestion",
        "id": "SP-s6a",
        "text": "For how long have you felt this fear or anxiety related to social situations? If you are unsure, please give your best estimate.",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "84da26c8-2e93-5432-8fe9-8e4c3bfa5ddf",
                "text": "Talking in front of others"
            },
            {
                "__typename": "Option",
                "id": "e307b879-8b6d-5e0f-950d-bdc01347e2d3",
                "text": "Meeting or talking to strangers or acquaintances"
            },
            {
                "__typename": "Option",
                "id": "3a2df70e-537a-5548-8f24-32a2a29da651",
                "text": "Eating or drinking in public"
            },
            {
                "__typename": "Option",
                "id": "ab832c79-0e01-5a40-b273-80805282a968",
                "text": "Being watched"
            },
            {
                "__typename": "Option",
                "id": "0f17cdba-f288-54e2-8a37-99ae7e664fa4",
                "text": "Performing in front of an audience"
            },
            {
                "__typename": "Option",
                "id": "c74dcc79-fb7f-55da-930e-313ebe0cb641",
                "text": "Socialising in a group"
            },
            {
                "__typename": "Option",
                "id": "c30a8e03-2164-5350-9e0b-486e36bd2ebb",
                "text": "Going to a public toilet"
            },
            {
                "__typename": "Option",
                "id": "b816dad5-6fb6-58cc-a84b-7187a04ab4c3",
                "text": "Eye-to-eye contact"
            },
            {
                "__typename": "Option",
                "id": "e4429794-5af9-5ed1-9ebb-ba8ca86a0ce1",
                "text": "Talking to a person you find attractive"
            },
            {
                "__typename": "Option",
                "id": "91b55a44-e875-576d-a183-43435c612a53",
                "text": "The possibility of vomiting in public"
            },
            {
                "__typename": "Option",
                "id": "b818ca80-ae9e-5b2c-8815-74a34c59c044",
                "text": "Talking to somebody who is in authority"
            },
            {
                "__typename": "Option",
                "id": "91b573f4-789e-5b64-92bd-ccd244e1972d",
                "text": "The possibility of being embarrassed"
            },
            {
                "__typename": "Option",
                "id": "e35006ab-1d51-5bcf-8813-bbeb792d491f",
                "text": "Being criticised"
            },
            {
                "__typename": "Option",
                "id": "cf26d3ef-30a0-549e-829f-7e75f8590565",
                "text": "Being the centre of attention"
            },
            {
                "__typename": "Option",
                "id": "f732df62-d939-52ee-ba37-a0cc386bd68f",
                "text": "None of the above",
                "exclusive": true
            }
        ],
        "id": "SP-1a",
        "text": "Do you feel extremely fearful or anxious about any of the following? Please select all that apply.",
        "isMultiselect": true
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "df7c2cde-0dfb-5913-a70a-6fb10882cd07",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f46ddc96-2ebe-5844-a9a4-1f11c5d98a36",
                "text": "Sometimes",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "699b519f-6c57-577e-9324-55766f14447f",
                "text": "Quite often",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "acd81294-8831-5a45-8e7a-1f458e4fbbdf",
                "text": "Very often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "dc100be9-9b82-5329-aa16-e143ad018760",
                "text": "All the time",
                "value": 1
            }
        ],
        "id": "SP-3.5a",
        "text": "How often do social situations make you feel very anxious or afraid?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "d37e215b-238d-53e0-a897-c4793cb3cfc5",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c61e6245-438c-520e-b1fc-8fdf2a2b777d",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "ab977d02-7d5f-5e37-bcfc-3dba076f51df",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "e4eff7df-e208-511d-820e-8607d2c089d8",
                "text": "Very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "10ff8965-17e1-59ec-a91b-3e903baa13a0",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "SP-4a",
        "text": "Are you worried you will act in a way or show anxiety symptoms that could be negatively judged by others or embarrassing?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "a271682c-056b-59bf-ad44-391fcbc15670",
                "text": "No, never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "9e4e0ea3-cb07-5f37-b5cb-ff5f93854c60",
                "text": "Sometimes",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "1d7cc7f7-6e71-5505-8531-3d8b180adb90",
                "text": "Quite often",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "abd020f5-cb4f-54b1-9ed2-893ac7ff4958",
                "text": "Very often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "e25496b1-0ed9-53e9-8ea8-993b3c3123f1",
                "text": "All the time",
                "value": 1
            }
        ],
        "id": "SP-5a",
        "text": "Some people feel very anxious in social or performance situations. They may experience physical symptoms such as ringing in the ears, a racing heart, sweating, trembling or shortness of breath. Does this happen to you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "9cc1eaa9-853e-50a1-848a-bbeaf73425bf",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b8b2eb62-775e-5d8b-b261-e346863f3b9a",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "1ceeedc6-bfdb-5552-b1c2-a4e4a9f3abe0",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "9ad81c0e-2776-568a-a0a5-c7eb52a58c78",
                "text": "Very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "4527f581-eb71-5e29-a9ce-4143e2938ecb",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "SP-6a",
        "text": "Do you feel that your fear of social or performance situations is excessive?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ed65a12c-b55b-5544-be9f-e070cc3b5a92",
                "text": "No, never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b616f997-0283-5f0c-8c36-131ca536758c",
                "text": "Sometimes",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "7001da0d-9f52-57b7-a595-77459b4de19b",
                "text": "Quite often",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "a0ef8867-a766-5812-a5d1-4249b1e9c99e",
                "text": "Very often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "396f7bdb-367e-544e-8dd1-49a0d8c58589",
                "text": "All the time",
                "value": 1
            }
        ],
        "id": "SP-12a",
        "text": "Do you replay social situations over and over again in your head after they have happened?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "12d8ea19-5e9f-583c-b31f-d0783c67d0e5",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "26aa2d4e-ad20-5a75-9e17-b762d7c4b14a",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "dbb234e2-d2f5-510c-afc0-0965824e35b2",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "2df17952-f192-59e5-966e-faa810365cdc",
                "text": "Very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "d43b4e51-8c41-5b94-85a5-1311e7e9c1ac",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "SP-13a",
        "text": "When you are faced with a social situation you fear, do you have certain behaviours you depend on? This could be things you do to avoid being noticed, to hide physical signs such as blushing or sweating, or to make others think well of you.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "d81a8e84-7a08-52dd-94d4-6c5f78f86d45",
                "text": "No, never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "fbad4753-c21f-5d92-b920-eeda0df028c1",
                "text": "Sometimes",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "e0ce7ba0-7bc1-525e-84ac-3eeb498d43ea",
                "text": "Quite often",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "6444b285-a910-5c51-b8e3-b9e2f3a1f4cb",
                "text": "Very often",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "d98a7849-60ea-54f3-b92b-2720f506b8a8",
                "text": "All the time",
                "value": 1
            }
        ],
        "id": "SP-14a",
        "text": "Do you tend to use certain substances to cope with social situations, such as alcohol, medication or recreational drugs?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "0aef8d58-89e6-58c6-b9d2-b29179a7a795",
                "text": "No, not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "45cab604-070b-5a9f-9c14-39f2d04b4883",
                "text": "To a small extent, but I still engage in most social situations",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "8c9d90a9-2d0c-553c-aaa2-6f53088f91e7",
                "text": "Somewhat. I tend to avoid the majority of social situations",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "af6f01bc-55f9-51d4-9531-ab0d07dab4a9",
                "text": "Very much so. I almost never engage in social events",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "1a4b5856-56e4-5e12-98ef-4b19bb86c61c",
                "text": "To an extreme amount. I live in social isolation",
                "value": 1
            }
        ],
        "id": "SP-15a",
        "text": "Does this fear cause you to avoid social situations? This could include avoiding talking on the phone or via video calls.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ab4ed09e-5682-5391-85a8-637e4c132c0a",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "a849e9fd-b390-5784-b120-8488a113fb34",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "cbacc3c9-c893-50d4-ab69-3826f7a9c80e",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "5e476e67-3397-596c-b5aa-228f70f81ca7",
                "text": "Very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "0b101597-878f-57d2-b689-f9b3f431a1d9",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "SP-16a",
        "text": "If you are not able to avoid a social situation, do you feel extreme fear or anxiety? This could include feeling extreme fear or anxiety during phone conversations or video calls.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e0b9578f-e9a5-56b0-96e1-a89329ddcf3c",
                "text": "Basic needs such as eating and sleeping"
            },
            {
                "__typename": "Option",
                "id": "ff70355e-77cb-5edd-acc6-4f5a5bd87812",
                "text": "Working or studying"
            },
            {
                "__typename": "Option",
                "id": "b14c4c87-62f1-5a86-b30c-d1a4e5a688bb",
                "text": "Intimate relationships"
            },
            {
                "__typename": "Option",
                "id": "d4bb9556-9e80-50cc-aada-b4362d4d12d6",
                "text": "Social life"
            },
            {
                "__typename": "Option",
                "id": "dc0787ea-aee0-537f-a6e8-06a5539be69c",
                "text": "Family"
            },
            {
                "__typename": "Option",
                "id": "7482d0b7-7353-5d8d-a520-9fbfa3343cbf",
                "text": "Leisure activities, hobbies and interests"
            },
            {
                "__typename": "Option",
                "id": "c75f20ef-3f69-5b6f-bbd1-3157cb401536",
                "text": "Keeping fit and healthy"
            },
            {
                "__typename": "Option",
                "id": "7149de4e-323b-530e-96c3-b9d13a5f2652",
                "text": "Finances"
            },
            {
                "__typename": "Option",
                "id": "c9c685c3-e334-5376-a214-5a96d666a44c",
                "text": "Other aspects of my life not mentioned above"
            },
            {
                "__typename": "Option",
                "id": "a3a9fc6f-d10d-4abe-86b7-08fd0d3f87e0",
                "text": "No areas of my life are affected by my worries about social situations",
                "exclusive": true
            }
        ],
        "id": "SP-17a",
        "text": "Which areas of your life, if any, do you feel are disrupted by worrying about social situations? Please select all that apply.",
        "isMultiselect": true
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b1d6f5a8-ed7b-5983-8c5f-3544ad79bb54",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f9277d5a-c693-5c89-aeba-600ca316f4c2",
                "text": "To a slight extent",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "748c3e8a-548a-59c0-841b-8ef86556a2c7",
                "text": "To a moderate extent",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "7ea4813f-57cd-5ba7-b21e-c076dda71014",
                "text": "To a significant extent",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "9cfbde86-55ad-56ae-b2c4-75aea806f939",
                "text": "To an extreme extent",
                "value": 1
            }
        ],
        "id": "SP-17.0a",
        "text": "To what extent do your worries related to social situations affect these areas of your life, or require you to make additional effort to maintain functioning in those areas?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "acd11895-4da3-5ed7-b7b6-d5242627e46e",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "59d8ffbf-ca3c-5a9f-b51c-ecfbe73c6890",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SP-20a",
        "text": "Do you have a job where you regularly have to perform or talk in front of an audience? This could include phone calls or video presentations.",
        "isMultiselect": false
    },
    {
        "__typename": "ContentQuestion",
        "id": "SP-S",
        "text": "You are making good progress through this question session. The next questions are about whether you have ever had very strong and sudden feelings of anxiety.",
        "answers": [
            {
                "__typename": "Option",
                "id": "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    }
];
exports.aspects = {
    "soa_ce": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "soa_c"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_soa"
                }
            }
        ],
        "anyToAll": 1
    },
    "has_soa": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "soa_A_marked_social_fear"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "soa_B_neg_evaluation"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "soa_C_fear_anxiety"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "soa_D_avoid_endure"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "soa_E_out_of_proportion"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "soa_F_duration"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "soa_G_distress_impairment"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "soa_screen_ever"
                }
            }
        ],
        "anyToAll": 1
    },
    "soa_A_marked_social_fear": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "SP-s1",
                            "options": {
                                "type": "severities",
                                "severities": {
                                    "e3f844ee-a641-5899-a9da-8d4dbd688782": 0.0005527786369235996,
                                    "a38151d3-973e-530c-b7c2-51b9e33001d5": 0.0066928509242848554,
                                    "729f7460-99f9-56df-94fe-854d75e389b1": 0.07585818002124355,
                                    "6b433886-476a-53c6-ada2-1e2260c12d17": 0.5,
                                    "c4f76c19-a59a-59dd-89af-dbc327647d2d": 0.9241418199787566
                                }
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "SP-s2",
                            "options": {
                                "type": "severities",
                                "severities": {
                                    "3796a228-2196-5f8a-9bdd-ce0d6f1ed8c5": 0.0005527786369235996,
                                    "c3f9b887-668c-5d88-bb1f-4492aa66b0e4": 0.0066928509242848554,
                                    "9e25633b-ffae-5cf6-aefc-bd5a3d5a0a12": 0.07585818002124355,
                                    "43effc14-ac9c-56fb-bba9-aca3514c6c77": 0.5,
                                    "0c4dea35-391c-55c6-8db1-5f20a1f4d6a7": 0.9241418199787566
                                }
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "SP-s3",
                            "options": {
                                "type": "severities",
                                "severities": {
                                    "89f821ca-0625-5069-b53c-b427bb9e13d4": 0.0005527786369235996,
                                    "662a26db-5ace-5827-a9b4-5d9907eefa79": 0.0066928509242848554,
                                    "06ead534-7682-558b-8af2-f1fe4ca79c1f": 0.07585818002124355,
                                    "5fe7f80f-b639-5222-a34d-394a586888a4": 0.5,
                                    "a6d974f8-4999-5ad7-ae39-524148b324c0": 0.9241418199787566
                                }
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "SP-s4",
                            "options": {
                                "type": "severities",
                                "severities": {
                                    "a0f2ad4f-2e36-5a45-9552-a8bf992a1ef1": 0.0005527786369235996,
                                    "918e349b-2944-5ad5-8010-9bc2bf8bdc2a": 0.0066928509242848554,
                                    "256f2151-3716-5adc-89c2-7cc79b551197": 0.07585818002124355,
                                    "442311b3-fedd-589a-95a1-5a0b5c17f8c4": 0.5,
                                    "89646e2f-b5da-5058-bf39-0dca36bec2cb": 0.9241418199787566
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 0.25
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "soa_A_marked_social_fear_allof_moderate"
                }
            }
        ],
        "anyToAll": 1
    },
    "soa_A_marked_social_fear_allof_moderate": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SP-s1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "e3f844ee-a641-5899-a9da-8d4dbd688782": 0.0066928509242848554,
                            "a38151d3-973e-530c-b7c2-51b9e33001d5": 0.07585818002124355,
                            "729f7460-99f9-56df-94fe-854d75e389b1": 0.5,
                            "6b433886-476a-53c6-ada2-1e2260c12d17": 0.9241418199787566,
                            "c4f76c19-a59a-59dd-89af-dbc327647d2d": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SP-s2",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "3796a228-2196-5f8a-9bdd-ce0d6f1ed8c5": 0.0066928509242848554,
                            "c3f9b887-668c-5d88-bb1f-4492aa66b0e4": 0.07585818002124355,
                            "9e25633b-ffae-5cf6-aefc-bd5a3d5a0a12": 0.5,
                            "43effc14-ac9c-56fb-bba9-aca3514c6c77": 0.9241418199787566,
                            "0c4dea35-391c-55c6-8db1-5f20a1f4d6a7": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SP-s3",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "89f821ca-0625-5069-b53c-b427bb9e13d4": 0.0066928509242848554,
                            "662a26db-5ace-5827-a9b4-5d9907eefa79": 0.07585818002124355,
                            "06ead534-7682-558b-8af2-f1fe4ca79c1f": 0.5,
                            "5fe7f80f-b639-5222-a34d-394a586888a4": 0.9241418199787566,
                            "a6d974f8-4999-5ad7-ae39-524148b324c0": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SP-s4",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "a0f2ad4f-2e36-5a45-9552-a8bf992a1ef1": 0.0066928509242848554,
                            "918e349b-2944-5ad5-8010-9bc2bf8bdc2a": 0.07585818002124355,
                            "256f2151-3716-5adc-89c2-7cc79b551197": 0.5,
                            "442311b3-fedd-589a-95a1-5a0b5c17f8c4": 0.9241418199787566,
                            "89646e2f-b5da-5058-bf39-0dca36bec2cb": 0.9933071490757153
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "soa_A_marked_social_fear_allof_slight": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SP-s1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "e3f844ee-a641-5899-a9da-8d4dbd688782": 0.07585818002124355,
                            "a38151d3-973e-530c-b7c2-51b9e33001d5": 0.5,
                            "729f7460-99f9-56df-94fe-854d75e389b1": 0.9241418199787566,
                            "6b433886-476a-53c6-ada2-1e2260c12d17": 0.9933071490757153,
                            "c4f76c19-a59a-59dd-89af-dbc327647d2d": 0.9994472213630764
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SP-s2",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "3796a228-2196-5f8a-9bdd-ce0d6f1ed8c5": 0.07585818002124355,
                            "c3f9b887-668c-5d88-bb1f-4492aa66b0e4": 0.5,
                            "9e25633b-ffae-5cf6-aefc-bd5a3d5a0a12": 0.9241418199787566,
                            "43effc14-ac9c-56fb-bba9-aca3514c6c77": 0.9933071490757153,
                            "0c4dea35-391c-55c6-8db1-5f20a1f4d6a7": 0.9994472213630764
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SP-s3",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "89f821ca-0625-5069-b53c-b427bb9e13d4": 0.07585818002124355,
                            "662a26db-5ace-5827-a9b4-5d9907eefa79": 0.5,
                            "06ead534-7682-558b-8af2-f1fe4ca79c1f": 0.9241418199787566,
                            "5fe7f80f-b639-5222-a34d-394a586888a4": 0.9933071490757153,
                            "a6d974f8-4999-5ad7-ae39-524148b324c0": 0.9994472213630764
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SP-s4",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "a0f2ad4f-2e36-5a45-9552-a8bf992a1ef1": 0.07585818002124355,
                            "918e349b-2944-5ad5-8010-9bc2bf8bdc2a": 0.5,
                            "256f2151-3716-5adc-89c2-7cc79b551197": 0.9241418199787566,
                            "442311b3-fedd-589a-95a1-5a0b5c17f8c4": 0.9933071490757153,
                            "89646e2f-b5da-5058-bf39-0dca36bec2cb": 0.9994472213630764
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "soa_B_neg_evaluation": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-4a",
            "options": {
                "type": "severities",
                "severities": {
                    "d37e215b-238d-53e0-a897-c4793cb3cfc5": 0.0066928509242848554,
                    "c61e6245-438c-520e-b1fc-8fdf2a2b777d": 0.07585818002124355,
                    "ab977d02-7d5f-5e37-bcfc-3dba076f51df": 0.5,
                    "e4eff7df-e208-511d-820e-8607d2c089d8": 0.9241418199787566,
                    "10ff8965-17e1-59ec-a91b-3e903baa13a0": 0.9933071490757153
                }
            }
        }
    },
    "soa_C_fear_anxiety": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-3.5a",
            "options": {
                "type": "severities",
                "severities": {
                    "df7c2cde-0dfb-5913-a70a-6fb10882cd07": 0.0066928509242848554,
                    "f46ddc96-2ebe-5844-a9a4-1f11c5d98a36": 0.07585818002124355,
                    "699b519f-6c57-577e-9324-55766f14447f": 0.5,
                    "acd81294-8831-5a45-8e7a-1f458e4fbbdf": 0.9241418199787566,
                    "dc100be9-9b82-5329-aa16-e143ad018760": 0.9933071490757153
                }
            }
        }
    },
    "soa_D_avoid_endure": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SP-16a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "ab4ed09e-5682-5391-85a8-637e4c132c0a": 0.0005527786369235996,
                            "a849e9fd-b390-5784-b120-8488a113fb34": 0.0066928509242848554,
                            "cbacc3c9-c893-50d4-ab69-3826f7a9c80e": 0.07585818002124355,
                            "5e476e67-3397-596c-b5aa-228f70f81ca7": 0.5,
                            "0b101597-878f-57d2-b689-f9b3f431a1d9": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SP-15a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "0aef8d58-89e6-58c6-b9d2-b29179a7a795": 0.0005527786369235996,
                            "45cab604-070b-5a9f-9c14-39f2d04b4883": 0.0066928509242848554,
                            "8c9d90a9-2d0c-553c-aaa2-6f53088f91e7": 0.07585818002124355,
                            "af6f01bc-55f9-51d4-9531-ab0d07dab4a9": 0.5,
                            "1a4b5856-56e4-5e12-98ef-4b19bb86c61c": 0.9241418199787566
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SP-13a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "12d8ea19-5e9f-583c-b31f-d0783c67d0e5": 0.0005527786369235996,
                            "26aa2d4e-ad20-5a75-9e17-b762d7c4b14a": 0.0066928509242848554,
                            "dbb234e2-d2f5-510c-afc0-0965824e35b2": 0.07585818002124355,
                            "2df17952-f192-59e5-966e-faa810365cdc": 0.5,
                            "d43b4e51-8c41-5b94-85a5-1311e7e9c1ac": 0.9241418199787566
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "soa_E_out_of_proportion": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-6a",
            "options": {
                "type": "severities",
                "severities": {
                    "9cc1eaa9-853e-50a1-848a-bbeaf73425bf": 0.07585818002124355,
                    "b8b2eb62-775e-5d8b-b261-e346863f3b9a": 0.5,
                    "1ceeedc6-bfdb-5552-b1c2-a4e4a9f3abe0": 0.9241418199787566,
                    "9ad81c0e-2776-568a-a0a5-c7eb52a58c78": 0.9933071490757153,
                    "4527f581-eb71-5e29-a9ce-4143e2938ecb": 0.9994472213630764
                }
            }
        }
    },
    "soa_F_duration": {
        "type": "input",
        "signal": {
            "type": "SpecificNumberInput",
            "questionId": "SP-s6a",
            "input": 182.5,
            "operator": ">="
        }
    },
    "soa_G_distress_impairment": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "soa_A_marked_social_fear"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "soa_impairment"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "soa_impairment": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "soa_impairment_atleast2_moderate"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "soa_impairment_one_severe"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "soa_impairment_atleast2_moderate": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SP17a_basic"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SP17a_work"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SP17a_relationships"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SP17a_social"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SP18a_family"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SP18a_leisure"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SP18a_health"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SP18a_financial"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "SP18a_other"
                }
            }
        ],
        "anyToAll": 0.2222222222222222
    },
    "soa_impairment_one_severe": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-17.0a",
            "options": {
                "type": "severities",
                "severities": {
                    "b1d6f5a8-ed7b-5983-8c5f-3544ad79bb54": 0.0005527786369235996,
                    "f9277d5a-c693-5c89-aeba-600ca316f4c2": 0.0066928509242848554,
                    "748c3e8a-548a-59c0-841b-8ef86556a2c7": 0.07585818002124355,
                    "7ea4813f-57cd-5ba7-b21e-c076dda71014": 0.5,
                    "9cfbde86-55ad-56ae-b2c4-75aea806f939": 0.9241418199787566
                }
            }
        }
    },
    "soa_c": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "soa_p"
            }
        }
    },
    "soa_p": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-s5",
            "options": {
                "type": "predicates",
                "predicates": {
                    "35110660-4738-5bcd-96a1-3ad5a821141f": true,
                    "081d965a-5599-5747-93df-ec639c98b41f": false
                }
            }
        }
    },
    "soa_screen_ever": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-s1",
            "options": {
                "type": "severities",
                "severities": {
                    "e3f844ee-a641-5899-a9da-8d4dbd688782": 0.07585818002124355,
                    "a38151d3-973e-530c-b7c2-51b9e33001d5": 0.5,
                    "729f7460-99f9-56df-94fe-854d75e389b1": 0.9241418199787566,
                    "6b433886-476a-53c6-ada2-1e2260c12d17": 0.9933071490757153,
                    "c4f76c19-a59a-59dd-89af-dbc327647d2d": 0.9994472213630764
                }
            }
        }
    },
    "SP1a_talking": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-1a",
            "options": {
                "type": "severities",
                "severities": {
                    "84da26c8-2e93-5432-8fe9-8e4c3bfa5ddf": 1
                }
            }
        }
    },
    "SP1a_strangers": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-1a",
            "options": {
                "type": "severities",
                "severities": {
                    "e307b879-8b6d-5e0f-950d-bdc01347e2d3": 1
                }
            }
        }
    },
    "SP1a_eatingdrinking": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-1a",
            "options": {
                "type": "severities",
                "severities": {
                    "3a2df70e-537a-5548-8f24-32a2a29da651": 1
                }
            }
        }
    },
    "SP1a_observed": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-1a",
            "options": {
                "type": "severities",
                "severities": {
                    "ab832c79-0e01-5a40-b273-80805282a968": 1
                }
            }
        }
    },
    "SP1a_performing": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-1a",
            "options": {
                "type": "severities",
                "severities": {
                    "0f17cdba-f288-54e2-8a37-99ae7e664fa4": 1
                }
            }
        }
    },
    "SP2a_socialising": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-1a",
            "options": {
                "type": "severities",
                "severities": {
                    "c74dcc79-fb7f-55da-930e-313ebe0cb641": 1
                }
            }
        }
    },
    "SP2a_toilet": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-1a",
            "options": {
                "type": "severities",
                "severities": {
                    "c30a8e03-2164-5350-9e0b-486e36bd2ebb": 1
                }
            }
        }
    },
    "SP2a_eye": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-1a",
            "options": {
                "type": "severities",
                "severities": {
                    "b816dad5-6fb6-58cc-a84b-7187a04ab4c3": 1
                }
            }
        }
    },
    "SP2a_fancy": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-1a",
            "options": {
                "type": "severities",
                "severities": {
                    "e4429794-5af9-5ed1-9ebb-ba8ca86a0ce1": 1
                }
            }
        }
    },
    "SP2a_vomiting": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-1a",
            "options": {
                "type": "severities",
                "severities": {
                    "91b55a44-e875-576d-a183-43435c612a53": 1
                }
            }
        }
    },
    "SP3a_authority": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-1a",
            "options": {
                "type": "severities",
                "severities": {
                    "b818ca80-ae9e-5b2c-8815-74a34c59c044": 1
                }
            }
        }
    },
    "SP3a_embarrassed": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-1a",
            "options": {
                "type": "severities",
                "severities": {
                    "91b573f4-789e-5b64-92bd-ccd244e1972d": 1
                }
            }
        }
    },
    "SP3a_criticised": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-1a",
            "options": {
                "type": "severities",
                "severities": {
                    "e35006ab-1d51-5bcf-8813-bbeb792d491f": 1
                }
            }
        }
    },
    "SP3a_attention": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-1a",
            "options": {
                "type": "severities",
                "severities": {
                    "cf26d3ef-30a0-549e-829f-7e75f8590565": 1
                }
            }
        }
    },
    "SP3a_": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-1a",
            "options": {
                "type": "severities",
                "severities": {
                    "f732df62-d939-52ee-ba37-a0cc386bd68f": 1
                }
            }
        }
    },
    "SP17a_basic": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-17a",
            "options": {
                "type": "severities",
                "severities": {
                    "e0b9578f-e9a5-56b0-96e1-a89329ddcf3c": 1
                }
            }
        }
    },
    "SP17a_work": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-17a",
            "options": {
                "type": "severities",
                "severities": {
                    "ff70355e-77cb-5edd-acc6-4f5a5bd87812": 1
                }
            }
        }
    },
    "SP17a_relationships": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-17a",
            "options": {
                "type": "severities",
                "severities": {
                    "b14c4c87-62f1-5a86-b30c-d1a4e5a688bb": 1
                }
            }
        }
    },
    "SP17a_social": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-17a",
            "options": {
                "type": "severities",
                "severities": {
                    "d4bb9556-9e80-50cc-aada-b4362d4d12d6": 1
                }
            }
        }
    },
    "SP18a_family": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-17a",
            "options": {
                "type": "severities",
                "severities": {
                    "dc0787ea-aee0-537f-a6e8-06a5539be69c": 1
                }
            }
        }
    },
    "SP18a_leisure": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-17a",
            "options": {
                "type": "severities",
                "severities": {
                    "7482d0b7-7353-5d8d-a520-9fbfa3343cbf": 1
                }
            }
        }
    },
    "SP18a_health": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-17a",
            "options": {
                "type": "severities",
                "severities": {
                    "c75f20ef-3f69-5b6f-bbd1-3157cb401536": 1
                }
            }
        }
    },
    "SP18a_financial": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-17a",
            "options": {
                "type": "severities",
                "severities": {
                    "7149de4e-323b-530e-96c3-b9d13a5f2652": 1
                }
            }
        }
    },
    "SP18a_other": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-17a",
            "options": {
                "type": "severities",
                "severities": {
                    "c9c685c3-e334-5376-a214-5a96d666a44c": 1
                }
            }
        }
    },
    "SP17a_none": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-17a",
            "options": {
                "type": "severities",
                "severities": {
                    "a3a9fc6f-d10d-4abe-86b7-08fd0d3f87e0": 1
                }
            }
        }
    },
    "not(SP-s1=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "SP-s1",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "e3f844ee-a641-5899-a9da-8d4dbd688782": true,
                        "a38151d3-973e-530c-b7c2-51b9e33001d5": false,
                        "729f7460-99f9-56df-94fe-854d75e389b1": false,
                        "6b433886-476a-53c6-ada2-1e2260c12d17": false,
                        "c4f76c19-a59a-59dd-89af-dbc327647d2d": false
                    }
                }
            }
        }
    },
    "and[or[isUndefined(and[SP-s1<=0.5, SP-s2<=0.5, SP-s3<=0.5, SP-s4<=0.5]), not(and[SP-s1<=0.5, SP-s2<=0.5, SP-s3<=0.5, SP-s4<=0.5])], not(isUndefined(SP-s4=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "SP-s1",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "e3f844ee-a641-5899-a9da-8d4dbd688782": 0.9933071490757152,
                                                "a38151d3-973e-530c-b7c2-51b9e33001d5": 0.9241418199787564,
                                                "729f7460-99f9-56df-94fe-854d75e389b1": 0.5,
                                                "6b433886-476a-53c6-ada2-1e2260c12d17": 0.07585818002124345,
                                                "c4f76c19-a59a-59dd-89af-dbc327647d2d": 0.006692850924284732
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "SP-s2",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "3796a228-2196-5f8a-9bdd-ce0d6f1ed8c5": 0.9933071490757152,
                                                "c3f9b887-668c-5d88-bb1f-4492aa66b0e4": 0.9241418199787564,
                                                "9e25633b-ffae-5cf6-aefc-bd5a3d5a0a12": 0.5,
                                                "43effc14-ac9c-56fb-bba9-aca3514c6c77": 0.07585818002124345,
                                                "0c4dea35-391c-55c6-8db1-5f20a1f4d6a7": 0.006692850924284732
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "SP-s3",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "89f821ca-0625-5069-b53c-b427bb9e13d4": 0.9933071490757152,
                                                "662a26db-5ace-5827-a9b4-5d9907eefa79": 0.9241418199787564,
                                                "06ead534-7682-558b-8af2-f1fe4ca79c1f": 0.5,
                                                "5fe7f80f-b639-5222-a34d-394a586888a4": 0.07585818002124345,
                                                "a6d974f8-4999-5ad7-ae39-524148b324c0": 0.006692850924284732
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "SP-s4",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "a0f2ad4f-2e36-5a45-9552-a8bf992a1ef1": 0.9933071490757152,
                                                "918e349b-2944-5ad5-8010-9bc2bf8bdc2a": 0.9241418199787564,
                                                "256f2151-3716-5adc-89c2-7cc79b551197": 0.5,
                                                "442311b3-fedd-589a-95a1-5a0b5c17f8c4": 0.07585818002124345,
                                                "89646e2f-b5da-5058-bf39-0dca36bec2cb": 0.006692850924284732
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "SP-s1",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "e3f844ee-a641-5899-a9da-8d4dbd688782": 0.9933071490757152,
                                                "a38151d3-973e-530c-b7c2-51b9e33001d5": 0.9241418199787564,
                                                "729f7460-99f9-56df-94fe-854d75e389b1": 0.5,
                                                "6b433886-476a-53c6-ada2-1e2260c12d17": 0.07585818002124345,
                                                "c4f76c19-a59a-59dd-89af-dbc327647d2d": 0.006692850924284732
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "SP-s2",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "3796a228-2196-5f8a-9bdd-ce0d6f1ed8c5": 0.9933071490757152,
                                                "c3f9b887-668c-5d88-bb1f-4492aa66b0e4": 0.9241418199787564,
                                                "9e25633b-ffae-5cf6-aefc-bd5a3d5a0a12": 0.5,
                                                "43effc14-ac9c-56fb-bba9-aca3514c6c77": 0.07585818002124345,
                                                "0c4dea35-391c-55c6-8db1-5f20a1f4d6a7": 0.006692850924284732
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "SP-s3",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "89f821ca-0625-5069-b53c-b427bb9e13d4": 0.9933071490757152,
                                                "662a26db-5ace-5827-a9b4-5d9907eefa79": 0.9241418199787564,
                                                "06ead534-7682-558b-8af2-f1fe4ca79c1f": 0.5,
                                                "5fe7f80f-b639-5222-a34d-394a586888a4": 0.07585818002124345,
                                                "a6d974f8-4999-5ad7-ae39-524148b324c0": 0.006692850924284732
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "SP-s4",
                                        "options": {
                                            "type": "severities",
                                            "severities": {
                                                "a0f2ad4f-2e36-5a45-9552-a8bf992a1ef1": 0.9933071490757152,
                                                "918e349b-2944-5ad5-8010-9bc2bf8bdc2a": 0.9241418199787564,
                                                "256f2151-3716-5adc-89c2-7cc79b551197": 0.5,
                                                "442311b3-fedd-589a-95a1-5a0b5c17f8c4": 0.07585818002124345,
                                                "89646e2f-b5da-5058-bf39-0dca36bec2cb": 0.006692850924284732
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "SP-s4",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "a0f2ad4f-2e36-5a45-9552-a8bf992a1ef1": true,
                                    "918e349b-2944-5ad5-8010-9bc2bf8bdc2a": false,
                                    "256f2151-3716-5adc-89c2-7cc79b551197": false,
                                    "442311b3-fedd-589a-95a1-5a0b5c17f8c4": false,
                                    "89646e2f-b5da-5058-bf39-0dca36bec2cb": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "SP-s5=0": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "SP-s5",
            "options": {
                "type": "predicates",
                "predicates": {
                    "35110660-4738-5bcd-96a1-3ad5a821141f": true,
                    "081d965a-5599-5747-93df-ec639c98b41f": false
                }
            }
        }
    },
    "not(SP-s5=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "SP-s5=0"
            }
        }
    },
    "and[or[isUndefined(SP17a_none=1), not(SP17a_none=1)], not(isUndefined(SP-17a=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "SP17a_none"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "SP17a_none"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "SP-17a",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "e0b9578f-e9a5-56b0-96e1-a89329ddcf3c": false,
                                    "ff70355e-77cb-5edd-acc6-4f5a5bd87812": false,
                                    "b14c4c87-62f1-5a86-b30c-d1a4e5a688bb": false,
                                    "d4bb9556-9e80-50cc-aada-b4362d4d12d6": false,
                                    "dc0787ea-aee0-537f-a6e8-06a5539be69c": false,
                                    "7482d0b7-7353-5d8d-a520-9fbfa3343cbf": false,
                                    "c75f20ef-3f69-5b6f-bbd1-3157cb401536": false,
                                    "7149de4e-323b-530e-96c3-b9d13a5f2652": false,
                                    "c9c685c3-e334-5376-a214-5a96d666a44c": false,
                                    "a3a9fc6f-d10d-4abe-86b7-08fd0d3f87e0": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    }
};
exports.tests = {
    "soa_ce": [
        {
            "name": "0 confidence subclinical: user: NEL6L3V aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "a38151d3-973e-530c-b7c2-51b9e33001d5"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "c3f9b887-668c-5d88-bb1f-4492aa66b0e4"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "662a26db-5ace-5827-a9b4-5d9907eefa79"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "a0f2ad4f-2e36-5a45-9552-a8bf992a1ef1"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: XE7OXEL aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "729f7460-99f9-56df-94fe-854d75e389b1"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "9e25633b-ffae-5cf6-aefc-bd5a3d5a0a12"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "06ead534-7682-558b-8af2-f1fe4ca79c1f"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "918e349b-2944-5ad5-8010-9bc2bf8bdc2a"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: O0KG5E1 aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-b": {
                    "questionId": "SP-b",
                    "freeValue": 0
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "c4f76c19-a59a-59dd-89af-dbc327647d2d"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "9e25633b-ffae-5cf6-aefc-bd5a3d5a0a12"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "89f821ca-0625-5069-b53c-b427bb9e13d4"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "442311b3-fedd-589a-95a1-5a0b5c17f8c4"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "35110660-4738-5bcd-96a1-3ad5a821141f"
                    ]
                },
                "SP-s6b": {
                    "questionId": "SP-s6b",
                    "freeValue": 0.041666666666666664
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: 6ED9M0M aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "729f7460-99f9-56df-94fe-854d75e389b1"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "c3f9b887-668c-5d88-bb1f-4492aa66b0e4"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "89f821ca-0625-5069-b53c-b427bb9e13d4"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "918e349b-2944-5ad5-8010-9bc2bf8bdc2a"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: NELRPEV aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "729f7460-99f9-56df-94fe-854d75e389b1"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "3796a228-2196-5f8a-9bdd-ce0d6f1ed8c5"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "89f821ca-0625-5069-b53c-b427bb9e13d4"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "256f2151-3716-5adc-89c2-7cc79b551197"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: J0VZ63L aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "a38151d3-973e-530c-b7c2-51b9e33001d5"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "3796a228-2196-5f8a-9bdd-ce0d6f1ed8c5"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "662a26db-5ace-5827-a9b4-5d9907eefa79"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "918e349b-2944-5ad5-8010-9bc2bf8bdc2a"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: 73ZJN0R aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "e3f844ee-a641-5899-a9da-8d4dbd688782"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: R042O34 aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "e3f844ee-a641-5899-a9da-8d4dbd688782"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: J0VRYEL aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "a38151d3-973e-530c-b7c2-51b9e33001d5"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "9e25633b-ffae-5cf6-aefc-bd5a3d5a0a12"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "06ead534-7682-558b-8af2-f1fe4ca79c1f"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "256f2151-3716-5adc-89c2-7cc79b551197"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: N0NN50Q aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "a38151d3-973e-530c-b7c2-51b9e33001d5"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "c3f9b887-668c-5d88-bb1f-4492aa66b0e4"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "662a26db-5ace-5827-a9b4-5d9907eefa79"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "918e349b-2944-5ad5-8010-9bc2bf8bdc2a"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: 73Z220R aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "729f7460-99f9-56df-94fe-854d75e389b1"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "c3f9b887-668c-5d88-bb1f-4492aa66b0e4"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "89f821ca-0625-5069-b53c-b427bb9e13d4"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "918e349b-2944-5ad5-8010-9bc2bf8bdc2a"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: N0N4J3Q aspect: soa_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "SP-12a": {
                    "questionId": "SP-12a",
                    "chosenOptionIds": [
                        "396f7bdb-367e-544e-8dd1-49a0d8c58589"
                    ]
                },
                "SP-13a": {
                    "questionId": "SP-13a",
                    "chosenOptionIds": [
                        "2df17952-f192-59e5-966e-faa810365cdc"
                    ]
                },
                "SP-14a": {
                    "questionId": "SP-14a",
                    "chosenOptionIds": [
                        "6444b285-a910-5c51-b8e3-b9e2f3a1f4cb"
                    ]
                },
                "SP-15a": {
                    "questionId": "SP-15a",
                    "chosenOptionIds": [
                        "af6f01bc-55f9-51d4-9531-ab0d07dab4a9"
                    ]
                },
                "SP-16a": {
                    "questionId": "SP-16a",
                    "chosenOptionIds": [
                        "0b101597-878f-57d2-b689-f9b3f431a1d9"
                    ]
                },
                "SP-17.0a": {
                    "questionId": "SP-17.0a",
                    "chosenOptionIds": [
                        "748c3e8a-548a-59c0-841b-8ef86556a2c7"
                    ]
                },
                "SP-17a": {
                    "questionId": "SP-17a",
                    "chosenOptionIds": [
                        "e0b9578f-e9a5-56b0-96e1-a89329ddcf3c",
                        "ff70355e-77cb-5edd-acc6-4f5a5bd87812",
                        "d4bb9556-9e80-50cc-aada-b4362d4d12d6",
                        "dc0787ea-aee0-537f-a6e8-06a5539be69c",
                        "7482d0b7-7353-5d8d-a520-9fbfa3343cbf",
                        "c75f20ef-3f69-5b6f-bbd1-3157cb401536"
                    ]
                },
                "SP-1a": {
                    "questionId": "SP-1a",
                    "chosenOptionIds": [
                        "84da26c8-2e93-5432-8fe9-8e4c3bfa5ddf",
                        "e307b879-8b6d-5e0f-950d-bdc01347e2d3",
                        "3a2df70e-537a-5548-8f24-32a2a29da651",
                        "ab832c79-0e01-5a40-b273-80805282a968",
                        "c74dcc79-fb7f-55da-930e-313ebe0cb641",
                        "0f17cdba-f288-54e2-8a37-99ae7e664fa4",
                        "b816dad5-6fb6-58cc-a84b-7187a04ab4c3",
                        "e4429794-5af9-5ed1-9ebb-ba8ca86a0ce1",
                        "91b573f4-789e-5b64-92bd-ccd244e1972d",
                        "cf26d3ef-30a0-549e-829f-7e75f8590565",
                        "e35006ab-1d51-5bcf-8813-bbeb792d491f",
                        "91b55a44-e875-576d-a183-43435c612a53"
                    ]
                },
                "SP-20a": {
                    "questionId": "SP-20a",
                    "chosenOptionIds": [
                        "59d8ffbf-ca3c-5a9f-b51c-ecfbe73c6890"
                    ]
                },
                "SP-3.5a": {
                    "questionId": "SP-3.5a",
                    "chosenOptionIds": [
                        "dc100be9-9b82-5329-aa16-e143ad018760"
                    ]
                },
                "SP-4a": {
                    "questionId": "SP-4a",
                    "chosenOptionIds": [
                        "10ff8965-17e1-59ec-a91b-3e903baa13a0"
                    ]
                },
                "SP-5a": {
                    "questionId": "SP-5a",
                    "chosenOptionIds": [
                        "e25496b1-0ed9-53e9-8ea8-993b3c3123f1"
                    ]
                },
                "SP-6a": {
                    "questionId": "SP-6a",
                    "chosenOptionIds": [
                        "4527f581-eb71-5e29-a9ce-4143e2938ecb"
                    ]
                },
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "c4f76c19-a59a-59dd-89af-dbc327647d2d"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "0c4dea35-391c-55c6-8db1-5f20a1f4d6a7"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "a6d974f8-4999-5ad7-ae39-524148b324c0"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "89646e2f-b5da-5058-bf39-0dca36bec2cb"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "081d965a-5599-5747-93df-ec639c98b41f"
                    ]
                },
                "SP-s6a": {
                    "questionId": "SP-s6a",
                    "freeValue": 3650
                }
            }
        },
        {
            "name": "Censeo vPrevious user: N0N4J3Q aspect: soa_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.75
            },
            "answers": {
                "SP-12a": {
                    "questionId": "SP-12a",
                    "chosenOptionIds": [
                        "396f7bdb-367e-544e-8dd1-49a0d8c58589"
                    ]
                },
                "SP-13a": {
                    "questionId": "SP-13a",
                    "chosenOptionIds": [
                        "2df17952-f192-59e5-966e-faa810365cdc"
                    ]
                },
                "SP-14a": {
                    "questionId": "SP-14a",
                    "chosenOptionIds": [
                        "6444b285-a910-5c51-b8e3-b9e2f3a1f4cb"
                    ]
                },
                "SP-15a": {
                    "questionId": "SP-15a",
                    "chosenOptionIds": [
                        "af6f01bc-55f9-51d4-9531-ab0d07dab4a9"
                    ]
                },
                "SP-16a": {
                    "questionId": "SP-16a",
                    "chosenOptionIds": [
                        "0b101597-878f-57d2-b689-f9b3f431a1d9"
                    ]
                },
                "SP-17.0a": {
                    "questionId": "SP-17.0a",
                    "chosenOptionIds": [
                        "748c3e8a-548a-59c0-841b-8ef86556a2c7"
                    ]
                },
                "SP-17a": {
                    "questionId": "SP-17a",
                    "chosenOptionIds": [
                        "e0b9578f-e9a5-56b0-96e1-a89329ddcf3c",
                        "ff70355e-77cb-5edd-acc6-4f5a5bd87812",
                        "d4bb9556-9e80-50cc-aada-b4362d4d12d6",
                        "dc0787ea-aee0-537f-a6e8-06a5539be69c",
                        "7482d0b7-7353-5d8d-a520-9fbfa3343cbf",
                        "c75f20ef-3f69-5b6f-bbd1-3157cb401536"
                    ]
                },
                "SP-1a": {
                    "questionId": "SP-1a",
                    "chosenOptionIds": [
                        "84da26c8-2e93-5432-8fe9-8e4c3bfa5ddf",
                        "e307b879-8b6d-5e0f-950d-bdc01347e2d3",
                        "3a2df70e-537a-5548-8f24-32a2a29da651",
                        "ab832c79-0e01-5a40-b273-80805282a968",
                        "c74dcc79-fb7f-55da-930e-313ebe0cb641",
                        "0f17cdba-f288-54e2-8a37-99ae7e664fa4",
                        "b816dad5-6fb6-58cc-a84b-7187a04ab4c3",
                        "e4429794-5af9-5ed1-9ebb-ba8ca86a0ce1",
                        "91b573f4-789e-5b64-92bd-ccd244e1972d",
                        "cf26d3ef-30a0-549e-829f-7e75f8590565",
                        "e35006ab-1d51-5bcf-8813-bbeb792d491f",
                        "91b55a44-e875-576d-a183-43435c612a53"
                    ]
                },
                "SP-20a": {
                    "questionId": "SP-20a",
                    "chosenOptionIds": [
                        "59d8ffbf-ca3c-5a9f-b51c-ecfbe73c6890"
                    ]
                },
                "SP-3.5a": {
                    "questionId": "SP-3.5a",
                    "chosenOptionIds": [
                        "dc100be9-9b82-5329-aa16-e143ad018760"
                    ]
                },
                "SP-4a": {
                    "questionId": "SP-4a",
                    "chosenOptionIds": [
                        "10ff8965-17e1-59ec-a91b-3e903baa13a0"
                    ]
                },
                "SP-5a": {
                    "questionId": "SP-5a",
                    "chosenOptionIds": [
                        "e25496b1-0ed9-53e9-8ea8-993b3c3123f1"
                    ]
                },
                "SP-6a": {
                    "questionId": "SP-6a",
                    "chosenOptionIds": [
                        "4527f581-eb71-5e29-a9ce-4143e2938ecb"
                    ]
                },
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "c4f76c19-a59a-59dd-89af-dbc327647d2d"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "0c4dea35-391c-55c6-8db1-5f20a1f4d6a7"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "a6d974f8-4999-5ad7-ae39-524148b324c0"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "89646e2f-b5da-5058-bf39-0dca36bec2cb"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "081d965a-5599-5747-93df-ec639c98b41f"
                    ]
                },
                "SP-s6a": {
                    "questionId": "SP-s6a",
                    "freeValue": 3650
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: 10QKZ0P aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-b": {
                    "questionId": "SP-b",
                    "freeValue": 365
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "c4f76c19-a59a-59dd-89af-dbc327647d2d"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "0c4dea35-391c-55c6-8db1-5f20a1f4d6a7"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "a6d974f8-4999-5ad7-ae39-524148b324c0"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "89646e2f-b5da-5058-bf39-0dca36bec2cb"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "35110660-4738-5bcd-96a1-3ad5a821141f"
                    ]
                },
                "SP-s6b": {
                    "questionId": "SP-s6b",
                    "freeValue": 1825
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: XE7ZR3L aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "a38151d3-973e-530c-b7c2-51b9e33001d5"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "c3f9b887-668c-5d88-bb1f-4492aa66b0e4"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "662a26db-5ace-5827-a9b4-5d9907eefa79"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "918e349b-2944-5ad5-8010-9bc2bf8bdc2a"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: K39V10L aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "e3f844ee-a641-5899-a9da-8d4dbd688782"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: 10QJ8EP aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "e3f844ee-a641-5899-a9da-8d4dbd688782"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: ME84L3W aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "a38151d3-973e-530c-b7c2-51b9e33001d5"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "c3f9b887-668c-5d88-bb1f-4492aa66b0e4"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "662a26db-5ace-5827-a9b4-5d9907eefa79"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "256f2151-3716-5adc-89c2-7cc79b551197"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: K392M0L aspect: soa_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SP-12a": {
                    "questionId": "SP-12a",
                    "chosenOptionIds": [
                        "b616f997-0283-5f0c-8c36-131ca536758c"
                    ]
                },
                "SP-13a": {
                    "questionId": "SP-13a",
                    "chosenOptionIds": [
                        "12d8ea19-5e9f-583c-b31f-d0783c67d0e5"
                    ]
                },
                "SP-14a": {
                    "questionId": "SP-14a",
                    "chosenOptionIds": [
                        "fbad4753-c21f-5d92-b920-eeda0df028c1"
                    ]
                },
                "SP-15a": {
                    "questionId": "SP-15a",
                    "chosenOptionIds": [
                        "af6f01bc-55f9-51d4-9531-ab0d07dab4a9"
                    ]
                },
                "SP-16a": {
                    "questionId": "SP-16a",
                    "chosenOptionIds": [
                        "ab4ed09e-5682-5391-85a8-637e4c132c0a"
                    ]
                },
                "SP-17.0a": {
                    "questionId": "SP-17.0a",
                    "chosenOptionIds": [
                        "748c3e8a-548a-59c0-841b-8ef86556a2c7"
                    ]
                },
                "SP-17a": {
                    "questionId": "SP-17a",
                    "chosenOptionIds": [
                        "ff70355e-77cb-5edd-acc6-4f5a5bd87812",
                        "b14c4c87-62f1-5a86-b30c-d1a4e5a688bb",
                        "d4bb9556-9e80-50cc-aada-b4362d4d12d6",
                        "dc0787ea-aee0-537f-a6e8-06a5539be69c"
                    ]
                },
                "SP-1a": {
                    "questionId": "SP-1a",
                    "chosenOptionIds": [
                        "f732df62-d939-52ee-ba37-a0cc386bd68f"
                    ]
                },
                "SP-20a": {
                    "questionId": "SP-20a",
                    "chosenOptionIds": [
                        "acd11895-4da3-5ed7-b7b6-d5242627e46e"
                    ]
                },
                "SP-3.5a": {
                    "questionId": "SP-3.5a",
                    "chosenOptionIds": [
                        "f46ddc96-2ebe-5844-a9a4-1f11c5d98a36"
                    ]
                },
                "SP-4a": {
                    "questionId": "SP-4a",
                    "chosenOptionIds": [
                        "c61e6245-438c-520e-b1fc-8fdf2a2b777d"
                    ]
                },
                "SP-5a": {
                    "questionId": "SP-5a",
                    "chosenOptionIds": [
                        "a271682c-056b-59bf-ad44-391fcbc15670"
                    ]
                },
                "SP-6a": {
                    "questionId": "SP-6a",
                    "chosenOptionIds": [
                        "9cc1eaa9-853e-50a1-848a-bbeaf73425bf"
                    ]
                },
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "6b433886-476a-53c6-ada2-1e2260c12d17"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "c3f9b887-668c-5d88-bb1f-4492aa66b0e4"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "662a26db-5ace-5827-a9b4-5d9907eefa79"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "256f2151-3716-5adc-89c2-7cc79b551197"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "081d965a-5599-5747-93df-ec639c98b41f"
                    ]
                },
                "SP-s6a": {
                    "questionId": "SP-s6a",
                    "freeValue": 0
                }
            }
        },
        {
            "name": "Censeo vPrevious user: K392M0L aspect: soa_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.4
            },
            "answers": {
                "SP-12a": {
                    "questionId": "SP-12a",
                    "chosenOptionIds": [
                        "b616f997-0283-5f0c-8c36-131ca536758c"
                    ]
                },
                "SP-13a": {
                    "questionId": "SP-13a",
                    "chosenOptionIds": [
                        "12d8ea19-5e9f-583c-b31f-d0783c67d0e5"
                    ]
                },
                "SP-14a": {
                    "questionId": "SP-14a",
                    "chosenOptionIds": [
                        "fbad4753-c21f-5d92-b920-eeda0df028c1"
                    ]
                },
                "SP-15a": {
                    "questionId": "SP-15a",
                    "chosenOptionIds": [
                        "af6f01bc-55f9-51d4-9531-ab0d07dab4a9"
                    ]
                },
                "SP-16a": {
                    "questionId": "SP-16a",
                    "chosenOptionIds": [
                        "ab4ed09e-5682-5391-85a8-637e4c132c0a"
                    ]
                },
                "SP-17.0a": {
                    "questionId": "SP-17.0a",
                    "chosenOptionIds": [
                        "748c3e8a-548a-59c0-841b-8ef86556a2c7"
                    ]
                },
                "SP-17a": {
                    "questionId": "SP-17a",
                    "chosenOptionIds": [
                        "ff70355e-77cb-5edd-acc6-4f5a5bd87812",
                        "b14c4c87-62f1-5a86-b30c-d1a4e5a688bb",
                        "d4bb9556-9e80-50cc-aada-b4362d4d12d6",
                        "dc0787ea-aee0-537f-a6e8-06a5539be69c"
                    ]
                },
                "SP-1a": {
                    "questionId": "SP-1a",
                    "chosenOptionIds": [
                        "f732df62-d939-52ee-ba37-a0cc386bd68f"
                    ]
                },
                "SP-20a": {
                    "questionId": "SP-20a",
                    "chosenOptionIds": [
                        "acd11895-4da3-5ed7-b7b6-d5242627e46e"
                    ]
                },
                "SP-3.5a": {
                    "questionId": "SP-3.5a",
                    "chosenOptionIds": [
                        "f46ddc96-2ebe-5844-a9a4-1f11c5d98a36"
                    ]
                },
                "SP-4a": {
                    "questionId": "SP-4a",
                    "chosenOptionIds": [
                        "c61e6245-438c-520e-b1fc-8fdf2a2b777d"
                    ]
                },
                "SP-5a": {
                    "questionId": "SP-5a",
                    "chosenOptionIds": [
                        "a271682c-056b-59bf-ad44-391fcbc15670"
                    ]
                },
                "SP-6a": {
                    "questionId": "SP-6a",
                    "chosenOptionIds": [
                        "9cc1eaa9-853e-50a1-848a-bbeaf73425bf"
                    ]
                },
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "6b433886-476a-53c6-ada2-1e2260c12d17"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "c3f9b887-668c-5d88-bb1f-4492aa66b0e4"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "662a26db-5ace-5827-a9b4-5d9907eefa79"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "256f2151-3716-5adc-89c2-7cc79b551197"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "081d965a-5599-5747-93df-ec639c98b41f"
                    ]
                },
                "SP-s6a": {
                    "questionId": "SP-s6a",
                    "freeValue": 0
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: 10Q6R0P aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "e3f844ee-a641-5899-a9da-8d4dbd688782"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: 63YPO3R aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "729f7460-99f9-56df-94fe-854d75e389b1"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "3796a228-2196-5f8a-9bdd-ce0d6f1ed8c5"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "662a26db-5ace-5827-a9b4-5d9907eefa79"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "256f2151-3716-5adc-89c2-7cc79b551197"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: 50MQK36 aspect: soa_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "SP-12a": {
                    "questionId": "SP-12a",
                    "chosenOptionIds": [
                        "396f7bdb-367e-544e-8dd1-49a0d8c58589"
                    ]
                },
                "SP-13a": {
                    "questionId": "SP-13a",
                    "chosenOptionIds": [
                        "2df17952-f192-59e5-966e-faa810365cdc"
                    ]
                },
                "SP-14a": {
                    "questionId": "SP-14a",
                    "chosenOptionIds": [
                        "e0ce7ba0-7bc1-525e-84ac-3eeb498d43ea"
                    ]
                },
                "SP-15a": {
                    "questionId": "SP-15a",
                    "chosenOptionIds": [
                        "45cab604-070b-5a9f-9c14-39f2d04b4883"
                    ]
                },
                "SP-16a": {
                    "questionId": "SP-16a",
                    "chosenOptionIds": [
                        "cbacc3c9-c893-50d4-ab69-3826f7a9c80e"
                    ]
                },
                "SP-17.0a": {
                    "questionId": "SP-17.0a",
                    "chosenOptionIds": [
                        "748c3e8a-548a-59c0-841b-8ef86556a2c7"
                    ]
                },
                "SP-17a": {
                    "questionId": "SP-17a",
                    "chosenOptionIds": [
                        "ff70355e-77cb-5edd-acc6-4f5a5bd87812",
                        "b14c4c87-62f1-5a86-b30c-d1a4e5a688bb",
                        "d4bb9556-9e80-50cc-aada-b4362d4d12d6",
                        "dc0787ea-aee0-537f-a6e8-06a5539be69c",
                        "7482d0b7-7353-5d8d-a520-9fbfa3343cbf"
                    ]
                },
                "SP-1a": {
                    "questionId": "SP-1a",
                    "chosenOptionIds": [
                        "84da26c8-2e93-5432-8fe9-8e4c3bfa5ddf",
                        "e307b879-8b6d-5e0f-950d-bdc01347e2d3",
                        "3a2df70e-537a-5548-8f24-32a2a29da651",
                        "ab832c79-0e01-5a40-b273-80805282a968",
                        "0f17cdba-f288-54e2-8a37-99ae7e664fa4",
                        "c30a8e03-2164-5350-9e0b-486e36bd2ebb",
                        "e4429794-5af9-5ed1-9ebb-ba8ca86a0ce1",
                        "b818ca80-ae9e-5b2c-8815-74a34c59c044",
                        "91b573f4-789e-5b64-92bd-ccd244e1972d",
                        "e35006ab-1d51-5bcf-8813-bbeb792d491f",
                        "cf26d3ef-30a0-549e-829f-7e75f8590565"
                    ]
                },
                "SP-20a": {
                    "questionId": "SP-20a",
                    "chosenOptionIds": [
                        "acd11895-4da3-5ed7-b7b6-d5242627e46e"
                    ]
                },
                "SP-3.5a": {
                    "questionId": "SP-3.5a",
                    "chosenOptionIds": [
                        "699b519f-6c57-577e-9324-55766f14447f"
                    ]
                },
                "SP-4a": {
                    "questionId": "SP-4a",
                    "chosenOptionIds": [
                        "e4eff7df-e208-511d-820e-8607d2c089d8"
                    ]
                },
                "SP-5a": {
                    "questionId": "SP-5a",
                    "chosenOptionIds": [
                        "a271682c-056b-59bf-ad44-391fcbc15670"
                    ]
                },
                "SP-6a": {
                    "questionId": "SP-6a",
                    "chosenOptionIds": [
                        "b8b2eb62-775e-5d8b-b261-e346863f3b9a"
                    ]
                },
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "c4f76c19-a59a-59dd-89af-dbc327647d2d"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "0c4dea35-391c-55c6-8db1-5f20a1f4d6a7"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "a6d974f8-4999-5ad7-ae39-524148b324c0"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "89646e2f-b5da-5058-bf39-0dca36bec2cb"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "081d965a-5599-5747-93df-ec639c98b41f"
                    ]
                },
                "SP-s6a": {
                    "questionId": "SP-s6a",
                    "freeValue": 8030
                }
            }
        },
        {
            "name": "Censeo vPrevious user: 50MQK36 aspect: soa_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.85
            },
            "answers": {
                "SP-12a": {
                    "questionId": "SP-12a",
                    "chosenOptionIds": [
                        "396f7bdb-367e-544e-8dd1-49a0d8c58589"
                    ]
                },
                "SP-13a": {
                    "questionId": "SP-13a",
                    "chosenOptionIds": [
                        "2df17952-f192-59e5-966e-faa810365cdc"
                    ]
                },
                "SP-14a": {
                    "questionId": "SP-14a",
                    "chosenOptionIds": [
                        "e0ce7ba0-7bc1-525e-84ac-3eeb498d43ea"
                    ]
                },
                "SP-15a": {
                    "questionId": "SP-15a",
                    "chosenOptionIds": [
                        "45cab604-070b-5a9f-9c14-39f2d04b4883"
                    ]
                },
                "SP-16a": {
                    "questionId": "SP-16a",
                    "chosenOptionIds": [
                        "cbacc3c9-c893-50d4-ab69-3826f7a9c80e"
                    ]
                },
                "SP-17.0a": {
                    "questionId": "SP-17.0a",
                    "chosenOptionIds": [
                        "748c3e8a-548a-59c0-841b-8ef86556a2c7"
                    ]
                },
                "SP-17a": {
                    "questionId": "SP-17a",
                    "chosenOptionIds": [
                        "ff70355e-77cb-5edd-acc6-4f5a5bd87812",
                        "b14c4c87-62f1-5a86-b30c-d1a4e5a688bb",
                        "d4bb9556-9e80-50cc-aada-b4362d4d12d6",
                        "dc0787ea-aee0-537f-a6e8-06a5539be69c",
                        "7482d0b7-7353-5d8d-a520-9fbfa3343cbf"
                    ]
                },
                "SP-1a": {
                    "questionId": "SP-1a",
                    "chosenOptionIds": [
                        "84da26c8-2e93-5432-8fe9-8e4c3bfa5ddf",
                        "e307b879-8b6d-5e0f-950d-bdc01347e2d3",
                        "3a2df70e-537a-5548-8f24-32a2a29da651",
                        "ab832c79-0e01-5a40-b273-80805282a968",
                        "0f17cdba-f288-54e2-8a37-99ae7e664fa4",
                        "c30a8e03-2164-5350-9e0b-486e36bd2ebb",
                        "e4429794-5af9-5ed1-9ebb-ba8ca86a0ce1",
                        "b818ca80-ae9e-5b2c-8815-74a34c59c044",
                        "91b573f4-789e-5b64-92bd-ccd244e1972d",
                        "e35006ab-1d51-5bcf-8813-bbeb792d491f",
                        "cf26d3ef-30a0-549e-829f-7e75f8590565"
                    ]
                },
                "SP-20a": {
                    "questionId": "SP-20a",
                    "chosenOptionIds": [
                        "acd11895-4da3-5ed7-b7b6-d5242627e46e"
                    ]
                },
                "SP-3.5a": {
                    "questionId": "SP-3.5a",
                    "chosenOptionIds": [
                        "699b519f-6c57-577e-9324-55766f14447f"
                    ]
                },
                "SP-4a": {
                    "questionId": "SP-4a",
                    "chosenOptionIds": [
                        "e4eff7df-e208-511d-820e-8607d2c089d8"
                    ]
                },
                "SP-5a": {
                    "questionId": "SP-5a",
                    "chosenOptionIds": [
                        "a271682c-056b-59bf-ad44-391fcbc15670"
                    ]
                },
                "SP-6a": {
                    "questionId": "SP-6a",
                    "chosenOptionIds": [
                        "b8b2eb62-775e-5d8b-b261-e346863f3b9a"
                    ]
                },
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "c4f76c19-a59a-59dd-89af-dbc327647d2d"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "0c4dea35-391c-55c6-8db1-5f20a1f4d6a7"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "a6d974f8-4999-5ad7-ae39-524148b324c0"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "89646e2f-b5da-5058-bf39-0dca36bec2cb"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "081d965a-5599-5747-93df-ec639c98b41f"
                    ]
                },
                "SP-s6a": {
                    "questionId": "SP-s6a",
                    "freeValue": 8030
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: N0N2PEQ aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "e3f844ee-a641-5899-a9da-8d4dbd688782"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: J3JO40Y aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "a38151d3-973e-530c-b7c2-51b9e33001d5"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "c3f9b887-668c-5d88-bb1f-4492aa66b0e4"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "89f821ca-0625-5069-b53c-b427bb9e13d4"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "256f2151-3716-5adc-89c2-7cc79b551197"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: 2E1JL0D aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "a38151d3-973e-530c-b7c2-51b9e33001d5"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "c3f9b887-668c-5d88-bb1f-4492aa66b0e4"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "662a26db-5ace-5827-a9b4-5d9907eefa79"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "918e349b-2944-5ad5-8010-9bc2bf8bdc2a"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: 23X4V3N aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "729f7460-99f9-56df-94fe-854d75e389b1"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "3796a228-2196-5f8a-9bdd-ce0d6f1ed8c5"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "89f821ca-0625-5069-b53c-b427bb9e13d4"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "a0f2ad4f-2e36-5a45-9552-a8bf992a1ef1"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: Z35X10O aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "e3f844ee-a641-5899-a9da-8d4dbd688782"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: O0K9D01 aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "729f7460-99f9-56df-94fe-854d75e389b1"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "9e25633b-ffae-5cf6-aefc-bd5a3d5a0a12"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "06ead534-7682-558b-8af2-f1fe4ca79c1f"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "256f2151-3716-5adc-89c2-7cc79b551197"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: NEL9J3V aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "729f7460-99f9-56df-94fe-854d75e389b1"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "9e25633b-ffae-5cf6-aefc-bd5a3d5a0a12"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "89f821ca-0625-5069-b53c-b427bb9e13d4"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "a0f2ad4f-2e36-5a45-9552-a8bf992a1ef1"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: PE694EL aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "a38151d3-973e-530c-b7c2-51b9e33001d5"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "3796a228-2196-5f8a-9bdd-ce0d6f1ed8c5"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "89f821ca-0625-5069-b53c-b427bb9e13d4"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "918e349b-2944-5ad5-8010-9bc2bf8bdc2a"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: K399M3L aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "e3f844ee-a641-5899-a9da-8d4dbd688782"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: Y32QME8 aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "729f7460-99f9-56df-94fe-854d75e389b1"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "9e25633b-ffae-5cf6-aefc-bd5a3d5a0a12"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "06ead534-7682-558b-8af2-f1fe4ca79c1f"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "256f2151-3716-5adc-89c2-7cc79b551197"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: 23XV2EN aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "729f7460-99f9-56df-94fe-854d75e389b1"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "9e25633b-ffae-5cf6-aefc-bd5a3d5a0a12"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "662a26db-5ace-5827-a9b4-5d9907eefa79"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "256f2151-3716-5adc-89c2-7cc79b551197"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: Z3R443O aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "e3f844ee-a641-5899-a9da-8d4dbd688782"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: J3JV43Y aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "729f7460-99f9-56df-94fe-854d75e389b1"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "9e25633b-ffae-5cf6-aefc-bd5a3d5a0a12"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "06ead534-7682-558b-8af2-f1fe4ca79c1f"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "256f2151-3716-5adc-89c2-7cc79b551197"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: 63Y843R aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "e3f844ee-a641-5899-a9da-8d4dbd688782"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: XE7DM0L aspect: soa_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SP-12a": {
                    "questionId": "SP-12a",
                    "chosenOptionIds": [
                        "a0ef8867-a766-5812-a5d1-4249b1e9c99e"
                    ]
                },
                "SP-13a": {
                    "questionId": "SP-13a",
                    "chosenOptionIds": [
                        "2df17952-f192-59e5-966e-faa810365cdc"
                    ]
                },
                "SP-14a": {
                    "questionId": "SP-14a",
                    "chosenOptionIds": [
                        "d81a8e84-7a08-52dd-94d4-6c5f78f86d45"
                    ]
                },
                "SP-15a": {
                    "questionId": "SP-15a",
                    "chosenOptionIds": [
                        "af6f01bc-55f9-51d4-9531-ab0d07dab4a9"
                    ]
                },
                "SP-16a": {
                    "questionId": "SP-16a",
                    "chosenOptionIds": [
                        "5e476e67-3397-596c-b5aa-228f70f81ca7"
                    ]
                },
                "SP-17.0a": {
                    "questionId": "SP-17.0a",
                    "chosenOptionIds": [
                        "7ea4813f-57cd-5ba7-b21e-c076dda71014"
                    ]
                },
                "SP-17a": {
                    "questionId": "SP-17a",
                    "chosenOptionIds": [
                        "e0b9578f-e9a5-56b0-96e1-a89329ddcf3c",
                        "ff70355e-77cb-5edd-acc6-4f5a5bd87812",
                        "d4bb9556-9e80-50cc-aada-b4362d4d12d6",
                        "dc0787ea-aee0-537f-a6e8-06a5539be69c",
                        "7482d0b7-7353-5d8d-a520-9fbfa3343cbf",
                        "c75f20ef-3f69-5b6f-bbd1-3157cb401536",
                        "7149de4e-323b-530e-96c3-b9d13a5f2652",
                        "c9c685c3-e334-5376-a214-5a96d666a44c"
                    ]
                },
                "SP-1a": {
                    "questionId": "SP-1a",
                    "chosenOptionIds": [
                        "84da26c8-2e93-5432-8fe9-8e4c3bfa5ddf",
                        "e307b879-8b6d-5e0f-950d-bdc01347e2d3",
                        "ab832c79-0e01-5a40-b273-80805282a968",
                        "0f17cdba-f288-54e2-8a37-99ae7e664fa4",
                        "c74dcc79-fb7f-55da-930e-313ebe0cb641",
                        "b816dad5-6fb6-58cc-a84b-7187a04ab4c3",
                        "e4429794-5af9-5ed1-9ebb-ba8ca86a0ce1",
                        "b818ca80-ae9e-5b2c-8815-74a34c59c044",
                        "91b573f4-789e-5b64-92bd-ccd244e1972d",
                        "e35006ab-1d51-5bcf-8813-bbeb792d491f",
                        "cf26d3ef-30a0-549e-829f-7e75f8590565"
                    ]
                },
                "SP-20a": {
                    "questionId": "SP-20a",
                    "chosenOptionIds": [
                        "acd11895-4da3-5ed7-b7b6-d5242627e46e"
                    ]
                },
                "SP-3.5a": {
                    "questionId": "SP-3.5a",
                    "chosenOptionIds": [
                        "dc100be9-9b82-5329-aa16-e143ad018760"
                    ]
                },
                "SP-4a": {
                    "questionId": "SP-4a",
                    "chosenOptionIds": [
                        "ab977d02-7d5f-5e37-bcfc-3dba076f51df"
                    ]
                },
                "SP-5a": {
                    "questionId": "SP-5a",
                    "chosenOptionIds": [
                        "9e4e0ea3-cb07-5f37-b5cb-ff5f93854c60"
                    ]
                },
                "SP-6a": {
                    "questionId": "SP-6a",
                    "chosenOptionIds": [
                        "9cc1eaa9-853e-50a1-848a-bbeaf73425bf"
                    ]
                },
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "6b433886-476a-53c6-ada2-1e2260c12d17"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "9e25633b-ffae-5cf6-aefc-bd5a3d5a0a12"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "06ead534-7682-558b-8af2-f1fe4ca79c1f"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "89646e2f-b5da-5058-bf39-0dca36bec2cb"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "081d965a-5599-5747-93df-ec639c98b41f"
                    ]
                },
                "SP-s6a": {
                    "questionId": "SP-s6a",
                    "freeValue": 5475
                }
            }
        },
        {
            "name": "Censeo vPrevious user: XE7DM0L aspect: soa_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.32
            },
            "answers": {
                "SP-12a": {
                    "questionId": "SP-12a",
                    "chosenOptionIds": [
                        "a0ef8867-a766-5812-a5d1-4249b1e9c99e"
                    ]
                },
                "SP-13a": {
                    "questionId": "SP-13a",
                    "chosenOptionIds": [
                        "2df17952-f192-59e5-966e-faa810365cdc"
                    ]
                },
                "SP-14a": {
                    "questionId": "SP-14a",
                    "chosenOptionIds": [
                        "d81a8e84-7a08-52dd-94d4-6c5f78f86d45"
                    ]
                },
                "SP-15a": {
                    "questionId": "SP-15a",
                    "chosenOptionIds": [
                        "af6f01bc-55f9-51d4-9531-ab0d07dab4a9"
                    ]
                },
                "SP-16a": {
                    "questionId": "SP-16a",
                    "chosenOptionIds": [
                        "5e476e67-3397-596c-b5aa-228f70f81ca7"
                    ]
                },
                "SP-17.0a": {
                    "questionId": "SP-17.0a",
                    "chosenOptionIds": [
                        "7ea4813f-57cd-5ba7-b21e-c076dda71014"
                    ]
                },
                "SP-17a": {
                    "questionId": "SP-17a",
                    "chosenOptionIds": [
                        "e0b9578f-e9a5-56b0-96e1-a89329ddcf3c",
                        "ff70355e-77cb-5edd-acc6-4f5a5bd87812",
                        "d4bb9556-9e80-50cc-aada-b4362d4d12d6",
                        "dc0787ea-aee0-537f-a6e8-06a5539be69c",
                        "7482d0b7-7353-5d8d-a520-9fbfa3343cbf",
                        "c75f20ef-3f69-5b6f-bbd1-3157cb401536",
                        "7149de4e-323b-530e-96c3-b9d13a5f2652",
                        "c9c685c3-e334-5376-a214-5a96d666a44c"
                    ]
                },
                "SP-1a": {
                    "questionId": "SP-1a",
                    "chosenOptionIds": [
                        "84da26c8-2e93-5432-8fe9-8e4c3bfa5ddf",
                        "e307b879-8b6d-5e0f-950d-bdc01347e2d3",
                        "ab832c79-0e01-5a40-b273-80805282a968",
                        "0f17cdba-f288-54e2-8a37-99ae7e664fa4",
                        "c74dcc79-fb7f-55da-930e-313ebe0cb641",
                        "b816dad5-6fb6-58cc-a84b-7187a04ab4c3",
                        "e4429794-5af9-5ed1-9ebb-ba8ca86a0ce1",
                        "b818ca80-ae9e-5b2c-8815-74a34c59c044",
                        "91b573f4-789e-5b64-92bd-ccd244e1972d",
                        "e35006ab-1d51-5bcf-8813-bbeb792d491f",
                        "cf26d3ef-30a0-549e-829f-7e75f8590565"
                    ]
                },
                "SP-20a": {
                    "questionId": "SP-20a",
                    "chosenOptionIds": [
                        "acd11895-4da3-5ed7-b7b6-d5242627e46e"
                    ]
                },
                "SP-3.5a": {
                    "questionId": "SP-3.5a",
                    "chosenOptionIds": [
                        "dc100be9-9b82-5329-aa16-e143ad018760"
                    ]
                },
                "SP-4a": {
                    "questionId": "SP-4a",
                    "chosenOptionIds": [
                        "ab977d02-7d5f-5e37-bcfc-3dba076f51df"
                    ]
                },
                "SP-5a": {
                    "questionId": "SP-5a",
                    "chosenOptionIds": [
                        "9e4e0ea3-cb07-5f37-b5cb-ff5f93854c60"
                    ]
                },
                "SP-6a": {
                    "questionId": "SP-6a",
                    "chosenOptionIds": [
                        "9cc1eaa9-853e-50a1-848a-bbeaf73425bf"
                    ]
                },
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "6b433886-476a-53c6-ada2-1e2260c12d17"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "9e25633b-ffae-5cf6-aefc-bd5a3d5a0a12"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "06ead534-7682-558b-8af2-f1fe4ca79c1f"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "89646e2f-b5da-5058-bf39-0dca36bec2cb"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "081d965a-5599-5747-93df-ec639c98b41f"
                    ]
                },
                "SP-s6a": {
                    "questionId": "SP-s6a",
                    "freeValue": 5475
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: Y32LM08 aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "e3f844ee-a641-5899-a9da-8d4dbd688782"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: J3J4P0Y aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "729f7460-99f9-56df-94fe-854d75e389b1"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "9e25633b-ffae-5cf6-aefc-bd5a3d5a0a12"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "662a26db-5ace-5827-a9b4-5d9907eefa79"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "918e349b-2944-5ad5-8010-9bc2bf8bdc2a"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: 50MDW06 aspect: soa_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "SP-12a": {
                    "questionId": "SP-12a",
                    "chosenOptionIds": [
                        "a0ef8867-a766-5812-a5d1-4249b1e9c99e"
                    ]
                },
                "SP-13a": {
                    "questionId": "SP-13a",
                    "chosenOptionIds": [
                        "2df17952-f192-59e5-966e-faa810365cdc"
                    ]
                },
                "SP-14a": {
                    "questionId": "SP-14a",
                    "chosenOptionIds": [
                        "d81a8e84-7a08-52dd-94d4-6c5f78f86d45"
                    ]
                },
                "SP-15a": {
                    "questionId": "SP-15a",
                    "chosenOptionIds": [
                        "8c9d90a9-2d0c-553c-aaa2-6f53088f91e7"
                    ]
                },
                "SP-16a": {
                    "questionId": "SP-16a",
                    "chosenOptionIds": [
                        "5e476e67-3397-596c-b5aa-228f70f81ca7"
                    ]
                },
                "SP-17.0a": {
                    "questionId": "SP-17.0a",
                    "chosenOptionIds": [
                        "748c3e8a-548a-59c0-841b-8ef86556a2c7"
                    ]
                },
                "SP-17a": {
                    "questionId": "SP-17a",
                    "chosenOptionIds": [
                        "b14c4c87-62f1-5a86-b30c-d1a4e5a688bb",
                        "d4bb9556-9e80-50cc-aada-b4362d4d12d6",
                        "7482d0b7-7353-5d8d-a520-9fbfa3343cbf"
                    ]
                },
                "SP-1a": {
                    "questionId": "SP-1a",
                    "chosenOptionIds": [
                        "84da26c8-2e93-5432-8fe9-8e4c3bfa5ddf",
                        "e307b879-8b6d-5e0f-950d-bdc01347e2d3",
                        "3a2df70e-537a-5548-8f24-32a2a29da651",
                        "c74dcc79-fb7f-55da-930e-313ebe0cb641",
                        "cf26d3ef-30a0-549e-829f-7e75f8590565",
                        "e35006ab-1d51-5bcf-8813-bbeb792d491f",
                        "91b573f4-789e-5b64-92bd-ccd244e1972d",
                        "91b55a44-e875-576d-a183-43435c612a53"
                    ]
                },
                "SP-20a": {
                    "questionId": "SP-20a",
                    "chosenOptionIds": [
                        "acd11895-4da3-5ed7-b7b6-d5242627e46e"
                    ]
                },
                "SP-3.5a": {
                    "questionId": "SP-3.5a",
                    "chosenOptionIds": [
                        "dc100be9-9b82-5329-aa16-e143ad018760"
                    ]
                },
                "SP-4a": {
                    "questionId": "SP-4a",
                    "chosenOptionIds": [
                        "10ff8965-17e1-59ec-a91b-3e903baa13a0"
                    ]
                },
                "SP-5a": {
                    "questionId": "SP-5a",
                    "chosenOptionIds": [
                        "e25496b1-0ed9-53e9-8ea8-993b3c3123f1"
                    ]
                },
                "SP-6a": {
                    "questionId": "SP-6a",
                    "chosenOptionIds": [
                        "9ad81c0e-2776-568a-a0a5-c7eb52a58c78"
                    ]
                },
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "6b433886-476a-53c6-ada2-1e2260c12d17"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "0c4dea35-391c-55c6-8db1-5f20a1f4d6a7"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "a6d974f8-4999-5ad7-ae39-524148b324c0"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "89646e2f-b5da-5058-bf39-0dca36bec2cb"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "081d965a-5599-5747-93df-ec639c98b41f"
                    ]
                },
                "SP-s6a": {
                    "questionId": "SP-s6a",
                    "freeValue": 2555
                }
            }
        },
        {
            "name": "Censeo vPrevious user: 50MDW06 aspect: soa_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.93
            },
            "answers": {
                "SP-12a": {
                    "questionId": "SP-12a",
                    "chosenOptionIds": [
                        "a0ef8867-a766-5812-a5d1-4249b1e9c99e"
                    ]
                },
                "SP-13a": {
                    "questionId": "SP-13a",
                    "chosenOptionIds": [
                        "2df17952-f192-59e5-966e-faa810365cdc"
                    ]
                },
                "SP-14a": {
                    "questionId": "SP-14a",
                    "chosenOptionIds": [
                        "d81a8e84-7a08-52dd-94d4-6c5f78f86d45"
                    ]
                },
                "SP-15a": {
                    "questionId": "SP-15a",
                    "chosenOptionIds": [
                        "8c9d90a9-2d0c-553c-aaa2-6f53088f91e7"
                    ]
                },
                "SP-16a": {
                    "questionId": "SP-16a",
                    "chosenOptionIds": [
                        "5e476e67-3397-596c-b5aa-228f70f81ca7"
                    ]
                },
                "SP-17.0a": {
                    "questionId": "SP-17.0a",
                    "chosenOptionIds": [
                        "748c3e8a-548a-59c0-841b-8ef86556a2c7"
                    ]
                },
                "SP-17a": {
                    "questionId": "SP-17a",
                    "chosenOptionIds": [
                        "b14c4c87-62f1-5a86-b30c-d1a4e5a688bb",
                        "d4bb9556-9e80-50cc-aada-b4362d4d12d6",
                        "7482d0b7-7353-5d8d-a520-9fbfa3343cbf"
                    ]
                },
                "SP-1a": {
                    "questionId": "SP-1a",
                    "chosenOptionIds": [
                        "84da26c8-2e93-5432-8fe9-8e4c3bfa5ddf",
                        "e307b879-8b6d-5e0f-950d-bdc01347e2d3",
                        "3a2df70e-537a-5548-8f24-32a2a29da651",
                        "c74dcc79-fb7f-55da-930e-313ebe0cb641",
                        "cf26d3ef-30a0-549e-829f-7e75f8590565",
                        "e35006ab-1d51-5bcf-8813-bbeb792d491f",
                        "91b573f4-789e-5b64-92bd-ccd244e1972d",
                        "91b55a44-e875-576d-a183-43435c612a53"
                    ]
                },
                "SP-20a": {
                    "questionId": "SP-20a",
                    "chosenOptionIds": [
                        "acd11895-4da3-5ed7-b7b6-d5242627e46e"
                    ]
                },
                "SP-3.5a": {
                    "questionId": "SP-3.5a",
                    "chosenOptionIds": [
                        "dc100be9-9b82-5329-aa16-e143ad018760"
                    ]
                },
                "SP-4a": {
                    "questionId": "SP-4a",
                    "chosenOptionIds": [
                        "10ff8965-17e1-59ec-a91b-3e903baa13a0"
                    ]
                },
                "SP-5a": {
                    "questionId": "SP-5a",
                    "chosenOptionIds": [
                        "e25496b1-0ed9-53e9-8ea8-993b3c3123f1"
                    ]
                },
                "SP-6a": {
                    "questionId": "SP-6a",
                    "chosenOptionIds": [
                        "9ad81c0e-2776-568a-a0a5-c7eb52a58c78"
                    ]
                },
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "6b433886-476a-53c6-ada2-1e2260c12d17"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "0c4dea35-391c-55c6-8db1-5f20a1f4d6a7"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "a6d974f8-4999-5ad7-ae39-524148b324c0"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "89646e2f-b5da-5058-bf39-0dca36bec2cb"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "081d965a-5599-5747-93df-ec639c98b41f"
                    ]
                },
                "SP-s6a": {
                    "questionId": "SP-s6a",
                    "freeValue": 2555
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: J0VQM3L aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "e3f844ee-a641-5899-a9da-8d4dbd688782"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: 4EGRG3P aspect: soa_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "SP-12a": {
                    "questionId": "SP-12a",
                    "chosenOptionIds": [
                        "396f7bdb-367e-544e-8dd1-49a0d8c58589"
                    ]
                },
                "SP-13a": {
                    "questionId": "SP-13a",
                    "chosenOptionIds": [
                        "2df17952-f192-59e5-966e-faa810365cdc"
                    ]
                },
                "SP-14a": {
                    "questionId": "SP-14a",
                    "chosenOptionIds": [
                        "d81a8e84-7a08-52dd-94d4-6c5f78f86d45"
                    ]
                },
                "SP-15a": {
                    "questionId": "SP-15a",
                    "chosenOptionIds": [
                        "af6f01bc-55f9-51d4-9531-ab0d07dab4a9"
                    ]
                },
                "SP-16a": {
                    "questionId": "SP-16a",
                    "chosenOptionIds": [
                        "0b101597-878f-57d2-b689-f9b3f431a1d9"
                    ]
                },
                "SP-17.0a": {
                    "questionId": "SP-17.0a",
                    "chosenOptionIds": [
                        "9cfbde86-55ad-56ae-b2c4-75aea806f939"
                    ]
                },
                "SP-17a": {
                    "questionId": "SP-17a",
                    "chosenOptionIds": [
                        "7149de4e-323b-530e-96c3-b9d13a5f2652",
                        "c75f20ef-3f69-5b6f-bbd1-3157cb401536",
                        "7482d0b7-7353-5d8d-a520-9fbfa3343cbf",
                        "dc0787ea-aee0-537f-a6e8-06a5539be69c",
                        "d4bb9556-9e80-50cc-aada-b4362d4d12d6",
                        "b14c4c87-62f1-5a86-b30c-d1a4e5a688bb",
                        "ff70355e-77cb-5edd-acc6-4f5a5bd87812",
                        "e0b9578f-e9a5-56b0-96e1-a89329ddcf3c"
                    ]
                },
                "SP-1a": {
                    "questionId": "SP-1a",
                    "chosenOptionIds": [
                        "84da26c8-2e93-5432-8fe9-8e4c3bfa5ddf",
                        "e307b879-8b6d-5e0f-950d-bdc01347e2d3",
                        "3a2df70e-537a-5548-8f24-32a2a29da651",
                        "ab832c79-0e01-5a40-b273-80805282a968",
                        "0f17cdba-f288-54e2-8a37-99ae7e664fa4",
                        "c74dcc79-fb7f-55da-930e-313ebe0cb641",
                        "c30a8e03-2164-5350-9e0b-486e36bd2ebb",
                        "b816dad5-6fb6-58cc-a84b-7187a04ab4c3",
                        "e4429794-5af9-5ed1-9ebb-ba8ca86a0ce1",
                        "91b55a44-e875-576d-a183-43435c612a53",
                        "b818ca80-ae9e-5b2c-8815-74a34c59c044",
                        "91b573f4-789e-5b64-92bd-ccd244e1972d",
                        "e35006ab-1d51-5bcf-8813-bbeb792d491f",
                        "cf26d3ef-30a0-549e-829f-7e75f8590565"
                    ]
                },
                "SP-20a": {
                    "questionId": "SP-20a",
                    "chosenOptionIds": [
                        "acd11895-4da3-5ed7-b7b6-d5242627e46e"
                    ]
                },
                "SP-3.5a": {
                    "questionId": "SP-3.5a",
                    "chosenOptionIds": [
                        "dc100be9-9b82-5329-aa16-e143ad018760"
                    ]
                },
                "SP-4a": {
                    "questionId": "SP-4a",
                    "chosenOptionIds": [
                        "e4eff7df-e208-511d-820e-8607d2c089d8"
                    ]
                },
                "SP-5a": {
                    "questionId": "SP-5a",
                    "chosenOptionIds": [
                        "e25496b1-0ed9-53e9-8ea8-993b3c3123f1"
                    ]
                },
                "SP-6a": {
                    "questionId": "SP-6a",
                    "chosenOptionIds": [
                        "4527f581-eb71-5e29-a9ce-4143e2938ecb"
                    ]
                },
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "c4f76c19-a59a-59dd-89af-dbc327647d2d"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "0c4dea35-391c-55c6-8db1-5f20a1f4d6a7"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "a6d974f8-4999-5ad7-ae39-524148b324c0"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "89646e2f-b5da-5058-bf39-0dca36bec2cb"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "081d965a-5599-5747-93df-ec639c98b41f"
                    ]
                },
                "SP-s6a": {
                    "questionId": "SP-s6a",
                    "freeValue": 3650
                }
            }
        },
        {
            "name": "Censeo vPrevious user: 4EGRG3P aspect: soa_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.82
            },
            "answers": {
                "SP-12a": {
                    "questionId": "SP-12a",
                    "chosenOptionIds": [
                        "396f7bdb-367e-544e-8dd1-49a0d8c58589"
                    ]
                },
                "SP-13a": {
                    "questionId": "SP-13a",
                    "chosenOptionIds": [
                        "2df17952-f192-59e5-966e-faa810365cdc"
                    ]
                },
                "SP-14a": {
                    "questionId": "SP-14a",
                    "chosenOptionIds": [
                        "d81a8e84-7a08-52dd-94d4-6c5f78f86d45"
                    ]
                },
                "SP-15a": {
                    "questionId": "SP-15a",
                    "chosenOptionIds": [
                        "af6f01bc-55f9-51d4-9531-ab0d07dab4a9"
                    ]
                },
                "SP-16a": {
                    "questionId": "SP-16a",
                    "chosenOptionIds": [
                        "0b101597-878f-57d2-b689-f9b3f431a1d9"
                    ]
                },
                "SP-17.0a": {
                    "questionId": "SP-17.0a",
                    "chosenOptionIds": [
                        "9cfbde86-55ad-56ae-b2c4-75aea806f939"
                    ]
                },
                "SP-17a": {
                    "questionId": "SP-17a",
                    "chosenOptionIds": [
                        "7149de4e-323b-530e-96c3-b9d13a5f2652",
                        "c75f20ef-3f69-5b6f-bbd1-3157cb401536",
                        "7482d0b7-7353-5d8d-a520-9fbfa3343cbf",
                        "dc0787ea-aee0-537f-a6e8-06a5539be69c",
                        "d4bb9556-9e80-50cc-aada-b4362d4d12d6",
                        "b14c4c87-62f1-5a86-b30c-d1a4e5a688bb",
                        "ff70355e-77cb-5edd-acc6-4f5a5bd87812",
                        "e0b9578f-e9a5-56b0-96e1-a89329ddcf3c"
                    ]
                },
                "SP-1a": {
                    "questionId": "SP-1a",
                    "chosenOptionIds": [
                        "84da26c8-2e93-5432-8fe9-8e4c3bfa5ddf",
                        "e307b879-8b6d-5e0f-950d-bdc01347e2d3",
                        "3a2df70e-537a-5548-8f24-32a2a29da651",
                        "ab832c79-0e01-5a40-b273-80805282a968",
                        "0f17cdba-f288-54e2-8a37-99ae7e664fa4",
                        "c74dcc79-fb7f-55da-930e-313ebe0cb641",
                        "c30a8e03-2164-5350-9e0b-486e36bd2ebb",
                        "b816dad5-6fb6-58cc-a84b-7187a04ab4c3",
                        "e4429794-5af9-5ed1-9ebb-ba8ca86a0ce1",
                        "91b55a44-e875-576d-a183-43435c612a53",
                        "b818ca80-ae9e-5b2c-8815-74a34c59c044",
                        "91b573f4-789e-5b64-92bd-ccd244e1972d",
                        "e35006ab-1d51-5bcf-8813-bbeb792d491f",
                        "cf26d3ef-30a0-549e-829f-7e75f8590565"
                    ]
                },
                "SP-20a": {
                    "questionId": "SP-20a",
                    "chosenOptionIds": [
                        "acd11895-4da3-5ed7-b7b6-d5242627e46e"
                    ]
                },
                "SP-3.5a": {
                    "questionId": "SP-3.5a",
                    "chosenOptionIds": [
                        "dc100be9-9b82-5329-aa16-e143ad018760"
                    ]
                },
                "SP-4a": {
                    "questionId": "SP-4a",
                    "chosenOptionIds": [
                        "e4eff7df-e208-511d-820e-8607d2c089d8"
                    ]
                },
                "SP-5a": {
                    "questionId": "SP-5a",
                    "chosenOptionIds": [
                        "e25496b1-0ed9-53e9-8ea8-993b3c3123f1"
                    ]
                },
                "SP-6a": {
                    "questionId": "SP-6a",
                    "chosenOptionIds": [
                        "4527f581-eb71-5e29-a9ce-4143e2938ecb"
                    ]
                },
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "c4f76c19-a59a-59dd-89af-dbc327647d2d"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "0c4dea35-391c-55c6-8db1-5f20a1f4d6a7"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "a6d974f8-4999-5ad7-ae39-524148b324c0"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "89646e2f-b5da-5058-bf39-0dca36bec2cb"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "081d965a-5599-5747-93df-ec639c98b41f"
                    ]
                },
                "SP-s6a": {
                    "questionId": "SP-s6a",
                    "freeValue": 3650
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: Z35L10O aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-b": {
                    "questionId": "SP-b",
                    "freeValue": 210
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "6b433886-476a-53c6-ada2-1e2260c12d17"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "0c4dea35-391c-55c6-8db1-5f20a1f4d6a7"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "a6d974f8-4999-5ad7-ae39-524148b324c0"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "89646e2f-b5da-5058-bf39-0dca36bec2cb"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "35110660-4738-5bcd-96a1-3ad5a821141f"
                    ]
                },
                "SP-s6b": {
                    "questionId": "SP-s6b",
                    "freeValue": 210
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: XE72M0L aspect: soa_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "SP-12a": {
                    "questionId": "SP-12a",
                    "chosenOptionIds": [
                        "a0ef8867-a766-5812-a5d1-4249b1e9c99e"
                    ]
                },
                "SP-13a": {
                    "questionId": "SP-13a",
                    "chosenOptionIds": [
                        "d43b4e51-8c41-5b94-85a5-1311e7e9c1ac"
                    ]
                },
                "SP-14a": {
                    "questionId": "SP-14a",
                    "chosenOptionIds": [
                        "e0ce7ba0-7bc1-525e-84ac-3eeb498d43ea"
                    ]
                },
                "SP-15a": {
                    "questionId": "SP-15a",
                    "chosenOptionIds": [
                        "1a4b5856-56e4-5e12-98ef-4b19bb86c61c"
                    ]
                },
                "SP-16a": {
                    "questionId": "SP-16a",
                    "chosenOptionIds": [
                        "0b101597-878f-57d2-b689-f9b3f431a1d9"
                    ]
                },
                "SP-17.0a": {
                    "questionId": "SP-17.0a",
                    "chosenOptionIds": [
                        "9cfbde86-55ad-56ae-b2c4-75aea806f939"
                    ]
                },
                "SP-17a": {
                    "questionId": "SP-17a",
                    "chosenOptionIds": [
                        "ff70355e-77cb-5edd-acc6-4f5a5bd87812",
                        "b14c4c87-62f1-5a86-b30c-d1a4e5a688bb",
                        "d4bb9556-9e80-50cc-aada-b4362d4d12d6",
                        "7482d0b7-7353-5d8d-a520-9fbfa3343cbf",
                        "dc0787ea-aee0-537f-a6e8-06a5539be69c",
                        "c75f20ef-3f69-5b6f-bbd1-3157cb401536",
                        "7149de4e-323b-530e-96c3-b9d13a5f2652",
                        "c9c685c3-e334-5376-a214-5a96d666a44c"
                    ]
                },
                "SP-1a": {
                    "questionId": "SP-1a",
                    "chosenOptionIds": [
                        "84da26c8-2e93-5432-8fe9-8e4c3bfa5ddf",
                        "e307b879-8b6d-5e0f-950d-bdc01347e2d3",
                        "ab832c79-0e01-5a40-b273-80805282a968",
                        "0f17cdba-f288-54e2-8a37-99ae7e664fa4",
                        "c74dcc79-fb7f-55da-930e-313ebe0cb641",
                        "e4429794-5af9-5ed1-9ebb-ba8ca86a0ce1",
                        "91b55a44-e875-576d-a183-43435c612a53",
                        "91b573f4-789e-5b64-92bd-ccd244e1972d",
                        "e35006ab-1d51-5bcf-8813-bbeb792d491f",
                        "cf26d3ef-30a0-549e-829f-7e75f8590565"
                    ]
                },
                "SP-20a": {
                    "questionId": "SP-20a",
                    "chosenOptionIds": [
                        "acd11895-4da3-5ed7-b7b6-d5242627e46e"
                    ]
                },
                "SP-3.5a": {
                    "questionId": "SP-3.5a",
                    "chosenOptionIds": [
                        "dc100be9-9b82-5329-aa16-e143ad018760"
                    ]
                },
                "SP-4a": {
                    "questionId": "SP-4a",
                    "chosenOptionIds": [
                        "e4eff7df-e208-511d-820e-8607d2c089d8"
                    ]
                },
                "SP-5a": {
                    "questionId": "SP-5a",
                    "chosenOptionIds": [
                        "abd020f5-cb4f-54b1-9ed2-893ac7ff4958"
                    ]
                },
                "SP-6a": {
                    "questionId": "SP-6a",
                    "chosenOptionIds": [
                        "4527f581-eb71-5e29-a9ce-4143e2938ecb"
                    ]
                },
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "c4f76c19-a59a-59dd-89af-dbc327647d2d"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "0c4dea35-391c-55c6-8db1-5f20a1f4d6a7"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "a6d974f8-4999-5ad7-ae39-524148b324c0"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "89646e2f-b5da-5058-bf39-0dca36bec2cb"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "081d965a-5599-5747-93df-ec639c98b41f"
                    ]
                },
                "SP-s6a": {
                    "questionId": "SP-s6a",
                    "freeValue": 8030
                }
            }
        },
        {
            "name": "Censeo vPrevious user: XE72M0L aspect: soa_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.83
            },
            "answers": {
                "SP-12a": {
                    "questionId": "SP-12a",
                    "chosenOptionIds": [
                        "a0ef8867-a766-5812-a5d1-4249b1e9c99e"
                    ]
                },
                "SP-13a": {
                    "questionId": "SP-13a",
                    "chosenOptionIds": [
                        "d43b4e51-8c41-5b94-85a5-1311e7e9c1ac"
                    ]
                },
                "SP-14a": {
                    "questionId": "SP-14a",
                    "chosenOptionIds": [
                        "e0ce7ba0-7bc1-525e-84ac-3eeb498d43ea"
                    ]
                },
                "SP-15a": {
                    "questionId": "SP-15a",
                    "chosenOptionIds": [
                        "1a4b5856-56e4-5e12-98ef-4b19bb86c61c"
                    ]
                },
                "SP-16a": {
                    "questionId": "SP-16a",
                    "chosenOptionIds": [
                        "0b101597-878f-57d2-b689-f9b3f431a1d9"
                    ]
                },
                "SP-17.0a": {
                    "questionId": "SP-17.0a",
                    "chosenOptionIds": [
                        "9cfbde86-55ad-56ae-b2c4-75aea806f939"
                    ]
                },
                "SP-17a": {
                    "questionId": "SP-17a",
                    "chosenOptionIds": [
                        "ff70355e-77cb-5edd-acc6-4f5a5bd87812",
                        "b14c4c87-62f1-5a86-b30c-d1a4e5a688bb",
                        "d4bb9556-9e80-50cc-aada-b4362d4d12d6",
                        "7482d0b7-7353-5d8d-a520-9fbfa3343cbf",
                        "dc0787ea-aee0-537f-a6e8-06a5539be69c",
                        "c75f20ef-3f69-5b6f-bbd1-3157cb401536",
                        "7149de4e-323b-530e-96c3-b9d13a5f2652",
                        "c9c685c3-e334-5376-a214-5a96d666a44c"
                    ]
                },
                "SP-1a": {
                    "questionId": "SP-1a",
                    "chosenOptionIds": [
                        "84da26c8-2e93-5432-8fe9-8e4c3bfa5ddf",
                        "e307b879-8b6d-5e0f-950d-bdc01347e2d3",
                        "ab832c79-0e01-5a40-b273-80805282a968",
                        "0f17cdba-f288-54e2-8a37-99ae7e664fa4",
                        "c74dcc79-fb7f-55da-930e-313ebe0cb641",
                        "e4429794-5af9-5ed1-9ebb-ba8ca86a0ce1",
                        "91b55a44-e875-576d-a183-43435c612a53",
                        "91b573f4-789e-5b64-92bd-ccd244e1972d",
                        "e35006ab-1d51-5bcf-8813-bbeb792d491f",
                        "cf26d3ef-30a0-549e-829f-7e75f8590565"
                    ]
                },
                "SP-20a": {
                    "questionId": "SP-20a",
                    "chosenOptionIds": [
                        "acd11895-4da3-5ed7-b7b6-d5242627e46e"
                    ]
                },
                "SP-3.5a": {
                    "questionId": "SP-3.5a",
                    "chosenOptionIds": [
                        "dc100be9-9b82-5329-aa16-e143ad018760"
                    ]
                },
                "SP-4a": {
                    "questionId": "SP-4a",
                    "chosenOptionIds": [
                        "e4eff7df-e208-511d-820e-8607d2c089d8"
                    ]
                },
                "SP-5a": {
                    "questionId": "SP-5a",
                    "chosenOptionIds": [
                        "abd020f5-cb4f-54b1-9ed2-893ac7ff4958"
                    ]
                },
                "SP-6a": {
                    "questionId": "SP-6a",
                    "chosenOptionIds": [
                        "4527f581-eb71-5e29-a9ce-4143e2938ecb"
                    ]
                },
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "c4f76c19-a59a-59dd-89af-dbc327647d2d"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "0c4dea35-391c-55c6-8db1-5f20a1f4d6a7"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "a6d974f8-4999-5ad7-ae39-524148b324c0"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "89646e2f-b5da-5058-bf39-0dca36bec2cb"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "081d965a-5599-5747-93df-ec639c98b41f"
                    ]
                },
                "SP-s6a": {
                    "questionId": "SP-s6a",
                    "freeValue": 8030
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: ME8MOEW aspect: soa_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SP-12a": {
                    "questionId": "SP-12a",
                    "chosenOptionIds": [
                        "a0ef8867-a766-5812-a5d1-4249b1e9c99e"
                    ]
                },
                "SP-13a": {
                    "questionId": "SP-13a",
                    "chosenOptionIds": [
                        "26aa2d4e-ad20-5a75-9e17-b762d7c4b14a"
                    ]
                },
                "SP-14a": {
                    "questionId": "SP-14a",
                    "chosenOptionIds": [
                        "e0ce7ba0-7bc1-525e-84ac-3eeb498d43ea"
                    ]
                },
                "SP-15a": {
                    "questionId": "SP-15a",
                    "chosenOptionIds": [
                        "45cab604-070b-5a9f-9c14-39f2d04b4883"
                    ]
                },
                "SP-16a": {
                    "questionId": "SP-16a",
                    "chosenOptionIds": [
                        "a849e9fd-b390-5784-b120-8488a113fb34"
                    ]
                },
                "SP-17.0a": {
                    "questionId": "SP-17.0a",
                    "chosenOptionIds": [
                        "7ea4813f-57cd-5ba7-b21e-c076dda71014"
                    ]
                },
                "SP-17a": {
                    "questionId": "SP-17a",
                    "chosenOptionIds": [
                        "ff70355e-77cb-5edd-acc6-4f5a5bd87812",
                        "e0b9578f-e9a5-56b0-96e1-a89329ddcf3c",
                        "b14c4c87-62f1-5a86-b30c-d1a4e5a688bb",
                        "dc0787ea-aee0-537f-a6e8-06a5539be69c",
                        "d4bb9556-9e80-50cc-aada-b4362d4d12d6",
                        "7482d0b7-7353-5d8d-a520-9fbfa3343cbf",
                        "7149de4e-323b-530e-96c3-b9d13a5f2652"
                    ]
                },
                "SP-1a": {
                    "questionId": "SP-1a",
                    "chosenOptionIds": [
                        "b818ca80-ae9e-5b2c-8815-74a34c59c044",
                        "91b573f4-789e-5b64-92bd-ccd244e1972d",
                        "e35006ab-1d51-5bcf-8813-bbeb792d491f",
                        "cf26d3ef-30a0-549e-829f-7e75f8590565"
                    ]
                },
                "SP-20a": {
                    "questionId": "SP-20a",
                    "chosenOptionIds": [
                        "59d8ffbf-ca3c-5a9f-b51c-ecfbe73c6890"
                    ]
                },
                "SP-3.5a": {
                    "questionId": "SP-3.5a",
                    "chosenOptionIds": [
                        "699b519f-6c57-577e-9324-55766f14447f"
                    ]
                },
                "SP-4a": {
                    "questionId": "SP-4a",
                    "chosenOptionIds": [
                        "d37e215b-238d-53e0-a897-c4793cb3cfc5"
                    ]
                },
                "SP-5a": {
                    "questionId": "SP-5a",
                    "chosenOptionIds": [
                        "a271682c-056b-59bf-ad44-391fcbc15670"
                    ]
                },
                "SP-6a": {
                    "questionId": "SP-6a",
                    "chosenOptionIds": [
                        "b8b2eb62-775e-5d8b-b261-e346863f3b9a"
                    ]
                },
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "c4f76c19-a59a-59dd-89af-dbc327647d2d"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "43effc14-ac9c-56fb-bba9-aca3514c6c77"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "06ead534-7682-558b-8af2-f1fe4ca79c1f"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "89646e2f-b5da-5058-bf39-0dca36bec2cb"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "081d965a-5599-5747-93df-ec639c98b41f"
                    ]
                },
                "SP-s6a": {
                    "questionId": "SP-s6a",
                    "freeValue": 730
                }
            }
        },
        {
            "name": "Censeo vPrevious user: ME8MOEW aspect: soa_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.29
            },
            "answers": {
                "SP-12a": {
                    "questionId": "SP-12a",
                    "chosenOptionIds": [
                        "a0ef8867-a766-5812-a5d1-4249b1e9c99e"
                    ]
                },
                "SP-13a": {
                    "questionId": "SP-13a",
                    "chosenOptionIds": [
                        "26aa2d4e-ad20-5a75-9e17-b762d7c4b14a"
                    ]
                },
                "SP-14a": {
                    "questionId": "SP-14a",
                    "chosenOptionIds": [
                        "e0ce7ba0-7bc1-525e-84ac-3eeb498d43ea"
                    ]
                },
                "SP-15a": {
                    "questionId": "SP-15a",
                    "chosenOptionIds": [
                        "45cab604-070b-5a9f-9c14-39f2d04b4883"
                    ]
                },
                "SP-16a": {
                    "questionId": "SP-16a",
                    "chosenOptionIds": [
                        "a849e9fd-b390-5784-b120-8488a113fb34"
                    ]
                },
                "SP-17.0a": {
                    "questionId": "SP-17.0a",
                    "chosenOptionIds": [
                        "7ea4813f-57cd-5ba7-b21e-c076dda71014"
                    ]
                },
                "SP-17a": {
                    "questionId": "SP-17a",
                    "chosenOptionIds": [
                        "ff70355e-77cb-5edd-acc6-4f5a5bd87812",
                        "e0b9578f-e9a5-56b0-96e1-a89329ddcf3c",
                        "b14c4c87-62f1-5a86-b30c-d1a4e5a688bb",
                        "dc0787ea-aee0-537f-a6e8-06a5539be69c",
                        "d4bb9556-9e80-50cc-aada-b4362d4d12d6",
                        "7482d0b7-7353-5d8d-a520-9fbfa3343cbf",
                        "7149de4e-323b-530e-96c3-b9d13a5f2652"
                    ]
                },
                "SP-1a": {
                    "questionId": "SP-1a",
                    "chosenOptionIds": [
                        "b818ca80-ae9e-5b2c-8815-74a34c59c044",
                        "91b573f4-789e-5b64-92bd-ccd244e1972d",
                        "e35006ab-1d51-5bcf-8813-bbeb792d491f",
                        "cf26d3ef-30a0-549e-829f-7e75f8590565"
                    ]
                },
                "SP-20a": {
                    "questionId": "SP-20a",
                    "chosenOptionIds": [
                        "59d8ffbf-ca3c-5a9f-b51c-ecfbe73c6890"
                    ]
                },
                "SP-3.5a": {
                    "questionId": "SP-3.5a",
                    "chosenOptionIds": [
                        "699b519f-6c57-577e-9324-55766f14447f"
                    ]
                },
                "SP-4a": {
                    "questionId": "SP-4a",
                    "chosenOptionIds": [
                        "d37e215b-238d-53e0-a897-c4793cb3cfc5"
                    ]
                },
                "SP-5a": {
                    "questionId": "SP-5a",
                    "chosenOptionIds": [
                        "a271682c-056b-59bf-ad44-391fcbc15670"
                    ]
                },
                "SP-6a": {
                    "questionId": "SP-6a",
                    "chosenOptionIds": [
                        "b8b2eb62-775e-5d8b-b261-e346863f3b9a"
                    ]
                },
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "c4f76c19-a59a-59dd-89af-dbc327647d2d"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "43effc14-ac9c-56fb-bba9-aca3514c6c77"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "06ead534-7682-558b-8af2-f1fe4ca79c1f"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "89646e2f-b5da-5058-bf39-0dca36bec2cb"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "081d965a-5599-5747-93df-ec639c98b41f"
                    ]
                },
                "SP-s6a": {
                    "questionId": "SP-s6a",
                    "freeValue": 730
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: Z35MQ3O aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-b": {
                    "questionId": "SP-b",
                    "freeValue": 14
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "c4f76c19-a59a-59dd-89af-dbc327647d2d"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "c3f9b887-668c-5d88-bb1f-4492aa66b0e4"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "89f821ca-0625-5069-b53c-b427bb9e13d4"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "256f2151-3716-5adc-89c2-7cc79b551197"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "35110660-4738-5bcd-96a1-3ad5a821141f"
                    ]
                },
                "SP-s6b": {
                    "questionId": "SP-s6b",
                    "freeValue": 120
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: 4EG443P aspect: soa_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SP-12a": {
                    "questionId": "SP-12a",
                    "chosenOptionIds": [
                        "396f7bdb-367e-544e-8dd1-49a0d8c58589"
                    ]
                },
                "SP-13a": {
                    "questionId": "SP-13a",
                    "chosenOptionIds": [
                        "d43b4e51-8c41-5b94-85a5-1311e7e9c1ac"
                    ]
                },
                "SP-14a": {
                    "questionId": "SP-14a",
                    "chosenOptionIds": [
                        "d81a8e84-7a08-52dd-94d4-6c5f78f86d45"
                    ]
                },
                "SP-15a": {
                    "questionId": "SP-15a",
                    "chosenOptionIds": [
                        "45cab604-070b-5a9f-9c14-39f2d04b4883"
                    ]
                },
                "SP-16a": {
                    "questionId": "SP-16a",
                    "chosenOptionIds": [
                        "cbacc3c9-c893-50d4-ab69-3826f7a9c80e"
                    ]
                },
                "SP-17.0a": {
                    "questionId": "SP-17.0a",
                    "chosenOptionIds": [
                        "748c3e8a-548a-59c0-841b-8ef86556a2c7"
                    ]
                },
                "SP-17a": {
                    "questionId": "SP-17a",
                    "chosenOptionIds": [
                        "e0b9578f-e9a5-56b0-96e1-a89329ddcf3c",
                        "ff70355e-77cb-5edd-acc6-4f5a5bd87812",
                        "d4bb9556-9e80-50cc-aada-b4362d4d12d6",
                        "dc0787ea-aee0-537f-a6e8-06a5539be69c",
                        "7482d0b7-7353-5d8d-a520-9fbfa3343cbf",
                        "7149de4e-323b-530e-96c3-b9d13a5f2652",
                        "c9c685c3-e334-5376-a214-5a96d666a44c"
                    ]
                },
                "SP-1a": {
                    "questionId": "SP-1a",
                    "chosenOptionIds": [
                        "e307b879-8b6d-5e0f-950d-bdc01347e2d3",
                        "ab832c79-0e01-5a40-b273-80805282a968",
                        "c74dcc79-fb7f-55da-930e-313ebe0cb641",
                        "c30a8e03-2164-5350-9e0b-486e36bd2ebb",
                        "b816dad5-6fb6-58cc-a84b-7187a04ab4c3",
                        "91b55a44-e875-576d-a183-43435c612a53",
                        "91b573f4-789e-5b64-92bd-ccd244e1972d",
                        "e35006ab-1d51-5bcf-8813-bbeb792d491f"
                    ]
                },
                "SP-20a": {
                    "questionId": "SP-20a",
                    "chosenOptionIds": [
                        "acd11895-4da3-5ed7-b7b6-d5242627e46e"
                    ]
                },
                "SP-3.5a": {
                    "questionId": "SP-3.5a",
                    "chosenOptionIds": [
                        "699b519f-6c57-577e-9324-55766f14447f"
                    ]
                },
                "SP-4a": {
                    "questionId": "SP-4a",
                    "chosenOptionIds": [
                        "ab977d02-7d5f-5e37-bcfc-3dba076f51df"
                    ]
                },
                "SP-5a": {
                    "questionId": "SP-5a",
                    "chosenOptionIds": [
                        "9e4e0ea3-cb07-5f37-b5cb-ff5f93854c60"
                    ]
                },
                "SP-6a": {
                    "questionId": "SP-6a",
                    "chosenOptionIds": [
                        "1ceeedc6-bfdb-5552-b1c2-a4e4a9f3abe0"
                    ]
                },
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "729f7460-99f9-56df-94fe-854d75e389b1"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "0c4dea35-391c-55c6-8db1-5f20a1f4d6a7"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "662a26db-5ace-5827-a9b4-5d9907eefa79"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "918e349b-2944-5ad5-8010-9bc2bf8bdc2a"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "081d965a-5599-5747-93df-ec639c98b41f"
                    ]
                },
                "SP-s6a": {
                    "questionId": "SP-s6a",
                    "freeValue": 6570
                }
            }
        },
        {
            "name": "Censeo vPrevious user: 4EG443P aspect: soa_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.37
            },
            "answers": {
                "SP-12a": {
                    "questionId": "SP-12a",
                    "chosenOptionIds": [
                        "396f7bdb-367e-544e-8dd1-49a0d8c58589"
                    ]
                },
                "SP-13a": {
                    "questionId": "SP-13a",
                    "chosenOptionIds": [
                        "d43b4e51-8c41-5b94-85a5-1311e7e9c1ac"
                    ]
                },
                "SP-14a": {
                    "questionId": "SP-14a",
                    "chosenOptionIds": [
                        "d81a8e84-7a08-52dd-94d4-6c5f78f86d45"
                    ]
                },
                "SP-15a": {
                    "questionId": "SP-15a",
                    "chosenOptionIds": [
                        "45cab604-070b-5a9f-9c14-39f2d04b4883"
                    ]
                },
                "SP-16a": {
                    "questionId": "SP-16a",
                    "chosenOptionIds": [
                        "cbacc3c9-c893-50d4-ab69-3826f7a9c80e"
                    ]
                },
                "SP-17.0a": {
                    "questionId": "SP-17.0a",
                    "chosenOptionIds": [
                        "748c3e8a-548a-59c0-841b-8ef86556a2c7"
                    ]
                },
                "SP-17a": {
                    "questionId": "SP-17a",
                    "chosenOptionIds": [
                        "e0b9578f-e9a5-56b0-96e1-a89329ddcf3c",
                        "ff70355e-77cb-5edd-acc6-4f5a5bd87812",
                        "d4bb9556-9e80-50cc-aada-b4362d4d12d6",
                        "dc0787ea-aee0-537f-a6e8-06a5539be69c",
                        "7482d0b7-7353-5d8d-a520-9fbfa3343cbf",
                        "7149de4e-323b-530e-96c3-b9d13a5f2652",
                        "c9c685c3-e334-5376-a214-5a96d666a44c"
                    ]
                },
                "SP-1a": {
                    "questionId": "SP-1a",
                    "chosenOptionIds": [
                        "e307b879-8b6d-5e0f-950d-bdc01347e2d3",
                        "ab832c79-0e01-5a40-b273-80805282a968",
                        "c74dcc79-fb7f-55da-930e-313ebe0cb641",
                        "c30a8e03-2164-5350-9e0b-486e36bd2ebb",
                        "b816dad5-6fb6-58cc-a84b-7187a04ab4c3",
                        "91b55a44-e875-576d-a183-43435c612a53",
                        "91b573f4-789e-5b64-92bd-ccd244e1972d",
                        "e35006ab-1d51-5bcf-8813-bbeb792d491f"
                    ]
                },
                "SP-20a": {
                    "questionId": "SP-20a",
                    "chosenOptionIds": [
                        "acd11895-4da3-5ed7-b7b6-d5242627e46e"
                    ]
                },
                "SP-3.5a": {
                    "questionId": "SP-3.5a",
                    "chosenOptionIds": [
                        "699b519f-6c57-577e-9324-55766f14447f"
                    ]
                },
                "SP-4a": {
                    "questionId": "SP-4a",
                    "chosenOptionIds": [
                        "ab977d02-7d5f-5e37-bcfc-3dba076f51df"
                    ]
                },
                "SP-5a": {
                    "questionId": "SP-5a",
                    "chosenOptionIds": [
                        "9e4e0ea3-cb07-5f37-b5cb-ff5f93854c60"
                    ]
                },
                "SP-6a": {
                    "questionId": "SP-6a",
                    "chosenOptionIds": [
                        "1ceeedc6-bfdb-5552-b1c2-a4e4a9f3abe0"
                    ]
                },
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "729f7460-99f9-56df-94fe-854d75e389b1"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "0c4dea35-391c-55c6-8db1-5f20a1f4d6a7"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "662a26db-5ace-5827-a9b4-5d9907eefa79"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "918e349b-2944-5ad5-8010-9bc2bf8bdc2a"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "081d965a-5599-5747-93df-ec639c98b41f"
                    ]
                },
                "SP-s6a": {
                    "questionId": "SP-s6a",
                    "freeValue": 6570
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: REOG402 aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-b": {
                    "questionId": "SP-b",
                    "freeValue": 70
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "729f7460-99f9-56df-94fe-854d75e389b1"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "c3f9b887-668c-5d88-bb1f-4492aa66b0e4"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "06ead534-7682-558b-8af2-f1fe4ca79c1f"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "89646e2f-b5da-5058-bf39-0dca36bec2cb"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "35110660-4738-5bcd-96a1-3ad5a821141f"
                    ]
                },
                "SP-s6b": {
                    "questionId": "SP-s6b",
                    "freeValue": 3650
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: J0V6N3L aspect: soa_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SP-12a": {
                    "questionId": "SP-12a",
                    "chosenOptionIds": [
                        "b616f997-0283-5f0c-8c36-131ca536758c"
                    ]
                },
                "SP-13a": {
                    "questionId": "SP-13a",
                    "chosenOptionIds": [
                        "12d8ea19-5e9f-583c-b31f-d0783c67d0e5"
                    ]
                },
                "SP-14a": {
                    "questionId": "SP-14a",
                    "chosenOptionIds": [
                        "6444b285-a910-5c51-b8e3-b9e2f3a1f4cb"
                    ]
                },
                "SP-15a": {
                    "questionId": "SP-15a",
                    "chosenOptionIds": [
                        "45cab604-070b-5a9f-9c14-39f2d04b4883"
                    ]
                },
                "SP-16a": {
                    "questionId": "SP-16a",
                    "chosenOptionIds": [
                        "a849e9fd-b390-5784-b120-8488a113fb34"
                    ]
                },
                "SP-17.0a": {
                    "questionId": "SP-17.0a",
                    "chosenOptionIds": [
                        "748c3e8a-548a-59c0-841b-8ef86556a2c7"
                    ]
                },
                "SP-17a": {
                    "questionId": "SP-17a",
                    "chosenOptionIds": [
                        "b14c4c87-62f1-5a86-b30c-d1a4e5a688bb"
                    ]
                },
                "SP-1a": {
                    "questionId": "SP-1a",
                    "chosenOptionIds": [
                        "c74dcc79-fb7f-55da-930e-313ebe0cb641",
                        "91b573f4-789e-5b64-92bd-ccd244e1972d",
                        "e35006ab-1d51-5bcf-8813-bbeb792d491f",
                        "cf26d3ef-30a0-549e-829f-7e75f8590565"
                    ]
                },
                "SP-20a": {
                    "questionId": "SP-20a",
                    "chosenOptionIds": [
                        "acd11895-4da3-5ed7-b7b6-d5242627e46e"
                    ]
                },
                "SP-3.5a": {
                    "questionId": "SP-3.5a",
                    "chosenOptionIds": [
                        "f46ddc96-2ebe-5844-a9a4-1f11c5d98a36"
                    ]
                },
                "SP-4a": {
                    "questionId": "SP-4a",
                    "chosenOptionIds": [
                        "c61e6245-438c-520e-b1fc-8fdf2a2b777d"
                    ]
                },
                "SP-5a": {
                    "questionId": "SP-5a",
                    "chosenOptionIds": [
                        "a271682c-056b-59bf-ad44-391fcbc15670"
                    ]
                },
                "SP-6a": {
                    "questionId": "SP-6a",
                    "chosenOptionIds": [
                        "9cc1eaa9-853e-50a1-848a-bbeaf73425bf"
                    ]
                },
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "c4f76c19-a59a-59dd-89af-dbc327647d2d"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "9e25633b-ffae-5cf6-aefc-bd5a3d5a0a12"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "06ead534-7682-558b-8af2-f1fe4ca79c1f"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "442311b3-fedd-589a-95a1-5a0b5c17f8c4"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "081d965a-5599-5747-93df-ec639c98b41f"
                    ]
                },
                "SP-s6a": {
                    "questionId": "SP-s6a",
                    "freeValue": 4380
                }
            }
        },
        {
            "name": "Censeo vPrevious user: J0V6N3L aspect: soa_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.4
            },
            "answers": {
                "SP-12a": {
                    "questionId": "SP-12a",
                    "chosenOptionIds": [
                        "b616f997-0283-5f0c-8c36-131ca536758c"
                    ]
                },
                "SP-13a": {
                    "questionId": "SP-13a",
                    "chosenOptionIds": [
                        "12d8ea19-5e9f-583c-b31f-d0783c67d0e5"
                    ]
                },
                "SP-14a": {
                    "questionId": "SP-14a",
                    "chosenOptionIds": [
                        "6444b285-a910-5c51-b8e3-b9e2f3a1f4cb"
                    ]
                },
                "SP-15a": {
                    "questionId": "SP-15a",
                    "chosenOptionIds": [
                        "45cab604-070b-5a9f-9c14-39f2d04b4883"
                    ]
                },
                "SP-16a": {
                    "questionId": "SP-16a",
                    "chosenOptionIds": [
                        "a849e9fd-b390-5784-b120-8488a113fb34"
                    ]
                },
                "SP-17.0a": {
                    "questionId": "SP-17.0a",
                    "chosenOptionIds": [
                        "748c3e8a-548a-59c0-841b-8ef86556a2c7"
                    ]
                },
                "SP-17a": {
                    "questionId": "SP-17a",
                    "chosenOptionIds": [
                        "b14c4c87-62f1-5a86-b30c-d1a4e5a688bb"
                    ]
                },
                "SP-1a": {
                    "questionId": "SP-1a",
                    "chosenOptionIds": [
                        "c74dcc79-fb7f-55da-930e-313ebe0cb641",
                        "91b573f4-789e-5b64-92bd-ccd244e1972d",
                        "e35006ab-1d51-5bcf-8813-bbeb792d491f",
                        "cf26d3ef-30a0-549e-829f-7e75f8590565"
                    ]
                },
                "SP-20a": {
                    "questionId": "SP-20a",
                    "chosenOptionIds": [
                        "acd11895-4da3-5ed7-b7b6-d5242627e46e"
                    ]
                },
                "SP-3.5a": {
                    "questionId": "SP-3.5a",
                    "chosenOptionIds": [
                        "f46ddc96-2ebe-5844-a9a4-1f11c5d98a36"
                    ]
                },
                "SP-4a": {
                    "questionId": "SP-4a",
                    "chosenOptionIds": [
                        "c61e6245-438c-520e-b1fc-8fdf2a2b777d"
                    ]
                },
                "SP-5a": {
                    "questionId": "SP-5a",
                    "chosenOptionIds": [
                        "a271682c-056b-59bf-ad44-391fcbc15670"
                    ]
                },
                "SP-6a": {
                    "questionId": "SP-6a",
                    "chosenOptionIds": [
                        "9cc1eaa9-853e-50a1-848a-bbeaf73425bf"
                    ]
                },
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "c4f76c19-a59a-59dd-89af-dbc327647d2d"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "9e25633b-ffae-5cf6-aefc-bd5a3d5a0a12"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "06ead534-7682-558b-8af2-f1fe4ca79c1f"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "442311b3-fedd-589a-95a1-5a0b5c17f8c4"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "081d965a-5599-5747-93df-ec639c98b41f"
                    ]
                },
                "SP-s6a": {
                    "questionId": "SP-s6a",
                    "freeValue": 4380
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: Z3R680O aspect: soa_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SP-12a": {
                    "questionId": "SP-12a",
                    "chosenOptionIds": [
                        "396f7bdb-367e-544e-8dd1-49a0d8c58589"
                    ]
                },
                "SP-13a": {
                    "questionId": "SP-13a",
                    "chosenOptionIds": [
                        "2df17952-f192-59e5-966e-faa810365cdc"
                    ]
                },
                "SP-14a": {
                    "questionId": "SP-14a",
                    "chosenOptionIds": [
                        "d81a8e84-7a08-52dd-94d4-6c5f78f86d45"
                    ]
                },
                "SP-15a": {
                    "questionId": "SP-15a",
                    "chosenOptionIds": [
                        "af6f01bc-55f9-51d4-9531-ab0d07dab4a9"
                    ]
                },
                "SP-16a": {
                    "questionId": "SP-16a",
                    "chosenOptionIds": [
                        "5e476e67-3397-596c-b5aa-228f70f81ca7"
                    ]
                },
                "SP-17.0a": {
                    "questionId": "SP-17.0a",
                    "chosenOptionIds": [
                        "748c3e8a-548a-59c0-841b-8ef86556a2c7"
                    ]
                },
                "SP-17a": {
                    "questionId": "SP-17a",
                    "chosenOptionIds": [
                        "e0b9578f-e9a5-56b0-96e1-a89329ddcf3c",
                        "b14c4c87-62f1-5a86-b30c-d1a4e5a688bb",
                        "d4bb9556-9e80-50cc-aada-b4362d4d12d6",
                        "dc0787ea-aee0-537f-a6e8-06a5539be69c",
                        "7482d0b7-7353-5d8d-a520-9fbfa3343cbf",
                        "c75f20ef-3f69-5b6f-bbd1-3157cb401536"
                    ]
                },
                "SP-1a": {
                    "questionId": "SP-1a",
                    "chosenOptionIds": [
                        "84da26c8-2e93-5432-8fe9-8e4c3bfa5ddf",
                        "e307b879-8b6d-5e0f-950d-bdc01347e2d3",
                        "3a2df70e-537a-5548-8f24-32a2a29da651",
                        "ab832c79-0e01-5a40-b273-80805282a968",
                        "0f17cdba-f288-54e2-8a37-99ae7e664fa4",
                        "c74dcc79-fb7f-55da-930e-313ebe0cb641",
                        "e4429794-5af9-5ed1-9ebb-ba8ca86a0ce1",
                        "91b55a44-e875-576d-a183-43435c612a53",
                        "b818ca80-ae9e-5b2c-8815-74a34c59c044",
                        "91b573f4-789e-5b64-92bd-ccd244e1972d",
                        "e35006ab-1d51-5bcf-8813-bbeb792d491f",
                        "cf26d3ef-30a0-549e-829f-7e75f8590565"
                    ]
                },
                "SP-20a": {
                    "questionId": "SP-20a",
                    "chosenOptionIds": [
                        "acd11895-4da3-5ed7-b7b6-d5242627e46e"
                    ]
                },
                "SP-3.5a": {
                    "questionId": "SP-3.5a",
                    "chosenOptionIds": [
                        "acd81294-8831-5a45-8e7a-1f458e4fbbdf"
                    ]
                },
                "SP-4a": {
                    "questionId": "SP-4a",
                    "chosenOptionIds": [
                        "e4eff7df-e208-511d-820e-8607d2c089d8"
                    ]
                },
                "SP-5a": {
                    "questionId": "SP-5a",
                    "chosenOptionIds": [
                        "1d7cc7f7-6e71-5505-8531-3d8b180adb90"
                    ]
                },
                "SP-6a": {
                    "questionId": "SP-6a",
                    "chosenOptionIds": [
                        "1ceeedc6-bfdb-5552-b1c2-a4e4a9f3abe0"
                    ]
                },
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "a38151d3-973e-530c-b7c2-51b9e33001d5"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "9e25633b-ffae-5cf6-aefc-bd5a3d5a0a12"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "5fe7f80f-b639-5222-a34d-394a586888a4"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "918e349b-2944-5ad5-8010-9bc2bf8bdc2a"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "081d965a-5599-5747-93df-ec639c98b41f"
                    ]
                },
                "SP-s6a": {
                    "questionId": "SP-s6a",
                    "freeValue": 18980
                }
            }
        },
        {
            "name": "Censeo vPrevious user: Z3R680O aspect: soa_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.4
            },
            "answers": {
                "SP-12a": {
                    "questionId": "SP-12a",
                    "chosenOptionIds": [
                        "396f7bdb-367e-544e-8dd1-49a0d8c58589"
                    ]
                },
                "SP-13a": {
                    "questionId": "SP-13a",
                    "chosenOptionIds": [
                        "2df17952-f192-59e5-966e-faa810365cdc"
                    ]
                },
                "SP-14a": {
                    "questionId": "SP-14a",
                    "chosenOptionIds": [
                        "d81a8e84-7a08-52dd-94d4-6c5f78f86d45"
                    ]
                },
                "SP-15a": {
                    "questionId": "SP-15a",
                    "chosenOptionIds": [
                        "af6f01bc-55f9-51d4-9531-ab0d07dab4a9"
                    ]
                },
                "SP-16a": {
                    "questionId": "SP-16a",
                    "chosenOptionIds": [
                        "5e476e67-3397-596c-b5aa-228f70f81ca7"
                    ]
                },
                "SP-17.0a": {
                    "questionId": "SP-17.0a",
                    "chosenOptionIds": [
                        "748c3e8a-548a-59c0-841b-8ef86556a2c7"
                    ]
                },
                "SP-17a": {
                    "questionId": "SP-17a",
                    "chosenOptionIds": [
                        "e0b9578f-e9a5-56b0-96e1-a89329ddcf3c",
                        "b14c4c87-62f1-5a86-b30c-d1a4e5a688bb",
                        "d4bb9556-9e80-50cc-aada-b4362d4d12d6",
                        "dc0787ea-aee0-537f-a6e8-06a5539be69c",
                        "7482d0b7-7353-5d8d-a520-9fbfa3343cbf",
                        "c75f20ef-3f69-5b6f-bbd1-3157cb401536"
                    ]
                },
                "SP-1a": {
                    "questionId": "SP-1a",
                    "chosenOptionIds": [
                        "84da26c8-2e93-5432-8fe9-8e4c3bfa5ddf",
                        "e307b879-8b6d-5e0f-950d-bdc01347e2d3",
                        "3a2df70e-537a-5548-8f24-32a2a29da651",
                        "ab832c79-0e01-5a40-b273-80805282a968",
                        "0f17cdba-f288-54e2-8a37-99ae7e664fa4",
                        "c74dcc79-fb7f-55da-930e-313ebe0cb641",
                        "e4429794-5af9-5ed1-9ebb-ba8ca86a0ce1",
                        "91b55a44-e875-576d-a183-43435c612a53",
                        "b818ca80-ae9e-5b2c-8815-74a34c59c044",
                        "91b573f4-789e-5b64-92bd-ccd244e1972d",
                        "e35006ab-1d51-5bcf-8813-bbeb792d491f",
                        "cf26d3ef-30a0-549e-829f-7e75f8590565"
                    ]
                },
                "SP-20a": {
                    "questionId": "SP-20a",
                    "chosenOptionIds": [
                        "acd11895-4da3-5ed7-b7b6-d5242627e46e"
                    ]
                },
                "SP-3.5a": {
                    "questionId": "SP-3.5a",
                    "chosenOptionIds": [
                        "acd81294-8831-5a45-8e7a-1f458e4fbbdf"
                    ]
                },
                "SP-4a": {
                    "questionId": "SP-4a",
                    "chosenOptionIds": [
                        "e4eff7df-e208-511d-820e-8607d2c089d8"
                    ]
                },
                "SP-5a": {
                    "questionId": "SP-5a",
                    "chosenOptionIds": [
                        "1d7cc7f7-6e71-5505-8531-3d8b180adb90"
                    ]
                },
                "SP-6a": {
                    "questionId": "SP-6a",
                    "chosenOptionIds": [
                        "1ceeedc6-bfdb-5552-b1c2-a4e4a9f3abe0"
                    ]
                },
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "a38151d3-973e-530c-b7c2-51b9e33001d5"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "9e25633b-ffae-5cf6-aefc-bd5a3d5a0a12"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "5fe7f80f-b639-5222-a34d-394a586888a4"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "918e349b-2944-5ad5-8010-9bc2bf8bdc2a"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "081d965a-5599-5747-93df-ec639c98b41f"
                    ]
                },
                "SP-s6a": {
                    "questionId": "SP-s6a",
                    "freeValue": 18980
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: R047J04 aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "a38151d3-973e-530c-b7c2-51b9e33001d5"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "9e25633b-ffae-5cf6-aefc-bd5a3d5a0a12"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "89f821ca-0625-5069-b53c-b427bb9e13d4"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "918e349b-2944-5ad5-8010-9bc2bf8bdc2a"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: ME87V3W aspect: soa_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "SP-12a": {
                    "questionId": "SP-12a",
                    "chosenOptionIds": [
                        "a0ef8867-a766-5812-a5d1-4249b1e9c99e"
                    ]
                },
                "SP-13a": {
                    "questionId": "SP-13a",
                    "chosenOptionIds": [
                        "d43b4e51-8c41-5b94-85a5-1311e7e9c1ac"
                    ]
                },
                "SP-14a": {
                    "questionId": "SP-14a",
                    "chosenOptionIds": [
                        "d81a8e84-7a08-52dd-94d4-6c5f78f86d45"
                    ]
                },
                "SP-15a": {
                    "questionId": "SP-15a",
                    "chosenOptionIds": [
                        "8c9d90a9-2d0c-553c-aaa2-6f53088f91e7"
                    ]
                },
                "SP-16a": {
                    "questionId": "SP-16a",
                    "chosenOptionIds": [
                        "5e476e67-3397-596c-b5aa-228f70f81ca7"
                    ]
                },
                "SP-17.0a": {
                    "questionId": "SP-17.0a",
                    "chosenOptionIds": [
                        "9cfbde86-55ad-56ae-b2c4-75aea806f939"
                    ]
                },
                "SP-17a": {
                    "questionId": "SP-17a",
                    "chosenOptionIds": [
                        "ff70355e-77cb-5edd-acc6-4f5a5bd87812",
                        "e0b9578f-e9a5-56b0-96e1-a89329ddcf3c",
                        "d4bb9556-9e80-50cc-aada-b4362d4d12d6",
                        "dc0787ea-aee0-537f-a6e8-06a5539be69c",
                        "7482d0b7-7353-5d8d-a520-9fbfa3343cbf"
                    ]
                },
                "SP-1a": {
                    "questionId": "SP-1a",
                    "chosenOptionIds": [
                        "e307b879-8b6d-5e0f-950d-bdc01347e2d3",
                        "ab832c79-0e01-5a40-b273-80805282a968",
                        "c74dcc79-fb7f-55da-930e-313ebe0cb641",
                        "e4429794-5af9-5ed1-9ebb-ba8ca86a0ce1",
                        "91b573f4-789e-5b64-92bd-ccd244e1972d",
                        "e35006ab-1d51-5bcf-8813-bbeb792d491f"
                    ]
                },
                "SP-20a": {
                    "questionId": "SP-20a",
                    "chosenOptionIds": [
                        "acd11895-4da3-5ed7-b7b6-d5242627e46e"
                    ]
                },
                "SP-3.5a": {
                    "questionId": "SP-3.5a",
                    "chosenOptionIds": [
                        "dc100be9-9b82-5329-aa16-e143ad018760"
                    ]
                },
                "SP-4a": {
                    "questionId": "SP-4a",
                    "chosenOptionIds": [
                        "c61e6245-438c-520e-b1fc-8fdf2a2b777d"
                    ]
                },
                "SP-5a": {
                    "questionId": "SP-5a",
                    "chosenOptionIds": [
                        "abd020f5-cb4f-54b1-9ed2-893ac7ff4958"
                    ]
                },
                "SP-6a": {
                    "questionId": "SP-6a",
                    "chosenOptionIds": [
                        "9ad81c0e-2776-568a-a0a5-c7eb52a58c78"
                    ]
                },
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "c4f76c19-a59a-59dd-89af-dbc327647d2d"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "0c4dea35-391c-55c6-8db1-5f20a1f4d6a7"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "5fe7f80f-b639-5222-a34d-394a586888a4"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "442311b3-fedd-589a-95a1-5a0b5c17f8c4"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "081d965a-5599-5747-93df-ec639c98b41f"
                    ]
                },
                "SP-s6a": {
                    "questionId": "SP-s6a",
                    "freeValue": 2555
                }
            }
        },
        {
            "name": "Censeo vPrevious user: ME87V3W aspect: soa_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.41
            },
            "answers": {
                "SP-12a": {
                    "questionId": "SP-12a",
                    "chosenOptionIds": [
                        "a0ef8867-a766-5812-a5d1-4249b1e9c99e"
                    ]
                },
                "SP-13a": {
                    "questionId": "SP-13a",
                    "chosenOptionIds": [
                        "d43b4e51-8c41-5b94-85a5-1311e7e9c1ac"
                    ]
                },
                "SP-14a": {
                    "questionId": "SP-14a",
                    "chosenOptionIds": [
                        "d81a8e84-7a08-52dd-94d4-6c5f78f86d45"
                    ]
                },
                "SP-15a": {
                    "questionId": "SP-15a",
                    "chosenOptionIds": [
                        "8c9d90a9-2d0c-553c-aaa2-6f53088f91e7"
                    ]
                },
                "SP-16a": {
                    "questionId": "SP-16a",
                    "chosenOptionIds": [
                        "5e476e67-3397-596c-b5aa-228f70f81ca7"
                    ]
                },
                "SP-17.0a": {
                    "questionId": "SP-17.0a",
                    "chosenOptionIds": [
                        "9cfbde86-55ad-56ae-b2c4-75aea806f939"
                    ]
                },
                "SP-17a": {
                    "questionId": "SP-17a",
                    "chosenOptionIds": [
                        "ff70355e-77cb-5edd-acc6-4f5a5bd87812",
                        "e0b9578f-e9a5-56b0-96e1-a89329ddcf3c",
                        "d4bb9556-9e80-50cc-aada-b4362d4d12d6",
                        "dc0787ea-aee0-537f-a6e8-06a5539be69c",
                        "7482d0b7-7353-5d8d-a520-9fbfa3343cbf"
                    ]
                },
                "SP-1a": {
                    "questionId": "SP-1a",
                    "chosenOptionIds": [
                        "e307b879-8b6d-5e0f-950d-bdc01347e2d3",
                        "ab832c79-0e01-5a40-b273-80805282a968",
                        "c74dcc79-fb7f-55da-930e-313ebe0cb641",
                        "e4429794-5af9-5ed1-9ebb-ba8ca86a0ce1",
                        "91b573f4-789e-5b64-92bd-ccd244e1972d",
                        "e35006ab-1d51-5bcf-8813-bbeb792d491f"
                    ]
                },
                "SP-20a": {
                    "questionId": "SP-20a",
                    "chosenOptionIds": [
                        "acd11895-4da3-5ed7-b7b6-d5242627e46e"
                    ]
                },
                "SP-3.5a": {
                    "questionId": "SP-3.5a",
                    "chosenOptionIds": [
                        "dc100be9-9b82-5329-aa16-e143ad018760"
                    ]
                },
                "SP-4a": {
                    "questionId": "SP-4a",
                    "chosenOptionIds": [
                        "c61e6245-438c-520e-b1fc-8fdf2a2b777d"
                    ]
                },
                "SP-5a": {
                    "questionId": "SP-5a",
                    "chosenOptionIds": [
                        "abd020f5-cb4f-54b1-9ed2-893ac7ff4958"
                    ]
                },
                "SP-6a": {
                    "questionId": "SP-6a",
                    "chosenOptionIds": [
                        "9ad81c0e-2776-568a-a0a5-c7eb52a58c78"
                    ]
                },
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "c4f76c19-a59a-59dd-89af-dbc327647d2d"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "0c4dea35-391c-55c6-8db1-5f20a1f4d6a7"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "5fe7f80f-b639-5222-a34d-394a586888a4"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "442311b3-fedd-589a-95a1-5a0b5c17f8c4"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "081d965a-5599-5747-93df-ec639c98b41f"
                    ]
                },
                "SP-s6a": {
                    "questionId": "SP-s6a",
                    "freeValue": 2555
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: 2E17NED aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "a38151d3-973e-530c-b7c2-51b9e33001d5"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "3796a228-2196-5f8a-9bdd-ce0d6f1ed8c5"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "662a26db-5ace-5827-a9b4-5d9907eefa79"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "256f2151-3716-5adc-89c2-7cc79b551197"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: PE61J0L aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "e3f844ee-a641-5899-a9da-8d4dbd688782"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: 50MVM06 aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "a38151d3-973e-530c-b7c2-51b9e33001d5"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "c3f9b887-668c-5d88-bb1f-4492aa66b0e4"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "662a26db-5ace-5827-a9b4-5d9907eefa79"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "918e349b-2944-5ad5-8010-9bc2bf8bdc2a"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: J3JK4EY aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "a38151d3-973e-530c-b7c2-51b9e33001d5"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "c3f9b887-668c-5d88-bb1f-4492aa66b0e4"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "662a26db-5ace-5827-a9b4-5d9907eefa79"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "918e349b-2944-5ad5-8010-9bc2bf8bdc2a"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: Z3RLO3O aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-b": {
                    "questionId": "SP-b",
                    "freeValue": 0
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "6b433886-476a-53c6-ada2-1e2260c12d17"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "c3f9b887-668c-5d88-bb1f-4492aa66b0e4"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "89f821ca-0625-5069-b53c-b427bb9e13d4"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "a0f2ad4f-2e36-5a45-9552-a8bf992a1ef1"
                    ]
                },
                "SP-s5": {
                    "questionId": "SP-s5",
                    "chosenOptionIds": [
                        "35110660-4738-5bcd-96a1-3ad5a821141f"
                    ]
                },
                "SP-s6b": {
                    "questionId": "SP-s6b",
                    "freeValue": 0
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: XE7WX3L aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "a38151d3-973e-530c-b7c2-51b9e33001d5"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "c3f9b887-668c-5d88-bb1f-4492aa66b0e4"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "89f821ca-0625-5069-b53c-b427bb9e13d4"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "a0f2ad4f-2e36-5a45-9552-a8bf992a1ef1"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: O3P263W aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "729f7460-99f9-56df-94fe-854d75e389b1"
                    ]
                },
                "SP-s2": {
                    "questionId": "SP-s2",
                    "chosenOptionIds": [
                        "c3f9b887-668c-5d88-bb1f-4492aa66b0e4"
                    ]
                },
                "SP-s3": {
                    "questionId": "SP-s3",
                    "chosenOptionIds": [
                        "662a26db-5ace-5827-a9b4-5d9907eefa79"
                    ]
                },
                "SP-s4": {
                    "questionId": "SP-s4",
                    "chosenOptionIds": [
                        "256f2151-3716-5adc-89c2-7cc79b551197"
                    ]
                }
            }
        },
        {
            "name": "0 confidence subclinical: user: NELXPEV aspect: soa_ce should be unknown",
            "expected": {
                "type": "Severity",
                "value": "low"
            },
            "answers": {
                "SP-S": {
                    "questionId": "SP-S",
                    "chosenOptionIds": [
                        "8200fc6e-befe-516f-9cd3-e7a5e89fd2ef"
                    ]
                },
                "SP-s1": {
                    "questionId": "SP-s1",
                    "chosenOptionIds": [
                        "e3f844ee-a641-5899-a9da-8d4dbd688782"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {
    "SP-s2": "not(SP-s1=0)",
    "SP-s3": "not(SP-s1=0)",
    "SP-s4": "not(SP-s1=0)",
    "SP-s5": "and[or[isUndefined(and[SP-s1<=0.5, SP-s2<=0.5, SP-s3<=0.5, SP-s4<=0.5]), not(and[SP-s1<=0.5, SP-s2<=0.5, SP-s3<=0.5, SP-s4<=0.5])], not(isUndefined(SP-s4=0))]",
    "SP-s6b": "SP-s5=0",
    "SP-b": "SP-s5=0",
    "SP-s6a": "not(SP-s5=0)",
    "SP-1a": "not(SP-s5=0)",
    "SP-3.5a": "not(SP-s5=0)",
    "SP-4a": "not(SP-s5=0)",
    "SP-5a": "not(SP-s5=0)",
    "SP-6a": "not(SP-s5=0)",
    "SP-12a": "not(SP-s5=0)",
    "SP-13a": "not(SP-s5=0)",
    "SP-14a": "not(SP-s5=0)",
    "SP-15a": "not(SP-s5=0)",
    "SP-16a": "not(SP-s5=0)",
    "SP-17a": "not(SP-s5=0)",
    "SP-17.0a": "and[or[isUndefined(SP17a_none=1), not(SP17a_none=1)], not(isUndefined(SP-17a=0))]",
    "SP-20a": "not(SP-s5=0)",
    "SP-S": "not(SP-s5=0)"
};
