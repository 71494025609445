"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
exports.questions = [
    {
        "__typename": "ContentQuestion",
        "id": "GAD7-content",
        "text": "The next set of questions asks about common experiences that can affect mental health, like feeling worried or having trouble sleeping. \n\nTake your time with these questions, and remember there are no right or wrong answers. \n\nSelect 'Continue' to start.",
        "answers": [
            {
                "__typename": "Option",
                "id": "35d4d798-c649-4104-8e77-874a96550bcb",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "4ea93ecb-d926-4892-91b8-9f873ef6185f",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2567389e-a4a4-4094-b502-7380094b5263",
                "text": "Several days",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "8aefd5f1-89d5-4707-882f-2937f9f01eba",
                "text": "More than half the days",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "e30ee916-3e11-4244-9937-1146940b7daa",
                "text": "Nearly every day",
                "value": 1
            }
        ],
        "id": "GAD7-1",
        "text": "Over the **last two weeks**, how often have you been bothered by the following problem? \n\n**Feeling nervous, anxious, or on edge**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "41bebfa2-4538-48c9-8e24-216858c44e72",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "6e027877-b40e-4295-82f4-e43af97b58fa",
                "text": "Several days",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "693d81df-cf3f-431f-8d23-372150709059",
                "text": "More than half the days",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "3b223845-ad4d-43af-86a4-a47f1f3ee4e1",
                "text": "Nearly every day",
                "value": 1
            }
        ],
        "id": "GAD7-2",
        "text": "Over the **last two weeks**, how often have you been bothered by the following problem? \n\n**Not being able to stop or control worrying**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "3187ac61-9092-4be7-9f8a-717b25ba838b",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c1c04481-505f-4e23-8ea8-16bb9bcdea91",
                "text": "Several days",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "e4a80d32-ec37-474e-bbdb-32ca13ceedf8",
                "text": "More than half the days",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "a433f33d-a253-489e-87a3-c9a5f9461439",
                "text": "Nearly every day",
                "value": 1
            }
        ],
        "id": "GAD7-3",
        "text": "Over the **last two weeks**, how often have you been bothered by the following problem? \n\n**Worrying too much about different things**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "468f9470-c70a-4cd3-965e-2a5846877d04",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "51f5beb8-e597-4d30-9bd0-2308f6220020",
                "text": "Several days",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "ee3eca9d-492b-413f-9390-2c805d12e454",
                "text": "More than half the days",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "b1e016a8-525c-4c18-975d-8976b256ac17",
                "text": "Nearly every day",
                "value": 1
            }
        ],
        "id": "GAD7-4",
        "text": "Over the **last two weeks**, how often have you been bothered by the following problem? \n\n**Trouble relaxing**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "78bfb5fc-7f66-4469-bb4c-e6c238e7fba3",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "5568a010-48b1-49f2-8c37-ccbd557af5ad",
                "text": "Several days",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "478ec691-f333-4d6b-b361-da703c3b1e52",
                "text": "More than half the days",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "07829607-ad08-4dd8-b3b6-cc1dbad76977",
                "text": "Nearly every day",
                "value": 1
            }
        ],
        "id": "GAD7-5",
        "text": "Over the **last two weeks**, how often have you been bothered by the following problem? \n\n**Being so restless that it is hard to sit still**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "0d16f6f0-9ff0-46f1-98e4-904f187c9401",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "4dfb954d-4745-4db6-ad48-cf85ee3a4905",
                "text": "Several days",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "bd6399ce-b73f-48d6-a8f5-5b0cd7c8de89",
                "text": "More than half the days",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "13141ab7-8a74-406d-a7eb-62e6d2e9e5e5",
                "text": "Nearly every day",
                "value": 1
            }
        ],
        "id": "GAD7-6",
        "text": "Over the **last two weeks**, how often have you been bothered by the following problem? \n\n**Becoming easily annoyed or irritable**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "102a45c2-8167-4f91-8dee-c0a8a4d155f3",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "af1f3fd2-c52e-4905-8298-736e79132bb0",
                "text": "Several days",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "ee62bba3-c90f-4577-9497-c77e540a118d",
                "text": "More than half the days",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "49a0101b-0dd2-4731-a0ac-1ec58c7c02cd",
                "text": "Nearly every day",
                "value": 1
            }
        ],
        "id": "GAD7-7",
        "text": "Over the **last two weeks**, how often have you been bothered by the following problem? \n\n**Feeling afraid, as if something awful might happen**",
        "isMultiselect": false
    }
];
exports.aspects = {
    "gad7": {
        "type": "mean",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "GAD7-1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "4ea93ecb-d926-4892-91b8-9f873ef6185f": 0,
                            "2567389e-a4a4-4094-b502-7380094b5263": 0.333333333333333,
                            "8aefd5f1-89d5-4707-882f-2937f9f01eba": 0.666666666666666,
                            "e30ee916-3e11-4244-9937-1146940b7daa": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "GAD7-2",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "41bebfa2-4538-48c9-8e24-216858c44e72": 0,
                            "6e027877-b40e-4295-82f4-e43af97b58fa": 0.333333333333333,
                            "693d81df-cf3f-431f-8d23-372150709059": 0.666666666666666,
                            "3b223845-ad4d-43af-86a4-a47f1f3ee4e1": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "GAD7-3",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "3187ac61-9092-4be7-9f8a-717b25ba838b": 0,
                            "c1c04481-505f-4e23-8ea8-16bb9bcdea91": 0.333333333333333,
                            "e4a80d32-ec37-474e-bbdb-32ca13ceedf8": 0.666666666666666,
                            "a433f33d-a253-489e-87a3-c9a5f9461439": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "GAD7-4",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "468f9470-c70a-4cd3-965e-2a5846877d04": 0,
                            "51f5beb8-e597-4d30-9bd0-2308f6220020": 0.333333333333333,
                            "ee3eca9d-492b-413f-9390-2c805d12e454": 0.666666666666666,
                            "b1e016a8-525c-4c18-975d-8976b256ac17": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "GAD7-5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "78bfb5fc-7f66-4469-bb4c-e6c238e7fba3": 0,
                            "5568a010-48b1-49f2-8c37-ccbd557af5ad": 0.333333333333333,
                            "478ec691-f333-4d6b-b361-da703c3b1e52": 0.666666666666666,
                            "07829607-ad08-4dd8-b3b6-cc1dbad76977": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "GAD7-6",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "0d16f6f0-9ff0-46f1-98e4-904f187c9401": 0,
                            "4dfb954d-4745-4db6-ad48-cf85ee3a4905": 0.333333333333333,
                            "bd6399ce-b73f-48d6-a8f5-5b0cd7c8de89": 0.666666666666666,
                            "13141ab7-8a74-406d-a7eb-62e6d2e9e5e5": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "GAD7-7",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "102a45c2-8167-4f91-8dee-c0a8a4d155f3": 0,
                            "af1f3fd2-c52e-4905-8298-736e79132bb0": 0.333333333333333,
                            "ee62bba3-c90f-4577-9497-c77e540a118d": 0.666666666666666,
                            "49a0101b-0dd2-4731-a0ac-1ec58c7c02cd": 1
                        }
                    }
                }
            }
        ]
    }
};
exports.tests = {
    "gad7": [
        {
            "name": "GAD7 - if score 4 -> minimal anxiety-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.19047619047619,
                "threshold": 0
            },
            "answers": {
                "GAD7-1": {
                    "questionId": "GAD7-1",
                    "chosenOptionIds": [
                        "2567389e-a4a4-4094-b502-7380094b5263"
                    ]
                },
                "GAD7-2": {
                    "questionId": "GAD7-2",
                    "chosenOptionIds": [
                        "6e027877-b40e-4295-82f4-e43af97b58fa"
                    ]
                },
                "GAD7-3": {
                    "questionId": "GAD7-3",
                    "chosenOptionIds": [
                        "c1c04481-505f-4e23-8ea8-16bb9bcdea91"
                    ]
                },
                "GAD7-4": {
                    "questionId": "GAD7-4",
                    "chosenOptionIds": [
                        "51f5beb8-e597-4d30-9bd0-2308f6220020"
                    ]
                },
                "GAD7-5": {
                    "questionId": "GAD7-5",
                    "chosenOptionIds": [
                        "78bfb5fc-7f66-4469-bb4c-e6c238e7fba3"
                    ]
                },
                "GAD7-6": {
                    "questionId": "GAD7-6",
                    "chosenOptionIds": [
                        "0d16f6f0-9ff0-46f1-98e4-904f187c9401"
                    ]
                },
                "GAD7-7": {
                    "questionId": "GAD7-7",
                    "chosenOptionIds": [
                        "102a45c2-8167-4f91-8dee-c0a8a4d155f3"
                    ]
                }
            }
        },
        {
            "name": "GAD7 - if score 5 -> mild anxiety-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.238095238095238,
                "threshold": 0
            },
            "answers": {
                "GAD7-1": {
                    "questionId": "GAD7-1",
                    "chosenOptionIds": [
                        "2567389e-a4a4-4094-b502-7380094b5263"
                    ]
                },
                "GAD7-2": {
                    "questionId": "GAD7-2",
                    "chosenOptionIds": [
                        "6e027877-b40e-4295-82f4-e43af97b58fa"
                    ]
                },
                "GAD7-3": {
                    "questionId": "GAD7-3",
                    "chosenOptionIds": [
                        "c1c04481-505f-4e23-8ea8-16bb9bcdea91"
                    ]
                },
                "GAD7-4": {
                    "questionId": "GAD7-4",
                    "chosenOptionIds": [
                        "51f5beb8-e597-4d30-9bd0-2308f6220020"
                    ]
                },
                "GAD7-5": {
                    "questionId": "GAD7-5",
                    "chosenOptionIds": [
                        "78bfb5fc-7f66-4469-bb4c-e6c238e7fba3"
                    ]
                },
                "GAD7-6": {
                    "questionId": "GAD7-6",
                    "chosenOptionIds": [
                        "0d16f6f0-9ff0-46f1-98e4-904f187c9401"
                    ]
                },
                "GAD7-7": {
                    "questionId": "GAD7-7",
                    "chosenOptionIds": [
                        "af1f3fd2-c52e-4905-8298-736e79132bb0"
                    ]
                }
            }
        },
        {
            "name": "GAD7 - if score 9 -> mild anxiety-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.428571428571428,
                "threshold": 0
            },
            "answers": {
                "GAD7-1": {
                    "questionId": "GAD7-1",
                    "chosenOptionIds": [
                        "e30ee916-3e11-4244-9937-1146940b7daa"
                    ]
                },
                "GAD7-2": {
                    "questionId": "GAD7-2",
                    "chosenOptionIds": [
                        "3b223845-ad4d-43af-86a4-a47f1f3ee4e1"
                    ]
                },
                "GAD7-3": {
                    "questionId": "GAD7-3",
                    "chosenOptionIds": [
                        "e4a80d32-ec37-474e-bbdb-32ca13ceedf8"
                    ]
                },
                "GAD7-4": {
                    "questionId": "GAD7-4",
                    "chosenOptionIds": [
                        "51f5beb8-e597-4d30-9bd0-2308f6220020"
                    ]
                },
                "GAD7-5": {
                    "questionId": "GAD7-5",
                    "chosenOptionIds": [
                        "78bfb5fc-7f66-4469-bb4c-e6c238e7fba3"
                    ]
                },
                "GAD7-6": {
                    "questionId": "GAD7-6",
                    "chosenOptionIds": [
                        "0d16f6f0-9ff0-46f1-98e4-904f187c9401"
                    ]
                },
                "GAD7-7": {
                    "questionId": "GAD7-7",
                    "chosenOptionIds": [
                        "102a45c2-8167-4f91-8dee-c0a8a4d155f3"
                    ]
                }
            }
        },
        {
            "name": "GAD7 - if score 10 -> moderate anxiety-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.476190476190476,
                "threshold": 0
            },
            "answers": {
                "GAD7-1": {
                    "questionId": "GAD7-1",
                    "chosenOptionIds": [
                        "e30ee916-3e11-4244-9937-1146940b7daa"
                    ]
                },
                "GAD7-2": {
                    "questionId": "GAD7-2",
                    "chosenOptionIds": [
                        "3b223845-ad4d-43af-86a4-a47f1f3ee4e1"
                    ]
                },
                "GAD7-3": {
                    "questionId": "GAD7-3",
                    "chosenOptionIds": [
                        "e4a80d32-ec37-474e-bbdb-32ca13ceedf8"
                    ]
                },
                "GAD7-4": {
                    "questionId": "GAD7-4",
                    "chosenOptionIds": [
                        "51f5beb8-e597-4d30-9bd0-2308f6220020"
                    ]
                },
                "GAD7-5": {
                    "questionId": "GAD7-5",
                    "chosenOptionIds": [
                        "5568a010-48b1-49f2-8c37-ccbd557af5ad"
                    ]
                },
                "GAD7-6": {
                    "questionId": "GAD7-6",
                    "chosenOptionIds": [
                        "0d16f6f0-9ff0-46f1-98e4-904f187c9401"
                    ]
                },
                "GAD7-7": {
                    "questionId": "GAD7-7",
                    "chosenOptionIds": [
                        "102a45c2-8167-4f91-8dee-c0a8a4d155f3"
                    ]
                }
            }
        },
        {
            "name": "GAD7 - if score 14 -> moderate anxiety-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.666666666666666,
                "threshold": 0
            },
            "answers": {
                "GAD7-1": {
                    "questionId": "GAD7-1",
                    "chosenOptionIds": [
                        "e30ee916-3e11-4244-9937-1146940b7daa"
                    ]
                },
                "GAD7-2": {
                    "questionId": "GAD7-2",
                    "chosenOptionIds": [
                        "3b223845-ad4d-43af-86a4-a47f1f3ee4e1"
                    ]
                },
                "GAD7-3": {
                    "questionId": "GAD7-3",
                    "chosenOptionIds": [
                        "a433f33d-a253-489e-87a3-c9a5f9461439"
                    ]
                },
                "GAD7-4": {
                    "questionId": "GAD7-4",
                    "chosenOptionIds": [
                        "ee3eca9d-492b-413f-9390-2c805d12e454"
                    ]
                },
                "GAD7-5": {
                    "questionId": "GAD7-5",
                    "chosenOptionIds": [
                        "5568a010-48b1-49f2-8c37-ccbd557af5ad"
                    ]
                },
                "GAD7-6": {
                    "questionId": "GAD7-6",
                    "chosenOptionIds": [
                        "4dfb954d-4745-4db6-ad48-cf85ee3a4905"
                    ]
                },
                "GAD7-7": {
                    "questionId": "GAD7-7",
                    "chosenOptionIds": [
                        "af1f3fd2-c52e-4905-8298-736e79132bb0"
                    ]
                }
            }
        },
        {
            "name": "GAD7 - if score 15 -> severe anxiety-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.714285714285714,
                "threshold": 0
            },
            "answers": {
                "GAD7-1": {
                    "questionId": "GAD7-1",
                    "chosenOptionIds": [
                        "e30ee916-3e11-4244-9937-1146940b7daa"
                    ]
                },
                "GAD7-2": {
                    "questionId": "GAD7-2",
                    "chosenOptionIds": [
                        "3b223845-ad4d-43af-86a4-a47f1f3ee4e1"
                    ]
                },
                "GAD7-3": {
                    "questionId": "GAD7-3",
                    "chosenOptionIds": [
                        "a433f33d-a253-489e-87a3-c9a5f9461439"
                    ]
                },
                "GAD7-4": {
                    "questionId": "GAD7-4",
                    "chosenOptionIds": [
                        "b1e016a8-525c-4c18-975d-8976b256ac17"
                    ]
                },
                "GAD7-5": {
                    "questionId": "GAD7-5",
                    "chosenOptionIds": [
                        "478ec691-f333-4d6b-b361-da703c3b1e52"
                    ]
                },
                "GAD7-6": {
                    "questionId": "GAD7-6",
                    "chosenOptionIds": [
                        "4dfb954d-4745-4db6-ad48-cf85ee3a4905"
                    ]
                },
                "GAD7-7": {
                    "questionId": "GAD7-7",
                    "chosenOptionIds": [
                        "102a45c2-8167-4f91-8dee-c0a8a4d155f3"
                    ]
                }
            }
        },
        {
            "name": "GAD7 - if score 21 -> severe anxiety-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 1,
                "threshold": 0
            },
            "answers": {
                "GAD7-1": {
                    "questionId": "GAD7-1",
                    "chosenOptionIds": [
                        "e30ee916-3e11-4244-9937-1146940b7daa"
                    ]
                },
                "GAD7-2": {
                    "questionId": "GAD7-2",
                    "chosenOptionIds": [
                        "3b223845-ad4d-43af-86a4-a47f1f3ee4e1"
                    ]
                },
                "GAD7-3": {
                    "questionId": "GAD7-3",
                    "chosenOptionIds": [
                        "a433f33d-a253-489e-87a3-c9a5f9461439"
                    ]
                },
                "GAD7-4": {
                    "questionId": "GAD7-4",
                    "chosenOptionIds": [
                        "b1e016a8-525c-4c18-975d-8976b256ac17"
                    ]
                },
                "GAD7-5": {
                    "questionId": "GAD7-5",
                    "chosenOptionIds": [
                        "07829607-ad08-4dd8-b3b6-cc1dbad76977"
                    ]
                },
                "GAD7-6": {
                    "questionId": "GAD7-6",
                    "chosenOptionIds": [
                        "13141ab7-8a74-406d-a7eb-62e6d2e9e5e5"
                    ]
                },
                "GAD7-7": {
                    "questionId": "GAD7-7",
                    "chosenOptionIds": [
                        "49a0101b-0dd2-4731-a0ac-1ec58c7c02cd"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {};
