"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Are you having any thoughts about suicide at the moment: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "v2SS-6",
                    "optionSummaries": {
                        "c58a00a3-61e0-49da-8680-ec9bc56c4d96": "No",
                        "f89b3e20-299c-4e05-8ece-0736de3a90a3": "Yes"
                    }
                }
            ]
        },
        "section": [
            "Suicidality and Self-harm Questions",
            "Suicidality"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "How intrusive or troubling do you find these thoughts: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "v2SS-7",
                    "optionSummaries": {
                        "128783be-9b36-4232-95e4-4407a15b55c4": "Not applicable",
                        "0d835984-2542-4869-ace4-dc0aa9a94fe2": "Slightly",
                        "d923cebc-d434-4400-8972-d3c5bd528da9": "Moderately",
                        "492a49d6-0498-4b0b-9dab-fcecbb999e04": "Very",
                        "36f8df4c-f41b-4c94-80b4-ecbcde30dc17": "Extremely"
                    }
                }
            ]
        },
        "section": [
            "Suicidality and Self-harm Questions",
            "Suicidality"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Do you have a specific plan for how you would end your life: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "v2SS-8",
                    "optionSummaries": {
                        "21e1e05a-a7f7-40c6-b42e-c4949b0eb99e": "Not applicable",
                        "e81da880-2f6b-4e89-b1a4-0b981e872643": "No",
                        "78f6e6b8-e43a-486e-bf28-fb4ec2fe911a": "Yes"
                    }
                }
            ]
        },
        "section": [
            "Suicidality and Self-harm Questions",
            "Suicidality"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "How likely are you to act on these thoughts: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "v2SS-9",
                    "optionSummaries": {
                        "ea6ccb52-3593-468f-8747-dff11486ccbf": "Not applicable",
                        "85a841b5-fe6f-4a5e-accf-75061c379020": "Not at all likely",
                        "8527ae99-131e-4834-82ca-a4bdc719199b": "Somewhat likely",
                        "fc8a2563-7b09-4205-a94d-7c4c5c42b42d": "Moderately likely",
                        "54ea6bb7-d5ca-4e66-83c9-1fe4802c9e45": "Very likely"
                    }
                }
            ]
        },
        "section": [
            "Suicidality and Self-harm Questions",
            "Suicidality"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "In the next 24 hours, do you feel able to keep yourself safe: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "v2SS-10",
                    "optionSummaries": {
                        "1a28c637-5b91-4a3e-aeba-0f339e0ecd76": "Yes",
                        "7dcb7e4f-eed8-4078-8e87-5f1e9ff9d3ed": "Not sure",
                        "b225a7de-c74c-4d07-8252-8be6aa671285": "No"
                    }
                }
            ]
        },
        "section": [
            "Suicidality and Self-harm Questions",
            "Suicidality"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "v2SS-11"
            ],
            "prefix": "",
            "formatText": "When did you last attempt suicide: {}"
        },
        "section": [
            "Suicidality and Self-harm Questions",
            "Suicidality"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "When did you last self-harm: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "v2SS-2",
                    "optionSummaries": {
                        "61241cbb-ca55-443d-ab3e-c673a742bd36": "Never",
                        "bc5b2f24-173d-479c-81ff-b5d05d87af78": "Within the last day",
                        "d0a5d2b6-0546-4504-b92d-92ef508d215e": "A couple of days ago",
                        "449eee34-66dc-4aaa-adb7-37ed82cabb2d": "A week ago",
                        "14d67e62-f2a4-4947-ba05-afbe97003d0e": "A month ago",
                        "c21cc13f-7271-4a4c-ab30-76c86e59e17b": "Several months ago",
                        "9523b6fc-2271-48e7-a36c-1065efbdf797": "Years ago"
                    }
                }
            ]
        },
        "section": [
            "Suicidality and Self-harm Questions",
            "Self-harm"
        ]
    },
    {
        "answerSummary": {
            "type": "PrefixOptionSummary",
            "questionIds": [
                "v2SS-3"
            ],
            "prefix": "",
            "formatText": "If you have self-harmed, please describe how: {}"
        },
        "section": [
            "Suicidality and Self-harm Questions",
            "Self-harm"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "How likely are you to harm yourself: {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "v2SS-4",
                    "optionSummaries": {
                        "f0d8e8c3-b286-49b7-a25c-948dddbd7d3a": "Not applicable",
                        "55c44df4-f302-4377-a603-e68808d0d718": "Not at all likely",
                        "6f7b0e76-bfef-499d-a4c1-ce336d258bc0": "Moderately likely",
                        "4e2863d6-5ee5-487c-9d71-ec0f189a6d2f": "Very likely",
                        "1cf1992c-afe2-4ed0-8d24-25ea325d8416": "Extremely likely"
                    }
                }
            ]
        },
        "section": [
            "Suicidality and Self-harm Questions",
            "Self-harm"
        ]
    }
];
