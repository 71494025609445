"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
exports.questions = [
    {
        "__typename": "ContentQuestion",
        "id": "DAST-content-1",
        "text": "The next set of questions are about any potential drug use you may be involved in, excluding alcohol and tobacco, during the past 12 months.\n\nWhen the words “drug abuse” are used, they mean the use of prescribed or over‐the‐counter medications/drugs in excess of the directions and any non‐medical use of drugs. \n\nThe various classes of drugs may include: cannabis (e.g., marijuana, hash), solvents, tranquilizers (e.g., Valium), barbiturates, cocaine, stimulants (e.g., speed), hallucinogens (e.g., LSD) or narcotics (e.g., heroin). \n\nRemember that the questions do not include alcohol or tobacco.\n\nSelect 'Continue' to start.",
        "answers": [
            {
                "__typename": "Option",
                "id": "85211d33-b20a-4afc-883c-7638f2a1b356",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "10203601-a616-4fda-9bf1-706c39b4e88e",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2813eee5-2f93-47e6-90ca-9d9f024c5f5b",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "DAST-1",
        "text": "Have you used drugs other than those required for medical reasons?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "2f6e0b86-8ac9-4cf7-bd30-6efb210c5def",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2dd960a5-333c-40c5-a07a-ae1cd48b319b",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "DAST-2",
        "text": "Do you abuse more than one drug at a time?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "11e5470d-1ff5-41e8-b5ca-b0ac1d7fdbc9",
                "text": "No",
                "value": 1
            },
            {
                "__typename": "Option",
                "id": "19492b92-03ec-4999-81d6-d997aea61f4c",
                "text": "Yes",
                "value": 0
            }
        ],
        "id": "DAST-3",
        "text": "Are you always able to stop using drugs when you want to? (If never use drugs, answer \"Yes\")",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "5e41cbb0-c26f-4d72-9cf0-974906c94e7c",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "5a157b72-ce87-422d-8fed-aab25f3042fd",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "DAST-4",
        "text": "Have you had \"blackouts\" or \"flashbacks\" as a result of drug use?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "d0fdc9ab-788b-47c1-9e97-5d751687140b",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "240c8040-a054-4434-93cb-260bd4d2fd78",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "DAST-5",
        "text": "Do you ever feel bad or guilty about your drug use? (If never use drugs, choose \"No\")",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "7060d7fc-6931-4cfe-95ff-de5f48a4c62d",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2757526f-9f7b-45a6-8bac-0306b743b49f",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "DAST-6",
        "text": "Does your spouse (or parents) ever complain about your involvement with drugs?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "10686681-f42c-4912-92a7-18a1c2dcbddc",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7a670253-5301-4f69-b7e4-cb0532f7da38",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "DAST-7",
        "text": "Have you neglected your family because of your use of drugs?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "87d7ff71-65da-4ef0-9b12-f14b2914927a",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "bbc880a6-f53f-4236-a137-60ba0c342abf",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "DAST-8",
        "text": "Have you engaged in illegal activities in order to obtain drugs?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "0cd2a75c-2c72-46e3-bc57-90b10af47ebb",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c2c584b5-51f0-4d1c-804b-ec65159062c3",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "DAST-9",
        "text": "Have you ever experienced withdrawal symptoms (felt sick) when you stopped taking drugs?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ad261975-b042-42fe-a43a-32e9d27788db",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "8f0e41a0-554d-439c-8b45-0a0909a06e42",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "DAST-10",
        "text": "Have you had medical problems as a result of your drug use (e.g. memory loss, hepatitis, convulsions, bleeding, etc.)?",
        "isMultiselect": false
    },
    {
        "__typename": "ContentQuestion",
        "id": "DAST-content-2",
        "text": "You're nearly finished. Thank you for taking the time to share your experiences with us.",
        "answers": [
            {
                "__typename": "Option",
                "id": "fae27fda-5b9a-4a6f-8679-d693beaef0e4",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    }
];
exports.aspects = {
    "dast10": {
        "type": "mean",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "DAST-1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "10203601-a616-4fda-9bf1-706c39b4e88e": 0,
                            "2813eee5-2f93-47e6-90ca-9d9f024c5f5b": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "DAST-2",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "2f6e0b86-8ac9-4cf7-bd30-6efb210c5def": 0,
                            "2dd960a5-333c-40c5-a07a-ae1cd48b319b": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "DAST-3",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "11e5470d-1ff5-41e8-b5ca-b0ac1d7fdbc9": 1,
                            "19492b92-03ec-4999-81d6-d997aea61f4c": 0
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "DAST-4",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "5e41cbb0-c26f-4d72-9cf0-974906c94e7c": 0,
                            "5a157b72-ce87-422d-8fed-aab25f3042fd": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "DAST-5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "d0fdc9ab-788b-47c1-9e97-5d751687140b": 0,
                            "240c8040-a054-4434-93cb-260bd4d2fd78": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "DAST-6",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "7060d7fc-6931-4cfe-95ff-de5f48a4c62d": 0,
                            "2757526f-9f7b-45a6-8bac-0306b743b49f": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "DAST-7",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "10686681-f42c-4912-92a7-18a1c2dcbddc": 0,
                            "7a670253-5301-4f69-b7e4-cb0532f7da38": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "DAST-8",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "87d7ff71-65da-4ef0-9b12-f14b2914927a": 0,
                            "bbc880a6-f53f-4236-a137-60ba0c342abf": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "DAST-9",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "0cd2a75c-2c72-46e3-bc57-90b10af47ebb": 0,
                            "c2c584b5-51f0-4d1c-804b-ec65159062c3": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "DAST-10",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "ad261975-b042-42fe-a43a-32e9d27788db": 0,
                            "8f0e41a0-554d-439c-8b45-0a0909a06e42": 1
                        }
                    }
                }
            }
        ]
    }
};
exports.tests = {
    "dast10": [
        {
            "name": "DAST 10 - 0 score-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0,
                "threshold": 0
            },
            "answers": {
                "DAST-1": {
                    "questionId": "DAST-1",
                    "chosenOptionIds": [
                        "10203601-a616-4fda-9bf1-706c39b4e88e"
                    ]
                },
                "DAST-2": {
                    "questionId": "DAST-2",
                    "chosenOptionIds": [
                        "2f6e0b86-8ac9-4cf7-bd30-6efb210c5def"
                    ]
                },
                "DAST-3": {
                    "questionId": "DAST-3",
                    "chosenOptionIds": [
                        "19492b92-03ec-4999-81d6-d997aea61f4c"
                    ]
                },
                "DAST-4": {
                    "questionId": "DAST-4",
                    "chosenOptionIds": [
                        "5e41cbb0-c26f-4d72-9cf0-974906c94e7c"
                    ]
                },
                "DAST-5": {
                    "questionId": "DAST-5",
                    "chosenOptionIds": [
                        "d0fdc9ab-788b-47c1-9e97-5d751687140b"
                    ]
                },
                "DAST-6": {
                    "questionId": "DAST-6",
                    "chosenOptionIds": [
                        "7060d7fc-6931-4cfe-95ff-de5f48a4c62d"
                    ]
                },
                "DAST-7": {
                    "questionId": "DAST-7",
                    "chosenOptionIds": [
                        "10686681-f42c-4912-92a7-18a1c2dcbddc"
                    ]
                },
                "DAST-8": {
                    "questionId": "DAST-8",
                    "chosenOptionIds": [
                        "87d7ff71-65da-4ef0-9b12-f14b2914927a"
                    ]
                },
                "DAST-9": {
                    "questionId": "DAST-9",
                    "chosenOptionIds": [
                        "0cd2a75c-2c72-46e3-bc57-90b10af47ebb"
                    ]
                },
                "DAST-10": {
                    "questionId": "DAST-10",
                    "chosenOptionIds": [
                        "ad261975-b042-42fe-a43a-32e9d27788db"
                    ]
                }
            }
        },
        {
            "name": "DAST 10 - full score-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 1,
                "threshold": 0
            },
            "answers": {
                "DAST-1": {
                    "questionId": "DAST-1",
                    "chosenOptionIds": [
                        "2813eee5-2f93-47e6-90ca-9d9f024c5f5b"
                    ]
                },
                "DAST-2": {
                    "questionId": "DAST-2",
                    "chosenOptionIds": [
                        "2dd960a5-333c-40c5-a07a-ae1cd48b319b"
                    ]
                },
                "DAST-3": {
                    "questionId": "DAST-3",
                    "chosenOptionIds": [
                        "11e5470d-1ff5-41e8-b5ca-b0ac1d7fdbc9"
                    ]
                },
                "DAST-4": {
                    "questionId": "DAST-4",
                    "chosenOptionIds": [
                        "5a157b72-ce87-422d-8fed-aab25f3042fd"
                    ]
                },
                "DAST-5": {
                    "questionId": "DAST-5",
                    "chosenOptionIds": [
                        "240c8040-a054-4434-93cb-260bd4d2fd78"
                    ]
                },
                "DAST-6": {
                    "questionId": "DAST-6",
                    "chosenOptionIds": [
                        "2757526f-9f7b-45a6-8bac-0306b743b49f"
                    ]
                },
                "DAST-7": {
                    "questionId": "DAST-7",
                    "chosenOptionIds": [
                        "7a670253-5301-4f69-b7e4-cb0532f7da38"
                    ]
                },
                "DAST-8": {
                    "questionId": "DAST-8",
                    "chosenOptionIds": [
                        "bbc880a6-f53f-4236-a137-60ba0c342abf"
                    ]
                },
                "DAST-9": {
                    "questionId": "DAST-9",
                    "chosenOptionIds": [
                        "c2c584b5-51f0-4d1c-804b-ec65159062c3"
                    ]
                },
                "DAST-10": {
                    "questionId": "DAST-10",
                    "chosenOptionIds": [
                        "8f0e41a0-554d-439c-8b45-0a0909a06e42"
                    ]
                }
            }
        },
        {
            "name": "DAST 10 - partial score 1-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.5,
                "threshold": 0
            },
            "answers": {
                "DAST-1": {
                    "questionId": "DAST-1",
                    "chosenOptionIds": [
                        "10203601-a616-4fda-9bf1-706c39b4e88e"
                    ]
                },
                "DAST-2": {
                    "questionId": "DAST-2",
                    "chosenOptionIds": [
                        "2dd960a5-333c-40c5-a07a-ae1cd48b319b"
                    ]
                },
                "DAST-3": {
                    "questionId": "DAST-3",
                    "chosenOptionIds": [
                        "11e5470d-1ff5-41e8-b5ca-b0ac1d7fdbc9"
                    ]
                },
                "DAST-4": {
                    "questionId": "DAST-4",
                    "chosenOptionIds": [
                        "5e41cbb0-c26f-4d72-9cf0-974906c94e7c"
                    ]
                },
                "DAST-5": {
                    "questionId": "DAST-5",
                    "chosenOptionIds": [
                        "d0fdc9ab-788b-47c1-9e97-5d751687140b"
                    ]
                },
                "DAST-6": {
                    "questionId": "DAST-6",
                    "chosenOptionIds": [
                        "7060d7fc-6931-4cfe-95ff-de5f48a4c62d"
                    ]
                },
                "DAST-7": {
                    "questionId": "DAST-7",
                    "chosenOptionIds": [
                        "7a670253-5301-4f69-b7e4-cb0532f7da38"
                    ]
                },
                "DAST-8": {
                    "questionId": "DAST-8",
                    "chosenOptionIds": [
                        "bbc880a6-f53f-4236-a137-60ba0c342abf"
                    ]
                },
                "DAST-9": {
                    "questionId": "DAST-9",
                    "chosenOptionIds": [
                        "0cd2a75c-2c72-46e3-bc57-90b10af47ebb"
                    ]
                },
                "DAST-10": {
                    "questionId": "DAST-10",
                    "chosenOptionIds": [
                        "8f0e41a0-554d-439c-8b45-0a0909a06e42"
                    ]
                }
            }
        },
        {
            "name": "DAST 10 - partial score 2-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.6,
                "threshold": 0
            },
            "answers": {
                "DAST-1": {
                    "questionId": "DAST-1",
                    "chosenOptionIds": [
                        "2813eee5-2f93-47e6-90ca-9d9f024c5f5b"
                    ]
                },
                "DAST-2": {
                    "questionId": "DAST-2",
                    "chosenOptionIds": [
                        "2dd960a5-333c-40c5-a07a-ae1cd48b319b"
                    ]
                },
                "DAST-3": {
                    "questionId": "DAST-3",
                    "chosenOptionIds": [
                        "19492b92-03ec-4999-81d6-d997aea61f4c"
                    ]
                },
                "DAST-4": {
                    "questionId": "DAST-4",
                    "chosenOptionIds": [
                        "5a157b72-ce87-422d-8fed-aab25f3042fd"
                    ]
                },
                "DAST-5": {
                    "questionId": "DAST-5",
                    "chosenOptionIds": [
                        "d0fdc9ab-788b-47c1-9e97-5d751687140b"
                    ]
                },
                "DAST-6": {
                    "questionId": "DAST-6",
                    "chosenOptionIds": [
                        "2757526f-9f7b-45a6-8bac-0306b743b49f"
                    ]
                },
                "DAST-7": {
                    "questionId": "DAST-7",
                    "chosenOptionIds": [
                        "10686681-f42c-4912-92a7-18a1c2dcbddc"
                    ]
                },
                "DAST-8": {
                    "questionId": "DAST-8",
                    "chosenOptionIds": [
                        "87d7ff71-65da-4ef0-9b12-f14b2914927a"
                    ]
                },
                "DAST-9": {
                    "questionId": "DAST-9",
                    "chosenOptionIds": [
                        "c2c584b5-51f0-4d1c-804b-ec65159062c3"
                    ]
                },
                "DAST-10": {
                    "questionId": "DAST-10",
                    "chosenOptionIds": [
                        "8f0e41a0-554d-439c-8b45-0a0909a06e42"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {};
