"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
const censeo_1 = require("../../schema/generated/censeo");
exports.questions = [
    {
        "__typename": "ContentQuestion",
        "id": "AU-S",
        "text": "The next set of questions will ask you about your habits surrounding alcohol and substances. If you are unsure how to answer a question, please give your best estimate.",
        "answers": [
            {
                "__typename": "Option",
                "id": "63a5090e-7b50-5803-b8a8-9a26f3c8288a",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "4b79c726-c8f2-5491-8e82-316ba4cf4288",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "297fdee2-d461-5ae6-9150-2340c8d2f89d",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "AU-s1",
        "text": "Have you ever had a drink containing alcohol?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8dec39a1-5dfe-53fe-99d1-752e776bbcbc",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ee458d60-7198-5b41-bd8f-5aaaa78473f0",
                "text": "Monthly or less",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "d5bb65be-f7eb-568b-93cf-fff92621ccb2",
                "text": "2–4 times per month",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "b1a86447-08b7-5e7d-a3d5-015e31be0840",
                "text": "2–3 times per week",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9",
                "text": "4 times or more per week",
                "value": 1
            }
        ],
        "id": "AU-1",
        "text": "How often do you have a drink containing alcohol?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "86e19e81-1b92-55bc-bdb0-8cf2b062525e",
                "text": "0–2 units",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "eea8763e-5c32-5ae4-84d0-91d5de001887",
                "text": "3–4 units",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "fd2be360-9f0c-51a5-a13f-acebafdf17e8",
                "text": "5–6 units",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "2214df09-874e-5dae-bdfc-0af7cb9ccc54",
                "text": "7–9 units",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "28265f82-928c-5c60-be2c-00d6ebfe1285",
                "text": "10 or more units",
                "value": 1
            }
        ],
        "id": "AU-2.1",
        "text": "How many units of alcohol do you drink on a typical day when you are drinking?\n\nFor reference:\n1 single (25 ml) shot of spirits = 1 unit;\n1 alcopop = 1.5 units;\n1 can or bottle of beer/cider/lager = 2 units;\n1 medium (175 ml) glass of wine = 2.1 units;\n1 pint of beer/cider/lager = 3 units; \n1 large (250 ml) glass of wine = 3 units.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "abda3745-2dfc-5d22-b8e2-02e58e0d7983",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "adadb641-a888-5f52-8c2d-a33d52f437ff",
                "text": "Less than monthly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "6835adaf-f7b6-5ec3-9027-f771cabd0b98",
                "text": "Monthly",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "f74522b1-9434-5e73-a272-84ca91bd2e84",
                "text": "Weekly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "337596cc-8422-5c6f-8e9d-6b93c0be0208",
                "text": "Daily or almost daily",
                "value": 1
            }
        ],
        "id": "AU-3",
        "text": "How often have you had 6 or more units if female, or 8 or more units if male, on a single occasion in the last year?\n\nFor reference:\n1 single (25 ml) shot of spirits (gin, rum, vodka, whisky, tequila) = 1 unit;\n1 alcopop = 1.5 units;\n1 can or bottle of beer/cider/lager = 2 units;\n1 medium (175 ml) glass of wine = 2.1 units;\n1 pint of beer/cider/lager = 3 units; \n1 large (250 ml) glass of wine = 3 units.",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "43ed898e-f5c2-5acf-a887-a7a4a4fb1708",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c72c8340-6d05-5771-84b1-dbb83f231123",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "AU-3.5",
        "text": "Have you ever had a time in the past when you regularly drank a large amount of alcohol?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "AU-4",
        "text": "How many units of alcohol do you drink in a typical week?\n\nFor reference:\n1 single (25 ml) shot of spirits (gin, rum, vodka, whisky, tequila) = 1 unit;\n1 alcopop = 1.5 units;\n1 can or bottle of beer/cider/lager = 2 units;\n1 medium (175 ml) glass of wine = 2.1 units;\n1 pint of beer/cider/lager = 3 units; \n1 large (250 ml) glass of wine = 3 units.",
        "unit": censeo_1.Unit.None
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "faa6a973-d8bd-5b74-bd70-d3791b527341",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "1dc8a1e4-8441-58c6-a0d6-7201676de0c1",
                "text": "Less than monthly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "251c8b46-84a4-5031-83a2-94569d2f7d9a",
                "text": "Monthly",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "9ac9db10-6204-521a-b600-d7c2cef3fbab",
                "text": "Weekly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "7b22fec7-e9a6-51fa-a0f7-4a08ffd60960",
                "text": "Daily or almost daily",
                "value": 1
            }
        ],
        "id": "AU-5",
        "text": "How often during the last year have you found that you were not able to stop drinking once you had started?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b3eeaf44-2489-51f5-b041-f252d4f87d49",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "abfc1a2d-bdb1-5553-b79f-f61fff82e345",
                "text": "Less than monthly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "2c0a41c0-5540-5b5f-9bc9-4e70349b1f0b",
                "text": "Monthly",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "59a42152-366d-5508-a888-9629c60e91fc",
                "text": "Weekly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "2d7c790a-161a-5001-b269-b70e430a1459",
                "text": "Daily or almost daily",
                "value": 1
            }
        ],
        "id": "AU-6",
        "text": "How often during the last year have you failed to do what was normally expected from you because of your drinking?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "d3d01784-987d-5627-a97b-98623a170df9",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b6739f38-a943-5c85-aa41-205d88f3fec3",
                "text": "Less than monthly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "73c56e21-cb1e-5967-b9aa-9afcddc6fd2e",
                "text": "Monthly",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "f6ed9e50-beef-54b5-bd8b-f00b3eecd6dd",
                "text": "Weekly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "bc75d147-4107-5d17-8a9f-a0bf805daa5f",
                "text": "Daily or almost daily",
                "value": 1
            }
        ],
        "id": "AU-7",
        "text": "How often during the last year have you needed an alcoholic drink in the morning to get yourself going after a heavy drinking session?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "7cd65629-9089-5fd1-b831-efb2a167e4b0",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "fff7d9c5-1abe-5faa-b26e-712c34570801",
                "text": "Less than monthly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "436aea9d-a160-5e1e-86eb-194137fd2a53",
                "text": "Monthly",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "1b63a95c-ea92-58a6-8e8b-54d5bc42d76d",
                "text": "Weekly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "77f82166-32a0-546f-b62a-af0e32e83986",
                "text": "Daily or almost daily",
                "value": 1
            }
        ],
        "id": "AU-8",
        "text": "How often during the last year have you had a feeling of guilt or remorse after drinking?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "026ad3bf-66f3-5834-83e2-be4e4873b633",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "e551f0fb-d55f-5f69-9728-989451dcf97f",
                "text": "Less than monthly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "9dbc0af9-57ce-5a39-a2f4-10bf717ecfca",
                "text": "Monthly",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "2932b119-b5a7-5478-a243-b880d0865081",
                "text": "Weekly",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "30b1f600-8ea0-57a1-83d5-c5b16fec6340",
                "text": "Daily or almost daily",
                "value": 1
            }
        ],
        "id": "AU-9",
        "text": "How often during the last year have you been unable to remember what happened the night before because you had been drinking?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "9d796df4-e587-5bff-bb07-00d6c098a8f4",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f100da78-6b18-5222-8771-521684105f95",
                "text": "Yes, but not in the last year",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "a3963f2f-1a9d-5c11-bf4e-e1c7cfdb8cea",
                "text": "Yes, during the last year",
                "value": 1
            }
        ],
        "id": "AU-10",
        "text": "Have you or has somebody else been injured as a result of your drinking?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "070cc48a-ddfd-59da-a89e-b08e575e66a3",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "705dc095-654b-5eb2-a806-1abc7edd31a0",
                "text": "Yes, but not in the last year",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "61613604-abe9-5fb9-97a6-faa874f7b9cc",
                "text": "Yes, during the last year",
                "value": 1
            }
        ],
        "id": "AU-11",
        "text": "Has a relative or friend, doctor or other health worker been concerned about your drinking or suggested that you cut down?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "44d6a11a-144b-56f5-963f-c6e4e5985cd8",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "2a74eb35-54b2-5648-8089-4a8c02933778",
                "text": "Yes, but not in the last year",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "c2e10e2d-0c13-55ac-a903-16aa35eaecef",
                "text": "Yes, during the last year",
                "value": 1
            }
        ],
        "id": "AU-12",
        "text": "Drinking alcohol can cause arguments or other serious problems with friends, family and co-workers. Has this ever happened to you?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "d78a1a3f-f493-555e-9776-41dc70e2f63b",
                "text": "Yes, but not in the last year",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "31663734-0b9f-53d0-ac93-ab227b6a43d1",
                "text": "Yes, during the last year",
                "value": 1
            }
        ],
        "id": "AU-13",
        "text": "Have you ever tried to cut down or control your alcohol intake but found that you couldn't?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "111f6379-4749-5adc-828d-88a4f35ba226",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "74d81b8d-4bdf-55b0-bc70-67b589b78a95",
                "text": "Yes, but not in the last year",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "b5bcc60d-e298-5621-8c59-f788672ef828",
                "text": "Yes, during the last year",
                "value": 1
            }
        ],
        "id": "AU-14",
        "text": "Have you found that you need to drink more alcohol to get the same effect as you used to?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "349b824f-b485-5672-8148-d3783d5b2392",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "5672e829-525c-5e5b-a8d4-46634023977b",
                "text": "Yes, but not in the last year",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "978ea017-853e-52cd-87d5-32f93372bce3",
                "text": "Yes, during the last year",
                "value": 1
            }
        ],
        "id": "AU-15",
        "text": "After trying to cut back on alcohol or not drinking alcohol for a while, some people experience shaking hands, hallucinations, anxiety, sweating, a high pulse, nausea and sleep problems, which can last for weeks. Is this something that has happened to you in the last year?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b36eef07-a5d5-5596-9d6c-f86d8267aeee",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b9593781-a11a-5660-9bda-a02059817589",
                "text": "Yes, but not in the last year",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "8d4c54c5-18d3-5813-9b02-01d220fe06a7",
                "text": "Yes, during the last year",
                "value": 1
            }
        ],
        "id": "AU-16",
        "text": "Has trying to get hold of alcohol, being under the influence or recovering from the use of alcohol ever taken up a large part of your time?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "1281438f-eb00-583a-aba5-1645f3104665",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "6647082b-0e8d-5835-80ad-7dca26d35c9f",
                "text": "Yes, but not in the last year",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "ddb8ca69-afbe-580f-b616-aa5987f59dc3",
                "text": "Yes, during the last year",
                "value": 1
            }
        ],
        "id": "AU-17",
        "text": "Have you ever got into serious trouble due to your drinking?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "c85d230a-988f-5850-a03d-0e1a877650da",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f470bbf0-ca10-5e97-9557-3dc05f6bea1d",
                "text": "Yes, but not in the last year",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "0b4586e1-706f-5d61-b912-b0934dcb5341",
                "text": "Yes, during the last year",
                "value": 1
            }
        ],
        "id": "AU-18",
        "text": "Do you ever feel like you need an alcoholic drink first thing when you wake up?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "39d41b2f-b56f-5f1f-a9b1-16200d417b6d",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "4f7598ed-d960-5a23-9255-544dab80fe9d",
                "text": "Yes, but not in the last year",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "0052107c-edfc-5abf-b8e2-22d833c18454",
                "text": "Yes, during the last year",
                "value": 1
            }
        ],
        "id": "AU-19",
        "text": "Do you ever have a very strong desire or urge to drink alcohol?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "16f73e60-f332-50cf-9151-860eb4be2677",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "da873ed6-7c53-580c-a2fb-ebaa52cef516",
                "text": "Yes, but not in the last year",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "bccc777f-2d79-5e66-bd2f-4c723ef88f42",
                "text": "Yes, during the last year",
                "value": 1
            }
        ],
        "id": "AU-20",
        "text": "Have you ever regularly drunk so much that you blacked out or cannot remember what happened?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "64d6c3e7-3a72-51f3-b882-1a153eb2d13e",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "93f9aeb7-77ef-5172-a965-d21a8be006cd",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "AU-21",
        "text": "Has the use of alcohol caused damage to your physical or mental health?",
        "isMultiselect": false
    }
];
exports.aspects = {
    "alc_ce": {
        "type": "segmentStretch",
        "thresholds": [
            [
                0.2,
                0.25
            ],
            [
                0.4,
                0.5
            ],
            [
                0.5,
                0.75
            ]
        ],
        "wire": {
            "type": "mean",
            "includeUnanswered": true,
            "wires": [
                {
                    "type": "input",
                    "signal": {
                        "type": "AnsweredOption",
                        "questionId": "AU-1",
                        "options": {
                            "type": "severities",
                            "severities": {
                                "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": 0,
                                "ee458d60-7198-5b41-bd8f-5aaaa78473f0": 0.25,
                                "d5bb65be-f7eb-568b-93cf-fff92621ccb2": 0.5,
                                "b1a86447-08b7-5e7d-a3d5-015e31be0840": 0.75,
                                "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": 1
                            }
                        }
                    }
                },
                {
                    "type": "input",
                    "signal": {
                        "type": "AnsweredOption",
                        "questionId": "AU-2.1",
                        "options": {
                            "type": "severities",
                            "severities": {
                                "86e19e81-1b92-55bc-bdb0-8cf2b062525e": 0,
                                "eea8763e-5c32-5ae4-84d0-91d5de001887": 0.25,
                                "fd2be360-9f0c-51a5-a13f-acebafdf17e8": 0.5,
                                "2214df09-874e-5dae-bdfc-0af7cb9ccc54": 0.75,
                                "28265f82-928c-5c60-be2c-00d6ebfe1285": 1
                            }
                        }
                    }
                },
                {
                    "type": "input",
                    "signal": {
                        "type": "AnsweredOption",
                        "questionId": "AU-3",
                        "options": {
                            "type": "severities",
                            "severities": {
                                "abda3745-2dfc-5d22-b8e2-02e58e0d7983": 0,
                                "adadb641-a888-5f52-8c2d-a33d52f437ff": 0.25,
                                "6835adaf-f7b6-5ec3-9027-f771cabd0b98": 0.5,
                                "f74522b1-9434-5e73-a272-84ca91bd2e84": 0.75,
                                "337596cc-8422-5c6f-8e9d-6b93c0be0208": 1
                            }
                        }
                    }
                },
                {
                    "type": "input",
                    "signal": {
                        "type": "AnsweredOption",
                        "questionId": "AU-5",
                        "options": {
                            "type": "severities",
                            "severities": {
                                "faa6a973-d8bd-5b74-bd70-d3791b527341": 0,
                                "1dc8a1e4-8441-58c6-a0d6-7201676de0c1": 0.25,
                                "251c8b46-84a4-5031-83a2-94569d2f7d9a": 0.5,
                                "9ac9db10-6204-521a-b600-d7c2cef3fbab": 0.75,
                                "7b22fec7-e9a6-51fa-a0f7-4a08ffd60960": 1
                            }
                        }
                    }
                },
                {
                    "type": "input",
                    "signal": {
                        "type": "AnsweredOption",
                        "questionId": "AU-6",
                        "options": {
                            "type": "severities",
                            "severities": {
                                "b3eeaf44-2489-51f5-b041-f252d4f87d49": 0,
                                "abfc1a2d-bdb1-5553-b79f-f61fff82e345": 0.25,
                                "2c0a41c0-5540-5b5f-9bc9-4e70349b1f0b": 0.5,
                                "59a42152-366d-5508-a888-9629c60e91fc": 0.75,
                                "2d7c790a-161a-5001-b269-b70e430a1459": 1
                            }
                        }
                    }
                },
                {
                    "type": "input",
                    "signal": {
                        "type": "AnsweredOption",
                        "questionId": "AU-7",
                        "options": {
                            "type": "severities",
                            "severities": {
                                "d3d01784-987d-5627-a97b-98623a170df9": 0,
                                "b6739f38-a943-5c85-aa41-205d88f3fec3": 0.25,
                                "73c56e21-cb1e-5967-b9aa-9afcddc6fd2e": 0.5,
                                "f6ed9e50-beef-54b5-bd8b-f00b3eecd6dd": 0.75,
                                "bc75d147-4107-5d17-8a9f-a0bf805daa5f": 1
                            }
                        }
                    }
                },
                {
                    "type": "input",
                    "signal": {
                        "type": "AnsweredOption",
                        "questionId": "AU-8",
                        "options": {
                            "type": "severities",
                            "severities": {
                                "7cd65629-9089-5fd1-b831-efb2a167e4b0": 0,
                                "fff7d9c5-1abe-5faa-b26e-712c34570801": 0.25,
                                "436aea9d-a160-5e1e-86eb-194137fd2a53": 0.5,
                                "1b63a95c-ea92-58a6-8e8b-54d5bc42d76d": 0.75,
                                "77f82166-32a0-546f-b62a-af0e32e83986": 1
                            }
                        }
                    }
                },
                {
                    "type": "input",
                    "signal": {
                        "type": "AnsweredOption",
                        "questionId": "AU-9",
                        "options": {
                            "type": "severities",
                            "severities": {
                                "026ad3bf-66f3-5834-83e2-be4e4873b633": 0,
                                "e551f0fb-d55f-5f69-9728-989451dcf97f": 0.25,
                                "9dbc0af9-57ce-5a39-a2f4-10bf717ecfca": 0.5,
                                "2932b119-b5a7-5478-a243-b880d0865081": 0.75,
                                "30b1f600-8ea0-57a1-83d5-c5b16fec6340": 1
                            }
                        }
                    }
                },
                {
                    "type": "input",
                    "signal": {
                        "type": "AnsweredOption",
                        "questionId": "AU-10",
                        "options": {
                            "type": "severities",
                            "severities": {
                                "9d796df4-e587-5bff-bb07-00d6c098a8f4": 0,
                                "f100da78-6b18-5222-8771-521684105f95": 0.5,
                                "a3963f2f-1a9d-5c11-bf4e-e1c7cfdb8cea": 1
                            }
                        }
                    }
                },
                {
                    "type": "input",
                    "signal": {
                        "type": "AnsweredOption",
                        "questionId": "AU-11",
                        "options": {
                            "type": "severities",
                            "severities": {
                                "070cc48a-ddfd-59da-a89e-b08e575e66a3": 0,
                                "705dc095-654b-5eb2-a806-1abc7edd31a0": 0.5,
                                "61613604-abe9-5fb9-97a6-faa874f7b9cc": 1
                            }
                        }
                    }
                }
            ]
        }
    },
    "alc_ce_dep": {
        "type": "input",
        "signal": {
            "type": "AspectReference",
            "aspectName": "has_c_au_ex_dep"
        }
    },
    "alc_pe_dep": {
        "type": "merge",
        "wires": [
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_c_au_ex_dep"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "has_c_au_ex"
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_p_au_dep"
                }
            }
        ],
        "anyToAll": 1
    },
    "has_c_au_ex": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "au_c_excessive_use"
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "au_c_dependency"
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AspectReference",
                        "aspectName": "AUDIT_score_dep"
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "au_screen_ever"
                }
            }
        ],
        "anyToAll": 1
    },
    "has_c_au_ex_dep": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "au_c_dependency"
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AspectReference",
                            "aspectName": "AUDIT_score_dep"
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "au_c_excessive_use"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "au_screen_ever"
                }
            }
        ],
        "anyToAll": 1
    },
    "has_p_au_dep": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "au_p_dependency"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "au_screen_ever"
                }
            }
        ],
        "anyToAll": 1
    },
    "au_c_excessive_use": {
        "type": "input",
        "signal": {
            "type": "SpecificNumberInput",
            "questionId": "AU-4",
            "input": 14,
            "operator": ">="
        }
    },
    "AUDIT_score_dep": {
        "type": "filter",
        "sigmoid": {
            "middle": 0.5,
            "width": 1
        },
        "wire": {
            "type": "input",
            "signal": {
                "type": "AspectReference",
                "aspectName": "AUDIT"
            }
        }
    },
    "au_c_dependency": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "au_c_control_loss"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "au_c_cut_down_inability"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "au_c_time_spent"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "au_c_craving"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "au_c_role_fulfillment_failure"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "au_c_interpersonal_problems"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "au_c_tolerance"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "au_c_withdrawal"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "au_c_psych_phys_problems"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "au_c_physical_hazard"
                }
            }
        ],
        "anyToAll": 0.2
    },
    "au_c_control_loss": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "AU-5",
            "options": {
                "type": "predicates",
                "predicates": {
                    "faa6a973-d8bd-5b74-bd70-d3791b527341": false,
                    "1dc8a1e4-8441-58c6-a0d6-7201676de0c1": false,
                    "251c8b46-84a4-5031-83a2-94569d2f7d9a": false,
                    "9ac9db10-6204-521a-b600-d7c2cef3fbab": false,
                    "7b22fec7-e9a6-51fa-a0f7-4a08ffd60960": true
                }
            }
        }
    },
    "au_c_cut_down_inability": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "AU-13",
            "options": {
                "type": "predicates",
                "predicates": {
                    "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8": false,
                    "d78a1a3f-f493-555e-9776-41dc70e2f63b": false,
                    "31663734-0b9f-53d0-ac93-ab227b6a43d1": true
                }
            }
        }
    },
    "au_c_time_spent": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "AU-16",
            "options": {
                "type": "predicates",
                "predicates": {
                    "b36eef07-a5d5-5596-9d6c-f86d8267aeee": false,
                    "b9593781-a11a-5660-9bda-a02059817589": false,
                    "8d4c54c5-18d3-5813-9b02-01d220fe06a7": true
                }
            }
        }
    },
    "au_c_craving": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "AU-19",
            "options": {
                "type": "predicates",
                "predicates": {
                    "39d41b2f-b56f-5f1f-a9b1-16200d417b6d": false,
                    "4f7598ed-d960-5a23-9255-544dab80fe9d": false,
                    "0052107c-edfc-5abf-b8e2-22d833c18454": true
                }
            }
        }
    },
    "au_c_role_fulfillment_failure": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "AU-6",
            "options": {
                "type": "predicates",
                "predicates": {
                    "b3eeaf44-2489-51f5-b041-f252d4f87d49": false,
                    "abfc1a2d-bdb1-5553-b79f-f61fff82e345": false,
                    "2c0a41c0-5540-5b5f-9bc9-4e70349b1f0b": false,
                    "59a42152-366d-5508-a888-9629c60e91fc": false,
                    "2d7c790a-161a-5001-b269-b70e430a1459": true
                }
            }
        }
    },
    "au_c_interpersonal_problems": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "AU-12",
            "options": {
                "type": "predicates",
                "predicates": {
                    "44d6a11a-144b-56f5-963f-c6e4e5985cd8": false,
                    "2a74eb35-54b2-5648-8089-4a8c02933778": false,
                    "c2e10e2d-0c13-55ac-a903-16aa35eaecef": true
                }
            }
        }
    },
    "au_c_tolerance": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "AU-14",
            "options": {
                "type": "predicates",
                "predicates": {
                    "111f6379-4749-5adc-828d-88a4f35ba226": false,
                    "74d81b8d-4bdf-55b0-bc70-67b589b78a95": false,
                    "b5bcc60d-e298-5621-8c59-f788672ef828": true
                }
            }
        }
    },
    "au_c_withdrawal": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AU-7",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "d3d01784-987d-5627-a97b-98623a170df9": false,
                            "b6739f38-a943-5c85-aa41-205d88f3fec3": false,
                            "73c56e21-cb1e-5967-b9aa-9afcddc6fd2e": false,
                            "f6ed9e50-beef-54b5-bd8b-f00b3eecd6dd": false,
                            "bc75d147-4107-5d17-8a9f-a0bf805daa5f": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AU-15",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "349b824f-b485-5672-8148-d3783d5b2392": false,
                            "5672e829-525c-5e5b-a8d4-46634023977b": false,
                            "978ea017-853e-52cd-87d5-32f93372bce3": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AU-18",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "c85d230a-988f-5850-a03d-0e1a877650da": false,
                            "f470bbf0-ca10-5e97-9557-3dc05f6bea1d": false,
                            "0b4586e1-706f-5d61-b912-b0934dcb5341": true
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "au_c_psych_phys_problems": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AU-21",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "64d6c3e7-3a72-51f3-b882-1a153eb2d13e": false,
                            "93f9aeb7-77ef-5172-a965-d21a8be006cd": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AU-20",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "16f73e60-f332-50cf-9151-860eb4be2677": false,
                            "da873ed6-7c53-580c-a2fb-ebaa52cef516": false,
                            "bccc777f-2d79-5e66-bd2f-4c723ef88f42": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AU-9",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "026ad3bf-66f3-5834-83e2-be4e4873b633": 0.0066928509242848554,
                            "e551f0fb-d55f-5f69-9728-989451dcf97f": 0.07585818002124355,
                            "9dbc0af9-57ce-5a39-a2f4-10bf717ecfca": 0.5,
                            "2932b119-b5a7-5478-a243-b880d0865081": 0.9241418199787566,
                            "30b1f600-8ea0-57a1-83d5-c5b16fec6340": 0.9933071490757153
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "au_c_physical_hazard": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "AU-10",
            "options": {
                "type": "predicates",
                "predicates": {
                    "9d796df4-e587-5bff-bb07-00d6c098a8f4": false,
                    "f100da78-6b18-5222-8771-521684105f95": false,
                    "a3963f2f-1a9d-5c11-bf4e-e1c7cfdb8cea": true
                }
            }
        }
    },
    "AUDIT": {
        "type": "mean",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AU-1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": 0,
                            "ee458d60-7198-5b41-bd8f-5aaaa78473f0": 0.25,
                            "d5bb65be-f7eb-568b-93cf-fff92621ccb2": 0.5,
                            "b1a86447-08b7-5e7d-a3d5-015e31be0840": 0.75,
                            "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AU-2.1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "86e19e81-1b92-55bc-bdb0-8cf2b062525e": 0,
                            "eea8763e-5c32-5ae4-84d0-91d5de001887": 0.25,
                            "fd2be360-9f0c-51a5-a13f-acebafdf17e8": 0.5,
                            "2214df09-874e-5dae-bdfc-0af7cb9ccc54": 0.75,
                            "28265f82-928c-5c60-be2c-00d6ebfe1285": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AU-3",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "abda3745-2dfc-5d22-b8e2-02e58e0d7983": 0,
                            "adadb641-a888-5f52-8c2d-a33d52f437ff": 0.25,
                            "6835adaf-f7b6-5ec3-9027-f771cabd0b98": 0.5,
                            "f74522b1-9434-5e73-a272-84ca91bd2e84": 0.75,
                            "337596cc-8422-5c6f-8e9d-6b93c0be0208": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AU-5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "faa6a973-d8bd-5b74-bd70-d3791b527341": 0,
                            "1dc8a1e4-8441-58c6-a0d6-7201676de0c1": 0.25,
                            "251c8b46-84a4-5031-83a2-94569d2f7d9a": 0.5,
                            "9ac9db10-6204-521a-b600-d7c2cef3fbab": 0.75,
                            "7b22fec7-e9a6-51fa-a0f7-4a08ffd60960": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AU-6",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "b3eeaf44-2489-51f5-b041-f252d4f87d49": 0,
                            "abfc1a2d-bdb1-5553-b79f-f61fff82e345": 0.25,
                            "2c0a41c0-5540-5b5f-9bc9-4e70349b1f0b": 0.5,
                            "59a42152-366d-5508-a888-9629c60e91fc": 0.75,
                            "2d7c790a-161a-5001-b269-b70e430a1459": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AU-7",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "d3d01784-987d-5627-a97b-98623a170df9": 0,
                            "b6739f38-a943-5c85-aa41-205d88f3fec3": 0.25,
                            "73c56e21-cb1e-5967-b9aa-9afcddc6fd2e": 0.5,
                            "f6ed9e50-beef-54b5-bd8b-f00b3eecd6dd": 0.75,
                            "bc75d147-4107-5d17-8a9f-a0bf805daa5f": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AU-8",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "7cd65629-9089-5fd1-b831-efb2a167e4b0": 0,
                            "fff7d9c5-1abe-5faa-b26e-712c34570801": 0.25,
                            "436aea9d-a160-5e1e-86eb-194137fd2a53": 0.5,
                            "1b63a95c-ea92-58a6-8e8b-54d5bc42d76d": 0.75,
                            "77f82166-32a0-546f-b62a-af0e32e83986": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AU-9",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "026ad3bf-66f3-5834-83e2-be4e4873b633": 0,
                            "e551f0fb-d55f-5f69-9728-989451dcf97f": 0.25,
                            "9dbc0af9-57ce-5a39-a2f4-10bf717ecfca": 0.5,
                            "2932b119-b5a7-5478-a243-b880d0865081": 0.75,
                            "30b1f600-8ea0-57a1-83d5-c5b16fec6340": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AU-10",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "9d796df4-e587-5bff-bb07-00d6c098a8f4": 0,
                            "f100da78-6b18-5222-8771-521684105f95": 0.5,
                            "a3963f2f-1a9d-5c11-bf4e-e1c7cfdb8cea": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AU-11",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "070cc48a-ddfd-59da-a89e-b08e575e66a3": 0,
                            "705dc095-654b-5eb2-a806-1abc7edd31a0": 0.5,
                            "61613604-abe9-5fb9-97a6-faa874f7b9cc": 1
                        }
                    }
                }
            }
        ]
    },
    "au_p_dependency": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "au_p_cut_down_inability"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "au_p_time_spent"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "au_p_craving"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "au_p_interpersonal_problems"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "au_p_tolerance"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "au_p_withdrawal"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "au_p_psych_phys_problems"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "au_p_physical_hazard"
                }
            }
        ],
        "anyToAll": 0.25
    },
    "au_p_cut_down_inability": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "AU-13",
            "options": {
                "type": "predicates",
                "predicates": {
                    "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8": false,
                    "d78a1a3f-f493-555e-9776-41dc70e2f63b": true,
                    "31663734-0b9f-53d0-ac93-ab227b6a43d1": false
                }
            }
        }
    },
    "au_p_time_spent": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "AU-16",
            "options": {
                "type": "predicates",
                "predicates": {
                    "b36eef07-a5d5-5596-9d6c-f86d8267aeee": false,
                    "b9593781-a11a-5660-9bda-a02059817589": true,
                    "8d4c54c5-18d3-5813-9b02-01d220fe06a7": false
                }
            }
        }
    },
    "au_p_craving": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "AU-19",
            "options": {
                "type": "predicates",
                "predicates": {
                    "39d41b2f-b56f-5f1f-a9b1-16200d417b6d": false,
                    "4f7598ed-d960-5a23-9255-544dab80fe9d": true,
                    "0052107c-edfc-5abf-b8e2-22d833c18454": false
                }
            }
        }
    },
    "au_p_interpersonal_problems": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "AU-12",
            "options": {
                "type": "predicates",
                "predicates": {
                    "44d6a11a-144b-56f5-963f-c6e4e5985cd8": false,
                    "2a74eb35-54b2-5648-8089-4a8c02933778": true,
                    "c2e10e2d-0c13-55ac-a903-16aa35eaecef": false
                }
            }
        }
    },
    "au_p_tolerance": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "AU-14",
            "options": {
                "type": "predicates",
                "predicates": {
                    "111f6379-4749-5adc-828d-88a4f35ba226": false,
                    "74d81b8d-4bdf-55b0-bc70-67b589b78a95": true,
                    "b5bcc60d-e298-5621-8c59-f788672ef828": false
                }
            }
        }
    },
    "au_p_withdrawal": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AU-15",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "349b824f-b485-5672-8148-d3783d5b2392": false,
                            "5672e829-525c-5e5b-a8d4-46634023977b": true,
                            "978ea017-853e-52cd-87d5-32f93372bce3": false
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AU-18",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "c85d230a-988f-5850-a03d-0e1a877650da": false,
                            "f470bbf0-ca10-5e97-9557-3dc05f6bea1d": true,
                            "0b4586e1-706f-5d61-b912-b0934dcb5341": false
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "au_p_psych_phys_problems": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AU-21",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "64d6c3e7-3a72-51f3-b882-1a153eb2d13e": false,
                            "93f9aeb7-77ef-5172-a965-d21a8be006cd": false
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AU-20",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "16f73e60-f332-50cf-9151-860eb4be2677": false,
                            "da873ed6-7c53-580c-a2fb-ebaa52cef516": true,
                            "bccc777f-2d79-5e66-bd2f-4c723ef88f42": false
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.5
    },
    "au_p_physical_hazard": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "AU-10",
            "options": {
                "type": "predicates",
                "predicates": {
                    "9d796df4-e587-5bff-bb07-00d6c098a8f4": false,
                    "f100da78-6b18-5222-8771-521684105f95": true,
                    "a3963f2f-1a9d-5c11-bf4e-e1c7cfdb8cea": false
                }
            }
        }
    },
    "au_screen_ever": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "AU-s1",
            "options": {
                "type": "predicates",
                "predicates": {
                    "4b79c726-c8f2-5491-8e82-316ba4cf4288": false,
                    "297fdee2-d461-5ae6-9150-2340c8d2f89d": true
                }
            }
        }
    },
    "not(AU-s1=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "AU-s1",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288": true,
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d": false
                    }
                }
            }
        }
    },
    "or[and[AU-1=0, or[and[AU-2.1=0, or[AU-3=0, AU-3=0.25, AU-3=0.5]], and[AU-2.1=0.25, AU-3=0], and[AU-2.1=0.25, AU-3=0.25], and[AU-2.1=0.5, AU-3=0]]], and[AU-1=0.25, AU-2.1=0, AU-3=0], and[AU-1=0.25, AU-2.1=0, AU-3=0.25], and[AU-1=0.25, AU-2.1=0.25, AU-3=0], and[AU-1=0.5, AU-2.1=0, AU-3=0]]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "AU-1",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": true,
                                    "ee458d60-7198-5b41-bd8f-5aaaa78473f0": false,
                                    "d5bb65be-f7eb-568b-93cf-fff92621ccb2": false,
                                    "b1a86447-08b7-5e7d-a3d5-015e31be0840": false,
                                    "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": false
                                }
                            }
                        }
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "AU-2.1",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "86e19e81-1b92-55bc-bdb0-8cf2b062525e": true,
                                                    "eea8763e-5c32-5ae4-84d0-91d5de001887": false,
                                                    "fd2be360-9f0c-51a5-a13f-acebafdf17e8": false,
                                                    "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                                    "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "merge",
                                        "wires": [
                                            {
                                                "type": "input",
                                                "signal": {
                                                    "type": "AnsweredOption",
                                                    "questionId": "AU-3",
                                                    "options": {
                                                        "type": "predicates",
                                                        "predicates": {
                                                            "abda3745-2dfc-5d22-b8e2-02e58e0d7983": true,
                                                            "adadb641-a888-5f52-8c2d-a33d52f437ff": false,
                                                            "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                                            "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                                            "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                "type": "input",
                                                "signal": {
                                                    "type": "AnsweredOption",
                                                    "questionId": "AU-3",
                                                    "options": {
                                                        "type": "predicates",
                                                        "predicates": {
                                                            "abda3745-2dfc-5d22-b8e2-02e58e0d7983": false,
                                                            "adadb641-a888-5f52-8c2d-a33d52f437ff": true,
                                                            "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                                            "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                                            "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                "type": "input",
                                                "signal": {
                                                    "type": "AnsweredOption",
                                                    "questionId": "AU-3",
                                                    "options": {
                                                        "type": "predicates",
                                                        "predicates": {
                                                            "abda3745-2dfc-5d22-b8e2-02e58e0d7983": false,
                                                            "adadb641-a888-5f52-8c2d-a33d52f437ff": false,
                                                            "6835adaf-f7b6-5ec3-9027-f771cabd0b98": true,
                                                            "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                                            "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                                        }
                                                    }
                                                }
                                            }
                                        ],
                                        "anyToAll": 0.3333333333333333
                                    }
                                ],
                                "anyToAll": 1
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "AU-2.1",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "86e19e81-1b92-55bc-bdb0-8cf2b062525e": false,
                                                    "eea8763e-5c32-5ae4-84d0-91d5de001887": true,
                                                    "fd2be360-9f0c-51a5-a13f-acebafdf17e8": false,
                                                    "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                                    "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "AU-3",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "abda3745-2dfc-5d22-b8e2-02e58e0d7983": true,
                                                    "adadb641-a888-5f52-8c2d-a33d52f437ff": false,
                                                    "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                                    "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                                    "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                                }
                                            }
                                        }
                                    }
                                ],
                                "anyToAll": 1
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "AU-2.1",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "86e19e81-1b92-55bc-bdb0-8cf2b062525e": false,
                                                    "eea8763e-5c32-5ae4-84d0-91d5de001887": true,
                                                    "fd2be360-9f0c-51a5-a13f-acebafdf17e8": false,
                                                    "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                                    "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "AU-3",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "abda3745-2dfc-5d22-b8e2-02e58e0d7983": false,
                                                    "adadb641-a888-5f52-8c2d-a33d52f437ff": true,
                                                    "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                                    "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                                    "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                                }
                                            }
                                        }
                                    }
                                ],
                                "anyToAll": 1
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "AU-2.1",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "86e19e81-1b92-55bc-bdb0-8cf2b062525e": false,
                                                    "eea8763e-5c32-5ae4-84d0-91d5de001887": false,
                                                    "fd2be360-9f0c-51a5-a13f-acebafdf17e8": true,
                                                    "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                                    "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "AU-3",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "abda3745-2dfc-5d22-b8e2-02e58e0d7983": true,
                                                    "adadb641-a888-5f52-8c2d-a33d52f437ff": false,
                                                    "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                                    "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                                    "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                                }
                                            }
                                        }
                                    }
                                ],
                                "anyToAll": 1
                            }
                        ],
                        "anyToAll": 0.25
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "AU-1",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": false,
                                    "ee458d60-7198-5b41-bd8f-5aaaa78473f0": true,
                                    "d5bb65be-f7eb-568b-93cf-fff92621ccb2": false,
                                    "b1a86447-08b7-5e7d-a3d5-015e31be0840": false,
                                    "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": false
                                }
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "AU-2.1",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "86e19e81-1b92-55bc-bdb0-8cf2b062525e": true,
                                    "eea8763e-5c32-5ae4-84d0-91d5de001887": false,
                                    "fd2be360-9f0c-51a5-a13f-acebafdf17e8": false,
                                    "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                    "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                }
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "AU-3",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "abda3745-2dfc-5d22-b8e2-02e58e0d7983": true,
                                    "adadb641-a888-5f52-8c2d-a33d52f437ff": false,
                                    "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                    "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                    "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "AU-1",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": false,
                                    "ee458d60-7198-5b41-bd8f-5aaaa78473f0": true,
                                    "d5bb65be-f7eb-568b-93cf-fff92621ccb2": false,
                                    "b1a86447-08b7-5e7d-a3d5-015e31be0840": false,
                                    "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": false
                                }
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "AU-2.1",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "86e19e81-1b92-55bc-bdb0-8cf2b062525e": true,
                                    "eea8763e-5c32-5ae4-84d0-91d5de001887": false,
                                    "fd2be360-9f0c-51a5-a13f-acebafdf17e8": false,
                                    "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                    "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                }
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "AU-3",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "abda3745-2dfc-5d22-b8e2-02e58e0d7983": false,
                                    "adadb641-a888-5f52-8c2d-a33d52f437ff": true,
                                    "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                    "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                    "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "AU-1",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": false,
                                    "ee458d60-7198-5b41-bd8f-5aaaa78473f0": true,
                                    "d5bb65be-f7eb-568b-93cf-fff92621ccb2": false,
                                    "b1a86447-08b7-5e7d-a3d5-015e31be0840": false,
                                    "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": false
                                }
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "AU-2.1",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "86e19e81-1b92-55bc-bdb0-8cf2b062525e": false,
                                    "eea8763e-5c32-5ae4-84d0-91d5de001887": true,
                                    "fd2be360-9f0c-51a5-a13f-acebafdf17e8": false,
                                    "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                    "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                }
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "AU-3",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "abda3745-2dfc-5d22-b8e2-02e58e0d7983": true,
                                    "adadb641-a888-5f52-8c2d-a33d52f437ff": false,
                                    "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                    "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                    "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "AU-1",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": false,
                                    "ee458d60-7198-5b41-bd8f-5aaaa78473f0": false,
                                    "d5bb65be-f7eb-568b-93cf-fff92621ccb2": true,
                                    "b1a86447-08b7-5e7d-a3d5-015e31be0840": false,
                                    "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": false
                                }
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "AU-2.1",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "86e19e81-1b92-55bc-bdb0-8cf2b062525e": true,
                                    "eea8763e-5c32-5ae4-84d0-91d5de001887": false,
                                    "fd2be360-9f0c-51a5-a13f-acebafdf17e8": false,
                                    "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                    "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                }
                            }
                        }
                    },
                    {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "AU-3",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "abda3745-2dfc-5d22-b8e2-02e58e0d7983": true,
                                    "adadb641-a888-5f52-8c2d-a33d52f437ff": false,
                                    "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                    "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                    "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                }
                            }
                        }
                    }
                ],
                "anyToAll": 1
            }
        ],
        "anyToAll": 0.2
    },
    "and[or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0]), not(and[AU-1=0, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.5]), not(and[AU-1=0, AU-2.1=0, AU-3=0.5])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0.5, AU-3=0]), not(and[AU-1=0, AU-2.1=0.5, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0.25, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0.5, AU-2.1=0, AU-3=0]), not(and[AU-1=0.5, AU-2.1=0, AU-3=0])], not(isUndefined(AU-3=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": true,
                                                "ee458d60-7198-5b41-bd8f-5aaaa78473f0": false,
                                                "d5bb65be-f7eb-568b-93cf-fff92621ccb2": false,
                                                "b1a86447-08b7-5e7d-a3d5-015e31be0840": false,
                                                "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-2.1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "86e19e81-1b92-55bc-bdb0-8cf2b062525e": true,
                                                "eea8763e-5c32-5ae4-84d0-91d5de001887": false,
                                                "fd2be360-9f0c-51a5-a13f-acebafdf17e8": false,
                                                "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                                "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-3",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "abda3745-2dfc-5d22-b8e2-02e58e0d7983": true,
                                                "adadb641-a888-5f52-8c2d-a33d52f437ff": false,
                                                "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                                "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                                "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": true,
                                                "ee458d60-7198-5b41-bd8f-5aaaa78473f0": false,
                                                "d5bb65be-f7eb-568b-93cf-fff92621ccb2": false,
                                                "b1a86447-08b7-5e7d-a3d5-015e31be0840": false,
                                                "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-2.1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "86e19e81-1b92-55bc-bdb0-8cf2b062525e": true,
                                                "eea8763e-5c32-5ae4-84d0-91d5de001887": false,
                                                "fd2be360-9f0c-51a5-a13f-acebafdf17e8": false,
                                                "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                                "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-3",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "abda3745-2dfc-5d22-b8e2-02e58e0d7983": true,
                                                "adadb641-a888-5f52-8c2d-a33d52f437ff": false,
                                                "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                                "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                                "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": true,
                                                "ee458d60-7198-5b41-bd8f-5aaaa78473f0": false,
                                                "d5bb65be-f7eb-568b-93cf-fff92621ccb2": false,
                                                "b1a86447-08b7-5e7d-a3d5-015e31be0840": false,
                                                "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-2.1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "86e19e81-1b92-55bc-bdb0-8cf2b062525e": true,
                                                "eea8763e-5c32-5ae4-84d0-91d5de001887": false,
                                                "fd2be360-9f0c-51a5-a13f-acebafdf17e8": false,
                                                "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                                "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-3",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "abda3745-2dfc-5d22-b8e2-02e58e0d7983": false,
                                                "adadb641-a888-5f52-8c2d-a33d52f437ff": true,
                                                "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                                "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                                "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": true,
                                                "ee458d60-7198-5b41-bd8f-5aaaa78473f0": false,
                                                "d5bb65be-f7eb-568b-93cf-fff92621ccb2": false,
                                                "b1a86447-08b7-5e7d-a3d5-015e31be0840": false,
                                                "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-2.1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "86e19e81-1b92-55bc-bdb0-8cf2b062525e": true,
                                                "eea8763e-5c32-5ae4-84d0-91d5de001887": false,
                                                "fd2be360-9f0c-51a5-a13f-acebafdf17e8": false,
                                                "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                                "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-3",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "abda3745-2dfc-5d22-b8e2-02e58e0d7983": false,
                                                "adadb641-a888-5f52-8c2d-a33d52f437ff": true,
                                                "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                                "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                                "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": true,
                                                "ee458d60-7198-5b41-bd8f-5aaaa78473f0": false,
                                                "d5bb65be-f7eb-568b-93cf-fff92621ccb2": false,
                                                "b1a86447-08b7-5e7d-a3d5-015e31be0840": false,
                                                "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-2.1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "86e19e81-1b92-55bc-bdb0-8cf2b062525e": true,
                                                "eea8763e-5c32-5ae4-84d0-91d5de001887": false,
                                                "fd2be360-9f0c-51a5-a13f-acebafdf17e8": false,
                                                "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                                "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-3",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "abda3745-2dfc-5d22-b8e2-02e58e0d7983": false,
                                                "adadb641-a888-5f52-8c2d-a33d52f437ff": false,
                                                "6835adaf-f7b6-5ec3-9027-f771cabd0b98": true,
                                                "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                                "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": true,
                                                "ee458d60-7198-5b41-bd8f-5aaaa78473f0": false,
                                                "d5bb65be-f7eb-568b-93cf-fff92621ccb2": false,
                                                "b1a86447-08b7-5e7d-a3d5-015e31be0840": false,
                                                "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-2.1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "86e19e81-1b92-55bc-bdb0-8cf2b062525e": true,
                                                "eea8763e-5c32-5ae4-84d0-91d5de001887": false,
                                                "fd2be360-9f0c-51a5-a13f-acebafdf17e8": false,
                                                "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                                "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-3",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "abda3745-2dfc-5d22-b8e2-02e58e0d7983": false,
                                                "adadb641-a888-5f52-8c2d-a33d52f437ff": false,
                                                "6835adaf-f7b6-5ec3-9027-f771cabd0b98": true,
                                                "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                                "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": true,
                                                "ee458d60-7198-5b41-bd8f-5aaaa78473f0": false,
                                                "d5bb65be-f7eb-568b-93cf-fff92621ccb2": false,
                                                "b1a86447-08b7-5e7d-a3d5-015e31be0840": false,
                                                "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-2.1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "86e19e81-1b92-55bc-bdb0-8cf2b062525e": false,
                                                "eea8763e-5c32-5ae4-84d0-91d5de001887": true,
                                                "fd2be360-9f0c-51a5-a13f-acebafdf17e8": false,
                                                "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                                "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-3",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "abda3745-2dfc-5d22-b8e2-02e58e0d7983": true,
                                                "adadb641-a888-5f52-8c2d-a33d52f437ff": false,
                                                "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                                "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                                "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": true,
                                                "ee458d60-7198-5b41-bd8f-5aaaa78473f0": false,
                                                "d5bb65be-f7eb-568b-93cf-fff92621ccb2": false,
                                                "b1a86447-08b7-5e7d-a3d5-015e31be0840": false,
                                                "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-2.1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "86e19e81-1b92-55bc-bdb0-8cf2b062525e": false,
                                                "eea8763e-5c32-5ae4-84d0-91d5de001887": true,
                                                "fd2be360-9f0c-51a5-a13f-acebafdf17e8": false,
                                                "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                                "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-3",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "abda3745-2dfc-5d22-b8e2-02e58e0d7983": true,
                                                "adadb641-a888-5f52-8c2d-a33d52f437ff": false,
                                                "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                                "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                                "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": true,
                                                "ee458d60-7198-5b41-bd8f-5aaaa78473f0": false,
                                                "d5bb65be-f7eb-568b-93cf-fff92621ccb2": false,
                                                "b1a86447-08b7-5e7d-a3d5-015e31be0840": false,
                                                "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-2.1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "86e19e81-1b92-55bc-bdb0-8cf2b062525e": false,
                                                "eea8763e-5c32-5ae4-84d0-91d5de001887": true,
                                                "fd2be360-9f0c-51a5-a13f-acebafdf17e8": false,
                                                "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                                "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-3",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "abda3745-2dfc-5d22-b8e2-02e58e0d7983": false,
                                                "adadb641-a888-5f52-8c2d-a33d52f437ff": true,
                                                "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                                "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                                "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": true,
                                                "ee458d60-7198-5b41-bd8f-5aaaa78473f0": false,
                                                "d5bb65be-f7eb-568b-93cf-fff92621ccb2": false,
                                                "b1a86447-08b7-5e7d-a3d5-015e31be0840": false,
                                                "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-2.1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "86e19e81-1b92-55bc-bdb0-8cf2b062525e": false,
                                                "eea8763e-5c32-5ae4-84d0-91d5de001887": true,
                                                "fd2be360-9f0c-51a5-a13f-acebafdf17e8": false,
                                                "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                                "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-3",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "abda3745-2dfc-5d22-b8e2-02e58e0d7983": false,
                                                "adadb641-a888-5f52-8c2d-a33d52f437ff": true,
                                                "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                                "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                                "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": true,
                                                "ee458d60-7198-5b41-bd8f-5aaaa78473f0": false,
                                                "d5bb65be-f7eb-568b-93cf-fff92621ccb2": false,
                                                "b1a86447-08b7-5e7d-a3d5-015e31be0840": false,
                                                "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-2.1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "86e19e81-1b92-55bc-bdb0-8cf2b062525e": false,
                                                "eea8763e-5c32-5ae4-84d0-91d5de001887": false,
                                                "fd2be360-9f0c-51a5-a13f-acebafdf17e8": true,
                                                "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                                "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-3",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "abda3745-2dfc-5d22-b8e2-02e58e0d7983": true,
                                                "adadb641-a888-5f52-8c2d-a33d52f437ff": false,
                                                "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                                "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                                "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": true,
                                                "ee458d60-7198-5b41-bd8f-5aaaa78473f0": false,
                                                "d5bb65be-f7eb-568b-93cf-fff92621ccb2": false,
                                                "b1a86447-08b7-5e7d-a3d5-015e31be0840": false,
                                                "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-2.1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "86e19e81-1b92-55bc-bdb0-8cf2b062525e": false,
                                                "eea8763e-5c32-5ae4-84d0-91d5de001887": false,
                                                "fd2be360-9f0c-51a5-a13f-acebafdf17e8": true,
                                                "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                                "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-3",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "abda3745-2dfc-5d22-b8e2-02e58e0d7983": true,
                                                "adadb641-a888-5f52-8c2d-a33d52f437ff": false,
                                                "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                                "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                                "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": false,
                                                "ee458d60-7198-5b41-bd8f-5aaaa78473f0": true,
                                                "d5bb65be-f7eb-568b-93cf-fff92621ccb2": false,
                                                "b1a86447-08b7-5e7d-a3d5-015e31be0840": false,
                                                "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-2.1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "86e19e81-1b92-55bc-bdb0-8cf2b062525e": true,
                                                "eea8763e-5c32-5ae4-84d0-91d5de001887": false,
                                                "fd2be360-9f0c-51a5-a13f-acebafdf17e8": false,
                                                "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                                "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-3",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "abda3745-2dfc-5d22-b8e2-02e58e0d7983": true,
                                                "adadb641-a888-5f52-8c2d-a33d52f437ff": false,
                                                "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                                "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                                "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": false,
                                                "ee458d60-7198-5b41-bd8f-5aaaa78473f0": true,
                                                "d5bb65be-f7eb-568b-93cf-fff92621ccb2": false,
                                                "b1a86447-08b7-5e7d-a3d5-015e31be0840": false,
                                                "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-2.1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "86e19e81-1b92-55bc-bdb0-8cf2b062525e": true,
                                                "eea8763e-5c32-5ae4-84d0-91d5de001887": false,
                                                "fd2be360-9f0c-51a5-a13f-acebafdf17e8": false,
                                                "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                                "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-3",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "abda3745-2dfc-5d22-b8e2-02e58e0d7983": true,
                                                "adadb641-a888-5f52-8c2d-a33d52f437ff": false,
                                                "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                                "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                                "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": false,
                                                "ee458d60-7198-5b41-bd8f-5aaaa78473f0": true,
                                                "d5bb65be-f7eb-568b-93cf-fff92621ccb2": false,
                                                "b1a86447-08b7-5e7d-a3d5-015e31be0840": false,
                                                "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-2.1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "86e19e81-1b92-55bc-bdb0-8cf2b062525e": true,
                                                "eea8763e-5c32-5ae4-84d0-91d5de001887": false,
                                                "fd2be360-9f0c-51a5-a13f-acebafdf17e8": false,
                                                "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                                "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-3",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "abda3745-2dfc-5d22-b8e2-02e58e0d7983": false,
                                                "adadb641-a888-5f52-8c2d-a33d52f437ff": true,
                                                "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                                "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                                "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": false,
                                                "ee458d60-7198-5b41-bd8f-5aaaa78473f0": true,
                                                "d5bb65be-f7eb-568b-93cf-fff92621ccb2": false,
                                                "b1a86447-08b7-5e7d-a3d5-015e31be0840": false,
                                                "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-2.1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "86e19e81-1b92-55bc-bdb0-8cf2b062525e": true,
                                                "eea8763e-5c32-5ae4-84d0-91d5de001887": false,
                                                "fd2be360-9f0c-51a5-a13f-acebafdf17e8": false,
                                                "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                                "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-3",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "abda3745-2dfc-5d22-b8e2-02e58e0d7983": false,
                                                "adadb641-a888-5f52-8c2d-a33d52f437ff": true,
                                                "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                                "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                                "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": false,
                                                "ee458d60-7198-5b41-bd8f-5aaaa78473f0": true,
                                                "d5bb65be-f7eb-568b-93cf-fff92621ccb2": false,
                                                "b1a86447-08b7-5e7d-a3d5-015e31be0840": false,
                                                "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-2.1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "86e19e81-1b92-55bc-bdb0-8cf2b062525e": false,
                                                "eea8763e-5c32-5ae4-84d0-91d5de001887": true,
                                                "fd2be360-9f0c-51a5-a13f-acebafdf17e8": false,
                                                "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                                "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-3",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "abda3745-2dfc-5d22-b8e2-02e58e0d7983": true,
                                                "adadb641-a888-5f52-8c2d-a33d52f437ff": false,
                                                "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                                "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                                "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": false,
                                                "ee458d60-7198-5b41-bd8f-5aaaa78473f0": true,
                                                "d5bb65be-f7eb-568b-93cf-fff92621ccb2": false,
                                                "b1a86447-08b7-5e7d-a3d5-015e31be0840": false,
                                                "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-2.1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "86e19e81-1b92-55bc-bdb0-8cf2b062525e": false,
                                                "eea8763e-5c32-5ae4-84d0-91d5de001887": true,
                                                "fd2be360-9f0c-51a5-a13f-acebafdf17e8": false,
                                                "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                                "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-3",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "abda3745-2dfc-5d22-b8e2-02e58e0d7983": true,
                                                "adadb641-a888-5f52-8c2d-a33d52f437ff": false,
                                                "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                                "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                                "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": false,
                                                "ee458d60-7198-5b41-bd8f-5aaaa78473f0": false,
                                                "d5bb65be-f7eb-568b-93cf-fff92621ccb2": true,
                                                "b1a86447-08b7-5e7d-a3d5-015e31be0840": false,
                                                "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-2.1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "86e19e81-1b92-55bc-bdb0-8cf2b062525e": true,
                                                "eea8763e-5c32-5ae4-84d0-91d5de001887": false,
                                                "fd2be360-9f0c-51a5-a13f-acebafdf17e8": false,
                                                "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                                "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-3",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "abda3745-2dfc-5d22-b8e2-02e58e0d7983": true,
                                                "adadb641-a888-5f52-8c2d-a33d52f437ff": false,
                                                "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                                "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                                "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "merge",
                            "wires": [
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "8dec39a1-5dfe-53fe-99d1-752e776bbcbc": false,
                                                "ee458d60-7198-5b41-bd8f-5aaaa78473f0": false,
                                                "d5bb65be-f7eb-568b-93cf-fff92621ccb2": true,
                                                "b1a86447-08b7-5e7d-a3d5-015e31be0840": false,
                                                "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-2.1",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "86e19e81-1b92-55bc-bdb0-8cf2b062525e": true,
                                                "eea8763e-5c32-5ae4-84d0-91d5de001887": false,
                                                "fd2be360-9f0c-51a5-a13f-acebafdf17e8": false,
                                                "2214df09-874e-5dae-bdfc-0af7cb9ccc54": false,
                                                "28265f82-928c-5c60-be2c-00d6ebfe1285": false
                                            }
                                        }
                                    }
                                },
                                {
                                    "type": "input",
                                    "signal": {
                                        "type": "AnsweredOption",
                                        "questionId": "AU-3",
                                        "options": {
                                            "type": "predicates",
                                            "predicates": {
                                                "abda3745-2dfc-5d22-b8e2-02e58e0d7983": true,
                                                "adadb641-a888-5f52-8c2d-a33d52f437ff": false,
                                                "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                                "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                                "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                            }
                                        }
                                    }
                                }
                            ],
                            "anyToAll": 1
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "AU-3",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "abda3745-2dfc-5d22-b8e2-02e58e0d7983": true,
                                    "adadb641-a888-5f52-8c2d-a33d52f437ff": false,
                                    "6835adaf-f7b6-5ec3-9027-f771cabd0b98": false,
                                    "f74522b1-9434-5e73-a272-84ca91bd2e84": false,
                                    "337596cc-8422-5c6f-8e9d-6b93c0be0208": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "or[AU-3.5=1, and[or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0]), not(and[AU-1=0, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.5]), not(and[AU-1=0, AU-2.1=0, AU-3=0.5])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0.5, AU-3=0]), not(and[AU-1=0, AU-2.1=0.5, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0.25, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0.5, AU-2.1=0, AU-3=0]), not(and[AU-1=0.5, AU-2.1=0, AU-3=0])], not(isUndefined(AU-3=0))]]": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "AU-3.5",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "43ed898e-f5c2-5acf-a887-a7a4a4fb1708": false,
                            "c72c8340-6d05-5771-84b1-dbb83f231123": true
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "and[or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0]), not(and[AU-1=0, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.5]), not(and[AU-1=0, AU-2.1=0, AU-3=0.5])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0.5, AU-3=0]), not(and[AU-1=0, AU-2.1=0.5, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0.25, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0.5, AU-2.1=0, AU-3=0]), not(and[AU-1=0.5, AU-2.1=0, AU-3=0])], not(isUndefined(AU-3=0))]"
                }
            }
        ],
        "anyToAll": 0.5
    }
};
exports.tests = {
    "alc_ce": [
        {
            "name": "screened user: Z3RPNEO aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "f470bbf0-ca10-5e97-9557-3dc05f6bea1d"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 4
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "251c8b46-84a4-5031-83a2-94569d2f7d9a"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "b6739f38-a943-5c85-aa41-205d88f3fec3"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: NEL6L3V aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: K39ZL3L aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from TRUE, Censeo vPrevious user: 6PE670L aspect: alc_ce",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 14
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MRME6 aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from FALSE, Censeo vPrevious user: O3PMG3W aspect: alc_ce",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "61613604-abe9-5fb9-97a6-faa874f7b9cc"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "c2e10e2d-0c13-55ac-a903-16aa35eaecef"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "ddb8ca69-afbe-580f-b616-aa5987f59dc3"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "2214df09-874e-5dae-bdfc-0af7cb9ccc54"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 15
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "251c8b46-84a4-5031-83a2-94569d2f7d9a"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "abfc1a2d-bdb1-5553-b79f-f61fff82e345"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "e551f0fb-d55f-5f69-9728-989451dcf97f"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32D838 aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 4
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7OXEL aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YX60R aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10QV8EP aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0KG5E1 aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from FALSE, Censeo vPrevious user: 6ED9M0M aspect: alc_ce",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 30
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from FALSE, Censeo vPrevious user: Z3R2NEO aspect: alc_ce",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "a3963f2f-1a9d-5c11-bf4e-e1c7cfdb8cea"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "61613604-abe9-5fb9-97a6-faa874f7b9cc"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "c2e10e2d-0c13-55ac-a903-16aa35eaecef"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "b5bcc60d-e298-5621-8c59-f788672ef828"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "8d4c54c5-18d3-5813-9b02-01d220fe06a7"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "bccc777f-2d79-5e66-bd2f-4c723ef88f42"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 180
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "251c8b46-84a4-5031-83a2-94569d2f7d9a"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "2c0a41c0-5540-5b5f-9bc9-4e70349b1f0b"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "1b63a95c-ea92-58a6-8e8b-54d5bc42d76d"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "9dbc0af9-57ce-5a39-a2f4-10bf717ecfca"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: NELRPEV aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 6
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "e551f0fb-d55f-5f69-9728-989451dcf97f"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZQN3R aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: REO9232 aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0KZ701 aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N6P0Q aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E148ED aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8G2EW aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 12
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6LM0L aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: K39ML0L aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7VX0L aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3RD4EO aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGQQ0P aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EW2K38 aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YR40R aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y329ME8 aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VZ63L aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZJN0R aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from TRUE, Censeo vPrevious user: R042O34 aspect: alc_ce",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "2214df09-874e-5dae-bdfc-0af7cb9ccc54"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 18
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VRYEL aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZNN3R aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b9593781-a11a-5660-9bda-a02059817589"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8D2EW aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 8
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "251c8b46-84a4-5031-83a2-94569d2f7d9a"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NN50Q aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73Z220R aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from TRUE, Censeo vPrevious user: N0N4J3Q aspect: alc_ce",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "b5bcc60d-e298-5621-8c59-f788672ef828"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "337596cc-8422-5c6f-8e9d-6b93c0be0208"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 45
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10QKZ0P aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7ZR3L aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N5PEQ aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from FALSE, Censeo vPrevious user: 23XKV0N aspect: alc_ce",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "b5bcc60d-e298-5621-8c59-f788672ef828"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "337596cc-8422-5c6f-8e9d-6b93c0be0208"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 30
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "7b22fec7-e9a6-51fa-a0f7-4a08ffd60960"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YD63R aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from FALSE, Censeo vPrevious user: ME8PLEW aspect: alc_ce",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "61613604-abe9-5fb9-97a6-faa874f7b9cc"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "c2e10e2d-0c13-55ac-a903-16aa35eaecef"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "b5bcc60d-e298-5621-8c59-f788672ef828"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 14
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "abfc1a2d-bdb1-5553-b79f-f61fff82e345"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "1b63a95c-ea92-58a6-8e8b-54d5bc42d76d"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "e551f0fb-d55f-5f69-9728-989451dcf97f"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: K39V10L aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0K1931 aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 12
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3J1D0Y aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 6
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "abfc1a2d-bdb1-5553-b79f-f61fff82e345"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "e551f0fb-d55f-5f69-9728-989451dcf97f"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10QJ8EP aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 6
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from FALSE, Censeo vPrevious user: 73ZL2ER aspect: alc_ce",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b9593781-a11a-5660-9bda-a02059817589"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 20
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "9ac9db10-6204-521a-b600-d7c2cef3fbab"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "59a42152-366d-5508-a888-9629c60e91fc"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "1b63a95c-ea92-58a6-8e8b-54d5bc42d76d"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "2932b119-b5a7-5478-a243-b880d0865081"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3P66EW aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "b5bcc60d-e298-5621-8c59-f788672ef828"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 0
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PGG3W aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32OM08 aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b9593781-a11a-5660-9bda-a02059817589"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME84L3W aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NJ5EQ aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 12
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NO53Q aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: K392M0L aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6EDJMEM aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 6
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10Q6R0P aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0KPDE1 aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YPO3R aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23X6V0N aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MQK36 aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 5
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from FALSE, Censeo vPrevious user: N0N2PEQ aspect: alc_ce",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "61613604-abe9-5fb9-97a6-faa874f7b9cc"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "c2e10e2d-0c13-55ac-a903-16aa35eaecef"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "b5bcc60d-e298-5621-8c59-f788672ef828"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "5672e829-525c-5e5b-a8d4-46634023977b"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "8d4c54c5-18d3-5813-9b02-01d220fe06a7"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "f470bbf0-ca10-5e97-9557-3dc05f6bea1d"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "2214df09-874e-5dae-bdfc-0af7cb9ccc54"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "bccc777f-2d79-5e66-bd2f-4c723ef88f42"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 8
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "abfc1a2d-bdb1-5553-b79f-f61fff82e345"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "b6739f38-a943-5c85-aa41-205d88f3fec3"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "436aea9d-a160-5e1e-86eb-194137fd2a53"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "9dbc0af9-57ce-5a39-a2f4-10bf717ecfca"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JO40Y aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGGGEP aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E1JL0D aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "5672e829-525c-5e5b-a8d4-46634023977b"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: R04JV34 aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from TRUE, Censeo vPrevious user: 7EWGK38 aspect: alc_ce",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 20
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23X4V3N aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35X10O aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0K9D01 aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 5
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from FALSE, Censeo vPrevious user: 23XP2EN aspect: alc_ce",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "61613604-abe9-5fb9-97a6-faa874f7b9cc"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "c2e10e2d-0c13-55ac-a903-16aa35eaecef"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "b5bcc60d-e298-5621-8c59-f788672ef828"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "8d4c54c5-18d3-5813-9b02-01d220fe06a7"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "bccc777f-2d79-5e66-bd2f-4c723ef88f42"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "337596cc-8422-5c6f-8e9d-6b93c0be0208"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 100
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "7b22fec7-e9a6-51fa-a0f7-4a08ffd60960"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "59a42152-366d-5508-a888-9629c60e91fc"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "1b63a95c-ea92-58a6-8e8b-54d5bc42d76d"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "9dbc0af9-57ce-5a39-a2f4-10bf717ecfca"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGLK3P aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: NEL9J3V aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE694EL aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: K399M3L aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "c2e10e2d-0c13-55ac-a903-16aa35eaecef"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 8
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MGW06 aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VGM0L aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32QME8 aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3P770W aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from FALSE, Censeo vPrevious user: 7EWZKE8 aspect: alc_ce",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "61613604-abe9-5fb9-97a6-faa874f7b9cc"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "5672e829-525c-5e5b-a8d4-46634023977b"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b9593781-a11a-5660-9bda-a02059817589"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 15
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "59a42152-366d-5508-a888-9629c60e91fc"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "77f82166-32a0-546f-b62a-af0e32e83986"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "30b1f600-8ea0-57a1-83d5-c5b16fec6340"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6NMEL aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z356NEO aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "5672e829-525c-5e5b-a8d4-46634023977b"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b9593781-a11a-5660-9bda-a02059817589"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "f470bbf0-ca10-5e97-9557-3dc05f6bea1d"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XV2EN aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R443O aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "2214df09-874e-5dae-bdfc-0af7cb9ccc54"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 16
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "436aea9d-a160-5e1e-86eb-194137fd2a53"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "e551f0fb-d55f-5f69-9728-989451dcf97f"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6Q4EL aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 3
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6EDW60M aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JV43Y aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6GM3L aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63Y843R aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NQJ0Q aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 3
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "251c8b46-84a4-5031-83a2-94569d2f7d9a"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "436aea9d-a160-5e1e-86eb-194137fd2a53"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7DM0L aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XX43N aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32LM08 aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3J4P0Y aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E1580D aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from TRUE, Censeo vPrevious user: O3PX60W aspect: alc_ce",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "2214df09-874e-5dae-bdfc-0af7cb9ccc54"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 24
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: REOQG32 aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MDW06 aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EW6KE8 aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VQM3L aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGRG3P aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35L10O aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YM43R aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 6
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y325838 aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE72M0L aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8MOEW aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50M6136 aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 6
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35MQ3O aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZW63R aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EG443P aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: REOG402 aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b9593781-a11a-5660-9bda-a02059817589"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 3
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted screen from TRUE, Censeo vPrevious user: J0V6N3L aspect: alc_ce",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b9593781-a11a-5660-9bda-a02059817589"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "2214df09-874e-5dae-bdfc-0af7cb9ccc54"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 35
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R6O0O aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R680O aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: R047J04 aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "2214df09-874e-5dae-bdfc-0af7cb9ccc54"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 8
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "e551f0fb-d55f-5f69-9728-989451dcf97f"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PR70W aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35PN3O aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME87V3W aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E17NED aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE61J0L aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MVM06 aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 5
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "e551f0fb-d55f-5f69-9728-989451dcf97f"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JK4EY aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EW4D08 aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "61613604-abe9-5fb9-97a6-faa874f7b9cc"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "b5bcc60d-e298-5621-8c59-f788672ef828"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 10
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3RLO3O aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 3
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JZREY aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7WX3L aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "337596cc-8422-5c6f-8e9d-6b93c0be0208"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE64M0L aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3P263W aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 3
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "e551f0fb-d55f-5f69-9728-989451dcf97f"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: NELXPEV aspect: alc_ce should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 6
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        }
    ],
    "alc_ce_dep": [
        {
            "name": "screened user: Z3RPNEO aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "f470bbf0-ca10-5e97-9557-3dc05f6bea1d"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 4
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "251c8b46-84a4-5031-83a2-94569d2f7d9a"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "b6739f38-a943-5c85-aa41-205d88f3fec3"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: NEL6L3V aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: K39ZL3L aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6PE670L aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 14
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MRME6 aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PMG3W aspect: alc_ce_dep should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "61613604-abe9-5fb9-97a6-faa874f7b9cc"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "c2e10e2d-0c13-55ac-a903-16aa35eaecef"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "ddb8ca69-afbe-580f-b616-aa5987f59dc3"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "2214df09-874e-5dae-bdfc-0af7cb9ccc54"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 15
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "251c8b46-84a4-5031-83a2-94569d2f7d9a"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "abfc1a2d-bdb1-5553-b79f-f61fff82e345"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "e551f0fb-d55f-5f69-9728-989451dcf97f"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32D838 aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 4
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7OXEL aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YX60R aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10QV8EP aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6EDLX3M aspect: alc_ce_dep should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "337596cc-8422-5c6f-8e9d-6b93c0be0208"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 48
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "abfc1a2d-bdb1-5553-b79f-f61fff82e345"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "e551f0fb-d55f-5f69-9728-989451dcf97f"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0KG5E1 aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6ED9M0M aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 30
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R2NEO aspect: alc_ce_dep should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "a3963f2f-1a9d-5c11-bf4e-e1c7cfdb8cea"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "61613604-abe9-5fb9-97a6-faa874f7b9cc"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "c2e10e2d-0c13-55ac-a903-16aa35eaecef"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "b5bcc60d-e298-5621-8c59-f788672ef828"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "8d4c54c5-18d3-5813-9b02-01d220fe06a7"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "bccc777f-2d79-5e66-bd2f-4c723ef88f42"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 180
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "251c8b46-84a4-5031-83a2-94569d2f7d9a"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "2c0a41c0-5540-5b5f-9bc9-4e70349b1f0b"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "1b63a95c-ea92-58a6-8e8b-54d5bc42d76d"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "9dbc0af9-57ce-5a39-a2f4-10bf717ecfca"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: NELRPEV aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 6
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "e551f0fb-d55f-5f69-9728-989451dcf97f"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZQN3R aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: REO9232 aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0KZ701 aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N6P0Q aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E148ED aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8G2EW aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 12
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6LM0L aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: K39ML0L aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7VX0L aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3RD4EO aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGQQ0P aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EW2K38 aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YR40R aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y329ME8 aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VZ63L aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZJN0R aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: R042O34 aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "2214df09-874e-5dae-bdfc-0af7cb9ccc54"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 18
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VRYEL aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZNN3R aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b9593781-a11a-5660-9bda-a02059817589"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8D2EW aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 8
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "251c8b46-84a4-5031-83a2-94569d2f7d9a"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NN50Q aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73Z220R aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N4J3Q aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "b5bcc60d-e298-5621-8c59-f788672ef828"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "337596cc-8422-5c6f-8e9d-6b93c0be0208"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 45
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10QKZ0P aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7ZR3L aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N5PEQ aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XKV0N aspect: alc_ce_dep should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "b5bcc60d-e298-5621-8c59-f788672ef828"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "337596cc-8422-5c6f-8e9d-6b93c0be0208"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 30
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "7b22fec7-e9a6-51fa-a0f7-4a08ffd60960"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YD63R aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8PLEW aspect: alc_ce_dep should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "61613604-abe9-5fb9-97a6-faa874f7b9cc"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "c2e10e2d-0c13-55ac-a903-16aa35eaecef"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "b5bcc60d-e298-5621-8c59-f788672ef828"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 14
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "abfc1a2d-bdb1-5553-b79f-f61fff82e345"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "1b63a95c-ea92-58a6-8e8b-54d5bc42d76d"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "e551f0fb-d55f-5f69-9728-989451dcf97f"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: K39V10L aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0K1931 aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 12
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3J1D0Y aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 6
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "abfc1a2d-bdb1-5553-b79f-f61fff82e345"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "e551f0fb-d55f-5f69-9728-989451dcf97f"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10QJ8EP aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 6
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZL2ER aspect: alc_ce_dep should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b9593781-a11a-5660-9bda-a02059817589"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 20
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "9ac9db10-6204-521a-b600-d7c2cef3fbab"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "59a42152-366d-5508-a888-9629c60e91fc"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "1b63a95c-ea92-58a6-8e8b-54d5bc42d76d"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "2932b119-b5a7-5478-a243-b880d0865081"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3P66EW aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "b5bcc60d-e298-5621-8c59-f788672ef828"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 0
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PGG3W aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32OM08 aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b9593781-a11a-5660-9bda-a02059817589"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME84L3W aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NJ5EQ aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 12
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NO53Q aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: K392M0L aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6EDJMEM aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 6
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10Q6R0P aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0KPDE1 aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YPO3R aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23X6V0N aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MQK36 aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 5
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N2PEQ aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "61613604-abe9-5fb9-97a6-faa874f7b9cc"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "c2e10e2d-0c13-55ac-a903-16aa35eaecef"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "b5bcc60d-e298-5621-8c59-f788672ef828"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "5672e829-525c-5e5b-a8d4-46634023977b"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "8d4c54c5-18d3-5813-9b02-01d220fe06a7"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "f470bbf0-ca10-5e97-9557-3dc05f6bea1d"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "2214df09-874e-5dae-bdfc-0af7cb9ccc54"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "bccc777f-2d79-5e66-bd2f-4c723ef88f42"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 8
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "abfc1a2d-bdb1-5553-b79f-f61fff82e345"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "b6739f38-a943-5c85-aa41-205d88f3fec3"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "436aea9d-a160-5e1e-86eb-194137fd2a53"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "9dbc0af9-57ce-5a39-a2f4-10bf717ecfca"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JO40Y aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGGGEP aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E1JL0D aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "5672e829-525c-5e5b-a8d4-46634023977b"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: R04JV34 aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EWGK38 aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 20
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23X4V3N aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35X10O aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0K9D01 aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 5
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XP2EN aspect: alc_ce_dep should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "61613604-abe9-5fb9-97a6-faa874f7b9cc"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "c2e10e2d-0c13-55ac-a903-16aa35eaecef"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "b5bcc60d-e298-5621-8c59-f788672ef828"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "8d4c54c5-18d3-5813-9b02-01d220fe06a7"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "bccc777f-2d79-5e66-bd2f-4c723ef88f42"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "337596cc-8422-5c6f-8e9d-6b93c0be0208"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 100
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "7b22fec7-e9a6-51fa-a0f7-4a08ffd60960"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "59a42152-366d-5508-a888-9629c60e91fc"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "1b63a95c-ea92-58a6-8e8b-54d5bc42d76d"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "9dbc0af9-57ce-5a39-a2f4-10bf717ecfca"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGLK3P aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: NEL9J3V aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE694EL aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: K399M3L aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "c2e10e2d-0c13-55ac-a903-16aa35eaecef"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 8
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MGW06 aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VGM0L aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32QME8 aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3P770W aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EWZKE8 aspect: alc_ce_dep should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "61613604-abe9-5fb9-97a6-faa874f7b9cc"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "5672e829-525c-5e5b-a8d4-46634023977b"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b9593781-a11a-5660-9bda-a02059817589"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 15
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "59a42152-366d-5508-a888-9629c60e91fc"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "77f82166-32a0-546f-b62a-af0e32e83986"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "30b1f600-8ea0-57a1-83d5-c5b16fec6340"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6NMEL aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z356NEO aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "5672e829-525c-5e5b-a8d4-46634023977b"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b9593781-a11a-5660-9bda-a02059817589"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "f470bbf0-ca10-5e97-9557-3dc05f6bea1d"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XV2EN aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R443O aspect: alc_ce_dep should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "2214df09-874e-5dae-bdfc-0af7cb9ccc54"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 16
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "436aea9d-a160-5e1e-86eb-194137fd2a53"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "e551f0fb-d55f-5f69-9728-989451dcf97f"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6Q4EL aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 3
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6EDW60M aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JV43Y aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6GM3L aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63Y843R aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NQJ0Q aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 3
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "251c8b46-84a4-5031-83a2-94569d2f7d9a"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "436aea9d-a160-5e1e-86eb-194137fd2a53"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7DM0L aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XX43N aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32LM08 aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3J4P0Y aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E1580D aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PX60W aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "2214df09-874e-5dae-bdfc-0af7cb9ccc54"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 24
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: REOQG32 aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MDW06 aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EW6KE8 aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VQM3L aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGRG3P aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35L10O aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YM43R aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 6
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y325838 aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE72M0L aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8MOEW aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50M6136 aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 6
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35MQ3O aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZW63R aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EG443P aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: REOG402 aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b9593781-a11a-5660-9bda-a02059817589"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 3
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0V6N3L aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b9593781-a11a-5660-9bda-a02059817589"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "2214df09-874e-5dae-bdfc-0af7cb9ccc54"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 35
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R6O0O aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R680O aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: R047J04 aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "2214df09-874e-5dae-bdfc-0af7cb9ccc54"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 8
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "e551f0fb-d55f-5f69-9728-989451dcf97f"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PR70W aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35PN3O aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME87V3W aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E17NED aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE61J0L aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MVM06 aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 5
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "e551f0fb-d55f-5f69-9728-989451dcf97f"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JK4EY aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EW4D08 aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "61613604-abe9-5fb9-97a6-faa874f7b9cc"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "b5bcc60d-e298-5621-8c59-f788672ef828"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 10
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3RLO3O aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 3
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JZREY aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7WX3L aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "337596cc-8422-5c6f-8e9d-6b93c0be0208"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE64M0L aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3P263W aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 3
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "e551f0fb-d55f-5f69-9728-989451dcf97f"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: NELXPEV aspect: alc_ce_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 6
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        }
    ],
    "alc_pe_dep": [
        {
            "name": "screened user: Z3RPNEO aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "f470bbf0-ca10-5e97-9557-3dc05f6bea1d"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 4
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "251c8b46-84a4-5031-83a2-94569d2f7d9a"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "b6739f38-a943-5c85-aa41-205d88f3fec3"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: NEL6L3V aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: K39ZL3L aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6PE670L aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 14
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MRME6 aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PMG3W aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "61613604-abe9-5fb9-97a6-faa874f7b9cc"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "c2e10e2d-0c13-55ac-a903-16aa35eaecef"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "ddb8ca69-afbe-580f-b616-aa5987f59dc3"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "2214df09-874e-5dae-bdfc-0af7cb9ccc54"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 15
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "251c8b46-84a4-5031-83a2-94569d2f7d9a"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "abfc1a2d-bdb1-5553-b79f-f61fff82e345"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "e551f0fb-d55f-5f69-9728-989451dcf97f"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32D838 aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 4
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7OXEL aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YX60R aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10QV8EP aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6EDLX3M aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "337596cc-8422-5c6f-8e9d-6b93c0be0208"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 48
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "abfc1a2d-bdb1-5553-b79f-f61fff82e345"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "e551f0fb-d55f-5f69-9728-989451dcf97f"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0KG5E1 aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6ED9M0M aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 30
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R2NEO aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "a3963f2f-1a9d-5c11-bf4e-e1c7cfdb8cea"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "61613604-abe9-5fb9-97a6-faa874f7b9cc"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "c2e10e2d-0c13-55ac-a903-16aa35eaecef"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "b5bcc60d-e298-5621-8c59-f788672ef828"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "8d4c54c5-18d3-5813-9b02-01d220fe06a7"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "bccc777f-2d79-5e66-bd2f-4c723ef88f42"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 180
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "251c8b46-84a4-5031-83a2-94569d2f7d9a"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "2c0a41c0-5540-5b5f-9bc9-4e70349b1f0b"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "1b63a95c-ea92-58a6-8e8b-54d5bc42d76d"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "9dbc0af9-57ce-5a39-a2f4-10bf717ecfca"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: NELRPEV aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 6
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "e551f0fb-d55f-5f69-9728-989451dcf97f"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZQN3R aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: REO9232 aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0KZ701 aspect: alc_pe_dep should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N6P0Q aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E148ED aspect: alc_pe_dep should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8G2EW aspect: alc_pe_dep should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 12
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6LM0L aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: K39ML0L aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7VX0L aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3RD4EO aspect: alc_pe_dep should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGQQ0P aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EW2K38 aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YR40R aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y329ME8 aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VZ63L aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZJN0R aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: R042O34 aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "2214df09-874e-5dae-bdfc-0af7cb9ccc54"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 18
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VRYEL aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZNN3R aspect: alc_pe_dep should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b9593781-a11a-5660-9bda-a02059817589"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8D2EW aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 8
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "251c8b46-84a4-5031-83a2-94569d2f7d9a"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NN50Q aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73Z220R aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N4J3Q aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "b5bcc60d-e298-5621-8c59-f788672ef828"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "337596cc-8422-5c6f-8e9d-6b93c0be0208"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 45
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10QKZ0P aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7ZR3L aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N5PEQ aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XKV0N aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "b5bcc60d-e298-5621-8c59-f788672ef828"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "337596cc-8422-5c6f-8e9d-6b93c0be0208"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 30
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "7b22fec7-e9a6-51fa-a0f7-4a08ffd60960"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YD63R aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8PLEW aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "61613604-abe9-5fb9-97a6-faa874f7b9cc"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "c2e10e2d-0c13-55ac-a903-16aa35eaecef"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "b5bcc60d-e298-5621-8c59-f788672ef828"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 14
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "abfc1a2d-bdb1-5553-b79f-f61fff82e345"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "1b63a95c-ea92-58a6-8e8b-54d5bc42d76d"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "e551f0fb-d55f-5f69-9728-989451dcf97f"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: K39V10L aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0K1931 aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 12
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3J1D0Y aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 6
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "abfc1a2d-bdb1-5553-b79f-f61fff82e345"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "e551f0fb-d55f-5f69-9728-989451dcf97f"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10QJ8EP aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 6
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZL2ER aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b9593781-a11a-5660-9bda-a02059817589"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 20
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "9ac9db10-6204-521a-b600-d7c2cef3fbab"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "59a42152-366d-5508-a888-9629c60e91fc"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "1b63a95c-ea92-58a6-8e8b-54d5bc42d76d"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "2932b119-b5a7-5478-a243-b880d0865081"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3P66EW aspect: alc_pe_dep should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "b5bcc60d-e298-5621-8c59-f788672ef828"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 0
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PGG3W aspect: alc_pe_dep should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32OM08 aspect: alc_pe_dep should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b9593781-a11a-5660-9bda-a02059817589"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME84L3W aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NJ5EQ aspect: alc_pe_dep should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 12
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NO53Q aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: K392M0L aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6EDJMEM aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 6
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 10Q6R0P aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0KPDE1 aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YPO3R aspect: alc_pe_dep should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23X6V0N aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MQK36 aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 5
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0N2PEQ aspect: alc_pe_dep should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "61613604-abe9-5fb9-97a6-faa874f7b9cc"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "c2e10e2d-0c13-55ac-a903-16aa35eaecef"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "b5bcc60d-e298-5621-8c59-f788672ef828"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "5672e829-525c-5e5b-a8d4-46634023977b"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "8d4c54c5-18d3-5813-9b02-01d220fe06a7"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "f470bbf0-ca10-5e97-9557-3dc05f6bea1d"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "2214df09-874e-5dae-bdfc-0af7cb9ccc54"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "bccc777f-2d79-5e66-bd2f-4c723ef88f42"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 8
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "abfc1a2d-bdb1-5553-b79f-f61fff82e345"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "b6739f38-a943-5c85-aa41-205d88f3fec3"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "436aea9d-a160-5e1e-86eb-194137fd2a53"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "9dbc0af9-57ce-5a39-a2f4-10bf717ecfca"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JO40Y aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGGGEP aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E1JL0D aspect: alc_pe_dep should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "5672e829-525c-5e5b-a8d4-46634023977b"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: R04JV34 aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EWGK38 aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 20
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23X4V3N aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35X10O aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: O0K9D01 aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 5
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XP2EN aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "61613604-abe9-5fb9-97a6-faa874f7b9cc"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "c2e10e2d-0c13-55ac-a903-16aa35eaecef"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "b5bcc60d-e298-5621-8c59-f788672ef828"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "8d4c54c5-18d3-5813-9b02-01d220fe06a7"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "bccc777f-2d79-5e66-bd2f-4c723ef88f42"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "337596cc-8422-5c6f-8e9d-6b93c0be0208"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 100
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "7b22fec7-e9a6-51fa-a0f7-4a08ffd60960"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "59a42152-366d-5508-a888-9629c60e91fc"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "1b63a95c-ea92-58a6-8e8b-54d5bc42d76d"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "9dbc0af9-57ce-5a39-a2f4-10bf717ecfca"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGLK3P aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: NEL9J3V aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE694EL aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: K399M3L aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "c2e10e2d-0c13-55ac-a903-16aa35eaecef"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 8
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MGW06 aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VGM0L aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32QME8 aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3P770W aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EWZKE8 aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "61613604-abe9-5fb9-97a6-faa874f7b9cc"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "5672e829-525c-5e5b-a8d4-46634023977b"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b9593781-a11a-5660-9bda-a02059817589"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 15
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "59a42152-366d-5508-a888-9629c60e91fc"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "77f82166-32a0-546f-b62a-af0e32e83986"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "30b1f600-8ea0-57a1-83d5-c5b16fec6340"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6NMEL aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z356NEO aspect: alc_pe_dep should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "5672e829-525c-5e5b-a8d4-46634023977b"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b9593781-a11a-5660-9bda-a02059817589"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "f470bbf0-ca10-5e97-9557-3dc05f6bea1d"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XV2EN aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R443O aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "2214df09-874e-5dae-bdfc-0af7cb9ccc54"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 16
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "436aea9d-a160-5e1e-86eb-194137fd2a53"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "e551f0fb-d55f-5f69-9728-989451dcf97f"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6Q4EL aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 3
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 6EDW60M aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JV43Y aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE6GM3L aspect: alc_pe_dep should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63Y843R aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: N0NQJ0Q aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 3
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "251c8b46-84a4-5031-83a2-94569d2f7d9a"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "436aea9d-a160-5e1e-86eb-194137fd2a53"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7DM0L aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 23XX43N aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y32LM08 aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3J4P0Y aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E1580D aspect: alc_pe_dep should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PX60W aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "2214df09-874e-5dae-bdfc-0af7cb9ccc54"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 24
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: REOQG32 aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MDW06 aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EW6KE8 aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0VQM3L aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EGRG3P aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35L10O aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 63YM43R aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 6
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Y325838 aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE72M0L aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME8MOEW aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50M6136 aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 6
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35MQ3O aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 73ZW63R aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 4EG443P aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: REOG402 aspect: alc_pe_dep should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b9593781-a11a-5660-9bda-a02059817589"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 3
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J0V6N3L aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "705dc095-654b-5eb2-a806-1abc7edd31a0"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "74d81b8d-4bdf-55b0-bc70-67b589b78a95"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b9593781-a11a-5660-9bda-a02059817589"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "2214df09-874e-5dae-bdfc-0af7cb9ccc54"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 35
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R6O0O aspect: alc_pe_dep should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "2a74eb35-54b2-5648-8089-4a8c02933778"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "4f7598ed-d960-5a23-9255-544dab80fe9d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3R680O aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: R047J04 aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "2214df09-874e-5dae-bdfc-0af7cb9ccc54"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "f74522b1-9434-5e73-a272-84ca91bd2e84"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 8
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "e551f0fb-d55f-5f69-9728-989451dcf97f"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3PR70W aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z35PN3O aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: ME87V3W aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 2E17NED aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE61J0L aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "4b79c726-c8f2-5491-8e82-316ba4cf4288"
                    ]
                }
            }
        },
        {
            "name": "screened user: 50MVM06 aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 5
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "fff7d9c5-1abe-5faa-b26e-712c34570801"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "e551f0fb-d55f-5f69-9728-989451dcf97f"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JK4EY aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: 7EW4D08 aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "0b3d129d-c4cd-5adc-a09a-5db81ba0b7b9"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "61613604-abe9-5fb9-97a6-faa874f7b9cc"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "31663734-0b9f-53d0-ac93-ab227b6a43d1"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "b5bcc60d-e298-5621-8c59-f788672ef828"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "28265f82-928c-5c60-be2c-00d6ebfe1285"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 10
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "1dc8a1e4-8441-58c6-a0d6-7201676de0c1"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: Z3RLO3O aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "d5bb65be-f7eb-568b-93cf-fff92621ccb2"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 3
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: J3JZREY aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "adadb641-a888-5f52-8c2d-a33d52f437ff"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: XE7WX3L aspect: alc_pe_dep should be positive",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "8dec39a1-5dfe-53fe-99d1-752e776bbcbc"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "f100da78-6b18-5222-8771-521684105f95"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "d78a1a3f-f493-555e-9776-41dc70e2f63b"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "6647082b-0e8d-5835-80ad-7dca26d35c9f"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "da873ed6-7c53-580c-a2fb-ebaa52cef516"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "93f9aeb7-77ef-5172-a965-d21a8be006cd"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "337596cc-8422-5c6f-8e9d-6b93c0be0208"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "c72c8340-6d05-5771-84b1-dbb83f231123"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: PE64M0L aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "ee458d60-7198-5b41-bd8f-5aaaa78473f0"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "86e19e81-1b92-55bc-bdb0-8cf2b062525e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "abda3745-2dfc-5d22-b8e2-02e58e0d7983"
                    ]
                },
                "AU-3.5": {
                    "questionId": "AU-3.5",
                    "chosenOptionIds": [
                        "43ed898e-f5c2-5acf-a887-a7a4a4fb1708"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: O3P263W aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "0052107c-edfc-5abf-b8e2-22d833c18454"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "fd2be360-9f0c-51a5-a13f-acebafdf17e8"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 3
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "e551f0fb-d55f-5f69-9728-989451dcf97f"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        },
        {
            "name": "screened user: NELXPEV aspect: alc_pe_dep should be negative",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "AU-1": {
                    "questionId": "AU-1",
                    "chosenOptionIds": [
                        "b1a86447-08b7-5e7d-a3d5-015e31be0840"
                    ]
                },
                "AU-10": {
                    "questionId": "AU-10",
                    "chosenOptionIds": [
                        "9d796df4-e587-5bff-bb07-00d6c098a8f4"
                    ]
                },
                "AU-11": {
                    "questionId": "AU-11",
                    "chosenOptionIds": [
                        "070cc48a-ddfd-59da-a89e-b08e575e66a3"
                    ]
                },
                "AU-12": {
                    "questionId": "AU-12",
                    "chosenOptionIds": [
                        "44d6a11a-144b-56f5-963f-c6e4e5985cd8"
                    ]
                },
                "AU-13": {
                    "questionId": "AU-13",
                    "chosenOptionIds": [
                        "6c1588ed-790e-57e2-9ec7-21e1edcdd8c8"
                    ]
                },
                "AU-14": {
                    "questionId": "AU-14",
                    "chosenOptionIds": [
                        "111f6379-4749-5adc-828d-88a4f35ba226"
                    ]
                },
                "AU-15": {
                    "questionId": "AU-15",
                    "chosenOptionIds": [
                        "349b824f-b485-5672-8148-d3783d5b2392"
                    ]
                },
                "AU-16": {
                    "questionId": "AU-16",
                    "chosenOptionIds": [
                        "b36eef07-a5d5-5596-9d6c-f86d8267aeee"
                    ]
                },
                "AU-17": {
                    "questionId": "AU-17",
                    "chosenOptionIds": [
                        "1281438f-eb00-583a-aba5-1645f3104665"
                    ]
                },
                "AU-18": {
                    "questionId": "AU-18",
                    "chosenOptionIds": [
                        "c85d230a-988f-5850-a03d-0e1a877650da"
                    ]
                },
                "AU-19": {
                    "questionId": "AU-19",
                    "chosenOptionIds": [
                        "39d41b2f-b56f-5f1f-a9b1-16200d417b6d"
                    ]
                },
                "AU-2.1": {
                    "questionId": "AU-2.1",
                    "chosenOptionIds": [
                        "eea8763e-5c32-5ae4-84d0-91d5de001887"
                    ]
                },
                "AU-20": {
                    "questionId": "AU-20",
                    "chosenOptionIds": [
                        "16f73e60-f332-50cf-9151-860eb4be2677"
                    ]
                },
                "AU-21": {
                    "questionId": "AU-21",
                    "chosenOptionIds": [
                        "64d6c3e7-3a72-51f3-b882-1a153eb2d13e"
                    ]
                },
                "AU-3": {
                    "questionId": "AU-3",
                    "chosenOptionIds": [
                        "6835adaf-f7b6-5ec3-9027-f771cabd0b98"
                    ]
                },
                "AU-4": {
                    "questionId": "AU-4",
                    "freeValue": 6
                },
                "AU-5": {
                    "questionId": "AU-5",
                    "chosenOptionIds": [
                        "faa6a973-d8bd-5b74-bd70-d3791b527341"
                    ]
                },
                "AU-6": {
                    "questionId": "AU-6",
                    "chosenOptionIds": [
                        "b3eeaf44-2489-51f5-b041-f252d4f87d49"
                    ]
                },
                "AU-7": {
                    "questionId": "AU-7",
                    "chosenOptionIds": [
                        "d3d01784-987d-5627-a97b-98623a170df9"
                    ]
                },
                "AU-8": {
                    "questionId": "AU-8",
                    "chosenOptionIds": [
                        "7cd65629-9089-5fd1-b831-efb2a167e4b0"
                    ]
                },
                "AU-9": {
                    "questionId": "AU-9",
                    "chosenOptionIds": [
                        "026ad3bf-66f3-5834-83e2-be4e4873b633"
                    ]
                },
                "AU-S": {
                    "questionId": "AU-S",
                    "chosenOptionIds": [
                        "63a5090e-7b50-5803-b8a8-9a26f3c8288a"
                    ]
                },
                "AU-s1": {
                    "questionId": "AU-s1",
                    "chosenOptionIds": [
                        "297fdee2-d461-5ae6-9150-2340c8d2f89d"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {
    "AU-1": "not(AU-s1=0)",
    "AU-2.1": "not(AU-s1=0)",
    "AU-3": "not(AU-s1=0)",
    "AU-3.5": "or[and[AU-1=0, or[and[AU-2.1=0, or[AU-3=0, AU-3=0.25, AU-3=0.5]], and[AU-2.1=0.25, AU-3=0], and[AU-2.1=0.25, AU-3=0.25], and[AU-2.1=0.5, AU-3=0]]], and[AU-1=0.25, AU-2.1=0, AU-3=0], and[AU-1=0.25, AU-2.1=0, AU-3=0.25], and[AU-1=0.25, AU-2.1=0.25, AU-3=0], and[AU-1=0.5, AU-2.1=0, AU-3=0]]",
    "AU-4": "and[or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0]), not(and[AU-1=0, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.5]), not(and[AU-1=0, AU-2.1=0, AU-3=0.5])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0.5, AU-3=0]), not(and[AU-1=0, AU-2.1=0.5, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0.25, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0.5, AU-2.1=0, AU-3=0]), not(and[AU-1=0.5, AU-2.1=0, AU-3=0])], not(isUndefined(AU-3=0))]",
    "AU-5": "and[or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0]), not(and[AU-1=0, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.5]), not(and[AU-1=0, AU-2.1=0, AU-3=0.5])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0.5, AU-3=0]), not(and[AU-1=0, AU-2.1=0.5, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0.25, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0.5, AU-2.1=0, AU-3=0]), not(and[AU-1=0.5, AU-2.1=0, AU-3=0])], not(isUndefined(AU-3=0))]",
    "AU-6": "and[or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0]), not(and[AU-1=0, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.5]), not(and[AU-1=0, AU-2.1=0, AU-3=0.5])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0.5, AU-3=0]), not(and[AU-1=0, AU-2.1=0.5, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0.25, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0.5, AU-2.1=0, AU-3=0]), not(and[AU-1=0.5, AU-2.1=0, AU-3=0])], not(isUndefined(AU-3=0))]",
    "AU-7": "and[or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0]), not(and[AU-1=0, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.5]), not(and[AU-1=0, AU-2.1=0, AU-3=0.5])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0.5, AU-3=0]), not(and[AU-1=0, AU-2.1=0.5, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0.25, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0.5, AU-2.1=0, AU-3=0]), not(and[AU-1=0.5, AU-2.1=0, AU-3=0])], not(isUndefined(AU-3=0))]",
    "AU-8": "and[or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0]), not(and[AU-1=0, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.5]), not(and[AU-1=0, AU-2.1=0, AU-3=0.5])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0.5, AU-3=0]), not(and[AU-1=0, AU-2.1=0.5, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0.25, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0.5, AU-2.1=0, AU-3=0]), not(and[AU-1=0.5, AU-2.1=0, AU-3=0])], not(isUndefined(AU-3=0))]",
    "AU-9": "and[or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0]), not(and[AU-1=0, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.5]), not(and[AU-1=0, AU-2.1=0, AU-3=0.5])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0.5, AU-3=0]), not(and[AU-1=0, AU-2.1=0.5, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0.25, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0.5, AU-2.1=0, AU-3=0]), not(and[AU-1=0.5, AU-2.1=0, AU-3=0])], not(isUndefined(AU-3=0))]",
    "AU-10": "or[AU-3.5=1, and[or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0]), not(and[AU-1=0, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.5]), not(and[AU-1=0, AU-2.1=0, AU-3=0.5])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0.5, AU-3=0]), not(and[AU-1=0, AU-2.1=0.5, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0.25, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0.5, AU-2.1=0, AU-3=0]), not(and[AU-1=0.5, AU-2.1=0, AU-3=0])], not(isUndefined(AU-3=0))]]",
    "AU-11": "or[AU-3.5=1, and[or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0]), not(and[AU-1=0, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.5]), not(and[AU-1=0, AU-2.1=0, AU-3=0.5])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0.5, AU-3=0]), not(and[AU-1=0, AU-2.1=0.5, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0.25, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0.5, AU-2.1=0, AU-3=0]), not(and[AU-1=0.5, AU-2.1=0, AU-3=0])], not(isUndefined(AU-3=0))]]",
    "AU-12": "or[AU-3.5=1, and[or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0]), not(and[AU-1=0, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.5]), not(and[AU-1=0, AU-2.1=0, AU-3=0.5])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0.5, AU-3=0]), not(and[AU-1=0, AU-2.1=0.5, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0.25, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0.5, AU-2.1=0, AU-3=0]), not(and[AU-1=0.5, AU-2.1=0, AU-3=0])], not(isUndefined(AU-3=0))]]",
    "AU-13": "or[AU-3.5=1, and[or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0]), not(and[AU-1=0, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.5]), not(and[AU-1=0, AU-2.1=0, AU-3=0.5])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0.5, AU-3=0]), not(and[AU-1=0, AU-2.1=0.5, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0.25, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0.5, AU-2.1=0, AU-3=0]), not(and[AU-1=0.5, AU-2.1=0, AU-3=0])], not(isUndefined(AU-3=0))]]",
    "AU-14": "or[AU-3.5=1, and[or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0]), not(and[AU-1=0, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.5]), not(and[AU-1=0, AU-2.1=0, AU-3=0.5])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0.5, AU-3=0]), not(and[AU-1=0, AU-2.1=0.5, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0.25, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0.5, AU-2.1=0, AU-3=0]), not(and[AU-1=0.5, AU-2.1=0, AU-3=0])], not(isUndefined(AU-3=0))]]",
    "AU-15": "or[AU-3.5=1, and[or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0]), not(and[AU-1=0, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.5]), not(and[AU-1=0, AU-2.1=0, AU-3=0.5])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0.5, AU-3=0]), not(and[AU-1=0, AU-2.1=0.5, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0.25, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0.5, AU-2.1=0, AU-3=0]), not(and[AU-1=0.5, AU-2.1=0, AU-3=0])], not(isUndefined(AU-3=0))]]",
    "AU-16": "or[AU-3.5=1, and[or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0]), not(and[AU-1=0, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.5]), not(and[AU-1=0, AU-2.1=0, AU-3=0.5])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0.5, AU-3=0]), not(and[AU-1=0, AU-2.1=0.5, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0.25, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0.5, AU-2.1=0, AU-3=0]), not(and[AU-1=0.5, AU-2.1=0, AU-3=0])], not(isUndefined(AU-3=0))]]",
    "AU-17": "or[AU-3.5=1, and[or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0]), not(and[AU-1=0, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.5]), not(and[AU-1=0, AU-2.1=0, AU-3=0.5])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0.5, AU-3=0]), not(and[AU-1=0, AU-2.1=0.5, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0.25, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0.5, AU-2.1=0, AU-3=0]), not(and[AU-1=0.5, AU-2.1=0, AU-3=0])], not(isUndefined(AU-3=0))]]",
    "AU-18": "or[AU-3.5=1, and[or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0]), not(and[AU-1=0, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.5]), not(and[AU-1=0, AU-2.1=0, AU-3=0.5])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0.5, AU-3=0]), not(and[AU-1=0, AU-2.1=0.5, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0.25, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0.5, AU-2.1=0, AU-3=0]), not(and[AU-1=0.5, AU-2.1=0, AU-3=0])], not(isUndefined(AU-3=0))]]",
    "AU-19": "or[AU-3.5=1, and[or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0]), not(and[AU-1=0, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.5]), not(and[AU-1=0, AU-2.1=0, AU-3=0.5])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0.5, AU-3=0]), not(and[AU-1=0, AU-2.1=0.5, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0.25, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0.5, AU-2.1=0, AU-3=0]), not(and[AU-1=0.5, AU-2.1=0, AU-3=0])], not(isUndefined(AU-3=0))]]",
    "AU-20": "or[AU-3.5=1, and[or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0]), not(and[AU-1=0, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.5]), not(and[AU-1=0, AU-2.1=0, AU-3=0.5])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0.5, AU-3=0]), not(and[AU-1=0, AU-2.1=0.5, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0.25, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0.5, AU-2.1=0, AU-3=0]), not(and[AU-1=0.5, AU-2.1=0, AU-3=0])], not(isUndefined(AU-3=0))]]",
    "AU-21": "or[AU-3.5=1, and[or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0]), not(and[AU-1=0, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0, AU-3=0.5]), not(and[AU-1=0, AU-2.1=0, AU-3=0.5])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0, AU-2.1=0.25, AU-3=0.25]), not(and[AU-1=0, AU-2.1=0.25, AU-3=0.25])], or[isUndefined(and[AU-1=0, AU-2.1=0.5, AU-3=0]), not(and[AU-1=0, AU-2.1=0.5, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0])], or[isUndefined(and[AU-1=0.25, AU-2.1=0, AU-3=0.25]), not(and[AU-1=0.25, AU-2.1=0, AU-3=0.25])], or[isUndefined(and[AU-1=0.25, AU-2.1=0.25, AU-3=0]), not(and[AU-1=0.25, AU-2.1=0.25, AU-3=0])], or[isUndefined(and[AU-1=0.5, AU-2.1=0, AU-3=0]), not(and[AU-1=0.5, AU-2.1=0, AU-3=0])], not(isUndefined(AU-3=0))]]"
};
