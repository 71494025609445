"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
const censeo_1 = require("../../schema/generated/censeo");
exports.questions = [
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "9caef0a6-36ce-54b4-a3d4-167d363dca3c",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "5adf2c06-eb6b-5aa6-b7b4-3acf6125cf84",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "PD-s1",
        "text": "In the last six months, on more than one occasion have you experienced a sudden attack of very strong fear, anxiety or discomfort that peaked within minutes?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "f508618d-9536-59d6-9553-eb7211e00f34",
                "text": "None",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f3393659-4a98-567b-9cd1-178b49b25f7f",
                "text": "Between 1 and 3",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "4fbbfacf-31cb-5c1d-b40e-8707c0829ec0",
                "text": "Between 4 and 6",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "856a4a58-5f9c-5379-9150-0b50b5d0fccf",
                "text": "Between 7 and 10",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "72dd979a-2357-5fac-bed4-da06cea96e4e",
                "text": "More than 10",
                "value": 1
            }
        ],
        "id": "PD-s3",
        "text": "How many anxiety attacks have occurred in the past six months?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "PD-1a",
        "text": "On average, approximately how long does an anxiety attack last for?",
        "unit": censeo_1.Unit.DurationMinutes
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "21982882-23e9-5a7c-88f0-1a2325372560",
                "text": "No, they have a clear trigger or only happen in a specific situation",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "78684e31-9745-5af1-99f9-1e5796aabe72",
                "text": "Yes, they happen out of the blue",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "3b1605f2-9652-5958-8059-bd8b7aec2f2d",
                "text": "I am not sure",
                "value": 1
            }
        ],
        "id": "PD-3a",
        "text": "Do you feel that your anxiety attacks happen out of the blue?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "cd09d547-49fa-5075-942e-c2445a1c9687",
                "text": "Pounding heart"
            },
            {
                "__typename": "Option",
                "id": "f789ab51-5fa9-537b-96b0-08f8cb294040",
                "text": "Increased heart rate"
            },
            {
                "__typename": "Option",
                "id": "359fd14e-76c8-58e0-a327-b3558bc689f1",
                "text": "Heart fluttering or feeling as if the heart skipped a beat"
            },
            {
                "__typename": "Option",
                "id": "2a760210-55f9-56c9-8244-8837c46fc869",
                "text": "Chest pain"
            },
            {
                "__typename": "Option",
                "id": "09558269-66f2-5c37-9fda-90b76dcda6d4",
                "text": "Excessive sweating"
            },
            {
                "__typename": "Option",
                "id": "6370bf14-ef56-5636-9d47-6dd92dcd3949",
                "text": "Trembling/shaking"
            },
            {
                "__typename": "Option",
                "id": "207eb06d-5508-5584-bbfb-35842d65a1b1",
                "text": "Shortness of breath or feeling as if being smothered"
            },
            {
                "__typename": "Option",
                "id": "99d611e9-3a3b-531b-9e34-03622d11036b",
                "text": "Choking"
            },
            {
                "__typename": "Option",
                "id": "ab3abe29-18fb-50dd-8bef-42e35988e746",
                "text": "Nausea"
            },
            {
                "__typename": "Option",
                "id": "fc3d9e34-1469-5c14-8235-b40e01a47e4d",
                "text": "Upset stomach"
            },
            {
                "__typename": "Option",
                "id": "bf141530-681d-5e5e-8740-0c846343b361",
                "text": "Dizziness"
            },
            {
                "__typename": "Option",
                "id": "05c3c9bc-7045-5533-98bb-3335d9815667",
                "text": "Feeling too cold or too hot"
            },
            {
                "__typename": "Option",
                "id": "9521c7f6-a5f3-57c8-a85d-e0abe74cbe1d",
                "text": "Fear of dying"
            },
            {
                "__typename": "Option",
                "id": "3bb0e41e-4edd-5d74-86ce-cecf92bbbadb",
                "text": "Fear of losing control"
            },
            {
                "__typename": "Option",
                "id": "06d84ce6-0739-5fe4-8bc0-44efeae17cc0",
                "text": "Numbness or tingling sensations"
            },
            {
                "__typename": "Option",
                "id": "313884fa-19f3-50c7-8a83-0909cb47347c",
                "text": "Feeling like the world isn't real"
            },
            {
                "__typename": "Option",
                "id": "66d2a64f-6ad5-5f44-8b3d-062aefecd20e",
                "text": "Feeling that I am not real"
            },
            {
                "__typename": "Option",
                "id": "040cb5af-b6c0-5561-821f-922bc98f255b",
                "text": "Fear of 'going crazy'"
            },
            {
                "__typename": "Option",
                "id": "1cdd65c3-7d83-5308-9457-399b96140b39",
                "text": "None of the above",
                "exclusive": true
            }
        ],
        "id": "PD-4a",
        "text": "During an anxiety attack, do you experience any of the following sensations or fears? Select all that apply.",
        "isMultiselect": true
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ae1c5b54-4e77-5cae-add0-74c2d7b58d65",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "4490ebc3-f879-5be2-b5d2-fd8bc35b02b9",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "b3cdd648-01d1-5e38-87aa-0d7e3aaa11f9",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "e28a324b-33cd-5b34-ae0d-1b6653c14634",
                "text": "Very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "ab6d392b-dfe7-5f88-85c1-830fe8ee1b52",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "PD-8a",
        "text": "Are you worried about having another anxiety attack?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "64c30b78-81b4-53a0-a97d-61b9b3d166a8",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "9b567469-85f9-58f7-9665-622d04adadd8",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "350fac3e-6a71-5040-8512-64f34f758bd5",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "459c484e-031b-5f83-b7e0-542f3d1c5020",
                "text": "Very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "b94cfc0c-3bc7-52d9-9a59-92d677c89c92",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "PD-9a",
        "text": "Were you worried that you might be 'going crazy' in the month after the anxiety attack?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "791fcdc1-132b-572e-8bc7-9de09fcc5cfb",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "1ce36e6a-5a3f-5842-b0a9-2c48cb1e9d75",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "58752e11-5a9a-57b7-b348-7aee292b99bb",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "6a825419-a602-5c70-a58f-6d1a4c35fe01",
                "text": "Very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "01f2d100-5e18-57b1-b7bc-1d3f1c855352",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "PD-10a",
        "text": "Are you worried that the anxiety attacks might be caused by a physical disease (e.g. heart problems or seizures)?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e97c8999-e8ba-5a8b-866d-550df538a261",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "591bebf0-2340-5fa7-8637-3067656fe6f5",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "102cc555-ba29-55b9-b619-10976c6782e2",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "ca08db06-d49d-5976-a9f0-cfc3237fac20",
                "text": "Very much so",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "9a95a69e-26f4-5e9a-a828-8574672e987b",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "PD-11a",
        "text": "Are you worried that others might judge you for having an anxiety attack?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "44c9c265-5727-55b4-a45b-f80f2f3d2928",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "423a96f8-8bfd-5b6c-a1a9-c774b8791c76",
                "text": "Rarely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "3519ecd3-0b41-5ec6-9951-b6ca15bfd473",
                "text": "Sometimes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "49a87d69-42f3-5a88-9e6f-98bb47e130c8",
                "text": "Frequently",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "7b5d4fdb-8dfa-5538-a1cb-8c8eff41a761",
                "text": "Almost always or always",
                "value": 1
            }
        ],
        "id": "PD-13a",
        "text": "Are you avoiding certain situations that you fear could trigger another anxiety attack (e.g. crowds, being alone or using public transport)?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "7ab204cd-efb0-5190-9c1f-b5a8746796f3",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "369a830f-a251-5df9-a1bb-a83ba74b02ec",
                "text": "Rarely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "16a15ddc-d3c4-5efe-a011-d452832682a5",
                "text": "Sometimes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "85b6cbb0-6f57-5c28-a3cf-9ded461ed3e4",
                "text": "Frequently",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "aea711e7-9612-51a3-b80b-36ab25e459e6",
                "text": "Almost always or always",
                "value": 1
            }
        ],
        "id": "PD-14a",
        "text": "Do you distract yourself or use specific strategies to avoid having another anxiety attack (e.g. counting numbers, listening to music or reading)?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "9b99e9c5-0092-5d1f-aa82-0db2a3278ce8",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "34790fef-2329-565c-9cd4-89b781b0a5d5",
                "text": "Rarely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "38eb3155-4361-5031-a733-6666ccfc02ae",
                "text": "Sometimes",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "89496f87-cb5e-58fa-b7cf-b94e6ce45c90",
                "text": "Frequently",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "d4c12269-3c45-5f93-a03c-3a2fdba3a082",
                "text": "Almost always or always",
                "value": 1
            }
        ],
        "id": "PD-15a",
        "text": "Do you avoid specific activities because they might trigger another anxiety attack, or because they make you feel like you felt during an anxiety attack (e.g. reducing exercise or caffeine intake)?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "d16d5209-9ac4-5bf9-adef-0cdb791aa379",
                "text": "Several times per week",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "6dea1f67-fe9f-5e00-adca-06f92c59a358",
                "text": "Several times per month",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "fd9a4b5a-6870-5a0b-bbdc-c3a7ba0eed4f",
                "text": "Several times per year",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "e51f80ea-46a9-5c50-8d7b-0f11bf75ef5e",
                "text": "Less than several times per year",
                "value": 1
            }
        ],
        "id": "PD-17a",
        "text": "How often are these anxiety attacks happening?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "7547d0f2-3d19-5ad0-a976-940d44999707",
                "text": "Less than a few weeks",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "9104a0d0-7f58-504d-bede-ea7b7379ea81",
                "text": "A few weeks",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "c44f8577-7031-5122-b8f0-9dd93e6adaf4",
                "text": "One month or longer",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "57040a04-d36e-552f-b7f8-9b54882ed290",
                "text": "Years",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "951ca64b-47da-5e2e-ad2e-b9078fa5053e",
                "text": "Most of my life",
                "value": 1
            }
        ],
        "id": "PD-18a",
        "text": "How long have these anxiety attacks been happening?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeQuestion",
        "id": "PD-18.5b",
        "text": "Approximately, when was your last anxiety attack?",
        "unit": censeo_1.Unit.DurationHours
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "dfbaea46-d479-5f6a-b5ae-babea561c4b7",
                "text": "Basic needs such as eating and sleeping"
            },
            {
                "__typename": "Option",
                "id": "cb67a061-ba15-5801-aa3e-743189178f51",
                "text": "Working or studying"
            },
            {
                "__typename": "Option",
                "id": "a0cb5d9a-bbcb-5c94-90ab-67f40a7f81e2",
                "text": "Intimate relationships"
            },
            {
                "__typename": "Option",
                "id": "14e5edc2-e598-502f-a03f-3f5ff5903e96",
                "text": "Social life"
            },
            {
                "__typename": "Option",
                "id": "b771b646-aa5c-5de5-b835-903811177ba9",
                "text": "Family"
            },
            {
                "__typename": "Option",
                "id": "9a12757a-819d-5ee0-990e-2cfc5b7c55c0",
                "text": "Leisure activities, hobbies and interests"
            },
            {
                "__typename": "Option",
                "id": "df1263a5-d865-513c-8a9b-c36645201066",
                "text": "Keeping fit and healthy"
            },
            {
                "__typename": "Option",
                "id": "bc851432-16bf-52cf-9287-020ba6763695",
                "text": "Finances"
            },
            {
                "__typename": "Option",
                "id": "b4d064d4-4b58-52de-8403-374108e44562",
                "text": "Other aspects of my life not mentioned above"
            },
            {
                "__typename": "Option",
                "id": "0ff1f1a7-4cc7-4756-9f7b-88586a678a63",
                "text": "No areas of my life are affected by these anxiety attacks",
                "exclusive": true
            }
        ],
        "id": "PD-19a",
        "text": "Which areas of your life, if any, do you feel are disrupted by these anxiety attacks? Please select all that apply.",
        "isMultiselect": true
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6f38d6b7-1e8e-59d8-855d-3a2dfab8a7a8",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "32d9f34a-6d55-5a74-9831-d50b0674028c",
                "text": "To a slight extent",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "b4ec769f-97d2-5fc9-9dd2-667849759e5f",
                "text": "To a moderate extent",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "c2ff6ef8-9fa1-5f4f-b8dd-f24d7e67d086",
                "text": "To a significant extent",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "f9006ec3-1581-5159-a4bb-c73bd1c0468b",
                "text": "To an extreme extent",
                "value": 1
            }
        ],
        "id": "PD-19.0a",
        "text": "To what extent do these anxiety attacks affect these areas of your life, or require you to make additional effort to maintain functioning in those areas?",
        "isMultiselect": false
    },
    {
        "__typename": "ContentQuestion",
        "id": "PD-S",
        "text": "You are making good progress through this question session. Remember, you can take a break at any time as your answers are saved automatically.",
        "answers": [
            {
                "__typename": "Option",
                "id": "08900817-6bfe-5d29-913d-0f226bdbd8e1",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    }
];
exports.aspects = {
    "pad_ce": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "has_pd"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "pd_c"
                }
            }
        ],
        "anyToAll": 1
    },
    "has_pd": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "pd_A_panic_attacks"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "pd_B_subsequent_change"
                }
            }
        ],
        "anyToAll": 1
    },
    "pd_A_panic_attacks": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "pd_recurrent_unexpected_panic_attacks"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "pd_panic_attack_symptoms"
                }
            }
        ],
        "anyToAll": 1
    },
    "pd_B_subsequent_change": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "pd_persistent_concern"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "pd_maladaptive_change"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "pd_recurrent_unexpected_panic_attacks": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PD-s1",
                    "options": {
                        "type": "predicates",
                        "predicates": {
                            "9caef0a6-36ce-54b4-a3d4-167d363dca3c": false,
                            "5adf2c06-eb6b-5aa6-b7b4-3acf6125cf84": true
                        }
                    }
                }
            },
            {
                "type": "not",
                "wire": {
                    "type": "input",
                    "signal": {
                        "type": "AnsweredOption",
                        "questionId": "PD-3a",
                        "options": {
                            "type": "predicates",
                            "predicates": {
                                "21982882-23e9-5a7c-88f0-1a2325372560": true,
                                "78684e31-9745-5af1-99f9-1e5796aabe72": false,
                                "3b1605f2-9652-5958-8059-bd8b7aec2f2d": false
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    },
    "pd_panic_attack_symptoms": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "pd_heart"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "pd_sweating"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "pd_trembling"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "pd_shortness_breath"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "pd_choking"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "pd_chest_pain"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "pd_nause_ab_distress"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "pd_dizzy"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "pd_chills_heat"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "pd_paresthesias"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "pd_deralisation"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "pd_going_crazy"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "pd_dying"
                }
            }
        ],
        "anyToAll": 0.3076923076923077
    },
    "pd_heart": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "PD4a_heart"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "PD4a_pulse"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "PD4a_palpitations"
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "pd_sweating": {
        "type": "input",
        "signal": {
            "type": "AspectReference",
            "aspectName": "PD4a_sweat"
        }
    },
    "pd_trembling": {
        "type": "input",
        "signal": {
            "type": "AspectReference",
            "aspectName": "PD5a_tremble"
        }
    },
    "pd_shortness_breath": {
        "type": "input",
        "signal": {
            "type": "AspectReference",
            "aspectName": "PD5a_breath"
        }
    },
    "pd_choking": {
        "type": "input",
        "signal": {
            "type": "AspectReference",
            "aspectName": "PD5a_choke"
        }
    },
    "pd_chest_pain": {
        "type": "input",
        "signal": {
            "type": "AspectReference",
            "aspectName": "PD4a_chest"
        }
    },
    "pd_nause_ab_distress": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "PD5a_nausea"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "PD5a_abdomen"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "pd_dizzy": {
        "type": "input",
        "signal": {
            "type": "AspectReference",
            "aspectName": "PD6a_dizzy"
        }
    },
    "pd_chills_heat": {
        "type": "input",
        "signal": {
            "type": "AspectReference",
            "aspectName": "PD6a_chills"
        }
    },
    "pd_paresthesias": {
        "type": "input",
        "signal": {
            "type": "AspectReference",
            "aspectName": "PD6a_parasthesia"
        }
    },
    "pd_deralisation": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "PD7a_derealisation"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "PD7a_depersonalisation"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "pd_going_crazy": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "PD7a_crazy"
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AspectReference",
                    "aspectName": "PD6a_control"
                }
            }
        ],
        "anyToAll": 0.5
    },
    "pd_dying": {
        "type": "input",
        "signal": {
            "type": "AspectReference",
            "aspectName": "PD6a_death"
        }
    },
    "pd_persistent_concern": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PD-8a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "ae1c5b54-4e77-5cae-add0-74c2d7b58d65": 0.0066928509242848554,
                            "4490ebc3-f879-5be2-b5d2-fd8bc35b02b9": 0.07585818002124355,
                            "b3cdd648-01d1-5e38-87aa-0d7e3aaa11f9": 0.5,
                            "e28a324b-33cd-5b34-ae0d-1b6653c14634": 0.9241418199787566,
                            "ab6d392b-dfe7-5f88-85c1-830fe8ee1b52": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PD-9a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "64c30b78-81b4-53a0-a97d-61b9b3d166a8": 0.0066928509242848554,
                            "9b567469-85f9-58f7-9665-622d04adadd8": 0.07585818002124355,
                            "350fac3e-6a71-5040-8512-64f34f758bd5": 0.5,
                            "459c484e-031b-5f83-b7e0-542f3d1c5020": 0.9241418199787566,
                            "b94cfc0c-3bc7-52d9-9a59-92d677c89c92": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PD-10a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "791fcdc1-132b-572e-8bc7-9de09fcc5cfb": 0.0066928509242848554,
                            "1ce36e6a-5a3f-5842-b0a9-2c48cb1e9d75": 0.07585818002124355,
                            "58752e11-5a9a-57b7-b348-7aee292b99bb": 0.5,
                            "6a825419-a602-5c70-a58f-6d1a4c35fe01": 0.9241418199787566,
                            "01f2d100-5e18-57b1-b7bc-1d3f1c855352": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PD-11a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "e97c8999-e8ba-5a8b-866d-550df538a261": 0.0066928509242848554,
                            "591bebf0-2340-5fa7-8637-3067656fe6f5": 0.07585818002124355,
                            "102cc555-ba29-55b9-b619-10976c6782e2": 0.5,
                            "ca08db06-d49d-5976-a9f0-cfc3237fac20": 0.9241418199787566,
                            "9a95a69e-26f4-5e9a-a828-8574672e987b": 0.9933071490757153
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.25
    },
    "pd_maladaptive_change": {
        "type": "merge",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PD-13a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "44c9c265-5727-55b4-a45b-f80f2f3d2928": 0.0066928509242848554,
                            "423a96f8-8bfd-5b6c-a1a9-c774b8791c76": 0.07585818002124355,
                            "3519ecd3-0b41-5ec6-9951-b6ca15bfd473": 0.5,
                            "49a87d69-42f3-5a88-9e6f-98bb47e130c8": 0.9241418199787566,
                            "7b5d4fdb-8dfa-5538-a1cb-8c8eff41a761": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PD-14a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "7ab204cd-efb0-5190-9c1f-b5a8746796f3": 0.0066928509242848554,
                            "369a830f-a251-5df9-a1bb-a83ba74b02ec": 0.07585818002124355,
                            "16a15ddc-d3c4-5efe-a011-d452832682a5": 0.5,
                            "85b6cbb0-6f57-5c28-a3cf-9ded461ed3e4": 0.9241418199787566,
                            "aea711e7-9612-51a3-b80b-36ab25e459e6": 0.9933071490757153
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "PD-15a",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "9b99e9c5-0092-5d1f-aa82-0db2a3278ce8": 0.0066928509242848554,
                            "34790fef-2329-565c-9cd4-89b781b0a5d5": 0.07585818002124355,
                            "38eb3155-4361-5031-a733-6666ccfc02ae": 0.5,
                            "89496f87-cb5e-58fa-b7cf-b94e6ce45c90": 0.9241418199787566,
                            "d4c12269-3c45-5f93-a03c-3a2fdba3a082": 0.9933071490757153
                        }
                    }
                }
            }
        ],
        "anyToAll": 0.3333333333333333
    },
    "pd_c": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-s3",
            "options": {
                "type": "severities",
                "severities": {
                    "f508618d-9536-59d6-9553-eb7211e00f34": 0.07585818002124355,
                    "f3393659-4a98-567b-9cd1-178b49b25f7f": 0.5,
                    "4fbbfacf-31cb-5c1d-b40e-8707c0829ec0": 0.9241418199787566,
                    "856a4a58-5f9c-5379-9150-0b50b5d0fccf": 0.9933071490757153,
                    "72dd979a-2357-5fac-bed4-da06cea96e4e": 0.9994472213630764
                }
            }
        }
    },
    "PD4a_heart": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-4a",
            "options": {
                "type": "severities",
                "severities": {
                    "cd09d547-49fa-5075-942e-c2445a1c9687": 1
                }
            }
        }
    },
    "PD4a_pulse": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-4a",
            "options": {
                "type": "severities",
                "severities": {
                    "f789ab51-5fa9-537b-96b0-08f8cb294040": 1
                }
            }
        }
    },
    "PD4a_palpitations": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-4a",
            "options": {
                "type": "severities",
                "severities": {
                    "359fd14e-76c8-58e0-a327-b3558bc689f1": 1
                }
            }
        }
    },
    "PD4a_chest": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-4a",
            "options": {
                "type": "severities",
                "severities": {
                    "2a760210-55f9-56c9-8244-8837c46fc869": 1
                }
            }
        }
    },
    "PD4a_sweat": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-4a",
            "options": {
                "type": "severities",
                "severities": {
                    "09558269-66f2-5c37-9fda-90b76dcda6d4": 1
                }
            }
        }
    },
    "PD5a_tremble": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-4a",
            "options": {
                "type": "severities",
                "severities": {
                    "6370bf14-ef56-5636-9d47-6dd92dcd3949": 1
                }
            }
        }
    },
    "PD5a_breath": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-4a",
            "options": {
                "type": "severities",
                "severities": {
                    "207eb06d-5508-5584-bbfb-35842d65a1b1": 1
                }
            }
        }
    },
    "PD5a_choke": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-4a",
            "options": {
                "type": "severities",
                "severities": {
                    "99d611e9-3a3b-531b-9e34-03622d11036b": 1
                }
            }
        }
    },
    "PD5a_nausea": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-4a",
            "options": {
                "type": "severities",
                "severities": {
                    "ab3abe29-18fb-50dd-8bef-42e35988e746": 1
                }
            }
        }
    },
    "PD5a_abdomen": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-4a",
            "options": {
                "type": "severities",
                "severities": {
                    "fc3d9e34-1469-5c14-8235-b40e01a47e4d": 1
                }
            }
        }
    },
    "PD6a_dizzy": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-4a",
            "options": {
                "type": "severities",
                "severities": {
                    "bf141530-681d-5e5e-8740-0c846343b361": 1
                }
            }
        }
    },
    "PD6a_chills": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-4a",
            "options": {
                "type": "severities",
                "severities": {
                    "05c3c9bc-7045-5533-98bb-3335d9815667": 1
                }
            }
        }
    },
    "PD6a_death": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-4a",
            "options": {
                "type": "severities",
                "severities": {
                    "9521c7f6-a5f3-57c8-a85d-e0abe74cbe1d": 1
                }
            }
        }
    },
    "PD6a_control": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-4a",
            "options": {
                "type": "severities",
                "severities": {
                    "3bb0e41e-4edd-5d74-86ce-cecf92bbbadb": 1
                }
            }
        }
    },
    "PD6a_parasthesia": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-4a",
            "options": {
                "type": "severities",
                "severities": {
                    "06d84ce6-0739-5fe4-8bc0-44efeae17cc0": 1
                }
            }
        }
    },
    "PD7a_derealisation": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-4a",
            "options": {
                "type": "severities",
                "severities": {
                    "313884fa-19f3-50c7-8a83-0909cb47347c": 1
                }
            }
        }
    },
    "PD7a_depersonalisation": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-4a",
            "options": {
                "type": "severities",
                "severities": {
                    "66d2a64f-6ad5-5f44-8b3d-062aefecd20e": 1
                }
            }
        }
    },
    "PD7a_crazy": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-4a",
            "options": {
                "type": "severities",
                "severities": {
                    "040cb5af-b6c0-5561-821f-922bc98f255b": 1
                }
            }
        }
    },
    "PD7a_": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-4a",
            "options": {
                "type": "severities",
                "severities": {
                    "1cdd65c3-7d83-5308-9457-399b96140b39": 1
                }
            }
        }
    },
    "PD19a_basic": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-19a",
            "options": {
                "type": "severities",
                "severities": {
                    "dfbaea46-d479-5f6a-b5ae-babea561c4b7": 1
                }
            }
        }
    },
    "PD19a_work": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-19a",
            "options": {
                "type": "severities",
                "severities": {
                    "cb67a061-ba15-5801-aa3e-743189178f51": 1
                }
            }
        }
    },
    "PD19a_relationships": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-19a",
            "options": {
                "type": "severities",
                "severities": {
                    "a0cb5d9a-bbcb-5c94-90ab-67f40a7f81e2": 1
                }
            }
        }
    },
    "PD19a_social": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-19a",
            "options": {
                "type": "severities",
                "severities": {
                    "14e5edc2-e598-502f-a03f-3f5ff5903e96": 1
                }
            }
        }
    },
    "PD19a_family": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-19a",
            "options": {
                "type": "severities",
                "severities": {
                    "b771b646-aa5c-5de5-b835-903811177ba9": 1
                }
            }
        }
    },
    "PD20a_leisure": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-19a",
            "options": {
                "type": "severities",
                "severities": {
                    "9a12757a-819d-5ee0-990e-2cfc5b7c55c0": 1
                }
            }
        }
    },
    "PD20a_health": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-19a",
            "options": {
                "type": "severities",
                "severities": {
                    "df1263a5-d865-513c-8a9b-c36645201066": 1
                }
            }
        }
    },
    "PD20a_financial": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-19a",
            "options": {
                "type": "severities",
                "severities": {
                    "bc851432-16bf-52cf-9287-020ba6763695": 1
                }
            }
        }
    },
    "PD20a_other": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-19a",
            "options": {
                "type": "severities",
                "severities": {
                    "b4d064d4-4b58-52de-8403-374108e44562": 1
                }
            }
        }
    },
    "PD19a_none": {
        "type": "input",
        "signal": {
            "type": "AnsweredOption",
            "questionId": "PD-19a",
            "options": {
                "type": "severities",
                "severities": {
                    "0ff1f1a7-4cc7-4756-9f7b-88586a678a63": 1
                }
            }
        }
    },
    "not(PD-s1=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "PD-s1",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "9caef0a6-36ce-54b4-a3d4-167d363dca3c": true,
                        "5adf2c06-eb6b-5aa6-b7b4-3acf6125cf84": false
                    }
                }
            }
        }
    },
    "not(PD-s3=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "PD-s3",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "f508618d-9536-59d6-9553-eb7211e00f34": true,
                        "f3393659-4a98-567b-9cd1-178b49b25f7f": false,
                        "4fbbfacf-31cb-5c1d-b40e-8707c0829ec0": false,
                        "856a4a58-5f9c-5379-9150-0b50b5d0fccf": false,
                        "72dd979a-2357-5fac-bed4-da06cea96e4e": false
                    }
                }
            }
        }
    },
    "and[or[isUndefined(PD19a_none=1), not(PD19a_none=1)], not(isUndefined(PD-19a=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "isUnknown",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "PD19a_none"
                            }
                        }
                    },
                    {
                        "type": "not",
                        "wire": {
                            "type": "input",
                            "signal": {
                                "type": "AspectReference",
                                "aspectName": "PD19a_none"
                            }
                        }
                    }
                ],
                "anyToAll": 0.5
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "PD-19a",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "dfbaea46-d479-5f6a-b5ae-babea561c4b7": false,
                                    "cb67a061-ba15-5801-aa3e-743189178f51": false,
                                    "a0cb5d9a-bbcb-5c94-90ab-67f40a7f81e2": false,
                                    "14e5edc2-e598-502f-a03f-3f5ff5903e96": false,
                                    "b771b646-aa5c-5de5-b835-903811177ba9": false,
                                    "9a12757a-819d-5ee0-990e-2cfc5b7c55c0": false,
                                    "df1263a5-d865-513c-8a9b-c36645201066": false,
                                    "bc851432-16bf-52cf-9287-020ba6763695": false,
                                    "b4d064d4-4b58-52de-8403-374108e44562": false,
                                    "0ff1f1a7-4cc7-4756-9f7b-88586a678a63": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    }
};
exports.tests = {
    "pad_ce": [
        {
            "name": "Clinical Diagnosis user: N0N4J3Q aspect: pad_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "PD-10a": {
                    "questionId": "PD-10a",
                    "chosenOptionIds": [
                        "58752e11-5a9a-57b7-b348-7aee292b99bb"
                    ]
                },
                "PD-11a": {
                    "questionId": "PD-11a",
                    "chosenOptionIds": [
                        "ca08db06-d49d-5976-a9f0-cfc3237fac20"
                    ]
                },
                "PD-13a": {
                    "questionId": "PD-13a",
                    "chosenOptionIds": [
                        "7b5d4fdb-8dfa-5538-a1cb-8c8eff41a761"
                    ]
                },
                "PD-14a": {
                    "questionId": "PD-14a",
                    "chosenOptionIds": [
                        "7ab204cd-efb0-5190-9c1f-b5a8746796f3"
                    ]
                },
                "PD-15a": {
                    "questionId": "PD-15a",
                    "chosenOptionIds": [
                        "9b99e9c5-0092-5d1f-aa82-0db2a3278ce8"
                    ]
                },
                "PD-17a": {
                    "questionId": "PD-17a",
                    "chosenOptionIds": [
                        "6dea1f67-fe9f-5e00-adca-06f92c59a358"
                    ]
                },
                "PD-18a": {
                    "questionId": "PD-18a",
                    "chosenOptionIds": [
                        "c44f8577-7031-5122-b8f0-9dd93e6adaf4"
                    ]
                },
                "PD-19.0a": {
                    "questionId": "PD-19.0a",
                    "chosenOptionIds": [
                        "b4ec769f-97d2-5fc9-9dd2-667849759e5f"
                    ]
                },
                "PD-19a": {
                    "questionId": "PD-19a",
                    "chosenOptionIds": [
                        "cb67a061-ba15-5801-aa3e-743189178f51",
                        "dfbaea46-d479-5f6a-b5ae-babea561c4b7",
                        "14e5edc2-e598-502f-a03f-3f5ff5903e96",
                        "b771b646-aa5c-5de5-b835-903811177ba9",
                        "df1263a5-d865-513c-8a9b-c36645201066",
                        "9a12757a-819d-5ee0-990e-2cfc5b7c55c0"
                    ]
                },
                "PD-1a": {
                    "questionId": "PD-1a",
                    "freeValue": 0.010416666666666666
                },
                "PD-3a": {
                    "questionId": "PD-3a",
                    "chosenOptionIds": []
                },
                "PD-4a": {
                    "questionId": "PD-4a",
                    "chosenOptionIds": [
                        "359fd14e-76c8-58e0-a327-b3558bc689f1",
                        "2a760210-55f9-56c9-8244-8837c46fc869",
                        "6370bf14-ef56-5636-9d47-6dd92dcd3949",
                        "ab3abe29-18fb-50dd-8bef-42e35988e746",
                        "fc3d9e34-1469-5c14-8235-b40e01a47e4d",
                        "bf141530-681d-5e5e-8740-0c846343b361",
                        "207eb06d-5508-5584-bbfb-35842d65a1b1",
                        "05c3c9bc-7045-5533-98bb-3335d9815667",
                        "9521c7f6-a5f3-57c8-a85d-e0abe74cbe1d",
                        "3bb0e41e-4edd-5d74-86ce-cecf92bbbadb",
                        "06d84ce6-0739-5fe4-8bc0-44efeae17cc0",
                        "313884fa-19f3-50c7-8a83-0909cb47347c",
                        "09558269-66f2-5c37-9fda-90b76dcda6d4"
                    ]
                },
                "PD-8a": {
                    "questionId": "PD-8a",
                    "chosenOptionIds": [
                        "e28a324b-33cd-5b34-ae0d-1b6653c14634"
                    ]
                },
                "PD-9a": {
                    "questionId": "PD-9a",
                    "chosenOptionIds": [
                        "64c30b78-81b4-53a0-a97d-61b9b3d166a8"
                    ]
                },
                "PD-S": {
                    "questionId": "PD-S",
                    "chosenOptionIds": [
                        "08900817-6bfe-5d29-913d-0f226bdbd8e1"
                    ]
                },
                "PD-s1": {
                    "questionId": "PD-s1",
                    "chosenOptionIds": [
                        "5adf2c06-eb6b-5aa6-b7b4-3acf6125cf84"
                    ]
                },
                "PD-s3": {
                    "questionId": "PD-s3",
                    "chosenOptionIds": [
                        "4fbbfacf-31cb-5c1d-b40e-8707c0829ec0"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: N0N4J3Q aspect: pad_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.81
            },
            "answers": {
                "PD-10a": {
                    "questionId": "PD-10a",
                    "chosenOptionIds": [
                        "58752e11-5a9a-57b7-b348-7aee292b99bb"
                    ]
                },
                "PD-11a": {
                    "questionId": "PD-11a",
                    "chosenOptionIds": [
                        "ca08db06-d49d-5976-a9f0-cfc3237fac20"
                    ]
                },
                "PD-13a": {
                    "questionId": "PD-13a",
                    "chosenOptionIds": [
                        "7b5d4fdb-8dfa-5538-a1cb-8c8eff41a761"
                    ]
                },
                "PD-14a": {
                    "questionId": "PD-14a",
                    "chosenOptionIds": [
                        "7ab204cd-efb0-5190-9c1f-b5a8746796f3"
                    ]
                },
                "PD-15a": {
                    "questionId": "PD-15a",
                    "chosenOptionIds": [
                        "9b99e9c5-0092-5d1f-aa82-0db2a3278ce8"
                    ]
                },
                "PD-17a": {
                    "questionId": "PD-17a",
                    "chosenOptionIds": [
                        "6dea1f67-fe9f-5e00-adca-06f92c59a358"
                    ]
                },
                "PD-18a": {
                    "questionId": "PD-18a",
                    "chosenOptionIds": [
                        "c44f8577-7031-5122-b8f0-9dd93e6adaf4"
                    ]
                },
                "PD-19.0a": {
                    "questionId": "PD-19.0a",
                    "chosenOptionIds": [
                        "b4ec769f-97d2-5fc9-9dd2-667849759e5f"
                    ]
                },
                "PD-19a": {
                    "questionId": "PD-19a",
                    "chosenOptionIds": [
                        "cb67a061-ba15-5801-aa3e-743189178f51",
                        "dfbaea46-d479-5f6a-b5ae-babea561c4b7",
                        "14e5edc2-e598-502f-a03f-3f5ff5903e96",
                        "b771b646-aa5c-5de5-b835-903811177ba9",
                        "df1263a5-d865-513c-8a9b-c36645201066",
                        "9a12757a-819d-5ee0-990e-2cfc5b7c55c0"
                    ]
                },
                "PD-1a": {
                    "questionId": "PD-1a",
                    "freeValue": 0.010416666666666666
                },
                "PD-3a": {
                    "questionId": "PD-3a",
                    "chosenOptionIds": []
                },
                "PD-4a": {
                    "questionId": "PD-4a",
                    "chosenOptionIds": [
                        "359fd14e-76c8-58e0-a327-b3558bc689f1",
                        "2a760210-55f9-56c9-8244-8837c46fc869",
                        "6370bf14-ef56-5636-9d47-6dd92dcd3949",
                        "ab3abe29-18fb-50dd-8bef-42e35988e746",
                        "fc3d9e34-1469-5c14-8235-b40e01a47e4d",
                        "bf141530-681d-5e5e-8740-0c846343b361",
                        "207eb06d-5508-5584-bbfb-35842d65a1b1",
                        "05c3c9bc-7045-5533-98bb-3335d9815667",
                        "9521c7f6-a5f3-57c8-a85d-e0abe74cbe1d",
                        "3bb0e41e-4edd-5d74-86ce-cecf92bbbadb",
                        "06d84ce6-0739-5fe4-8bc0-44efeae17cc0",
                        "313884fa-19f3-50c7-8a83-0909cb47347c",
                        "09558269-66f2-5c37-9fda-90b76dcda6d4"
                    ]
                },
                "PD-8a": {
                    "questionId": "PD-8a",
                    "chosenOptionIds": [
                        "e28a324b-33cd-5b34-ae0d-1b6653c14634"
                    ]
                },
                "PD-9a": {
                    "questionId": "PD-9a",
                    "chosenOptionIds": [
                        "64c30b78-81b4-53a0-a97d-61b9b3d166a8"
                    ]
                },
                "PD-S": {
                    "questionId": "PD-S",
                    "chosenOptionIds": [
                        "08900817-6bfe-5d29-913d-0f226bdbd8e1"
                    ]
                },
                "PD-s1": {
                    "questionId": "PD-s1",
                    "chosenOptionIds": [
                        "5adf2c06-eb6b-5aa6-b7b4-3acf6125cf84"
                    ]
                },
                "PD-s3": {
                    "questionId": "PD-s3",
                    "chosenOptionIds": [
                        "4fbbfacf-31cb-5c1d-b40e-8707c0829ec0"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: XE7DM0L aspect: pad_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "PD-10a": {
                    "questionId": "PD-10a",
                    "chosenOptionIds": [
                        "791fcdc1-132b-572e-8bc7-9de09fcc5cfb"
                    ]
                },
                "PD-11a": {
                    "questionId": "PD-11a",
                    "chosenOptionIds": [
                        "102cc555-ba29-55b9-b619-10976c6782e2"
                    ]
                },
                "PD-13a": {
                    "questionId": "PD-13a",
                    "chosenOptionIds": [
                        "49a87d69-42f3-5a88-9e6f-98bb47e130c8"
                    ]
                },
                "PD-14a": {
                    "questionId": "PD-14a",
                    "chosenOptionIds": [
                        "85b6cbb0-6f57-5c28-a3cf-9ded461ed3e4"
                    ]
                },
                "PD-15a": {
                    "questionId": "PD-15a",
                    "chosenOptionIds": [
                        "89496f87-cb5e-58fa-b7cf-b94e6ce45c90"
                    ]
                },
                "PD-17a": {
                    "questionId": "PD-17a",
                    "chosenOptionIds": [
                        "d16d5209-9ac4-5bf9-adef-0cdb791aa379"
                    ]
                },
                "PD-18a": {
                    "questionId": "PD-18a",
                    "chosenOptionIds": [
                        "57040a04-d36e-552f-b7f8-9b54882ed290"
                    ]
                },
                "PD-19.0a": {
                    "questionId": "PD-19.0a",
                    "chosenOptionIds": [
                        "c2ff6ef8-9fa1-5f4f-b8dd-f24d7e67d086"
                    ]
                },
                "PD-19a": {
                    "questionId": "PD-19a",
                    "chosenOptionIds": [
                        "dfbaea46-d479-5f6a-b5ae-babea561c4b7",
                        "cb67a061-ba15-5801-aa3e-743189178f51",
                        "a0cb5d9a-bbcb-5c94-90ab-67f40a7f81e2",
                        "14e5edc2-e598-502f-a03f-3f5ff5903e96",
                        "b771b646-aa5c-5de5-b835-903811177ba9",
                        "9a12757a-819d-5ee0-990e-2cfc5b7c55c0",
                        "df1263a5-d865-513c-8a9b-c36645201066",
                        "bc851432-16bf-52cf-9287-020ba6763695",
                        "b4d064d4-4b58-52de-8403-374108e44562"
                    ]
                },
                "PD-1a": {
                    "questionId": "PD-1a",
                    "freeValue": 180
                },
                "PD-3a": {
                    "questionId": "PD-3a",
                    "chosenOptionIds": [
                        "21982882-23e9-5a7c-88f0-1a2325372560"
                    ]
                },
                "PD-4a": {
                    "questionId": "PD-4a",
                    "chosenOptionIds": [
                        "cd09d547-49fa-5075-942e-c2445a1c9687",
                        "f789ab51-5fa9-537b-96b0-08f8cb294040",
                        "359fd14e-76c8-58e0-a327-b3558bc689f1",
                        "ab3abe29-18fb-50dd-8bef-42e35988e746",
                        "fc3d9e34-1469-5c14-8235-b40e01a47e4d",
                        "3bb0e41e-4edd-5d74-86ce-cecf92bbbadb",
                        "040cb5af-b6c0-5561-821f-922bc98f255b",
                        "207eb06d-5508-5584-bbfb-35842d65a1b1",
                        "bf141530-681d-5e5e-8740-0c846343b361",
                        "05c3c9bc-7045-5533-98bb-3335d9815667"
                    ]
                },
                "PD-8a": {
                    "questionId": "PD-8a",
                    "chosenOptionIds": [
                        "e28a324b-33cd-5b34-ae0d-1b6653c14634"
                    ]
                },
                "PD-9a": {
                    "questionId": "PD-9a",
                    "chosenOptionIds": [
                        "350fac3e-6a71-5040-8512-64f34f758bd5"
                    ]
                },
                "PD-S": {
                    "questionId": "PD-S",
                    "chosenOptionIds": [
                        "08900817-6bfe-5d29-913d-0f226bdbd8e1"
                    ]
                },
                "PD-s1": {
                    "questionId": "PD-s1",
                    "chosenOptionIds": [
                        "5adf2c06-eb6b-5aa6-b7b4-3acf6125cf84"
                    ]
                },
                "PD-s3": {
                    "questionId": "PD-s3",
                    "chosenOptionIds": [
                        "72dd979a-2357-5fac-bed4-da06cea96e4e"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: XE7DM0L aspect: pad_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.31
            },
            "answers": {
                "PD-10a": {
                    "questionId": "PD-10a",
                    "chosenOptionIds": [
                        "791fcdc1-132b-572e-8bc7-9de09fcc5cfb"
                    ]
                },
                "PD-11a": {
                    "questionId": "PD-11a",
                    "chosenOptionIds": [
                        "102cc555-ba29-55b9-b619-10976c6782e2"
                    ]
                },
                "PD-13a": {
                    "questionId": "PD-13a",
                    "chosenOptionIds": [
                        "49a87d69-42f3-5a88-9e6f-98bb47e130c8"
                    ]
                },
                "PD-14a": {
                    "questionId": "PD-14a",
                    "chosenOptionIds": [
                        "85b6cbb0-6f57-5c28-a3cf-9ded461ed3e4"
                    ]
                },
                "PD-15a": {
                    "questionId": "PD-15a",
                    "chosenOptionIds": [
                        "89496f87-cb5e-58fa-b7cf-b94e6ce45c90"
                    ]
                },
                "PD-17a": {
                    "questionId": "PD-17a",
                    "chosenOptionIds": [
                        "d16d5209-9ac4-5bf9-adef-0cdb791aa379"
                    ]
                },
                "PD-18a": {
                    "questionId": "PD-18a",
                    "chosenOptionIds": [
                        "57040a04-d36e-552f-b7f8-9b54882ed290"
                    ]
                },
                "PD-19.0a": {
                    "questionId": "PD-19.0a",
                    "chosenOptionIds": [
                        "c2ff6ef8-9fa1-5f4f-b8dd-f24d7e67d086"
                    ]
                },
                "PD-19a": {
                    "questionId": "PD-19a",
                    "chosenOptionIds": [
                        "dfbaea46-d479-5f6a-b5ae-babea561c4b7",
                        "cb67a061-ba15-5801-aa3e-743189178f51",
                        "a0cb5d9a-bbcb-5c94-90ab-67f40a7f81e2",
                        "14e5edc2-e598-502f-a03f-3f5ff5903e96",
                        "b771b646-aa5c-5de5-b835-903811177ba9",
                        "9a12757a-819d-5ee0-990e-2cfc5b7c55c0",
                        "df1263a5-d865-513c-8a9b-c36645201066",
                        "bc851432-16bf-52cf-9287-020ba6763695",
                        "b4d064d4-4b58-52de-8403-374108e44562"
                    ]
                },
                "PD-1a": {
                    "questionId": "PD-1a",
                    "freeValue": 180
                },
                "PD-3a": {
                    "questionId": "PD-3a",
                    "chosenOptionIds": [
                        "21982882-23e9-5a7c-88f0-1a2325372560"
                    ]
                },
                "PD-4a": {
                    "questionId": "PD-4a",
                    "chosenOptionIds": [
                        "cd09d547-49fa-5075-942e-c2445a1c9687",
                        "f789ab51-5fa9-537b-96b0-08f8cb294040",
                        "359fd14e-76c8-58e0-a327-b3558bc689f1",
                        "ab3abe29-18fb-50dd-8bef-42e35988e746",
                        "fc3d9e34-1469-5c14-8235-b40e01a47e4d",
                        "3bb0e41e-4edd-5d74-86ce-cecf92bbbadb",
                        "040cb5af-b6c0-5561-821f-922bc98f255b",
                        "207eb06d-5508-5584-bbfb-35842d65a1b1",
                        "bf141530-681d-5e5e-8740-0c846343b361",
                        "05c3c9bc-7045-5533-98bb-3335d9815667"
                    ]
                },
                "PD-8a": {
                    "questionId": "PD-8a",
                    "chosenOptionIds": [
                        "e28a324b-33cd-5b34-ae0d-1b6653c14634"
                    ]
                },
                "PD-9a": {
                    "questionId": "PD-9a",
                    "chosenOptionIds": [
                        "350fac3e-6a71-5040-8512-64f34f758bd5"
                    ]
                },
                "PD-S": {
                    "questionId": "PD-S",
                    "chosenOptionIds": [
                        "08900817-6bfe-5d29-913d-0f226bdbd8e1"
                    ]
                },
                "PD-s1": {
                    "questionId": "PD-s1",
                    "chosenOptionIds": [
                        "5adf2c06-eb6b-5aa6-b7b4-3acf6125cf84"
                    ]
                },
                "PD-s3": {
                    "questionId": "PD-s3",
                    "chosenOptionIds": [
                        "72dd979a-2357-5fac-bed4-da06cea96e4e"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: 50MDW06 aspect: pad_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "PD-10a": {
                    "questionId": "PD-10a",
                    "chosenOptionIds": [
                        "791fcdc1-132b-572e-8bc7-9de09fcc5cfb"
                    ]
                },
                "PD-11a": {
                    "questionId": "PD-11a",
                    "chosenOptionIds": [
                        "ca08db06-d49d-5976-a9f0-cfc3237fac20"
                    ]
                },
                "PD-13a": {
                    "questionId": "PD-13a",
                    "chosenOptionIds": [
                        "49a87d69-42f3-5a88-9e6f-98bb47e130c8"
                    ]
                },
                "PD-14a": {
                    "questionId": "PD-14a",
                    "chosenOptionIds": [
                        "85b6cbb0-6f57-5c28-a3cf-9ded461ed3e4"
                    ]
                },
                "PD-15a": {
                    "questionId": "PD-15a",
                    "chosenOptionIds": [
                        "89496f87-cb5e-58fa-b7cf-b94e6ce45c90"
                    ]
                },
                "PD-17a": {
                    "questionId": "PD-17a",
                    "chosenOptionIds": [
                        "6dea1f67-fe9f-5e00-adca-06f92c59a358"
                    ]
                },
                "PD-18a": {
                    "questionId": "PD-18a",
                    "chosenOptionIds": [
                        "57040a04-d36e-552f-b7f8-9b54882ed290"
                    ]
                },
                "PD-19.0a": {
                    "questionId": "PD-19.0a",
                    "chosenOptionIds": [
                        "b4ec769f-97d2-5fc9-9dd2-667849759e5f"
                    ]
                },
                "PD-19a": {
                    "questionId": "PD-19a",
                    "chosenOptionIds": [
                        "14e5edc2-e598-502f-a03f-3f5ff5903e96",
                        "b771b646-aa5c-5de5-b835-903811177ba9",
                        "a0cb5d9a-bbcb-5c94-90ab-67f40a7f81e2",
                        "9a12757a-819d-5ee0-990e-2cfc5b7c55c0"
                    ]
                },
                "PD-1a": {
                    "questionId": "PD-1a",
                    "freeValue": 0.16666666666666666
                },
                "PD-3a": {
                    "questionId": "PD-3a",
                    "chosenOptionIds": [
                        "21982882-23e9-5a7c-88f0-1a2325372560"
                    ]
                },
                "PD-4a": {
                    "questionId": "PD-4a",
                    "chosenOptionIds": [
                        "cd09d547-49fa-5075-942e-c2445a1c9687",
                        "05c3c9bc-7045-5533-98bb-3335d9815667",
                        "ab3abe29-18fb-50dd-8bef-42e35988e746",
                        "207eb06d-5508-5584-bbfb-35842d65a1b1",
                        "6370bf14-ef56-5636-9d47-6dd92dcd3949"
                    ]
                },
                "PD-8a": {
                    "questionId": "PD-8a",
                    "chosenOptionIds": [
                        "b3cdd648-01d1-5e38-87aa-0d7e3aaa11f9"
                    ]
                },
                "PD-9a": {
                    "questionId": "PD-9a",
                    "chosenOptionIds": [
                        "350fac3e-6a71-5040-8512-64f34f758bd5"
                    ]
                },
                "PD-S": {
                    "questionId": "PD-S",
                    "chosenOptionIds": [
                        "08900817-6bfe-5d29-913d-0f226bdbd8e1"
                    ]
                },
                "PD-s1": {
                    "questionId": "PD-s1",
                    "chosenOptionIds": [
                        "5adf2c06-eb6b-5aa6-b7b4-3acf6125cf84"
                    ]
                },
                "PD-s3": {
                    "questionId": "PD-s3",
                    "chosenOptionIds": [
                        "4fbbfacf-31cb-5c1d-b40e-8707c0829ec0"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: 50MDW06 aspect: pad_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.3
            },
            "answers": {
                "PD-10a": {
                    "questionId": "PD-10a",
                    "chosenOptionIds": [
                        "791fcdc1-132b-572e-8bc7-9de09fcc5cfb"
                    ]
                },
                "PD-11a": {
                    "questionId": "PD-11a",
                    "chosenOptionIds": [
                        "ca08db06-d49d-5976-a9f0-cfc3237fac20"
                    ]
                },
                "PD-13a": {
                    "questionId": "PD-13a",
                    "chosenOptionIds": [
                        "49a87d69-42f3-5a88-9e6f-98bb47e130c8"
                    ]
                },
                "PD-14a": {
                    "questionId": "PD-14a",
                    "chosenOptionIds": [
                        "85b6cbb0-6f57-5c28-a3cf-9ded461ed3e4"
                    ]
                },
                "PD-15a": {
                    "questionId": "PD-15a",
                    "chosenOptionIds": [
                        "89496f87-cb5e-58fa-b7cf-b94e6ce45c90"
                    ]
                },
                "PD-17a": {
                    "questionId": "PD-17a",
                    "chosenOptionIds": [
                        "6dea1f67-fe9f-5e00-adca-06f92c59a358"
                    ]
                },
                "PD-18a": {
                    "questionId": "PD-18a",
                    "chosenOptionIds": [
                        "57040a04-d36e-552f-b7f8-9b54882ed290"
                    ]
                },
                "PD-19.0a": {
                    "questionId": "PD-19.0a",
                    "chosenOptionIds": [
                        "b4ec769f-97d2-5fc9-9dd2-667849759e5f"
                    ]
                },
                "PD-19a": {
                    "questionId": "PD-19a",
                    "chosenOptionIds": [
                        "14e5edc2-e598-502f-a03f-3f5ff5903e96",
                        "b771b646-aa5c-5de5-b835-903811177ba9",
                        "a0cb5d9a-bbcb-5c94-90ab-67f40a7f81e2",
                        "9a12757a-819d-5ee0-990e-2cfc5b7c55c0"
                    ]
                },
                "PD-1a": {
                    "questionId": "PD-1a",
                    "freeValue": 0.16666666666666666
                },
                "PD-3a": {
                    "questionId": "PD-3a",
                    "chosenOptionIds": [
                        "21982882-23e9-5a7c-88f0-1a2325372560"
                    ]
                },
                "PD-4a": {
                    "questionId": "PD-4a",
                    "chosenOptionIds": [
                        "cd09d547-49fa-5075-942e-c2445a1c9687",
                        "05c3c9bc-7045-5533-98bb-3335d9815667",
                        "ab3abe29-18fb-50dd-8bef-42e35988e746",
                        "207eb06d-5508-5584-bbfb-35842d65a1b1",
                        "6370bf14-ef56-5636-9d47-6dd92dcd3949"
                    ]
                },
                "PD-8a": {
                    "questionId": "PD-8a",
                    "chosenOptionIds": [
                        "b3cdd648-01d1-5e38-87aa-0d7e3aaa11f9"
                    ]
                },
                "PD-9a": {
                    "questionId": "PD-9a",
                    "chosenOptionIds": [
                        "350fac3e-6a71-5040-8512-64f34f758bd5"
                    ]
                },
                "PD-S": {
                    "questionId": "PD-S",
                    "chosenOptionIds": [
                        "08900817-6bfe-5d29-913d-0f226bdbd8e1"
                    ]
                },
                "PD-s1": {
                    "questionId": "PD-s1",
                    "chosenOptionIds": [
                        "5adf2c06-eb6b-5aa6-b7b4-3acf6125cf84"
                    ]
                },
                "PD-s3": {
                    "questionId": "PD-s3",
                    "chosenOptionIds": [
                        "4fbbfacf-31cb-5c1d-b40e-8707c0829ec0"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: 4EGRG3P aspect: pad_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "PD-10a": {
                    "questionId": "PD-10a",
                    "chosenOptionIds": [
                        "791fcdc1-132b-572e-8bc7-9de09fcc5cfb"
                    ]
                },
                "PD-11a": {
                    "questionId": "PD-11a",
                    "chosenOptionIds": [
                        "ca08db06-d49d-5976-a9f0-cfc3237fac20"
                    ]
                },
                "PD-13a": {
                    "questionId": "PD-13a",
                    "chosenOptionIds": [
                        "7b5d4fdb-8dfa-5538-a1cb-8c8eff41a761"
                    ]
                },
                "PD-14a": {
                    "questionId": "PD-14a",
                    "chosenOptionIds": [
                        "85b6cbb0-6f57-5c28-a3cf-9ded461ed3e4"
                    ]
                },
                "PD-15a": {
                    "questionId": "PD-15a",
                    "chosenOptionIds": [
                        "89496f87-cb5e-58fa-b7cf-b94e6ce45c90"
                    ]
                },
                "PD-17a": {
                    "questionId": "PD-17a",
                    "chosenOptionIds": [
                        "fd9a4b5a-6870-5a0b-bbdc-c3a7ba0eed4f"
                    ]
                },
                "PD-18a": {
                    "questionId": "PD-18a",
                    "chosenOptionIds": [
                        "57040a04-d36e-552f-b7f8-9b54882ed290"
                    ]
                },
                "PD-19.0a": {
                    "questionId": "PD-19.0a",
                    "chosenOptionIds": [
                        "f9006ec3-1581-5159-a4bb-c73bd1c0468b"
                    ]
                },
                "PD-19a": {
                    "questionId": "PD-19a",
                    "chosenOptionIds": [
                        "dfbaea46-d479-5f6a-b5ae-babea561c4b7",
                        "cb67a061-ba15-5801-aa3e-743189178f51",
                        "a0cb5d9a-bbcb-5c94-90ab-67f40a7f81e2",
                        "14e5edc2-e598-502f-a03f-3f5ff5903e96",
                        "b771b646-aa5c-5de5-b835-903811177ba9",
                        "9a12757a-819d-5ee0-990e-2cfc5b7c55c0",
                        "df1263a5-d865-513c-8a9b-c36645201066",
                        "bc851432-16bf-52cf-9287-020ba6763695"
                    ]
                },
                "PD-1a": {
                    "questionId": "PD-1a",
                    "freeValue": 0.010416666666666666
                },
                "PD-3a": {
                    "questionId": "PD-3a",
                    "chosenOptionIds": [
                        "21982882-23e9-5a7c-88f0-1a2325372560"
                    ]
                },
                "PD-4a": {
                    "questionId": "PD-4a",
                    "chosenOptionIds": [
                        "cd09d547-49fa-5075-942e-c2445a1c9687",
                        "f789ab51-5fa9-537b-96b0-08f8cb294040",
                        "359fd14e-76c8-58e0-a327-b3558bc689f1",
                        "09558269-66f2-5c37-9fda-90b76dcda6d4",
                        "6370bf14-ef56-5636-9d47-6dd92dcd3949",
                        "bf141530-681d-5e5e-8740-0c846343b361",
                        "fc3d9e34-1469-5c14-8235-b40e01a47e4d",
                        "ab3abe29-18fb-50dd-8bef-42e35988e746",
                        "2a760210-55f9-56c9-8244-8837c46fc869",
                        "207eb06d-5508-5584-bbfb-35842d65a1b1",
                        "05c3c9bc-7045-5533-98bb-3335d9815667",
                        "3bb0e41e-4edd-5d74-86ce-cecf92bbbadb",
                        "06d84ce6-0739-5fe4-8bc0-44efeae17cc0"
                    ]
                },
                "PD-8a": {
                    "questionId": "PD-8a",
                    "chosenOptionIds": [
                        "4490ebc3-f879-5be2-b5d2-fd8bc35b02b9"
                    ]
                },
                "PD-9a": {
                    "questionId": "PD-9a",
                    "chosenOptionIds": [
                        "9b567469-85f9-58f7-9665-622d04adadd8"
                    ]
                },
                "PD-S": {
                    "questionId": "PD-S",
                    "chosenOptionIds": [
                        "08900817-6bfe-5d29-913d-0f226bdbd8e1"
                    ]
                },
                "PD-s1": {
                    "questionId": "PD-s1",
                    "chosenOptionIds": [
                        "5adf2c06-eb6b-5aa6-b7b4-3acf6125cf84"
                    ]
                },
                "PD-s3": {
                    "questionId": "PD-s3",
                    "chosenOptionIds": [
                        "4fbbfacf-31cb-5c1d-b40e-8707c0829ec0"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: 4EGRG3P aspect: pad_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.3
            },
            "answers": {
                "PD-10a": {
                    "questionId": "PD-10a",
                    "chosenOptionIds": [
                        "791fcdc1-132b-572e-8bc7-9de09fcc5cfb"
                    ]
                },
                "PD-11a": {
                    "questionId": "PD-11a",
                    "chosenOptionIds": [
                        "ca08db06-d49d-5976-a9f0-cfc3237fac20"
                    ]
                },
                "PD-13a": {
                    "questionId": "PD-13a",
                    "chosenOptionIds": [
                        "7b5d4fdb-8dfa-5538-a1cb-8c8eff41a761"
                    ]
                },
                "PD-14a": {
                    "questionId": "PD-14a",
                    "chosenOptionIds": [
                        "85b6cbb0-6f57-5c28-a3cf-9ded461ed3e4"
                    ]
                },
                "PD-15a": {
                    "questionId": "PD-15a",
                    "chosenOptionIds": [
                        "89496f87-cb5e-58fa-b7cf-b94e6ce45c90"
                    ]
                },
                "PD-17a": {
                    "questionId": "PD-17a",
                    "chosenOptionIds": [
                        "fd9a4b5a-6870-5a0b-bbdc-c3a7ba0eed4f"
                    ]
                },
                "PD-18a": {
                    "questionId": "PD-18a",
                    "chosenOptionIds": [
                        "57040a04-d36e-552f-b7f8-9b54882ed290"
                    ]
                },
                "PD-19.0a": {
                    "questionId": "PD-19.0a",
                    "chosenOptionIds": [
                        "f9006ec3-1581-5159-a4bb-c73bd1c0468b"
                    ]
                },
                "PD-19a": {
                    "questionId": "PD-19a",
                    "chosenOptionIds": [
                        "dfbaea46-d479-5f6a-b5ae-babea561c4b7",
                        "cb67a061-ba15-5801-aa3e-743189178f51",
                        "a0cb5d9a-bbcb-5c94-90ab-67f40a7f81e2",
                        "14e5edc2-e598-502f-a03f-3f5ff5903e96",
                        "b771b646-aa5c-5de5-b835-903811177ba9",
                        "9a12757a-819d-5ee0-990e-2cfc5b7c55c0",
                        "df1263a5-d865-513c-8a9b-c36645201066",
                        "bc851432-16bf-52cf-9287-020ba6763695"
                    ]
                },
                "PD-1a": {
                    "questionId": "PD-1a",
                    "freeValue": 0.010416666666666666
                },
                "PD-3a": {
                    "questionId": "PD-3a",
                    "chosenOptionIds": [
                        "21982882-23e9-5a7c-88f0-1a2325372560"
                    ]
                },
                "PD-4a": {
                    "questionId": "PD-4a",
                    "chosenOptionIds": [
                        "cd09d547-49fa-5075-942e-c2445a1c9687",
                        "f789ab51-5fa9-537b-96b0-08f8cb294040",
                        "359fd14e-76c8-58e0-a327-b3558bc689f1",
                        "09558269-66f2-5c37-9fda-90b76dcda6d4",
                        "6370bf14-ef56-5636-9d47-6dd92dcd3949",
                        "bf141530-681d-5e5e-8740-0c846343b361",
                        "fc3d9e34-1469-5c14-8235-b40e01a47e4d",
                        "ab3abe29-18fb-50dd-8bef-42e35988e746",
                        "2a760210-55f9-56c9-8244-8837c46fc869",
                        "207eb06d-5508-5584-bbfb-35842d65a1b1",
                        "05c3c9bc-7045-5533-98bb-3335d9815667",
                        "3bb0e41e-4edd-5d74-86ce-cecf92bbbadb",
                        "06d84ce6-0739-5fe4-8bc0-44efeae17cc0"
                    ]
                },
                "PD-8a": {
                    "questionId": "PD-8a",
                    "chosenOptionIds": [
                        "4490ebc3-f879-5be2-b5d2-fd8bc35b02b9"
                    ]
                },
                "PD-9a": {
                    "questionId": "PD-9a",
                    "chosenOptionIds": [
                        "9b567469-85f9-58f7-9665-622d04adadd8"
                    ]
                },
                "PD-S": {
                    "questionId": "PD-S",
                    "chosenOptionIds": [
                        "08900817-6bfe-5d29-913d-0f226bdbd8e1"
                    ]
                },
                "PD-s1": {
                    "questionId": "PD-s1",
                    "chosenOptionIds": [
                        "5adf2c06-eb6b-5aa6-b7b4-3acf6125cf84"
                    ]
                },
                "PD-s3": {
                    "questionId": "PD-s3",
                    "chosenOptionIds": [
                        "4fbbfacf-31cb-5c1d-b40e-8707c0829ec0"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: XE72M0L aspect: pad_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": true
            },
            "answers": {
                "PD-10a": {
                    "questionId": "PD-10a",
                    "chosenOptionIds": [
                        "58752e11-5a9a-57b7-b348-7aee292b99bb"
                    ]
                },
                "PD-11a": {
                    "questionId": "PD-11a",
                    "chosenOptionIds": [
                        "9a95a69e-26f4-5e9a-a828-8574672e987b"
                    ]
                },
                "PD-13a": {
                    "questionId": "PD-13a",
                    "chosenOptionIds": [
                        "7b5d4fdb-8dfa-5538-a1cb-8c8eff41a761"
                    ]
                },
                "PD-14a": {
                    "questionId": "PD-14a",
                    "chosenOptionIds": [
                        "aea711e7-9612-51a3-b80b-36ab25e459e6"
                    ]
                },
                "PD-15a": {
                    "questionId": "PD-15a",
                    "chosenOptionIds": [
                        "34790fef-2329-565c-9cd4-89b781b0a5d5"
                    ]
                },
                "PD-17a": {
                    "questionId": "PD-17a",
                    "chosenOptionIds": [
                        "fd9a4b5a-6870-5a0b-bbdc-c3a7ba0eed4f"
                    ]
                },
                "PD-18a": {
                    "questionId": "PD-18a",
                    "chosenOptionIds": [
                        "951ca64b-47da-5e2e-ad2e-b9078fa5053e"
                    ]
                },
                "PD-19.0a": {
                    "questionId": "PD-19.0a",
                    "chosenOptionIds": [
                        "f9006ec3-1581-5159-a4bb-c73bd1c0468b"
                    ]
                },
                "PD-19a": {
                    "questionId": "PD-19a",
                    "chosenOptionIds": [
                        "cb67a061-ba15-5801-aa3e-743189178f51",
                        "a0cb5d9a-bbcb-5c94-90ab-67f40a7f81e2",
                        "14e5edc2-e598-502f-a03f-3f5ff5903e96",
                        "b771b646-aa5c-5de5-b835-903811177ba9",
                        "9a12757a-819d-5ee0-990e-2cfc5b7c55c0",
                        "df1263a5-d865-513c-8a9b-c36645201066",
                        "bc851432-16bf-52cf-9287-020ba6763695",
                        "b4d064d4-4b58-52de-8403-374108e44562"
                    ]
                },
                "PD-1a": {
                    "questionId": "PD-1a",
                    "freeValue": 1
                },
                "PD-3a": {
                    "questionId": "PD-3a",
                    "chosenOptionIds": [
                        "3b1605f2-9652-5958-8059-bd8b7aec2f2d"
                    ]
                },
                "PD-4a": {
                    "questionId": "PD-4a",
                    "chosenOptionIds": [
                        "cd09d547-49fa-5075-942e-c2445a1c9687",
                        "f789ab51-5fa9-537b-96b0-08f8cb294040",
                        "2a760210-55f9-56c9-8244-8837c46fc869",
                        "6370bf14-ef56-5636-9d47-6dd92dcd3949",
                        "207eb06d-5508-5584-bbfb-35842d65a1b1"
                    ]
                },
                "PD-8a": {
                    "questionId": "PD-8a",
                    "chosenOptionIds": [
                        "ab6d392b-dfe7-5f88-85c1-830fe8ee1b52"
                    ]
                },
                "PD-9a": {
                    "questionId": "PD-9a",
                    "chosenOptionIds": [
                        "b94cfc0c-3bc7-52d9-9a59-92d677c89c92"
                    ]
                },
                "PD-S": {
                    "questionId": "PD-S",
                    "chosenOptionIds": [
                        "08900817-6bfe-5d29-913d-0f226bdbd8e1"
                    ]
                },
                "PD-s1": {
                    "questionId": "PD-s1",
                    "chosenOptionIds": [
                        "5adf2c06-eb6b-5aa6-b7b4-3acf6125cf84"
                    ]
                },
                "PD-s3": {
                    "questionId": "PD-s3",
                    "chosenOptionIds": [
                        "f3393659-4a98-567b-9cd1-178b49b25f7f"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: XE72M0L aspect: pad_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.66
            },
            "answers": {
                "PD-10a": {
                    "questionId": "PD-10a",
                    "chosenOptionIds": [
                        "58752e11-5a9a-57b7-b348-7aee292b99bb"
                    ]
                },
                "PD-11a": {
                    "questionId": "PD-11a",
                    "chosenOptionIds": [
                        "9a95a69e-26f4-5e9a-a828-8574672e987b"
                    ]
                },
                "PD-13a": {
                    "questionId": "PD-13a",
                    "chosenOptionIds": [
                        "7b5d4fdb-8dfa-5538-a1cb-8c8eff41a761"
                    ]
                },
                "PD-14a": {
                    "questionId": "PD-14a",
                    "chosenOptionIds": [
                        "aea711e7-9612-51a3-b80b-36ab25e459e6"
                    ]
                },
                "PD-15a": {
                    "questionId": "PD-15a",
                    "chosenOptionIds": [
                        "34790fef-2329-565c-9cd4-89b781b0a5d5"
                    ]
                },
                "PD-17a": {
                    "questionId": "PD-17a",
                    "chosenOptionIds": [
                        "fd9a4b5a-6870-5a0b-bbdc-c3a7ba0eed4f"
                    ]
                },
                "PD-18a": {
                    "questionId": "PD-18a",
                    "chosenOptionIds": [
                        "951ca64b-47da-5e2e-ad2e-b9078fa5053e"
                    ]
                },
                "PD-19.0a": {
                    "questionId": "PD-19.0a",
                    "chosenOptionIds": [
                        "f9006ec3-1581-5159-a4bb-c73bd1c0468b"
                    ]
                },
                "PD-19a": {
                    "questionId": "PD-19a",
                    "chosenOptionIds": [
                        "cb67a061-ba15-5801-aa3e-743189178f51",
                        "a0cb5d9a-bbcb-5c94-90ab-67f40a7f81e2",
                        "14e5edc2-e598-502f-a03f-3f5ff5903e96",
                        "b771b646-aa5c-5de5-b835-903811177ba9",
                        "9a12757a-819d-5ee0-990e-2cfc5b7c55c0",
                        "df1263a5-d865-513c-8a9b-c36645201066",
                        "bc851432-16bf-52cf-9287-020ba6763695",
                        "b4d064d4-4b58-52de-8403-374108e44562"
                    ]
                },
                "PD-1a": {
                    "questionId": "PD-1a",
                    "freeValue": 1
                },
                "PD-3a": {
                    "questionId": "PD-3a",
                    "chosenOptionIds": [
                        "3b1605f2-9652-5958-8059-bd8b7aec2f2d"
                    ]
                },
                "PD-4a": {
                    "questionId": "PD-4a",
                    "chosenOptionIds": [
                        "cd09d547-49fa-5075-942e-c2445a1c9687",
                        "f789ab51-5fa9-537b-96b0-08f8cb294040",
                        "2a760210-55f9-56c9-8244-8837c46fc869",
                        "6370bf14-ef56-5636-9d47-6dd92dcd3949",
                        "207eb06d-5508-5584-bbfb-35842d65a1b1"
                    ]
                },
                "PD-8a": {
                    "questionId": "PD-8a",
                    "chosenOptionIds": [
                        "ab6d392b-dfe7-5f88-85c1-830fe8ee1b52"
                    ]
                },
                "PD-9a": {
                    "questionId": "PD-9a",
                    "chosenOptionIds": [
                        "b94cfc0c-3bc7-52d9-9a59-92d677c89c92"
                    ]
                },
                "PD-S": {
                    "questionId": "PD-S",
                    "chosenOptionIds": [
                        "08900817-6bfe-5d29-913d-0f226bdbd8e1"
                    ]
                },
                "PD-s1": {
                    "questionId": "PD-s1",
                    "chosenOptionIds": [
                        "5adf2c06-eb6b-5aa6-b7b4-3acf6125cf84"
                    ]
                },
                "PD-s3": {
                    "questionId": "PD-s3",
                    "chosenOptionIds": [
                        "f3393659-4a98-567b-9cd1-178b49b25f7f"
                    ]
                }
            }
        },
        {
            "name": "Clinical Diagnosis user: R047J04 aspect: pad_ce ",
            "expected": {
                "type": "ClinicalDiagnosis",
                "value": false
            },
            "answers": {
                "PD-10a": {
                    "questionId": "PD-10a",
                    "chosenOptionIds": [
                        "791fcdc1-132b-572e-8bc7-9de09fcc5cfb"
                    ]
                },
                "PD-11a": {
                    "questionId": "PD-11a",
                    "chosenOptionIds": [
                        "e97c8999-e8ba-5a8b-866d-550df538a261"
                    ]
                },
                "PD-13a": {
                    "questionId": "PD-13a",
                    "chosenOptionIds": [
                        "423a96f8-8bfd-5b6c-a1a9-c774b8791c76"
                    ]
                },
                "PD-14a": {
                    "questionId": "PD-14a",
                    "chosenOptionIds": [
                        "369a830f-a251-5df9-a1bb-a83ba74b02ec"
                    ]
                },
                "PD-15a": {
                    "questionId": "PD-15a",
                    "chosenOptionIds": [
                        "9b99e9c5-0092-5d1f-aa82-0db2a3278ce8"
                    ]
                },
                "PD-17a": {
                    "questionId": "PD-17a",
                    "chosenOptionIds": [
                        "fd9a4b5a-6870-5a0b-bbdc-c3a7ba0eed4f"
                    ]
                },
                "PD-18a": {
                    "questionId": "PD-18a",
                    "chosenOptionIds": [
                        "57040a04-d36e-552f-b7f8-9b54882ed290"
                    ]
                },
                "PD-19.0a": {
                    "questionId": "PD-19.0a",
                    "chosenOptionIds": [
                        "b4ec769f-97d2-5fc9-9dd2-667849759e5f"
                    ]
                },
                "PD-19a": {
                    "questionId": "PD-19a",
                    "chosenOptionIds": [
                        "cb67a061-ba15-5801-aa3e-743189178f51",
                        "a0cb5d9a-bbcb-5c94-90ab-67f40a7f81e2",
                        "14e5edc2-e598-502f-a03f-3f5ff5903e96",
                        "9a12757a-819d-5ee0-990e-2cfc5b7c55c0",
                        "df1263a5-d865-513c-8a9b-c36645201066",
                        "bc851432-16bf-52cf-9287-020ba6763695",
                        "b4d064d4-4b58-52de-8403-374108e44562"
                    ]
                },
                "PD-1a": {
                    "questionId": "PD-1a",
                    "freeValue": 0.041666666666666664
                },
                "PD-3a": {
                    "questionId": "PD-3a",
                    "chosenOptionIds": [
                        "21982882-23e9-5a7c-88f0-1a2325372560"
                    ]
                },
                "PD-4a": {
                    "questionId": "PD-4a",
                    "chosenOptionIds": [
                        "f789ab51-5fa9-537b-96b0-08f8cb294040",
                        "207eb06d-5508-5584-bbfb-35842d65a1b1"
                    ]
                },
                "PD-8a": {
                    "questionId": "PD-8a",
                    "chosenOptionIds": [
                        "4490ebc3-f879-5be2-b5d2-fd8bc35b02b9"
                    ]
                },
                "PD-9a": {
                    "questionId": "PD-9a",
                    "chosenOptionIds": [
                        "64c30b78-81b4-53a0-a97d-61b9b3d166a8"
                    ]
                },
                "PD-S": {
                    "questionId": "PD-S",
                    "chosenOptionIds": [
                        "08900817-6bfe-5d29-913d-0f226bdbd8e1"
                    ]
                },
                "PD-s1": {
                    "questionId": "PD-s1",
                    "chosenOptionIds": [
                        "5adf2c06-eb6b-5aa6-b7b4-3acf6125cf84"
                    ]
                },
                "PD-s3": {
                    "questionId": "PD-s3",
                    "chosenOptionIds": [
                        "f3393659-4a98-567b-9cd1-178b49b25f7f"
                    ]
                }
            }
        },
        {
            "name": "Censeo vPrevious user: R047J04 aspect: pad_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.05
            },
            "answers": {
                "PD-10a": {
                    "questionId": "PD-10a",
                    "chosenOptionIds": [
                        "791fcdc1-132b-572e-8bc7-9de09fcc5cfb"
                    ]
                },
                "PD-11a": {
                    "questionId": "PD-11a",
                    "chosenOptionIds": [
                        "e97c8999-e8ba-5a8b-866d-550df538a261"
                    ]
                },
                "PD-13a": {
                    "questionId": "PD-13a",
                    "chosenOptionIds": [
                        "423a96f8-8bfd-5b6c-a1a9-c774b8791c76"
                    ]
                },
                "PD-14a": {
                    "questionId": "PD-14a",
                    "chosenOptionIds": [
                        "369a830f-a251-5df9-a1bb-a83ba74b02ec"
                    ]
                },
                "PD-15a": {
                    "questionId": "PD-15a",
                    "chosenOptionIds": [
                        "9b99e9c5-0092-5d1f-aa82-0db2a3278ce8"
                    ]
                },
                "PD-17a": {
                    "questionId": "PD-17a",
                    "chosenOptionIds": [
                        "fd9a4b5a-6870-5a0b-bbdc-c3a7ba0eed4f"
                    ]
                },
                "PD-18a": {
                    "questionId": "PD-18a",
                    "chosenOptionIds": [
                        "57040a04-d36e-552f-b7f8-9b54882ed290"
                    ]
                },
                "PD-19.0a": {
                    "questionId": "PD-19.0a",
                    "chosenOptionIds": [
                        "b4ec769f-97d2-5fc9-9dd2-667849759e5f"
                    ]
                },
                "PD-19a": {
                    "questionId": "PD-19a",
                    "chosenOptionIds": [
                        "cb67a061-ba15-5801-aa3e-743189178f51",
                        "a0cb5d9a-bbcb-5c94-90ab-67f40a7f81e2",
                        "14e5edc2-e598-502f-a03f-3f5ff5903e96",
                        "9a12757a-819d-5ee0-990e-2cfc5b7c55c0",
                        "df1263a5-d865-513c-8a9b-c36645201066",
                        "bc851432-16bf-52cf-9287-020ba6763695",
                        "b4d064d4-4b58-52de-8403-374108e44562"
                    ]
                },
                "PD-1a": {
                    "questionId": "PD-1a",
                    "freeValue": 0.041666666666666664
                },
                "PD-3a": {
                    "questionId": "PD-3a",
                    "chosenOptionIds": [
                        "21982882-23e9-5a7c-88f0-1a2325372560"
                    ]
                },
                "PD-4a": {
                    "questionId": "PD-4a",
                    "chosenOptionIds": [
                        "f789ab51-5fa9-537b-96b0-08f8cb294040",
                        "207eb06d-5508-5584-bbfb-35842d65a1b1"
                    ]
                },
                "PD-8a": {
                    "questionId": "PD-8a",
                    "chosenOptionIds": [
                        "4490ebc3-f879-5be2-b5d2-fd8bc35b02b9"
                    ]
                },
                "PD-9a": {
                    "questionId": "PD-9a",
                    "chosenOptionIds": [
                        "64c30b78-81b4-53a0-a97d-61b9b3d166a8"
                    ]
                },
                "PD-S": {
                    "questionId": "PD-S",
                    "chosenOptionIds": [
                        "08900817-6bfe-5d29-913d-0f226bdbd8e1"
                    ]
                },
                "PD-s1": {
                    "questionId": "PD-s1",
                    "chosenOptionIds": [
                        "5adf2c06-eb6b-5aa6-b7b4-3acf6125cf84"
                    ]
                },
                "PD-s3": {
                    "questionId": "PD-s3",
                    "chosenOptionIds": [
                        "f3393659-4a98-567b-9cd1-178b49b25f7f"
                    ]
                }
            }
        },
        {
            "name": "Tech team adjusted from 49, Censeo vPrevious user: 2E17NED aspect: pad_ce",
            "expected": {
                "type": "CloseTo",
                "value": 0.27
            },
            "answers": {
                "PD-10a": {
                    "questionId": "PD-10a",
                    "chosenOptionIds": [
                        "58752e11-5a9a-57b7-b348-7aee292b99bb"
                    ]
                },
                "PD-11a": {
                    "questionId": "PD-11a",
                    "chosenOptionIds": [
                        "e97c8999-e8ba-5a8b-866d-550df538a261"
                    ]
                },
                "PD-13a": {
                    "questionId": "PD-13a",
                    "chosenOptionIds": [
                        "3519ecd3-0b41-5ec6-9951-b6ca15bfd473"
                    ]
                },
                "PD-14a": {
                    "questionId": "PD-14a",
                    "chosenOptionIds": [
                        "85b6cbb0-6f57-5c28-a3cf-9ded461ed3e4"
                    ]
                },
                "PD-15a": {
                    "questionId": "PD-15a",
                    "chosenOptionIds": [
                        "9b99e9c5-0092-5d1f-aa82-0db2a3278ce8"
                    ]
                },
                "PD-17a": {
                    "questionId": "PD-17a",
                    "chosenOptionIds": [
                        "fd9a4b5a-6870-5a0b-bbdc-c3a7ba0eed4f"
                    ]
                },
                "PD-18a": {
                    "questionId": "PD-18a",
                    "chosenOptionIds": [
                        "9104a0d0-7f58-504d-bede-ea7b7379ea81"
                    ]
                },
                "PD-19.0a": {
                    "questionId": "PD-19.0a",
                    "chosenOptionIds": [
                        "b4ec769f-97d2-5fc9-9dd2-667849759e5f"
                    ]
                },
                "PD-19a": {
                    "questionId": "PD-19a",
                    "chosenOptionIds": [
                        "9a12757a-819d-5ee0-990e-2cfc5b7c55c0",
                        "df1263a5-d865-513c-8a9b-c36645201066",
                        "dfbaea46-d479-5f6a-b5ae-babea561c4b7"
                    ]
                },
                "PD-1a": {
                    "questionId": "PD-1a",
                    "freeValue": 0.010416666666666666
                },
                "PD-3a": {
                    "questionId": "PD-3a",
                    "chosenOptionIds": []
                },
                "PD-4a": {
                    "questionId": "PD-4a",
                    "chosenOptionIds": [
                        "f789ab51-5fa9-537b-96b0-08f8cb294040",
                        "2a760210-55f9-56c9-8244-8837c46fc869",
                        "207eb06d-5508-5584-bbfb-35842d65a1b1"
                    ]
                },
                "PD-8a": {
                    "questionId": "PD-8a",
                    "chosenOptionIds": [
                        "b3cdd648-01d1-5e38-87aa-0d7e3aaa11f9"
                    ]
                },
                "PD-9a": {
                    "questionId": "PD-9a",
                    "chosenOptionIds": [
                        "64c30b78-81b4-53a0-a97d-61b9b3d166a8"
                    ]
                },
                "PD-S": {
                    "questionId": "PD-S",
                    "chosenOptionIds": [
                        "08900817-6bfe-5d29-913d-0f226bdbd8e1"
                    ]
                },
                "PD-s1": {
                    "questionId": "PD-s1",
                    "chosenOptionIds": [
                        "5adf2c06-eb6b-5aa6-b7b4-3acf6125cf84"
                    ]
                },
                "PD-s3": {
                    "questionId": "PD-s3",
                    "chosenOptionIds": [
                        "f3393659-4a98-567b-9cd1-178b49b25f7f"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {
    "PD-s3": "not(PD-s1=0)",
    "PD-1a": "not(PD-s3=0)",
    "PD-3a": "not(PD-s3=0)",
    "PD-4a": "not(PD-s3=0)",
    "PD-8a": "not(PD-s3=0)",
    "PD-9a": "not(PD-s3=0)",
    "PD-10a": "not(PD-s3=0)",
    "PD-11a": "not(PD-s3=0)",
    "PD-13a": "not(PD-s3=0)",
    "PD-14a": "not(PD-s3=0)",
    "PD-15a": "not(PD-s3=0)",
    "PD-17a": "not(PD-s3=0)",
    "PD-18a": "not(PD-s3=0)",
    "PD-18.5b": "not(PD-s3=0)",
    "PD-19a": "not(PD-s3=0)",
    "PD-19.0a": "and[or[isUndefined(PD19a_none=1), not(PD19a_none=1)], not(isUndefined(PD-19a=0))]",
    "PD-S": "and[or[isUndefined(PD19a_none=1), not(PD19a_none=1)], not(isUndefined(PD-19a=0))]"
};
