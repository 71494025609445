import React, { useState, useEffect, useRef, useContext } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useQuery, useLazyQuery } from '@apollo/client'
import { Splash } from './Splash'
import { Maybe, QueryReportDetailsArgs, ReportDetails, ReportResultMessage, UserType } from 'censeo-core'
import { FetchJsonReportData, fetchJsonReportQuery } from 'data/queries/fetchJsonReport'
import { useParams, useLocation } from 'react-router-dom'
import CenseoError from './Error'
import NotFound from './NotFound'
import * as Sentry from '@sentry/react'
import { useAuth } from 'registration/context/auth'
import {
    Page,
    ReportTabs,
    Disclaimer,
    TabLabelClinician,
    Button,
    Text,
    Link,
    DemographicsBar,
} from '@psyomics/components'
import { IPageProps } from '@psyomics/components/components/Page/Page'
import { ReportDetailsData, reportDetailsQuery } from 'data/queries/reportDetails'
import { demoReports, demoReportsfakeDataHealthIT } from 'registration/pages/Demo/ClinicalReports/fakeData'
import { InfoContext, IFU_LINK } from 'configure'
import { posthogCapture } from 'ui/utils/posthog'
import { usePdfDownload } from '../hooks/usePdfDownload'
import { getLocale } from 'registration/utils/locale'
import Header from '../components/Header'
// import { authoriseReportMutation, AuthoriseReportResponse } from 'data/mutations/authoriseReport'
import DemoFooter from '../components/DemoFooter'
import { TabLabel, UserFacing } from '@psyomics/components/components/Report/Report'
import { downloadFromLink } from '../hooks/usePdfDownload'
import { decommissionDate } from '../../../decommission'
import { fetchDemoReport as fetchDemo } from './LoadReportV2'

const defaultUserFacing = 'clinician-default'

export async function fetchDemoReport(reportUrl: string): Promise<ReportDetails | undefined> {
    const response = await fetch(reportUrl)
    const json = await response.json()
    if (!response.ok) {
        Sentry.captureException(new Error('Response not ok'), {
            extra: {
                responseCode: response.status,
                responseContent: json,
            },
            tags: { section: 'Load report' },
        })
        return undefined
    }
    return json
}

const isValidUserFacing = (value: string): value is UserFacing => {
    return ['service-user', 'clinician-default', 'clinician-contextual'].includes(value)
}

const getUserFacingToUse = (
    queryUserFacing: string | null,
    patientFacing?: boolean,
    userType?: Maybe<string>
): UserFacing => {
    if (queryUserFacing && isValidUserFacing(queryUserFacing)) {
        return queryUserFacing
    }

    if (patientFacing || userType === UserType.D2C) {
        return 'service-user'
    }

    return defaultUserFacing
}

export const LoadReportV2: React.FC<{ patientFacing?: boolean }> = ({ patientFacing = false }) => {
    const demoLocale = getLocale()
    const info = useContext(InfoContext)
    // Remove sharing for Health IT
    // const [shareError, setShareError] = useState(false)
    // const [loading, setLoading] = useState(false)
    // const [authoriseReport] = useMutation<AuthoriseReportResponse>(authoriseReportMutation)
    // const [showShareConfirmationModal, setShowShareConfirmationModal] = useState(false)

    let { id } = useParams<{ id: string }>()
    if (id === undefined) {
        // Extract report id from URL in the case when loading a Demo report.
        // Demo Routes are fixed string which include the allowed demo id
        // e.g. report/0f860b00-9c9c-4db4-8877-51a312352090
        // Hence we do not have :id defined for these routes to be able
        // to use useParams()
        id = window.location.pathname.split('/').slice(-1)[0]
    }
    const posthogEventSent = useRef(false)
    const isDemoAssessment = demoReports.ids.includes(id) || demoReportsfakeDataHealthIT.ids.includes(id)
    const scrollingElementRef = useRef<HTMLDivElement>(null)
    const { getCurrentUser } = useAuth()
    const [currentUserId, setCurrentUserId] = useState<string | undefined>()
    const [demoReport, setDemoReport] = useState<ReportDetails | undefined>()
    const [skipPdf, setSkipPdf] = useState<boolean>(false)
    const [orgCode, setOrgCode] = useState<string | undefined>(undefined)
    const [orgName, setOrgName] = useState<string | undefined>(undefined)
    const [, setIsAuthorisedForPatient] = useState<boolean | undefined>(undefined)

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const queryUserFacing = queryParams.get('userFacing') ?? defaultUserFacing

    const pageProps = {
        layout: 'standard',
        width: 'x-wide',
        scrollingElementRef,
        header: <Header patientFacing={patientFacing} demoMenu={isDemoAssessment} orgCode={orgCode}></Header>,
        footer: isDemoAssessment ? <DemoFooter footerType="clinician" /> : undefined,
    } as IPageProps

    const previewPageProps = {
        layout: 'standard',
        width: 'full',
        header: <Header patientFacing={patientFacing} demoMenu={isDemoAssessment} orgCode={orgCode}></Header>,
    } as IPageProps

    // const handleShareConfirm = () => {
    //     setShowShareConfirmationModal(false)
    //     if (setIsAuthorisedForPatient) {
    //         setLoading(true)
    //         authoriseReport({ variables: { assessmentID: id } })
    //             .then((response) => {
    //                 if (response.data?.authoriseReport) {
    //                     setIsAuthorisedForPatient(true)
    //                 }
    //             })
    //             .catch((error) => {
    //                 const errMsg = 'Error authorising report:'
    //                 console.error(errMsg, error)
    //                 setShareError(true)
    //                 Sentry.captureException(errMsg, error)
    //             })
    //             .finally(() => {
    //                 setLoading(false)
    //             })
    //     }
    // }

    useEffect(() => {
        ;(async () => {
            const user = await getCurrentUser()
            const userId = user?.getUsername()
            if (userId) {
                setCurrentUserId(userId)
            } else {
                Sentry.captureException(new Error('Failed to load report'), {
                    extra: {
                        detail: 'Unable to get user id',
                    },
                    tags: { section: 'Load report', reportVersion: 2 },
                })
            }
        })()
    }, [getCurrentUser])

    const {
        loading: dbReportLoading,
        data: dbReportData,
        error: dbReportError,
    } = useQuery<FetchJsonReportData, QueryReportDetailsArgs>(fetchJsonReportQuery, {
        variables: { id },
    })

    const [getReportDetails, { loading: genReportLoading, data: genReportData, error: genReportError }] = useLazyQuery<
        ReportDetailsData,
        QueryReportDetailsArgs
    >(reportDetailsQuery)

    const [activeTab, setActiveTab] = useState<TabLabel>(TabLabelClinician.Summary)
    const handleTabClick = (newTab: TabLabel) => {
        setActiveTab(newTab)
        scrollingElementRef.current?.scrollTo({ behavior: 'smooth', left: 0, top: 0 })
    }

    const [clickedConditionCode, setClickedConditionCode] = useState('')
    const handleNavigateToConditions = (conditionCode?: string) => {
        if (conditionCode) {
            setClickedConditionCode(conditionCode)
        }
        setActiveTab(TabLabelClinician.ConditionDetails)
    }
    const handleNavigateToContext = (section?: string) => {
        //TODO: Handle section
        setActiveTab(TabLabelClinician.PatientContextDetails)
    }

    useEffect(() => {
        const isError = dbReportError || genReportError
        const isNotFound =
            dbReportData?.fetchJsonReport?.message === ReportResultMessage.NotFound &&
            genReportData?.reportDetails?.message === ReportResultMessage.NotFound
        const isAccessDenied =
            dbReportData?.fetchJsonReport?.message === ReportResultMessage.AccessDenied ||
            genReportData?.reportDetails?.message === ReportResultMessage.AccessDenied
        if (isError || isNotFound || isAccessDenied) {
            setSkipPdf(true)
        }
    }, [dbReportError, genReportError, dbReportData, genReportData])
    const { handlePdfDownloadClick, pdfUrl } = usePdfDownload({
        id,
        skip: skipPdf,
        patientFacing,
    })

    useEffect(() => {
        if (!id) {
            Sentry.captureException('No id found for report')
            return
        }
        if (isDemoAssessment) {
            ;(async () => {
                const res = await fetchDemo(demoReports.location(id))
                setDemoReport(res)
            })().catch((error) => {
                Sentry.captureException(error, { tags: { section: 'Failed to load demo report V2' } })
            })
        } else if (
            !dbReportLoading &&
            dbReportData?.fetchJsonReport.message === ReportResultMessage.NotFound &&
            !genReportData
        ) {
            getReportDetails({ variables: { id } })
        }
    }, [dbReportLoading, dbReportData, getReportDetails, id, isDemoAssessment])

    let reportDetails: Maybe<ReportDetails> | undefined
    // let allCompletedReportsForUser: Maybe<Maybe<AllExistingReports>[]> | undefined
    if (isDemoAssessment) {
        reportDetails = demoReport
    } else if (dbReportData?.fetchJsonReport.message === ReportResultMessage.Success) {
        reportDetails = dbReportData.fetchJsonReport.reportDetails
        // allCompletedReportsForUser = dbReportData.fetchJsonReport.allExistingReports
    } else if (genReportData?.reportDetails.message === ReportResultMessage.Success) {
        reportDetails = genReportData.reportDetails.reportDetails
    }

    useEffect(() => {
        if (reportDetails?.organisationConfig?.patientReport && reportDetails.isAuthorisedForPatient !== null) {
            setIsAuthorisedForPatient(reportDetails.isAuthorisedForPatient)
        }
    }, [reportDetails])

    useEffect(() => {
        ;(async () => {
            try {
                const orgResponse = await fetch(`${info?.referralApiUrl}referrals/unauthenticated?assessmentId=${id}`)

                if (!orgResponse.ok) {
                    throw new Error('Failed to fetch org data or posthog capture for report V2')
                }
                const orgData = await orgResponse.json()
                setOrgCode(orgData?.organisationCode)
                setOrgName(orgData?.organisationName)
            } catch (error) {
                Sentry.captureException(error, { extra: { errorMessage: 'Failed to get org data', assessmentId: id } })
            }
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            if (!posthogEventSent.current && reportDetails?.demographics && orgCode && orgName) {
                try {
                    const clinicianUserId = (await getCurrentUser())?.getUsername()

                    posthogCapture(info?.posthogProxyDomain, 'Report viewed', {
                        environment: info?.environment,
                        orgCode: orgCode,
                        orgName: orgName,
                        userType: reportDetails.demographics?.userType,
                        referralCode: reportDetails.demographics?.referralCode,
                        reportId: id,
                        clinicianUserId,
                    })

                    posthogEventSent.current = true
                } catch (error) {
                    Sentry.captureException(error, { extra: { errorMessage: 'Failed to send posthog event', orgCode } })
                }
            }
        })()
    }, [reportDetails, orgCode, orgName])

    const reportIsDecommissioned = reportDetails?.completedDate < decommissionDate.getTime() && !isDemoAssessment

    if (dbReportLoading || genReportLoading) return <Splash />

    if (reportIsDecommissioned && reportDetails) {
        return (
            <Page {...previewPageProps}>
                <Text weight="bold" css={{ mt: 3 }}>
                    This product has been decommissioned, please view the related Field Safety Notice{' '}
                    <Link rel="noreferrer" target="_blank" href="https://mhra-gov.filecamp.com/s/d/EzLebjODMDgP5qcX">
                        <span
                            style={{
                                color: 'var(--c-teal-dark)',
                                textDecoration: 'underline',
                                fontWeight: 'var(--f-weight-bold)',
                            }}
                        >
                            here
                        </span>
                    </Link>{' '}
                    for more details.
                </Text>
                <Text weight="bold" css={{ mb: 6 }}>
                    To continue and still view the report please click the click the &#39;View Report&#39; button.
                </Text>
                <Button
                    appearance="primary"
                    label="View Report"
                    onClick={() => pdfUrl && window.open(pdfUrl, '_blank', 'noopener,noreferrer')}
                ></Button>
            </Page>
        )
    }

    if (!isDemoAssessment && ((dbReportError && !genReportData) || (genReportError && !dbReportData))) {
        const errorToReport = dbReportError || genReportError
        Sentry.captureException(errorToReport, {
            extra: { detail: `Report id: ${id}, User: ${currentUserId}` },
            tags: { section: 'Load report', reportVersion: 2 },
        })
        return <CenseoError homepageButton={false} customTitle={`Failed to load report (Report ID: ${id})`} />
    }

    if (
        dbReportData?.fetchJsonReport.message === ReportResultMessage.NotFound &&
        genReportData?.reportDetails.message === ReportResultMessage.NotFound
    ) {
        Sentry.captureException(new Error('Report not found'), {
            extra: { detail: `Report id: ${id} User: ${currentUserId}` },
            tags: { section: 'Load report', reportVersion: 2 },
        })
        return <NotFound homepageButton={true} />
    }

    if (
        dbReportData?.fetchJsonReport.message === ReportResultMessage.AccessDenied ||
        genReportData?.reportDetails.message === ReportResultMessage.AccessDenied
    ) {
        return (
            <CenseoError
                homepageButton={false}
                customTitle="Report Not Accessible"
                customMessage="Something went wrong, please contact your system administrator."
            />
        )
    }

    if (reportDetails && !reportIsDecommissioned) {
        const userFacingToUse: UserFacing = getUserFacingToUse(
            queryUserFacing,
            patientFacing,
            reportDetails.demographics?.userType
        )
        const isPatientFacing = userFacingToUse === 'service-user'

        const { userType, givenName, familyName, nhsNumber, nhsNumberTrust, birthdate, gender, sex } =
            reportDetails.demographics!

        return (
            <ErrorBoundary
                fallbackRender={(error) => {
                    Sentry.captureException(error)
                    return (
                        <CenseoError
                            homepageButton={false}
                            customTitle="Report Cannot Be Shown"
                            customMessage="Something went wrong, please contact your system administrator."
                        />
                    )
                }}
            >
                <Page
                    style={{
                        marginTop: 0,
                    }}
                    {...pageProps}
                >
                    <Disclaimer patientFacing={patientFacing} informationForUseUrl={IFU_LINK}></Disclaimer>

                    <div style={{ padding: `var(--s-2) 0` }}>
                        <DemographicsBar
                            personDetails={{
                                name: `${givenName} ${familyName}`,
                                nhsNumber,
                                nhsNumberTrust,
                                DOB: birthdate,
                                gender,
                                sex,
                                userType,
                                identifierLogical: reportDetails?.identifierLogical,
                            }}
                            showName
                            locale={demoLocale}
                            patientFacing={isPatientFacing}
                            userFacing={userFacingToUse}
                            showLogicalId={!!reportDetails.organisationConfig?.omitNhsLookup}
                        />
                    </div>

                    <ReportTabs
                        report={reportDetails}
                        activeTab={activeTab}
                        onTabChange={handleTabClick}
                        navigateToConditions={handleNavigateToConditions}
                        navigateToContext={handleNavigateToContext}
                        conditionCode={clickedConditionCode}
                        pageScrollElementRef={scrollingElementRef}
                        locale={demoLocale}
                        userFacing={userFacingToUse}
                        mhraModifiedVersion={true}
                    >
                        <Button
                            id="pdf-button"
                            icon="pdf"
                            label="Export PDF"
                            size="small"
                            onClick={() => {
                                if (isDemoAssessment) {
                                    if (id) {
                                        downloadFromLink(demoReports.pdfLocation(id))
                                    }
                                } else {
                                    handlePdfDownloadClick()
                                }
                            }}
                        ></Button>
                    </ReportTabs>
                </Page>
            </ErrorBoundary>
        )
    }

    return <Splash />
}
