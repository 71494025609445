"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "{}",
            "functionId": "OciROcdSeverity"
        },
        "section": [
            "Outcome"
        ]
    },
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "{}",
            "functionId": "OciRScoringKey"
        },
        "section": [
            "Patient-reported issues over the last month"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I have saved up so many things that they get in the way? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI_R-2",
                    "optionSummaries": {
                        "13b7f302-0fd7-4d1b-aef0-c0497bddbc6c": "0 - Not at all",
                        "7646ef49-0c23-4516-b132-02cf44dac3cd": "1 - A little",
                        "565e2012-036d-4dc3-a0a3-96d11f9dc70c": "2 - Moderately",
                        "457bde62-7b9d-4e6b-b8fd-3c47edd3a1fc": "3 - A lot",
                        "fb74ba05-4489-48e4-911a-8c58c77bba91": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Patient-reported issues over the last month"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I check things more often than necessary? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI_R-3",
                    "optionSummaries": {
                        "bab86586-0ccd-492a-9a75-6c2f22e86ab3": "0 - Not at all",
                        "296e536f-bbe5-4994-a34d-0a89b3490c9a": "1 - A little",
                        "832bcab2-d39e-4198-858b-3788cc30908b": "2 - Moderately",
                        "2e9c8242-9de5-4450-aa3d-635e9dca03af": "3 - A lot",
                        "9bf9a461-5817-446e-9ec5-d8e9cebab9a7": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Patient-reported issues over the last month"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I get upset if objects are not arranged properly? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI_R-4",
                    "optionSummaries": {
                        "38e7ac14-8a29-45cc-8a49-0003921d119f": "0 - Not at all",
                        "f6f2fcd1-0a03-44f7-99af-576535ee0a1c": "1 - A little",
                        "4b625451-7b24-4dcd-9cfa-851fd3d9b194": "2 - Moderately",
                        "48ec0abf-b682-49ac-b604-b70d72056f5f": "3 - A lot",
                        "5a13fc6a-f5fa-4fdc-86a0-9cfd86414dd9": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Patient-reported issues over the last month"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I feel compelled to count while I am doing things? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI_R-5",
                    "optionSummaries": {
                        "6961b86d-57da-4148-91f2-b48c56f8c7ea": "0 - Not at all",
                        "7fe921e0-c1e4-4cf0-80d2-1f221159cfd8": "1 - A little",
                        "b1a1116e-0455-460e-b937-21b7c4b9480b": "2 - Moderately",
                        "0d4624bc-9565-4a2c-bf98-f0199362ef67": "3 - A lot",
                        "9aa037e0-e1bf-442d-903b-0d521c3b16df": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Patient-reported issues over the last month"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I find it difficult to touch an object when I know it has been touched by strangers or certain people? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI_R-6",
                    "optionSummaries": {
                        "44d85fc8-1f8c-45c9-9dcb-c8ee1caf8f6d": "0 - Not at all",
                        "cc024879-36e8-4bac-87a3-7b492d9de8cb": "1 - A little",
                        "fc754e30-1dbe-4b5e-b05d-bb334ee4e347": "2 - Moderately",
                        "d7ec3936-d183-4833-8d80-f4399d255d77": "3 - A lot",
                        "698cde41-b06a-49be-99ba-21016759d37c": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Patient-reported issues over the last month"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I find it difficult to control my own thoughts? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI_R-7",
                    "optionSummaries": {
                        "90a9b18e-f6ed-476e-b00a-913409cfab60": "0 - Not at all",
                        "9af9b95c-c26c-4dcb-adb4-8c1610ef15e5": "1 - A little",
                        "02834643-1011-4d82-8359-21e828d9bc23": "2 - Moderately",
                        "a93ea17c-0ac4-4271-9f85-710a24409e58": "3 - A lot",
                        "2fec116c-335a-4da7-9153-57d4cd8438b9": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Patient-reported issues over the last month"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I collect things I don't need? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI_R-8",
                    "optionSummaries": {
                        "24b2f7ab-2222-415e-a43b-5f35417f18d7": "0 - Not at all",
                        "51f596f5-f310-4d28-b0b6-0640a72adb0b": "1 - A little",
                        "560ad5d8-9025-4df9-9617-d43ad39f1d1b": "2 - Moderately",
                        "1890db87-c398-44fd-a39a-99ac170add80": "3 - A lot",
                        "17e2f02e-b299-49a9-8c87-f7fbf055262c": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Patient-reported issues over the last month"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I repeatedly check doors, windows, drawers, etc.? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI_R-9",
                    "optionSummaries": {
                        "8a57a734-2634-4b0f-97a1-15e375985fa7": "0 - Not at all",
                        "427172e3-78e7-430c-baca-369391a130e1": "1 - A little",
                        "342d2fc0-250a-4eb2-8e6d-6e1fd70d4558": "2 - Moderately",
                        "6c8556a1-72bd-4892-86b6-e395fde62cb7": "3 - A lot",
                        "c8b942c7-c9ce-42b8-8172-41c7ed29a2c3": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Patient-reported issues over the last month"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I get upset if others change the way I have arranged things? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI_R-10",
                    "optionSummaries": {
                        "e3bdb0a8-d4b2-4b48-9840-08e7a84e04ed": "0 - Not at all",
                        "847f2166-d531-4a51-b3db-e383b870549b": "1 - A little",
                        "0982d4d0-4381-4bbe-8005-5fa5edab4053": "2 - Moderately",
                        "d26c22f4-ae63-414b-a2a9-b34eba404940": "3 - A lot",
                        "16baad7b-fc4a-4f05-9905-179ae60659d7": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Patient-reported issues over the last month"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I feel I have to repeat certain numbers? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI_R-11",
                    "optionSummaries": {
                        "022708d6-da3a-492b-af94-9f59d146789c": "0 - Not at all",
                        "cee86485-08d9-4f0d-856b-d0357479c4b6": "1 - A little",
                        "58b60964-1a22-4fdf-9f0c-8fff56cc8b5b": "2 - Moderately",
                        "330d37b1-b0e1-4b8d-bd52-7145ee56124a": "3 - A lot",
                        "e5044612-5422-4c8f-9b0f-9cf7bd8913d5": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Patient-reported issues over the last month"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I sometimes have to wash or clean myself simply because I feel contaminated? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI_R-12",
                    "optionSummaries": {
                        "06ace63c-76fa-44a9-b54a-a4ca212179b8": "0 - Not at all",
                        "ce4972d8-a8a2-4909-b316-42f290b0be08": "1 - A little",
                        "95810cfc-b9d0-4122-868d-ddd66d6ad59d": "2 - Moderately",
                        "0e2b9c74-ddc5-4536-8ddd-e7b1c1894509": "3 - A lot",
                        "cf3e4209-6108-4cf8-9d78-2fb8ef5c0e28": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Patient-reported issues over the last month"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I am upset by unpleasant thoughts that come into my mind against my will? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI_R-13",
                    "optionSummaries": {
                        "d8c763a9-70f7-4e27-91e9-deb75bc8dd52": "0 - Not at all",
                        "46b9f602-ab0d-491e-931b-f98fd961b60c": "1 - A little",
                        "77a8d6a3-0711-46f8-b50e-1d2715adff02": "2 - Moderately",
                        "eaa9d917-5d91-41e9-b895-7ae2714c536f": "3 - A lot",
                        "f0a7d382-58fd-42cb-8546-52b2eeceda4d": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Patient-reported issues over the last month"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I avoid throwing things away because I am afraid I might need them later? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI_R-14",
                    "optionSummaries": {
                        "5c3f296f-f9a3-4159-a608-8a571a593fc6": "0 - Not at all",
                        "1196f470-b956-48da-b0ff-3d58a848b060": "1 - A little",
                        "6d46cdf8-179a-4117-ac00-081cb2f39c23": "2 - Moderately",
                        "cf1e9416-e714-4f5c-b7c3-89c8c49cf673": "3 - A lot",
                        "5d0a5863-e505-4acb-adc3-3b7ae92f6e48": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Patient-reported issues over the last month"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I repeatedly check gas and water taps and light switches after turning them off? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI_R-15",
                    "optionSummaries": {
                        "1569e528-7499-4472-9ff6-ba2d553520cc": "0 - Not at all",
                        "ac542969-492d-43a8-993f-0c3afd2bb92f": "1 - A little",
                        "8c264d46-824d-41a8-b2c3-261002adbffa": "2 - Moderately",
                        "62727ae9-d254-4e87-bd16-8c6c18ef890d": "3 - A lot",
                        "ee32aa1b-2cf3-41d4-9b88-6db89cd506b7": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Patient-reported issues over the last month"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I need things to be arranged in a particular order? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI_R-16",
                    "optionSummaries": {
                        "0cc0e03f-9298-4ea2-847c-a5b6e589d87c": "0 - Not at all",
                        "748fafdf-a71b-4737-ac66-a65f619a7523": "1 - A little",
                        "37b5e49d-68a8-44a2-a744-7dccf43ed642": "2 - Moderately",
                        "9cf22bcd-9e90-42f7-9f77-70b91d91f9ad": "3 - A lot",
                        "acad0ce6-d760-4f64-8517-895febc602c0": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Patient-reported issues over the last month"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I feel that there are good and bad numbers? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI_R-17",
                    "optionSummaries": {
                        "01196058-39f5-46a2-ba0e-2b3e85ef09e1": "0 - Not at all",
                        "8a6a2fdf-48b1-40ed-9be6-eed7a07f5e67": "1 - A little",
                        "29a9fa3f-0aea-4984-923a-3515d1753d6a": "2 - Moderately",
                        "e3738514-70eb-4cfe-b3b7-af1f907fffac": "3 - A lot",
                        "6f979724-353c-459c-8cf7-93cb21b50a9d": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Patient-reported issues over the last month"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I wash my hands more often or longer than necessary? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI_R-18",
                    "optionSummaries": {
                        "785d7cf1-1d5e-4473-b768-149d472ac41e": "0 - Not at all",
                        "8cc6a438-4a40-4953-85cb-dcf31fd716fe": "1 - A little",
                        "83adf75f-52d0-4807-b4e1-d4b99a3c7222": "2 - Moderately",
                        "38db906c-a00b-4f75-a01e-30522abea3dc": "3 - A lot",
                        "401bb479-78a4-42a2-8973-3660263e3a10": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Patient-reported issues over the last month"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I frequently get nasty thoughts and have difficulty in getting rid of them? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "OCI_R-19",
                    "optionSummaries": {
                        "4a683a19-a34b-4262-9461-43a6d44cf340": "0 - Not at all",
                        "76d30f14-0462-4c5f-a362-673b378ba787": "1 - A little",
                        "b04b5161-7e76-4216-b7e1-e19fbc072903": "2 - Moderately",
                        "8212d3d3-7e34-4627-8c22-774d0a82274f": "3 - A lot",
                        "f3e0225c-0167-47d3-a013-e1c961973054": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Patient-reported issues over the last month"
        ]
    }
];
