import React from 'react'
import { Button, Heading, Page, Text } from '@psyomics/components'
import Header from 'ui/app/components/Header'
import DemoFooter from 'ui/app/components/DemoFooter'
import Card from 'registration/components/Card'
import { useNavigate } from 'react-router-dom'
import Styles from './DemoDashboardHealthIT.module.scss'
import { ClinicalReportList } from '../ClinicalReports'

interface StatCardProps {
    linkType: 'reports' | 'referralStats'
    header: string
    subheading: string
    mainNumber: string
}

export const StatCard: React.FC<StatCardProps> = ({ linkType, header, subheading, mainNumber }) => {
    const navigate = useNavigate()
    const typeToLinkMap: Record<typeof linkType, string> = {
        referralStats: '/demo/healthcare/activity-dashboard',
        reports: '/demo/healthcare/clinical-reports',
    }

    return (
        <div className={Styles.cardContainer}>
            <div>
                <Card
                    style={{
                        position: 'relative',
                        padding: '50px 24px 25px 24px',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: '10px',
                    }}
                    onClick={() => navigate(typeToLinkMap[linkType])}
                >
                    <div className={Styles.typeIcon + ' ' + Styles[linkType]}>
                        {linkType === 'reports' && <i className="bx bx-file "></i>}
                        {linkType === 'referralStats' && <i className="bx bx-cabinet "></i>}
                    </div>
                    <i className={'bx bx-right-arrow-alt ' + Styles.arrow}></i>
                    <div>
                        <Text size="xsmall"> {header}</Text>
                    </div>
                    <div>
                        <Text size="xsmall"> {subheading}</Text>
                    </div>
                    <div className={Styles.mainInfo}>{mainNumber}</div>
                </Card>
            </div>
        </div>
    )
}

export const DemoDashboardHealthIT: React.FC = () => {
    const navigate = useNavigate()
    return (
        <Page
            style={{ backgroundColor: 'transparent' }}
            layout="standard"
            width="wide"
            header={<Header patientFacing={false} demoMenu={true} />}
            footer={<DemoFooter footerType="clinician" />}
        >
            <div className={Styles.statCardsContainer}>
                <StatCard linkType="reports" header="New Reports" subheading="+2 last day" mainNumber="5"></StatCard>
                <StatCard
                    linkType="referralStats"
                    header="Total Referrals"
                    subheading="+2 last day"
                    mainNumber="25,550"
                ></StatCard>
                <StatCard
                    linkType="referralStats"
                    header="Weekly Referrals"
                    subheading="+7 last day"
                    mainNumber="25"
                ></StatCard>
                <StatCard
                    linkType="referralStats"
                    header="Weekly Completions"
                    subheading="+4 last day"
                    mainNumber="15"
                ></StatCard>
            </div>
            <div className={Styles.popHealthContainer}>
                <div className={Styles.popHealthHeading}>
                    <Heading size="heading2">Reports List</Heading>
                    <Button
                        appearance="secondary"
                        label="More"
                        onClick={() => navigate('/demo/healthcare/clinical-reports')}
                    ></Button>
                </div>
                <div>
                    <ClinicalReportList risk={false} />
                </div>
            </div>
        </Page>
    )
}
