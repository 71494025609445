"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "CombinedAnswersSummary",
            "questionsIdGroups": [
                [
                    "SPIN-1"
                ],
                [
                    "SPIN-2"
                ],
                [
                    "SPIN-3"
                ],
                [
                    "SPIN-4"
                ],
                [
                    "SPIN-5"
                ],
                [
                    "SPIN-6"
                ],
                [
                    "SPIN-7"
                ],
                [
                    "SPIN-8"
                ],
                [
                    "SPIN-9"
                ],
                [
                    "SPIN-10"
                ],
                [
                    "SPIN-11"
                ],
                [
                    "SPIN-12"
                ],
                [
                    "SPIN-13"
                ],
                [
                    "SPIN-14"
                ],
                [
                    "SPIN-15"
                ],
                [
                    "SPIN-16"
                ],
                [
                    "SPIN-17"
                ]
            ],
            "groupsType": "Mean",
            "pattern": "{}",
            "coefficients": {
                "0": "None (0-20)",
                "0.3": "Mild (21-30)",
                "0.45": "Moderate (31-40)",
                "0.6": "Severe (41-50)",
                "0.75": "Very Severe (>= 51)"
            }
        },
        "section": [
            "Outcome"
        ]
    },
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "{}",
            "functionId": "SpinScoringKey"
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "1. I have been afraid of people in authority | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SPIN-1",
                    "optionSummaries": {
                        "10a7f897-bd32-47c4-9347-af91a222117d": "0 - Not at all",
                        "1f44827f-6da4-4f71-bee9-feff62d66931": "1 - A little bit",
                        "a9f4147b-de23-431a-9d4f-984a139daacc": "2 - Somewhat",
                        "34f69a55-9c8c-4a3e-9a2c-1abaaca27fdd": "3 - Very much",
                        "770386f1-1247-4dff-b6d7-cdd7411a6eaf": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "2. I have been bothered by blushing in front of people | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SPIN-2",
                    "optionSummaries": {
                        "0f4799c1-58ca-44e7-8e9d-9d93eb435824": "0 - Not at all",
                        "cbd8820e-2f1f-48f2-90a0-f57f07a6e9b5": "1 - A little bit",
                        "58b4bf1b-7b5b-4918-91db-24ee98ff31a7": "2 - Somewhat",
                        "e0e04750-2cfa-4ddc-ac59-663001f2e157": "3 - Very much",
                        "c943f2e0-1abf-4b9b-af6e-b03f1be7293e": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "3. Parties and social events have scared me | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SPIN-3",
                    "optionSummaries": {
                        "5e88899d-b422-448a-bc31-b9290389b24b": "0 - Not at all",
                        "8f93d703-c5aa-456c-b80a-1fd5ebf35bdd": "1 - A little bit",
                        "5dba76b1-4f99-4ea8-869c-c9b1826fc2b5": "2 - Somewhat",
                        "8b0285cd-dc35-4abc-8c4c-b260e961798d": "3 - Very much",
                        "e55e06d0-a80a-49cf-b97a-d93856c9b6e6": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "4. I have been avoiding talking to people I don't know | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SPIN-4",
                    "optionSummaries": {
                        "30d4b328-aa2d-47c2-81ea-e461f5a3db95": "0 - Not at all",
                        "824dae28-34c1-4b9b-affb-ce9bc2e20722": "1 - A little bit",
                        "104f2688-c8c1-47bf-bc0d-f75edb339658": "2 - Somewhat",
                        "8cb350f5-4e5e-48e1-ad39-b6715a9c6fc6": "3 - Very much",
                        "e3a7d05d-face-40f4-88c7-0227e330c168": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "5. Being criticised has scared me a lot | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SPIN-5",
                    "optionSummaries": {
                        "3c176563-96ce-4882-9801-5214c64fb208": "0 - Not at all",
                        "45dc1926-39e6-4582-a7f4-389ba60b35c6": "1 - A little bit",
                        "e0545a04-f0d7-4299-9564-513a8f474ad6": "2 - Somewhat",
                        "decb6ff1-a794-4b4f-8ea1-1cb11ed5c651": "3 - Very much",
                        "7f949716-0532-4fef-b2e6-7c3ddd19e7c8": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "6. Fear of embarrassment has caused me to avoid doing things or speaking to people | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SPIN-6",
                    "optionSummaries": {
                        "fb9b81e3-9337-4747-a4b5-35ea36731d2e": "0 - Not at all",
                        "3f84d2a6-a250-48ba-ad1a-629117d132e6": "1 - A little bit",
                        "b2385f61-cfea-40e5-b9a5-d13ca53f4e83": "2 - Somewhat",
                        "aaa78523-dc8f-412a-a63c-be03a4389871": "3 - Very much",
                        "9aba5edc-2c6b-4b2c-9ede-d9d00a4607f6": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "7. Sweating in front of people has caused distress | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SPIN-7",
                    "optionSummaries": {
                        "649b2e53-9dfd-4e9a-b242-22ef6b2a7596": "0 - Not at all",
                        "c4ad9dc5-55c9-47f0-a156-d0058546551e": "1 - A little bit",
                        "01532923-fed0-4729-8041-7d93938c1b0e": "2 - Somewhat",
                        "ca2ef297-00ca-4a8a-9b0d-0ff758bf7971": "3 - Very much",
                        "6ad48e76-8b30-4f52-ad73-31a7ab528fc0": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "8. I have avoided going to parties | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SPIN-8",
                    "optionSummaries": {
                        "6e061bcd-fd39-4260-8299-8cdda6ec2c7a": "0 - Not at all",
                        "8593e863-4f2f-469d-846f-b6e99abeea86": "1 - A little bit",
                        "d3a56256-bf5b-4569-9b46-d1958d571767": "2 - Somewhat",
                        "65a5a045-0c3b-42b5-9761-15bdecacd190": "3 - Very much",
                        "f9f1f366-3bf7-4f32-9c82-45bd4e0ddc0c": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "9. I have avoided activities in which I am the center of attention | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SPIN-9",
                    "optionSummaries": {
                        "66a71dd2-defd-46c3-b007-56205a4bb70e": "0 - Not at all",
                        "fa8dc1b9-bde1-4a75-a23d-36b5f0d7cc45": "1 - A little bit",
                        "b5b46ff4-4400-448f-8cf0-fa9a9add1715": "2 - Somewhat",
                        "e3b10c69-da39-4e9b-b256-22076f5ba50e": "3 - Very much",
                        "b9515c9e-e20c-4065-b7a9-6475912dfe99": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "10. Talking to strangers has scared me | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SPIN-10",
                    "optionSummaries": {
                        "327e6f18-a876-4f6f-a831-4babe492be27": "0 - Not at all",
                        "317c7b62-fdd8-4b6e-9037-427914724520": "1 - A little bit",
                        "dcc0dec6-0159-4d3a-9254-d1a9d41c28b7": "2 - Somewhat",
                        "7be4e6b0-6271-4a61-b7bd-75135c567ecc": "3 - Very much",
                        "990f4c6d-978c-4998-94a0-e3594872f6cb": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "11. I have avoided having to give speeches | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SPIN-11",
                    "optionSummaries": {
                        "d0ef791f-c86c-45cf-a1c7-0761dd0464c7": "0 - Not at all",
                        "3218fb66-6a50-44c9-b413-f29b234f1c8b": "1 - A little bit",
                        "7f9cbcbd-4289-4df3-a602-87881ff37fd9": "2 - Somewhat",
                        "dc581743-f929-4554-a853-77fa314199a7": "3 - Very much",
                        "1d70b3b3-c01b-4e4f-b2ab-423c2e204332": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "12. I would do anything to avoid being criticised | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SPIN-12",
                    "optionSummaries": {
                        "465eb61c-f6ad-4591-a15e-b062fd9884c5": "0 - Not at all",
                        "54633303-cb98-4f00-ae61-96eb24544ffd": "1 - A little bit",
                        "ca134533-ef1b-45f0-a7d6-3db7cfdf5d52": "2 - Somewhat",
                        "1bd79e4d-6be1-4231-9abb-de2c65d4f3f8": "3 - Very much",
                        "e592f8c3-8847-42a6-ae8f-b172334a9e84": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "13. Heart palpitations bothered me when I have been around people | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SPIN-13",
                    "optionSummaries": {
                        "8f25f8dd-f345-499f-ae14-9c4c4b8eb2be": "0 - Not at all",
                        "39e4416b-6fa4-4b1b-b2f5-615f30c52c18": "1 - A little bit",
                        "5dfc6a21-beb2-473a-9e14-cbad8c9dd846": "2 - Somewhat",
                        "477439a1-4fff-4e48-a654-31f21df9c37e": "3 - Very much",
                        "43db293d-49f0-469c-9c24-8a0b8560bafa": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "14. I have been afraid of doing things when people might be watching | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SPIN-14",
                    "optionSummaries": {
                        "b01b2a1c-9f10-4e29-b373-2d7de22fb036": "0 - Not at all",
                        "1eb31148-0a57-4b0b-8dd5-79a0828a2b86": "1 - A little bit",
                        "73916a0a-32e8-4d6f-9c87-ffc5598a8aca": "2 - Somewhat",
                        "c67c0005-37c9-494a-a028-c17e63f7865d": "3 - Very much",
                        "e5107537-a807-431d-a539-05e5088b13e2": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "15. Being embarrassed or looking stupid has been among my worst fears | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SPIN-15",
                    "optionSummaries": {
                        "cef38955-ec38-4b97-a5a2-8426a22ee54d": "0 - Not at all",
                        "19bdf7d4-fc6a-4dfa-b558-7e0062671b6e": "1 - A little bit",
                        "05d1a9b2-ec60-44e2-a59b-ce4d96ebb93d": "2 - Somewhat",
                        "a7cabf3a-76cb-4182-aec6-6999bd796328": "3 - Very much",
                        "e517969e-071b-459b-8cd0-7d946ec91f7f": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "16. I have avoided speaking to anyone in authority | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SPIN-16",
                    "optionSummaries": {
                        "eb4ccff0-99ab-472f-a5da-e83ddd942f9d": "0 - Not at all",
                        "ec7c203b-4111-4865-b24c-c1ac3fe638da": "1 - A little bit",
                        "8b831873-b0be-419b-bd85-74ba9c6f5110": "2 - Somewhat",
                        "f641fc70-9653-4772-8afb-d1c0ed31472c": "3 - Very much",
                        "df681418-e830-441f-a27d-da5655c0bdd6": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "17. Trembling or shaking in front of others has been distressing to me | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "SPIN-17",
                    "optionSummaries": {
                        "bed2b452-32e2-49fc-a627-71432d1edd5d": "0 - Not at all",
                        "7740da2a-4030-46ef-94f0-a78ce2987524": "1 - A little bit",
                        "4c964ee4-170c-408b-b4f2-de47cedc6f41": "2 - Somewhat",
                        "95847550-aa03-4286-be77-1de278e4fb9e": "3 - Very much",
                        "7f864414-5836-4eff-a69a-ae33dca809c3": "4 - Extremely"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    }
];
