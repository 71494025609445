import React from 'react'
import ContentPage from '../components/ContentPage'
import { useNavigate } from 'react-router'
import { Text, Link, Heading } from '@psyomics/components'
import Styles from './RefundPolicy.module.scss'

export const RefundPolicy: React.FC = () => {
    const navigate = useNavigate()
    const onMyAccount = () => {
        navigate('/userprofile')
    }

    return (
        <ContentPage
            headingText={'Refund Policy for Private Consumer Users'}
            headingButton={{ text: 'My Account', link: onMyAccount }}
        >
            <div className={Styles.content}>
                <Text size="medium" css={{ mb: 5 }}>
                    We understand that you may not always be satisfied with your purchase of our Psyomics Platform
                    digital self-assessment product. To ensure your satisfaction, if you are a private consumer user, we
                    offer a full refund policy within 14 days.
                </Text>
                <Heading el="h2" size="heading2" color="mid" css={{ mb: 3 }}>
                    Eligibility
                </Heading>
                <Text size="medium" css={{ mb: 5 }}>
                    You are eligible for a refund if you are a private consumer user and meet either of the following
                    conditions:
                </Text>
                <Heading el="h3" size="heading3" color="mid" css={{ mb: 3 }}>
                    Purchased but not used
                </Heading>
                <Text size="medium" css={{ mb: 5 }}>
                    If you have purchased the &apos;Psyomics Platform&apos; digital self-assessment product, but have
                    not started your assessment, you can request a full refund of the product within 14 calendar days of
                    purchase.
                </Text>
                <Heading el="h3" size="heading3" color="mid" css={{ mb: 3 }}>
                    Not satisfied
                </Heading>
                <Text size="medium" css={{ mb: 5 }}>
                    If you have used the &apos;Psyomics Platform&apos; digital self-assessment product but are not
                    satisfied with it, you can request a refund within 14 calendar days of completing the assessment.
                    Please note that you may need to provide a brief explanation of your reasons for requesting the
                    refund.
                </Text>
                <Heading el="h3" size="heading3" color="mid" css={{ mb: 3 }}>
                    Refund Process
                </Heading>
                <Text size="medium" css={{ mb: 5 }}>
                    To request a refund, please email us at{' '}
                    <Link href="mailto:support@psyomics.com">support@psyomics.com</Link> from the email address that you
                    used to register your account. In your email, please clearly state that you are requesting a refund
                    and the reason why you are requesting a refund.
                </Text>
                <Text size="medium" css={{ mb: 5 }}>
                    Once we receive your request, we will review it and process your refund promptly. Please allow up to
                    5-7 business days for the refund to be reflected in your account.
                </Text>
                <Heading el="h3" size="heading3" color="mid" css={{ mb: 3 }}>
                    Exemptions
                </Heading>
                <Text size="medium" css={{ mb: 5 }}>
                    This refund policy is only applicable to private consumer users who have purchased the product from
                    Psyomics Ltd. This refund policy does not apply to business clients, NHS clients, or individual
                    users accessing the product via third-party businesses or the NHS.
                </Text>
            </div>
        </ContentPage>
    )
}
