"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateScoreBreakdown = exports.calculateOciMentalNeutralisingMean = exports.calculateOciHoardingMean = exports.calculateOciObsessingMean = exports.calculateOciOrderingMean = exports.calculateOciDoubtingMean = exports.calculateOciCheckingMean = exports.calculateOciWashingMean = void 0;
const calculateSubscaleMean_1 = require("../utils/calculateSubscaleMean");
const getAnswerValue_1 = require("../utils/getAnswerValue");
const toFormattedMean_1 = require("../utils/toFormattedMean");
const oci_1 = require("../yaml/generated/oci");
const ANSWER_SCALE_FACTOR = 4;
const createOciSubscaleMeanScore = (subscaleQuestionIds) => (answers) => (0, calculateSubscaleMean_1.calculateSubscaleMean)(oci_1.questions, answers, subscaleQuestionIds, ANSWER_SCALE_FACTOR);
const washingQuestionIds = ['OCI-2', 'OCI-4', 'OCI-8', 'OCI-21', 'OCI-22', 'OCI-27', 'OCI-38', 'OCI-42'];
const checkingQuestionIds = ['OCI-3', 'OCI-7', 'OCI-9', 'OCI-10', 'OCI-19', 'OCI-24', 'OCI-31', 'OCI-32', 'OCI-40'];
const doubtingQuestionIds = ['OCI-26', 'OCI-37', 'OCI-41'];
const orderingQuestionIds = ['OCI-14', 'OCI-15', 'OCI-23', 'OCI-29', 'OCI-35'];
const obsessingQuestionIds = ['OCI-1', 'OCI-12', 'OCI-13', 'OCI-17', 'OCI-20', 'OCI-28', 'OCI-30', 'OCI-33'];
const hoardingQuestionIds = ['OCI-6', 'OCI-11', 'OCI-34'];
const mentalNeutralisingQuestionIds = ['OCI-5', 'OCI-16', 'OCI-18', 'OCI-25', 'OCI-36', 'OCI-39'];
const breakdownMeta = [
    {
        title: 'Washing Mean Score',
        questionIds: washingQuestionIds,
    },
    {
        title: 'Checking Mean Score',
        questionIds: checkingQuestionIds,
    },
    {
        title: 'Doubting Mean Score',
        questionIds: doubtingQuestionIds,
    },
    {
        title: 'Ordering Mean Score',
        questionIds: orderingQuestionIds,
    },
    {
        title: 'Obsessing Mean Score',
        questionIds: obsessingQuestionIds,
    },
    {
        title: 'Hoarding Mean Score',
        questionIds: hoardingQuestionIds,
    },
    {
        title: 'Mental Neutralising Mean Score',
        questionIds: mentalNeutralisingQuestionIds,
    },
];
exports.calculateOciWashingMean = createOciSubscaleMeanScore(washingQuestionIds);
exports.calculateOciCheckingMean = createOciSubscaleMeanScore(checkingQuestionIds);
exports.calculateOciDoubtingMean = createOciSubscaleMeanScore(doubtingQuestionIds);
exports.calculateOciOrderingMean = createOciSubscaleMeanScore(orderingQuestionIds);
exports.calculateOciObsessingMean = createOciSubscaleMeanScore(obsessingQuestionIds);
exports.calculateOciHoardingMean = createOciSubscaleMeanScore(hoardingQuestionIds);
exports.calculateOciMentalNeutralisingMean = createOciSubscaleMeanScore(mentalNeutralisingQuestionIds);
const computeBreakdown = (questions, answers, questionIds) => {
    const answered = questionIds
        .map((id) => {
        const answer = answers.find(({ questionId }) => questionId === id);
        if (!answer)
            throw new Error(`No question found for: ` + id);
        return answer;
    })
        .map((answer) => (0, getAnswerValue_1.getAnswerValue)(questions, answer, ANSWER_SCALE_FACTOR));
    const total = answered.reduce((acc, value) => acc + value, 0);
    const length = answered.length;
    return `(${answered.join(' + ')}) / ${answered.length} = ${(0, toFormattedMean_1.toFormattedMean)(total, length)}`;
};
const generateScoreBreakdown = (questions, answers) => {
    const answerQuestionIdSet = new Set(answers.map((a) => a.questionId));
    const answersCheck = breakdownMeta
        .map((section) => section.questionIds)
        .flat()
        .every((id) => answerQuestionIdSet.has(id));
    if (!answersCheck)
        return undefined;
    return {
        title: 'OCI calculation',
        breakdownSections: breakdownMeta.map(({ title, questionIds }) => ({
            title,
            text: computeBreakdown(questions, answers, questionIds),
        })),
    };
};
exports.generateScoreBreakdown = generateScoreBreakdown;
