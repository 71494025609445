import React, { useState } from 'react'
import DOMPurify from 'dompurify'
import { Markdown, Text, Textarea } from '@psyomics/components'
import QuestionPage from 'ui/app/components/QuestionPage'
import Styles from './questions.module.scss'
import Validation from 'registration/components/Validation'
import { FreeTextQuestion as FreeTextQuestionType } from 'censeo-core'

interface Props {
    question: FreeTextQuestionType
    updating: boolean
    skippable?: string | null
    onSelect: (answerText: string) => void
    onBack: () => void
}

export const FreeTextQuestion: React.FC<Props> = ({ question, updating, skippable, onSelect, onBack }: Props) => {
    const [answerText, changeAnswerText] = useState(question.answerText ?? '')
    const [nextPressed, setNextPressed] = useState(false)
    const isMandatoryQuestion = question?.mandatory
    const handleOk = () => {
        setNextPressed(true)
        if (!isMandatoryQuestion || (answerText && answerText.length > 0)) {
            const cleanText = DOMPurify.sanitize(answerText)
            onSelect(cleanText)
        }
    }

    const maxLength = 600
    return (
        <QuestionPage
            onOk={handleOk}
            onBack={onBack}
            onSkip={(): void => {
                const stringToEnter = 'User clicked: ' + skippable
                onSelect(stringToEnter)
            }}
            skippable={skippable}
            backDisabled={!!nextPressed}
            updating={updating}
        >
            <Markdown color="mid" css={{ mb: 4 }}>
                {question.text}
            </Markdown>
            <Textarea
                data-private="lipsum"
                fullWidth
                name="information"
                maxLength={maxLength}
                rows={6}
                value={answerText}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => changeAnswerText(event.target.value)}
            />
            <Text size="small" color="light" className={Styles.charCounter}>
                You have {maxLength - answerText.length} characters remaining
            </Text>
            {isMandatoryQuestion && nextPressed && answerText?.length < 1 ? (
                <Validation>Please enter your reasons for seeking help</Validation>
            ) : null}
        </QuestionPage>
    )
}
