"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
exports.questions = [
    {
        "__typename": "ContentQuestion",
        "id": "SPIN-content",
        "text": "These next questions will ask about whether you experience fear or anxiety in social or performance sitations. When you are answering them, please think back over how you felt during the past week.\n\nRemember, there are no right or wrong answers.\n\nClick 'Continue' when you are ready to start.",
        "answers": [
            {
                "__typename": "Option",
                "id": "56813673-f0cc-4d91-8db0-84e2cfe61504",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "10a7f897-bd32-47c4-9347-af91a222117d",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "1f44827f-6da4-4f71-bee9-feff62d66931",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "a9f4147b-de23-431a-9d4f-984a139daacc",
                "text": "Somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "34f69a55-9c8c-4a3e-9a2c-1abaaca27fdd",
                "text": "Very much",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "770386f1-1247-4dff-b6d7-cdd7411a6eaf",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "SPIN-1",
        "text": "During the past week: \n\n**I have been afraid of people in authority**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "0f4799c1-58ca-44e7-8e9d-9d93eb435824",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "cbd8820e-2f1f-48f2-90a0-f57f07a6e9b5",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "58b4bf1b-7b5b-4918-91db-24ee98ff31a7",
                "text": "Somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "e0e04750-2cfa-4ddc-ac59-663001f2e157",
                "text": "Very much",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "c943f2e0-1abf-4b9b-af6e-b03f1be7293e",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "SPIN-2",
        "text": "During the past week:\n\n**I have been bothered by blushing in front of people**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "5e88899d-b422-448a-bc31-b9290389b24b",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "8f93d703-c5aa-456c-b80a-1fd5ebf35bdd",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "5dba76b1-4f99-4ea8-869c-c9b1826fc2b5",
                "text": "Somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "8b0285cd-dc35-4abc-8c4c-b260e961798d",
                "text": "Very much",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "e55e06d0-a80a-49cf-b97a-d93856c9b6e6",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "SPIN-3",
        "text": "During the past week:\n\n**Parties and social events have scared me**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "30d4b328-aa2d-47c2-81ea-e461f5a3db95",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "824dae28-34c1-4b9b-affb-ce9bc2e20722",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "104f2688-c8c1-47bf-bc0d-f75edb339658",
                "text": "Somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "8cb350f5-4e5e-48e1-ad39-b6715a9c6fc6",
                "text": "Very much",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "e3a7d05d-face-40f4-88c7-0227e330c168",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "SPIN-4",
        "text": "During the past week:\n\n**I have been avoiding talking to people I don't know**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "3c176563-96ce-4882-9801-5214c64fb208",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "45dc1926-39e6-4582-a7f4-389ba60b35c6",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "e0545a04-f0d7-4299-9564-513a8f474ad6",
                "text": "Somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "decb6ff1-a794-4b4f-8ea1-1cb11ed5c651",
                "text": "Very much",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "7f949716-0532-4fef-b2e6-7c3ddd19e7c8",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "SPIN-5",
        "text": "During the past week: \n\n**Being criticised has scared me a lot**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "fb9b81e3-9337-4747-a4b5-35ea36731d2e",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "3f84d2a6-a250-48ba-ad1a-629117d132e6",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "b2385f61-cfea-40e5-b9a5-d13ca53f4e83",
                "text": "Somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "aaa78523-dc8f-412a-a63c-be03a4389871",
                "text": "Very much",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "9aba5edc-2c6b-4b2c-9ede-d9d00a4607f6",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "SPIN-6",
        "text": "During the past week:\n\n**Fear of embarrassment has caused me to avoid doing things or speaking to people**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "649b2e53-9dfd-4e9a-b242-22ef6b2a7596",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "c4ad9dc5-55c9-47f0-a156-d0058546551e",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "01532923-fed0-4729-8041-7d93938c1b0e",
                "text": "Somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "ca2ef297-00ca-4a8a-9b0d-0ff758bf7971",
                "text": "Very much",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "6ad48e76-8b30-4f52-ad73-31a7ab528fc0",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "SPIN-7",
        "text": "During the past week: \n\n**Sweating in front of people has caused me distress**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6e061bcd-fd39-4260-8299-8cdda6ec2c7a",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "8593e863-4f2f-469d-846f-b6e99abeea86",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "d3a56256-bf5b-4569-9b46-d1958d571767",
                "text": "Somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "65a5a045-0c3b-42b5-9761-15bdecacd190",
                "text": "Very much",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "f9f1f366-3bf7-4f32-9c82-45bd4e0ddc0c",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "SPIN-8",
        "text": "During the past week: \n\n**I have avoided going to parties**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "66a71dd2-defd-46c3-b007-56205a4bb70e",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "fa8dc1b9-bde1-4a75-a23d-36b5f0d7cc45",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "b5b46ff4-4400-448f-8cf0-fa9a9add1715",
                "text": "Somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "e3b10c69-da39-4e9b-b256-22076f5ba50e",
                "text": "Very much",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "b9515c9e-e20c-4065-b7a9-6475912dfe99",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "SPIN-9",
        "text": "During the past week:\n\n**I have avoided activities in which I am the center of attention**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "327e6f18-a876-4f6f-a831-4babe492be27",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "317c7b62-fdd8-4b6e-9037-427914724520",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "dcc0dec6-0159-4d3a-9254-d1a9d41c28b7",
                "text": "Somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "7be4e6b0-6271-4a61-b7bd-75135c567ecc",
                "text": "Very much",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "990f4c6d-978c-4998-94a0-e3594872f6cb",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "SPIN-10",
        "text": "During the past week:\n\n**Talking to strangers has scared me**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "d0ef791f-c86c-45cf-a1c7-0761dd0464c7",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "3218fb66-6a50-44c9-b413-f29b234f1c8b",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "7f9cbcbd-4289-4df3-a602-87881ff37fd9",
                "text": "Somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "dc581743-f929-4554-a853-77fa314199a7",
                "text": "Very much",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "1d70b3b3-c01b-4e4f-b2ab-423c2e204332",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "SPIN-11",
        "text": "During the past week: \n\n**I have avoided having to give speeches**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "465eb61c-f6ad-4591-a15e-b062fd9884c5",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "54633303-cb98-4f00-ae61-96eb24544ffd",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "ca134533-ef1b-45f0-a7d6-3db7cfdf5d52",
                "text": "Somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "1bd79e4d-6be1-4231-9abb-de2c65d4f3f8",
                "text": "Very much",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "e592f8c3-8847-42a6-ae8f-b172334a9e84",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "SPIN-12",
        "text": "During the past week: \n\n**I would do anything to avoid being criticised**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "8f25f8dd-f345-499f-ae14-9c4c4b8eb2be",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "39e4416b-6fa4-4b1b-b2f5-615f30c52c18",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "5dfc6a21-beb2-473a-9e14-cbad8c9dd846",
                "text": "Somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "477439a1-4fff-4e48-a654-31f21df9c37e",
                "text": "Very much",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "43db293d-49f0-469c-9c24-8a0b8560bafa",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "SPIN-13",
        "text": "During the past week: \n\n**Heart palpitations bothered me when I have been around people**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "b01b2a1c-9f10-4e29-b373-2d7de22fb036",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "1eb31148-0a57-4b0b-8dd5-79a0828a2b86",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "73916a0a-32e8-4d6f-9c87-ffc5598a8aca",
                "text": "Somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "c67c0005-37c9-494a-a028-c17e63f7865d",
                "text": "Very much",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "e5107537-a807-431d-a539-05e5088b13e2",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "SPIN-14",
        "text": "During the past week:\n\n**I have been afraid of doing things when people might be watching**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "cef38955-ec38-4b97-a5a2-8426a22ee54d",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "19bdf7d4-fc6a-4dfa-b558-7e0062671b6e",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "05d1a9b2-ec60-44e2-a59b-ce4d96ebb93d",
                "text": "Somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "a7cabf3a-76cb-4182-aec6-6999bd796328",
                "text": "Very much",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "e517969e-071b-459b-8cd0-7d946ec91f7f",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "SPIN-15",
        "text": "During the past week: \n\n**Being embarrassed or looking stupid has been among my worst fears**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "eb4ccff0-99ab-472f-a5da-e83ddd942f9d",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ec7c203b-4111-4865-b24c-c1ac3fe638da",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "8b831873-b0be-419b-bd85-74ba9c6f5110",
                "text": "Somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "f641fc70-9653-4772-8afb-d1c0ed31472c",
                "text": "Very much",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "df681418-e830-441f-a27d-da5655c0bdd6",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "SPIN-16",
        "text": "During the past week: \n\n**I have avoided speaking to anyone in authority**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "bed2b452-32e2-49fc-a627-71432d1edd5d",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7740da2a-4030-46ef-94f0-a78ce2987524",
                "text": "A little bit",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "4c964ee4-170c-408b-b4f2-de47cedc6f41",
                "text": "Somewhat",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "95847550-aa03-4286-be77-1de278e4fb9e",
                "text": "Very much",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "7f864414-5836-4eff-a69a-ae33dca809c3",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "SPIN-17",
        "text": "During the past week:\n\n**Trembling or shaking in front of others has been distressing to me**",
        "isMultiselect": false
    }
];
exports.aspects = {
    "spin": {
        "type": "mean",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SPIN-1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "10a7f897-bd32-47c4-9347-af91a222117d": 0,
                            "1f44827f-6da4-4f71-bee9-feff62d66931": 0.25,
                            "a9f4147b-de23-431a-9d4f-984a139daacc": 0.5,
                            "34f69a55-9c8c-4a3e-9a2c-1abaaca27fdd": 0.75,
                            "770386f1-1247-4dff-b6d7-cdd7411a6eaf": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SPIN-2",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "0f4799c1-58ca-44e7-8e9d-9d93eb435824": 0,
                            "cbd8820e-2f1f-48f2-90a0-f57f07a6e9b5": 0.25,
                            "58b4bf1b-7b5b-4918-91db-24ee98ff31a7": 0.5,
                            "e0e04750-2cfa-4ddc-ac59-663001f2e157": 0.75,
                            "c943f2e0-1abf-4b9b-af6e-b03f1be7293e": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SPIN-3",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "5e88899d-b422-448a-bc31-b9290389b24b": 0,
                            "8f93d703-c5aa-456c-b80a-1fd5ebf35bdd": 0.25,
                            "5dba76b1-4f99-4ea8-869c-c9b1826fc2b5": 0.5,
                            "8b0285cd-dc35-4abc-8c4c-b260e961798d": 0.75,
                            "e55e06d0-a80a-49cf-b97a-d93856c9b6e6": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SPIN-4",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "30d4b328-aa2d-47c2-81ea-e461f5a3db95": 0,
                            "824dae28-34c1-4b9b-affb-ce9bc2e20722": 0.25,
                            "104f2688-c8c1-47bf-bc0d-f75edb339658": 0.5,
                            "8cb350f5-4e5e-48e1-ad39-b6715a9c6fc6": 0.75,
                            "e3a7d05d-face-40f4-88c7-0227e330c168": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SPIN-5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "3c176563-96ce-4882-9801-5214c64fb208": 0,
                            "45dc1926-39e6-4582-a7f4-389ba60b35c6": 0.25,
                            "e0545a04-f0d7-4299-9564-513a8f474ad6": 0.5,
                            "decb6ff1-a794-4b4f-8ea1-1cb11ed5c651": 0.75,
                            "7f949716-0532-4fef-b2e6-7c3ddd19e7c8": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SPIN-6",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "fb9b81e3-9337-4747-a4b5-35ea36731d2e": 0,
                            "3f84d2a6-a250-48ba-ad1a-629117d132e6": 0.25,
                            "b2385f61-cfea-40e5-b9a5-d13ca53f4e83": 0.5,
                            "aaa78523-dc8f-412a-a63c-be03a4389871": 0.75,
                            "9aba5edc-2c6b-4b2c-9ede-d9d00a4607f6": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SPIN-7",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "649b2e53-9dfd-4e9a-b242-22ef6b2a7596": 0,
                            "c4ad9dc5-55c9-47f0-a156-d0058546551e": 0.25,
                            "01532923-fed0-4729-8041-7d93938c1b0e": 0.5,
                            "ca2ef297-00ca-4a8a-9b0d-0ff758bf7971": 0.75,
                            "6ad48e76-8b30-4f52-ad73-31a7ab528fc0": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SPIN-8",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "6e061bcd-fd39-4260-8299-8cdda6ec2c7a": 0,
                            "8593e863-4f2f-469d-846f-b6e99abeea86": 0.25,
                            "d3a56256-bf5b-4569-9b46-d1958d571767": 0.5,
                            "65a5a045-0c3b-42b5-9761-15bdecacd190": 0.75,
                            "f9f1f366-3bf7-4f32-9c82-45bd4e0ddc0c": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SPIN-9",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "66a71dd2-defd-46c3-b007-56205a4bb70e": 0,
                            "fa8dc1b9-bde1-4a75-a23d-36b5f0d7cc45": 0.25,
                            "b5b46ff4-4400-448f-8cf0-fa9a9add1715": 0.5,
                            "e3b10c69-da39-4e9b-b256-22076f5ba50e": 0.75,
                            "b9515c9e-e20c-4065-b7a9-6475912dfe99": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SPIN-10",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "327e6f18-a876-4f6f-a831-4babe492be27": 0,
                            "317c7b62-fdd8-4b6e-9037-427914724520": 0.25,
                            "dcc0dec6-0159-4d3a-9254-d1a9d41c28b7": 0.5,
                            "7be4e6b0-6271-4a61-b7bd-75135c567ecc": 0.75,
                            "990f4c6d-978c-4998-94a0-e3594872f6cb": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SPIN-11",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "d0ef791f-c86c-45cf-a1c7-0761dd0464c7": 0,
                            "3218fb66-6a50-44c9-b413-f29b234f1c8b": 0.25,
                            "7f9cbcbd-4289-4df3-a602-87881ff37fd9": 0.5,
                            "dc581743-f929-4554-a853-77fa314199a7": 0.75,
                            "1d70b3b3-c01b-4e4f-b2ab-423c2e204332": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SPIN-12",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "465eb61c-f6ad-4591-a15e-b062fd9884c5": 0,
                            "54633303-cb98-4f00-ae61-96eb24544ffd": 0.25,
                            "ca134533-ef1b-45f0-a7d6-3db7cfdf5d52": 0.5,
                            "1bd79e4d-6be1-4231-9abb-de2c65d4f3f8": 0.75,
                            "e592f8c3-8847-42a6-ae8f-b172334a9e84": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SPIN-13",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "8f25f8dd-f345-499f-ae14-9c4c4b8eb2be": 0,
                            "39e4416b-6fa4-4b1b-b2f5-615f30c52c18": 0.25,
                            "5dfc6a21-beb2-473a-9e14-cbad8c9dd846": 0.5,
                            "477439a1-4fff-4e48-a654-31f21df9c37e": 0.75,
                            "43db293d-49f0-469c-9c24-8a0b8560bafa": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SPIN-14",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "b01b2a1c-9f10-4e29-b373-2d7de22fb036": 0,
                            "1eb31148-0a57-4b0b-8dd5-79a0828a2b86": 0.25,
                            "73916a0a-32e8-4d6f-9c87-ffc5598a8aca": 0.5,
                            "c67c0005-37c9-494a-a028-c17e63f7865d": 0.75,
                            "e5107537-a807-431d-a539-05e5088b13e2": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SPIN-15",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "cef38955-ec38-4b97-a5a2-8426a22ee54d": 0,
                            "19bdf7d4-fc6a-4dfa-b558-7e0062671b6e": 0.25,
                            "05d1a9b2-ec60-44e2-a59b-ce4d96ebb93d": 0.5,
                            "a7cabf3a-76cb-4182-aec6-6999bd796328": 0.75,
                            "e517969e-071b-459b-8cd0-7d946ec91f7f": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SPIN-16",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "eb4ccff0-99ab-472f-a5da-e83ddd942f9d": 0,
                            "ec7c203b-4111-4865-b24c-c1ac3fe638da": 0.25,
                            "8b831873-b0be-419b-bd85-74ba9c6f5110": 0.5,
                            "f641fc70-9653-4772-8afb-d1c0ed31472c": 0.75,
                            "df681418-e830-441f-a27d-da5655c0bdd6": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SPIN-17",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "bed2b452-32e2-49fc-a627-71432d1edd5d": 0,
                            "7740da2a-4030-46ef-94f0-a78ce2987524": 0.25,
                            "4c964ee4-170c-408b-b4f2-de47cedc6f41": 0.5,
                            "95847550-aa03-4286-be77-1de278e4fb9e": 0.75,
                            "7f864414-5836-4eff-a69a-ae33dca809c3": 1
                        }
                    }
                }
            }
        ]
    }
};
exports.tests = {
    "spin": [
        {
            "name": "SPIN 0 score-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0,
                "threshold": 0
            },
            "answers": {
                "SPIN-1": {
                    "questionId": "SPIN-1",
                    "chosenOptionIds": [
                        "10a7f897-bd32-47c4-9347-af91a222117d"
                    ]
                },
                "SPIN-2": {
                    "questionId": "SPIN-2",
                    "chosenOptionIds": [
                        "0f4799c1-58ca-44e7-8e9d-9d93eb435824"
                    ]
                },
                "SPIN-3": {
                    "questionId": "SPIN-3",
                    "chosenOptionIds": [
                        "5e88899d-b422-448a-bc31-b9290389b24b"
                    ]
                },
                "SPIN-4": {
                    "questionId": "SPIN-4",
                    "chosenOptionIds": [
                        "30d4b328-aa2d-47c2-81ea-e461f5a3db95"
                    ]
                },
                "SPIN-5": {
                    "questionId": "SPIN-5",
                    "chosenOptionIds": [
                        "3c176563-96ce-4882-9801-5214c64fb208"
                    ]
                },
                "SPIN-6": {
                    "questionId": "SPIN-6",
                    "chosenOptionIds": [
                        "fb9b81e3-9337-4747-a4b5-35ea36731d2e"
                    ]
                },
                "SPIN-7": {
                    "questionId": "SPIN-7",
                    "chosenOptionIds": [
                        "649b2e53-9dfd-4e9a-b242-22ef6b2a7596"
                    ]
                },
                "SPIN-8": {
                    "questionId": "SPIN-8",
                    "chosenOptionIds": [
                        "6e061bcd-fd39-4260-8299-8cdda6ec2c7a"
                    ]
                },
                "SPIN-9": {
                    "questionId": "SPIN-9",
                    "chosenOptionIds": [
                        "66a71dd2-defd-46c3-b007-56205a4bb70e"
                    ]
                },
                "SPIN-10": {
                    "questionId": "SPIN-10",
                    "chosenOptionIds": [
                        "327e6f18-a876-4f6f-a831-4babe492be27"
                    ]
                },
                "SPIN-11": {
                    "questionId": "SPIN-11",
                    "chosenOptionIds": [
                        "d0ef791f-c86c-45cf-a1c7-0761dd0464c7"
                    ]
                },
                "SPIN-12": {
                    "questionId": "SPIN-12",
                    "chosenOptionIds": [
                        "465eb61c-f6ad-4591-a15e-b062fd9884c5"
                    ]
                },
                "SPIN-13": {
                    "questionId": "SPIN-13",
                    "chosenOptionIds": [
                        "8f25f8dd-f345-499f-ae14-9c4c4b8eb2be"
                    ]
                },
                "SPIN-14": {
                    "questionId": "SPIN-14",
                    "chosenOptionIds": [
                        "b01b2a1c-9f10-4e29-b373-2d7de22fb036"
                    ]
                },
                "SPIN-15": {
                    "questionId": "SPIN-15",
                    "chosenOptionIds": [
                        "cef38955-ec38-4b97-a5a2-8426a22ee54d"
                    ]
                },
                "SPIN-16": {
                    "questionId": "SPIN-16",
                    "chosenOptionIds": [
                        "eb4ccff0-99ab-472f-a5da-e83ddd942f9d"
                    ]
                },
                "SPIN-17": {
                    "questionId": "SPIN-17",
                    "chosenOptionIds": [
                        "bed2b452-32e2-49fc-a627-71432d1edd5d"
                    ]
                }
            }
        },
        {
            "name": "SPIN full score-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 1,
                "threshold": 0
            },
            "answers": {
                "SPIN-1": {
                    "questionId": "SPIN-1",
                    "chosenOptionIds": [
                        "770386f1-1247-4dff-b6d7-cdd7411a6eaf"
                    ]
                },
                "SPIN-2": {
                    "questionId": "SPIN-2",
                    "chosenOptionIds": [
                        "c943f2e0-1abf-4b9b-af6e-b03f1be7293e"
                    ]
                },
                "SPIN-3": {
                    "questionId": "SPIN-3",
                    "chosenOptionIds": [
                        "e55e06d0-a80a-49cf-b97a-d93856c9b6e6"
                    ]
                },
                "SPIN-4": {
                    "questionId": "SPIN-4",
                    "chosenOptionIds": [
                        "e3a7d05d-face-40f4-88c7-0227e330c168"
                    ]
                },
                "SPIN-5": {
                    "questionId": "SPIN-5",
                    "chosenOptionIds": [
                        "7f949716-0532-4fef-b2e6-7c3ddd19e7c8"
                    ]
                },
                "SPIN-6": {
                    "questionId": "SPIN-6",
                    "chosenOptionIds": [
                        "9aba5edc-2c6b-4b2c-9ede-d9d00a4607f6"
                    ]
                },
                "SPIN-7": {
                    "questionId": "SPIN-7",
                    "chosenOptionIds": [
                        "6ad48e76-8b30-4f52-ad73-31a7ab528fc0"
                    ]
                },
                "SPIN-8": {
                    "questionId": "SPIN-8",
                    "chosenOptionIds": [
                        "f9f1f366-3bf7-4f32-9c82-45bd4e0ddc0c"
                    ]
                },
                "SPIN-9": {
                    "questionId": "SPIN-9",
                    "chosenOptionIds": [
                        "b9515c9e-e20c-4065-b7a9-6475912dfe99"
                    ]
                },
                "SPIN-10": {
                    "questionId": "SPIN-10",
                    "chosenOptionIds": [
                        "990f4c6d-978c-4998-94a0-e3594872f6cb"
                    ]
                },
                "SPIN-11": {
                    "questionId": "SPIN-11",
                    "chosenOptionIds": [
                        "1d70b3b3-c01b-4e4f-b2ab-423c2e204332"
                    ]
                },
                "SPIN-12": {
                    "questionId": "SPIN-12",
                    "chosenOptionIds": [
                        "e592f8c3-8847-42a6-ae8f-b172334a9e84"
                    ]
                },
                "SPIN-13": {
                    "questionId": "SPIN-13",
                    "chosenOptionIds": [
                        "43db293d-49f0-469c-9c24-8a0b8560bafa"
                    ]
                },
                "SPIN-14": {
                    "questionId": "SPIN-14",
                    "chosenOptionIds": [
                        "e5107537-a807-431d-a539-05e5088b13e2"
                    ]
                },
                "SPIN-15": {
                    "questionId": "SPIN-15",
                    "chosenOptionIds": [
                        "e517969e-071b-459b-8cd0-7d946ec91f7f"
                    ]
                },
                "SPIN-16": {
                    "questionId": "SPIN-16",
                    "chosenOptionIds": [
                        "df681418-e830-441f-a27d-da5655c0bdd6"
                    ]
                },
                "SPIN-17": {
                    "questionId": "SPIN-17",
                    "chosenOptionIds": [
                        "7f864414-5836-4eff-a69a-ae33dca809c3"
                    ]
                }
            }
        },
        {
            "name": "SPIN - partial score 0.5-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.5,
                "threshold": 0
            },
            "answers": {
                "SPIN-1": {
                    "questionId": "SPIN-1",
                    "chosenOptionIds": [
                        "a9f4147b-de23-431a-9d4f-984a139daacc"
                    ]
                },
                "SPIN-2": {
                    "questionId": "SPIN-2",
                    "chosenOptionIds": [
                        "58b4bf1b-7b5b-4918-91db-24ee98ff31a7"
                    ]
                },
                "SPIN-3": {
                    "questionId": "SPIN-3",
                    "chosenOptionIds": [
                        "5dba76b1-4f99-4ea8-869c-c9b1826fc2b5"
                    ]
                },
                "SPIN-4": {
                    "questionId": "SPIN-4",
                    "chosenOptionIds": [
                        "104f2688-c8c1-47bf-bc0d-f75edb339658"
                    ]
                },
                "SPIN-5": {
                    "questionId": "SPIN-5",
                    "chosenOptionIds": [
                        "e0545a04-f0d7-4299-9564-513a8f474ad6"
                    ]
                },
                "SPIN-6": {
                    "questionId": "SPIN-6",
                    "chosenOptionIds": [
                        "b2385f61-cfea-40e5-b9a5-d13ca53f4e83"
                    ]
                },
                "SPIN-7": {
                    "questionId": "SPIN-7",
                    "chosenOptionIds": [
                        "01532923-fed0-4729-8041-7d93938c1b0e"
                    ]
                },
                "SPIN-8": {
                    "questionId": "SPIN-8",
                    "chosenOptionIds": [
                        "d3a56256-bf5b-4569-9b46-d1958d571767"
                    ]
                },
                "SPIN-9": {
                    "questionId": "SPIN-9",
                    "chosenOptionIds": [
                        "b5b46ff4-4400-448f-8cf0-fa9a9add1715"
                    ]
                },
                "SPIN-10": {
                    "questionId": "SPIN-10",
                    "chosenOptionIds": [
                        "dcc0dec6-0159-4d3a-9254-d1a9d41c28b7"
                    ]
                },
                "SPIN-11": {
                    "questionId": "SPIN-11",
                    "chosenOptionIds": [
                        "7f9cbcbd-4289-4df3-a602-87881ff37fd9"
                    ]
                },
                "SPIN-12": {
                    "questionId": "SPIN-12",
                    "chosenOptionIds": [
                        "ca134533-ef1b-45f0-a7d6-3db7cfdf5d52"
                    ]
                },
                "SPIN-13": {
                    "questionId": "SPIN-13",
                    "chosenOptionIds": [
                        "5dfc6a21-beb2-473a-9e14-cbad8c9dd846"
                    ]
                },
                "SPIN-14": {
                    "questionId": "SPIN-14",
                    "chosenOptionIds": [
                        "73916a0a-32e8-4d6f-9c87-ffc5598a8aca"
                    ]
                },
                "SPIN-15": {
                    "questionId": "SPIN-15",
                    "chosenOptionIds": [
                        "05d1a9b2-ec60-44e2-a59b-ce4d96ebb93d"
                    ]
                },
                "SPIN-16": {
                    "questionId": "SPIN-16",
                    "chosenOptionIds": [
                        "8b831873-b0be-419b-bd85-74ba9c6f5110"
                    ]
                },
                "SPIN-17": {
                    "questionId": "SPIN-17",
                    "chosenOptionIds": [
                        "4c964ee4-170c-408b-b4f2-de47cedc6f41"
                    ]
                }
            }
        },
        {
            "name": "SPIN score 0.75-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.75,
                "threshold": 0
            },
            "answers": {
                "SPIN-1": {
                    "questionId": "SPIN-1",
                    "chosenOptionIds": [
                        "34f69a55-9c8c-4a3e-9a2c-1abaaca27fdd"
                    ]
                },
                "SPIN-2": {
                    "questionId": "SPIN-2",
                    "chosenOptionIds": [
                        "e0e04750-2cfa-4ddc-ac59-663001f2e157"
                    ]
                },
                "SPIN-3": {
                    "questionId": "SPIN-3",
                    "chosenOptionIds": [
                        "8b0285cd-dc35-4abc-8c4c-b260e961798d"
                    ]
                },
                "SPIN-4": {
                    "questionId": "SPIN-4",
                    "chosenOptionIds": [
                        "8cb350f5-4e5e-48e1-ad39-b6715a9c6fc6"
                    ]
                },
                "SPIN-5": {
                    "questionId": "SPIN-5",
                    "chosenOptionIds": [
                        "decb6ff1-a794-4b4f-8ea1-1cb11ed5c651"
                    ]
                },
                "SPIN-6": {
                    "questionId": "SPIN-6",
                    "chosenOptionIds": [
                        "aaa78523-dc8f-412a-a63c-be03a4389871"
                    ]
                },
                "SPIN-7": {
                    "questionId": "SPIN-7",
                    "chosenOptionIds": [
                        "ca2ef297-00ca-4a8a-9b0d-0ff758bf7971"
                    ]
                },
                "SPIN-8": {
                    "questionId": "SPIN-8",
                    "chosenOptionIds": [
                        "65a5a045-0c3b-42b5-9761-15bdecacd190"
                    ]
                },
                "SPIN-9": {
                    "questionId": "SPIN-9",
                    "chosenOptionIds": [
                        "e3b10c69-da39-4e9b-b256-22076f5ba50e"
                    ]
                },
                "SPIN-10": {
                    "questionId": "SPIN-10",
                    "chosenOptionIds": [
                        "7be4e6b0-6271-4a61-b7bd-75135c567ecc"
                    ]
                },
                "SPIN-11": {
                    "questionId": "SPIN-11",
                    "chosenOptionIds": [
                        "dc581743-f929-4554-a853-77fa314199a7"
                    ]
                },
                "SPIN-12": {
                    "questionId": "SPIN-12",
                    "chosenOptionIds": [
                        "1bd79e4d-6be1-4231-9abb-de2c65d4f3f8"
                    ]
                },
                "SPIN-13": {
                    "questionId": "SPIN-13",
                    "chosenOptionIds": [
                        "477439a1-4fff-4e48-a654-31f21df9c37e"
                    ]
                },
                "SPIN-14": {
                    "questionId": "SPIN-14",
                    "chosenOptionIds": [
                        "c67c0005-37c9-494a-a028-c17e63f7865d"
                    ]
                },
                "SPIN-15": {
                    "questionId": "SPIN-15",
                    "chosenOptionIds": [
                        "a7cabf3a-76cb-4182-aec6-6999bd796328"
                    ]
                },
                "SPIN-16": {
                    "questionId": "SPIN-16",
                    "chosenOptionIds": [
                        "f641fc70-9653-4772-8afb-d1c0ed31472c"
                    ]
                },
                "SPIN-17": {
                    "questionId": "SPIN-17",
                    "chosenOptionIds": [
                        "95847550-aa03-4286-be77-1de278e4fb9e"
                    ]
                }
            }
        },
        {
            "name": "SPIN partial score 1-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.48529411764,
                "threshold": 0
            },
            "answers": {
                "SPIN-1": {
                    "questionId": "SPIN-1",
                    "chosenOptionIds": [
                        "a9f4147b-de23-431a-9d4f-984a139daacc"
                    ]
                },
                "SPIN-2": {
                    "questionId": "SPIN-2",
                    "chosenOptionIds": [
                        "0f4799c1-58ca-44e7-8e9d-9d93eb435824"
                    ]
                },
                "SPIN-3": {
                    "questionId": "SPIN-3",
                    "chosenOptionIds": [
                        "8b0285cd-dc35-4abc-8c4c-b260e961798d"
                    ]
                },
                "SPIN-4": {
                    "questionId": "SPIN-4",
                    "chosenOptionIds": [
                        "824dae28-34c1-4b9b-affb-ce9bc2e20722"
                    ]
                },
                "SPIN-5": {
                    "questionId": "SPIN-5",
                    "chosenOptionIds": [
                        "decb6ff1-a794-4b4f-8ea1-1cb11ed5c651"
                    ]
                },
                "SPIN-6": {
                    "questionId": "SPIN-6",
                    "chosenOptionIds": [
                        "aaa78523-dc8f-412a-a63c-be03a4389871"
                    ]
                },
                "SPIN-7": {
                    "questionId": "SPIN-7",
                    "chosenOptionIds": [
                        "ca2ef297-00ca-4a8a-9b0d-0ff758bf7971"
                    ]
                },
                "SPIN-8": {
                    "questionId": "SPIN-8",
                    "chosenOptionIds": [
                        "6e061bcd-fd39-4260-8299-8cdda6ec2c7a"
                    ]
                },
                "SPIN-9": {
                    "questionId": "SPIN-9",
                    "chosenOptionIds": [
                        "b9515c9e-e20c-4065-b7a9-6475912dfe99"
                    ]
                },
                "SPIN-10": {
                    "questionId": "SPIN-10",
                    "chosenOptionIds": [
                        "317c7b62-fdd8-4b6e-9037-427914724520"
                    ]
                },
                "SPIN-11": {
                    "questionId": "SPIN-11",
                    "chosenOptionIds": [
                        "3218fb66-6a50-44c9-b413-f29b234f1c8b"
                    ]
                },
                "SPIN-12": {
                    "questionId": "SPIN-12",
                    "chosenOptionIds": [
                        "ca134533-ef1b-45f0-a7d6-3db7cfdf5d52"
                    ]
                },
                "SPIN-13": {
                    "questionId": "SPIN-13",
                    "chosenOptionIds": [
                        "43db293d-49f0-469c-9c24-8a0b8560bafa"
                    ]
                },
                "SPIN-14": {
                    "questionId": "SPIN-14",
                    "chosenOptionIds": [
                        "c67c0005-37c9-494a-a028-c17e63f7865d"
                    ]
                },
                "SPIN-15": {
                    "questionId": "SPIN-15",
                    "chosenOptionIds": [
                        "05d1a9b2-ec60-44e2-a59b-ce4d96ebb93d"
                    ]
                },
                "SPIN-16": {
                    "questionId": "SPIN-16",
                    "chosenOptionIds": [
                        "ec7c203b-4111-4865-b24c-c1ac3fe638da"
                    ]
                },
                "SPIN-17": {
                    "questionId": "SPIN-17",
                    "chosenOptionIds": [
                        "bed2b452-32e2-49fc-a627-71432d1edd5d"
                    ]
                }
            }
        },
        {
            "name": "SPIN partial score 2-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.5,
                "threshold": 0
            },
            "answers": {
                "SPIN-1": {
                    "questionId": "SPIN-1",
                    "chosenOptionIds": [
                        "770386f1-1247-4dff-b6d7-cdd7411a6eaf"
                    ]
                },
                "SPIN-2": {
                    "questionId": "SPIN-2",
                    "chosenOptionIds": [
                        "cbd8820e-2f1f-48f2-90a0-f57f07a6e9b5"
                    ]
                },
                "SPIN-3": {
                    "questionId": "SPIN-3",
                    "chosenOptionIds": [
                        "5dba76b1-4f99-4ea8-869c-c9b1826fc2b5"
                    ]
                },
                "SPIN-4": {
                    "questionId": "SPIN-4",
                    "chosenOptionIds": [
                        "8cb350f5-4e5e-48e1-ad39-b6715a9c6fc6"
                    ]
                },
                "SPIN-5": {
                    "questionId": "SPIN-5",
                    "chosenOptionIds": [
                        "45dc1926-39e6-4582-a7f4-389ba60b35c6"
                    ]
                },
                "SPIN-6": {
                    "questionId": "SPIN-6",
                    "chosenOptionIds": [
                        "b2385f61-cfea-40e5-b9a5-d13ca53f4e83"
                    ]
                },
                "SPIN-7": {
                    "questionId": "SPIN-7",
                    "chosenOptionIds": [
                        "01532923-fed0-4729-8041-7d93938c1b0e"
                    ]
                },
                "SPIN-8": {
                    "questionId": "SPIN-8",
                    "chosenOptionIds": [
                        "65a5a045-0c3b-42b5-9761-15bdecacd190"
                    ]
                },
                "SPIN-9": {
                    "questionId": "SPIN-9",
                    "chosenOptionIds": [
                        "66a71dd2-defd-46c3-b007-56205a4bb70e"
                    ]
                },
                "SPIN-10": {
                    "questionId": "SPIN-10",
                    "chosenOptionIds": [
                        "327e6f18-a876-4f6f-a831-4babe492be27"
                    ]
                },
                "SPIN-11": {
                    "questionId": "SPIN-11",
                    "chosenOptionIds": [
                        "3218fb66-6a50-44c9-b413-f29b234f1c8b"
                    ]
                },
                "SPIN-12": {
                    "questionId": "SPIN-12",
                    "chosenOptionIds": [
                        "ca134533-ef1b-45f0-a7d6-3db7cfdf5d52"
                    ]
                },
                "SPIN-13": {
                    "questionId": "SPIN-13",
                    "chosenOptionIds": [
                        "477439a1-4fff-4e48-a654-31f21df9c37e"
                    ]
                },
                "SPIN-14": {
                    "questionId": "SPIN-14",
                    "chosenOptionIds": [
                        "e5107537-a807-431d-a539-05e5088b13e2"
                    ]
                },
                "SPIN-15": {
                    "questionId": "SPIN-15",
                    "chosenOptionIds": [
                        "a7cabf3a-76cb-4182-aec6-6999bd796328"
                    ]
                },
                "SPIN-16": {
                    "questionId": "SPIN-16",
                    "chosenOptionIds": [
                        "eb4ccff0-99ab-472f-a5da-e83ddd942f9d"
                    ]
                },
                "SPIN-17": {
                    "questionId": "SPIN-17",
                    "chosenOptionIds": [
                        "95847550-aa03-4286-be77-1de278e4fb9e"
                    ]
                }
            }
        },
        {
            "name": "SPIN partial score 3-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.41176470588,
                "threshold": 0
            },
            "answers": {
                "SPIN-1": {
                    "questionId": "SPIN-1",
                    "chosenOptionIds": [
                        "10a7f897-bd32-47c4-9347-af91a222117d"
                    ]
                },
                "SPIN-2": {
                    "questionId": "SPIN-2",
                    "chosenOptionIds": [
                        "cbd8820e-2f1f-48f2-90a0-f57f07a6e9b5"
                    ]
                },
                "SPIN-3": {
                    "questionId": "SPIN-3",
                    "chosenOptionIds": [
                        "5dba76b1-4f99-4ea8-869c-c9b1826fc2b5"
                    ]
                },
                "SPIN-4": {
                    "questionId": "SPIN-4",
                    "chosenOptionIds": [
                        "8cb350f5-4e5e-48e1-ad39-b6715a9c6fc6"
                    ]
                },
                "SPIN-5": {
                    "questionId": "SPIN-5",
                    "chosenOptionIds": [
                        "45dc1926-39e6-4582-a7f4-389ba60b35c6"
                    ]
                },
                "SPIN-6": {
                    "questionId": "SPIN-6",
                    "chosenOptionIds": [
                        "b2385f61-cfea-40e5-b9a5-d13ca53f4e83"
                    ]
                },
                "SPIN-7": {
                    "questionId": "SPIN-7",
                    "chosenOptionIds": [
                        "c4ad9dc5-55c9-47f0-a156-d0058546551e"
                    ]
                },
                "SPIN-8": {
                    "questionId": "SPIN-8",
                    "chosenOptionIds": [
                        "f9f1f366-3bf7-4f32-9c82-45bd4e0ddc0c"
                    ]
                },
                "SPIN-9": {
                    "questionId": "SPIN-9",
                    "chosenOptionIds": [
                        "66a71dd2-defd-46c3-b007-56205a4bb70e"
                    ]
                },
                "SPIN-10": {
                    "questionId": "SPIN-10",
                    "chosenOptionIds": [
                        "327e6f18-a876-4f6f-a831-4babe492be27"
                    ]
                },
                "SPIN-11": {
                    "questionId": "SPIN-11",
                    "chosenOptionIds": [
                        "d0ef791f-c86c-45cf-a1c7-0761dd0464c7"
                    ]
                },
                "SPIN-12": {
                    "questionId": "SPIN-12",
                    "chosenOptionIds": [
                        "ca134533-ef1b-45f0-a7d6-3db7cfdf5d52"
                    ]
                },
                "SPIN-13": {
                    "questionId": "SPIN-13",
                    "chosenOptionIds": [
                        "43db293d-49f0-469c-9c24-8a0b8560bafa"
                    ]
                },
                "SPIN-14": {
                    "questionId": "SPIN-14",
                    "chosenOptionIds": [
                        "c67c0005-37c9-494a-a028-c17e63f7865d"
                    ]
                },
                "SPIN-15": {
                    "questionId": "SPIN-15",
                    "chosenOptionIds": [
                        "19bdf7d4-fc6a-4dfa-b558-7e0062671b6e"
                    ]
                },
                "SPIN-16": {
                    "questionId": "SPIN-16",
                    "chosenOptionIds": [
                        "df681418-e830-441f-a27d-da5655c0bdd6"
                    ]
                },
                "SPIN-17": {
                    "questionId": "SPIN-17",
                    "chosenOptionIds": [
                        "bed2b452-32e2-49fc-a627-71432d1edd5d"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {};
