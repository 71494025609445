"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "{}",
            "functionId": "PQ16RiskLevel"
        },
        "section": []
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I feel uninterested in the things I used to enjoy | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-1",
                    "optionSummaries": {
                        "ba52aa32-bab9-44e4-bb68-df2ae4d00134": "False",
                        "a6a7c248-8833-4170-a8b3-58f09a115fee": "True"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "How much distress did you experience from feeling uninterested in the things you used to enjoy? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-2",
                    "optionSummaries": {
                        "696e9d1a-a60c-4851-bb9e-f3329e858737": "0 - None",
                        "185f6c77-363d-43f1-b34d-6c3ef5da144c": "1 - Mild",
                        "189a78e9-5ab6-4c35-845d-7ce85e139ee2": "2 - Moderate",
                        "ec2b4c1e-0bbf-41b8-9097-3f69a30b1740": "3 - Severe"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I often seem to live through events exactly as they happened before (déjà vu) | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-3",
                    "optionSummaries": {
                        "c75fe2ce-2682-4661-8bac-da62181010d9": "False",
                        "17bd8f73-0a62-43fd-9392-91c10b44ad22": "True"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "How much distress did you experience from often seeming to live through events exactly as they happened before (déjà vu)? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-4",
                    "optionSummaries": {
                        "bc5b1d6b-32cd-4a75-8791-eee6a2717fd6": "0 - None",
                        "d8dcabf9-c43c-4fd9-baea-402034912901": "1 - Mild",
                        "218e77be-ab1b-43fc-86c9-158c859db6b7": "2 - Moderate",
                        "4e2563c5-3fff-45e4-941b-55beb01fbea6": "3 - Severe"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I sometimes smell or taste things that other people can’t smell or taste | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-5",
                    "optionSummaries": {
                        "fa0609de-87b9-4d4f-8abb-82c8f7e44712": "False",
                        "b6223a9b-27c9-4962-a76e-4f4a9aa17a32": "True"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "How much distress did you experience from sometimes smelling or tasting things that other people can’t smell or taste? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-6",
                    "optionSummaries": {
                        "327384bd-0414-4f76-a6ed-c5934dd57f55": "0 - None",
                        "de66c6de-546d-4a98-9eb1-93fddd23852a": "1 - Mild",
                        "c12c2f68-6702-4b66-a669-1a7b4b45d065": "2 - Moderate",
                        "0c043cdc-1445-4571-a884-74c82288d6f8": "3 - Severe"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I often hear unusual sounds like banging, clicking, hissing, clapping or ringing in my ears | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-7",
                    "optionSummaries": {
                        "feb32ba3-0629-424b-b86a-ba38c8dd48e3": "False",
                        "53881799-b644-48f2-8230-6856af7da942": "True"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "How much distress did you experience from often hearing unusual sounds like banging, clicking, hissing, clapping or ringing in your ears? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-8",
                    "optionSummaries": {
                        "0b0e31cc-62a4-4110-a1a8-646549fc7e35": "0 - None",
                        "ed001067-819c-4ee2-b6dc-680372ae7042": "1 - Mild",
                        "f1f22530-8493-498e-ba08-0b27ae12049e": "2 - Moderate",
                        "9c20db13-356d-4c1f-a396-52297a58b253": "3 - Severe"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I have been confused at times whether something I experienced was real or imaginary | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-9",
                    "optionSummaries": {
                        "c9d94315-a43c-4a7c-a5d9-ff9f9b6eeb09": "False",
                        "69aed6d9-adb4-4f39-a236-a65532fca894": "True"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "How much distress did you experience from being confused at times whether something you experienced was real or imaginary? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-10",
                    "optionSummaries": {
                        "dd86ab9d-d442-4a10-98df-4e5c155fcf6e": "0 - None",
                        "81453f5c-f3bc-4b11-bc99-2cd06c05e35f": "1 - Mild",
                        "038a091d-2f6a-4410-99bb-6930ecd8130a": "2 - Moderate",
                        "23b999d1-5157-4a43-8780-78eb670ca3de": "3 - Severe"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "When I look at a person, or look at myself in a mirror, I have seen the face change right before my eyes | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-11",
                    "optionSummaries": {
                        "cd8191c8-f42f-4a7a-bc15-2d67d7656e28": "False",
                        "9b82f7a5-4eca-419e-afc7-402399e897ea": "True"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "How much distress did you experience from seeing the face change right before your eyes when looking at a person, or yourself in a mirror? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-12",
                    "optionSummaries": {
                        "8c3edbdf-23ee-4b38-bb87-6f5be436feba": "0 - None",
                        "fef16de9-f80b-4aef-90a9-c9835247a62f": "1 - Mild",
                        "077a7b78-6ba5-4188-b7e8-172a7eff8f72": "2 - Moderate",
                        "788ff4a2-fb37-4655-9a9d-d0723f2f7c98": "3 - Severe"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I get extremely anxious when meeting people for the first time | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-13",
                    "optionSummaries": {
                        "a7fcc554-4d77-4ff4-aa62-017618d3a9fe": "False",
                        "4ec44f32-fffc-4edd-b02f-07f64f36f408": "True"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "How much distress did you experience from getting extremely anxious when meeting people for the first time? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-14",
                    "optionSummaries": {
                        "6307e69b-b5f4-4a9e-b259-539d4ccdc7c4": "0 - None",
                        "c759757d-d3fc-4836-9f83-1a37dd41c4a3": "1 - Mild",
                        "06be6bb2-bf78-499d-af38-f0ea14fc85d0": "2 - Moderate",
                        "0028737a-ad2e-4882-b857-7551101baa8a": "3 - Severe"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I have seen things that other people apparently can’t see | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-15",
                    "optionSummaries": {
                        "d05b6553-b6e2-4705-aa65-71babd98a0a9": "False",
                        "ea28fddf-d374-4c71-b85a-55e5224558c3": "True"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "How much distress did you experience from having seen things that other people apparently can’t see? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-16",
                    "optionSummaries": {
                        "491b6a42-d8dc-4234-9a31-17d4a8939e81": "0 - None",
                        "7026f08a-3b9b-4e5f-8531-eecb02159330": "1 - Mild",
                        "d6fbd883-c2ea-4487-a7da-6e6748adc8ed": "2 - Moderate",
                        "f50f09dc-abc0-41cd-89df-be98c4122e91": "3 - Severe"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "My thoughts are sometimes so strong that I can almost hear them | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-17",
                    "optionSummaries": {
                        "53a0d94b-1a1f-4222-a072-6d850c60b105": "False",
                        "1c45ff8b-70d3-4f37-a33d-5e0274c98386": "True"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "How much distress did you experience from sometimes having thoughts so strong that you can almost hear them? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-18",
                    "optionSummaries": {
                        "69619c7c-d313-471b-8f28-a339104de38d": "0 - None",
                        "29bbf8dd-10e9-4ef7-a1c1-d5efabfb8757": "1 - Mild",
                        "7356ae7f-ae83-454f-844b-431361be2b0e": "2 - Moderate",
                        "2612f271-6494-4c7c-aa4f-1421cc78caed": "3 - Severe"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I sometimes see special meanings in advertisements, shop windows, or in the way things are arranged around me | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-19",
                    "optionSummaries": {
                        "67077ce7-f5ce-4558-89a2-fe9e3c04e4d5": "False",
                        "9d407539-8496-4f80-933c-88c3bd3f104b": "True"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "How much distress did you experience from sometimes seeing special meanings in advertisements, shop windows, or in the way things are arranged around you? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-20",
                    "optionSummaries": {
                        "1c0ee8bb-6d1b-41c4-8493-84b0c001371c": "0 - None",
                        "afcaa979-d187-4378-a4ac-473ac2397dc3": "1 - Mild",
                        "12b45527-b8d0-496e-b11e-6c6c19fe33ca": "2 - Moderate",
                        "4adacf6c-efff-4655-98af-6bb3810eee1e": "3 - Severe"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Sometimes I have felt that I’m not in control of my own ideas or thoughts | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-21",
                    "optionSummaries": {
                        "b0bf0d54-a05f-4f92-9287-f5f4aba9bb5d": "False",
                        "bfe24eb1-bcc9-4f24-85dc-db6cd7fc0147": "True"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "How much distress did you experience from sometimes feeling that you’re not in control of your own ideas or thoughts? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-22",
                    "optionSummaries": {
                        "6f291fb4-b194-43d1-be75-759046df94ea": "0 - None",
                        "393c43b6-d9c6-403c-8913-044d33bb5365": "1 - Mild",
                        "3891120f-9ff8-496d-854c-73ae102bee79": "2 - Moderate",
                        "5e025a3d-9508-491c-8427-73f56f3ae779": "3 - Severe"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Sometimes I feel suddenly distracted by distant sounds that I am not normally aware of | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-23",
                    "optionSummaries": {
                        "a51d638d-7cd9-4620-964c-7d2cee3c625b": "False",
                        "b610511c-a527-4a8d-9364-40841f56d699": "True"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "How much distress did you experience from sometimes feeling suddenly distracted by distant sounds that you are not normally aware of? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-24",
                    "optionSummaries": {
                        "07e2486c-4bb6-4989-a671-8b155017bbd5": "0 - None",
                        "6a8abf03-05bc-4329-ae4d-f188231ccf61": "1 - Mild",
                        "0ff4b3c4-2a4b-4b0e-91f6-2234f83d26e3": "2 - Moderate",
                        "7eb004cc-e5ec-404b-b8e4-7e18bee03a59": "3 - Severe"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I have heard things other people can’t hear like voices of people whispering or talking | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-25",
                    "optionSummaries": {
                        "77230379-e298-438f-8b51-7ace302d1e11": "False",
                        "6729fd97-effb-492e-b4cd-171cff5bc861": "True"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "How much distress did you experience from having heard things other people can’t hear like voices of people whispering or talking? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-26",
                    "optionSummaries": {
                        "ca00b666-462f-4e0c-b4da-e592a8334f2c": "0 - None",
                        "675e8f0c-a50b-4f9c-bba8-6485e1f0f37d": "1 - Mild",
                        "7f44f7f7-6c2e-42ba-87c0-54d14bb2836e": "2 - Moderate",
                        "1ed22c05-4ec0-493d-9bbf-b65457a15aee": "3 - Severe"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I often feel that others have it in for me | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-27",
                    "optionSummaries": {
                        "a97455ae-190b-44f6-ad18-fc21530122a7": "False",
                        "c1dc1d47-c09a-4893-b938-de4a89ec3752": "True"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "How much distress did you experience from often feeling that others have it in for you? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-28",
                    "optionSummaries": {
                        "0eecdacf-2a78-4b35-8dac-28f8bbd0676c": "0 - None",
                        "15c6a5e8-d4b6-4b60-8b8f-59eddb2c8bdb": "1 - Mild",
                        "d113628c-cc0f-4dff-aea9-a36e883439e5": "2 - Moderate",
                        "85d21bf7-7f44-4b5d-8612-067ed1b451ab": "3 - Severe"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I have had the sense that some person or force is around me, even though I could not see anyone | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-29",
                    "optionSummaries": {
                        "55d4fc44-9580-4527-86ba-a94a3c15190f": "False",
                        "27ddef39-6320-49e6-8c2f-d3c9e48ffeb9": "True"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "How much distress did you experience from having had the sense that some person or force is around you, even though you could not see anyone? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-30",
                    "optionSummaries": {
                        "8b264b5d-08b1-47ef-a86b-fb54ee62d97b": "0 - None",
                        "403c25b5-447b-43a6-8a09-05ce3bacbb12": "1 - Mild",
                        "ed138599-3f27-4122-9bd0-77526c186a06": "2 - Moderate",
                        "186dfb77-c06e-4dbf-9763-970509f73f70": "3 - Severe"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "I feel that parts of my body have changed in some way, or that parts of my body are working differently than before | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-31",
                    "optionSummaries": {
                        "8ad16b9e-f3c5-48f7-ac40-dd5c3d2bccec": "False",
                        "b9707ed2-5434-4ec6-8404-e9610aeec041": "True"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "How much distress did you experience from feeling that parts of your body have changed in some way, or that parts of your body are working differently than before? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PQ16-32",
                    "optionSummaries": {
                        "419624b3-ec59-4621-b8c4-d0cf6f5ac604": "0 - None",
                        "ec9cdb8a-2e6c-48be-ac0a-96132772b9ee": "1 - Mild",
                        "c5b7f89b-3bfa-46d6-9928-9df56e9b44b9": "2 - Moderate",
                        "8138f443-dd64-410c-ad5e-5af16ddda7e7": "3 - Severe"
                    }
                }
            ]
        },
        "section": [
            "Symptoms"
        ]
    }
];
