"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.staticConditionOrdering = exports.conditionOrdering = exports.allConditionAspectNames = exports.conditionInformation = exports.createSkipCheck = void 0;
const __1 = require("../..");
const Suicide_symptoms_1 = require("./Suicide.symptoms");
const GAD_symptoms_1 = require("./GAD.symptoms");
const AD_symptoms_1 = require("./AD.symptoms");
const SoA_symptoms_1 = require("./SoA.symptoms");
const Insomnia_symptoms_1 = require("./Insomnia.symptoms");
const PD_symptoms_1 = require("./PD.symptoms");
const EUPD_symptoms_1 = require("./EUPD.symptoms");
const ED_symptoms_1 = require("./ED.symptoms");
const BP_symptoms_1 = require("./BP.symptoms");
const MDD_symptoms_1 = require("./MDD.symptoms");
const Alcohol_symptoms_1 = require("./Alcohol.symptoms");
const Substance_symptoms_1 = require("./Substance.symptoms");
const OCD_symptoms_1 = require("./OCD.symptoms");
const Psychosis_symptoms_1 = require("./Psychosis.symptoms");
const PTSD_symptoms_1 = require("./PTSD.symptoms");
const PC_symptoms_1 = require("./PC.symptoms");
const V2PC_symptoms_1 = require("./V2PC.symptoms");
const ADHD_symptoms_1 = require("./ADHD.symptoms");
const MDQ_symptoms_1 = require("./MDQ.symptoms");
const DAST10_symptoms_1 = require("./DAST10.symptoms");
const AUDIT_symptoms_1 = require("./AUDIT.symptoms");
const GAD7_symptoms_1 = require("./GAD7.symptoms");
const EDEQS_symptoms_1 = require("./EDEQS.symptoms");
const SPIN_symptoms_1 = require("./SPIN.symptoms");
const PHQ9_symptoms_1 = require("./PHQ9.symptoms");
const FIVEPS_symptoms_1 = require("./FIVEPS.symptoms");
const PDSS_symptoms_1 = require("./PDSS.symptoms");
const DIALOG_symptoms_1 = require("./DIALOG.symptoms");
const ISI_symptoms_1 = require("./ISI.symptoms");
const PCL5_symptoms_1 = require("./PCL5.symptoms");
const oci_symptoms_1 = require("./oci.symptoms");
const ocir_symptoms_1 = require("./ocir.symptoms");
const MSIBPD_symptoms_1 = require("./MSIBPD.symptoms");
const adnm_symptoms_1 = require("./adnm.symptoms");
const V2SUICIDESELFHARM_symptoms_1 = require("./V2SUICIDESELFHARM.symptoms");
const pq16_symptoms_1 = require("./pq16.symptoms");
const SCOFF_symptoms_1 = require("./SCOFF.symptoms");
const oci_1 = require("./oci");
const SCOFF_1 = require("./SCOFF");
const PCL5_1 = require("./PCL5");
const answerSummaries_1 = require("../../answerSummaries");
const oci_2 = require("../../conditions/oci");
function createSkipCheck(questions) {
    return (answers) => {
        const answerQuestionIdSet = new Set(answers.map((a) => a.questionId));
        return !questions
            .filter((question) => question.__typename !== 'ContentQuestion')
            .every(({ id }) => answerQuestionIdSet.has(id));
    };
}
exports.createSkipCheck = createSkipCheck;
const conditionInformation = (userType) => {
    return {
        simple_bd1: {
            name: 'Bipolar I',
            patientFacingName: 'Extremes in Mood and Energy',
            summaries: BP_symptoms_1.symptoms.filter((s) => (0, answerSummaries_1.questionIds)(s.answerSummary).every((questionId) => !questionId.startsWith('D-'))),
            summariesOrdering: [
                'Previous manic episode(s) details',
                'Current manic symptoms',
                'Hospitalised',
                'Duration of hospital stay:',
                'Duration of longest hospital stay:',
            ],
        },
        simple_bd2: {
            name: 'Bipolar II',
            nameContextual: 'Recurring Low Mood with Periods of Increased Energy',
            patientFacingName: 'Recurrent Depression with Intermittent Mild Highs',
            summaries: BP_symptoms_1.symptoms,
            summariesOrdering: [
                'Previous manic episode(s) details',
                'Previous depressive episode(s) details',
                'Current manic symptoms',
                'Hospitalised',
                'Duration of hospital stay:',
                'Duration of longest stay in hospital:',
                'Formal admittance to hospital due to symptoms:',
            ],
        },
        simple_mdd: {
            name: 'Major depressive disorder',
            patientFacingName: 'Low Mood / Enjoyment in Life',
            summaries: MDD_symptoms_1.symptoms,
            summariesOrdering: [
                'Current episode',
                'Past episode',
                'Typical episode',
                'Most severe episode',
                'Longest episode',
                'Previous episode(s) details',
                'Hospitalised',
                'Duration of hospital stay:',
                'Duration of longest stay in hospital:',
                'Formal admittance to hospital due to symptoms:',
            ],
        },
        gad_ce: {
            name: 'Generalised anxiety disorder',
            patientFacingName: 'Persistent Anxiety, Worrying and Tension',
            summaries: GAD_symptoms_1.symptoms,
            summariesOrdering: ['Current episode', 'Previous episode'],
        },
        adj_ce: {
            name: 'Adjustment disorder',
            patientFacingName: 'Difficulties Adjusting to Life Stressor(s)',
            summaries: AD_symptoms_1.symptoms,
            summariesOrdering: ['Recent major life event(s)', 'Symptoms'],
        },
        soa_ce: {
            name: 'Social anxiety disorder',
            patientFacingName: 'Strong Fear of Social Situations',
            summaries: SoA_symptoms_1.symptoms,
            summariesOrdering: ['Current episode'],
        },
        ins_ce: {
            name: 'Insomnia',
            nameContextual: 'Persistent Difficulties with Sleep',
            patientFacingName: 'Problems with Sleep',
            summaries: Insomnia_symptoms_1.symptoms,
            summariesOrdering: ['Current episode'],
        },
        pad_ce: {
            name: 'Panic disorder',
            patientFacingName: 'Unexpected Panic Attacks',
            summaries: PD_symptoms_1.symptoms,
            summariesOrdering: ['Current episode'],
        },
        emi_ce: {
            name: 'Emotional instability',
            patientFacingName: 'Emotional and Relationship Instability',
            summaries: EUPD_symptoms_1.symptoms,
            summariesOrdering: ['Current symptoms and impairment', 'Duration and frequency of symptoms'],
        },
        dse_ce: {
            name: 'Disordered eating',
            nameContextual: 'Difficult Relationship with Food and Eating',
            patientFacingName: 'Problematic Eating Patterns',
            summaries: ED_symptoms_1.symptoms,
            summariesOrdering: ['Current episode'],
        },
        s_current: {
            name: 'Suicide',
            summaries: Suicide_symptoms_1.symptoms.filter((s) => (0, answerSummaries_1.questionIds)(s.answerSummary).every((questionId) => questionId.startsWith('S-'))),
            summariesOrdering: ['Current period', 'Past period(s)'],
        },
        sh_current: {
            name: 'Self harm',
            summaries: Suicide_symptoms_1.symptoms.filter((s) => (0, answerSummaries_1.questionIds)(s.answerSummary).every((questionId) => questionId.startsWith('SH-'))),
            summariesOrdering: [
                'Frequency of self-harm behaviours',
                'Self-harm details',
                'Medical assistance and hospitalisation',
            ],
        },
        alc_ce: {
            name: 'Alcohol use',
            patientFacingName: 'Problematic Alcohol Use',
            summaries: Alcohol_symptoms_1.symptoms,
            summariesOrdering: ['Current episode (in the last year)', 'Previous episode'],
        },
        alc_ce_dep: {
            name: 'Alcohol use and dependency',
            summaries: Alcohol_symptoms_1.symptoms,
            summariesOrdering: ['Current episode (in the last year)', 'Previous episode'],
        },
        alc_pe_dep: {
            name: 'Past Alcohol use and dependency',
            summaries: Alcohol_symptoms_1.symptoms,
            summariesOrdering: ['Current episode (in the last year)', 'Previous episode'],
        },
        sbs_ce: {
            name: 'Substance abuse',
            patientFacingName: 'Problematic Drug Use',
            summaries: Substance_symptoms_1.symptoms.filter((s) => { var _a; return !s.answerSummary.tags || ((_a = s.answerSummary.tags) === null || _a === void 0 ? void 0 : _a.includes('sbs_ce')); }),
            summariesOrdering: ['Episode details'],
        },
        sbs_pe: {
            name: 'Past substance abuse',
            summaries: Substance_symptoms_1.symptoms.filter((s) => { var _a; return !s.answerSummary.tags || ((_a = s.answerSummary.tags) === null || _a === void 0 ? void 0 : _a.includes('sbs_pe')); }),
            summariesOrdering: ['Episode details'],
        },
        ocd_ce: {
            name: 'Obsessive compulsive disorder',
            patientFacingName: 'Unwanted Thoughts and Repetitive Behaviours',
            summaries: OCD_symptoms_1.symptoms,
            summariesOrdering: [
                'Recent experience of obsessions and compulsions',
                'Current episode',
                'Duration of symptoms',
            ],
        },
        pso_ce: {
            name: 'Psychotic symptoms',
            patientFacingName: 'Unusual Perceptions, Beliefs and Experiences',
            summaries: Psychosis_symptoms_1.symptoms,
            summariesOrdering: [
                'Recent symptoms (in the last year)',
                'Previous symptoms (not in the last year)',
                'Symptoms',
                'Duration and onset',
            ],
        },
        trm_re_ptsd: {
            name: userType === __1.UserType.D2C ? 'Traumatic Stress' : 'Trauma',
            nameContextual: 'Distress Reactions to Past Trauma',
            patientFacingName: 'Traumatic Stress',
            summaries: PTSD_symptoms_1.symptoms,
            summariesOrdering: ['Reported traumatic event(s)', 'Current trauma related symptoms'],
        },
        adhd: {
            name: 'ADHD',
            patientFacingName: 'ADHD',
            summaries: ADHD_symptoms_1.symptoms,
        },
        dast10: {
            name: 'DAST Substance use',
            patientFacingName: 'DAST Substance use',
            summaries: DAST10_symptoms_1.symptoms,
            introduction: 'Screens drug use patterns over past year',
            measures: {
                total: 10,
                thresholds: [{
                        thresholdLabel: "none",
                        thresholdValue: 0
                    }, {
                        thresholdLabel: "low level",
                        thresholdValue: 1
                    }, {
                        thresholdLabel: "moderate level",
                        thresholdValue: 3
                    }, {
                        thresholdLabel: "substantial level",
                        thresholdValue: 6
                    }, {
                        thresholdLabel: "severe level",
                        thresholdValue: 9
                    }]
            }
        },
        audit: {
            name: 'AUDIT Alcohol use',
            patientFacingName: 'AUDIT Alcohol use',
            summaries: AUDIT_symptoms_1.symptoms,
            introduction: "Screens alcohol use patterns over past year",
            measures: {
                total: 40,
                thresholds: [{
                        thresholdLabel: "low risk",
                        thresholdValue: 0
                    }, {
                        thresholdLabel: "increasing risk",
                        thresholdValue: 8
                    }, {
                        thresholdLabel: "higher risk",
                        thresholdValue: 16
                    }, {
                        thresholdLabel: "possible dependence",
                        thresholdValue: 20
                    }]
            }
        },
        gad7: {
            name: 'GAD-7 Anxiety',
            patientFacingName: 'GAD-7 Anxiety',
            summaries: GAD7_symptoms_1.symptoms,
            introduction: 'Measures anxiety symptoms over the last 2 weeks',
            measures: {
                total: 21,
                thresholds: [{
                        thresholdLabel: 'minimal',
                        thresholdValue: 0
                    }, {
                        thresholdLabel: 'mild',
                        thresholdValue: 5
                    }, {
                        thresholdLabel: 'moderate',
                        thresholdValue: 10
                    }, {
                        thresholdLabel: 'severe',
                        thresholdValue: 15
                    }]
            }
        },
        ede_qs: {
            name: 'Eating Disorder Examination Questionnaire Short (EDE-QS)',
            patientFacingName: 'Eating Disorder Examination Questionnaire Short (EDE-QS)',
            summaries: EDEQS_symptoms_1.symptoms,
            introduction: 'Measures eating disorder symptom severity',
            measures: {
                total: 36,
                thresholds: [{
                        thresholdLabel: 'below',
                        thresholdValue: 0
                    }, {
                        thresholdLabel: 'above',
                        thresholdValue: 15
                    }]
            }
        },
        spin: {
            name: 'SPIN Social anxiety',
            patientFacingName: 'SPIN Social anxiety',
            introduction: 'Assesses social anxiety disorder severity during the last week',
            summaries: SPIN_symptoms_1.symptoms,
            measures: {
                total: 68,
                thresholds: [{
                        thresholdLabel: 'none',
                        thresholdValue: 0
                    }, {
                        thresholdLabel: 'mild',
                        thresholdValue: 21
                    }, {
                        thresholdLabel: 'moderate',
                        thresholdValue: 31
                    }, {
                        thresholdLabel: 'severe',
                        thresholdValue: 41
                    }, {
                        thresholdLabel: 'very severe',
                        thresholdValue: 51
                    }]
            }
        },
        mdq: {
            name: 'Mood Disorder Questionnaire (MDQ)',
            patientFacingName: 'Mood Disorder Questionnaire (MDQ)',
            introduction: 'Screens for lifetime manic/hypomanic symptoms',
            summaries: MDQ_symptoms_1.symptoms,
            measures: {
                total: 13,
                thresholds: [{
                        thresholdLabel: 'negative',
                        thresholdValue: 0
                    }, {
                        thresholdLabel: 'positive',
                        thresholdValue: 7
                    }]
            }
        },
        isi: {
            name: 'ISI Insomnia',
            patientFacingName: 'ISI Insomnia',
            summaries: ISI_symptoms_1.symptoms,
            introduction: 'Measures severity of insomnia symptoms over past two weeks',
            measures: {
                total: 28,
                thresholds: [{
                        thresholdLabel: 'not clinically significant',
                        thresholdValue: 0
                    }, {
                        thresholdLabel: 'subthreshold',
                        thresholdValue: 8
                    }, {
                        thresholdLabel: 'moderate',
                        thresholdValue: 15
                    }, {
                        thresholdLabel: 'severe',
                        thresholdValue: 22
                    }]
            }
        },
        pc: {
            type: 'static',
            name: 'Patient context',
            patientFacingName: 'Your Circumstances',
            summaries: PC_symptoms_1.symptoms,
            summariesOrdering: [
                'Social support',
                'Relationship',
                'Housing',
                'Caring responsibilities',
                'Mental health',
                'Physical health',
            ],
        },
        v2Pc: {
            type: 'static',
            name: 'Patient context V2',
            patientFacingName: 'Your Circumstances',
            summaries: V2PC_symptoms_1.symptoms,
            summariesOrdering: [
                'Patient Current Status',
                'Past Physical and Mental Health Conditions',
            ],
        },
        dialog: {
            name: 'Dialog Questionnaire',
            patientFacingName: 'Dialog Questionnaire',
            summaries: DIALOG_symptoms_1.symptoms,
        },
        phq9: {
            name: 'PHQ-9 Depression',
            patientFacingName: 'PHQ-9 Depression',
            introduction: 'Measures symptoms of depression over the last 2 weeks',
            summaries: PHQ9_symptoms_1.symptoms,
            measures: {
                total: 27,
                thresholds: [{
                        thresholdLabel: 'minimal',
                        thresholdValue: 0
                    }, {
                        thresholdLabel: 'mild',
                        thresholdValue: 5
                    }, {
                        thresholdLabel: 'moderate',
                        thresholdValue: 10
                    }, {
                        thresholdLabel: 'moderately severe',
                        thresholdValue: 15
                    }, {
                        thresholdLabel: 'severe',
                        thresholdValue: 20
                    }]
            }
        },
        five_ps: {
            name: 'Five Ps',
            patientFacingName: 'Five Ps',
            summaries: FIVEPS_symptoms_1.symptoms,
        },
        pcl5: {
            name: 'PCL-5 PTSD',
            patientFacingName: 'Posttraumatic Stress Disorder Checklist (PCL-5)',
            introduction: 'Assesses PTSD symptoms (past month)',
            summaries: PCL5_symptoms_1.symptoms,
            measures: {
                total: 80,
                thresholds: [{
                        thresholdLabel: 'not probable',
                        thresholdValue: 0
                    }, {
                        thresholdLabel: 'probable',
                        thresholdValue: 31
                    }]
            },
            applySkipCheck: createSkipCheck(PCL5_1.questions)
        },
        pdss: {
            name: 'PDSS Panic Disorder',
            patientFacingName: 'PDSS Panic Disorder',
            introduction: 'Assesses panic disorder severity over the past week',
            summaries: PDSS_symptoms_1.symptoms,
            measures: {
                total: 28,
                thresholds: [
                    {
                        thresholdLabel: 'normal',
                        thresholdValue: 0
                    }, {
                        thresholdLabel: 'borderline',
                        thresholdValue: 2
                    }, {
                        thresholdLabel: 'slightly ill',
                        thresholdValue: 6
                    }, {
                        thresholdLabel: 'moderately ill',
                        thresholdValue: 10
                    }, {
                        thresholdLabel: 'markedly ill',
                        thresholdValue: 14
                    }
                ]
            }
        },
        oci: {
            name: 'OCI OCD',
            patientFacingName: 'OCI OCD',
            summaries: oci_symptoms_1.symptoms,
            introduction: 'Assesses the presence and severity of obsessive-compulsive symptoms',
            generateScoreBreakdown: oci_2.generateScoreBreakdown,
            measures: {
                total: 168,
                thresholds: [
                    {
                        thresholdLabel: 'not suggested',
                        thresholdValue: 0
                    }, {
                        thresholdLabel: 'suggested',
                        thresholdValue: 42
                    }
                ]
            },
            applySkipCheck: createSkipCheck(oci_1.questions)
        },
        ocir: {
            name: 'Obsessional Compulsive Inventory (OCI-R)',
            patientFacingName: 'Obsessional Compulsive Inventory (OCI-R)',
            introduction: 'Measures OCD symptoms and severity over the past month',
            summaries: ocir_symptoms_1.symptoms,
            measures: {
                total: 72,
                thresholds: [
                    {
                        thresholdLabel: 'not likely',
                        thresholdValue: 0
                    }, {
                        thresholdLabel: 'likely',
                        thresholdValue: 21
                    }
                ]
            },
        },
        msi_bpd: {
            name: 'McLean Screening Instrument for BPD (MSI-BPD)',
            patientFacingName: 'McLean Screening Instrument for BPD (MSI-BPD)',
            summaries: MSIBPD_symptoms_1.symptoms,
            introduction: 'Screens for borderline personality disorder',
            measures: {
                total: 10,
                thresholds: [
                    {
                        thresholdLabel: '',
                        thresholdValue: 0
                    }, {
                        thresholdLabel: 'likely',
                        thresholdValue: 7
                    }
                ]
            }
        },
        adnm: {
            name: 'Adjustment Disorder New Module 20 (ADNM-20)',
            patientFacingName: 'Adjustment Disorder–New Module 20 (ADNM-20)',
            introduction: 'Measures adjustment disorder core symptoms',
            summaries: adnm_symptoms_1.symptoms,
            measures: {
                total: 80,
                thresholds: [
                    {
                        thresholdLabel: 'No',
                        thresholdValue: 0
                    }, {
                        thresholdLabel: 'Yes',
                        thresholdValue: 48
                    }
                ]
            }
        },
        pq16: {
            name: 'Prodromal Questionnaire (PQ-16)',
            patientFacingName: 'Prodromal Questionnaire (PQ-16)',
            introduction: 'Screens for early signs of psychosis',
            summaries: pq16_symptoms_1.symptoms,
            measures: {
                total: 16,
                thresholds: [
                    {
                        thresholdLabel: 'No',
                        thresholdValue: 0
                    }, {
                        thresholdLabel: 'Yes',
                        thresholdValue: 6
                    }
                ]
            }
        },
        v2SuicideSelfharm: {
            name: 'Suicide and Self-harm Questions',
            patientFacingName: 'Suicide and Self-harm Questions',
            summaries: V2SUICIDESELFHARM_symptoms_1.symptoms,
        },
        scoff: {
            name: 'SCOFF Eating Disorder',
            patientFacingName: 'SCOFF Eating Disorder',
            introduction: 'Assesses symptoms of an eating disorder',
            summaries: SCOFF_symptoms_1.symptoms,
            measures: {
                total: 5,
                thresholds: [
                    {
                        thresholdLabel: 'not likely',
                        thresholdValue: 0
                    }, {
                        thresholdLabel: 'likely',
                        thresholdValue: 2
                    }
                ]
            },
            applySkipCheck: createSkipCheck(SCOFF_1.questions.filter(q => ['SCOFF-1', 'SCOFF-2', 'SCOFF-3', 'SCOFF-4', 'SCOFF-5'].includes(q.id)))
        },
    };
};
exports.conditionInformation = conditionInformation;
const allConditionAspectNames = (userType) => Object.keys((0, exports.conditionInformation)(userType));
exports.allConditionAspectNames = allConditionAspectNames;
exports.conditionOrdering = [
    // [{
    //     aspectName: "s_current",
    //     mininimSeverity: 0.25
    // }],
    // [{
    //     aspectName: "sh_current",
    //     mininimSeverity: 0.25
    // }],
    [
        {
            aspectName: 'pso_ce',
            mininimSeverity: 0.5,
        },
    ],
    [
        {
            aspectName: 'sbs_ce',
            mininimSeverity: 0.75,
        },
    ],
    [
        {
            aspectName: 'sbs_pe',
            mininimSeverity: 0.75,
        },
    ],
    [
        {
            aspectName: 'alc_ce_dep',
            mininimSeverity: 0.75,
        },
    ],
    [
        {
            aspectName: 'alc_ce',
            mininimSeverity: 0.75,
        },
    ],
    [
        {
            aspectName: 'alc_pe_dep',
            mininimSeverity: 0.75,
        },
    ],
    [
        {
            aspectName: 'simple_bd1',
            mininimSeverity: 0.5,
        },
        {
            aspectName: 'simple_bd2',
            mininimSeverity: 0.5,
        },
    ],
    [
        {
            aspectName: 'simple_mdd',
            mininimSeverity: 0.75,
        },
    ],
    [
        {
            aspectName: 'emi_ce',
            mininimSeverity: 0.75,
        },
    ],
    [
        {
            aspectName: 'pso_ce',
            mininimSeverity: 0.25,
        },
    ],
    [
        {
            aspectName: 'trm_re_ptsd',
            mininimSeverity: 0.75,
        },
        {
            aspectName: 'gad_ce',
            mininimSeverity: 0.75,
        },
        {
            aspectName: 'soa_ce',
            mininimSeverity: 0.75,
        },
        {
            aspectName: 'pad_ce',
            mininimSeverity: 0.75,
        },
        {
            aspectName: 'ocd_ce',
            mininimSeverity: 0.75,
        },
        {
            aspectName: 'dse_ce',
            mininimSeverity: 0.75,
        },
    ],
    [
        {
            aspectName: 'sbs_ce',
            mininimSeverity: 0.5,
        },
    ],
    [
        {
            aspectName: 'sbs_pe',
            mininimSeverity: 0.5,
        },
    ],
    [
        {
            aspectName: 'alc_ce_dep',
            mininimSeverity: 0.5,
        },
    ],
    [
        {
            aspectName: 'alc_ce',
            mininimSeverity: 0.5,
        },
    ],
    [
        {
            aspectName: 'alc_pe_dep',
            mininimSeverity: 0.5,
        },
    ],
    [
        {
            aspectName: 'simple_mdd',
            mininimSeverity: 0.5,
        },
    ],
    [
        {
            aspectName: 'emi_ce',
            mininimSeverity: 0.5,
        },
    ],
    [
        {
            aspectName: 'trm_re_ptsd',
            mininimSeverity: 0.5,
        },
        {
            aspectName: 'gad_ce',
            mininimSeverity: 0.5,
        },
        {
            aspectName: 'soa_ce',
            mininimSeverity: 0.5,
        },
        {
            aspectName: 'pad_ce',
            mininimSeverity: 0.5,
        },
        {
            aspectName: 'ocd_ce',
            mininimSeverity: 0.5,
        },
        {
            aspectName: 'dse_ce',
            mininimSeverity: 0.5,
        },
    ],
    [
        {
            aspectName: 'ins_ce',
            mininimSeverity: 0.5,
        },
        {
            aspectName: 'adj_ce',
            mininimSeverity: 0.5,
        },
    ],
    [
        {
            aspectName: 'simple_bd1',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'simple_bd2',
            mininimSeverity: 0.25,
        },
    ],
    [
        {
            aspectName: 'simple_mdd',
            mininimSeverity: 0.25,
        },
    ],
    [
        {
            aspectName: 'emi_ce',
            mininimSeverity: 0.25,
        },
    ],
    [
        {
            aspectName: 'trm_re_ptsd',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'gad_ce',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'soa_ce',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'pad_ce',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'ocd_ce',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'dse_ce',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'adhd',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'gad7',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'dast10',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'ede_qs',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'phq9',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'spin',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'isi',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'mdq',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'pcl5',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'pdss',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'oci',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'ocir',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'msi_bpd',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'adnm',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'pq16',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'scoff',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'audit',
            mininimSeverity: 0.25
        }
    ],
    // [{
    //     aspectName: "sbs_ce",
    //     mininimSeverity: 0.25
    // }],
    // [{
    //     aspectName: "sbs_pe",
    //     mininimSeverity: 0.25
    // }],
    // [{
    //     aspectName: "alc_ce_dep",
    //     mininimSeverity: 0.25
    // }],
    // [{
    //     aspectName: "alc_ce",
    //     mininimSeverity: 0.25
    // }],
    // [{
    //     aspectName: "alc_pe_dep",
    //     mininimSeverity: 0.25
    // }],
    [
        {
            aspectName: 'ins_ce',
            mininimSeverity: 0.25,
        },
        {
            aspectName: 'adj_ce',
            mininimSeverity: 0.25,
        },
    ],
];
exports.staticConditionOrdering = ['pc', 'alc_pe_dep', 'sbs_pe', 'dialog', 'five_ps', 'v2SuicideSelfharm', 'v2Pc'];
