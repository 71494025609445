"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
exports.questions = [
    {
        "__typename": "ContentQuestion",
        "id": "SCOFF-content",
        "text": "The next questions ask about eating patterns, weight, and body image. We know these can be sensitive topics that might bring up difficult feelings.\n\nIt is important to know:\n\n- If you skip these questions, you will not be able to go back later.\n- Skipping will not affect your care.\n- You can talk about these experiences with your clinician whenever you are ready.",
        "answers": [
            {
                "__typename": "Option",
                "id": "2c42653f-484e-4a6e-bc21-98b42b59479c",
                "text": "Continue and answer these questions",
                "primary": true,
                "value": 1
            },
            {
                "__typename": "Option",
                "id": "600ae564-ccf9-429c-9156-2c33eda5acc7",
                "text": "Skip these questions",
                "primary": false,
                "value": 0
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "e0eaa4cd-5620-4c33-928d-d519aa08246e",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "bd562ae7-f6a5-4c90-986e-d7a0775b33fd",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SCOFF-1",
        "text": "Do you make yourself sick because you feel uncomfortably full?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ca3d2d61-a99e-4aef-a143-28dcf7a11b74",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "dec92aba-a555-4bdc-9f85-4301c852792b",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SCOFF-2",
        "text": "Do you worry you have lost control over how much you eat?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ea0da749-e9e1-4860-bdaa-d55efb821662",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "afae9912-278f-49a0-9997-86ad98f4de9f",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SCOFF-3",
        "text": "Have you recently lost more than one stone (6.35 kg) in a three-month period?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "9290f8d3-d0cf-4c49-bc1f-26724c6e4320",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "cd806bc9-53ec-4f38-aecf-123ce260a0ba",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SCOFF-4",
        "text": "Do you believe yourself to be fat when others say you are too thin?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "0c6d4a9d-fcd5-478e-80e2-379b93846c94",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b92959b6-7f31-4e8e-91e8-17b2373ca25c",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SCOFF-5",
        "text": "Would you say food dominates your life?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "90ccaf6a-7abd-45cd-a09e-bd369f072f43",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "14a5d540-2c89-4d9a-a52a-c31c85c3ac9b",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SCOFF-6",
        "text": "Are you satisfied with your eating patterns?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "d5d2c703-05fb-4a50-b5b5-ed1bbf7355b1",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "934e2d1b-3d74-46a7-8da4-f6a7566f777a",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "SCOFF-7",
        "text": "Do you ever eat in secret?",
        "isMultiselect": false
    }
];
exports.aspects = {
    "scoff": {
        "type": "mean",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SCOFF-1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "e0eaa4cd-5620-4c33-928d-d519aa08246e": 0,
                            "bd562ae7-f6a5-4c90-986e-d7a0775b33fd": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SCOFF-2",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "ca3d2d61-a99e-4aef-a143-28dcf7a11b74": 0,
                            "dec92aba-a555-4bdc-9f85-4301c852792b": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SCOFF-3",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "ea0da749-e9e1-4860-bdaa-d55efb821662": 0,
                            "afae9912-278f-49a0-9997-86ad98f4de9f": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SCOFF-4",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "9290f8d3-d0cf-4c49-bc1f-26724c6e4320": 0,
                            "cd806bc9-53ec-4f38-aecf-123ce260a0ba": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "SCOFF-5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "0c6d4a9d-fcd5-478e-80e2-379b93846c94": 0,
                            "b92959b6-7f31-4e8e-91e8-17b2373ca25c": 1
                        }
                    }
                }
            }
        ]
    },
    "not(SCOFF-content=0)": {
        "type": "not",
        "wire": {
            "type": "input",
            "signal": {
                "type": "AnsweredOption",
                "questionId": "SCOFF-content",
                "options": {
                    "type": "predicates",
                    "predicates": {
                        "2c42653f-484e-4a6e-bc21-98b42b59479c": false,
                        "600ae564-ccf9-429c-9156-2c33eda5acc7": true
                    }
                }
            }
        }
    },
    "and[or[and[SCOFF-1=1, or[SCOFF-2=1, SCOFF-3=1, SCOFF-4=1, SCOFF-5=1]], and[SCOFF-2=1, SCOFF-3=1], and[SCOFF-2=1, SCOFF-4=1], and[SCOFF-2=1, SCOFF-5=1], and[SCOFF-3=1, SCOFF-4=1], and[SCOFF-3=1, SCOFF-5=1], and[SCOFF-4=1, SCOFF-5=1]], not(isUndefined(SCOFF-5=0))]": {
        "type": "merge",
        "wires": [
            {
                "type": "merge",
                "wires": [
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "SCOFF-1",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "e0eaa4cd-5620-4c33-928d-d519aa08246e": false,
                                            "bd562ae7-f6a5-4c90-986e-d7a0775b33fd": true
                                        }
                                    }
                                }
                            },
                            {
                                "type": "merge",
                                "wires": [
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "SCOFF-2",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "ca3d2d61-a99e-4aef-a143-28dcf7a11b74": false,
                                                    "dec92aba-a555-4bdc-9f85-4301c852792b": true
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "SCOFF-3",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "ea0da749-e9e1-4860-bdaa-d55efb821662": false,
                                                    "afae9912-278f-49a0-9997-86ad98f4de9f": true
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "SCOFF-4",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "9290f8d3-d0cf-4c49-bc1f-26724c6e4320": false,
                                                    "cd806bc9-53ec-4f38-aecf-123ce260a0ba": true
                                                }
                                            }
                                        }
                                    },
                                    {
                                        "type": "input",
                                        "signal": {
                                            "type": "AnsweredOption",
                                            "questionId": "SCOFF-5",
                                            "options": {
                                                "type": "predicates",
                                                "predicates": {
                                                    "0c6d4a9d-fcd5-478e-80e2-379b93846c94": false,
                                                    "b92959b6-7f31-4e8e-91e8-17b2373ca25c": true
                                                }
                                            }
                                        }
                                    }
                                ],
                                "anyToAll": 0.25
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "SCOFF-2",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "ca3d2d61-a99e-4aef-a143-28dcf7a11b74": false,
                                            "dec92aba-a555-4bdc-9f85-4301c852792b": true
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "SCOFF-3",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "ea0da749-e9e1-4860-bdaa-d55efb821662": false,
                                            "afae9912-278f-49a0-9997-86ad98f4de9f": true
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "SCOFF-2",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "ca3d2d61-a99e-4aef-a143-28dcf7a11b74": false,
                                            "dec92aba-a555-4bdc-9f85-4301c852792b": true
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "SCOFF-4",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "9290f8d3-d0cf-4c49-bc1f-26724c6e4320": false,
                                            "cd806bc9-53ec-4f38-aecf-123ce260a0ba": true
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "SCOFF-2",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "ca3d2d61-a99e-4aef-a143-28dcf7a11b74": false,
                                            "dec92aba-a555-4bdc-9f85-4301c852792b": true
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "SCOFF-5",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "0c6d4a9d-fcd5-478e-80e2-379b93846c94": false,
                                            "b92959b6-7f31-4e8e-91e8-17b2373ca25c": true
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "SCOFF-3",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "ea0da749-e9e1-4860-bdaa-d55efb821662": false,
                                            "afae9912-278f-49a0-9997-86ad98f4de9f": true
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "SCOFF-4",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "9290f8d3-d0cf-4c49-bc1f-26724c6e4320": false,
                                            "cd806bc9-53ec-4f38-aecf-123ce260a0ba": true
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "SCOFF-3",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "ea0da749-e9e1-4860-bdaa-d55efb821662": false,
                                            "afae9912-278f-49a0-9997-86ad98f4de9f": true
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "SCOFF-5",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "0c6d4a9d-fcd5-478e-80e2-379b93846c94": false,
                                            "b92959b6-7f31-4e8e-91e8-17b2373ca25c": true
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    },
                    {
                        "type": "merge",
                        "wires": [
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "SCOFF-4",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "9290f8d3-d0cf-4c49-bc1f-26724c6e4320": false,
                                            "cd806bc9-53ec-4f38-aecf-123ce260a0ba": true
                                        }
                                    }
                                }
                            },
                            {
                                "type": "input",
                                "signal": {
                                    "type": "AnsweredOption",
                                    "questionId": "SCOFF-5",
                                    "options": {
                                        "type": "predicates",
                                        "predicates": {
                                            "0c6d4a9d-fcd5-478e-80e2-379b93846c94": false,
                                            "b92959b6-7f31-4e8e-91e8-17b2373ca25c": true
                                        }
                                    }
                                }
                            }
                        ],
                        "anyToAll": 1
                    }
                ],
                "anyToAll": 0.14285714285714285
            },
            {
                "type": "not",
                "wire": {
                    "type": "isUnknown",
                    "wire": {
                        "type": "input",
                        "signal": {
                            "type": "AnsweredOption",
                            "questionId": "SCOFF-5",
                            "options": {
                                "type": "predicates",
                                "predicates": {
                                    "0c6d4a9d-fcd5-478e-80e2-379b93846c94": true,
                                    "b92959b6-7f31-4e8e-91e8-17b2373ca25c": false
                                }
                            }
                        }
                    }
                }
            }
        ],
        "anyToAll": 1
    }
};
exports.tests = {
    "scoff": [
        {
            "name": "SCOFF - score 0-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0,
                "threshold": 0
            },
            "answers": {
                "SCOFF-1": {
                    "questionId": "SCOFF-1",
                    "chosenOptionIds": [
                        "e0eaa4cd-5620-4c33-928d-d519aa08246e"
                    ]
                },
                "SCOFF-2": {
                    "questionId": "SCOFF-2",
                    "chosenOptionIds": [
                        "ca3d2d61-a99e-4aef-a143-28dcf7a11b74"
                    ]
                },
                "SCOFF-3": {
                    "questionId": "SCOFF-3",
                    "chosenOptionIds": [
                        "ea0da749-e9e1-4860-bdaa-d55efb821662"
                    ]
                },
                "SCOFF-4": {
                    "questionId": "SCOFF-4",
                    "chosenOptionIds": [
                        "9290f8d3-d0cf-4c49-bc1f-26724c6e4320"
                    ]
                },
                "SCOFF-5": {
                    "questionId": "SCOFF-5",
                    "chosenOptionIds": [
                        "0c6d4a9d-fcd5-478e-80e2-379b93846c94"
                    ]
                },
                "SCOFF-6": {
                    "questionId": "SCOFF-6",
                    "chosenOptionIds": [
                        "90ccaf6a-7abd-45cd-a09e-bd369f072f43"
                    ]
                },
                "SCOFF-7": {
                    "questionId": "SCOFF-7",
                    "chosenOptionIds": [
                        "d5d2c703-05fb-4a50-b5b5-ed1bbf7355b1"
                    ]
                }
            }
        },
        {
            "name": "SCOFF - score 2 out of 5-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.4,
                "threshold": 0
            },
            "answers": {
                "SCOFF-1": {
                    "questionId": "SCOFF-1",
                    "chosenOptionIds": [
                        "bd562ae7-f6a5-4c90-986e-d7a0775b33fd"
                    ]
                },
                "SCOFF-2": {
                    "questionId": "SCOFF-2",
                    "chosenOptionIds": [
                        "dec92aba-a555-4bdc-9f85-4301c852792b"
                    ]
                },
                "SCOFF-3": {
                    "questionId": "SCOFF-3",
                    "chosenOptionIds": [
                        "ea0da749-e9e1-4860-bdaa-d55efb821662"
                    ]
                },
                "SCOFF-4": {
                    "questionId": "SCOFF-4",
                    "chosenOptionIds": [
                        "9290f8d3-d0cf-4c49-bc1f-26724c6e4320"
                    ]
                },
                "SCOFF-5": {
                    "questionId": "SCOFF-5",
                    "chosenOptionIds": [
                        "0c6d4a9d-fcd5-478e-80e2-379b93846c94"
                    ]
                },
                "SCOFF-6": {
                    "questionId": "SCOFF-6",
                    "chosenOptionIds": [
                        "90ccaf6a-7abd-45cd-a09e-bd369f072f43"
                    ]
                },
                "SCOFF-7": {
                    "questionId": "SCOFF-7",
                    "chosenOptionIds": [
                        "d5d2c703-05fb-4a50-b5b5-ed1bbf7355b1"
                    ]
                }
            }
        },
        {
            "name": "SCOFF - score 2 out of 5 - disregard final two-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.4,
                "threshold": 0
            },
            "answers": {
                "SCOFF-1": {
                    "questionId": "SCOFF-1",
                    "chosenOptionIds": [
                        "bd562ae7-f6a5-4c90-986e-d7a0775b33fd"
                    ]
                },
                "SCOFF-2": {
                    "questionId": "SCOFF-2",
                    "chosenOptionIds": [
                        "dec92aba-a555-4bdc-9f85-4301c852792b"
                    ]
                },
                "SCOFF-3": {
                    "questionId": "SCOFF-3",
                    "chosenOptionIds": [
                        "ea0da749-e9e1-4860-bdaa-d55efb821662"
                    ]
                },
                "SCOFF-4": {
                    "questionId": "SCOFF-4",
                    "chosenOptionIds": [
                        "9290f8d3-d0cf-4c49-bc1f-26724c6e4320"
                    ]
                },
                "SCOFF-5": {
                    "questionId": "SCOFF-5",
                    "chosenOptionIds": [
                        "0c6d4a9d-fcd5-478e-80e2-379b93846c94"
                    ]
                },
                "SCOFF-6": {
                    "questionId": "SCOFF-6",
                    "chosenOptionIds": [
                        "14a5d540-2c89-4d9a-a52a-c31c85c3ac9b"
                    ]
                },
                "SCOFF-7": {
                    "questionId": "SCOFF-7",
                    "chosenOptionIds": [
                        "934e2d1b-3d74-46a7-8da4-f6a7566f777a"
                    ]
                }
            }
        },
        {
            "name": "SCOFF - full score of 5-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 1,
                "threshold": 0
            },
            "answers": {
                "SCOFF-1": {
                    "questionId": "SCOFF-1",
                    "chosenOptionIds": [
                        "bd562ae7-f6a5-4c90-986e-d7a0775b33fd"
                    ]
                },
                "SCOFF-2": {
                    "questionId": "SCOFF-2",
                    "chosenOptionIds": [
                        "dec92aba-a555-4bdc-9f85-4301c852792b"
                    ]
                },
                "SCOFF-3": {
                    "questionId": "SCOFF-3",
                    "chosenOptionIds": [
                        "afae9912-278f-49a0-9997-86ad98f4de9f"
                    ]
                },
                "SCOFF-4": {
                    "questionId": "SCOFF-4",
                    "chosenOptionIds": [
                        "cd806bc9-53ec-4f38-aecf-123ce260a0ba"
                    ]
                },
                "SCOFF-5": {
                    "questionId": "SCOFF-5",
                    "chosenOptionIds": [
                        "b92959b6-7f31-4e8e-91e8-17b2373ca25c"
                    ]
                },
                "SCOFF-6": {
                    "questionId": "SCOFF-6",
                    "chosenOptionIds": [
                        "14a5d540-2c89-4d9a-a52a-c31c85c3ac9b"
                    ]
                },
                "SCOFF-7": {
                    "questionId": "SCOFF-7",
                    "chosenOptionIds": [
                        "934e2d1b-3d74-46a7-8da4-f6a7566f777a"
                    ]
                }
            }
        },
        {
            "name": "SCOFF - full score of 5 - disregard final two-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 1,
                "threshold": 0
            },
            "answers": {
                "SCOFF-1": {
                    "questionId": "SCOFF-1",
                    "chosenOptionIds": [
                        "bd562ae7-f6a5-4c90-986e-d7a0775b33fd"
                    ]
                },
                "SCOFF-2": {
                    "questionId": "SCOFF-2",
                    "chosenOptionIds": [
                        "dec92aba-a555-4bdc-9f85-4301c852792b"
                    ]
                },
                "SCOFF-3": {
                    "questionId": "SCOFF-3",
                    "chosenOptionIds": [
                        "afae9912-278f-49a0-9997-86ad98f4de9f"
                    ]
                },
                "SCOFF-4": {
                    "questionId": "SCOFF-4",
                    "chosenOptionIds": [
                        "cd806bc9-53ec-4f38-aecf-123ce260a0ba"
                    ]
                },
                "SCOFF-5": {
                    "questionId": "SCOFF-5",
                    "chosenOptionIds": [
                        "b92959b6-7f31-4e8e-91e8-17b2373ca25c"
                    ]
                },
                "SCOFF-6": {
                    "questionId": "SCOFF-6",
                    "chosenOptionIds": [
                        "90ccaf6a-7abd-45cd-a09e-bd369f072f43"
                    ]
                },
                "SCOFF-7": {
                    "questionId": "SCOFF-7",
                    "chosenOptionIds": [
                        "d5d2c703-05fb-4a50-b5b5-ed1bbf7355b1"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {
    "SCOFF-1": "not(SCOFF-content=0)",
    "SCOFF-2": "not(SCOFF-content=0)",
    "SCOFF-3": "not(SCOFF-content=0)",
    "SCOFF-4": "not(SCOFF-content=0)",
    "SCOFF-5": "not(SCOFF-content=0)",
    "SCOFF-6": "and[or[and[SCOFF-1=1, or[SCOFF-2=1, SCOFF-3=1, SCOFF-4=1, SCOFF-5=1]], and[SCOFF-2=1, SCOFF-3=1], and[SCOFF-2=1, SCOFF-4=1], and[SCOFF-2=1, SCOFF-5=1], and[SCOFF-3=1, SCOFF-4=1], and[SCOFF-3=1, SCOFF-5=1], and[SCOFF-4=1, SCOFF-5=1]], not(isUndefined(SCOFF-5=0))]",
    "SCOFF-7": "and[or[and[SCOFF-1=1, or[SCOFF-2=1, SCOFF-3=1, SCOFF-4=1, SCOFF-5=1]], and[SCOFF-2=1, SCOFF-3=1], and[SCOFF-2=1, SCOFF-4=1], and[SCOFF-2=1, SCOFF-5=1], and[SCOFF-3=1, SCOFF-4=1], and[SCOFF-3=1, SCOFF-5=1], and[SCOFF-4=1, SCOFF-5=1]], not(isUndefined(SCOFF-5=0))]"
};
