"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
const censeo_1 = require("../../schema/generated/censeo");
exports.questions = [
    {
        "__typename": "ContentQuestion",
        "id": "v2SS-1",
        "text": "You're almost done with the questionnaire. The final questions ask about thoughts of suicide and self-harm. While these can be difficult topics, your honest answers help us understand how best to support you. Remember:\n\n- You can take breaks if needed\n- The 'need urgent help' button is always available\n- There are no right or wrong answers\n\nAre you ready to continue?",
        "answers": [
            {
                "__typename": "Option",
                "id": "a43270a5-dbbf-477f-8963-dc627b7f63a7",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "61241cbb-ca55-443d-ab3e-c673a742bd36",
                "text": "Never",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "bc5b2f24-173d-479c-81ff-b5d05d87af78",
                "text": "Within the last day",
                "value": 0.16
            },
            {
                "__typename": "Option",
                "id": "d0a5d2b6-0546-4504-b92d-92ef508d215e",
                "text": "A couple of days ago",
                "value": 0.33
            },
            {
                "__typename": "Option",
                "id": "449eee34-66dc-4aaa-adb7-37ed82cabb2d",
                "text": "A week ago",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "14d67e62-f2a4-4947-ba05-afbe97003d0e",
                "text": "A month ago",
                "value": 0.66
            },
            {
                "__typename": "Option",
                "id": "c21cc13f-7271-4a4c-ab30-76c86e59e17b",
                "text": "Several months ago",
                "value": 0.83
            },
            {
                "__typename": "Option",
                "id": "9523b6fc-2271-48e7-a36c-1065efbdf797",
                "text": "Years ago",
                "value": 1
            }
        ],
        "id": "v2SS-2",
        "text": "When did you last self-harm?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeTextQuestion",
        "id": "v2SS-3",
        "text": "If you have self-harmed, please describe how?",
        "userType": censeo_1.UserType.Nhs,
        "mandatory": false,
        "skippable": "Not applicable"
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "f0d8e8c3-b286-49b7-a25c-948dddbd7d3a",
                "text": "Not applicable",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "55c44df4-f302-4377-a603-e68808d0d718",
                "text": "Not at all likely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "6f7b0e76-bfef-499d-a4c1-ce336d258bc0",
                "text": "Moderately likely",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "4e2863d6-5ee5-487c-9d71-ec0f189a6d2f",
                "text": "Very likely",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "1cf1992c-afe2-4ed0-8d24-25ea325d8416",
                "text": "Extremely likely",
                "value": 1
            }
        ],
        "id": "v2SS-4",
        "text": "How likely are you to harm yourself?",
        "isMultiselect": false
    },
    {
        "__typename": "ContentQuestion",
        "id": "v2SS-5",
        "text": "Thank you for answering those questions. \nPlease take a break if needed. \nYour honest answers will help us understand how best to support you, so please know there are no right or wrong answers.",
        "answers": [
            {
                "__typename": "Option",
                "id": "e2927e69-a05c-459f-bd5d-8f952e00c705",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "c58a00a3-61e0-49da-8680-ec9bc56c4d96",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f89b3e20-299c-4e05-8ece-0736de3a90a3",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "v2SS-6",
        "text": "Are you having any thoughts about suicide at the moment?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "128783be-9b36-4232-95e4-4407a15b55c4",
                "text": "Not applicable",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "0d835984-2542-4869-ace4-dc0aa9a94fe2",
                "text": "Slightly",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "d923cebc-d434-4400-8972-d3c5bd528da9",
                "text": "Moderately",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "492a49d6-0498-4b0b-9dab-fcecbb999e04",
                "text": "Very",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "36f8df4c-f41b-4c94-80b4-ecbcde30dc17",
                "text": "Extremely",
                "value": 1
            }
        ],
        "id": "v2SS-7",
        "text": "How intrusive or troubling do you find these thoughts?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "21e1e05a-a7f7-40c6-b42e-c4949b0eb99e",
                "text": "Not applicable",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "e81da880-2f6b-4e89-b1a4-0b981e872643",
                "text": "No",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "78f6e6b8-e43a-486e-bf28-fb4ec2fe911a",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "v2SS-8",
        "text": "Do you have a specific plan for how you would end your life?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ea6ccb52-3593-468f-8747-dff11486ccbf",
                "text": "Not applicable",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "85a841b5-fe6f-4a5e-accf-75061c379020",
                "text": "Not at all likely",
                "value": 0.25
            },
            {
                "__typename": "Option",
                "id": "8527ae99-131e-4834-82ca-a4bdc719199b",
                "text": "Somewhat likely",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "fc8a2563-7b09-4205-a94d-7c4c5c42b42d",
                "text": "Moderately likely",
                "value": 0.75
            },
            {
                "__typename": "Option",
                "id": "54ea6bb7-d5ca-4e66-83c9-1fe4802c9e45",
                "text": "Very likely",
                "value": 1
            }
        ],
        "id": "v2SS-9",
        "text": "How likely are you to act on these thoughts?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "1a28c637-5b91-4a3e-aeba-0f339e0ecd76",
                "text": "Yes",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7dcb7e4f-eed8-4078-8e87-5f1e9ff9d3ed",
                "text": "Not sure",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "b225a7de-c74c-4d07-8252-8be6aa671285",
                "text": "No",
                "value": 1
            }
        ],
        "id": "v2SS-10",
        "text": "In the next 24 hours, do you feel able to keep yourself safe?",
        "isMultiselect": false
    },
    {
        "__typename": "FreeTextQuestion",
        "id": "v2SS-11",
        "text": "When did you last attempt suicide?",
        "userType": censeo_1.UserType.Nhs,
        "skippable": "Not applicable"
    },
    {
        "__typename": "ContentQuestion",
        "id": "v2SS-12",
        "text": "Thank you for completing all the questions and sharing your experiences with us. Your responses will help us understand your needs better.\nA few important things to know:\n- A mental health professional will review all your answers\n- The review usually takes place during working hours (Monday to Friday, 9am-5pm)\n- If you need urgent support before then, you can still use the 'need urgent help' button which has contact details for crisis services",
        "answers": [
            {
                "__typename": "Option",
                "id": "850da2e2-84da-4476-a5a4-d1c41c63d572",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    }
];
exports.aspects = {
    "v2SuicideSelfharm": {
        "type": "static"
    }
};
exports.tests = {};
exports.questionPredicates = {};
