export const enum AssessmentStatus {
    UNCOMPLETED = 'incomplete',
    IN_PROGRESS = 'in progress',
}

const baseReportUrl = 'https://demo-reports-psyomics.s3.eu-west-2.amazonaws.com'
const getReportLocation = (id: string): string => `${baseReportUrl}/${id}.json`
const getReportPdfLocation = (id: string): string => `${baseReportUrl}/${id}.full.pdf`

export const demoReports = {
    ids: [
        'f530cfdf-321e-405e-b25e-376dae911925',
        '92968fef-98f8-49fc-9f97-e9266e661b60',
        'b6d795e7-b1bc-43fd-a6f0-d11c679bfe06',
        '9270da96-135c-4378-9a9c-15aafc3adc97',
        'c710ae26-04d9-4298-868f-62a31bee005d',
        '7c6b31cc-9051-40cb-b5f5-174a0060e6ad',
    ],
    location: getReportLocation,
    pdfLocation: getReportPdfLocation,
}

export const enum Risk {
    LOW = 'Low',
    MEDIUM = 'Moderate',
    HIGH = 'High',
    VERY_HIGH = 'Very High',
}
export interface FakeDataRow {
    firstName: string
    givenName: string
    dob: string
    nhsNumber: string
    completed: string | AssessmentStatus
    risk: Risk | undefined
    reportId: string | undefined
}

export const fakeData: FakeDataRow[] = [
    {
        firstName: 'Philipa',
        givenName: 'Coleman',
        dob: '1997-11-12',
        nhsNumber: '4848833573',
        completed: '2023-07-21',
        risk: Risk.LOW,
        reportId: demoReports.ids[0],
    },
    {
        firstName: 'Olivia',
        givenName: 'Hall',
        dob: '1992-04-22',
        nhsNumber: '6031635375',
        completed: '2023-08-14',
        risk: Risk.MEDIUM,
        reportId: demoReports.ids[1],
    },
    {
        firstName: 'Gary',
        givenName: 'White',
        dob: '1963-11-12',
        nhsNumber: '1773813024',
        completed: '2023-07-09',
        risk: Risk.LOW,
        reportId: demoReports.ids[2],
    },
    {
        firstName: 'Aaron',
        givenName: 'Kite',
        dob: '1981-03-25',
        nhsNumber: '7855913408',
        completed: '2023-05-15',
        risk: Risk.LOW,
        reportId: demoReports.ids[3],
    },
    {
        firstName: 'Jeremy',
        givenName: 'Parsons',
        dob: '1969-01-29',
        nhsNumber: '8405814901',
        completed: '2023-09-01',
        risk: Risk.VERY_HIGH,
        reportId: demoReports.ids[4],
    },
    {
        firstName: 'Zachary',
        givenName: 'Thorburn',
        dob: '1984-11-11',
        nhsNumber: '5771238154',
        completed: '2023-09-02',
        risk: Risk.HIGH,
        reportId: demoReports.ids[5],
    },
    {
        firstName: 'James',
        givenName: 'Davis',
        dob: '1979-01-17',
        nhsNumber: '3889960190',
        completed: AssessmentStatus.IN_PROGRESS,
        risk: undefined,
        reportId: undefined,
    },
    {
        firstName: 'Maisy',
        givenName: 'Oldburn',
        dob: '1955-06-02',
        nhsNumber: '8491235419',
        completed: AssessmentStatus.IN_PROGRESS,
        risk: undefined,
        reportId: undefined,
    },
    {
        firstName: 'Sandra',
        givenName: 'Ruckman',
        dob: '1984-09-31',
        nhsNumber: '9688466852',
        completed: AssessmentStatus.UNCOMPLETED,
        risk: undefined,
        reportId: undefined,
    },
]

// TODO: use these report ids for the new Health IT demo flow
export const demoReportsfakeDataHealthIT = {
    ids: [
        '3429a8bb-9eac-4a66-b570-bcfe6fff0811',
        '97412f30-bc45-4677-8134-f5898cdee0a5',
        '3b80ad5f-c25c-4f90-95b1-57a9d04c3bc5',
        'b9190d64-113e-4f8c-9cb3-d1286d895aae',
    ],
    location: getReportLocation,
    pdfLocation: getReportPdfLocation,
}

export const fakeDataHealthIT: FakeDataRow[] = [
    {
        firstName: 'John',
        givenName: 'Jackson',
        dob: '1986-06-26',
        nhsNumber: '6083748364',
        completed: '2025-03-08',
        risk: undefined,
        reportId: demoReportsfakeDataHealthIT.ids[0],
    },
    {
        firstName: 'Emily',
        givenName: 'Clarke',
        dob: '2003-07-15',
        nhsNumber: '9876543210',
        completed: '2025-03-06',
        risk: undefined,
        reportId: demoReportsfakeDataHealthIT.ids[1],
    },
    {
        firstName: 'Oliver',
        givenName: 'Thompson',
        dob: '1989-05-12',
        nhsNumber: '0123456789',
        completed: '2025-03-05',
        risk: undefined,
        reportId: demoReportsfakeDataHealthIT.ids[2],
    },
    {
        firstName: 'Sarah',
        givenName: 'Brown',
        dob: '1985-04-12',
        nhsNumber: '1234567890',
        completed: '2025-03-08',
        risk: undefined,
        reportId: demoReportsfakeDataHealthIT.ids[3],
    },
]
