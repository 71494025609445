"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "CombinedAnswersSummary",
            "questionsIdGroups": [
                [
                    "PHQ9-1"
                ],
                [
                    "PHQ9-2"
                ],
                [
                    "PHQ9-3"
                ],
                [
                    "PHQ9-4"
                ],
                [
                    "PHQ9-5"
                ],
                [
                    "PHQ9-6"
                ],
                [
                    "PHQ9-7"
                ],
                [
                    "PHQ9-8"
                ],
                [
                    "PHQ9-9"
                ]
            ],
            "groupsType": "Mean",
            "pattern": "{}",
            "coefficients": {
                "0": "None-minimal (0-4)",
                "0.185185185185185": "Mild (5-9)",
                "0.37037037037037": "Moderate (10-14)",
                "0.555555555555556": "Moderately severe (15-19)",
                "0.740740740740741": "Severe (>= 20)"
            }
        },
        "section": [
            "Outcome"
        ]
    },
    {
        "answerSummary": {
            "type": "FunctionSummary",
            "formatText": "{}",
            "functionId": "Phq9ScoringKey"
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "1. Little interest or pleasure in doing things | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PHQ9-1",
                    "optionSummaries": {
                        "dbe573d6-5fba-492e-be0d-1a1ee170fc40": "0 - Not at all",
                        "125a7eda-82ba-4a99-83c0-e696d361fc55": "1 - Several days",
                        "831bf8b4-aa49-4bbf-8fba-d232a84abb5f": "2 - More than half the days",
                        "769f5145-db30-4d43-8693-5f70e845d899": "3 - Nearly every day"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "2. Feeling down, depressed, or hopeless | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PHQ9-2",
                    "optionSummaries": {
                        "85fda995-fe12-4cda-9ed1-d826130d7c48": "0 - Not at all",
                        "a3d2724b-5259-4000-8ba6-18eb484990ec": "1 - Several days",
                        "dc9d66f1-cf94-4f9b-982d-076cb8456b2d": "2 - More than half the days",
                        "df47dbdb-3828-4e6a-b60e-3f90cf87d2bf": "3 - Nearly every day"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "3. Trouble falling or staying asleep, or sleeping too much | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PHQ9-3",
                    "optionSummaries": {
                        "e827fb22-2923-441a-bb47-89a357c06bea": "0 - Not at all",
                        "95032328-5739-4db3-b84d-92e1aae2a9f3": "1 - Several days",
                        "06cab638-e9b5-4d4c-bf87-3c39c513d08e": "2 - More than half the days",
                        "1e46647a-aed2-4743-b898-72ed9a124d2b": "3 - Nearly every day"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "4. Feeling tired or having little energy | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PHQ9-4",
                    "optionSummaries": {
                        "5cc56ebd-1dae-4ea1-ad4d-1688654bca4c": "0 - Not at all",
                        "92ae7887-d4a4-4ca4-b625-f9f257ad80d0": "1 - Several days",
                        "ef213d38-cc6d-43ca-b8ca-51674addb41a": "2 - More than half the days",
                        "11bd5ee9-d975-44fe-b1a0-111790a17874": "3 - Nearly every day"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "5. Poor appetite or overeating | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PHQ9-5",
                    "optionSummaries": {
                        "ce46771a-e4a1-481e-938b-69a2101d230c": "0 - Not at all",
                        "3da02a45-5dc9-42d8-bb65-d9da394507b1": "1 - Several days",
                        "8da4060f-ba78-43be-8133-f796748fc2cc": "2 - More than half the days",
                        "7ac0d947-1fd4-4490-a9ab-0e44aa7c7fdf": "3 - Nearly every day"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "6. Feeling bad about yourself - or that you are a failure or have let yourself or your family down | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PHQ9-6",
                    "optionSummaries": {
                        "f8d411eb-6779-492c-8fcf-993028994c5f": "0 - Not at all",
                        "cdd78b2a-62ca-4d5d-9b6b-4501004e90c6": "1 - Several days",
                        "7a4130c2-bf7f-4c48-9c15-8f4a96916c7f": "2 - More than half the days",
                        "0eab3a18-d9a3-4348-866a-28ac8e5ec513": "3 - Nearly every day"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "7. Trouble concentrating on things, such as reading the newspaper or watching television | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PHQ9-7",
                    "optionSummaries": {
                        "522cfd34-4ef6-49d7-b12c-5502d5ab9e13": "0 - Not at all",
                        "413d6cf5-ee97-4f88-bbae-4450bdb0e802": "1 - Several days",
                        "75abc951-0543-4a36-b6c2-144e396c3f41": "2 - More than half the days",
                        "56ec3094-6f9a-48b8-927a-f532b0f807b1": "3 - Nearly every day"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "8. Moving or speaking so slowly that other people could have noticed? Or the opposite — being so fidgety or restless that you have been moving around a lot more than usual | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PHQ9-8",
                    "optionSummaries": {
                        "f6bbb281-d92c-493b-9dd2-c6e455301b5d": "0 - Not at all",
                        "ec3a5d08-df23-4f87-8692-98ef565b877a": "1 - Several days",
                        "86ee26d5-9c84-46d9-a6bf-faa278d6eb79": "2 - More than half the days",
                        "598b6f5b-fbfb-4f1b-8501-d1a0f1746363": "3 - Nearly every day"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "9. Thoughts that you would be better off dead or of hurting yourself in some way | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "PHQ9-9",
                    "optionSummaries": {
                        "c5078f8f-6e74-4fb8-ba85-102eac78197d": "0 - Not at all",
                        "ef3d12b2-6dde-4ac4-8362-4f8caed72fee": "1 - Several days",
                        "b68eb3c3-b673-4a90-ae54-4374c9ddab7d": "2 - More than half the days",
                        "2c164bb5-6fb3-4320-8211-ac2a6d358349": "3 - Nearly every day"
                    }
                }
            ]
        },
        "section": [
            "Questions & Answers"
        ]
    }
];
