"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.symptoms = void 0;
exports.symptoms = [
    {
        "answerSummary": {
            "type": "CombinedAnswersSummary",
            "questionsIdGroups": [
                [
                    "MSI-1"
                ],
                [
                    "MSI-2"
                ],
                [
                    "MSI-3"
                ],
                [
                    "MSI-4"
                ],
                [
                    "MSI-5"
                ],
                [
                    "MSI-6"
                ],
                [
                    "MSI-7"
                ],
                [
                    "MSI-8"
                ],
                [
                    "MSI-9"
                ],
                [
                    "MSI-10"
                ]
            ],
            "groupsType": "Mean",
            "pattern": "{}",
            "coefficients": {
                "0": "Level of symptoms are not consistent with borderline personality disorder",
                "0.5": "Warrants further evaluation for borderline personality disorder",
                "0.7": "Likely to meet the criteria for borderline personality disorder"
            }
        },
        "section": []
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Have any of your closest relationships been troubled by a lot of arguments or repeated breakups? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "MSI-1",
                    "optionSummaries": {
                        "ea198c0d-1822-46d7-9353-477e903451e5": "0 - No",
                        "f1ffc187-d293-4e71-8167-b9a3ac2c9589": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions & answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Have you deliberately hurt yourself physically (e.g. punched yourself, cut yourself, burned yourself)? How about made a suicide attempt? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "MSI-2",
                    "optionSummaries": {
                        "714cda97-5b1d-4e54-84d0-c235b0a75734": "0 - No",
                        "b0ac9e73-827b-408e-b91b-11f212476a19": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions & answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Have you had at least two other problems with impulsivity (e.g. eating binges and spending sprees, drinking too much and verbal outbursts)? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "MSI-3",
                    "optionSummaries": {
                        "30b79416-6813-44da-8153-6cfd6b3872d1": "0 - No",
                        "7dbb7c64-7075-4447-930f-479c19b7f34c": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions & answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Have you been extremely moody? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "MSI-4",
                    "optionSummaries": {
                        "064a9dbc-b4b4-4a6d-b8da-009ca0f76ba6": "0 - No",
                        "6771ec66-b0be-4632-aca9-ca119104aaf1": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions & answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Have you felt very angry a lot of the time? How about often acted in an angry or sarcastic manner? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "MSI-5",
                    "optionSummaries": {
                        "34dfaf01-37a7-441b-bf8b-65fbbb90e451": "0 - No",
                        "ff4609e4-9764-4d65-8a96-c142896204af": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions & answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Have you often been distrustful of other people? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "MSI-6",
                    "optionSummaries": {
                        "0d41e2bf-946f-4cc1-b796-d83648c2b5fb": "0 - No",
                        "42654395-1d9b-424d-97e1-86d097072f75": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions & answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Have you frequently felt unreal or as if things around you were unreal? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "MSI-7",
                    "optionSummaries": {
                        "65fb097b-b596-4faf-92e1-f8f368b4106e": "0 - No",
                        "b282561c-a101-453a-b25e-fa18c2637e9c": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions & answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Have you chronically felt empty? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "MSI-8",
                    "optionSummaries": {
                        "fe8ca265-efe4-48cf-8761-fb79b115a6fc": "0 - No",
                        "5added35-1443-460c-b769-26782ecc23ce": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions & answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Have you often felt that you had no idea of who you are or that you have no identity? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "MSI-9",
                    "optionSummaries": {
                        "ab997c40-e162-45d3-9c36-577740c531f3": "0 - No",
                        "b966d782-f088-4e3b-afd0-e551adfb9791": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions & answers"
        ]
    },
    {
        "answerSummary": {
            "type": "ShowWhenSelected",
            "pattern": "Have you made desperate efforts to avoid feeling abandoned or being abandoned (e.g., repeatedly called someone to reassure yourself that he or she still cared, begged them not to leave you, clung to them physically)? | {}",
            "matchFirstAnsweredQuestion": [
                {
                    "questionId": "MSI-10",
                    "optionSummaries": {
                        "62018c69-53be-4500-a08a-0e864f7edd3a": "0 - No",
                        "f3d099e4-dc2c-4cf0-bb86-df9d017f76b0": "1 - Yes"
                    }
                }
            ]
        },
        "section": [
            "Questions & answers"
        ]
    }
];
