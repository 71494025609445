"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
exports.questions = [
    {
        "__typename": "ContentQuestion",
        "id": "DemoHealthIT3-content-1",
        "text": "The next set of questions asks about common experiences that can affect mental health, like feeling worried.\n\nTake your time with these questions, and remember there are no right or wrong answers.\n\nSelect 'Continue' to start.",
        "answers": [
            {
                "__typename": "Option",
                "id": "5614a45d-d43a-4556-8cbe-899b5335c323",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6688506a-1eef-44eb-ba98-e40f91e25831",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "396b7790-1e78-4721-b36b-80a9a0aeb2e3",
                "text": "Several days",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "d24773e5-f8d6-4c1a-ad47-3e818951e08e",
                "text": "More than half the days",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "4e5fb5e8-a242-4385-90f7-c4fbf881458e",
                "text": "Nearly every day",
                "value": 1
            }
        ],
        "id": "DemoHealthIT3-GAD7-1",
        "text": "Over the **last two weeks**, how often have you been bothered by the following problem?\n\n**Feeling nervous, anxious, or on edge**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "9afe5aea-92d8-4345-8654-e1aff03416e6",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "cf34dbbe-862d-4530-a716-da34cbba3628",
                "text": "Several days",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "6029ca61-abde-404f-8625-5fb9a2569e0d",
                "text": "More than half the days",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "2fb2259a-91d9-4826-ab2d-2f4599169231",
                "text": "Nearly every day",
                "value": 1
            }
        ],
        "id": "DemoHealthIT3-GAD7-2",
        "text": "Over the **last two weeks**, how often have you been bothered by the following problem?\n\n**Not being able to stop or control worrying**",
        "isMultiselect": false
    },
    {
        "__typename": "ContentQuestion",
        "id": "DemoHealthIT3-content-2",
        "text": "Thank you for answering the questions so far.\n\nThese next questions will ask you about your mood over the last two weeks. While this can be a difficult topic, your honest answers help us understand how best to support you. Remember:\n\n- You can take breaks if needed\n- The 'Urgent help' button is always available\n- There are no right or wrong answers\n\nAre you ready to continue?\n\nWhen you are ready to continue, click 'Continue' to begin answering these questions.",
        "answers": [
            {
                "__typename": "Option",
                "id": "b83d2bb1-ab79-4d14-885b-76b8ffcae1ac",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "21c20543-0a2c-4f89-a855-0489c409d5a6",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "5cb94d82-24df-4fd2-848b-0b12288f0f1d",
                "text": "Several days",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "874660ce-8ab6-4bde-9be0-f58503b59b83",
                "text": "More than half the days",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "84eaa547-3653-44a2-84a8-d81b18564318",
                "text": "Nearly every day",
                "value": 1
            }
        ],
        "id": "DemoHealthIT3-PHQ9-1",
        "text": "Over the last two weeks:\n\n**How often have you been bothered by little interest or pleasure in doing things?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "4b755fb1-c2ac-4ed4-812a-90407b9489cd",
                "text": "Not at all",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "632ebd08-a6ad-4b3a-b1d0-c13fe706efb0",
                "text": "Several days",
                "value": 0.333333333333333
            },
            {
                "__typename": "Option",
                "id": "05bf0255-02ba-4bfe-9e75-dfae11f70cd8",
                "text": "More than half the days",
                "value": 0.666666666666666
            },
            {
                "__typename": "Option",
                "id": "1318d645-ed82-4486-bfa1-84900f4cc454",
                "text": "Nearly every day",
                "value": 1
            }
        ],
        "id": "DemoHealthIT3-PHQ9-2",
        "text": "Over the last two weeks:\n\n**How often have you been bothered by feeling down, depressed, or hopeless?**",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "6d229e36-b3ad-4e32-b8b4-aaafd736fded",
                "text": "Yes",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "3087de53-d645-4afe-8295-ba3e2f8609ae",
                "text": "Not sure",
                "value": 0.5
            },
            {
                "__typename": "Option",
                "id": "83318fdc-95e1-46ae-82c2-20564784596d",
                "text": "No",
                "value": 1
            }
        ],
        "id": "DemoHealthIT3-v2SS-10",
        "text": "In the next 24 hours, do you feel able to keep yourself safe?",
        "isMultiselect": false
    },
    {
        "__typename": "ContentQuestion",
        "id": "DemoHealthIT3-v2SS-12",
        "text": "Thank you for completing all the questions and sharing your experiences with us. Your responses will help us understand your needs better.\n\nA few important things to know:\n\n- A mental health professional will review all your answers\n- The review usually takes place during working hours (Monday to Friday, 9am-5pm)\n- If you need urgent support before then, you can still use the 'Urgent help' button which has contact details for crisis services",
        "answers": [
            {
                "__typename": "Option",
                "id": "14e1112f-cd5e-4dae-95b8-aff9828c2bb2",
                "text": "Continue",
                "primary": true,
                "value": 1
            }
        ]
    }
];
exports.aspects = {};
exports.tests = {};
exports.questionPredicates = {};
