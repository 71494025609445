import React, { useContext, useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { Page, Heading, Logo } from '@psyomics/components'
import Content from 'registration/components/Content'
import {
    OnboardingInputsInitialValues,
    OnboardingFormProps,
    OnboardingFormSchema,
    OnboardingFormStage,
} from './use-Onboarding'
import { Formik } from 'formik'
import Gender from './Gender'
import Ethnicity from './Ethnicity'
import Sex from './Sex'
import Error from 'ui/app/pages/Error'
import { useAuth } from 'registration/context/auth'
import { useNavigate } from 'react-router-dom'
import Styles from '../SignUp/SignUp.module.scss'
import Name from '../SignUp/Name'
import AboutYou from '../SignUp/AboutYou'
import { ReferralDetails, ReferralDetailsContext } from 'ui/app/context/referralDetails'
import { Splash } from 'ui/app/pages/Splash'
import { useMutation } from '@apollo/client'
import { UpdateUserProfileInput, updateUserProfileMutation, UpdateUserProfileResponse } from 'data/mutations/updateUser'
import { useOnboardingState } from 'registration/context/onboarding/onboarding-context'
import { useAsync } from 'registration/hooks/use-async'

type IOnboarding = React.ComponentPropsWithoutRef<'div'> & {
    setReferralPublicId: React.Dispatch<React.SetStateAction<string | undefined>>
}

const Onboarding: React.FC<IOnboarding> = ({ setReferralPublicId }) => {
    const formSchema = OnboardingFormSchema

    // todo: deal with any questions already being answered.
    const { state, updateUserAttributes } = useAuth()
    const { run } = useAsync()

    const referralDetails = useContext(ReferralDetailsContext)
    const { userUpdated, setUserUpdated, error, setError, askOnboardingQuestionsAgain } = useOnboardingState()
    const isLoadingReferralContext = referralDetails?.status === 'loading' || referralDetails?.status === 'pending'

    const sex = state.user?.sex
    const gender = state.user?.gender
    const formInitialValues = {
        ...OnboardingInputsInitialValues,
        ...(gender ? { gender } : {}),
        ...(sex ? { sex } : {}),
    }

    const [pageState, setPageState] = useState<OnboardingFormStage>('name')
    const navigate = useNavigate()

    useEffect(() => {
        if (userUpdated || !askOnboardingQuestionsAgain) {
            navigate('/welcome')
        }
    }, [userUpdated, askOnboardingQuestionsAgain])

    const pageProps = {
        className: Styles.page,
        layout: 'standard',
        width: 'narrow',
        header: (
            <header className={Styles.header}>
                <div className={Styles.headerOuter}>
                    <div className={Styles.headerInner}>
                        <Logo className={Styles.largeCenseoLogo} height="56" width="190" />
                    </div>
                </div>
            </header>
        ),
    }

    const [updateUserProfile] = useMutation<UpdateUserProfileResponse, { input: UpdateUserProfileInput }>(
        updateUserProfileMutation
    )

    return (
        <>
            {error && (
                <Error customMessage={error} homepageButtonLabel="Try again" homebuttonCbFn={() => setError(null)} />
            )}
            {!error && isLoadingReferralContext && <Splash />}
            {!error && !isLoadingReferralContext && referralDetails && (
                <Page {...pageProps}>
                    <Content>
                        <Heading el="h1" size="heading2" color="mid" css={{ mb: 4 }}>
                            Further details
                        </Heading>
                        <Formik
                            initialValues={formInitialValues}
                            validationSchema={formSchema}
                            onSubmit={async (values) => {
                                setError(null)
                                setReferralPublicId(referralDetails?.activeReferral?.publicId)

                                try {
                                    const updateInput: UpdateUserProfileInput = {
                                        userId: state.user?.id!,
                                        email: state.user?.email,
                                        sex: values.sex,
                                        gender: values.gender,
                                        ethnicity: values.ethnicity,
                                        firstName: values.firstName.trim(),
                                        lastName: values.lastName.trim(),
                                        birthDate: values.birthdate,
                                        referralPublicId: referralDetails?.activeReferral?.publicId!,
                                    }

                                    if (!referralDetails.activeReferral?.organisationConfig.omitNhsLookup) {
                                        updateInput.postalCode = values.postcode
                                    }
                                    const { data } = await updateUserProfile({
                                        variables: {
                                            input: updateInput,
                                        },
                                    })

                                    const userIsUpdated = data?.updateUserProfile
                                    if (userIsUpdated) {
                                        setUserUpdated(true)
                                    } else {
                                        Sentry.captureException('Failed to update user profile', { extra: { data } })
                                        setError('Failed to update user profile. Please try again.')
                                    }
                                } catch (err) {
                                    Sentry.captureException(err, {
                                        extra: { errorMessage: 'Error updating user profile' },
                                    })
                                    setError('An error occurred while updating your profile. Please try again later.')
                                }

                                // TODO: remove once CP-1967 merged
                                await run(
                                    updateUserAttributes({
                                        sex: values.sex,
                                        gender: values.gender,
                                        ethnicity: values.ethnicity,
                                        given_name: values.firstName.trim(),
                                        family_name: values.lastName.trim(),
                                        birthdate: values.birthdate,
                                        address: JSON.stringify({ postal_code: values.postcode }),
                                        ...(referralDetails?.activeReferral?.publicId && {
                                            referralPublicId: referralDetails?.activeReferral?.publicId,
                                        }),
                                    })
                                )
                            }}
                        >
                            {(formikProps) => (
                                <form onSubmit={formikProps.handleSubmit}>
                                    <View
                                        values={formikProps.values}
                                        errors={formikProps.errors}
                                        touched={formikProps.touched}
                                        onChange={formikProps.handleChange}
                                        onBlur={formikProps.handleBlur}
                                        validateForm={formikProps.validateForm}
                                        submitForm={formikProps.submitForm}
                                        pageState={pageState}
                                        setPageState={setPageState}
                                        setFieldValue={formikProps.setFieldValue}
                                        referralDetails={referralDetails}
                                    />
                                </form>
                            )}
                        </Formik>
                    </Content>
                </Page>
            )}
        </>
    )
}

type ViewProps = OnboardingFormProps & {
    pageState: OnboardingFormStage
    referralDetails: ReferralDetails
} & React.ComponentPropsWithoutRef<'div'>

const View: React.FC<ViewProps> = ({
    values,
    errors,
    touched,
    onChange,
    onBlur,
    validateForm,
    submitForm,
    pageState,
    setPageState,
    setFieldValue,
    referralDetails,
}) => {
    switch (pageState) {
        case 'name':
            return (
                <React.Suspense fallback={<p>loading...</p>}>
                    <Name
                        values={values}
                        errors={errors}
                        touched={touched}
                        onChange={onChange}
                        onBlur={onBlur}
                        validateForm={validateForm}
                        submitForm={submitForm}
                        setPageState={setPageState}
                        setFieldValue={setFieldValue}
                        referralDetails={referralDetails}
                    />
                </React.Suspense>
            )
        case 'postcode':
            return (
                <React.Suspense fallback={<p>loading...</p>}>
                    <AboutYou
                        values={values}
                        errors={errors}
                        touched={touched}
                        onChange={onChange}
                        onBlur={onBlur}
                        validateForm={validateForm}
                        submitForm={submitForm}
                        setPageState={setPageState}
                        setFieldValue={setFieldValue}
                        referralDetails={referralDetails}
                    />
                </React.Suspense>
            )
        case 'sex':
            return (
                <React.Suspense fallback={<p>loading...</p>}>
                    <Sex
                        values={values}
                        errors={errors}
                        touched={touched}
                        onChange={onChange}
                        onBlur={onBlur}
                        validateForm={validateForm}
                        submitForm={submitForm}
                        setPageState={setPageState}
                        setFieldValue={setFieldValue}
                        referralDetails={referralDetails}
                    />
                </React.Suspense>
            )
        case 'gender':
            return (
                <React.Suspense fallback={<p>loading...</p>}>
                    <Gender
                        values={values}
                        errors={errors}
                        touched={touched}
                        onChange={onChange}
                        onBlur={onBlur}
                        validateForm={validateForm}
                        submitForm={submitForm}
                        setPageState={setPageState}
                        setFieldValue={setFieldValue}
                    />
                </React.Suspense>
            )
        case 'ethnicity':
            return (
                <React.Suspense fallback={<p>loading...</p>}>
                    <Ethnicity
                        values={values}
                        errors={errors}
                        touched={touched}
                        onChange={onChange}
                        onBlur={onBlur}
                        validateForm={validateForm}
                        submitForm={submitForm}
                        setPageState={setPageState}
                        setFieldValue={setFieldValue}
                    />
                </React.Suspense>
            )
        default:
            return <></>
    }
}

export default Onboarding
