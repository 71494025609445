import { Button, Page, Text } from '@psyomics/components'
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Styles from './Demo.module.scss'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import { useMixpanelEvent } from 'ui/app/useMixpanelEvent'
import DemoHeader from 'ui/app/components/DemoHeader'
import DemoFooter from 'ui/app/components/DemoFooter'

const DemoStartPatientJourney: React.FC = () => {
    const navigate = useNavigate()
    const mixpanelApiUrl = useContext(MixpanelContext)
    const { visitedDemoStartPatientJourney } = useMixpanelEvent(mixpanelApiUrl)

    useEffect(() => {
        visitedDemoStartPatientJourney(undefined, undefined)
    }, [])

    return (
        <Page
            style={{ backgroundColor: 'transparent' }}
            layout="standard"
            width="narrow"
            header={<DemoHeader navButtons={false} />}
            footer={<DemoFooter footerType="patient" />}
        >
            <Text size="medium" css={{ mb: 5 }}>
                Now you have a Psyomics Platform account, you can start the questionnaire.
            </Text>
            <Text size="medium" css={{ mb: 5 }}>
                All your answers will be saved as you go, so if you would like to take a break you can revisit the
                assessment using this link -{' '}
                <a href="https://app.psyomics.com/signin" rel="noreferrer" target="_blank">
                    https://app.psyomics.com/signin
                </a>
            </Text>
            <Text size="medium" css={{ mb: 5 }}>
                There are only three sessions in this demo, so this could take around 5 - 15 minutes depending on the
                answers that are selected.
            </Text>
            <div className={Styles.btnWrapper}>
                <Button
                    className="text-right"
                    appearance="primary"
                    size="large"
                    label="Start"
                    onClick={() => {
                        navigate('/')
                    }}
                />
            </div>
        </Page>
    )
}

export default DemoStartPatientJourney
