import { useMutation, useQuery } from '@apollo/client'
import {
    allSessionsFiltered,
    conditionByQuestionId,
    getQuestionsForSession,
    getSessionFromQuestionId,
    MutationAnswerArgs,
    MutationDemoAnswerArgs,
    MutationInvalidateAnswerArgs,
    QueryQuestionArgs,
    QueryDemoQuestionArgs,
    UserType,
    screenInQuestionIds,
    AnsweredQuestion,
    DemoType,
} from 'censeo-core'
import React, { useContext, useEffect, useMemo, Dispatch, SetStateAction, useState } from 'react'
import * as Sentry from '@sentry/react'
import { useNavigate, useNavigationType } from 'react-router'
import {
    answerMutation,
    AnswerResponse,
    demoAnswerMutation,
    isDemoAnswerResponse,
} from '../../../data/mutations/answer'
import { InvalidateAnswerResponse, invalidateAnswerMutation } from '../../../data/mutations/invalidateAnswer'
import { QuestionData, questionQuery, demoQuestionQuery } from '../../../data/queries/question'
import { MultipleChoice } from '../components/questions/MultipleChoice'
import { FreeQuestion } from '../components/questions/FreeQuestionToMultipleChoice'
import { FreeTextQuestion } from '../components/questions/FreeTextQuestion'
import { ContentQuestion } from '../components/questions/ContentQuestion'
import { Splash } from './Splash'
import { useAuth } from 'registration/context/auth'
import { useMixpanelEvent } from '../useMixpanelEvent'
import { MixpanelContext } from 'registration/context/mixpanel/mixpanel-context'
import { enableTimer, getTimer, restartTimers, Timers } from 'ui/utils/timers'
import { OrganisationContext } from '../context/organisation'
import { ReferralDetailsContext } from '../context/referralDetails'
import CenseoError from './Error'

interface Props {
    questionId: string
    setQuestionId: (newId: string) => void
    restart?: true
    setReferralPublicId?: React.Dispatch<React.SetStateAction<string | undefined>>
    demo?: {
        demoType: DemoType
        allAnswers: AnsweredQuestion[]
        setDemoAnswers: Dispatch<SetStateAction<AnsweredQuestion[]>>
    }
}

export const Questionnaire: React.FC<Props> = ({ questionId, setQuestionId, restart, setReferralPublicId, demo }) => {
    const prevQuestionIdRef = React.useRef('')
    const organisation = useContext(OrganisationContext)
    const referralDetails = useContext(ReferralDetailsContext)

    console.log('demo', demo)
    const [skipQuery, setSkipQuery] = useState(true)
    const [variables, setVariables] = useState(
        demo ? { id: questionId, allAnswers: demo.allAnswers, demoType: demo.demoType } : { id: questionId }
    )
    useEffect(() => {
        setVariables(
            demo ? { id: questionId, allAnswers: demo.allAnswers, demoType: demo.demoType } : { id: questionId }
        )
        setSkipQuery(false)
    }, [questionId])

    let data
    const {
        data: apiData,
        loading,
        error: apiError,
    } = useQuery<QuestionData, QueryQuestionArgs | QueryDemoQuestionArgs>(demo ? demoQuestionQuery : questionQuery, {
        fetchPolicy: 'no-cache',
        variables,
        skip: skipQuery,
    })
    data = apiData

    const navigate = useNavigate()
    const navigationType = useNavigationType()
    const {
        state: { user },
    } = useAuth()
    const mixpanelApiUrl = useContext(MixpanelContext)
    const {
        assessmentStarted,
        sessionStarted,
        questionAsked,
        questionAnswered,
        sessionCompleted,
        screenedIn,
        assessmentCompleted,
        answerRemoved,
    } = useMixpanelEvent(mixpanelApiUrl)

    const userReferralCode = user?.referralCode
    const userType = user?.userType ?? UserType.Nhs
    const allSessions = useMemo(
        () => allSessionsFiltered(userType, organisation?.assessmentSettings),
        [userType, organisation?.assessmentSettings]
    )
    const session = getSessionFromQuestionId(questionId, organisation?.assessmentSettings)
    const questions = useMemo(
        () => getQuestionsForSession(session, userType, organisation?.assessmentSettings),
        [session, userType, organisation?.assessmentSettings]
    )

    const isFirstSession = allSessions[0].name === session
    const isFirstQuestion = questions?.[0].id === questionId
    const isLastSession = allSessions[allSessions.length - 1].name === session
    const startNewAssessment = restart && navigationType !== 'POP'

    const [mutate, { loading: mutateLoading }] = useMutation<
        AnswerResponse,
        MutationAnswerArgs | MutationDemoAnswerArgs
    >(demo ? demoAnswerMutation : answerMutation, {
        onError: (error) => {
            console.error('Mutation error:', error)
            Sentry.captureException(error)
        },
        onCompleted: (response) => {
            if (isDemoAnswerResponse(response)) {
                if (response.demoAnswer.__typename === 'NextQuestion') {
                    setQuestionId(response.demoAnswer.id)
                }
                if (response.demoAnswer.__typename === 'SessionComplete') {
                    setQuestionId('')
                    navigate(`/demo/service-user/checkIn`)
                }
                return
            }
            const { answer } = response
            if (isFirstQuestion) {
                if (isFirstSession) {
                    assessmentStarted(
                        organisation?.organisationCode,
                        organisation?.organisationName || userReferralCode,
                        referralDetails?.data?.publicId
                    )
                    enableTimer(Timers.QuestionTimeSpent)
                }
                sessionStarted(
                    organisation?.organisationCode,
                    organisation?.organisationName || userReferralCode,
                    session,
                    referralDetails?.data?.publicId
                )
            }
            const questionType = questions?.[0].__typename
            const timeSpentOnQuestion = getTimer(Timers.QuestionTimeSpent, true)
            const conditionName = conditionByQuestionId(questionId)
            questionAnswered(
                organisation?.organisationCode,
                organisation?.organisationName || userReferralCode,
                questionId,
                questionType,
                conditionName,
                timeSpentOnQuestion,
                referralDetails?.data?.publicId
            )

            if (answer.__typename === 'InvalidReferral') {
                if (setReferralPublicId && answer.status !== referralDetails?.data?.status) {
                    setReferralPublicId(undefined)
                }
                navigate(`/`)
            }

            if (answer.__typename === 'NextQuestion') {
                setQuestionId(answer.id)
            }

            if (answer.__typename === 'SessionComplete') {
                sessionCompleted(
                    organisation?.organisationCode,
                    organisation?.organisationName || userReferralCode,
                    session,
                    referralDetails?.data?.publicId
                )
                if (isLastSession) {
                    assessmentCompleted(
                        organisation?.organisationCode,
                        organisation?.organisationName || userReferralCode,
                        referralDetails?.data?.publicId
                    )
                }
                setQuestionId('')
                navigate(`/`)
            }
        },
    })

    // todo: loading
    const [goBack, { loading: backLoading }] = useMutation<InvalidateAnswerResponse, MutationInvalidateAnswerArgs>(
        invalidateAnswerMutation,
        {
            onCompleted: ({ invalidateAnswer }) => {
                answerRemoved(
                    organisation?.organisationCode,
                    organisation?.organisationName || userReferralCode,
                    questionId,
                    referralDetails?.data?.publicId
                )
                if (invalidateAnswer.__typename === 'PreviousQuestion') {
                    setQuestionId(invalidateAnswer.id)
                }
                if (invalidateAnswer.__typename === 'SessionStart') {
                    setQuestionId('')
                    navigate(`/`)
                }
            },
        }
    )

    const backHandler = () => {
        if (demo) {
            const allAnswers = [...demo.allAnswers]

            for (let i = allAnswers.length - 1; i >= 0; i--) {
                if (!allAnswers[i].invalidated) {
                    allAnswers[i].invalidated = true
                    setQuestionId(allAnswers[i].questionId)

                    break
                }
            }
            demo.setDemoAnswers(allAnswers)
        } else {
            goBack({
                variables: {
                    questionId,
                },
            })
        }
    }

    useEffect(() => {
        enableTimer(Timers.AssessmentTotalDuration)
        enableTimer(Timers.QuestionTimeSpent)
        restartTimers()
    })

    useEffect(() => {
        if (questionId !== prevQuestionIdRef.current) {
            questionAsked(
                organisation?.organisationCode,
                organisation?.organisationName || userReferralCode,
                questionId,
                referralDetails?.data?.publicId
            )
        }
        prevQuestionIdRef.current = questionId

        if (screenInQuestionIds.includes(questionId)) {
            const conditionName = conditionByQuestionId(questionId)
            screenedIn(
                organisation?.organisationCode,
                organisation?.organisationName || userReferralCode,
                questionId,
                conditionName,
                referralDetails?.data?.publicId
            )
        }
    }, [questionId])

    if (demo && data && 'demoQuestion' in data) {
        data = {
            question: data?.demoQuestion,
        }
    }
    if (data && 'demoQuestion' in data) {
        const errMsg = `Demo override data not working on fetching question.`
        Sentry.captureException(errMsg)
        return <CenseoError customTitle={`Demo override not available`} />
    }

    if (apiError) {
        const errMsg = `GraphQL questionQuery request failed for referral ${referralDetails?.data?.publicId} and question id ${questionId}. Response from API: ${apiError?.message}`
        Sentry.captureException(errMsg)
        return <CenseoError customTitle={`Next question not found (Referral ID: ${referralDetails?.data?.publicId})`} />
    }

    return loading || backLoading ? (
        <Splash />
    ) : (
        <>
            {!loading && data?.question && data.question.__typename === 'RestrictedChoiceQuestion' && (
                <MultipleChoice
                    isMultiselect={data.question.isMultiselect ?? false}
                    question={data.question.text}
                    options={data.question.answers}
                    selectedOptionIds={!startNewAssessment ? data.question.chosenOptionIds || [] : []}
                    onSelect={(ids) => {
                        mutate(
                            answerForRequest({
                                demo,
                                questionId,
                                startNewAssessment,
                                answer: ids,
                            })
                        )
                    }}
                    onBack={backHandler}
                    updating={mutateLoading}
                />
            )}
            {!loading && data?.question && data.question.__typename === 'ContentQuestion' && (
                <ContentQuestion
                    question={data.question.text}
                    options={data.question.answers}
                    onSelect={(ids) => {
                        mutate(
                            answerForRequest({
                                demo,
                                questionId,
                                startNewAssessment,
                                answer: ids,
                            })
                        )
                    }}
                    onBack={backHandler}
                    updating={mutateLoading}
                />
            )}
            {!loading && data?.question && data.question.__typename === 'FreeQuestion' && (
                <FreeQuestion
                    question={data.question.text}
                    unit={data.question.unit}
                    skippable={data.question.skippable}
                    answer={startNewAssessment ? undefined : data.question.answer}
                    onSelect={(answer) =>
                        mutate(
                            answerForRequest({
                                demo,
                                questionId,
                                startNewAssessment,
                                answer,
                            })
                        )
                    }
                    onBack={backHandler}
                    updating={mutateLoading}
                />
            )}
            {!loading && data?.question?.__typename === 'FreeTextQuestion' && (
                <FreeTextQuestion
                    question={data.question}
                    updating={mutateLoading}
                    skippable={data.question.skippable}
                    onSelect={(answerText) =>
                        mutate(
                            answerForRequest({
                                demo,
                                questionId,
                                startNewAssessment,
                                answer: answerText,
                            })
                        )
                    }
                    onBack={backHandler}
                />
            )}
        </>
    )
}

function answerForRequest({
    demo,
    questionId,
    startNewAssessment,
    answer,
}: {
    demo: Props['demo']
    questionId: string
    startNewAssessment: boolean | undefined
    answer: string[] | number | string
}) {
    const answerObj: AnsweredQuestion = {
        questionId,
        startNewAssessment,
    }
    if (Array.isArray(answer)) {
        answerObj['chosenOptionIds'] = answer
    } else if (typeof answer === 'string') {
        answerObj['freeText'] = answer
    } else if (typeof answer === 'number') {
        answerObj['freeValue'] = answer
    }
    if (demo) {
        const allAnswers = [...demo.allAnswers]
        allAnswers.push(answerObj)
        demo.setDemoAnswers(allAnswers)
        return {
            variables: {
                demoType: demo.demoType,
                allAnswers,
            },
        }
    } else {
        return {
            variables: {
                answer: answerObj,
            },
        }
    }
}
