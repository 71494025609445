import React from 'react'
import { useNavigate } from 'react-router-dom'
import AccountDetails from 'ui/app/components/AccountDetails'
import MenuItem from 'ui/app/components/MenuItem'
import ContentPage from '../components/ContentPage'
import { useAuth } from 'registration/context/auth'
import { useDemoContext } from 'registration/pages/Demo/DemoContext'

export const UserProfile: React.FC = () => {
    const { demo } = useDemoContext()
    const navigate = useNavigate()
    const { isD2CUser } = useAuth()

    const onHome = () => {
        navigate(demo ? '/demo/service-user/checkIn' : '/welcome')
    }

    return (
        <ContentPage headingText="My Account" headingButton={{ text: 'Home', link: onHome }}>
            <AccountDetails />
            <MenuItem
                to={demo ? '/demo/service-user/regulatory-information' : '/regulatory-information'}
                label="About this app"
            />
            <MenuItem href="https://www.psyomics.com/termsofuse" label="Terms of Use" />
            <MenuItem href="https://www.psyomics.com/privacy-policy" label="Privacy Policy" />
            {isD2CUser && <MenuItem to={'/refund-policy'} label="Refund Policy" />}
        </ContentPage>
    )
}
