"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.questionPredicates = exports.tests = exports.aspects = exports.questions = void 0;
exports.questions = [
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ea198c0d-1822-46d7-9353-477e903451e5",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f1ffc187-d293-4e71-8167-b9a3ac2c9589",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "MSI-1",
        "text": "Have any of your closest relationships been troubled by a lot of arguments or repeated breakups?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "714cda97-5b1d-4e54-84d0-c235b0a75734",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b0ac9e73-827b-408e-b91b-11f212476a19",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "MSI-2",
        "text": "Have you deliberately hurt yourself physically (e.g. punched yourself, cut yourself, burned yourself)? How about made a suicide attempt?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "30b79416-6813-44da-8153-6cfd6b3872d1",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "7dbb7c64-7075-4447-930f-479c19b7f34c",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "MSI-3",
        "text": "Have you had at least two other problems with impulsivity (e.g. eating binges and spending sprees, drinking too much and verbal outbursts)?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "064a9dbc-b4b4-4a6d-b8da-009ca0f76ba6",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "6771ec66-b0be-4632-aca9-ca119104aaf1",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "MSI-4",
        "text": "Have you been extremely moody?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "34dfaf01-37a7-441b-bf8b-65fbbb90e451",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "ff4609e4-9764-4d65-8a96-c142896204af",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "MSI-5",
        "text": "Have you felt very angry a lot of the time? How about often acted in an angry or sarcastic manner?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "0d41e2bf-946f-4cc1-b796-d83648c2b5fb",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "42654395-1d9b-424d-97e1-86d097072f75",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "MSI-6",
        "text": "Have you often been distrustful of other people?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "65fb097b-b596-4faf-92e1-f8f368b4106e",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b282561c-a101-453a-b25e-fa18c2637e9c",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "MSI-7",
        "text": "Have you frequently felt unreal or as if things around you were unreal?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "fe8ca265-efe4-48cf-8761-fb79b115a6fc",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "5added35-1443-460c-b769-26782ecc23ce",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "MSI-8",
        "text": "Have you chronically felt empty?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "ab997c40-e162-45d3-9c36-577740c531f3",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "b966d782-f088-4e3b-afd0-e551adfb9791",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "MSI-9",
        "text": "Have you often felt that you had no idea of who you are or that you have no identity?",
        "isMultiselect": false
    },
    {
        "__typename": "RestrictedChoiceQuestion",
        "answers": [
            {
                "__typename": "Option",
                "id": "62018c69-53be-4500-a08a-0e864f7edd3a",
                "text": "No",
                "value": 0
            },
            {
                "__typename": "Option",
                "id": "f3d099e4-dc2c-4cf0-bb86-df9d017f76b0",
                "text": "Yes",
                "value": 1
            }
        ],
        "id": "MSI-10",
        "text": "Have you made desperate efforts to avoid feeling abandoned or being abandoned (e.g., repeatedly called someone to reassure yourself that he or she still cared, begged them not to leave you, clung to them physically)?",
        "isMultiselect": false
    }
];
exports.aspects = {
    "msi_bpd": {
        "type": "mean",
        "wires": [
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "MSI-1",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "ea198c0d-1822-46d7-9353-477e903451e5": 0,
                            "f1ffc187-d293-4e71-8167-b9a3ac2c9589": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "MSI-2",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "714cda97-5b1d-4e54-84d0-c235b0a75734": 0,
                            "b0ac9e73-827b-408e-b91b-11f212476a19": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "MSI-3",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "30b79416-6813-44da-8153-6cfd6b3872d1": 0,
                            "7dbb7c64-7075-4447-930f-479c19b7f34c": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "MSI-4",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "064a9dbc-b4b4-4a6d-b8da-009ca0f76ba6": 0,
                            "6771ec66-b0be-4632-aca9-ca119104aaf1": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "MSI-5",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "34dfaf01-37a7-441b-bf8b-65fbbb90e451": 0,
                            "ff4609e4-9764-4d65-8a96-c142896204af": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "MSI-6",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "0d41e2bf-946f-4cc1-b796-d83648c2b5fb": 0,
                            "42654395-1d9b-424d-97e1-86d097072f75": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "MSI-7",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "65fb097b-b596-4faf-92e1-f8f368b4106e": 0,
                            "b282561c-a101-453a-b25e-fa18c2637e9c": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "MSI-8",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "fe8ca265-efe4-48cf-8761-fb79b115a6fc": 0,
                            "5added35-1443-460c-b769-26782ecc23ce": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "MSI-9",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "ab997c40-e162-45d3-9c36-577740c531f3": 0,
                            "b966d782-f088-4e3b-afd0-e551adfb9791": 1
                        }
                    }
                }
            },
            {
                "type": "input",
                "signal": {
                    "type": "AnsweredOption",
                    "questionId": "MSI-10",
                    "options": {
                        "type": "severities",
                        "severities": {
                            "62018c69-53be-4500-a08a-0e864f7edd3a": 0,
                            "f3d099e4-dc2c-4cf0-bb86-df9d017f76b0": 1
                        }
                    }
                }
            }
        ]
    }
};
exports.tests = {
    "msi_bpd": [
        {
            "name": "MSI-BPD - if score 4 -> no BPD-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.4,
                "threshold": 0
            },
            "answers": {
                "MSI-1": {
                    "questionId": "MSI-1",
                    "chosenOptionIds": [
                        "f1ffc187-d293-4e71-8167-b9a3ac2c9589"
                    ]
                },
                "MSI-2": {
                    "questionId": "MSI-2",
                    "chosenOptionIds": [
                        "b0ac9e73-827b-408e-b91b-11f212476a19"
                    ]
                },
                "MSI-3": {
                    "questionId": "MSI-3",
                    "chosenOptionIds": [
                        "7dbb7c64-7075-4447-930f-479c19b7f34c"
                    ]
                },
                "MSI-4": {
                    "questionId": "MSI-4",
                    "chosenOptionIds": [
                        "6771ec66-b0be-4632-aca9-ca119104aaf1"
                    ]
                },
                "MSI-5": {
                    "questionId": "MSI-5",
                    "chosenOptionIds": [
                        "34dfaf01-37a7-441b-bf8b-65fbbb90e451"
                    ]
                },
                "MSI-6": {
                    "questionId": "MSI-6",
                    "chosenOptionIds": [
                        "0d41e2bf-946f-4cc1-b796-d83648c2b5fb"
                    ]
                },
                "MSI-7": {
                    "questionId": "MSI-7",
                    "chosenOptionIds": [
                        "65fb097b-b596-4faf-92e1-f8f368b4106e"
                    ]
                },
                "MSI-8": {
                    "questionId": "MSI-8",
                    "chosenOptionIds": [
                        "fe8ca265-efe4-48cf-8761-fb79b115a6fc"
                    ]
                },
                "MSI-9": {
                    "questionId": "MSI-9",
                    "chosenOptionIds": [
                        "ab997c40-e162-45d3-9c36-577740c531f3"
                    ]
                },
                "MSI-10": {
                    "questionId": "MSI-10",
                    "chosenOptionIds": [
                        "62018c69-53be-4500-a08a-0e864f7edd3a"
                    ]
                }
            }
        },
        {
            "name": "MSI-BPD - if score 6 -> borderline BPD-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.6,
                "threshold": 0
            },
            "answers": {
                "MSI-1": {
                    "questionId": "MSI-1",
                    "chosenOptionIds": [
                        "f1ffc187-d293-4e71-8167-b9a3ac2c9589"
                    ]
                },
                "MSI-2": {
                    "questionId": "MSI-2",
                    "chosenOptionIds": [
                        "b0ac9e73-827b-408e-b91b-11f212476a19"
                    ]
                },
                "MSI-3": {
                    "questionId": "MSI-3",
                    "chosenOptionIds": [
                        "7dbb7c64-7075-4447-930f-479c19b7f34c"
                    ]
                },
                "MSI-4": {
                    "questionId": "MSI-4",
                    "chosenOptionIds": [
                        "6771ec66-b0be-4632-aca9-ca119104aaf1"
                    ]
                },
                "MSI-5": {
                    "questionId": "MSI-5",
                    "chosenOptionIds": [
                        "ff4609e4-9764-4d65-8a96-c142896204af"
                    ]
                },
                "MSI-6": {
                    "questionId": "MSI-6",
                    "chosenOptionIds": [
                        "42654395-1d9b-424d-97e1-86d097072f75"
                    ]
                },
                "MSI-7": {
                    "questionId": "MSI-7",
                    "chosenOptionIds": [
                        "65fb097b-b596-4faf-92e1-f8f368b4106e"
                    ]
                },
                "MSI-8": {
                    "questionId": "MSI-8",
                    "chosenOptionIds": [
                        "fe8ca265-efe4-48cf-8761-fb79b115a6fc"
                    ]
                },
                "MSI-9": {
                    "questionId": "MSI-9",
                    "chosenOptionIds": [
                        "ab997c40-e162-45d3-9c36-577740c531f3"
                    ]
                },
                "MSI-10": {
                    "questionId": "MSI-10",
                    "chosenOptionIds": [
                        "62018c69-53be-4500-a08a-0e864f7edd3a"
                    ]
                }
            }
        },
        {
            "name": "MSI-BPD - if score 7 -> BPD-fuzzy",
            "expected": {
                "type": "CloseTo",
                "value": 0.7,
                "threshold": 0
            },
            "answers": {
                "MSI-1": {
                    "questionId": "MSI-1",
                    "chosenOptionIds": [
                        "f1ffc187-d293-4e71-8167-b9a3ac2c9589"
                    ]
                },
                "MSI-2": {
                    "questionId": "MSI-2",
                    "chosenOptionIds": [
                        "b0ac9e73-827b-408e-b91b-11f212476a19"
                    ]
                },
                "MSI-3": {
                    "questionId": "MSI-3",
                    "chosenOptionIds": [
                        "7dbb7c64-7075-4447-930f-479c19b7f34c"
                    ]
                },
                "MSI-4": {
                    "questionId": "MSI-4",
                    "chosenOptionIds": [
                        "6771ec66-b0be-4632-aca9-ca119104aaf1"
                    ]
                },
                "MSI-5": {
                    "questionId": "MSI-5",
                    "chosenOptionIds": [
                        "ff4609e4-9764-4d65-8a96-c142896204af"
                    ]
                },
                "MSI-6": {
                    "questionId": "MSI-6",
                    "chosenOptionIds": [
                        "42654395-1d9b-424d-97e1-86d097072f75"
                    ]
                },
                "MSI-7": {
                    "questionId": "MSI-7",
                    "chosenOptionIds": [
                        "b282561c-a101-453a-b25e-fa18c2637e9c"
                    ]
                },
                "MSI-8": {
                    "questionId": "MSI-8",
                    "chosenOptionIds": [
                        "fe8ca265-efe4-48cf-8761-fb79b115a6fc"
                    ]
                },
                "MSI-9": {
                    "questionId": "MSI-9",
                    "chosenOptionIds": [
                        "ab997c40-e162-45d3-9c36-577740c531f3"
                    ]
                },
                "MSI-10": {
                    "questionId": "MSI-10",
                    "chosenOptionIds": [
                        "62018c69-53be-4500-a08a-0e864f7edd3a"
                    ]
                }
            }
        }
    ]
};
exports.questionPredicates = {};
